import { withRouter } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import { rootStore } from "../../mobx/store";
import { Page_settings } from "../../config/page_settings";
import strings from "../../lang/strings";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import AddChapterUnitModal from "./modal/add_chapter_unit_modal";
import CreateCurriculumStep4Modal from "./modal/create_curriculum_step4_modal";
import WarningModal from "./modal/warning_modal";
import StudentCourseInfoModal from "./modal/student_course_info_modal";
import { getMinuteFromSecond } from "../../helper/common";
import Exam_file_viewer from "../class/quiz/exam_file_viewer";

const Test = (props) => {
  const history = useHistory();
  const context = useContext(Page_settings);
  const [answerList, setAnswerList] = useState([]);
  const [time, setTime] = useState(0);// 시험 시간
  const [testInfo,setTestInfo] = useState();

  const test_id = parseInt(props.match.params.id);


  useEffect(() => {
    
    loadTestData();
    context.handleSetPageHeader(false);
    context.handleSetPageSidebar(false);

    const timer = window.setInterval(() => {
      setTime(prevTime => prevTime - 1);
    }, 1000);

    return () => {
      context.handleSetPageHeader(true);
      context.handleSetPageSidebar(true);
      window.clearInterval(timer);
    }
  }, []);

  const loadTestData = () => {

    context.get(
      "curriculum/getTestData",
      {
        test:test_id
      },
      response => {
        const answerData = JSON.parse(response.answer_data);
                const answers = answerData.map((item, idx) => {
                    const updatedItem = {
                        ...item,
                        answer: '',
                    };

                    return updatedItem;
                });
        setAnswerList(answers);
        setTestInfo(response);
        console.log("response: " + JSON.stringify(response));
        setTime(parseInt(response.time) * 60);
      }
    );
  }


  const onChangeAnswer = (index, answer) => {
    const newList = answerList.map((item, idx) => {
      if (idx === index) {
        const updatedItem = {
          ...item,
          answer: answer,
        };

        return updatedItem;
      }

      return item;
    });
    setAnswerList(newList);
  }

  const submitExam = () => {
    let answer = JSON.stringify(answerList);
        context.post(
            'curriculum/setTestResult',
            {
                test: test_id,
                time: (parseInt(testInfo.time) * 60 - time),
                answer_data: answer,
            },
            response => {
                // props.history.push('/exam/student/' + test_id);
                history.replace(`/curriculum/test_review/${test_id}`,{from:'test'})
            }
        );
    
  }

  const handleBack =() => {
    history.goBack();
  }
  



  return (
    <div class="main-content exam-container">
      <div class="time-limit">
        <div className='d-flex flex-row align-items-center' style={{ margin: 'auto', width: 132, height: 38, borderRadius: 6, background: '#FFF', padding: '0 13px' }}>
          <div className="flex-grow-1" style={{ color: '#222', fontSize: 14, fontWeight: 700 }}>{'남은 시간'}</div>
          <div className="enter-time" style={{ color: '#EB4A4A', fontSize: 14, fontWeight: 500 }}>{getMinuteFromSecond(time)}</div>
        </div>
      </div>
      <div className="left-container">
        <div className="top-container">
          <div className="header-container">
            <div className="left">
              <img className="cursor" src="/assets/image/icon_arrow_back_black.png" onClick={()=>handleBack()} alt="" />
              <div className="title"><span className="highlight">{testInfo?.section_name}</span> 테스트</div>
            </div>
            <div className="right">
              {answerList.length}문항
            </div>
          </div>
        </div>
        <div className="bottom-container">
          {/* <img src="/assets/image/img_exam_example.png" alt="" /> */}
          <div className='info-wrap' style={{ flex: 1, overflowY: 'auto', position: 'relative', height: '100%' }}>
                                <Exam_file_viewer url={context.loadImage(testInfo?.test_url)} />
                            </div>
        </div>
      </div>
      <div className="right-container">
        <div className="title">
          OMR 답안지
        </div>
        <PerfectScrollbar style={{ height: 'calc(100vh - 300px)' }} className="omr-content">
          <div className='omr-data'>
            <div className='omr-header'>
              <div className='omr-no'>{'문제'}</div>
              <div style={{ width: 1, height: '100%', background: '#DFE1E8' }} />
              <div className='omr-answer'>
                <div>1</div>
                <div>2</div>
                <div>3</div>
                <div>4</div>
                <div>5</div>
              </div>
              <div style={{ width: 1, height: '100%', background: '#DFE1E8' }} />
              <div className='omr-rate'>
                <div>{'배점'}</div>
              </div>
            </div>
            <div className='omr-list'>
              {
                answerList.map((answer, index) => {
                  return <div key={index} className='omr-item'>
                    <div className='omr-no'>{(index + 1) + '번'}</div>
                    <div style={{ width: 1, height: '100%', background: '#DFE1E8' }} />
                    {
                      answer.type == 1 ?
                        <div className='omr-answer'>
                          <button className={'check-item' + (answer.answer == '1' ? ' selected' : '')} onClick={() => { onChangeAnswer(index, '1') }} />
                          <button className={'check-item' + (answer.answer == '2' ? ' selected' : '')} onClick={() => { onChangeAnswer(index, '2') }} />
                          <button className={'check-item' + (answer.answer == '3' ? ' selected' : '')} onClick={() => { onChangeAnswer(index, '3') }} />
                          <button className={'check-item' + (answer.answer == '4' ? ' selected' : '')} onClick={() => { onChangeAnswer(index, '4') }} />
                          <button className={'check-item' + (answer.answer == '5' ? ' selected' : '')} onClick={() => { onChangeAnswer(index, '5') }} />
                        </div>
                        :
                        <div className='omr-answer'>
                          <input value={answer.answer} onChange={(e) => { onChangeAnswer(index, e.target.value) }} />
                        </div>
                    }
                    <div style={{ width: 1, height: '100%', background: '#DFE1E8' }} />
                    <div className={'omr-rate'}>
                      <input value={answer.rate} disabled />
                    </div>
                  </div>
                })
              }
            </div>
          </div>
        </PerfectScrollbar>
        <div className="btn-container">
          <button className="submit-btn" onClick={() => submitExam()}>제출하기</button>
        </div>
      </div>



    </div >
  );






}
export default withRouter(Test);
