import React, { useContext, useEffect, useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from "styled-components";
import strings from "../../../lang/strings";
import StudentSearch from "../customSearch/studentSearch";
import { Page_settings } from '../../../config/page_settings';
import { rootStore } from "../../../mobx/store";
import CalendarModal from './calendarModal';
import axios from "axios";

function ApproveModal({ onClose,submit_id, stu_id }) {
  const context = useContext(Page_settings);
  

  
  const handleClose = () => {
    onClose?.();
  };
  const approve = () => {
    
    context.post('class/changeStatusMission',
      {
        submit_id,
        mission_status : '승인',
        stu_id
      },
      response => {
        onClose?.();
      })
  }


  return (
     <>
    
    <Overlay>
      <ModalWrap>
        <div class="approveModal-container">
        <div class="approveModal-head">
          승인
        </div>
        <div class="approveModal-subTitle">
          정말로 승인하시겠습니까?
        </div>
        <div class="approveModal-bottom">
          <button class="cancel-btn" onClick={()=> handleClose()}>취소</button>
          <button class="approve-btn" onClick={()=> approve()}>승인</button>
        </div>
        </div>
      </ModalWrap>
    </Overlay>
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const ModalWrap = styled.div`
  width: 350px;
  height: fit-content;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -70%);
  padding: 10px;
  overflow: auto;
`;


export default ApproveModal;