import React from "react";
import { IconType } from "../../type/icon_type";


const IconFilter = (props: IconType) => {
    const { width = "24px", height = "24px", color = "#73787E" } = props;
    
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 5.6H13.7778M10.2222 5.6H4M20 12H12M8.44444 12H4M20 18.4H15.5556M12 18.4H4M13.7778 4V7.2M8.44444 10.4V13.6M15.5556 16.8V20" stroke={color} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export default IconFilter;