import { withRouter } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import { rootStore } from "../../../mobx/store";
import { Page_settings } from "../../../config/page_settings";
import strings from "../../../lang/strings";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import Step1 from "./edit_curriculum_step1";
import Step2 from "./edit_curriculum_step2";
import Step3 from "./edit_curriculum_step3";
import Step4 from "./edit_curriculum_step4";
import Step5 from "./edit_curriculum_step5";

const EditCurriculum = (props) => {
  const history = useHistory();
  const location = useLocation();
  const context = useContext(Page_settings);
  const [openCreateRoadMap, setOpenCreateRoadMap] = useState(false);
  const curriculumStep = parseInt(props.match.params.step);
  const curriculumId = parseInt(props.match.params.id);
  const [curriculumData,setCurriculumData] = useState();
  const [lastClickPart,setLastClickPart] = useState();
  

    useEffect(() => {
      loadCurriculumData();
    },[curriculumId]);
    
    const loadCurriculumData = () => {
      context.get(
        "curriculum/getCurriculumData",
        {
          curriculum_id:curriculumId
        },
        response => {
          console.log("res",response);
            setCurriculumData(response.list)
        }
      );
    }

    const updatePartCategory = (newPartCategory) => {
      setCurriculumData(prevData => ({
        ...prevData,
        parts: newPartCategory
      }));
    }

    const updateThumbnail = (newThumbnail) => {
      setCurriculumData(prevData => ({
        ...prevData,
        thumbnail: newThumbnail
      }));
    }

    const updateLastClickPart = (color,subject) => {
      
      setLastClickPart({
        color,
        subject
      });
    }
    

    return(
      <div>
        {
                        curriculumStep == 1 &&
                        <Step1
                        curriculum={curriculumId}
                        curriculumData={curriculumData}
                        lastClickPart={lastClickPart}
                        updatePartCategory={updatePartCategory} 
                        updateThumbnail={updateThumbnail}
                        updateLastClickPart={updateLastClickPart}
                        />
                    }
                    {
                        curriculumStep == 2 &&
                        <Step2
                        curriculumData={curriculumData}
                        curriculum={curriculumId}
                        updateThumbnail={updateThumbnail}
                        updatePartCategory={updatePartCategory} 
                        />
                    }
                    {
                        curriculumStep == 3 &&
                        <Step3
                        curriculumData={curriculumData}
                        curriculum={curriculumId}
                        updateThumbnail={updateThumbnail}
                        updatePartCategory={updatePartCategory} 
                        />
                    }
                    {
                        curriculumStep == 4 &&
                        <Step4
                        curriculumData={curriculumData}
                        curriculum={curriculumId}
                        updateThumbnail={updateThumbnail}
                        updatePartCategory={updatePartCategory} 
                        />
                    }
                        {
                        curriculumStep == 5 &&
                        <Step5
                        curriculumData={curriculumData}
                        updateThumbnail={updateThumbnail}
                        updatePartCategory={updatePartCategory} 
                        />
                    }
      </div>
    );




    
    
}
export default withRouter(EditCurriculum);
