import React from "react";
import colors from "../../../design/colors";
import IconX from "../../icons/x";
import styled from "styled-components";
import fonts from "../../../design/typography";

interface InquiryContentProps {
    toggle: () => void;
}


const InquiryContent = ({ toggle }: InquiryContentProps) => {
    return (
        <ContentWrapper>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: 20, width: "100%"}}>
                <span style={{...fonts.label2SemiBold, color: colors.gray900}}>문의</span>
                <div style={{cursor: "pointer"}} onClick={toggle}>
                    <IconX width="32px" height="32px" color={colors.gray900} background={colors.gray900}/>
                </div>
            </div>
            <TermsWrapper>
                <span style={{...fonts.heading1Bold , color: colors.gray900}}>문의는 아직 준비중입니다.</span>
            </TermsWrapper>
        </ContentWrapper>
    );
};

export default InquiryContent;

const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 28px 32px 0 32px;
    position: relative;
`;

const TermsWrapper = styled.div`
    width: 100%;
    height: calc(100% - 76px);
    overflow-y: auto;
    padding: 16px;
    background-color: ${colors.white};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-thumb {
        background: ${colors.gray400};
        border-radius: 4px;
    }
`;