import React from 'react';
import {Link} from 'react-router-dom';
import { matchPath, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const SidebarNavList= (props) => {
    const { data } = props;
    const curUrl = useLocation().pathname;
    if(data === null || curUrl === '/login') return null; 

    
    let icon_active =  data.icon_active &&
        <img className="menu-icon" src={ data.icon_active} alt="" style={{ marginLeft:18 }} />;
    let icon_inactive =  data.icon_inactive &&
        <img className="menu-icon" src={ data.icon_inactive} alt="" style={{ marginLeft:18 }} />;
    let label =  data.label && <span className="label label-theme m-l-5">{ data.label}</span>;
    let badge =  data.badge && <span className="badge pull-right">{ data.badge}</span>;
    let title =  data.title && <span>{ data.title} {label}</span>;
    let highlight =  data.highlight && <i className="fa fa-paper-plane text-theme"/>;
    const pathMatchMain = (!!matchPath(curUrl, { path: data.path, exact: false })) || (data.children && data.children.some(child => matchPath(curUrl, { path: child.path, exact: true })));
    const className = (pathMatchMain ? "active " : "") + 'expand ' + ( data.children ? "has-sub " : "") + ( data.path === '/curriculum/store'? "navbar-cloud":"");
    const exactMatch = (path) => matchPath(curUrl, { path: path, exact: true })?.isExact;

    return (
            <li className={className}>
                <Link to={ data.path} > {badge} {pathMatchMain ? icon_active : icon_inactive} {title} {highlight}</Link>
                 <ul className="sub-menu">
                {
                    data?.children &&
                    data?.children.map((child, i) => (
                        <li className={exactMatch(child.path) ? 'active':''}>
                        <Link key={child.path} to={ child.path} > {child.title}</Link>
                        </li>
                    ))
                }
                </ul>
            </li>
    )
    
}

export default SidebarNavList;
