import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import {data} from './unit_data.js'
function AddUnitModal(props) {
  const [classification, setClassification] = useState('');
  const [part, setPart] = useState('');
  const [chapter, setChapter] = useState('');
  const [section, setSection] = useState('');

  const [classificationBox, setClassificationBox] = useState(false);
  const [partBox, setPartBox] = useState(false);
  const [chapterBox, setChapterBox] = useState(false);
  const [sectionBox, setSectionBox] = useState(false);

  const course = props.course;
  const subject = props.subject;

  const [classificationList, setClassificationList] = useState([]);
  const [partOptions, setPartOptions] = useState([]);
  const [chapterOptions, setChapterOptions] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);


  useEffect(() => {
    setClassificationList(Object.keys(data[course]?.[subject] || {}));
    setPartOptions([]);
    setChapterOptions([]);
    setSectionOptions([]);
  }, [course, subject]);

  useEffect(() => {
    const classificationData = data[course]?.[subject]?.[classification];
    const newPartOptions = classificationData 
      ? Array.isArray(classificationData) 
        ? classificationData 
        : Object.keys(classificationData)
      : [];
    setPartOptions(newPartOptions);
    setChapterOptions([]);
    setSectionOptions([]);
    console.log("classification", newPartOptions);
  }, [classification]);

  useEffect(() => {
    const partData = data[course]?.[subject]?.[classification]?.[part];
    const newChapterOptions = partData
      ? Array.isArray(partData)
        ? partData
        : Object.keys(partData)
      : [];
    setChapterOptions(newChapterOptions);
    setSectionOptions([]);
    console.log("part", newChapterOptions);
  }, [part]);

  useEffect(() => {
    const chapterData = data[course]?.[subject]?.[classification]?.[part]?.[chapter];
    const newSectionOptions = chapterData
      ? Array.isArray(chapterData)
        ? chapterData
        : []
      : [];
    setSectionOptions(newSectionOptions);
    console.log("chapter", newSectionOptions);
  }, [chapter]);

  const handleClassificationChange = (item) => {
    setClassification(item);
    setPart('');
    setChapter('');
    setSection('');
    setClassificationBox(false);
    setPartBox(false);
  };

  const handlePartChange = (item) => {
    setPart(item);
    setChapter('');
    setSection('');
    setPartBox(false);
    setChapterBox(false);
  };

  const handleChapterChange = (item) => {
    setChapter(item);
    setSection('');
    setChapterBox(false);
    setSectionBox(false);
  };

  const handleSectionChange = (item) => {
    setSection(item);
    setSectionBox(false);
  };

  const EnableConfirm = () => {
    return classification;
  };
  
  const addUnit = () => {
    const unitData = {};

  if (classification) unitData.classification = classification;
  if (part) unitData.part = part;
  if (chapter) unitData.chapter = chapter;
  if (section) unitData.section = section;

  if (Object.keys(unitData).length > 0) {
    props.getUnitData(unitData);
  }
    props.onClose();
  }


  return (
    <>
      <Overlay>
        <ModalWrap>
          <div className='create-roadmap-step0-modal quiz-add-unit-modal'>
            <div className="classAddModal-head">
              <div className='title'>단원 추가</div>
              <img className="exit-btn" onClick={() => props.onClose()} src="/assets/image/icon_close_gray.png" alt="" />
            </div>

            <div className="classAddModal-subTitle">분류</div>
            <div className="class-search align-item-center box-shadow" style={classificationBox ? { borderRadius: '10px 10px 0px 0px', position: 'relative' } : {}}>
              <input
                className="input-transparent cursor"
                placeholder={"검색"}
                type={'text'}
                value={classification}
                onClick={() => setClassificationBox(!classificationBox)}
                readOnly
              />
              <img className="cursor" onClick={() => setClassificationBox(!classificationBox)} src={"/assets/image/icon_dropdown_down_arrow_black.png"} alt='' />
            </div>
            {classificationBox &&
              <DropDownBox>
                {classificationList.map((item, idx) => (
                  <DropDownItem key={idx} onClick={() => handleClassificationChange(item)}>
                    {item}
                  </DropDownItem>
                ))}
              </DropDownBox>
            }

            <div className="classAddModal-subTitle">대단원</div>
            <div className="class-search align-item-center box-shadow" style={partBox && partOptions.length > 0 ? { borderRadius: '10px 10px 0px 0px', position: 'relative' } : {}}>
              <input
                className="input-transparent cursor"
                placeholder={"검색"}
                type={'text'}
                value={part}
                onClick={() => setPartBox(!partBox)}
                readOnly
              />
              <img className="cursor" onClick={() => setPartBox(!partBox)} src={"/assets/image/icon_dropdown_down_arrow_black.png"} alt='' />
            </div>
            {partBox && partOptions.length > 0 &&
              <DropDownBox>
                {partOptions.map((item, idx) => (
                  <DropDownItem key={idx} onClick={() => handlePartChange(item)}>
                    {item}
                  </DropDownItem>
                ))}
              </DropDownBox>
            }

            <div className="classAddModal-subTitle">중단원</div>
            <div className="class-search align-item-center box-shadow" style={chapterBox && chapterOptions.length > 0 ? { borderRadius: '10px 10px 0px 0px', position: 'relative' } : {}}>
              <input
                className="input-transparent cursor"
                placeholder={"검색"}
                type={'text'}
                value={chapter}
                onClick={() => setChapterBox(!chapterBox)}
                readOnly
              />
              <img className="cursor" onClick={() => setChapterBox(!chapterBox)} src={"/assets/image/icon_dropdown_down_arrow_black.png"} alt='' />
            </div>
            {chapterBox && chapterOptions.length > 0 &&
              <DropDownBox>
                {chapterOptions.map((item, idx) => (
                  <DropDownItem key={idx} onClick={() => handleChapterChange(item)}>
                    {item}
                  </DropDownItem>
                ))}
              </DropDownBox>
            }
            <div className="classAddModal-subTitle">소단원</div>
            <div className="class-search align-item-center box-shadow" style={sectionBox && sectionOptions.length > 0 ? { borderRadius: '10px 10px 0px 0px', position: 'relative' } : {}}>
              <input
                className="input-transparent cursor"
                placeholder={"검색"}
                type={'text'}
                value={section}
                onClick={() => setSectionBox(!sectionBox)}
                readOnly
              />
              <img className="cursor" onClick={() => setSectionBox(!sectionBox)} src={"/assets/image/icon_dropdown_down_arrow_black.png"} alt='' />
            </div>
            {sectionBox && sectionOptions.length > 0 &&
              <DropDownBox>
                {sectionOptions.map((item, idx) => (
                  <DropDownItem key={idx} onClick={() => handleSectionChange(item)}>
                    {item}
                  </DropDownItem>
                ))}
              </DropDownBox>
            }
          </div>

          <Button style={{ backgroundColor: EnableConfirm() ? '#0F84F4' : '#DFE3E8' }} disabled={!EnableConfirm()} onClick={addUnit}>
            <img style={{ width: '10px' }} src='/assets/image/icon_plus_white.png' alt='' />
            <div>만들기</div>
          </Button>
        </ModalWrap>
      </Overlay>
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const ModalWrap = styled.div`
  width: 700px;
  height: 750px;
  border-radius: 15px;
  background-color: #fff;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  overflow: auto;
`;

const Button = styled.button`
  cursor: pointer;
  width: calc(100% - 80px);
  color: rgb(255, 255, 255);
  border-radius: 5px;
  border: 0px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  gap: 5px;
  position: absolute;
  bottom: 40px;
`;

const DropDownBox = styled.ul`
  display: block;
  position: absolute;
  margin: 0 auto;
  padding: 0px 5px;
  width: 89%;
  background-color: white;
  border: 1px solid #DFE1E8;
  border-radius: 8px;
  box-shadow: 0 10px 10px rgb(0, 0, 0, 0.3);
  list-style-type: none;
  z-index: 3;
  max-height: 200px;
  overflow: auto;
`;

const DropDownItem = styled.li`
  text-align: start;
  padding: 0px 5px;
  margin: 10px 0px;
  cursor: pointer;
`;

export default AddUnitModal;