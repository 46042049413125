
import { Page_settings } from '../../../config/page_settings';
import HomeSec1 from '../Home/HomeSec1';
import HomeSec2 from '../Home/HomeSec2';
import HomeSec3 from '../Home/HomeSec3';
import HomeSec4 from '../Home/HomeSec4';
import HomeSec5 from '../Home/HomeSec5';
import HomeSec6 from '../Home/HomeSec6';
import HomeSec7 from '../Home/HomeSec7';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import "../style/main.css";
import "../style/mob.css";
import "../style/reset.css";
import "../style/sub.css";
import Header from '../Layout/Header';
import Qna from '../Layout/Qna';
import Footer from '../Layout/Footer';


const Main = () => {
    const context = useContext(Page_settings);
    const idRef = useRef();

    const history = useHistory();
    useEffect(() => {
        const userInfoString = localStorage.getItem("user_info");
        const userInfo = JSON.parse(userInfoString);
    
        // // 'id' 키가 있는지 확인합니다.
        // if (userInfo && userInfo.hasOwnProperty('id')) {
        //     history.push("/class"); // "/class" 페이지로 리디렉션
        // }
            context.handleSetPageHeader(false);
            context.handleSetPageSidebar(false);
    
            idRef?.current?.focus();
    
            return () => {
                context.handleSetPageHeader(true);
                context.handleSetPageSidebar(true);
            }
        }, []);


    return (
        <>  
            <Header/>
            <HomeSec1 />
            <HomeSec2 />
            <HomeSec3 />
            <HomeSec4 />
            <HomeSec5 />
            <HomeSec6 />
            <HomeSec7 />
            <Qna/>
            <Footer/>
        
        </>
    );
}
    
export default Main