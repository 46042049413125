import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter, Link } from "react-router-dom";
import "../style/reset.css";
import "../style/main.css";
import "../style/mob.css";
import "../style/sub.css";
import { rootStore } from "../../../mobx/store";
import { useNavigate } from "react-router-dom";


// 중복된 css 파일이 있는지 체크
function isCSSLinkExists(href) {
    return Array.from(document.head.getElementsByTagName("link")).some(
        (link) => link.href === href
    );
}

function addCSSLink(href) {
    if (!isCSSLinkExists(href)) {
        const css = document.createElement("link");
        css.rel = "stylesheet";
        css.href = href;
        document.head.appendChild(css);
    }
}

// 중복된 js파일이 있는지 체크
function isJSScriptExists(src) {
    return Array.from(document.head.getElementsByTagName("script")).some(
        (script) => script.src === src
    );
}

function addJSScript(src) {
    if (!isJSScriptExists(src)) {
        const js = document.createElement("script");
        js.src = src;
        document.head.appendChild(js);
    }
}


const onSignUp = (loginType, email) => {
    rootStore.setSignUpInfo({
        loginType: loginType,
        email: email
    });
    
}

const Header = () => {
    const [isOpen, setIsOpen] = useState(false); // 메뉴바 오픈
    const [didScroll, setDidScroll] = useState(false); // 스크롤 상태
    const [lastScrollTop, setLastScrollTop] = useState(0); // 마지막 스크롤

    const delta = 5;
    const headerRef = useRef(null);

    // header 스크롤 상태 변경
    const handleScroll = () => {
        setDidScroll(true);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    
        const interval = setInterval(() => {
            if (didScroll) {
                hasScrolled();
                setDidScroll(false);
            }
        }, 250);
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
            clearInterval(interval);
        };
    }, [didScroll, lastScrollTop]);

    // scroll시 실행
    const hasScrolled = () => {
        const st =  window.scrollY;
        const navbarHeight = headerRef.current ? headerRef.current.offsetHeight : 0;

        if (Math.abs(lastScrollTop - st) <= delta) {
            return;
        }
        if (st > lastScrollTop && st > navbarHeight) {
            // Scroll Down
            headerRef.current.classList.add('header_up');
            headerRef.current.classList.remove('header_down');
        } else {
            // Scroll Up
            if(st + window.innerHeight < document.documentElement.scrollHeight) {
                headerRef.current.classList.remove('header_up');
                headerRef.current.classList.add('header_down');
            }
        }
        setLastScrollTop(st);
    }

    // 햄버거 버튼 클릭 시 메뉴 토글
    const toggleMenu = () => {
        setIsOpen(!isOpen);
        if (!isOpen) {
            // 메뉴가 열릴 때 body에 overflow: hidden 스타일 적용
            document.body.style.overflow = 'hidden';
        } else {
            // 메뉴가 닫힐 때 body에 overflow: auto 스타일 복원
            document.body.style.overflow = 'auto';
        }
    };

    const handleLinkClick = () => {
        // 화면 맨 위로 스크롤
        window.scrollTo(0, 0);
    }

    const menuLinkClick = () => {
        // 화면 맨 위로 스크롤
        window.scrollTo(0, 0);
        // 메뉴 닫기
        setIsOpen(false);
        document.body.style.overflow = 'auto';
    };
    // cdn link 추가
    addJSScript(
        "https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"
    )
    
    addCSSLink(
        "https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css"
    );

    return (
        <>
        <header ref={headerRef} className="lading-header">
            <div className="h-left">
            <div className="logo">
                <Link to="/landing/main" onClick={menuLinkClick}>
                <img src={"/assets/image/landing_page/logo_chewing_plus.png"} alt="츄잉 로고" style={{width:'100%'}} />
                </Link>
            </div>
            <ul className="main-nav">
                <li>
                <Link to={"/landing/main"} onClick={handleLinkClick}>대시보드기반 클래스</Link>
                </li>
                <li>
                <Link to={"/landing/ai"} onClick={handleLinkClick}>
                    AI 코스웨어
                    <span className="point">NEW</span>
                </Link>
                </li>
                <li>
                <Link to={"/landing/platform"} onClick={handleLinkClick}>실시간 소통 플랫폼</Link>
                </li>
                <li>｜</li>
                <li>
                <Link to="/">가이드</Link>
                </li>
                <li>
                <Link to="/">요금</Link>
                </li>
            </ul>
            </div>
            <ul className="h-login" >
            <li>
                <Link to="/login">로그인</Link>
            </li>
            <li onClick={() => onSignUp("EMAIL", "")}>
                <Link to="/signup/0">회원가입</Link>
            </li>
            </ul>
            <div className={`hamburger ${isOpen ? 'menu-open' : ''}`} onClick={toggleMenu} id="hamburger">
            <span></span>
            <span></span>
            <span></span>
            </div>
            <div className={`mob-menu ${isOpen ? 'menu-open' : ''}`} id="mob-menu">
            <ul className="mob-nav">
                <li>
                <Link to="/class" onClick={menuLinkClick}>대시보드기반 클래스</Link>
                </li>
                <li>
                <Link to="/ai" onClick={menuLinkClick}>AI 코스웨어</Link>
                </li>
                <li>
                <Link to="/platform" onClick={menuLinkClick}>실시간 소통 플랫폼</Link>
                </li>
                <li>
                <Link to="/">가이드</Link>
                </li>
                <li>
                <Link to="/">요금</Link>
                </li>
            </ul>
            <ul className="mob-login">
                <li>
                <Link to="/">로그인</Link>
                </li>
                <li>
                <Link to="/">회원가입</Link>
                </li>
            </ul>
            </div>
        </header>
        </>
    );
};

export default Header;