import React, { useContext, useEffect, useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from "styled-components";
import strings from "../../../lang/strings";

import { Page_settings } from '../../../config/page_settings';
import { rootStore } from "../../../mobx/store";
import { API_URL } from "../../../config/const";
import axios from "axios";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import moment from "moment";
import CalendarModal from '../../class/Modals/calendarModal';

function CreateCurriculumStep0Modal(props) {
  const history = useHistory();
  const [wholeTextArray, setWholeTextArray] = useState([]);
  const [classId, setClassId] = useState('')
  const [classValue, setClassValue] = useState('')
  const [titleValue, setTitleValue] = useState('')
  const [creatorValue, setCreatorValue] = useState('')
  const [watchVideoValue, setWatchVideoValue] = useState('') // 문제 풀이
  const [solveValue, setSolveValue] = useState('') // 문제 풀이
  const [testScoreValue, setTestScoreValue] = useState('') // 테스트 점수
  const [isHaveInputValue, setIsHaveInputValue] = useState(false)
  const [clickInput, setClickInput] = useState(false)
  const [dropDownList, setDropDownList] = useState(wholeTextArray)
  const [dropDownItemIndex, setDropDownItemIndex] = useState(-1)
  const [allSelectedStatus, setAllSelectedStatus] = useState(false);
  const imageRef = useRef();
  const [imgFile, setImgFile] = useState("")
  const [imgName, setImgName] = useState("");
  const [url, setUrl] = useState([]);
  const [profile, setProfile] = useState("");
  const [dateValue, setDateValue] = useState(moment().format('YYYY-MM-DD'))
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectThumbnail, setSelectThumbnail] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [selectBasicImg, setSelectBasicImg] = useState(false);

  const context = useContext(Page_settings);
  const limit_time = [];

  useEffect(() => {
    loadClassList();

    if (rootStore.getProfile != null) {
      setProfile(rootStore.getProfile.profile);
    }
  }, []);

  useEffect(() => {
    showDropDownList()
  }, [classValue, clickInput])

  useEffect(() => {
    function handleClickOutside(event) {

      function getIdOrParentId(event) {
        let targetElement = event.target;

        // targetElement에 id가 있으면 해당 id를 사용
        while (targetElement && !targetElement.id) {
          // id가 없으면 부모 요소로 이동
          targetElement = targetElement.parentNode;
        }

        // 최종적으로 찾은 요소의 id 반환, 요소에 id가 없다면 null 반환
        return targetElement ? targetElement.id : null;
      }

      if (getIdOrParentId(event) !== 'inputBox' && getIdOrParentId(event) !== 'dropBox' && getIdOrParentId(event) !== 'dropBox1') {
        handleFocusOut();  // 외부 클릭 시 실행할 함수
      }
    }

    // 문서 전체에 이벤트 리스너 추가
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // 컴포넌트 언마운트 시 이벤트 리스너 제거
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);  // 의존성 배열에 handleFocusOut 추가

  const loadClassList = () => {
    context.get(
      'class/getClassList',
      {},
      response => {
        if (response) {
          setWholeTextArray(response);
        }
      });
  }

  const showDropDownList = () => {
    setDropDownList(wholeTextArray);
  };

  for (let hours = 0; hours < 24; hours++) {
    for (let minutes = 0; minutes < 60; minutes += 10) {
      const hourFormatted = hours.toString().padStart(2, '0');
      const minuteFormatted = minutes.toString().padStart(2, '0');
      limit_time.push({ time: `${hourFormatted}:${minuteFormatted}` });
    }
  }

  const changeInputValue = event => {
    setClassValue(event.target.value)
    setIsHaveInputValue(true)
    setClickInput(false)
  }

  const changeWatchVideoValue = e => {
    const value = e.target.value;
    // 숫자가 아니거나 음수일 경우 빈 문자열로 설정
    if (value === '' || (/^\d+$/.test(value) && parseInt(value) >= 0)) {
      setWatchVideoValue(value);
    }
  }

  const changeSolveValue = e => {
    const value = e.target.value;
    // 숫자가 아니거나 음수일 경우 빈 문자열로 설정
    if (value === '' || (/^\d+$/.test(value) && parseInt(value) >= 0)) {
      setSolveValue(value);
    }
  }

  const changeTestScoreValue = e => {
    let value = parseInt(e.target.value);
    if (value > 100) {
      value = 100;
    }
    // 숫자가 아니거나 음수일 경우 빈 문자열로 설정
    if (value === '' || (/^\d+$/.test(value) && parseInt(value) >= 0)) {
      setTestScoreValue(value);
    }
  }

  const clickInputBox = event => {
    setClickInput(true)
  }

  const changeTitleValue = event => {
    const newValue = event.target.value;
    if (newValue.length <= 15) {
      setTitleValue(newValue);
    }
  }

  const changeDateValue = event => {
    setDateValue(event.target.value)
  }

  const changeCreatorValue = event => {
    setCreatorValue(event.target.value)
  }

  const handleFocusOut = () => {
    setIsHaveInputValue(false)
    setAllSelectedStatus(false);
    setClickInput(false);
  }

  const clickValue = (id, class_info) => {
    setClassId(id)
    setClassValue(class_info)
    setClickInput(false)
  }

  const onClickDate = () => {
    setOpenCalendar(!openCalendar);
  };

  const handleClose = () => {
    props.onClose?.();
  };

  const EnableConfirm = () => {
    return (
      classValue !== '' && titleValue !== '' && creatorValue !== '' && dateValue !== '' && (isChecked || imgFile.length > 0)
    )
  }

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    // setSelectBasicImg(true)
  };

  const handleImageClick = () => {
    if (!isChecked && imageRef.current) {
      imageRef.current.click();
    }
  };

  const onImageChange = (e) => {
    if (e.target.files.length < 1) {
      return;
    }
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.onloadend = function () {
      setSelectThumbnail(true);
      setImgFile(reader.result);
      setImgName(file.name);
      setUrl(file);
    };
    reader.readAsDataURL(file);
  };

  const onCreateRoadMap = async () => {
    const step0Data = {
      class_id: classId,
      classValue,
      title: titleValue,
      open_date: dateValue,
      creator_name: creatorValue,
      imgFile: imgFile,
      imgName: imgName,
      checkBasicImg: isChecked
    };

    sessionStorage.setItem('curriculum', JSON.stringify(step0Data));
    history.push('/curriculum/create_curriculum/1');
  }

  return (
    <>
      <Overlay>
        <ModalWrap>
          <div className='create-roadmap-step0-modal'>
            {!selectBasicImg ?
              <>
                <div className="classAddModal-head">
                  <div className='title'>나만의 커리큘럼 제작하기</div>
                  <img className="exit-btn" onClick={handleClose} src="/assets/image/icon_close_gray.png" alt="" />
                </div>
                <div className="classAddModal-subTitle">
                  클래스 선택
                </div>
                <div className="studentAddModal-searchContainer" isHaveInputValue={isHaveInputValue.toString()}>
                  <div className="class-search align-item-center box-shadow" style={{ flex: 8 }}>
                    <input
                      id='inputBox'
                      autoComplete="off" // 자동 완성 기능 비활성화
                      className="input-transparent cursor"
                      placeholder={"클래스를 선택해주세요"}
                      type={'text'}
                      value={classValue}
                      onChange={changeInputValue}
                      onClick={clickInputBox}
                      readOnly
                    />
                    <img className='cursor' onClick={clickInputBox} src={"/assets/image/icon_dropdown_down_arrow_black.png"} alt='' />
                  </div>
                </div>

                {clickInput && !isHaveInputValue ? (
                  <DropDownBox id='dropBox'>
                    {dropDownList.length === 0 ?
                      <DropDownItem>클래스가 없습니다.</DropDownItem>
                      : null
                    }

                    {dropDownList.map((dropDownItem, dropDownIndex) => {
                      const classInfo = `${dropDownItem.class_name} ${dropDownItem.grade} ${dropDownItem.classroom}반`
                      return (
                        <DropDownItem
                          key={dropDownIndex}
                          onClick={() => clickValue(dropDownItem.id, classInfo)}
                          onMouseOver={() => setDropDownItemIndex(dropDownIndex)}
                          className={
                            dropDownItemIndex === dropDownIndex ? 'selected cursor' : 'cursor'
                          }
                        >
                          <div className="studentAddModal-dropdown-container">
                            <div className="studentAddModal-dropdown-name-mail">
                              {classInfo}
                            </div>
                          </div>
                        </DropDownItem>
                      )
                    })}
                  </DropDownBox>
                ) : null}

                <div className="classAddModal-subTitle">
                  커리큘럼 이름
                </div>
                <input className="class-search"
                  placeholder={"보여질 커리큘럼의 이름을 작성해주세요"}
                  type={'text'}
                  value={titleValue}
                  onChange={changeTitleValue}>
                </input>
                <div className="flex-container">
                  <div className="left">
                    <div className="classAddModal-subTitle">
                      제작자
                    </div>
                    <input className="class-search"
                      placeholder={"제작자의 이름을 적어주세요."}
                      type={'text'}
                      value={creatorValue}
                      onChange={changeCreatorValue}>
                    </input>
                  </div>
                  <div className="right">
                    <div className="classAddModal-subTitle">
                      커리큘럼 오픈 날짜
                    </div>
                    {openCalendar && (<CalendarModal
                      open={openCalendar}
                      onClose={() => {
                        setOpenCalendar(false);
                      }}
                      setDateValue={setDateValue}
                    />)}
                    <div className="class-search align-item-center box-shadow">
                      <input
                        className="input-transparent cursor"
                        placeholder={"2023. 01. 01"}
                        type={'text'}
                        value={dateValue}
                        onChange={changeDateValue}
                        onClick={onClickDate} />
                      <img className="cursor" onClick={onClickDate} src={"/assets/image/icon_dropdown_down_arrow_black.png"} alt='' />
                    </div>
                  </div>
                </div>

                <div className="classAddModal-subTitle">썸네일 추가하기</div>

                <input type='file' accept='image/*' className='hide'
                  onChange={(e) => onImageChange(e)}
                  ref={imageRef} />

                <div className={`classAddModal-imgAdd`} style={isChecked ? {border:'none'} : null} onClick={handleImageClick}>
                  {
                    isChecked ? 
                    <img className="classAddModal-basic-thumbnail" src="/assets/image/img_curriculum_default_thumbnail.png" alt="" /> 
                    :
                      imgFile ?
                        <img className={'classAddModal-thumbnail'} src={imgFile} alt="" />
                        :
                        <img className="classAddModal-no-thumbnail" src="/assets/image/class_addImg1.png" alt="" />
                  }
                </div>
                <div className="checkbox-container">
                  <input type="checkbox" id="agree" checked={isChecked} onChange={handleCheckboxChange} />
                  <label htmlFor="agree" className="checkbox-label">기본 이미지로 할게요</label>
                </div>
                <div>
            <Button style={{ backgroundColor: EnableConfirm() ? '#0F84F4' : '#DFE3E8' }} disabled={!EnableConfirm()} onClick={onCreateRoadMap}>
              <img style={{ width: '10px' }} src='/assets/image/icon_plus_white.png' alt='' />
              <div>만들기</div>
            </Button>
          </div>
              </>
              :
              
              <div style={{display:'flex', flexDirection:'column', gap: '20px'}}>
                <div className="classAddModal-head" style={{ justifyContent: 'unset', gap: '10px', height: '30px' }}>
                  <img src='/assets/image/icon_arrow_back_black.png' alt='' onClick={() => setSelectBasicImg(false)} />
                  <div className='title'>썸네일 이미지 선택하기</div>
                </div>
                <div className='thumbnail-container'>
                  {strings.curriculum_part_category.map((item, index) => (
                    <div className='thumbnail-box'>
                      <img src={`/assets/image/img_curriculum_thumbnail_${item.color}.png`} alt=''/>
                      <div>{item.subject}</div>
                    </div>
                  ))}
                </div>
              </div>
              
              
            }
          </div>


        </ModalWrap>
      </Overlay>
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const ModalWrap = styled.div`
  width: 700px;
  height: 650px;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  overflow: auto;
`;
const Button = styled.button`
cursor: pointer;
width: 100%;
color: #ffffff;
border-radius: 5px;
border:0px;
padding: 15px;
margin-top: 60px;
display:flex;
align-items: center;
justify-content:center;
font-size:16px;
gap:5px;
`;

const DropDownBox = styled.ul`
  display: block;
  position: absolute;
  margin: 0 auto;
  padding: 0px 5px;
  width: 89%;
  background-color: white;
  border: 1px solid #DFE1E8;
  border-radius: 8px;
  box-shadow: 0 10px 10px rgb(0, 0, 0, 0.3);
  list-style-type: none;
  z-index: 3;
  max-height: 200px; /* 최대 높이 설정 */
  overflow: auto; /* 스크롤바 표시 설정 */
`

const DropDownItem = styled.li`
  text-align: start;
  padding: 0px 5px;
  margin: 10px 0px;
`

export default CreateCurriculumStep0Modal;
