import React from "react";

const HomeSec5 = () => {
    return (
        <>
            <section className="wrap main-sec5">
                <div className="inner">
                    <div className="main-tit">
                        <h4>
                            <img src="/assets/image/landing_page/main/sec5-tit.svg" alt="차트 아이콘" />
                            AI 코스웨어
                        </h4>
                        <h2>
                            잘못된 학습의 방향은 <br />
                            츄잉 AI 코스웨어로
                        </h2>
                        <p>
                            AI 튜터 기반의 코스웨어 절차를 통해 <br />
                            학생의 취약점을 확인하고 성장시켜보세요
                        </p>
                    </div>
                    <div className="sec5-con">
                        <div className="left">
                            <p>
                            잘못된 방향을 바로 잡는 <br />
                            츄잉만의 내비게이션 시스템
                            </p>
                            <div className="img-box">
                                <img src="/assets/image/landing_page/main/sec5-left.png" alt="네비게이션 시스템" />
                            </div>
                        </div>
                        <div className="right">
                            <p>
                                체계적인 츄잉의 5단계 코스로 <br />
                                <span>학생별로 꼼꼼히 분석해주니까.</span>
                            </p>
                            <img src="/assets/image/landing_page/main/sec5-right.png" alt="5단계 코스 시스템" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeSec5;