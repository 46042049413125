import React, { useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { inject, observer } from "mobx-react";

import strings from "../../lang/strings";
import Menu, { Extra } from '../../config/page_route.jsx';

const Content = ({ rootStore }) => {
  const location = useLocation();

  const routes = [...Extra, ...Menu];

  const setTitle = path => {
    let pageTitle = strings.admin;

    const route = routes.find(it => it.path === path || it.children?.some(c => c.path === path));
    if (route) {
      if (route.path === path) {
        pageTitle = route.title;
      } else {
        pageTitle = route.children?.find(it => it.path === path)?.title;
      }
    }

    document.title = pageTitle;
  }

  useEffect(() => {
    setTitle(location.pathname);
  }, [location.pathname]);

  return (
    <Routes>
      {routes.filter(route => !route.user_type || route.user_type === rootStore.getProfile?.userType).map((route, index) => {
        if (route.hasOwnProperty('children')) {
          return route.children.filter(subRoute => !subRoute.user_type || subRoute.user_type === rootStore.getProfile?.userType).map((subRoute, subIndex) => (
            <Route
              key={`${index}-${subIndex}`}
              path={subRoute.path}
              element={
                !subRoute.private || rootStore.isSignedIn ? <subRoute.component /> : <Navigate to='/login' />
              }
            />
          ));
        } else {
          return (
            <Route
              key={index}
              path={route.path}
              element={
                !route.private || rootStore.isSignedIn ? <route.component /> : <Navigate to='/login' />
              }
            />
          );
        }
      })}
      <Route path="*" element={<Navigate to='/landing/main' />} />
    </Routes>
  );
};

export default inject('rootStore')(observer(Content));
