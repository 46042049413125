import React, { useContext, useEffect, useState } from 'react';
import styled from "styled-components";
import { Page_settings } from '../../../config/page_settings';
import { rootStore } from "../../../mobx/store";

function ConfirmModal(props) {

  const context = useContext(Page_settings);

  const { choiceBill, count, onClose} = props;

  const [paymentToken, setPaymentToken] = useState(localStorage.getItem('payment_token') ? JSON.parse(localStorage.getItem('payment_token')).token : null);
  const [tokenTime, setTokenTime] = useState(localStorage.getItem('payment_token') ? JSON.parse(localStorage.getItem('payment_token')).time : null)

  useEffect(() => {
    return () => {
      context.handleSetPageHeader(true);
      context.handleSetPageSidebar(true);
    };
  }, []);

  const tokenVerification = (tokenTime) => {
    // 현재 시각을 밀리초 단위로 얻음
    const currentTime = Date.now();

    // 주어진 시간 값과 현재 시각의 차이를 계산
    const difference = currentTime - tokenTime;

    // 차이가 지정된 시간 이상이면 true, 아니면 false 반환
    return difference >= 3600000;
  }

  const handleClose = () => {
    onClose?.();
  };

  const handleResend = () => {
    props.onResend?.();
  };

  const submitBill = () => {
    if (tokenVerification(tokenTime)) {
      context.post(
        'payment/getToken',
        {},
        response => {
          rootStore.setPaymentToken(response);
          setPaymentToken(response);
          setTokenTime(Date.now());
          context.post(
            'payment/resend',
            {
              token: response,
              data: choiceBill,
            },
            response => {
              handleResend()
              handleClose()
              alert('재발송 성공')
              
            }
          );
        }
      )
    } else {
      context.post(
        'payment/resend',
        {
          token: paymentToken,
          data: choiceBill,
        },
        response => {
          handleResend()
          handleClose()
          alert('재발송 성공')
        }
      );
    }
  }



    return (
      <Overlay>
        <ModalWrap>
          <div className='bill-confirm-container'>
            <div className='title'>{count}건의 청구서를 재발송하시겠습니까?</div>
            <div className='content'>즉시 발송되며 발송내역에서 확인할 수 있습니다.</div>
            <div className='btn-box'>
              <button onClick={() => handleClose()} className='cancel-btn'>취소</button>
              <button onClick={() => submitBill()} className='send-btn'>발송</button>
            </div>
          </div>
        </ModalWrap>
      </Overlay>
    );
  }


  const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

  const ModalWrap = styled.div`
  width: 326px;
  height: 160px;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
`;


  export default ConfirmModal;