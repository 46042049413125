
const BUCKET_URL  = process.env.REACT_APP_BUCKET_URL;

export const getFilePath = (imageName?: string, fallback?: string) => {
    if (!imageName || imageName == "") {
        return fallback || '/assets/image/default_profile.png';
    }

    return `${BUCKET_URL}/${imageName}`;
}

export const getFileImagePath = (name: string) => {
    let ext = name.split(".")[name.split(".").length - 1];
    let path = "/assets/image/icon_file_";
    if (ext == "docx") {
        path += "doc";
    } else {
        path += ext;
    }
    path += ".png";
    return path
}