import React, { useContext, useState, useCallback } from 'react';
import { Modal, ModalBody } from "reactstrap";
import strings from "../../../lang/strings";
import './modal.scss'
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from "moment";
import { Page_settings } from "../../../config/page_settings";
import CloudViewModal from "./cloud_view_modal";

const StudyMaterialModal = (props) => {
    const [currentCat, setCurrentCat] = useState(0);
    const context = useContext(Page_settings);
    const [materialList, setMaterialList] = useState([]);
    const [materialKeyword, setMaterialKeyword] = useState("");
    const [order, setOrder] = useState("latest");
    const [item, setItem] = useState("");
    const [showCloudItem, setShowCloudItem] = useState(false);
    const [pageNum, setPageNum] = useState(1);
    const [loadMore, setLoadMore] = useState(false);
    const [scroll, setScroll] = useState(0);

    const onCloudItemClick = useCallback(() => setShowCloudItem(true), []);
    const onCloudItemToggleClick = useCallback(() => setShowCloudItem(!showCloudItem), [showCloudItem]);

    const handleEnter = e => {
        if (e.keyCode == 13) {
            onSearchMaterial()
        }
    };

    const getMaterialList = (order, pageNum) => {
        context.get(
            "room/getMaterialList",
            {
                keyword: materialKeyword,
                page_num: pageNum,
                order: order
            },
            response => {
                if (pageNum == 1) {
                    setMaterialList(response.list);
                    setPageNum(1)
                } else {
                    setMaterialList([...materialList, ...response.list]);
                    setPageNum(pageNum)
                }
                setLoadMore(response.list.length == response.limit);
            }
        );
    }

    const onEnd = () => {
        if (scroll <= 3) {
            setScroll(scroll + 1)
        }
        if (loadMore && scroll == 2) {
            getMaterialList(order, pageNum + 1)
        }
    }

    const onSearchMaterial = () => {
        getMaterialList(order, 1);
    }

    const onOrder = () => {
        if (order == "latest") {
            setOrder("oldest");
            getMaterialList("oldest", 1);
        } else if (order == "oldest") {
            setOrder("abc");
            getMaterialList("abc", 1);
        } else {
            setOrder("latest");
            getMaterialList("latest", 1);
        }
    }

    const getType = (item) => {
        let ext = item.split(".")[item.split(".").length - 1];
        let type = "/assets/image/icon_file_";
        if (ext == "docx") {
            type += "doc";
        } else if (ext == "mp4") {
            type += "drive";
        } else {
            type += ext
        }
        type += ".png";
        return type
    };

    const addDefaultSrc = (ev) => {
        ev.target.src = "/assets/image/icon_file_drive.png"
    }

    const {
        show,
        close,
        toggle,
    } = props;

    return (
        <Modal isOpen={show} toggle={() => toggle()} className='study-material'
            onOpened={() => getMaterialList("latest", 1)}>
            <ModalBody className="p-0">
                <div className='d-flex header align-item-center m-b-30'>
                    <div className='f-s-20'>{strings.study_material}</div>
                    <div className='flex-grow-1' />
                    <button className='modal-close-btn' onClick={() => close()}>
                        <img src={"/assets/image/icon_close_white.png"} alt="" className="modal-close" />
                    </button>
                </div>
                <div className="d-flex m-b-20">
                    {
                        strings.study_material_button_list.map((item, idx) => {
                            return (
                                <button
                                    key={idx}
                                    className={("material-cat ") + (item.code == currentCat ? "material-cat-selected" : "")}
                                    onClick={() => {
                                        setCurrentCat(item.code)
                                    }}>{item.name}</button>
                            )
                        })
                    }
                </div>
                <div className="material-container">
                    <div className="d-flex align-item-center m-b-40">
                        <div className="search-box material-search">
                            <input type={"text"} className="input-transparent"
                                value={materialKeyword} placeholder={strings.please_enter}
                                onChange={(v) => setMaterialKeyword(v.target.value)} onKeyDown={handleEnter}
                            />
                            <button className="common-button" onClick={() => onSearchMaterial()}>
                                <img src={"/assets/image/icon_search_white.png"} alt="" />
                            </button>

                        </div>
                        <button className="common-button m-l-auto" onClick={() => onOrder()}>
                            <img src={"/assets/image/icon_view_list.png"} alt="" />
                        </button>
                    </div>
                    <PerfectScrollbar className="row scroll" options={{ suppressScrollX: true }}
                        onYReachEnd={() => onEnd()}
                        onScrollUp={() => setScroll(0)} onYReachStart={() => setScroll(0)}>
                        {
                            materialList.map((item, idx) => {
                                return (
                                    <button
                                        key={idx}
                                        className="material-item"
                                        onClick={() => {
                                            setItem(item);
                                            onCloudItemClick();
                                        }}
                                    >
                                        <img src={getType(item.name)} alt="" onError={addDefaultSrc} className="item-image m-b-10" />
                                        <div className="material-name">{item.name}</div>
                                        <div
                                            className="material-date">{moment(item.create_date).format("YY.MM.DD")}</div>
                                    </button>
                                )
                            })
                        }
                    </PerfectScrollbar>
                </div>
            </ModalBody>
            <CloudViewModal
                show={showCloudItem}
                toggle={onCloudItemToggleClick}
                item={item}
                hideDownload={true}
            />
        </Modal>
    );
}

export default StudyMaterialModal;
