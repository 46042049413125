import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import ClassAddModal from './Modals/classAddModal';
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import CustomSearch from '../../components/control/custom_input/custom_search';
import strings from '../../lang/strings';
import CloudContainer from '../cabinet/cloud_container';
import { Page_settings } from '../../config/page_settings';
import { rootStore } from '../../mobx/store';
import ClassInfo from './classInfo';

const Class = observer((props) => {
  const context = useContext(Page_settings);
  const [isOpen, setIsOpen] = useState(false);
  const [classList, setClassList] = useState([])
  const user_id = rootStore.getProfile?.id ?? ""
  const user_type = rootStore.getProfile?.user_type ?? ""
  

  useEffect(()=>{
    getClassList();
    checkMissionProblem();
  },[isOpen])
  

  const getClassList = () => {
    context.get(
      'class/getClassList',
      {},
      response => {
        console.log("getClassList",response)
        setClassList(response)
      });  
  }

  const checkMissionProblem = () => {
    context.get(
      'class/checkMissionProblem',
      {
      },
      response => {
      });  
  }
  


  const onClickAddClass = () => {
    setIsOpen(true);
  };

  return (
    
    
      <div className="main-content">
      {/* <CloudContainer/> */}
      {isOpen && (<ClassAddModal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />)}
        

      { classList !== null && classList.length > 0 ? 
        <ClassInfo classList={classList}/>
      :
      (
      <>
      <div class="class-head">
        <div class="class-myClass">
          나의 클래스
        </div>
      {user_type === "STUDENT" ? null 
      :
      <Button onClick={onClickAddClass}>+ 클래스 추가</Button>
      }
      </div>
      <div class="class-nothing-student">
        <div className='nothing-container'>
          <img class="class-nothing-img" src="/assets/image/img_gray_chuchu.png" alt="" />
          <div>아직 클래스가 없습니다.<br />지금 바로 클래스를 등록해보세요!</div>
        </div>
      </div>
      </>  
      )}
      </div>
    
    
  );
})

const Button = styled.button`
  font-size: 14px;
  padding: 10px 20px;
  border: none;
  background-color: #0F84F4;
  border-radius: 10px;
  color: white;
  font-weight: 500;
  cursor: pointer;
  // &:hover {
  //   background-color: #fac2be;
  // }
`;

const AppWrap = styled.div`
  text-align: center;
  margin: 10px auto;
  position: relative;
  width: 1440px;
  
`;

export default withRouter(Class);
