import React from "react";
import { Link } from "react-router-dom";

const HomeSec1 = () => {
    return (
        <>
            <section className="wrap main-sec1">
                <div className="inner center">
                    <h1>
                        <span className="col1">선생님</span>과 <span className="col2">학생</span>이
                        함께 <br />
                        성장에만 집중할 수 있도록
                    </h1>
                    <Link to="#">츄잉 도입 문의하기</Link>
                    {/* <a href="#">츄잉 도입 문의하기</a>/ */}
                </div>
                <img
                    className="sec1-point1"
                    src="/assets/image/landing_page/main/sec1-point1.svg"
                    // src="/assets/image/icon_curriculum_guide.png"
                    alt="배경 이미지"
                />
                <img
                    className="sec1-point2"
                    src="/assets/image/landing_page/main/sec1-point2.svg"
                    alt="배경 이미지"
                />
                <img
                    className="sec1-point3"
                    src="/assets/image/landing_page/main/sec1-point3.svg"
                    alt="배경 이미지"
                />
                <img
                    className="sec1-point4"
                    src="/assets/image/landing_page/main/sec1-point4.svg"
                    alt="배경 이미지"
                />
            </section>
        </>
    );
}

export default HomeSec1;