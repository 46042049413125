import React, { useContext, useEffect, useState } from "react";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withRouter } from "react-router-dom";
import { Page_settings } from "../../config/page_settings";
import StudentWarningModal from "./modal/student_warning_modal";
import ExamFileViewer from "../class/exam/exam_file_viewer"
const ProblemSolving = (props) => {
  const context = useContext(Page_settings);
  const [hideStatus,setHideStatus] = useState(true); // 해설지 가리기 
  const [warningModal,setWarningModal] = useState(false); // 경고모달창 
  const [unitItem, setUnitItem] = useState();
  const [test,setTest] = useState();
  const [commentary,setCommentary] = useState();
  const unit_id = parseInt(props.match.params.id);

  useEffect(() => {
    loadUnitData();
    addStudySubsection();
  }, []);

  const loadUnitData = () => {
    context.get(
      "curriculum/getUnitData",
      {
        unit:unit_id,
      },
      response => {
        const responseObject = JSON.parse(response);
        console.log("response",responseObject)
        setUnitItem(responseObject)
        setTest(responseObject.test_url)
        setCommentary(responseObject.commentary_url)
      }
  );
  }

  const addStudySubsection = () => {
    
    context.post(
      "curriculum/addStudySubsection",
      {
        unit:unit_id,
        type: "SOLVE"
      },
      response => {
        
      }
  );
  }

  const openWarningModal = () => {
    setWarningModal(true)
  }





  return (
    <div class="main-content exam-solving-problem"> 

{warningModal && (<StudentWarningModal
        open={warningModal}
        onClose={() => {
          setWarningModal(false);
        }}
        mode={"solving"}
      />)}
      <div className="left-container">
        <div className="top-container">
          <div className="header-container">
            <div className="left">
              <img className="cursor" src="/assets/image/icon_arrow_back_black.png" alt="" onClick={()=>openWarningModal()}/>
              <div className="title"><span className="highlight">순열 알아보기 </span> 문제풀이</div>
            </div>
            {/* <div className="right">
              <div>{unitItem?.count}문항</div>
            </div> */}
          </div>
        </div>
        <div className="bottom-container">
          {/* <PerfectScrollbar className='flex-1' style={{ height : 'calc(100% - 50px)'}}>
          <img src={context.loadImage(commentary) } alt="" style={{width: '100%'}}/>
          </PerfectScrollbar> */}
          <div className='info-wrap' style={{ flex: 1, overflowY: 'auto', position: 'relative', height: '100%' }}>
                                <ExamFileViewer url={context.loadImage(commentary)} />
                            </div>
        </div>


      </div>
      <div className="right-container">
        <div className="top-container">
          <div className="top-header">
          <div className="title">해설지 같이보기</div>
          <button className="hide-commentary" onClick={()=>setHideStatus(!hideStatus)}>해설지 가리기</button>
          
          </div>
        </div>
        
        <div className="bottom-container">

        {hideStatus ? 
        <PerfectScrollbar className='flex-1' style={{ height : '100%'}}>
        {/* <img src={context.loadImage(commentary) } alt="" style={{width: '100%'}}/>    */}
        <div className='info-wrap' style={{ flex: 1, overflowY: 'auto', position: 'relative', height: '100%' }}>
                                <ExamFileViewer url={context.loadImage(commentary)} />
                            </div>
        </PerfectScrollbar> 
        
        :
        <div className="hide-commentary-container">

        </div>
        }
        
         
        </div>
 
                                               
      </div>
      


    </div >
  );






}
export default withRouter(ProblemSolving);
