import React, {useState} from 'react';
import {Modal, ModalBody} from "reactstrap";
import './modal.scss'
import strings from "../../../lang/strings";

const PasswordModal = (props) => {
    const [password, setPassword] = useState("");
    const [alert, setAlert] = useState(false);
    const {show, close, toggle, onConfirm, room} = props;
    const onConfirmPassword = () => {
        if (password == room.password) {
            onConfirm()
        } else {
            setAlert(true);
        }
    }
    const handleEnter = e => {
        if (e.keyCode == 13) {
            onConfirmPassword()
        }
    };
    return (
        <Modal isOpen={show} toggle={() => toggle()} className='tag-modal'>
            <ModalBody>
                <div className='d-flex'>
                    <div className='f-s-20 f-w-600'>{strings.password}</div>
                    <div className='flex-grow-1'/>
                    <button className='modal-close-btn' onClick={() => close()}>
                        <img src={"/assets/image/icon_close_black.png"} alt="" className="modal-close"/>
                    </button>
                </div>
                <div className='tag-input d-flex'>
                    <input
                        className='form-control input-transparent d-flex align-items-lg-center'
                        type={"password"}
                        onChange={(e) => {
                            setAlert(false);
                            setPassword(e.target.value);
                        }}
                        onKeyDown={handleEnter}
                        value={password}/>
                </div>
                <div className="wrong-pwd">{alert ? strings.wrong_password : ""}</div>
                <button className='tag-create-btn-disable tag-create-btn' onClick={() => onConfirmPassword()}>
                    {strings.confirm}
                </button>
            </ModalBody>
        </Modal>
    );
}

export default PasswordModal;
