import { observer } from "mobx-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Button from "../../../components/button";
import Checkbox from "../../../components/checkbox";
import PlusIcon from "../../../components/icons/plus";
import IconX from "../../../components/icons/x";
import SearchBar from "../../../components/search_bar";
import SelectBox from "../../../components/select_box";
import TextField from "../../../components/text_field";
import { Page_settings } from "../../../config/page_settings";
import colors from "../../../design/colors";
import fonts from "../../../design/typography";
import { rootStore } from "../../../mobx/store";
import { IClassMemberList } from "../../../type/classroom";
import { Subject } from "../../../type/subject";
import CalendarModal from "../../../components/calendar_modal";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { getFileImagePath, getFilePath } from "../../../utils/image";

const FILE_TYPES = {
    IMAGES: ["bmp", "jpg", "jpeg", "png", "tiff"],
    ALLOWED: [
      "image/bmp", "image/jpg", "image/jpeg", "image/png", "image/tiff",
      "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/htm", "text/html", "application/pdf", "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "text/plain", "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "video/mp4"
    ]
  } as const;


interface IMission {
    id: number;
    classroom: number;
    title: string;
    content: string;
    startTime: string;
    endTime: string;
    subject: string;
    fileList: {
        name: string;
        size: number;
        type: string;
        url: string;
    }[];
    missionMembers: {
        member: number;
        name: string;
        profile: string;
        school: string;
    }[];
}

const MissionEdit = observer(() => {
    const context: any = useContext(Page_settings);
    const classId = rootStore.getClassId;
    const navigate = useNavigate();
    const missionId = useParams().mission_id;
    const [missionInfo, setMissionInfo] = useState<IMission | null>(null);

    const [searchKeyword, setSearchKeyword] = useState("");
    const [studentList, setStudentList] = useState<IClassMemberList[]>([]);
    const [fileList, setFileList] = useState<File[]>([]);
    const imageRef = useRef<HTMLInputElement>(null);

    const [isStartDateCalendarOpen, setIsStartDateCalendarOpen] = useState(false);
    const [isEndDateCalendarOpen, setIsEndDateCalendarOpen] = useState(false);


    useEffect(() => {
        if (classId) {
            context.get(
                `/class/${classId}/member`,
                {},
                (res: any) => {
                    setStudentList(res);
                }
            )
        }

        if (missionId) {
            context.get(
                `/classroom/${classId}/mission/${missionId}`,
                {},
                (res: IMission) => {
                    setMissionInfo(res);
                }
            )
        }
    }, [classId, missionId]);

    const onImageChange = (e) => {
        const files = e.target.files;
        if (!files?.length) return;
    
        if (!FILE_TYPES.ALLOWED.includes(files[0].type as any)) return;
        setFileList(prev => [...prev, ...files]);
    };
    

    const onSend = () => {
        if (fileList.length > 0) {
            let formData = new FormData();
            fileList.forEach((entry, index) => {
              formData.append('files', entry);
            });

            context.post(
              'upload/files/mission',
              formData,
              (response: any) => {

                const {id, missionMembers, classroom, ...rest} = missionInfo;
                context.put(
                  `classroom/${classId}/mission/${missionId}`,
                  {
                    ...rest,
                    fileList: [
                        ...missionInfo.fileList,
                        ...response
                    ],
                    classMembers: missionInfo.missionMembers.map((member) => member.member)
                  },
                  () => {
                    setFileList([]);
                    navigate(`/study/mission`);
                  }
                );
              }
            );
        } else {

            const {id, missionMembers, classroom, ...rest} = missionInfo;

            context.put(
                `classroom/${classId}/mission/${missionId}`,
                {
                  ...rest,
                  classMembers: missionInfo.missionMembers.map((member) => member.member)
                },
                () => {
                  setFileList([]);
                  navigate(`/study/mission`);
                }
            );
        }
    }

    return (
        <Container>
            <Header>과제 수정하기</Header>
            <ContentWrapper>
                <Section>
                    <TitleWrapper>
                        <span style={{color: colors.gray900}}>학생 추가</span>
                        <RequiredCircle/>
                    </TitleWrapper>
                    <SearchBar placeholder="학생 이름을 검색해주세요." size="medium" width="100%" value={searchKeyword} onChange={(e) => setSearchKeyword(e.target.value)}/>
                    <SelectedStudentWrapper isVisible={missionInfo?.missionMembers.length > 0}>
                        {missionInfo?.missionMembers.map((student) => (
                            <SelectedStudentItem>
                                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start"}}>
                                    <img src={getFilePath(student.profile)} alt="profile" style={{width: 28, height: 28, borderRadius: 14}}/>
                                    <span style={{...fonts.label4SemiBold, color: colors.gray900, marginLeft: 12}}>{student.name}</span>
                                </div>
                                <Checkbox size="20px" checked={missionInfo?.missionMembers.map((s) => s.member).includes(student.member)} 
                                setChecked={() => {}}/>
                            </SelectedStudentItem>
                        ))}
                    </SelectedStudentWrapper>
                    <CheckBoxWrapper>
                        <Checkbox checked={missionInfo?.missionMembers.length === studentList.length} setChecked={() => {
                            if (missionInfo?.missionMembers.length === studentList.length) {
                                setMissionInfo({...missionInfo, missionMembers: []});
                            } else {
                                setMissionInfo({...missionInfo, missionMembers: studentList.map((student) => ({member: student.memberId, name: student.name, profile: student.profile, school: student.school}))});
                            }
                        }}/>
                        <span style={{...fonts.label4SemiBold, color: colors.gray900, marginLeft: 12}}>모두 선택</span>
                    </CheckBoxWrapper>
                    <StudentListWrapper isVisible={missionInfo?.missionMembers.length > 0}>
                        {studentList.filter((student) => {
                            if (searchKeyword.length > 0) {
                                return student.name.includes(searchKeyword);
                            }
                            return true;
                        }).map((student) => (
                            <StudentItemWrapper>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", width: "calc(100% - 20px)", height: "100%"}}>
                                    <img src={getFilePath(student.profile)} alt="profile" style={{width: 28, height: 28, borderRadius: 14}}/>
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center"}}>
                                        <span style={{...fonts.label4SemiBold, color: colors.gray900, marginLeft: 7}}>{student.name}</span>
                                        <span style={{...fonts.label5Regular, color: colors.gray500, marginLeft: 7}}>{student.school}</span>
                                    </div>
                                </div>
                                <Checkbox size="20px" checked={missionInfo?.missionMembers.map((s) => s.member).includes(student.memberId)} 
                                setChecked={() => {
                                    setMissionInfo({...missionInfo, missionMembers: missionInfo?.missionMembers.map((s) => s.member).includes(student.memberId) ? missionInfo?.missionMembers.filter((s) => s.member !== student.memberId) : [...missionInfo?.missionMembers, {member: student.memberId, name: student.name, profile: student.profile, school: student.school}]})
                                }}/>
                            </StudentItemWrapper>
                        ))}
                    </StudentListWrapper>
                </Section>
                <div style={{width: "1px", height: "100%", backgroundColor: colors.gray100, margin: "0 56px"}}/>
                <Section>
                    <div style={{width: "100%", height: "100%", gap: 16, display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start", marginBottom: 24}}>
                        <div style={{width: "calc(66% - 16px)", height: "100%"}}>
                            <TitleWrapper>
                                <span>제목</span>
                                <RequiredCircle/>
                            </TitleWrapper>
                            <TextField value={missionInfo?.title} onChange={(e) => setMissionInfo({...missionInfo, title: e.target.value})} placeholder="제목을 입력해주세요" size="small" complete={missionInfo?.title.length > 0}/>
                        </div>
                        <div style={{width: "calc(34% - 16px)", height: "100%"}}>
                            <TitleWrapper>
                                <span>과목</span>
                                <RequiredCircle/>
                            </TitleWrapper>
                            <SelectBox 
                            selectedOptionLabel={missionInfo?.subject ? Subject[missionInfo?.subject].showingName : "과목을 선택해주세요"}
                            options={Object.values(Subject).map((subject) => ({label: subject.showingName, onSelect: () => setMissionInfo({...missionInfo, subject: subject.name})}))}/>
                        </div>
                    </div>
                    <TitleWrapper>
                        <span>내용</span>
                        <RequiredCircle/>
                    </TitleWrapper>
                    <TextField type="textarea" value={missionInfo?.content} onChange={(e) => setMissionInfo({...missionInfo, content: e.target.value})} placeholder="과제 내용을 입력해주세요" size="small" complete={missionInfo?.content.length > 0} height="104px"/>
                    <input type='file' accept='image/bmp,image/jpg,image/jpeg,image/png,image/tiff,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/htm,text/html,application/pdf,application/vnd.ms-powerpoint,applicatiapplication/vnd.openxmlformats-officedocument.presentationml.presentation,text/plain,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,video/mp4' className='hide'
                    onChange={(e) => onImageChange(e)}
                    ref={imageRef} />
                    <FileWrapper isVisible={true}>
                        {[...missionInfo?.fileList ?? [], ...fileList].map((file, index) => (
                            <FileItem>
                                <img src={getFileImagePath(file.name)} alt='' style={{ width: "24px", height: "24px" }}/>
                                <div style={{ ...fonts.label4Medium, color: colors.gray600, width: 228}}>
                                    {file.name.length > 35 ? file.name.slice(0, 35) + "..." : file.name}
                                </div>
                                <div style={{ ...fonts.label4Medium, color: colors.gray400, width: "calc(100% - 228px - 24px - 24px)", textAlign: "center" }}>
                                    {formatFileSize(file.size)}
                                </div>
                                <div style={{ cursor: "pointer" }} onClick={() => {
                                    if (index < missionInfo?.fileList.length) {
                                        setMissionInfo({...missionInfo, fileList: missionInfo?.fileList.filter((item, idx) => idx !== index)});
                                    } else {
                                        setFileList(prev => prev.filter((item, idx) => idx !== index - missionInfo?.fileList.length));
                                    }
                                }}>
                                    <IconX width="24px" height="24px" color={colors.gray500} />
                                </div>
                            </FileItem>
                        ))}
                    </FileWrapper>
                    <div style={{ width: "56px", height: "56px", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", border: `1px solid ${colors.gray100}`, borderRadius: "4px", marginBottom: "24px" }} onClick={() => imageRef?.current.click()}>
                        <PlusIcon width="24px" height="24px" color={colors.gray200} />
                    </div>
                    <TitleWrapper>
                        <span>시간</span>
                        <RequiredCircle/>
                        {(moment(`${moment(missionInfo?.startTime).format("YYYY-MM-DD")} ${missionInfo?.startTime.split(" ")[1].split(":")[0]}:${missionInfo?.startTime.split(" ")[1].split(":")[1]}:00`).isAfter(moment(`${moment(missionInfo?.endTime).format("YYYY-MM-DD")} ${missionInfo?.endTime.split(" ")[1].split(":")[0]}:${missionInfo?.endTime.split(" ")[1].split(":")[1]}:00`)))
                        && <span style={{color: colors.warning, ...fonts.label4Medium, marginLeft: 4}}>종료 시간이 시작 시간보다 이전입니다.</span>}
                    </TitleWrapper>
                    <div style={{width: "100%", height: "100%", gap: 8, display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", marginBottom: 24, position: "relative"}}>
                        <div style={{width: "100%", height: "100%", gap: 16, display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start", position: "relative"}}>
                            <div style={{width: "calc(58% - 12px)", height: "100%"}}>
                                {missionInfo?.startTime ? 
                                <>
                                    <TextField value={moment(missionInfo?.startTime).format("YYYY-MM-DD")} size="small" complete={true} disabled={true} />
                                    <Overlay onClick={() => setIsStartDateCalendarOpen(prev => !prev)} />
                                </> : 
                                <SelectBox options={[]} defaultOptionLabel="시작 날짜 선택" onSelect={() => setIsStartDateCalendarOpen(true)}/>}
                            </div>
                            <div style={{width: "calc(42% - 12px)", height: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start"}}>
                                <TextField type="number" value={missionInfo?.startTime.split(" ")[1].split(":")[0]} onChange={(e) => setMissionInfo({...missionInfo, startTime: `${missionInfo?.startTime.split(" ")[0]} ${isNaN(parseInt(e.target.value)) ? "0" : (parseInt(e.target.value) as number) > 23 ? "23" : e.target.value.toString()}:${missionInfo?.startTime.split(" ")[1].split(":")[1]}`})} placeholder="시간을 입력해주세요" size="small" textAlign="center" width="calc(50% - 10px)"/>
                                <span style={{color: colors.gray900, ...fonts.body3Medium, width: 20, textAlign: "center"}}>:</span>
                                <TextField type="number" value={missionInfo?.startTime.split(" ")[1].split(":")[1]} onChange={(e) => setMissionInfo({...missionInfo, startTime: `${missionInfo?.startTime.split(" ")[0]} ${isNaN(parseInt(e.target.value)) ? "0" : (parseInt(e.target.value) as number) > 59 ? "59" : e.target.value.toString()}:${missionInfo?.startTime.split(" ")[1].split(":")[1]}`})} placeholder="분을 입력해주세요" size="small" textAlign="center" width="calc(50% - 10px)"/>
                            </div>
                        </div>
                        <div style={{width: "100%", height: "100%", gap: 16, display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start", position: "relative"}}>
                            <div style={{width: "calc(58% - 12px)", height: "100%"}}>
                                {missionInfo?.endTime ? 
                                <>
                                    <TextField value={moment(missionInfo?.endTime).format("YYYY-MM-DD")} size="small" complete={true} disabled={true}/> 
                                    <Overlay onClick={() =>setIsEndDateCalendarOpen(prev => !prev)}/>
                                </>: 
                                <SelectBox options={[]} defaultOptionLabel="종료 날짜 선택" onSelect={() => setIsEndDateCalendarOpen(true)}/>}
                            </div>
                            <div style={{width: "calc(42% - 12px)", height: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start"}}>
                                <TextField type="number" value={missionInfo?.endTime.split(" ")[1].split(":")[0]} onChange={(e) => {
                                    if (parseInt(e.target.value) > 23) {
                                        return setMissionInfo({...missionInfo, endTime: `${missionInfo?.endTime.split(" ")[0]} 23:${missionInfo?.endTime.split(" ")[1].split(":")[1]}`});
                                    }
                                    if (parseInt(e.target.value) < 0) {
                                        return setMissionInfo({...missionInfo, endTime: `${missionInfo?.endTime.split(" ")[0]} 00:${missionInfo?.endTime.split(" ")[1].split(":")[1]}`});
                                    }
                                    setMissionInfo({...missionInfo, endTime: `${missionInfo?.endTime.split(" ")[0]} ${parseInt(e.target.value).toString().padStart(2, '0')}:${missionInfo?.endTime.split(" ")[1].split(":")[1]}`});
                                }} placeholder="시간을 입력해주세요" size="small" textAlign="center" width="calc(50% - 10px)"/>
                                <span style={{color: colors.gray900, ...fonts.body3Medium, width: 20, textAlign: "center"}}>:</span>
                                <TextField type="number" value={missionInfo?.endTime.split(" ")[1].split(":")[1]} onChange={(e) => {
                                    if (parseInt(e.target.value) > 59) {
                                        return setMissionInfo({...missionInfo, endTime: `${missionInfo?.endTime.split(" ")[0]} ${missionInfo?.endTime.split(" ")[1].split(":")[0]}:59`});
                                    }
                                    if (parseInt(e.target.value) < 0) {
                                        return setMissionInfo({...missionInfo, endTime: `${missionInfo?.endTime.split(" ")[0]} ${missionInfo?.endTime.split(" ")[1].split(":")[0]}:00`});
                                    }
                                    setMissionInfo({...missionInfo, endTime: `${missionInfo?.endTime.split(" ")[0]} ${missionInfo?.endTime.split(" ")[1].split(":")[0]}:${parseInt(e.target.value).toString().padStart(2, '0')}`});
                                }} placeholder="분을 입력해주세요" size="small" textAlign="center" width="calc(50% - 10px)"/>
                            </div>
                        </div>
                    
                        {isStartDateCalendarOpen && 
                            <div style={{position: "absolute", bottom: 160, right: 250, width: "100%", height: "100%", zIndex: 1000}}>
                                <CalendarModal onClose={() => setIsStartDateCalendarOpen(false)} dateValue={missionInfo?.startTime} setDateValue={(date) => {
                                    const prevTimeString = missionInfo?.startTime.split(" ")[1];
                                    const newDateString = date.split(" ")[0];
                                    setMissionInfo({...missionInfo, startTime: `${newDateString} ${prevTimeString > "23:59" ? "23:59" : prevTimeString}`});
                                    setIsStartDateCalendarOpen(false);
                                }}/>
                            </div>
                        }
                        {isEndDateCalendarOpen && 
                            <div style={{position: "absolute", bottom: 140, right: 250, width: "100%", height: "100%", zIndex: 1000}}>
                                <CalendarModal onClose={() => setIsEndDateCalendarOpen(false)} dateValue={missionInfo?.endTime} setDateValue={(date) => {
                                    const prevTimeString = missionInfo?.endTime.split(" ")[1];
                                    const newDateString = date.split(" ")[0];
                                    setMissionInfo({...missionInfo, endTime: `${newDateString} ${prevTimeString > "23:59" ? "23:59" : prevTimeString}`});
                                    setIsEndDateCalendarOpen(false);
                                }}/>
                            </div>
                        }
                    </div>
                    <Button size="medium" text="수정하기" onClick={onSend} height="44px" disabled={missionInfo?.title.length === 0 || missionInfo?.content.length === 0 || missionInfo?.missionMembers.length === 0 || missionInfo?.startTime === null || missionInfo?.endTime === null || missionInfo?.subject === null
                         || (moment(`${moment(missionInfo?.startTime).format("YYYY-MM-DD")} ${missionInfo?.startTime.split(" ")[1].split(":")[0]}:${missionInfo?.startTime.split(" ")[1].split(":")[1]}:00`).isAfter(moment(`${moment(missionInfo?.endTime).format("YYYY-MM-DD")} ${missionInfo?.endTime.split(" ")[1].split(":")[0]}:${missionInfo?.endTime.split(" ")[1].split(":")[1]}:00`)))
                    } />
                </Section>
            </ContentWrapper>
        </Container>
    )
})

export default MissionEdit;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 44px 40px;
    width: 100%;
    height: 100%;
`;

const Header = styled.div`
    font-size: ${fonts.title3SemiBold.fontSize};
    line-height: ${fonts.title3SemiBold.lineHeight};
    font-family: ${fonts.title3SemiBold.fontFamily};
    color: ${colors.gray900};
    margin-bottom: 32px;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-color: ${colors.white};
    padding: 32px 24px 32px 32px;
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: calc(50% - 56px);
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: ${fonts.body3SemiBold.fontSize};
    line-height: ${fonts.body3SemiBold.lineHeight};
    font-family: ${fonts.body3SemiBold.fontFamily};
    color: ${colors.gray900};
    margin-bottom: 16px;
`;

const RequiredCircle = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${colors.warning};
    margin-left: 4px;
`;


const SelectedStudentWrapper = styled.div<{isVisible: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-height: 124px;
    flex-wrap: wrap;
    gap: 8px 14px; /* 14px for x-axis gap, 10px for y-axis gap */
    overflow-y: auto;
    margin-top: ${props => props.isVisible ? "16px" : "0px"};
`;

const SelectedStudentItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(25% - 14px);
    height: 40px;
    background-color: ${colors.gray50};
    border-radius: 16px;
    font-size: ${fonts.label4Medium.fontSize};
    line-height: ${fonts.label4Medium.lineHeight};
    font-family: ${fonts.label4Medium.fontFamily};
    color: ${colors.gray50};
    padding: 0 8px;
`;

const CheckBoxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 20px;
    margin-top: 16px;
    margin-bottom: 16px;
`;

const StudentListWrapper = styled.div<{isVisible: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 400px;
    overflow-y: auto;
    border: 1px solid ${colors.gray100};
    border-radius: 8px;

    &::-webkit-scrollbar {
        width: 5px; /* Adjust the width of the scrollbar */
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${colors.gray200}; /* Color of the scrollbar thumb */
        border-radius: 4px; /* Rounded corners for the thumb */
    }
    &::-webkit-scrollbar-track {
        background: transparent; /* Make the track invisible */
    }
`;

const StudentItemWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    background-color: ${colors.white};
    padding: 16px 20px 16px 16px;
`;

const FileWrapper = styled.div<{isVisible: boolean}>`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  gap: 4px;
  width: 100%;
  margin-top: ${props => props.isVisible ? "16px" : "0px"};

`;

const FileItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px;
  gap: 8px;
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    background-color: transparent;
    z-index: 10;
    width: calc(58% - 12px);
`;


const formatFileSize = (fileSize) => {
    const dataSize = fileSize
    let formattedData = '';

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    if (dataSize === 0) return '0 Byte';

    const k = Math.floor(Math.log(dataSize) / Math.log(1024));

    formattedData = Math.round(100 * (dataSize / Math.pow(1024, k))) / 100 + ' ' + sizes[k];
    return formattedData;
}