import { withRouter } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import { rootStore } from "../../mobx/store";
import { Page_settings } from "../../config/page_settings";
import strings from "../../lang/strings";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import AddChapterUnitModal from "./modal/add_chapter_unit_modal";
import CreateCurriculumStep4Modal from "./modal/create_curriculum_step4_modal";
import WarningModal from "./modal/warning_modal";
import StudentCourseInfoModal from "./modal/student_course_info_modal";
import ExamFileViewer from "../class/exam/exam_file_viewer";

const TestReview = (props) => {
  const history = useHistory();
  const context = useContext(Page_settings);
  const [bookmarkStatus,setBookmarkStatus] = useState()
  const [modeStatus, setModeStatus] = useState(0);
  const [testInfo, setTestInfo] = useState(null); // 시험 정보
  const [testResultInfo, setTestResultInfo] = useState(null);
  const [correctAnswerList, setCorrectAnswerList] = useState([]);
  const [userAnswerList, setUserAnswerList] = useState([]);
  const test_id = parseInt(props.match.params.id);
  const location = useLocation();

  useEffect(() => {
      getTestInfo();
  }, []);

  const getTestInfo = () => {
    // 저장한 문제함에서 올경우 여깃 ㅣㄹ행
    if(location.state?.from === 'test_store'){
      context.get(
        'curriculum/getStoreData',
        {
          test_result:test_id //여기서는 testResult id가 들어감
        },
        response => {
          console.log("response",response)
            setTestResultInfo(response);
            setTestInfo(response);
            setCorrectAnswerList(JSON.parse(response.correct_answer_data));
            setUserAnswerList(JSON.parse(response.user_answer_data));
            // getTestResult();
        }
    );
    }else{
      context.get(
        'curriculum/getTestData',
        {
            test:test_id
        },
        response => {
            setTestInfo(response);
            setCorrectAnswerList(JSON.parse(response.answer_data));
            getTestResult();
        }
    );
    }

}

  const getTestResult = () => {
    context.get(
        'curriculum/getTestResult',
        {
            test: test_id,
            member: rootStore.getProfile?.id
        },
        response => {
            console.log("res",response);
            setTestResultInfo(response);
            setUserAnswerList(JSON.parse(response.answer_data));
            setBookmarkStatus(response.store_status)
            
            // setExtraScore(response.extra_score);
            // setExtraScoreType(response.extra_score > 0 ? 1 : resultInfo.extra_score < 0 ? -1 : 0);
        }
    );
}
  const changeBookMark = (status) => {
    let status_data = 0;
    if(status === 0 ){
      status_data = 1;
      setBookmarkStatus(1)
    }else{
      status_data = 0;
      setBookmarkStatus(0)
    }
    context.get(
      'curriculum/changeStoreStatus',
      {
        test_result: location.state?.from === 'test_store'? testResultInfo.test_result : testResultInfo.id,
        status: status_data
      },
      response => {
      }
  );
    
  }

  const goBack = () => {
    if (location.state?.from === 'test_store') {
      history.push(`/curriculum/store`);
    } else if (location.state?.from === 'test') {
      history.goBack();
    } else {
      history.goBack(); // Default behavior
    }
  };

  return (
    <div class="main-content exam-review-container exam-result-content"> 
    
      <div className="left-container">
        <div className="top-container">
          <div className="header-container">
            <div className="left">
              <img class="cursor" src="/assets/image/icon_arrow_back_black.png" alt="" onClick={goBack}/>
              <div className="title">시험지 다시보기</div>
            </div>
            <div className="right">
              <button className={`${modeStatus === 0 ? 'selected': '' }`} onClick={()=>setModeStatus(0)}>시험지 + 해설지</button>
              <button className={`${modeStatus === 1 ? 'selected': '' }`} onClick={()=>setModeStatus(1)}>시험지</button>
              <button className={`${modeStatus === 2 ? 'selected': '' }`} onClick={()=>setModeStatus(2)}>해설지</button>
            </div>
          </div>
        </div>
        <div className="bottom-container">
        <PerfectScrollbar style={{ height : 'calc(100vh - 220px)'}}>
          {modeStatus === 0 ?
          <div className='info-wrap' style={{ flex: 1, overflowY: 'auto', position: 'relative', height: '100%' }}>
                                <ExamFileViewer url={context.loadImage(testInfo?.test_url)} />
                                <ExamFileViewer url={context.loadImage(testInfo?.commentary_url)} />
          </div>
          :
          modeStatus === 1 ? 
          <div className='info-wrap' style={{ flex: 1, overflowY: 'auto', position: 'relative', height: '100%' }}>
                                <ExamFileViewer url={context.loadImage(testInfo?.test_url)} />
          </div>
          :
          modeStatus === 2 &&
          <div className='info-wrap' style={{ flex: 1, overflowY: 'auto', position: 'relative', height: '100%' }}>
                                <ExamFileViewer url={context.loadImage(testInfo?.commentary_url)} />
          </div>
         
          }
          
        </PerfectScrollbar>
        </div>
    
      </div>
      <div className="right-container">
        <div className="top-container">
          <div className="top-header">
          <div className="title">테스트 채점 결과지</div>
          <div className="bookmark cursor" onClick={()=> changeBookMark(bookmarkStatus)}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="31" viewBox="0 0 25 31" fill={`${bookmarkStatus !== 0? '#146DE3' : 'none'}`}>
  <path d="M3.23609 3.23609C4.02755 2.44464 5.101 2 6.22029 2H12.5507H18.8812C20.0004 2 21.0739 2.44464 21.8654 3.23609C22.6568 4.02755 23.1014 5.101 23.1014 6.22029V27.6734C23.1014 28.0889 22.8576 28.4657 22.4786 28.6359C22.0996 28.8061 21.656 28.738 21.3454 28.462L12.5507 20.6445L3.75602 28.462C3.44549 28.738 3.00187 28.8061 2.62286 28.6359C2.24384 28.4657 2 28.0889 2 27.6734V6.22029C2 5.101 2.44464 4.02755 3.23609 3.23609Z" stroke={`${bookmarkStatus !== 0 ? '#146DE3' : '#5C5C5C'}`} stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
</svg></div>
          </div>
          <div className="bottom-box">
            <div className="content-box">
              <div className="left">맞은 문제</div>
              <div className="right correct">{testResultInfo?.correct_count}문제</div>
            </div>
            <div className="content-box">
              <div className="left">틀린 문제</div>
              <div className="right wrong">{testResultInfo?.incorrect_count}문제</div>
            </div>
            <div className="content-box">
              <div className="left">점수</div>
              <div className="right">{testResultInfo?.score}점</div>
            </div>
            <div className="content-box">
              <div className="left">응시 시간</div>
              <div className="right">{testResultInfo?.test_time}</div>
            </div>
          </div>
        </div>
        
        <div className="bottom-container">
        <div className="title">
          OMR 답안지
        </div>
        {/* <PerfectScrollbar style={{height : 'calc(100vh - 300px)'}} className="omr-content"> */}
        <PerfectScrollbar className='flex-1' style={{ height : 'calc(100vh - 400px)', border: '1px solid #DFE1E8', borderRadius: 7, padding: '16px 14px' }}>
                                <div className='d-flex flex-column' style={{ flexWrap: 'wrap'}} >
                                    {
                                        userAnswerList.map((answerItem, index) => {
                                            return <div key={index} className='d-flex flex-row align-items-center m-b-10'>
                                                <div className='d-flex' style={{ width: 38, height: 38, position: 'relative' }}>
                                                    <div style={{ margin: 'auto' }}>{(index + 1) + '번'}</div>
                                                    <img src={'/assets/image/icon_answer_' + (correctAnswerList[index].answer == answerItem.answer ? 'correct' : 'wrong') + '.png'} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                                                </div>
                                                {
                                                    answerItem.type == 1 ?
                                                        <div className='omr-answer'>
                                                            <button className={'check-item' + (answerItem.answer == '1' ? ' selected' : '') + (correctAnswerList[index].answer == '1' ? ' correct' : '')} />
                                                            <button className={'check-item' + (answerItem.answer == '2' ? ' selected' : '') + (correctAnswerList[index].answer == '2' ? ' correct' : '')} />
                                                            <button className={'check-item' + (answerItem.answer == '3' ? ' selected' : '') + (correctAnswerList[index].answer == '3' ? ' correct' : '')} />
                                                            <button className={'check-item' + (answerItem.answer == '4' ? ' selected' : '') + (correctAnswerList[index].answer == '4' ? ' correct' : '')} />
                                                            <button className={'check-item' + (answerItem.answer == '5' ? ' selected' : '') + (correctAnswerList[index].answer == '5' ? ' correct' : '')} />
                                                        </div>
                                                        :
                                                        <div className='omr-answer'>
                                                            <div className='answer-text'>
                                                                {answerItem.answer}
                                                                {
                                                                    correctAnswerList[index].answer != answerItem.answer &&
                                                                    <div className='correct-answer'>{correctAnswerList[index].answer}</div>
                                                                }
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        })
                                    }
                                </div>
                            </PerfectScrollbar>
         {/* </PerfectScrollbar> */}
        </div>
 
                                               
      </div>
      


    </div >
  );






}
export default withRouter(TestReview);
