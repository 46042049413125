import React from "react";
import { IconType } from "../../type/icon_type";

const IconNotification = (props: IconType) => {
    const { color="#73787E", width="20", height="20", style } = props;

    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path d="M4 5H20M19.2 5V14.1667C19.2 14.6087 19.0314 15.0326 18.7314 15.3452C18.4313 15.6577 18.0243 15.8333 17.6 15.8333H6.4C5.97565 15.8333 5.56869 15.6577 5.26863 15.3452C4.96857 15.0326 4.8 14.6087 4.8 14.1667V5M8 20L12 15.8333L16 20" stroke={color} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export default IconNotification;
