
export interface ISubject {
    name: string;
    showingName: string;
    color: string;
    backgroundColor: string;
}

export const Subject: { [key: string]: ISubject } = {
    KOREAN: {
        name: "KOREAN",
        showingName: "국어",
        color: "#EBAC07",
        backgroundColor: "#F7F1B8"
    },
    MATH: {
        name: "MATH",
        showingName: "수학",
        color: "#419EE1",
        backgroundColor: "#CFE7F8"
    },
    ENGLISH: {
        name: "ENGLISH",
        showingName: "영어",
        color: "#DC6060",
        backgroundColor: "#FFE8E3"
    },
    SOCIAL: {
        name: "SOCIAL",
        showingName: "사회",
        color: "#2F6653",
        backgroundColor: "#D6EDBE"
    },
    SCIENCE: {
        name: "SCIENCE",
        showingName: "과학",
        color: "#654078",
        backgroundColor: "#CDC3F5"
    },
    KOREAN_HISTORY: {
        name: "KOREAN_HISTORY",
        showingName: "한국사",
        color: "#885D00",
        backgroundColor: "#E5CFA1"
    },
    JOB: {
        name: "JOB",
        showingName: "직업",
        color: "#C04FB3",
        backgroundColor: "#F9DDF8"
    },
    FOREIGN_LANGUAGE: {
        name: "FOREIGN_LANGUAGE",
        showingName: "외국어",
        color: "#7D7D7D",
        backgroundColor: "#E5E5E5"
    }
};