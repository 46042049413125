import React, {useState} from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import strings from "../../../lang/strings";


const TagMoreButton = (props) => {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const {onEdit, onDelete} = props;

    const toggle = () => {
        setDropdownOpen(!dropdownOpen)
    }

    return (
        <Dropdown isOpen={dropdownOpen} toggle={() => toggle()}
                  className="icon-more">
            <DropdownToggle tag="a">
                <img src={"/assets/image/icon_more_dark_gray.png"} alt=""/>
            </DropdownToggle>
            <DropdownMenu className="media-list dropdown-menu planner-dropdown" tag="ul" style={{height: 80}}>
                <DropdownItem style={{borderBottom: "#e2e2e2 1px solid", color: 'black'}} onClick={() => onEdit()}>
                    {strings.edit}
                </DropdownItem>
                <DropdownItem style={{borderBottom: "#e2e2e2 1px solid", color: '#ee4c69'}} onClick={() => onDelete()}>
                    {strings.delete}
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}

export default TagMoreButton;
