
export const data = {
  '고등': {
    '사회': {
      '한국지리': {
        '국토 인식과 지리 정보': {
          '우리 나라의 위치와 영토': ['우리나라의 위치', '우리나라의 영역', '독도와 동해'],
          '국토 인식의 변화': ['전통적 국토 인식', '고문헌에 나타난 국토 인식', '고지도에 나타난 국토 인식', '근대 이후 국토 인식의 변화'],
          '지리 정보와 지역 조사': ['지리 정보와 지리 정보 시스템', '지역 조사']
        },
        '지형 환경과 인간 생활': {
          '한반도의 형성과 산지의 모습': ['한반도의 형성 과정', '한반도의 산지 지형'],
          '하천 지형과 해안 지형': ['우리나라 하천의 특색', '우리나라의 하천 지형', '하천 지형과 인간 생활', '우리나라 해안의 특색과 해안 지형의 형성', '우리나라의 해안 지형', '해안 지형과 인간 생활'],
          '화산 지형과 카르스트 지형': ['화산 지형의 형성과 인간 생활', '카르스트 지형의 형성과 인간 생활']
        },
        '기후 환경과 인간 생활': {
          '우리나라의 기후 특성': ['기후의 이해', '우리나라의 기후 특성', '우리나라의 계절별 기후 특성'],
          '기후와 주민 생활': ['기후 특성과 주민 생활', '기후와 경제 생활'],
          '자연재해와 기후 변화': ['우리나라의 자연재해', '우리나라의 기후 변화', '우리나라의 식생과 토양']
        },
        '거주 공간의 변화와 지역 개발': {
          '촌락의 변화와 도시 발달': ['전통 촌락의 특징과 촌락의 변화', '도시와 촌락의 관계', '도시 발달과 도시 체계'],
          '도시 구조와 대도시권': ['도시의 지역 분화', '도시 내부 구조', '대도시권의 형성과 확대'],
          '도시 계획과 재개발 및 지역 개발과 공간 불평등': ['도시 계획과 도시 재개발', '지역 개발과 공간 불평등']
        },
        '생산과 소비의 공간': {
          '자원의 의미와 자원 문제': ['자원의 의미와 분류', '자원의 분포와 이용', '자원 문제와 신,재생 에너지'],
          '농업의 변화와 농촌 문제': ['농업의 변화', '농촌 및 농업 문제', '농업 문제의 해결 방안'],
          '공업의 발달과 지역 변화': ['우리나라 공업의 발달과 특징', '공업의 입지 요인과 입지 유형', '공업 지역의 형성과 변화'],
          '서비스업의 변화와 교통,통신의 발달': ['상업의 입지와 변화', '서비스업의 입지와 변화', '교통,통신의 발달과 공간 변화']
        },
        '인구 변화와 다문화 공간': {
          '인구 변화 및 인구 문제와 공간 변화': ['인구 분포와 인구 이동', '인구 구조의 변화', '인구 문제와 공간 변화'],
          '외국인 이주와 다문화 공간': ['외국인 이주자의 증가', '다문화 사회의 형성']
        },
        '우리나라의 지역 이해': {
          '지역 구분 및 북한 지역의 지리적 특성': ['지역의 의미와 지역 구분', '우리나라의 지역 구분', '북한의 자연환경과 자원', '북한의 인문 환경', '북한의 개방 지역과 남북 교류', '통일 국토의 미래'],
          '수도권과 강원 지방의 지리적 특성': ['수도권의 위치와 지역 특성', '수도권의 공간 구조 변화', '수도권의 문제점과 해결 방안', '강원 지방의 위치와 지역 특성', '강원 지방의 산업 구조 변화와 주민 생활'],
          '충청 지방과 호남 지방의 지리적 특성': ['충청 지방의 위치와 지역 특성', '충청 지방의 지역 구조 변화', '호남 지방의 위치와 지역 특성', '호남 지방의 산업 구조 변화'],
          '영남 지방과 제주도의 지리적 특성': ['영남 지방의 위치와 지역 특성', '영남 지방의 주요 도시', '제주도의 위치와 지역 특성', '제주도의 발전 노력과 미래']
        }
      },
      '생활과 윤리': {
        '현대의 삶과 실천 윤리': {
          '현대 생활과 실천 윤리': ['현대인의 삶과 다양한 윤리적 쟁점들', '실천 윤리학의 성격과 특징'],
          '현대 윤리 문제에 대한 접근': ['동양 윤리의 접근', '서양 윤리의 접근'],
          '윤리 문제에 대한 탐구와 성찰': ['도덕적 탐구의 방법', '윤리적 성찰과 실천']
        },
        '생명과 윤리': {
          '삶과 죽음의 윤리,생명윤리': ['출생의 의미와 삶의 가치', '죽음과 관련된 윤리적 쟁점', '생명 복제와 유전자 치료 문제', '동물 실험과 동물 권리의 문제'],
          '사랑과 성 윤리': ['사랑과 성의 관계', '결혼과 가족의 윤리']
        },
        '사회와 윤리': {
          '직업과 청렴의 윤리': ['직업 생활과 행복한 삶', '동양,서양 사상가들의 직업관', '직업 윤리와 청렴,부패 방지와 청렴 문화'],
          '사회 정의와 윤리': ['분배 정의의 의미와 윤리적 쟁점들', '교정적 정의의 의미와 윤리적 쟁점들'],
          '국가와 시민의 윤리': ['국가의 권위와 시민에 대한 의무', '동양의 관점,서양의 관점', '민주 시민의 참여와 시민 불복종']
        },
        '과학과 윤리': {
          '과학 기술과 윤리': ['과학 기술 가치 중립성 논쟁', '과학 기술의 사회적 책임'],
          '정보 사회와 윤리': ['정보 기술 발달과 정보 윤리', '정보 사회에서의 매체 윤리'],
          '자연과 윤리': ['인간과 자연의 관계에 대한 다양한 관점', '환경 문제에 대한 윤리적 쟁점']
        },
        '문화와 윤리': {
          '예술과 대중문화 윤리': ['미적 가치와 윤리적 가치', '대중 문화의 윤리적 문제'],
          '의식주 윤리와 윤리적 소비': ['의복과 관련된 윤리 문제', '음식 문화와 윤리적 문제', '주거 문화와 윤리적 문제', '합리적 소비와 윤리적 소비']
        },
        '다문화 사회의 윤리': {
          '문화 다양성과 존중,종교의 공존과 관용': ['문화 다양성과 존중', '종교의 공존과 관용'],
          '종교와 윤리의 관계': ['종교와 윤리의 관계']
        },
        '평화와 공존의 윤리': {
          '갈등 해결과 소통의 윤리,민족 통합의 윤리': ['사회 갈등과 사회 통합', '소통과 담론의 윤리', '통일 문제를 둘러싼 쟁점 및 통일이 지향해야할 가치'],
          '지구촌 평화의 윤리': ['국제 분쟁의 해결과 평화', '국제 사회에 대한 책임과 기여']
        }
      },
      '경제': {
        '경제 생활과 경제 문제': {
          '희소성과 합리적 선택': ['경제 활동과 그 주체 및 객체', '희소성과 경제 문제', '기회 비용과 합리적 선택', '경제적 유인,경제 활동의 순환'],
          '경제 체제와 및 시장 경제의 원리': ['경제 체제의 유형', '시장 경제의 기본 원리', '시장 경제 원리를 뒷받침하는 제도들'],
          '가계,기업,정부의 경제 활동': ['가계의 경제 활동', '기업의 경제 활동', '정부의 경제 활동']
        },
        '시장과 경제 활동': {
          '시장 가격의 결정과 변동': ['시장의 의미와 기능', '생산물 시장과 생산 요소 시장', '시장의 수요', '수요량의 변동과 수요의 변동', '수요 변동의 요인', '시장의 공급', '공급과 공급량의 의미', '공급 법칙,공급 곡선', '공급량의 변동과 공급의 변동', '시장 균형의 결정', '시장 균형과 불균형', '시장 균형의 결정 원리, 시장 균형의 변동'],
          '잉여와 자원 배분의 효율성': ['시장의 효율성과 잉여', '소비자 잉여와 생산자 잉여', '총잉여', '정부의 가격 규제 정책'],
          '수요와 공급의 가격 탄력성': ['수요의 가격 탄력성', '공급의 가격 탄력성'],
          '시장 실패와 정부 실패': ['시장의 역할과 시장 실패,실패의 요인', '시장 실패 개선을 위한 정부의 역할', '정부 실패와 보완 방안']
        },
        '국가와 경제 활동': {
          '경제 순환과 경제 성장': ['세계 속의 한국 경제', '국민 경제의 순환과 국민 소득', '국민 소득 3면 등가의 법칙', '국내 총생산(GDP)를 계산하는 세가지 방법', '국내 총생산 관련 지표', '경제 성장의 의미와 필요성', '경제 성장률과 경제 성장의 요인'],
          '실업과 인플레이션': ['고용지표,물가와 물가 지수', '인플레이션의 의미', '인플레이션의 부정적인 영향', '인플레이션 유형에 따른 대책', '인플레이션의 의미 유형'],
          '경기 변동과 안정화 정책': ['총 수요와 공급,국민 경제의 균형과 변동', '경기 변동,경기 순환', '경기 변동의 원인', '경제 안정화 정책,재정 정책', '통화 정책']
        },
        '세계 시장과 교역': {
          '무역 원리와 무역 정책': ['무역의 의미와 발생 원인', '무역의 이익과 국내 거래와의 차이점', '무역 발생에 관한 이론', '비교 우위 원리와 무역의 이익', '자유 무역과 보호 무역', '자유 무역의 이익'],
          '외환 시장과 환율': ['외환 시장의 의미,환율의 의미', '환율의 결정,환율의 변동', '환율 변동의 경제적 효과,환율 제도'],
          '국제 수지': ['국제 수지표의 구성', '경상 수지', '자본 수지,금융 계정,오차 및 누락', '경상 수지 불균형이 국민 경제에 미치는 영향', '경상 수지 흑자,적자의 영향', '경상 수지의 환율의 관계']
        },
        '경제 생활과 금융': {
          '금융 생활과 신용': ['금융과 금융 시장', '화폐와 이자율', '가계의 금융 생활'],
          '금융 상품과 재무 계획': ['자산 관리,분산 투자', '금융 상품의 유형과 특징', '생애 주기와 재무 설계', '재무 설계의 절차', '생애 주기 곡선']
        }
      },
      '한국사': {
        '전근대 한국사의 이해': {
          '고대 국가의 지배 체제': [
            '선사 문화의 시작과 여러 나라의 성장',
            '삼국,가야의 성립과 지배 체제의 정비',
            '신라의 삼국 통일',
            '통일 신라와 발해의 지배 체제'
          ],
          '고대 사회의 종교와 사상': [
            '고대의 천신 신앙과 건국 신화',
            '삼국의 종교와 사상',
            '통일 신라 및 발해의 종교와 사상'
          ],
          '고려의 통치 체제와 국제 질서의 변동': [
            '후삼국 통일과 체제 정비',
            '문벌 사회의 형성과 동요',
            '국제 질서의 변동과 대외 관계의 변화',
            '원의 간섭과 고려 사회의 변화'
          ],
          '고려의 사회와 사상': [
            '고려의 사회 구조와 사회 변화',
            '다양한 사상과 신앙의 공존'
          ],
          '조선 시대 세계관의 변화': [
            '정치 운영의 변화',
            '사대교린 외교의 전개',
            '왜란과 호란',
            '북벌론과 북학론'
          ],
          '양반 신분제 사회와 상품 화폐 경제': [
            '신분제의 정비와 향촌 지배 체제의 형성',
            '조선 후기 농업과 상품 화폐 경제의 발달',
            '신분제와 향촌 지배 질서의 동요',
            '사회 모순의 심화와 농민 봉기'
          ]
        },
        '근대 국민 국가 수립 운동': {
          '서구 열강의 접근과 조선의 대응': [
            '서구 열강의 침략적 접근',
            '흥성대원군의 개혁 정치',
            '통상 수교 거부 정책과 양요'
          ],
          '동아시아의 변화와 근대적 개혁의 추진': [
            '동아시아의 개항과 근대화 노력',
            '강화도 조약의 체결과 문호 개방',
            '개화 정책의 추진과 갈등',
            '갑신정변의 전개와 영향'
          ],
          '근대 국민 국가 수립을 위한 노력': [
            '동학 농민 운동의 전개',
            '갑오개혁의 추진',
            '독립 협회의 창립과 대한 제국의 수립'
          ],
          '일본의 침략 확대와 국권 수호 운동': [
            '러일 전쟁과 국권 침탈',
            '항일 의병 운동의 전개',
            '애국 계몽 운동의 전개',
            '독도와 간도'
          ],
          '개항 이후 경제적 변화': [
            '열강의 경제 침략과 경제적 변화',
            '경제적 구국 운동'
          ],
          '개항 이후 사회, 문화적 변화': [
            '근대 문물의 수용과 생활의 변화',
            '근대 의식의 확대와 근대 문화의 발달',
            '해외 이주'
          ]
        },
        '일제 식민지 지배와 민족 운동의 전개': {
          '일제의 식민지 지배 정책': [
            '제1차 세계 대전과 전후의 세계',
            '무단 통치와 수탈 체제의 확립',
            '문화 통치와 경제 수탈의 확대'
          ],
          '3.1운동과 대한민국 임시 정부': [
            '1910년대 국내외 독립운동',
            '3.1운동의 전개',
            '대한민국 임시 정부의 수립'
          ],
          '다양한 민족 운동의 전개': [
            '독립을 위한 무장 투쟁',
            '실력 양성 운동의 전개',
            '민족 유일당 운동'
          ],
          '사회, 문화의 변화와 사회 운동': [
            '근대 의식의 확산과 도시, 농촌의 변화',
            '농민 운동과 노동 운동',
            '다양한 대중 운동'
          ],
          '전시 동원 체제와 민중의 삶': [
            '전체주의 국가의 침략 전쟁과 제2차 세계 대전',
            '민족 말살 통치',
            '병참 기지화 정책',
            '해외 이주 동포들의 활동과 수난'
          ],
          '광복을 위한 노력': [
            '항일 연합 전선의 투쟁',
            '대한민국 임시 정부의 재정비와 한국 광복군',
            '신국가 건설을 위한 노력'
          ]
        },
        '대한민국의 발전': {
          '8.15광복과 통일 정부 수립을 위한 노력': [
            '8.15광복과 남북 분단',
            '통일 정부 수립을 위한 노력'
          ],
          '대한민국 정부의 수립': [
            '대한민국 정부 수립과 북한 정권의 수립',
            '친일파 청산 노력과 농지 개혁'
          ],
          '6.25전쟁과 남북 분단의 고착화': [
            '6.25전쟁과 영향',
            '전후 남북 분단의 고착화'
          ],
          '4.19혁명과 민주화를 위한 노력': [
            '4.19혁명과 이승만 독재 정권의 몰락',
            '5.16군사 정변과 군부 독재 체제',
            '유신 체제의 성립과 몰락',
            '신군부의 등장과 5.18민주화 운동'
          ],
          '경제 성장과 사회, 문화의 변화': [
            '정부 주도의 경제 개발과 산업화',
            '경제 성장에 따른 사회의 변화',
            '대중 매체의 보급과 대중문화의 발달'
          ],
          '6월 민주 항쟁과 민주주의의 발전': [
            '6월 민주 항쟁',
            '민주주의 발전과 평화적 정권 교체',
            '지방 자치의 발전과 시민 사회의 성장'
          ],
          '외환 위기와 사회, 경제적 변화': [
            '세계화와 외환 위기 극복',
            '사회, 경제적 변화',
            '대중문화의 변화와 한류의 확산'
          ],
          '남북 화해와 동아시아 평화를 위한 노력': [
            '북한 사회의 변화',
            '평화 통일 노력과 남북 정상 회담',
            '동아시아 갈등 해결을 위한 노력'
          ]
        }
      },
      '사회문화': {
        '사회,문화 현상의 탐구': {
          '사회,문화 현상의 이해': [
            '사회,문화 현상의 의미와 특성',
            '사회,문화 현상을 보는 관점'
          ],
          '사회,문화 현상의 탐구 방법': [
            '사회,문화 현상의 연구 방법',
            '자료 수집 방법'
          ],
          '사회,문화 현상의 탐구 절차와 태도': [
            '사회,문화 현상의 탐구 절차',
            '사회,문화 현상의 탐구 태도',
            '사회,문화 현상의 탐구와 연구 윤리'
          ]
        },
        '개인과 사회 구조': {
          '인간의 사회화': [
            '개인과 사회의 관계',
            '개인과 사회의 관계를 바라보는 관점',
            '사회화와 사회화 기관',
            '사회적 지위의 역할'
          ],
          '사회 집단과 사회 조직': [
            '사회 집단의 유형,공식 조직과 비공식 조직',
            '관료제와 탈관료제'
          ],
          '일탈 행동': [
            '일탈 행동에 관한 다양한 이론'
          ]
        },
        '문화와 일상생활': {
          '문화의 이해': [
            '문화의 의미와 속성',
            '문화를 바라보는 관점과 문화 이해 태도'
          ],
          '허위 문화와 대중 문화': [
            '허위 문화와 대중 문화'
          ],
          '문화 변동': [
            '문화 변동의 요인과 양상',
            '문화 접변의 결과',
            '문화 변동의 문제점과 대처 방안'
          ]
        },
        '사회 계층과 불평등': {
          '사회 불평등 현상과 계층': [
            '사회 불평등 현상의 유형',
            '사회 불평등 현상을 보는 관점',
            '사회 계층 구조와 사회 이동'
          ],
          '다양한 사회 불평등 양상': [
            '사회적 소수자,성 불평등,빈곤'
          ],
          '사회 복지와 복지 제도': [
            '사회 보장 제도의 유형',
            '복지 제도의 역할과 한계'
          ]
        },
        '현대의 사회 변동': {
          '사회 변동과 사회 운동': [
            '사회 변동의 요인',
            '진화론,순환론,기능론,갈등론',
            '사회 운동의 유형'
          ],
          '저출산,고령화와 다문화적 변화': [],
          '세계화,정보화와 전 지구적 수준의 문제': [
            '세계화로 나타나는 변화 양상',
            '정보화,전 지구적 수준의 문제'
          ]
        }
      },
     '세계지리': {
        '세계화의 지역 이해': {
          '세계화와 지역화,지리 정보 공간 인식': [],
          '동양과 서양의 세계지도와 세계관': []
        },
        '세계의 자연 환경과 인간 생활': {
          '세계의 기후 지역과 열대 기후': [
            '기후의 이해,세계의 기후 지역',
            '열대기후의 특징',
            '열대기후 지역의 식생과 주민 생활'
          ],
          '온대 기후와 건조 및 냉,한대 기후': [
            '온대 기후의 특징',
            '온대 기후 지역의 주민 생활',
            '건조 기후의 특징',
            '사막의 형성 원인',
            '건조 기후 지역의 지형과 주민 생활',
            '냉,한대 기후의 특징',
            '냉,한대 기후 지역의 지형과 주민 생활'
          ],
          '세계의 주요 대지형과 특수한 지형들': [
            '대지형의 형성',
            '판의 경계 유형',
            '세계의 주요 대지형',
            '화산 지형',
            '카르스트 지형,해안지형'
          ]
        },
        '세계의 인문 환경과 인문 경관': {
          '세계의 주요 종교': [
            '세계 주요 종교의 특징과 분포',
            '세계 주요 종교의 성지',
            '종교와 관련된 다양한 주민 생활'
          ],
          '세계의 인구와 도시': [
            '세계의 인구 성장과 변천',
            '세계의 인구 이주,도시화와 세계의 도시화',
            '세계 도시의 등장과 특징',
            '세계 도시 체계'
          ],
          '세계의 주요 식량 및 에너지 자원': [
            '세계의 주요 식량 자원의 특징과 이동',
            '에너지 자원의 의미와 특징'
          ]
        },
        '몬순 아시아와 오세아니아': {
          '자연환경에 적응한 생활 모습': [
            '몬순 아시아의 자연 환경',
            '몬순 아시아의 농업적 토지 이용과 특색',
            '몬순 아시아의 전통 생활 모습'
          ],
          '주요 자원의 분포와 산업구조 및 민족,종교의 다양성과 지역갈등': [
            '주요 자원의 분포,이동'
          ]
        },
        '건조 아시아와 북부 아프리카': {
          '자연환경에 적응한 생활 모습': [
            '건조 아시아와 북부 아프리카의 자연환경,전통적인 생활 모습',
            '건조 아시아와 북부 아프리카의 토지 이용방식과 주민 생활의 변화'
          ],
          '주요 자원의 분포와 산업 구조 및 사막화의 진행': [
            '화석 에너지 자원의 분포 및 이동',
            '건조 아시아와 북부 아프리카 산업 구조',
            '사막화의 발생 원인,문제,노력'
          ]
        },
        '유럽과 북부 아메리카': {
          '주요 공업 지역의 형성과 최근 변화': [
            '유럽의 공업 지역 형성과 변화',
            '북부 아메리카의 전통공업 지역'
          ],
          '현대 도시의 내부 구조와 특징 및 지역의 통합과 분리 운동': [
            '유럽과 북부 아메리카 도시 특징과 대도시권 형성',
            '유럽의 지역 통합과 분리 운동',
            '북부 아메리카의 지역 통합과 분리 운동',
            '자유 무역 협성(NAFTA)체결',
            '북부 아메리카의 분리 독립운동과 이주민 갈등'
          ]
        },
        '사하라 이남 아프리카와 중,남부 아메리카': {
          '도시화 및 도시 구조의 특색': [
            '중,남부 아메리카의 도시화 과정과 특색',
            '과도시화 현상, 도시의 일자리 부족',
            '중,남부 아메리카의 민족 및 문화 특색',
            '중,남부 아메리카의 도시 구조와 도시 문제'
          ],
          '다양한 지역 분쟁과 저개발 및 자원 개발을 둘러싼 과제': [
            '사하라 이남 아프리카의 다양한 지역 분쟁,저개발 원인 및 현황',
            '자원 개발을 둘러싼 과제',
            '사하라 이남 아프리카 자원분포와 개발',
            '자원 개발에 따른 문제와 해결 방안'
          ]
        },
        '공존과 평화의 세계': {
          '경제의 세계화와 경제 블록의 형성': [
            '경제의 세계화의 의미와 영향',
            '세계 주요 경제 블록의 형성과 특징'
          ],
          '지구적 환경 문제와 국제협력 및 세계 평화와 정의를 위한 노력': [
            '기후변화와 지구 온난화',
            '지구적 환경 문제',
            '국가 간 주요 환경 협약',
            '세계 평화와 정의를 위한 노력',
            '주요 분쟁',
            '지역 분쟁으로 인한 난민의 발생',
            '분쟁 해결을 위한 노력'
          ]
        }
      },
      '정치와 법': {
        '민주주의와 헌법': {
          '정치와 법': [
            '정치의 의미와 기능,법의 의미와 이념',
            '정치를 보는 관점,공소 시효 제도',
            '민주주의와 법치주의',
            '대표적인 시민 혁명'
          ],
          '헌법의 의의와 기본원리': [
            '국민 주권주의,자유 민주주의',
            '복지국가의 원리,국제 평화주의',
            '평화 통일지향,문화국가의 원리'
          ],
          '기본권의 보장과 제한': [
            '기본권의 유형',
            '기본권의 제한과 한계'
          ]
        },
        '민주 국가와 정부': {
          '정부 형태': [
            '의원 내각제,대통령제',
            '헌법 개정과 우리나라 정부 형태의 변화 과정'
          ],
          '우리나라의 국가 기관': [
            '국회,대통령과 행정부,법원과 헌법 재판소'
          ],
          '지방 자치의 의의와 과제': [
            '우리나라 지방 자치의 역사',
            '우리나라 지방 자치의 현실과 과제'
          ]
        },
        '정치 과정과 참여': {
          '정치 과정과 정치 참여': [
            '민주 국가의 정치 과정',
            '이스턴의 정책 결정 모형',
            '정치 과정의 중요성,정치 참여의 의의와 유형'
          ],
          '선거와 선거 제도': [
            '선거의 의미와 기능,선거 제도의 유형',
            '대표 결정 방식'
          ],
          '다양한 정치 주제와 시민 참여': [
            '정당을 통한 정치 참여',
            '이익 집단과 시민 단체를 통한 정치 참여',
            '언론을 통한 정치 참여'
          ]
        },
        '개인 생활과 법': {
          '민법의 기초': [
            '민법의 개념,민법의 의의와 기능',
            '민법의 구성,민법의 규율 대상',
            '신의 성실의 원칙,권리 남용 금지의 원칙'
          ],
          '재산 관계에 관련된 법': [
            '계약의 의미와 성립,미성년자의 계약',
            '불법 행위와 손해 배상'
          ],
          '가족 관계에 관련된 법': [
            '혼인과 이혼',
            '혼인의 성립 요건,이혼의 유형과 법적 효과',
            '부모와 자녀의 관계',
            '친자 관계',
            '친권',
            '출생과 사망에 관련된 법,유언,상속'
          ]
        },
        '사회 생활과 법': {
          '형법의 의의와 기능': [
            '형법의 필요성과 기능,좌형 법정주의'
          ],
          '형사 절차와 인권 보장': [
            '형사 절차와 인권 보장 제도',
            '소년 보호 사건과 국민 참여 재판',
            '미란다 원칙'
          ],
          '근로자의 권리': [
            '헌법상 근로자의 권리 보장',
            '노동법의 의의,노동법의 의미와 종류',
            '근로자의 권리 보장 제도',
            '청소년의 근로 보호'
          ]
        },
        '국제 관계와 한반도': {
          '국제 관계의 변화와 국제법': [
            '국제 사회의 의미와 특징',
            '국제 사회의 변천 과정',
            '국제 관계를 바라보는 관점',
            '국제 사회의 행위 주체,세계화 현상',
            '국제법의 이해,국제법의 법원,국제법의 한계'
          ],
          '국제 문제와 국제기구~우리나라의 국제 관계와 국제 질서': [
            '국제 문제의 이해,양상,해결',
            '국제 문제 해결을 위한 국제 기구의 역할',
            '우리나라의 국제 관계와 국제 질서',
            '바람직한 외교 정책'
          ]
        }
      },
      '동아시아사': {
        '동아시아 역사의 시작': {
          '동아시아의 자연환경과 선사문화': [
            '동아시아와 동아시아 학습',
            '동아시아 지역 세계',
            '동아시아의 자연환경과 생업',
            '농경과 목축,동아시아의 구석기 문화',
            '동아시아의 신석기 문화'
          ],
          '국가의 성립과 발전': [
            '청동기 문화의 발전',
            '하,상,주,춘추 전국 시대,진,한',
            '만주와 한반도,일본 열도',
            '동아시아 국가간의 교류와 전쟁'
          ]
        },
        '동아시아 세계의 성립과 변화': {
          '인구 이동과 정치,사회 변동': [
            '화북,강남 방면으로의 이동',
            '만주와 한반도로의 이동',
            '일본 열도로의 이동,지역 국가의 성장'
          ],
          '국제 관계의 다원화': [
            '조공과 책봉의 외교 형식',
            '북방 민족의 성장과 국제 관계의 다원화',
            '몽골 제국의 등장과 동아시아',
            '명 시기의 국제 질서'
          ],
          '유학과 불교': [
            '율령과 유교에 기초한 통치 체제',
            '불교의 전파와 문화 교류',
            '성리학의 성립과 확산'
          ]
        },
        '동아시아의 사회 변동과 문화 교류': {
          '17세기 전후의 동아시아 전쟁': [
            '15~16세기 동아시아의 정세',
            '17세기 전후의 동아시아 전쟁',
            '국제 질서의 재편과 문물 교류'
          ],
          '교역망의 발달과 은 유통': [
            '동아시아 각국의 교역 관계',
            '유럽의 동아시아 진출과 교역망의 확대',
            '은 유통의 활성화'
          ],
          '사회 변동과 서민 문화': [
            '명,청,도선 후기,에도 시대,인구의 증가,상업과 도시의 발달',
            '서민 문화의 발달',
            '새로운 학문의 발전'
          ]
        },
        '동아시아의 근대화 운동과 반제국주의 민족 운동': {
          '새로운 국제 질서와 근대화 운동': [
            '동아시아 각국의 개항',
            '근대화 운동의 전개',
            '국민 국가 수립을 위한 노력'
          ],
          '제국주의 침략 전쟁과 민족 운동': [
            '제국주의 침략과 동아시아 질서의 재편',
            '제1차 세계 대전과 민족 운동의 발전',
            '중국의 국민 혁명',
            '침략 전쟁의 확대와 국제 연대 노력'
          ],
          '서양 문물의 수용': [
            '서구적 세계관의 전파와 수용',
            '근대적 지식의 확산',
            '근대적 생활방식의 확산'
          ]
        },
        '오늘날의 동아시아': {
          '제2차 세계대전 전후 처리와 냉전 체제': [
            '연합국의 전후 처리 구상',
            '동아시아의 전후 처리',
            '냉전과 동아시아의 전쟁',
            '동아시아의 국교 회복'
          ],
          '경제 성장과 정치 발전~동아시아의 갈등과 화해': [
            '경제 성장과 교역의 확대',
            '정치와 사회의 발전',
            '영토를 둘러싼 갈등',
            '독도,한국 고유의 영토',
            '화해와 협력을 위한 모색'
          ]
        }
      },
      '세계사': {
        '인류의 출현과 문명의 발생': {
          '인류의 출현과 선사 문화,문명의 발생': [
            '인류의 출현과 선사문화',
            '문명의 발생과정,4대 문명의 발생',
            '메소포타미아 문명',
            '이집트 문명',
            '지중해 연안의 문명',
            '인도 문명',
            '중국 문명'
          ]
        },
        '동아시아 지역의 역사': {
          '동아시아 세계의 형성': [
            '춘추 전국 시대',
            '진,한 제국의 성립과 발전',
            '위진 남북조 시대',
            '수,당 제국의 발전',
            '한반도와 일본의 고대 국가들'
          ],
          '동아시아 세계의 발전 및 변동': [
            '송의 발전',
            '북방 민족 국가의 대두',
            '몽골 제국의 발전',
            '일본 막부 정권의 성립과 발전',
            '명의 건국과 발전',
            '청의 성립과 발전',
            '명,청대의 사회와 경제,문화'
          ]
        },
        '서아시아,인도 지역의 역사': {
          '서아시아의 여러 제국과 이슬람 세계의 형성': [
            '고대 서아시아 세계의 발전',
            '이슬람 세계의 형성과 발전',
            '이슬람 세계의 팽창'
          ],
          '인도의 역사와 다양한 종교,문화의 출현': [
            '고대 인도 세계의 발전',
            '굽타 왕조와 인도 고전 문화의 발달',
            '인도 문화의 이슬람 문화의 공존'
          ]
        },
        '유럽,아메리카 지역의 역사': {
          '고대 지중해 세계': [
            '그리스 세계의 성립과 발전',
            '알렉산드로스 제국과 헬레니즘 문화',
            '로마의 발전과 문화'
          ],
          '유럽 세계의 형성과 변화': [
            '서유럽 봉건 사회의 성립',
            '비잔티움 제국',
            '봉건 사회의 변화',
            '르네상스와 종교 개혁',
            '신항로 개척과 유럽 교역망의 확장',
            '절대 왕정'
          ],
          '시민 혁명과 산업혁명': [
            '과학 혁명',
            '근대 철학과 사상의 발전',
            '영국 혁명',
            '미국 혁명',
            '프랑스 혁명',
            '나폴레옹 시대',
            '빈 체제와 자유주의의 확산',
            '프랑스의 자유주의 운동',
            '영국의 자유주의 개혁',
            '민족주의 확산과 국민 국가의 발전',
            '산업 혁명'
          ],
          '제국주의와 두 차례 세계 대전': {
            '제국주의와 민족 운동': [
              '제국주의의 등장과 세계 분할',
              '중국의 문호 개방',
              '중국의 근대화 운동',
              '일본의 근대화와 제국주의',
              '서아시아와 아프리카의 민족 운동'
            ],
            '두 차례의 세계 대전': [
              '제1차 세계 대전',
              '러시아 혁명과 소련의 성립',
              '제1차 세계 대전 이후의 세계',
              '중국,인도,튀르키예의 민족운동',
              '대공황과 전체주의',
              '제2차 세계대전',
              '독일의 침략',
              '이탈리아의 침략',
              '일본의 팽창',
              '연합국의 반격과 승리',
              '국제 연합의 성립'
            ]
          }
        }
      },
      '통합사회': {
        '인간,사회,환경과 행복': {
          '인간,사회,환경의 탐구와 통합적 관점': [
            '시간적 관점,공간적 관점',
            '사회적 관점,윤리적 관점',
            '통합적 관점의 필요성',
            '통합적 관점으로 살펴보는 사회 문제'
          ],
          '행복의 의미와 기준': [
            '행복의 다양한 기준',
            '시대에 따른,지역에 따른, 행복의 기준',
            '동양의 행복론,서양의 행복론',
            '행복의 의미, 진정한 행복이란?',
            '행복한 삶을 실현하기 위한 조건'
          ]
        },
        '자연 환경과 인간': {
          '자연 환경과 인간 생활': [
            '세계의 기후 지역',
            '지형과 인간 생활',
            '인간의 삶을 위협하는 자연재해',
            '안전하고 쾌적한 환경에서 살아갈 시민의 권리'
          ],
          '인간과 자연의 바람직한 관계': [
            '인간 중심주의와 생태 중심주의',
            '환경 문제의 발생'
          ]
        },
        '생활 공간과 사회': {
          '산업화,도시화에 따른 생활 공간의 변화': [
            '산업화,도시화에 따른 거주 공간의 변화,생태 환경의 변화',
            '생활 양식의 변화'
          ],
          '교통,통신 및 정보화의 발달과 지역 변화': [
            '교통,통신 발달에 따른 생활 공간과 생활 양식의 변화',
            '정보화에 따른 생활 공간의 변화',
            '지역의 공간 변화'
          ]
        },
        '인권 보장과 헌법': {
          '인권의 의미와 변화 양상': [
            '인권의 특징,인권 보장의 역사',
            '현대 사회의 인권'
          ],
          '인권 보장을 위한 노력과 인권 문제': [
            '인권 보장을 위한 헌법상의 제도적 장치',
            '인권 보장을 위한 국가 기관',
            '우리나라 헌법이 보장하는 기본권',
            '준법 의식과 시민참여',
            '국내 인권 문제와 해결 방안',
            '세계 인권 문제와 해결 방안'
          ]
        },
        '시장 경제와 금융': {
          '자본주의와 시장 경제': [
            '자본주의의 특징과 역사적 전개 과정',
            '합리적 선택의 의미와 한계',
            '시장의 한계',
            '시장 참여자의 바람직한 역학'
          ],
          '국제 분업과 금융 설계': [
            '국제 분업과 무역의 의미',
            '국제 분업과 무역 발생의 원리',
            '국제 무역 확대에 따른 영향',
            '자산 관리와 금융 자산',
            '생애 주기별 금융 설계'
          ]
        },
        '사회 정의와 불평등': {
          '정의의 실현': [
            '정의의 의미와 필요성',
            '정의의 실질적 기준',
            '자유주의적 정의관',
            '공동체주의적 정의관'
          ],
          '사회 및 공간 불평등 현상과 개선 방안': [
            '사회 불평등 현상',
            '공간 불평등 현상',
            '사회 불평등을 해결하기 위한 제도',
            '공간 불평등을 해결하기 위한 정책'
          ]
        },
        '문화와 다양성': {
          '다양한 문화권과 문화 변동': [
            '문화권의 의미와 형성 요인',
            '다양한 문화권의 특징',
            '문화 변동의 요인과 양상',
            '전통문화의 의의와 창조적 계승'
          ],
          '문화 상대주의와 다문화 사회': [
            '문화 절대주의,문화 사대주의',
            '다문화 사회의 형성 배경',
            '다문화 사회의 갈등해결 방법',
            '다문화 정책'
          ]
        },
        '세계화와 평화': {
          '세계화에 따른 변화': [
            '세계화와 지역화',
            '세계 도시의 형성에 따른 변화',
            '다국적 기업의 등장에 따른 변화',
            '세계화의 문제점'
          ],
          '국제 평화를 위한 노력': [
            '국제 갈등과 협력의 양상',
            '국제 사회의 행위 주체',
            '평화의 중요성과 평화를 위한 노력',
            '남북 분단의 과정과 통일의 필요성',
            '동아시아 역사갈등과 세계문제 해결을 위한 노력'
          ]
        },
        '미래와 지속 가능한 삶': {
          '인구 문제의 양상과 해결 방안': [
            '세계의 인구 성장과 인구 분포',
            '국가별 인구 구조와 세계의 인구이동',
            '세계의 인구 문제',
            '인구 문제 해결을 위한 노력'
          ],
          '지속가능한 발전과 미래 지구촌': [
            '자원의 특성과 에너지 자원의 소비 특징',
            '주요 에너지 자원의 특징과 문제점',
            '지속 가능한 발전을 위한 노력',
            '미래 지구촌의 모습과 우리의 삶'
          ]
        }
      },
      '윤리와 사상': {
        '인간과 윤리 사상': {
          '윤리 사상과 사회사상의 필요성': [
            '인간에 대한 다양한 관점',
            '인간 삶에서 윤리 사상과 사회사상의 중요성'
          ],
          '윤리 사상과 사회사상의 역할': [
            '한국 및 동서양 윤리 사상과 우리 삶',
            '사회사상과 우리 삶'
          ]
        },
        '동양과 한국 윤리 사상': {
          '사상의 연원': [
            '동양 윤리 사상의 연원',
            '한국 윤리 사상의 연원'
          ],
          '인의 윤리': [
            '도덕의 성립 근거',
            '도덕 법칙의 탐구 방법'
          ],
          '도덕적 심성': [
            '도덕 감정',
            '도덕 본성'
          ],
          '자비의 윤리': [
            '깨달음',
            '깨달음의 길'
          ],
          '분쟁과 화합': [
            '한국 불교의 전통',
            '한국 불교의 윤리적 특징'
          ],
          '무위자연의 윤리': [
            '도가 사상의 전개',
            '도가 사상의 영향'
          ],
          '한국과 동양 윤리 사상의 의의': [
            '한국 전통 윤리 사상의 근대적 지향성',
            '동양의 이상적 인간상과 시민'
          ]
        },
        '서양 윤리 사상': {
          '사상의 연원': [
            '고대 그리스 사상과 헤브라이즘',
            '규범의 다양성과 보편 도덕'
          ],
          '덕': [
            '영혼의 정의와 행복',
            '이론과 실천의 탁월성과 행복'
          ],
          '행복 추구의 방법': [
            '쾌락의 추구와 평정심',
            '금욕과 부동심'
          ],
          '신앙': [
            '그리스도교와 사랑의 윤리',
            '그리스도교와 자연법 윤리'
          ],
          '도덕의 기초': [
            '도덕적인 삶과 이성',
            '도덕적인 삶과 감정'
          ],
          '옳고 그름의 기준': [
            '의무론과 칸트주의',
            '결과론과 공리주의'
          ],
          '현대의 윤리적 삶': [
            '주체적 결단과 실존',
            '실용주의와 문제 해결의 유용성'
          ]
        },
        '사회사상': {
          '사회사상': [
            '인간의 삶과 사회사상의 지향',
            '동서양 이상 사회론의 현대적 의의'
          ],
          '국가': [
            '국가의 기원과 본질에 대한 관점',
            '국가의 역할과 정당성에 대한 동서양 사상'
          ],
          '시민': [
            '시민적 자유와 권리의 근거',
            '공동체와 공동선 및 시민적 덕성'
          ],
          '민주주의': [
            '근대 민주주의의 지향과 자유 민주주의',
            '도덕적 자율성과 책임성 및 시민의 소통과 유대'
          ],
          '자본주의': [
            '자본주의의 규범적 특징과 기여',
            '자본주의에 대한 비판과 대안들'
          ],
          '평화': [
            '동서양의 다양한 평화 사상',
            '세계 시민주의와 세계 시민 윤리의 구상'
          ]
        }
      }

      },
    '과탐': {
    '물리 I': {
      '역학과 에너지': ['힘과 운동', '운동량과 충격량', '역학적 에너지 보존', '열역학 법칙', '시간과 공간'],
      '물질과 전자기장': ['물질의 전기적 특성', '물질의 자기적 특성'],
      '파동과 정보 통신': ['파동의 성질과 활용', '빛과 물질의 이중성']
    },
    '물리 II': {
      '역학적 상호 작용': ['힘과 평형', '물체의 운동 I', '물체의 운동 II', '일반 상대성 이론', '일과 에너지'],
      '전자기장': ['전기장과 정전기 유도', '저항의 연결과 전기 에너지', '트랜지스터와 축전기', '전류에 의한 자기장', '전자기 유도와 상호유도'],
      '파동과 물질의 성질': ['전자기파의 간섭과 회절', '도플러 효과와 전자기파의 송수신', '볼록 렌즈에 의한 상', '빛과 물질의 이중성', '불확정성 원리']
    },
    '화학 II': {
      '물질의 세 가지 상태와 용액': ['기체', '액체와 고체', '용액'],
      '반응 엔탈피와 화학 평형': ['반응 엔탈피', '화학 평형과 평형 이동', '산 염기 평형'],
      '반응 속도와 촉매': ['반응 속도', '반응 속도에 영향을 미치는 요인'],
      '전기 화학과 이용': ['전기 화학과 이용']
    },
    '생명과학 I': {
      '생명과학의 이해': ['생명과학의 이해'],
      '사람의 물질대사': ['생명 활동과 에너지', '물질대사와 건강'],
      '향상성과 몸의 조절': ['자극의 전달', '신경계', '향상성', '방어 작용'],
      '유전': ['유전 정보와 염색체', '사람의 유전', '사람의 유전병'],
      '생태계와 상호 작용': ['생태계의 구성과 기능', '에너지 흐름과 물질 순환, 생물 다양성']
    },
    '생명과학 II': {
      '생명과학의 역사': ['생명과학의 역사'],
      '세포의 특성': ['세포의 특성', '세포막과 효소'],
      '세포 호흡과 광합성': ['세포 호흡과 발효', '광합성'],
      '유전자의 발현과 조절': ['유전 물질', '유전자 발현', '유전자 발현의 조절'],
      '생물의 진화와 다양성': ['생명의 기원', '생명의 분류와 다양성', '진화의 원리'],
      '생명 공학 기술과 인간 생활': ['생명 공학 기술과 인간 생활']
    },
    '지구과학 I': {
      '고체 지구': ['판 구조론과 대륙 분포의 변화', '판 이동의 원동력과 마그마 활동', '퇴적암과 지질 구조', '지구의 역사'],
      '대기와 해양': ['대기의 변화', '해양의 변화', '대기와 해양의 상호 작용'],
      '우주': ['별의 특성', '외계 행성계와 외계 생명체 탐사', '외부 은하와 우주 팽창']
    },
    '지구과학 II': {
      '고체 지구': ['지구의 형성과 역장', '광물', '지구의 자원', '한반도의 지질'],
      '대기와 해양': ['해수의 운동과 순환', '대기 안정도', '대기의 운동과 대기 대순환'],
      '우주': ['행성의 운동 I', '행성의 운동 II', '우리 은하와 우주의 구조']
    }
  }
  },
  '중등': {
    '과탐': {
      '물리': {
        '여러 가지 힘': ['중력과 탄성력', '마찰력과 부력'],
        '빛과 파동': ['빛', '파동'],
        '전기와 자기': ['마찰 전기', '전류,저항,전기', '자기작용'],
        '열과 우리 생활': ['온도와 열', '비열과 열팽창'],
        '운동과 에너지': ['운동', '에너지'],
        '에너지 전환과 보존': ['여러가지 에너지 전환과 보존', '전기 에너지 발생과 이용']
      },
      '화학': {
        '기체의 성질': ['입자의 운동', '압력과 온도에 따른 기체의 부피 변화'],
        '물질의 상태 변화': ['물질의 상태 변화', '상태 변화와 열 에너지'],
        '물질의 구성': ['원소', '원자와 분자'],
        '열과 우리생활': ['온도와 열', '비열과 열팽창'],
        '화학 반응과 에너지': ['물질 변화와 화학 반응식', '화학 반응의 법칙', '화학 반응에서의 에너지 출입'],
        '여러가지 화학 반응': ['산과 염기', '중화 반응', '산화 환원 반응']
      },
      '생명과학': {
        '생물의 다양성': ['생물의 다양성과 분류', '생물 다양성의 보전'],
        '식물과 에너지': ['광합성', '증산 작용', '식물의 호흡'],
        '동물과 에너지': ['소화', '순환', '호흡', '배설'],
        '자극과 반응': ['감각 기관', '신경계', '호르몬과 향상성 유지'],
        '유전과 진화': ['유전 법칙', '사람의 유전', '진화', '생물의 분류']
      },
      '지구과학': {
        '지권의 변화': ['지구계와 지구의 구조', '암석', '광물과 풍화', '지권의 운동'],
        '태양계': ['지구', '달', '태양계 행성과 태양'],
        '수권과 해수의 순환': ['수권의 구성', '해수의 특성과 순환'],
        '기권과 날씨': ['기권의 특징', '구름과 강수', '기압과 날씨'],
        '외권과 우주개발': ['별자리', '별의 특성', '은하와 우주']
      },
      '과학과 나의 미래': {
        '과학과 직업': ['과학과 직업'],
        '재해 재난의 원인과 대처방안': ['재해 재난의 원인과 대처방안']
      }
    }
  }
};