import React, { Component, useContext, useEffect, useState } from "react";
import { Page_settings } from "../../config/page_settings";
import strings from "../../lang/strings";
import ProgressBar from "../../components/control/progress_bar/progress_bar";
import { getDecimal } from "../../helper/common";
import { rootStore } from "../../mobx/store";

function CloudContainer(props) {

    const [premium, setPremium] = useState(0);
    const context = useContext(Page_settings);
    const [usedCloud, setUsedCloud] = useState(0);
    const [totalCloud, setTotalCloud] = useState(0);

    const getCloudDate = () => {
        context.get(
            "datalab/getCloudData",
            {},
            response => {
                setTotalCloud(Number(response.total_limit));
                setUsedCloud(Number(response.used_capacity) < 0 ? 0 : Number(response.used_capacity))
            }
        )
    }

    useEffect(() => {
        setPremium(rootStore.getProfile.premium ?? 0)
        getCloudDate()
    }, [rootStore.getPremium]);

    return (
        <>
            <div className="cloud-container">
                <div className="d-flex align-bottom m-b-15">
                    <div className="cloud-used f-w-600">{getDecimal((usedCloud / 1024).toString()) + strings._GB}</div>
                    <div
                        className="f-s-15 f-w-600 flex-grow-1">{"/ " + getDecimal((totalCloud / 1024).toString()) + strings._GB}</div>
                    <div
                        className="cloud-free">{strings.free + " " + (totalCloud - usedCloud > 0 ? getDecimal(((totalCloud - usedCloud) / 1024).toString()) : "0") + strings._GB}</div>
                </div>
                <ProgressBar
                    percent={usedCloud / totalCloud * 100}
                    color={"#0f84f4"}
                    height={5}
                    width={"100%"}
                />
                {
                    props.premium == 0 &&
                    <button className="m-t-20" onClick={() => window.open("http://chewing.ai/", "_blank")}>{strings.increase_capacity}</button>
                }
            </div>
        </>
    )
}

export default CloudContainer;