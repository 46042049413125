import React from "react";

export default function MissionCategoryButton(props) {
    return (
      // <div className="col-md-4">
      
        <button
            className={props.selected == true ? "mission-category-selected" : "mission-category"}
            onClick={props.onClick}>{props.value}{props.value === '전체' ? <span> {props.listCount} </span> : null}</button>
        
      // </div>
    );
}
