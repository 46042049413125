
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { Page_settings } from '../../../config/page_settings';
import { Store } from "react-notifications-component";
import moment from "moment";
import Search from './search';
import { dateForm, getDaysFromStartEnd, getDecimal, getTimeFromSecond } from "../../../helper/common";
import strings from '../../../lang/strings';

const Record = observer((props) => {
  const [checkboxes, setCheckboxes] = useState([]);
  const context = useContext(Page_settings);
  const [period, setPeriod] = useState(false)
  const [count, setCount] = useState(0);
  const [selectAll, setSelectAll] = useState(false); // 전체 선택 체크박스
  const [attendanceList,setAttendanceList]  = useState([]);
  const [totalAmount, setTotalAmount] = useState();
  const [toggleMenu, setToggleMenu] = useState({});
  const [keyword,setKeyword] = useState('');
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectCategory, setSelectCategory] = useState(false);
  const [category, setCategory] = useState(-1);
  const [selectStorageType, setSelectStorageType] = useState(false);
  const { setMode } = props;
  const class_id = parseInt(props.match.params.class_id);
  const [yearSelectOpen, setYearSelectOpen] = useState(false);
  const [monthSelectOpen, setMonthSelectOpen] = useState(false);
  const [daySelectOpen, setDaySelectOpen] = useState(false);
  const [year, setYear] = useState(-1);
  const [yearList, setYearList] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [month, setMonth] = useState(-1);
  const [dayList, setDayList] = useState([]);
  const [day, setDay] = useState(-1);

  useEffect(() => {
    getList(new Date());
    getDayList(moment(new Date()).daysInMonth());
    getYearList();
    getMonthList();
    return () => {
        context.handleSetPageHeader(true);
        context.handleSetPageSidebar(true);
    }
  }, []);

  useEffect(() => {
    getAttendanceList();
  }, [category]);


  const selectedDate =() => {
      setPeriod(true)

        // if (year == -1) {
        //     setYear(yearList.indexOf(moment(new Date()).year().toString() + strings._y));
        // }
        // if (month == -1) {
        //     let mm = moment(new Date()).month() + 1;
        //     mm = dateForm(mm) + strings._m
        //     setMonth(monthList.indexOf(mm))
        // }
        // if (day == -1) {
        //     setDay(dayList.indexOf(dateForm(moment(new Date()).date()) + strings._d))
        // }

    }
  

  const toggleYear = () => {
    setYearSelectOpen(!yearSelectOpen);
}

const toggleMonth = () => {
    setMonthSelectOpen(!monthSelectOpen);
}

const toggleDay = () => {
    setDaySelectOpen(!daySelectOpen);
}

const getYearList = () => {
  let now = moment(new Date()).year();
  let list = [];
  for (let i = now - 5; i < now + 1; i++) {
      list.push(i.toString() + strings._y)
  }
  setYearList(list)
  
  // setYear(yearList.length - 1)
  setYear(list.length - 1)
}

const getMonthList = () => {
  let list = [];
  let now = moment(new Date()).month() -1 ;
  let month = ""
  // for (let i = 1; i < 13; i++) {
  //     month = dateForm(i) + strings._m
  //     list.push(month)
  // }
  for (let i = now; i < now + 3; i++) {
    month = dateForm(i) + strings._m
    list.push(month)
}
  setMonthList(list)

  setMonth(list.length- 1)
  
}


const getDayList = (days) => {
  let list = [];
  let day = "";
  for (let i = 1; i <= days; i++) {
      day = dateForm(i) + strings._d
      list.push(day)
  }
  setDayList(list)
  
}

const addNotification = (notificationType, notificationTitle, notificationMessage) => {
  Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: 'top',
      container: 'top-right',
      dismiss: {
          duration: 1000,
      }
  });
};

const vaildDate = (yearIdx, monthIdx, dayIdx) => {
  const yIdx = yearIdx == -1 ? yearList.indexOf(moment(new Date()).year().toString() + strings._y) : yearIdx;
  const mIdx = monthIdx == -1 ? monthList.indexOf(dateForm(moment(new Date()).month() + 1) + strings._m) : monthIdx;
  const dIdx = dayIdx == -1 ? dayList.indexOf(dateForm(moment(new Date()).date()) + strings._d) : dayIdx;

  if (yIdx == undefined || mIdx == undefined || dIdx == undefined) {
      return false;
  }
  const dateStr = yearList[yIdx].split("년")[0] + "-" + monthList[mIdx].split("월")[0] + "-" + dayList[dIdx].split("일")[0]
  var date = moment(dateStr);
  return date.isValid();
}


  const getList = (select_date) => {
      context.get(
        'attendance/getAttendanceList',
        {
          class_id,
          keyword,
          category,
          selected_date:moment(select_date).format("YYYY-MM-DD"),
          mode:"1"
        },
        response => {
          console.log("response",response)
          setAttendanceList(response)
        }); 
    
  }

  const MoveBack =() => {
    props.history.push(`/class`)
  }

  useEffect(() => {
    getAttendanceList();
}, [year, month, day, dayList])

const getAttendanceList = () => {
  if (year == -1) {
    setYear(yearList.indexOf(moment(new Date()).year().toString() + strings._y));
  }
  if (month == -1) {
    let mm = moment(new Date()).month() + 1;
    mm = dateForm(mm) + strings._m
    setMonth(monthList.indexOf(mm))
  }
  if (day == -1) {
    setDay(dayList.indexOf(dateForm(moment(new Date()).date()) + strings._d))
  }
  
  if (period) {
    if (dayList.length < 10) {
        getDayList(moment(yearList[year].split("년")[0] + "." + monthList[month].split("월")[0] + ".01").daysInMonth());
    }
    getList(yearList[year].split("년")[0] + "-" + monthList[month].split("월")[0] + "-" + dayList[day].split("일")[0], yearList[year].split("년")[0] + "-" + monthList[month].split("월")[0] + "-" + dayList[day].split("일")[0]);
  } else {
    getList(new Date, new Date);
    
  }
}



  return (
  <div className='attendance-container record-mode'>
  <div class="return-container">
  <svg class="cursor"xmlns="http://www.w3.org/2000/svg" width="17" height="29" viewBox="0 0 17 29" fill="none" onClick={MoveBack}>
    <path d="M17 25.5925L6.49258 14.5L17 3.4075L13.7652 -2.82797e-07L7.42695e-07 14.5L13.7652 29L17 25.5925Z" fill="black"/>
  </svg>
    <div className="text">출결</div>
  </div>
  <div class="top-content">
    <div class="left">
    <div class="menu-btn" onClick={() => setMode(0)}>출석부</div>
    <div class="menu-btn selected">기록</div>
    </div>
    <div class="right">
      <Search
        inputType={'text'}
        value={keyword}
        onChange={(v) => {
          setKeyword(v)
        }}
        onSearch={() => {
          getAttendanceList()
        }}
        placeholder={'학생 이름을 검색해주세요.'}
      />
       <Dropdown isOpen={yearSelectOpen} toggle={() => toggleYear()}
                            className="dropdown navbar-user report-dropdown" tag="li" style={{ width: "100px" }}>
                            <DropdownToggle tag="a"
                                className="display-flex-important align-item-center profile-dropdown report-dropdown">
                                <div
                                    className="flex-grow-1">{year != -1 ? yearList[year] : moment(new Date()).year().toString() + strings._y}</div>
                                <img src={"/assets/image/icon_arrow_down_black.png"} alt="" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu dropdown-menu-right profile-dropdown-menu" tag="ul">
                                {
                                    yearList.map((item, idx) =>
                                        <DropdownItem key={idx}
                                            className="dropdown-header display-flex-important justify-content-center f-s-15"
                                            onClick={() => {
                                                if (!vaildDate(idx, month, day)) {
                                                    addNotification('warning', '유효하지 않은 날짜입니다.')
                                                    return;
                                                }

                                                selectedDate()
                                                setYear(idx)
                                            }}
                                        >
                                            {item}
                                        </DropdownItem>
                                    )
                                }
                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown isOpen={monthSelectOpen} toggle={() => toggleMonth()}
                        className="dropdown navbar-user report-dropdown m-l-10" tag="li">
                        <DropdownToggle tag="a"
                            className="display-flex-important align-item-center profile-dropdown report-dropdown">
                            <div
                                className="flex-grow-1">{month != -1 ? monthList[month] : dateForm(moment(new Date()).month() + 1) + strings._m}</div>
                            <img src={"/assets/image/icon_arrow_down_black.png"} alt="" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu dropdown-menu-right profile-dropdown-menu" tag="ul">
                            {
                                monthList.map((item, idx) =>
                                    <DropdownItem key={idx}
                                        className="dropdown-header display-flex-important justify-content-center f-s-15"
                                        onClick={() => {
                                            if (!vaildDate(year, idx, day)) {
                                                addNotification('warning', '유효하지 않은 날짜입니다.')
                                                return;
                                            }
                                            selectedDate()
                                            setMonth(idx)
                                            
                                        }}
                                    >
                                        {item}
                                    </DropdownItem>
                                )
                            }
                        </DropdownMenu>
                    </Dropdown>
                            <Dropdown isOpen={daySelectOpen} toggle={() => toggleDay()}
                        className="dropdown navbar-user report-dropdown m-l-10" tag="li">
                        <DropdownToggle tag="a"
                            className="display-flex-important align-item-center profile-dropdown report-dropdown">
                            <div
                                className="flex-grow-1">{day != -1 ? dayList[day] : moment(new Date()).date().toString() + strings._d}</div>
                            <img src={"/assets/image/icon_arrow_down_black.png"} alt="" />
                        </DropdownToggle>
                        <DropdownMenu
                            className="dropdown-menu dropdown-menu-right profile-dropdown-menu "
                            tag="ul">
                            <PerfectScrollbar className="report-dropdown-menu" options={{ suppressScrollX: true }}>
                                {
                                    dayList.map((item, idx) =>
                                        <DropdownItem key={idx}
                                            className="dropdown-header display-flex-important justify-content-center f-s-15"
                                            onClick={() => {
                                                if (!vaildDate(year, month, idx)) {
                                                    addNotification('warning', '유효하지 않은 날짜입니다.')
                                                    return;
                                                }
                                                // periodFix()
                                                selectedDate()
                                                setDay(idx)
                                            }}
                                        >
                                            {item}
                                        </DropdownItem>
                                    )
                                }
                            </PerfectScrollbar>
                        </DropdownMenu>
                    </Dropdown>
    </div>
</div>

<div class="bill-management-box middle-height" style={{overflow:'hidden'}}>
  <div class="attendance-grid">
        <div class="category" onClick={()=> setSelectCategory(!selectCategory)}>
          <div class="flex">{category === "DONE" ? '수납' : category === "CANCELED" ? '취소' : '전체'}
          <img class={selectCategory ? 'reverse' : ''} src='/assets/image/icon_arrow_down_gray.png' alt=''/>
          </div>
        {selectCategory && (
            <div className="category-box">
              <button onClick={() => setCategory(-1)}>전체</button>
              <button onClick={() => setCategory(0)} >입실</button>
              <button onClick={() => setCategory(1)}>퇴실</button>
            </div>
          )}
        </div>
        <div>이름</div>
        <div>번호</div>
        <div>학교</div>
        <div>학년</div>
        <div>이메일</div>
        <div>부모님 번호</div>
        <div style={{textAlign:'start'}}>발송일자</div>
        
        
  </div> 
  <div style={{height:'calc(95% - 30px)'}}>
  <PerfectScrollbar style={{ height: `100%`}} options={{ suppressScrollX: true }}>
  {
    attendanceList.length > 0 ? 
      attendanceList.map((item, index) =>
      <div class={`grid-container attendance-grid-content`}>
        <div className='center'>
        <button class={`status-btn ${item?.status === 0 ? "enter" : "exit"}`}>{item?.status === 0 ? "입실" : "퇴실"}</button>
        </div>
      <div class="name">{item?.name}</div>
      <div class="phone-num">{item?.phone}</div>
      <div class="billing-amount">{item?.school}</div>
      <div >{item?.grade}</div>
      <div >{item?.email}</div>
      <div class="billing-reason no-wrap">{item?.phone}</div>
      <div class="date">
        <div style={{justifyContent:'start'}}>{item?.date}</div>
        <div class="time" style={{justifyContent:'start'}}>{item?.time}</div>
        </div>
      
      </div>
      
  )
  :
  null
}
  </PerfectScrollbar>
  </div>
  
  {count > 0 && <div className="storage-bottom-menu">
    <div class="left">
      인쇄하기
    </div>
  </div>}    
</div>

      </div>
    
    
  );
})


export default withRouter(Record);