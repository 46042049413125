import React from "react";
import styled from "styled-components";
import colors from "../design/colors";


interface ImageCircleProps {
    src: string;
    size: number | string;
    style?: React.CSSProperties;
    onClick?: () => void;
}

const ImageCircle = ({ src, size, style, onClick }: ImageCircleProps) => {
    return <Image size={size} style={style} onClick={onClick}>
        <img src={src} alt="profile" />
    </Image>;
};

export default ImageCircle;

const Image = styled.div<{ size: number | string }>`
    width: ${props => typeof props.size === 'number' ? `${props.size}px` : props.size};
    height: ${props => typeof props.size === 'number' ? `${props.size}px` : props.size};
    border-radius: 50%;
    overflow: hidden;
    background-color: ${colors.white};
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
`;
