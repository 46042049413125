import React, { useState } from "react";

export default function classSearch(props) {

  const handleEnter = e => {
    if (e.keyCode == 13) {
        props.onSearch()
    }
  }

    return (
        <div >
            <div className="class-search  align-item-center box-shadow" style={{padding: '9px 15px'}}>             
                <input className="input-transparent" placeholder={props.placeholder} type={props.inputType} maxLength={props.maxLength} value={props.value} onChange={(e) => props.onChange(e.target.value)} onKeyD
                own={handleEnter}
                />
            </div>
        </div>
    );
}