import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { observer } from "mobx-react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import IconButton from "../../../components/icon_button";
import { ChevronDown, ChevronUp } from "../../../components/icons/chevron";
import SearchBar from "../../../components/search_bar";
import { Page_settings } from "../../../config/page_settings";
import colors from "../../../design/colors";
import fonts from "../../../design/typography";
import useDebounce from "../../../hooks/useDebounce";
import { rootStore } from "../../../mobx/store";
import MissionAddModal from "../modals/missionAddModal";
import { Subject } from "../../../type/subject";
import SubjectChip from "../../../components/subject_chip";
import NoClassPage from "../../no_class";

interface IMission {
  id: number;
  title: string;
  content: string;
  startTime: string;
  endTime: string;
  resolvedStudents: number;
  totalStudents: number;
  status: "NONE" | "SUBMITTED" | "REJECTED" | "APPROVED";
  subject: keyof typeof Subject;
}


interface IStatus {
  code: number;
  name: string;
  hidden?: boolean;
}

const statusList: IStatus[] = [
  {
    code: 0,
    name: "전체"
  },
  {
    code: 1,
    name: "예정",
    hidden: true
  },
  {
    code: 2,
    name: "진행중"
  },
  {
    code: 3,
    name: "마감"
  }
]


interface ISort {
  code: number;
  name: string;
}

const sortList: ISort[] = [
  {
    code: 0,
    name: "최신순"
  }, {
    code: 1,
    name: "오래된 순"
  }, {
    code: 2,
    name: "마감일순"
  }
]

const MissionList = observer((props) => {
  const context: any = useContext(Page_settings);
  const classId = rootStore.getClassId;
  const navigate = useNavigate(); 

  const [status, setStatus] = useState<IStatus>(statusList[0]);
  const [sort, setSort] = useState<ISort>(sortList[0]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [openAddMission, setOpenAddMission] = useState(false);
  // URL로 부터 데이터 가져오기
  const [missionList, setMissionList] = useState<IMission[]>([]);
  const [missionListCount, setMissionListCount] = useState<number>(0);
  const [searchKeyword, setSearchKeyword] = useState("");

  const keyword = useDebounce(searchKeyword, 500);
  const userType = rootStore.getProfile?.userType ?? "";
  const [hoveredDropdownIndex, setHoveredDropdownIndex] = useState<number>(-1);

  useEffect(() => {
    if (!isNaN(classId) && classId !== 0 && classId !== undefined && classId !== null) {
      context.get(
        `classroom/${classId}/mission`,
        {
          status: status.code,
          sort: sort.code,
          keyword,
        },
        (response) => {
          setMissionList(response.list);
          setMissionListCount(response.totalCount);
        }
      );
    }
  }, [openAddMission, status, sort, keyword]);

  const onClickMission = (missionId) => {
    navigate(`/study/mission/${missionId}`);
  };


  const getStatusChip = (status: "NONE" | "SUBMITTED" | "REJECTED" | "APPROVED") => {
    switch (status) {
      case "NONE":
        return <div style={{...fonts.label4Medium, borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center", width: "43px", height: "24px"}}/>;
      case "SUBMITTED":
        return <div style={{...fonts.label4Medium, backgroundColor: colors.gray100, borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center", width: "43px", height: "24px"}}>
          <span style={{color: colors.gray500}}>대기</span>
        </div>;
      case "REJECTED":
        return <div style={{...fonts.label4Medium, backgroundColor: colors.warning50, borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center", width: "43px", height: "24px"}}>
          <span style={{color: colors.warning}}>반려</span>
        </div>;
      case "APPROVED":
        return <div style={{...fonts.label4Medium, backgroundColor: colors.blue50, borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center", width: "43px", height: "24px"}}>
          <span style={{color: colors.success}}>완료</span>
        </div>;
    }
  }

  return (
    classId ? (
    <Container>
      <Header>
        <span style={{...fonts.title2SemiBold, color: colors.gray900}}>과제</span>
          {userType === "EXPERT" && (
            <IconButton
              text="과제 추가"
              onClick={() => navigate("/study/mission/add")}
              size="medium"
              style={{
                width: 93,
                height: 36
              }}
            />
          )}
      </Header>
      <TabContainer>
        {statusList.filter((item) => userType === "STUDENT" ? !item.hidden : true).map((item, index) => (
          <TabItem key={index} selected={status.code === item.code} onClick={() => setStatus(item)}>
            {item.name}
            {status.code === item.code && <span style={{...fonts.body2SemiBold, color: colors.blue600, marginLeft: "10px"}}>{missionListCount}</span>}
          </TabItem>
        ))}
      </TabContainer>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", position: "relative"}}>
        <SearchBar value={searchKeyword} onChange={(e) => setSearchKeyword(e.target.value)} placeholder="과제를 검색해주세요" size="medium" width="245px"/>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", cursor: "pointer"}} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
          <span style={{...fonts.body3Medium, color: colors.gray600, width: "100%"}}>{sort.name}</span>
          {isDropdownOpen ? (
            <ChevronUp width="24px" height="24px" color={colors.gray500}/> 
          ) : (
            <ChevronDown width="24px" height="24px" color={colors.gray500}/>
          )}
          {isDropdownOpen && (
            <DropDownBox>
              {sortList.map((item, index) => (
                <DropDownItem key={index} onClick={() => setSort(item)} hovered={index === hoveredDropdownIndex} 
                isFirst={index === 0} isLast={index === sortList.length - 1}
                onMouseEnter={() => setHoveredDropdownIndex(index)} onMouseLeave={() => setHoveredDropdownIndex(-1)}>{item.name}</DropDownItem>
              ))}
            </DropDownBox>
          )}
        </div>
      </div>
      {/* 미션 리스트 */}
      {missionList !== null && missionList.length > 0 ? (
        <MissionContentWrapper>
          {missionList.map((item, idx) => {
            const open = item.startTime < moment().format("YYYY-MM-DD HH:mm:ss");
            const end = item.endTime < moment().format("YYYY-MM-DD HH:mm:ss");
            return (
              <MissionItem key={idx} onClick={() => {
                  if (!open && (userType !== "EXPERT")) {
                    return;
                  }
                  onClickMission(item.id);
                }}
                style={{cursor: (open || userType === "EXPERT") ? "pointer" : "default"}}
              >
                <SubjectChip subject={item.subject} style={{marginBottom: "16px"}}/>
                <div style={{...fonts.body2SemiBold, color: colors.gray900, width: "100%"}}>{item.title}</div>
                <div style={{...fonts.label4Medium, color: colors.gray500, width: "100%", marginTop: "16px"}}>{item.content}</div>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "calc(100% - 48px)", position: "absolute", bottom: "24px", left: "24px"}}>
                  { userType === "EXPERT" && !open && <div style={{...fonts.label4Medium, color: colors.gray500, width: "100%"}}>{moment(item.startTime).format("YYYY-MM-DD HH:mm") + " 예정"}</div>}
                  { userType === "EXPERT" && open && !end && <>
                    <div style={{...fonts.label4Medium, color: colors.gray500}}>{moment(item.endTime).format("YYYY-MM-DD HH:mm") + " 까지"}</div>
                    <div style={{...fonts.label4Medium}}>
                      <span style={{color: colors.blue600}}>{item.resolvedStudents}명</span>
                      <span style={{color: colors.gray400}}>/{item.totalStudents}명</span>
                    </div>
                  </>}
                  {userType === "EXPERT" && end && <>
                    <div style={{...fonts.label4Medium, color: colors.gray500}}>{"마감"}</div>
                    <div style={{...fonts.label4Medium}}>
                      <span style={{color: colors.blue600}}>{item.resolvedStudents}명</span>
                      <span style={{color: colors.gray400}}>/{item.totalStudents}명</span>
                    </div>
                    </>
                  }
                  {userType === "STUDENT" && open && !end && <>
                    <div style={{...fonts.label4Medium, color: colors.gray500}}>{moment(item.endTime).format("YYYY-MM-DD HH:mm") + " 까지"}</div>
                    {getStatusChip(item.status)}
                  </>}
                  {userType === "STUDENT" && end && <>
                    <div style={{...fonts.label4Medium, color: colors.gray500}}>{"마감"}</div>
                    {getStatusChip(item.status)}
                  </>}
                </div>
              </MissionItem>
            );
          })}
        </MissionContentWrapper>
      ) : (
          <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", marginTop: "114px"}}>
            <img
              style={{width: "152px", height: "113px", marginBottom: "24px"}}
              src="/assets/image/sad_rai.png"
              alt=""
            />
            <span style={{...fonts.body3Medium, color: colors.gray400}}>아직 과제가 없습니다.</span>
            {userType === "EXPERT" && (
              <span style={{...fonts.body3Medium, color: colors.gray400}}>지금 바로 과제를 등록해보세요!</span>
            )}
          </div>
      
      )}
    {openAddMission && (
      <MissionAddModal
        onClose={() => {
          setOpenAddMission(false);
        }}
        class_id={classId}
        creator={rootStore.getProfile?.id}
      />
    )}
    </Container>
  ) : (
    <NoClassPage userType={userType} />
  )
)});

const Container = styled.div`
  padding: 24px 40px;
  width: 100%;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;
`;

const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid ${colors.gray100};
  margin-bottom: 32px;
`;

const TabItem = styled.div<{selected: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${props => props.selected ? colors.gray900 : colors.gray600};
  font-size: ${props => props.selected ? fonts.body3SemiBold.fontSize : fonts.body3Medium.fontSize};
  line-height: ${props => props.selected ? fonts.body3SemiBold.lineHeight : fonts.body3Medium.lineHeight};
  font-family: ${props => props.selected ? fonts.body3SemiBold.fontFamily : fonts.body3Medium.fontFamily};
  cursor: pointer;
  width: 74px;
  height: 40px;
  border-bottom: ${props => props.selected ? `2px solid ${colors.gray900}` : "none"};
`;

const DropDownBox = styled.div`
  position: absolute;  
  width: 74px;
  background-color: white;
  border: 1px solid ${colors.gray100};
  border-radius: 8px;
  z-index: 3;
  top: 40px;
  border-radius: 8px;
`;

const DropDownItem = styled.div<{hovered: boolean, isFirst: boolean, isLast: boolean}>`
  width: 100%;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${props => props.hovered ? colors.blue50 : colors.white};

  color: ${colors.gray500};
  font-size: ${fonts.body3Regular.fontSize};
  line-height: ${fonts.body3Regular.lineHeight};
  font-family: ${fonts.body3Regular.fontFamily};

  border-top-left-radius: ${props => props.isFirst ? "8px" : "0px"};
  border-top-right-radius: ${props => props.isFirst ? "8px" : "0px"};
  border-bottom-left-radius: ${props => props.isLast ? "8px" : "0px"};
  border-bottom-right-radius: ${props => props.isLast ? "8px" : "0px"};
`;

const MissionContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px 10px;
  flex-wrap: wrap;
  margin-top: 20px;
`;

const MissionItem = styled.div`
  width: calc(33.333333% - 10px);
  height: 200px;
  background-color: ${colors.white};
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
`;

export default MissionList;
