import React from "react";
import { IconType } from "../../type/icon_type";

const IconFlag = (props: IconType) => {
    const { color="#73787E", width="20", height="20", style } = props;
    
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path d="M4.16602 11.9997C4.16602 11.9997 4.89518 11.333 7.08268 11.333C9.27018 11.333 10.7285 12.6663 12.916 12.6663C15.1035 12.6663 15.8327 11.9997 15.8327 11.9997V3.99967C15.8327 3.99967 15.1035 4.66634 12.916 4.66634C10.7285 4.66634 9.27018 3.33301 7.08268 3.33301C4.89518 3.33301 4.16602 3.99967 4.16602 3.99967V11.9997ZM4.16602 11.9997V16.6663" stroke={color} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export default IconFlag;