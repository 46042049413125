import moment from "moment";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import IconDownload from "../../../components/icons/download";
import { Page_settings } from "../../../config/page_settings";
import colors from "../../../design/colors";
import fonts from "../../../design/typography";
import { rootStore } from "../../../mobx/store";
import { getFileImagePath, getFilePath } from "../../../utils/image";
import IconX from "../../../components/icons/x";
import TextField from "../../../components/text_field";
import PlusIcon from "../../../components/icons/plus";
import Button from "../../../components/button";
import ImageCircle from "../../../components/image_circle";

interface IFileInfo {
  name: string;
  size: number;
  type: string;
  url: string;
}

interface IMissionMember {
  member: number;
  name: string;
  profile: string;
  school: string;
  missionStatus: "SUBMITTED" | "APPROVED" | "REJECTED" | "NONE";
  submitId: number;
}

interface IMission {
  id: number;
  classroom: number;
  title: string;
  content: string;
  startTime: string;
  endTime: string;
  createDate: string;
  fileList: IFileInfo[];
  missionMembers: IMissionMember[];
}

interface IMissionSubmit {
  title: string;
  content: string;
  fileList: IFileInfo[];
  status: "SUBMITTED" | "APPROVED" | "REJECTED" | "NONE";
  createDate: string;
  updateTime?: string;
}

const MissionStudentDetail = (props) => {
  const classId = rootStore.getClassId;
  const missionId = parseInt(useParams().mission_id);
  const context: any = useContext(Page_settings);

  const [missionInfo, setMissionInfo] = useState<IMission | null>(null);

  const [missionSubmitId, setMissionSubmitId] = useState<number>(-1);
  const [isEdit, setEdit] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    context.get(`classroom/${classId}/mission/${missionId}`, {}, (response) => {
      console.log("getMissionInfo", response);
      if (response.length === 0) {
        alert("찾을수 없습니다");
        navigate(`/class/mission/${classId}`);
      }
      setMissionInfo(response);
      setMissionSubmitId(response.missionMembers[0].submitId === null ? -1 : response.missionMembers[0].submitId);
    });
  }, []);

  return (
    <Container>
      <Section style={{ padding: "32px 40px", width: "calc(60% - 40px)" }}>
        <MissionInfoContent missionInfo={missionInfo} />
      </Section>
      <div
        style={{
          width: "1px",
          height: "650px",
          backgroundColor: `${colors.gray100}`,
          margin: "0 40px",
        }}
      />
      <Section style={{ padding: "24px", width: "calc(40% - 40px)" }}>
        <MissionSubmitContent classId={classId} missionId={missionId} submitId={missionSubmitId} isEdit={isEdit} setEdit={setEdit} isClosed={new Date() > new Date(missionInfo?.endTime)}/>
      </Section>
    </Container>
  );
};

export default MissionStudentDetail;

const Container = styled.div`
  padding: 32px 40px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  border-radius: 12px;
  height: 650px;
  position: relative;
`;

const FileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 4px;
  gap: 4px;
  max-height: 72px;
  overflow-y: auto;
`;

const FileItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px;
  gap: 8px;
  height: 24px;
`;


const MissionInfoContent = ({ missionInfo }: { missionInfo: IMission }) => {
  if (!missionInfo) return null;

  return (
    <>
      {missionInfo.startTime > moment().format("YYYY-MM-DD HH:mm") && missionInfo.endTime > moment().format("YYYY-MM-DD HH:mm") && (<div
        style={{
          ...fonts.label4Medium,
          color: `${colors.blue600}`,
          marginBottom: "20px",
        }}
      >
        {moment(new Date(missionInfo.endTime)).format("YYYY.MM.DD HH:mm")}까지
      </div>)}
      {
        missionInfo.endTime < moment().format("YYYY-MM-DD HH:mm") && (<div
          style={{
            ...fonts.label4Medium,
            color: `${colors.warning}`,
            marginBottom: "20px",
          }}
        >
          마감
        </div>
      )}
      <div
        style={{
          ...fonts.body1SemiBold,
          color: `${colors.gray900}`,
          marginBottom: "32px",
        }}
      >
        {missionInfo.title}
      </div>
      <div
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: `${colors.gray100}`,
          marginBottom: "32px",
        }}
      />
      <div
        style={{
          ...fonts.label3Medium,
          color: `${colors.gray500}`,
          marginBottom: "10px",
          height: "160px",
        }}
      >
        <textarea
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            outline: "none",
            ...fonts.label3Medium,
            color: `${colors.gray500}`,
          }}
          value={missionInfo.content}
          readOnly
        />
      </div>
      <div>
        {missionInfo.fileList.map((file, idx) => (
          <div
            key={idx}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "calc(100% - 274px)",
              }}
            >
              <img
                src={getFileImagePath(file.name)}
                alt={file.name}
                width={24}
                height={24}
              />
              <span
                style={{
                  ...fonts.label4Medium,
                  color: colors.gray900,
                  marginLeft: 8,
                }}
              >
                {file.name.length > 40
                  ? file.name.slice(0, 40) + "..."
                  : file.name}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: 250,
              }}
            >
              <span style={{ ...fonts.label4Medium, color: colors.gray400 }}>
                {fileResize(file.size)}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: 24,
                cursor: "pointer",
              }}
              onClick={() => onDownload(file.url, file.name)}
            >
              <IconDownload color={colors.gray500} width="24px" height="24px" />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};


const MissionSubmitContent = ({ classId, missionId, submitId, isEdit, setEdit, isClosed }: { classId: number, missionId: number, submitId: number, isEdit: boolean, setEdit: (isEdit: boolean) => void, isClosed: boolean }) => {
  const [submitInfo, setSubmitInfo] = useState<IMissionSubmit>({
    title: "",
    content: "",
    fileList: [],
    status: "NONE",
    createDate: "",
  });
  useEffect(() => {
    if (submitId !== -1) {
      context.get(`classroom/${classId}/mission/${missionId}/submit/${submitId}`, {}, (response) => {
        setSubmitInfo(response);
      });
    } else {
      setSubmitInfo({
        title: "",
        content: "",
        fileList: [],
        status: "NONE",
        createDate: "",
      });
    }
  }, [submitId]);

  const inputFileRef = useRef<HTMLInputElement>(null);
  const context: any = useContext(Page_settings);
  
  const navigate = useNavigate();
  const [fileList, setFileList] = useState<File[]>([]);
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    setFileList(prev => [...prev, ...files]);
  };

  const enableSubmit = useMemo(() => {
    return submitInfo.title.length > 0 && submitInfo.content.length > 0;
  }, [submitInfo.title, submitInfo.content]);
  

  const onSubmit = (submitInfo: IMissionSubmit) => {
    if(isEdit) {
      if(fileList.length > 0) {
        const formData = new FormData();
        fileList.forEach(file => {
          formData.append("files", file);
        });

        context.post('/upload/files/mission', formData, (response) => {
          context.put(`classroom/${classId}/mission/${missionId}/submit/${submitId}`, {
            title: submitInfo.title,
            content: submitInfo.content,  
            fileList: [...submitInfo.fileList, ...response],
          }, (response) => {
            navigate(0);
          });
        });
      } else {
        context.put(`classroom/${classId}/mission/${missionId}/submit/${submitId}`, {
          title: submitInfo.title,
          content: submitInfo.content,
          fileList: submitInfo.fileList,
        }, (response) => {
          navigate(0);
        });
      }
    } else {
      if(fileList.length > 0) {
        const formData = new FormData();
        fileList.forEach(file => {
          formData.append("files", file);
        });

        context.post('/upload/files/mission', formData, (response) => {
          context.post(`classroom/${classId}/mission/${missionId}/submit`, {
            title: submitInfo.title,
            content: submitInfo.content,
            fileList: [...submitInfo.fileList, ...response],
          }, (response) => {
            navigate(0);
          });    
        });
      } else {
        context.post(`classroom/${classId}/mission/${missionId}/submit`, {
          title: submitInfo.title,
          content: submitInfo.content,
          fileList: submitInfo.fileList,
          }, (response) => {
            navigate(0);
          });
        }
      }
  }

  const profile = rootStore.getProfile?.profile;
  const name = rootStore.getProfile?.name;

  return (
    <>
    {(isEdit || submitId === -1 ) && !isClosed &&
      <>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
            <span style={{...fonts.body2SemiBold, color: `${colors.gray900}`}}>
                과제 {isEdit ? "수정" : "제출"}
            </span>
        </div>
        <div style={{marginTop: "40px", gap: "16px", display: "flex", flexDirection: "column"}}>
            <span style={{...fonts.body2SemiBold, color: colors.gray900}}>
                제목
            </span>
            <TextField
                value={submitInfo.title}
                onChange={(e) => {
                    setSubmitInfo({
                        ...submitInfo,
                        title: e.target.value,
                    });
                }}
                placeholder="제목을 입력해주세요"
                size="small"
                complete={submitInfo.title.length > 0}
            />
        </div>
        <div style={{marginTop: "40px", gap: "16px", display: "flex", flexDirection: "column"}}>
            <span style={{...fonts.body2SemiBold, color: colors.gray900}}>
                내용
            </span>
            <TextField
                type="textarea"
                value={submitInfo.content}
                onChange={(e) => {
                    setSubmitInfo({
                        ...submitInfo,
                        content: e.target.value,
                    });
                }}
                placeholder="내용을 입력해주세요"
                height="160px"
                size="medium"
                complete={submitInfo.content.length > 0}
            />
        </div>
        {(fileList.length > 0 || submitInfo.fileList.length > 0) && (
        <FileWrapper>
          {[...fileList, ...submitInfo.fileList].map((file, index) => (
            <FileItem>
              <img src={getFileImagePath(file.name)} alt='' style={{ width: "24px", height: "24px" }}/>
              <div style={{ ...fonts.label4Medium, color: colors.gray500, width: 228}}>
                {file.name.length > 40 ? file.name.slice(0, 40) + "..." : file.name}
              </div>
              <div style={{ ...fonts.label4Medium, color: colors.gray500, width: "calc(100% - 228px - 24px - 24px)", textAlign: "center" }}>
                {fileResize(file.size)}
              </div>
              <div style={{ cursor: "pointer" }} onClick={() => {
                if (index < fileList.length) {
                  setFileList(fileList.filter((_, i) => i !== index));
                } else {
                  setSubmitInfo({
                    ...submitInfo,
                    fileList: submitInfo.fileList.filter((_, i) => i !== index - fileList.length),
                  });
                }
              }}>
                <IconX width="24px" height="24px" color={colors.gray500} />
              </div>
            </FileItem>
          ))}
        </FileWrapper>
        )}
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", 
          border: `1px solid ${colors.gray100}`, borderRadius: "8px", padding: "8px", cursor: "pointer", marginTop: "16px",
          width: "48px", height: "48px"}} onClick={() => {
            inputFileRef.current.click();
          }}>
            <PlusIcon color={colors.gray200} width="24px" height="24px" />
        </div>
        <input type="file" ref={inputFileRef} style={{display: "none"}} onChange={handleFileChange} />
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: "16px", position: "absolute", bottom: 24, width: "calc(100% - 48px)"}}>
          <Button text={isEdit ? "수정하기" : "제출하기"} size="large" width="100%" height="44px" disabled={!enableSubmit} onClick={() => onSubmit(submitInfo)}/>
        </div>
        </>
      }
    {submitId !== -1 && !isEdit && 
      <>
        <div style={{marginBottom: "16px"}}>
          {getStatusChip(submitInfo.status)}
        </div>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", marginBottom: "28px"}}>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "8px"}}>
            <ImageCircle src={getFilePath(profile)} size={40} />
            <span style={{...fonts.body2SemiBold, color: colors.gray900}}>
              {name}
            </span>
          </div>
          {submitInfo.status !== "APPROVED" && !isClosed && <Button text="수정" size="small" onClick={() => {setEdit(true)}} width="55px" height="36px"/>}
        </div>
        <div style={{marginBottom: "24px"}}>
          <span style={{...fonts.body2SemiBold, color: colors.gray900}}>{submitInfo.title}</span>
        </div>
        <div style={{maxHeight: "160px", overflowY: "auto"}}>
          <pre style={{width: "100%", border: "none", outline: "none", ...fonts.label3Medium, color: colors.gray500}}>
            {submitInfo.content}
          </pre>
        </div>
        <div style={{marginBottom: "24px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", color: colors.gray500}}>
          {!submitInfo.updateTime && moment(new Date(submitInfo.createDate)).format("YYYY.MM.DD HH:mm")}
          {submitInfo.updateTime && moment(new Date(submitInfo.updateTime)).format("YYYY.MM.DD HH:mm") + " (수정됨)"}
        </div>
        <div style={{width: "100%", height: "1px", backgroundColor: colors.gray100, margin: "28px 0"}}/>
        <div style={{display: "flex", flexDirection: "column", width: "100%", gap: "8px"}}>
          {submitInfo.fileList.map((file, idx) => (
        <div
          key={idx}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "calc(100% - 274px)",
            }}
          >
            <img
              src={getFileImagePath(file.name)}
              alt={file.name}
              width={24}
              height={24}
            />
            <span
              style={{
                ...fonts.label4Medium,
                color: colors.gray900,
                marginLeft: 8,
              }}
            >
              {file.name.length > 40
                ? file.name.slice(0, 40) + "..."
                : file.name}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: 250,
            }}
          >
            <span style={{ ...fonts.label4Medium, color: colors.gray400 }}>
              {fileResize(file.size)}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: 24,
              cursor: "pointer",
            }}
            onClick={() => onDownload(file.url, file.name)}
          >
            <IconDownload color={colors.gray500} width="24px" height="24px" />
          </div>
        </div>
      ))}
        </div>
      </>
    }
    {
      isClosed && submitId === -1 &&
      <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "16px"}}>
        <img src={"/assets/image/sad_rai.png"} style={{width: "131px", height: "97px"}}/>
        <span style={{...fonts.body3Medium, color: colors.gray400}}>과제를 제출하지 않았습니다</span>
      </div>
    }
    </>
  )
};


const fileResize = (size) => {
  const dataSize = size;
  let formattedData = "";

  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

  if (dataSize === 0) return "0 Byte";

  const k = Math.floor(Math.log(dataSize) / Math.log(1024));

  formattedData =
    Math.round(100 * (dataSize / Math.pow(1024, k))) / 100 + " " + sizes[k];
  return formattedData;
};

const getStatusChip = (status: "NONE" | "SUBMITTED" | "REJECTED" | "APPROVED") => {
  switch (status) {
    case "NONE":
      return <div style={{...fonts.label4Medium, backgroundColor: colors.gray100, borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center", width: "43px", height: "24px"}}>
        <span style={{color: colors.gray500}}>대기</span>
      </div>;
    case "SUBMITTED":
      return <div style={{...fonts.label4Medium, backgroundColor: colors.gray100, borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center", width: "43px", height: "24px"}}>
        <span style={{color: colors.gray500}}>대기</span>
      </div>;
    case "REJECTED":
      return <div style={{...fonts.label4Medium, backgroundColor: colors.warning50, borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center", width: "43px", height: "24px"}}>
        <span style={{color: colors.warning}}>반려</span>
      </div>;
    case "APPROVED":
      return <div style={{...fonts.label4Medium, backgroundColor: colors.blue50, borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center", width: "43px", height: "24px"}}>
        <span style={{color: colors.success}}>완료</span>
      </div>;
  }
}

const onDownload = async (url, name) => {
  const STORAGE_BASE_URL = process.env.REACT_APP_BUCKET_URL;
  const fileUrl = `${STORAGE_BASE_URL}/${url}`;

  try {
    const response = await fetch(fileUrl, { method: "GET" });
    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = blobUrl;
    a.download = name;
    document.body.appendChild(a);
    a.click();

    // 메모리 해제
    window.URL.revokeObjectURL(blobUrl);
    document.body.removeChild(a);
  } catch (error) {
    console.error("파일 다운로드 중 오류 발생:", error);
  }
};