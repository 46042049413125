import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { Page_settings } from '../../config/page_settings';
import PaymentHeader from './header/payment_header';
import Shipment from './shipment';
import Storage from './storage';

const ManageShipmentStorage = observer((props) => {
  const context = useContext(Page_settings);
  const [isOpen, setIsOpen] = useState(false);
  // const [toggleMenu, setToggleMenu] = useState(false);
  const [mode, setMode] = useState(0);


  useEffect(() => {

  }, [isOpen])

  useEffect(() => {

    context.handleSetPageHeader(false);

    return () => {
      context.handleSetPageHeader(true);
      context.handleSetPageSidebar(true);
    }
  }, []);



  return (
    <div class="main-content bill-management-container">
      {/* <CloudContainer/> */}
      <PaymentHeader />
      {mode === 0 && 
        <Shipment
          setMode={setMode}
        />
      }

      {mode === 1 &&
        <Storage 
          setMode={setMode}
        />
      }
    </div>


  );
})


export default withRouter(ManageShipmentStorage);
