import React from "react";

const ClassSec1 = () => {
    return (
        <>
            <section className="wrap class-sec1">
                <div className="inner">
                    <div className="main-tit">
                        <h2>
                            학습관리, <br />
                            어렵고 복잡해야할까요?
                        </h2>
                    </div>
                    <div className="box-layout">
                        <div className="wid-100 box1">
                            <div className="txt-box">
                                <h6>클래스</h6>
                                <h3>
                                    학생과 학급을 <br />
                                    쉽고 간편하게 관리
                                </h3>
                                <p>
                                    학생에게 준 과제 수행률, 목표달성률 등 <br />
                                    학습데이터를 수집하여 한눈에 확인하고 <br />
                                    따라오지 못하는 학생들을 체크하여 <br />
                                    원활하게 학급을 운영할 수 있어요.
                                </p>
                            </div>
                            <div className="img-box">
                                <img src="Img/sub/class-sec1-box1.png" alt="학급 관리" />
                            </div>
                        </div>
                        <div className="wid-50 box2">
                            <div className="txt-box">
                                <h6>데이터랩</h6>
                                <h3>
                                    학생의 성장 기록을 <br />
                                    함께 확인해요
                                </h3>
                                <p>
                                    함께하는 시간이 갈수록 쌓이는 학습데이터로 <br />
                                    학생들을 쉽게 관리해요
                                </p>
                            </div>
                            <div className="img-box">
                                <img src="Img/sub/class-sec1-box2.png" alt="성장기록" />
                            </div>
                        </div>
                        <div className="wid-50 box3">
                            <div className="txt-box">
                                <h6>이상학생</h6>
                                <h3>
                                    학생들이 잘따라오지 못하면 <br />
                                    바로바로 보여줘요
                                </h3>
                                <p>과제제출이 늦거나 학습성장률이 더디면 빠르게 알려줘요</p>
                            </div>
                            <div className="img-box">
                                <img src="Img/sub/class-sec1-box3.png" alt="이상학생" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ClassSec1;