import React, { useState } from "react";

export default function StudentSearch(props) {

    return (
            <div className="class-search  align-item-center box-shadow" style={{flex:8}} >             
                <input className="input-transparent cursor" placeholder={props.placeholder} type={props.inputType} maxLength={props.maxLength} value={props.value} onChange={props.onChange} onKeyUp={props.onKeyUp} onClick={props.onClick} 
                />
                {/* <div class="email">{props.value.email}</div> */}
                <button className="common-button" >
                    {/* {props.value ? 
                    <img src={'/assets/image/btn_delete.png'} onClick={()=>test()} alt=""/>
                    :
                    <img src={'/assets/image/icon_search_black.png'} alt=""/>} */}
                    <img src={'/assets/image/icon_search_black.png'} alt=""/>
                    
                </button>
            </div>
    );
}