import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Store } from "react-notifications-component";
import { withRouter } from "react-router-dom";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";
import styled from "styled-components";
import { Page_settings } from "../../../config/page_settings";
import {
    dateForm,
} from "../../../helper/common";
import strings from "../../../lang/strings";
import DataLabModalReport from "./component/DataLabModalReport";
import DataLabModalPlanner from "./component/DataLabModalPlanner";


const DataLabModal = (props) => {
  const [periodOpen, setPeriodOpen] = useState(false);
  const context = useContext(Page_settings);
  const [period, setPeriod] = useState(-1);
  const [yearSelectOpen, setYearSelectOpen] = useState(false);
  const [yearList, setYearList] = useState([]);
  const [year, setYear] = useState(
    yearList.indexOf(moment(new Date()).year().toString() + strings._y)
  );
  const [monthList, setMonthList] = useState([]);
  const [month, setMonth] = useState(-1);
  const [dayList, setDayList] = useState([]);
  const [day, setDay] = useState(-1);

  const modeList = [{mode_name:"REPORT", mode_title: '레포트'}, {mode_name:"PLANNER", mode_title: '플래너'}];
  const [mode, setMode] = useState(modeList[0].mode_name);


  const handleClose = () => {
    props.onClose?.();
  };

  useEffect(() => {
    context.setHeaderType(2);
    context.handleSetPlannerPageHeader(true);
    getYearList();
    getMonthList();
    getDayList(moment(new Date()).daysInMonth());
    return () => {
      context.setHeaderType(1);
      context.handleSetPlannerPageHeader(false);
    };
  }, []);

  useEffect(() => {
    if (period == 1) {
      setDayList(strings.weekList);
      return;
    }
    if (year != -1 && month != -1) {
      getDayList(
        moment(
          yearList[year].split("년")[0] +
            "." +
            monthList[month].split("월")[0] +
            ".01"
        ).daysInMonth()
      );
    }
  }, [year, month]);

  const getYearList = () => {
    let now = moment(new Date()).year();
    let list = [];
    for (let i = now; i < now + 1; i++) {
      list.push(i.toString() + strings._y);
    }
    setYearList(list);
    setYear(list.indexOf(moment(new Date()).year().toString() + strings._y));
  };

  const getMonthList = () => {
    let list = [];
    let month = "";
    for (let i = 1; i < 13; i++) {
      month = dateForm(i) + strings._m;
      list.push(month);
    }
    setMonthList(list);
  };

  const getDayList = (days) => {
    let list = [];
    let day = "";
    for (let i = 1; i <= days; i++) {
      day = dateForm(i) + strings._d;
      list.push(day);
    }
    setDayList(list);
  };

  const togglePeriod = () => {
    setPeriodOpen(!periodOpen);
  };

  const toggleYear = () => {
    setYearSelectOpen(!yearSelectOpen);
  };

  const periodFix = () => {
    if (period == -1) {
      // setPeriod(0);
      setPeriod(2);
    }
    if (year == -1) {
      setYear(
        yearList.indexOf(moment(new Date()).year().toString() + strings._y)
      );
    }
    if (month == -1) {
      let mm = moment(new Date()).month() + 1;
      mm = dateForm(mm) + strings._m;
      setMonth(monthList.indexOf(mm));
    }
    if (day == -1) {
      setDay(dayList.indexOf(dateForm(moment(new Date()).date()) + strings._d));
    }
  };

  const vaildDate = (yearIdx, monthIdx, dayIdx) => {
    const yIdx =
      yearIdx == -1
        ? yearList.indexOf(moment(new Date()).year().toString() + strings._y)
        : yearIdx;
    const mIdx =
      monthIdx == -1
        ? monthList.indexOf(
            dateForm(moment(new Date()).month() + 1) + strings._m
          )
        : monthIdx;
    const dIdx =
      dayIdx == -1
        ? dayList.indexOf(dateForm(moment(new Date()).date()) + strings._d)
        : dayIdx;

    if (yIdx == undefined || mIdx == undefined || dIdx == undefined) {
      return false;
    }
    const dateStr =
      yearList[yIdx].split("년")[0] +
      "-" +
      monthList[mIdx].split("월")[0] +
      "-" +
      dayList[dIdx].split("일")[0];
    var date = moment(dateStr);
    return period == 1 ? true : date.isValid();
  };

  const addNotification = (
    notificationType,
    notificationTitle,
    notificationMessage
  ) => {
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: "top-right",
      dismiss: {
        duration: 1000,
      },
    });
  };

  return (
    <Overlay>
      <ModalWrap>
        <div className="main-content chewingtalk-report-container">
          <CloseButton
            onClick={handleClose}
            src="/assets/image/icon_close_gray.png"
          />
          <div className="d-flex m-b-10">
            <div className="flex-box">
              <div className="left">
                {modeList.map((item, idx) => (
                    <div
                    key={idx}
                    className={`${mode === item.mode_name ? "selected" : ""}`}
                    onClick={() => setMode(item.mode_name)}
                    >
                    {item.mode_title}
                  </div>
                ))}
              </div>
              <div className="right">
                <Dropdown
                  isOpen={periodOpen}
                  toggle={() => togglePeriod()}
                  className="dropdown navbar-user date-dropdown report-dropdown"
                  tag="li"
                >
                  <DropdownToggle
                    tag="a"
                    className="display-flex-important align-item-center profile-dropdown report-dropdown"
                  >
                    <div className="flex-grow-1">
                      {period === 2 ? "년도별" : "년도별"}
                    </div>
                    <img
                      src={"/assets/image/icon_arrow_down_black.png"}
                      alt=""
                    />
                  </DropdownToggle>
                  <DropdownMenu
                    className="dropdown-menu dropdown-menu-right profile-dropdown-menu"
                    tag="ul"
                  >
                    {strings.report_period.map((item, idx) => (
                      <DropdownItem
                        key={idx}
                        className="dropdown-header display-flex-important justify-content-center f-s-15"
                        onClick={() => {
                          periodFix();
                          setPeriod(item.code);
                        }}
                      >
                        {item.name}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>

                <Dropdown
                  isOpen={yearSelectOpen}
                  toggle={() => toggleYear()}
                  className="dropdown navbar-user report-dropdown"
                  tag="li"
                  style={{ width: "100px" }}
                >
                  <DropdownToggle
                    tag="a"
                    className="display-flex-important align-item-center profile-dropdown report-dropdown"
                  >
                    <div className="flex-grow-1">
                      {year != -1
                        ? yearList[year]
                        : moment(new Date()).year().toString() + strings._y}
                    </div>
                    <img
                      src={"/assets/image/icon_arrow_down_black.png"}
                      alt=""
                    />
                  </DropdownToggle>
                  <DropdownMenu
                    className="dropdown-menu dropdown-menu-right profile-dropdown-menu"
                    tag="ul"
                  >
                    {yearList.map((item, idx) => (
                      <DropdownItem
                        key={idx}
                        className="dropdown-header display-flex-important justify-content-center f-s-15"
                        onClick={() => {
                          if (!vaildDate(idx, month, day)) {
                            addNotification(
                              "warning",
                              "유효하지 않은 날짜입니다."
                            );
                            return;
                          }

                          periodFix();
                          setYear(idx);
                        }}
                      >
                        {item}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </div>
          {mode === "REPORT" && 
          <DataLabModalReport 
            member={props.member}
            class_id={props.class_id}/>}
          {mode === "PLANNER" && 
          <DataLabModalPlanner 
            member={props.member}
            class_id={props.class_id}/>}
        </div>
      </ModalWrap>
    </Overlay>
  );
};

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const ModalWrap = styled.div`
  width: 75%;
  height: 85%;
  border-radius: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
  background: #f5f9fd;
  overflow: auto;
`;

const CloseButton = styled.img`
  position: fixed;
  width: 30px;
  height: 30px;
  top: 10px;
  right: 10px;
  z-index: 9999;
  cursor: pointer;
`;

export default withRouter(DataLabModal);
