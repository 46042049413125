import React, {useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import DatePicker from "react-datepicker";
import moment from "moment";
import { Page_settings } from "../../../../config/page_settings";
import strings from "../../../../lang/strings";
import { colorList, checkIconList } from '../../../../config/const';
import { Store } from 'react-notifications-component';
import colors from '../../../../design/colors';
import fonts from '../../../../design/typography';
import { ChevronLeft, ChevronRight } from '../../../../components/icons/chevron';
import { ko } from "date-fns/locale/ko";
import 'react-datepicker/dist/react-datepicker.css';
import IconButton from '../../../../components/icon_button';
import IconMenu from '../../../../components/icons/menu';
import Checkbox from '../../../../components/checkbox';

interface PlannerPropI {
    member: number;
    classId: number;
}

interface IPlannerTag {
  id: number;
  plan: number;
  title: string;
  color: string;
  orderNum: number;
  planDate: string;
  todoList: IPlannerToDo[];
}


interface IPlannerToDo {
  id?: number;
  tag?: number;
  title: string;
  createDate: string;
  status: "DONE" | "IN_PROGRESS";
}

const CALENDAR_FONT_STYLES = {
    fontSize: fonts.label4Regular.fontSize,
    lineHeight: fonts.label4Regular.lineHeight,
    fontFamily: fonts.label4Regular.fontFamily,
};

const DataLabModalPlanner = (props: PlannerPropI) => {
    const { member, classId } = props;
    const context: any = useContext(Page_settings);
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [onCalendarMonth, setOnCalendarMonth] = useState<Date>(new Date());
    const [isFeedbackFocused, setIsFeedbackFocused] = useState(false);

    const [tagsOfMonth, setTagsOfMonth] = useState<IPlannerTag[]>([]);
    const [tagList, setTagList] = useState<IPlannerTag[]>([]);
    useEffect(() => {
        context.get(
            'plan/tag/monthly',
            {
                memberId: member,
                yearMonth: moment(onCalendarMonth).startOf('month').format('YYYY-MM')
            },
            response => {
                setTagsOfMonth(response.map((item) => ({...item, todo_list:[]})));
            }
        )
    }, [onCalendarMonth]);
    const [feedback, setFeedback] = useState('');


    useEffect(() => {
        context.get(
            'plan',
            {
                memberId: member,
                plannerDate: moment(selectedDate).format('YYYY-MM-DD')
            },
            response => {
                if(response) {
                    setFeedback(response.feedbacks.length > 0 ? response.feedbacks[0].content : '');
                } else {
                    setFeedback('');
                }
            }
        );

        context.get(
            'plan/tag',
            {
                memberId: member,
                selectedDate: moment(selectedDate).format('YYYY-MM-DD')
            },
            response => {
                setTagList(response.map((item) => {
                    return item;
                }));
            }
        );
    }, [selectedDate]);

    const saveFeedback = () => {
        context.put(
            'plan/feedback',
            {
                memberId: member,
                plannerDate: moment(selectedDate).format('YYYY-MM-DD'),
                feedback: feedback
            },
            response => {
                addNotification('success', '', strings.success_to_save)
            }
        )
    }

    const addNotification = (notificationType, notificationTitle, notificationMessage) => {
        Store.addNotification({
            title: notificationTitle,
            message: notificationMessage,
            type: notificationType,
            insert: 'top',
            container: 'top-right',
            dismiss: {
                duration: 1000,
            }
        });
    }


    return (
    <Container>
        <LeftSection>
            <StyledDatePickerWrapper>
                <DatePicker
                    inline
                    selected={selectedDate}
                    locale={ko}
                    onChange={(date: Date) => {
                        setSelectedDate(date);
                        setOnCalendarMonth(date);
                    }}
                    renderDayContents={(dayOfMonth, date) => {
                    let event = tagsOfMonth.filter((item) => item.planDate == moment(date).format('YYYY-MM-DD'));
                    return (
                        <>
                            <div style={{position: 'relative'}}>
                                {dayOfMonth}
                            {(event.length > 0  && moment(selectedDate).format('YYYY-MM-DD') !== moment(date).format('YYYY-MM-DD'))&& 
                                <div style={{position: 'absolute', top: 15, left: 0, width: '100%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                                {event.length < 3 ? (
                                    event.map((item) => {
                                    return <div style={{height: 5, width: 5, backgroundColor: item.color, borderRadius: "50%", marginLeft: 1, marginRight: 1, marginBottom: 6}}></div>
                                    })
                                ) : (
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start'}}>
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
                                        <div style={{height: 5, width: 5, backgroundColor: event[0].color, borderRadius: "50%"}} />
                                        <div style={{height: 5, width: 5, backgroundColor: event[1].color, borderRadius: "50%", marginLeft: 2}} />
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
                                        <div style={{height: 5, width: 5, backgroundColor: event[2].color, borderRadius: "50%"}} />
                                    </div>
                                    </div>
                                )}
                                </div>
                            }
                            </div>
                        </>
                    )
                }}
                renderCustomHeader={(params => {
                    return (
                        <CalendarHeader>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', padding: "0 8px"}}>
                                <span style={{ marginLeft: 4, color: colors.gray900, ...fonts.label3SemiBold }}>
                                    {(params.monthDate.getFullYear()).toString() + '년 '}
                                </span>
                                <span style={{ color: colors.gray900, ...fonts.label3SemiBold, marginLeft: 4 }}>
                                    {(params.monthDate.getMonth() + 1).toString() + '월'}
                                </span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', padding: "0 8px"}}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}} onClick={() => {
                                    setOnCalendarMonth(new Date(moment(onCalendarMonth).subtract(1, 'months').format('YYYY-MM-DD')));
                                    params.decreaseMonth();
                                }}>
                                    <ChevronLeft color={colors.gray500} width="20px" height="20px" />
                                </div>
                                
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}} onClick={() => {
                                    setOnCalendarMonth(new Date(moment(onCalendarMonth).add(1, 'months').format('YYYY-MM-DD')));
                                    params.increaseMonth();
                                }}>
                                    <ChevronRight color={colors.gray500} width="20px" height="20px" />
                                </div>
                            </div>
                        </CalendarHeader>
                    )
                })}/>
            </StyledDatePickerWrapper>
            <FeedBackContainer isFocused={isFeedbackFocused}>
                <textarea placeholder={strings.input_feedback} style={{width: '100%', height: '100%'}} 
                    onChange={(e) => setFeedback(e.target.value)} value={feedback} 
                    onBlur={() => {
                        saveFeedback();
                        setIsFeedbackFocused(false);
                    }}
                    onFocus={() => setIsFeedbackFocused(true)}
                />
            </FeedBackContainer>
        </LeftSection>
        <RightWrapper>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", padding: "24px 32px "}}>
                <span style={{...fonts.body2SemiBold, color: colors.gray900}}>{moment(selectedDate).format('YYYY MM월 DD일 dddd')}</span>                    
            </div>
        <RightWrapperContent>
          {tagList.map((item, index) => {
            return (
              <TagItem key={index}>
                <div style={{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                  <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start"}}>
                    <span style={{color: colors.white, ...fonts.label4SemiBold, backgroundColor: item.color, padding: "8px 10px", borderRadius: "4px"}}>{item.title}</span>
                  </div>
                </div>
                <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", marginTop: item.todoList.length > 0 ? "16px" : "0", gap: 20}}>
                    {item.todoList.map((todo, todoIndex) => {
                      return (
                        <div style={{width: "100%", height: "24px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", position: "relative"}} >
                          <Checkbox size="20px" checked={todo.status === "DONE"}/>
                            <div style={{width: "calc(100% - 32px)", height: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start"}}>
                              <div style={{...fonts.label3Medium, color: colors.gray800, width: "calc(100% - 8px)", height: "100%"}}>{todo.title}</div>
                            </div>
                          
                        </div>
                      )
                        })}
                  </div>
              </TagItem>
            )
          })}
        </RightWrapperContent>
      </RightWrapper>
    </Container>
    );
}

export default DataLabModalPlanner;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 8px; /* Adjust the width of the scrollbar */
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${colors.gray200}; /* Color of the scrollbar thumb */
        border-radius: 4px; /* Rounded corners for the thumb */
    }
    &::-webkit-scrollbar-track {
        background: transparent; /* Make the track invisible */
    }
`;

const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 340px;
`;

const StyledDatePickerWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 340px;
  height: calc(100% - 156px);
  border-radius: 8px;
  padding: 20px;
  background-color: ${colors.gray50};
  box-shadow: 0 1px 2px 0 rgb(0, 0, 0, 0.05);

  .react-datepicker {
    background-color: ${colors.white};
    border-radius: 8px;
    width: 100%;
    height: 100%;
    border: none;
    padding: 12px 16px;
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__month {
    margin: 8px 0 0 0 ;
  }

  .react-datepicker__week {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .react-datepicker__day-names {
    display: flex;
    justify-content: space-around;
    margin-top: 8px;
  }

  .react-datepicker__day-name {
    color: ${colors.gray500};
    ${CALENDAR_FONT_STYLES}
  }

  .react-datepicker__day {
    width: 32px;
    height: 32px;
    ${CALENDAR_FONT_STYLES}
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
    border-radius: 50%;
    position: relative;
  }

  .react-datepicker__day--selected {
    background-color: ${colors.blue500};
    color: ${colors.white};
  }

  .react-datepicker__day--today {
    font-weight: bold;
  }

  .react-datepicker__header {
    background-color: white;
    border-bottom: none;
    border-radius: 20px 20px 0 0;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: ${colors.white};
    color: ${colors.gray900};
  }

    .react-datepicker__day--outside-month {
    color: ${colors.gray400};
  }

  .react-datepicker__day:hover {
    background-color: ${colors.blue50};
    color: ${colors.blue600};
    border-radius: 50%;
  }
`;

const CalendarHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: ${colors.white};
`;

const FeedBackContainer = styled.div<{isFocused: boolean}>`
    width: 100%;
    height: 140px;
    border-radius: 8px;
    background-color: ${colors.gray50};
    padding: 20px 16px;
    margin-top: 16px;

    font-size: ${fonts.body3Regular.fontSize};
    line-height: ${fonts.body3Regular.lineHeight};
    font-family: ${fonts.body3Regular.fontFamily};

    &::placeholder {
        color: ${colors.gray500};
    }

`;

const RightWrapper = styled.div`
  width: calc(100% - 360px);
  display: flex;
  flex-direction: column;
  background-color: ${colors.gray50};
  border-radius: 8px;
  height: 486px;
`;

const RightWrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 32px 32px 32px;
  height: 473px;
  width: 100%;
  gap: 32px;
  overflow-y: auto;
`;

const TagItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  position: relative;
`;