import { IconType } from "../../type/icon_type";
import React from "react";

const IconTalk = (props: IconType) => {

    const { color, width, height, style } = props;

    return (
        <svg width={width || "18px"} height={height || "18px"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path d="M5.99998 10H6.00998M9.99998 10H10.01M14 10H14.01M5.89998 18C7.80856 18.9791 10.0041 19.2443 12.0909 18.7478C14.1777 18.2514 16.0185 17.0259 17.2817 15.2922C18.5449 13.5586 19.1474 11.4308 18.9806 9.29221C18.8137 7.15366 17.8886 5.14502 16.3718 3.62824C14.855 2.11146 12.8464 1.1863 10.7078 1.01946C8.56927 0.852628 6.44145 1.45509 4.7078 2.71829C2.97415 3.98149 1.74867 5.82236 1.2522 7.90916C0.755733 9.99596 1.02092 12.1915 1.99998 14.1L1.25222 18.7478L5.89998 18Z" stroke={color || "#73787E"} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export default IconTalk;