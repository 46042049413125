import strings from "../../lang/strings";
import { withRouter } from "react-router-dom";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import React, { useContext, useEffect, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { rootStore } from "../../mobx/store";
import { Page_settings } from "../../config/page_settings";
import { onMessageListener } from './../../helper/firebase';
import CoachListItem from "../../components/control/item/coach_list_item";
import CoachChatItem from "../../components/control/item/coach_chat_item";
import StartTestModal from "../../components/control/modal/start_test_modal";
import CoachingTestModal from "../../components/control/modal/coaching_test_modal";
import CoachingAnswerModal from "../../components/control/modal/coaching_answer_modal";
import YoutubeViewModal from "../../components/control/modal/youtube_view_modal";
import DocViewModal from "../../components/control/modal/doc_view_modal";
import CloudContainer from '../cabinet/cloud_container';
import AIChatItem from "../../components/control/item/ai_chat_item";

const AiRai = (props) => {
    const context = useContext(Page_settings);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [userId, setUserId] = useState(-1);
    const [keyword, setKeyword] = useState("");
    const [sortCode, setSortCode] = useState(0);
    const [coachList, setCoachList] = useState([]);
    const [chatList, setChatList] = useState([]);
    const [chat, setChat] = useState("");
    const [showSearch, setShowSearch] = useState(false);
    const [coachId, setCoachId] = useState(2);
    const [selectedCoach, setSelectedCoach] = useState({});
    const [fileList, setFileList] = useState([])
    const imageRef = useRef();
    const [urlList, setUrlList] = useState([]);
    const [testItem, setTestItem] = useState({});
    const [showStartTest, setShowStartTest] = useState(false);
    const [showTest, setShowTest] = useState(false);
    const [testTime, setTestTime] = useState(0);
    const [testAnswer, setTestAnswer] = useState([]);
    const [showAnswer, setShowAnswer] = useState(false);
    const [showDoc, setShowDoc] = useState(false);
    const [docUrl, setDocUrl] = useState('');
    const [showVideo, setShowVideo] = useState(false);
    const [youtubeUrl, setYoutubeUrl] = useState('');
    const [aiChatList, setAIChatList] = useState([]);


    const aiTestItem = context.aiTestItem;

    useEffect(() => {
        setUserId(rootStore.getProfile?.id ?? 0);
    }, [rootStore.getProfile]);

    useEffect(() => {
        loadCoachList("");
    }, []);

    useEffect(() => {
        if (coachId != -1) {
            if (coachId == 2) { // 국어인 경우 챗봇 채팅내역 가져옴
                getAIChatList();
            } else {
                getChatList(false);
            }
        }
    }, [coachId]);

    onMessageListener().then(payload => {
        const chatItem = JSON.parse(payload.data.custom_data);
        if (!chatItem) {
            return;
        }

        // 현재 상담중인 전문가인 경우 채팅목록에 내용 추가
        if (coachId == chatItem.clinic) {
            setChatList([chatItem, ...chatList]);

            // 전문가 목록 최근 메시지 내용 업데이트
            setCoachList(prev => prev.map((item, idx) => {
                if (item.id == chatItem.clinic) {
                    item.content = chatItem.content;
                    item.chat_date = chatItem.chat_date;
                }
                return item
            }))
        } else { // 현재 상담중이 아닌 전문가인 경우 전문가목록 내용만 업데이트(최근 문자, 시간, 읽지 않은 채팅수)
            setCoachList(prev => prev.map((item, idx) => {
                if (item.id == chatItem.clinic) {
                    item.unread_cnt = item.unread_cnt + 1;
                    item.content = chatItem.content;
                    item.chat_date = chatItem.chat_date;
                }
                return item
            }))
        }

    }).catch(err => console.log('failed: ', err));

    const loadCoachList = (keyword) => {
        context.get(
            "coach/getCoachList",
            {
                keyword: keyword
            },
            response => {
                const list = response.list;
                setCoachList(list);
                if (aiTestItem != null) {
                    context.post(
                        "coach/sendChat",
                        {
                            coach: list[0].id,
                            content: aiTestItem.name + '테스트를 보고싶어요',
                            file: ""
                        },
                        response => {
                            setCoachId(list[0].id);
                            setSelectedCoach(list[0]);
                            context.setAITestItem(null);
                        }
                    );
                }
            }
        );
    }
    const onSearch = () => {
        loadCoachList(keyword);
    };
    const getChatList = (setHistory) => {
        context.get(
            "coach/getChatList",
            {
                coach: coachId,
                page_num: 1
            },
            response => {
                setChatList(response.list)
                if (setHistory) {
                    setCoachList(prev => prev.map((item) => {
                        if (item.id === coachId) {
                            item.content = response.list[0].content
                            item.chat_date = response.list[0].create_date
                        }
                        return item
                    }))
                }

            }
        );
    }

    const getAIChatList = () => {
        context.get(
            "coach/getAIChatList",
            {
                type: 1,
                page_num: 1
            },
            response => {
                setAIChatList(response.list)
            }
        );
    }

    const onSend = () => {
        let new_chat = chat.replace(/ /g, "");
        if (coachId == 2) { // 국어인 경우 챗GPT AI 채팅
            if (chat != "" && new_chat != "") {
                console.log("gogo")
                const tempList = [...aiChatList];
                const dialogSet = aiChatList.length > 10 ? tempList.reverse().slice(-10) : tempList.reverse();

                const newChat = {
                    isChatbot: false,
                    text: chat
                }

                const newList1 = [newChat, ...aiChatList];
                setAIChatList(newList1);
                setChat("");

                context.post(
                    "coach/sendAIChat",
                    {
                        prev_data: JSON.stringify(dialogSet),
                        content: chat,
                    },
                    response => {
                        const newItem = {
                            isChatbot: true,
                            text: response.content
                        }
                        const newList2 = [newItem, ...newList1];
                        setAIChatList(newList2);
                    }
                );
            }
            return;
        }
        if (((chat != "" && new_chat != '') || fileList.length != 0) && coachId != -1) {
            if (urlList.length != 0) {
                let formData = new FormData();
                urlList.forEach((entry, index) => {
                    formData.append('file', entry);
                });
                context.post(
                    'upload/images/coach',
                    formData,
                    response => {
                        context.post(
                            "coach/sendChat",
                            {
                                coach: coachId,
                                content: chat,
                                file: response.image
                            },
                            response => {
                                setChat("");
                                setUrlList([]);
                                setFileList([]);
                                getChatList(true);
                            }
                        );
                    });
            } else {
                context.post(
                    "coach/sendChat",
                    {
                        coach: coachId,
                        content: chat,
                        file: ""
                    },
                    response => {
                        setChat("");
                        getChatList(true);
                    }
                );
            }
        }
    };

    const handleEnter = e => {
        if (e.keyCode == 13) {
            onSearch()
        }
    };

    const handleEnterChat = e => {
        if (e.keyCode == 13) {
            onSend()
        }
    };

    const onClickCoach = (index) => {
        setCoachId(coachList[index].id);
        setSelectedCoach(coachList[index]);
        setCoachList(prev => prev.map((item, idx) => {
            if (index == idx) {
                item.unread_cnt = 0
            }
            return item
        }))
    }

    const onImageChange = (e) => {
        if (e.target.files.length < 1) {
            return;
        }
        let file = e.target.files[0];
        let reader = new FileReader();

        reader.onloadend = function () {
            setFileList([...fileList, reader.result]);
            setUrlList([...urlList, file]);
        };
        reader.readAsDataURL(file);
    };

    const delFile = (idx) => {
        setFileList(preList => preList.filter((item, index) => {
            return idx != index
        }));
        setUrlList(preList => preList.filter((item, index) => {
            return idx != index
        }));
    }

    const onDownload = (item) => {
        context.download(
            "GET",
            "clinic/download",
            {
                id: item.id
            },
            response => {
            }
        );
    }

    const onStartTest = (item) => {
        setTestItem(item);
        setShowStartTest(true);
    }

    const onQuestionAnswer = (item, answer) => {
        context.post(
            "coach/answerQuestion",
            {
                chat_id: item.id,
                answer: answer
            },
            response => {
                getChatList(true);
            }
        );
    }

    const onShowDoc = (docCode) => {
        setDocUrl('uploads/aistudy/' + docCode + '.pdf');
        setShowDoc(true);
    }

    const onShowVideo = (url) => {
        setYoutubeUrl(url);
        setShowVideo(true);
    }

    const getAnswer = () => {
        const code = JSON.parse(testItem.content).code
        context.get(
            "coach/getTestAnswer",
            {
                code: code,
            },
            response => {
                const answer = response.answer;
                setTestAnswer(answer.split('#'));
                setShowAnswer(true);
            }
        );
    }

    const onSubmitAnswer = (answers) => {

        context.post(
            "coach/testComplete",
            {
                chat_id: testItem.id,
                answer: answers.join('#'),
                time: testTime
            },
            response => {
                getChatList(true);
            }
        );
    }

    return (
        <div className={"main-content" + ` component-fixed ${props.isVisible ? 'component-center' : ''}`}>
            <div className="ai-coach-bg">
                <div className="row " style={{ marginLeft: 0, marginRight: 0 , height: '103.8%', boxShadow: '-10px 0 5px -5px rgba(0, 0, 0, 0.1)'}}>
                    <div className={("chat-area ") }>
                        <div className={"chat-input-container flex" + (coachId == -1 ? " d-none" : "")}>
                            <input type='file' accept='image/*' className='hide'
                                onChange={(e) => onImageChange(e, fileList.length)}
                                ref={imageRef} />
                            <input placeholder={strings.ai_rai_chat_placeholder}
                                type={"text"}
                                value={chat} onChange={(e) => setChat(e.target.value)}
                                onKeyDown={handleEnterChat}
                            />
                            <button
                                    className={("send ") + (((chat != "" && chat.replace(/ /g, "") != "") || fileList.length != 0) && coachId != -1 ? "send-active" : "")}
                                    onClick={() => onSend()}>{strings.send}</button>
                            <div>
                                {
                                    fileList.map((item, idx) => {
                                        return (
                                            <div className="position-relative m-r-15" style={{ width: 75 }} key={idx}>
                                                <img style={{ width: 75, height: 75, borderRadius: 5 }} src={item} alt="" />
                                                <button
                                                    style={{
                                                        border: "none",
                                                        background: "none",
                                                        position: "absolute",
                                                        top: -10,
                                                        right: -20
                                                    }}
                                                    onClick={() => {
                                                        delFile(idx)
                                                    }}
                                                >
                                                    <img src={"/assets/image/btn_delete.png"} alt="" />
                                                </button>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {/* <div className="d-flex m-t-20">
                                <button className={"emoticon" + (coachId == 2 ? " d-none" : "")} onClick={() => {
                                    if (fileList.length == 0) {
                                        imageRef?.current.click()
                                    }
                                }}>
                                    <img src={"/assets/image/icon_link.png"} style={{ width: 35, height: 35 }} alt="" />
                                </button>
                                
                            </div> */}
                        </div>
                        <div className={"coach-chat-area p-t-10" + (coachId == -1 ? " d-none" : "")}>
                            {
                                coachId == 2 && aiChatList.length != 0 &&
                                aiChatList.map((item, idx) => {
                                    return (
                                        <AIChatItem
                                            mine={!item.isChatbot}
                                            key={idx}
                                            item={item}
                                        />
                                    );
                                })
                            }
                            {
                                coachId != 2 && chatList.length != 0 &&
                                chatList.map((item, idx) => {
                                    return (
                                        <CoachChatItem
                                            mine={userId == item.sender}
                                            key={idx}
                                            item={item}
                                            coach={selectedCoach}
                                            onDownload={(item) => onDownload(item)}
                                            onStartTest={(item) => onStartTest(item)}
                                            onUse={(item, use) => onQuestionAnswer(item, use)}
                                            onShowDoc={(code) => onShowDoc(code)}
                                            onShowVideo={(url) => onShowVideo(url)}
                                        />
                                    );
                                })
                            }
                        </div>
                        <div className={"chat-area-top"}>
                            <img className="profile"
                                src={"/assets/image/img_rai_body.png"}
                                alt="" />
                            <div className="name">{"AI " + (coachId == 2 ? '라이' : selectedCoach.name)}</div>
                            <div className={"function-buttons"}>
                                <button className="search" onClick={props.onClose}>
                                    <img src={"/assets/image/icon_close_gray.png"} alt="" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                showStartTest &&
                <StartTestModal
                    show={showStartTest}
                    toggle={() => setShowStartTest(!showStartTest)}
                    onStart={() => {
                        setShowTest(true);
                        setShowStartTest(false);
                    }}
                    item={testItem}
                />
            }
            {
                showTest &&
                <CoachingTestModal
                    show={showTest}
                    toggle={() => setShowTest(!showTest)}
                    onSubmit={(time) => {
                        setTestTime(time);
                        setShowTest(false);
                        getAnswer();
                    }}
                    item={JSON.parse(testItem.content).code}
                />
            }
            {
                showAnswer &&
                <CoachingAnswerModal
                    show={showAnswer}
                    toggle={() => setShowAnswer(!showAnswer)}
                    onConfirm={(wrongAnswers) => {
                        setShowAnswer(false);
                        onSubmitAnswer(wrongAnswers);
                    }}
                    answers={testAnswer}
                />
            }
            {
                showVideo &&
                <YoutubeViewModal
                    show={showVideo}
                    toggle={() => setShowVideo(!showVideo)}
                    url={youtubeUrl}
                />
            }
            {
                showDoc &&
                <DocViewModal
                    show={showDoc}
                    toggle={() => setShowDoc(!showDoc)}
                    url={docUrl}
                />
            }
        </div>
    );
}
export default withRouter(AiRai);
