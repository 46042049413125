import React from 'react';
import { withRouter } from "react-router-dom";
import axios from "axios";
import { inject, observer } from "mobx-react";
import { ReactNotifications, Store } from 'react-notifications-component';
import Lightbox from 'react-image-lightbox';
import { fetchToken, onMessageListener } from './helper/firebase';

import { Page_settings } from './config/page_settings.js';
import Sidebar from './components/sidebar/sidebar.jsx';
import Content from './components/content/content.jsx';
import Loading_bar from "./components/control/loading_bar";
import CommonModal from "./components/control/modal";
import strings from "./lang/strings";
import { API_URL, HEADER, RESULT_CODE } from "./config/const";
import HeaderWhite from "./components/header/header_white";
import EditProfileModal from "./components/control/modal/edit_profile_modal";
import SettingModal from "./components/control/modal/setting_modal";
import NotificationModal from "./components/control/modal/notification_modal";
import TermModal from "./components/control/modal/term_modal";
import TermDetailModal from "./components/control/modal/term_detail_modal";
import AccountModal from "./components/control/modal/account_modal";
import ChangePasswordModal from "./components/control/modal/change_password_modal";
import SignOutModal from "./components/control/modal/signout_modal";
import Chewingtalk from './pages/chewingtalk/chewingtalk';
import BillSendModal from './pages/payment/modal/bill_send_modal.js';

const BUCKET_URL = `https://kr.object.ncloudstorage.com/chewing-storage`;

class App extends React.Component {
    constructor(props) {
        super(props);

        this.toggleMobileSidebar = () => {
            this.setState(state => ({
                pageSidebarToggled: !state.pageSidebarToggled
            }));
        }
        this.handleSetPageSidebar = (value) => {
            this.setState({
                pageSidebar: value
            });
        }
        this.handleSetPageHeader = (value) => {
            this.setState({
                pageHeader: value
            });
        };
        this.handleSetPageHeaderFixed = (value) => {
            this.setState({
                pageHeaderFixed: value
            });
        };
        this.handleSetContent = (value) => {
            this.setState({
                content: value
            });
        };
        this.handleSetPlannerPageHeader = (value) => {
            this.setState({
                plannerPageHeader: value
            });
        };
        this.handleSetPageBoxedLayout = (value) => {
            if (value === true) {
                document.body.classList.add('boxed-layout');
            } else {
                document.body.classList.remove('boxed-layout');
            }
        }
        this.toggleLoadingBar = isLoading => {
            this.setState({ pageLoading: isLoading });
        }

        this.addNotification = (title, message, type = 'success') => {
            Store.addNotification({
                title,
                message,
                type,
                insert: 'top',
                container: 'top-right',
                /*animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],*/
                dismiss: {
                    duration: 5000,
                    // onScreen: true
                }
            });
        }
        this.showGallery = (image_list, index = 0) => {
            this.setState({
                galleryShow: true,
                galleryData: image_list,
                galleryIndex: index,
            });
        }
        this.showModal = (title, text, okBtn, cancelBtn, cb) => {
            this.setState({
                modal: {
                    show: true,
                    title: title,
                    text: text,
                    subText: '',
                    okBtn: okBtn,
                    cancelBtn: cancelBtn,
                    cb: cb
                }
            });
        }
        this.showAlert = (text, cb, okBtn = strings.modal.confirm) => {
            this.showModal(strings.modal.alert, text, okBtn, '', cb);
        }
        this.showConfirm = (text, cb, okBtn = strings.modal.ok, cancelBtn = strings.modal.no) => {
            this.showModal(strings.modal.confirm, text, okBtn, cancelBtn, cb);
        }
        this.showUserPopup = (user_id, callback) => {
            this.setState(state => ({
                member_detail: {
                    ...state.member_detail,
                    isOpen: true,
                    id: user_id,
                    callback: callback
                }
            }));
        }
        this.hideUserPopup = () => {
            this.setState({
                member_detail: {
                    isOpen: false,
                }
            });
        }
        this.getSettingList = (...name) => {
            let item = this.props.rootStore.getSetting;
            for (props of name) {
                item = item[props];
            }
            return item;
        }
        this.getSettingName = (code, ...name) => {
            let item = this.props.rootStore.getSetting;

            if (name == 'auth') {
                return item[name]?.find(it => it.code == code)?.name ?? '';
            }

            for (props of name) {
                item = item[props];
            }
            return Array.isArray(item) ? (item?.find(it => it.code == code)?.name ?? '') : item[code];
        }
        this.api = axios.create({
            baseURL: API_URL + '/app/',
            headers: {
                [HEADER.LANG]: 'ko'
            }
        })
        this.loadImage = (...url) => {
            return BUCKET_URL + "/" + url;
        }
        this.post = async (uri, params, cb, showProgress = true) => {
            await this.request('POST', uri, params, cb, showProgress);
        }
        this.get = async (uri, params, cb, showProgress = true) => {
            await this.request('GET', uri, params, cb, showProgress);
        }
        this.delete = async (uri, params, cb, showProgress = true) => {
            await this.request('DELETE', uri, params, cb, showProgress);
        }
        this.request = async (method, uri, params, cb, showProgress = true) => {
            if (showProgress) {
                this.toggleLoadingBar(true);
            }

            this.api.defaults.headers.common[HEADER.AUTH_TOKEN] = this.props.rootStore.token;
            this.api.defaults.responseType = 'json';

            try {
                let { data } = method !== 'GET' ?
                    await this.api[method.toLowerCase()](uri, params)
                    :
                    await this.api.get(uri, { params });

                if (showProgress) {
                    this.toggleLoadingBar(false);
                }

                if (Number(data.result_code) === RESULT_CODE.SUCCESS) {
                    cb?.(data.result_data);
                } else {
                    if (Number(data.result_code) === RESULT_CODE.ERROR && uri === 'auth/login' && params.login_type !== 'EMAIL') {
                        cb?.(data);
                    } else {
                        this.handleApiError(data);
                    }
                }
            } catch (err) {
                console.log(err);

                if (showProgress) {
                    this.toggleLoadingBar(false);
                }

                this.showAlert(err.message);
            }
        }
        this.download = async (method, uri, params, cb, showProgress = true) => {
            if (showProgress) {
                this.toggleLoadingBar(true);
            }

            this.api.defaults.headers.common[HEADER.AUTH_TOKEN] = this.props.rootStore.token;
            this.api.defaults.responseType = 'blob';


            try {
                let response = method !== 'GET' ?
                    await this.api[method.toLowerCase()](uri, params)
                    :
                    await this.api.get(uri, { params });

                if (showProgress) {
                    this.toggleLoadingBar(false);
                }
                const filename = decodeURIComponent(response.headers["x-suggested-filename"]);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename); //or any other extension
                document.body.appendChild(link);
                link.click();

            } catch (err) {
                console.log(err);

                if (showProgress) {
                    this.toggleLoadingBar(false);
                }

                this.showAlert(err.message);
            }
        }
        this.handleProfileModal = (value) => {
            this.setState({
                profileModal: value
            });
        }
        
        this.handleBillSendModal = (value) => {
            this.setState({
                billSendModal: value
            });
        }

        this.handleSettingModal = (value) => {
            this.setState({
                settingModal: value
            });
        }
        this.handleApiError = e => {
            if (Number(e.result_code) === RESULT_CODE.EMPTY_TOKEN ||
                Number(e.result_code) === RESULT_CODE.TOKEN_EXPIRED) {
                // this.props.history.replace('/login');
                this.props.history.replace('/landing/main');
                localStorage.clear();
                return;
            }

            this.showAlert(e.result_msg);
        }

        this.handleNotificationModal = (value) => {
            this.setState({
                notificationModal: value
            });
        }

        this.handleTermModal = (value) => {
            this.setState({
                termModal: value
            });
        }

        this.handleAccountModal = (value) => {
            this.setState({
                accountModal: value
            });
        }

        this.handleChangePasswordModal = (value) => {
            this.setState({
                changePasswordModal: value
            });
        }

        this.handleSignOutModal = (value) => {
            this.setState({
                signOutModal: value
            });
        }

        this.handleTermDetail = (value) => {
            this.setState({
                termDetailModal: value
            });
        }

        this.setTermType = (value) => {
            this.setState({
                termType: value
            });
        }

        this.setHeaderType = (value) => {
            this.setState({
                headerType: value
            });
        }
        
        this.setContentBgType = (value) => {
            this.setState({
                contentBgType: value
            });
        }

        this.setBillId = (value) => {
            this.setState({
                billId: value
            });
        }

        this.setAITestItem = (value) => {
            this.setState({
                aiTestItem: value
            });
        }


        this.state = {
            hasScroll: false,

            pageHeader: 1,
            handleSetPageHeader: this.handleSetPageHeader,

            plannerPageHeader: false,
            handleSetPlannerPageHeader: this.handleSetPlannerPageHeader,

            pageSidebar: true,
            pageSidebarToggled: false,
            pageHeaderFixed: true,
            handleSetPageHeaderFixed: this.handleSetPageHeaderFixed,
            handleSetPageSidebar: this.handleSetPageSidebar,
            toggleMobileSidebar: this.toggleMobileSidebar,
            profileModal: false,
            settingModal: false,
            handleSettingModal: this.handleSettingModal,

            content: true,
            handleSetContent: this.handleSetContent,

            termDetailModal: false,
            handleTermDetail: this.handleTermDetail,
            
            billSendModal: false,
            handleBillSendModal: this.handleBillSendModal,

            notificationModal: false,
            handleNotificationModal: this.handleNotificationModal,

            changePasswordModal: false,
            handleChangePasswordModal: this.handleChangePasswordModal,

            signOutModal: false,
            handleSignOutModal: this.handleSignOutModal,

            termModal: false,
            handleTermModal: this.handleTermModal,

            accountModal: false,
            handleAccountModal: this.handleAccountModal,

            termType: 1,
            setTermType: this.setTermType,

            headerType: 1,
            setHeaderType: this.setHeaderType,

            contentBgType: 1,
            setContentBgType: this.setContentBgType,

            billId: "",
            setBillId: this.setBillId,

            aiTestItem: null,
            setAITestItem: this.setAITestItem,

            handleSetPageBoxedLayout: this.handleSetPageBoxedLayout,

            pageLoading: false,
            toggleLoadingBar: this.toggleLoadingBar,


            modal: {
                show: false,
                title: '',
                text: '',
                subText: '',
                okBtn: '',
                cancelBtn: '',
                cb: null
            },
            showModal: this.showModal,
            showAlert: this.showAlert,
            showConfirm: this.showConfirm,
            handleProfileModal: this.handleProfileModal,
            

            addNotification: this.addNotification,

            showGallery: this.showGallery,
            galleryShow: false,
            galleryData: [],
            galleryIndex: 0,

            post: this.post,
            get: this.get,
            delete: this.delete,
            download: this.download,

            getSettingName: this.getSettingName,
            getSettingList: this.getSettingList,
            loadImage: this.loadImage,

            member_detail: {
                isOpen: false,
                id: 0,
                callback: null
            },
            showUserPopup: this.showUserPopup,
            isTokenFound: false,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll)
        this.getSettingInfo();

        // firebase
        fetchToken((tokenFound) => this.setState({ isTokenFound: tokenFound }));
        onMessageListener().then(payload => {
            
            this.setNotification({ title: payload.notification.title, body: payload.notification.body })
        }).catch(err => console.log('failed: ', err));

        // 전역 에러 핸들러 설정
        window.onerror = (message, source, lineno, colno, error) => {
            if (message.includes("you can only call it when room is writable")) {
                // 에러를 무시하고 아무 작업도 하지 않음
                console.log("Caught and ignored 'you can only call it when room is writable' error");
                return true; // 에러를 무시하고 전파하지 않음
            }
            return false; // 다른 에러는 전파
        };

        // 미처리된 Promise 거부 처리
        window.addEventListener('unhandledrejection', (event) => {
            if (event.reason.message.includes("you can only call it when room is writable")) {
                // 에러를 무시하고 아무 작업도 하지 않음
                console.log("Caught and ignored 'you can only call it when room is writable' error");
                event.preventDefault(); // 기본 동작을 막음
            }
        });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
    }

    getSettingInfo = async () => {
        await this.get(
            'auth/getAppData',
            {},
            response => {
                this.props.rootStore.saveSetting(response);
            },
            false
        );
    };

    handleScroll = () => {
        this.setState({
            hasScroll: window.scrollY > 0
        });
        let elm = document.getElementsByClassName('nvtooltip');
        for (let i = 0; i < elm.length; i++) {
            elm[i].classList.add('d-none');
        }
    }

    onModalClose = res => {
        this.setState(state => ({
            modal: { ...state.modal, show: false }
        }), () => this.state.modal.cb?.(res));
    }

    render() {
        return (
            <Page_settings.Provider value={this.state}>
                <div className={
                    'fade page-sidebar-fixed show page-container ' +
                    (this.state.pageHeaderFixed ? 'page-header-fixed ' : '') +
                    (this.state.pageSidebar ? '' : 'page-without-sidebar ') +
                    (this.state.pageSidebarToggled ? 'page-sidebar-toggled ' : '') +
                    (this.state.hasScroll ? 'has-scroll ' : '')
                }>
                    <ReactNotifications />
                    {this.state.pageHeader && <HeaderWhite type={this.state.headerType} />}
                    {this.state.pageSidebar && <Sidebar />}
                    {this.state.content && <Content bgType={this.state.contentBgType} />}
                    <CommonModal {...this.state.modal} onModalClose={this.onModalClose} />
                    <EditProfileModal show={this.state.profileModal} toggle={() => {
                        this.handleProfileModal(!this.state.profileModal)
                    }} />
                    <BillSendModal show={this.state.billSendModal} toggle={() => {
                        this.handleBillSendModal(!this.state.billSendModal)
                    }}/>
                    
                    <SettingModal show={this.state.settingModal} toggle={() => {
                        this.handleSettingModal(!this.state.settingModal)
                    }} />
                    <NotificationModal show={this.state.notificationModal} toggle={() => {
                        this.handleNotificationModal(!this.state.notificationModal)
                    }} />
                    <TermModal show={this.state.termModal} toggle={() => {
                        this.handleTermModal(!this.state.termModal)
                    }} />
                    <TermDetailModal show={this.state.termDetailModal} type={this.state.termType} toggle={() => {
                        this.handleTermDetail(!this.state.termDetailModal)
                    }} />
                    <AccountModal show={this.state.accountModal} toggle={() => {
                        this.handleAccountModal(!this.state.accountModal)
                    }} />
                    <ChangePasswordModal show={this.state.changePasswordModal} toggle={() => {
                        this.handleChangePasswordModal(!this.state.changePasswordModal)
                    }} />
                    <SignOutModal show={this.state.signOutModal} toggle={() => {
                        this.handleSignOutModal(!this.state.signOutModal)
                    }} />
                    {this.state.galleryShow &&
                        <Lightbox
                            reactModalStyle={{ overlay: { zIndex: 10000 } }}
                            mainSrc={this.state.galleryData[this.state.galleryIndex]}
                            nextSrc={this.state.galleryData[(this.state.galleryIndex + 1) % this.state.galleryData.length]}
                            prevSrc={this.state.galleryData[(this.state.galleryIndex + this.state.galleryData.length - 1) % this.state.galleryData.length]}
                            onCloseRequest={() => this.setState({
                                galleryShow: false,
                                galleryData: [],
                                galleryIndex: 0
                            })}
                            onMovePrevRequest={() =>
                                this.setState({
                                    galleryIndex: (this.state.galleryIndex + this.state.galleryData.length - 1) % this.state.galleryData.length,
                                })
                            }
                            onMoveNextRequest={() =>
                                this.setState({
                                    galleryIndex: (this.state.galleryIndex + 1) % this.state.galleryData.length,
                                })
                            }
                        />
                    }
                    {this.state.pageLoading && <Loading_bar />}
                </div>
            </Page_settings.Provider>
        )
    }
}

export default withRouter(inject('rootStore')(observer(App)));
