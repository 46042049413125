import React, { useState, useRef, useContext, useEffect } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import styled from "styled-components";
import strings from "../../../lang/strings";
import OrgaSearch from "../customSearch/orgaSearch";
import ClassSearch from "../customSearch/classSearch";
import { Page_settings } from "../../../config/page_settings";
import PerfectScrollbar from "react-perfect-scrollbar";
import Form from 'react-bootstrap/Form';
import { rootStore } from '../../../mobx/store';


function LateNotificationModal({ onClose }) {
  const context = useContext(Page_settings);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };


  useEffect(() => {

  }, []);

  const offAlarm = () => {
    context.get(
      'member/offAlarm',
      {
      },
      response => {
        context.get(
            'member/getProfile',
            {
                id: 0
            },
            response => {
                console.log(response.user_info);
                console.log(response.user_info.late_alarm);
                rootStore.setProfile(response.user_info);
                context.handleProfileModal(false);
            }
        );
    });
  }



  const handleClose = () => {
    onClose?.();
    if(isChecked){
      offAlarm();
    }
    
  };


  return (
    <Overlay>
      <ModalWrap>
        <div className="late-notification-modal">
          <div className="top-content">
            <div className="title">지각알림이란?</div>
            <div className="subtitle">학생들이 등원/등교하지 않았을 때 <span className="highlight">‘OOO학생이 아직 입실하지 않았습니다.’</span>라는 <br></br>
              메세지가 학생/학부모에게 발송되는 기능입니다. 이 기능을 통해 학생들의 지각을 사전에 방지해보세요!</div>
          </div>
          <div className="middle-content">
            <img src="/assets/image/img_late_notification_guide.png" alt="" />
          </div>
          <div className="bottom-content">
            <div className="close-btn" onClick={() => handleClose()}>닫기</div>
            <div className="checkbox-box">
              <input
                type="checkbox"
                className="all-check"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <div className="text">더 이상 그만보기</div>
            </div>
          </div>
        </div>
      </ModalWrap>
    </Overlay>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const ModalWrap = styled.div`
  width: 1400px;
  height: 800px;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
`;

const CloseButton = styled.div`
  float: right;
  width: 40px;
  height: 40px;
  margin: 20px;
  cursor: pointer;
  i {
    color: #5d5d5d;
    font-size: 30px;
  }
`;

const Contents = styled.div`
  margin: 50px 30px;
  h1 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 60px;
  }
  img {
    margin-top: 60px;
    width: 300px;
  }
`;
const Button = styled.button`
  cursor: pointer;
  width: 100%;
  color: #ffffff;
  border-radius: 5px;
  border:0px;
  padding: 10px;
  margin-top: 20px;
`;
export default LateNotificationModal;