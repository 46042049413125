import React, {useState} from "react";
import StudyBottomButton from "../../../components/control/button/study_bottom_button";
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import {reactionList} from "../../../config/const";
import styled from "styled-components";
import { rootStore } from "../../../mobx/store";

interface IBottomContainerProps {
    showChat: boolean;
    showParticipant: boolean;
    participants: number;
    showPlanner: boolean;
    onMaterial: () => void;
    onOut: () => void;
    onClickReaction: (item: any) => void;
    onSetting: () => void;
    onChat: () => void;
    onParticipant: () => void;
    onPlanner: () => void;
    newChat: boolean;
}


const BottomContainer = (props: IBottomContainerProps) => {
    const {
        showChat, 
        showParticipant,
        participants, 
        showPlanner, 
        onMaterial,
        onOut,
        onClickReaction,
        onSetting,
        onChat,
        onParticipant,
        onPlanner, 
        newChat, 
        
        } = props;

    const [showReaction, setShowReaction] = useState(false);

    const onReaction = () => {
        setShowReaction(true)
    }

    const toggleReaction = () => {
        setShowReaction(!showReaction);
    }
    return (
        <StudyRoomBottomContainer>
            <div className="flex-grow-1"/>
            <StudyBottomButton
                image={"/assets/image/btn_cloud.png"}
                onClick={() => onMaterial()}
            />
            <div className="position-relative">
                <Dropdown isOpen={showReaction} toggle={() => toggleReaction()}>
                    <DropdownToggle className="display-none"/>
                    <DropdownMenu className="media-list dropdown-menu reaction-container" tag="ul">
                        {
                            reactionList.map((item, idx) => {
                                return (
                                    <button className="common-button reaction-button" key={idx} onClick={() => {
                                        onClickReaction(item);
                                        setShowReaction(false);
                                    }}>
                                        <img src={item.icon} alt=""/>
                                    </button>
                                )
                            })
                        }
                    </DropdownMenu>
                </Dropdown>
                <StudyBottomButton
                    image={"/assets/image/btn_emoji.png"}
                    onClick={() => onReaction()}
                />
            </div>
            <StudyBottomButton
                image={"/assets/image/btn_settings.png"}
                onClick={() => onSetting()}
            />
            <StudyBottomButton
                image={"/assets/image/btn_leave.png"}
                onClick={() => onOut()}
            />
            <div className="flex-grow-1"/>
            <div className="position-relative">
                <StudyBottomButton
                    image={`/assets/image/icon_chat${(showChat ? '_white' : '')}.png`}
                    newchat={newChat}
                    onClick={() => onChat()}
                />
                <StudyBottomButton
                    image={`/assets/image/icon_user_${(showParticipant ? 'white2' : 'gray_big')}.png`}
                    badge={participants}
                    onClick={() => onParticipant()}
                />
                { (rootStore.getProfile.user_type === "STUDENT") && <StudyBottomButton
                    image={`/assets/image/icon_calendar${(showPlanner ? '_white' : '')}.png`}
                    onClick={() => onPlanner()}
                />}
            </div>
        </StudyRoomBottomContainer>
    );
}

export default BottomContainer;

const StudyRoomBottomContainer = styled.div`
    width: 100%;
    height: 75px;
    position: absolute;
    bottom: 0;
    background: #353443;
    border-top: black 1px solid;
    left: 0;
    display: flex;
    align-items: center;
    padding-left: 30px;
    `;
