import { withRouter } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import { rootStore } from "../../mobx/store";
import { Page_settings } from "../../config/page_settings";
import CreateCurriculumStep0Modal from "./modal/create_curriculum_step0_modal";
import { useLocation } from 'react-router-dom';
import FinishCreateCurriculumModal from "./modal/finish_create_curriculum_modal";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import DeleteCurriculumModal from "./modal/delete_curriculum_modal";


const CreateCurriculumLobby = (props) => {
    const context = useContext(Page_settings);
    const [openCreateRoadMap, setOpenCreateRoadMap] = useState(false);
    const [finishCreate,setFinishCreate] = useState(false)
    const location = useLocation();
    const history = useHistory();
    const [curriculum,setCurriculum] = useState([]);
    const [curriculumId,setCurriculumId] = useState();
    const [selectedIndex,setSelectedIndex] = useState();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    
    const optionRefs = useRef([]);

    useEffect(()=>{
      loadCurriculumList();
    }, [openDeleteModal]);

    useEffect(() => {
      
      if (location.state && location.state.showAlert) {
        setFinishCreate(true)
      }
      
    }, [location]);

    const clearSessionStorage = () => {
      sessionStorage.removeItem('curriculum');
      sessionStorage.removeItem('data');
    };


// 옵션 박스 외에 다른곳을 클릭하면 꺼지게
useEffect(() => {
  const handleClickOutside = (event) => {
    if (
      optionRefs.current.every(
        (ref) => ref && !ref.contains(event.target)
      )
    ) {
      setSelectedIndex(null);
    }
  };

  document.addEventListener('mousedown', handleClickOutside);

  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, [optionRefs]);

const loadCurriculumList = () => {
  context.get(
    'curriculum/getCurriculumList',
    {  
    },
    response => {
      console.log("response.list",response.list)
      setCurriculum(response.list)
    }
  );
}

    const openCreateRoadmapModal = () => {
        setOpenCreateRoadMap(true)
      }

      const clickCurriculum = (id) => {
        history.push(`/curriculum/teacher_curriculum_info/${id}`);
      };

      const clickOptions = (index) => {
        setSelectedIndex(index === selectedIndex ? null : index);
      };
    
      const clickDelete = () => {
        setSelectedIndex(null);
        setOpenDeleteModal(true)
      }

      const clickUpdate = (id) => {
        setSelectedIndex(null);
        history.push(`/curriculum/edit_curriculum/${id}/1`);
      }
        


    return(
    <div class="main-content create-curriculum-lobby-container">
      
      <div class="create-curriculum-header">
        <div class="title">
          맞춤 커리큘럼 제작
        </div>
        <div class="create-btn" onClick={()=>openCreateRoadmapModal()}>
          <div>+ 커리큘럼 만들기</div>
        </div>
      </div>

      {finishCreate && (<FinishCreateCurriculumModal
          open={finishCreate}
          onClose={() => {
            setFinishCreate(false);
          }}
          
        />)}

      {openDeleteModal && (<DeleteCurriculumModal
          open={openDeleteModal}
          onClose={() => {
            setOpenDeleteModal(false);
          }}
          id={curriculumId}
        />)}

      {openCreateRoadMap && (<CreateCurriculumStep0Modal
          open={openCreateRoadMap}
          onClose={() => {
            setOpenCreateRoadMap(false);
          }}
          
        />)}
      

        <div class="content-container">
          {curriculum.map((item,index)=> (
             <div className="content-box">
             <div className="content-header">
                 <div className="left">
                 <div className={`category blue ${item.color}`}/>
                 <div className="title">{item.title}</div>
                 </div>
                 <img className="cursor options" src="/assets/image/icon_3_dots_black.png" alt="" onClick={()=>clickOptions(index)}/>
                 {selectedIndex === index && (
              <div
                className="option-box"
                ref={(el) => (optionRefs.current[index] = el)}
                onClick={()=> setCurriculumId(item.id)}
              >
                <div className="edit cursor" onClick={()=> clickUpdate(item.id)}>수정하기</div>
                <div className="delete cursor" onClick={()=> clickDelete()} >삭제하기</div>
              </div>
            )}
             </div>
            
            {
            item.create_status === 0 && item.thumbnail === null ? 
            <div class="incomplete-curriculum" onClick={()=>clickUpdate(item.id)}>
            <img src="/assets/image/img_curriculum_default_thumbnail.png" alt="" />
            <div class="overlay-text">제작중</div>
          </div>
            :
            item.create_status === 0 && item.thumbnail !== null ? 
            <div class="incomplete-curriculum" onClick={()=>clickUpdate(item.id)}>
              <img src={context.loadImage(item.thumbnail)} alt="" />
              <div class="overlay-text">제작중</div>
            </div>
            : 
            item.thumbnail != null ? 
            <div className="complete-curriculum">
              <img className="thumbnail"  src={ context.loadImage(item.thumbnail)} alt="" onClick={()=>clickCurriculum(item.id)}/>
              </div>
            :
            <div className="complete-curriculum">
            <img className="thumbnail"  src="/assets/image/img_curriculum_default_thumbnail.png" alt="" onClick={()=>clickCurriculum(item.id)}/>
            </div>
              
            }
             
         </div>
          ))}
           
           
        </div>
        

      {/* <div class="null-data create-btn">
          <div>+ 커리큘럼 만들기</div>
        </div> */}
      </div>    
    );




    
    
}
export default withRouter(CreateCurriculumLobby);
