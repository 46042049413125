import React, { useContext, useEffect, useRef, useState } from "react";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withRouter } from "react-router-dom";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import styled from "styled-components";
import { Page_settings } from "../../../config/page_settings";
import SaveModal from "../modal/save_modal";
import EditCurriculumStep4Modal from "./edit_curriculum_step4_modal";
import EditThumbnailModal from "./edit_thumbnail_modal";

const EditCurriculumStep4 = (props) => {
  const history = useHistory();
  const context = useContext(Page_settings);
  const [openCurriculumModal, setOpenCurriculumModal] = useState(false);
  const [titleValue, setTitleValue] = useState('');
  const [partTitleValue, setPartTitleValue] = useState('');
  const [categoryColor, setCategoryColor] = useState('yellow'); //카테고리 색상값
  const [categorySubject, setCategorySubject] = useState(); //카테고리 과목
  const [partCategory, setPartCategory] = useState([]); // 대단원 항목 데이터
  const [selectedPart, setSelectedPart] = useState();
  const [data, setData] = useState({});
  const [clickPart, setClickPart] = useState();
  const [clickPartIdx, setClickPartIdx] = useState(0);
  const [clickSection, setClickSection] = useState();
  const [clickSectionIdx, setClickSectionIdx] = useState();
  const [clickChapterIdx, setClickChapterIdx] = useState();
  const [clickChapter, setClickChapter] = useState();
  const [clickUnit, setClickUnit] = useState();
  const [clickUnitIdx,setClickUnitIdx] = useState(0);
  const [modalData, setModalData] = useState([]);
  const [warningPoint, setWarningPoint] = useState(false);
  const [roadmapData, setRoadmapData] = useState(props?.curriculumData?.thumbnail);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [openGuide, setOpenGuide] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const scrollRef = useRef();
  const mouseCoords = useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0
  });
  
  
  const [editStatus, setEditStatus] = useState(false);

  const thumbnail = props?.curriculumData?.thumbnail;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.guide-img')) {
        setOpenGuide(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  useEffect(() => {
    if (props.curriculumData && props.curriculumData.parts) {
     
      const formattedParts = props.curriculumData.parts.map(part => ({
        id: part.id ? part.id : null,
        subject: part.subject,
        color: part.color,
        name: part.name,
        chapters: part.chapters || []
      }));
      setPartCategory(formattedParts);
    }
    if (props.curriculumData && props?.curriculumData?.thumbnail) {
      const formattedThumbnail = props?.curriculumData?.thumbnail;
      // setPartCategory(formattedParts);
      setRoadmapData(formattedThumbnail)
    }
  }, [props.curriculumData]);


  useEffect(() => {

    if (sessionStorage.getItem('step3')) {
      const step3modal = JSON.parse(sessionStorage.getItem('step3'));
      setModalData(step3modal);
    }

  }, [openCurriculumModal]);





  const addPart = (subject, color, title) => {
    setData(prevData => ({
      ...prevData,
      [selectedPart]: [...(prevData[selectedPart] || []), partTitleValue]
    }));

    setPartTitleValue('');
    setCategorySubject('')
    setCategoryColor('yellow')
    
  }


  const handleCategoryClick = (item,index) => {
    setSelectedPart(item);
    setClickPartIdx(index)
    setCategoryColor(item.color);
    setCategorySubject(item.subject)
  };


  const handleKeyDown = (e) => {

    if (e.key === 'Enter') {
      addPart(categorySubject, categoryColor, partTitleValue)
    }
  };

  const onNext = async () => {
    // setWarningModal(true);
    // props.updatePartCategory(filteredPartCategory);
    history.push(`/curriculum/edit_curriculum/${props.curriculum}/5`);
  }


  const temporaryStorage = async () => {
    try {
      
      const curriculumImgFile = roadmapData?.checkBasicImg ? null : roadmapData?.imgFile;
      const curriculumImgName = roadmapData?.checkBasicImg ? null : roadmapData?.imgName;
      const curriculumThumbNail = roadmapData?.checkBasicImg ? null : roadmapData?.thumbnail;

      
      // roadmapData에서 imgfile 키를 제거
      const { imgFile, ...updatedRoadmapData } = roadmapData;
  
      if (curriculumImgFile && curriculumImgName) {
        const roadmapImgUrl = await roadMapImg(curriculumImgFile, curriculumImgName);
        console.log("1",roadmapImgUrl)
        context.post(
          'curriculum/editCurriculum',
          {  
            curriculum: {
              ...updatedRoadmapData,
              thumbnail: roadmapImgUrl // 업로드된 이미지 URL을 roadmapData에 추가
            },
            curriculum_child:partCategory,
            mode:0
          },
          response => {
            setSaveModal(true);
            
          }
        );
      }else if(curriculumThumbNail){ 
         // 이미지 파일이 없는 경우에도 데이터 전송
         console.log("2",curriculumThumbNail)
         context.post(
          'curriculum/editCurriculum',
          {  
            curriculum: {
              ...updatedRoadmapData,
              thumbnail: curriculumThumbNail
            },
            curriculum_child:partCategory,
            mode:0
          },
          response => {
            setSaveModal(true);
            
          }
        );
      }else {
        // 이미지 파일이 없는 경우에도 데이터 전송
        context.post(
          'curriculum/editCurriculum',
          {  
            curriculum: {
              ...updatedRoadmapData,
              thumbnail: null // 업로드된 이미지 URL을 roadmapData에 추가
            },
            curriculum_child:partCategory,
            mode:0
          },
          response => {
            setSaveModal(true);
            
          }
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const roadMapImg = (imgFile, imgName) => {
    return new Promise((resolve, reject) => {
      const arr = imgFile.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
  
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      const url = new File([u8arr], imgName, { type: mime });
  
      let formData = new FormData();
      formData.append('file', url);
  
      context.post(
        'upload/image/thumbnail',
        formData,
        response => {
          if (response.image) {
            resolve(response.image);
          } else {
            reject(new Error("Image upload failed"));
          }
        },
        error => {
          reject(error);
        }
      );
    });
  };



  const openModal = (part,chapter,chapterIdx,section,sectionIdx,unit,unitIdx) => {

    setClickPart(part)
    setClickSection(section)
    setClickSectionIdx(sectionIdx)
    setClickChapterIdx(chapterIdx)
    setClickChapter(chapter)
    setClickUnit(unit)
    setClickUnitIdx(unitIdx)
    
    setOpenCurriculumModal(true)
  }



  const handleDragStart = (e) => {
    const startX = e.pageX - scrollRef.current._container.offsetLeft;
    const startY = e.pageY - scrollRef.current._container.offsetTop;
    const scrollLeft = scrollRef.current._container.scrollLeft;
    const scrollTop = scrollRef.current._container.scrollTop;
    mouseCoords.current = { startX, startY, scrollLeft, scrollTop }
    setIsMouseDown(true);
  }

  const handleDragEnd = (e) => {
    setIsMouseDown(false);
  }

  const handleDrag = (e) => {
    if (!isMouseDown) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current._container.offsetLeft;
    const y = e.pageY - scrollRef.current._container.offsetTop;
    const walkX = x - mouseCoords.current.startX;
    const walkY = y - mouseCoords.current.startY;
    scrollRef.current._container.scrollLeft = mouseCoords.current.scrollLeft - walkX;
    scrollRef.current._container.scrollTop = mouseCoords.current.scrollTop - walkY;
  }

  const EnableConfirm = () => {
    const areAllSectionsPresent = partCategory.every(part =>
      part.chapters.every(chapter =>
        chapter.sections.every(section =>
          section.units.every(unit =>
            (unit.link || (unit?.video_url && unit?.video_url.length > 0)) || ((unit.commentary_url && unit.commentary_url.length > 0)  && (unit.test_url&&unit.test_url.length > 0))
          )
        )
      )
    );
    return areAllSectionsPresent;
  }

  const unitConfirm = (unit) => {
    if((unit?.link|| (unit?.video_url && unit?.video_url.length > 0)) || ((unit.test_url&&unit.test_url.length > 0) && (unit.commentary_url && unit.commentary_url.length > 0))){
      return true;
    }else{
      return false;
    }
  }
  const goBack = () => {
  history.push(`/curriculum/edit_curriculum/${props.curriculum}/3`);
 }


  return (
    <div class="main-content create-curriculum-step4-container">
      {openCurriculumModal && (<EditCurriculumStep4Modal
        open={openCurriculumModal}
        onClose={() => {
          setOpenCurriculumModal(false);
        }}
        clickPart={clickPart}
        clickPartIdx={clickPartIdx}
        clickChapter={clickChapter}
        clickChapterIdx={clickChapterIdx}
        clickSection={clickSection}
        clickSectionIdx={clickSectionIdx}
        clickUnit={clickUnit}
        clickUnitIdx={clickUnitIdx}
        curriculumData={props.curriculumData}
        updatePartCategory={props.updatePartCategory}
        
      />)}
              

              {editStatus && (<EditThumbnailModal
          open={editStatus}
          onClose={() => {
            setEditStatus(false);
          }}
          curriculumData={props.curriculumData}
          updateThumbnail={props.updateThumbnail}
        />)} 


{saveModal && (<SaveModal
        open={saveModal}
        onClose={() => {
          setSaveModal(false);
        }}
      />
      )}
      <div class="top content-container">
        <div class="progress-container">
          <div class="progress-box">
            <div class="progress" />
          </div>
          <div class="step"><span>4</span> / 5</div>
        </div>
        <div class="flex-box">
          <div className="title-box">
          <img className="cursor" src="/assets/image/icon_arrow_back_black.png" alt="" onClick={()=>goBack()}/>
            <div className="title">
              {thumbnail?.title}
            </div>
            <img className="cursor" src="/assets/image/icon_edit_btn_gray.png" alt="" onClick={()=>setEditStatus(true)}/>
          </div>
          <div className="curriculum-guide-box">
            {/* <img src="/assets/image/icon_warning_point.png" alt="" onClick={()=>setWarningPoint(true)}/> */}
            <img src="/assets/image/icon_curriculum_guide.png" style={{width:'65px'}} alt="" onClick={()=>setOpenGuide(true)}/>
            {openGuide &&
            <img className="guide-img" src="/assets/image/img_curriculum_guide_4.png" alt="" />
    }
          </div>
        </div>

      </div>

      <div className="middle content-container">
        <div className="flex-box">
        <div className="title">콘텐츠 추가하기</div>
        {/* <div className="right">
          <div className="category-box">
            <div className={`category ${partCategory[clickPartIdx]?.color}`}></div>
            <div>콘텐츠 업로드 완료</div>
            </div>
            <div className="category-box">
            <div className={`category`}></div>
            <div>콘텐츠 업로드 필요</div>
            </div>
        </div> */}
        </div>
        {partCategory.length > 0 &&
          <div className="row-box">
            {partCategory.map((item, index) => (
              <div
                key={index}
                className={`partCategory-box ${clickPartIdx === index ? item?.color : ''}`}
                onClick={() => handleCategoryClick(item,index)}
              >
                <div>{item.name}</div>
              </div>
            ))}
          </div>
        }
      </div>

      <div className="bottom content-container">
        <div className="container-top">
  <PerfectScrollbar className="section-unit-container" ref={scrollRef} style={{ height: '100%', cursor: "grab" }} onMouseDown={handleDragStart} onMouseUp={handleDragEnd} onMouseMove={handleDrag}>
{
  (clickPartIdx !== undefined && partCategory[clickPartIdx]?.chapters?.length > 0) ? (
    partCategory.map((part, partIdx) => (
      partIdx === clickPartIdx ? (
        part.chapters.map((chapter, chapterIdx) => (
          <div className="row-box" key={chapterIdx}>
            <div className="chapter">{chapter?.name}</div>
            {chapter.sections && chapter.sections.map((section, sectionIdx) => (
              <div className="section-container" key={sectionIdx}>
                <div className="section-box">
                  <div className="section-title">{section.name}</div>
                  <div className={`unit-box-outside ${part?.color}`}>
                  {section.units && section.units.map((unit, unitIdx) => (
                      <div
                        className={`unit-box-inside`}
                        key={unitIdx}
                        onClick={() => openModal(part, chapter.name, chapterIdx, section, sectionIdx, unit, unitIdx)}
                      >
                        {unit.name}
                        <div className={`check ${part?.color}`}>
                          {unitConfirm(unit) ? <img src={`/assets/image/icon_check_fill_${part?.color}.png`} alt=""></img> :null}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))
      ) : null
    ))
  ) : null
}
</PerfectScrollbar>





        </div>
        <div className="container-bottom">
        <button className="save-btn" onClick={() => temporaryStorage()}>임시저장</button>
        <button className="next-btn" style={{ backgroundColor: EnableConfirm() ? '#0F84F4' : '#DFE3E8' }} disabled={!EnableConfirm()} onClick={() => onNext()}>다음으로</button>
        </div>
      </div >


    </div >
  );






}

const BalloonContainer = styled.div`
  position: relative;
  background: #fff;
  border-radius: 10px;
  padding: 30px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  margin: 20px;
  position: absolute;
  top: 20px;
  right: 40px;
  z-index: 9999;
  width: 350px;
  
  &:before {
    content: '';
    position: absolute;
    top: 0px;
    right: -8px;
    width: 10px;
    height: 10px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #fff;
  }
`;

const CloseButton = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const BalloonImage = styled.img`
  display: block;
  margin: 0 auto 10px;
`;

const BalloonText = styled.div`
  text-align: center;
  color: #71768C;

font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 25px; /* 156.25% */
`;
export default withRouter(EditCurriculumStep4);
