import { useNavigate } from "react-router-dom";
import React, { useContext, useState } from "react";
import colors from '../../design/colors';
import fonts from '../../design/typography';
import styled from "styled-components";
import TextField from "../../components/text_field";
import Button from "../../components/button";
import { Page_settings } from "../../config/page_settings";
import { rootStore } from "../../mobx/store";

const LoginEmail = () => {
    const context: any = useContext(Page_settings);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();


    const onLogin = () => {
        if (email == "" || password == "") {
            return;
        }

        context.post('auth/login', {
            email: email,
            password: password,
            loginType: "EMAIL"
        }, response => {
            rootStore.signIn(response, "EMAIL");
            context.get('member/info', {}, response => {
                rootStore.setProfile(response);
            });
            navigate("/class");
        });


    }

    const onSignUp = () => {
        rootStore.setSignUpInfo({
            loginType: "EMAIL"
        });
        navigate("/signup/0");
    }

    return (
        <div style={{ width: "100%", height: "100vh", display: "flex", flexDirection: "column", backgroundColor: colors.white, alignItems: "center" }}>
            <div style={{width: "384px"}}>
                <TitleWrapper>
                    <Title>로그인</Title>
                </TitleWrapper>
                <div style={{marginBottom: 16}}>
                    <span style={{...fonts.body3SemiBold, color: colors.gray900}}>{"이메일"}</span>
                </div>
                <TextField
                    placeholder={"이메일을 입력해주세요"}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    complete={email != ""}
                />
                <div style={{marginBottom: 16, marginTop: 40}}>
                    <span style={{...fonts.body3SemiBold, color: colors.gray900}}>{"비밀번호"}</span>
                </div>
                <div style={{position: "relative", marginBottom: 56}}>
                    <TextField
                        type={showPassword ? "text" : "password"}
                        placeholder={"비밀번호를 입력해주세요"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        complete={password != ""}
                    />
                    {
                        password != "" &&
                        <Eye onMouseDown={() => setShowPassword(true)} onMouseUp={() => setShowPassword(false)}
                            onTouchStart={() => setShowPassword(true)} onTouchEnd={() => setShowPassword(false)}>
                            <img src={"/assets/image/icon_eye.png"} alt="" />
                        </Eye>
                    }
                    <div style={{position: "absolute", right: 10, top: 70, bottom: 0, display: "flex", alignItems: "center", justifyContent: "center"}} onClick={() => navigate('/find/1')}>
                        <span style={{...fonts.detail2Regular, color: colors.gray900, cursor: "pointer"}}>{"비밀번호 찾기"}</span>
                    </div>
                </div>
                <Button
                    text={"완료"}
                    onClick={() => {
                        onLogin();
                        navigate("/class");
                    }}
                    style={{marginTop: 81}}
                    size={"large"}
                    width={"100%"}
                    height={"48px"}
                    disabled={email == "" || password == "" || password.length < 8}
                />
                <div style={{marginTop: 24, display: "flex", alignItems: "center", justifyContent: "center"}} onClick={onSignUp}>
                    <span style={{...fonts.body3SemiBold, color: colors.gray900, textDecoration: "underline", cursor: "pointer"}}>{"이메일로 가입하기"}</span>
                </div>
            </div>
        </div>
    );
};

export default LoginEmail;

const TitleWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 80px;
`;

const Title = styled.span`
    font-size: ${fonts.heading1Bold.fontSize};
    font-family: ${fonts.heading1Bold.fontFamily};
    line-height: ${fonts.heading1Bold.lineHeight};
    color: ${colors.black};
    margin-bottom: 44px;
`;

const Eye = styled.button`
    position: absolute;
    right: 20px;
    top: 16px;
    border: none;
    background: none;
`;