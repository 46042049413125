import React, {useContext} from 'react';
import {Modal, ModalBody} from "reactstrap";
import strings from "../../../lang/strings";
import './modal.scss'
import {Page_settings} from "../../../config/page_settings";
import {rootStore} from "../../../mobx/store";
import {inject} from "mobx-react";
import {withRouter} from "react-router-dom";

const SignOutModal = (props) => {
    const context = useContext(Page_settings);
    const {show, toggle} = props;


    const onDeleteAccount = () => {
        context.post(
            "member/deleteAccount",
        );
        context.handleSignOutModal(false);
        rootStore.signOut();
        props.history.push('/login');
    }

    return (
        <Modal isOpen={show} toggle={() => toggle()} className="account-info-modal">
            <ModalBody className='p-30'>
                <div className='d-flex m-b-40'>
                    <div className='f-s-20 f-w-600'>{strings.sign_out}</div>
                    <div className='flex-grow-1'/>
                    <button className='modal-close-btn' onClick={() => toggle()}>
                        <img src={"/assets/image/icon_close_black.png"} alt="" className="modal-close"/>
                    </button>
                </div>
                <div className="sign-out-warning m-b-35">{strings.sign_out_warning}</div>
                <button onClick={() => onDeleteAccount()}
                        className="tag-create-btn-disable delete-account">{strings.delete_account}</button>

            </ModalBody>
        </Modal>

    );
}

export default withRouter(inject('rootStore')(SignOutModal));
