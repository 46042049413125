import React from 'react';
import {Modal, ModalBody} from "reactstrap";
import './modal.scss'
import strings from "../../../lang/strings";

const TodoModal = (props) => {
    const {show, close, todoIdx, toggle, onChange, value, onConfirm} = props;
    return (
        <Modal isOpen={show} toggle={() => toggle()}>
            <ModalBody>
                <div className='d-flex'>
                    <div className='f-s-20 f-w-600'>{todoIdx < 0 ? strings.add_todo : strings.edit_todo}</div>
                    <div className='flex-grow-1'/>
                    <button className='modal-close-btn' onClick={() => close()}>
                        <img src={"/assets/image/icon_close_black.png"} alt="" className="modal-close"/>
                    </button>
                </div>
                <div className='tag-input'>
                    <input
                        className='form-control'
                        style={{border: 'none', outline: 'none', fontSize: 15, height: "100%", boxShadow: 'none'}}
                        onChange={(e) => {
                            onChange(e.target.value)
                        }}
                        value={value}/>
                </div>
                <div className='d-flex'>
                    <div className='flex-grow-1'/>
                    <div style={{color: '#d6d6d6'}}>{strings.color_random}</div>
                </div>
                <button className={("tag-create-btn-disable ") + (value != "" ? "tag-create-btn" : "")} onClick={() => onConfirm()}>
                    {todoIdx < 0 ? strings.create : strings.modify}
                </button>
            </ModalBody>
        </Modal>

    );
}

export default TodoModal;
