import React, { useContext, useEffect, useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from "styled-components";
import strings from "../../../lang/strings";
import { Page_settings } from '../../../config/page_settings';
import { rootStore } from "../../../mobx/store";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';

function AddChapterUnitModal(props) {
  const history = useHistory();
  const [wholeTextArray, setWholeTextArray] = useState([]);
  const [classValue, setClassValue] = useState('')
  const [sectionValue, setSectionValue] = useState('')
  const [unitValue, setUnitValue] = useState('')
  const [isHaveInputValue, setIsHaveInputValue] = useState(false)
  const [clickInput, setClickInput] = useState(false)
  const [dropDownList, setDropDownList] = useState(wholeTextArray)
  const [profile, setProfile] = useState("");
  const [chapterData, setChapterData] = useState([]);
  const [unitData, setUnitData] = useState([]);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const scrollRef = useRef();
  const mouseCoords = useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0
  });

  useEffect(() => {
    showDropDownList();
  }, [classValue, clickInput]);


  console.log("props", props)

  useEffect(() => {
    if (props.clickChapter && props.curriculumData) {
      const formattedData = props.curriculumData.parts.map(part => ({
        ...part,
        chapters: part.chapters.map(chapter => ({
          ...chapter,
          sections: chapter.sections || []
        }))
      }));
      setChapterData(formattedData);
    }
  }, [props.curriculumData]);
  const color = props.selectedPart.color;


  // useEffect(() => {
  //   if (sessionStorage.getItem('data')) {
  //     const data = JSON.parse(sessionStorage.getItem('data'));
  //     const formattedData = data.parts.map(part => ({
  //       ...part,
  //       chapters: part.chapters.map(chapter => ({
  //         ...chapter,
  //         sections: chapter.sections || []
  //       }))
  //     }));
  //     setChapterData(formattedData);
  //   }
  // }, [props.curriculumData]);

  const showDropDownList = () => {
    setDropDownList(wholeTextArray);
  };

  const changeChapterValue = event => {
    setSectionValue(event.target.value);
  };

  const changeUnitValue = event => {
    setUnitValue(event.target.value);
  };

  const handleClose = () => {
    props.onClose?.();
  };

  const EnableConfirm = () => {
    return unitData.length > 0 && sectionValue !== '';
  };

  const handleDragStart = (e) => {
    const startX = e.pageX - scrollRef.current._container.offsetLeft;
    const startY = e.pageY - scrollRef.current._container.offsetTop;
    const scrollLeft = scrollRef.current._container.scrollLeft;
    const scrollTop = scrollRef.current._container.scrollTop;
    mouseCoords.current = { startX, startY, scrollLeft, scrollTop }
    setIsMouseDown(true);
  }

  const handleDragEnd = () => {
    setIsMouseDown(false);
  }

  const handleDrag = (e) => {
    if (!isMouseDown) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current._container.offsetLeft;
    const y = e.pageY - scrollRef.current._container.offsetTop;
    const walkX = x - mouseCoords.current.startX;
    const walkY = y - mouseCoords.current.startY;
    scrollRef.current._container.scrollLeft = mouseCoords.current.scrollLeft - walkX;
    scrollRef.current._container.scrollTop = mouseCoords.current.scrollTop - walkY;
  }

  const onAddUnit = async () => {
    if (sectionValue.trim() === '') {
      setSectionValue('');
      return false;
    }
    const newSection = {
      name: sectionValue,
      units: unitData
    };

    const updatedParts = addData(chapterData, {
      part: props.selectedPart.name,
      chapter: props.clickChapter.name,
      section: newSection.name,
      unit: newSection.units
    },
    props.selectedPartIdx,
    props.clickChapterIdx
    );

    setChapterData(updatedParts);
    setSectionValue('');
    setUnitData([]);
    sessionStorage.setItem('data', JSON.stringify({
      lastClickColor: props.selectedPart.color,
      lastClickSubject: props.selectedPart.subject,
      parts: updatedParts
    }));
    props.onClose();
  }

  const addSection = () => {
    if (sectionValue.trim() === '') {
      setSectionValue('');
      return false;
    }
    const newSection = {
      name: sectionValue,
      units: unitData
    };

    

    const updatedParts = addData(chapterData, {
      part: props.selectedPart.name,
      chapter: props.clickChapter.name,
      section: newSection.name,
      unit: newSection.units
    });

    setChapterData(updatedParts);
    setSectionValue('');
    setUnitData([]);
    props.updatePartCategory(updatedParts);
    props.onClose();
  };

  function addData(existingData, newData) {
    if (!Array.isArray(existingData)) {
      existingData = [];
    }

    let part = existingData.find(p => p.name === newData.part);

    if (!part) {
      part = { name: newData.part, subject: props.selectedPart.subject, color: props.selectedPart.color, chapters: [] };
      existingData.push(part);
    }

    let chapter = part.chapters.find(c => c.name === newData.chapter);
    if (!chapter) {
      chapter = { name: newData.chapter, sections: [] };
      part.chapters.push(chapter);
    }

    let section = chapter.sections.find(s => s.name === newData.section);
    if (!section) {
      section = { name: newData.section, units: [] };
      chapter.sections.push(section);
    }

    const existingUnitNames = new Set(section.units.map(unit => unit.name));
    newData.unit.forEach(newUnit => {
      if (!existingUnitNames.has(newUnit.name)) {
        section.units.push(newUnit);
      }
    });

    return existingData;
  }

  // function addData(existingData, newData, partIndex, chapterIndex, sectionIndex) {

  //   console.log("partIndex",partIndex,"chapterIndex",chapterIndex)
  //   if (!Array.isArray(existingData)) {
  //     existingData = [];
  //   }
  
  //   // part를 인덱스로 찾음
  //   let part = existingData[partIndex];
  //   if (!part) {
  //     part = { name: newData.part, subject: props.selectedPart.subject, color: props.selectedPart.color, chapters: [] };
  //     existingData[partIndex] = part;
  //   }
  
  //   // chapter를 인덱스로 찾음
  //   let chapter = part.chapters[chapterIndex];
  //   console.log("chapter",chapter)
  //   if (!chapter) {
  //     chapter = { name: newData.chapter, sections: [] };
  //     part.chapters[chapterIndex] = chapter;
  //   }
  
  //   // section을 인덱스로 찾음
  //   // let section = chapter.sections[sectionIndex];
  //   let section = chapter.sections.find(s => s.name === newData.section);
    
  //   if (!section) {
  //     section = { name: newData.section, units: [] };
  //     chapter.sections[sectionIndex] = section;
  //     console.log("section",chapter.sections[sectionIndex])
  //   }
  
  //   // units 배열에서 중복된 이름을 가진 유닛을 제외하고 추가
  //   const existingUnitNames = new Set(section.units.map(unit => unit.name));
  //   console.log("existingUnitNames",existingUnitNames)
  //   newData.unit.forEach(newUnit => {
  //     if (!existingUnitNames.has(newUnit.name)) {
  //       section.units.push(newUnit);
  //     }
  //   });
  
  //   return existingData;
  // }
  

  const addUnit = (unit) => {
    if (unitValue === '') {
      setUnitValue('');
      return false;
    }
  
    setUnitData(prevUnitData => [
      ...prevUnitData,
      { name: unit, achievement_rate: 0 }
    ]);
    setUnitValue('');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      addUnit(unitValue);
    }
  };

  const handleRemoveClick = (index) => {
    setUnitData(unitData.filter((_, idx) => idx !== index));
  };

  const handleUpClick = (index) => {
    if (index > 0) {
      const newUnitData = [...unitData];
      [newUnitData[index - 1], newUnitData[index]] = [newUnitData[index], newUnitData[index - 1]];
      setUnitData(newUnitData);
    }
  };

  const handleDownClick = (index) => {
    if (index < unitData.length - 1) {
      const newUnitData = [...unitData];
      [newUnitData[index], newUnitData[index + 1]] = [newUnitData[index + 1], newUnitData[index]];
      setUnitData(newUnitData);
    }
  };

  return (
    <>
      <Overlay>
        <ModalWrap>
          <div className='create-curriculum-step3-modal-container'>
            <div class="modal-head">
              <div className='title'>소단원 & 유닛 추가</div>
              <img class="exit-btn cursor" onClick={handleClose} src="/assets/image/icon_close_gray.png" />
            </div>
            <div className="section-unit-container">
              <div className="row-box">
                <div className="chapter">{props.clickChapter.name}</div>
                <div className="section-container">
                  <div className="section-box">
                    <div className="section-title">{sectionValue ? sectionValue : '소단원 명'}</div>
                    <PerfectScrollbar ref={scrollRef} className={`unit-box-outside ${color}`} options={{ suppressScrollX: false, suppressScrollY: false }} style={{ height: '100%', maxWidth: '530px', cursor: "grab", whiteSpace: "nowrap" }} onMouseDown={handleDragStart} onMouseUp={handleDragEnd} onMouseMove={handleDrag}>
                    <div className={`unit-box-outside ${color}`} style={{ boxShadow: 'unset', padding: '0px' }}>
                      <>


                      {unitData.length > 0 ? (
                          unitData.map((unit, index) => (
                            <div className="unit-box-inside" key={index}>
                              {unit.name}
                            </div>
                          ))
                        ) : null}

                        
                        {unitData.length > 0 && unitValue ? (
                          <div className="unit-box-inside">
                            {unitValue}
                          </div>
                        ) : null}

                        {unitData.length === 0 ? (
                          <div className="unit-box-inside">
                            {unitValue ? unitValue : '유닛 명'}
                          </div>
                        ) : null}



                      </>
                    </div>
                  </PerfectScrollbar>
                  </div>
                </div>
              </div>
            </div>
            <div className="padding-container">
              <div class="subtitle">소단원 이름</div>
              <input class="class-search full-width"
                placeholder={"소단원을 적어주세요"}
                inputType={'text'}
                value={sectionValue}
                onChange={changeChapterValue}>
              </input>
            </div>
            <div className="padding-container" style={{ paddingTop: '20px', borderTop: '2px solid #F5F9FD' }}>
              <div class="subtitle">유닛 이름</div>
              <div className="class-search align-item-center box-shadow" style={{ flex: 8, width: '100%' }}>
                <input
                  id='inputBox'
                  className="input-transparent cursor"
                  placeholder={"유닛 이름을 적어주세요"}
                  inputType={'text'}
                  value={unitValue}
                  onChange={changeUnitValue}
                  onKeyPress={handleKeyPress}
                />
                <img className='cursor add' src={"/assets/image/icon_plus_gray.png"} alt='' onClick={() => addUnit(unitValue)} />
              </div>
            </div>
            <PerfectScrollbar style={{ height: '230px' }} options={{ suppressScrollX: true }}>
            <div className="partCategory-container">
              {
                unitData.length > 0 ?
                  unitData.map((item, index) => (
                    <div className="row-box" key={index}>
                      <div className='arrow-buttons'>
                        <button className="up-btn" onClick={() => handleUpClick(index)}>▲</button>
                        <button className="down-btn" onClick={() => handleDownClick(index)}>▼</button>
                      </div>
                      <div className="partCategory-box">
                        <div className="left">
                          <div className='category' />
                          <div>{item.name}</div>
                        </div>
                        <div className="right">
                          <img className='cursor' src={"/assets/image/icon_minus.png"} alt="" onClick={() => handleRemoveClick(index)} />
                        </div>
                      </div>
                    </div>
                  ))
                  : null
              }
            </div>
          </PerfectScrollbar>

            <div className='padding-container' style={{ padding: '20px', borderTop: '2px solid #F5F9FD' }}>
            <Button style={{ backgroundColor: sectionValue !== '' && unitData.length > 0 ? '#0F84F4' : '#DFE3E8' }} disabled={sectionValue === '' || unitData.length === 0} onClick={addSection}>
              <img style={{ width: '10px' }} src='/assets/image/icon_plus_white.png' alt='' />
              <div>만들기</div>
            </Button>
            </div>
          </div>
        </ModalWrap>
      </Overlay>
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const ModalWrap = styled.div`
  width: 700px;
  height: 910px;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
`;
const Button = styled.button`
cursor: pointer;
width: 100%;
color: #ffffff;
border-radius: 5px;
border: 0px;
padding: 15px;
display: flex;
align-items: center;
justify-content: center;
font-size: 16px;
gap: 5px;
`;

const DropDownBox = styled.ul`
  display: block;
  position: absolute;
  margin: 0 auto;
  padding: 0px 5px;
  width: 89%;
  background-color: white;
  border: 1px solid #DFE1E8;
  border-radius: 8px;
  box-shadow: 0 10px 10px rgb(0, 0, 0, 0.3);
  list-style-type: none;
  z-index: 3;
  max-height: 200px; /* 최대 높이 설정 */
  overflow: auto; /* 스크롤바 표시 설정 */
`;

const DropDownItem = styled.li`
  text-align: start;
  padding: 0px 5px;
  margin: 10px 0px;
`;

export default AddChapterUnitModal;
