import React from "react";
import IconCheck from "./icons/check";
import styled from "styled-components";
import colors from "../design/colors";

interface CheckboxProps {
    checked: boolean;
    setChecked?: (checked: boolean) => void;
    size?: string;
    style?: React.CSSProperties;
}

const Checkbox = ({ checked, setChecked, size = "24px", style }: CheckboxProps) => {

    return (
        <CheckWrapper size={size} onClick={() => setChecked?.(checked)} checked={checked} style={style}>
            {checked && <IconCheck color={colors.white} width={`calc(${size} - 8px)`} height={`calc(${size} - 8px)`} />}
        </CheckWrapper>
    );
};

export default Checkbox;

const CheckWrapper = styled.div<{ size: string, checked: boolean }>`
    width: ${props => props.size} !important;
    height: ${props => props.size} !important;
    border-radius: 4px;
    border: 1px solid ${props => props.checked ? colors.blue600 : colors.gray200};
    background-color: ${props => props.checked ? colors.blue600 : colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
`;