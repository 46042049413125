import React from "react";
import { IconType } from "../../type/icon_type";



const IconFolder = (props: IconType) => {

    const { width = "24px", height = "24px", color = "#73787E" , style} = props;

    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path d="M19.2 20C19.6774 20 20.1352 19.8141 20.4728 19.4831C20.8104 19.1522 21 18.7033 21 18.2353V9.41176C21 8.94374 20.8104 8.49488 20.4728 8.16393C20.1352 7.83298 19.6774 7.64706 19.2 7.64706H12.09C11.789 7.64995 11.492 7.57877 11.2263 7.44004C10.9605 7.30131 10.7346 7.09945 10.569 6.85294L9.84 5.79412C9.6761 5.55012 9.45298 5.34983 9.19065 5.21123C8.92832 5.07263 8.635 5.00005 8.337 5H4.8C4.32261 5 3.86477 5.18592 3.52721 5.51687C3.18964 5.84782 3 6.29668 3 6.76471V18.2353C3 18.7033 3.18964 19.1522 3.52721 19.4831C3.86477 19.8141 4.32261 20 4.8 20H19.2Z" stroke={color} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export default IconFolder;