import React from "react";
import { IconType } from "../../type/icon_type";  



const ArrowLeft = (props: IconType) => {
    const { width, height, color } = props;
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 19L5 12M5 12L12 5M5 12H19" stroke={color} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

const  ArrowRight = (props: IconType) => {
    const { width, height, color } = props;
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke={color} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    )
}

export { ArrowRight, ArrowLeft };