import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import IconButton from '../../../components/icon_button';
import SearchBar from '../../../components/search_bar';
import { Page_settings } from '../../../config/page_settings';
import colors from '../../../design/colors';
import fonts from "../../../design/typography";
import useDebounce from '../../../hooks/useDebounce';
import { rootStore } from '../../../mobx/store';
import NotificationAddModal from './notificationAddModal';
import NoClassPage from '../../no_class';


interface INotificationItem {
  id: number;
  classroom: number;
  title: string;
  content: string;
  reservation: string;
  showTime: string | null;
  createDate: string;
  fileList: IFileResponse[];
}

interface IFileResponse {
  name: string;
  size: number;
  type: string;
  url: string;
}



const Notification = observer((props) => {
  const context: any = useContext(Page_settings);
  const classId = rootStore.getClassId;
  const [openAddNotification, setOpenAddNotification] = useState(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [notificationList, setNotificationList] = useState<INotificationItem[]>([]);
  const userType = rootStore.getProfile?.userType ?? ""
  const [searchKeyword, setSearchKeyword] = useState('');
  const keyword = useDebounce(searchKeyword, 500);
  const [searchStatus, setSearchStatus] = useState(false);
  const [now, setNow] = useState(new Date());

  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date());
      setSearchStatus(prev => !prev)
    }, 1000 * 60);
    return () => clearInterval(interval);
  }, []);


  useEffect(()=>{
    if(!isNaN(classId) && classId !== 0 && classId !== undefined && classId !== null){
      context.get(
        `classroom/${classId}/notice`,
        { keyword },
        response => {
          setNotificationList(response.list);
          setTotalCount(response.totalCount)
        }
      );
      }
  },[searchStatus, keyword])

  const getFileIcon = (name: string) => {
    const extension = name.split(".").pop();
    return `/assets/image/icon_file_${extension}.png`
  }

  return (
    classId ? (
  <PageContainer>
    <Header>
      <Title>
        공지사항
      </Title>
      {userType === "STUDENT" ? null : 
      <IconButton  size='medium' onClick={() => setOpenAddNotification(true)} text={"공지 추가"} style={{width: "92px", height: "36px"}}/> }
    </Header>
    <div style={{width: "100%", marginTop: 32, marginBottom: 20}}>
      <SearchBar
        placeholder={"제목을 검색해보세요"}
        value={searchKeyword}
        onChange={(e) => setSearchKeyword(e.target.value)}
        width='245px'
        size='medium'
      />
    </div>
    <ContentContainer hasNotification={totalCount > 0}>
      {totalCount > 0 ? notificationList.map((item, idx) => {
        return (    
          <NotificationItem onClick={()=>navigate(`/class/notification/${item.id}`)}>
            <div style={{display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between", width: "100%"}}>
              <div style={{...fonts.body2SemiBold, color: colors.gray900}}>
                {item.title}
              </div>
              <div style={{...fonts.label4Regular, color: item.showTime && new Date(item.showTime) > now ? colors.green600 : colors.gray400}}>
                {new Date(item.showTime || item.createDate).toLocaleString('ko-KR', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }).replace(/\//g, '.').replace(',', '')}
                {item.showTime && new Date(item.showTime) > now && <span style={{...fonts.label4Regular, marginLeft: 4}}> 예약</span>}
              </div>
            </div>
            <div style={{...fonts.label4Medium, color: colors.gray600, marginTop: 24, height: 40, width: "100%"}}>
              <textarea style={{
                width: "100%", height: "100%", border: "none", outline: "none", backgroundColor: "transparent", fontSize: fonts.label4Medium.fontSize, 
                fontFamily: fonts.label4Medium.fontFamily, lineHeight: fonts.label4Medium.lineHeight, color: colors.gray600,
                overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", cursor: "pointer"}} 
                value={item.content.length > 130 ? item.content.slice(0, 130) + "..." : item.content} readOnly />
            </div>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", width: "100%", marginTop: 24, height: 20}}>
              {item.fileList.length > 0 && 
              <>
              <img style={{width: 20, height: 20, marginRight: 8}} src={getFileIcon(item.fileList[0].name)} alt="" />
              <div style={{...fonts.label4Regular, color: colors.gray500, marginRight: 8}}>{item.fileList[0].name}</div>              
              </>
              }
            </div>
          </NotificationItem>
        )})
      :
      <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: 100 }}>
        <img style={{width: "152px", height: "113px"}} src="/assets/image/sad_rai.png" alt="" />
        <div style={{fontSize: 16, fontFamily: fonts.body3Medium.fontFamily, lineHeight: fonts.body3Medium.lineHeight, color: colors.gray400, marginTop: 23}}>아직 공지사항이 없습니다.</div>
        <div style={{fontSize: 16, fontFamily: fonts.body3Medium.fontFamily, lineHeight: fonts.body3Medium.lineHeight, color: colors.gray400}}>지금 바로 공지사항을 등록해보세요!</div>
      </div>}
    </ContentContainer>
    {openAddNotification && <NotificationAddModal onClose={() => {
      setOpenAddNotification(prev => !prev)
      setSearchStatus(prev => !prev)
      }} classId={classId}/>}
  </PageContainer>
  ) : (
    <NoClassPage userType={userType} />
  ))
});

const PageContainer = styled.div`
  display: flex;
  padding: 24px 40px;
  flex-direction: column;
  align-items: flex-start;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled.div`
  font-size:${fonts.title2SemiBold.fontSize};
  font-family: ${fonts.title2SemiBold.fontFamily};
  line-height: ${fonts.title2SemiBold.lineHeight};
  color: ${colors.gray600};
`;

const ContentContainer = styled.div<{hasNotification: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: ${props => props.hasNotification ? "flex-start" : "center"};
  width: 100%;
  flex-wrap: wrap;
  gap: 16px;
  height: ${props => props.hasNotification && "auto"};
`;

const NotificationItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(50% - 16px);
  height: 180px;
  border-radius: 12px;
  padding: 28px 24px;
  background-color: ${colors.white};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
`;

export default Notification;
