import strings from "../../../lang/strings";
import {formatNumber} from "../../../helper/common";
import {useContext} from "react";
import {Page_settings} from "../../../config/page_settings";

const ExpertItem = (props) => {
    const context = useContext(Page_settings);
    const {item, onReview, onBook, onFavourite, onExpertDetail} = props;

    return (
        <div className="expert-item">
            <div className="flex-grow-1">
                <div className="d-flex align-item-center">
                    <div className="name f-w-600" onClick={() => onExpertDetail()}>{item.name + " " + strings.expert}</div>
                    <button className="favourite" onClick={() => onFavourite()}>
                        <img
                            src={item.is_like ? "/assets/image/icon_bookmark_active.png" : "/assets/image/icon_bookmark_inactive.png"}
                            alt=""/>
                    </button>
                </div>
                <div className="org">{item.department}</div>
                <div className="title" onClick={() => onExpertDetail()}>{item.title}</div>
                <div className="d-flex align-item-center m-b-15">
                    <button className="review"
                            onClick={() => onReview()}>{strings.review + " " + (item.review_count != null ? formatNumber(item.review_count) : "0") + strings._reviews}</button>
                    <img className="rating-star" src={"/assets/image/icon_star_yellow.png"} alt=""/>
                    <div className="score">{item.score}</div>
                </div>
                <button
                    className={item.clinic_status == 0 ? "book-button" : (item.clinic_status == 2 ? "book-button write-review" : "book-button book-done")}
                    onClick={() => onBook()}>
                    {
                        item.clinic_status == 0 &&
                        <div>{strings.book_now}</div>
                    }
                    {
                        item.clinic_status == 1 &&
                        <div>{strings.reservation_complete}</div>
                    }
                    {
                        item.clinic_status == 2 &&
                        <div>{strings.write_review}</div>
                    }
                    {
                        item.clinic_status == 3 &&
                        <div>{strings.match_complete}</div>
                    }
                </button>
            </div>
            <button className="common-button" onClick={() => onExpertDetail()}>
                <img className="profile"
                     src={item.profile != null ? context.loadImage(item.profile) : "assets/image/chewing_plus_logo.png"} alt=""/>
            </button>
        </div>
    );
}

export default ExpertItem;
