import styled from "styled-components";
import React, { useContext, useEffect, useState } from "react";
import { Page_settings } from "../../../config/page_settings";
import colors from "../../../design/colors";
import { IconRaiFill } from "../../icons/rai";
import IconX from "../../icons/x";
import fonts from "../../../design/typography";
import { ArrowRight } from "../../icons/arrow";
interface IChatbotList {
    type: number;
    isChatBot: boolean;
    text: string;
    createDate: string;
}

interface IChatbotDropdownProps {
    toggle: () => void;
}

const ChatbotDropdown = (props: IChatbotDropdownProps) => {
    const context: any = useContext(Page_settings);
    const {toggle} = props;
    const [chatList, setChatList] = useState<IChatbotList[]>([]);
    const [inputValue, setInputValue] = useState<string>("");
    const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
    useEffect(() => {
        context.get("/chat-bot", {}, (res: IChatbotList[]) => {
            const newChatList = res.map((item) => ({
                type: item.type,
                isChatBot: item.isChatBot,
                text: item.text,
                createDate: item.createDate,
            }));
            setChatList(newChatList);
        })
    }, []);


    const handleSendMessage = () => {
        context.post("/chat-bot", {
            content: inputValue,
            prevData: chatList.slice(10).reverse() 
        }, (response) => {
            const newChat = response.content;
            const newChatList = [{
                type: 2,
                isChatBot: true,
                text: newChat,
                createDate: new Date().toISOString()
            }, {
                type: 2,
                isChatBot: false,
                text: inputValue,
                createDate: new Date().toISOString()
            }, ...chatList ];
            setInputValue("");
            setChatList(newChatList);
        })
    }
    return (
        <ChatbotDropdownWrapper>
            <RowWrapper style={{height: "40px"}}>
                <RowWrapper>
                    <div style={{ width: "44px", height: "44px", borderRadius: "50%", background: "linear-gradient(180deg, #0F84F4, #8DC9FF)", display: "flex", alignItems: "center", justifyContent: "center" }}
                    >  
                        <IconRaiFill color={colors.white} background={colors.blue400} width="32px" height="32px"/>
                    </div>
                    <span style={{ ...fonts.body2SemiBold, color: colors.gray900, marginLeft: 12 }}> AI 라이</span>
                </RowWrapper>
                <div style={{cursor: "pointer"}} onClick={() => toggle()}>
                    <IconX color={colors.gray900} width="24px" height="24px"/>
                </div>
            </RowWrapper>
            <ContentWrapper isChatListEmpty={chatList.length === 0}>
            {chatList.length === 0 &&
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                    <img src="/assets/image/rai_stand.png" style={{width: 122, height: 165, marginBottom: 12}}/>
                    <span style={{ ...fonts.label4Medium, color: colors.gray900 }}> 안녕하세요! 저는 수업도우미 AI라이에요.</span>
                    <span style={{ ...fonts.label4Medium, color: colors.gray900 }}> 궁금한 점이나 도움이 필요하시면 뭐든지 질문해보세요!</span>
                </div>
            }
            {
                    chatList.map((item, index) => (
                        <ChatItem key={index} isBot={item.isChatBot}>
                            {item.isChatBot &&
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 8}}>
                                <img src="/assets/image/img_expert.png" height={28} style={{width: 28, height: 28, borderRadius: "50%", background: "#5DB4FF", display: "flex", alignItems: "center", justifyContent: "center", objectFit: "contain", paddingTop: 2}}/>
                                <span style={{ ...fonts.label4Medium, color: colors.gray900, marginLeft: 12 }}> AI 라이</span>
                            </div>
                            }
                            <ChatBubble isBot={item.isChatBot}>
                                {item.text}
                            </ChatBubble>
                        </ChatItem>
                    ))
            }
            </ContentWrapper>
            <InputWrapper>
                <InputBox 
                    placeholder="궁금한 점이나 전달할 내용을 입력해주세요" 
                    value={inputValue} 
                    onChange={(e) => setInputValue(e.target.value)} 
                    onFocus={() => setIsInputFocused(true)} 
                    onBlur={() => setIsInputFocused(false)} 
                    isFocused={isInputFocused} 
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && inputValue !== "") {
                            handleSendMessage();
                        }
                    }}
                />
                <div style={{position: "absolute", width: 24, height: 24, borderRadius: "50%", background: inputValue !== "" ? colors.blue600 : colors.gray100, display: "flex", alignItems: "center", justifyContent: "center", right: 12, top: 8, cursor: "pointer"}} 
                    onClick={() => {
                        if (inputValue !== "") {
                            handleSendMessage();
                        }
                    }}>
                    <ArrowRight color={colors.white} width="24px" height="24px"/>
                </div>
            </InputWrapper>
        </ChatbotDropdownWrapper>
    )
}

export default ChatbotDropdown;

const ChatbotDropdownWrapper = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${colors.white};
    width: 375px;
    height: 683px;
    border-radius: 12px;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.2);
    padding: 28px 20px 24px 20px;
`;

const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
`;

const ContentWrapper = styled.div<{isChatListEmpty: boolean}>`
    width: 100%;
    height: calc(100% - 80px);
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: ${props => props.isChatListEmpty ? "center" : "flex-start"};
    padding-bottom: 28px;
    gap: 12px;

    &::-webkit-scrollbar {
        width: 8px; /* Width of the scrollbar */
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${colors.gray100}; /* Color of the scrollbar thumb */
        border-radius: 4px; /* Rounded corners for the scrollbar thumb */
    }
    &::-webkit-scrollbar-track {
        background: transparent; /* Background of the scrollbar track */
    }
`;

const ChatItem = styled.div<{isBot: boolean}>`
    width: 100%;
    display:flex;
    flex-direction: column;
    justify-content: ${props => props.isBot ? "flex-start" : "flex-end"};
    align-items: ${props => props.isBot ? "flex-start" : "flex-end"};
    border-radius: 16px;
`;


const ChatBubble = styled.div<{isBot: boolean}>`
    max-width: 80%;
    display: flex;
    background-color: ${props => props.isBot ? colors.blue50 : colors.blue600};
    border-radius: 16px;
    padding: 12px 14px;
    color: ${props => props.isBot ? colors.gray800 : colors.white};
    font-size: ${fonts.label4Medium.fontSize};
    font-family: ${fonts.label4Medium.fontFamily};
    line-height: ${fonts.label4Medium.lineHeight};
`;

const InputWrapper = styled.div`
    width: 100%;
    height: 40px;
    position: relative;
`;

const InputBox = styled.input<{isFocused: boolean}>`
    width: 100%;
    height: 40px;
    justify-content: space-between;
    padding-top: 8px;
    padding-right: 12px;
    padding-bottom: 8px;
    padding-left: 16px;
    border-radius: 20px;
    border: 1px solid ${props => props.isFocused ? colors.blue600 : colors.gray100};
    &::placeholder {
        color: ${colors.gray500};
        font-size: ${fonts.label4Medium.fontSize};
        font-family: ${fonts.label4Medium.fontFamily};
        line-height: ${fonts.label4Medium.lineHeight};
    }
`;