import React, { useContext, useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import styled from "styled-components";
import ChatInput from "../../../components/control/custom_input/chat_input";
import ChatItem from "../../../components/control/item/chat_item";
import { Page_settings } from "../../../config/page_settings";
import strings from "../../../lang/strings";
import { rootStore } from "../../../mobx/store";

interface IRoomMember {
    user: {
        id: number;
        name: string;
        video: "ON" | "OFF";
        speaker: "ON" | "OFF";
        mirror: "ON" | "OFF";
        profile: string;
        studyTime?: number;
        studyTimeThisMonth?: number;
        studyStartTime?: Date;
    };
    member: number;
    videoTrack?: any;
    audioTrack?: any;
}

interface IChat {
    sender: number;
    sender_name: string;
    sender_profile?: string;
    receiver?: number;
    receiver_name?: string;
    message: string;
    time: string;
    show_profile: boolean;
    show_time: boolean;
}


interface ConferenceRightProps {
    mine: boolean;
    isUsingVideo: boolean;
    ownerVideoTrack: any;
    ownerAudioTrack: any;
    ownerProfile: string;
    ownerReactionUrl: string;
    memberList: IRoomMember[];
    onSendChat: (message: string, receiver?: number, receiver_name?: string) => void;
    isOwnerMirrored: boolean;
    screenShare: boolean;
    chatList: IChat[];
    onCopy: (e : React.ClipboardEvent<HTMLInputElement>) => void;
}

const ConferenceRight = (props: ConferenceRightProps) => {
    const context: any = useContext(Page_settings);
    const { mine, isUsingVideo, ownerVideoTrack, ownerProfile, ownerReactionUrl, memberList, onSendChat, isOwnerMirrored, chatList, screenShare, onCopy } = props;
    const [ ownerCamZoom, setOwnerCamZoom] = useState<boolean>(false);
    const [ selectedMemberIndex, setSelectedMemberIndex ] = useState<number>(-1);
    const [chat, setChat] = useState("");
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    console.log("ownerVideoTrack", ownerVideoTrack);

    return (
        <RightContainerWrapper>
            <OwnerCamWrapper id="owner-player" 
                            ownerCamZoom={ownerCamZoom && ((ownerVideoTrack != null && ownerVideoTrack != undefined) && (!mine || isUsingVideo)) } 
                            isMirrored={mine ? !isOwnerMirrored : isOwnerMirrored} 
                            width={"50%"} 
                            height={"50%"} 
                            left={"calc(50% - 350px)"} 
                            onClick={() => {
                                if ( !ownerVideoTrack || ownerVideoTrack == undefined) return;
                                if (screenShare) return;
                                setOwnerCamZoom(prev => !prev);
                            }}>
                {
                    ((ownerVideoTrack == null || ownerVideoTrack == undefined || screenShare) || (mine && !isUsingVideo) ) &&
                    <img
                        src={(ownerProfile && ownerProfile !== "") ? context.loadImage(ownerProfile) : "/assets/image/chewing_plus_logo.png"}
                        className="position-absolute"
                        style={{ width: 120, height: 120, borderRadius: 60, objectFit: 'cover' }}
                        alt="" />
                }
                {
                    ownerReactionUrl != "" &&
                    <img src={ownerReactionUrl} className="position-absolute" alt="" />
                }
            </OwnerCamWrapper>
            <ChatHeader>
                <div style={{color: '#747383'}}>{'채팅'}</div>
                <div className="chat-member-count m-l-auto" style={{ width: 'auto', height: 'auto', background: 'none' }}>
                    <img src={"/assets/image/icon_user_gray.png"} alt="" />
                    <div className="f-s-11 p-r-5" style={{ lineHeight: '17px', color: '#747383' }}>{memberList.length}</div>
                </div>
            </ChatHeader>
            <ChatArea ownerCamZoom={ownerCamZoom && ((ownerVideoTrack != null && ownerVideoTrack != undefined) && (!mine || isUsingVideo))}>
                    {chatList.length != 0 &&
                        chatList.map((item, idx) => {
                            return (
                                <ChatItem
                                    key={idx}
                                    item={item}
                                />)
                        })
                    }
            </ChatArea>
            <ChattingTargetContainer>
                <span>To: </span>
                <Dropdown isOpen={isDropdownOpen} toggle={() => setIsDropdownOpen(prev => !prev)}>
                    <DropdownToggle style={{height: 25, display:"flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                        <span style={{fontSize: 15}}>{selectedMemberIndex === -1 ? strings.all : memberList[selectedMemberIndex].user.name}</span>
                    </DropdownToggle>
                    <DropdownMenu>
                        <div className="dropdown-item" onClick={() => {
                            setSelectedMemberIndex(-1)
                            setIsDropdownOpen(false)
                            }}>
                            <div className="text-ellipsis opponent-name">{strings.all}</div>
                        </div>
                        {memberList.map((item, idx) => {
                            if(item.user.id == rootStore.getProfile.id) return null;

                            return (
                                <div key={idx} className="dropdown-item" onClick={() => {
                                    setSelectedMemberIndex(idx)
                                    setIsDropdownOpen(false)
                                }}>
                                    <div className="text-ellipsis opponent-name">{item.user.name}</div>
                                </div>
                            )
                        })}
                    </DropdownMenu>
                </Dropdown>
            </ChattingTargetContainer>
            <ChatInput
                placeholder={strings.enter_chat}
                onChange={(v) => setChat(v)}
                value={chat}
                inputType={"text"}
                onSend={() => {
                    if (chat != "" && chat.replace(/ /g, "") != "") {
                        if(selectedMemberIndex === -1) {
                            onSendChat(chat);
                        } else {
                            onSendChat(chat, memberList[selectedMemberIndex].member, memberList[selectedMemberIndex].user.name);
                        }
                        setChat("")
                    }
                }}

                onCopy={onCopy}
            />
            {/* <div className="ai-couch-container conference-couch">
                <div className='temperature-container'>
                    <div className="display-flex-important align-item-center width-100-percent">
                        <div className="temperature">{strings.temperature}</div>
                        <div className="width-100-percent position-relative">
                            <div className="progress rounded-corner width-100-percent progress-background">
                                <div className="progress-bar f-s-10 f-w-600" style={{
                                    width: `${temperature}%`,
                                    backgroundColor: "red",
                                    transition: 'all .2s ease-out',
                                    minWidth: '30px',
                                }}
                                />
                            </div>
                            <img className="fire" src={"/assets/image/icon_fire.png"}
                                 style={{right: `${93 - temperature}%`}}/>
                        </div>

                        <div className="f-s-16 progress-number">{strings.hot}</div>
                    </div>
                </div>
                <div className={"ai-couch"}>{strings.ai_chewing}</div>
                <div>{strings.before_start_chew[props.rand]}</div>
                <img className="couch-image" src={"/assets/image/img_ai_bot4.png"} alt=""/>
            </div> */}

        </RightContainerWrapper>
    );
}
export default ConferenceRight;


const RightContainerWrapper = styled.div`
    overflow-y: auto;
    height: calc(100% - 134px);
    width: 350px;
    position: absolute;
    right: 0;
    background: #353443;
    margin-top: -11px;
    border-left: black 1px solid;
    z-index: 30;
`;

interface IOwnerCamWrapperProps {
    ownerCamZoom: boolean;
    isMirrored: boolean;
    width: string;
    height: string;
    left: string
}

const OwnerCamWrapper = styled.div<IOwnerCamWrapperProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props => props.ownerCamZoom ? `${props.width}` : '100%'};
    height: ${props => props.ownerCamZoom ? `${props.height}` : '190px'};
    position: ${props => props.ownerCamZoom ? 'fixed' : 'relative'};
    ${props => props.isMirrored && 'transform: scaleX(-1);'}
    z-index: ${props => props.ownerCamZoom ? '300' : '0'};
    left: ${props => props.ownerCamZoom ? props.left : '0'};
    font-size: 0px;
`;


const ChatHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: #353443;
    color: white;
    font-size: 16px;
    font-weight: bold;
    height: 40px;
`;

interface ChatAreaProps {
    ownerCamZoom: boolean;
}
const ChatArea = styled.div<ChatAreaProps>`
    padding: 0 15px;
    height: ${props => props.ownerCamZoom ? 'calc(100% - 140px)' : 'calc(100% - 330px)'};
    flex: 1 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
    background: #353443;

    &::-webkit-scrollbar {
        width: 4px;
        background-color: #323142;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #444444;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
        background-color: #323142;
    }
`;

const ChattingTargetContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    background: #353443;
    color: white;
    font-size: 15px;
    font-weight: bold;
    height: 30px;
`;