import React from "react";

export default function RoomCreateButton(props) {
    return (
        <button
            className={"create-room-button" + (props.enable ? "" : " disabled")}
            onClick={props.onClick}>{props.value}
        </button>
    );
}
