import React, {useContext} from 'react';
import {Modal, ModalBody} from "reactstrap";
import './modal.scss'
import {Page_settings} from "../../../config/page_settings";

const ImageViewModal = (props) => {

    const context = useContext(Page_settings);

    const {show, toggle, image, close} = props;
    return (
        <Modal isOpen={show} toggle={() => toggle()} className="image-view-modal">
            <ModalBody className="p-30 ">
                <div className="d-flex align-items-center justify-content-center">
                    <img src={context.loadImage(image)} className="main-image" alt=""/>
                    <button className="common-button close-btn" onClick={() => close()}>
                        <img src={"/assets/image/icon_close_black.png"} alt=""/>
                    </button>
                </div>
            </ModalBody>
        </Modal>

    );
}

export default ImageViewModal;
