import React, { memo, useContext, useEffect, useState } from 'react';
import { MdCheck, MdModeEditOutline } from 'react-icons/md';
import styled from 'styled-components';
import { Page_settings } from '../../../../config/page_settings';
import colors from '../../../../design/colors';
import fonts from '../../../../design/typography';
interface ISpecialty {
    id: number;
    major: string;
    bookList: string;
    clubActivity: string;
    optionalSubject: string;
    performanceAssessment: string;
    schoolEvent: string;
    feedbacks?: IFeedback[];
}

interface IFeedback {
    id: number;
    class_teacher_member: number;
    class_name: string;
    grade: string;
    classroom: string;
    content: string;
}

const LifeRecordContent = (props: {member: number, classId: number}) => {
    const { member, classId } = props;
    const context: any = useContext(Page_settings);
    const [edit, setEdit] = useState(false);
    const [specialty, setSpecialty] = useState<ISpecialty>({
        id: 0,
        major: '',
        bookList: '',
        clubActivity: '',
        optionalSubject: '',
        performanceAssessment: '',
        schoolEvent: '',
        feedbacks: [{
            id: 0,
            class_teacher_member: 0,
            class_name: '',
            grade: '',
            classroom: '',
            content: ''
        }]
    });
    

    useEffect(() => {
        context.get(
            'specialty',
            {
                member: member,
                classId: classId
            },
            response => {
                setSpecialty(response);
            }
        )
    },[])

    const onConfirm = () => {
        context.post(
            'specialty/feedback',
            {
                memberId: member,
                classId: classId,
                feedback: specialty.feedbacks ? specialty.feedbacks[0].content : ''
            },
            () => {
                context.addNotification("",'피드백이 전송되었습니다.');
            }
        )
    }

    return (        
    <Container>
        <TopInputWrapper>
            <TopInputTitle>지원예정 학과</TopInputTitle>
            <TopInputValue>{specialty.major === '' ? '항목이 입력되지 않았습니다.' : specialty.major}</TopInputValue>
        </TopInputWrapper>
        <div style={{width: "100%", marginBottom: 20, display: "flex", flexDirection: "column", gap: 16, padding: "28px 24px", backgroundColor: colors.gray50, borderRadius: "12px"}}>
                <InputBox title='독서리스트' value={specialty.bookList === '' ? '항목이 입력되지 않았습니다.' : specialty.bookList} />
                <InputBox title='동아리 활동' value={specialty.clubActivity  === '' ? '항목이 입력되지 않았습니다.' : specialty.clubActivity}/>
                <InputBox title='선택 과목' value={specialty.optionalSubject  === '' ? '항목이 입력되지 않았습니다.' : specialty.optionalSubject}/>
                <InputBox title='수행평가' value={specialty.performanceAssessment  === '' ? '항목이 입력되지 않았습니다.' : specialty.performanceAssessment}/>
                <InputBox title='교내행사' value={specialty.schoolEvent === '' ? '항목이 입력되지 않았습니다.' : specialty.schoolEvent}/>
        </div>
        <div style={{position: "relative", width: "100%", padding: "28px 24px", backgroundColor: colors.gray50, borderRadius: "12px",height: "100%"}}>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: 28}}>
                <h2 style={{
                            color: colors.gray900,
                            ...fonts.label2SemiBold
                            }}>{"선생님 피드백"}</h2>
                <EditButton edit={edit} onClick={() => {
                    if(edit) {
                        onConfirm();
                    }
                    setEdit(prev => !prev);
                }} />
            </div>
            <textarea disabled={!edit} style={{width: "100%", minHeight: 165, backgroundColor: "#FFFFFF", padding: "24px 16px", borderRadius: "8px", ...fonts.body3Medium}}
                value={specialty.feedbacks.length > 0 ? 
                    specialty.feedbacks[0].content : ""} onChange={e => 
                        setSpecialty(prev => {
                            if(prev) {
                                return {...prev, feedbacks: [{...prev.feedbacks[0], content: e.target.value}]};
                            }
                            return prev;
                        })} 
                    placeholder="피드백 내용을 작성해주세요."
            />
        </div>
    </Container>);
}

export default LifeRecordContent;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 8px; /* Adjust the width of the scrollbar */
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${colors.gray200}; /* Color of the scrollbar thumb */
        border-radius: 4px; /* Rounded corners for the thumb */
    }
    &::-webkit-scrollbar-track {
        background: transparent; /* Make the track invisible */
    }
`;



const InputBox = (
    props : {
        title: string;
        value: string;
    }
) => {
    const {title, value} = props;
    return (
        <div style={{width: "100%", height: 160, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
            <div style={{display: "flex", flexDirection:"row", width: 200, height: "100%", position:"relative", alignItems: "center", backgroundColor: colors.gray100, justifyContent: "center", borderRadius: "8px 0 0 8px"}}>
                <h2 style={{display: "flex", alignItems: "center", ...fonts.label2SemiBold, textAlign: "center"}}>{title}</h2>
            </div>
            <InputBoxTextArea value={value} disabled={true}/> 
        </div>
    );
}

const TopInputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;
    align-items: flex-start;
    border-radius: 12px;
    background-color: ${colors.gray50};
    font-size: ${fonts.label1SemiBold.fontSize};
    line-height: ${fonts.label1SemiBold.lineHeight};
    font-family: ${fonts.label1SemiBold.fontFamily};
    color: ${colors.gray900};
    margin-bottom: 16px;
`;

const TopInputTitle = styled.div`
    width: 164px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.blue600};
`;

const TopInputValue = styled.div`
    width: calc(100% - 164px);
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: ${colors.gray900};
`;

const InputBoxTextArea = styled.textarea`
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    padding: 16px 24px;
    border-radius: 0 0 10px 10px;
    font-family: ${fonts.body3Regular.fontFamily};
    line-height: ${fonts.body3Regular.lineHeight};
    font-size: ${fonts.body3Regular.fontSize};
`;

const EditButton = memo((
    props: {
        onClick: () => void;
        edit: boolean;
    }
) => {
    const {onClick, edit} = props;

    return (
        <div style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...fonts.label2SemiBold,
            color: colors.blue600,
            }} onClick={() => {onClick();}}>
            {edit ? <span>완료</span> : <span>수정</span>}
        </div>
    );
});
