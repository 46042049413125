import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay  } from 'swiper/modules';

const modules = [Autoplay];

const HomeSec4 = () => {
    return (
        <>
            <section className="wrap main-sec4">
                <div className="main-tit">
                    <h2>
                    이제 교육은 달라지고 있습니다. <br />
                    새로운 AI 튜터링의 시작 츄잉
                    </h2>
                </div>
                <Swiper
                    className="mySwiper sec4-swiper"
                    modules={modules}
                    slidesPerView={1}
                    spaceBetween={0} 
                    autoplay={{
                        delay:3500,
                        disableOnInteraction: false,
                    }}
                    centeredSlides={false}
                    loop={true}
                    loopedslides={2}
                    breakpoints={{
                        1024: {
                            slidesPerView:3.3,
                            spaceBetween:40,
                            centeredSlides:true,
                        },
                    }}
                >
                    <div className="swiper-wrapper">
                        <SwiperSlide className="slide-con">
                            <div className="txt-box">
                                <h4>
                                    선생님도 <br />
                                    ‘혼자서는’ 힘들어요
                                </h4>
                            </div>
                            <div className="img-box">
                                <img src="/assets/image/landing_page/main/sec4-slide1.png" alt="" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="slide-img">
                            <div className="img-box">
                                <img src="/assets/image/landing_page/main/sec4-slide2.png" alt="" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="slide-con slide-con2">
                            <div className="txt-box">
                                <h4>이제는 AI라이와 함께!</h4>
                                <p>
                                    반복되는 강의업무와 학생들의 질의응답은 <br />
                                    라이에게 맡겨주세요
                                </p>
                            </div>
                            <div className="img-box">
                                <img src="/assets/image/landing_page/main/sec4-slide3.png" alt="" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="slide-img">
                            <div className="img-box">
                                <img src="/assets/image/landing_page/main/sec4-slide4.png" alt="" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="slide-con slide-con3">
                            <div className="txt-box">
                                <h4>
                                    교사의 강의와 학생의 학습도 <br />
                                    이제는 스마트하게
                                </h4>
                                <p>
                                    학생에게는 공부하는 법을 알려주고 <br />
                                    선생님은 강의에 집중할수 있도록
                                </p>
                            </div>
                            <div className="img-box">
                                <img src="/assets/image/landing_page/main/sec4-slide5.png" alt="" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="slide-img">
                            <div className="img-box">
                                <img src="/assets/image/landing_page/main/sec4-slide2.png" alt="" />
                            </div>
                        </SwiperSlide>
                    </div>
                </Swiper>
            </section>
        </>
    );
}

export default HomeSec4;