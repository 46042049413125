import React, { useContext, useEffect, useState } from 'react';
import styled from "styled-components";
import { rootStore } from "../../../mobx/store";
import { Page_settings } from '../../../config/page_settings';
import PerfectScrollbar from "react-perfect-scrollbar";
import BillCancelModal from './bill_cancel_modal';

function BillDetailModal(props) {
  const { bill } = props;
  const context = useContext(Page_settings);
  const [billDetail, setBillDetail] = useState([]);
  const [name,setName] = useState('');
  const [phone,setPhone] = useState('');
  const [reason,setReason] = useState('');
  const [message,setMessage] = useState('');
  const [price,setPrice] = useState(0);
  const [sender, setSender] = useState('');
  const [cancelModal, setCancelModal] = useState(false);

  const [paymentToken, setPaymentToken] = useState(localStorage.getItem('payment_token') ? JSON.parse(localStorage.getItem('payment_token')).token : null);
  const [tokenTime, setTokenTime] = useState(localStorage.getItem('payment_token') ? JSON.parse(localStorage.getItem('payment_token')).time : null)

  useEffect(() => {
    if (tokenVerification(tokenTime)) {
      context.post(
        'payment/getToken',
        {},
        response => {
          rootStore.setPaymentToken(response);
          setPaymentToken(response);
          context.post(
            'payment/getDetailInfo',
            {
              token: response,
              bill: bill.id
            },
            response => {
              setPrice(response.bill.totalAmount)
              setName(response.bill.receiver.name);
              setPhone(response.bill.receiver.phone);
              setReason(response.bill.reason);
              setMessage(response.bill.message);
              setBillDetail(response.bill)
              setSender(response.sender)
            });
        }
      )
    } else {
      context.post(
        'payment/getDetailInfo',
        {
          token: paymentToken,
          bill: bill.id
        },
        response => {
          setPrice(response.bill.totalAmount)
          setName(response.bill.receiver.name);
          setPhone(response.bill.receiver.phone);
          setReason(response.bill.reason);
          setMessage(response.bill.message);
          setBillDetail(response.bill)
          setSender(response.sender)
        });
    }
  }, []);


  const handleClose = () => {
    props.onClose?.();
  };

  const handleDelete = () => {
    props.onDelete?.();
  }
  const handleResend = () => {
    props.onResend?.();
  };

  const tokenVerification = (tokenTime) => {
    // 현재 시각을 밀리초 단위로 얻음
    const currentTime = Date.now();

    // 주어진 시간 값과 현재 시각의 차이를 계산
    const difference = currentTime - tokenTime;

    // 차이가 지정된 시간 이상이면 true, 아니면 false 반환
    return difference >= 3600000;
  }

  const resend = () => {
    
    if (tokenVerification(tokenTime)) {
      context.post(
        'payment/getToken',
        {},
        response => {
          rootStore.setPaymentToken(response);
          context.post(
            'payment/send',
            {
              token: response,
              reason,
              message,
              price,
              members: [{name, phone}]
            },
            response => {
              alert('재발송 성공')
              handleResend()
            }
          );
        }
      )
    } else {
      context.post(
        'payment/send',
        {
          token: paymentToken,
          reason,
          message,
          price,
          members: [{name, phone}]
        },
        response => {
          alert('재발송 성공')
          handleResend()
        }
      );
    }
  }

  const deleteBill = () => {
    // 토큰 유효성 검사 후, 만료된 경우 새 토큰 발행
    if (tokenVerification(tokenTime)) {
      context.post(
        'payment/getToken',
        {},
        response => {
          rootStore.setPaymentToken(response);
          setPaymentToken(response);
          context.post(
            'payment/delete',
            {
              token: response,
              billID: billDetail.id
            },
            response => {
              handleDelete()
              alert('삭제 되었습니다')
              handleClose()
            });
        }
      )
    } else {
      context.post(
        'payment/delete',
        {
          token: paymentToken,
          billID: billDetail.id
        },
        response => {
          handleDelete()
          alert('삭제 되었습니다')
          handleClose()
        });
    }
  }

  const cancelBill = () => {
    setCancelModal(true);
  }

  // status 0: 취소 / 1: 미납 / 2 : 수납


  return (
    <Overlay >
      <ModalWrap >

      {cancelModal && (<BillCancelModal
        open={cancelModal}
        onClose={() => {
          setCancelModal(false);
        }}
        bill={billDetail}
      />)}
        <div className="bill-send-modal">
          <div className="p-30 padding-zero">


            <div class="bill-send-modal">
              <div class="modal-header">
                <div class="left">
                  청구서 상세
                </div>
                <div class="right" onClick={() => handleClose()}>
                  <img src="/assets/image/icon_close_black.png" alt="" />
                </div>
              </div>
              <PerfectScrollbar style={{ height: '600px' }} options={{ suppressScrollX: true }}>


                <div class="bill-modal-content">

                  <div class="top-box">
                    <div className={`top ${billDetail?.status}`}>
                      {billDetail?.status === "PENDING" ? '미납' : billDetail?.status === "CANCELED" ? "취소" : '수납'}
                    </div>
                    <div class="middle1">{billDetail?.receiver?.name}</div>
                    <div class="middle2">{billDetail?.totalAmount}원</div>
                    <div class="middle-box">
                      <div class="flex-space-between">
                        <div class="left">공급가액</div>
                        <div class="right">{ billDetail?.supplyPrice}원</div>
                      </div>
                      <div class="flex-space-between">
                        <div class="left">부가세</div>
                        <div class="right">{ billDetail?.vat}원</div>
                      </div>
                    </div>
                    {billDetail?.status === "PENDING" ?
                      <div class="flex-space-between">
                        <button class="del-btn" onClick={() => deleteBill()}>삭제하기</button>
                        {/* <button class="receive-btn">수납처리</button> */}
                      </div>
                      :
                      billDetail?.status === "DONE" ?
                        <div class="flex-space-between">
                          <button class="cancel-btn" onClick={() => cancelBill(true)}>결제 취소</button>
                          
                        </div>
                        :
                        billDetail?.status === "CANCELED" &&
                          null 
                    }

                  </div>
                  <div class="history-box">
                    <div class="title">받는 분</div>
                    <div class="flex-space-between">
                      <div class="left">이름</div>
                      <div class="right">{billDetail?.receiver?.name}</div>
                    </div>
                    <div class="flex-space-between">
                      <div class="left">휴대폰 번호</div>
                      <div class="right">{billDetail?.receiver?.phone}</div>
                    </div>
                    <hr />
                    <div class="title">청구 내용</div>
                    {billDetail?.status === "PENDING" ?
                      <>
                          <div class="flex-space-between">
                          <div class="left">청구금액</div>
                          <div class="right">{billDetail?.totalAmount ? billDetail?.totalAmount : null}원</div>
                        </div>
                        <div class="flex-space-between">
                          <div class="left">청구사유</div>
                          <div class="right">{bill.reason}</div>
                        </div>
                        <div class="flex-space-between">
                          <div class="left">안내 메시지</div>
                          <div class="right">{bill.message}</div>
                        </div>
                        <div class="flex-space-between">
                          <div class="left">보낸사람</div>
                          <div class="right">{sender}</div>
                        </div>
                        <div class="flex-space-between">
                          <div class="left">발송일시</div>
                          <div class="right">{billDetail?.createdAt}</div>
                        </div>
                        </>
                      :
                      billDetail?.status === "DONE" ?
                      <>
                      <div class="flex-space-between">
                      <div class="left">결제일시</div>
                      <div class="right">{billDetail?.successPayment.createdAt ? billDetail?.successPayment.createdAt : null}</div>
                    </div>
                    <div class="flex-space-between">
                      <div class="left">승인번호</div>
                      <div class="right">{billDetail?.successPayment.acceptNumber ? billDetail?.successPayment.acceptNumber : null}</div>
                    </div>
                    <div class="flex-space-between">
                      <div class="left">결제금액</div>
                      <div class="right">{billDetail?.totalAmount ? billDetail?.totalAmount : null}원</div>
                    </div>
                    <div class="flex-space-between">
                      <div class="left">결제수단</div>
                      <div class="right">카드</div>
                    </div>
                    <div class="flex-space-between">
                      <div class="left">고객식별번호</div>
                      <div class="right">{billDetail?.receiver.phone}</div>
                    </div>
                    </>
                        :
                        billDetail?.status === "CANCELED" &&
                        <>
                        <div class="flex-space-between">
                        <div class="left">청구금액</div>
                        <div class="right">{billDetail?.totalAmount ? billDetail?.totalAmount : null}원</div>
                      </div>
                      <div class="flex-space-between">
                        <div class="left">청구사유</div>
                        <div class="right">{billDetail?.reason ? billDetail?.reason : null}</div>
                      </div>
                      <div class="flex-space-between">
                        <div class="left">안내 메시지</div>
                        <div class="right">{billDetail?.message ? billDetail?.message : "-"}</div>
                      </div>``
                      <div class="flex-space-between">
                        <div class="left">취소일시</div>
                        <div class="right">{billDetail?.canceledPayment.createdAt ? billDetail?.canceledPayment.createdAt : "-"}</div>
                      </div>
                      </>}
                    {/* <div class="flex-space-between">
                <div class="left">재발송</div>
                <div class="right">0</div>
              </div> */}
                  </div>


                </div>
              </PerfectScrollbar>
              <div class="bill-modal-bottom">
                <button class="submit-btn submit" onClick={() => resend()}> 청구서 재발송 </button>
              </div>
            </div>
          </div>
        </div>
      </ModalWrap>
    </Overlay>

  );
}

const Overlay = styled.div`
position: fixed;
width: 100%;
height: 100%;
top: 0;
bottom: 0;
left: 0;
right: 0;
background: rgba(0, 0, 0, 0.5);
z-index: 9999;
`;

const ModalWrap = styled.div`
width: 1452px;
  height: 760px;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
`;

export default BillDetailModal;