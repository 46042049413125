import React, {useContext} from 'react';
import {Modal, ModalBody} from "reactstrap";
import './modal.scss'
import PerfectScrollbar from "react-perfect-scrollbar";
import strings from "../../../lang/strings";
import {formatNumber} from "../../../helper/common";
import {Page_settings} from "../../../config/page_settings";

const ShopItemModal = (props) => {

    const {show, toggle, onClosed, item, onGet} = props;
    const context = useContext(Page_settings)

    return (
        <Modal isOpen={show} toggle={() => toggle()} onClosed={onClosed} className="shop-item-modal">
            <ModalBody>
                <div className="d-flex">
                    <div className="left-container m-r-25">
                        <img className="brand-image" src={context.loadImage(item.brand_image)} alt=""/>
                        <div className="d-flex align-item-center height-full">
                            <img className="product-image" src={context.loadImage(item.image)} alt=""/>
                        </div>
                    </div>
                    <PerfectScrollbar className="right-container" options={{suppressScrollX: true}}>
                        <div className="m-t-20 f-s-22 f-w-800">{item.brand}</div>
                        <div className="m-t-20 f-s-24">{item.title}</div>
                        <div className="m-t-20 horizontal-divider m-b-20"/>
                        <div className="d-flex align-item-center m-b-30">
                            <div className="f-s-16">{strings.point}</div>
                            <img className="m-l-auto" src={"/assets/image/icon_coin.png"} alt=""/>
                            <div className="m-l-5 f-s-25 f-w-700">{formatNumber(item.point)}</div>
                        </div>
                        <button className="shop-get-button" onClick={() => onGet()}>
                            <div>{strings.get}</div>
                        </button>
                        <div className="m-t-50 f-s-20 f-w-700 m-b-15">{strings.product_info}</div>
                        <div className="product-content">{item.content}</div>
                    </PerfectScrollbar>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default ShopItemModal;
