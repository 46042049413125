import React, { useContext, useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from "styled-components";
import strings from "../../../lang/strings";
import StudentSearch from "../customSearch/studentSearch";
import { Page_settings } from '../../../config/page_settings';
import {rootStore} from "../../../mobx/store";
import { useHistory } from 'react-router-dom';

function  MissionFinishModal({ onClose, mission_id, class_id}, props) {


  const history = useHistory();
  const context = useContext(Page_settings);
  

  const handleClose = () => {    
    onClose?.();
  };

  const finishMission = () => {
    context.post(
      'class/finishMission',
      { 
        mission_id : mission_id
      },
      response => {
        history.push(`/class/mission_list/${class_id}`);
      }
    );
  }


  return (
      <Overlay>
        <ModalWrap>
          <div className='deleteModal-container'>
            <div className='title'>마감</div>
            <div className='content'>정말로 마감하시겠습니까?</div>
            <div className='btn-box'>
              <button onClick={()=>handleClose()} className='cancel-btn'>취소</button>
              <button onClick={()=>finishMission()} className='delete-btn'>마감</button>
            </div>
          </div>
        </ModalWrap>
      </Overlay>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top\: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const ModalWrap = styled.div`
  width: 326px;
  height: 160px;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
`;


export default MissionFinishModal;