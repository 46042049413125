import { withRouter } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import { rootStore } from "../../../mobx/store";
import { Page_settings } from "../../../config/page_settings";
import strings from "../../../lang/strings";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import AddChapterUnitModal from "../modal/add_chapter_unit_modal";
import styled from "styled-components";
import EditThumbnailModal from "./edit_thumbnail_modal";
import EditAddChapterUnitModal from "./edit_add_chapter_unit_modal";
import SaveModal from "../modal/save_modal";

const EditCurriculumStep3 = (props) => {
  const history = useHistory();
  const context = useContext(Page_settings);
  const [openChapterUnitAddModal, setOpenChapterUnitAddModal] = useState(false);
  const [titleValue, setTitleValue] = useState('');
  const [partTitleValue, setPartTitleValue] = useState('');
  const [categoryBox, setCategoryBox] = useState(false); //카테고리 박스 켜져있는지 유무
  const [categoryColor, setCategoryColor] = useState('yellow'); //카테고리 색상값
  const [categorySubject, setCategorySubject] = useState(); //카테고리 과목
  const [partCategory, setPartCategory] = useState([]); // 대단원 항목 데이터
  const [selectedPart, setSelectedPart] = useState();
  const [selectedPartIdx, setSelectedPartIdx] = useState();
  const [clickChapter, setClickChapter] = useState();
  const [modalData, setModalData] = useState([]);
  const [editStatus, setEditStatus] = useState(false);
  const [warningPoint, setWarningPoint] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [openGuide, setOpenGuide] = useState(false)
  const [roadmapData, setRoadmapData] = useState(props?.curriculumData?.thumbnail);
  const [saveModal, setSaveModal] = useState(false);
  const scrollRef = useRef();
  const mouseCoords = useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0
  });



  useEffect(() => {
    setSelectedPartIdx(0)
    if (props.curriculumData && props.curriculumData.parts) {
      const formattedParts = props.curriculumData.parts.map(part => ({
        id: part.id ? part.id : null,
        subject: part.subject,
        color: part.color,
        name: part.name,
        chapters: part.chapters || []
      }));
      setPartCategory(formattedParts);
      setSelectedPart(formattedParts[0]);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.guide-img')) {
        setOpenGuide(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  useEffect(() => {
    if (props.curriculumData && props.curriculumData.parts) {
      const formattedParts = props.curriculumData.parts.map(part => ({
        id: part.id ? part.id : null,
        subject: part.subject,
        color: part.color,
        name: part.name,
        chapters: part.chapters || []
      }));
      setPartCategory(formattedParts);
      
    }

    if (props.curriculumData && props?.curriculumData?.thumbnail) {
      const formattedThumbnail = props?.curriculumData?.thumbnail;
      setRoadmapData(formattedThumbnail)
    }
  }, [props.curriculumData]);

  const handleCategoryClick = (item,index) => {
    setSelectedPart(item);
    setSelectedPartIdx(index);
    setCategoryColor(item.color);
    setCategorySubject(item.subject)
  };


  const deleteSection = (chapterIndex, sectionIndex) => {
    // 새로운 배열을 만들어서 변경 사항을 반영
    const updatedPartCategory = partCategory.map((part, partIndex) => {
      if (partIndex === selectedPartIdx) {
        return {
          ...part,
          chapters: part.chapters.map((chapter, chIndex) => {
            if (chIndex === chapterIndex) {
              return {
                ...chapter,
                sections: chapter.sections.filter((_, secIndex) => secIndex !== sectionIndex)
              };
            }
            return chapter;
          })
        };
      }
      return part;
    });
  
    // 업데이트된 partCategory를 설정
    setPartCategory(updatedPartCategory);
    props.updatePartCategory(updatedPartCategory);
  };

  const filterChaptersWithoutSections = (partCategory) => {
    return partCategory
      .map(part => {
        // chapters 배열을 필터링하여 sections 키가 존재하고 배열이 비어 있지 않은 chapter만 남김
        const filteredChapters = part.chapters.filter(chapter => chapter.sections && chapter.sections.length > 0);
        
        // 필터링된 chapters 배열로 part 객체를 업데이트
        return { ...part, chapters: filteredChapters };
      })
      // chapters 배열이 비어 있지 않은 part만 남김
      .filter(part => part.chapters.length > 0);
  };
  
  const onNext = async () => {
    // sections가 없는 chapter를 필터링
    const filteredPartCategory = filterChaptersWithoutSections(partCategory);
    // 업데이트된 partCategory로 작업을 수행
    props.updatePartCategory(filteredPartCategory);
    history.push(`/curriculum/edit_curriculum/${props.curriculum}/4`);
  };
  

  const openModal = (chapter) => {
    setClickChapter(chapter)
    setOpenChapterUnitAddModal(true)
  }



  const handleDragStart = (e) => {
    const startX = e.pageX - scrollRef.current._container.offsetLeft;
    const startY = e.pageY - scrollRef.current._container.offsetTop;
    const scrollLeft = scrollRef.current._container.scrollLeft;
    const scrollTop = scrollRef.current._container.scrollTop;
    mouseCoords.current = { startX, startY, scrollLeft, scrollTop }
    setIsMouseDown(true);
  }

  const handleDragEnd = (e) => {
    setIsMouseDown(false);
  }

  const handleDrag = (e) => {
    if (!isMouseDown) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current._container.offsetLeft;
    const y = e.pageY - scrollRef.current._container.offsetTop;
    const walkX = x - mouseCoords.current.startX;
    const walkY = y - mouseCoords.current.startY;
    scrollRef.current._container.scrollLeft = mouseCoords.current.scrollLeft - walkX;
    scrollRef.current._container.scrollTop = mouseCoords.current.scrollTop - walkY;
  }

  const EnableConfirm = () => {
    const areAllSectionsPresent = partCategory.every(
      part => part.chapters.every(
        chapter => chapter.sections && chapter.sections.length > 0
      )
    );
  
    return areAllSectionsPresent;
  }


  const temporaryStorage = async () => {
    try {
      const curriculumImgFile = roadmapData?.checkBasicImg ? null : roadmapData?.imgFile;
      const curriculumImgName = roadmapData?.checkBasicImg ? null : roadmapData?.imgName;
      const curriculumThumbNail = roadmapData?.checkBasicImg ? null : roadmapData?.thumbnail;
      // roadmapData에서 imgfile 키를 제거
      const { imgFile, ...updatedRoadmapData } = roadmapData;
  
      if (curriculumImgFile && curriculumImgName) {
        const roadmapImgUrl = await roadMapImg(curriculumImgFile, curriculumImgName);
        context.post(
          'curriculum/editCurriculum',
          {  
            curriculum: {
              ...updatedRoadmapData,
              thumbnail: roadmapImgUrl // 업로드된 이미지 URL을 roadmapData에 추가
            },
            curriculum_child:partCategory,
            mode:0
          },
          response => {
            setSaveModal(true);
            
          }
        );
      }else if(curriculumThumbNail){ 
         // 이미지 파일이 없는 경우에도 데이터 전송
         context.post(
          'curriculum/editCurriculum',
          {  
            curriculum: {
              ...updatedRoadmapData,
              thumbnail: curriculumThumbNail
            },
            curriculum_child:partCategory,
            mode:0
          },
          response => {
            setSaveModal(true);
            
          }
        );
      }else {
        // 이미지 파일이 없는 경우에도 데이터 전송
        context.post(
          'curriculum/editCurriculum',
          {  
            curriculum: {
              ...updatedRoadmapData,
              thumbnail: null // 업로드된 이미지 URL을 roadmapData에 추가
            },
            curriculum_child:partCategory,
            mode:0
          },
          response => {
            setSaveModal(true);
            
          }
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  const roadMapImg = (imgFile, imgName) => {
    return new Promise((resolve, reject) => {
      const arr = imgFile.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
  
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      const url = new File([u8arr], imgName, { type: mime });
  
      let formData = new FormData();
      formData.append('file', url);
  
      context.post(
        'upload/image/thumbnail',
        formData,
        response => {
          if (response.image) {
            resolve(response.image);
          } else {
            reject(new Error("Image upload failed"));
          }
        },
        error => {
          reject(error);
        }
      );
    });
  };

  const goBack = () => {
    history.push(`/curriculum/edit_curriculum/${props.curriculum}/2`);
   }
  


  return (
    <div class="main-content create-curriculum-step3-container">
      {openChapterUnitAddModal && (<EditAddChapterUnitModal
        open={openChapterUnitAddModal}
        onClose={() => {
          setOpenChapterUnitAddModal(false);
        }}
        categoryColor={categoryColor}
        categorySubject={categorySubject}
        clickChapter={clickChapter}
        selectedPart={selectedPart}
        updatePartCategory={props.updatePartCategory}
        curriculumData={props.curriculumData}
      />)}

      {editStatus && (<EditThumbnailModal
        open={editStatus}
        onClose={() => {
          setEditStatus(false);
        }}
        curriculumData={props.curriculumData}
        updateThumbnail={props.updateThumbnail}
      />)}

      {saveModal && (<SaveModal
        open={saveModal}
        onClose={() => {
          setSaveModal(false);
        }}
      />
      )}

      <div class="top content-container">
        <div class="progress-container">
          <div class="progress-box">
            <div class="progress" />
          </div>
          <div class="step"><span>3</span> / 5</div>
        </div>
        <div class="flex-box">
          <div className="title-box">
          <img className="cursor" src="/assets/image/icon_arrow_back_black.png" alt="" onClick={()=>goBack()}/>
            <div className="title">
              {props?.curriculumData?.thumbnail?.title}
            </div>
            <img className="cursor" src="/assets/image/icon_edit_btn_gray.png" alt="" onClick={() => setEditStatus(true)} />
          </div>
          <div className="curriculum-guide-box">
            {/* <img src="/assets/image/icon_warning_point.png" alt="" onClick={()=>setWarningPoint(true)}/> */}
            <img src="/assets/image/icon_curriculum_guide.png" style={{width:'65px'}} alt="" onClick={()=>setOpenGuide(true)}/>
            {openGuide &&
            <img className="guide-img" src="/assets/image/img_curriculum_guide_3.png" alt="" />
    }
          </div>
        </div>

      </div>

      <div className="middle content-container">
        <div className="title">소단원 & 유닛 추가</div>
        {partCategory.length > 0 &&
          <div className="row-box">
            {partCategory.map((item, index) => (
              <div
                key={index}
                className={`partCategory-box ${selectedPartIdx === index ? item?.color : ''}`}
                onClick={() => handleCategoryClick(item,index)}
              >
                <div>{item.name}</div>
              </div>
            ))}
          </div>
        }
      </div>

      <div className="bottom content-container">
        <div className="container-top">

          <PerfectScrollbar className="section-unit-container" ref={scrollRef} style={{ height: '100%', cursor: "grab" }} onMouseDown={handleDragStart} onMouseUp={handleDragEnd} onMouseMove={handleDrag}>


          {
  (selectedPartIdx !== undefined && partCategory[selectedPartIdx]?.chapters?.length > 0) ? (
    partCategory[selectedPartIdx].chapters.map((chapter, chapterIdx) => (
      <div className="row-box" key={chapterIdx}>
        <div className="chapter">{chapter?.name}</div>
        {chapter.sections && chapter.sections.map((section, sectionIdx) => (
          <div className="section-container" key={sectionIdx}>
            <div className="section-box">
              <div className="section-title">{section.name}
              <img className='cursor' src='/assets/image/icon_cancel_btn_gray.png' alt='' onClick={()=> deleteSection(chapterIdx,sectionIdx)}/>
              </div>
              <div className={`unit-box-outside ${partCategory[selectedPartIdx]?.color}`}>
                {section.units && section.units.map((unit, unitIdx) => (
                  <div className={`unit-box-inside`} key={unitIdx}>
                    {unit.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
        <div className="section-container" style={{ marginTop: '20px' }}>
          <div className="section-box">
            <div className={`unit-box-outside ${partCategory[selectedPartIdx]?.color}`}>
              <div className="unit-box-inside" onClick={() => openModal(chapter)}>
                <img className="add-unit-img" src="/assets/image/icon_plus_circle_gray.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    ))
  ) : null
}


         

          </PerfectScrollbar>




        </div>
        <div className="container-bottom">
        <button className="save-btn" onClick={() => temporaryStorage()}>임시저장</button>
        <button className="next-btn" style={{ backgroundColor: EnableConfirm() ? '#0F84F4' : '#DFE3E8' }} disabled={!EnableConfirm()} onClick={() => onNext()}>다음으로</button>
        </div>
      </div>


    </div>
  );






}


const BalloonContainer = styled.div`
  position: relative;
  background: #fff;
  border-radius: 10px;
  padding: 30px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  margin: 20px;
  position: absolute;
  top: 20px;
  right: 40px;
  z-index: 9999;
  width: 350px;
  
  &:before {
    content: '';
    position: absolute;
    top: 0px;
    right: -8px;
    width: 10px;
    height: 10px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #fff;
  }
`;

const CloseButton = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const BalloonImage = styled.img`
  display: block;
  margin: 0 auto 10px;
`;

const BalloonText = styled.div`
  text-align: center;
  color: #71768C;

font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 25px; /* 156.25% */
`;

export default withRouter(EditCurriculumStep3);
