import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from "styled-components";
import IconDownload from '../../../components/icons/download';
import IconMenu from '../../../components/icons/menu';
import { Page_settings } from '../../../config/page_settings';
import colors from '../../../design/colors';
import fonts from '../../../design/typography';
import { rootStore } from '../../../mobx/store';
import { Modal, ModalBody } from 'reactstrap';
import Button from '../../../components/button';
import NotificationAddModal from './notificationAddModal';

interface INoticeListItem {
  id: number;
  title: string;
  showTime: string;
  createDate: string;
  content: string;
}


interface INotificationInfo {
  id: number;
  classroom: number;
  title: string;
  content: string;
  reservation: string;
  showTime: string;
  createDate: string;
  fileList: IFileResponse[];
}

interface IFileResponse {
  name: string;
  size: number;
  type: string;
  url: string;
}

const NotificationInfo = observer((props) => {
  const context: any = useContext(Page_settings);
  const navigate = useNavigate();
  const classId = rootStore.getClassId;
  const { notification_id } = useParams();

  const [notificationInfo, setNotificationInfo] = useState<INotificationInfo | null>(null);
  const [noticeList, setNoticeList] = useState<INoticeListItem[]>([]);
  const user_type = rootStore.getProfile?.userType ?? "STUDENT";
  const [openDeleteModal,setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const imgType = ["bmp",
    "jpg",
    "jpeg",
    "png",
    "tiff"]

  useEffect(() => {
    context.get(
      `classroom/${classId}/notice/${notification_id}`,
      {},
      response => {
        if(!response) {
          alert('찾을수 없습니다');
          navigate(`/class/notice/${classId}`)
        }
        setNotificationInfo(response)
      }
    );

    context.get(
      `classroom/${classId}/notice`,
      {},
      response => {
        setNoticeList(response.list)
      }
    );

  }, [notification_id])

  const getImagePath = (name) => {
    const ext = name.split(".")[name.split(".").length - 1];

    return `/assets/image/icon_file_${ext}.png`;
  }

  const onDownload = async (url, name) => {
    const STORAGE_BASE_URL = process.env.REACT_APP_BUCKET_URL;
    const fileUrl = `${STORAGE_BASE_URL}/${url}`;

    try {
        const response = await fetch(fileUrl, { method: "GET" });
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = name;
        document.body.appendChild(a);
        a.click();

        // 메모리 해제
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);
    } catch (error) {
        console.error("파일 다운로드 중 오류 발생:", error);
    }
};


  const fileResize = (size) => {

    const dataSize = size;
    let formattedData = '';
    
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  
    if (dataSize === 0) return '0 Byte';
    
    const k = Math.floor(Math.log(dataSize) / Math.log(1024));
  
    formattedData =  Math.round(100 * (dataSize / Math.pow(1024, k))) / 100 + ' ' + sizes[k];
    return formattedData;
  }


  return (
    <Container>
      <NoticeInfoSection>
        {notificationInfo && (
          <>
          <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between', width: '100%', marginBottom: 20, position: 'relative'}}>
            <span style={{...fonts.label4Regular, color: colors.gray500}}>{notificationInfo.showTime || notificationInfo.createDate}</span>
            {user_type === "EXPERT" && (
              <div onClick={() => setIsMenuOpen(!isMenuOpen)} style={{cursor: 'pointer'}}>
                <IconMenu color={colors.gray500}/>
              </div>
            )}
            {isMenuOpen && 
            (<MenuContainer>
              <MenuItem onClick={() => {
                setOpenEditModal(true);
                setIsMenuOpen(false);
              }} isFirst={true} isLast={false}>수정하기</MenuItem>
              <MenuItem onClick={() => {
                setOpenDeleteModal(true);
                setIsMenuOpen(false);
              }} isFirst={false} isLast={true}>삭제하기</MenuItem>
            </MenuContainer>)}
          </div>
          <span style={{...fonts.body1SemiBold, color: colors.gray900}}>{notificationInfo.title}</span>
          <div style={{height: 1 ,width: '100%', backgroundColor: colors.gray100, margin: "32px 0"}}/>
          <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', justifyContent:'flex-start', width: '100%'}}>
            <textarea style={{width: '100%', height: '300px' , border: 'none', outline: 'none', ...fonts.body3Medium, color: colors.gray900, overflowY: 'auto'}} value={notificationInfo.content}/>
          </div>
        
          {notificationInfo.fileList.map((item, idx) => {
            return (
              <div key={idx} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: 'calc(100% - 274px)'}}>
                  <img src={getImagePath(item.name)} alt={item.name} width={24} height={24}/>
                  <span style={{...fonts.body3Medium, color: colors.gray900, marginLeft: 8}}>{item.name.length > 50 ? item.name.slice(0, 50) + "..." : item.name}</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: 250}}>
                  <span style={{...fonts.body3Medium, color: colors.gray900}}>{fileResize(item.size)}</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: 24, cursor: 'pointer'}} onClick={() => onDownload(item.url, item.name)}>
                  <IconDownload color={colors.gray500} width="24px" height="24px"/>
                </div>
              </div>
            )
          })}
        </>
        )}
      </NoticeInfoSection>
      <Divider/>
      <NoticeListSection>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: 24}}>
          <span style={{...fonts.body2SemiBold, color: colors.gray900}}>목록</span>
        </div>
        <NoticeListWrapper>
          {noticeList.map((item, idx) => {
            return (
              <NotcieListItem key={idx} onClick={() => {navigate(`/class/notification/${item.id}`); console.log(`/class/notification/${item.id}`)}}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%', marginBottom: 24}}>
                  <span style={{...fonts.body3SemiBold, color: colors.gray900}}>{item.title}</span>
                  <span style={{...fonts.label5Regular, color: colors.gray500}}>
                    {new Date(item.showTime || item.createDate).toLocaleString('ko-KR', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }).replace(/\//g, '.').replace(',', '')}
                  </span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', width: '100%'}}>
                  <textarea style={{...fonts.label4Medium, color: colors.gray600, border: 'none', outline: 'none', width: '100%', height: '100%', overflowY: 'hidden',cursor: 'pointer'}} value={item.content.length > 210 ? item.content.slice(0, 210) + "..." : item.content} readOnly/>
                </div>
              </NotcieListItem>
            )
          })}
        </NoticeListWrapper>
      </NoticeListSection>
      
      {openDeleteModal && (<NotificationDeleteModal
            onClose={() => {
              setOpenDeleteModal(false);
            }}
            notificationId={notification_id}
            classId={classId}
          />)}
      {openEditModal && (<NotificationAddModal
        onClose={() => {
          setOpenEditModal(false);
        }}
        classId={classId}
        notification={notificationInfo}
        setNotification={setNotificationInfo}
      />)}
    </Container>        
  );
});

export default NotificationInfo;


const NotificationDeleteModal = ({onClose, notificationId, classId}) => {

  const context: any = useContext(Page_settings);
  const navigate = useNavigate();
  const deleteNotification = () => {
    context.delete(`classroom/${classId}/notice/${notificationId}`, {}, response => {
      onClose();
      navigate(`/class/notification`);
    });
  }

  return (
    <Modal isOpen={true} toggle={onClose} size='sm'>
      <ModalBody style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '156px', padding: 24}}>
        <div style={{...fonts.body2Medium, color: colors.gray900, marginBottom: 32, marginTop: 24}}>공지를 삭제하시겠습니까?</div>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%', gap: 8}}>
          <Button size='medium' onClick={()=>onClose()} text='취소' buttonColor={colors.gray100} textColor={colors.gray400} height='40px'/>
          <Button size='medium' onClick={()=>deleteNotification()} text='삭제' buttonColor={colors.warning} textColor={colors.white} height='40px'/>
        </div>
      </ModalBody>
    </Modal>
  )
}


const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 32px 40px;
`;

const NoticeInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(50% - 41px);
  background-color: ${colors.white};
  border-radius: 12px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  height: 650px;
  padding: 40px 24px;
`;

const NoticeListSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(50% - 41px);
  height: 100%;
`;

const Divider = styled.div`
  margin: 0 40px;
  width: 1px;
  height: 650px;
  background-color: ${colors.gray100};
`;

const NoticeListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: calc(100% - 35px);
  overflow-y: auto;
  gap: 12px;
`;

const NotcieListItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 24px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  background-color: ${colors.white};
  border-radius: 12px;
  height: 135px;
  cursor: pointer;
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  top: 30px;
  right: 10px;
  background-color: ${colors.white};
  border-radius: 8px;
  border: 1px solid ${colors.gray100};
  width: 76px;
  height: 88px;
`;

const MenuItem = styled.div<{isFirst: boolean, isLast: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  cursor: pointer;
  font-size: ${fonts.body3Regular.fontSize};
  color: ${colors.gray500};
  font-family: ${fonts.body3Regular.fontFamily};
  line-height: ${fonts.body3Regular.lineHeight};

  border-radius: ${props => props.isFirst && props.isLast ? "8px" : props.isFirst ? "8px 8px 0 0" : props.isLast ? "0 0 8px 8px" : "0"};
`;