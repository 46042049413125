import React, { useContext, useState } from 'react';
import { Modal, ModalBody, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import strings from "../../../lang/strings";
import './modal.scss'
import { Page_settings } from "../../../config/page_settings";
import Webcam from 'react-webcam';
import { isMobile } from 'react-device-detect';


const ScreenSettingModal = (props) => {
    const context = useContext(Page_settings);
    const { show, close, video, speaker, reverse, toggle, name, profile, cameraType, cams, mics, curCam, curMic, onConfirm, onBack } = props;
    const [videoEnable, setVideoEnable] = useState(video)
    const [speakerEnable, setSpeakerEnable] = useState(speaker)
    const [reverseEnable, setReverseEnable] = useState(reverse)
    const [camSelectOpen, setCamSelectOpen] = useState(false)
    const [micSelectOpen, setMicSelectOpen] = useState(false)
    const [selectedCam, setSelectedCam] = useState(curCam);
    const [selectedMic, setSelectedMic] = useState(curMic);
    const [curCamIdx, setCurCamIdx] = useState(0);
    const [curMicIdx, setCurMicIdx] = useState(0);

    const toggleCam = () => {
        setCamSelectOpen(!camSelectOpen);
    }

    const toggleMic = () => {
        setMicSelectOpen(!micSelectOpen);
    }

    return (
        <Modal isOpen={show} toggle={() => toggle()} className='screen-setting-modal'>
            <ModalBody style={{ backgroundColor: '#1A1A1A', borderRadius: 20, padding: 0 }}>
                <div className='d-flex header align-item-center'>
                    <button className='modal-close-btn' onClick={() => onBack()}>
                        <img src={"/assets/image/icon_arrow_back.png"} alt="" className="modal-close" />
                    </button>
                    <div className='f-s-20 m-l-10' style={{ color: "white" }}>{strings.screen_settings}</div>
                    <div className='flex-grow-1' />
                    <button className='modal-close-btn' onClick={() => close()}>
                        <img src={"/assets/image/icon_close_white.png"} alt="" className="modal-close" />
                    </button>
                </div>
                <div style={{ height: 250 }}>
                    {
                        videoEnable &&
                        <div style={{ height: '100%', position: "relative" }}>
                            <Webcam
                                audio={false}
                                mirrored={isMobile ? reverseEnable : !reverseEnable} />
                            <div className='name-badge d-center'>{name}</div>
                            <div className='camera-badge d-center'>{cameraType}</div>
                        </div>
                    }
                    {
                        !videoEnable &&
                        <div className='d-center' style={{ height: '100%' }}>
                            <div>
                                <div style={{
                                    backgroundImage: "url(" + (profile != null && profile != "" ? context.loadImage(profile) : "/assets/image/chewing_plus_logo.png") + ")",
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    height: 120,
                                    width: 120,
                                    borderRadius: 60
                                }} />
                                <div style={{ color: "white", textAlign: "center" }} className='f-s-20 mt-3'>{name}</div>
                            </div>
                        </div>
                    }
                </div>
                <div style={{ height: 150 }} className='d-flex'>
                    <div className='flex-grow-1 d-center'>
                        <div className='flex-column d-center'>
                            <img src={videoEnable ? "/assets/image/btn_video_on.png" : "/assets/image/btn_video_off.png"}
                                alt="" className='setting-image' onClick={() => {
                                    if(cams.length === 0) {
                                        return;
                                    }
                                    setVideoEnable(!videoEnable)
                                    }} style={{ cursor: 'pointer' }} />
                            <div className='setting-btn-text'>
                                <Dropdown isOpen={camSelectOpen} toggle={() => toggleCam()}
                                    className="dropdown navbar-user report-dropdown" tag="li" style={{ width: "100px", background: 'transparent' }}>
                                    <DropdownToggle tag="a"
                                        className="display-flex-important align-item-center profile-dropdown report-dropdown" style={{ background: 'transparent', border: 'none' }}>
                                        <div
                                            className="flex-grow-1" style={{ color: 'white', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%', height: '100%' }}>{selectedCam != null ? selectedCam.label : ''}</div>
                                        <img src={"/assets/image/icon_arrow_down_white_small.png"} alt="" />
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu dropdown-menu-right profile-dropdown-menu" tag="ul">

                                        {
                                            cams.length == 0 &&
                                            <DropdownItem
                                                className="dropdown-header display-flex-important justify-content-center f-s-15"
                                            >
                                                {'사용가능한 카메라가 없습니다.'}
                                            </DropdownItem>
                                        }
                                        {
                                            cams.map((item, idx) =>
                                                <DropdownItem key={idx}
                                                    className="dropdown-header display-flex-important justify-content-center f-s-15"
                                                    onClick={() => {
                                                        setCurCamIdx(idx);
                                                        setSelectedCam(item);
                                                    }}
                                                >
                                                    {item.label}
                                                </DropdownItem>
                                            )
                                        }
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                    <div className='flex-grow-1 d-center'>
                        <div className='flex-column d-center'>
                            <img src={speakerEnable ? "/assets/image/btn_audio_on.png" : "/assets/image/btn_audio_off.png"}
                                alt="" className='setting-image' onClick={() => {
                                    if(mics.length === 0) {
                                        return;
                                    }
                                    setSpeakerEnable(!speakerEnable)}
                                } style={{ cursor: 'pointer' }} />
                            <div className='setting-btn-text'>
                                <Dropdown isOpen={micSelectOpen} toggle={() => toggleMic()}
                                    className="dropdown navbar-user report-dropdown" tag="li" style={{ width: "100px", background: 'transparent' }}>
                                    <DropdownToggle tag="a"
                                        className="display-flex-important align-item-center profile-dropdown report-dropdown" style={{ background: 'transparent', border: 'none' }}>
                                        <div
                                            className="flex-grow-1" style={{ color: 'white', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%', height: '100%' }}>{selectedMic != null ? selectedMic.label : ''}</div>
                                        <img src={"/assets/image/icon_arrow_down_white_small.png"} alt="" />
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu dropdown-menu-right profile-dropdown-menu" tag="ul">
                                        {mics.length == 0 &&
                                            <DropdownItem
                                            className="dropdown-header display-flex-important justify-content-center f-s-15"
                                            >
                                                {'사용가능한 마이크가 없습니다.'}
                                            </DropdownItem>    
                                            
                                        }
                                        {
                                            
                                            mics.map((item, idx) =>
                                                <DropdownItem key={idx}
                                                    className="dropdown-header display-flex-important justify-content-center f-s-15"
                                                    onClick={() => {
                                                        setCurMicIdx(idx)
                                                        setSelectedMic(item)
                                                    }}
                                                >
                                                    {item.label}
                                                </DropdownItem>
                                            )
                                        }
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                    <div className='flex-grow-1 d-center' onClick={() => setReverseEnable(!reverseEnable)}
                        style={{ cursor: 'pointer' }}>
                        <div className='flex-column d-center'>
                            <img src={"/assets/image/btn_reverse.png"} alt="" className='setting-image' />
                            <div className='setting-btn-text' style={{ height: 40 }}>{strings.reverse}</div>
                        </div>
                    </div>
                </div>
                <div className='btn-confirm d-center' onClick={() => onConfirm(videoEnable, speakerEnable, reverseEnable, curCamIdx, curMicIdx)}>
                    {strings.confirm}
                </div>
            </ModalBody>
        </Modal>

    );
}

export default ScreenSettingModal;
