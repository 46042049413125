import React, { useContext, useState } from 'react';
import { Modal, ModalBody } from "reactstrap";
import './modal.scss'
import { API_URL } from "../../../config/const";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Document, Page } from 'react-pdf';
import {Page_settings} from "../../../config/page_settings";
import styled from 'styled-components';

const CloudViewModal = (props) => {
    const context: any = useContext(Page_settings)
    const { show, toggle, item, onDownload, hideDownload } = props;
    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);
  
    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
      setNumPages(numPages);
    }
  

    const getFileType = (url) => {
        if (!url) return '';
        const extension = url.split('.').pop().toLowerCase();
        return extension.split(',')[0]; // In case there are query parameters
    };
    const fileType = getFileType(item.url);
    return (
        <Modal isOpen={show} toggle={() => toggle()} style={{display: "flex", flexDirection: "column", justifyContent: "center", maxWidth: "90%"}}>
            <CloudViewModalBodyWrapper>
                <div className="d-flex align-items-center m-b-40 p-l-40 p-r-40">
                    <div className="f-s-20 f-w-600 flex-grow-1">{item.name ?? item.title}</div>
                    <button className='modal-close-btn' onClick={() => toggle()}>
                        <img src={"/assets/image/icon_close_black.png"} alt="" className="modal-close" />
                    </button>
                </div>
                <ViewerWrapper>
                    {fileType === 'pdf' ? (
                    <>
                        <Document
                            file={{url: context.loadImage(item.url)}}
                            loading={<div>Loading PDF...</div>}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            <Page 
                            pageNumber={pageNumber}
                            scale={1.0}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                            />
                        </Document>
                        
                        <PageWrapper>
                            <button onClick={() => pageNumber > 1 && setPageNumber(pageNumber - 1)}>이전</button>
                                <span>{pageNumber} / {numPages}</span>
                            <button onClick={() => pageNumber < numPages && setPageNumber(pageNumber + 1)}>다음</button>
                        </PageWrapper>
                    </>
                    )
                    :
                        <DocViewer documents={[{ uri: context.loadImage(item.url), fileType: fileType}]} pluginRenderers={DocViewerRenderers}
                            config={{
                                header: {
                                    disableHeader: true,
                                    disableFileName: false,
                                    retainURLParams: false
                                }
                            }} />
                    }
                </ViewerWrapper>
                {
                    !hideDownload &&
                    <div className="p-r-35 width-100-percent d-flex">
                        <button
                            className="common-button m-l-auto"
                            onClick={() => onDownload([item.id])}>
                            <img src={"/assets/image/icon_cloud_download.png"} alt="" />
                        </button>
                    </div>
                }
            </CloudViewModalBodyWrapper>
        </Modal>
    );
}

export default React.memo(CloudViewModal);

const ViewerWrapper = styled.div`
    border-top: #eeeef0 1px solid;
    border-bottom: #eeeef0 1px solid;
    height: 600px;
    background: white;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const PageWrapper = styled.div`
    padding: 0 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

const CloudViewModalBodyWrapper = styled(ModalBody)`
    height: 800px !important;
    padding: 40px 0 25px;
    background: #fafafa;
    border-radius: 20px;
`;