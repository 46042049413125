import React, { useContext, useEffect, useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from "styled-components";
import strings from "../../../lang/strings";

import { Page_settings } from '../../../config/page_settings';
import { rootStore } from "../../../mobx/store";
import { API_URL } from "../../../config/const";
import axios from "axios";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import moment from "moment";
import PerfectScrollbar from 'react-perfect-scrollbar';

function WarningModal(props) {
  const [roadmapData, setRoadmapData] = useState();
  
  const [curriculumData, setCurriculumData] = useState(props.curriculumData.parts);
  
  useEffect(() => {
    loadFromSessionStorage();
  }, []);

  

  const handleClose = () => {
    props.onClose?.();
  };

  const history = useHistory();

  // const handleClick = () => {
    
  // };

  const loadFromSessionStorage = () => {
    const step0_data = sessionStorage.getItem('curriculum');
    if (step0_data) {
      const step0Data = JSON.parse(step0_data);
      setRoadmapData(step0Data)
    }

  };

  const addCurriculum = async () => {
    try {
      const curriculum = JSON.parse(sessionStorage.getItem('curriculum'));
      const curriculumImgFile = curriculum.checkBasicImg ? ''  : curriculum.imgFile;
      const curriculumImgName = curriculum.checkBasicImg ? ''  : curriculum.imgName;


      //첫번째로 unit안에 존재하는 파일값들이 전부 있는지 확인후 url로 추출하여 변환해야함 

      // roadmapData에서 imgfile 키를 제거
      const { imgFile, ...updatedRoadmapData } = roadmapData;
      const updatedCurriculumData = await processFiles(curriculumData);
  
      if (curriculumImgFile && curriculumImgName) {
        const roadmapImgUrl = await roadMapImg(curriculumImgFile, curriculumImgName);
        context.post(
          'curriculum/addCurriculum',
          {  
            curriculum: {
              ...updatedRoadmapData,
              thumbnail: roadmapImgUrl // 업로드된 이미지 URL을 roadmapData에 추가
            },
            curriculum_child: updatedCurriculumData,
            mode:1
          },
          response => {
            history.push('/curriculum/create_curriculum_lobby', { showAlert: true });
          }
        );
      } else {
        // 이미지 파일이 없는 경우에도 데이터 전송
        context.post(
          'curriculum/addCurriculum',
          {  
            curriculum: updatedRoadmapData,
            curriculum_child: updatedCurriculumData,
            mode:1
          },
          response => {
            history.push('/curriculum/create_curriculum_lobby', { showAlert: true });
            
          }
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const processFiles = async (curriculumData) => {
    const updatedData = [...curriculumData];
    for (const parts of updatedData) {
      for (const chapter of parts.chapters) {
        for (const section of chapter.sections) {
          section.test.test_url = await handleFileList(section.test.test_url);
          section.test.commentary_url = await handleFileList(section.test.commentary_url);
          for (const unit of section.units) {
            unit.test_url = await handleFileList(unit.test_url);
            unit.commentary_url = await handleFileList(unit.commentary_url);
            unit.video_url = await handleFileList(unit.video_url);
          }
        }
      }
    }
  
    return updatedData;
  };
  
  const handleFileList = async (fileList) => {    
    // url이 "/upload/..." 형태일 경우 그대로 들어감
    if (typeof fileList !== 'object' || !fileList) {  
      return fileList;
    }
    const fileUrls = await sendFile(fileList);
    return fileUrls;
  };

  const client = axios.create({
    baseURL: API_URL + '/app/',
  })

  const sendFile = async (url) => {
    console.log("url",url)
    let formData = new FormData();
    url.forEach((entry, index) => {
      formData.append('file', entry[0]);
    });

    try {
      const response = await client.post(
        'upload/file/curriculum',
        formData,
      );
      console.log("response.data.result_data.file",response.data.result_data.file)

      return response.data.result_data.file; // 리스폰 값을 리턴합니다
    } catch (error) {
      console.error('File upload error:', error);
      return null; // 에러 발생 시 null 리턴
    }

  }

  const roadMapImg = (imgFile, imgName) => {
    return new Promise((resolve, reject) => {
      const arr = imgFile.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
  
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      const url = new File([u8arr], imgName, { type: mime });
  
      let formData = new FormData();
      formData.append('file', url);
  
      context.post(
        'upload/image/thumbnail',
        formData,
        response => {
          if (response.image) {
            
            resolve(response.image);
          } else {
            reject(new Error("Image upload failed"));
          }
        },
        error => {
          reject(error);
        }
      );
    });
  };


  const context = useContext(Page_settings);

  return (
    <>

      <Overlay>
        <ModalWrap>
          <div className='curriculum-warning-modal'>
            <img style={{width: '143px', margin:'0px 40px 0px auto'}} src="/assets/image/img_rai_stop.png" alt='' />
            <div className='warning-comment'>커리큘럼에 필요한 제작을 모두 마쳤습니다. 최종으로 업로드하시겠습니까?</div>
            <div className="container-bottom">
          <button className="no-btn" onClick={()=> handleClose()} >아니요</button>
          <button className="yes-btn" onClick={() => addCurriculum()} >네</button>
        </div>
          </div>
        </ModalWrap>
      </Overlay>
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const ModalWrap = styled.div`
  width: 320px;
  height: fit-content;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
`;
const Button = styled.button`
cursor: pointer;
width: 100%;
color: #ffffff;
border-radius: 5px;
border:0px;
padding: 15px;
display:flex;
align-items: center;
justify-content:center;
font-size:16px;
gap:5px;
`;


export default WarningModal;