import React, { useContext, useEffect, useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from "styled-components";
import strings from "../../../lang/strings";

import { Page_settings } from '../../../config/page_settings';
import { rootStore } from "../../../mobx/store";
import { API_URL } from "../../../config/const";
import axios from "axios";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import moment from "moment";
import PerfectScrollbar from 'react-perfect-scrollbar';

function TeacherCourseInfoModal(props) {

  const [profile, setProfile] = useState("");
  const [detailStatus, setDetailStatus] = useState(null);;
  const color = props.partItem.color;
  const chapterItem = props.chapterItem;
  const sectionItem = props.sectionItem;
  const unitItem = props.unitItem;

  console.log("sectionItem",sectionItem)



  const handleClose = () => {
    props.onClose?.();
  };

  const history = useHistory();

  const context = useContext(Page_settings);
  const limit_time = [];

  useEffect(() => {
    loadStudyData()
    if (rootStore.getProfile != null) {
      setProfile(rootStore.getProfile.profile);
    }
  }, []);

  const loadStudyData = () => {
    context.get(
      'curriculum/getStudyData',
      {
        section: sectionItem.id
      },
      response => {
        // 변환 과정
        if (response) {
          response.forEach(row => {
            // 각 유닛 오브젝트를 찾아서 업데이트
            const unit = sectionItem.units.find(unit => unit.id === row.unit);
            if (unit) {
              unit.solve_count = row.solve_count;
              unit.watch_count = row.watch_count;
              unit.test_count = row.test_count;
              unit.total_member_count = row.total_member_count;
              unit.member_info = row.member_info;
            }
          });
        }
      }
    );
  }

  const changeDetailStatus = (member_info, idx) => {
    if (member_info) {
      setDetailStatus(idx)
    } else {
      alert('완료한 학생이 없습니다.')
    }
  }

  const unitConfirm = (unit) => {
    if((unit?.link|| unit?.video_url) || (unit?.test_url || unit?.commentary_url)){
      return true;
    }else{
      return false;
    }
  }


  const allConfirm = (section,unit) => {
    if((unit?.link || unit?.video_url) && ( (unit?.test_url && unit?.test_url.length > 0) && (unit?.commentary_url && unit?.commentary_url.length > 0)) && (section?.answer_data && section?.commentary_url && section?.test_url && section?.time > 0)){
      return true;
    }else{
      return false;
    }
  }
  console.log("sectionItem",sectionItem)

  return (
    <>

      <Overlay>
        <ModalWrap>
          <div className='teacher-course-info-container'>
            <div className='modal-header'>
              <div className='title'>{chapterItem.name}</div>
              <img class="exit-btn cursor" onClick={handleClose} src="/assets/image/icon_close_gray.png" />
            </div>
            <div className='middle'>
              <div className='title'>{sectionItem.name}</div>
              <div className={`course-container ${color}`}>
                <PerfectScrollbar className="partCategory-container" style={{ height: '350px', padding: '20px 20px 0px 0px' }} options={{ suppressScrollX: true }}>


                  {sectionItem.units.map((unit, unitIdx) => (
                    <div className='course-row'>
                      <div className='number'>
                        <div className='number-box'>
                          {unitIdx + 1}
                        </div>
                        {sectionItem.units.length > 1 && unitIdx !== sectionItem.units.length - 1 && (
                          <div className='course-index-arrow'>
                            <img src='/assets/image/icon_arrow_down_gray.png' />
                          </div>
                        )}
                      </div>

                      <div className={`course-box ${allConfirm(sectionItem,unit) ? color + ' perfect' : ''}`}>
                        {detailStatus === unitIdx ?
                          <>
                            <div className='detail-header'>
                              <img className='cursor' src="/assets/image/icon_back_gray.png" onClick={() => setDetailStatus(null)} alt='' />
                              <div className='title'>유닛 완료한 학생 목록</div>
                            </div>

                            <PerfectScrollbar className='student-list-container' style={{ height: '60px' }} options={{ suppressScrollX: true }}>
                              {unit.member_info.map((item, idx) => (
                                <div className='student-info-box'>
                                  <div className='profile'>
                                    <img src={item.profile != null ? context.loadImage(item.profile) : "/assets/image/img_student_non_profile.png"} alt="" />
                                  </div>
                                  <div className='student-info'>
                                    <div className='name'>{item.name}</div>
                                    <div className='grade-classroom'>
                                      {item.grade ? item.grade : "@"}학년 {item.classroom ? item.classroom : "@"}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </PerfectScrollbar>

                          </>

                          :
                          <>
                            <div className='title'>
                              {unit.name}
                            </div>
                            <div className='info-box'>
                              {
                                (unit.video_url || unit.link) ? 
                                <div className='info-row'>
                                <div className='left'>
                                영상 시청 현황
                                </div>
                                <div className='right'>
                                      {`${unit.watch_count} / ${unit.total_member_count}명`}
                                </div>
                              </div>
                                :null
                              }
                           
                              {
                                (unit?.commentary_url&& unit?.commentary_url.length>0 )?
                                <div className='info-row'>
                                <div className='left'>
                                  문제풀이 현황
                                </div>
                                <div className='right'>
                                  {`${unit.solve_count} / ${unit.total_member_count}명`}
                                </div>
                              </div>
                                :null

                              }
                             
                              <div className='info-row'>
                                <div className='left'>
                                  테스트 현황
                                </div>
                                <div className='right'>
                                  {
                                    (sectionItem.test.answer_data || unit.test_count) 
                                      
                                        ? `${unit.test_count} / ${unit.total_member_count}명`
                                        
                                      : null
                                  }
                                </div>
                              </div>
                            </div>
                            <button className={`detail-btn ${color}`} onClick={() => changeDetailStatus(unit.member_info, unitIdx)}>전체 완료 학생</button>
                          </>
                        }
                      </div>

                    </div>
                  ))}





                  {/* <img class="exit-btn cursor" src="/assets/image/icon_arrow_down_gray.png" /> */}
                </PerfectScrollbar>
              </div>
            </div>
          </div>
        </ModalWrap>
      </Overlay>
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalWrap = styled.div`
  width: 680px;
  height: 450px;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  
`;



export default TeacherCourseInfoModal;