import React from 'react';
import { IconType } from '../../type/icon_type';

export const IconStudy = ({color="#73787E", width="24px", height="24px", style}: IconType) => {
    return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        <path d="M10 3V10.2L13 7.5L16 10.2V3M4 18.75V5.25C4 4.65326 4.26339 4.08097 4.73223 3.65901C5.20107 3.23705 5.83696 3 6.5 3H19C19.2652 3 19.5196 3.09482 19.7071 3.2636C19.8946 3.43239 20 3.66131 20 3.9V20.1C20 20.3387 19.8946 20.5676 19.7071 20.7364C19.5196 20.9052 19.2652 21 19 21H6.5C5.83696 21 5.20107 20.7629 4.73223 20.341C4.26339 19.919 4 19.3467 4 18.75ZM4 18.75C4 18.1533 4.26339 17.581 4.73223 17.159C5.20107 16.7371 5.83696 16.5 6.5 16.5H20" stroke={color} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    )
}

export const IconStudyFilled = ({color="#73787E", width="24px", height="24px", style}: IconType) => {
    return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        <path d="M10 10.2V3H6.5C5.83696 3 5.20107 3.23705 4.73223 3.65901C4.26339 4.08097 4 4.65326 4 5.25V18.75C4 18.1533 4.26339 17.581 4.73223 17.159C5.20107 16.7371 5.83696 16.5 6.5 16.5H20V3.9C20 3.66131 19.8946 3.43239 19.7071 3.2636C19.5196 3.09482 19.2652 3 19 3H16V10.2L13 7.5L10 10.2Z" fill={color}/>
        <path d="M4 18.75V5.25C4 4.65326 4.26339 4.08097 4.73223 3.65901C5.20107 3.23705 5.83696 3 6.5 3M4 18.75C4 19.3467 4.26339 19.919 4.73223 20.341C5.20107 20.7629 5.83696 21 6.5 21H19C19.2652 21 19.5196 20.9052 19.7071 20.7364C19.8946 20.5676 20 20.3387 20 20.1V3.9M4 18.75C4 18.1533 4.26339 17.581 4.73223 17.159C5.20107 16.7371 5.83696 16.5 6.5 16.5H20V3.9M6.5 3H19M6.5 3H10V10.2L13 7.5L16 10.2V3H19M19 3C19.2652 3 19.5196 3.09482 19.7071 3.2636C19.8946 3.43239 20 3.66131 20 3.9" stroke={color} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    )
}
