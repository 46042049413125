import React, {useContext, useState} from "react";
import {Page_settings} from "../../../config/page_settings";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import { PiMicrophoneFill, PiMicrophoneSlashFill } from "react-icons/pi";
import { MdVideocam, MdVideocamOff } from "react-icons/md";
import strings from "../../../lang/strings";
import styled from "styled-components";
import { rootStore } from "../../../mobx/store";


interface IParticipantItemProps {
    item: any;
    onKick: () => void;
    index: number;
    selected: boolean;
    setSelectedParticipantIndex: (index: number) => void;
    setMemberInfo: (item: any) => void;
    isOwner: boolean;
}

const ParticipantItem = (props: IParticipantItemProps) => {
    const context: any = useContext(Page_settings)
    const {item, onKick, index, selected, setSelectedParticipantIndex, setMemberInfo, isOwner } = props;

    return (
        <ParticipantWrapper>
            <ProfileAndNameWrapper>
                <ProfileCircle>
                    <img className="profile"
                        src={item.user.profile != null ? context.loadImage(item.user.profile) : "/assets/image/chewing_plus_logo.png"} alt="" 
                        width={25} height={25}/>
                </ProfileCircle>
                <NameWrapper>{item.user.name}</NameWrapper>
            </ProfileAndNameWrapper>
            <StatusDropdownWrapper>
                <div style={{marginRight: 15}}>
                {item.user.video === "ON" ? <MdVideocam color='white' size={18}/> : <MdVideocamOff color='red' size={18}/>}
                </div>
                <div style={{marginRight: 15}}>
                {item.user.speaker === "ON" ? <PiMicrophoneFill color='white' size={18}/> : <PiMicrophoneSlashFill color='red' size={18}/>}
                </div>
                { item && item.user.user_type === "STUDENT" ? <Dropdown isOpen={selected} toggle={
                    () => { 
                        if (selected) {
                            setSelectedParticipantIndex(-1);
                        } else {
                            setSelectedParticipantIndex(index);
                        }}} className="participant-dropdown more">
                    <DropdownToggle tag="a" style={{paddingLeft: 5, paddingRight: 5}}>
                        <img src={"/assets/image/icon_more_gray.png"} alt=""/>
                    </DropdownToggle>
                    <DropdownMenu className="media-list dropdown-menu plan-dropdown" tag="ul" style={{position: "absolute", left: -20 }}>
                        <DropdownItem onClick={() => setMemberInfo(item)}>
                            {strings.info}
                        </DropdownItem>
                        {
                            isOwner &&
                            <DropdownItem onClick={() => onKick()} className="kick-out">
                                {strings.kick_out}
                            </DropdownItem>
                        }
                    </DropdownMenu>
                </Dropdown> : <div style={{width: 10}}></div>}
            </StatusDropdownWrapper>
        </ParticipantWrapper>
    );
}

export default ParticipantItem;

const ParticipantWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0px;
`;

const ProfileAndNameWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const ProfileCircle = styled.div`
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
`;

const NameWrapper = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
    margin-left: 10px;
`;

const StatusDropdownWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px 10px 20px;
`