import React, { useState } from "react";
import MoreButton from "../button/more_button";
import PlanInput from "../custom_input/plan_input";
import { isMobile } from "react-device-detect";
import { checkIconList, colorList } from "../../../config/const";

const PlanItem = (props) => {
    const [hover, setHover] = useState(false);

    let { item, value, onEdit, onDelete, onConfirm, onCheckTodo, onChange } = props;
    return (
        <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            {!item.editable &&
                <div className="mission-item">
                    <button style={{ border: "none", background: "none", padding: 0 }} onClick={() => onCheckTodo()}>
                        <img className="todo-icon"
                            src={item.status == "Y" ? checkIconList[colorList.indexOf(item.color)] : ("/assets/image/icon_todo_check_normal_darkgray.png")}
                            alt="" />
                    </button>
                    <div className="mission-detail plan-item">{item.title}</div>
                    {
                        (isMobile || hover) &&
                        <MoreButton
                            onEdit={() => onEdit()}
                            onDelete={() => onDelete()}
                        />
                    }
                </div>
            }
            {
                item.editable &&
                <PlanInput
                    value={value}
                    type={"text"}
                    onChange={(text) => onChange(text)}
                    onAddPlan={() => onConfirm()}
                />
            }
        </div>
    );
}

export default PlanItem;
