import React, { useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { Page_settings } from "../../../config/page_settings";
import { getTimeFromSecond } from "../../../helper/common";
import { rootStore } from "../../../mobx/store";

interface IRoomMember {
    user: {
        id: number;
        name: string;
        video: "ON" | "OFF";
        speaker: "ON" | "OFF";
        mirror: "ON" | "OFF";
        profile: string;
        studyTime?: number;
        studyStartTime?: Date;
    };
    member: number;
    videoTrack?: any;
    audioTrack?: any;
    reaction?: IReaction;
}

interface IReaction {
    icon: string;
    desc: string;
}

interface IRoomItemProps {
    item: IRoomMember;
    videoTrack: any;
    now: Date;
}

const RoomItem = (props: IRoomItemProps) => {
    const context: any = useContext(Page_settings);
    const { item, now, videoTrack} = props;

    const getStudyTime = (study_time: number, now: Date, study_start_time?: Date) => {
        return getTimeFromSecond(study_time + (study_start_time ? Math.floor((now.getTime() - study_start_time.getTime()) / 1000) : 0));
    }

    const videoRef = useRef(null);
    useEffect(() => {
        if (videoTrack && videoRef.current) {
            // videoTrack을 video 요소에 연결
            videoTrack.play(videoRef.current);
        }

        // 컴포넌트가 언마운트될 때 비디오 트랙 정리
        return () => {
            if (videoTrack) {
                videoTrack.stop();
            }
        };
    }, [videoTrack]);

    return (
        <StudyRoomItem id={`stream-player-${item.user?.id}`}>
            {
                (videoTrack == undefined || videoTrack == null) &&
                <div
                    className="width-100-percent height-full d-flex align-item-center justify-content-around" style={{ position: 'absolute', left: 0, top: 0 }}>
                    <img src={item.user?.profile != null && item.user?.profile != "" ? context.loadImage(item.user?.profile) : "/assets/image/chewing_plus_logo.png"}
                        style={{ width: 120, height: 120, borderRadius: 60, objectFit: 'cover' }}
                        alt=""
                    />
                </div>
            }
            {
                videoTrack != undefined && videoTrack != null &&
                <video
                    ref={videoRef}
                    autoPlay
                    playsInline
                    style={{ 
                        width: '100%', 
                        height: '100%', 
                        objectFit: 'cover', 
                        transform: rootStore.getProfile.id === item.user?.id 
                            ? (item.user?.mirror === "ON" ? 'scaleX(-1)' : 'scaleX(1)')
                            : (item.user?.mirror === "ON" ? 'scaleX(1)' : 'scaleX(-1)')
                    }}
                />
            }
            <Status>
                <StatusUser>{item.user?.name}</StatusUser>
                <div>{getStudyTime(item.user.studyTime, now, item.user.studyStartTime)}</div>
            </Status>
            {
                item.reaction != undefined && item.reaction != null &&
                <div className="width-100-percent height-full d-flex align-item-center justify-content-around" style={{ position: 'absolute', left: 0, top: 0, background: '#000000D4' }}>
                    <img src={item.reaction.icon} alt="" />
                </div>
            }
        </StudyRoomItem>
    );
}

export default RoomItem;

const StudyRoomItem = styled.div`
    width: 20%;
    border: black 1px solid;
    height: 180px;
    background-size: cover;
    display: flex; 
    position: relative;
`;

const Status = styled.div`
    width: 120px;
    height: 35px;
    font-size: 13px;
    background: black;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    position: absolute;
    z-index: 999;
`;

const StatusUser = styled.div`
    color: #89888e;
    margin-right: 10px;
    max-width: 46%;
    overflow: hidden;
    text-overflow: ellipsis;
`;