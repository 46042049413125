import React, { useState } from "react";
import strings from "../../../lang/strings";
import { withRouter } from "react-router-dom";
import { inject } from "mobx-react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { reactionList } from "../../../config/const";
import PerfectScrollbar from "react-perfect-scrollbar";
import ParticipantItem from "../../../components/control/item/participant_item";
import styled from "styled-components";
import { PiSquareSplitHorizontalFill } from "react-icons/pi";
import { MdOutlineMonitor, MdSentimentVerySatisfied } from "react-icons/md";
import { FaUser } from "react-icons/fa6";
import { IoMdSettings } from "react-icons/io";
import { FiLogOut } from "react-icons/fi";
import { rootStore } from "../../../mobx/store";

interface ConferenceBottomProps {
    onInvite: () => void;
    onKick: (item) => void;
    onInfo: (item) => void;
    onClickReaction: (item) => void;
    onShare: () => void;
    onSetting: () => void;
    onOut: () => void;
    memberList: any;
    participants: number;
    mine: boolean;
    onSplit: () => void;
    isSplit: boolean;
    isShare: boolean;
    roomOwner: number;
}

const ConferenceBottom = (props: ConferenceBottomProps) => {
  const {
    onInvite,
    onKick,
    onInfo,
    onClickReaction,
    onShare,
    onSetting,
    onOut,
    memberList,
    participants,
    mine,
    onSplit,
    isSplit,
    isShare,
    roomOwner
  } = props;

  const [showReaction, setShowReaction] = useState(false);
  const [showParticipant, setShowParticipant] = useState(false);
  const [selectedParticipantIndex, setSelectedParticipantIndex] = useState<number>(-1);
  const profile_id = rootStore.getProfile.id;


  const onReaction = () => {
    setShowReaction(true);
  };

  const toggleReaction = () => {
    setShowReaction(!showReaction);
  };

  const toggleParticipant = () => {
    setShowParticipant(!showParticipant);
  };

  return (
    <StudyRoomBottomContainer>
      <div className="flex-grow-1" />
      <div className="m-l-5" />
      <div className="position-relative">
        <Dropdown isOpen={showParticipant} toggle={() => toggleParticipant()}>
          <DropdownToggle className="display-none" />
          <DropdownMenu
            className="media-list dropdown-menu participant-container"
            tag="ul"
          >
            <PerfectScrollbar
              className="participant-scroll"
              options={{ suppressScrollX: true }}
            >
              <button className="invite" onClick={() => onInvite()}>
                <img src={"/assets/image/icon_person_add_white.png"} alt="" />
                <div className="f-s-14 m-l-5">{strings.invite}</div>
              </button>
              {memberList.map((item, idx) => {
                return (
                  <ParticipantItem
                    key={idx}
                    item={item}
                    index={idx}
                    onKick={() => onKick(item)}
                    selected={selectedParticipantIndex == idx}
                    setSelectedParticipantIndex={setSelectedParticipantIndex}
                    setMemberInfo={() => onInfo(item)}
                    isOwner={roomOwner == profile_id}
                    isMultiRoom={true}
                  />
                );
              })}
            </PerfectScrollbar>
          </DropdownMenu>
        </Dropdown>
        <StudyBottomButtonWrapper isActive={false} onClick={() => setShowParticipant(true)}>
            <FaUser color="white" size={16} />
            <div className="number-badge">{participants}</div>
        </StudyBottomButtonWrapper>
      </div>
      <div className="m-l-5" />
      <div className="position-relative">
        <Dropdown isOpen={showReaction} toggle={() => toggleReaction()}>
          <DropdownToggle className="display-none" />
          <DropdownMenu
            className="media-list dropdown-menu reaction-container"
            tag="ul"
          >
            {reactionList.map((item, idx) => {
              return (
                <button
                  className="common-button reaction-button"
                  key={idx}
                  onClick={() => {
                    onClickReaction(item);
                    setShowReaction(false);
                  }}
                >
                  <img src={item.icon} alt="" />
                </button>
              );
            })}
          </DropdownMenu>
        </Dropdown>
        <StudyBottomButtonWrapper isActive={false} onClick={() => onReaction()}>
            <MdSentimentVerySatisfied color="white" size={25} />
        </StudyBottomButtonWrapper>
      </div>
      <div className="m-l-5" />
      {mine && (
        <>
          <StudyBottomButtonWrapper isActive={isShare} onClick={() => onShare()}>
            <MdOutlineMonitor color={isShare ? "black" : "white"} size={25} />
          </StudyBottomButtonWrapper>
          <div className="m-l-5" />
          <StudyBottomButtonWrapper isActive={isSplit} onClick={() => onSplit()}>
            <PiSquareSplitHorizontalFill color={ isSplit ? "black" : "white" } size={25} />
          </StudyBottomButtonWrapper>
          <div className="m-l-5" />
        </>
      )}
      <StudyBottomButtonWrapper isActive={false} onClick={() => onSetting()}>
        <IoMdSettings color="white" size={25} />
      </StudyBottomButtonWrapper>
      <div className="m-l-5" />
        <StudyBottomButtonWrapper isActive={false} onClick={() => onOut()}>
            <FiLogOut color="red" size={22} />
        </StudyBottomButtonWrapper>
      <div className="flex-grow-1" />
    </StudyRoomBottomContainer>
  );
};

export default withRouter(inject("rootStore")(ConferenceBottom));

const StudyRoomBottomContainer = styled.div`
    width: 100%;
    height: 75px;
    position: absolute;
    bottom: 0;
    background: #353443;
    left: 0;
    display: flex;
    align-items: center;
    padding: 0 360px 0 30px;`;

interface StudyBottomButtonProps {
  isActive?: boolean;
}

const StudyBottomButtonWrapper = styled.button<StudyBottomButtonProps>`
  width: 38px;
  height: 38px;
  margin: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: ${(props) => (props.isActive ? "#04D891" : "#1F1F1F")};
  .number-badge {
    position: absolute;
    top: -5px;
    right: 18px;
    height: 20px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
`;
