import React, { useState, useRef, useContext, useEffect } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import styled from "styled-components";
import strings from "../../../lang/strings";
import OrgaSearch from "../customSearch/orgaSearch";
import ClassSearch from "../customSearch/classSearch";
import { Page_settings } from "../../../config/page_settings";
import PerfectScrollbar from "react-perfect-scrollbar";
import Form from 'react-bootstrap/Form';


function ClassAddModal({ onClose }) {
  const context = useContext(Page_settings);
  const [choiceYear, setChoiceYear] = useState("연도");
  const [choiceGrade, setChoiceGrade] = useState("학년");
  const [inputClass, setInputClass] = useState("");
  const imageRef = useRef();
  const [imgFile, setImgFile] = useState("")
  const [url, setUrl] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [selectThumbnail, setSelectThumbnail] = useState(false);
  const [schoolListAll, setSchoolListAll] = useState([]);
  const [schoolSelected, setSchoolSelected] = useState(false);
  const [schoolList, setSchoolList] = useState([]);
  const [schoolName, setSchoolName] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [noSchool,setNoSchool] = useState(false); // 학교 검색 끄기

  useEffect(() => {
    getSchoolListAll();
}, []);


const getSchoolListAll = async () => {
  context.get(
      'member/getSchoolList',
      {
          keyword: schoolName
      },
      response => {
          setSchoolListAll(response.list);
      });
}

const onChange = (v) => {
  setSchoolName(v);

  let list = [];
  for (let i = 0; i < schoolListAll.length; i++) {
      if (schoolListAll[i].schoolNm.toLowerCase().indexOf(v.toLowerCase()) > -1) {
          list.push(schoolListAll[i].schoolNm);
      }
  }
  setSchoolList(list);
  setShowSearch(v != "");
  setSchoolSelected(false);
}


  const handleClose = () => {
    onClose?.();
  };

  const onImageChange = (e) => {
    if (e.target.files.length < 1) {
        return;
    }
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.onloadend = function () {
        setSelectThumbnail(true);
        setImgFile(reader.result);
        setUrl(file);
        // setEdited(true);
    };
    reader.readAsDataURL(file);
};

const EnableConfirm = () => {
  return (
    schoolName !== '' && choiceGrade !== '학년' && inputClass !== ''
  )
}



// 모든 필드가 비어있지 않으면 확인 버튼 활성화

const onAddClass = () => {
  console.log("imgae url",url)

  if (selectThumbnail) {
      let formData = new FormData();
      formData.append('file', url);

      context.post(
          'upload/image/thumbnail',
          formData,
          response => {
            console.log("response.image",response.image)
              setThumbnail(response.image);
              sendClassData(response.image);
          });
  } else {
    sendClassData();
  }
}


  const sendClassData = (image) =>{
    window.location.reload();
    context.post(
      'class/addClass',
      {
        class_name : schoolName,
        class_grade : choiceGrade,
        class_class : inputClass,
        class_thumbnail : image ?? thumbnail,
      },
      response => {
        
        
      });
  }

  const handleCheckboxChange = (event) => {
    setNoSchool(event.target.checked);
  };

  return (
      <Overlay>
        <ModalWrap>
          <div class="classAddModal-head">
            <h4>클래스 추가</h4>
            <img onClick={handleClose} src="/assets/image/icon_close_black.png"/>
          </div>
          <div class="classAddModal-subTitle">
            기관 검색
          </div>
          <div>
          <OrgaSearch
            placeholder={strings.organization_search_placeholder}
            inputType={'text'}
            value={schoolName}
            // onChange={(v) => {
            //   setOrgaSearchValue(v)
            // }}
            onChange={(e) => onChange(e)}
            onSearch={() => {
              //여기에 기관 뜨는 데이터 넣어야할듯 
            }}
        />
          </div>
          <Form>
        {['checkbox'].map((type) => (
        <div key={`default-${type}`} className="mb-3" style={{marginTop:'10px'}}>
          <Form.Check
            type={type}
            id={`default-${type}`}
            label={'학교가 아니에요 (자유입력)'}
            onChange={handleCheckboxChange} 
            checked={noSchool}
          />
        </div>
      ))}
      </Form>
                    {
                      
                      showSearch && !noSchool && (
                        <PerfectScrollbar className="school-result" options={{suppressScrollX: true}}>
                        {
                        schoolList.map((item, idx) => {
                            return (
                                <button className="search-item" key={idx} onClick={() => {
                                    setSchoolName(item);
                                    setSchoolSelected(true);
                                    setShowSearch(false);
                                }}>
                                    <img src={"/assets/image/icon_search_black.png"} alt=""/>
                                    <div className={"d-flex"}>
                                        {
                                            item.split("").map((char, charIdx) => {
                                                let searchIdx = item.toLowerCase().indexOf(schoolName.toLowerCase())
                                                return (
                                                    <div
                                                        className={(searchIdx == -1 ? '' : (charIdx >= searchIdx && charIdx < searchIdx + schoolName.length) ? 'searched ' : '') + (char == " " ? "space-char" : "")}
                                                        key={charIdx}>{char}</div>
                                                )
                                            })
                                        }
                                    </div>
                                </button>
                            )
                        })
                    }
                        </PerfectScrollbar>
                        )   
                    }


          <div className="classAddModal-secondContent">

            <div className="col-md-3">
              <div class="classAddModal-subTitle">학년</div>
              <Dropdown>
                 <Dropdown.Toggle variant="success" id="dropdown-basic" className="classAddModal-dropdown">
                  {choiceGrade}
                 </Dropdown.Toggle>
                  <Dropdown.Menu>
                    { strings.class_grade.map((item,index)=>{
                      return(
                      <Dropdown.Item onClick={()=> {setChoiceGrade(item.grade)}}>
                        {item.grade}
                      </Dropdown.Item>
                    )
                    })}
                  </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-1"/>
            <div className="col-md-8">
              <div class="classAddModal-subTitle">반</div>
              <ClassSearch
                
                placeholder={strings.class_input_placeholder}
                inputType={'text'}
                value={inputClass}
                onChange={(v) => {
                  setInputClass(v)
                }}
                onSearch={() => {
                  
                }}
        />
            </div>
            
          </div>
          <input type='file' accept='image/*' className='hide'
                           onChange={(e) => onImageChange(e)}
                           ref={imageRef}/>
                           
          <div class="classAddModal-subTitle">
            섬네일 이미지
          </div>
          <div class="classAddModal-imgAdd" onClick={() => imageRef?.current.click()}>
            {selectThumbnail ? 
            <img class= "classAddModal-thumbnail" src = {imgFile} alt=""/>
            :
            <>
            <img class= "classAddModal-no-thumbnail" src="/assets/image/class_addImg1.png" alt=""/>
            </>
            }
            
          </div>
          <Button style={{backgroundColor: EnableConfirm() ? '#0F84F4' : '#DFE3E8'}}disabled ={!EnableConfirm()} onClick={onAddClass}>확인</Button>
        </ModalWrap>
      </Overlay>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const ModalWrap = styled.div`
  width: 400px;
  height: fit-content;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
`;

const CloseButton = styled.div`
  float: right;
  width: 40px;
  height: 40px;
  margin: 20px;
  cursor: pointer;
  i {
    color: #5d5d5d;
    font-size: 30px;
  }
`;

const Contents = styled.div`
  margin: 50px 30px;
  h1 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 60px;
  }
  img {
    margin-top: 60px;
    width: 300px;
  }
`;
const Button = styled.button`
  cursor: pointer;
  width: 100%;
  color: #ffffff;
  border-radius: 5px;
  border:0px;
  padding: 10px;
  margin-top: 20px;
`;
export default ClassAddModal;