import React from "react";

const HomeSec6 = () => {
    return (
        <>
            <section className="wrap main-sec6">
                <div className="inner">
                    <div className="main-tit">
                        <h4>
                            <img src="/assets/image/landing_page/main/sec6-tit.svg" alt="로드맵 아이콘" />
                            유닛별 로드맵
                        </h4>
                        <h2>
                            비효율적인 학습은 그만 <br />
                            유닛별 로드맵으로 빠른 약점보완
                        </h2>
                        <p>과목별로 유닛별 학습 로드맵 제공</p>
                    </div>
                    <div className="sec6-con">
                        <div className="box1">
                            <div className="txt-box">
                                <h3>
                                    시작이 어려운 학생들, <br />
                                    <span>취약점에 따른 유닛별 로드맵으로 이해도 향상!</span>
                                </h3>
                            </div>
                            <div className="img-box">
                                <img src="/assets/image/landing_page/main/sec6-box1.png" alt="" />
                            </div>
                        </div>
                        <div className="box2 wid-50">
                            <div className="txt-box">
                                <h6>취약점별 콘텐츠 제공</h6>
                                <h3>학습에 필요한 자료 한번에</h3>
                            </div>
                            <div className="img-box">
                                <img src="/assets/image/landing_page/main/sec6-box2.png" alt="" />
                            </div>
                        </div>
                        <div className="box3 wid-50">
                            <div className="txt-box">
                                <h6>유닛별 테스트</h6>
                                <h3>제대로 이해했는지 한번 더 확인</h3>
                            </div>
                            <div className="img-box">
                                <img src="/assets/image/landing_page/main/sec6-box3.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeSec6;