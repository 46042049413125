import React from "react"
import { IconType } from "../../type/icon_type"

const IconEyeClosed = ({ width="24px", height="24px", color="#111827" }: IconType) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.4 16L13.8224 13.4M4 8C4.59868 9.64062 5.6873 11.0575 7.11832 12.0586C8.54934 13.0598 10.2536 13.5967 12 13.5967C13.7464 13.5967 15.4507 13.0598 16.8817 12.0586C18.3127 11.0575 19.4013 9.64062 20 8M18.4 13.6L17.0192 11.96M5.6 13.6L6.9808 11.96M9.6 16L10.1776 13.4" stroke={color} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

const IconEyeOpen = ({ width="24px", height="24px", color="#111827" }: IconType) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.05625 12.2983C2.98125 12.1059 2.98125 11.8941 3.05625 11.7017C3.78675 10.0146 5.02672 8.57202 6.61897 7.55695C8.21121 6.54188 10.084 6 12 6C13.916 6 15.7888 6.54188 17.381 7.55695C18.9733 8.57202 20.2133 10.0146 20.9437 11.7017C21.0188 11.8941 21.0188 12.1059 20.9437 12.2983C20.2133 13.9854 18.9733 15.428 17.381 16.443C15.7888 17.4581 13.916 18 12 18C10.084 18 8.21121 17.4581 6.61897 16.443C5.02672 15.428 3.78675 13.9854 3.05625 12.2983Z" stroke={color} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 14.5717C13.4911 14.5717 14.6999 13.4203 14.6999 12C14.6999 10.5797 13.4911 9.42834 12 9.42834C10.5089 9.42834 9.30014 10.5797 9.30014 12C9.30014 13.4203 10.5089 14.5717 12 14.5717Z" stroke={color} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}   

export { IconEyeClosed, IconEyeOpen };
