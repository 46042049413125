import React, { useContext, useEffect, useState } from "react";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withRouter } from "react-router-dom";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import styled from "styled-components";
import { Page_settings } from "../../../config/page_settings";
import strings from "../../../lang/strings";
import EditThumbnailModal from "./edit_thumbnail_modal";

const EditCurriculumStep1 = (props) => {

  
  const history = useHistory();
    const context = useContext(Page_settings);
    const [openCreateRoadMap, setOpenCreateRoadMap] = useState(false);
    const [titleValue,setTitleValue] = useState('');
    const [partTitleValue,setPartTitleValue] = useState('');
    const [categoryBox,setCategoryBox] = useState(false); //카테고리 박스 켜져있는지 유무
    const [categoryColor,setCategoryColor] = useState(props?.lastClickPart?.color ? props?.lastClickPart?.color : strings.curriculum_part_category[0].color); //카테고리 색상값
    const [categorySubject,setCategorySubject] = useState(props?.lastClickPart?.subject ? props?.lastClickPart?.subject :strings.curriculum_part_category[0].subject); //카테고리 과목
    const [partCategory, setPartCategory] = useState([]); // 대단원 항목 데이터
    const [editStatus, setEditStatus] = useState(false);
    const [warningPoint, setWarningPoint] = useState(false);
    const [openGuide, setOpenGuide] = useState(false)
    

    useEffect(() => {
      
      if (props.curriculumData && props.curriculumData.parts) {
        const formattedParts = props.curriculumData.parts.map(part => ({
          id:part.id,
          subject:part.subject,
          color:part.color,
          name: part.name,
          chapters: part.chapters
        }));
        setPartCategory(formattedParts);
      }
    }, [props.curriculumData]);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (!event.target.closest('.guide-img')) {
          setOpenGuide(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
  

    const openCreateRoadmapModal = () => {
        setOpenCreateRoadMap(true)
      }

      const changeInputValue = event => {
        setPartTitleValue(event.target.value)
        
      }

      const openCategoryBox = event => {
        setCategoryBox(!categoryBox)
      }


      const addPart = (subject, color, title) => {
        if (partTitleValue.trim() === '') {
          return false;
        }
      
        const newPart = {
          subject,
          color,
          name: title
        };
      
        const updatedParts = [newPart , ...(props.curriculumData.parts || [])];
        props.updatePartCategory(updatedParts);
        setPartTitleValue('');
      }

      const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          addPart(categorySubject, categoryColor, partTitleValue);
        }
      };

      const clickCategory = (subject,color) => {
        setCategorySubject(subject)
        setCategoryColor(color)
        setCategoryBox(false)
        props.updateLastClickPart(color,subject)
      }

      const handleRemoveClick = (id,index) => {
        const updatedParts = props.curriculumData.parts.filter((_, idx) => idx !== index);
        // deletePart(id);
        props.updatePartCategory(updatedParts);
      };
      
      const handleUpClick = (index) => {
        if (index > 0) {
          const newPartCategory = [...props.curriculumData.parts];
          [newPartCategory[index - 1], newPartCategory[index]] = [newPartCategory[index], newPartCategory[index - 1]];
          props.updatePartCategory(newPartCategory);
        }
      };
      
      const handleDownClick = (index) => {
        if (index < props.curriculumData.parts.length - 1) {
          const newPartCategory = [...props.curriculumData.parts];
          [newPartCategory[index], newPartCategory[index + 1]] = [newPartCategory[index + 1], newPartCategory[index]];
          props.updatePartCategory(newPartCategory);
        }
      };

      // const deletePart = (id) => {
        
      //   context.get(
      //     'curriculum/deletePart',
      //     {  
      //       part: id
      //     },
      //     response => {
      //     }
      //   );
      // }



  const EnableConfirm = () => {
    return (
      partCategory.length > 0
    )
  }


      const onNext = async () => {
      
        history.push(`/curriculum/edit_curriculum/${props.curriculum}/2`);
      }


      const goBack = () => {
        history.push('/curriculum/create_curriculum_lobby');
       }

   


    return(
    <div class="main-content create-curriculum-step1-container">
      
      <div class="top content-container">
        <div class="progress-container">
          <div class="progress-box">
            <div class="progress"/>
          </div>
          <div class="step"><span>1</span> / 5</div>
        </div>
        <div class="flex-box">
          <div className="title-box">
          <img className="cursor" src="/assets/image/icon_arrow_back_black.png" alt="" onClick={()=>goBack()}/>
            <div className="title">
              {props?.curriculumData?.thumbnail?.title}
            </div>
            <img className="cursor" src="/assets/image/icon_edit_btn_gray.png" alt="" onClick={()=>setEditStatus(true)}/>
          </div>
          <div className="curriculum-guide-box">
            {/* <img src="/assets/image/icon_warning_point.png" alt="" onClick={()=>setWarningPoint(true)}/> */}
            <img src="/assets/image/icon_curriculum_guide.png" style={{width:'65px'}} alt="" onClick={()=>setOpenGuide(true)}/>
            {openGuide &&
            <img className="guide-img" src="/assets/image/img_curriculum_guide_1.png" alt="" />
    }
          </div>
        </div>

      </div>
      {editStatus && (<EditThumbnailModal
          open={editStatus}
          onClose={() => {
            setEditStatus(false);
          }}
          curriculumData={props.curriculumData}
          updateThumbnail={props.updateThumbnail}
        />)} 
      
      <div className="middle content-container">
        <div className="title">대단원 추가하기</div>
        <div className="class-search  align-item-center box-shadow" style={{ flex: 8 }}  >
              <div className={`category cursor ${categoryColor}`} onClick={openCategoryBox}/>
              
                <input
                  id='inputBox'
                  className="input-transparent cursor"
                  placeholder={"대단원 이름을 적어주세요"}
                  inputType={'text'}
                  value={partTitleValue}
                  onChange={changeInputValue}
                  onKeyPress={handleKeyPress}
                />
                
                <img className='cursor add' src={"/assets/image/icon_plus_gray.png"} alt='' onClick={()=>addPart(categorySubject,categoryColor,partTitleValue)} />
                {
                  categoryBox ?
                  <div className="category-container">
                  {strings.curriculum_part_category.map((item, index) => (
                    <div className="category-box cursor" key={index} onClick={() => clickCategory(item.subject,item.color)}>
                      <div className={`category ${item.color}`}/>
                      <div>{item.subject}</div>
                    </div>
                  ))}
                  </div>
                : null
                
                }
          </div>
      </div>

      <div className="bottom content-container">
        <div className="container-top">
        <div className="title">대단원 항목</div>
        
        {
                  partCategory.length > 0 ?
                  // <div >
                  <PerfectScrollbar className="partCategory-container" style={{height: 'calc(100vh - 600px)'}} options={{ suppressScrollX: true }}>
                  {partCategory.map((item, index) => (
                    <div className="row-box" key={index}>
                      <div class="arrow-buttons">
                        <button className="up-btn" onClick={() => handleUpClick(index)}>▲</button>
                        <button className="down-btn" onClick={() => handleDownClick(index)}>▼</button>
                      </div>
                      <div className="partCategory-box">
                        <div className="left">
                          <div className={`category ${item.color}`}/>
                          <div>{item.name}</div>
                        </div>
                        <div className="right">
                          <img className="cursor" src={"/assets/image/icon_minus.png"} alt="" onClick={() => handleRemoveClick(item.id,index)}/>
                        </div>
                      </div>
                      
                    </div>
                  ))}
                  </PerfectScrollbar>
                  // </div>
                : null
                
        }
        

      </div>
      <div className="container-bottom">
        <button className="next-btn" style={{backgroundColor: EnableConfirm() ? '#0F84F4' : '#DFE3E8'}}disabled ={!EnableConfirm()} onClick={()=>onNext()}>다음으로</button>
      </div>
      </div>
          

      </div>    
    );




    
    
}

const BalloonContainer = styled.div`
  position: relative;
  background: #fff;
  border-radius: 10px;
  padding: 30px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  margin: 20px;
  position: absolute;
  top: 20px;
  right: 40px;
  z-index: 9999;
  width: 350px;
  
  &:before {
    content: '';
    position: absolute;
    top: 0px;
    right: -8px;
    width: 10px;
    height: 10px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #fff;
  }
`;

const CloseButton = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const BalloonImage = styled.img`
  display: block;
  margin: 0 auto 10px;
`;

const BalloonText = styled.div`
  text-align: center;
  color: #71768C;

font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 25px; /* 156.25% */
`;

export default withRouter(EditCurriculumStep1);
