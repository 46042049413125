const PlatFomr_SubTop = () => {
  return (
    <>
      <section class="wrap sub-top sub-top3">
        <div class="inner">
          <div class="main-tit">
            <h4>
              <img src="/assets/image/landing_page/sub/plat-tit.svg" alt="공부 아이콘" />
              스터디카페
            </h4>
            <h2>
              온라인으로 간편하게 수업하고 <br />
              관리하는 실시간 소통 플랫폼
            </h2>
            <p>
              다양한 기능이 있는 츄잉만의 멀티공간에서 <br />
              학생드로가 소통하며 수업해보세요
            </p>
          </div>
          <div class="con-box">
            <div class="btn-box">
              <a class="on" href="#">
                {" "}
                츄잉 시작하기{" "}
              </a>
              <a href="#">문의하기</a>
            </div>
            <div class="img-box">
              <img src="/assets/image/landing_page/sub/plat-subtop.png" alt="소통 플랫폼" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default PlatFomr_SubTop;
