import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import styled from 'styled-components';
import Checkbox from '../../../components/checkbox';
import IconX from '../../../components/icons/x';
import SearchBar from '../../../components/search_bar';
import TextField from '../../../components/text_field';
import colors from '../../../design/colors';
import fonts from '../../../design/typography';
import Button from '../../../components/button';

interface SubjectSelectModalProps {
    show: boolean;
    type: "국어" | "수학" | "영어" | "사회" | "과학";
    onConfirm: (subject: string) => void;
    onClose: () => void;
}

const SubjectSelectModal = (props: SubjectSelectModalProps) => {
    const { show, type, onConfirm, onClose } = props;
    const [searchValue, setSearchValue] = useState<string>("");
    const [useCustomSubject, setUseCustomSubject] = useState<boolean>(false);
    const [selectedSubject, setSelectedSubject] = useState<string>("");
    
    return (
        <Modal isOpen={show} toggle={() => {onClose(); setSearchValue(""); setSelectedSubject("");}} size="md">
            <ModalBody >
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "24px"}}>
                    <span style={{...fonts.body2SemiBold, color: colors.gray900}}>교과목 추가</span>
                    <div style={{cursor: "pointer"}} onClick={() => {onClose(); setSearchValue(""); setSelectedSubject("");}}>
                        <IconX width="24px" height="24px" color={colors.gray900}/>
                    </div>
                </div>
                {useCustomSubject ? 
                    <TextField size='medium' placeholder="교과목을 입력해주세요" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} complete={searchValue.length > 0}/>
                    :    
                    <SearchBar size='medium' placeholder="교과목 검색" value={searchValue} onChange={(e) => setSearchValue(e.target.value)}/>}
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "8px", margin:"12px 0px"}}>
                    <Checkbox size='20px' checked={useCustomSubject} setChecked={() => {setUseCustomSubject(!useCustomSubject); setSelectedSubject("");}}/>
                    <span style={{...fonts.label4Medium, color: colors.gray500}}>직접 입력</span>
                </div>
                <SubjectListWrapper>
                    {subjectDict[type]?.length > 0 && subjectDict[type].filter(subject => {
                        if (useCustomSubject) {
                            return false;
                        }

                        if (searchValue === "") {
                            return true;
                        }
                        const lowerQuery = searchValue.toLowerCase();
                        return subject.toLowerCase().includes(lowerQuery);
                    }).map((subject) => (
                        <SubjectItem key={subject} onClick={() => setSelectedSubject(subject)} isSelected={selectedSubject === subject}>
                            <span>{subject}</span>
                        </SubjectItem>
                    ))}
                </SubjectListWrapper>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "8px", marginTop:"24px", marginBottom:"24px"}}>
                    <Button size='large' onClick={() => {onConfirm(useCustomSubject ? searchValue : selectedSubject); setSearchValue(""); setSelectedSubject("");}} text="추가하기" height='43px' disabled={selectedSubject === "" && (!useCustomSubject || searchValue === "")}/>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default SubjectSelectModal;

const SubjectItem = styled.div<{isSelected: boolean}>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 12px;
    cursor: pointer;
    font-size: ${props => props.isSelected ? fonts.body3Medium.fontSize : fonts.body3Regular.fontSize};
    line-height: ${props => props.isSelected ? fonts.body3Medium.lineHeight : fonts.body3Regular.lineHeight};
    font-family: ${props => props.isSelected ? fonts.body3Medium.fontFamily : fonts.body3Regular.fontFamily};
    color: ${props => props.isSelected ? colors.gray900 : colors.gray500};
    height: 44px;
    min-height: 44px;
    flex-shrink: 0;
    background-color: ${props => props.isSelected ? colors.blue50 : "transparent"};

`;

const SubjectListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${colors.gray100};
    border-radius: 8px;
    height: 352px;
    min-height: 200px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 5px;
        margin-right: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${colors.gray200};
        border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
`;

const subjectDict = {
    "국어": [
        "국전",
        "고전강해",
        "고전문학 감상",
        "고전문학의 감상과 비평", 
        "고전쓰기",
        "교양독서와 논술",
        "국어",
        "국어1",
        "국어1.2",
        "국어생활",
        "국어의 이해",
        "국어의 이해1", 
        "국어의 이해2",
        "글쓰기",
        "논술",
        "독서",
        "독서1",
        "독서논술",
        "독서생활",
        "독서와 문법",
        "독서와 문법 |",
        "독서와 문법 ||",
        "독서와 삶",
        "독서와 의사소통",
        "독서와 의사소통1",
        "독서와 의사소통2",
        "매체언어",
        "매체언어의 이해와 감상",
        "문법",
        "문예창작",
        "문학",
        "문학1",
        "문학2", 
        "문학개론",
        "문학여행",
        "문학감상",
        "문학의 이해",
        "생활국어",
        "언어논술",
        "언어탐구",
        "작문",
        "통합국어 |",
        "통합국어 ||",
        "한국현대문학",
        "화법",
        "화법과 작문",
        "화법과 작문 |",
        "화법과 작문 ||",
        "현대문학",
        "현대문학 감상",
        "현대문학의 감상과 비평"
    ],
    "수학": [
        "고급미적분학",
        "고급수학",
        "고급수학 |",
        "고급수학 ||",
        "고급수학기본",
        "고급수학의 기본",
        "공업수학",
        "공통수학", 
        "기본수학",
        "기초미적분학",
        "기초수학",
        "기하",
        "기하와 벡터",
        "기하와 벡터 심화",
        "기하학과 삛각함수",
        "미분과 적분",
        "미분적분학1",
        "미분적분학2",
        "미분방정식",
        "미적분",
        "미적분 |",
        "미적분 ||",
        "미적분과 통계기본",
        "미적분과 통계기본 심화",
        "미적분과 통계 심화",
        "미적분학",
        "미적분학 |",
        "미적분학 ||",
        "미적분학 |||",
        "선형대수",
        "선형대수학",
        "선형대수학1",
        "수리탐구 |",
        "수리탐구 ||",
        "수학",
        "수학1",
        "수학1-가",
        "수학1-나",
        "수학 | - A",
        "수학 | - B", 
        "수학 | 심화",
        "수학 1.2",
        "수학2",
        "수학2-가",
        "수학2-나",
        "수학 || - A",
        "수학 || - B",
        "수학 || 심화",
        "수학3",
        "수학10-가",
        "수학10-나",
        "수학과 사고",
        "수학연습|",
        "수학연습||",
        "수학의 기본 |",
        "수학의 기본 ||",
        "수학의 활용",
        "수학재량",
        "실업수학",
        "실업수학1", 
        "실업수학2",
        "실용수학",
        "심화수학",
        "심화수학 |",
        "심화수학 ||",
        "생활수학",
        "수리탐구",
        "응용수학",
        "이산수학",
        "인문수학",
        "인문통합수학", 
        "일반수학",
        "자연수학",
        "자연통합수학",
        "적분과 통계",
        "정수론",
        "향상수학",
        "해석",
        "확률과 통계"
    ],
    "영어": [
        "Advanced English",
        "고급영어",
        "고급영어1",
        "고급영어2", 
        "고급영어작문",
        "고급영어청해",
        "고급영어회화",
        "고급영어회화1",
        "고급영어회화2",
        "고등영어",
        "공업영어",
        "공통영어",
        "관광영어",
        "기초영어",
        "무역영어",
        "상업영어",
        "생활영어",
        "시사영어",
        "실무영어",
        "실용영어",
        "실용영어 |",
        "실용영어 ||",
        "실용영어독해와 작문",
        "실용영어회화",
        "실전영어",
        "심화영어",
        "심화영어독해 |",
        "심화영어독해 ||",
        "심화영어 독해와 작문",
        "심화영어작문",
        "심화영어회화",
        "심화영어회화 |",
        "심화영어회화 ||",
        "영문독해",
        "영미문화",
        "영미문화1",
        "영미문화2",
        "영어",
        "영어1",
        "영어2", 
        "영어 1.2",
        "영어 1- b",
        "영어 10-A",
        "영어 10-B",
        "영어강독",
        "영어고급독해",
        "영어권문화",
        "영어권문화 |",
        "영어권문화 ||",
        "영어기본 |",
        "영어기본 ||",
        "영어독해",
        "영어독해1",
        "영어독해2",
        "영어독해와 작문",
        "영어듣기",
        "영어문법",
        "영어문법1",
        "영어문법2",
        "영어문학",
        "영어문화",
        "영어심화회화",
        "영어심화회화1",
        "영어심화회화2",
        "영어의 기본 |",
        "영어의 기본 ||",
        "영어작문",
        "영어작문 1",
        "영어작문 2",
        "영어청력",
        "영어청해",
        "영어회화",
        "영어회화1",
        "영어회화2",
        "영작문",
        "원어민영어회화",
        "인터넷영어",
        "해사영어",
        "환경영어"
    ],
    "사회": [
        "경영대요",
        "경영일반",
        "경영학의 이해",
        "경제",
        "경제생활", 
        "경제지리",
        "공통사회",
        "과제연구",
        "관광지리",
        "국민윤리",
        "국사",
        "국제경영",
        "국제경제",
        "국제경제1",
        "국제경제2",
        "국제관계",
        "국제관계와 국제기구",
        "국제관계의 이해",
        "국제문제",
        "국제법",
        "국제외교",
        "국제정치",
        "국제정치 |",
        "국제정치경제",
        "국토지리",
        "글로벌경제의 이해",
        "기업경영",
        "농업경영",
        "도덕",
        "동아시아 근.현대사",
        "동아시아사",
        "문화경제지리",
        "법과사회",
        "법과정치",
        "비교문화",
        "비교문화 |",
        "비교문화 ||",
        "사회",
        "사회1",
        "사회2",
        "사회과학논술",
        "사회문화",
        "사회.문화",
        "사회문화연구",
        "사회과학방법론",
        "사회-국사",
        "사회철학",
        "사회탐구",
        "삶과 철학",
        "상업 경제",
        "생활경제",
        "생활과 교육",
        "생활과 논리",
        "생활과 심리",
        "생활과 종교",
        "생활과 윤리",
        "생활과 헌법",
        "생활철학",
        "세계문제",
        "세계사",
        "세계역사의 이해", 
        "세계이해",
        "세계지리",
        "시민윤리",
        "역사",
        "예절",
        "윤리",
        "윤리와 사상",
        "인간과 사회환경",
        "인간과 환경",
        "인간사회와 환경",
        "인류의 미래사회",
        "인문지리",
        "인터넷 윤리",
        "일반사회",
        "전통윤리",
        "정보와 사회",
        "정치",
        "정치경제",
        "정치경제학",
        "종교와 사회",
        "지리",
        "지리1",
        "지리2",
        "지역사회의 이해",
        "지역과 사회",
        "지역과 역사", 
        "지역이해",
        "직업윤리",
        "철학",
        "통합사회1",
        "한국 경제의 이해",
        "한국 근현대사",
        "한국문화사",
        "한국사",
        "한국사1",
        "한국사2",
        "한국의 사회와 문화",
        "한국의 전통문화",
        "한국의 현대사회",
        "현대사회와 철학",
        "현대생활과 윤리",
        "한국지리",
        "행동예절"
    ],
    "과학": [
        "고급물리",
        "고급생명과학",
        "고급생물",
        "고급지구과학",
        "고급화학",
        "공업화학",
        "공통과학",
        "과제연구",
        "과학",
        "과학1",
        "과학2",
        "과학3",
        "과학4",
        "과학교양",
        "과학사",
        "과학사 및 과학철학",
        "과학실험",
        "과학융합",
        "과학적사고 및 실험",
        "과학철학",
        "기초물리학",
        "기초물리학1",
        "기초물리학2",
        "기초물리학실험",
        "기초물리학실험1",
        "기초물리학실험2",
        "기초생물학",
        "기초생물학1",
        "기초생물학2",
        "기초생물학실험1",
        "기초생물학실험2",
        "기초지구과학1",
        "기초지구과학2",
        "기초지구과학실험1",
        "기초지구과학실험2",
        "기초화학",
        "기초화학2",
        "기초화학실험1",
        "기초화학실험2",
        "대기과학",
        "무기공업화학",
        "물리",
        "물리학B",
        "물리1",
        "물리2",
        "물리1.2",
        "물리실험",
        "물리실험1",
        "물리실험2",
        "물리탐구",
        "물리탐구1",
        "물리탐구2",
        "물리학 및 실험1",
        "물리학 및 실험2",
        "물리 및 실험",
        "분석화학",
        "분자생물학",
        "생명공학의 이해",
        "생명과학",
        "생명과학 |",
        "생명과학 | 의 이해",
        "생명과학 ||",
        "생명과학실험",
        "생명과학실험1",
        "생명과학실험2",
        "생명과학 및 실험1",
        "생명과학 및 실험2",
        "생물",
        "생물1",
        "생물2",
        "생물1.2",
        "생물공학",
        "생물공학기초",
        "생물공학실습",
        "생물공학실험",
        "생물과학||",
        "생물과학실습 |",
        "생물과학실습 ||",
        "생물 및 실험",
        "생물실험",
        "생물탐구",
        "생물탐구1",
        "생물탐구2",
        "생물학 및 실험",
        "생물학 및 실험1",
        "생물학 및 실험2",
        "생물학의 시대",
        "생태각론 |",
        "생태각론 ||",
        "생태와 환경",
        "생태입문",
        "생태학습",
        "생태환경",
        "생활과학",
        "생활과 과학",
        "세포생물학",
        "식품과학",
        "식품미생물",
        "심화물리",
        "심화생명과학",
        "심화지구과학",
        "심화화학",
        "양자역학",
        "역학",
        "유기공업화학",
        "유기화학",
        "유전학",
        "일반물리1",
        "일반물리학1",
        "일만물리학2",
        "일반물리학실험",
        "일반물리학실험1",
        "일반물리학실험2",
        "일반생명과학",
        "일반생명과학1",
        "일반생명과학2",
        "일반생명과학실험",
        "일반생명과학실험1",
        "일반생명과학실험2",
        "일반생물학",
        "일반생물학1",
        "일반생물학2",
        "일반생물학실험",
        "일반생물학실험1",
        "일반생물학실험2",
        "일반지구과학",
        "일반지구과학1",
        "일반지구과학2",
        "일반지구과학실험1",
        "일반지구과학실험2",
        "일반화학",
        "일반화학1",
        "일반화학2",
        "일반화학실험",
        "일반화학실험1",
        "일반화학실험2",
        "자연친화",
        "전산",
        "전산일반",
        "전자과학",
        "전통과학의 이해",
        "정보",
        "정보과학",
        "정보과학 |",
        "정보과학 ||",
        "정보사회와 컴퓨터",
        "정보.컴퓨터",
        "정보와 컴퓨터",
        "제조화학",
        "지구",
        "지구과학",
        "지구과학1",
        "지구과학2",
        "지구과학실험",
        "지구과학실험1",
        "지구과학실험2",
        "지구의 역사",
        "지학1",
        "지학2",
        "천문학",
        "컴퓨터",
        "컴퓨터과학",
        "컴퓨터과학1",
        "컴퓨터과학2",
        "컴퓨터일반",
        "해양과학",
        "현대과학과 기술",
        "현대물리",
        "현대물리 및 실험",
        "화학",
        "화학1",
        "화학2",
        "화학 1.2",
        "화학공학",
        "화학공학1",
        "화학공학2",
        "화학실험",
        "화학탐구",
        "화학탐구1",
        "화학탐구2",
        "환경과학",
        "환경화학"
    ]
}