import React, { useContext, useEffect, useRef, useState } from "react";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withRouter } from "react-router-dom";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import styled from "styled-components";
import { Page_settings } from "../../config/page_settings";
import CreateCurriculumStep0EditModal from "./modal/create_curriculum_step0_edit_modal";
import CreateCurriculumStep5Modal from "./modal/create_curriculum_step5_modal";
import SaveModal from "./modal/save_modal";
import WarningModal from "./modal/warning_modal";

const CreateCurriculumStep5 = (props) => {
  const history = useHistory();
  const context = useContext(Page_settings);
  const [openCurriculumModal, setOpenCurriculumModal] = useState(false);
  const [titleValue, setTitleValue] = useState('');
  const [partCategory, setPartCategory] = useState([]); // 대단원 항목 데이터
  const [clickPart, setClickPart] = useState();
  const [clickPartIdx, setClickPartIdx] = useState(0);
  const [clickSection, setClickSection] = useState();
  const [clickSectionIdx, setClickSectionIdx] = useState();
  const [clickChapterIdx, setClickChapterIdx] = useState();
  const [clickChapter, setClickChapter] = useState();
  const [clickUnit, setClickUnit] = useState();
  const [clickUnitIdx,setClickUnitIdx] = useState(0);

  const [openGuide, setOpenGuide] = useState(false)
  
  const [warningModal, setWarningModal] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [roadmapData,setRoadmapData] = useState();
  const [saveModal, setSaveModal] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);
  

  const scrollRef = useRef();
    const mouseCoords = useRef({
        startX: 0,
        startY: 0,
        scrollLeft: 0,
        scrollTop: 0
    });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.guide-img')) {
        setOpenGuide(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    loadFromSessionStorage();
  }, [editStatus]);

  const loadFromSessionStorage = () => {
    const step0_data = sessionStorage.getItem('curriculum');
    if (step0_data) {
      const step0Data = JSON.parse(step0_data);
      setTitleValue(step0Data.title);
    }
  };


  useEffect(() => {
    if (props.curriculumData && props.curriculumData.parts) {
     
      const formattedParts = props.curriculumData.parts.map(part => ({
        id: part.id ? part.id : null,
        subject: part.subject,
        color: part.color,
        name: part.name,
        chapters: part.chapters || []
      }));
      setPartCategory(formattedParts);
    }
    if (props.curriculumData && props?.curriculumData?.thumbnail) {
      const formattedThumbnail = props?.curriculumData?.thumbnail;
      // setPartCategory(formattedParts);
      setRoadmapData(formattedThumbnail)
    }
  }, [props.curriculumData]);



  const handleCategoryClick = (item,index,color, subject) => {
    setClickPartIdx(index)
  };


  const handleDragStart = (e) => {
    const startX = e.pageX - scrollRef.current._container.offsetLeft;
    const startY = e.pageY - scrollRef.current._container.offsetTop;
    const scrollLeft = scrollRef.current._container.scrollLeft;
    const scrollTop = scrollRef.current._container.scrollTop;
    mouseCoords.current = { startX, startY, scrollLeft, scrollTop }
    setIsMouseDown(true);
}

const handleDragEnd = (e) => {
    setIsMouseDown(false);
}

const handleDrag = (e) => {
    if (!isMouseDown) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current._container.offsetLeft;
    const y = e.pageY - scrollRef.current._container.offsetTop;
    const walkX = x - mouseCoords.current.startX;
    const walkY = y - mouseCoords.current.startY;
    scrollRef.current._container.scrollLeft = mouseCoords.current.scrollLeft - walkX;
    scrollRef.current._container.scrollTop = mouseCoords.current.scrollTop - walkY;
}



  const onNext = async () => {
    setWarningModal(true);    
  }

  const openModal = (part,chapter,chapterIdx,section,sectionIdx,unit,unitIdx) => {
    // setClickItem(index) 
    // Part 아이템을 보내고 그거에따른 section index , unit index를 잡아내어 구현 
    setClickPart(part)
    setClickSection(section)
    setClickSectionIdx(sectionIdx)
    setClickChapterIdx(chapterIdx)
    setClickChapter(chapter)
    setClickUnit(unit)
    setClickUnitIdx(unitIdx)
    
    
    setOpenCurriculumModal(true)
  }

  const temporaryStorage = async () => {
    try {
      const curriculum = JSON.parse(sessionStorage.getItem('curriculum'));
      const curriculumImgFile = curriculum.imgFile;
      const curriculumImgName = curriculum.imgName;

      // roadmapData에서 imgfile 키를 제거
      // const { imgFile, ...updatedRoadmapData } = roadmapData;
      if (!curriculum.id){
        if (curriculumImgFile && curriculumImgName) {
          const roadmapImgUrl = await roadMapImg(curriculumImgFile, curriculumImgName);
          context.post(
            'curriculum/addCurriculum',
            {
              curriculum: {
                ...curriculum,
                thumbnail: roadmapImgUrl // 업로드된 이미지 URL을 roadmapData에 추가
              },
              curriculum_child: partCategory,
              mode: 0
            },
            response => {
              curriculum.id = response;
              sessionStorage.setItem('curriculum', JSON.stringify(curriculum));
              setSaveModal(true);
            }
          );
        } else {
          // 이미지 파일이 없는 경우에도 데이터 전송
          context.post(
            'curriculum/addCurriculum',
            {
              curriculum: curriculum,
              curriculum_child: partCategory,
              mode: 0
            },
            response => {
              curriculum.id = response;
              sessionStorage.setItem('curriculum', JSON.stringify(curriculum));
              setSaveModal(true);
            }
          );
        }
      }else{
        if (curriculumImgFile && curriculumImgName) {
          const roadmapImgUrl = await roadMapImg(curriculumImgFile, curriculumImgName);
          context.post(
            'curriculum/editCurriculum',
            {  
              curriculum: {
                ...curriculum,
                thumbnail: roadmapImgUrl // 업로드된 이미지 URL을 roadmapData에 추가
              },
              curriculum_child:partCategory,
              mode:0
            },
            response => {
              setSaveModal(true);
            }
          );
        }else{ 
           // 이미지 파일이 없는 경우에도 데이터 전송
           context.post(
            'curriculum/editCurriculum',
            {  
              curriculum: curriculum,
              curriculum_child:partCategory,
              mode:0
            },
            response => {
              setSaveModal(true);
            }
          );
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const roadMapImg = (imgFile, imgName) => {
    return new Promise((resolve, reject) => {
      const arr = imgFile.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
  
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      const url = new File([u8arr], imgName, { type: mime });
  
      let formData = new FormData();
      formData.append('file', url);
  
      context.post(
        'upload/image/thumbnail',
        formData,
        response => {
          if (response.image) {
            
            resolve(response.image);
          } else {
            reject(new Error("Image upload failed"));
          }
        },
        error => {
          reject(error);
        }
      );
    });
  };
  

  // const filteredChapters = modalData.filter(part => part.name === clickPartIdx);

  const sectionConfirm = (section,unit) => {
    
    if ((section?.test?.answer_data && section?.test?.commentary_url && section?.test?.test_url && section?.test?.time > 0)) {
      return true;
    }
      return false;
  }

  const EnableConfirm = () => {
  
    const isAllConditionsMet = partCategory.every(
      part => part.chapters.every(
        chapter => chapter.sections.every(
          section => section.test &&
                     section.test.test_url &&
                     section.test.time >= 0 &&
                     extractNumericValue(section.test.pass_score) >= 0 &&
                     section.test.answer_data &&
                     section.test.answer_data.length > 0 &&
                     section.test.commentary_url
        )
      )
    );
  
    return isAllConditionsMet;

  }

  const extractNumericValue = (value) => {
    if (typeof value === 'string') {
        const numericValue = parseInt(value.replace(/\D/g, ''), 10);
        return isNaN(numericValue) ? 0 : numericValue;
    }
    return typeof value === 'number' ? value : 0;
}
  
const goBack = () => {
  history.push('/curriculum/create_curriculum/4');
 }


  return (
    <div class="main-content create-curriculum-step5-container">
      {openCurriculumModal && (<CreateCurriculumStep5Modal
        open={openCurriculumModal}
        onClose={() => {
          setOpenCurriculumModal(false);
        }}
        clickPart={clickPart}
        clickPartIdx={clickPartIdx}
        clickChapter={clickChapter}
        clickChapterIdx={clickChapterIdx}
        clickSection={clickSection}
        clickSectionIdx={clickSectionIdx}
        clickUnit={clickUnit}
        clickUnitIdx={clickUnitIdx}
        curriculumData={props.curriculumData}
        updatePartCategory={props.updatePartCategory}
        
      />)}

      {warningModal && (<WarningModal
                open={warningModal}
                onClose={() => {
                  setWarningModal(false);
                }}
                curriculumData={props.curriculumData}
              />)}
              

              {editStatus && (<CreateCurriculumStep0EditModal
          open={editStatus}
          onClose={() => {
            setEditStatus(false);
          }}
          
        />)} 

{saveModal && (<SaveModal
        open={saveModal}
        onClose={() => {
          setSaveModal(false);
        }}
      />
      )}
      <div class="top content-container">
        <div class="progress-container">
          <div class="progress-box">
            <div class="progress" />
          </div>
          <div class="step"><span>5</span> / 5</div>
        </div>
        <div class="flex-box">
          <div className="title-box">
          <img className="cursor" src="/assets/image/icon_arrow_back_black.png" alt="" onClick={()=>goBack()}/>
            <div className="title">
              {titleValue}
            </div>
            <img className="cursor" src="/assets/image/icon_edit_btn_gray.png" alt="" onClick={()=>setEditStatus(true)}/>
          </div>
          <div className="curriculum-guide-box">
            {/* <img src="/assets/image/icon_warning_point.png" alt="" onClick={()=>setWarningPoint(true)}/> */}
            <img src="/assets/image/icon_curriculum_guide.png" style={{width:'65px'}} alt="" onClick={()=>setOpenGuide(true)}/>
            {openGuide &&
            <img className="guide-img" src="/assets/image/img_curriculum_guide_5.png" alt="" />
    }
          </div>
        </div>

      </div>

      <div className="middle content-container">
        <div className="flex-box">
        <div className="title">콘텐츠 추가하기</div>
        {/* <div className="right">
          <div className="category-box">
            <div className={`category ${categoryColor}`}></div>
            <div>콘텐츠 업로드 완료</div>
            </div>
            <div className="category-box">
            <div className={`category`}></div>
            <div>콘텐츠 업로드 완료</div>
            </div>
        </div> */}
        </div>
        {
          partCategory.length > 0 ?
            <div className="row-box" >
              {partCategory.map((item, index) => (
                <div
                  key={index}
                  className={`partCategory-box ${clickPartIdx === index ? item.color : ''}`}
                  onClick={() => handleCategoryClick(item, index, item.color, item.subject)}
                >
                  <div>{item.name}</div>
                </div>

              ))}
            </div>

            : null
        }
      </div>

      <div className="bottom content-container">
      <div className="container-top">
  <PerfectScrollbar className="section-unit-container" ref={scrollRef} style={{ height: '100%', cursor: "grab" }} onMouseDown={handleDragStart} onMouseUp={handleDragEnd} onMouseMove={handleDrag}>
{
  (clickPartIdx !== undefined && partCategory[clickPartIdx]?.chapters?.length > 0) ? (
    partCategory.map((part, partIdx) => (
      partIdx === clickPartIdx ? (
        part.chapters.map((chapter, chapterIdx) => (
          <div className="row-box" key={chapterIdx}>
            <div className="chapter">{chapter?.name}</div>
            {chapter.sections && chapter.sections.map((section, sectionIdx) => (
              <div className="section-container" key={sectionIdx}>
                <div className="section-box">
                  <div className="section-title">{section.name}</div>
                  <div className={`unit-box-outside ${part?.color}`}>
                    {section.units && section.units.map((unit, unitIdx) => (
                      <div
                        className={`unit-box-inside ${sectionConfirm(section,unit)? part?.color : ''}`}
                        key={unitIdx}
                        onClick={() => openModal(part, chapter.name, chapterIdx, section, sectionIdx, unit, unitIdx)}
                      >
                        {unit.name}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))
      ) : null
    ))
  ) : null
}
</PerfectScrollbar>





        </div>
        <div className="container-bottom">
          <button className="save-btn" onClick={() => temporaryStorage()}>임시저장</button>
          <button className="next-btn" style={{ backgroundColor: EnableConfirm() ? '#0F84F4' : '#DFE3E8' }} disabled={!EnableConfirm()} onClick={() => onNext()}>업로드</button>
        </div>
      </div >


    </div >
  );






}

const BalloonContainer = styled.div`
  position: relative;
  background: #fff;
  border-radius: 10px;
  padding: 30px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  margin: 20px;
  position: absolute;
  top: 20px;
  right: 40px;
  z-index: 9999;
  width: 350px;
  
  &:before {
    content: '';
    position: absolute;
    top: 0px;
    right: -8px;
    width: 10px;
    height: 10px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #fff;
  }
`;

const CloseButton = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const BalloonImage = styled.img`
  display: block;
  margin: 0 auto 10px;
`;

const BalloonText = styled.div`
  text-align: center;
  color: #71768C;

font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 25px; /* 156.25% */
`;
export default withRouter(CreateCurriculumStep5);