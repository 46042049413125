import React from "react";
import { Link } from "react-router-dom";

const ClassTop = () => {
    return (
        <>
            <section className="wrap sub-top">
                <div className="inner">
                    <div className="main-tit">
                    <h4>
                        <img src="Img/sub/class-tit.svg" alt="홈 아이콘" />
                        클래스
                    </h4>
                    <h2>
                        학생별 학습데이터를 한눈에 <br />
                        대시보드 기반 클래스 운영
                    </h2>
                    <p>
                        이상학생 파악 및 관리를 위해 <br />
                        츄잉 LMS를 활용해보세요
                    </p>
                    </div>
                    <div className="con-box">
                        <div className="btn-box">
                            <Link to="#" className="on">츄잉 시작하기</Link>
                            {/* <a className="on" href="#"> 츄잉 시작하기 </a> */}
                            <Link to="#">문의하기</Link>
                            {/* <a href="#">문의하기</a> */}
                        </div>
                        <div className="img-box">
                            <img src="Img/sub/class-subtop.png" alt="클래스 운영" />
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default ClassTop;