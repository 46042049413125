import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import styled from "styled-components";
import { ChevronDown, ChevronUp } from "../../../components/icons/chevron";
import IconDownload from "../../../components/icons/download";
import ImageCircle from "../../../components/image_circle";
import { Page_settings } from "../../../config/page_settings";
import colors from "../../../design/colors";
import fonts from "../../../design/typography";
import { rootStore } from "../../../mobx/store";
import { getFileImagePath, getFilePath } from "../../../utils/image";
import IconMenu from "../../../components/icons/menu";
import { Modal, ModalBody } from "reactstrap";
import Button from "../../../components/button";

interface IFileInfo {
  name: string;
  size: number;
  type: string;
  url: string;
}

interface IMissionMember {
  member: number;
  name: string;
  profile: string;
  school: string;
  missionStatus: "SUBMITTED" | "APPROVED" | "REJECTED" | "NONE";
  submitId: number;
}

interface IMission {
  id: number;
  classroom: number;
  title: string;
  content: string;
  startTime: string;
  endTime: string;
  createDate: string;
  fileList: IFileInfo[];
  missionMembers: IMissionMember[];
}

interface IMissionSubmit {
  id: number;
  mission: number;
  title: string;
  content: string;
  profile: string;
  name: string;
  school: string;
  status: "SUBMITTED" | "APPROVED" | "REJECTED" | "NONE";
  createDate: string;
  fileList: IFileInfo[];
}

const MissionDetail = (props) => {
  const classId = rootStore.getClassId;
  const missionId = parseInt(useParams().mission_id);
  const context: any = useContext(Page_settings);

  const [missionInfo, setMissionInfo] = useState<IMission | null>(null);

  const [submitId, setSubmitId] = useState<number>(-1);
  const [missionSubmitInfo, setMissionSubmitInfo] = useState<IMissionSubmit | null>(null);

  useEffect(() => {
    context.get(
      `classroom/${classId}/mission/${missionId}`,
      {},
      (response) => {
        if (response.length === 0) {
          alert("찾을수 없습니다");
          props.history.replace(`/class/mission/${classId}`);
        }
        setMissionInfo(response);
      }
    );
  }, []);


  useEffect(() => {
    if (submitId !== -1) {
      context.get(
        `classroom/${classId}/mission/${missionId}/submit/${submitId}`,
        {},
        (response) => {
          setMissionSubmitInfo(response);
        }
      );
    }
  }, [submitId]);


  const notSubmitMember = () => {
    if (!missionInfo || !missionInfo.missionMembers) return [];
    return missionInfo?.missionMembers.filter(member => member.missionStatus === "NONE");
  };

  const submitMember = () => {
    if (!missionInfo || !missionInfo.missionMembers) return [];
    return missionInfo?.missionMembers.filter(member => member.missionStatus !== "NONE");
  };

  const [showNotSubmitMember, setShowNotSubmitMember] = useState(true);
  const [showSubmitMember, setShowSubmitMember] = useState(true);

  if(!missionInfo) return null;

  return (
    <Container>
      <Section style={{ padding: "32px 40px", width: "calc(60% - 40px)"}}>
        {submitId !== -1 ? (
          <StudentMissionSubmitContent context={context} missionSubmitInfo={missionSubmitInfo} clickBack={() => setSubmitId(-1)} />
        ) : (
          <MissionInfoContent context={context} missionInfo={missionInfo} />
        )}
      </Section>
      <div style={{ width: "1px", height: "650px", backgroundColor: `${colors.gray100}`, margin: "0 40px"}} />
      <Section style={{ padding: "20px", width: "calc(40% - 40px)"}}>
        {
          missionInfo.startTime > moment().format("YYYY-MM-DD HH:mm") ?
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", height: "100%"}}>
            <img src="/assets/image/sad_rai.png" alt="warning" width="152px" height="113px" />
            <div style={{ ...fonts.body3Medium, color: colors.gray400, marginTop: 23}}>
              아직 과제 기간이 아닙니다
            </div>
          </div>
          :
          <>
          <div style={{ marginBottom: "20px"}}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", alignContent: "center", ...fonts.body2SemiBold, color: `${colors.gray900}`, backgroundColor: `${colors.gray50}`, padding: "12px 16px", borderRadius: "8px" }}>
            <div>
              <span>미제출</span>
              <span style={{ marginLeft: "4px" }}>({notSubmitMember().length})</span>
            </div>
            {showNotSubmitMember ? 
            <div style={{ cursor: "pointer" }} onClick={() => setShowNotSubmitMember(false)}>
              <ChevronUp width="24px" height="24px" color={colors.gray500} /> 
            </div> 
            : 
            <div style={{ cursor: "pointer" }} onClick={() => setShowNotSubmitMember(true)}>
              <ChevronDown width="24px" height="24px" color={colors.gray500} />
            </div>}
          </div>
          {showNotSubmitMember && (
            <MemberListWrapper>
              {notSubmitMember().map((member) => (
                <MemberItemWrapper key={member.member}>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <ImageCircle src={getFilePath(member.profile)} size={40} style={{ marginRight: "12px" }}/>
                    <div>
                      <div style={{ ...fonts.body3SemiBold, color: `${colors.gray900}`}}>{member.name}</div>
                      <div style={{ ...fonts.label4Medium, color: `${colors.gray600}`, marginTop: "4px"}}>{member.school}</div>
                    </div>
                  </div>
                </MemberItemWrapper>
              ))}
            </MemberListWrapper>
          )}
        </div>
        <div>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", alignContent: "center", ...fonts.body2SemiBold, color: `${colors.gray900}`, backgroundColor: `${colors.gray50}`, padding: "12px 16px", borderRadius: "8px" }}>
            <div>
              <span>제출</span>
              <span style={{ marginLeft: "4px" }}>({submitMember().length})</span>
            </div>
            {showSubmitMember ? 
            <div style={{ cursor: "pointer" }} onClick={() => setShowSubmitMember(false)}>
              <ChevronUp width="24px" height="24px" color={colors.gray500} /> 
            </div> 
            : 
            <div style={{ cursor: "pointer" }} onClick={() => setShowSubmitMember(true)}>
              <ChevronDown width="24px" height="24px" color={colors.gray500} />
            </div>}
          </div>
            {showSubmitMember && (
            <MemberListWrapper>
              {submitMember().map((member) => (
                <MemberItemWrapper key={member.member} style={{ cursor: "pointer" }} onClick={() => setSubmitId(member.submitId)}>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <img src={getFilePath(member.profile)} alt="profile" width="40px" height="40jpx" style={{ borderRadius: "50%", marginRight: "12px" }}/>
                    <div>
                      <div style={{ ...fonts.body3SemiBold, color: `${colors.gray900}`}}>{member.name}</div>
                      <div style={{ ...fonts.label4Medium, color: `${colors.gray600}`, marginTop: "4px"}}>{member.school}</div>
                    </div>
                  </div>
                  {getStatusChip(member.missionStatus)}
                </MemberItemWrapper>
              ))}
            </MemberListWrapper>
          )}
        </div>
          </>
        }
        
      </Section>
    </Container>
  );
};


const MissionInfoContent = ({context, missionInfo}: {context: any, missionInfo: IMission}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const classId = rootStore.getClassId;
  const missionId = parseInt(useParams().mission_id);
  const navigate = useNavigate();
  
  if(!missionInfo) return null;

  const onEditMission = () => {
    navigate(`/study/mission/edit/${missionId}`);
  }

  const onDeleteMission = () => {
    context.delete(`classroom/${classId}/mission/${missionId}`, {}, () => {
      navigate(`/study/mission`);
    });
  }

  const onCloseMission = () => {
    context.post(`classroom/${classId}/mission/${missionId}/close`, {}, () => {
      navigate(`/study/mission`);
    });
  }

  return (
    <>
      <div style={{display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between", position: "relative"}}>
        <div>
          {
            missionInfo.startTime > moment().format("YYYY-MM-DD HH:mm") ?
            <div style={{ ...fonts.label4Medium, color: `${colors.blue600}`, marginBottom: "20px"}}>
              {moment(new Date(missionInfo.startTime)).format("YYYY.MM.DD HH:mm")} 예정
            </div>
            :
            missionInfo.endTime > moment().format("YYYY-MM-DD HH:mm") ?
            <div style={{ ...fonts.label4Medium, color: `${colors.blue600}`, marginBottom: "20px"}}>
              {moment(new Date(missionInfo.endTime)).format("YYYY.MM.DD HH:mm")}까지
            </div>
            :
            <div style={{ ...fonts.label4Medium, color: `${colors.warning}`, marginBottom: "20px"}}>
              마감
            </div>
          }
          <div style={{ ...fonts.body1SemiBold, color: `${colors.gray900}`, marginBottom: "32px"}}>
            {missionInfo.title}
          </div>
        </div>
        <div style={{cursor: "pointer"}} onClick={() => setShowMenu(prev => !prev)}>
          <IconMenu color={colors.gray500} width="24px" height="24px"/>
        </div>
        {showMenu && (
          <MenuContainer>
            {
              missionInfo.startTime > moment().format("YYYY-MM-DD HH:mm") && (
                <MenuItem onClick={() => setShowEditModal(true)}>
                  수정하기
                </MenuItem>
              )
            }
            <MenuItem onClick={() => setShowDeleteModal(true)}>
              삭제하기
            </MenuItem>
            {(missionInfo.endTime > moment().format("YYYY-MM-DD HH:mm") && missionInfo.startTime < moment().format("YYYY-MM-DD HH:mm")) && (
              <MenuItem onClick={() => setShowCloseModal(true)}>
                마감하기
              </MenuItem>
            )}
          </MenuContainer>
        )}
      </div>
      <div style={{ width: "100%", height: "1px", backgroundColor: `${colors.gray100}`, marginBottom: "32px"}}/>
      <div style={{ ...fonts.label3Medium, color: `${colors.gray500}`, marginBottom: "10px", height: "160px"}}>
        <textarea style={{ width: "100%", height: "100%", border: "none", outline: "none", ...fonts.label3Medium, color: `${colors.gray500}`}} value={missionInfo.content} readOnly />
      </div>
      <div>
        {missionInfo.fileList.map((file, idx) => (
          <div key={idx} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: 'calc(100% - 274px)'}}>
            <img src={getFileImagePath(file.name)} alt={file.name} width={24} height={24}/>
            <span style={{...fonts.body3Medium, color: colors.gray900, marginLeft: 8}}>{file.name.length > 50 ? file.name.slice(0, 50) + "..." : file.name}</span>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: 250}}>
            <span style={{...fonts.body3Medium, color: colors.gray900}}>{fileResize(file.size)}</span>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: 24, cursor: 'pointer'}} onClick={() => onDownload(file.url, file.name)}>
            <IconDownload color={colors.gray500} width="24px" height="24px"/>
          </div>
        </div>
        ))}
      </div>
      {showDeleteModal && (
        <DeleteModal onClose={() => setShowDeleteModal(false)} onConfirm={onDeleteMission} />
      )}
      {showCloseModal && (
        <CloseModal onClose={() => setShowCloseModal(false)} onConfirm={onCloseMission} />
      )}
      {showEditModal && (
        <EditModal onClose={() => setShowEditModal(false)} onConfirm={onEditMission} />
      )}
    </>
  );
};

const StudentMissionSubmitContent = ({context, missionSubmitInfo, clickBack} : {context: any, missionSubmitInfo: IMissionSubmit, clickBack: () => void}) => {
  const [showScoreModal, setShowScoreModal] = useState(false);
  const classId = rootStore.getClassId;
  const missionId = parseInt(useParams().mission_id);
  const navigate = useNavigate();

  if(!missionSubmitInfo) return null;

  const onAssign = () => {
    context.put(`classroom/${classId}/mission/${missionId}/submit/${missionSubmitInfo.id}/status`, {
      status: 1
    }, () => {
      setShowScoreModal(false);
      navigate(0);
    });
  }

  const onReject = () => {
    context.put(`classroom/${classId}/mission/${missionId}/submit/${missionSubmitInfo.id}/status`, {
      status: -1
    }, () => {
      setShowScoreModal(false);
      navigate(0);
    });
  }

  return (
    <>
      <div style={{ marginBottom: "16px"}}>
        {getStatusChip(missionSubmitInfo.status)}
      </div>
      
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", width: "calc(100% - 55px)"}}>
          <ImageCircle src={getFilePath(missionSubmitInfo.profile)} size={48} style={{ marginRight: "12px" }}/>
          <div>
            <div style={{ ...fonts.body3SemiBold, color: `${colors.gray900}`}}>{missionSubmitInfo.name}</div>
            <div style={{ ...fonts.label4Medium, color: `${colors.gray600}`, marginTop: "4px"}}>{missionSubmitInfo.school || " - "}</div>
          </div>
        </div>
        {missionSubmitInfo.status === "SUBMITTED" && <Button size="small" text="채점" onClick={() => setShowScoreModal(true)} width="55px" height="36px" />}
      </div>
      <div style={{ width: "100%", marginTop:  " 28px", ...fonts.body2SemiBold, color: `${colors.gray900}`}}>
        {missionSubmitInfo.title}
      </div>
      <pre contentEditable={false} style={{ width: "100%", maxHeight: "200px", border: "none", outline: "none", color: `${colors.gray500}`, marginTop: "28px", overflowY: "auto",  ...fonts.label3Medium, }}>
        {missionSubmitInfo.content}
      </pre>
      <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", width: "100%", ...fonts.label4Medium, color: `${colors.gray500}`, marginTop: "28px"}}>
        {moment(missionSubmitInfo.createDate).format("YYYY.MM.DD HH:mm")}
      </div>
      <div style={{ width: "100%", marginTop:  " 28px", height: "1px", backgroundColor: `${colors.gray100}`}}/>
      <div style={{ marginTop: "28px"}}>
        {missionSubmitInfo.fileList.map((file, idx) => (
          <div key={idx} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: 'calc(100% - 274px)'}}>
            <img src={getFileImagePath(file.name)} alt={file.name} width={24} height={24}/>
            <span style={{...fonts.label4Medium, color: colors.gray600, marginLeft: 8}}>{file.name.length > 30 ? file.name.slice(0, 30) + "..." : file.name}</span>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: 100}}>
            <span style={{...fonts.label4Medium, color: colors.gray400}}>{fileResize(file.size)}</span>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: 24, cursor: 'pointer'}} onClick={() => onDownload(file.url, file.name)}>
            <IconDownload color={colors.gray500} width="24px" height="24px"/>
          </div>
        </div>
        ))}
      </div>
      {
        showScoreModal && (
          <ScoreModal onClose={() => setShowScoreModal(false)} onAssign={onAssign} onReject={onReject} />
        )
      }
    </>
  );
};

const DeleteModal = ({onClose, onConfirm}) => {
  return (
    <Modal size="sm" isOpen={true} toggle={onClose}>
      <ModalBody style={{padding: "24px"}}>
        <div style={{...fonts.body2Medium, color: colors.gray900, marginBottom: 32, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: 24}}>
          과제를 삭제하시겠습니까?
        </div>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 8}}>
          <Button
            size="medium"
            text="취소"
            onClick={onClose}
            buttonColor={colors.gray100}
            textColor={colors.gray500}
            width="112px"
            height="44px"
          />
          <Button
            size="medium"
            text="삭제"
            onClick={onConfirm}
            buttonColor={colors.warning}
            textColor={colors.white}
            width="112px"
            height="44px"
          />
        </div>
      </ModalBody>
    </Modal>
  );
}

const CloseModal = ({onClose, onConfirm}) => {
  return (
    <Modal size="sm" isOpen={true} toggle={onClose}>
      <ModalBody style={{padding: "24px"}}>
        <div style={{...fonts.body2Medium, color: colors.gray900, marginBottom: 32, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: 24}}>
          과제를 마감하시겠습니까?
        </div>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 8}}>
          <Button
            size="medium"
            text="취소"
            onClick={onClose}
            buttonColor={colors.gray100}
            textColor={colors.gray500}
            width="112px"
            height="44px"
          />
          <Button
            size="medium"
            text="마감"
            onClick={onConfirm}
            buttonColor={colors.warning}
            textColor={colors.white}
            width="112px"
            height="44px"
          />
        </div>
      </ModalBody>
    </Modal>
  );
}

const EditModal = ({onClose, onConfirm}) => {
  return (
    <Modal size="sm" isOpen={true} toggle={onClose}>
      <ModalBody style={{padding: "24px"}}>
        <div style={{...fonts.body2Medium, color: colors.gray900, marginBottom: 32, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: 24}}>
          과제를 수정하시겠습니까?
        </div>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 8}}>
          <Button
            size="medium"
            text="취소"
            onClick={onClose}
            buttonColor={colors.gray100}
            textColor={colors.gray500}
            width="112px"
            height="44px"
          />
          <Button
            size="medium"
            text="수정"
            onClick={onConfirm}
            buttonColor={colors.blue600}
            textColor={colors.white}
            width="112px"
            height="44px"
          />
        </div>
      </ModalBody>
    </Modal>
  );
}

const ScoreModal = ({onClose, onAssign, onReject}) => {
  return (
    <Modal size="sm" isOpen={true} toggle={onClose}>
      <ModalBody style={{padding: "24px 32px"}}>
        <div style={{...fonts.body2Medium, color: colors.gray900, marginBottom: 32, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: 24}}>
          과제 채점
        </div>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 8}}>
          <Button size="small" text="승인" onClick={onAssign} width="118px" height="44px" />
          <Button size="small" text="반려" onClick={onReject} buttonColor={colors.warning} width="118px" height="44px" />
        </div>
      </ModalBody>
    </Modal>
  );
}


const Container = styled.div`
  padding: 32px 40px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  border-radius: 12px;
  height: 650px;
`;

const MemberListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  width: 100%;
  height: 100%;
  margin-top: 4px;
`;

const MemberItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  height: 72px;
  width: 100%;
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  top: 30px;
  right: 10px;
  background-color: ${colors.white};
  border-radius: 8px;
  border: 1px solid ${colors.gray100};
  width: 76px;
`;

const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  cursor: pointer;
  font-size: ${fonts.body3Regular.fontSize};
  color: ${colors.gray500};
  font-family: ${fonts.body3Regular.fontFamily};
  line-height: ${fonts.body3Regular.lineHeight};
  &:hover {
    background-color: ${colors.blue50};
  }
  
  border-radius: 8px;
`;

export default MissionDetail;

const fileResize = (size) => {

  const dataSize = size;
  let formattedData = '';
  
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  if (dataSize === 0) return '0 Byte';
  
  const k = Math.floor(Math.log(dataSize) / Math.log(1024));

  formattedData =  Math.round(100 * (dataSize / Math.pow(1024, k))) / 100 + ' ' + sizes[k];
  return formattedData;
}


const onDownload = async (url, name) => {
  const STORAGE_BASE_URL = process.env.REACT_APP_BUCKET_URL;
  const fileUrl = `${STORAGE_BASE_URL}/${url}`;

  try {
      const response = await fetch(fileUrl, { method: "GET" });
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = name;
      document.body.appendChild(a);
      a.click();

      // 메모리 해제
      window.URL.revokeObjectURL(blobUrl);
      document.body.removeChild(a);
  } catch (error) {
      console.error("파일 다운로드 중 오류 발생:", error);
  }
};



const getStatusChip = (status: "NONE" | "SUBMITTED" | "REJECTED" | "APPROVED") => {
  switch (status) {
    case "NONE":
      return <div style={{...fonts.label4Medium, backgroundColor: colors.gray100, borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center", width: "43px", height: "24px"}}>
        <span style={{color: colors.gray500}}>대기</span>
      </div>;
    case "SUBMITTED":
      return <div style={{...fonts.label4Medium, backgroundColor: colors.gray100, borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center", width: "43px", height: "24px"}}>
        <span style={{color: colors.gray500}}>대기</span>
      </div>;
    case "REJECTED":
      return <div style={{...fonts.label4Medium, backgroundColor: colors.warning50, borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center", width: "43px", height: "24px"}}>
        <span style={{color: colors.warning}}>반려</span>
      </div>;
    case "APPROVED":
      return <div style={{...fonts.label4Medium, backgroundColor: colors.blue50, borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center", width: "43px", height: "24px"}}>
        <span style={{color: colors.success}}>승인</span>
      </div>;
  }
}