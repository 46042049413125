import React, {useRef, useState} from 'react';
import {Modal, ModalBody} from "reactstrap";
import './modal.scss'
import strings from "../../../lang/strings";
import PerfectScrollbar from "react-perfect-scrollbar";

const ReviewDetailModal = (props) => {
    const [fileList, setFileList] = useState([])
    const imageRef = useRef();
    const [urlList, setUrlList] = useState([]);
    const [review, setReview] = useState("")

    const onImageAdd = () => {
        if (fileList.length < 10) {
            imageRef?.current.click();
        } else {
            maxImageError();
        }
    }

    const onImageChange = (e, idx) => {

        if (e.target.files.length < 1) {
            return;
        }

        let file = e.target.files[0];
        let reader = new FileReader();

        reader.onloadend = function () {
            setFileList([...fileList, reader.result]);
            setUrlList([...urlList, file]);
        };
        reader.readAsDataURL(file);
    };

    const delFile = (idx) => {
        setFileList(preList => preList.filter((item, index) => {
            return idx != index
        }));
        setUrlList(preList => preList.filter((item, index) => {
            return idx != index
        }));
    }

    const onOpened = () => {
        setReview("");
        setFileList([]);
        setUrlList([]);
    }

    const {show, close, toggle, onPhoto, expert, maxImageError, onWrite} = props;
    return (
        <Modal isOpen={show} toggle={() => toggle()} className='clinic-review-modal write-detail'
               onOpened={() => onOpened()}>
            <ModalBody className="p-0">
                <div className='d-flex header align-item-center m-b-20'>
                    <div className="f-s-20">{strings.write_review}</div>
                    <div className='flex-grow-1'/>
                    <button className='modal-close-btn' onClick={() => close()}>
                        <img src={"/assets/image/icon_close_white.png"} alt="" className="modal-close"/>
                    </button>
                </div>
                <div className="consultant f-s-16">{expert.name + strings._consultant}</div>
                <div className="d-flex align-item-center m-b-15">
                    <div className="f-s-16">{strings.review}</div>
                    <input type='file' accept='image/*' className='hide'
                           onChange={(e) => onImageChange(e, fileList.length)}
                           ref={imageRef}/>
                    <div className="clinic-review-hint f-s-15 m-l-5">{strings.max_500}</div>
                </div>
                <div className="search-box write-detail-container">
                        <textarea className="input-transparent"
                                  value={review}
                                  onChange={(v) => setReview(v.target.value)}
                                  maxLength={500}
                        />
                </div>

                <div className="d-flex align-item-center m-t-20 m-b-15">
                    <div className="f-s-16">{strings.photo_files}</div>
                    <div className="clinic-review-hint f-s-15 m-l-5  flex-grow-1">{strings.max_10}</div>
                    {
                        fileList.length != 0 &&
                        <div className="d-flex">
                            <button className="upload-btn" onClick={() => onImageAdd()}>
                                <div>{strings.file_photo}</div>
                                <img src={"/assets/image/icon_photo.png"} alt=""/>
                            </button>

                            <button className="upload-btn" onClick={() => onPhoto()}>
                                <div>{strings.camera}</div>
                                <img src={"/assets/image/icon_camera.png"} alt=""/>
                            </button>
                        </div>
                    }

                </div>
                <div className="search-box write-detail-container m-b-20">
                    {
                        fileList.length == 0 &&
                        <div className="d-flex" style={{width: "100%"}}>
                            <div className="ask-file" style={{borderRight: "#3d3c41 1px solid"}}>
                                <button style={{border: "none", background: "none"}}
                                        onClick={() => onImageAdd()}>
                                    <img src={"/assets/image/icon_photo.png"} alt=""/>
                                    <div style={{
                                        whiteSpace: "break-spaces",
                                        lineHeight: "13px",
                                        color: "#a5a4ae",
                                        marginTop: 5
                                    }}>
                                        {strings.upload_photo}
                                    </div>
                                </button>
                            </div>
                            <div className="ask-file">
                                <button className="common-button" onClick={() => onPhoto()}>
                                    <img src={"/assets/image/icon_camera.png"} alt=""/>
                                    <div style={{color: "#a5a4ae", marginTop: 5}}>
                                        {strings.take_photo}
                                    </div>
                                </button>
                            </div>
                        </div>
                    }
                    {
                        // fileList.length != 0 &&
                        <PerfectScrollbar className="d-flex" style={{paddingTop: 10}} options={{suppressScrollY: true}}>
                            {
                                fileList.map((item, idx) =>
                                    <div className="position-relative m-r-15" key={idx}>
                                        <img style={{width: 110, height: 110, borderRadius: 5}} src={item} alt=""/>
                                        <button
                                            style={{
                                                border: "none",
                                                background: "none",
                                                position: "absolute",
                                                top: -10,
                                                right: -20
                                            }}
                                            onClick={() => {
                                                delFile(idx)
                                            }}
                                        >
                                            <img src={"/assets/image/btn_delete.png"} alt=""/>
                                        </button>
                                    </div>
                                )
                            }
                        </PerfectScrollbar>
                    }

                </div>
                <div className={"d-flex justify-content-center"}>
                    <button
                        className={"review-next " + (review != "" ? "" : "review-next-disable")}
                        onClick={() => {
                            if (review != "") {
                                onWrite(review, urlList)
                            }
                        }}
                    >{strings.write}</button>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default ReviewDetailModal;
