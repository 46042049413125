import { withRouter } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import { rootStore } from "../../mobx/store";
import { Page_settings } from "../../config/page_settings";
import strings from "../../lang/strings";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import AddChapterUnitModal from "./modal/add_chapter_unit_modal";
import CreateCurriculumStep4Modal from "./modal/create_curriculum_step4_modal";
import WarningModal from "./modal/warning_modal";
import StudentCourseInfoModal from "./modal/student_course_info_modal";
import StudentWarningModal from "./modal/student_warning_modal";
import YoutubeViewModal from "../../components/control/modal/youtube_view_modal";
import CurriculumVideoModal from "./modal/video_modal";

const StudentCurriculumInfo = (props) => {
  const history = useHistory();
  const context = useContext(Page_settings);
  const [categoryColor, setCategoryColor] = useState('yellow'); //카테고리 색상값
  const [selectedPart, setSelectedPart] = useState();
  const [selectedPartIdx, setSelectedPartIdx] = useState(0);
  const [warningModal, setWarningModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [selectedSubItem, setSelectedSubItem] = useState({});
  const [showLinkVideo, setShowLinkVideo] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [thumbnailData, setThumbnailData] = useState();
  const [curriculumData, setCurriculumData] = useState([]);
  const [clickPartItem, setClickPartItem] = useState();
  const [clickChapterItem, setClickChapterItem] = useState();
  const [clickSectionItem, setClickSectionItem] = useState();
  const [clickUnitItem, setClickUnitItem] = useState();
  const curriculum_id = parseInt(props.match.params.id);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [filteredPart, setFilteredPart] = useState();

  const scrollRef = useRef();
    const mouseCoords = useRef({
        startX: 0,
        startY: 0,
        scrollLeft: 0,
        scrollTop: 0
    });

  useEffect(() => {
    loadCurriculumData();
  }, []);

  // useEffect(() => {
  //   loadStudyData();
  // }, [selectedPartIdx]);

  

  const loadCurriculumData = () => {

    context.get(
      "curriculum/getCurriculumData",
      {
        curriculum_id
      },
      response => {
        console.log("response",response);
        setThumbnailData(response.list.thumbnail)
        setCurriculumData(response.list.parts)
        setFilteredPart(response.list.parts)
        setSelectedPart(response.list.parts.name)
      }
    );
  }


  const handleCategoryClick = (title, color, index) => {
    setSelectedPart(title);
    setCategoryColor(color);
    setSelectedPartIdx(index)
  };



  const openModal = (part, chapter, section, unit) => {
    setClickPartItem(part)
    setClickChapterItem(chapter)
    setClickSectionItem(section)
    setClickUnitItem(unit)
    setOpenInfoModal(true)
  }
const handleDragStart = (e) => {
    const startX = e.pageX - scrollRef.current._container.offsetLeft;
    const startY = e.pageY - scrollRef.current._container.offsetTop;
    const scrollLeft = scrollRef.current._container.scrollLeft;
    const scrollTop = scrollRef.current._container.scrollTop;
    mouseCoords.current = { startX, startY, scrollLeft, scrollTop }
    setIsMouseDown(true);
}

const handleDragEnd = (e) => {
    setIsMouseDown(false);
}

const handleDrag = (e) => {
    if (!isMouseDown) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current._container.offsetLeft;
    const y = e.pageY - scrollRef.current._container.offsetTop;
    const walkX = x - mouseCoords.current.startX;
    const walkY = y - mouseCoords.current.startY;
    scrollRef.current._container.scrollLeft = mouseCoords.current.scrollLeft - walkX;
    scrollRef.current._container.scrollTop = mouseCoords.current.scrollTop - walkY;
}
  const clickMoveTest = () => {
    setOpenInfoModal(false);
    setWarningModal(true);
  };

  const onWatchVideo = (item) => {
    if (item.link !== null && item.link !== '') {
      setShowLinkVideo(true);
      addStudySubsection(item.id, 'WATCH');
    } else if (item.video_url !== null && item.video_url !== '') {
      setShowVideo(true);
      addStudySubsection(item.id, 'WATCH');
    } else {
      alert("영상 준비중입니다.");
      return;
    }

  }

  const addStudySubsection = (unit, type) => {
    context.post(
      "curriculum/addStudySubsection",
      {
        unit,
        type
      },
      response => {
        
      }
    );
  }



  return (
    <div class="main-content student-curriculum-info-container">

      {openInfoModal && (<StudentCourseInfoModal
        open={openInfoModal}
        onClose={() => {
          setOpenInfoModal(false);
        }}
        clickMoveTest={() => {
          clickMoveTest()
        }}
        onView={(item) => {
          setSelectedSubItem(item);
          onWatchVideo(item);
        }}
        partItem={clickPartItem}
        chapterItem={clickChapterItem}
        sectionItem={clickSectionItem}
        unitItem={clickUnitItem}
      />)}

      {warningModal && (<StudentWarningModal
        open={warningModal}
        onClose={() => {
          setWarningModal(false);
        }}
        mode={"test"}
        sectionItem={clickSectionItem}
      />)}

      {
        showLinkVideo &&
        <YoutubeViewModal
          show={showLinkVideo}
          toggle={() => {
            setShowLinkVideo(!showLinkVideo);
            // loadRoadmapList(selectedSubject.id, detailSubject);
            // onClickSubItem(selectedMiddleItem, selectedItem);
          }}
          title={selectedSubItem.name}
          url={selectedSubItem.link}
          mode={'curriculum'}
        />
      }

      {
        showVideo &&
        <CurriculumVideoModal
          show={showVideo}
          toggle={() => setShowVideo(!showVideo)}
          close={() => setShowVideo(false)}
          onBack={() => {
            setShowVideo(false);
          }}
          item={selectedSubItem}
        />
      }

      <div className="content-container">
        <div className="container-top">
          <div className="title">{thumbnailData?.title ? thumbnailData.title : null}</div>
          {curriculumData.length > 0 ?
            <div className="part-box" >
              {curriculumData.map((item, index) => (
                <div
                  key={index}
                  className={`partCategory-box ${selectedPartIdx === index ? item.color : ''}`}
                  onClick={() => handleCategoryClick(item.name, item.color, index)}>
                  <div>{item.name}</div>
                </div>
              ))}
            </div>
            :
            null
          }
        {
  filteredPart ? (
    <PerfectScrollbar
      className="section-unit-container"
      ref={scrollRef}
      style={{ height: '100%', cursor: "grab", padding: '10px 0px'  }}
      onMouseDown={handleDragStart}
      onMouseUp={handleDragEnd}
      onMouseMove={handleDrag}
    >
      {filteredPart[selectedPartIdx].chapters.map((chapter, chapterIdx) => (
        <React.Fragment key={chapterIdx}>
          <div className="row-box">
            <div className="chapter">{chapter.name}</div>
            {chapter.sections.map((section, sectionIdx) => (
              <div className="section-container" key={sectionIdx}>
                <div className="section-box">
                  <div className="section-title">{section.name}</div>
                  <div className={`unit-box-outside ${filteredPart[selectedPartIdx]?.color}`}>
                    {section.units.map((unit, unitIdx) => (
                      <div
                        className={`unit-box-inside`}
                        key={unitIdx}
                        onClick={() => openModal(filteredPart[selectedPartIdx], chapter, section, unit)}
                      >
                        {unit.name}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </React.Fragment>
      ))}
    </PerfectScrollbar>
  ) : null
}




        </div>
      </div >


    </div >
  );






}
export default withRouter(StudentCurriculumInfo);
