import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app.jsx';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { rootStore } from "./mobx/store";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import 'chart.js/auto';

// v1.0.0
// css
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-notifications-component/dist/theme.css';
import 'react-datetime/css/react-datetime.css';
import './assets/css/default/app.min.css';
import './scss/react.scss';
import './scss/font.scss';
import './scss/custom.scss';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'react-image-lightbox/style.css';
// ========================================

// extension
import './helper/extension';
import 'moment/locale/ko';
// ========================================

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then(function(registration) {
      console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function(err) {
      console.log("Service worker registration failed, error:", err);
    });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider rootStore={rootStore}>
      <App/>
    </Provider>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
