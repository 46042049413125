import React from "react";
import strings from "../lang/strings";

import { IconClass, IconClassFilled } from "../components/icons/class";
import { IconDatalab, IconDatalabFill } from "../components/icons/datalab";
import { IconStudy, IconStudyFilled } from "../components/icons/study";
import colors from "../design/colors";
import Find from "../pages/auth/find";
import Login from "../pages/auth/login";
import LoginEmail from "../pages/auth/login_email";
import Signup from "../pages/auth/signup";
import Specialty from "../pages/class/specialty";
import ApplicationForAdmission from "../pages/class/application_for_admission";
import ClassManageStudent from "../pages/class/class_manage_student";
import CounselingLog from "../pages/class/counseling_log/counseling_log";
import CounselingLogDetail from "../pages/class/counseling_log/counseling_log_detail";
import CounselingLogList from "../pages/class/counseling_log/counseling_log_list";
import CreateExam from "../pages/class/exam/create_exam";
import Exam from "../pages/class/exam/exam";
import MissionAdd from "../pages/class/missions/mission_add";
import MissionDetail from "../pages/class/missions/mission_detail";
import MissionList from "../pages/class/missions/mission_list";
import Notification from "../pages/class/notification/notification";
import NotificationInfo from "../pages/class/notification/notificationInfo";
import Planner from "../pages/class/planner";
import Cloud from "../pages/cloud";
import LandingAI from "../pages/landing/Page/AI";
import LandingClass from "../pages/landing/Page/Class";
import LandingMain from "../pages/landing/Page/Main";
import LandingPlatForm from "../pages/landing/Page/PlatForm";
import MissionStudentDetail from "../pages/class/missions/mission_student_detail";
import MissionEdit from "../pages/class/missions/mission_edit";
import ExamStudent from "../pages/class/exam/exam_student";
import ExamStudentResult from "../pages/class/exam/exam_student_result";
import ExamTeacher from "../pages/class/exam/exam_teacher";
import ApplicationForAdmissionDetail from "../pages/class/application_for_admission_detail";
import ClassSchedule from "../pages/class/schedule";

export const Extra = [
    {
        path: '/login', title: strings.login.login, exact: true, private: false,
        component: () => <Login/>
    },
    {
        path: '/login/email', title: strings.login.login, exact: true, private: false,
        component: () => <LoginEmail/>
    },
    {
        path: '/landing/main', title: "츄잉", exact: true, private: false,
        component: () => <LandingMain/>
    },
    {
        path: '/landing/class', title: "클래스", exact: true, private: false,
        component: () => <LandingClass/>
    },
    {
        path: '/landing/ai', title: "AI", exact: true, private: false,
        component: () => <LandingAI/>
    },
    {
        path: '/landing/platform', title: "플랫폼", exact: true, private: false,
        component: () => <LandingPlatForm/>
    },
    {
        path: '/signup/:step', title: strings.signup, exact: true, private: false,
        component: () => <Signup/>
    },{
        path: '/find/:step', title: strings.find, exact: true, private: false,
        component: () => <Find/>
    },{
        path: '/class/notification/:notification_id', title: "공지사항 상세", exact: true, private: false,
        component: () => <NotificationInfo/>
    },
    {
        path: '/study/mission/add', title: "과제 추가", exact: true, private: true,
        component: () => <MissionAdd/>,
        user_type: "EXPERT"
    },{
        path: '/study/mission/edit/:mission_id', title: "과제 수정", exact: true, private: true,
        component: () => <MissionEdit/>,
        user_type: "EXPERT"
    },
    {
        path: '/study/mission/:mission_id', title: "과제", exact: true, private: true,
        component: () => <MissionDetail/>,
        user_type: "EXPERT"
    },
    {
        path: '/study/mission/:mission_id', title: "과제", exact: true, private: true,
        component: () => <MissionStudentDetail/>,
        user_type: "STUDENT"
    },
    {
        path: '/study/exam/add/:exam_id', title: "시험 출제", exact: true, private: false,
        component: () => <CreateExam/>
    },
    {
        path: '/study/exam/:exam_id/ing', title: "시험", exact: true, private: true,
        component: () => <ExamStudent/>,
        user_type: "STUDENT"
    },
    {
        path: '/study/exam/:exam_id/result', title: "시험 결과", exact: true, private: true,
        component: () => <ExamStudentResult/>,
        user_type: "STUDENT"
    },
    {
        path: '/study/exam/:exam_id/info', title: "시험 정보", exact: true, private: true,
        component: () => <ExamTeacher/>,
        user_type: "EXPERT"
    },
    {
        path: '/cloud',
        title: strings.menu.cloud,
        component: () => <Cloud/>
    },{
        path: '/class/application-for-admission',
        title: '원서 제출',
        component: () => <ApplicationForAdmission/>
    },{
        path: '/class/application-for-admission/:id',
        title: '원서 상세',
        component: () => <ApplicationForAdmissionDetail/>
    },{
        path: '/class/counseling-log/add',
        title: '상담 일지 작성',
        component: () => <CounselingLog/>,
        user_type: 'EXPERT'
    }, {
        path: '/class/counseling-log/:id/edit',
        title: '상담 일지 수정',
        component: () => <CounselingLog/>,
        user_type: 'EXPERT'
    }
    ,{
        path: '/class/counseling-log/:id',
        title: '상담 일지 상세',
        component: () => <CounselingLogDetail/>,
        user_type: 'EXPERT'
    }
];

const Menu = [
    {
        path: '/class',
        iconActive: <IconClassFilled width="20px" height="20px" color={colors.blue600}/>,
        iconInactive: <IconClass width="20px" height="20px" color={colors.gray500}/>,
        title: strings.menu.class,
        children: [
            {
                path: '/class', title: "홈", exact: true, private: true,
                component: () => <ClassSchedule/>,
                containPath: ["/class"]
            },{
                path: '/class/member',
                title: '학생관리',
                exact: true,
                private: true,
                component: () => <ClassManageStudent/>,
                user_type: "EXPERT",
                containPath: ["/class/member"]
            },
            {
                path: '/class/notification', title: "공지사항", exact: true, private: false,
                component: () => <Notification/>,
                containPath: ["/class/notification"]
            }, 
            {
                path: '/class/counseling-log/list', 
                title: '상담일지', 
                exact: true, 
                private: true,
                component: () => <CounselingLogList/>,
                user_type: "EXPERT",
                containPath: ["/class/counseling-log/list", "/class/counseling-log/add", "/class/counseling-log/:id/edit", "/class/counseling-log/:id"]
            },{
                path: '/class/planner', title: "플래너", exact: true, private: true,
                component: () => <Planner/>,
                user_type: "STUDENT",
                containPath: ["/class/planner"]
            }
        ]
    }
    ,{
        path: '/study/mission',
        title: '학습',
        iconActive: <IconStudyFilled width="20px" height="20px" color={colors.blue600}/>,
        iconInactive: <IconStudy width="20px" height="20px" color={colors.gray500}/>, 
        children: [
            {
                path: '/study/mission',
                title: '과제',
                component: () => <MissionList/>,
                containPath: ["/study/mission", "/study/mission/:mission_id"]
            },
            {
                path: '/study/exam',
                title: '시험',
                component: () => <Exam/>,
                containPath: ["/study/exam"]
            },
        ]
    },
    {
        path: '/report/specialty',
        iconActive: <IconDatalabFill width="20px" height="20px" color={colors.blue600}/>,
        iconInactive: <IconDatalab width="20px" height="20px" color={colors.gray500}/>,
        title: "데이터랩",
        children: [ {
            path: '/report/specialty',
            component: () => <Specialty/>,
            title: "생기부 노트",
            containPath: ["/report/specialty"]
        }],
        user_type: 'STUDENT',
        containPath: ["/report/specialty"]
    }
];

export default Menu;
