import React, { useContext, useState, useEffect } from "react";
import {Page_settings} from "../../../config/page_settings";
import moment from "moment";
import styled from "styled-components";

interface IFile {
    id: number;
    name: string;
    type: string;
    url: string;
    size?: number;
}

interface IChat {
    id: number;
    room: number;
    sender: number;
    message: string;
    create_date: string;
    is_read: number;
    user: {
      sender_name: string;
      sender_profile: string | null;
    };
    file: IFile[] | null;
};

interface IPartner {
    id: number;
    name: string;
    profile: string | null;
}

interface IChewingTalkChatItem {
    item: IChat;
    mine: boolean;
    partner: IPartner;
    setLightboxImage: (url: string) => void;
}
const STORAGE_BASE_URL = 'https://kr.object.ncloudstorage.com/chewing-storage';

const ChewingTalkChatItem = (props: IChewingTalkChatItem) => {

    const {item, mine, partner, setLightboxImage} = props;
    
    return (
        <ChatItemContainer mine={mine}>
            {mine && (
                <ChatAllWrapper>
                    <ChatTimeAndReadContainer mine={true}>
                        <ChatRead>{item.is_read === 1 ? "" : "1"}</ChatRead>
                        <ChatTime mine={true}><span color={'#A8ACBA'}>{moment(item.create_date).format("HH:mm")}</span></ChatTime>
                    </ChatTimeAndReadContainer>
                    { item.file ? 
                        item.file.map((file_i, index) => (
                            <ChatFile key={index} file={file_i} setLightboxImage={setLightboxImage}/>
                        ))
                        : 
                        <ChatArea mine={true}>
                            {item.message}
                        </ChatArea>
                    }
                </ChatAllWrapper>
            )}
            {!mine && (
                <>
                <ChatProfile src={partner.profile ? partner.profile : "/assets/image/chewing_plus_logo.png"} />
                <ChatAllWrapper>
                    <ChatMessageAndNameContainer>
                        <ChatName>{partner.name}</ChatName>
                        {item.file ? 
                           item.file.map((file_i, index) => (
                            <ChatFile key={index} file={file_i} setLightboxImage={setLightboxImage}/>
                        ))
                        :
                        <ChatArea mine={false}>
                            {item.message}
                        </ChatArea>
                        }
                    </ChatMessageAndNameContainer>
                    <ChatTimeAndReadContainer mine={false}>
                        <ChatRead>{item.is_read === 1 ? "" : "1"}</ChatRead>
                        <ChatTime mine={false}><span color={'#A8ACBA'}>{moment(item.create_date).format("HH:mm")}</span></ChatTime>
                    </ChatTimeAndReadContainer>
                </ChatAllWrapper>
                </>
            )}
        </ChatItemContainer>
    );
}

const ChatFile = (props: {file: IFile, setLightboxImage: (string) => void}) => {
    const context: any = useContext(Page_settings);
    const {file, setLightboxImage} = props;
    const ext = file.url.split(".")[file.url.split(".").length - 1];
    if (ext === "jpg" || ext === "jpeg" || ext === "png" || ext === "gif") {
        return (
        <ImageWithFallback 
            src={context.loadImage(file.url)} 
            alt={file.name} 
            fallbackSrc={`/assets/image/icon_file_${ext}.png`} 
            setLightboxImage={setLightboxImage}    
        />
        )
            
    } else if (ext === "mp4" || ext === "avi" || ext === "mov" || ext === "wmv") {
        return (
        <ImageBox>
            <video src={context.loadImage(file.url)} controls style={{height:'150%'}}/>
        </ImageBox>
        )
    } else {
        return (
            <button style={{marginBottom: 10}} onClick={() => handleDownload(file)}>
                <FileContainer file={file} />
            </button>
            
        )
    }
}

const handleDownload = (file: IFile) => {
    const a = document.createElement("a");
    a.href = STORAGE_BASE_URL+"/"+file.url;
    a.download = file.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

export default ChewingTalkChatItem;

const ImageWithFallback = ({ src, alt, fallbackSrc, setLightboxImage, ...props }) => {
    const [error, setError] = useState(false);
  
    return (
        error ? <FileContainer file={{id: 0, name: alt, type: "image", url: fallbackSrc}} /> :
        <button onClick={() => setLightboxImage(src)} >
            <ImageBox>
                <img src={src} alt={alt} onError={() => setError(true)} {...props} style={{width:'100%'}}/>
            </ImageBox>
        </button>
    )
  };

const FileContainer = ({file}: {file: IFile}) => {
    const [fileSize, setFileSize] = useState<string | null>(null);

    const ext = file.url.split(".")[file.url.split(".").length - 1];

    // 파일 용량을 계산하여 반환하는 함수
    const getFileSize = async (url: string) => {
        const res = await fetch(url);
        const blob = await res.blob();
        const size = blob.size;

        if (size < 1024) {
            return `${size} B`;
        } else if (size < 1024 * 1024) {
            return `${(size / 1024).toFixed(2)} KB`;
        } else {
            return `${(size / 1024 / 1024).toFixed(2)} MB`;
        }
    };

    useEffect(() => {
        if(file.size) {
            setFileSize(file.size < 1024 ? `${file.size} B` : file.size < 1024 * 1024 ? `${(file.size / 1024).toFixed(2)} KB` : `${(file.size / 1024 / 1024).toFixed(2)} MB`);
            return;
        }
        getFileSize(file.url).then(size => setFileSize(size));
    }, [file.url]);
    
    return (<FileBox>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", maxWidth: "70%"}}>
                    <span style={{fontSize: 13, fontWeight: "bolder"}}>{file.name.length > 25 ? file.name.substring(0,25) + "...": file.name}</span>
                </div>
                <div style={{display:"flex", flexDirection: "row", justifyContent:"space-between"}}>
                    <div style={{display:"flex", flexDirection: "row", alignItems: "flex-end", fontSize: 13}}>{fileSize}</div>
                    <img src={`/assets/image/icon_file_${ext}.png`} alt={``} width={50} />
                </div>
            </FileBox>)
}

const ChatItemContainer = styled.div<{mine: boolean}>`
    display: flex;
    margin-left: ${props => props.mine ? "auto" : "20px"};
    margin-right: ${props => props.mine ? "20px" : "auto"};
    justify-content: ${props => props.mine ? "flex-end" : "flex-start"};
`;

const ChatProfile = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin-right: 10px;
    margin-bottom: "auto";
    object-fit: "cover";
`;

const ChatMessageAndNameContainer = styled.div`
    max-width: 70%;
`;

const ChatName = styled.div`
    font-size: 14px;
    font-weight: 600;
    margin-right: 10px;
`;

const ChatTimeAndReadContainer = styled.div<{mine: boolean}>`
    margin-left: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.mine ? "flex-end" : "flex-start"};
`;

const ChatTime = styled.div<{mine: boolean}>`
    color: "#A8ACBA";
    font-size: 13px;
    min-width: 90px;
    padding-left: 20;
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.mine ? "flex-end" : "flex-start"};
`;

const ChatRead = styled.div`
    font-size: 12px;
    color: #0F84F4;
    margin-left: auto;
`;

const ChatArea = styled.div<{mine: boolean}>`
    display: flex;
    flex-direction: column;
    background-color: ${props => props.mine ? "#323D4D" : "#FEFEFE"};
    border-bottom-left-radius: 10px;	
    border-bottom-right-radius: 10px;
    border-top-left-radius: ${props => props.mine ? "10px" : "0px"};
    border-top-right-radius: ${props => props.mine ? "0px" : "10px"};
    padding: 10px;
    margin-bottom: 10px;
    word-break: break-all;
    font-weight: ${props => props.mine ? "600" : "500"};
    color: ${props => props.mine ? "#FFFFFF" : "#000000"};
    font-size: 16px;
`;

const ChatAllWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
`

const FileBox = styled.div`
    width: 220px;
    height: 140px;
    border-radius: 12px;
    background-color: #FFFFFF;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
`;

const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 140px;
  overflow: hidden;
  border-radius: 12px;
  margin-bottom: 10px;
  background-color: black;
`