import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Page_settings } from '../../../config/page_settings';
import strings from '../../../lang/strings';
import LateNotificationModal from '../Modals/late_notification_modal';
import LateNotificationSendModal from '../Modals/late_notification_send_modal';
import moment from "moment";
import { rootStore } from '../../../mobx/store';

const Note = observer((props) => {
  const context = useContext(Page_settings);
  const { setMode } = props;
  const class_id = parseInt(props.match.params.class_id);
  const [memberList, setMemberList] = useState([]);
  const [status, setStatus] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);
  const [lateMode, setLateMode] = useState(false);
  const [checkedMembers, setCheckedMembers] = useState([]);
  const [checkedCount, setCheckedCount] = useState(0);
  const [openSendModal,setOpenSendModal] = useState(false);
  const late_alarm = rootStore.getProfile?.late_alarm;


  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    context.get(
      'attendance/getAttendanceList',
      {
        class_id,
        selected_date:moment(new Date()).format("YYYY-MM-DD"),
        mode: "0"
      },
      response => {
        setMemberList(response)
      }
    );
  }
  


  const changeStatus = (index, newStatus) => {
    setMemberList(prevList => {
      const newList = [...prevList];
      const currentMember = newList[index];


      if (currentMember.status === newStatus) {
        currentMember.status = -1;
        addAttendance(currentMember.member,-1);
      } else {
        currentMember.status = newStatus;
        addAttendance(currentMember.member,newStatus);
      }

      return newList;
    });
  }

  const addAttendance = (member, status) => {

    context.post(
      'attendance/addAttendance',
      {
        class_id,
        member,
        status,
        // phone:"75528601",
        // mode: 'kiosk'
      },
      response => {
        
      }
    );
  }

  const handleCheckAll = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setCheckedMembers(memberList);
      setCheckedCount(memberList.length);
    } else {
      setCheckedMembers([]);
      setCheckedCount(0);
    }
  }

  const handleCheckMember = (member) => {
    setCheckedMembers(prev => {
      const isAlreadyChecked = prev.some(m => m.member === member.member);
      if (isAlreadyChecked) {
        setCheckedCount(prevCount => prevCount - 1);
        return prev.filter(m => m.member !== member.member);
      } else {
        setCheckedCount(prevCount => prevCount + 1);
        return [...prev, member];
      }
    });
  }

  const openLateNotificationModal = () => {

    if(late_alarm==="ON"){
      setIsOpen(true);
    }

    setLateMode(true);
  }

  const MoveBack = () => {
    props.history.push(`/class`)
  }

  const handleSendClick = () => {
    if (checkedCount > 0) {
      setOpenSendModal(true);
    }
  }

  return (
    <div className='attendance-container'>
      {isOpen && (<LateNotificationModal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        setMode={setMode}
      />)}

{openSendModal && (<LateNotificationSendModal
        open={openSendModal}
        onClose={() => {
          setOpenSendModal(false);
        }}
        checkedCount={checkedCount}
        checkedMembers={checkedMembers}
        class_id={class_id}
      />)}
      <div className="return-container" >
        <svg className="cursor" xmlns="http://www.w3.org/2000/svg" width="17" height="29" viewBox="0 0 17 29" fill="none" onClick={MoveBack}>
          <path d="M17 25.5925L6.49258 14.5L17 3.4075L13.7652 -2.82797e-07L7.42695e-07 14.5L13.7652 29L17 25.5925Z" fill="black" />
        </svg>
        <div className="text">출결</div>
      </div>
      <div className="top-content">
        {lateMode ?
          <div className='late-mode'>
            <div className="left">
              <div className="checkbox-box">
                <input type="checkbox" className="all-check" onChange={handleCheckAll} />
                <div className="text">전체 선택</div>
              </div>
            </div>
            <div className="right">
              <div className='cancel-btn' onClick={() => setLateMode(false)}>
                취소하기
              </div>
              <div className={`send-btn ${checkedCount > 0 ? 'on' : ''}`} onClick={handleSendClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <g clipPath="url(#clip0_2230_10619)">
                    <path d="M17.6848 0.161106L2.2825 5.28593C1.1887 5.65018 0.344696 6.56906 0.0817009 7.68382C-0.147182 8.65442 0.111412 9.62942 0.774952 10.293C0.813466 10.3315 0.854181 10.3667 0.898197 10.3975L6.87886 14.6805C7.32343 14.9985 7.94295 14.8962 8.26097 14.4516C8.27527 14.4318 8.28627 14.412 8.29948 14.3922L13.1258 9.61512C13.5143 9.22996 13.5176 8.6038 13.1335 8.21424C12.7484 7.82578 12.1223 7.82248 11.7327 8.20654L7.32893 12.5643L2.13394 8.8437C1.93917 8.6071 1.97109 8.30338 2.0107 8.13721C2.11634 7.69262 2.46847 7.30966 2.90973 7.1633L18.3098 2.04068C18.6542 1.92624 18.9667 1.97576 19.1681 2.17714C19.3695 2.37852 19.419 2.69105 19.3046 3.0355L14.18 18.4397C14.0337 18.8799 13.6507 19.2331 13.2062 19.3387C13.04 19.3784 12.7374 19.4103 12.4997 19.2155L9.98199 15.7028C9.66397 15.2583 9.04445 15.1559 8.59989 15.475C8.15533 15.7942 8.05299 16.4126 8.37211 16.8572L10.9481 20.4513C10.9801 20.4953 11.0142 20.536 11.0527 20.5746C11.5567 21.0786 12.2411 21.3493 12.9674 21.3493C13.1963 21.3493 13.4295 21.3229 13.6617 21.2678C14.7764 21.0048 15.6953 20.1608 16.0595 19.0669L21.1841 3.66276C21.5384 2.59641 21.3084 1.51797 20.5689 0.778461C19.8295 0.0389552 18.7511 -0.19104 17.6848 0.163307V0.161106Z" fill="white" />
                  </g>
                  <defs>
                    <clipPath id="clip0_2230_10619">
                      <rect width="21.3488" height="21.3488" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div >전송하기</div>
              </div>
            </div>
          </div>
          :
          <div className='basic-mode'>
            <div className="left">
              <div className="menu-btn selected">출석부</div>
              <div className="menu-btn" onClick={() => setMode(1)}>기록</div>
            </div>
            <div className="right">
              <div className='late-btn' onClick={openLateNotificationModal}>
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <g clip-path="url(#clip0_2230_10619)">
                    <path d="M17.6848 0.161106L2.2825 5.28593C1.1887 5.65018 0.344696 6.56906 0.0817009 7.68382C-0.147182 8.65442 0.111412 9.62942 0.774952 10.293C0.813466 10.3315 0.854181 10.3667 0.898197 10.3975L6.87886 14.6805C7.32343 14.9985 7.94295 14.8962 8.26097 14.4516C8.27527 14.4318 8.28627 14.412 8.29948 14.3922L13.1258 9.61512C13.5143 9.22996 13.5176 8.6038 13.1335 8.21424C12.7484 7.82578 12.1223 7.82248 11.7327 8.20654L7.32893 12.5643L2.13394 8.8437C1.93917 8.6071 1.97109 8.30338 2.0107 8.13721C2.11634 7.69262 2.46847 7.30966 2.90973 7.1633L18.3098 2.04068C18.6542 1.92624 18.9667 1.97576 19.1681 2.17714C19.3695 2.37852 19.419 2.69105 19.3046 3.0355L14.18 18.4397C14.0337 18.8799 13.6507 19.2331 13.2062 19.3387C13.04 19.3784 12.7374 19.4103 12.4997 19.2155L9.98199 15.7028C9.66397 15.2583 9.04445 15.1559 8.59989 15.475C8.15533 15.7942 8.05299 16.4126 8.37211 16.8572L10.9481 20.4513C10.9801 20.4953 11.0142 20.536 11.0527 20.5746C11.5567 21.0786 12.2411 21.3493 12.9674 21.3493C13.1963 21.3493 13.4295 21.3229 13.6617 21.2678C14.7764 21.0048 15.6953 20.1608 16.0595 19.0669L21.1841 3.66276C21.5384 2.59641 21.3084 1.51797 20.5689 0.778461C19.8295 0.0389552 18.7511 -0.19104 17.6848 0.163307V0.161106Z" fill="white" />
                  </g>
                  <defs>
                    <clipPath id="clip0_2230_10619">
                      <rect width="21.3488" height="21.3488" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <div>지각알림</div>
              </div>
            </div>
          </div>
        }
      </div>
      <div className="middle-content student-list-container">
        {lateMode ? (
          memberList.map((member, index) => (
            <div key={index} className={`student-box late-mode ${member.status === -1 ? '' : member.status === 0 ? 'admissions' : 'exit'}`}>
              <div className='student-info'>
                <div className='left'>
                  <div className='profile-box'><img src={member.profile ? context.loadImage(member.profile) : "/assets/image/chewing_plus_logo.png"} alt='' /></div>
                  <div className='info-box'>
                    <div className='name'>{member.name}</div>
                    <div className='school'>{member.school} {member.grade ? member.grade : '@'}학년</div>
                    <div className='phone'>{member.phone}</div>
                  </div>
                </div>
                <div className="right">
                  <div className="checkbox-box">
                    <input type="checkbox" className="member-check" checked={checkedMembers.some(m => m.member === member.member)} onChange={() => handleCheckMember(member)} />
                  </div>
                </div>
              </div>
              <div className='btn-box'>
                <div className='admissions-btn' onClick={() => changeStatus(index, 0)}>입실</div>
                <div className='exit-btn' onClick={() => changeStatus(index, 1)}>퇴실</div>
              </div>
            </div>
          ))
        ) : (
          memberList.map((member, index) => (
            <div key={index} className={`student-box ${member.status === -1 ? '' : member.status === 0 ? 'admissions' : 'exit'}`}>
              <div className='student-info'>
                <div className='left profile-box'>
                  <img src={member.profile ? context.loadImage(member.profile) : "/assets/image/chewing_plus_logo.png"} alt='' />
                </div>
                <div className="right">
                  <div className='name'>{member.name}</div>
                  <div className='school'>{member.school} {member.grade ? member.grade : '@'}학년</div>
                  <div className='phone'>{member.phone}</div>
                </div>
              </div>
              <div className='btn-box'>
                <div className='admissions-btn' onClick={() => changeStatus(index, 0)}>입실</div>
                <div className='exit-btn' onClick={() => changeStatus(index, 1)}>퇴실</div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
})

export default withRouter(Note);
