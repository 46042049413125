import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { API_URL } from "../../../config/const";
import { Page_settings } from "../../../config/page_settings";
import strings from "../../../lang/strings";
import CalendarModal from "../../../components/calendar_modal";

interface IClassMember {
  id: number;
  memberId: number;
  name: string;
  phone: string;
  profile: string;
  school: string;
}

interface IFile {
  name: string;
  size: number;
  type: string;
  url: string;
}

function MissionAddModal({ onClose, class_id, creator }) {
  const [classMembers, setClassMembers] = useState<IClassMember[]>([]);
  const [studentValue, setStudentValue] = useState("");
  const [titleValue, setTitleValue] = useState("");
  const [contentValue, setContentValue] = useState("");
  const [clickInput, setClickInput] = useState(false);
  const [dropDownList, setDropDownList] = useState(classMembers);
  const [dropDownItemIndex, setDropDownItemIndex] = useState(-1);
  const [allSelectedStatus, setAllSelectedStatus] = useState(false);
  const [checkedItems, setCheckedItems] = useState<IClassMember[]>([]);
  const imageRef = useRef<HTMLInputElement>(null);
  const [fileList, setFileList] = useState<IFile[]>([]);

  const [openCalendar, setOpenCalendar] = useState(false);
  const [settingStartDate, setSettingStartDate] = useState(true);
  const [startHour, setStartHour] = useState<number>(0);
  const [startMinute, setStartMinute] = useState<number>(0);
  const [startDate, setStartDate] = useState<string>(
    moment().format("YYYY-MM-DD")
  );
  const [endHour, setEndHour] = useState<number>(0);
  const [endMinute, setEndMinute] = useState<number>(0);
  const [endDate, setEndDate] = useState<string>(moment().format("YYYY-MM-DD"));

  const [enableConfirm, setEnableConfirm] = useState(false);

  const fileTypes = [
    "image/bmp",
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/tiff",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "text/htm",
    "text/html",
    "application/pdf",
    "application/vnd.ms-powerpoint",
    "applicatiapplication/vnd.openxmlformats-officedocument.presentationml.presentation",
    "text/plain",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "video/mp4",
  ];
  const imgType = ["bmp", "jpg", "jpeg", "png", "tiff"];

  const context: any = useContext(Page_settings);
  useEffect(() => {
    context.get(`class/${class_id}/member`, {}, (response) => {
      setClassMembers(response);
    });
  }, []);

  useEffect(() => {
    if (studentValue === "") {
      setDropDownList([]);
    } else {
      if (classMembers != null) {
        const choosenTextList = classMembers.filter((dataItem) =>
          dataItem.name.includes(studentValue)
        );
        setDropDownList(choosenTextList);

        // Check if there are any unchecked items
        const hasUncheckedItems = choosenTextList.some(
          (item) => !checkedItems.includes(item)
        );

        if (hasUncheckedItems) {
          setAllSelectedStatus(false);
        }
      } else {
        setDropDownList([]);
      }
    }

    if (studentValue === "" && clickInput) {
      if (classMembers != null) {
        const choosenTextList = classMembers.filter((dataItem) =>
          dataItem.name.includes(studentValue)
        );
        setDropDownList(choosenTextList);

        // Check if there are any unchecked items
        const hasUncheckedItems = choosenTextList.some(
          (item) => !checkedItems.includes(item)
        );

        if (hasUncheckedItems) {
          setAllSelectedStatus(false);
        }
      } else {
        setDropDownList([]);
      }
    }
  }, [studentValue, clickInput]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        getIdOrParentId(event) !== "inputBox" &&
        getIdOrParentId(event) !== "dropBox" &&
        getIdOrParentId(event) !== "dropBox1"
      ) {
        handleFocusOut(); // 외부 클릭 시 실행할 함수
      }
    }

    function getIdOrParentId(event) {
      let targetElement = event.target;

      // targetElement에 id가 있으면 해당 id를 사용
      while (targetElement && !targetElement.id) {
        // id가 없으면 부모 요소로 이동
        targetElement = targetElement.parentNode;
      }

      // 최종적으로 찾은 요소의 id 반환, 요소에 id가 없다면 null 반환
      return targetElement ? targetElement.id : null;
    }

    // 문서 전체에 이벤트 리스너 추가
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // 컴포넌트 언마운트 시 이벤트 리스너 제거
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // 의존성 배열에 handleFocusOut 추가

  useEffect(() => {
    if (titleValue === "" || contentValue === "" || checkedItems.length === 0) {
      setEnableConfirm(false);
      return;
    }

    if (startDate === endDate) {
      if (startHour > endHour) {
        setEnableConfirm(false);
        return;
      }
      if (startHour === endHour && startMinute >= endMinute) {
        setEnableConfirm(false);
        return;
      }
    } else if (startDate > endDate) {
      setEnableConfirm(false);
      return;
    }

    const currentTime = moment();
    if (endDate < currentTime.format("YYYY-MM-DD")) {
      setEnableConfirm(false);
      return;
    } else if (endDate === currentTime.format("YYYY-MM-DD")) {
      if (endHour < parseInt(currentTime.format("HH"))) {
        setEnableConfirm(false);
        return;
      } else if (endHour === parseInt(currentTime.format("HH"))) {
        if (endMinute < parseInt(currentTime.format("mm"))) {
          setEnableConfirm(false);
          return;
        }
      }
    }

    setEnableConfirm(true);
  }, [
    titleValue,
    contentValue,
    checkedItems,
    startDate,
    startHour,
    startMinute,
    endDate,
    endHour,
    endMinute,
  ]);

  const changeInputValue = (event) => {
    setStudentValue(event.target.value);
    setClickInput(false);
  };

  const changeTitleValue = (event) => {
    setTitleValue(event.target.value);
  };

  const changeContentValue = (event) => {
    setContentValue(event.target.value);
  };

  const handleEnterKeyPress = () => {
    // setAllSelectedStatus(false);
    setStudentValue("");
    setClickInput(false);
  };

  const clickInputBox = (event) => {
    setClickInput(!clickInput);
  };

  const handleDropDownKey = (event) => {
    if (checkedItems.length > 0) {
      if (event.key === "Enter") {
        handleEnterKeyPress();
      }
    }
  };

  const handleFocusOut = () => {
    setStudentValue("");
    setClickInput(false);
  };

  const handleCheckboxChange = (event, dropDownIndex) => {
    const isChecked = event
      ? event.target.checked
      : !checkedItems.includes(dropDownList[dropDownIndex]);

    if (isChecked) {
      setCheckedItems((prev) => [...prev, dropDownList[dropDownIndex]]);
    } else {
      setCheckedItems((prev) =>
        prev.filter((item) => item !== dropDownList[dropDownIndex])
      );
    }
  };

  const onClickAllStudent = () => {
    setAllSelectedStatus((prev) => !prev);

    if (!allSelectedStatus) {
      setCheckedItems((prev) => {
        const updatedItems = [
          ...prev,
          ...dropDownList.filter((item) => !prev.includes(item)),
        ];
        return updatedItems;
      });
    } else {
      setCheckedItems([]);
    }
  };

  const deleteStudent = (studentID) => {
    // 삭제 버튼 클릭 시 해당 아이디를 checkedItems에서 찾아서 삭제
    setCheckedItems((prevItems) =>
      prevItems.filter((item) => item.memberId !== studentID)
    );
  };

  const handleClose = () => {
    onClose?.();
  };

  const onImageChange = (e) => {
    const files: any[] = Array.from(e.target.files);

    // 파일이 선택되지 않았거나 빈 파일인 경우 업로드하지 않음
    if (files.length === 0 || files.some(file => file.size === 0)) {
      console.warn("빈 파일이거나 파일이 선택되지 않았습니다.");
      return;
    }

    files.forEach(file => {
      const reader = new FileReader();
      reader.onloadend = function () {
        console.log(reader.result);
      };
      reader.readAsDataURL(file);

      const formData = new FormData();
      formData.append("file", file);

      context.post(`upload/file/mission`, formData, (response) => {
        setFileList((prev) => [...prev, ...response.file]);
      });
    });

    // 파일 입력 초기화
    if (imageRef.current) {
      imageRef.current.value = "";
    }
  };

  const onAddMission = async () => {
      context.post(
        `classroom/${class_id}/mission`,
        {
          title: titleValue,
          content: contentValue,
          fileList,
          classMembers: checkedItems.map((item) => item.memberId),
          startTime: moment(`${startDate} ${startHour}:${startMinute}:00`).format("YYYY-MM-DD HH:mm:ss"),
          endTime: moment(`${endDate} ${endHour}:${endMinute}:00`).format("YYYY-MM-DD HH:mm:ss"),
        },
        (response) => {
          onClose?.();
        }
      );
  };

  const delFile = (idx) => {
    setFileList((preList) =>
      preList.filter((item, index) => {
        return idx != index;
      })
    );
  };

  const fileResize = (size: number) => {
    const dataSize = size;
    let formattedData = "";

    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

    if (dataSize === 0) return "0 Byte";
    const k = Math.floor(Math.log(dataSize) / Math.log(1024));

    formattedData =
      Math.round(100 * (dataSize / Math.pow(1024, k))) / 100 + " " + sizes[k];
    return formattedData;
  };

  return (
    <>
      {openCalendar && (
        <CalendarModal
          // open={openCalendar}
          onClose={() => {
            setOpenCalendar(false);
          }}
          dateValue={settingStartDate ? startDate : endDate}
          setDateValue={settingStartDate ? setStartDate : setEndDate}
        />
      )}
      <Overlay>
        <ModalWrap>
          <div className="classAddModal-head">
            <h4> 과제 추가</h4>
            <img
              className="exit-btn"
              onClick={handleClose}
              src="/assets/image/icon_close_gray.png"
            />
          </div>
          <div className="classAddModal-subTitle">학생 검색</div>
          <div className="studentAddModal-searchContainer">
            <div
              className="class-search  align-item-center box-shadow"
              style={{ flex: 8 }}
            >
              <input
                id="inputBox"
                autoComplete="off" // 자동 완성 기능 비활성화
                className="input-transparent cursor"
                placeholder={"학생 이름을 검색하세요."}
                type={"text"}
                value={studentValue}
                onChange={changeInputValue}
                onKeyUp={handleDropDownKey}
                onClick={clickInputBox}
              />
            </div>
          </div>

          {clickInput ? (
            <DropDownBox id="dropBox">
              {dropDownList.length === 0 ? (
                <DropDownItem>해당하는 학생이 없습니다</DropDownItem>
              ) : (
                <div
                  onClick={() => onClickAllStudent()}
                  className={
                    allSelectedStatus
                      ? "missionAddModal-all-checked"
                      : "missionAddModal-all-unchecked"
                  }
                >
                  모두 선택
                  <img
                    src={
                      allSelectedStatus
                        ? "/assets/image/icon_checked.png"
                        : "/assets/image/icon_unchecked.png"
                    }
                    alt=""
                  />
                </div>
              )}
              {dropDownList.map((dropDownItem, dropDownIndex) => {
                return (
                  <DropDownItem
                    key={dropDownIndex}
                    onMouseOver={() => setDropDownItemIndex(dropDownIndex)}
                    className={
                      dropDownItemIndex === dropDownIndex ? "selected" : ""
                    }
                  >
                    <div
                      className="studentAddModal-dropdown-container"
                      onClick={() => handleCheckboxChange(null, dropDownIndex)} // 부모 영역 클릭 시 체크박스 선택
                    >
                      <div className="studentAddModal-dropdown-profile">
                        <img
                          className="user-img"
                          src={
                            dropDownItem.profile != null &&
                            dropDownItem.profile !== ""
                              ? context.loadImage(dropDownItem.profile)
                              : "/assets/image/chewing_plus_logo.png"
                          }
                          alt=""
                        />
                      </div>
                      <div className="studentAddModal-dropdown-name-mail">
                        {dropDownItem.name}
                      </div>
                      <div className="missionAddModal-dropdown-checkbox">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={checkedItems.includes(dropDownItem)}
                            onChange={(event) =>
                              handleCheckboxChange(event, dropDownIndex)
                            }
                            onClick={(e) => e.stopPropagation()} // 클릭 이벤트가 부모에게 전파되지 않도록 함
                          />
                        </div>
                      </div>
                    </div>
                  </DropDownItem>
                );
              })}
            </DropDownBox>
          ) : null}
          <div
            className={
              checkedItems.length > 0
                ? "missionAdd-student-list-container"
                : "missionAdd-student-list-null"
            }
          >
            {checkedItems.length > 0
              ? checkedItems.map((item, idx) => {
                  return (
                    <div className="missionAdd-student-list">
                      <div className="missionAdd-img-box">
                        <img
                          src={
                            item.profile != null
                              ? context.loadImage(item.profile)
                              : "/assets/image/chewing_plus_logo.png"
                          }
                          alt=""
                        />
                      </div>
                      <div className="missionAdd-name"> {item.name}</div>
                      <div
                        className="missionAdd-cancel"
                        onClick={() => deleteStudent(item.memberId)}
                      >
                        {" "}
                        <img src="/assets/image/icon_close_gray.png" alt="" />
                      </div>
                    </div>
                  );
                })
              : "과제를 받을 학생을 추가해주세요"}
          </div>

          <div className="classAddModal-subTitle">제목</div>
          <input
            className="missionAddModal-content"
            placeholder={strings.enter_title}
            type={"text"}
            value={titleValue}
            onChange={changeTitleValue}
            style={{ height: "auto" }}
          ></input>

          <div className="classAddModal-subTitle">내용</div>
          <textarea
            className="missionAddModal-content"
            placeholder={strings.enter_content}
            value={contentValue}
            onChange={changeContentValue}
            style={{ resize: "none" }}
          ></textarea>

          <input
            type="file"
            accept="image/bmp,image/jpg,image/jpeg,image/png,image/tiff,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/htm,text/html,application/pdf,application/vnd.ms-powerpoint,applicatiapplication/vnd.openxmlformats-officedocument.presentationml.presentation,text/plain,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,video/mp4"
            className="hide"
            onChange={onImageChange}
            ref={imageRef}
          />

          {fileList.map((file, index) => (
            <div>
              {!imgType.includes(file.name.split(".").pop().toLowerCase()) ? (
                <div className="mission-add-file-box">
                  <div className="">
                    <img
                      className="grid-item file-img"
                      src={`/assets/image/icon_file_${file.type}.png`}
                      alt=""
                    />
                  </div>
                  <div className="grid-item file-title">{file.name}</div>
                  <div className="grid-item file-size">
                    {fileResize(file.size)}
                  </div>
                  <div className="grid-item img-box">
                    <img
                      src="/assets/image/icon_close_gray.png"
                      alt=""
                      onClick={() => {
                        delFile(index);
                      }}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          ))}

          <div className="missionAddModal-img-container">
            <div
              className="missionAddModal-imgAdd"
              onClick={() => imageRef.current && imageRef.current.click()}
            >
              <img
                className="classAddModal-no-thumbnail"
                src="/assets/image/class_addImg1.png"
                alt=""
              />
            </div>
            <div className="missionAddModal-img-contents">
              {fileList.map((file, index) => (
                <div className="position-relative" key={index}>
                  {imgType.includes(
                    file.name.split(".").pop().toLowerCase()
                  ) ? (
                    <>
                      <img
                        className="missionAddModal-thumbnail"
                        src={context.loadImage(file.url)}
                        alt={`Thumbnail ${index}`}
                      />
                      <button
                        style={{
                          border: "none",
                          background: "none",
                          position: "absolute",
                          top: -10,
                          right: -15,
                        }}
                        onClick={() => {
                          delFile(index);
                        }}
                      >
                        <img src={"/assets/image/icon_cancel_btn.png"} alt="" />
                      </button>
                    </>
                  ) : null}
                </div>
              ))}
            </div>
          </div>

          <div className="classAddModal-subTitle">기한</div>
          <TimeRowWrapper>
            <DateInput
              type={"text"}
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value);
                if (endDate < e.target.value) {
                  setEndDate(e.target.value);
                }
              }}
              onClick={() => {
                setSettingStartDate(true);
                setOpenCalendar(true);
              }}
            ></DateInput>
            <TimeInput
              id="timeInput"
              type="text"
              value={startHour}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length > 2) return;
                if (value > "23") return;
                if (value === "") {
                  setStartHour(0);
                  return;
                }

                setStartHour(parseInt(value));
              }}
            />
            <span>:</span>
            <TimeInput
              id="timeInput"
              type="text"
              value={startMinute}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length > 2) return;
                if (value > "59") return;
                if (value === "") {
                  setStartMinute(0);
                  return;
                }

                setStartMinute(parseInt(value));
              }}
            />

            <span> ~ </span>
            <DateInput
              type={"text"}
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              onClick={() => {
                setSettingStartDate(false);
                setOpenCalendar(true);
              }}
            ></DateInput>
            <TimeInput
              id="timeInput"
              type="text"
              value={endHour}
              onChange={(e) => {
                const value = e.target.value;

                if (value.length > 2) return;
                if (value > "23") return;
                if (value === "") {
                  setEndHour(0);
                  return;
                }

                setEndHour(parseInt(value));
              }}
            />
            <span>:</span>
            <TimeInput
              id="timeInput"
              type="text"
              value={endMinute}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length > 2) return;
                if (value > "59") return;
                if (value === "") {
                  setEndMinute(0);
                  return;
                }

                setEndMinute(parseInt(value));
              }}
            />
          </TimeRowWrapper>
          <Button
            style={{ backgroundColor: enableConfirm ? "#0F84F4" : "#DFE3E8" }}
            disabled={!enableConfirm}
            onClick={onAddMission}
          >
            추가
          </Button>
        </ModalWrap>
      </Overlay>
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const ModalWrap = styled.div`
  width: 700px;
  height: 650px;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  overflow: auto;
`;

const CloseButton = styled.div`
  float: right;
  width: 40px;
  height: 40px;
  margin: 20px;
  cursor: pointer;
  i {
    color: #5d5d5d;
    font-size: 30px;
  }
`;

const Contents = styled.div`
  margin: 50px 30px;
  h1 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 60px;
  }
  img {
    margin-top: 60px;
    width: 300px;
  }
`;
const Button = styled.button`
  cursor: pointer;
  width: 100%;
  color: #ffffff;
  border-radius: 5px;
  border: 0px;
  padding: 10px;
  margin-top: 20px;
`;

const DropDownBox = styled.ul`
  display: block;
  position: absolute;
  margin: 0 auto;
  padding: 0px 5px;
  width: 94%;
  background-color: white;
  border: 1px solid #dfe1e8;
  border-radius: 8px;
  box-shadow: 0 10px 10px rgb(0, 0, 0, 0.3);
  list-style-type: none;
  z-index: 3;
  max-height: 200px; /* 최대 높이 설정 */
  overflow: auto; /* 스크롤바 표시 설정 */
  border-top: none;
`;

const DropDownItem = styled.li`
  text-align: start;
  padding: 0px 5px;
  margin: 10px 0px;
`;

const TimeRowWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const TimeBox = styled.input`
  background: #ffffff;
  display: flex;
  padding: 10px 15px;
  border: 1px solid #dfe1e8;
  border-radius: 10px;
  height: 40px;
`;

const DateInput = styled(TimeBox)`
  width: 100px;
  text-align: center;
  cursor: pointer;
`;

const TimeInput = styled(TimeBox)`
  width: 60px;
  text-align: center;
`;

export default MissionAddModal;
