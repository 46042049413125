import React from "react";
import {withRouter} from "react-router-dom";
import {inject} from "mobx-react";
import styled from "styled-components";
import { IoChevronForwardOutline } from "react-icons/io5";


interface SignupStep0Props {
    setUserType: (userType: "STUDENT" | "EXPERT") => void;
    history: any;
}

const SignupStep0 = (props: SignupStep0Props) => {

    const { setUserType, history } = props;
    const onNext = () => {
        history.push(`/signup/1`);
    };

    return (
        <RowWholeWrapper>
            <CardWrapper>
                <ImageWrapper userType={"EXPERT"} >
                    <img src="/assets/image/img_expert.png" height={150} style={{marginTop: 20}} />
                </ImageWrapper>
                <span>선생님으로</span>
                <span>활동하고 싶어요</span>
                <ButtonWrapper userType={"EXPERT"} onClick={() => {
                    setUserType("EXPERT");
                    onNext();
                    }}>선생님 회원 <IoChevronForwardOutline/></ButtonWrapper>
            </CardWrapper>
            <CardWrapper>
            <ImageWrapper userType={"STUDENT"}>
                <img src="/assets/image/img_student.png" height={150} style={{marginTop: 20}}/>
            </ImageWrapper>
                <span>학생으로</span>
                <span>활동하고 싶어요</span>
                <ButtonWrapper userType={"STUDENT"} onClick={() => {
                    setUserType("STUDENT");
                    onNext();
                    }}>학생 회원 <IoChevronForwardOutline/></ButtonWrapper>
            </CardWrapper>
        </RowWholeWrapper>
    )
}

export default withRouter(inject('rootStore')(SignupStep0));

interface IUserType {
    userType: "STUDENT" | "EXPERT";
}

const RowWholeWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 800px;
    margin-top: 10px;
`;

const CardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 280px;
    height: 370px;
    margin-top: 10px;
    padding: 30px;
    background-color: white;
    border-radius: 16px;
    font-size: 15px;
    font-weight: bold;
    border: 1px solid #ECF1F8;
`;

const ImageWrapper = styled.div<IUserType>`    
    width: 170px;
    height: 170px;
    border-radius: 50%;
    margin-bottom: 25px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.userType === "EXPERT" ? "#EFF3F6" : "#FFFCF1"};
`;

const ButtonWrapper = styled.button<IUserType>`
    width: 100%;
    height: 50px;
    font: 20px;
    color: ${props => props.userType === "EXPERT" ? "#32B3FB" : "#04C886"};
    border: 1px solid ${props => props.userType === "EXPERT" ? "#32B3FB" : "#04C886"};
    border-radius: 5px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;

    &:hover {
        background-color: ${props => props.userType === "EXPERT" ? "#32B3FB" : "#04C886"};
        color: white;
        transition: 0.3s;
    }
`