import { set } from 'mobx';
import React, { memo, useState } from 'react';
import { MdModeEditOutline, MdCheck } from "react-icons/md";
import styled from 'styled-components';


export default function Specialty() {

    const [edit, setEdit] = useState(false);


    return (
        <div style={{position: "relative", padding: "0px 100px"}}>
            <h1>세특세부항목</h1>
            <div style={{width: 5, height: 60}}/>
            <TopInputWrapper>
                <TopInputTitle>지원예정학과</TopInputTitle>
                <TopInputValue type='text' disabled={!edit}/>
                <div style={{position: "absolute", right: 120}}>
                    <EditButton diameter={38} edit={edit} onClick={() => setEdit(prev => !prev)}/>
                </div>
            </TopInputWrapper>
            <Separtor/>
            <div style={{width: "100%"}}>
                <div style={{display: "flex", flexDirection:"row", width: "100%", gap: "10%", marginBottom: 50}}>
                    <InputBox title='독서리스트' placeholder='학교에서 진행한 독서목록을 모두 작성해주세요.' onConfirm={(value) => {
                        console.log(value);
                    }}/>
                    <InputBox title='동아리 활동' placeholder='학교 동아리 시간에 했던 활동을 모두 작성해주세요.' onConfirm={(value) => {
                        console.log(value);
                    }}/>
                    <InputBox title='선택 과목' placeholder='이번 학기(학년)에 선택한 과목을 모두 작성해주세요.' onConfirm={(value) => {
                        console.log(value);
                    }}/>
                </div>
                <div style={{display: "flex", flexDirection:"row", width: "100%", gap:"10%"}}>
                    <InputBox title='수행평가' placeholder='이번 학기에 진행한 수행평가를 모두 작성해주세요.' onConfirm={(value) => {
                        console.log(value);
                    }}/>
                    <InputBox title='교내행사' placeholder='이번 학기에 참여한 교내 행사를 모두 작성해주세요.' onConfirm={(value) => {
                        console.log(value);
                    }}/>
                    <div style={{width: "30%", height: 270}}>
                        <div style={{display: "flex", flexDirection:"row", width: "100%", height: 70, position:"relative", alignItems: "center"}}>
                            <h2 style={{width: "100%", 
                                height: "100%", 
                                backgroundImage: "linear-gradient(to right, #146DE3 , #235190)", 
                                display: "flex", 
                                alignItems: "center", 
                                paddingLeft: 20, 
                                borderRadius: "10px 10px 0 0", 
                                margin: 0,
                                color: "white"
                                }}>{"컨설턴트 피드백"}</h2>
                        </div>
                        <InputBoxTextArea placeholder={"컨설턴트의 최종 피드백과 생기부 보완 항목을 확인하세요."} disabled={true}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

const Separtor = memo(() => {
    return (
        <div style={{width: "100%", height: 10, margin: "50px 0px", borderRadius: 5, backgroundColor: "white"}}/>
    );
});


const InputBox = (
    props : {
        title: string;
        placeholder: string;
        onConfirm: (value: string) => void;
    }
) => {

    const [value, setValue] = useState('');
    const [edit, setEdit] = useState(false);
    
    return (
        <div style={{width: "30%", height: 270}}>
            <div style={{display: "flex", flexDirection:"row", width: "100%", height: 70, position:"relative", alignItems: "center"}}>
                <h2 style={{width: "100%", height: "100%", backgroundColor: "#D3E9FF", display: "flex", alignItems: "center", paddingLeft: 20, borderRadius: "10px 10px 0 0", margin: 0}}>{props.title}</h2>
                <div style={{position: "absolute", right: 15}}>
                    <EditButton diameter={38} edit={edit} onClick={() => setEdit(prev => !prev)}/>
                </div>
            </div>
            <InputBoxTextArea placeholder={props.placeholder} value={value} disabled={!edit} onChange={e => setValue(e.target.value)}/>
        </div>
    );
}

const EditButton = memo((
    props: {
        diameter: number;
        onClick: () => void;
        edit: boolean;
    }
) => {
    const {diameter, onClick, edit} = props;

    return (
        <div style={{
            width: diameter, 
            height: diameter, 
            borderRadius: diameter/2, 
            backgroundColor: "#F3F3F3", 
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            }} onClick={onClick}>
            {edit ? <MdCheck color='#A4AAB0' size={diameter * 0.6}/> : <MdModeEditOutline color='#A4AAB0' size={diameter * 0.5}/>}
        </div>
    );
});

const TopInputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100px;
    align-items: center;
    border-radius: 10px;
`;

const TopInputTitle = styled.div`
    width: 30%;
    height: 100%;
    background-color: #D3E9FF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border-radius: 10px 0px 0px 10px;
    font-size: 30px;
`;

const TopInputValue = styled.input`
    width: 70%;
    height: 100%;
    border-radius: 0px 10px 10px 0px;
    font-size: 30px;
    text-align: center;
    &:disabled {
        background-color: #FFFFFF;
    }
`;

const InputBoxTextArea = styled.textarea`
    width: 100%;
    height: calc(100% - 70px);
    background-color: #FFFFFF;
    padding: 15px 20px;
    font-size: 20px;
    border-radius: 0 0 10px 10px;
    &::placeholder {
        font-size: 20px;
    }
`;