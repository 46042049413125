import React from "react";

export default function PlannerButton(props) {
    return (
        <button
            className={props.selected == true ? "planner-button-selected":"planner-button"}
            onClick={props.onClick}
            style={{ background: props.selected == true ? props.selectedColor : '' }}>{props.value}</button>
    );
}
