import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

import "swiper/css";

const SlideData = [
    {
        imgSrc: "../assets/image/landing_page/main/sec2-slide1.png",
        altText: "츄잉 활동 사진",
        title: "<span>1. 매년 바뀌는 학생들과 자료</span>에도<br /> 츄잉 LMS 서비스만 있으면 <br />훨씬 빠르고 편리해요",
        school: "서울고등학교",
        teacher: "김츄잉 선생님",
    },
    {
        imgSrc: "/assets/image/landing_page/main/sec2-slide2.png",
        altText: "츄잉 활동 사진",
        title: "<span>2. 매년 바뀌는 학생들과 자료</span>에도<br /> 츄잉 LMS 서비스만 있으면 <br />훨씬 빠르고 편리해요",
        school: "서울고등학교",
        teacher: "김츄잉 선생님",
    },
    {
        imgSrc: "/assets/image/landing_page/main/sec2-slide3.png",
        altText: "츄잉 활동 사진",
        title: "<span>3. 매년 바뀌는 학생들과 자료</span>에도<br /> 츄잉 LMS 서비스만 있으면 <br />훨씬 빠르고 편리해요",
        school: "서울고등학교",
        teacher: "김츄잉 선생님",
    },
    {
        imgSrc: "/assets/image/landing_page/main/sec2-slide4.png",
        altText: "츄잉 활동 사진",
        title: "<span>4. 매년 바뀌는 학생들과 자료</span>에도<br /> 츄잉 LMS 서비스만 있으면 <br />훨씬 빠르고 편리해요",
        school: "서울고등학교",
        teacher: "김츄잉 선생님",
    },
    {
        imgSrc: "/assets/image/landing_page/main/sec2-slide5.png",
        altText: "츄잉 활동 사진",
        title: "<span>5. 매년 바뀌는 학생들과 자료</span>에도<br /> 츄잉 LMS 서비스만 있으면 <br />훨씬 빠르고 편리해요",
        school: "서울고등학교",
        teacher: "김츄잉 선생님",
    },
]

const modules = [Autoplay];

const HomeSec2 = () => {
    return (
        <>
            <section className="wrap main-sec2">
                <div className="main-tit">
                    <h2>
                    츄잉은 오늘도 <br />
                    활발히 움직이고 있어요
                    </h2>
                </div>
                <Swiper
                    slidesPerView={3} // 한번에 보여지는 slide 개수
                    spaceBetween={14} // slide간의 간격
                    loopedSlides={2}
                    loop={true}
                    centeredSlides={true}
                    autoplay={{
                        delay: 3500,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{ // 반응형 구현
                        1200: {
                            slidesPerView: 4.5,
                            spaceBetween: 40,
                        }, // width 값이 1200이 넘을때 실행
                    }}
                    modules={modules}
                    className={'mySwiper sec2-swiper'}
                >
                    {SlideData.map((item, index) => (
                        <SwiperSlide key={index}>
                            <div className="img-box">
                                <img 
                                    src={item.imgSrc}
                                    alt={item.altText} />
                            </div>
                            <div className="txt-box">
                                <h4 dangerouslySetInnerHTML={{__html:item.title}} />
                                <dl>
                                    <dt>{item.school}</dt>
                                    <dd>{item.teacher}</dd>
                                </dl>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </section>
        </>
    );
}

export default HomeSec2;

