// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Pretendard Black.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Pretendard Bold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Pretendard ExtraBold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./Pretendard Light.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./Pretendard Medium.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./Pretendard Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./Pretendard SemiBold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("./Pretendard Thin.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("./Pretendard ExtraLight.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Pretendard Black';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('opentype');
}

@font-face {
  font-family: 'Pretendard Bold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('opentype');
}

@font-face {
  font-family: 'Pretendard ExtraBold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('opentype');
}

@font-face {
  font-family: 'Pretendard Light';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('opentype');
}

@font-face {
  font-family: 'Pretendard Medium';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('opentype');
}

@font-face {
  font-family: 'Pretendard Regular';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('opentype');
}

@font-face {
  font-family: 'Pretendard SemiBold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format('opentype');
}

@font-face {
  font-family: 'Pretendard Thin';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format('opentype');
}

@font-face {
  font-family: 'Pretendard ExtraLight';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format('opentype');
}

/* Add other font weights as needed */




`, "",{"version":3,"sources":["webpack://./src/assets/fonts/fonts.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,+DAAqD;AACvD;;AAEA;EACE,8BAA8B;EAC9B,+DAAoD;AACtD;;AAEA;EACE,mCAAmC;EACnC,+DAAyD;AAC3D;;AAEA;EACE,+BAA+B;EAC/B,+DAAqD;AACvD;;AAEA;EACE,gCAAgC;EAChC,+DAAsD;AACxD;;AAEA;EACE,iCAAiC;EACjC,+DAAuD;AACzD;;AAEA;EACE,kCAAkC;EAClC,+DAAwD;AAC1D;;AAEA;EACE,8BAA8B;EAC9B,+DAAoD;AACtD;;AAEA;EACE,oCAAoC;EACpC,+DAA0D;AAC5D;;AAEA,qCAAqC","sourcesContent":["@font-face {\n  font-family: 'Pretendard Black';\n  src: url('./Pretendard Black.otf') format('opentype');\n}\n\n@font-face {\n  font-family: 'Pretendard Bold';\n  src: url('./Pretendard Bold.otf') format('opentype');\n}\n\n@font-face {\n  font-family: 'Pretendard ExtraBold';\n  src: url('./Pretendard ExtraBold.otf') format('opentype');\n}\n\n@font-face {\n  font-family: 'Pretendard Light';\n  src: url('./Pretendard Light.otf') format('opentype');\n}\n\n@font-face {\n  font-family: 'Pretendard Medium';\n  src: url('./Pretendard Medium.otf') format('opentype');\n}\n\n@font-face {\n  font-family: 'Pretendard Regular';\n  src: url('./Pretendard Regular.otf') format('opentype');\n}\n\n@font-face {\n  font-family: 'Pretendard SemiBold';\n  src: url('./Pretendard SemiBold.otf') format('opentype');\n}\n\n@font-face {\n  font-family: 'Pretendard Thin';\n  src: url('./Pretendard Thin.otf') format('opentype');\n}\n\n@font-face {\n  font-family: 'Pretendard ExtraLight';\n  src: url('./Pretendard ExtraLight.otf') format('opentype');\n}\n\n/* Add other font weights as needed */\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
