import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { inject } from 'mobx-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { NAVER_CALLBACK_URL, NAVER_CLIENT_ID, RESULT_CODE } from '../../config/const';
import { Page_settings } from '../../config/page_settings';
import colors from '../../design/colors';
import fonts from '../../design/typography';
import strings from '../../lang/strings';
import { rootStore } from '../../mobx/store';

// TODO: OAUTH 연결

const Login = () => {
    const navigate = useNavigate();
    const context: any = useContext(Page_settings);
    const idRef = useRef<HTMLInputElement>(null);
    const { naver, Kakao } = window as any;
    const naverRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const userInfoString = localStorage.getItem("user_info");
        const userInfo = JSON.parse(userInfoString);

        if (userInfo && userInfo.hasOwnProperty('id')) {
            navigate("/class");
        };
        
        const naverLogin = new naver.LoginWithNaverId({
            clientId: NAVER_CLIENT_ID,
            callbackUrl: NAVER_CALLBACK_URL,
            isPopup: false,
            loginButton: { color: 'white', type: 1, height: '47' },
            callbackHandle: false,
        });
        naverLogin.init();
        naverLogin.logout();
        naverLogin.getLoginStatus(function (status) {
            if (status) {
                const email = naverLogin.user.getEmail();
                if (email === null || email === undefined) {
                    alert("이메일이 필요합니다. 정보제공을 동의해주세요.");
                    naverLogin.reprompt();
                    return;
                } else {
                    login("NAVER", email, "");
                }
            }
        });

        idRef?.current?.focus();

        if (!Kakao.isInitialized()) {
            Kakao.init(process.env.REACT_APP_KAKAO_KEY);
            Kakao.isInitialized();
        }

    }, []);


    const googleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            try {
                const { access_token } = tokenResponse;

                // Fetch user info from Google People API
                const response = await axios.get('https://www.googleapis.com/oauth2/v1/userinfo', {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                });

                const email = response.data.email;
                login("GOOGLE", email, '');
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        },
        onError: (error) => {
            console.log('Login failed:', error);
        },
    });

    function loginWithKakao() {
        Kakao.Auth.login({
          success: function (authObj) {
            console.log(authObj); //access토큰 값
            Kakao.Auth.setAccessToken(authObj.access_token); //access 토큰 값 저장
            getInfo();
          },
          fail: function (err) {
            console.log(err);
          },
        });
      }
      //4. 엑세스 토큰을 발급받고, 아래 함수를 호출시켜 사용자 정보 받아옴.
      function getInfo() {
        Kakao.API.request({
          url: "/v2/user/me",
          success: function (res) {
            const email = res.kakao_account.email;
            login("KAKAO", email, '');
          },
          fail: function (error) {
            alert("카카오 로그인 실패" + JSON.stringify(error));
          },
        });
      }

    const login = (type, email, pwd) => {
        context.post(
            'auth/login',
            {
                loginType: type,
                email: email,
                password: pwd
            },
            response => {
                if (Number(response.result_code) == RESULT_CODE.ERROR) {
                    onSignUp(type, email);
                    return;
                }
                rootStore.signIn(response, type);
                context.get(
                    'member/info',
                    {},
                    response => {
                        rootStore.setProfile(response);
                        navigate('/class');
                    }
                );
            }
        );
    }

    const onSignUp = (loginType, email) => {
        rootStore.setSignUpInfo({
            loginType: loginType,
            email: email
        });
        navigate('/signup/0');
    }

    return (
        <div style={{ width: "100%", height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: colors.white }}>
            <TitleWrapper>
                <Title>{strings.login.login}</Title>
            </TitleWrapper>
            <LoginContainer>
                <LoginRow backgroundColor={colors.gray100} borderRadius="8px" padding="0 16px" onClick={() => googleLogin()}>
                    <img src="/assets/image/google.png" alt="Google" style={{ width: "24px", height: "24px" }} />
                    <span>{"구글로 시작하기"}</span>
                </LoginRow>
                <LoginRow backgroundColor={"#FAE64C"} borderRadius="8px" padding="0 16px" onClick={() => loginWithKakao()}>
                    <img src="/assets/image/kakao_talk.png" alt="kakao" style={{ width: "24px", height: "24px" }} />
                    <span>{"카카오로 시작하기"}</span>
                </LoginRow>
                <LoginRow backgroundColor={"#04C75B"} borderRadius="8px" padding="0 16px" onClick={(e) => {
                    e.preventDefault();
                    const naverButton = naverRef.current?.children[0] as HTMLElement;
                    naverButton?.click();
                }}>
                    <img src="/assets/image/naver.png" alt="Naver" style={{ width: "24px", height: "24px" }} />
                    <span style={{color: colors.white}}>{"네이버로 시작하기"}</span>
                    <div id='naverIdLogin' ref={naverRef} style={{display: "none"}}/>
                </LoginRow>
                <LoginRow backgroundColor={colors.white} borderRadius="8px" padding="0 16px" style={{ border: `1px solid ${colors.gray400}` }} onClick={() => navigate('/login/email')}>
                    <img src="/assets/image/person.png" alt="Email" style={{ width: "20px", height: "20px" }} />
                    <span>{"이메일로 시작하기"}</span>
                </LoginRow>
            </LoginContainer>
            <div style={{ cursor: 'pointer' }} onClick={() => onSignUp("EMAIL", "")}>
                <span style={{...fonts.detail2Regular, color: colors.gray900, textDecoration: 'underline'}}>{strings.are_you_new}</span>
            </div>
        </div>
    );
}

export default inject('rootStore')(Login);

const TitleWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 80px;
`;

const Title = styled.span`
    font-size: ${fonts.heading1Bold.fontSize};
    font-family: ${fonts.heading1Bold.fontFamily};
    line-height: ${fonts.heading1Bold.lineHeight};
    color: ${colors.black};
    margin-bottom: 44px;
`;

const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    width: 384px;
    margin-bottom: 64px;
`;

const LoginRow = styled.div<{
    backgroundColor: string;
    borderRadius: string;
    padding: string;
}>`
    width: 100%;
    height: 50px;
    background-color: ${props => props.backgroundColor};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    cursor: pointer;
    font-size: ${fonts.body2Medium.fontSize};
    font-family: ${fonts.body2Medium.fontFamily};
    line-height: ${fonts.body2Medium.lineHeight};
`;
