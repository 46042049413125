import strings from "../../lang/strings";
import React, {useContext, useState} from "react";
import {check_validation_email} from "../../helper/common";
import {withRouter} from "react-router-dom";
import {inject} from "mobx-react";
import {Page_settings} from "../../config/page_settings";

const SignupStep1 = (props) => {
    const [showPassword, setShowPassword] = useState(false);
    const [invalidPwdConfirm, setInvalidPwdConfirm] = useState(false);
    const [invalidId, setInvalidId] = useState(false);
    const [invalidPwd, setInvalidPwd] = useState(false);
    const context = useContext(Page_settings);

    const onMouseDown = () => {
        setShowPassword(true);
    }

    const onMouseUp = () => {
        setShowPassword(false);
    }

    const {
        id,
        idRef,
        type,
        onChangeId,
        onChangePwdConfirm,
        pwd,
        onChangePwd,
        pwdConfirm,
        onAgreeInfo,
        agreeInfo,
        onAgreeAdv,
        agreeAdv,
    } = props;

    const onNext = () => {
        if (id == "" || (type == "EMAIL" && (pwd == "" || pwdConfirm == "")) || !agreeInfo) {
            return
        } else if (!check_validation_email(id)) {
            setInvalidId(true);
            return
        }
        if (type == "EMAIL") {
            if (pwd.length < 8 || pwd.length > 20) {
                setInvalidPwd(true);
                return
            } else if (pwd != pwdConfirm) {
                setInvalidPwdConfirm(true)
                return
            }
        }
        context.post(
            'auth/check',
            {
                email: id,
            },
            response => {
                if (response.email) {
                    props.history.push('/signup/2');
                } else {
                    setInvalidId(true);
                }
            }
        );

    }
    return (
        <div style={{width: "100%"}}>
            <div className="login-item">
                <span>{strings.email}</span>
                <span style={{fontSize: 13, fontWeight: "lighter", marginLeft: 10}}>{"("}</span>
                <span style={{color:"red"}}>{" * "}</span>
                <span style={{fontSize: 13, fontWeight: "lighter"}}>{"잘못된 이메일 입력시 일부 기능이 제한될 수 있습니다.)"}</span>
            </div>
            <input
                className={("login-input ") + ((invalidId) ? "signup-error" : "m-b-40")}
                type="text"
                ref={idRef}
                autoComplete={'off'}
                placeholder={"chewing@chewing.com"}
                value={id}
                disabled={type != 'EMAIL'}
                onChange={e => {
                    onChangeId(e.target.value);
                    setInvalidId(false)
                }}
                required />
            {
                invalidId &&
                <div className="sign-error-text m-b-40">{strings.invalid_email}</div>
            }
            {
                type == 'EMAIL' &&
                <div>
                    <div className="login-item">{strings.password}</div>
                    <div className="position-relative">
                        <input type={showPassword ? "text" : "password"}
                            name="password"
                            className={("login-input ") + (invalidPwd ? "signup-error" : "m-b-40")}
                            placeholder={strings.password_placeholder}
                            value={pwd}
                            onChange={e => {
                                onChangePwd(e.target.value);
                                setInvalidPwd(false)
                            }}
                            required />
                        {
                            invalidPwd &&
                            <div className="sign-error-text m-b-40">{strings.invalid_password}</div>
                        }

                        {
                            pwd != "" &&
                            <button className="eye" onMouseDown={onMouseDown} onMouseUp={onMouseUp}
                                onTouchStart={onMouseDown} onTouchEnd={onMouseUp}>
                                <img src={"/assets/image/icon_eye.png"} alt="" />
                            </button>
                        }
                    </div>
                    <div className="login-item">{strings.confirm_password}</div>
                    <input type="password"
                        className={("login-input ") + (invalidPwdConfirm ? "signup-error" : "m-b-25")}
                        placeholder={strings.re_enter_password}
                        value={pwdConfirm}
                        onChange={e => {
                            onChangePwdConfirm(e.target.value);
                            setInvalidPwdConfirm(false)
                        }}
                        required />
                    {
                        invalidPwdConfirm &&
                        <div className="sign-error-text m-b-25">{strings.wrong_password}</div>
                    }
                </div>
            }
            <div className="signup-agree m-b-40">
                <div className="d-flex m-b-25">
                    <button className="p-l-0" onClick={() => onAgreeInfo()}>
                        <img
                            src={agreeInfo ? "/assets/image/icon_rect_check.png" : "/assets/image/icon_rect_check_normal.png"}
                            alt="" />
                        <div className="m-r-0">{strings.to_collection_use_info}</div>
                        <div className="sign-term">{strings.i_agree}</div>
                    </button>
                    <img className="ellipse" src={"/assets/image/icon_ellipse.png"} alt="" />
                </div>
                <button className="m-r-auto p-l-0" onClick={() => onAgreeAdv()}>
                    <img
                        src={agreeAdv ? "/assets/image/icon_rect_check.png" : "/assets/image/icon_rect_check_normal.png"}
                        alt="" />
                    <div className="m-r-0">{strings.to_use_marketing_receive_adv}</div>
                    <div className="sign-term">{strings.i_agree_optional}</div>
                </button>
            </div>
            <button onClick={() => onNext()}
                className={"login-button m-b-40 " + ((id != "" && (type != "EMAIL" || (type == "EMAIL" && pwd != "" && pwdConfirm != "")) && agreeInfo) ? "login-button-enable" : "")}>{strings.next}</button>
        </div>
    );
}

export default withRouter(inject('rootStore')(SignupStep1));
