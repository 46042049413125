import React, { useContext, useEffect, useRef, useState } from 'react';
import { withRouter } from "react-router-dom";
import strings from "../../../lang/strings";
import styled from "styled-components";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import DateTime from "react-datetime";
import 'react-datetime/css/react-datetime.css';
import { ReactNotifications, Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import moment from "moment";
import { Page_settings } from "../../../config/page_settings";
import SweetAlert from "react-bootstrap-sweetalert";
import AddUnitModal from './add_unit_modal';
import CreateQuizModal from './create_quiz_modal';
import PerfectScrollbar from "react-perfect-scrollbar";
import { getAgeFromBirthday, getTimeFromSecond, getMinuteFromSecond } from '../../../helper/common';

const CreateQuiz = (props) => {
    const context = useContext(Page_settings);
    const classId = parseInt(props.match.params.class);
    const quizId = parseInt(props.match.params.quiz_id);
    const [quizInfo, setQuizInfo] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [title, setTitle] = useState("");
    const [note, setNote] = useState("");
    const [subject, setSubject] = useState("");
    const courseList = ["중등", "고등"]
    const [courseItem, setCourseItem] = useState('');
    const [courseDropdownOpen, setCourseDropdownOpen] = useState(false);
    const [semesterDropdownOpen, setSemesterDropdownOpen] = useState(false);
    const subjectList = ["국어", "수학", "영어","과탐", "사회"]
    const [subjectDropdownOpen, setSubjectDropdownOpen] = useState(false);
    const [itemCountList, setItemCountList] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [passCountList, setPassCountList] = useState([]);
    const [passCountItem, setPassCountItem] = useState(0);
    const [passCountDropdownOpen, setPassCountDropdownOpen] = useState(false);
    const [count, setCount] = useState(null);
    const [fileUrl, setFileUrl] = useState("");
    const [answerList, setAnswerList] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [studentDropdownOpen, setStudentDropdownOpen] = useState(false);
    const [startDate, setStartDate] = useState(moment(new Date()).format('YYYY.MM.DD'));
    const [startTime, setStartTime] = useState(new Date());
    const [endTime, setEndTime] = useState(new Date());
    const [timeList, setTimeList] = useState([10, 20, 30, 40, 50, 60]);
    const [limitTime, setLimitTime] = useState(0);
    const [timeDropdownOpen, setTimeDropdownOpen] = useState(false);
    const [classUsers, setClassUsers] = useState([]);
    const [dropDownList, setDropDownList] = useState([])
    const [users, setUsers] = useState([]);
    const [titleError, setTitleError] = useState(false);
    const [subjectError, setSubjectError] = useState(false);
    const [saveAlert, setSaveAlert] = useState(false);
    const [createAlert, setCreateAlert] = useState(false);
    const [addUnitModal, setAddUnitModal] = useState(false);
    const minYesterday = DateTime.moment().subtract(1, 'day');
    const maxDate = DateTime.moment().add(1, 'month');
    const [quizList, setQuizList] = useState([]);
    const [createQuizModal,setCreateQuizModal] = useState(false);
    const [allCheckStatus,setAllCheckStatus] = useState(false);
    const [userList, setUserList] = useState([]);
    const [unitData, setUnitData] = useState('');
    const [createDone,setCreateDone] = useState(false);

    useEffect(() => {
        let list = [];
        let pass_list = [];
        for (let idx = 0; idx <= 50; idx += 5) {
            list.push('' + idx);
        }
        setItemCountList(list);

        for (let idx = 0; idx <= list.length; idx += 1) {
            pass_list.push('' + idx);
        }
        setPassCountList(pass_list);

        if (quizId != 0) {
            getQuizInfo();
        }
        getClassUsers();
    }, []);

    useEffect(() => {
        if (keyword == '') {
            // setDropDownList([]);
            setDropDownList(classUsers);
        } else {
            const choosenList = classUsers.filter((userItem) =>
                userItem.name.includes(keyword)
            );
            const newList = choosenList.map((item, idx) => {
                const updatedItem = {
                    ...item,
                    selected: false,
                };

                return updatedItem;
            });
            setDropDownList(newList)
        }
    }, [keyword])

    const getQuizInfo = () => {
        context.post(
            'quiz/info',
            {
                id: quizId
            },
            response => {
                const quizInfo = response.info;
                setTitle(quizInfo.title);
                setSubject(quizInfo.subject);
                setCount(quizInfo.count);
                setStartDate(moment(quizInfo.date));
                setStartTime(moment(quizInfo.start_time).format('hh:mm'));
                setEndTime(moment(quizInfo.end_time).format('hh:mm'));
                setLimitTime(quizInfo.time);
                const answerList = JSON.parse(quizInfo.answer_data);
                setAnswerList(answerList);
            }
        );
    }

    const getClassUsers = () => {
        context.get(
            'class/getClassMemberList',
            {class_id:classId},
            response => {
                console.log("r",response)
                
                setUserList(response)
            }
        );
    }

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const toggleTimeDropdown = () => {
        setTimeDropdownOpen(!timeDropdownOpen);
    };

    const toggleStudentDropdown = () => {
        setStudentDropdownOpen(!studentDropdownOpen);
    };

    const toggleGradeDropdown = () => {
        setCourseDropdownOpen(!courseDropdownOpen);
    };

    const toggleSemesterDropdown = () => {
        setSemesterDropdownOpen(!semesterDropdownOpen);
    };

    const toggleSubjectDropdown = () => {
        setSubjectDropdownOpen(!subjectDropdownOpen);
    };

    const togglePassCountDropdown = () => {
        setPassCountDropdownOpen(!passCountDropdownOpen);
    };

    const minDateRange = (current) => {
        return current.isAfter(minYesterday) && current.isBefore(maxDate);
    }

    const onChangeItems = (category, item) => {
        
        if (category === "grade") {
            setCourseItem(item)
        }
        if (category === "subject") {
            setSubject(item)
        }
        if (category === "count") {
            setCount(item);
        }
        if (category === "pass_count") {
            const parsedItem = parseInt(item, 10);
            if (isNaN(parsedItem)) {
                setPassCountItem(null);
            } else if (count < parsedItem) {
                setPassCountItem(count);
            } else {
                setPassCountItem(parsedItem);
            }

        }

    }

    const onTempSave = () => {
        let selectedDate = moment(startDate).format("YYYY-MM-DD");
        let selectedStartTime = moment(startTime).format("HH:mm");
        let selectedEndTime = moment(endTime).format("HH:mm");
        let answer = JSON.stringify(answerList);


        context.post(
            'quiz/add',
            {
                id: quizId,
                class_id: classId,
                title: title,
                subject: subject,
                count: count,
                file: fileUrl,
                date: selectedDate,
                start_time: selectedStartTime,
                end_time: selectedEndTime,
                time: limitTime,
                testers: '',
                answer_data: answer,
                status: 0
            },
            response => {
                props.history.push('/class/quiz/' + classId);
            }
        );
    }

    const checkCreateAvailable = () => {

        if (courseItem === ""|| subject === "" || !count > 0 || title === "" || unitData === '') {
            return false;
        }else{
            return true;
        }
    }

    const checkSubmitAvailable = () => {
        let selectedDate = moment(startDate).format("YYYY-MM-DD");

        if (moment(new Date(selectedDate)).isBefore(moment(new Date()), 'day')) {
            addNotification('warning', strings.warning, strings.select_period)
            return;
        }

        if (courseItem === ""|| subject === "" || !selectedUsers.length > 0 || !count > 0 || !answerList.length > 0  || !count > 0 || title === "" || unitData === '' || passCountItem > 0 ) {
            return false;
        }else{
            return true;
        }
    }


    const onCreate = () => {

        let selectedDate = moment(startDate).format("YYYY-MM-DD");
        let selectedStartTime = moment(startTime).format("HH:mm");
        let selectedEndTime = moment(endTime).format("HH:mm");
        let answer = JSON.stringify(answerList);

        let testers = [];
        selectedUsers.map((item, index) => {
            testers.push(item.member);
        });
        
        context.post(
            'quiz/add',
            {
                id: quizId,
                class_id: classId,
                title: title,
                subject: subject,
                count: count,
                pass_count: passCountItem,
                date: selectedDate,
                start_time: selectedStartTime,
                end_time: selectedEndTime,
                time: limitTime,
                testers: testers.join(','),
                answer_data: answer,
                status: 1
            },
            response => {
                props.history.push('/class/quiz/' + classId);
            }
        );
    }

    const handleDateChange = (date) => {
        const newDate = moment(date).format('YYYY.MM.DD');
        setStartDate(newDate);
    }

    const handleTimeChange = (time, type) => {
        if (type == 1) {
            setStartTime(time);
        } else {
            setEndTime(time);
        }
    }

    const addNotification = (notificationType, notificationTitle, notificationMessage) => {
        Store.addNotification({
            title: notificationTitle,
            message: notificationMessage,
            type: notificationType,
            insert: 'top',
            container: 'top-center',
            dismiss: {
                duration: 1000,
            }
        });
    }

    //수동상태 퀴즈 추가
    const addQuiz = () => {
        setQuizList([...quizList, { quiz: '' }]);

        
    };

    const handleNoteChange = (index, value) => {
        const newQuizList = [...quizList];
        newQuizList[index].quiz = value;
        setQuizList(newQuizList);
    };

    const getAnswerList = (answerList) => {
        setAnswerList(answerList);
        setCreateDone(true)
    }

    const getUnitData = (unitData) => {
        setUnitData(unitData);
    }
    
    const EnableClickBtn = () => {
        return (courseItem !== '' && subject !== '')
      };

    
  // 학생을 배열에 추가하거나 제거하는 함수
    const addStudent = (index) => {
    const user = userList[index];
    setSelectedUsers(prevSelectedUsers => {
        // 중복 체크: 이미 배열에 있는 사용자인지 확인
        if (prevSelectedUsers.some(selectedUser => selectedUser.member === user.member)) {
            // 배열에서 해당 사용자 제거
            return prevSelectedUsers.filter(selectedUser => selectedUser.member !== user.member);
        } else {
            // 배열에 사용자 추가
            return [...prevSelectedUsers, user];
        }
    });
    }

      // 전체 선택 함수
      const selectAllStudents = () => {
        setSelectedUsers(prevSelectedUsers => {
            if (prevSelectedUsers.length === userList.length) {
                // 이미 모두 선택된 경우 선택 해제
                return [];
            } else {
                // 모두 선택
                return userList;
            }
        });
    }

    // 퀴즈 생성 함수
    const createQuiz = () => {
        context.post(
            'quiz/create_quiz',
            {
                course:courseItem,
                subject,
                unitData,
                count,
                note
            },
            response => {
                try {
                    const parsedResponse = typeof response === 'string' ? JSON.parse(response) : response;
                    setAnswerList(parsedResponse);
                    setCreateQuizModal(true);
                } catch (error) {
                    console.error("Error parsing response:", error);
                    alert('다시 시도해주세요')
                }
                
            }
        );

        
    }

    
    

    return (
        <div style={{ width: '100%', height: 'calc(100vh - 90px)' }}>
            <div className="main-content create-quiz" style={{ width: '100%', height: '100%', padding: '18px 28px 0', borderRadius: 12, background: '#fff', display: 'flex', flexDirection: 'column' }}>
                <ReactNotifications />
                <div className="f-s-25 f-w-600 m-b-10 d-flex flex-row align-items-center">
                    {/* <div className='head-flex'>
                        <div className={mode === 0 ? 'selected' : ''} onClick={() => setMode(0)}>자동</div>
                        <div className={mode === 1 ? 'selected' : ''} onClick={() => setMode(1)}>수동</div>
                    </div> */}
                    <div>
                        자동 퀴즈 생성하기
                    </div>
                    {
    createDone ? (
        <button
            className={checkSubmitAvailable() ? "quiz-create-button" : 'quiz-no-create-button'}
            style={{ marginLeft: 'auto' }}
            disabled={!checkSubmitAvailable()}
            onClick={() => setCreateAlert(true)}
        >
            {'출제하기'}
        </button>
    ) : (
        <button
            className={checkCreateAvailable() ? "quiz-create-button" : 'quiz-no-create-button'}
            style={{ marginLeft: 'auto' }}
            disabled={!checkCreateAvailable()}
            onClick={()=>createQuiz()}
        >
            {'생성하기'}
        </button>
    )
}
                </div>

                <div className='d-flex flex-row flex-1' style={{ overflow: 'hidden' }}>
                    <div className='info-wrap' style={{ flex: 1, overflowY: 'auto', position: 'relative' }}>
                        <div className={"study-room-input"}>
                            <div className="display-flex-important align-top f-w-600">
                                <div>{'퀴즈 제목'}</div>
                            </div>

                            <input placeholder={'제목을 입력해주세요'} type={'text'}
                                value={title} onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>
                            <>
                                <div style={{ display: 'flex', gap: '10px' }}>
                                    <div>
                                        <div className="display-flex-important study-room-input align-item-center f-w-600">
                                            <div>{'학년'}</div>
                                        </div>
                                        <div className="display-flex-important m-t-10">
                                            <Dropdown isOpen={courseDropdownOpen} toggle={() => toggleGradeDropdown()}
                                                className="dropdown rank-dropdown quiz-dropdown" style={{ width: '160px' }} tag="li">
                                                <DropdownToggle tag="a"
                                                    className="display-flex-important align-item-center profile-dropdown"
                                                    style={{ background: "white", margin: 0, padding: '0px 15px', height: '100%' }}>
                                                    <div className="flex-grow-1"
                                                        style={{ color: "#3f3f3f", lineHeight: '13px' }}>{courseItem == '' ? '선택' : courseItem}</div>
                                                    <img src={"/assets/image/icon_arrow_down_black.png"} alt="" />
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu dropdown-menu-right profile-dropdown-menu" tag="ul">
                                                    {
                                                        courseList.map((item, idx) =>
                                                            <DropdownItem key={idx}
                                                                className="dropdown-header display-flex-important justify-content-center f-s-15"
                                                                onClick={() => {
                                                                    onChangeItems("grade", item);
                                                                }}>
                                                                {item == 0 ? '선택' : item}
                                                            </DropdownItem>
                                                        )
                                                    }
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="display-flex-important study-room-input align-item-center f-w-600">
                                            <div>{'과목'}</div>
                                        </div>
                                        <div className="display-flex-important m-t-10">
                                            <Dropdown isOpen={subjectDropdownOpen} toggle={() => toggleSubjectDropdown()}
                                                className="dropdown rank-dropdown quiz-dropdown" tag="li">
                                                <DropdownToggle tag="a"
                                                    className="display-flex-important align-item-center profile-dropdown"
                                                    style={{ background: "white", margin: 0, padding: '0px 15px', height: '100%' }}>
                                                    <div className="flex-grow-1"
                                                        style={{ color: "#3f3f3f", lineHeight: '13px' }}>{subject == '' ? '선택' : subject}</div>
                                                    <img src={"/assets/image/icon_arrow_down_black.png"} alt="" />
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu dropdown-menu-right profile-dropdown-menu" tag="ul">
                                                    {
                                                        subjectList.map((item, idx) =>
                                                            <DropdownItem key={idx}
                                                                className="dropdown-header display-flex-important justify-content-center f-s-15"
                                                                onClick={() => {
                                                                    onChangeItems("subject", item);
                                                                }}>
                                                                {item === 0 ? '선택' : item}
                                                            </DropdownItem>
                                                        )
                                                    }
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    <div className="display-flex-important study-room-input align-item-center f-w-600">
                                        <div>{'단원 선택'}</div>
                                    </div>
                                    <button className="gray-btn" disabled={!EnableClickBtn()} onClick={() => setAddUnitModal(true)}>
                                        {unitData === ''?
                                        <>
                                        {'+ 추가하기'}
                                        </>
                                        :unitData.classification}
                                        
                                    </button>
                                </div>
                                </div>
                                <div style={{ display: 'flex', gap: '10px' }}>
                                    <div>
                                        <div className={"study-room-input"}>
                                            <div className="display-flex-important align-top f-w-600">
                                                <div>{'문항수'}</div>
                                            </div>

                                            <input placeholder={'0'} type={'text'}
                                                value={count} onChange={(e) => onChangeItems("count", e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={"study-room-input"}>
                                            <div className="display-flex-important align-top f-w-600">
                                                <div>{'합격 문항수'}</div>
                                            </div>

                                            <input placeholder={'0'} type={'text'}
                                                value={passCountItem} onChange={(e) => onChangeItems("pass_count", e.target.value)}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="display-flex-important study-room-input align-item-center f-w-600">
                                    <div>{'시험 기한'}</div>
                                </div>
                                <div className="display-flex-important m-t-10">
                                    <div className="datepicker-custom-container" style={{ width: 150 }}>
                                        <DateTime value={startDate} onChange={(date) => handleDateChange(date)} isValidDate={minDateRange}
                                            timeFormat={false} className="create-room-datepicker" />
                                    </div>
                                    <div className="datepicker-custom-container timepicker-container">
                                        <DateTime value={startTime} onChange={(time) => handleTimeChange(time, 1)} dateFormat={false} timeFormat={"HH:mm"} className="create-room-datepicker" />
                                    </div>
                                    <div className="display-flex-important align-item-center m-r-10">~</div>
                                    <div className="datepicker-custom-container timepicker-container" style={{ marginRight: 0 }}>
                                        <DateTime value={endTime} onChange={(time) => handleTimeChange(time, 2)}
                                            dateFormat={false} timeFormat={"HH:mm"} className="create-room-datepicker" />
                                    </div>
                                </div>
                                <div className={"study-room-input"}>
                                    <div className="display-flex-important align-top f-w-600">
                                        <div>{'비고'}<span className='quiz-note'> *선택사항</span></div>
                                    </div>

                                    <textarea placeholder={'더 상세한 퀴즈를 생성하고 싶으면 자세한 상황 부여는 여기 적어주세요.'} type={'text'}
                                        value={note} onChange={(e) => setNote(e.target.value)}
                                    />
                                </div>
                            </>
                            <>
                                <PerfectScrollbar style={{ height: '55%' }}>
                                    {quizList.length > 0 && quizList.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <div className="study-room-input">
                                                <div className="display-flex-important align-top f-w-600">
                                                    <div>{index + 1}번 퀴즈</div>
                                                </div>
                                                <textarea
                                                    placeholder="출제할 퀴즈를 적어주세요."
                                                    type="text"
                                                    value={item.quiz}
                                                    onChange={(e) => handleNoteChange(index, e.target.value)}
                                                />
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </PerfectScrollbar>
                                <button className={"quiz-create-button"} style={{ marginLeft: 'auto' }} onClick={addQuiz}>
                                    {'+ 퀴즈 추가'}
                                </button>
                            </>
                        
                    </div>

                    <div style={{ width: 1, background: '#DFE1E8', margin: '18px 24px 24px' }} />
                    <div className='d-flex flex-column col-md-4'>
                        <div className='students-list' style={{ maxWidth: 'unset' }}>
                            <div className='list-header'>
                                <div style={{display:'flex',gap:'10px', alignItems:'end'}}>
                                <div className='text'>{'학생'}</div>
                                <div className='participates' style={{marginRight:'10px'}}>
                                    <div className='active'>
                                        {selectedUsers.length + '명 / '}
                                    </div>
                                    <div className='normal'>
                                        {userList.length + '명'}
                                    </div>
                                </div>
                                </div>
                                <div style={{cursor:'pointer',color:'#505050',fontSize:'13px'}} onClick={()=> selectAllStudents()}>전체선택</div>
                            </div>
                            <div className='list-content'>
                                {
                                    userList.map((student, index) => {
                                        const isSelected = selectedUsers.some(selected => selected.member === student.member); // 학생이 selectedUsers에 있는지 확인
                                        return (
                                            <div
                                                key={index}
                                                className={'student-item' + (isSelected ? ' selected' : '')}
                                                onClick={() => { addStudent(index) }}
                                            >
                                                <img
                                                    className='profile'
                                                    src={student.profile != null ? context.loadImage(student.profile) : "/assets/image/chewing_plus_logo.png"}
                                                />
                                                <div className='d-flex flex-column' style={{ marginLeft: 12 }}>
                                                    <div className='nickname'>{student.name}</div>
                                                    <div className='info'>
                                                        {student.school + ' • ' + (getAgeFromBirthday(student.birthday) >= 19 ? strings.g3 : (getAgeFromBirthday(student.birthday) === 18 ? strings.g2 : strings.g1))}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {(saveAlert &&
                <SweetAlert showCancel
                    confirmBtnText={strings.save}
                    confirmBtnBsStyle="primary"
                    cancelBtnText={strings.cancel}
                    cancelBtnBsStyle="default"
                    title={"임시 저장"}
                    customClass="quiz-save-alert"
                    onConfirm={() => onTempSave()}
                    onCancel={() => setSaveAlert(false)}
                    confirmBtnCssClass="alert-confirm"
                    cancelBtnCssClass="alert-cancel"
                >
                    <div>
                        {'임시 저장을 하시겠습니까?'}
                        <br />
                        {'학생에겐 보이지 않습니다.'}
                    </div>
                </SweetAlert>
            )}
            {(addUnitModal &&
                <AddUnitModal
                    onClose={() => {
                        setAddUnitModal(false);
                    }}
                    getUnitData={getUnitData} // 단원 선택 데이터 가져오기
                    course={courseItem}
                    subject={subject}
                    
                />

            )}

{(createQuizModal &&
                <CreateQuizModal
                    onClose={() => {
                        setCreateQuizModal(false);
                    }}
                    getAnswerList={getAnswerList}
                    title={title}
                    count={count}
                    answerList={answerList}
                    unitData={unitData}
                    course={courseItem}
                    subject={subject}
                />

            )}


{(createAlert &&
                <SweetAlert showCancel
                    confirmBtnText={'출제'}
                    confirmBtnBsStyle="primary"
                    cancelBtnText={strings.cancel}
                    cancelBtnBsStyle="default"
                    title={"퀴즈 출제"}
                    customClass="exam-save-alert"
                    onConfirm={() => onCreate()}
                    onCancel={() => setCreateAlert(false)}
                    confirmBtnCssClass="alert-confirm"
                    cancelBtnCssClass="alert-cancel"
                >
                    <div>
                        {'퀴즈를 출제 하시겠습니까?'}
                        <br />
                        {'출제 하시면 퀴즈방에 바로 업로드 됩니다.'}
                    </div>
                </SweetAlert>
            )}
        </div>

        

        
    );

}


const DropDownBox = styled.ul`
  display: block;
  position: absolute;
  margin: 0 auto;
  padding: 14px 13px 0;
  width: 100%;
  border-radius: 7px;
  border: 1px solid #DFE1E8;
  background: #FFF;
  box-shadow: 0px 10px 30px 0px rgba(134, 134, 134, 0.25);
  list-style-type: none;
  z-index: 3;
  max-height: 200px; /* 최대 높이 설정 */
  overflow: auto; /* 스크롤바 표시 설정 */
`

const DropDownItem = styled.li`
  text-align: start;
  padding: 0px 10px;
  margin: 10px 0px;
  &.selected {
    background-color: #EFF3F9;
    border: 1px solid #EFF3F9;
    border-radius: 8px;
    
  }
`

export default withRouter(CreateQuiz);
