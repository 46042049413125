import React, {useEffect, useState} from 'react';import DropdownProfile from './dropdown/profile.jsx';
import {Page_settings} from '../../config/page_settings.js';
import DropdownNotification from "./dropdown/notification.jsx";

import styled from "styled-components";
import { PiMicrophoneFill, PiMicrophoneSlashFill, PiDeviceRotateFill } from "react-icons/pi";
import { MdVideocam, MdVideocamOff } from "react-icons/md";
import { FaPlay } from "react-icons/fa";
import { IoIosPause } from "react-icons/io";
import { LuMoveDiagonal } from "react-icons/lu";


interface IHeaderBlackProps {
    roomTitle: string;
    owner: string;
    isUsingSetting: boolean;
    isStudying?: boolean;
    studyTime?: string;
    toggleIsStudying?: () => void;
    onHide?: () => void;
    isUsingMic?: boolean;
    toggleUsingMic?: () => void;
    isUsingVideo?: boolean;
    toggleUsingVideo?: () => void;
    onMirror?: () => void;
}

const HeaderBlack = (props: IHeaderBlackProps) => {
    let {
        roomTitle, 
        owner, 
        isUsingSetting,
        isStudying, 
        studyTime, 
        toggleIsStudying, 
        onHide,
        isUsingMic,
        toggleUsingMic,
        isUsingVideo,
        toggleUsingVideo,
        onMirror
        } = props;

    return (
        <Page_settings.Consumer>
            {({toggleMobileSidebar, pageSidebar}: any) => (
                <div id="header" className="header navbar-default header-black" style={{display:'flex', justifyContent: "space-between", paddingRight: 10}}>
                    <div className="navbar-header">
                        <div className="navbar-brand">
                            <img className="logo" src={'/assets/image/logo_chewing_plus_white.png'} alt=""/>
                        </div>
                        <div style={{display:"flex", flexDirection: "column"}}>
                            <span style={{color: "white", fontSize: 16, fontWeight: "bolder"}}>{roomTitle}</span>
                            <span style={{color: "white", fontSize: 12 }}>{owner}</span>
                        </div>
                    </div>
                    { isUsingSetting && <ButtonWrapper>
                        <button onClick={toggleUsingVideo}>
                            <ButtonImageWrapper>
                                {isUsingVideo ? <MdVideocam color='white' size={20}/> : <MdVideocamOff color='red' size={20}/>}
                            </ButtonImageWrapper>
                        </button>
                        <button onClick={toggleUsingMic}>
                            <ButtonImageWrapper>
                                {isUsingMic ? <PiMicrophoneFill color='white' size={20}/> : <PiMicrophoneSlashFill color='red' size={20}/>}
                            </ButtonImageWrapper>
                        </button>
                        <button onClick={onMirror}>
                            <ButtonImageWrapper>
                                <PiDeviceRotateFill color='white' size={20}/>
                            </ButtonImageWrapper>
                        </button>
                    </ButtonWrapper> }
                    <div style={{display: 'flex', flexDirection: "row"}}>
                        { studyTime &&
                        <StudyButton isPlaying={isStudying} onClick={toggleIsStudying}>
                            {isStudying ? <IoIosPause size={14}/> : <FaPlay size={10}/>}
                            <span style={{marginLeft: 10}}>{studyTime}</span>
                        </StudyButton>}
                        <AlarmAndProfileWrapper>
                            <DropdownNotification theme={"black"}/>
                            <DropdownProfile theme={"black"}/>
                        </AlarmAndProfileWrapper>
                        <ButtonImageWrapper onClick={onHide}>
                            <LuMoveDiagonal color='white' size={20}/>
                        </ButtonImageWrapper>
                    </div>
                </div>
            )}
        </Page_settings.Consumer>
    )
}

export default HeaderBlack;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 200px;
`;

const ButtonImageWrapper = styled.button`
    width: 38px;
    height: 38px;
    border-radius: 5px;
    background-color: #1F1F1F;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
`;

interface IStudyButtonProps {
    isPlaying: boolean;
}

const StudyButton = styled.button<IStudyButtonProps>`
    width: 102px;
    height: 40px;
    background-color: ${props => props.isPlaying ? "#002140" : "#0F84F4"};
    border-radius: 5px;
    border-color: #0F84F4;
    color: white;
    border-width: 1.5px;
    border-style: solid;
`;

const AlarmAndProfileWrapper = styled.button`
    background-color: #1F1F1F;
    display: flex; 
    flex-direction: row;
    margin: 0 20px;
    padding-left: 10px;
    padding-right: 10px;
    width: 85px;
    height: 40px;
    border-radius: 5px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;