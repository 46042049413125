import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import 'react-datepicker/dist/react-datepicker.css';
import { useState } from "react";
import styled from "styled-components";
import colors from "../design/colors";
import fonts from "../design/typography";
import { ChevronLeft, ChevronRight } from "../components/icons/chevron";
import { ko } from "date-fns/locale/ko";

const CALENDAR_FONT_STYLES = {
    fontSize: fonts.label4Regular.fontSize,
    lineHeight: fonts.label4Regular.lineHeight,
    fontFamily: fonts.label4Regular.fontFamily,
};


const CalendarModal = ({ onClose, dateValue, setDateValue, disablePreviousDays = false }) => {
    const [selectedDate, setSelectedDate] = useState(moment(dateValue ?? new Date()).toDate());
    const [onCalendarMonth, setOnCalendarMonth] = useState(moment(dateValue ?? new Date()).toDate());
    return (
        <StyledDatePickerWrapper>
            <DatePicker
                inline
                selected={selectedDate}
                locale={ko}
                onChange={(date: Date) => {
                    setSelectedDate(date);
                    setOnCalendarMonth(date);
                    setDateValue(moment(date).format('YYYY-MM-DD'));
                    onClose?.();
                }}
                renderDayContents={(dayOfMonth, date) => (
                    <div style={{position: 'relative'}}>
                        {dayOfMonth}
                    </div>        
                )}
                minDate={disablePreviousDays ? moment().toDate() : undefined}
            renderCustomHeader={(params => {
                return (
                    <CalendarHeader>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', padding: "0 8px"}}>
                            <span style={{ marginLeft: 4, color: colors.gray900, ...fonts.label3SemiBold }}>
                                {(params.monthDate.getFullYear()).toString() + '년 '}
                            </span>
                            <span style={{ color: colors.gray900, ...fonts.label3SemiBold, marginLeft: 4 }}>
                                {(params.monthDate.getMonth() + 1).toString() + '월'}
                            </span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', padding: "0 8px"}}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', cursor: 'pointer'}} onClick={() => {
                                params.decreaseMonth();
                                setOnCalendarMonth(new Date(moment(onCalendarMonth).subtract(1, 'months').format('YYYY-MM-DD')));
                                setSelectedDate(new Date(moment(onCalendarMonth).subtract(1, 'months').format('YYYY-MM-DD')));
                            }}>
                                <ChevronLeft color={colors.gray500} width="20px" height="20px" />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', cursor: 'pointer'}} onClick={() => {
                                params.increaseMonth();
                                setOnCalendarMonth(new Date(moment(onCalendarMonth).add(1, 'months').format('YYYY-MM-DD')));
                                setSelectedDate(new Date(moment(onCalendarMonth).add(1, 'months').format('YYYY-MM-DD')));
                            }}>
                                <ChevronRight color={colors.gray500} width="20px" height="20px" />
                            </div>
                        </div>
                    </CalendarHeader>
                )
            })}
            />
            
        </StyledDatePickerWrapper>
    );
}

export default CalendarModal;

const StyledDatePickerWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 224px;
  height: 250px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgb(0, 0, 0, 0.15);

  .react-datepicker {
    background-color: ${colors.white};
    border-radius: 8px;
    width: 100%;
    height: 100%;
    border: none;
    padding: 12px 16px;
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__month {
    margin: 0;
  }

  .react-datepicker__week {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .react-datepicker__day-names {
    display: flex;
    justify-content: space-around;
    margin-top: 8px;
  }

  .react-datepicker__day-name {
    color: ${colors.gray500};
    ${CALENDAR_FONT_STYLES}
  }

  .react-datepicker__day {
    width: 24px;
    height: 24px;
    ${CALENDAR_FONT_STYLES}
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
    border-radius: 50%;
    position: relative;
  }

  .react-datepicker__day--selected {
    background-color: ${colors.blue500};
    color: ${colors.white};
  }

  .react-datepicker__day--today {
    font-weight: bold;
  }

  .react-datepicker__header {
    background-color: white;
    border-bottom: none;
    border-radius: 20px 20px 0 0;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: ${colors.white};
    color: ${colors.gray900};
  }

  .react-datepicker__day:hover {
    background-color: ${colors.blue50};
    color: ${colors.blue600};
    border-radius: 50%;
  }

  .react-datepicker__day--outside-month {
    color: ${colors.gray400};
  }

  z-index: 10;
`;

const CalendarHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: ${colors.white};
`;