import React, { useState, useRef, useContext, useEffect } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import styled from "styled-components";
import strings from "../../../lang/strings";
import OrgaSearch from "../customSearch/orgaSearch";
import ClassSearch from "../customSearch/classSearch";
import { Page_settings } from "../../../config/page_settings";
import PerfectScrollbar from "react-perfect-scrollbar";
import Form from 'react-bootstrap/Form';


function LateNotificationSendModal(props) {
  const context = useContext(Page_settings);

  const {checkedMembers, class_id} = props


  console.log('에게?',checkedMembers,class_id)
  useEffect(() => {

  }, []);

  const handleClose = () => {
    props?.onClose?.();
  };

  const sendLateAlarm = () => {
    context.post(
      'attendance/late_notification',
      {
        members: checkedMembers,
        class_id 
      },
      response => {
        props?.onClose?.();
      }
    );
  }


  return (
    <Overlay>
      <ModalWrap>
        <div className="late-notification-send-modal">
          <div className="middle-content">
            총 <span className="highlight">{props.checkedCount}명</span>의 학생에게 <br></br>
            지각 알림을 보내시겠습니까?
          </div>
          <div className="bottom-content">
            <div className="close-btn" onClick={()=>handleClose()}>취소</div>
            <div className="send-btn" onClick={()=> sendLateAlarm()}>전송</div>
          </div>
        </div>
      </ModalWrap>
    </Overlay>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const ModalWrap = styled.div`
  width: 326px;
  height: 180px;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
`;

const CloseButton = styled.div`
  float: right;
  width: 40px;
  height: 40px;
  margin: 20px;
  cursor: pointer;
  i {
    color: #5d5d5d;
    font-size: 30px;
  }
`;

const Contents = styled.div`
  margin: 50px 30px;
  h1 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 60px;
  }
  img {
    margin-top: 60px;
    width: 300px;
  }
`;
const Button = styled.button`
  cursor: pointer;
  width: 100%;
  color: #ffffff;
  border-radius: 5px;
  border:0px;
  padding: 10px;
  margin-top: 20px;
`;
export default LateNotificationSendModal;