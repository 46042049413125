import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { withRouter } from "react-router-dom";


import Note from './note';
import Record from './record';

const Attendance = observer((props) => {
  const [mode, setMode] = useState(0);

  return (
    <div style={{height: '100%'}}>
      {/* <CloudContainer/> */}
      {mode === 0 && 
        <Note
          setMode={setMode}
        />
      }

      {mode === 1 &&
        <Record 
          setMode={setMode}
        />
      }
    </div>


  );
})


export default withRouter(Attendance);
