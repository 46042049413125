import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Store } from "react-notifications-component";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import IconButton from '../../../components/icon_button';
import { ChevronDown, ChevronUp } from '../../../components/icons/chevron';
import SearchBar from '../../../components/search_bar';
import SubjectChip from '../../../components/subject_chip';
import { Page_settings } from '../../../config/page_settings';
import colors from "../../../design/colors";
import fonts from "../../../design/typography";
import useDebounce from '../../../hooks/useDebounce';
import { rootStore } from '../../../mobx/store';
import IconMenu from '../../../components/icons/menu';
import NoClassPage from '../../no_class';
import Button from '../../../components/button';
import { Modal, ModalBody } from 'reactstrap';
import IconX from '../../../components/icons/x';

interface IExam {
  id: number;
  startTime: string;
  endTime: string;
  items: number;
  subject: string;
  title: string;
  status: number;
  participantCount: number;
  submitCount: number;
  submit: boolean;
}


interface IStatus {
  code: number;
  name: string;
}

const statusList: IStatus[] = [
  {
    code: 0,
    name: "전체"
  },
  {
    code: 3,
    name: "예정"
  },
  {
    code: 1,
    name: "진행중"
  },
  {
    code: 2,
    name: "마감"
  }
]


interface ISort {
  code: number;
  name: string;
}

const sortList: ISort[] = [
  {
    code: 0,
    name: "최신순"
  }, {
    code: 1,
    name: "오래된 순"
  }, {
    code: 2,
    name: "마감일순"
  }
]


const Exam = observer((props) => {
  const context: any = useContext(Page_settings);
  const navigate = useNavigate();
  const classId = rootStore.getClassId;
  const userType = rootStore.getProfile?.userType ?? "STUDENT";
  const [status, setStatus] = useState<IStatus>(statusList[0]);
  const [sort, setSort] = useState<ISort>(sortList[0]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [examList, setExamList] = useState<IExam[]> ([]);
  const [examListCount, setExamListCount] = useState<number>(0);
  const [keywordSearchTerm, setKeywordSearchTerm] = useState('');
  const keyword = useDebounce(keywordSearchTerm, 500);
  const [menuOpenIndex, setMenuOpenIndex] = useState<number>(-1);
  const [now, setNow] = useState(new Date()); // 현재
  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date());
    }, 1000);
    return () => clearInterval(interval);
  },[]);

  useEffect(() => {
    if(!isNaN(classId) && classId !== 0 && classId !== undefined && classId !== null){
    context.get(
      'exam',
      {
        classId,
        status: status.code,
        keyword: keyword,
        sort: sort.code,
        pageNum: 1,
        limit: 10
      },
      response => {
        setExamList(response.list);
          setExamListCount(response.totalCount);
        }
      );
    }
  }, [status, keyword, sort]);

  const [enterningExamId, setEnterningExamId] = useState<number>(-1);
  const [deleteExamId, setDeleteExamId] = useState<number>(-1);

  return (
    classId ? (
    <Container>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginBottom: 48 }}>
        <Title>시험</Title>
        {userType === "EXPERT" && <IconButton onClick={() => navigate("/study/exam/add/0")} size="medium" text={"시험 출제"} width="93px" height="36px" />}
      </div>
      <TabContainer>
        {statusList.filter(item => {
          if (userType === "STUDENT") {
            return item.code === 0 || item.code === 1 || item.code === 2;
          } else {
            return item.code === 0 || item.code === 1 || item.code === 2 || item.code === 3;
          }
        }).map((item, index) => (
          <TabItem key={index} selected={status.code === item.code} onClick={() => setStatus(item)}>
            {item.name}
            {status.code === item.code && <span style={{...fonts.body3SemiBold, color: colors.blue600, marginLeft: "10px"}}>{examListCount}</span>}
          </TabItem>
        ))}
      </TabContainer>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", position: "relative"}}>
        <SearchBar value={keywordSearchTerm} onChange={(e) => setKeywordSearchTerm(e.target.value)} placeholder="시험을 검색해주세요" size="medium" width="245px"/>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", cursor: "pointer"}} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
          <span style={{...fonts.body3Medium, color: colors.gray600, width: "100%"}}>{sort.name}</span>
          {isDropdownOpen ? (
            <ChevronUp width="24px" height="24px" color={colors.gray500}/> 
          ) : (
            <ChevronDown width="24px" height="24px" color={colors.gray500}/>
          )}
          {isDropdownOpen && (
            <DropDownBox>
              {sortList.map((item, index) => (
                <DropDownItem key={index} onClick={() => setSort(item)}>{item.name}</DropDownItem>
              ))}
            </DropDownBox>
          )}
        </div>
      </div>
      {/* 시험 리스트 */}
      {examList !== null && examList.length > 0 ? (
        <ExamContentWrapper>
          {examList.map((item, idx) => {
            const open = item.startTime < moment(now).format("YYYY-MM-DD HH:mm:ss");
            const end = item.endTime < moment(now).format("YYYY-MM-DD HH:mm:ss");
            return (
              <ExamItem key={idx} 
                style={{cursor: (!end || rootStore.getProfile?.userType === "EXPERT") ? "pointer" : "default"}}
                onClick={() => {
                  if ((rootStore.getProfile?.userType !== "EXPERT")) {
                    return;
                  }
                  navigate("/study/exam/" + item.id + "/info");
                }}
              >
                <div style={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", position: "relative"}}>
                  <SubjectChip subject={item.subject} style={{marginBottom: "16px"}}/>
                  {userType === "EXPERT" && <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}} onClick={(e) => {
                    if (menuOpenIndex === idx) {
                      setMenuOpenIndex(-1);
                    } else {
                      setMenuOpenIndex(idx);
                    }
                    e.stopPropagation();
                  }}>
                    <IconMenu width="24px" height="24px" color={colors.gray500}/>
                  </div>}
                  {menuOpenIndex === idx && (
                    <DropDownBox>
                      <DropDownItem onClick={(e) => {
                        setDeleteExamId(item.id);
                        setMenuOpenIndex(-1);
                        e.stopPropagation();
                      }}>삭제하기</DropDownItem>
                    </DropDownBox>
                  )}
                </div>
                <div style={{...fonts.body2SemiBold, color: colors.gray900, width: "100%"}}>{item.title}</div>
                <div style={{display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "space-between", width: "calc(100% - 48px)", position: "absolute", bottom: "24px", left: "24px"}}>
                  { !open && <div style={{...fonts.label4Medium, color: colors.gray500, width: "100%"}}>{moment(item.startTime).format("YYYY-MM-DD HH:mm") + " 예정"}</div>}
                  { userType === "EXPERT" && open && !end && <>
                    <div style={{...fonts.label4Medium, color: colors.gray500}}>{moment(item.endTime).format("YYYY-MM-DD HH:mm") + " 까지"}</div>
                    <div style={{...fonts.label4Medium}}>
                      <span style={{color: colors.blue600}}>{item.submitCount}명</span>
                      <span style={{color: colors.gray400}}>/{item.participantCount}명</span>
                    </div>
                  </>}
                  {end && <div style={{...fonts.label4Medium, color: colors.gray500, width: "100%"}}>{"마감"}</div>}
                  {
                    userType === "STUDENT" && open && !end && <>
                      <div style={{...fonts.label4Medium, color: colors.blue600}}>{moment(item.endTime).format("YYYY-MM-DD HH:mm") + " 까지"}</div>
                      {
                        item.submit ? 
                        <Button
                          text="결과보기"
                          width="77px"
                          height="34px"
                          size="small"
                          onClick={() => navigate("/study/exam/" + item.id + "/result")}
                          buttonColor={colors.gray100}
                          textColor={colors.gray800}
                        />
                          : 
                        <Button
                          text="응시하기"
                          width="77px"
                          height="34px"
                          size="small"
                          onClick={() => setEnterningExamId(item.id)}
                        />
                      }
                    </>
                  }
                </div>
              </ExamItem>
            );
          })}
          {
            enterningExamId !== -1 && 
            (<ExamEnterModal 
              toggle={() => setEnterningExamId(-1)} 
              onConfirm={() => {
                navigate("/study/exam/" + enterningExamId + "/ing");
                setEnterningExamId(-1);
              }}
            />)
          }
          {
            deleteExamId !== -1 && 
            (<ExamDeleteModal 
              toggle={() => setDeleteExamId(-1)} 
              onConfirm={() => {
                context.delete("exam/" + deleteExamId);
                navigate(0);
              }}
            />)
          }
        </ExamContentWrapper>
      ) : (
          <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", marginTop: "114px"}}>
            <img
              style={{width: "152px", height: "113px", marginBottom: "24px"}}
              src="/assets/image/sad_rai.png"
              alt=""
            />
            <span style={{...fonts.body3Medium, color: colors.gray400}}>아직 시험이 없습니다.</span>
            <span style={{...fonts.body3Medium, color: colors.gray400}}>지금 바로 시험을 등록해보세요!</span>
          </div>
      
      )}
    </Container>
  ) : (
    <NoClassPage userType={userType} />

))});

const ExamEnterModal = ({toggle, onConfirm}) => {
  return (
    <Modal isOpen={true} toggle={toggle}>
      <ModalBody style={{padding: "30px 32px"}}>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", marginBottom: "40px"}}>
          <div style={{cursor: "pointer"}} onClick={toggle}>
            <IconX width="28px" height="28px" color={colors.gray500}/>
          </div>
        </div>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", marginBottom: "45px",position: "relative"}}>
          <img src="/assets/image/warning_rai.png" alt="" style={{width: "50%", height: "50%", marginLeft: 60}}/>
          <span style={{fontSize: "22px", fontFamily: "Pretendard Bold", color: "#F9B5B5", position: "absolute", top: 41, right: 111}}>잠깐!</span>
        </div>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", marginBottom: "40px"}}>
          <span style={{...fonts.body3Medium, color: colors.gray600}}>시험을 응시하게 되면 다른창으로 이동할 수 없습니다.</span>
          <span style={{...fonts.body3Medium, color: colors.gray600}}>임시저장 또는 시험중단이 되지 않으니 신중하게 시작해주세요</span>
        </div>
        <Button
          text="응시하기"
          width="100%"
          height="44px"
          size="large"
          onClick={onConfirm}
        />
      </ModalBody>
    </Modal>
  )
}

const ExamDeleteModal = ({toggle, onConfirm}) => {
  return (
    <Modal size="md" isOpen={true} toggle={toggle}>
    <ModalBody style={{padding: "24px"}}>
      <div style={{...fonts.body2Medium, color: colors.gray900, marginBottom: 32, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: 24}}>
        <span>시험을 삭제하시겠습니까?</span>
        <span style={{...fonts.body2Medium, color: colors.gray900}}>문제와 학생들의 응시기록이 모두 삭제됩니다</span>
      </div>
      <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 8}}>
        <Button
          size="medium"
          text="취소"
          onClick={toggle}
          buttonColor={colors.gray100}
          textColor={colors.gray500}
          width="112px"
          height="44px"
        />
        <Button
          size="medium"
          text="삭제"
          onClick={onConfirm}
          buttonColor={colors.warning}
          textColor={colors.white}
          width="112px"
          height="44px"
        />
      </div>
    </ModalBody>
  </Modal>
  )
}
const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 24px 40px;
`;


const Title = styled.div`
  font-size: ${fonts.title2SemiBold.fontSize};
  font-family: ${fonts.title2SemiBold.fontFamily};
  line-height: ${fonts.title2SemiBold.lineHeight};
  color: ${colors.gray900};
`;

const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid ${colors.gray100};
  margin-bottom: 32px;
`;

const TabItem = styled.div<{selected: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${props => props.selected ? colors.gray900 : colors.gray600};
  font-size: ${props => props.selected ? fonts.body3SemiBold.fontSize : fonts.body3Medium.fontSize};
  line-height: ${props => props.selected ? fonts.body3SemiBold.lineHeight : fonts.body3Medium.lineHeight};
  font-family: ${props => props.selected ? fonts.body3SemiBold.fontFamily : fonts.body3Medium.fontFamily};
  cursor: pointer;
  width: 74px;
  height: 40px;
  border-bottom: ${props => props.selected ? `2px solid ${colors.gray900}` : "none"};
`;

const DropDownBox = styled.div`
  position: absolute;  
  width: 74px;
  background-color: white;
  border: 1px solid ${colors.gray100};
  border-radius: 8px;
  z-index: 3;
  top: 40px;
  right: 0;

  &:nth-child(1) {
    border-radius: 8px 8px 0 0;
  }
  &:nth-child(2) {
    border-radius: 0 0 8px 8px;
  }
`;

const DropDownItem = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  color: ${colors.gray500};
  font-size: ${fonts.body3Regular.fontSize};
  line-height: ${fonts.body3Regular.lineHeight};
  font-family: ${fonts.body3Regular.fontFamily};
  &:hover {
    background-color: ${colors.blue50};
  }
`;


const ExamContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px 10px;
  flex-wrap: wrap;
  margin-top: 20px;
`;

const ExamItem = styled.div`
  width: calc(33.333333% - 10px);
  height: 200px;
  background-color: ${colors.white};
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
`;


export default Exam;
