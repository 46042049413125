import React from 'react';
import { useLocation } from 'react-router-dom';
import BlankHeader from '../header/blank_header';
import Sidebar from '../sidebar/sidebar';
import Header from '../header/header';
import colors from '../../design/colors';
interface LayoutProps {
    children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    const location = useLocation();
    const blankHeaderRoutes = ['/login', '/signup', '/find'];
    const noHeaderRoutes = ['/landing'];
    const noSidebarRoutes = [
        '/login', 
        '/signup', 
        '/landing', 
        '/find', 
    ];

    const noHeaderRegex = /^\/study\/exam\/\d+\/ing$/;
    const noSidebarRegex = /^\/study\/exam\/\d+\/ing$/;

    const showBlankHeader = blankHeaderRoutes.some(path => location.pathname.startsWith(path));
    const noHeader = noHeaderRoutes.some(path => location.pathname.startsWith(path)) || noHeaderRegex.test(location.pathname);

    const showSidebar = !noSidebarRoutes.some(path => location.pathname.startsWith(path)) && !noSidebarRegex.test(location.pathname);

    return (
        <div style={{ display: 'flex' , flexDirection: "row", overflow: "hidden"}}>
            {showSidebar && <Sidebar />}
            <div style={{ flex: 1 , width:  showSidebar ? "calc(100% - 200px)" : "100%", height: "100vh"}}>
                {showBlankHeader && <BlankHeader />}
                {!showBlankHeader && !noHeader && <Header />}
                <main style={{height: (showBlankHeader || !noHeader) ? "calc(100% - 100px)" : "100%", width: "100%", backgroundColor: colors.gray50, position: "relative"}}>
                {children}
                </main>
            </div>
        </div>
    );
};

export default Layout; 