import React, { useState } from "react";

export default function PaymentSearch(props) {


    const handleEnter = e => {
        if (e.keyCode == 13) {
            props.onSearch()
        }
      }

    return (
        <div>
            <div className="payment-search  align-item-center box-shadow">             
                <button className="common-button" onClick={()=>{props.onSearch();}} > 
                    <img src={'/assets/image/icon_search_black.png'} alt=""/>
                </button>
                <input className="input-transparent" placeholder={props.placeholder} type={props.inputType} maxLength={props.maxLength} value={props.value} onChange={(e) => props.onChange(e.target.value)} onKeyDown={handleEnter}
                />
            </div>
        </div>
    );


}
