import React from "react";
import { Link } from "react-router-dom";


const Footer = () => {
    return (
        <>
            <section className="wrap banner-wrap">
                <div className="banner-bg">
                    <h2>
                    기관별 츄잉만의 특별한 기능적 차이를 <br />
                    지금 확인해보세요
                    </h2>
                    <Link to="#">츄잉 시작하기</Link>
                    {/* <a href="#"> 츄잉 시작하기 </a> */}
                </div>
            </section>
            <footer className="footer" style={{position:'absolute',left:'0',width:'100%'}}>
                <div className="inner">
                    <a className="f-logo" href="index.html">
                        <img src={"/assets/image/landing_page/logo_chewing_plus.png"} alt="츄잉 로고" style={{width:'100%'}}/>
                    </a>
                    <ul className="f-nav">
                    <li>
                        <Link to="#">이용약관</Link>
                        {/* <a href="#">이용약관</a> */}
                    </li>
                    <li>｜</li>
                    <li>
                        <Link to="#">개인정보처리방침</Link>
                        {/* <a href="#">개인정보처리방침</a> */}
                    </li>
                    <li>｜</li>
                    <li>
                        <Link to="#">FAQ</Link>
                        {/* <a href="#">FAQ</a> */}
                    </li>
                    </ul>
                    <p className="f-info">
                    <span>주식회사 고트헤이븐</span> 사업자등록번호 : 177-88-02306
                    </p>
                </div>
                <p className="f-copy">© 2024 GOATHEAVEN</p>
            </footer>
        </>
    )
}

export default Footer;