import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";
import IconButton from "../../../components/icon_button";
import { ChevronDown, ChevronUp } from "../../../components/icons/chevron";
import Pencil from "../../../components/icons/pencil";
import TrashCan from "../../../components/icons/trash_can";
import SearchBar from "../../../components/search_bar";
import { Page_settings } from "../../../config/page_settings";
import colors from "../../../design/colors";
import fonts from "../../../design/typography";
import useDebounce from "../../../hooks/useDebounce";
import { rootStore } from "../../../mobx/store";
import NoClassPage from "../../no_class";
import Checkbox from "../../../components/checkbox";

interface CounselingLog {
  id: number, 
  classroom: number,
  counselingDate: string,
  name: string,
  birthday: string,
  gender: string,
  school: string,
  university: string,
  department: string,
  isGraduated: boolean,
  phone: string,
  email: string,
  studyCounseling: {
    subject: string,
    content: string
  }[],
  lifeCounseling: string,
  suggestion: string,
  familyInfo: {
    name: string,
    phone: string,
    time: string,
    relationship: string
  }[],
  isRegistered: boolean,
  grade: number,
  address: string,
  career: string
}

const counselingSortList: {
  name: "최신순" | "오래된순" | "이름순";
  sort: number;
}[] = [
  { name: "최신순", sort: 0},
  { name: "오래된순", sort: 1 },
  { name: "이름순", sort: 2 },
];

const categoryList: { name: "등록" | "미등록" | "전체"; category: string }[] = [
  { name: "전체", category: "" },
  { name: "등록", category: "등록" },
  { name: "미등록", category: "미등록" },
];

const CounselingLogList: React.FC = observer(() => {
  const context: any = useContext(Page_settings);
  const navigate = useNavigate();
  const classId = rootStore.getClassId;
  const [logs, setLogs] = useState<CounselingLog[]>([]);
  const [selectedLogs, setSelectedLogs] = useState<number[]>([]);
  const [searchStatus, setSearchStatus] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortDropdownStatus, setSortDropdownStatus] = useState(false);
  const [selectSort, setSelectSort] = useState<{
    name: "최신순" | "오래된순" | "이름순";
    sort: number;
  }>({ name: "최신순", sort: 0 });
  const [categoryDropdownStatus, setCategoryDropdownStatus] = useState(false);
  const [selectCategory, setSelectCategory] = useState<{
    name: "등록" | "미등록" | "전체";
    category: string;
  }>({ name: "전체", category: "" });
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageNumbers, setPageNumbers] = useState<number[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [idsToDelete, setIdsToDelete] = useState<number[]>([]);


  const keyword = useDebounce(searchTerm, 500);
  const userType = rootStore.getProfile?.userType ?? "STUDENT";
  useEffect(() => {
    if(!isNaN(classId) && classId !== 0 && classId !== undefined && classId !== null){
    context.get(
      `/class/${classId}/counseling-log`,
      {
        sort: selectSort.sort,
        keyword,
        page,
        category: selectCategory.category,
      },
      (res: any) => {
        if (res.list && res.totalCount > 0) {
          setLogs(res.list);
          setTotalCount(res.totalCount ?? 0);
          setTotalPages(res.totalCount ? Math.ceil(res.totalCount / 10) : 1);
          setPageNumbers(Array.from({ length: 5 }, (_, i) => page + i - 2));
        } else {
          setLogs([]);
          setTotalCount(0);
          setTotalPages(1);
          setPageNumbers([1]);
        }
        }
      );
    }
  }, [selectSort, selectCategory, searchStatus, page, keyword]);

  const handleSelectAll = () => {
    if (selectedLogs.length === logs.length && logs.length > 0) {
      setSelectedLogs([]);
    } else {
      setSelectedLogs(logs.map((log) => log.id));
    }
  };

  const handleSelect = (id: number) => {
    if (selectedLogs.includes(id)) {
      setSelectedLogs(selectedLogs.filter((logId) => logId !== id));
    } else {
      setSelectedLogs([...selectedLogs, id]);
    }
  };

  const moveToCounselingLogDetail = (id: number) => {
    navigate(`/class/counseling-log/${id}`);
  };

  const moveToCounselingLogEdit = (id: number) => {
    navigate(`/class/counseling-log/${id}/edit`);
  };

  const handleDelete = () => {
    context.delete(`/class/${classId}/counseling-log`, {
      ids: idsToDelete,
    }, (res: any) => {
      setSearchStatus(!searchStatus);
      setIdsToDelete([]);
    });
  }

  const [useRegisterConfirm, setUseRegisterConfirm] = useState(false);

  const handleRegister = () => {
    context.post(`/class/${classId}/counseling-log/register`, {
      ids: selectedLogs,
    }, (res: any) => {
      setSearchStatus(!searchStatus);
      setSelectedLogs([])
      setUseRegisterConfirm(false)
    });
  }

  const [selectedRegisterLogsCount, setSelectedRegisterLogsCount] = useState<number>(0);
  const [selectedUnregisterLogsCount, setSelectedUnregisterLogsCount] = useState<number>(0);

  useEffect(() => {
    setSelectedRegisterLogsCount(selectedLogs.filter((log) => logs.find((l) => l.id === log)?.isRegistered).length);
    setSelectedUnregisterLogsCount(selectedLogs.filter((log) => !logs.find((l) => l.id === log)?.isRegistered).length);
  }, [selectedLogs, logs]);

  return (
    classId ? (
    <Container>
      <Header>
        <Title>상담일지</Title>
        <IconButton onClick={() => navigate(`/class/counseling-log/add`)} text="일지 작성" size="medium" style={{width: "93px", height: "36px"}}/>
      </Header>
      <SearchRow>
        <SearchBar
          placeholder="이름을 검색해주세요"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          width="245px"
        />
          <DropDownMenu onClick={() => setSortDropdownStatus(!sortDropdownStatus)}>
            <span style={{...fonts.label4Medium, color: colors.gray500, whiteSpace: 'nowrap'}}>{selectSort.name}</span>
            {sortDropdownStatus ? (
              <ChevronUp color={colors.gray600} width="16px" height="16px"/>
            ) : (
              <ChevronDown color={colors.gray600} width="16px" height="16px"/>
            )}
            {sortDropdownStatus && (
              <DropDownBox>
                {counselingSortList.map((item, index) => (
                  <DropDownItem
                    key={index}
                    onClick={() =>
                      setSelectSort({ name: item.name, sort: item.sort })
                    }
                  >
                    {item.name}
                  </DropDownItem>
                ))}
              </DropDownBox>
            )}
          </DropDownMenu>
      </SearchRow>
      <div style={{display: "flex", flexDirection: "row", alignItems: "flex-end", marginBottom: "20px", justifyContent: "space-between", height: "56px"}}>
        {selectedLogs.length === 0 && 
        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
          <span style={{ ...fonts.label4SemiBold, color: colors.gray600}}>
                총 
          </span>
          <span style={{ ...fonts.label4SemiBold, color: colors.blue800, margin:"0 4px" }}>
            {totalCount}
          </span>
          <span style={{ ...fonts.label4SemiBold, color: colors.gray600 }}>
            개
          </span>
        </div>}
        {selectedLogs.length > 0 && 
        <>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
          <span style={{ color: '#1561CE', fontSize: "13px", margin:"0 4px" }}>
            {selectedLogs.length}
          </span>
          <span style={{ color: '#73787E', fontSize: "13px" }}>
            개 선택
          </span>
        </div>
        <BottomButtonBox>
          <Button onClick={() => setIdsToDelete(selectedLogs)} style={{
            color: "white", 
            height: 40,
            backgroundColor: "#EE4C69",
            borderRadius: "8px",
            flex: "none",
            order: 0,
            flexGrow: 0
          }}>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "4px", height: "100%", padding: "6px 10px"}}>
              <TrashCan color="white" />
              <span color="white">삭제하기</span>
            </div>
          </Button>
          <Button onClick={() => setUseRegisterConfirm(true)} style={{
            height: 40,
            backgroundColor: "#0F84F4",
            color: "white", 
            borderRadius: "8px",
            flex: "none",
            order: 0,
            flexGrow: 0
          }}>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "4px", height: "100%", padding: "6px 10px"}}>
              <span>등록 상태 전환</span>
            </div>
          </Button>
      </BottomButtonBox>
      </>}
      </div>
      <Table>
        <thead>
          <tr>
            <Th width="100px">
              <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%"}}>
                <Checkbox
                  setChecked={() => handleSelectAll()}
                  checked={selectedLogs.length === logs.length && logs.length > 0}
                  size="20px"
                />
              </div>
            </Th>
            <Th width="200px">
              <DropDownMenu
                onClick={() =>
                  setCategoryDropdownStatus(!categoryDropdownStatus)
                }
              >
                <span style={{...fonts.label4Medium, color: colors.gray500}}>{selectCategory.name}</span>
                {categoryDropdownStatus ? (
                  <ChevronUp color={colors.gray500} width="16px" height="16px"/>
                ) : (
                  <ChevronDown color={colors.gray500} width="16px" height="16px"/>
                )}
                {categoryDropdownStatus && (
                  <DropDownBox style={{right: "calc(50% - 40px)"}}>
                    {categoryList.map((item, index) => (
                      <DropDownItem
                        key={index}
                        onClick={() => {
                          setCategoryDropdownStatus(false);
                          setSelectCategory({
                            name: item.name,
                            category: item.category,
                          });
                          setSelectedLogs([]);
                        }}
                      >
                        {item.name}
                      </DropDownItem>
                    ))}
                  </DropDownBox>
                )}
              </DropDownMenu>
            </Th>
            <Th width="calc(25% - 125px)">상담일자</Th>
            <Th width="calc(25% - 125px)">이름</Th>
            <Th width="calc(25% - 125px)">전화번호</Th>
            <Th width="calc(25% - 125px)">학교</Th>
            <Th width="100px">수정</Th>
            <Th width="100px">삭제</Th>
          </tr>
        </thead>
        <tbody>
          {logs.map((log) => (
            <tr key={log.id}>
              <Td>
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%"}}>
                  <Checkbox
                    checked={selectedLogs.includes(log.id)}
                    setChecked={() => handleSelect(log.id)}
                    size="20px"
                  />
                </div>
              </Td>
              <Td 
                style={{cursor: "pointer", width: "200px"}}
                onClick={() => moveToCounselingLogDetail(log.id)}
              >
                <StatusBadge is_registered={log.isRegistered}>
                  {log.isRegistered ? "등록" : "미등록"}
                </StatusBadge>
              </Td>
              <Td 
                style={{cursor: "pointer"}}
                onClick={() => moveToCounselingLogDetail(log.id)}
              >
                <span>
                  {log.counselingDate}
                </span>
              </Td>
              <Td 
                style={{cursor: "pointer"}}
                onClick={() => moveToCounselingLogDetail(log.id)}
              >
                <span>
                  {log.name}
                </span>
              </Td>
              <Td 
                style={{cursor: "pointer"}}
                onClick={() => moveToCounselingLogDetail(log.id)}
              >
                <span>
                  {log.phone}
                </span>
              </Td>
              <Td 
                style={{cursor: "pointer"}}
                onClick={() => moveToCounselingLogDetail(log.id)}
              >
                <span>
                  {log.school}
                </span>
              </Td>
              <Td>
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer"}} onClick={() => moveToCounselingLogEdit(log.id)}>
                  <Pencil color="#73787E"/>
                </div>
              </Td>
              <Td>
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer"}} onClick={() => setIdsToDelete([log.id])}>
                  <TrashCan color="#73787E" />
                </div>
              </Td>
            </tr>
          ))}
          {logs.length === 0 && (
            <>
              <tr>
                <Td
                  colSpan={8}
                  style={{
                    height: "400px",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  상담일지가 없습니다.
                </Td>
              </tr>
            </>
          )}
        </tbody>
      </Table>
      <PaginationBox>
        <PageButton onClick={() => setPage(1)} disabled={page === 1}>{'<<'}</PageButton>
        <PageButton onClick={() => setPage(page - 1)} disabled={page === 1}>{'<'}</PageButton>
        {pageNumbers.map((num) => (
            <PageButton
            key={num}
            isActive={page === num}
            onClick={() => setPage(num)}
            disabled={num <= 0 || num > totalPages}
            style={{ display: (num <= 0 || num > totalPages) ? 'none' : 'flex' }}
            >
            {num}
            </PageButton>
        ))}
        <PageButton onClick={() => setPage(page + 1)} disabled={page === totalPages}>{'>'}</PageButton>
        <PageButton onClick={() => setPage(totalPages)} disabled={page === totalPages}>{'>>'}</PageButton>
      </PaginationBox>
      {idsToDelete.length > 0 && (
        <ChangeModal
          onConfirm={handleDelete}
          onCancel={() => setIdsToDelete([])}
          showCancel={true}
          confirmBtnText="삭제"
          cancelBtnText="취소"
          text={``}
        >
          <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "32px", marginTop: "24px"}}>
            <span style={{...fonts.body2Medium, color: colors.gray900}}>{`${idsToDelete.length}개의 상담일지를 삭제하시겠습니까?`}</span>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "10px"}}>
              <Button onClick={() => setIdsToDelete([])} style={{backgroundColor: colors.gray200, color: colors.gray600, width: "112px", height: "40px"}}>취소</Button>
              <Button onClick={handleDelete} style={{backgroundColor: colors.warning, color: colors.white, width: "112px", height: "40px"}}>삭제</Button>
            </div>
          </div>
        </ChangeModal>
      )}
      {useRegisterConfirm && (
        <ChangeModal
          title="등록 상태 변경"
          onConfirm={handleRegister}
          onCancel={() => setUseRegisterConfirm(false)}
          showCancel={true}
          confirmBtnText="변경"
          cancelBtnText="취소"
          dependencies={[selectedUnregisterLogsCount, selectedRegisterLogsCount]}
          >
              <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: "24px"}}>
              {selectedUnregisterLogsCount > 0 && (
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", ...fonts.body2Medium}}>
                  <span>{`${selectedUnregisterLogsCount}건을 `}</span>
                  <span style={{ color: "#EE4C69", margin: "0 4px" }}>미등록</span>
                  <span>{` 에서 `}</span>
                  <span style={{ color: "#1561CE", margin: "0 4px" }}>등록</span>
                  <span>{` 으로 `}</span>
                </div>
              )}
              {selectedRegisterLogsCount > 0 && (
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", ...fonts.body2Medium}}>
                  <span>{`${selectedRegisterLogsCount}건을 `}</span>
                  <span style={{ color: "#1561CE", margin: "0 4px" }}>등록</span>
                  <span>{` 에서 `}</span>
                  <span style={{ color: "#EE4C69", margin: "0 4px" }}>미등록</span>
                  <span>{` 으로 `}</span>
                </div>
              )}
              <span style={{ ...fonts.body2Medium, color: colors.gray900}}>등록 상태를 변경하시겠습니까?</span>
              <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "10px", marginTop: "32px"}}>
                <Button onClick={() => setUseRegisterConfirm(false)} style={{backgroundColor: colors.gray200, color: colors.gray600, width: "112px", height: "40px"}}>취소</Button>
                <Button onClick={handleRegister} style={{backgroundColor: colors.primary, color: colors.white, width: "112px", height: "40px"}}>변경</Button>
              </div>
            </div>
        </ChangeModal>
      )}
    </Container>
    ) : (
      <NoClassPage userType={userType} />
    )
  );
});

const Container = styled.div`
  padding: 24px 40px;
  position: relative;
  height: 100%;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 44px;
  height: 40px;
`;

const Title = styled.div`
  font-size: ${fonts.title2SemiBold.fontSize};
  font-family: ${fonts.title2SemiBold.fontFamily};
  line-height: ${fonts.title2SemiBold.lineHeight};
  color: ${colors.gray900};
`;

const Button = styled.button`
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  height: 40px;
  background-color: #0F84F4;
  font-size: ${fonts.label3Medium.fontSize};
  font-family: ${fonts.label3Medium.fontFamily};
  line-height: ${fonts.label3Medium.lineHeight};
`;

const SearchRow = styled.div`
  margin-bottom: 7px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;


const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th<{ width?: string }>`
  background-color: ${colors.gray100};
  padding: 0 12px;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid #e9ebed;
  width: ${(props) => props.width || "auto"};
  height: 40px;
  color: ${colors.gray500};
  font-family: ${fonts.label4Medium.fontFamily};
  font-size: ${fonts.label4Medium.fontSize};
  line-height: ${fonts.label4Medium.lineHeight};
`;

const Td = styled.td`
  padding: 12px;
  border-bottom: 1px solid #e9ebed;
  background-color: white;
  height: 40px;
  text-align: center;
  vertical-align: middle;
  font-family: ${fonts.label4Medium.fontFamily};
  font-size: ${fonts.label4Medium.fontSize};
  line-height: ${fonts.label4Medium.lineHeight};
  color: ${colors.gray900};
`;

const StatusBadge = styled.span<{ is_registered: boolean }>`
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  background-color: ${(props) => (props.is_registered ? "#E3F2FD" : "#FFE0E0")};
  color: ${(props) => (props.is_registered ? "#1976D2" : "#D32F2F")};
`;

const DropDownMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: transparent;
  position: relative;
  padding: 0 8px;
  gap: 10px;
  font-family: ${fonts.label4Medium.fontFamily};
  font-size: ${fonts.label4Medium.fontSize};
  line-height: ${fonts.label4Medium.lineHeight};
`;

const DropDownBox = styled.div`
  position: absolute;  
  width: 74px;
  background-color: white;
  border: 1px solid ${colors.gray100};
  border-radius: 8px;
  z-index: 3;
  top: 40px;
  right: 0;
`;

const DropDownItem = styled.div<{isFirst?: boolean, isLast?: boolean}>`
  width: 100%;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  color: ${colors.gray500};
  font-size: ${fonts.body3Regular.fontSize};
  line-height: ${fonts.body3Regular.lineHeight};
  font-family: ${fonts.body3Regular.fontFamily};
  &:hover {
    background-color: ${colors.blue50};
  }

  border-radius: ${(props) => props.isFirst ? "8px 0 0 8px" : props.isLast ? "0 8px 8px 0" : "0"};
`;

const PaginationBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-top: 20px;
`;

const PageButton = styled.button<{ isActive?: boolean }>`
  width: 40px;
  height: 40px;
  border: none;
  background-color: ${props => props.isActive ? '#0F84F4' : '#FFFFFF'};
  color: ${props => props.isActive ? '#FFFFFF' : '#73787E'};
  border-radius: 50%;  // 원형 버튼
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${props => props.isActive ? '#0F84F4' : '#F5F7FA'};
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  // 첫 페이지, 마지막 페이지 버튼 스타일
  &:first-child,
  &:last-child {
    font-size: 12px;
  }

  // 이전, 다음 버튼 스타일
  &:nth-child(2),
  &:nth-last-child(2) {
    font-size: 12px;
  }
`;

const BottomButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  padding: 12px 22px;
  gap: 8px;
`;


const ChangeModal = ({ children, ...props }: { children: React.ReactNode } & React.ComponentProps<typeof Modal>) => {
  return (
    <Modal size="sm" isOpen={true} {...props}>
      <ModalBody style={{padding: "24px"}}>
        {children}
      </ModalBody>
    </Modal>
  )
}

export default CounselingLogList;


