import React, { useContext, useEffect, useState } from 'react';
import SweetAlert from "react-bootstrap-sweetalert";
import 'react-datetime/css/react-datetime.css';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { withRouter } from "react-router-dom";
import { Page_settings } from "../../../config/page_settings";
import { getMinuteFromSecond } from '../../../helper/common';
import strings from "../../../lang/strings";
import ExamFileViewer from './exam_file_viewer';

const StartExam = (props) => {
    const context = useContext(Page_settings);
    const examId = parseInt(props.match.params.exam_id);
    const [examInfo, setExamInfo] = useState(null);
    const [answerList, setAnswerList] = useState([]);
    const [time, setTime] = useState(0);
    const [allAnsweredAlert, setAllAnsweredAlert] = useState(false);
    const [createAlert, setCreateAlert] = useState(false);  
    const [startExam, setStartExam] = useState(false);
    const history = props.history;
    const [startExamAlert, setStartExamAlert] = useState('');

    const handleStartExam = () => {
        context.post(
            'exam/enter',
            {
                id: examId
            },
            response => {
                if (response === true) {
                    setStartExam(true);
                } else {
                    setStartExamAlert(response);
                }
            },
        );
    }

    useEffect(() => {
        context.handleSetPageHeader(false);
        context.handleSetPageSidebar(false);

        getExamInfo();
        const timer = window.setInterval(() => {
            setTime(prevTime => prevTime - 1);
        }, 1000);
        handleStartExam();

        return () => {
            context.handleSetPageHeader(true);
            context.handleSetPageSidebar(true);
            window.clearInterval(timer);
        }
    },[]);

    const getExamInfo = () => {
        context.post(
            'exam/info',
            {
                id: examId
            },
            response => {
                const info = response.info;
                setExamInfo(info);
                setTime(parseInt(info.time) * 60);
                const answerData = typeof info.answer_data === 'string' ? JSON.parse(info.answer_data) : info.answer_data;
                setAnswerList(answerData);
            }
        );
    }

    const checkAllAnswered = () => {
        const allAnswered = answerList.every((item) => {
            console.log(typeof item.answer);
            if (item.answer === '' || (item.answer === '[]' && item.type === 1)) {
                return false;
            }
            return true;
        });

        if (!allAnswered) {
            setAllAnsweredAlert(true);
            return;
        }

        setCreateAlert(true);
    }

    const onCreate = () => {
        let answer = JSON.stringify(answerList);
        context.post(
            'exam/set_result',
            {
                exam_id: examId,
                time: (parseInt(examInfo.time) * 60 - time),
                answer_data: answer,
            },
            response => {
                props.history.push('/exam/student/' + examId);
            }
        );
    };

    const onChangeAnswer = (index, answer) => {
        const newList = answerList.map((item, idx) => {
            if (idx === index) {
                const updatedItem = {
                    ...item,
                    answer: answer,
                };

                return updatedItem;
            }

            return item;
        });
        setAnswerList(newList);
    }

    useEffect(() => {
        if (time && time <= 0) {
            onCreate();
        }
    }
    ,[time]);

    return (
        <React.Fragment>
            {startExam ? (
            <>
            <div id="header" className="header navbar-default">
                <div className="d-flex flex-row align-items-center" style={{ position: 'absolute', left: 24, top: 20 }}>
                    <div style={{ color: '#222', fontSize: 17, fontWeight: 700 }}>
                        {examInfo == null ? '' : examInfo.title}
                    </div>
                    <div style={{ color: '#6C7276', fontSize: 14, fontWeight: 500, marginLeft: 6 }}>
                        {'(' + (examInfo == null ? '0' : examInfo.time) + '분)'}
                    </div>
                </div>
                <div className='d-flex flex-row align-items-center' style={{ margin: 'auto', width: 132, height: 38, borderRadius: 6, background: '#FFF', padding: '0 13px' }}>
                    <div className="flex-grow-1" style={{ color: '#222', fontSize: 14, fontWeight: 700 }}>{'남은 시간'}</div>
                    <div className="enter-time" style={{ color: '#EB4A4A', fontSize: 14, fontWeight: 500 }}>{getMinuteFromSecond(time)}</div>
                </div>
                <ul className="navbar-nav navbar-right">
                </ul>
            </div>
            <div className="display-flex-important" id='conferenceRoom'>
                <div style={{ width: '100%', height: 'calc(100vh - 90px)' }}>
                    <div className="main-content" style={{ width: '100%', height: '100%', padding: '18px 28px 0', borderRadius: 12, background: '#fff', display: 'flex', flexDirection: 'column' }}>
                        <ReactNotifications />
                        <div className='d-flex flex-row flex-1' style={{ overflow: 'hidden' }}>
                            <div className='info-wrap' style={{ flex: 1, overflowY: 'auto', position: 'relative' }}>
                                <ExamFileViewer url={examInfo == null ? '' : context.loadImage(examInfo.file)} />
                            </div>
                            <div style={{ width: 1, background: '#DFE1E8', margin: '18px 24px 24px' }} />
                            <div className='d-flex flex-column omr-wrap'>
                                <div className="display-flex-important study-room-input align-item-center f-w-600">
                                    <div>{'OMR 답안지'}</div>
                                    <button className={"exam-create-button"} style={{ marginLeft: 'auto', background: '#0F84F4', border: 'none' }} onClick={() => { checkAllAnswered() }}>
                                        {'제출하기'}
                                    </button>
                                </div>
                                <div className="omr-content">
                                    <div className='omr-data'>
                                        <div className='omr-header'>
                                            <div className='omr-no'>{'문제'}</div>
                                            <div style={{ width: 1, height: '100%', background: '#DFE1E8' }} />
                                            <div className='omr-answer'>
                                                <div>1</div>
                                                <div>2</div>
                                                <div>3</div>
                                                <div>4</div>
                                                <div>5</div>
                                            </div>
                                            <div style={{ width: 1, height: '100%', background: '#DFE1E8' }} />
                                            <div className='omr-rate'>
                                                <div>{'배점'}</div>
                                            </div>
                                        </div>
                                        <div className='omr-list'>
                                            {
                                                answerList.map((answer, index) => {
                                                    var selectedAnswers = answer.type === 1 ? answer.answer === ""? []: JSON.parse(answer.answer) : null;
                                                    const toggleAnswer = (answer) => {
                                                    if (selectedAnswers.includes(answer)) {
                                                      selectedAnswers = selectedAnswers.filter(
                                                        (item) => item !== answer
                                                      );
                                                    } else {
                                                      selectedAnswers.push(answer);
                                                    }
                                                    selectedAnswers = selectedAnswers.sort((a, b) => a - b);
                                                  };

                                                    const changeChoiceAnswer = (index, answer) => {
                                                        toggleAnswer(answer);
                                                        onChangeAnswer(index, JSON.stringify(selectedAnswers));
                                                    }


                                                    return <div key={index} className='omr-item'>
                                                        <div className='omr-no'>{(index + 1) + '번'}</div>
                                                        <div style={{ width: 1, height: '100%', background: '#DFE1E8' }} />
                                                        {
                                                            answer.type === 1 ?
                                                                <div className='omr-answer'>
                                                                    <button className={'check-item' + (selectedAnswers.includes(1) ? ' selected' : '')} onClick={() => { changeChoiceAnswer(index, 1) }} />
                                                                    <button className={'check-item' + (selectedAnswers.includes(2) ? ' selected' : '')} onClick={() => { changeChoiceAnswer(index, 2) }} />
                                                                    <button className={'check-item' + (selectedAnswers.includes(3) ? ' selected' : '')} onClick={() => { changeChoiceAnswer(index, 3) }} />
                                                                    <button className={'check-item' + (selectedAnswers.includes(4) ? ' selected' : '')} onClick={() => { changeChoiceAnswer(index, 4) }} />
                                                                    <button className={'check-item' + (selectedAnswers.includes(5) ? ' selected' : '')} onClick={() => { changeChoiceAnswer(index, 5) }} />
                                                                </div>
                                                                :
                                                                <div className='omr-answer'>
                                                                    <input value={answer.answer} onChange={(e) => { onChangeAnswer(index, e.target.value) }} />
                                                                </div>
                                                        }
                                                        <div style={{ width: 1, height: '100%', background: '#DFE1E8' }} />
                                                        <div className={'omr-rate'}>
                                                            <input value={answer.rate} disabled />
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {allAnsweredAlert &&
                        <SweetAlert showCancel
                        confirmBtnText={'제출'}
                        confirmBtnBsStyle="primary"
                        cancelBtnText={strings.cancel}
                        cancelBtnBsStyle="default"
                        title={
                            <div>
                                <span style={{color:"red"}}>{'모든 문제에 정답 체크를 하지 않으셨습니다.'}</span>
                                <br />
                                {'정말로 제출 하시겠습니까?'}
                            </div>
                        }
                        customClass="exam-save-alert"
                        onConfirm={() => {
                            setAllAnsweredAlert(false);
                            onCreate();
                        }}
                        onCancel={() => setAllAnsweredAlert(false)}
                        confirmBtnCssClass="alert-confirm"
                        cancelBtnCssClass="alert-cancel"
                    />
                    }
                    {(createAlert &&
                        <SweetAlert showCancel
                            confirmBtnText={'제출'}
                            confirmBtnBsStyle="primary"
                            cancelBtnText={strings.cancel}
                            cancelBtnBsStyle="default"
                            title={
                                <div>
                                    {'제출을 하면 수정이 불가능합니다.'}
                                    <br />
                                    {'정말로 제출 하시겠습니까?'}
                                </div>
                            }
                            customClass="exam-save-alert"
                            onConfirm={() => {
                                setCreateAlert(false);
                                onCreate();
                            }}
                            onCancel={() => setCreateAlert(false)}
                            confirmBtnCssClass="alert-confirm"
                            cancelBtnCssClass="alert-cancel"
                        />
                    )}
                </div>
            </div>
            </>): startExamAlert && (
                <SweetAlert
                    title={startExamAlert}
                    onConfirm={() => {
                        setStartExamAlert(false);
                        history.goBack();
                    }}
                    confirmBtnCssClass="alert-confirm"
                    confirmBtnText={'돌아가기'}
                    showCancel={false}
                >
                    {'시험 시작에 실패했습니다.'}
                </SweetAlert>
            )}
        </React.Fragment>
    );

}

export default withRouter(StartExam);
