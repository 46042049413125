import React from 'react';
import strings from "../../lang/strings";
import { barColorList, emoticonList } from "../../config/const";
import ProgressBar from "../../components/control/progress_bar/progress_bar";
import { getDecimal, getTimeFromSecond } from "../../helper/common";
import { withRouter } from "react-router-dom";
import MyScoreProgressBar from "../../components/control/progress_bar/my_score_progress_bar";

const MyScore = (props) => {
    const {
        realTimeToday,
        realTimeYesterday,
        efficiencyYesterday,
        goalAchYesterday,
        efficiency,
        goalAchToday,
        period,
        totalTimeToday,
        totalTimeYesterday,
    } = props;

    return (
        <div className="study-status m-t-30">
            <div className="row gy-3">
                <div className="p-r-0">
                    <div className="real-time display-flex-important justify-content-center box-shadow">
                        <div className="d-flex align-item-center">
                            <div className='my-score-time p-r-5'>"지금까지</div>
                            <div className='p-t-2'>{getTimeFromSecond(realTimeToday)}</div>
                            <div className='my-score-time p-l-5'>공부했어요"</div>
                        </div>
                    </div>
                </div>
                <div className="p-r-0">
                    <div className="achieve-percent position-relative justify-content-between box-shadow">
                        <div className="f-s-15 progress-number-right m-l-10">{strings.study_efficiency}</div>
                        <ProgressBar
                            percent={efficiency * 100}
                            color={barColorList[5]}
                            width={"60%"}
                            backgroundColor={"#f4f4f4"}
                            margin={"6px"}
                        />
                        <div className="f-s-15 progress-number">{getDecimal((efficiency * 100).toString()) + "%"}</div>
                    </div>
                </div>
                <div className="p-r-0">
                    <div className="lounge-compare box-shadow">
                        <div className="m-l-4 m-b-15">
                            <div className='d-flex'>
                                <div className='f-s-14 compare me-auto p-2'>{strings.comparison}</div>
                                <div className="d-flex align-item-center m-l-10 p-2">
                                    <div className="report-gray-dot" />
                                    <div className="f-s-14 compare">{strings.past_list[period]}</div>
                                </div>
                                <div className="d-flex align-item-center m-l-10 p-2">
                                    <div className="report-blue-dot" />
                                    <div className="f-s-14 compare">{strings.present_list[period]}</div>
                                </div>
                            </div>
                            
                        </div>
                        <div className="horizontal-divider" />
                        <div className='d-flex flex-column yesterdayAndToday'>
                            <MyScoreProgressBar
                                yesterday={getDecimal(realTimeYesterday.toString()) + strings._h}
                                yesterdayPercent={totalTimeYesterday != 0 ? realTimeYesterday / totalTimeYesterday * 100 : 0}
                                today={getDecimal((realTimeToday / 3600).toString()) + strings._h}
                                todayPercent={totalTimeToday != 0 ? realTimeToday / 3600 / totalTimeToday * 100 : 0}
                                indicator={strings.realTime}
                                margin={true}
                            />
                            <MyScoreProgressBar
                                yesterday={getDecimal((goalAchYesterday * 100).toString()) + "%"}
                                yesterdayPercent={goalAchYesterday * 100}
                                today={getDecimal((goalAchToday * 100).toString()) + "%"}
                                todayPercent={goalAchToday * 100}
                                indicator={strings.goal_ach}
                                margin={true}
                            />
                            <MyScoreProgressBar
                                yesterday={getDecimal((efficiencyYesterday * 100).toString()) + "%"}
                                yesterdayPercent={efficiencyYesterday * 100}
                                today={getDecimal((efficiency * 100).toString()) + "%"}
                                todayPercent={efficiency * 100}
                                indicator={strings.efficiency}
                                margin={true}
                            />

                        </div>
                    </div>
                </div>
                <div className="p-r-0">
                    <div className="quiz justify-content-center box-shadow">
                        <div className='flex-column align-item-center'>
                            <img className='d-flex' src={"/assets/image/img_chewchew_01.png"}/>
                            <div className="d-flex justify-content-center m-t-10">아직 준비중 입니다.</div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(MyScore);
