import React, { useState } from 'react';
import styled from 'styled-components';
import colors from '../design/colors';
import fonts from '../design/typography';
import SearchIcon from './icons/search';

interface IOption {
    label: string;
    onSelect: () => void;
}

interface ISearchBarProps {
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
    required?: boolean;
    fullWidth?: boolean;
    size?: 'small' | 'medium';
    width?: string;
    options?: IOption[];
    style?: React.CSSProperties;
}

const SearchBar = (props: ISearchBarProps) => {
    const { placeholder, value, onChange, disabled, error, required, width, size, options, style } = props;
    const [selectedOption, setSelectedOption] = useState<IOption | null>(null);
    const [isFocused, setIsFocused] = useState(false);

    return (
        <Container style={style}>
            <div style={{ position: 'absolute', left: '16px' }}>
                <SearchIcon color={isFocused ? colors.gray900 : colors.gray500} width="24px" height="24px" />
            </div>
            <Input
                type="text"
                value={value}
                width={width}
                onChange={(e) => {
                    onChange(e);
                    setSelectedOption(null);
                }}
                disabled={disabled}
                required={required}
                error={error}
                size={size}
                placeholder={placeholder}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                completed={selectedOption != null}
            />
            {options && options.length > 0 && selectedOption == null && (
                <DropdownContent>
                    {options.map((option, index) => (
                        <DropdownItem
                            key={index}
                            onClick={() => {
                                option.onSelect();
                                setSelectedOption(option);
                            }}
                        >
                            {highlightMatch(option.label, value)}
                        </DropdownItem>
                    ))}
                </DropdownContent>
            )}
        </Container>
    );
};

export default SearchBar;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
`;

const Input = styled.input<{size?: 'small' | 'medium', error?: boolean, completed?: boolean, width?: string}>`
    width: ${props => props.width ? props.width : '100%'};
    justify-content: space-between;
    height: 48px;
    border-radius: 8px;
    border-width: 1px;
    padding-right: 16px;
    padding-left: 50px;
    font-size: ${props => props.size === 'small' ? fonts.body3Regular.fontSize : fonts.label4Medium.fontSize};
    font-family: ${props => props.size === 'small' ? fonts.body3Regular.fontFamily : fonts.label4Medium.fontFamily};
    line-height: ${props => props.size === 'small' ? fonts.body3Regular.lineHeight : fonts.label4Medium.lineHeight};
    color: ${colors.gray900};
    border-color: ${props => props.error ? colors.warning : colors.gray100};
    border-width: 1px;
    border-style: solid;
    background-color: ${props => props.completed ? colors.gray50 : colors.white};

    &:disabled {
        background-color: ${colors.gray100};
        color: ${colors.gray400};
    }
    &:focus {
        border-color: ${colors.blue600};
    }
    &:completed {
        border-color: ${colors.blue600};
    }
`;

const DropdownContent = styled.div<{ fontSize?: string }>`
    position: absolute;
    background-color: ${colors.white};
    width: 100%;
    max-height: 132px;
    overflow: auto;
    z-index: 1;
    padding-left: 10px;
    border-radius: 10px;
    top: 64px;
    border: 1px solid ${colors.gray100};
    padding-right: 4px;

    &::-webkit-scrollbar {
        width: 5px; /* Adjust the width of the scrollbar */
        margin-right: 4px; /* Add space on the right side of the scrollbar */
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${colors.gray200}; /* Set the color of the scrollbar thumb */
        border-radius: 4px; /* Make the ends rounded */
    }
    &::-webkit-scrollbar-track {
        background: transparent; /* Make the track transparent */
    }
`

const DropdownItem = styled.div`
    padding: 10px 0;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const highlightMatch = (text: string, search: string) => {
    if (!search) return (
        <span style={{ ...fonts.body3Medium, color: colors.gray500}}>{text}</span>
    );
    
    const parts = text.split(new RegExp(`(${search})`, 'gi'));
    return (
        <span>
            {parts.map((part, index) => 
                part.toLowerCase() === search.toLowerCase() ? (
                    <span key={index} style={{ ...fonts.body3Medium, color: colors.gray900}}>
                        {part}
                    </span>
                ) : (
                    <span key={index} style={{ ...fonts.body3Medium, color: colors.gray500}}>{part}</span>
                )
            )}
        </span>
    );
};