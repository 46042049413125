import { inject, observer } from 'mobx-react';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Page_settings } from '../../../config/page_settings';
import BottomContainer from "./bottom_container";
import HeaderBlack from "../../../components/header/header_black";
import { getTimeFromSecond } from "../../../helper/common";
import { AGORA_APP_ID, SOCKET_URL } from "../../../config/const";
import RoomItem from "../../../components/control/item/room_item";
import ScreenSettingModal from "../../../components/control/modal/screen_setting_modal";
import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import strings from "../../../lang/strings";
import io, { Socket } from 'socket.io-client';
import { rootStore } from "../../../mobx/store";
import StudyMaterialModal from "../../../components/control/modal/study_material_modal";
import NewSettingModal from "../../../components/control/modal/new_setting_modal";
import RoomInfoModal from "../../../components/control/modal/room_info_modal";
import SweetAlert from "react-bootstrap-sweetalert";
import MemberInfoModal from "../../../components/control/modal/member_info_modal";
import '../room.scss'
import AgoraRTC from 'agora-rtc-sdk-ng';
import styled from 'styled-components';
import { ClientRole } from 'agora-rtc-sdk-ng/rtc-sdk_en';
import ChatRightContainer from './chat_right_container';
import PlannerRightContainer from './planner_right_container';
import MemberListRightContainer from './member_list_right_container';
import moment from 'moment';
import { set } from 'mobx';

const agoraEngine = AgoraRTC.createClient({ mode: "live", codec: "vp8" });

interface IRoomInfo {
    id: number;
    title: string;
    meeting_number: string;
    user: {
        id: number;
        name: string;
        video: "ON" | "OFF";
        speaker: "ON" | "OFF";
        mirror: "ON" | "OFF";
    };
}

interface IRoomMember {
    user: {
        id: number;
        name: string;
        video: "ON" | "OFF";
        speaker: "ON" | "OFF";
        mirror: "ON" | "OFF";
        profile: string;
        studyTime?: number;
        studyTimeThisMonth?: number;
        studyStartTime?: Date | null;
    };
    member: number;
    videoTrack?: any;
    audioTrack?: any;
    reaction?: IReaction;
}

interface IReaction {
    icon: string;
    desc: string;
}

interface IChat {
    sender: number;
    sender_name: string;
    sender_profile?: string;
    receiver?: number;
    receiver_name?: string;
    message: string;
    time: string;
    show_profile: boolean;
    show_time: boolean;
}

const StudyRoom = observer((props) => {
    const context: any = useContext(Page_settings);
    //본인정보
    const [profile, setProfile] = useState<any>("");

    //방정보
    const roomId = parseInt(props.match.params.id);
    const [memberList, setMemberList] = useState<IRoomMember[]>([]);
    const memberListRef = useRef(memberList);
    useEffect(() => {
        memberListRef.current = memberList;
    }, [memberList]);

    const [roomInfo, setRoomInfo] = useState<IRoomInfo>();

    //공부시간 관련
    const [studyStartTime, setStudyStartTime] = useState<Date| null>(null);
    const [studyTime, setStudyTime] = useState<number>(0);
    const [now, setNow] = useState<Date>(new Date());

    //setting 관련
    const [isUsingVideo, setisUsingVideo] = useState<boolean>(true)
    const [isUsingMic, setisUsingMic] = useState<boolean>(true)
    const [isMirrored, setisMirrored] = useState<boolean>(true)
    
    // reaction 관련
    const [reaction, setReaction] = useState<any>("");
    const [reactionTime, setReactionTime] = useState(0);

    // modal 관련
    const [showSettingModal, setShowSettingModal] = useState<boolean>(false);
    const [materialModalShow, setMaterialModalShow] = useState<boolean>(false);
    const [showChat, setShowChat] = useState<boolean>(false);
    const [showParticipant, setShowParticipant] = useState<boolean>(false);
    const [showPlanner, setShowPlanner] = useState<boolean>(false);
    const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
    const [memberInfo, setMemberInfo] = useState<any|null>();
    const [showSetting, setShowSetting] = useState(false);
    const [showRoomInfo, setShowRoomInfo] = useState(false);
    const [leaveAlert, setLeaveAlert] = useState(false);

    // agora 관련
    const [mics, setMics] = useState([]);
    const [curMic, setCurMic] = useState(null);
    const [cams, setCams] = useState([]);
    const [curCam, setCurCam] = useState(null);
    const [meetingNumber, setMeetingNumber] = useState('');
    const [meetingRole, setMeetingRole] = useState(2);
    const [localAudioTrack, setLocalAudioTrack] = useState(null);
    const [localVideoTrack, setLocalVideoTrack] = useState(null);
    const [isJoined, setIsJoined] = useState(false);
    const [settingFinished, setSettingFinished] = useState(false);

    // socket 관련
    const [socket, setSocket] = useState<null | Socket>(null);

    // chatting 관려
    const [chatList, setChatList] = useState<IChat[]>([]);
    const chatListRef = useRef(chatList);
    useEffect(() => {
        chatListRef.current = chatList;
    }, [chatList]);


    AgoraRTC.onMicrophoneChanged = async changedDevice => {
        // When plugging in a device, switch to a device that is newly plugged in.
        if (changedDevice.state === "ACTIVE") {
            localAudioTrack.setDevice(changedDevice.device.deviceId);
            // Switch to an existing device when the current device is unplugged.
        } else if (changedDevice.device.label === localAudioTrack?.getTrackLabel()) {
            const oldMicrophones = await AgoraRTC.getMicrophones();
            oldMicrophones[0] && localAudioTrack.setDevice(oldMicrophones[0].deviceId);
        }
    };

    AgoraRTC.onCameraChanged = async changedDevice => {
        // When plugging in a device, switch to a device that is newly plugged in.
        if (changedDevice.state === "ACTIVE") {
            localVideoTrack.setDevice(changedDevice.device.deviceId);
            // Switch to an existing device when the current device is unplugged.
        } else if (changedDevice.device.label === localVideoTrack?.getTrackLabel()) {
            const oldCameras = await AgoraRTC.getCameras();
            oldCameras[0] && localVideoTrack.setDevice(oldCameras[0].deviceId);
        }
    };

    useEffect(() => {
        context.handleSetPageHeader(false);
        context.handleSetPageSidebar(false);
        context.setContentBgType(0);
        const initDevices = async () => {
            const micList = await AgoraRTC.getMicrophones().catch(err => []);
            setMics(micList);
            setisUsingMic(rootStore.getProfile.speaker == "ON" && micList.length > 0);
    
            const cameraList = await AgoraRTC.getCameras().catch(err => []);
            setCams(cameraList);
            setisUsingVideo(rootStore.getProfile.video == "ON" && cameraList.length > 0);
        }
        initDevices().then(() => {
            setSettingFinished(true);
        })
        setProfile(rootStore.getProfile);
        setisMirrored(rootStore.getProfile.mirror == "ON");


        // 방의 정보를 가져온다.
        context.get(
            'room/detail',
            {
                id: roomId
            },
            response => {
                setRoomInfo(response.room)
                setMeetingNumber(response.room.meeting_number);
                setMeetingRole(response.room.user.id == rootStore.getProfile?.id ? 1 : 2);
            }
        );

        // 현재 방의 멤버 리스트를 가져온다.
        context.get(
            'room/getMemberList',
            {
                room: roomId,
                type: 1
            },
            response => {
                if (response.list.length !== 0) {
                    setMemberList(response.list.map(item => {
                        return {
                            user: item.user,
                            member: item.member,
                            videoTrack: null,
                            audioTrack: null,
                            reaction: null
                        }
                    }));
                    
                    const myInfo = response.list.find(item => item.member == parseInt(rootStore.getProfile.id));
                    if (myInfo) {
                        setisMirrored(myInfo.user.mirror == "ON");
                        setStudyTime(myInfo.studyTime ?? 0);
                    }
                    
                }
            }
        );

        if (!socket) {
            setSocket(io(SOCKET_URL, {
                withCredentials: true,
                auth: {
                    token: props.rootStore.token,
                }
            }));
        }

        // agoraEngine.on("user-published") 이벤트 핸들러
        agoraEngine.on("user-published", async (user, mediaType) => {
            const memberId = user.uid as number;
            await agoraEngine.subscribe(user, mediaType);
            setMemberList(prevList => {
                const existingMember = memberListRef.current.find(item => item.member === memberId);
                if (!existingMember) {
                    const newMember: IRoomMember = {
                        user: { id: memberId, name: memberId.toString(), profile: "" , video: "OFF", speaker: "OFF", mirror: "OFF"},
                        member: memberId,
                        videoTrack: mediaType === 'video' ? user.videoTrack : null,
                        audioTrack: mediaType === 'audio' ? user.audioTrack : null,
                        reaction: null
                    };
                    return [...prevList, newMember];
                } else {
                    return prevList.map(item => {
                        if (item.member === memberId) {
                            if (mediaType === 'video') {
                                return { ...item, videoTrack: user.videoTrack };
                            } else if (mediaType === 'audio') {
                                return { ...item, audioTrack: user.audioTrack };
                            }
                        }
                        return item;
                    });
                }
            });
        });

        // "user-unpublished" 이벤트를 한 번만 등록
        agoraEngine.on("user-unpublished", (user, mediaType) => {
            const memberId = user.uid as number;
            // 사용자가 언팔로우될 경우 트랙을 null로 설정
            setMemberList(prevList =>
                prevList.map(item =>
                    item.member === memberId
                        ? {
                            ...item,
                            videoTrack: mediaType === 'video' ? null : item.videoTrack,
                            audioTrack: mediaType === 'audio' ? null : item.audioTrack,
                        }
                        : item
                )
            );
        });
        
        const timer = setInterval(() => {
            setNow(new Date());
        }, 1000);
        
        return () => {
            context.setContentBgType(2);
            context.handleSetPageHeader(true);
            context.handleSetPageSidebar(true);

            clearInterval(timer);
            // Destroy the local audio and video tracks.
            if (localAudioTrack != null && localAudioTrack != '') {
                localAudioTrack.close();
            }

            if (localVideoTrack != null && localVideoTrack != '') {
                localVideoTrack.close();
            }

            if (agoraEngine != null) {
                // Leave the channel
                agoraEngine.leave();
                // Refresh the page for reuse
                window.location.reload();
            }
        }
    }, []);

    useEffect(() => {
        if (!socket) return;
        socket.connect();
        socket.on('connect', () => {
            socket.emit('add user', roomId);
        });

        socket.on('reconnect', () => {
            socket.emit('add user', roomId);
        });

        socket.on("kickout", data => {
            if (data.member === rootStore.getProfile.id) {
                props.history.push("/room/study")
                addNotification('warning', strings.kicked)
            }
        })

        socket.on('user joined', async ({room, user, member}) => {
            if (room !== roomId) return;

            setMemberList(prevList => {
                const existingMember = memberListRef.current.find(item => item.member === user.id);
                if (!existingMember) {
                    const newMember = {
                        user: user,
                        member: member,
                        videoTrack: null,
                        audioTrack: null,
                        reaction: null
                    };
                    return [...prevList, newMember];
                } else {
                    return prevList.map(item => {
                        if (item.member === user.id) {
                            return { ...item, user: user };
                        }
                        return item;
                    });
                }
            });
            
            if(user.id == rootStore.getProfile.id) {
                setStudyTime(user.studyTime ?? 0);
            }
        });

        socket.on('user left', ({room, user}) => {
            if(room !== roomId) return;
            setMemberList(prevList => prevList.filter((item, idx) => item.member !== user.id))
        });

        socket.on("study started", ({room, user, start_time}) => {
            if (room !== roomId) return;
            setMemberList(prev => {
                const newList = memberListRef.current.map((item, idx) => {
                    if (item.user.id == user) {
                        return {
                            ...item,
                            user: {
                                ...item.user,
                                studyStartTime: new Date(start_time)
                            }
                        };
                    }
                    return item;
                });
                return newList;
            });
        });
        socket.on("study ended", ({room, user, end_time}) => {
            if (room !== roomId) return;
            setMemberList(prev => {
                const newList = memberListRef.current.map((item, idx) => {
                    if (item.user.id == user) {
                        return {
                            ...item,
                            user: {
                                ...item.user,
                                studyTime: item.user.studyTime + Math.floor((new Date(end_time).getTime() - item.user.studyStartTime.getTime()) / 1000),
                                studyStartTime: null
                            }
                        };
                    }
                    return item;
                });
                return newList;
            });
        });
        socket.on('reaction', data => {
            setMemberList(prev => prev.map((item, idx) => {
                if (item.user.id == data.user_info.id) {
                    item.reaction = data.reaction
                }
                return item
            }));
        });
        
        socket.on('video', ({user, video, room }) => {
            if (room !== roomId) return;

            const member = memberListRef.current.find(item => item.member === user);
            if (member) {
                setMemberList(prev => prev.map((item, idx) => {
                    if (item.member === user) {
                        item.user.video = video ? "ON" : "OFF";
                    }
                    return item
                }));

                if (member.member == rootStore.getProfile.id) {
                    setisUsingVideo(video);
                }
            }
        });

        socket.on('speaker', ({user, speaker, room }) => {
            if (room !== roomId) return;

            const member = memberListRef.current.find(item => item.member === user);
            if (member) {
                setMemberList(prev => prev.map((item, idx) => {
                    if (item.member === user) {
                        item.user.speaker = speaker ? "ON" : "OFF";
                    }
                    return item
                }));

                if (member.member == rootStore.getProfile.id) {
                    setisUsingMic(speaker);
                }
            }
        });

        socket.on('cam mirror', ({user, reverse, room }) => {
            if (room !== roomId) return;
            const member = memberListRef.current.find(item => item.member === user);
            if (member) {
                setMemberList(prev => prev.map((item, idx) => {
                    if (item.member === user) {
                        item.user.mirror = reverse ? "ON" : "OFF";
                    }
                    return item
                }));
            }
        });

        socket.on("new message server", ({room, sender, sender_name, sender_profile, receiver, receiver_name, message, time}) => {
            if (room != roomId) {return;}
            // 받는 사람이 없거나, 받는 사람이 본인이거나, 보내는 사람이 본인이면 채팅 리스트에 추가
            if (!receiver || (receiver == rootStore.getProfile.id || sender == rootStore.getProfile.id)) {
                if (chatListRef.current.length === 0) {
                    setChatList([{
                        sender: sender,
                        sender_name: sender_name,
                        sender_profile: sender_profile,
                        ...(receiver && { receiver }),
                        ...(receiver_name && { receiver_name: receiver_name }),
                        message: message,
                        time: time,
                        show_profile: true,
                        show_time: true
                    }])
                } else {
                    const latest_chat = chatListRef.current[0];
                    const other_chats = chatListRef.current.slice(1);
                    const change_person = latest_chat.sender != sender;
                    // 사람이 변하거나 분이 변화하면 시간을 표시하고 분이 변하지 않으면 이전 메시지의 시간을 표시하지 않음
                    const show_time = (moment(latest_chat.time).minute() != moment(time).minute()) || change_person;
                    setChatList([{
                        sender: sender,
                        sender_name: sender_name,
                        sender_profile: sender_profile,
                        ...(receiver && { receiver }),
                        ...(receiver && { receiver_name: receiver_name }),
                        message: message,
                        time: time,
                        show_profile: change_person,
                        show_time: true
                    }, {... latest_chat, show_time: show_time}, ...other_chats])
                }
            }
        })

        socket.emit('video', { room: roomId, video: isUsingVideo });
        socket.emit('speaker', { room: roomId, speaker: isUsingMic });
        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.off('reconnect');
            socket.off('reaction');
            socket.off('kickout');
            socket.off("user joined");
            socket.off("user left");
            socket.off("study started");
            socket.off("study ended");
            socket.off("speaker");
            socket.off("video");
            socket.disconnect();
            setSocket(null);
        }
    }, [socket]);


    // Setting 은 ON 인데, 기기가 없어 OFF로 변경되는 경우, Setting을 OFF로 변경
    useEffect(() => {
        if (!settingFinished || !socket) return;

        onSettingConfirm(isUsingVideo, isUsingMic, isMirrored);
    },[settingFinished, socket])

    useEffect(() => {
        if (studyStartTime) {
            const timer = window.setInterval(() => {
                setStudyTime((prev) => prev + 1);
            }, 1000);
            return () => {
                window.clearInterval(timer);
            }
        }
    }, [studyStartTime])


    useEffect(() => {
        async function startMeeting(token) {
            const options = {
                appId: AGORA_APP_ID,
                channel: meetingNumber,
                token: token,
                uid: rootStore.getProfile.id,
                role: 'host' as ClientRole,
            };
    
            agoraEngine.setClientRole(options.role);
    
            // Join a channel
            await agoraEngine.join(options.appId, options.channel, options.token, options.uid)
            .then(() => setIsJoined(true));
        }
    
        if (meetingNumber !== "") {
            context.get(
                'room/getAgoraRtcToken',
                { channelName: meetingNumber },
                response => {
                    startMeeting(response.token);
                }
            );
        }
    }, [meetingNumber, meetingRole]); // `meetingRole` 의존성은 `useEffect` 안에서 사용되지 않음
    
    useEffect(() => {
    
        const settingAudioTrack = async() => {
            if (mics.length !== 0) {
                const audioTrackNow = await AgoraRTC.createMicrophoneAudioTrack().catch(() => null);
                const audioTrackLabel = audioTrackNow.getTrackLabel();
                const currentMic = mics.find(item => item.label === audioTrackLabel);    
                setCurMic(currentMic || mics[0]);
                if (!currentMic) audioTrackNow.setDevice(mics[0].deviceId);

                setLocalAudioTrack(audioTrackNow);
            }
        }

        settingAudioTrack();
    },[mics]);

    useEffect(() => {
        if (!localAudioTrack || !isJoined ) return;
        
        if(isUsingMic) {
            agoraEngine.publish(localAudioTrack);
        } else {
            agoraEngine.unpublish(localAudioTrack);
        }

    },[localAudioTrack, isUsingMic, isJoined]);

    useEffect(() => {
        const settingVideoTrack = async () => {
            if (cams.length !== 0) {
                const videoTrackNow = await AgoraRTC.createCameraVideoTrack().catch(() => null);
                const videoTrackLabel = videoTrackNow.getTrackLabel();
                const currentCam = cams.find(item => item.label === videoTrackLabel);
                setCurCam(currentCam || cams[0]);
                if (!currentCam) videoTrackNow.setDevice(cams[0].deviceId);
                setLocalVideoTrack(videoTrackNow);
            }
        }
        settingVideoTrack();
    },[cams])

    useEffect(() => {
        if (!localVideoTrack || !isJoined) return;

        if (isUsingVideo) {
            agoraEngine.publish(localVideoTrack);
            localVideoTrack.play('local-player');
        } else {
            agoraEngine.unpublish(localVideoTrack);
            localVideoTrack.stop('local-player');
        }
    }, [localVideoTrack, isUsingVideo, isJoined]);

    const onClickReaction = (item) => {
        socket.emit("reaction", { room: roomId, reaction: item });
        setReaction(item)
        setReactionTime(0);
    }

    useEffect(() => {
        if (reaction === "") return;
    
        const timer = setTimeout(() => {
            setReactionTime(prevTime => prevTime + 1);
        }, 1000);
    
        // `reactionTime`이 3이 되면 상태를 초기화하고 소켓 이벤트 전송
        if (reactionTime === 3) {
            clearTimeout(timer);
            setReactionTime(0);
            setReaction("");
            socket.emit("reaction", { room: roomId, reaction: null });
        }
    
        return () => clearTimeout(timer);
    }, [reaction, reactionTime]); 

    const addNotification = (notificationType, notificationTitle, notificationMessage="") => {
        Store.addNotification({
            title: notificationTitle,
            message: notificationMessage,
            type: notificationType,
            insert: 'top',
            container: 'top-right',
            dismiss: {
                duration: 1000,
            }
        });
    };

    const onSettingConfirm = async (video, speaker, reverse, camIdx = -1, micIdx = -1) => {
        if (curCam && camIdx !== -1 && curCam.label != cams[camIdx].label) {
            setCurCam(cams[camIdx]);
            await localVideoTrack.setDevice(cams[camIdx].deviceId);
        }

        if (curMic && micIdx !== -1 && curMic.label != mics[micIdx].label) {
            setCurMic(mics[micIdx]);
            await localAudioTrack.setDevice(mics[micIdx].deviceId);
        }

        context.post(
            'room/updateSetting',
            {
                video: video ? "ON" : "OFF",
                speaker: speaker ? "ON" : "OFF",
                mirror: reverse ? "ON" : "OFF",
            },
            response => {
                setScreenSetting(video, speaker, reverse);
                setShowSettingModal(false);
                rootStore.setScreenSetting(video, speaker, reverse)
            }
        );
    }

    const setScreenSetting = async (video, speaker, reverse) => {
        if (isUsingVideo != video && localVideoTrack) {
            if (video) {
                await agoraEngine.publish(localVideoTrack);
                localVideoTrack?.play('local-player');
            } else {
                await agoraEngine.unpublish(localVideoTrack);
                localVideoTrack?.stop('local-player');
            }
            socket.emit("video", { room: roomId, video: video });
            setisUsingVideo(video);
        }

        if (isUsingMic != speaker && localAudioTrack) {
            if (speaker) {
                await agoraEngine.publish(localAudioTrack);
            } else {
                await agoraEngine.unpublish(localAudioTrack);
            }
            socket.emit("speaker", { room: roomId, speaker: speaker});
            setisUsingMic(speaker);
        }

        if (isMirrored != reverse) {
            socket.emit("cam mirror", { room: roomId, reverse: reverse });
            setisMirrored(reverse);
        }
    }

    const onRemoveRoom = () => {
        context.post(
            'room/delete',
            {
                id: roomId
            },
            response => {
                props.history.push("/study")
            }
        );
    }

    const onInvite = () => {
        if (navigator.clipboard && window.isSecureContext) {
            // Clipboard API 사용
            navigator.clipboard.writeText(document.location.href)
                .then(() => {
                    addNotification('success', '', strings.url_copied);
                })
                .catch(err => {
                    console.error("URL 복사 실패:", err);
                });
        } else {
            const tempElem = document.createElement('textarea');
            tempElem.value = document.location.href;
            document.body.appendChild(tempElem);
            tempElem.select();
            document.execCommand("copy");
            document.body.removeChild(tempElem);
            addNotification('success', '', strings.url_copied)
        }
    }

    const memberCount = useMemo(() => {
        return memberList.length;
    }, [memberList]);

    const containerRef = useRef(null);
    const enterFullscreen = () => {
        if (containerRef.current) {
            // DOM 요소를 전체 화면으로 전환
            if (containerRef.current.requestFullscreen) {
                containerRef.current.requestFullscreen();
            } else if (containerRef.current.webkitRequestFullscreen) {
                // Safari 지원
                containerRef.current.webkitRequestFullscreen();
            } else if (containerRef.current.msRequestFullscreen) {
                // IE 지원
                containerRef.current.msRequestFullscreen();
            }
        }
    };

    return (
        <div ref={containerRef}>
            <HeaderBlack
                isUsingSetting={true}
                isStudying={studyStartTime !== null}
                studyTime={getTimeFromSecond(studyTime)}
                toggleIsStudying={() => {
                    if (!socket) return;
                    studyStartTime ? socket.emit("study end", {room:roomId}) : socket.emit('study start', { room: roomId });
                    setStudyStartTime(studyStartTime ? null : new Date());
                }}
                owner={roomInfo?.user?.name}
                roomTitle={roomInfo?.title}
                onHide={() => {
                    if (document.fullscreenElement) {
                        document.exitFullscreen();
                    } else {
                        enterFullscreen();
                    }
                }}
                isUsingMic={isUsingMic}
                toggleUsingMic={() => {
                    if (mics.length === 0) { return;} 
                    setisUsingMic(!isUsingMic)
                    onSettingConfirm(isUsingVideo, !isUsingMic, isMirrored);
                    socket.emit("speaker", { room: roomId, user: rootStore.getProfile.id, speaker: isUsingMic ? "OFF" : "ON" });
                }}
                isUsingVideo={isUsingVideo}
                toggleUsingVideo={() => {
                    if (cams.length === 0) { return;}
                    setisUsingVideo(!isUsingVideo)
                    onSettingConfirm(!isUsingVideo, isUsingMic, isMirrored);
                    socket.emit("video", { room: roomId, user: rootStore.getProfile.id, video: isUsingVideo ? "OFF" : "ON" });
                }}
                onMirror={() => {
                    if (cams.length === 0) { return;}
                    setisMirrored(!isMirrored)
                    onSettingConfirm(isUsingVideo, isUsingMic, !isMirrored);
                }}
            />
            <div className="display-flex-important study-room" style={{marginTop: document.fullscreenElement ? 70 : 0}}>
                <StudyRoomRow showRightContainer={showChat || showParticipant || showPlanner}>
                    <div className={"study-room-item study-room-selected" + (isMirrored ? " camera-reverse" : "")} id='local-player'
                        style={{ display: "flex", position: 'relative' }}>
                        {
                            !isUsingVideo &&
                            <div
                                className="width-100-percent height-full d-flex align-item-center justify-content-around" style={{ position: 'absolute', left: 0, top: 0 }}>
                                <img src={rootStore.getProfile.profile != null && rootStore.getProfile.profile != "" ? context.loadImage(rootStore.getProfile.profile) : "/assets/image/chewing_plus_logo.png"}
                                    style={{ width: 120, height: 120, borderRadius: 60, objectFit: 'cover' }}
                                    alt=""
                                />
                            </div>
                        }
                        {
                            reaction != "" &&
                            <div
                                className="width-100-percent height-full d-flex align-item-center justify-content-around" style={{ position: 'absolute', left: 0, top: 0 }}>
                                <img src={reaction} alt="" />
                            </div>
                        }
                    </div>
                    {
                        memberList
                        .filter((item) => item.user.id != profile.id)
                        .map((item, idx) => { 
                                return (
                                    <RoomItem
                                        key={idx}
                                        item={item}
                                        videoTrack={item.videoTrack}
                                        now={now}
                                    />
                                )
                        })
                    }
                </StudyRoomRow>
                {showChat && 
                    <ChatRightContainer 
                        memberList={memberList}
                        onSendChat={(message, receiver?, receiver_name?) => {
                            socket.emit('new message', { room: roomId, message: message, receiver, receiver_name, sender_name: rootStore.getProfile.name, sender_profile: rootStore.getProfile.profile });
                        }}
                        chatList={chatList}
                    />}
                {showParticipant && 
                    <MemberListRightContainer
                        roomId={roomId}
                        roomOwner={roomInfo?.user.id}
                        socket={socket}
                        memberList={memberList}
                        setShowParticipant={setShowParticipant}
                        setMemberInfo={setMemberInfo}
                        onInvite={onInvite}
                    />}
                {showPlanner && 
                    <PlannerRightContainer 
                        roomId={roomId} 
                        onClose={() => setShowPlanner(false)}
                    />}
                <BottomContainer
                    showChat={showChat}
                    showParticipant={showParticipant}
                    showPlanner={showPlanner}
                    participants={memberCount}
                    onMaterial={() => setMaterialModalShow(true)}
                    onClickReaction={(item) => onClickReaction(item)}
                    onOut={() => {
                        setLeaveAlert(true);
                    }}
                    onSetting={() => setShowSetting(true)}
                    onChat={() => {
                        setShowParticipant(false);
                        setShowPlanner(false);
                        setShowChat(!showChat);
                    }}
                    onParticipant={() => {
                        setShowChat(false);
                        setShowPlanner(false);
                        setShowParticipant(!showParticipant);
                    }}
                    onPlanner={() => {
                        setShowChat(false);
                        setShowParticipant(false);
                        if (profile.user_type === "STUDENT") {
                            setShowPlanner(!showPlanner);
                        }
                    }}
                    newChat={false}
                />
                {
                    reaction &&
                    <div className="reaction-alarm">
                        <div className="reaction-alarm-content">
                            <img src={reaction.icon} alt="" />
                            {reaction.desc}
                        </div>
                    </div>
                }
                <NewSettingModal
                    show={showSetting}
                    toggle={() => setShowSetting(!showSetting)}
                    mine={roomInfo?.user.id == parseInt(profile.id)}
                    onScreenSettings={() => {
                        setShowSetting(false);
                        setShowSettingModal(true);
                    }}
                    onRoomInfo={() => {
                        setShowSetting(false);
                        setShowRoomInfo(true);
                    }}
                    onRemove={() => {
                        setShowSetting(false);
                        setDeleteAlert(true);
                    }}
                    isMultiRoom={false}
                />
                <RoomInfoModal
                    show={showRoomInfo}
                    mine={roomInfo?.user.id == parseInt(profile.id)}
                    toggle={() => setShowRoomInfo(!showRoomInfo)}
                    onBack={() => {
                        setShowRoomInfo(false);
                        setShowSetting(true);
                    }}
                    roomInfo={roomInfo}
                />
                {memberInfo && memberInfo !== null &&
                    <MemberInfoModal
                        memberInfo={memberInfo}
                        setMemberInfo={setMemberInfo}
                        now={now}
                />}
                {
                    showSettingModal &&
                    <ScreenSettingModal
                        show={showSettingModal}
                        name={profile.name}
                        profile={profile}
                        cameraType={'전면'}
                        toggle={() => setShowSettingModal(!showSettingModal)}
                        close={() => setShowSettingModal(false)}
                        video={isUsingVideo}
                        speaker={isUsingMic}
                        reverse={isMirrored}
                        cams={cams}
                        mics={mics}
                        curCam={curCam}
                        curMic={curMic}
                        onConfirm={(video, speaker, reverse, camIdx, micIdx) => {
                            onSettingConfirm(video, speaker, reverse, camIdx, micIdx)
                        }}
                        onBack={() => {
                            setShowSettingModal(false);
                            setShowSetting(true);
                        }}
                    />
                }
                <StudyMaterialModal
                    show={materialModalShow}
                    close={() => setMaterialModalShow(false)}
                    toggle={() => setMaterialModalShow(!materialModalShow)}
                />
                {(leaveAlert &&
                    <SweetAlert showCancel
                        confirmBtnText={strings.leave}
                        confirmBtnBsStyle="primary"
                        cancelBtnText={strings.cancel}
                        cancelBtnBsStyle="default"
                        title={""}
                        customClass="alert-black"
                        onConfirm={() => props.history.push("/room/study")}
                        onCancel={() => setLeaveAlert(!leaveAlert)}
                        confirmBtnCssClass="alert-confirm"
                        cancelBtnCssClass="alert-cancel"
                    >
                        {'나가시겠습니까?'}
                    </SweetAlert>
                )}
                {(deleteAlert &&
                    <SweetAlert showCancel
                        confirmBtnText={'제거'}
                        confirmBtnBsStyle="primary"
                        cancelBtnText={strings.cancel}
                        cancelBtnBsStyle="default"
                        title={""}
                        customClass="alert-black alert-leave"
                        onConfirm={() => { onRemoveRoom() }}
                        onCancel={() => setDeleteAlert(!deleteAlert)}
                        confirmBtnCssClass="alert-confirm"
                        cancelBtnCssClass="alert-cancel"
                    >
                        {'룸을 제거하시겠습니까?'}
                    </SweetAlert>
                )}
            </div>
        </div>
    );
});

export default withRouter(inject('rootStore')(StudyRoom));

interface IStudyRoomProps {
    showRightContainer: boolean;
}

const StudyRoomRow = styled.div<IStudyRoomProps>`
    margin-top: -11px;
    margin-left: -30px;
    margin-right: ${props => props.showRightContainer ? "300px" : "0px"};
    width: 100%;
    max-height: calc(100vh - 134px);
    overflow-y: auto;
    height: 100vh;
    background: black;
    display: flex;
    flex-wrap: wrap;
`;