const PlatFomr_Sec2 = () => {
  return (
    <>
      <section class="wrap plat-sec2">
        <div class="inner">
          <div class="main-tit">
            <h2>
              자기주도학습의 첫걸음 <br />
              <span>함게 공부하며 몰입할 수 있는 공간</span>
            </h2>
          </div>
          <div class="box-layout">
            <div class="wid-100 box1">
              <div class="txt-box">
                <h6>스터디룸</h6>
                <h3>
                  얼마나 공부하고, <br />
                  얼마나 쉴까요?
                </h3>
                <p>
                  학생들의 학습데이터를 쌓고 관리해주며 <br />
                  최적화된 자습을 위한 환경을 제공합니다.
                </p>
              </div>
              <div class="img-box">
                <img src="/assets/image/landing_page/sub/plat-sec2-box1.png" alt="스터디룸" />
              </div>
            </div>
            <div class="wid-50 box2">
              <div class="txt-box">
                <h6>리얼타임</h6>
                <h3>
                  시간관리도 혼자서 <br />
                  쉽고 정확하게
                </h3>
                <p>
                  학생의 실제 공부시간을 측정하여 <br />
                  공부효율도를 나타냅니다.
                </p>
              </div>
              <div class="img-box">
                <img src="/assets/image/landing_page/sub/plat-sec2-box2.png" alt="리얼타임" />
              </div>
            </div>
            <div class="wid-50 box3">
              <div class="txt-box">
                <h6>AI 학습도우미</h6>
                <h3>
                  도움이 필요한 순간, <br />
                  AI 학습도우미를 만나보세요
                </h3>
                <p>
                  학습중 질문이 필요한 경우 질의응답 및 <br />
                  필요한 정보를 제공합니다.
                </p>
              </div>
              <div class="img-box">
                <img src="/assets/image/landing_page/sub/plat-sec2-box3.png" alt="학습 도우미" />
              </div>
            </div>
            <div class="wid-50 box4">
              <div class="txt-box">
                <h6>학습지원</h6>
                <h3>
                  학습 공간이 바뀌어도 <br />
                  걱정 마세요.
                </h3>
                <p>필요한 학습자료를 바로 찾아 사용하세요</p>
              </div>
              <div class="img-box">
                <img src="/assets/image/landing_page/sub/plat-sec2-box4.png" alt="실시간 소통" />
              </div>
            </div>
            <div class="wid-50 box5">
              <div class="txt-box">
                <h6>채팅</h6>
                <h3>
                  혼자 또 같이, <br />
                  우리는 모두 친구
                </h3>
                <p>
                  같이 공부하는 학생들과 상호작용하며 <br />
                  서로 동기부여를 해줄 수 있어요
                </p>
              </div>
              <div class="img-box">
                <img src="/assets/image/landing_page/sub/plat-sec2-box5.png" alt="채팅" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default PlatFomr_Sec2;
