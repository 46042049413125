import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Page_settings } from '../../config/page_settings';
import SidebarNavList from './sidebar_nav_list.jsx';
import { Link } from "react-router-dom";
import { rootStore } from '../../mobx/store.js';
import menus from '../../config/page_route';

const Sidebar = () => {
    const userType = rootStore.getProfile?.user_type ?? 'STUDENT';
    const updatedMenu = menus.map((menu) => {
        if (menu.path === '/class') {
          const updatedChildren = menu.children.map((child) => {
            switch (child.path) {
                case '/class/mission_list/:class_id':
                    return { ...child, path: `/class/mission_list/${sessionStorage.getItem('class_id')}` };
                case '/class/notification/:class_id':
                    return { ...child, path: `/class/notification/${sessionStorage.getItem('class_id')}` };
                case '/class/quiz/:class_id':
                    return { ...child, path: `/class/quiz/${sessionStorage.getItem('class_id')}` };
                case '/exam/:class_id': 
                    return { ...child, path: `/exam/${sessionStorage.getItem('class_id')}` };
                case '/class/attendance/:class_id':
                    return { ...child, path: `/class/attendance/${sessionStorage.getItem('class_id')}` };
                default:
                    return child;
            }

          });
          return { ...menu, children: updatedChildren };
        }

        if (menu.path === '/clinic') {
            return null;
        }

        if (menu.user_type && menu.user_type !== userType) {
            return null;
        }

        return menu;
      });

    return (
        <Page_settings.Consumer>
            {({ toggleMobileSidebar }) => (
                <React.Fragment>
                    <div id="sidebar" className={'sidebar custom-sidebar'}>
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle" onClick={toggleMobileSidebar} >
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                            <Link to="/" className="navbar-brand">
                                <img className="logo" src={'/assets/image/logo_chewing_plus.png'} alt="" />
                            </Link>
                        </div>
                        <PerfectScrollbar className="height-full" options={{ suppressScrollX: true }}>
                            <ul className="nav p-t-10">
                            {updatedMenu.map((menu, i) => (
                                <SidebarNavList
                                    data={menu}
                                />
                            ))}
                            </ul>
                        </PerfectScrollbar>
                    </div>
                    <div className="sidebar-mobile-dismiss" onClick={toggleMobileSidebar} />
                </React.Fragment>
            )}
        </Page_settings.Consumer>
    )
}

export default Sidebar;
