import { withRouter } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import { rootStore } from "../../mobx/store";
import { Page_settings } from "../../config/page_settings";
import CreateCurriculumStep0Modal from "./modal/create_curriculum_step0_modal";
import { useLocation } from 'react-router-dom';
import FinishCreateCurriculumModal from "./modal/finish_create_curriculum_modal";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';


const CustomizeCurriculum = (props) => {
    const context = useContext(Page_settings);
    const [openCreateRoadMap, setOpenCreateRoadMap] = useState(false);
    const [finishCreate,setFinishCreate] = useState(false)
    const location = useLocation();
    const history = useHistory();
    const [curriculum,setCurriculum] = useState([]);

    useEffect(()=>{
      loadCurriculumList();
    }, []);

    const loadCurriculumList = () => {
      context.get(
        'curriculum/getCurriculumList',
        {  
        },
        response => {
          if(response){
            setCurriculum(response.list)
          }
          
        }
      );
    }


    useEffect(() => {
      if (location.state && location.state.showAlert) {
        setFinishCreate(true)
      }
    }, [location]);
  

    const openCreateRoadmapModal = () => {
        setOpenCreateRoadMap(true)
      }

      const clickCurriculum = (id) => {
        history.push(`/curriculum/student_curriculum_info/${id}`);
      };
        


    return(
    <div class="main-content create-curriculum-lobby-container">
      
      <div class="create-curriculum-header">
        <div class="title">
          우리쌤 커리큘럼
        </div>

      </div>

      {finishCreate && (<FinishCreateCurriculumModal
          open={finishCreate}
          onClose={() => {
            setFinishCreate(false);
          }}
          
        />)}

      {openCreateRoadMap && (<CreateCurriculumStep0Modal
          open={openCreateRoadMap}
          onClose={() => {
            setOpenCreateRoadMap(false);
          }}
          
        />)}
      

        <div class="content-container">
        {curriculum.map((item,index)=> (
             <div className="content-box">
             <div className="content-header">
                 <div className="left">
                 <div className={`category blue ${item.color}`}/>
                 <div className="title">{item.title}</div>
                 </div>
             </div>
            
            {item.thumbnail != null ? 
              <img className="thumbnail"  src={ context.loadImage(item.thumbnail)} alt="" onClick={()=>clickCurriculum(item.id)}/>
            :
              <img className="thumbnail"  src="/assets/image/img_curriculum_default_thumbnail.png" alt="" onClick={()=>clickCurriculum(item.id)}/>
              
            }
             
         </div>
          ))}
        </div>
        

      {/* <div class="null-data create-btn">
          <div>+ 커리큘럼 만들기</div>
        </div> */}
      </div>    
    );




    
    
}
export default withRouter(CustomizeCurriculum);
