import React, { useContext, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import strings from "../../../lang/strings";
import "react-datetime/css/react-datetime.css";
import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import moment from "moment";
import { Page_settings } from "../../../config/page_settings";
import SweetAlert from "react-bootstrap-sweetalert";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { getMinuteFromSecond } from "../../../helper/common";
import styled from "styled-components";

const StartQuizModal = (props) => {
  const context = useContext(Page_settings);
  const [quizInfo, setQuizInfo] = useState(null);
  const [answerList, setAnswerList] = useState([{}]);
  const [time, setTime] = useState(0);
  const [submitAlert, setSubmitAlert] = useState(false);
  const [timerId, setTimerId] = useState(null);

  const quizId = props.quizId;
  const title = props.title;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [items, setItems] = useState(0);
  const handleNext = () => {
    if (currentIndex < answerList.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  useEffect(() => {
    getExamInfo();
    const timer = window.setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);

    return () => window.clearInterval(timer); // 컴포넌트 언마운트 시 타이머 정리
  }, []);

  useEffect(() => {
    const timer = window.setInterval(() => {
      setAnswerList((prevAnswerList) => {
        const updatedAnswerList = [...prevAnswerList];
        updatedAnswerList[currentIndex] = {
          ...updatedAnswerList[currentIndex],
          time: updatedAnswerList[currentIndex].time + 1,
        };
        return updatedAnswerList;
      });
    }, 1000);

    return () => window.clearInterval(timer);
  }, [currentIndex]);

  const getExamInfo = () => {
    context.post(
      "quiz/info",
      {
        id: quizId,
      },
      (response) => {
        const info = response.info;
        setQuizInfo(info);
        setTime(0);
        const answerData = info.answer_data;
        const answers = answerData.map((item, idx) => {
          const updatedItem = {
            ...item,
            answer: "",
          };

          return updatedItem;
        });
        setAnswerList(answers);
      }
    );
  };

  const checkCreateAvailable = () => {
    for (let idx = 0; idx < answerList.length; idx++) {
      if (
        answerList[idx].answer === undefined ||
        answerList[idx].answer === ""
      ) {
        return true; // 답변이 없는 경우 true 반환
      }
    }
    return false; // 모든 답변이 입력된 경우 false 반환
  };

  const onCreate = () => {
    let answer = JSON.stringify(answerList);

    context.post(
      "quiz/set_result",
      {
        quiz: quizId,
        time: parseInt(time),
        answer_data: answer,
      },
      (response) => {
      }
    );

    setSubmitAlert(false);
    props?.onClose();
  };

  const completeQuiz = () => {
    setSubmitAlert(true);
  };

  const onChangeAnswer = (index, answer) => {
    const newList = answerList.map((item, idx) => {
      if (idx === index) {
        const updatedItem = {
          ...item,
          answer: answer,
        };

        return updatedItem;
      }

      return item;
    });
    setAnswerList(newList);
  };

  const addNotification = (
    notificationType,
    notificationTitle,
    notificationMessage
  ) => {
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: "top-center",
      dismiss: {
        duration: 1000,
      },
    });
  };

  return (
    <Overlay>
      <ModalWrap>
        <React.Fragment>
          <div style={{ width: "100%", height: "100%" }}>
            <div
              className="main-content create-quiz"
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                className="d-flex flex-row flex-1"
                style={{ overflow: "hidden", justifyContent: "center" }}
              >
                <div
                  className="info-wrap"
                  style={{
                    flex: 3,
                    overflowY: "auto",
                    position: "relative",
                    height: "90%",
                  }}
                >
                  {/* <div onClick={()=>props.onClose()}>x</div>    */}
                  <div
                    className="display-flex-important align-top f-w-600"
                    style={{
                      marginTop: "30px",
                      height: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: "16px",
                        display: "flex",
                        flexDirection: "column",
                        padding: "0px 20px",
                        width: "100%",
                      }}
                    >
                      <div
                        className="quiz-top"
                        style={{ width: "80%", margin: "0 auto" }}
                      >
                        <div className="quiz-title">{title}</div>
                        <div
                          className="enter-time"
                          style={{
                            color: "#222222",
                            fontSize: 18,
                            fontWeight: 500,
                            position: "absolute",
                            left: "0",
                            border: "1px solid #DFE1E8",
                            borderRadius: "8px",
                            padding: "5px 15px",
                          }}
                        >
                          {getMinuteFromSecond(time)}
                        </div>
                        <div
                          className="enter-time"
                          style={{
                            color: "#222222",
                            fontSize: 18,
                            fontWeight: 500,
                            position: "absolute",
                            right: "0",
                            border: "1px solid #DFE1E8",
                            borderRadius: "8px",
                            padding: "5px 15px",
                          }}
                        >
                          {getMinuteFromSecond(answerList[currentIndex].time)}
                        </div>
                      </div>
                      <h1
                        style={{ margin: "30px auto", textAlign: "start" }}
                      >{`${currentIndex + 1}. ${
                        answerList[currentIndex].title
                      }`}</h1>
                      <div
                        className={`quiz-answer-box ${
                          answerList[currentIndex].type === 0
                            ? " subjective"
                            : answerList[currentIndex].type === 2
                            ? "ox-quiz-box"
                            : ""
                        }`}
                      >
                        {(answerList[currentIndex].type === 1 ||
                          answerList[currentIndex].type === 2) &&
                          answerList.length > 0 &&
                          answerList[currentIndex] &&
                          Object.keys(answerList[currentIndex].question).map(
                            (key) => (
                              <div key={key} className="quiz-answer">
                                <button
                                  style={{
                                    width: "30px",
                                    height: "30px",

                                    borderRadius: "50%",
                                    border:
                                      answerList[currentIndex].answer === key
                                        ? "2px solid black"
                                        : "1px solid grey",
                                    background:
                                      answerList[currentIndex].answer === key
                                        ? "black"
                                        : "white",
                                    color:
                                      answerList[currentIndex].answer === key
                                        ? "white"
                                        : "black",
                                  }}
                                  disabled={true}
                                >
                                  {key}
                                </button>
                                {answerList[currentIndex].type === 1 ? (
                                  <span>
                                    {answerList[currentIndex].question[key]}
                                  </span>
                                ) : null}
                              </div>
                            )
                          )}
                        {answerList[currentIndex].type === 0 && (
                          <input
                            className="quiz-input"
                            value={answerList[currentIndex].answer}
                            onChange={(e) => {
                              onChangeAnswer(currentIndex, e.target.value);
                            }}
                          />
                        )}
                      </div>
                      <div className={"quiz-btn-box"}>
                        <button
                          className="left-btn"
                          style={{ marginBottom: "none" }}
                          onClick={handlePrev}
                          disabled={currentIndex === 0}
                        >
                          <img
                            src={
                              currentIndex === 0
                                ? "/assets/image/icon_arrow_left_white.png"
                                : "/assets/image/icon_arrow_left_white.png"
                            }
                            alt=""
                          />
                        </button>
                        {/* <button onClick={()=>onChangeAnswer()}> */}
                        {/* </button> */}
                        <div
                          className="quiz-progress"
                          style={{
                            position: "unset",
                            border: "1px solid #222222",
                            padding: "5px 30px",
                          }}
                        >
                          {currentIndex + 1} / {answerList.length}
                        </div>

                        <button
                          className="right-btn"
                          onClick={handleNext}
                          disabled={currentIndex === answerList.length - 1}
                        >
                          <img
                            src={
                              currentIndex === 0
                                ? "/assets/image/icon_arrow_right_white1.png"
                                : "/assets/image/icon_arrow_right_white1.png"
                            }
                            alt=""
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex flex-column omr-wrap"
                  style={{ height: "90%" }}
                >
                  <div
                    className="display-flex-important study-room-input align-item-center f-w-600"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>{"OMR 답안지"}</div>
                    <button
                      className={
                        !checkCreateAvailable()
                          ? "quiz-create-button"
                          : "quiz-no-create-button"
                      }
                      disabled={checkCreateAvailable()}
                      onClick={() => completeQuiz()}
                    >
                      {"제출"}
                    </button>
                  </div>
                  <div className="omr-content">
                    <div className="omr-data">
                      <div className="omr-header">
                        <div className="omr-no">{"문제"}</div>
                        <div
                          style={{
                            width: 1,
                            height: "100%",
                            background: "#DFE1E8",
                          }}
                        />
                        <div className="omr-answer">
                          <div>1</div>
                          <div>2</div>
                          <div>3</div>
                          <div>4</div>
                          <div>5</div>
                        </div>
                      </div>
                      <div className="omr-list">
                        {answerList.map((answer, index) => {
                          return (
                            <div key={index} className="omr-item">
                              <div className="omr-no">{index + 1 + "번"}</div>
                              <div
                                style={{
                                  width: 1,
                                  height: "100%",
                                  background: "#DFE1E8",
                                }}
                              />
                              {answer.type === 1 ? (
                                <div className="omr-answer">
                                  <button
                                    className={
                                      "check-item" +
                                      (answer.answer === "1" ? " selected" : "")
                                    }
                                    onClick={() => {
                                      onChangeAnswer(index, "1");
                                    }}
                                  />
                                  <button
                                    className={
                                      "check-item" +
                                      (answer.answer === "2" ? " selected" : "")
                                    }
                                    onClick={() => {
                                      onChangeAnswer(index, "2");
                                    }}
                                  />
                                  <button
                                    className={
                                      "check-item" +
                                      (answer.answer === "3" ? " selected" : "")
                                    }
                                    onClick={() => {
                                      onChangeAnswer(index, "3");
                                    }}
                                  />
                                  <button
                                    className={
                                      "check-item" +
                                      (answer.answer === "4" ? " selected" : "")
                                    }
                                    onClick={() => {
                                      onChangeAnswer(index, "4");
                                    }}
                                  />
                                  <button
                                    className={
                                      "check-item" +
                                      (answer.answer === "5" ? " selected" : "")
                                    }
                                    onClick={() => {
                                      onChangeAnswer(index, "5");
                                    }}
                                  />
                                </div>
                              ) : answer.type === 0 ? (
                                <div className="omr-answer">
                                  <input
                                    value={answer.answer}
                                    onChange={(e) => {
                                      onChangeAnswer(index, e.target.value);
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className="omr-answer">
                                  <button
                                    className={
                                      "check-item" +
                                      (answer.answer === "O" ? " selected" : "")
                                    }
                                    onClick={() => {
                                      onChangeAnswer(index, "O");
                                    }}
                                  >
                                    O
                                  </button>
                                  <button
                                    className={
                                      "check-item" +
                                      (answer.answer === "X" ? " selected" : "")
                                    }
                                    onClick={() => {
                                      onChangeAnswer(index, "X");
                                    }}
                                  >
                                    X
                                  </button>
                                </div>
                              )}
                              <div
                                style={{
                                  width: 1,
                                  height: "100%",
                                  background: "#DFE1E8",
                                }}
                              />
                              {/* <div className={'omr-rate'}>
                                                            <input value={answer.rate} className={(omrError ? ' error' : '')} onChange={(e) => { onChangeRate(index, e.target.value) }} />
                                                        </div> */}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {submitAlert && (
            <SweetAlert
              showCancel
              confirmBtnText={"출제"}
              confirmBtnBsStyle="primary"
              cancelBtnText={strings.cancel}
              cancelBtnBsStyle="default"
              title={"퀴즈 제출"}
              customClass="exam-save-alert"
              onConfirm={() => onCreate()}
              onCancel={() => setSubmitAlert(false)}
              confirmBtnCssClass="alert-confirm"
              cancelBtnCssClass="alert-cancel"
            >
              <div>
                {"퀴즈를 제출 하시겠습니까?"}
                <br />
                {"제출 하시면 수정이 불가능합니다"}
              </div>
            </SweetAlert>
          )}
        </React.Fragment>
      </ModalWrap>
    </Overlay>
  );
};

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const ModalWrap = styled.div`
  width: 80%;
  height: 80%;
  border-radius: 15px;
  background-color: #fff;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  overflow: auto;
`;
const Button = styled.button`
  cursor: pointer;
  width: calc(100% - 80px);
  color: rgb(255, 255, 255);
  border-radius: 5px;
  border: 0px;
  padding: 15px;

  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-size: 16px;
  gap: 5px;

  position: absolute;
  bottom: 40px;
`;

export default withRouter(StartQuizModal);
