import React from "react";
import { IconType } from "../../type/icon_type";


const IconClass = ({width="24px", height="24px", color = "#73787E"}: IconType) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6667 20V13.263C14.6667 13.0396 14.573 12.8254 14.4063 12.6675C14.2396 12.5096 14.0135 12.4208 13.7778 12.4208H10.2222C9.98647 12.4208 9.76038 12.5096 9.59368 12.6675C9.42698 12.8254 9.33333 13.0396 9.33333 13.263V20M14.6667 20H18.2222C18.6937 20 19.1459 19.8226 19.4793 19.5067C19.8127 19.1908 20 18.7624 20 18.3157V10.7366C20.0001 10.4916 19.9437 10.2495 19.8349 10.0273C19.726 9.80503 19.5673 9.60796 19.3698 9.44982L13.1476 4.39789C12.8267 4.14096 12.4201 4 12 4C11.5799 4 11.1733 4.14096 10.8524 4.39789L4.63022 9.44982C4.43271 9.60796 4.27399 9.80503 4.16514 10.0273C4.0563 10.2495 3.99994 10.4916 4 10.7366V18.3157C4 18.7624 4.1873 19.1908 4.5207 19.5067C4.8541 19.8226 5.30628 20 5.77778 20H9.33333M14.6667 20H9.33333" stroke={color} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

const IconClassFilled = ({width="24px", height="24px", color = "#73787E"}: IconType) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path d="M14.6667 13.263V20H18.2222C18.6937 20 19.1459 19.8226 19.4793 19.5067C19.8127 19.1908 20 18.7624 20 18.3157V10.7366C20.0001 10.4916 19.9437 10.2495 19.8349 10.0273C19.726 9.80503 19.5673 9.60796 19.3698 9.44982L13.1476 4.39789C12.8267 4.14096 12.4201 4 12 4C11.5799 4 11.1733 4.14096 10.8524 4.39789L4.63022 9.44982C4.43271 9.60796 4.27399 9.80503 4.16514 10.0273C4.0563 10.2495 3.99994 10.4916 4 10.7366V18.3157C4 18.7624 4.1873 19.1908 4.5207 19.5067C4.8541 19.8226 5.30628 20 5.77778 20H9.33333V13.263C9.33333 13.0396 9.42698 12.8254 9.59368 12.6675C9.76038 12.5096 9.98647 12.4208 10.2222 12.4208H13.7778C14.0135 12.4208 14.2396 12.5096 14.4063 12.6675C14.573 12.8254 14.6667 13.0396 14.6667 13.263Z" fill={color} stroke={color} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export { IconClass, IconClassFilled };
