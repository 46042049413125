import React, { useContext, useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import strings from "../../../lang/strings";
import "./modal.scss";
import { Page_settings } from "../../../config/page_settings";
import PerfectScrollbar from "react-perfect-scrollbar";

const SignSchoolModal = (props) => {
  const context = useContext(Page_settings);
  const { show, toggle, onSearchDone } = props;
  const [schoolList, setSchoolList] = useState([]);
  const [keyword, setKeyword] = useState("");
  
  const defaultSchool = {
    name: "미정"
  }

  useEffect(() => {
    setKeyword("");
    }, [show]);

  const onChange = (v) => {
    setKeyword(v);
  };

  const onSearch = (input) => {
      context.get(
        "member/getSchoolList",
        {
          keyword: input,
        },
        (response) => {
          setSchoolList(response.list);
        });
    };
  
  const onKeyPress = (e) => {
    if(keyword.length < 2) {
        return;
    }
    if (e.key === "Enter") {
        onSearch(keyword);
    }
  }

  const onDelete = () => {
    setKeyword("");
  };

  const onConfirm = () => {
    onSearchDone(defaultSchool);
    toggle();
    return;
  };

  return (
    <Modal isOpen={show} toggle={() => toggle()} className="account-info-modal">
      <ModalBody className="p-30">
        <div className="d-flex m-b-20">
          <div className="m-l-10 f-s-20 f-w-600">{strings.school_search}</div>
          <div className="flex-grow-1" />
          <button className="modal-close-btn" onClick={() => toggle()}>
            <img
              src={"/assets/image/icon_close_black.png"}
              alt=""
              className="modal-close"
            />
          </button>
        </div>
        <div className="study-room-input m-b-20" style={{display: 'flex', alignItems: 'space-between'}}>
          <input
            placeholder={strings.enter_school_name}
            type={"text"}
            value={keyword}
            onChange={(e) => onChange(e.target.value)}
            onKeyDown={(e) => onKeyPress(e)}
            />      
            <div style={{display:'flex', position: 'absolute', marginTop: 22.5, marginLeft: 330, justifyContent:'flex-end', width:10}}>
                {keyword !== '' &&
                <button  onClick={() => onDelete()}>
                    <img src={"/assets/image/icon_close_black.png"} className="modal-close" alt=""/>
                </button>
                }   
                <button  onClick={() => onSearch(keyword)} disabled={keyword.length < 2} >
                    <img src={"/assets/image/icon_search_black.png"} alt=""/>
                </button>
            </div>
        </div>
        <PerfectScrollbar
          className="school-result"
          options={{ suppressScrollX: true }}
        >
          {schoolList.map((item, idx) => {
              return (
                <button
                  className="search-item"
                  key={idx}
                  onClick={() => {
                    onSearchDone({
                        name: item.schoolNm,
                        id: item.id
                    });
                    toggle();
                  }}
                >
                  <img src={"/assets/image/icon_search_black.png"} alt="" />
                  <div className={"d-flex"}>
                    {item.schoolNm.split("").map((char, charIdx) => {
                      let searchIdx = item.schoolNm
                        .toLowerCase()
                        .indexOf(keyword.toLowerCase());
                      return (
                        <div
                          className={
                            (searchIdx == -1
                              ? ""
                              : charIdx >= searchIdx &&
                                charIdx < searchIdx + keyword.length
                              ? "searched "
                              : "") + (char == " " ? "space-char" : "")
                          }
                          key={charIdx}
                        >
                          {char}
                        </div>
                      );
                    })}
                  </div>
                </button>
              );
            })}
        </PerfectScrollbar>
        <button
          className={
            "school-confirm-disable"
          }
          onClick={() => onConfirm()}
        >
          {"원하는 학교/학원이 없습니다"}
        </button>
      </ModalBody>
    </Modal>
  );
};

export default SignSchoolModal;
