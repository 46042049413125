import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";
import Button from "../../../components/button";
import Pencil from "../../../components/icons/pencil";
import { Page_settings } from "../../../config/page_settings";
import colors from "../../../design/colors";
import fonts from "../../../design/typography";
import { rootStore } from "../../../mobx/store";
interface CounselingLogDetail {
    id: number;
    classroom: number;
    counselingDate: string;
    name: string;
    birthday: string;
    gender: string;
    school: string;
    university: string;
    department: string;
    isGraduated: boolean;
    phone: string;
    email: string;
    studyCounseling: [
      {
        subject: string;
        content: string;
      }
    ],
    lifeCounseling: string;
    suggestion: string;
    familyInfo: [
      {
        name: string;
        phone: string;
        time: string;
        relationship: string;
      }
    ],
    isRegistered: boolean;
    grade: number;
    address: string;
    career: string;
}

const CounselingLogDetail: React.FC = observer(() => {
  const context: any = useContext(Page_settings);
  const classId = rootStore.getClassId;
  const { id } = useParams<{ id:  string }>();

  const [data, setData] = useState<CounselingLogDetail | null>(null);
  
  const navigate = useNavigate();

  useEffect(() => {
    context.get(
      `/class/${classId}/counseling-log/${id}`,
      {},
      (res: any) => {
        setData(res);
      }
    );
  }, [classId, id]);

  const handleEdit = () => {
    setUseEditConfirm(false);
    navigate(`/class/counseling-log/${id}/edit`);
  }

  const handleRegister = () => {
    context.post(
      `/class/${classId}/counseling-log/register`,
      {
        ids: [id],
      },
      (res: any) => {
        setData(prev => ({
          ...prev!,
          isRegistered: !prev!.isRegistered
        }));
        setUseRegisterConfirm(false);
      }
    );
  }

  const handleDelete = () => {
    context.delete(
      `/class/${classId}/counseling-log`,
      {ids: [id]},
      () => {
        navigate(`/class/counseling-log/list`);
      }
    );
  }

  const [useRegisterConfirm, setUseRegisterConfirm] = useState(false);
  const [useEditConfirm, setUseEditConfirm] = useState(false);
  const [useDeleteConfirm, setUseDeleteConfirm] = useState(false);
  if (!data) return <div>로딩중...</div>;

  return (
    <WholeWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 20,
        }}
      >
        <span style={{ ...fonts.title2SemiBold }}>상담 일지</span>
        <div style={{display: "flex", gap: 8}}>
          <Button onClick={() => setUseEditConfirm(true)} size="small" text="수정하기" width="69px" height="32px" buttonColor={colors.blue600} textColor={colors.white}/>
          <Button onClick={() => setUseDeleteConfirm(true)} size="small" text={"삭제하기"} width="69px" height="32px" buttonColor={colors.warning} textColor={colors.white}/>
        </div>
        
      </div>
      <FormWrapper>
        <SectionWrapper>
          <RowWrapper>
            <StatusBadge is_registered={data.isRegistered} onClick={() => setUseRegisterConfirm(true)}>{data.isRegistered ? "등록" : "미등록"}{<Pencil color={data.isRegistered ? "#1976D2" : "#D32F2F"} width="16px" height="16px"/>}</StatusBadge>
          </RowWrapper>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>상담 일자</SectionTitle>
          </RowWrapper>
          <RowWrapper>
            <FieldText style={{width: "25%", textAlign: "center"}}>{data.counselingDate}</FieldText>
          </RowWrapper>
        </SectionWrapper>

        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>이름 및 성별</SectionTitle>
          </RowWrapper>
          <RowWrapper>
            <FieldText style={{width: "calc(100% - 200px)"}}>{data.name}</FieldText>
            <SexButton isPressed={data.gender === 'M'}>남자</SexButton>
            <SexButton isPressed={data.gender === 'F'}>여자</SexButton>
          </RowWrapper>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper style={{ width: "100%" }}>
            <div style={{ width: "25%" }}>
              <RowWrapper style={{ marginBottom: "15px" }}>
                <SectionTitle>생년월일</SectionTitle>
              </RowWrapper>
              <RowWrapper>
                <FieldText style={{textAlign: "center", width: "100%"}}>{data.birthday}</FieldText>
              </RowWrapper>
            </div>
            <div style={{ width: "75%" }}>
              <RowWrapper style={{ marginBottom: "15px" }}>
                <SectionTitle>연락처</SectionTitle>
              </RowWrapper>
              <RowWrapper>
                <FieldText style={{width: "100%"}}>{data.phone}</FieldText>
              </RowWrapper>
            </div>
          </RowWrapper>
        </SectionWrapper>

        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>주소</SectionTitle>
          </RowWrapper>
          <RowWrapper>
            <FieldText style={{textAlign: "start", width: "100%"}}>{data.address}</FieldText>
          </RowWrapper>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>학교</SectionTitle>
          </RowWrapper>
          <RowWrapper>
            <FieldText style={{width: 290}}>{data.school}</FieldText>
            <FieldText style={{width: 190}}>{data.isGraduated ? `졸업` : `${data.grade}학년`}</FieldText>
          </RowWrapper>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>반수</SectionTitle>
          </RowWrapper>
          <RowWrapper>
            {data.university ? (
              <>
                <FieldText>{data.university}</FieldText>
                <FieldText>{data.department}</FieldText>
              </>
            ) : (
              <>
                <CheckBoxCustom checked={true} />
                <span style={{ display: "flex", alignItems: "center",}}>해당 없음</span>
              </>
            )}
          </RowWrapper>
        </SectionWrapper>

        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>이메일</SectionTitle>
          </RowWrapper>
          <RowWrapper>
            <FieldText style={{width: "100%"}}>{data.email}</FieldText>
          </RowWrapper>
        </SectionWrapper>

        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>가족 관계</SectionTitle>
          </RowWrapper>
          <TableCustom>
            <thead>
              <tr>
                <ColoredCell>관계</ColoredCell>
                <ColoredCell>이름</ColoredCell>
                <ColoredCell>연락처</ColoredCell>
                <ColoredCell>상담 가능 시간</ColoredCell>
              </tr>
            </thead>
            <tbody>
              {data.familyInfo.map((item, index) => (
                <tr key={index}>
                  <td>{item.relationship}</td>
                  <td><FieldText style={{width: "100%", textAlign: "start"}}>{item.name}</FieldText></td>
                  <td><FieldText style={{width: "100%", textAlign: "start"}}>{item.phone}</FieldText></td>
                  <td><FieldText style={{width: "100%", textAlign: "start"}}>{item.time}</FieldText></td>
                </tr>
              ))}
            </tbody>
          </TableCustom>
        </SectionWrapper>

        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>학습 상담</SectionTitle>
          </RowWrapper>
          <TableCustom>
            <thead>
              <tr>
                <ColoredCell style={{width: "20%"}}>과목</ColoredCell>
                <ColoredCell style={{width: "80%"}}>내용</ColoredCell>
              </tr>
            </thead>
            <tbody>
              {data.studyCounseling.map((item, index) => (
                <tr key={index}>
                  <td>{item.subject}</td>
                  <td>
                    <textarea disabled={true} style={{width: "100%", height: "100%", border: "none", outline: "none", resize: "none", backgroundColor: "#f8f9fa", borderRadius: "8px", padding: "16px", ...fonts.label4Medium}}>{item.content}</textarea>
                  </td>
                </tr>
              ))}
            </tbody>
          </TableCustom>
        </SectionWrapper>

        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>생활 상담</SectionTitle>
          </RowWrapper>
          <ContentBox>
            {data.lifeCounseling || '생활 상담 내용이 없습니다.'}
          </ContentBox>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>진로 사항</SectionTitle>
          </RowWrapper>
          <ContentBox>
            {data.career || '진로 사항이 없습니다.'}
          </ContentBox>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>건의 사항</SectionTitle>
          </RowWrapper>
          <ContentBox>
            {data.suggestion || '건의 사항이 없습니다.'}
          </ContentBox>
        </SectionWrapper>
      </FormWrapper>
      {useRegisterConfirm && <RegisterConfirmModal isRegistered={data.isRegistered} onClose={() => setUseRegisterConfirm(false)} onConfirm={handleRegister} />}
      {useEditConfirm && <EditConfirmModal onClose={() => setUseEditConfirm(false)} onConfirm={handleEdit} />}
      {useDeleteConfirm && <DeleteConfirmModal onClose={() => setUseDeleteConfirm(false)} onConfirm={handleDelete} />}
    </WholeWrapper>
  );
});

const WholeWrapper = styled.div`
  padding: 0 80px 50px 80px;
  height: 100%;
  overflow-y: auto;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 50px 250px;
  margin-top: 20px;
  border-radius: 20px;
  gap: 40px;
  position: relative;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const SectionTitle = styled.span`
  font-size: ${fonts.body2SemiBold.fontSize};
  line-height: ${fonts.body2SemiBold.lineHeight};
  font-family: ${fonts.body2SemiBold.fontFamily};
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

const FieldText = styled.span`
  font-size: ${fonts.label4Medium.fontSize};
  line-height: ${fonts.label4Medium.lineHeight};
  font-family: ${fonts.label4Medium.fontFamily};
  color: ${colors.gray900};
  padding: 12px 16px;
  background-color: ${colors.gray50};
  border-radius: 8px;
  min-width: 100px;
  display: inline-block;
`;

const TableCustom = styled.table`
  border-collapse: collapse;
  width: 100%;
  & td, th {
    text-align: center;
    vertical-align: middle;
    height: 45px;
    padding: 8px 10px;
    font-size: 14px;
    border-bottom: 1px solid #E9EBED;
  }
`;

const ColoredCell = styled.td`
  background-color: #f5f7fa;
`;

const ContentBox = styled.div`
  padding: 16px;
  background-color: #f8f9fa;
  border-radius: 8px;
  min-height: 100px;
  white-space: pre-wrap;
  line-height: 1.5;
`;

const SexButton = styled.div<{ isPressed: boolean }>`
  width: 80px;
  height: 50px;
  border: 1px solid #e9ebed;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${(props) => (props.isPressed ? "#0F84F4" : "#ffffff")};
  color: ${(props) => (props.isPressed ? "#ffffff" : "#73787E")};
`;

const CheckBoxCustom = styled.input.attrs({ type: 'checkbox' })`
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #e9ebed;
  cursor: pointer;
  appearance: none;
  background-color: #ffffff;
  margin: 0;
  
  &:checked {
    background-color: #0F84F4;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4L4.5 7.5L11 1' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    border-color: #0F84F4;
  }

  &:hover {
    border-color: #0F84F4;
  }
`;

const StatusBadge = styled.span<{ is_registered: boolean }>`
  padding: 10px 16px;
  border-radius: 8px;
  font-size: 14px;
  background-color: ${(props) => (props.is_registered ? "#E3F2FD" : "#FFE0E0")};
  color: ${(props) => (props.is_registered ? "#1976D2" : "#D32F2F")};
  gap: 2px;
  cursor: pointer;
`;


const RegisterConfirmModal = ({ isRegistered, onClose, onConfirm }: { isRegistered: boolean, onClose: () => void, onConfirm: () => void }) => {
  return (
    <Modal size="sm" isOpen={true} toggle={() => {}}>
      <ModalBody style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 10}}>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", ...fonts.body2Medium, marginTop: 30}}>
            <span style={{color: isRegistered ? "#1561CE" : "#EE4C69" , margin: "0 4px"}}>{isRegistered ? "등록" : "미등록"}</span>
            <span>{` 에서 `}</span>
            <span style={{color: isRegistered ? "#EE4C69" : "#1561CE" , margin: "0 4px"}}>{isRegistered ? "미등록" : "등록"}</span>
            <span>{` 으로 `}</span>
        </div>
        <span style={{...fonts.body2Medium, marginBottom: 32}}>등록 상태를 변경하시겠습니까?</span>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 10}}>
          <Button onClick={onClose} size="small" text="취소" width="112px" height="40px" buttonColor={colors.gray100} textColor={colors.gray400}/>
          <Button onClick={onConfirm} size="small" text="변경" width="112px" height="40px" buttonColor={colors.blue600} textColor={colors.white}/>
        </div>
      </ModalBody>
    </Modal>
  )
}

const DeleteConfirmModal = ({onClose, onConfirm}: {onClose: () => void, onConfirm: () => void}) => {
  return (
    <Modal size="sm" isOpen={true} toggle={() => {}}>
      <ModalBody style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 10}}>
        <span style={{...fonts.body2Medium, marginBottom: 32, marginTop: 40}}>상담일지를 삭제하시겠습니까?</span>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 10}}>
          <Button onClick={onClose} size="small" text="취소" width="112px" height="40px" buttonColor={colors.gray100} textColor={colors.gray400}/>
          <Button onClick={onConfirm} size="small" text="삭제" width="112px" height="40px" buttonColor={colors.warning} textColor={colors.white}/>
        </div>
      </ModalBody>
    </Modal>
  )
}

const EditConfirmModal = ({onClose, onConfirm}: {onClose: () => void, onConfirm: () => void}) => {
  return (
    <Modal size="sm" isOpen={true} toggle={() => {}}>
      <ModalBody style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 10}}>
        <span style={{...fonts.body2Medium, marginBottom: 32, marginTop: 40}}>상담일지를 수정하시겠습니까?</span>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 10}}>
          <Button onClick={onClose} size="small" text="취소" width="112px" height="40px" buttonColor={colors.gray100} textColor={colors.gray400}/>
          <Button onClick={onConfirm} size="small" text="수정" width="112px" height="40px" buttonColor={colors.blue600} textColor={colors.white}/>
        </div>
      </ModalBody>
    </Modal>
  )
}
export default CounselingLogDetail;