import React, {useState} from 'react';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import strings from "../../../lang/strings";
import moment from "moment";

const NotificationItem = (props) => {
    const [dropdownOpen, setDropDownOpen] = useState(false);
    const {item, onMark, onTurn, last} = props;

    const toggleSmall = () => {
        setDropDownOpen(!dropdownOpen)
    }

    return (
        <div className={("noti-item ") + (item.status == 1 ? "item-read " : "") + (last ? "m-b-40" : "")}>
            <img className="noti-profile" src={"/assets/image/chewing_plus_logo.png"} alt=""/>
            <div>
                <label className="noti-main">
                    <span className="f-s-15 f-w-600 noti-name text-ellipsis">{item.type == 1 ? '' : ''}</span>
                    <span className="f-s-15 f-w-400">{item.type == 1 ? item.content : strings._owner_open}</span>
                </label>
                <div className="noti-time">{moment(item.create_date).format("a HH.mm")}</div>
            </div>

            <Dropdown isOpen={dropdownOpen} toggle={() => toggleSmall()}
                      className="icon-noti more">
                <DropdownToggle tag="a">
                    <img src={"/assets/image/icon_more_horitonal_gray.png"} alt=""/>
                </DropdownToggle>
                <DropdownMenu className="media-list dropdown-menu plan-dropdown" tag="ul">
                    <DropdownItem onClick={() => {
                        onMark()
                    }} className="border-top">
                        {!item.status == 1 ? strings.mark_read : strings.delete}
                    </DropdownItem>
                    <DropdownItem onClick={() => {
                        onTurn()
                    }} className="border-bottom">
                        {strings.turn_off_noti}
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    );
}

export default NotificationItem;
