import React from 'react';
import { IconType } from "../../type/icon_type";

const SearchIcon = ({ width, height, color }: IconType) => {
    return (
        <svg width={width || "24"} height={height || "24"} viewBox="0 0 24 24" stroke={color} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 20L16.1778 16.1778M18.2222 11.1111C18.2222 15.0385 15.0385 18.2222 11.1111 18.2222C7.18375 18.2222 4 15.0385 4 11.1111C4 7.18375 7.18375 4 11.1111 4C15.0385 4 18.2222 7.18375 18.2222 11.1111Z" stroke={color} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export default SearchIcon;


