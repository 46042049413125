import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import ChewingTalkHeader from "./dropdown/chewingTalk_header.jsx";
import { Page_settings } from '../../../config/page_settings.js';
import { rootStore } from '../../../mobx/store.js';
import { set } from 'mobx';



const PaymentHeader = (props) => {
  const currentYear= new Date().getFullYear(); 
  const [saleOfYear, setSaleOfYear] = useState();

  const [paymentToken, setPaymentToken] =  useState(localStorage.getItem('payment_token') ? JSON.parse(localStorage.getItem('payment_token')).token : null);
  const [tokenTime, setTokenTime] =  useState(localStorage.getItem('payment_token') ? JSON.parse(localStorage.getItem('payment_token')).time : null)
  

  const context = useContext(Page_settings);

  const onBillSendModal = () => {
    context.handleBillSendModal(true);
  }

  useEffect(() => {
    getSaleOfYear();
  
  }, []);

  const tokenVerification = (tokenTime) => {
    // 현재 시각을 밀리초 단위로 얻음
  const currentTime = Date.now();

  // 주어진 시간 값과 현재 시각의 차이를 계산
  const difference = currentTime - tokenTime;

  // 차이가 지정된 시간 이상이면 true, 아니면 false 반환
  return difference >= 3600000;
  }


  const getSaleOfYear = () => {
    // 토큰 유효성 검사 후, 만료된 경우 새 토큰 발행
    if (tokenVerification(tokenTime)) {
      context.post(
        'payment/getToken',
        {},
        response => {
            rootStore.setPaymentToken(response);
            setPaymentToken(response);
            setTokenTime(Date.now());
            context.post(
              'payment/getSaleOfYear',
              {
                token : response,
                year: currentYear
                // mode: "management"
              },
              response => {
                if(!response){
                  return;
                }
                setSaleOfYear(response.toLocaleString())
              });  
        }
      )
    }else{
      context.post(
        'payment/getSaleOfYear',
        {
          token : paymentToken,
          year: currentYear
          // mode: "management"
        },
        response => {
          if(!response){
            return;
          }
          setSaleOfYear(response.toLocaleString())
        });  
    }
  }


  return (
    <div id="header" className={"header navbar-default"}>
      <div className="navbar-header">
        <Link to="/" className="navbar-brand">
          <img className="logo" src={'/assets/image/logo_chewing_plus.png'} alt="" />
        </Link>

          <button type="button" className="navbar-toggle" >
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>

      </div>

      <ul className="navbar-nav rank-info" >

      </ul>

      <ul className="navbar-nav rank-info navbar-right align-item-center year-sales">
        <div class="left">{currentYear} 매출</div>
        <div class="right">{saleOfYear ? saleOfYear : '0'}원</div>
      </ul>

      <ul className="navbar-nav navbar-right align-item-center bill-send-btn" onClick={()=>onBillSendModal()}>
        <img src='/assets/image/icon_payment_send_bill.png' alt='' />
        청구서 발송
      </ul>

    </div>
  )
}

export default PaymentHeader;