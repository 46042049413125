import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../components/button";
import CloudUploadModal from "../components/control/modal/cloud_upload_modal";
import { ChevronDown, ChevronUp } from "../components/icons/chevron";
import IconDownload from "../components/icons/download";
import IconFolder from "../components/icons/folder";
import IconTrashCan from "../components/icons/trash_can";
import IconX from "../components/icons/x";
import SearchBar from "../components/search_bar";
import { Page_settings } from "../config/page_settings";
import colors from "../design/colors";
import fonts from "../design/typography";
import strings from "../lang/strings";
import PDFViewer from "../components/pdf_viewer";
import ImageViewer from "../components/image_viwer";
import { getFilePath } from "../utils/image";
interface SortType {
    name: string;
    order: string;
    dir:  "asc" | "desc";
}

const sortTypes: Record<string, SortType> = {
    "이름순": { name: "이름순", order: "name", dir: "asc" },
    "최신순": { name: "최신순", order: "create_date", dir: "desc" },
    "오래된순": { name: "오래된순", order: "create_date", dir: "asc" },
};

interface FileItem {
    id: string;
    name: string;
    size: number;
    url: string;
    createDate: string;
}

interface FileViewer {
    canHandle(fileName: string): boolean;
    view(url: string, name: string, toggle: () => void): React.ReactNode;
}

class PdfViewer implements FileViewer {
    canHandle(fileName: string): boolean {
        return fileName.toLowerCase().endsWith('.pdf');
    }

    view(url: string, name: string, toggle: () => void): React.ReactNode {
        return <PDFViewer file={url} name={name} toggle={toggle} />;
    }
}

class Imageviewer implements FileViewer {
    private readonly SUPPORTED_FORMATS = ['jpg', 'jpeg', 'png', 'gif'];

    canHandle(fileName: string): boolean {
        const ext = fileName.split('.').pop()?.toLowerCase();
        return ext ? this.SUPPORTED_FORMATS.includes(ext) : false;
    }

    view(url: string, name: string, toggle: () => void): React.ReactNode {
        return <ImageViewer file={url} name={name} toggle={toggle} />;
    }
}

class FileViewerFactory {
    private static viewers: FileViewer[] = [
        new PdfViewer(),
        new Imageviewer()
    ];

    static getViewer(fileName: string): FileViewer | null {
        return this.viewers.find(viewer => viewer.canHandle(fileName)) || null;
    }
}

const Cloud = (props) => {
    const context: any = useContext(Page_settings);
    const [sortType, setSortType] = useState(sortTypes.이름순);
    const [keyword, setKeyword] = useState("");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [fileList, setFileList] = useState<FileItem[]>([]);
    const [selectedItemList, setSelectedItemList] = useState<FileItem[]>([]);
    const [showCloudUpload, setShowCloudUpload] = useState(false);
    const [pageNum, setPageNum] = useState(1);
    const [searchStatus, setSearchStatus] = useState(false);
    const [viewingFile, setViewingFile] = useState<FileItem | null>(null);
    
    useEffect(() => {
        context.get(
            "cloud",
            {
                ...((keyword !== ""  && keyword.length > 2) && {keyword: keyword}),
                page: pageNum,
                order: sortType.order,
                dir: sortType.dir,
            },
            response => {
                setFileList(response.list)
            }
        );
    }, [keyword, sortType,pageNum,searchStatus]);

    const getImagePath = (name: string) => {
        let ext = name.split(".")[name.split(".").length - 1];
        let path = "/assets/image/icon_file_";
        if (ext == "docx") {
            path += "doc";
        } else {
            path += ext;
        }
        path += ".png";
        return path
    };

    const onClick = (item: FileItem) => {
        const selectedItemIdList = selectedItemList.map(it => it.id);
        if (selectedItemIdList.includes(item.id)) {
            setSelectedItemList(selectedItemList.filter(it => it.id !== item.id));
        } else {
            setSelectedItemList([...selectedItemList, item]);
        }
    };
    
    const onDownload = () => {
        const ids = selectedItemList.map(it => it.id);
        context.download(
            "POST",
            "cloud/download",
            { ids },
            selectedItemList.length == 1 ? selectedItemList[0].name : null
        );
    };

    const doRemove = () => {
        context.delete(
            "cloud",
            { fileIds: selectedItemList.map(it => it.id) },
            response => {
                setSelectedItemList([]);
                setSearchStatus(!searchStatus);
            }
        );
    };

    const getFileSize = (size: number) => {
        return size < 1024 ? "1" + strings._KB : (size < 1048576 ? (Math.round((size / 1024) * 10) / 10).toString() + strings._KB : (size < 1073741824 ? (Math.round((size / 1048576) * 10) / 10).toString() + strings._MB : (Math.round((size / 1073741824) * 10) / 10).toString() + strings._GB));
    }

    const renderFileViewer = useCallback(() => {
        if (!viewingFile) return null;
        const viewer = FileViewerFactory.getViewer(viewingFile.name);
        return viewer?.view(getFilePath(viewingFile.url), viewingFile.name, () => setViewingFile(null));
    }, [viewingFile]);

    return (
        <CloudContainer>
            <div style={{ display: "flex", flexDirection: "column", width: selectedItemList.length > 0 ? "calc(100% - 320px)" : "100%", marginRight: selectedItemList.length > 0 ? "24px" : "40px", marginTop: "24px", marginLeft: "40px"}}>
                <Header>
                    <span style={{ ...fonts.title2SemiBold, color: colors.gray900 }}>{"클라우드"}</span>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 8 }}>
                        <Button
                            onClick={() => setShowCloudUpload(true)}
                            text={"업로드"}
                            size="small"
                            width="66px"
                            height="36px"
                        />
                    </div>
                </Header>
                <TabWrapper>
                    <TabItem isActive={true}>
                        <span style={{ ...fonts.body3SemiBold, color: colors.gray900 }}>전체</span>
                    </TabItem>
                </TabWrapper>
                <SearchRowWrapper>
                    <SearchBar
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                        width="245px"
                        placeholder="파일을 검색해보세요"
                    />
                    <DropDownWrapper onClick={() => setDropdownOpen(!dropdownOpen)}>
                        <span style={{ ...fonts.label4Medium, color: colors.gray500 }}>{sortType.name}</span>
                        { dropdownOpen ?  <ChevronUp width={"16px"} height={"16px"} color={colors.gray500} /> : <ChevronDown width={"16px"} height={"16px"} color={colors.gray500} />}
                        {dropdownOpen && (
                            <DropdownMenu>
                                {Object.values(sortTypes).map((item, idx) => (
                                    <DropdownItem key={idx} onClick={() => setSortType(item)} style={{ borderTopLeftRadius: idx === 0 ? "8px" : "0px", borderTopRightRadius: idx === 0 ? "8px" : "0px", borderBottomLeftRadius: idx === Object.values(sortTypes).length - 1 ? "8px" : "0px", borderBottomRightRadius: idx === Object.values(sortTypes).length - 1 ? "8px" : "0px" }}><span style={{ ...fonts.label5Medium, color: colors.gray500 }}>{item.name}</span></DropdownItem>
                                ))}
                            </DropdownMenu>
                        )}
                    </DropDownWrapper>
                </SearchRowWrapper>
                <FileWrapper isVisible={fileList.length > 0}>
                    {fileList.map((item, index) => (
                        <FileItem key={index} item={item} onClick={onClick} isSelected={selectedItemList.includes(item)} />
                    ))}
                </FileWrapper>
            </div>
            {
                selectedItemList.length > 0 &&
                <RightTab>
                    {
                        selectedItemList.length == 1 &&
                        <>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" , marginBottom: "18px"}} onClick={() => setSelectedItemList([])} >
                                <div style={{ cursor: "pointer"}}>
                                <IconX width={"20px"} height={"20px"} color={colors.gray500}/> 
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" , marginBottom: "24px", backgroundColor: colors.gray50, width: "100%", height: "136px", borderRadius: "8px"}}>
                                <img src={getImagePath(selectedItemList[0].name)} alt="" width="64px" height="64px" />
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", gap: "24px", marginBottom: "25px"}}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                                    <div style={{ ...fonts.label5Medium, color: colors.gray500 ,width: "66px"}}>{"종류"}</div>
                                    <div style={{ ...fonts.label5Medium, color: colors.gray900 }}>{selectedItemList[0].name.split(".")[selectedItemList[0].name.split(".").length - 1] + strings._file}</div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                                    <div style={{ ...fonts.label5Medium, color: colors.gray500 ,width: "66px"}}>{"크기"}</div>
                                    <div style={{ ...fonts.label5Medium, color: colors.gray900 }}>{getFileSize(selectedItemList[0].size)}</div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                                    <div style={{ ...fonts.label5Medium, color: colors.gray500 ,width: "66px"}}>{"올린 날짜"}</div>
                                    <div style={{ ...fonts.label5Medium, color: colors.gray900 }}>{selectedItemList[0].createDate}</div>
                                </div>
                            </div>
                            <div style={{ width: "100%", height: "1px", backgroundColor: colors.gray100, marginBottom: "25px"}} />
                            <div style={{ display: "flex", flexDirection: "column", gap: "24px"}}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer"}} onClick={() => {
                                    setViewingFile(selectedItemList[0]);
                                }}>
                                    <IconFolder width={"24px"} height={"24px"} color={colors.gray500} style={{ marginRight: "8px"}} />
                                    <span style={{ ...fonts.label4Regular, color: colors.gray500 }}>파일 열기</span>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer"}} onClick={() => {onDownload()}}>
                                    <IconDownload width={"24px"} height={"24px"} color={colors.gray500} style={{ marginRight: "8px"}} />
                                    <span style={{ ...fonts.label4Regular, color: colors.gray500 }}>파일 다운로드</span>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer"}} onClick={() => doRemove()}>
                                    <IconTrashCan width={"24px"} height={"24px"} color={colors.gray500} style={{ marginRight: "8px"}} />
                                    <span style={{ ...fonts.label4Regular, color: colors.gray500 }}>클라우드에서 제거</span>
                                </div>
                            </div>
                        </>
                    }
                    {
                        selectedItemList.length > 1 &&
                        <>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" , marginBottom: "56px"}} onClick={() => setSelectedItemList([])} >
                                <span style={{ ...fonts.label3Medium, color: colors.gray600 }}>{selectedItemList.length}개 선택</span>
                                <div style={{ cursor: "pointer"}}>
                                <IconX width={"20px"} height={"20px"} color={colors.gray500}/> 
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", gap: "24px"}}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer"}} onClick={() => {onDownload()}}>
                                    <IconDownload width={"24px"} height={"24px"} color={colors.gray500} style={{ marginRight: "8px"}} />
                                    <span style={{ ...fonts.label4Regular, color: colors.gray800 }}>파일 다운로드</span>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer"}} onClick={() => doRemove()}>
                                    <IconTrashCan width={"24px"} height={"24px"} color={colors.gray500} style={{ marginRight: "8px"}} />
                                    <span style={{ ...fonts.label4Regular, color: colors.gray800 }}>클라우드에서 제거</span>
                                </div>
                            </div>
                        </>
                    }
                </RightTab>
            }

            <CloudUploadModal
                show={showCloudUpload}
                toggle={() => setShowCloudUpload(!showCloudUpload)}
                reload={() => setSearchStatus(!searchStatus)}
            />
            {renderFileViewer()}
        </CloudContainer>
    );

}

export default Cloud;


const FileItem = ({item, onClick, isSelected} : {item: FileItem, onClick: (FileItem) => void, isSelected: boolean}) => {
    const getImagePath = (name: string) => {
        let ext = name.split(".")[name.split(".").length - 1];
        let path = "/assets/image/icon_file_";
        if (ext == "docx") {
            path += "doc";
        } else {
            path += ext;
        }
        path += ".png";
        return path
    };
    
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 8 , width: "180px", height: "164px", borderRadius: "8px", padding: "16px" , backgroundColor: isSelected ? colors.blue50 : colors.white, border: isSelected ? `1px solid ${colors.blue600}` : "none"}} onClick={() => onClick(item)}>
            <div style={{ ...fonts.label5Medium, color: colors.gray900 }}>{item.name.length > 15 ? item.name.slice(0, 10) + "..." : item.name}</div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", backgroundColor: colors.gray50, width: "100%", height: "100%", borderRadius: "8px"}}>
                <img src={getImagePath(item.name)} alt="" width="64px" height="64px" />
            </div>
        </div>
    )
}


const CloudContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
`;

const Header = styled.div`
    width: 100%;
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 48px;
`;

const TabWrapper = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 32px;
`;

const TabItem = styled.div<{isActive: boolean}>`
    width: 72px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: ${props => props.isActive ? `2px solid ${colors.gray900}` : "none"};
`;

const SearchRowWrapper = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    
`;

const DropDownWrapper = styled.div`
    width: 74px;
    height: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: ${colors.gray500};
    position: relative;
    cursor: pointer;
`;

const DropdownMenu = styled.div`
    width: 100%;
    position: absolute;
    top: 28px;
    left: 0;
    background-color: ${colors.white};
    border-radius: 8px;
    border: 1px solid ${colors.gray200};
`;


const DropdownItem = styled.div`
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: ${colors.gray900};

    &:hover {
        background-color: ${colors.blue50};
    }


`;

const RightTab = styled.div`
    position: fixed;
    top: 80px;
    right: 0;
    width: 250px;
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    background-color: ${colors.white};
    padding: 16px 20px 0 20px;
`;

const FileWrapper = styled.div<{ isVisible: boolean }>`
    display: ${props => props.isVisible ? 'flex' : 'none'};
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 16px;
    gap: 16px 12px;
    flex-wrap: wrap;
`;
