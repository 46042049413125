import React, {useState, memo, useMemo, useCallback} from 'react';
import styled from 'styled-components';
import SubjectSelectModal from './modal/subject_select';
import { set } from 'mobx';

interface subject {
    type: "국어" | "수학" | "영어" | "사회" | "과학";
    name: string;
    time_1: number;
    rank_1: number; 
    time_2: number;
    rank_2: number;
    deletable: boolean;
}

const ReportScoreCalculator = () => {
    const [gradePercent, setGradePercent] = useState<number[]>([NaN, NaN, NaN]);
    const setPercent = (index: number, percent: number) => {
        const newGradePercent = [...gradePercent];
        newGradePercent[index] = percent;
        setGradePercent(newGradePercent);
    };

    const [firstGradeScore, setFirstGradeScore] = useState<subject[]>(
        [{type: "국어", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
        {type: "수학", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
        {type: "영어", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
        {type: "사회", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
        {type: "과학", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false}]);

    const [secondGradeScore, setSecondGradeScore] = useState<subject[]>(
        [{type: "국어", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "수학", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "영어", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "사회", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "과학", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false}]
    );

    const [thirdGradeScore, setThirdGradeScore] = useState<subject[]>(
        [{type: "국어", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "수학", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "영어", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "사회", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "과학", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false}]
    );
        
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [currentModalType, setCurrentModalType] = useState<subject["type"] | null>(null);
    const [currentModalGrade, setCurrentModalGrade] = useState<number | null>(null);
    const [currentIndex, setCurrentIndex] = useState<number | null>(null);

    const openModal = (type: subject["type"], grade: number, index: number) => {
        setCurrentModalType(type);
        setCurrentModalGrade(grade);
        setCurrentIndex(index);
        setIsModalOpen(true);
    };

    const handleConfirm = (selectedName: string) => {
        if (currentIndex !== null && currentModalType !== null) {
            if (currentModalGrade === 0) {
                const newSubjectList = [...firstGradeScore];
                newSubjectList[currentIndex] = {...newSubjectList[currentIndex], name: selectedName};
                setFirstGradeScore(newSubjectList);
            } else if (currentModalGrade === 1) {
                const newSubjectList = [...secondGradeScore];
                newSubjectList[currentIndex] = {...newSubjectList[currentIndex], name: selectedName};
                setSecondGradeScore(newSubjectList);
            } else if (currentModalGrade === 2) {
                const newSubjectList = [...thirdGradeScore];
                newSubjectList[currentIndex] = {...newSubjectList[currentIndex], name: selectedName};
                setThirdGradeScore(newSubjectList);
            }
        }
        setIsModalOpen(false);
    };

    const handleClose = () => {
        setIsModalOpen(false);
    };

    const [firstGradeScoreAverage, setFirstGradeScoreAverage] = useState<number>(NaN);
    const [secondGradeScoreAverage, setSecondGradeScoreAverage] = useState<number>(NaN);
    const [thirdGradeScoreAverage, setThirdGradeScoreAverage] = useState<number>(NaN);
    const [totalScore, setTotalScore] = useState<number>(NaN);

    const onCalculate = () => {
        const firstGradeScoreAverageCur = firstGradeScore.reduce((acc, cur) => {
            return acc + (cur.time_1 * cur.rank_1 + cur.time_2 * cur.rank_2);
        }, 0)/firstGradeScore.reduce((acc, cur) => {
            return acc + (cur.time_1 + cur.time_2);
        }, 0);
        setFirstGradeScoreAverage(firstGradeScoreAverageCur);
        
        const secondGradeScoreAverageCur = secondGradeScore.reduce((acc, cur) => {
            return acc + (cur.time_1 * cur.rank_1 + cur.time_2 * cur.rank_2);
        } , 0)/secondGradeScore.reduce((acc, cur) => {
            return acc + (cur.time_1 + cur.time_2);
        }, 0);
        setSecondGradeScoreAverage(secondGradeScoreAverageCur);

        const thirdGradeScoreAverageCur = thirdGradeScore.reduce((acc, cur) => {
            return acc + (cur.time_1 * cur.rank_1 + cur.time_2 * cur.rank_2);
        }, 0)/thirdGradeScore.reduce((acc, cur) => {
            return acc + (cur.time_1 + cur.time_2);
        }, 0);
        setThirdGradeScoreAverage(thirdGradeScoreAverageCur);

       setTotalScore((firstGradeScoreAverageCur * gradePercent[0] + secondGradeScoreAverageCur * gradePercent[1] + thirdGradeScoreAverageCur * gradePercent[2])/(gradePercent[0] + gradePercent[1] + gradePercent[2]));
    }

    const onReset = () => {
        setGradePercent([NaN, NaN, NaN]);
        setFirstGradeScore([{type: "국어", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "수학", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "영어", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "사회", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "과학", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false}]);
        setSecondGradeScore([{type: "국어", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "수학", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "영어", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "사회", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "과학", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false}]);
        setThirdGradeScore([{type: "국어", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "수학", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "영어", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "사회", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "과학", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false}]);
        setFirstGradeScoreAverage(NaN);
        setSecondGradeScoreAverage(NaN);
        setThirdGradeScoreAverage(NaN);
        setTotalScore(NaN);
    }

    return (
        <ReportScoreCalculatorWrapper>
            <span style={{fontSize: 30, fontWeight: "bolder"}}>내신계산기</span>
            <div style={{width: 5, height: 30}}/>
            <GradePercentRow grade={1} percent={gradePercent[0]} setPercent={(percent) => setPercent(0, percent)} />
            <GradePercentTable gradeIndex={0} subjectList={firstGradeScore} setSubjectList={(subjectList) => setFirstGradeScore(subjectList)} openModal={openModal}/>
            <GradePercentRow grade={2} percent={gradePercent[1]} setPercent={(percent) => setPercent(1, percent)} />
            <GradePercentTable gradeIndex={1} subjectList={secondGradeScore} setSubjectList={(subjectList) => setSecondGradeScore(subjectList)} openModal={openModal}/>
            <GradePercentRow grade={3} percent={gradePercent[2]} setPercent={(percent) => setPercent(2, percent)} />
            <GradePercentTable gradeIndex={2} subjectList={thirdGradeScore} setSubjectList={(subjectList) => setThirdGradeScore(subjectList)} openModal={openModal}/>
            <SubjectSelectModal 
                show={isModalOpen}
                type={currentModalType!}
                onConfirm={handleConfirm}
                onClose={handleClose}
            />
            <div style={{width: "100%", display: "flex", justifyContent: "center", marginTop: 20, marginBottom: 60}}>
                <button style={{width: 200, height: 50, backgroundColor: "#146DE3", color: "#FFFFFF", fontSize: 20, fontWeight: "bolder", borderRadius: 8}} onClick={onCalculate}>성적 산출하기</button>
                <button style={{width: 200, height: 50, backgroundColor: "#BCBCBC", color: "#FFFFFF", fontSize: 20, fontWeight: "bolder", borderRadius: 8, marginLeft: 20}}
                    onClick={onReset} 
                >다시 입력</button>
            </div>
            <ReportScoreResult firstGradeScore={firstGradeScoreAverage} secondGradeScore={secondGradeScoreAverage} thirdGradeScore={thirdGradeScoreAverage} totalScore={totalScore}/>
        </ReportScoreCalculatorWrapper>
    )
}

// Memoize GradePercentRow to prevent unnecessary re-renders
const GradePercentRow = memo((props: {
    grade: number;
    percent: number;
    setPercent: (percent: number) => void;
}) => {
    const { grade, percent } = props;
    return (
        <GradePercentRowWrapper>
            <div style={{display:"flex", justifyContent: "center", alignItems: "center", width: 65, fontSize: 18, fontWeight: "bolder"}}>{grade}학년</div>
            <Separtor />
            <input style={{width: 170, borderRadius: 4, fontSize: 15, paddingLeft: 20, height: "100%"}} placeholder='값을 입력하세요.' type="number" value={percent} onChange={(e) => props.setPercent(parseInt(e.target.value))} />
            <span style={{fontSize: 25, marginLeft: 20, fontWeight: "bolder"}}>%</span> 
        </GradePercentRowWrapper>
    )
});

// Memoize GradePercentTable to enhance performance
const GradePercentTable = memo((props: {
    gradeIndex: number;
    subjectList: subject[];
    setSubjectList: (subjectList: subject[]) => void;
    openModal: (type: subject["type"], grade: number, index: number) => void;
}) => {
    const {gradeIndex, subjectList, setSubjectList, openModal} = props;

    const addRowAfterIndex = (index: number, type:  "국어" | "수학" | "영어" | "사회" | "과학") => {
        const before = subjectList.slice(0, index + 1);
        const after = subjectList.slice(index + 1);
        setSubjectList([...before, {type: type, name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: true}, ...after]);
    }

    const deleteRowAtIndex = (index: number) => {
        const before = subjectList.slice(0, index);
        const after = subjectList.slice(index + 1);
        setSubjectList([...before, ...after]);
    }

    const setSubjectAtIndex = (index: number, new_subject:subject) => {
        const newSubjectList = [...subjectList];
        newSubjectList[index] = new_subject;
        setSubjectList(newSubjectList);
    }

    return (
        <Table>
            <tr>
                <th rowSpan={2}>교과</th>
                <th rowSpan={2} colSpan={2}>교과목</th>
                <th colSpan={2}>1학기</th>
                <th colSpan={2}>2학기</th>
                <th rowSpan={2}>추가/삭제</th>
            </tr>
            <tr>
                <th>이수단위</th>
                <th>과목등급</th>
                <th>이수단위</th>
                <th>과목등급</th>
            </tr>
            {subjectList.map((subject, index) => {
                return (
                    <tr key={index}>
                        <td>{subject.type}</td>
                        <td colSpan={2}>
                            <div style={{display: "flex", flexDirection: "row", position: "relative", padding: "10px 20px", height: "100%"}}>
                                <input type='text' style={{width: "100%", height: "100%", border:"1px solid #E0E0E0", paddingLeft: 10}}
                                    value={subject.name}
                                    onChange={(e) => setSubjectAtIndex(index, {...subject, name: e.target.value})}
                                />
                                <ButtonWrapper onClick={() => openModal(subject.type, gradeIndex, index)}>
                                    검색
                                </ButtonWrapper>
                            </div>
                        </td>
                        <td style={{padding: "10px 20px"}}><BoxInput type='number' placeholder='이수단위를 입력하세요.' value={subject.time_1}
                            onChange={(e) => setSubjectAtIndex(index, {...subject, time_1: parseInt(e.target.value)})}
                        /></td>
                        <td style={{padding: "10px 20px"}}><BoxInput type='number' placeholder='과목등급을 입력하세요.' value={subject.rank_1}
                            onChange={(e) => setSubjectAtIndex(index, {...subject, rank_1: parseInt(e.target.value)})}
                        /></td>
                        <td style={{padding: "10px 20px"}}><BoxInput type='number' placeholder='이수단위를 입력하세요.' value={subject.time_2}
                            onChange={(e) => setSubjectAtIndex(index, {...subject, time_2: parseInt(e.target.value)})}
                        /></td>
                        <td style={{padding: "10px 20px"}}><BoxInput type='number' placeholder='과목등급을 입력하세요.' value={subject.rank_2}
                            onChange={(e) => setSubjectAtIndex(index, {...subject, rank_2: parseInt(e.target.value)})}
                        /></td>
                        <td style={{padding: "10px 20px"}}>
                            <button 
                                style={{backgroundColor: "#55BEFF", color: "#FFFFFF", width: 75, height: "100%"}} 
                                onClick={() => addRowAfterIndex(index, subject.type)}
                            >
                                추가
                            </button>
                            {subject.deletable && 
                                <button style={{backgroundColor: "#FF5555", color: "#FFFFFF", width: 75, height: "100%"}} onClick={() => deleteRowAtIndex(index)}>삭제</button>
                            }
                        </td>
                    </tr>
                )    
                })
            }
        </Table>
    )
})

const ReportScoreResult = memo((props: {
    firstGradeScore: number;
    secondGradeScore: number;
    thirdGradeScore: number;
    totalScore: number;
}) => {
    const {firstGradeScore, secondGradeScore, thirdGradeScore, totalScore} = props;

    return (
        <div style={{position: "relative", width: "100%", display:"flex", flexDirection: "column", alignItems: "center"}}>
            <div style={{display:'flex', flexDirection: "row", width: "50%"}}>
                <div style={{width: "50%", padding: "0px 10px"}}>
                    <span style={{fontSize: 15, fontWeight: "bolder"}}>성적 산출</span>
                    <ResultTable>
                        <tr>
                            <th>학년</th>
                            <th>등급</th>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>{Number.isNaN(firstGradeScore) ? 0 : firstGradeScore.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>{Number.isNaN(secondGradeScore) ? 0 : secondGradeScore.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>{Number.isNaN(thirdGradeScore) ? 0 : thirdGradeScore.toFixed(2)}</td>
                        </tr>
                    </ResultTable>
                </div>
                <div style={{width: "50%", padding: "0px 10px"}}>
                    <span style={{fontSize: 15, fontWeight: "bolder"}}>합산등급 성적 산출</span>
                    <ResultTable>
                        <tr>
                            <th>학년(반영비율)</th>
                            <th>등급</th>
                        </tr>
                        <tr>
                            <td>1,2,3</td>
                            <td>{Number.isNaN(totalScore) ? 0 : totalScore.toFixed(2)}</td>
                        </tr>
                    </ResultTable>
                </div>
            </div>
        </div>
    )
});

export default ReportScoreCalculator;

const ReportScoreCalculatorWrapper = styled.div`
    margin: 0px 100px;
    padding-bottom: 70px;
`;

const GradePercentRowWrapper = styled.div`
    width: 320px;
    height: 65px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    background: #BCBCBC;
    color: #FFFFFF;
    padding: 10px 5px;
    border-radius: 8px;
    align-items: center;
`

const Separtor = styled.div`
    width: 5px;
    height: 100%;
    background: #FFFFFF;
    margin: 10px 0px;
    border-radius: 3px;
    margin: 0px 10px;
`

const Table = styled.table`
    width: 100%;
    border-radius: 8px;
    margin-top: 20px;
    margin-bottom: 50px;
    th, td {
        height: 50px;
        text-align: center;
        vertical-align: middle;
        border: 1px solid #E0E0E0;
    }
    th {
        font-size: 20px;
        font-weight: bold;
        color: #747474;
    }
    tr {
        background: #FFFFFF;
    }
    tr:nth-child(1), tr:nth-child(2) {
        background: #D6E8FF;
    }

`;



const ResultTable = styled.table`
    width: 100%;
    margin-top: 20px;
    margin-bottom: 50px;
    th, td {
        height: 50px;
        text-align: center;
        vertical-align: middle;
        border: 1px solid #E0E0E0;
    }
    th {
        font-size: 20px;
        font-weight: bold;
        color: #747474;
    }
    td {
        font-size: 18px;
        font-weight: bold;
        color: #747474;
    }
    tr {
        background: #FFFFFF;
    }
    tr:nth-child(1) {
        background: #D6E8FF;
    }
    tr:nth-child(2) {
        background: #E8F4FF;
    }
`;

const BoxInput = styled.input`
width: 100%;
height: 100%;
border: 1px solid #E0E0E0;
text-align: center;
font-size: 15px;
`

// Move ButtonWrapper outside to avoid recreation on each render
const ButtonWrapper = styled.div`
    position: absolute;
    top: 10px;
    right: 20px;
    padding: 0;
    font-size: 12px;
    cursor: pointer;
    width: 70px;
    height: calc(100% - 20px);
    background: #E0E0E0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;