import { observer } from 'mobx-react';
import moment from "moment";
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../../components/button';
import Checkbox from '../../components/checkbox';
import TextField from '../../components/text_field';
import { Page_settings } from "../../config/page_settings";
import colors from '../../design/colors';
import fonts from '../../design/typography';
import { rootStore } from '../../mobx/store';
interface IApplicationForResponse {
  id: number;
  classMember: number;
  name: string;
  gender: string;
  phone: string;
  guardianInfo: {
    name: string;
    time: string;
    phone: string;
    relationship: string;
  }[];
  email: string;
  koreanOption: string;
  koreanRank: number;
  mathOption: string;
  mathRank: number;
  englishRank: number;
  elective1Option: string;
  elective2Option: string;
  elective1Rank: number;
  elective2Rank: number;
  devices: {
    notebook: boolean;
    tablet: boolean;
    smartphone: boolean;
    others: string;
  };
  career: string;
  birthday: string;
  address: string;
  school: string;
  isGraduated: boolean;
  university: string;
  weakSubject: string;
  onlineClass: {
    subject: string;
    content: string;
    site: string;
  }[];
  etcInfo: null;
  createDate: string;
  grade: number;
  updateDate: null;
  historyRank: number;
  admissionReason: {
    internet: boolean;
    recommend: boolean;
    etc: string;
  };
  siblingsInfo: {
    name: string;
    phone: string;
    school: string;
    grade: number;
  }[];
  department: string;
}

const electiveOptionsDict = {
  "사회탐구": ["생활과 윤리", "윤리와 사상", "한국지리", "세계사", "경제", "동아시아사", "법과 정치", "사회문화", "세계지리"],
  "과학탐구": ["물리1", "화학1", "생명과학1", "지구과학1", "물리2", "화학2", "생명과학2", "지구과학2"],
};

const ApplicationForAdmissionDetail: React.FC = observer(() => {
  const context: any = useContext(Page_settings);
  const navigate = useNavigate();
  const [applicationData, setApplicationData] = useState<IApplicationForResponse | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const classId = rootStore.getClassId;
  useEffect(() => {
    context.get(`class/${classId}/class-application/${rootStore.getProfile?.id}`, {},
    (res: IApplicationForResponse) => {
      if (res === null) {
        navigate(`/class/application-for-admission`);
        return;
      }
      setApplicationData(res);
      if (electiveOptionsDict["사회탐구"].includes(res.elective1Option)) {
        setSelectedCategory("사회탐구");
      } else if (electiveOptionsDict["과학탐구"].includes(res.elective1Option)) {
        setSelectedCategory("과학탐구");
      }
    });
  }, [classId]);

  return (
    <WholeWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 20,
        }}
      >
        <span style={{ ...fonts.title3SemiBold }}>입학원서</span>
        <Button text={"수정하기"} onClick={() => navigate(`/class/application-for-admission`)} size="small" width="77px" height="36px" />
      </div>
      <FormWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>이름 및 성별</SectionTitle>
          </RowWrapper>
          <RowWrapper>
            <TextField
              value={ (applicationData?.name ?? "")}
              complete={true}
              size="small"
            />
            <SexButton
              isPressed={applicationData?.gender === "M"}
            >
              남자
            </SexButton>
            <SexButton
              isPressed={applicationData?.gender === "F"}
            >
              여자
            </SexButton>
          </RowWrapper>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper style={{ width: "100%" }}>
            <div style={{ width: "25%" }}>
              <RowWrapper style={{ marginBottom: "15px" }}>
                <SectionTitle>생년월일</SectionTitle>
              </RowWrapper>
              <RowWrapper>
                <TextField
                  value={moment(applicationData?.birthday).format("YYYY.MM.DD")}
                  complete={true}
                  size="small"
                />
              </RowWrapper>
            </div>
            <div style={{ width: "75%" }}>
              <RowWrapper style={{ marginBottom: "15px" }}>
                <SectionTitle>연락처</SectionTitle>
              </RowWrapper>
              <RowWrapper>
                <TextField
                  value={applicationData?.phone}
                  complete={true}
                  size="small"
                />
              </RowWrapper>
            </div>
          </RowWrapper>          
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>주소</SectionTitle>
          </RowWrapper>
          <RowWrapper>
            <TextField
              value={applicationData?.address}
              complete={true}
              size="small"
            />
          </RowWrapper>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>학교</SectionTitle>
          </RowWrapper>
          <RowWrapper>
            <TextField
              value={applicationData?.school}
              complete={true}
              size="small"
            />
            <TextField
              value={applicationData?.grade ? applicationData?.grade.toString() + "학년" : "졸업"}
              complete={true}
              size="small"
            />
          </RowWrapper>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>반수</SectionTitle>
          </RowWrapper>
          <RowWrapper>
            {applicationData?.university && <TextField
              value={applicationData?.university}
              complete={true}
            />}
            {applicationData?.department && <TextField
              value={applicationData?.department}
              complete={true}
            />}
            {applicationData?.university === null && applicationData?.department === null && 
            <>
            <Checkbox
              checked={true}
            />
            <span
              style={{ display: "flex", alignItems: "center", ...fonts.label4Medium, color: colors.gray900 }}
            >
              해당 없음
            </span>
            </>}
          </RowWrapper>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>이메일</SectionTitle>
          </RowWrapper>
          <RowWrapper>
            <TextField
              value={applicationData?.email}
              complete={true}
              size="small"
            />
          </RowWrapper>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>보호자 정보</SectionTitle>
          </RowWrapper>
          <TableCustom>
            <thead>
              <tr>
                <ColoredCell>관계</ColoredCell>
                <ColoredCell>이름</ColoredCell>
                <ColoredCell>연락처</ColoredCell>
                <ColoredCell>상담 가능 시간</ColoredCell>
              </tr>
            </thead>
            <tbody>
              {applicationData?.guardianInfo.map((item, index) => (
                <tr key={index}>
                  <td>
                    <span>{item.relationship}</span>
                  </td>
                  <td>
                    <TextField
                      value={item.name}
                      complete={true}
                      size="small"
                    />  
                  </td>
                  <td>
                    <TextField
                      value={item.phone}
                      complete={true}
                      size="small"
                    />
                  </td>
                  <td>
                    <TextField
                      value={item.time}
                      complete={true}
                      size="small"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </TableCustom>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>형제자매</SectionTitle>
          </RowWrapper>
          <TableCustom>
            <thead>
              <tr>
                <ColoredCell>관계</ColoredCell>
                <ColoredCell>이름</ColoredCell>
                <ColoredCell>연락처</ColoredCell>
                <ColoredCell>학교 및 학년</ColoredCell>
              </tr>
            </thead>
            <tbody>
              {applicationData?.siblingsInfo.map((item, index) => (
                <tr key={index}>
                  {index === 0 && <td rowSpan={applicationData?.siblingsInfo.length}>형제<br />자매</td>}
                  <td>
                    <TextField
                      value={item.name}
                      complete={true}
                    />
                  </td>
                  <td>
                    <TextField
                      value={item.phone}
                      complete={true}
                    />
                  </td>
                  <td>
                    <RowWrapper>
                      <TextField
                        value={item.school}
                        complete={true}
                      />
                      <TextField
                        value={item.grade.toString() + "학년"}
                        complete={true}
                      />
                    </RowWrapper>
                  </td>
                </tr>
              ))}
            </tbody>
          </TableCustom>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>수능 및 모의고사 성적</SectionTitle>
          </RowWrapper>
          <TableCustom>
            <tbody>
              <tr>
                <ColoredCell>국어</ColoredCell>
                <td>
                  <TextField
                    value={applicationData?.koreanOption}
                    complete={true}
                    size="small"
                  />
                </td>
                <td>
                  <RowWrapper style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <TextField
                      value={(applicationData?.koreanRank ?? "").toString()}
                      complete={true}
                      type="number"
                      width='80%'
                      size="small"
                    />
                    <span style={{...fonts.label4Medium, color: colors.gray900}}>등급</span>
                  </RowWrapper>
                </td>
              </tr>
              <tr>
                <ColoredCell>수학</ColoredCell>
                <td>
                  <TextField
                    value={applicationData?.mathOption}
                    complete={true}
                    size="small"
                  />
                </td>
                <td>
                  <RowWrapper style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <TextField
                      value={(applicationData?.mathRank ?? "").toString()}
                      complete={true}
                      type="number"
                      width='80%'
                      size="small"
                    />
                    <span style={{...fonts.label4Medium, color: colors.gray900}}>등급</span>
                  </RowWrapper>
                </td>
              </tr>
              <tr>
                <ColoredCell>영어</ColoredCell>
                <td>
                  <span> - </span>
                </td>
                <td>
                  <RowWrapper style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <TextField
                      value={(applicationData?.englishRank ?? "").toString()}
                      complete={true}
                      type="number"
                      width='80%'
                      size="small"
                    />
                    <span style={{...fonts.label4Medium, color: colors.gray900}}>등급</span>
                  </RowWrapper>
                </td>
              </tr>
              <tr>
                <ColoredCell>한국사</ColoredCell>
                <td>
                  <span> - </span>
                </td>
                <td>
                  <RowWrapper style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <TextField
                      value={(applicationData?.historyRank ?? "").toString()}
                      complete={true}
                      type="number"
                      width='80%'
                      size="small"
                    />
                    <span style={{...fonts.label4Medium, color: colors.gray900}}>등급</span>
                  </RowWrapper>
                </td>
              </tr>
              <tr>
                <ColoredCell>탐구1</ColoredCell>
                <td>
                  <RowWrapper>  
                    <TextField
                      value={selectedCategory}
                      complete={true}
                      size="small"
                    />
                    <TextField
                      value={applicationData?.elective1Option}
                      complete={true}
                      size="small"
                    />
                  </RowWrapper>
                </td>
                <td>
                  <RowWrapper style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <TextField
                      value={(applicationData?.elective1Rank ?? "").toString()}
                      complete={true}
                      width='80%'
                      size="small"
                    />
                    <span style={{...fonts.label4Medium, color: colors.gray900}}>등급</span>
                  </RowWrapper>
                </td>
              </tr>
              <tr>
                <ColoredCell>탐구2</ColoredCell>
                <td>
                  <RowWrapper>
                    <TextField
                      value={selectedCategory}
                      complete={true}
                      size="small"
                    />
                    <TextField
                      value={applicationData?.elective2Option}
                      complete={true}
                      size="small"
                    />
                  </RowWrapper>
                </td>
                <td>
                  <RowWrapper style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <TextField
                      value={(applicationData?.elective2Rank ?? "").toString()}
                     complete={true}
                      type="number"
                      width='80%'
                      size="small"
                    />
                    <span style={{...fonts.label4Medium, color: colors.gray900}}>등급</span>
                  </RowWrapper> 
                </td>
              </tr>
            </tbody>
          </TableCustom>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>취약 과목</SectionTitle>
          </RowWrapper>
          <RowWrapper>
            <TextField
              value={applicationData?.weakSubject ?? ""}
              complete={true}
              placeholder="내용을 입력해주세요"
            />
          </RowWrapper>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>수강한 인터넷 강의</SectionTitle>
          </RowWrapper>
          <TableCustom>
            <thead>
              <tr>
                <ColoredCell>과목</ColoredCell>
                <ColoredCell>내용</ColoredCell>
                <ColoredCell>사이트</ColoredCell>
              </tr>
            </thead>
            <tbody>
              {applicationData?.onlineClass.map((item, index) => (
                <tr key={index}>
                  <td>
                    <TextField
                      value={item.subject}
                      complete={true}
                    />
                  </td>
                  <td>
                    <TextField
                      value={item.content}
                      complete={true}
                    />
                  </td>
                  <td>
                    <TextField
                      value={item.site}
                      complete={true}
                    />
                  </td>
                </tr>
              ))}
              {applicationData?.onlineClass.length === 0 && <tr>
                <td colSpan={3}>
                  <span>추가된 강의가 없습니다.</span>
                </td>
              </tr>}
            </tbody>
          </TableCustom>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>특이 사항</SectionTitle>
          </RowWrapper>
          <TableCustom>
            <tbody>
              <tr>
                <ColoredCell>학습</ColoredCell>
                <td>
                  <TextField
                    value={JSON.parse(applicationData?.etcInfo ?? "{}").learning ?? ""}
                    complete={true}
                    placeholder="특정 신청 과목 등"
                  />
                </td>
              </tr>
              <tr>
                <ColoredCell>건강</ColoredCell>
                <td>
                  <TextField
                    value={JSON.parse(applicationData?.etcInfo ?? "{}").health ?? ""}
                    complete={true}
                    placeholder="아토피, 당뇨, 코골이 등"
                  />
                </td>
              </tr>
              <tr>
                <ColoredCell>생활</ColoredCell>
                <td>
                  <TextField
                    value={JSON.parse(applicationData?.etcInfo ?? "{}").lifeStyle ?? ""}
                    complete={true}
                    placeholder="아침형, 저녁형, 채식주의 등"
                  />
                </td>
              </tr>
            </tbody>
          </TableCustom>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>입학 계기</SectionTitle>
          </RowWrapper>
            {applicationData?.admissionReason?.internet && 
            <RowWrapper>
            <Checkbox 
              checked={Boolean(applicationData?.admissionReason?.internet)} 
              setChecked={() => {}} 
            />
            <TextSpan>{"인터넷 검색 (파워링크, 블로그, 유튜브 등)"}</TextSpan>
            </RowWrapper>
            }
            {applicationData?.admissionReason?.recommend && 
            <RowWrapper>
            <Checkbox checked={Boolean(applicationData?.admissionReason?.recommend)} setChecked={() => {}} />
            <TextSpan>{"주위 추천 (부모님, 친구, 선후배, 강사, 지인, 타 학원, 타 학습 사이트 등)"}</TextSpan>
            </RowWrapper>
            }
            {applicationData?.admissionReason?.etc && 
            <RowWrapper>
            <Checkbox checked={Boolean(applicationData?.admissionReason?.etc)} setChecked={() => {}} />
            <TextSpan>{"기타"}</TextSpan>
            <TextField
              placeholder="내용 입력"
              value={applicationData?.admissionReason?.etc ?? ""}
              complete={true}
              size="small"
              width="calc(100% - 80px)"
            />
            </RowWrapper>
            }
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>진로 계획</SectionTitle>
          </RowWrapper>
          <RowWrapper>
            <TextField
              value={applicationData?.career ?? ""}
              complete={true}
              placeholder="내용을 입력해주세요"
              helperText={"* 대학, 전공, 자사고, 특목고 진학 등의 계획을 작성해주세요"}
              size="small"
            />
          </RowWrapper>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>전자기기</SectionTitle>
          </RowWrapper>
          {applicationData?.devices?.notebook || applicationData?.devices?.tablet || applicationData?.devices?.smartphone || applicationData?.devices?.others && 
          <RowWrapper>
            {applicationData?.devices?.notebook && 
            <>
            <Checkbox checked={Boolean(applicationData?.devices?.notebook)}/>
            <TextSpan>{"노트북"}</TextSpan>
            </>
            }
            {applicationData?.devices?.tablet && 
            <>
            <Checkbox checked={Boolean(applicationData?.devices?.tablet)}/>
            <TextSpan>{"태블릿PC"}</TextSpan>
            </>
            }
            {applicationData?.devices?.smartphone && 
            <>
            <Checkbox checked={Boolean(applicationData?.devices?.smartphone)}/>
            <TextSpan>{"스마트폰"}</TextSpan>
            </>
            }
          </RowWrapper>
          }
          {applicationData?.devices?.others && 
          <RowWrapper>
          <Checkbox checked={Boolean(applicationData?.devices?.others)}/>
          <TextSpan>{"기타"}</TextSpan>
          <TextField
            placeholder="내용 입력"
            value={applicationData?.devices?.others ?? ""}
            complete={true}
            width="calc(100% - 80px)"
          />
          </RowWrapper>
          }
          {
            !applicationData?.devices?.notebook && !applicationData?.devices?.tablet && !applicationData?.devices?.smartphone && !applicationData?.devices?.others && 
            <RowWrapper>
            <Checkbox checked={true}/>
            <TextSpan>{"전자기기 없음"}</TextSpan>
            </RowWrapper>
          }
        </SectionWrapper>
      </FormWrapper>
    </WholeWrapper>
  );
});

const WholeWrapper = styled.div`
  padding: 0 80px 30px 80px;
  overflow-y: auto;
  height: 100%;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 50px 250px;
  margin-top: 20px;
  border-radius: 20px;
  gap: 40px;
  position: relative;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const SectionTitle = styled.span`
  font-family: ${fonts.body3SemiBold.fontFamily};
  font-size: ${fonts.body3SemiBold.fontSize};
  color: ${colors.gray900};
  line-height: ${fonts.body3SemiBold.lineHeight};
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
`;

const SexButton = styled.div<{ isPressed: boolean }>`
  width: 80px;
  height: 50px;
  border: 1px solid #e9ebed;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${(props) => (props.isPressed ? "#0F84F4" : "#ffffff")};
  color: ${(props) => (props.isPressed ? "#ffffff" : "#73787E")};
`;


const TableCustom = styled.table`
  border-collapse: collapse;
  width: 100%;
  & td, th {
    text-align: center;
    vertical-align: middle;
    height: 45px;
    padding: 8px 10px;
    font-size: 14px;
    border-bottom: 1px solid #E9EBED;
    border-top: 1px solid #E9EBED;
  }
`;

const ColoredCell = styled.td`
  background-color: #E9EBED;
  font-family: ${fonts.label4Medium.fontFamily};
  font-size: ${fonts.label4Medium.fontSize};
  color: ${colors.gray500};
  line-height: ${fonts.label4Medium.lineHeight};
`;

const TextSpan = styled.span`
  font-family: ${fonts.label4Medium.fontFamily};
  font-size: ${fonts.label4Medium.fontSize};
  color: ${colors.gray900};
  line-height: ${fonts.label4Medium.lineHeight};
`;

export default ApplicationForAdmissionDetail;
