import React, {useContext, useState} from "react";
import {Page_settings} from "../../../config/page_settings";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import { PiMicrophoneFill, PiMicrophoneSlashFill } from "react-icons/pi";
import { MdVideocam, MdVideocamOff } from "react-icons/md";
import strings from "../../../lang/strings";
import styled from "styled-components";
import { rootStore } from "../../../mobx/store";


interface IRoomMember {
    user: {
        id: number;
        name: string;
        video: "ON" | "OFF";
        speaker: "ON" | "OFF";
        mirror: "ON" | "OFF";
        profile?: string;
    };
    member: number;
    videoTrack?: any;
    audioTrack?: any;
    reaction?: any;
    studyTime?: number;
    studyTimeThisMonth?: number;
}

interface IParticipantItemProps {
    item: IRoomMember;
    onKick: () => void;
    index: number;
    selected: boolean;
    setSelectedParticipantIndex: (index: number) => void;
    setMemberInfo: (item: any) => void;
    isOwner: boolean;
    isMultiRoom: boolean;
    onVideoMute?: (user_id: number) => void;
    onAudioMute?: (user_id: number) => void;
}

const ParticipantItem = (props: IParticipantItemProps) => {
    const context: any = useContext(Page_settings)
    const {item, onKick, index, selected, setSelectedParticipantIndex, setMemberInfo, isOwner, isMultiRoom, onAudioMute, onVideoMute } = props;

    return (
        <ParticipantWrapper>
            <ProfileAndNameWrapper>
                <ProfileCircle>
                    <img className="profile"
                        src={(item.user.profile != null && item.user.profile != "") ? context.loadImage(item.user.profile) : "/assets/image/chewing_plus_logo.png"} alt="" 
                        width={25} height={25}/>
                </ProfileCircle>
                <NameWrapper>{item.user.name}</NameWrapper>
            </ProfileAndNameWrapper>
            <StatusDropdownWrapper>
                <div style={{marginRight: 15}}>
                                   {/* 방장으로 변화 필요 */}
                {(!isMultiRoom || (isOwner && item.member == rootStore.getProfile.id)) && 
                    (item.user.video === "ON" 
                    ? 
                    (isOwner && onVideoMute) ?  <button onClick={() => onVideoMute(item.member)}><MdVideocam color='white' size={18}/></button> : <MdVideocam color='white' size={18}/>
                    : 
                    <MdVideocamOff color='red' size={18}/>)}
                </div>
                <div style={{marginRight: 15}}>
                                    {/* 방장으로 변화 필요 */}
                {(!isMultiRoom || (isOwner && item.member == rootStore.getProfile.id)) && 
                    (item.user.speaker === "ON" 
                    ? 
                    (isOwner && onAudioMute) ? <button onClick={() => onAudioMute(item.member)}><PiMicrophoneFill color='white' size={18}/> </button>: <PiMicrophoneFill color='white' size={18}/>
                    : 
                    <PiMicrophoneSlashFill color='red' size={18}/>)}
                </div>
                 <Dropdown isOpen={selected} toggle={
                                    () => { 
                                        if (selected) {
                                            setSelectedParticipantIndex(-1);
                                        } else {
                                            setSelectedParticipantIndex(index);
                                        }}} className="participant-dropdown more">
                                    <DropdownToggle tag="a" style={{paddingLeft: 5, paddingRight: 5}}>
                                        <img src={"/assets/image/icon_more_gray.png"} alt=""/>
                                    </DropdownToggle>
                                    <DropdownMenu 
                                        className="media-list dropdown-menu plan-dropdown" 
                                        tag="ul" 
                                        style={{position: "absolute", left: -20 }}
                                        modifiers={{
                                            computeStyle: {
                                                gpuAcceleration: false
                                            }
                                        }}
                                    >
                                        <DropdownItem onClick={() => setMemberInfo(item)}>
                                            {strings.info}
                                        </DropdownItem>
                                        {
                                            (isOwner && (item.member != rootStore.getProfile.id)) &&
                                            <DropdownItem onClick={() => onKick()} className="kick-out">
                                                {strings.kick_out}
                                            </DropdownItem>
                                        }
                                    </DropdownMenu>
                </Dropdown> : <div style={{width: 10}}></div>
            </StatusDropdownWrapper>
        </ParticipantWrapper>
    );
}

export default ParticipantItem;

const ParticipantWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0px;
`;

const ProfileAndNameWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const ProfileCircle = styled.div`
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
`;

const NameWrapper = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;
    margin-left: 10px;
`;

const StatusDropdownWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px 10px 20px;
`