import React, { useContext, useEffect, useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from "styled-components";
import strings from "../../../lang/strings";

import { Page_settings } from '../../../config/page_settings';
import { rootStore } from "../../../mobx/store";
import { API_URL } from "../../../config/const";
import axios from "axios";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import moment from "moment";
import CalendarModal from '../../class/Modals/calendarModal';

function CreateQuizModal(props) {
  const history = useHistory();
  const title = props.title;
  const [wholeTextArray, setWholeTextArray] = useState([]);
  const [classValue, setClassValue] = useState('')
  const [isHaveInputValue, setIsHaveInputValue] = useState(false)
  const [clickInput, setClickInput] = useState(false)
  const [dropDownList, setDropDownList] = useState(wholeTextArray)
  const context = useContext(Page_settings);
  const limit_time = [];
  const [items, setItems] = useState(0);
  const [answerList, setAnswerList] = useState(props.answerList);
  const [currentIndex, setCurrentIndex] = useState(0);


  useEffect(() => {
    showDropDownList()
    setItems(answerList.length);
  }, [classValue, clickInput])


  const showDropDownList = () => {
    setDropDownList(wholeTextArray);
  };

  

  const handleNext = () => {
      if (currentIndex < answerList.length - 1) {
          setCurrentIndex(currentIndex + 1);
      }
  };

  const handlePrev = () => {
      if (currentIndex > 0) {
          setCurrentIndex(currentIndex - 1);
      }
  };

  const onChangeAnswer = (index, answer) => {
    const newList = answerList.map((item, idx) => {
        if (idx === index) {
            const updatedItem = {
                ...item,
                answer: answer,
            };

            return updatedItem;
        }

        return item;
    });
    setAnswerList(newList);
    // setAnswerList(newList);
}

  for (let hours = 0; hours < 24; hours++) {
    for (let minutes = 0; minutes < 60; minutes += 10) {
      const hourFormatted = hours.toString().padStart(2, '0');
      const minuteFormatted = minutes.toString().padStart(2, '0');
      limit_time.push({ time: `${hourFormatted}:${minuteFormatted}` });
    }
  }

  const completeQuiz = ()=>{
    props.getAnswerList(answerList);
    props?.onClose();
  }

  const reCreate = () => {
    console.log("recreate",currentIndex)
    console.log("answerData",answerList)
    context.post(
        'quiz/re_create_quiz',
        {
            course:props.course,
            subject:props.subject,
            unitData:props.unitData,
            type:answerList[currentIndex].type
        },
        response => {
            try {
                const parsedResponse = typeof response === 'string' ? JSON.parse(response) : response;
                

                // answerList 복사
                const updatedAnswerList = [...answerList];

                // currentIndex의 값을 parsedResponse로 대체
                updatedAnswerList[currentIndex] = parsedResponse;

                console.log("originalDAta",answerList)
                console.log("updateData",updatedAnswerList)

                 // setAnswerList 호출
                 setAnswerList(updatedAnswerList);
            } catch (error) {
                console.error("Error parsing response:", error);
                alert('다시 시도해주세요')
            }
            
        }
    );
  }

  return (
    <>
      <Overlay>
        <ModalWrap>
        <div style={{ width: '100%', height: '100%' }}>
            <div className="create-quiz" style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', position:'relative' }}>
                <img style={{position:'absolute',right:'0',top:'-1%', cursor:'pointer'}} src="/assets/image/icon_close_gray.png" alt="" onClick={props?.onClose}/>

                <div className="f-s-25 f-w-600 m-b-10 d-flex flex-row align-items-center">
                </div>

                <div className='d-flex flex-row flex-1' style={{ overflow: 'hidden', justifyContent: 'center' }}>
                    <div className='info-wrap' style={{ flex: 3, overflowY: 'auto', position: 'relative', height:'90%'  }}>
                        
                            <div className="display-flex-important align-top f-w-600" style={{marginTop:'30px', height:'70%',justifyContent:'center'}}> 
                            <div style={{ textAlign: 'center',fontSize: '16px',position:'relative', width: '80%' }}>
            <div className='quiz-top'>
                <div className='quiz-title'>{props.title}</div>
            </div>
            <h1 style={{margin:'60px auto',width:'80%',textAlign:'start'}}>{`${currentIndex + 1}. ${answerList[currentIndex].title}`}</h1>
            <div className={`quiz-answer-box ${answerList[currentIndex].type === 0 ? ' subjective': answerList[currentIndex].type === 2 ?'ox-quiz-box':''}`}>
                {(answerList[currentIndex].type === 1 || answerList[currentIndex].type === 2) && answerList.length > 0 && answerList[currentIndex] && Object.keys(answerList[currentIndex].question).map(key => (
                    <div key={key} className='quiz-answer'>
                        
                                                    <button
                                                    style={{
                                                        width: '30px',
                                                        height: '30px',
                                                        
                                                        borderRadius: '50%',
                                                        border: answerList[currentIndex].answer === key ? '2px solid black' : '1px solid grey',
                                                        background: answerList[currentIndex].answer === key ? 'black' : 'white',
                                                        color: answerList[currentIndex].answer === key ? 'white' : 'black'
                                                    }}
                                                    disabled={true}
                                                >
                                                    {key}
                                                </button>
                                                {answerList[currentIndex].type === 1 ?
                                                    <span>{answerList[currentIndex].question[key]}</span>
                                                    : null}
                                                

                    </div>
                ))}
                 {answerList[currentIndex].type === 0 &&
                            <input className='quiz-input' value={answerList[currentIndex].answer} onChange={(e) => { onChangeAnswer(currentIndex, e.target.value) }} />
                }
                  
            </div>
            <div className={'quiz-btn-box'} >
                <button className='left-btn' style={{marginBottom:'unset'}} onClick={handlePrev} disabled={currentIndex === 0}>
                    <img src={currentIndex === 0 ? '/assets/image/icon_arrow_left_white.png':'/assets/image/icon_arrow_left_white.png'} alt=''/>
                </button>
                <div className='flex-box'>
                <div className='quiz-progress'>{currentIndex+1} / {answerList.length}</div>
                <button className='regenerate-btn' onClick={reCreate}>현재 페이지 퀴즈 재생성</button>
                </div>
                <button className={`right-btn ${currentIndex === 0 ? 'disable': ''}`}  onClick={handleNext} disabled={currentIndex === answerList.length - 1}>
                <img src={currentIndex === 0?'/assets/image/icon_arrow_right_white1.png':'/assets/image/icon_arrow_right_white1.png'} alt=""/>
                </button>
            </div>
        </div>
                            </div>
                        
                    </div>
                    <div className='d-flex flex-column omr-wrap' style={{height: '90%'}}>
                        <div className="display-flex-important study-room-input align-item-center f-w-600" style={{justifyContent:'space-between'}}>
                            <div>{'OMR 답안지'}</div>
                            <button className={"quiz-create-button"} onClick={()=>completeQuiz()}>
                                {'완료'}
                            </button>
                        </div>
                        <div className="omr-content">
                            {
                                items == 0 ?
                                    <div className='omr-empty'>
                                        {'AI 자동 생성 기능은 채점이 자동으로 이루어집니다.'}
                                    </div>
                                    :
                                    <div className='omr-data'>
                                        <div className='omr-header'>
                                            <div className='omr-no'>{'문제'}</div>
                                            <div style={{ width: 1, height: '100%', background: '#DFE1E8' }} />
                                            <div className='omr-answer'>
                                                <div>1</div>
                                                <div>2</div>
                                                <div>3</div>
                                                <div>4</div>
                                                <div>5</div>
                                            </div>
                                        </div>
                                        <div className='omr-list'>
                                            {
                                                answerList.map((answer, index) => {
                                                    return <div key={index} className='omr-item'>
                                                        <div className='omr-no'>{(index + 1) + '번'}</div>
                                                        <div style={{ width: 1, height: '100%', background: '#DFE1E8' }} />
                                                        {
                                                            answer.type === 1 ? (
                                                                <div className='omr-answer'>
                                                                    <button className={'check-item' + (answer.answer === '1' ? ' selected' : '')} onClick={() => { onChangeAnswer(index, '1') }} />
                                                                    <button className={'check-item' + (answer.answer === '2' ? ' selected' : '')} onClick={() => { onChangeAnswer(index, '2') }} />
                                                                    <button className={'check-item' + (answer.answer === '3' ? ' selected' : '')} onClick={() => { onChangeAnswer(index, '3') }} />
                                                                    <button className={'check-item' + (answer.answer === '4' ? ' selected' : '')} onClick={() => { onChangeAnswer(index, '4') }} />
                                                                    <button className={'check-item' + (answer.answer === '5' ? ' selected' : '')} onClick={() => { onChangeAnswer(index, '5') }} />
                                                                </div>
                                                            ) : answer.type === 0 ? (
                                                                <div className='omr-answer'>
                                                                    <input value={answer.answer} onChange={(e) => { onChangeAnswer(index, e.target.value) }} />
                                                                </div>
                                                            ) : (
                                                                <div className='omr-answer'>
                                                                    <button className={'check-item' + (answer.answer === 'O' ? ' selected' : '')} onClick={() => { onChangeAnswer(index, 'O') }}>O</button>
                                                                    <button className={'check-item' + (answer.answer === 'X' ? ' selected' : '')} onClick={() => { onChangeAnswer(index, 'X') }}>X</button>
                                                                </div>
                                                            )
                                                        }
                                                        <div style={{ width: 1, height: '100%', background: '#DFE1E8' }} />
                                                        {/* <div className={'omr-rate'}>
                                                            <input value={answer.rate} className={(omrError ? ' error' : '')} onChange={(e) => { onChangeRate(index, e.target.value) }} />
                                                        </div> */}
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </ModalWrap>
      </Overlay>
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const ModalWrap = styled.div`
  width: 90%;
  height: 90%;
  border-radius: 15px;
  background-color: #fff;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  overflow: auto;
  
`;
const Button = styled.button`
cursor: pointer;
    width: calc(100% - 80px);
    color: rgb(255, 255, 255);
    border-radius: 5px;
    border: 0px;
    padding: 15px;
    
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-size: 16px;
    gap: 5px;
    
    position: absolute;
    bottom: 40px;
`;


export default CreateQuizModal;
