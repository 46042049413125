const PlatFomr_Sec1 = () => {
  return (
    <>
      <section class="wrap plat-sec1">
        <div class="inner">
          <div class="main-tit">
            <h2>
              본질에 집중할 수 있는 <br />
              최고의 수업환경
            </h2>
          </div>
          <div class="box-layout">
            <div class="wid-100 box1">
              <div class="txt-box">
                <h6>멀티룸</h6>
                <h3>
                  기본 수업 환경은 물론 <br />
                  선생님에 맞게 커스텀까지
                </h3>
                <p>
                  수업에 필요한 모든 솔루션이 들어간 공간에서 <br />
                  온라인 수업을 진행해보세요
                </p>
              </div>
              <div class="img-box">
                <img src="/assets/image/landing_page/sub/plat-sec1-box1.png" alt="멀티룸" />
              </div>
            </div>
            <div class="wid-50 box2">
              <div class="txt-box">
                <h6>츄잉 판서</h6>
                <h3>
                  츄잉의 판서기능이 <br />
                  특별한 이유
                </h3>
                <p>
                  텍스트, 도형, 형광펜 등 다양한 <br />
                  판서 기능 등 사용자에 맞게 <br />
                  깔끔하고 간편하게 사용할 수 있습니다.
                </p>
              </div>
              <div class="img-box">
                <img src="/assets/image/landing_page/sub/plat-sec1-box2.png" alt="츄잉 판서" />
              </div>
            </div>
            <div class="wid-50 box3">
              <div class="txt-box">
                <h6>화면 공유</h6>
                <h3>
                  수업에 필요한 자료는 <br />
                  모두 볼 수 있도록
                </h3>
                <p>
                  판서로 부족한 수업자료는 화면공유 기능으로 모두 <br />
                  대체가 가능하며, 학생들끼리 개별 과제 확인이 가능합니다.
                </p>
              </div>
              <div class="img-box">
                <img src="/assets/image/landing_page/sub/plat-sec1-box3.png" alt="화면 공유" />
              </div>
            </div>
            <div class="wid-50 box4">
              <div class="txt-box">
                <h6>실시간 소통</h6>
                <h3>
                  흐름이 끊기지 않는 <br />
                  수업의 노하우는?
                </h3>
                <p>
                  실시간으로 학생과 채팅으로 소통하며 <br />
                  원활한 질의응답을 경험해보세요
                </p>
              </div>
              <div class="img-box">
                <img src="/assets/image/landing_page/sub/plat-sec1-box4.png" alt="실시간 소통" />
              </div>
            </div>
            <div class="wid-50 box5">
              <div class="txt-box">
                <h6>화면 녹화</h6>
                <h3>
                  강의 영상, <br />
                  다시 새로 찍어야 할까요?
                </h3>
                <p>
                  자동으로 기록되는 나만의 학습영상 <br />
                  학생에게 쉽게 공유해보세요
                </p>
              </div>
              <div class="img-box">
                <img src="/assets/image/landing_page/sub/plat-sec1-box5.png" alt="화면 녹화" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default PlatFomr_Sec1;
