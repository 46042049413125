import React, {useContext, useEffect, useRef, useState} from 'react';
import {Modal, ModalBody} from "reactstrap";
import strings from "../../../lang/strings";
import './modal.scss'
import StudyRoomInput from "../custom_input/study_room_input";
import {rootStore} from "../../../mobx/store";
import {Page_settings} from "../../../config/page_settings";
import {observer} from "mobx-react";
import { extractNumber } from '../../../helper/common';
import PerfectScrollbar from "react-perfect-scrollbar";
import SignSchoolModal from './signUp_school_modal';

const EditProfileModal = observer((props) => {
    const context = useContext(Page_settings);
    const [email, setEmail] = useState('');
    const [nickname, setNickname] = useState('');
    const [school, setSchool] = useState(rootStore.getSchool ?? {});
    const [hopeSchool, setHopeSchool] = useState(rootStore.getHope ?? "");
    const [career, setCareer] = useState("");
    const [grade, setGrade] = useState("");
    const [classroom, setClassRoom] = useState("");
    const [profile, setProfile] = useState("");
    const imageRef = useRef();
    const [imgFile, setImgFile] = useState("")
    const [url, setUrl] = useState("");
    const [selectProfile, setSelectProfile] = useState(false);
    const [edited, setEdited] = useState(false);
    const [guardianPhone,setGuardianPhone] = useState(rootStore.getGuardianPhone ?? "");
    const [showSchoolModal, setShowSchoolModal] = useState(false);

    const {show, toggle} = props;
    
    const onOpened = () => {
        setEmail(rootStore.getProfile?.email ?? "");
        setNickname(rootStore.getProfile?.name ?? "");
        setProfile(rootStore.getProfile?.profile ?? "");
        setCareer(rootStore.getProfile?.hope_career ?? "");
        setGrade(rootStore.getProfile?.grade ?? "");
        setClassRoom(rootStore.getProfile?.classroom ?? "");
        setSelectProfile(false);
    }


    const onImageChange = (e) => {
        if (e.target.files.length < 1) {
            return;
        }
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onloadend = function () {
            setSelectProfile(true);
            setImgFile(reader.result);
            setUrl(file);
            setEdited(true);
        };
        reader.readAsDataURL(file);
    };

    useEffect(() => {
        if (school != (rootStore.getSchool ?? "")) {
            setEdited(true);
        }
        setSchool(rootStore.getSchool ?? "");
    }, [rootStore.getSchool]);

    useEffect(() => {
        if (hopeSchool != (rootStore.getHope ?? "")) {
            setEdited(true);
        }
        setHopeSchool(rootStore.getHope ?? "");
    }, [rootStore.getHope]);

    useEffect(() => {
        if (guardianPhone != (rootStore.getGuardianPhone ?? "")) {
            setEdited(true);
        }
        setGuardianPhone(rootStore.getGuardianPhone ?? "");
    }, [rootStore.getGuardianPhone]);

    const onEdit = () => {
        if (!edited) {
            return;
        }

        if (nickname == "") {
            alert(strings.enter_nick);
            return;
        }
        if (school == "") {
            alert(strings.school);
            return;
        }

        if (guardianPhone == ""){
            alert(strings.guardian_phone);
        }

        if (selectProfile) {
            let formData = new FormData();
            formData.append('file', url);

            context.post(
                'upload/image/profile',
                formData,
                response => {
                    setProfile(response.image);
                    editProfile(response.image);
                });
        } else {
            editProfile();
        }
    }

    const editProfile = (image) => {
        context.post(
            'member/editProfile',
            {
                name: nickname,
                profile: image ?? profile,
                school: school?.id,
                hope_school: hopeSchool,
                guardian_phone: guardianPhone, 
                hope_career: career,
                grade : grade,
                classroom : classroom
                
            },
            response => {
                context.get(
                    'member/getProfile',
                    {},
                    response => {
                        rootStore.setProfile(response.user_info);
                        rootStore.setSchool({
                            id: response.user_info.school_id,
                            name: response.user_info.school
                        });
                        rootStore.setHope(response.user_info.hope_school);
                        context.handleProfileModal(false);
                    }
                );
            });
    }

    const onClosed = () => {
        setEdited(false)
    }

    const checkMaxLength = (v) => {
   // 정규식을 사용하여 숫자만 추출
   const numericValue = v.replace(/\D/g, '');

   // 11글자 이하인 경우에만 상태 업데이트
   if (numericValue.length <= 11) {
       setGuardianPhone(numericValue);
       setEdited(false)
   }
   if(numericValue.length === 11){
     setEdited(true)
   }
    }

    return (
        <Modal isOpen={show} toggle={() => toggle()} onOpened={() => onOpened()} onClosed={() => onClosed()} className="account-info-modal">
            <ModalBody className="p-10">
            <PerfectScrollbar style={{padding:'20px'}} className="upload-modal-scroll m-b-25"
                                                          options={{suppressScrollX: true}}>
                                                            
                <div className='d-flex'>
                    <div className='f-s-20 f-w-600'>{strings.edit_profile}</div>
                    <div className='flex-grow-1'/>
                    <button className='modal-close-btn' onClick={() => toggle()}>
                        <img src={"/assets/image/icon_close_black.png"} alt="" style={{width: 25, height: 25}}/>
                    </button>
                    <input type='file' accept='image/*' className='hide'
                           onChange={(e) => onImageChange(e)}
                           ref={imageRef}/>
                </div>
                <div className="d-flex justify-content-center m-t-10 position-relative m-b-10">
                    <img className="user-profile"
                         src={selectProfile ? imgFile : (profile != null ? context.loadImage(profile) : "/assets/image/chewing_plus_logo.png")}
                         alt=""/>
                    <button className="camera-btn" onClick={() => imageRef?.current.click()}>
                        <img src={"/assets/image/btn_camera.png"} alt=""/>
                    </button>
                </div>
                <StudyRoomInput
                    name={strings.nick_name}
                    required={true}
                    inputType={"text"}
                    value={nickname}
                    onChange={(v) => {
                        setNickname(v);
                        setEdited(true);
                    }}
                />
                <StudyRoomInput
                    name={strings.email}
                    inputType={"email"}
                    value={email}
                    onChange={(v) => {
                        setEmail(v);
                        setEdited(true);
                    }}
                />
                <StudyRoomInput
                    name={strings.school}
                    required={true}
                    search={true}
                    inputType={"text"}
                    disable={true}
                    value={school.name}
                    onSearch={() => setShowSchoolModal(true)}
                    onChange={(v) => {
                        setEdited(true);
                    }}
                />
                <StudyRoomInput
                    name={strings.hope_school}
                    inputType={"text"}
                    value={hopeSchool}
                    onChange={(v) => {
                        setHopeSchool(v);
                        setEdited(true);
                    }}
                />
                <StudyRoomInput
                    name={strings.guardian_phone}
                    inputType={"text"}
                    value={guardianPhone}
                    onChange={(v) => {
                        checkMaxLength(extractNumber(v));
                    }}
                    />

                <StudyRoomInput
                    name={strings.hope_career}
                    inputType={"text"}
                    value={career}
                    onChange={(v) => {
                        setCareer(v);
                        setEdited(true);
                    }}/>

                <StudyRoomInput
                    name={strings.grade}
                    inputType={"text"}
                    value={grade}
                    onChange={(v) => {
                        setGrade(v);
                        setEdited(true);
                    }}/>
                <StudyRoomInput
                    name={strings.class}
                    inputType={"text"}
                    value={classroom}
                    onChange={(v) => {
                        setClassRoom(v);
                        setEdited(true);
                    }}/>
                <button className={('tag-create-btn ') + (edited ? '' : ' tag-create-btn-disable')} onClick={() => onEdit()}>
                    {strings.edit}
                </button>
                </PerfectScrollbar>
            </ModalBody>
            <SignSchoolModal show={showSchoolModal} toggle={() => setShowSchoolModal(false)} onSearchDone={(school) => {
                setShowSchoolModal(false);
                setEdited(true);
                setSchool(school);
            }}/>
        </Modal>

    );
})

export default EditProfileModal;
