import React from "react";
import styled from "styled-components";
import { HiMiniPlus } from "react-icons/hi2";

export default function PlanAddButton(props) {
    return (
        <PlanAddButtonWrapper
            onClick={props.onClick}>
            <HiMiniPlus color={"white"} size={24}/>
        </PlanAddButtonWrapper>
    );
}

const PlanAddButtonWrapper = styled.button`
    width: 35px;
    height: 35px;
    border-radius: 8px;
    background: #31303a;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
`;