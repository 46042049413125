import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import colors from "../../../design/colors";
import fonts from "../../../design/typography";
import { IMemberInfo } from "../../../type/member";
import IconCloud from "../../icons/cloud";
import InquiryContent from "./setting_modal_inquiry";
import NoticeContent from "./setting_modal_notice_content";
import PolicyContent from "./setting_modal_policy_cotent";
import PrivacyContent from "./setting_modal_privacy_content";
import TermsContent from "./setting_modal_term";
import { getFilePath } from "../../../utils/image";
import ImageCircle from "../../image_circle";

interface SettingModalProps {
    memberInfo: IMemberInfo;
    toggle: () => void;
    refetchMemberInfo: () => void;
}


const SettingModal = ({ memberInfo, toggle, refetchMemberInfo }: SettingModalProps) => {
    const [selectedMenuItem, setSelectedMenuItem] = useState<"PRIVACY" | "NOTICE" | "INQUIRY" | "TERMS" | "POLICY">("PRIVACY");
    
    const navigate = useNavigate();
    return (
        <SettingModalWrapper>
            <MenuSection>
                <RowWrapper style={{ marginBottom: 40 }}>
                    <ImageCircle src={getFilePath(memberInfo?.profile, "/assets/image/default_profile.png")} size={36} />
                    <div>
                        <RowWrapper style={{ marginLeft: 10 }}>
                            <span style={{ ...fonts.body3SemiBold, color: colors.gray900 }}>{memberInfo?.name}</span>
                            <UserTypeBox>
                                <span style={{ ...fonts.label5Medium, color: colors.gray900 }}>{memberInfo?.userType === "EXPERT" ? "선생님" : "학생"}</span>
                            </UserTypeBox>
                        </RowWrapper>
                        <RowWrapper style={{ marginLeft: 10 }}>
                            <div>{memberInfo?.email}</div>
                        </RowWrapper>
                    </div>
                </RowWrapper>
                <MenuItemWrapper>
                    <MenuItem isActive={selectedMenuItem === "PRIVACY"} onClick={() => setSelectedMenuItem("PRIVACY")}>
                        <span style={{ ...fonts.label4Medium, color: colors.gray900 }}>개인정보</span>
                    </MenuItem>
                    <MenuItem isActive={selectedMenuItem === "NOTICE"} onClick={() => setSelectedMenuItem("NOTICE")}>
                        <span style={{ ...fonts.label4Medium, color: colors.gray900 }}>알림</span>
                    </MenuItem>
                    <MenuItem isActive={selectedMenuItem === "INQUIRY"} onClick={() => setSelectedMenuItem("INQUIRY")}>
                        <span style={{ ...fonts.label4Medium, color: colors.gray900 }}>문의</span>
                    </MenuItem>
                    <MenuItem isActive={selectedMenuItem === "TERMS"} onClick={() => setSelectedMenuItem("TERMS")}>
                        <span style={{ ...fonts.label4Medium, color: colors.gray900 }}>서비스 이용약관</span>
                    </MenuItem>
                    <MenuItem isActive={selectedMenuItem === "POLICY"} onClick={() => setSelectedMenuItem("POLICY")}>
                        <span style={{ ...fonts.label4Medium, color: colors.gray900 }}>개인정보 처리방침</span>
                    </MenuItem>
                </MenuItemWrapper>
                <CloudSection onClick={() => {
                    navigate("/cloud");
                    toggle();
                    }}>
                    <IconCloud width="20px" height="20px" color={colors.blue600} background={colors.blue600} style={{ marginBottom: 10}} />
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", gap: 6, marginBottom: 12}}>
                        <span style={{ ...fonts.label4Medium, color: colors.blue600 }}>{0}GB</span>
                        <span style={{ ...fonts.label4Medium, color: colors.gray600 }}>{"/"}</span>
                        <span style={{ ...fonts.label4Medium, color: colors.gray600 }}>{memberInfo?.cloudLimit}GB</span>
                    </div>
                    <div style={{ position: "relative", width: "100%", height: 6, borderRadius: 2, backgroundColor: colors.gray200, zIndex: 6}}>
                        <div style={{ width: "20%", height: 6, backgroundColor: colors.blue600, position: "absolute", top: 0, left: 0, zIndex: 7, borderRadius: 4}} />
                    </div>
                </CloudSection>
                {/* <div style={{ width: "100%", height: 28, backgroundColor: colors.blue50, borderRadius: 8, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                    <span style={{ ...fonts.label5Medium, color: colors.blue600 }}>기관 관리자 전환</span>
                </div> */}
            </MenuSection>
            <ContentSection>
                {selectedMenuItem === "PRIVACY" && <PrivacyContent memberInfo={memberInfo} toggle={toggle} refetchMemberInfo={refetchMemberInfo} />}
                {selectedMenuItem === "NOTICE" && <NoticeContent toggle={toggle} />}
                {selectedMenuItem === "INQUIRY" && <InquiryContent toggle={toggle} />}
                {selectedMenuItem === "TERMS" && <TermsContent toggle={toggle} />}
                {selectedMenuItem === "POLICY" && <PolicyContent toggle={toggle} />}
            </ContentSection>
        </SettingModalWrapper>
    )
}

export default SettingModal;

const SettingModalWrapper = styled.div`
    width: 880px;
    height: 500px;
    background-color: ${colors.white};
    border-radius: 20px;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
`;

const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const MenuSection = styled.div`
    width: 220px;
    height: 100%;
    background-color: ${colors.gray50};
    border-radius: 20px 0 0 20px;
    padding: 24px 16px 20px 16px;

`;

const MenuItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: 40px;
`;

const MenuItem = styled.div<{ isActive: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 12px;
    border-radius: 8px;
    background-color: ${props => props.isActive ? colors.gray100 : "transparent"};
    height: 36px;
    cursor: pointer;
`;
const UserTypeBox = styled.div`
    height: 20px;
    padding: 6px 8px;
    border-radius: 12px;
    background-color: ${colors.gray100};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
`;

const CloudSection = styled.div`
    width: 100%;
    height: 92px;
    background-color: ${colors.gray100};
    border-radius: 8px;
    margin-top: 20px;
    margin-bottom: 8px;
    padding: 14px 12px;
    cursor: pointer;
`;


const ContentSection = styled.div`
    width: 660px;
    height: 100%;
    background-color: ${colors.white};
    border-radius: 0 20px 20px 0;
`;