import React from "react";
import { IconType } from "../../type/icon_type"

const IconDownload = ({ width = "24px", height = "24px", color = "#73787E", style }: IconType) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path d="M20 14.3529V18.1176C20 18.6169 19.8127 19.0957 19.4793 19.4487C19.1459 19.8017 18.6937 20 18.2222 20H5.77778C5.30628 20 4.8541 19.8017 4.5207 19.4487C4.1873 19.0957 4 18.6169 4 18.1176V14.3529M7.55556 10.5882L12 15.2941M12 15.2941L16.4444 10.5882M12 15.2941V5" stroke={color} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    )
}

export default IconDownload;