import { withRouter } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import { rootStore } from "../../mobx/store";
import { Page_settings } from "../../config/page_settings";
import strings from "../../lang/strings";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import AddChapterUnitModal from "./modal/add_chapter_unit_modal";
import CreateCurriculumStep4Modal from "./modal/create_curriculum_step4_modal";
import WarningModal from "./modal/warning_modal";
import StudentCourseInfoModal from "./modal/student_course_info_modal";

const TestStore = (props) => {
  const history = useHistory();
  const context = useContext(Page_settings);
  const [storeList, setStoreList] = useState([]);


  useEffect(() => {
    loadStoreList();
  }, []);

  const moveExamReview = (result) => {
    history.push(`/curriculum/test_review/${result}`,{from: 'test_store'})
  }

  const loadStoreList = () => {
    context.get(
      'curriculum/getStoreList',
      {},
      response => {
        console.log("re",response);
        setStoreList(response)
      }
  );
  }

  const deleteExam = (id) => {
    context.get(
      'curriculum/changeStoreStatus',
      {
        test_result: id,
        status: 0
      },
      response => {
        loadStoreList(); // Reload the list after deletion
      }
    );
  }


  return (
    <div class="main-content exam-store"> 
      <div class="title">테스트 보관함</div>
      {/* <div class="studentAddModal-searchContainer" >
              <div className="class-search  align-item-center box-shadow" style={{ width: '10%', padding:'10px'}}  >
                <input
                  id='inputBox'
                  className="input-transparent cursor"
                  placeholder={"커리큘럼을 선택해주세요."}
                  inputType={'text'}
                  readOnly
                />
                <img className='cursor' src={"/assets/image/icon_dropdown_down_arrow_black.png"} alt='' />
              </div>
            </div> */}
      <div>
      <PerfectScrollbar style={{height : 'calc(100vh - 200px)'}} className="exam-container">
        {storeList?.map((item,idx) => (
            <div className="exam-box">
            <div className="left">
              <div className="title">
                테스트
              </div>
              <div className="date">
                {item.create_date}
              </div>
            </div>
            <div className="flex-box" onClick={()=> moveExamReview(item.test_result)}>
              <div className="flex-left">
              <div>{item.chapter_name}</div>
              <div>{">"}</div>
              <div className="highlight">{item.section_name}</div>
              <div>{">"}</div>
              <div >{item.unit_name}</div>
              </div>
              <img 
    src="/assets/image/icon_trash_gray.png" 
    alt="" 
    onClick={(e) => {
      e.stopPropagation();
      deleteExam(item.id);
    }}
  />
            </div>
          </div> 
        ))}
    
     
        
      </PerfectScrollbar>
      </div>
        
 
                                               
      
      


    </div >
  );






}
export default withRouter(TestStore);
