import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { rootStore } from "../../../mobx/store";
import { Page_settings } from "../../../config/page_settings";
import { Modal, ModalBody } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { set } from "mobx";

function BillSendModal(props) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [price, setPrice] = useState("");
  const [reason, setReason] = useState("");
  const [message, setMessage] = useState("");
  const [members, setMembers] = useState([]);

  const context = useContext(Page_settings);

  const [paymentToken, setPaymentToken]  = useState(localStorage.getItem('payment_token') ? JSON.parse(localStorage.getItem('payment_token')).token : null);
  const tokenTime = paymentToken == null ? null : paymentToken.time;

  const { show, toggle, status } = props;

  useEffect(() => {
    setName("");
    setPhone("");
    setPrice("");
    setReason("");
    setMessage("");
    setMembers([]);
    setPaymentToken(localStorage.getItem('payment_token') ? JSON.parse(localStorage.getItem('payment_token')).token : null);
  }, [show]);



  const tokenVerification = (tokenTime) => {
    // 현재 시각을 밀리초 단위로 얻음
    const currentTime = Date.now();

    // 주어진 시간 값과 현재 시각의 차이를 계산
    const difference = currentTime - tokenTime;
    // 차이가 지정된 시간 이상이면 true, 아니면 false 반환
    return difference >= 3600000;
  };

  const disable = () => {
    if (((name && phone) || members.length > 0) && price && reason) {
      return false;
    } else {
      return true;
    }
  };

  const sendBill = () => {
    if (phone && name && validatePhoneNumber(phone)) {
        members.push({ name, phone });
    }

    if (members.length === 0) {
        addMember();
        return;
    }

    if (!validateAmount(price)) {
      alert("금액은 숫자만 입력 가능합니다.");
      return;
    }

    context.post(
        "payment/send",
        {
          token: paymentToken,
          members,
          price,
          reason,
          message,
        },
        (response) => {
          alert("청구서 발송 완료");
          toggle();
        }
    );
  };

  const onSend = () => {
    if (tokenVerification(tokenTime)) {
      context.post("payment/getToken", {}, (response) => {
        rootStore.setPaymentToken(response);
        setPaymentToken(response);
        sendBill();
      });
    } else {
      sendBill();
    }
  }

  const deleteMember = (idx) => {
    // 삭제 버튼 클릭 시 해당 아이디를 members에서 찾아서 삭제
    setMembers(members.filter((item, index) => index !== idx));
  };

  function validatePhoneNumber(phoneNumber) {
    const regex = /^010\d{8}$/;
    return regex.test(phoneNumber);
  }

  const validateAmount = (amount) => {
    const regex = /^[0-9]*$/;
    return regex.test(amount);
  };

  const addMember = () => {
    if (!name && !phone) {
      alert("이름과 번호을 입력해 주세요.");
    } else if (!name) {
      alert("이름을 입력해 주세요.");
    } else if (!validatePhoneNumber(phone)) {
      alert("휴대폰 번호를 양식에 맞게 입력해주세요 ex)01012345678");
    } else {
      setMembers([...members, { name: name, phone: phone }]);
      setName("");
      setPhone("");
    }
  };

  return (
    <Modal isOpen={show} toggle={() => toggle()} className="bill-send-modal">
      <ModalBody className="p-30 padding-zero">
        <div class="bill-send-modal">
          <div class="modal-header">
            <div class="left">청구서 발송</div>
            <div class="right" onClick={() => toggle()}>
              <img src="/assets/image/icon_close_black.png" alt="" />
            </div>
          </div>

          <div class="bill-modal-content">
            <div class="bill-modal-header">
              <div class="left">청구서</div>
            </div>
            <PerfectScrollbar
              style={{ height: "720px" }}
              options={{ suppressScrollX: true }}
            >
              {members.length > 0 && (
                <div
                  class={
                    members.length > 0
                      ? "member-list-container"
                      : "missionAdd-student-list-null"
                  }
                >
                  {members.map((item, idx) => {
                    return (
                      <div class="member-list">
                        <div class="name">{item.name}</div>
                        <div
                          class="cancel-btn"
                          onClick={() => deleteMember(idx)}
                        >
                          {" "}
                          <img
                            src="/assets/image/icon_circle_close_btn.png"
                            alt=""
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              <div class="bill-subtitle">
                <div>이름</div>
                <div class="require">*</div>
              </div>
              <input
                placeholder="청구서를 받는 사람의 이름을 입력해 주세요"
                class="bill-input"
                value={name}
                onChange={(v) => setName(v.target.value)}
              />
              <div class="bill-subtitle">
                <div>휴대폰 번호</div>
                <div class="require">*</div>
              </div>
              <input
                placeholder="휴대폰 번호를 입력해주세요"
                class="bill-input"
                value={phone}
                onChange={(v) => setPhone(v.target.value)}
              />
              <button class="add-person-btn" onClick={() => addMember()}>
                인원 추가 +{" "}
              </button>
              <div class="bill-subtitle">
                <div>금액</div>
                <div class="require">*</div>
              </div>
              <input
                placeholder="청구할 금액을 입력해주세요"
                class="bill-input"
                value={price}
                onChange={(v) => setPrice(v.target.value)}
              />
              <div class="bill-subtitle">
                <div>청구 사유</div>
                <div class="require">*</div>
              </div>
              <input
                placeholder="청구사유를 입력해주세요 (100자 이내)"
                maxLength={100}
                class="bill-input"
                value={reason}
                onChange={(v) => setReason(v.target.value)}
              />
              <div class="bill-subtitle">
                <div>안내 메시지</div>
              </div>
              <textarea
                placeholder="안내 메시지를 입력해주세요 (500자 이내)"
                rows="4"
                maxLength={500}
                class="bill-input bill-textarea"
                onChange={(v) => setMessage(v.target.value)}
              />
            </PerfectScrollbar>
          </div>

          <div class="bill-modal-bottom">
            <button
              class={`submit-btn submit ${disable() ? "disable" : ""}`}
              disabled={disable()}
              onClick={() => onSend()}
            >
              보내기
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999999;
`;

const ModalWrap = styled.div`
  width: 80%;
  max-width: 1452px;
  height: 920px;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export default BillSendModal;
