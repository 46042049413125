import React, {useContext, useEffect, useState} from 'react';
import {Modal, ModalBody} from "reactstrap";
import strings from "../../../lang/strings";
import './modal.scss'
import moment from "moment";
import {Page_settings} from "../../../config/page_settings";
import {Store} from "react-notifications-component";

const RoomInfoModal = (props) => {
    const {show, toggle, onBack, mine, roomInfo} = props;
    const context = useContext(Page_settings);
    const [intro, setIntro] = useState(roomInfo?.intro);
    const [modified, setModified] = useState(false)
    
    useEffect(() => {
        if(roomInfo) {
            setModified(intro != roomInfo?.intro)
        }
        
    }, [intro])
    if (!roomInfo) return null;

    const addNotification = (notificationType, notificationTitle, notificationMessage) => {
        Store.addNotification({
            title: notificationTitle,
            message: notificationMessage,
            type: notificationType,
            insert: 'top',
            container: 'top-right',
            dismiss: {
                duration: 1000,
            }
        });
    }

    const onConfirm = () => {
        context.post(
            "room/updateIntro",
            {
                room: roomInfo?.id,
                intro: intro
            },
            response => {
                setModified(false)
                addNotification('success', '', strings.change_success)
            }
        )
    }

    return (
        <Modal isOpen={show} toggle={() => toggle()} className="room-info-modal"
               onOpened={() => setIntro(roomInfo?.intro)}>
            <ModalBody>
                <div className='d-flex modal-title-custom align-item-center'>
                    <button className='modal-close-btn' onClick={() => onBack()}>
                        <img src={"/assets/image/icon_arrow_back.png"} alt="" className="modal-close"/>
                    </button>
                    <div className='f-s-20 f-w-600 m-l-10'>{strings.room_info}</div>
                    <div className='flex-grow-1'/>
                    <button className='modal-close-btn' onClick={() => toggle()}>
                        <img src={"/assets/image/icon_close_white.png"} alt="" className="modal-close"/>
                    </button>
                </div>
                <div className="f-s-18">{strings.room_title}</div>
                <div className="room-title">{roomInfo?.title}</div>
                <div className="m-t-20 f-s-18">{strings.tag}</div>
                <div className="room-title">
                    {
                        roomInfo && roomInfo?.tag && roomInfo?.tag !== "" &&
                        roomInfo?.tag.split("#").map((item, idx) => {
                            return (
                                <div key={idx} className="room-tag">{"#" + item}</div>
                            )
                        })
                    }
                </div>
                <div className="d-flex m-t-20">
                    <div className="flex-1 p-r-10">
                        <div className="f-s-18 m-b-10">{strings.category}</div>
                        <div className="room-title">
                            <div
                                className="room-category">{roomInfo != "" ? strings.room_category_list[parseInt(roomInfo?.category)]?.name : ""}</div>
                        </div>
                    </div>
                    <div className="flex-1">
                        <div className="f-s-18 m-b-10">{strings.room_date}</div>
                        <div className="room-title">
                            <div className="f-s-16 m-r-5">{moment(roomInfo?.open_date).format("YYYY-MM-DD")}</div>
                            <div className="f-s-16">{strings.until}</div>
                            <div
                                className="day-left">{strings.d_ + moment(moment(roomInfo?.open_date).format("YYYY-MM-DD")).diff(moment().format("YYYY-MM-DD"), 'days')}</div>
                        </div>
                    </div>
                </div>
                <div className="f-s-18 m-t-20">{strings.introduction}</div>
                <div className="introduction-box">
                        <textarea className="input-transparent"
                                  value={intro}
                                  onChange={(v) => setIntro(v.target.value)}
                                  disabled={!mine}
                        />
                </div>
                {
                    mine &&
                    <button
                        onClick={() => onConfirm()}
                        className={"info-confirm " + (modified ? "confirm-avail" : "")}
                        disabled={!modified}>{strings.confirm}</button>
                }
            </ModalBody>
        </Modal>
    );
}

export default RoomInfoModal;
