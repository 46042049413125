import React from 'react';
import styled from 'styled-components';
import colors from '../design/colors';
import PlusIcon from './icons/plus';
import fonts from '../design/typography';

interface IButtonProps {
    size: 'medium' | 'large';
    text: string;
    width?: string;
    height?: string;
    onClick: () => void;
    disabled?: boolean;
    style?: React.CSSProperties;
    icon?: React.ReactNode;
    buttonColor?: string;
    textColor?: string;
}


const IconButton = (props: IButtonProps) => {
    const {size, onClick, text, disabled, style, width, height, icon, buttonColor, textColor} = props;
    const [hovered, setHovered] = React.useState(false);

    return (
        <ButtonContainer 
            onMouseEnter={() => setHovered(true)} 
            onMouseLeave={() => setHovered(false)} 
            hovered={hovered}
            size={size}
            onClick={onClick}
            disabled={disabled}
            style={style}
            width={width}
            height={height}
            buttonColor={buttonColor}
        >
            {icon ? icon : <PlusIcon color={colors.white} width={size === 'medium' ? '20px' : '24px'} height={size === 'medium' ? '20px' : '24px'}/>}
            <ButtonText size={size} textColor={textColor}>{text}</ButtonText>
        </ButtonContainer>
    )
}

export default IconButton;

const ButtonContainer = styled.button<{hovered: boolean, size?: 'medium' | 'large', disabled?: boolean, width?: string, height?: string, buttonColor?: string}>`
    background-color: ${props => props.buttonColor ?? (props.hovered ? colors.blue800 : props.disabled ? colors.gray200 : colors.blue600)};
    width: ${props => props.width ?? "100%"};
    height: ${props => props.height ?? "100%"};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`;

const ButtonText = styled.span<{size?: 'medium' | 'large', textColor?: string}>`
    color: ${props => props.textColor ?? colors.white};
    margin-left: 4px;
    font-size: ${props => props.size === 'medium' ? fonts.label4Medium.fontSize : fonts.body2Medium.fontSize};
    font-family: ${props => props.size === 'medium' ? fonts.label4Medium.fontFamily : fonts.body2Medium.fontFamily};
    line-height: ${props => props.size === 'medium' ? fonts.label4Medium.lineHeight : fonts.body2Medium.lineHeight};
`;
