import { inject, observer } from 'mobx-react';
import React, { useContext, useEffect, useRef, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { Page_settings } from '../../../config/page_settings';
import HeaderBlack from "../../../components/header/header_black";
import { AGORA_APP_ID, AGORA_REGION, AGORA_WHITEBOARD_AK, AGORA_WHITEBOARD_APP_IDENTIFIER, AGORA_WHITEBOARD_REGION, AGORA_WHITEBOARD_SK, SOCKET_URL } from "../../../config/const";
import ConferenceRight from "./conference_right";
import ConferenceTop from "./conference_top";
import ConferenceBottom from "./conferece_bottom";
import ScreenSettingModal from "../../../components/control/modal/screen_setting_modal";
import io, { Socket } from 'socket.io-client';
import { rootStore } from "../../../mobx/store";
import strings from "../../../lang/strings";
import { Store } from "react-notifications-component";
import NewSettingModal from "../../../components/control/modal/new_setting_modal";
import RoomInfoModal from "../../../components/control/modal/room_info_modal";
import MemberInfoModal from "../../../components/control/modal/member_info_modal";
import AgoraRTC, { ClientRole } from 'agora-rtc-sdk-ng';
import { createFastboard } from '@netless/fastboard-core';
import { apps, createUI } from '@netless/fastboard-ui';
import SweetAlert from "react-bootstrap-sweetalert";
import moment from 'moment';
import fixWebmDuration from 'fix-webm-duration';
import axios from 'axios';
import { set } from 'mobx';

interface IRoomInfo {
    id: number;
    title: string;
    meeting_number: string;
    user: {
        id: number;
        name: string;
        profile: string;
    };
}

interface IRoomMember {
    user: {
        id: number;
        name: string;
        video: "ON" | "OFF";
        speaker: "ON" | "OFF";
        mirror: "ON" | "OFF";
        profile: string;
        studyTime?: number;
        studyTimeThisMonth?: number;
        studyStartTime?: Date | null;
    };
    member: number;
    videoTrack?: any;
    audioTrack?: any;
    reaction?: IReaction;
}

interface IReaction {
    icon: string;
    desc: string;
}

interface IChat {
    sender: number;
    sender_name: string;
    sender_profile?: string;
    receiver?: number;
    receiver_name?: string;
    message: string;
    time: string;
    show_profile: boolean;
    show_time: boolean;
}

const ChallengeRoom = observer((props) => {
    const context: any = useContext(Page_settings);
    const [socket, setSocket] = useState<Socket | null>(null);
    const [agoraEngine, setAgoraEngine] = useState<any>(null);

    // 본인정보
    const [profile, setProfile] = useState<any>();

    // 방정보
    const roomId = parseInt(props.match.params.id);
    const [memberList, setMemberList] = useState<IRoomMember[]>([]);
    const [roomInfo, setRoomInfo] = useState<IRoomInfo>();
    const [mine, setMine] = useState(false);

    // setting 관련
    const [isUsingVideo, setisUsingVideo] = useState<boolean>(false)
    const [isUsingMic, setisUsingMic] = useState<boolean>(false)
    const [isMirrored, setisMirrored] = useState<boolean>(false)

    // reaction 관련
    const [reaction, setReaction] = useState<IReaction | null>(null);
    const [reactionTime, setReactionTime] = useState(0);

    // modal 관련
    const [showSettingModal, setShowSettingModal] = useState(false)
    const [showSetting, setShowSetting] = useState(false);
    const [leaveAlert, setLeaveAlert] = useState(false);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [showRoomInfo, setShowRoomInfo] = useState(false);
    const [memberInfo, setMemberInfo] = useState("");
    const [showBottom, setShowBottom] = useState(true);

    // agora 관련
    const [mics, setMics] = useState([]);
    const [curMic, setCurMic] = useState(null);
    const [cams, setCams] = useState([]);
    const [curCam, setCurCam] = useState(null);
    const [meetingNumber, setMeetingNumber] = useState('');
    const [meetingRole, setMeetingRole] = useState(2);
    const [ownerVideoTrack, setOwnerVideoTrack] = useState(null);
    const [ownerAudioTrack, setOwnerAudioTrack] = useState(null);
    const [isJoined, setIsJoined] = useState(false);
    
    // 화면공유 관련
    const [screenShare, setScreenShare] = useState(false);
    const [boardSplit, setBoardSplit] = useState(false);
    const [screenTrack, setScreenTrack] = useState(null);
    const [publishStatus, setPublishStatus] = useState(false);

    // whiteboard 관련
    const [boardUuid, setBoardUuid] = useState('');
    const [recorder, setRecorder] = useState(null);
    const [recordStartTime, setRecordStartTime] = useState(0);
    const [fastboard, setFastboard] = useState(null);
    const [fastboardUI, setFastboardUI] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [chunks, setChunks] = useState([]);

    //채팅 메시지
    const [chatList, setChatList] = useState<IChat[]>([]);
    const chatListRef = useRef(chatList);
    useEffect(() => {
        chatListRef.current = chatList;
    }, [chatList]);

    AgoraRTC.onMicrophoneChanged = async changedDevice => {
        // When plugging in a device, switch to a device that is newly plugged in.
        if (changedDevice.state === "ACTIVE") {
            ownerAudioTrack.setDevice(changedDevice.device.deviceId);
            // Switch to an existing device when the current device is unplugged.
        } else if (changedDevice.device.label === ownerAudioTrack?.getTrackLabel()) {
            const oldMicrophones = await AgoraRTC.getMicrophones();
            oldMicrophones[0] && ownerAudioTrack.setDevice(oldMicrophones[0].deviceId);
        }
    };

    AgoraRTC.onCameraChanged = async changedDevice => {
        // When plugging in a device, switch to a device that is newly plugged in.
        if (changedDevice.state === "ACTIVE") {
            ownerVideoTrack?.setDevice(changedDevice.device.deviceId);
            // Switch to an existing device when the current device is unplugged.
        } else if (changedDevice.device.label === ownerVideoTrack?.getTrackLabel()) {
            const oldCameras = await AgoraRTC.getCameras();
            oldCameras[0] && ownerVideoTrack?.setDevice(oldCameras[0].deviceId);
        }
    };

    // requestAnimationFrame loop. Each frame, we draw to the canvas.
    const loop = () => {
        if (!isRecording) {
            return;
        }
        draw();
    }

    // our drawing function
    const draw = () => {
        // 👈 DRAWING COMMANDS HERE!
        const canvasElement = document.getElementById('recordingCanvas') as HTMLCanvasElement;
        const canvasContext = canvasElement.getContext('2d');
        const { width, height } = canvasElement;
        const shareElement = document.getElementById('whiteboardDiv');

        if (!isRecording || shareElement == null) {
            return
        }

        // clear out the entire canvas and paint from scratch
        canvasContext.clearRect(0, 0, width, height);

        // draw our screen share in top-left
        // would need to do real math to get proper aspect ratio.
        canvasContext.drawImage(screenShareVideoRef.current, 0, 0, width, height);
        if (recorder != null && recorder.state == 'active') {
            recorder.requestData();
        }

        if (!isRecording) {
            return
        }
        requestAnimationFrame(loop);
    }

    // agora 설정 & setSocket
    useEffect(() => {
        context.handleSetPageHeader(false);
        context.handleSetPageSidebar(false);
        context.setContentBgType(3);
        setProfile(rootStore.getProfile);
        setisMirrored(rootStore.getProfile.mirror == "ON");
        const engine = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });
        setAgoraEngine(engine);
        
        context.get(
            'room/detail',
            {
                id: roomId
            },
            response => {
                setMine(response.room.user.id == rootStore.getProfile.id);
                setRoomInfo(response.room)
                setMeetingNumber(response.room.meeting_number);
                setMeetingRole(response.room.user.id == rootStore.getProfile?.id ? 1 : 2);
                setBoardUuid(response.room.whiteboard_uuid);
                
                if (response.room.whiteboard_uuid == '') {
                    setIsRecording(true);
                    requestAnimationFrame(loop);
                    recorder.start();
                }
            }
        );

        context.get(
            'room/getMemberList',
            {
                room: roomId,
                type: 2
            },
            response => {
                if (response.list.length !== 0) {
                    setMemberList(response.list.map((item, idx) => {
                        item.reaction = ""
                        return item
                    }));
                    listRef.current = response.list;
                    
                    const myInfo = response.list.find(item => item.member == parseInt(rootStore.getProfile.id));
                    if (myInfo && mine) {
                        setisUsingVideo(myInfo.user.video == "ON");
                        setisUsingMic(myInfo.user.speaker == "ON");
                        setisMirrored(myInfo.user.mirror == "ON");
                    }
                }
            }
        );

        if (rootStore.getProfile != null) {
            setisUsingVideo(rootStore.getProfile.video === "ON" && cams.length > 0);
            setisUsingMic(rootStore.getProfile.speaker === "ON" && mics.length > 0);
            setisMirrored(rootStore.getProfile.mirror === "ON");
        }
        
        if (!socket) {
            setSocket(io(SOCKET_URL, {
                withCredentials: true,
                auth: {
                    token: props.rootStore.token,
                }
            }));
        }

        const setupRecording = () => {
            const canvas = document.getElementById('recordingCanvas') as HTMLCanvasElement;

            // create a MediaStream from our canvas
            // the `30` here is frames per second, feel free to set your own FPS
            const canvasStream = canvas.captureStream(30);
    
            // combine the canvas stream and mic stream (from above) by collecting
            //  tracks from each.
            const combinedStream = new MediaStream([
                ...canvasStream.getTracks()
            ]);
            const curRecorder = new MediaRecorder(combinedStream, {
                // requested media type, basically limited to webm 🤦‍♂️
                mimeType: "video/webm;codecs=vp9"
            });
    
            // collect blobs when available
            curRecorder.ondataavailable = (evt) => {
                setChunks(prev => [...prev,evt.data]);
            }
    
            // when starting the recording, track the start time
            curRecorder.onstart = () => {
                setRecordStartTime(performance.now());
            }
            setRecorder(curRecorder);
        }
        setupRecording();

        return () => {
            context.setContentBgType(2);
            context.handleSetPageHeader(1);
            context.handleSetPageSidebar(true);

            if (engine) {
                engine.leave().then(() => {
                setAgoraEngine(null);
                window.location.reload();
            })}

            if (ownerAudioTrack) {
                ownerAudioTrack.close();
                setOwnerAudioTrack(null);
            }

            if (ownerVideoTrack) {
                ownerVideoTrack.close();
                setOwnerVideoTrack(null);
            }

            if (fastboardUI != null) {
                fastboardUI.destroy();
                setFastboardUI(null);
            }

            if (fastboard != null) {
                fastboard.destroy();
                setFastboard(null);
            }
        }
    }, []);

    useEffect(() => {
        if (agoraEngine == null) return;

        agoraEngine.on("user-published", async (user: any, mediaType: 'video' | 'audio') => {
            if (roomInfo && roomInfo.user) {
                if ((user.uid as number === roomInfo.user.id) && !mine) {
                    await agoraEngine.subscribe(user, mediaType);      
                    if (mediaType === 'video') {
                        setOwnerVideoTrack(user.videoTrack);
                        setPublishStatus(prev => !prev);
                    } else if (mediaType === 'audio') {
                        setOwnerAudioTrack(user.audioTrack);
                        user.audioTrack.play();
                    }
                }
            }
        });

        // "user-unpublished" 이벤트를 한 번만 등록
        agoraEngine.on("user-unpublished", (user, mediaType) => {
            if (roomInfo && roomInfo.user) {
                if ((user.uid as number === roomInfo.user.id) && !mine) {
                    if (mediaType === 'video') {
                        setOwnerVideoTrack(null);
                        setPublishStatus(prev => !prev);
                    } else if (mediaType === 'audio') {
                        setOwnerAudioTrack(null);
                    }                        
                
                }}

            return () => {
                agoraEngine.off("user-unpublished");
                agoraEngine.off("user-published");
            }
        });
    }, [agoraEngine, mine, roomInfo])

    //socket 설정
    useEffect(() => {
        if( socket == null ) return;
        socket.connect();

        socket.on('connect', () => {
            socket.emit('add user', roomId);
        });
        socket.on('reconnect', data => {
            socket.emit('add user', roomId);
        });
        socket.on("kickout", data => {
            if(data.room != roomId) return;
            if (data.member == rootStore.getProfile.id) {
                props.history.push("/room/study/conference")
                addNotification('warning', strings.kicked)
            }
        });
        socket.on("screen share", ({user, room, status}) => {
            if (room != roomId) {return;}
            setScreenShare(status);
        });

        socket.on("screen split", ({room, split}) => {
            if (room != roomId) {return;}
            setBoardSplit(split);
        });

        socket.on('user joined',({room, user, member, owner}) => {
            if (room != roomId) {return;}

            if (memberList.findIndex(item => item.member == member) != -1) {return;}
            setMemberList(prevList => [...prevList, { user: user, member: member}]);
        });

        socket.on('user left', data => {
            if (data.room != roomId) {return;}

            setMemberList(prevList => prevList.filter((item, idx) => {
                return (item.user.id != data.user.id)
            }))
        });

        socket.on('reaction', data => {
            setMemberList(prev => prev.map((item, idx) => {
                if (item.user.id == data.user_info.id) {
                    item.reaction = data.reaction
                }
                return item
            }));
        });

        socket.on('video', ({user, video, room }) => {
            if (room !== roomId) return;

            const member = memberList.find(item => item.member === user);
            if (member) {
                setMemberList(prev => prev.map((item, idx) => {
                    if (item.member === user) {
                        item.user.video = video ? "ON" : "OFF";
                    }
                    return item
                }));
            }
        });

        socket.on('speaker', ({user, speaker, room }) => {
            if (room !== roomId) return;

            const member = memberList.find(item => item.member === user);
            if (member) {
                setMemberList(prev => prev.map((item, idx) => {
                    if (item.member === user) {
                        item.user.speaker = speaker ? "ON" : "OFF";
                    }
                    return item
                }));
            }
        });

        socket.on('cam mirror', ({user, reverse, room }) => {
            if (room !== roomId) return;

            console.log('cam mirror =>', user, reverse, room);
            const member = memberList.find(item => item.member === user);
            if (member) {
                setMemberList(prev => prev.map((item, idx) => {
                    if (item.member === user) {
                        item.user.mirror = reverse ? "ON" : "OFF";
                    }
                    return item
                }));
            }
        });

        socket.on("new message server", ({room, sender, sender_name, sender_profile, receiver, message, time}) => {
            if (room != roomId) {return;}
            // 받는 사람이 없거나, 받는 사람이 본인이거나, 보내는 사람이 본인이면 채팅 리스트에 추가
            if (!receiver || (receiver == rootStore.getProfile.id || sender == rootStore.getProfile.id)) {
                const receiver_name = receiver ? memberList.find(item => item.member == receiver)?.user.name : "?";
                if (chatListRef.current.length === 0) {
                    setChatList([{
                        sender: sender,
                        sender_name: sender_name,
                        sender_profile: sender_profile,
                        ...(receiver && { receiver }),
                        ...(receiver && { receiver_name: receiver_name }),
                        message: message,
                        time: time,
                        show_profile: true,
                        show_time: true
                    }])
                } else {
                    const latest_chat = chatListRef.current[0];
                    const other_chats = chatListRef.current.slice(1);
                    const change_person = latest_chat.sender != sender;
                    // 사람이 변하거나 분이 변화하면 시간을 표시하고 분이 변하지 않으면 이전 메시지의 시간을 표시하지 않음
                    const show_time = (moment(latest_chat.time).minute() != moment(time).minute()) || change_person;
                    setChatList([{
                        sender: sender,
                        sender_name: sender_name,
                        sender_profile: sender_profile,
                        ...(receiver && { receiver }),
                        ...(receiver && { receiver_name: receiver_name }),
                        message: message,
                        time: time,
                        show_profile: change_person,
                        show_time: true
                    }, {... latest_chat, show_time: show_time}, ...other_chats])
                }
            }
        })

        return () => {
            socket.emit('leaveRoom', {room: roomId});
            socket.disconnect();
            socket.off('connect');
            socket.off('reconnect');
            socket.off('kickout');
            socket.off("screen share");
            socket.off("screen split");
            socket.off('user joined');
            socket.off('user left');
            socket.off("reaction");
            socket.off("video");
            socket.off("speaker");
            socket.off("cam mirror");
            socket.off("new message server");
            setSocket(null);
        }
    }, [socket]);

    useEffect(() => {
        async function startMeeting(token) {

            const options = {
                appId: AGORA_APP_ID,
                channel: meetingNumber,
                token: token,
                uid: rootStore.getProfile.id,
                role: mine ? 'host' : 'audience'
            };
            
            agoraEngine.setClientRole(options.role as ClientRole);
            await agoraEngine.join(options.appId, options.channel, options.token, options.uid)
            .then(() => setIsJoined(true));
            
        }

        if (meetingNumber != "") {
            context.get(
                'room/getAgoraRtcToken',
                {
                    channelName: meetingNumber
                },
                response => {
                    startMeeting(response.token)
                }
            );
        }
    }, [meetingNumber, meetingRole, mine])

    useEffect(() => {
        async function joinWhiteBoardRoom(roomToken) {
            if (fastboard != null) {
                fastboard.destroy();
                setFastboard(null);
                fastboardUI.destroy();
                setFastboardUI(null);
            }
            const fastboardCur = await createFastboard({
                sdkConfig: {
                    appIdentifier: AGORA_WHITEBOARD_APP_IDENTIFIER,
                    region: "sg", // "cn-hz" | "us-sv" | "sg" | "in-mum" | "gb-lon"
                },
                joinRoom: {
                    uid: '' + rootStore.getProfile?.id,
                    uuid: boardUuid,
                    roomToken: roomToken,
                    userPayload: {
                        nickName: "foo",
                    },
                    isWritable: mine
                },
                managerConfig: {
                    cursor: true,
                },
            });

            setFastboard(fastboardCur);
            setFastboardUI(createUI(fastboardCur, document.getElementById("whiteboardSDKElement")));
    
            apps.clear();
            apps.push({
                icon: "https://chewing1.com/assets/image/icon_file_upload.png",
                kind: "Plyr",
                label: "Upload",
                onClick: (app) => {
                    console.log('upload button clicked!');
                    // app.insertImage("https://api.chewing1.com/uploads/room/04.png")
                    const docElement = document.querySelector('.telebox-box');
                    if (docElement) {
                        (document.querySelector('.telebox-box .telebox-titlebar .telebox-titlebar-icon-close') as HTMLElement).click();
                    } else {
                        if (fileRef?.current) {
                            fileRef?.current.click();
                        }
                    }
                }
            });
    
            setIsRecording(true);
            requestAnimationFrame(loop);
            recorder?.start();
        }

        if (boardUuid != "") {
            context.get(
                'room/getWhiteBoardRoomToken',
                {
                    role: mine ? 'writer' : 'reader',
                    uuid: boardUuid
                },
                response => {
                    joinWhiteBoardRoom(response.token);
                }
            );
        }

        return () => {
            if (fastboard != null) {
                fastboard.destroy();
                setFastboard(null);
                fastboardUI.destroy();
                setFastboardUI(null);
            }
        }
    }, [boardUuid])

    useEffect(() => {
        const settingDevice = async() => {
            if (mine) {
            const micList = await AgoraRTC.getMicrophones().catch(err => { return [];});        
            setMics(micList)
            setisUsingMic(rootStore.getProfile.speaker == "ON" && micList.length > 0);

            const cameraList = await AgoraRTC.getCameras().catch(err => { return [];});
            setCams(cameraList);
            setisUsingVideo(rootStore.getProfile.video == "ON" && cameraList.length > 0);
        }
        
    }
    settingDevice();
    },[mine])

    useEffect(() => {
        const settingAudioTrack = async () => {
            const audioTrackNow = await AgoraRTC.createMicrophoneAudioTrack().catch(() => null);
            if (audioTrackNow) {
                const audioTrackLabel = audioTrackNow.getTrackLabel();
                const currentMic = mics.find(item => item.label === audioTrackLabel);    
                setCurMic(currentMic || mics[0]);
                if (!currentMic && mics[0] && typeof audioTrackNow.setDevice === 'function' ) audioTrackNow.setDevice(mics[0].deviceId);
                setOwnerAudioTrack(audioTrackNow);
            }
        }
        settingAudioTrack();
    },[mics])

    useEffect(() => {
        if (!mine || !ownerAudioTrack || !isJoined) return;
        if (isUsingMic) {
            agoraEngine.publish(ownerAudioTrack);
        } else {
            agoraEngine.unpublish(ownerAudioTrack);
        }
    },[mine, isUsingMic, ownerAudioTrack, isJoined])

    useEffect(() => {
        const settingVideoTrack = async () => {
            const videoTrackNow = await AgoraRTC.createCameraVideoTrack().catch(() => null);
            if (videoTrackNow) {
                const videoTrackLabel = videoTrackNow.getTrackLabel();
                const currentCam = cams.find(item => item.label === videoTrackLabel);
                setCurCam(currentCam || cams[0]);
                if (!currentCam && cams[0] && typeof videoTrackNow.setDevice === 'function') videoTrackNow.setDevice(cams[0].deviceId);
                setOwnerVideoTrack(videoTrackNow);
            }
        }

        if (!mine) {return;}
        settingVideoTrack();
    }, [cams, mine])

    useEffect(() => {
        if (!mine || !agoraEngine || !isJoined) return;

        console.log('isUsingVideo =>', isUsingVideo);
        if (isUsingVideo && !screenShare) {
            ownerVideoTrack?.stop('owner-player');
            console.log('ownerVideoTrack =>', ownerVideoTrack);
            agoraEngine.publish(ownerVideoTrack);
            ownerVideoTrack?.play('owner-player');
        } else {
            console.log('unpublish ownerVideoTrack =>', ownerVideoTrack);
            agoraEngine.unpublish(ownerVideoTrack);
            ownerVideoTrack?.stop('owner-player');
        }

        if(screenShare && screenTrack) {
            screenTrack.play('screenShareElement');
            agoraEngine.publish(screenTrack);
        } else {  
            agoraEngine.unpublish(screenTrack);
            screenTrack?.stop();
            screenTrack?.close();
        }
    }, [ mine, isUsingVideo, ownerVideoTrack, screenShare, agoraEngine, isJoined, screenTrack]);

    useEffect(() => {
        if(mine || !isJoined) {return;}

        if (screenShare) {
            ownerVideoTrack?.stop('owner-player');
            ownerVideoTrack?.play('screenShareElement');
        } else {
            ownerVideoTrack?.stop('screenShareElement');
            ownerVideoTrack?.play('owner-player');
        }
    },[screenShare, ownerVideoTrack, mine, publishStatus, isJoined])

    useEffect(() => {
        if (!reaction) return;
    
        const timer = setTimeout(() => {
            setReactionTime(prevTime => prevTime + 1);
        }, 1000);
    
        // `reactionTime`이 3이 되면 상태를 초기화하고 소켓 이벤트 전송
        if (reactionTime === 3) {
            clearTimeout(timer);
            setReactionTime(0);
            setReaction(null);
            socket.emit("reaction", { room: roomId, reaction: "" });
        }
    
        return () => clearTimeout(timer);
    }, [reaction, reactionTime]); 

    const onClickReaction = (item) => {
        socket.emit("reaction", { room: roomId, reaction: item });
        setReaction(item)
        setReactionTime(0);
    }



    const addNotification = (notificationType, notificationTitle, notificationMessage="") => {
        Store.addNotification({
            title: notificationTitle,
            message: notificationMessage,
            type: notificationType,
            insert: 'top',
            container: 'top-left',
            dismiss: {
                duration: 1000,
            }
        });
    };

    const onSettingConfirm = async (video, speaker, reverse, camIdx = -1, micIdx = -1) => {
        if (curCam && camIdx !== -1 && curCam.label != cams[camIdx].label) {
            setCurCam(cams[camIdx]);
            await ownerVideoTrack.setDevice(cams[camIdx].deviceId);
        }

        if (curMic && micIdx !== -1 && curMic.label != mics[micIdx].label) {
            setCurMic(mics[micIdx]);
            await ownerAudioTrack.setDevice(mics[micIdx].deviceId);
        }

        setScreenSetting(video, speaker, reverse);
        setShowSettingModal(false);
        rootStore.setScreenSetting(video, speaker, reverse)

        context.post(
            'room/updateSetting',
            {
                video: video ? "ON" : "OFF",
                speaker: speaker ? "ON" : "OFF",
                mirror: reverse ? "ON" : "OFF",
            },
        );
    }

    const setScreenSetting = async (video, speaker, reverse) => {
        if (isUsingVideo != video && ownerVideoTrack) {
            socket.emit("video", { room: roomId, video: video });
            setisUsingVideo(video);
        }

        if (isUsingMic != speaker && ownerAudioTrack) {
            socket.emit("speaker", { room: roomId, speaker: speaker});
            setisUsingMic(speaker);
        }

        if (isMirrored != reverse) {
            socket.emit("cam mirror", { room: roomId, reverse: reverse });
            setisMirrored(reverse);
        }
    }

    const onRemoveRoom = () => {
        context.post(
            'room/delete',
            {
                id: roomId
            },
            response => {
                props.history.push("/conference")
            }
        );
    }

    const onInvite = () => {
        const tempElem = document.createElement('textarea');
        tempElem.value = document.location.href;
        document.body.appendChild(tempElem);
        tempElem.select();
        document.execCommand("copy");
        document.body.removeChild(tempElem);
        addNotification('success', '', strings.url_copied)
    }

    const listRef = useRef(memberList);

    // 💡 hold a reference to video element
    const screenShareVideoRef = useRef(null);
    const fileRef = useRef<HTMLInputElement>(null);

    const stopRecording = () => {
        setIsRecording(false);

        if (chunks.length == 0) {
            return;
        }

        const recordedBlob = new Blob(chunks, { type: chunks[0].type });

        // 💡 manually compute duration, and patch the blob
        const duration = performance.now() - recordStartTime;
        fixWebmDuration(recordedBlob, duration, (patchedBlob) => {
            saveRecordedFile(patchedBlob);
        })
    }

    const saveRecordedFile = (blob) => {
        console.log('saveRecordedFile blob =>', blob);
        let fileName = moment().format('YYYYMMDD_hhmmss');
        let mimeType = '.webm';
        let entry = new File([blob], fileName + mimeType, {
            type: 'video/webm'
        });

        let formData = new FormData();
        formData.append('file', entry);

        context.post(
            "upload/file/cloud",
            formData,
            response => {
                let type = "/assets/image/icon_file_drive.png";
                let list = [{ title: fileName + "_레코딩" + mimeType, size: blob.size, type: type, url: response.file }]
                context.post(
                    "cloud/add",
                    {
                        file_data: list
                    },
                    response => {
                        console.log('recording uploaded');
                        window.location.reload();
                    }
                );
            }
        );
    }

    const leavePage = () => {
        recorder?.stop();
        setTimeout(() => {
            props.history.push("/conference")
        }, 1000)
    }

    const onShare = useCallback(async () => {
        if (boardSplit) {return;}

        if (isUsingVideo) {
            onSettingConfirm(false, isUsingMic, isMirrored);
            setisUsingVideo(false);
        }

        if (screenShare) {
            // 화면 공유 중지 상태를 방에 알리기
            const message = {
                room: roomId,
                user: rootStore.getProfile.id,
                status: false,
            };
            socket.emit("screen share", message);
            await agoraEngine.unpublish(screenTrack);
            screenTrack.stop();
            setScreenTrack(null);
        } else {
            const screenTrackCur = await AgoraRTC.createScreenVideoTrack({
                displaySurface: 'window',
                selfBrowserSurface: 'exclude',
            }).catch((err) => {
                console.error('createScreenVideoTrack failed', err);
                return null;
            });
    
            if (screenTrackCur) {
                // `track-ended` 이벤트 핸들러 등록
                screenTrackCur.on('track-ended', async () => {
                    const message = {
                        room: roomId,
                        user: rootStore.getProfile.id,
                        status: false,
                    };
                    socket.emit("screen share", message);
    
                    await agoraEngine.unpublish(screenTrackCur);
                    screenTrackCur.stop();
                    setScreenTrack(null);
                });
    
                setScreenTrack(screenTrackCur);
                const message = {
                    room: roomId,
                    user: rootStore.getProfile.id,
                    status: true,
                };
                socket.emit("screen share", message);
                setTimeout(async () => {
                    await agoraEngine.publish(screenTrackCur);
                }, 2000);
                screenTrackCur.play('whiteboardSDKElement');
            }
        }
    }, [screenShare, screenTrack, socket, agoraEngine, boardSplit, isUsingVideo]);

    const onSplit = () => {
        socket.emit("screen split", { room: roomId, split: !boardSplit });
        if (boardSplit) {
            onShare();
        } else {
            if (!screenShare) {
                onShare();
            }
        }
    }

    const onFileChange = (e) => {
        if (e.target.files.length < 1) {
            return;
        }
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.onloadend = function () {
            uploadDocFile(reader.result, file);
        };
        reader.readAsDataURL(file);
    };

    const uploadDocFile = (file, url) => {
        let formData = new FormData();
        formData.append('file', url);

        context.post(
            "upload/file/whiteboard",
            formData,
            response => {
                let uploadedUrl = response.file;
                context.toggleLoadingBar(true);
                getWhiteBoardToken(uploadedUrl);
            }
        );

        // 파일변환을 위한 화이트보드 토큰을 요청하는 함수
        const getWhiteBoardToken = async (url) => {
            const result = await axios.post("https://api.netless.link/v5/tokens/teams", {
                "accessKey": AGORA_WHITEBOARD_AK,
                "secretAccessKey": AGORA_WHITEBOARD_SK,
                "lifespan": 0,
                "role": "admin"
            }, {
                headers: {
                    "region": AGORA_WHITEBOARD_REGION,
                    'Content-Type': 'application/json'
                }
            });

            if (result.status == 201) {
                const token = result.data;
                convertDocFile(url, token);
            } else {
                // 오류 발생
                context.toggleLoadingBar(false);
            }
        }
        
        // 서버에 업로드한 파일을 페이지별로 이미지로 변환 요청하는 함수
        const convertDocFile = async (url, token) => {
            const result = await axios.post("https://api.netless.link/v5/projector/tasks", {
                "resource": context.loadImage(url),
                "type": "static",
                "scale": 3,
            }, {
                headers: {
                    "token": token,
                    "region": AGORA_WHITEBOARD_REGION,
                    'Content-Type': 'application/json'
                }
            });
    
            if (result.status == 201) {
                const uuid = result.data.uuid;
                setTimeout(() => {
                    getConvertedList(token, uuid);
                }, 5000);
            } else {
                // 오류 발생
                context.toggleLoadingBar(false);
            }
        }

        // 변환된 이미지 목록을 가져오는 함수
        const getConvertedList = async (token, uuid) => {
            const result = await axios.get("https://api.netless.link/v5/projector/tasks/" + uuid, {
                headers: {
                    "token": token,
                    "region": AGORA_WHITEBOARD_REGION,
                    'Content-Type': 'application/json'
                }
            });

            if (result.status == 200) {
                if (result.data.status == 'Converting') {
                    setTimeout(() => {
                        getConvertedList(token, uuid);
                    }, 5000);
                } else if (result.data.status == 'Finished') {
                    context.toggleLoadingBar(false);
                    showDocs(uuid, result.data.images, result.data.pageCount);
                }
            } else {
                // 오류 발생
                context.toggleLoadingBar(false);
            }
        }

        const showDocs = async (uuid, images, pageCount) => {

            let scenes = [];
            for (let idx = 1; idx <= pageCount; idx++) {
                const key = `'${idx}'`;
                const imageData = images[idx];
    
                let scene = {
                    name: key,
                    ppt: {
                        width: imageData.width,
                        height: imageData.height,
                        src: imageData.url,
                    }
                }
    
                scenes.push(scene);
            }
    
            await fastboard.insertDocs({
                title: '',
                fileType: 'pdf',
                scenePath: '/pdf/' + uuid,
                scenes: scenes,
            })
        }
    }
    
    console.log(curCam, mine, ownerVideoTrack);

    return (
        <React.Fragment>
            <HeaderBlack
                roomTitle={roomInfo?.title}
                owner={roomInfo?.user?.name}
                isUsingSetting={mine}
                onHide={
                    () => setShowBottom(!showBottom)
                }
                isUsingMic={isUsingMic}
                toggleUsingMic={() => {
                    setisUsingMic(!isUsingMic);
                    onSettingConfirm(isUsingVideo, !isUsingMic, isMirrored); 
                    
                }}
                isUsingVideo={isUsingVideo}
                toggleUsingVideo={ () => {
                    setisUsingVideo(!isUsingVideo)
                    onSettingConfirm(!isUsingVideo, isUsingMic, isMirrored);
                }}

                onMirror={() => {
                    setisMirrored(!isMirrored);
                    onSettingConfirm(isUsingVideo, isUsingMic, !isMirrored);
                }}
            />
            <div className="display-flex-important" id='conferenceRoom'>
                <ConferenceRight
                    mine={mine}
                    showBottom={showBottom}
                    ownerVideoTrack={ownerVideoTrack}
                    ownerAudioTrack={ownerAudioTrack}
                    ownerProfile={roomInfo?.user?.profile != null && roomInfo?.user?.profile != "" ? context.loadImage(roomInfo?.user?.profile) : "/assets/image/logo_big.png"}
                    ownerReactionUrl={memberList.find(item => item.user.id == roomInfo?.user?.id)?.reaction?.icon}
                    memberList={memberList}
                    onSendChat={(message, receiver?, receiver_name?) => {
                        socket.emit('new message', { room: roomId, message: message, receiver, receiver_name, sender_name: rootStore.getProfile.name, sender_profile: rootStore.getProfile.profile });
                    }}
                    isOwnerMirrored={memberList.find(item => item.user.id == roomInfo?.user?.id)?.user.mirror == "ON"}
                    chatList={chatList}
                />
                <ConferenceTop
                    mine={mine}
                    initHide={memberList.length == 0}
                    memberList={memberList}
                    reaction={reaction}
                    muteVideo={!isUsingVideo}
                    reverseCamera={isMirrored}
                    name={rootStore.getProfile.name}
                    profile={rootStore.getProfile.profile != null && rootStore.getProfile.profile != "" ? context.loadImage(rootStore.getProfile.profile) : "/assets/image/logo_big.png"}
                    splitBoard={boardSplit}
                    screenTrack={screenTrack}
                    screenShare={screenShare}
                    myShare={screenShare}
                    isStudent={rootStore.getProfile?.user_type == 'STUDENT'}
                    showBoard={boardUuid != '' && !screenShare}
                />
                {showBottom && <ConferenceBottom
                    mine={mine}
                    memberList={memberList}
                    onSetting={() => setShowSetting(true)}
                    onOut={() => setLeaveAlert(true)}
                    owner={roomInfo?.user}
                    participants={memberList.length}
                    title={roomInfo?.title}
                    onClickReaction={(item) => onClickReaction(item)}
                    onInvite={() => onInvite()}
                    onKick={(item) => socket.emit("kickout", { room: roomId, member: item.member })}
                    onInfo={(item) => {
                        setMemberInfo(item);
                    }}
                    onShare={() => {
                        onShare();
                    }}
                    onSplit={() => {
                        onSplit();
                    }}
                    isSplit={boardSplit}
                    isShare={screenShare}
                    roomOwner={roomInfo?.user?.id}
                />}
                <input ref={fileRef} type='file' accept='application/pdf' className='hide' onChange={(e) => onFileChange(e)} />
                <video ref={screenShareVideoRef} autoPlay playsInline muted style={{ width: '95%', height: '95%', position: 'absolute', left: 0, top: 0, zIndex: -1 }} />
                {
                    reaction &&
                    <div className="reaction-alarm">
                        <div className="reaction-alarm-content">
                            <img src={reaction.icon} alt="" />
                            {reaction.desc}
                        </div>
                    </div>
                }
                <NewSettingModal
                    show={showSetting}
                    toggle={() => setShowSetting(!showSetting)}
                    mine={mine}
                    onScreenSettings={() => {
                        setShowSetting(false);
                        setShowSettingModal(true);
                    }}
                    onRoomInfo={() => {
                        setShowSetting(false);
                        setShowRoomInfo(true);
                    }}
                    onRemove={() => {
                        setShowSetting(false);
                        setDeleteAlert(true);
                    }}
                />
                <RoomInfoModal
                    show={showRoomInfo}
                    mine={mine}
                    toggle={() => setShowRoomInfo(!showRoomInfo)}
                    onBack={() => {
                        setShowRoomInfo(false);
                        setShowSetting(true);
                    }}
                    roomInfo={roomInfo}
                />
                {memberInfo && <MemberInfoModal
                    memberInfo={memberInfo}
                    setMemberInfo={setMemberInfo}
                />}
                {
                    showSettingModal &&
                    <ScreenSettingModal
                        show={showSettingModal}
                        name={rootStore.getProfile.name}
                        profile={profile}
                        cameraType={'전면'}
                        toggle={() => setShowSettingModal(!showSettingModal)}
                        close={() => setShowSettingModal(false)}
                        video={isUsingVideo}
                        speaker={isUsingMic}
                        reverse={isMirrored}
                        cams={cams}
                        mics={mics}
                        curCam={curCam}
                        curMic={curMic}
                        onConfirm={(video, speaker, reverse, camIdx, micIdx) => {
                            onSettingConfirm(video, speaker, reverse, camIdx, micIdx)
                        }}
                        onBack={() => {
                            setShowSettingModal(false);
                            setShowSetting(true);
                        }}
                    />
                }
                {(leaveAlert &&
                    <SweetAlert showCancel
                        confirmBtnText={strings.leave}
                        confirmBtnBsStyle="primary"
                        cancelBtnText={strings.cancel}
                        cancelBtnBsStyle="default"
                        title={""}
                        customClass="alert-black alert-leave"
                        onConfirm={() => leavePage()}
                        onCancel={() => setLeaveAlert(!leaveAlert)}
                        confirmBtnCssClass="alert-confirm"
                        cancelBtnCssClass="alert-cancel"
                    >
                        {'나가시겠습니까?'}
                    </SweetAlert>
                )}
                {(deleteAlert &&
                    <SweetAlert showCancel
                        confirmBtnText={'제거'}
                        confirmBtnBsStyle="primary"
                        cancelBtnText={strings.cancel}
                        cancelBtnBsStyle="default"
                        title={""}
                        customClass="alert-black alert-leave"
                        onConfirm={() => { onRemoveRoom() }}
                        onCancel={() => setDeleteAlert(!deleteAlert)}
                        confirmBtnCssClass="alert-confirm"
                        cancelBtnCssClass="alert-cancel"
                    >
                        {'룸을 제거하시겠습니까?'}
                    </SweetAlert>
                )}
                <canvas id='recordingCanvas' style={{ width: window.innerWidth, height: window.innerHeight - 70, position: "absolute", zIndex: "-1" }}></canvas>
            </div>
        </React.Fragment>
    );
})

export default withRouter(inject('rootStore')(ChallengeRoom));
