import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import CustomSearch from '../../../components/control/custom_input/custom_search';
import strings from '../../../lang/strings';
import CloudContainer from '../../cabinet/cloud_container';
import './quiz.scss'
import moment from 'moment';
import { Page_settings } from '../../../config/page_settings';
import { rootStore } from '../../../mobx/store';
import WarningModal from '../../../components/control/modal/start_exam_modal';
import StartQuizModal from './start_quiz_modal';

const Quiz = observer((props) => {
  const classId = props.match.params.class_id;

  const context = useContext(Page_settings);

  const history = useHistory();
  const [filter, setFilter] = useState(0);
  const [quizList, setQuizList] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [selectedItem, setSelectedItem] = useState({});
  const [showStartModal, setShowStartModal] = useState(false);
  const [startQuizModal, setStartQuizModal] = useState(false);
  const user_type = rootStore.getProfile?.user_type;
  console.log(classId);
  // 한국어 로케일 설정
  moment.locale('ko');

  useEffect(() => {
    getExamList();
  }, [filter]);

  useEffect(()=> {
    getExamList();
  },[startQuizModal]);

  const getExamList = () => {
    context.get(
      'quiz/list',
      {
        class_id: classId,
        status: filter
      },
      response => {
        setQuizList(response.list);
      }
    );
  }

  const onAdd = () => {
    history.push("/quiz/" + classId + "/add/0");
  };

  const handleClick = (item) => {

    if (rootStore.getProfile?.user_type === 'STUDENT'){
      return false;
    }
    
    if (item.status == 0) { // 임시저장된 시험인 경우 작성화면으로 이동
      history.push("/quiz/" + classId + "/add/" + item.id);
    } else {
      if (rootStore.getProfile?.user_type === 'STUDENT') {
        if (item.is_participate == 1) {
          history.push("/quiz/student/" + item.id);
        } else {
          setSelectedItem(item);
          setShowStartModal(true);
        }
      } else {
        history.push("/quiz/teacher/" + item.id)
      }
    }
  }

  const clickQuizButton = (item) => {
    const status = item.quiz_status;
    if (status === 0) { // 미제출
      return false;
    }else if (status === -1) { // 응시 하기
      setSelectedItem(item);
      setShowStartModal(true);
    }
    else { //결과 보기
      history.push("/quiz/student/" + item.id);
    }
  }

  const subjectClass = (subject) => {
    switch (subject) {
      case '사회':
        return 'society';
      case '국어':
        return 'korean';
      case '수학':
        return 'math';
      case '과학':
        return 'science';
      case '영어':
          return 'english';
      default:  
        return '';
    }
  };

  return (

    <AppWrap>
      <div className="main-content quiz-list">
        {/* <CloudContainer/> */}
        <div className="exam-head">
          <div className="exam-title">
            퀴즈방 <div className='exam-new-badge'>BETA</div>
          </div>
          {
            rootStore.getProfile?.user_type === 'EXPERT' &&
            <Button onClick={onAdd}><img src={"/assets/image/icon_plus_white.png"} style={{ marginRight: 10, height: 10 }} /> 퀴즈 출제</Button>
          }
        </div>

        {/* {
          <CustomSearch
            placeholder={strings.exam_search_placeholder}
            inputType={'text'}
            value={keyword}
            onChange={(v) => {
              setKeyword(v)
            }}
            onSearch={() => {
            }}
          />

        } */}

        <div className="col-md-12" style={{ textAlign: 'start', padding: 0 }}>
          {
            strings.exam_filter_list.map((item, idx) => {
              return (
                <button
                  key={idx}
                  className={"exam-filter " + (filter == item.code ? "selected" : "")}
                  onClick={() => setFilter(item.code)}>
                  {item.name}
                </button>
              )
            })
          }
        </div>

        {quizList.length > 0 ? (
          <div className="quiz-list-container">
            {quizList.map((quiz, idx) => {
              const open = moment(quiz.start_time).format("YYYY-MM-DD HH:mm:ss") < moment().format("YYYY-MM-DD HH:mm:ss") || user_type === 'EXPERT';
              return (
                <div key={idx} className={"quiz-list-item" + (quiz.status == 0 ? ' temp' : '') + (user_type === 'EXPERT' ? ' cursor' : '')}  onClick={() => handleClick(quiz)}>
                  <div className='d-flex' style={{ justifyContent: 'space-between' }}>
                    <div className='title'>{quiz.title}</div>
                    <div className={'date ' + (quiz.status == 0 ? 'temp' : quiz.status == 2 ? 'ended' : '')}>{
                      quiz.status == 0 ? '임시저장' : quiz.status == 2 ? '마감' :
                        (moment(quiz.start_time).format('MM-DD HH:mm') + '~' + moment(quiz.end_time).format('MM-DD HH:mm'))  
                    }</div>
                  </div>
                  <div style={{ width: 'max-content' }}>
                    <div className={`subject ${subjectClass(quiz.subject)}`}>{quiz.subject}</div>
                  </div>
                  <div className='d-flex align-items-center' style={{ justifyContent: 'space-between', marginTop: 'auto' }}>
                    <div className="items">{quiz.count + '문항'}</div>
                    <div className='d-flex align-items-center'>
                      {quiz.quiz_status === undefined? 
                      <>
                        <div className='testers active'>{quiz.participates + '명 / '}</div>
                        <div className='testers'>{quiz.testers.split(',').length + '명'}</div>
                        </>
                      : 
                        <button className={quiz.quiz_status === 1 ? 'result-btn' : (quiz.quiz_status === 0 || !open) ? 'not-submit-btn' : 'take-quiz-btn'} onClick={()=> {
                          if (!open) {return;}
                          clickQuizButton(quiz)}}
                          style={{ cursor: open ? "pointer": "default"}}>
                          {quiz.quiz_status === 1 ? '결과 보기' : quiz.quiz_status === 0 ? '미제출' : 
                            quiz.start_time > moment().format("YYYY-MM-DD HH:mm:ss") ? "시작 전" : '응시 하기' }
                        </button>
                      }
                      
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        ) :
          (
            <div className="exam-nothing">
              <img className="exam-nothing-img" src="/assets/image/class_nothing.png" />
            </div>
          )}
      </div>
      {
        showStartModal &&
        <WarningModal 
          show={showStartModal}
          toggle={() => setShowStartModal(!showStartModal)}
          onStart={() => {
            setShowStartModal(false);
            setStartQuizModal(true);
            setSelectedItem(selectedItem);
          }}
          mode={"quiz"}
        />
      }
{
        startQuizModal &&
        <StartQuizModal 
          show={startQuizModal}
          // toggle={() => setStartQuizModal(!startQuizModal)}
          quizId={selectedItem.id}
          title={selectedItem.title}
          onClose={()=>{setStartQuizModal(false)}}
        />
      }
    </AppWrap>

  );
})

const Button = styled.button`
  font-size: 13px;
  padding: 9px 14px 9px 9px;
  border: none;
  background-color: #0F84F4;
  border-radius: 6px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  line-height: 13px;
  // &:hover {
  //   background-color: #fac2be;
  // }
`;

const AppWrap = styled.div`
  text-align: center;
  margin: 10px auto;
  position: relative;
  width: 100%;
  height: 100vh; /* 화면 높이의 100%로 설정하여 화면 전체를 채우도록 만듭니다. */
`;

export default withRouter(Quiz);
