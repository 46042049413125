import { gapi, loadClientAuth2 } from 'gapi-script';
import { inject } from 'mobx-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import GoogleLogin from 'react-google-login';
import { withRouter } from 'react-router-dom';
import { GOOGLE_CLIENT_ID, NAVER_CALLBACK_URL, NAVER_CLIENT_ID, RESULT_CODE } from '../../config/const';

import { Page_settings } from '../../config/page_settings';
import strings from '../../lang/strings';

const Login = ({ rootStore, history }) => {
    const context = useContext(Page_settings);
    const idRef = useRef();

    const naverRef = useRef()
    const { naver } = window;

    const [id, setId] = useState('');
    const [pwd, setPwd] = useState('');
    const [saveId, setSaveId] = useState(false);

    useEffect(() => {
    const userInfoString = localStorage.getItem("user_info");
    const userInfo = JSON.parse(userInfoString);

    // 'id' 키가 있는지 확인합니다.
    if (userInfo && userInfo.hasOwnProperty('id')) {
        history.push("/class"); // "/class" 페이지로 리디렉션
    }

        initializeGoogleLogin();
        initializeNaverLogin();
        context.handleSetPageHeader(false);
        context.handleSetPageSidebar(false);

        idRef?.current?.focus();

        return () => {
            context.handleSetPageHeader(true);
            context.handleSetPageSidebar(true);
        }
    }, []);

    const initializeGoogleLogin = async () => {
        const auth2 = await loadClientAuth2(gapi, GOOGLE_CLIENT_ID, '');
    }

    const initializeNaverLogin = () => {
        const naverLogin = new naver.LoginWithNaverId({
            clientId: NAVER_CLIENT_ID,
            callbackUrl: NAVER_CALLBACK_URL,
            isPopup: false, // 팝업으로 띄울것인지 설정
            loginButton: { color: 'white', type: 1, height: '47' }, //버튼의 스타일, 타입, 크기를 지정
            callbackHandle: false,
        });
        naverLogin.init();
        naverLogin.logout();
        naverLogin.getLoginStatus(function (status) {
            if (status) {
                const email = naverLogin.user.getEmail();

                if (email === null || email === undefined) {
                    alert("이메일이 필요합니다. 정보제공을 동의해주세요.");
                    naverLogin.reprompt();
                    return;
                } else {
                    login("NAVER", email, "");
                }
            }
        });
    };

    const handleSubmit = e => {
        if(id == "" || pwd == "") return
        e.preventDefault();
        login("EMAIL", id, pwd)
    }

    const login = (type, email, pwd) => {
        context.post(
            'auth/login',
            {
                login_type: type,
                email: email,
                pwd: pwd
            },
            response => {
                if (Number(response.result_code) == RESULT_CODE.ERROR) {
                    onSignUp(type, email);
                    return;
                }
                rootStore.signIn(response);
                console.log("login" , response);
                context.get(
                    'member/getProfile',
                    {},
                    response => {
                        rootStore.setProfile(response.user_info);
                        rootStore.setSchool({
                            id: response.user_info.school_id,
                            name: response.user_info.school
                        });
                        rootStore.setHope(response.user_info.hope_school);
                        if(response.user_info.type !== "STUDENT"){
                            context.post(
                                'payment/getToken',
                                {},
                                response => {
                                    rootStore.setPaymentToken(response);
                                    history.push('/class');
                                }
                            )}else{
                                history.push('/class');        
                            }
                            
                    }
                );
            }
        );
        
    }

    const onSignUp = (login_type, email) => {
        rootStore.setSignUpInfo({
            login_type: login_type,
            email: email
        });
        history.push('/signup/1');
    }

    const onGoogleSuccess = (response) => {
        console.log(response);
        const email = response.wt.cu;
        login("GOOGLE", email, '');
    }

    const onGoogleFailure = (response) => {
        console.log(response);
    }

    const onNaverLogin = e => {
        e.preventDefault();

        naverRef.current.children[0].click()
    }

    return (
        <React.Fragment>
            <div id="header" className="header navbar-default login-header">
                <div className="navbar-header">
                    <img className="logo" src={'/assets/image/logo_chewing_plus.png'} alt="" />
                </div>
            </div>

            <div className="login-container">
                <div className="login-title">{strings.login.login}</div>

                <div className="login-content">
                    <form onSubmit={handleSubmit}>
                        <div className="login-item">{strings.email}</div>
                        <input
                            className="login-input m-b-40"
                            type="text"
                            ref={idRef}
                            autoComplete={'off'}
                            placeholder={strings.enter_your_email}
                            value={id}
                            onChange={e => setId(e.target.value)}
                            required />
                        <div className="login-item">{strings.password}</div>
                        <input type="password"
                            className="login-input m-b-15"
                            placeholder={strings.enter_your_password}
                            value={pwd}
                            onChange={e => setPwd(e.target.value)}
                            required />
                    </form>
                    <div className="login-save">
                        <button className="m-r-auto p-l-0" onClick={() => setSaveId(!saveId)}>
                            <img
                                src={saveId ? "/assets/image/icon_rect_check.png" : "/assets/image/icon_rect_check_normal.png"}
                                alt="" />
                            <div>{strings.save_id}</div>
                        </button>
                        <button onClick={() => history.push("find/0")}>{strings.ID} 찾기</button>
                        <div className="divider" />
                        <button className="p-r-0" onClick={() => history.push("find/1")}>{strings.password} 찾기</button>
                    </div>
                    <button onClick={handleSubmit}
                        className={"login-button " + ((id != "" && pwd != "") ? "login-button-enable" : "")}>{strings.login.login}</button>
                    <div className="other-login">
                        <GoogleLogin
                            clientId={GOOGLE_CLIENT_ID}
                            render={renderProps => (
                                <button className="sns-login m-r-25" onClick={renderProps.onClick} disabled={renderProps.disabled}>
                                    <img src={"assets/image/btn_google.png"} alt="" />
                                </button>
                            )}
                            buttonText=""
                            onSuccess={onGoogleSuccess}
                            onFailure={onGoogleFailure}
                            cookiePolicy={'single_host_origin'}
                        />
                        <button className="sns-login" onClick={onNaverLogin}>
                            <img src={"assets/image/btn_naver.png"} alt="" />
                            <div id='naverIdLogin' className='naver' ref={naverRef} />
                        </button>
                    </div>
                    <div className="new-to-chewing m-b-30">{strings.are_you_new}</div>
                    <button className="login-common-button m-b-40" onClick={() => onSignUp("EMAIL", "")}>
                        <div className="m-r-10">{strings.signup_and}</div>
                        <div className="check-out">{strings.try_for_free}</div>
                    </button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(inject('rootStore')(Login));
