import React, { useEffect, useState } from "react";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withRouter } from "react-router-dom";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import styled from "styled-components";
import EditThumbnailModal from "./edit_thumbnail_modal";

const EditCurriculumStep2 = (props) => {
  const history = useHistory();
  const [chapterValue, setChapterValue] = useState('');
  const [partCategory, setPartCategory] = useState([]); // 대단원 항목 데이터
  const [selectedCategory, setSelectedCategory] = useState(0); // 인덱스 번호 
  const [editStatus, setEditStatus] = useState(false);
  const [openGuide, setOpenGuide] = useState(false)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.guide-img')) {
        setOpenGuide(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (props.curriculumData && props.curriculumData.parts) {
      const formattedParts = props.curriculumData.parts.map(part => ({
        id: part.id ? part.id : null,
        subject: part.subject,
        color: part.color,
        name: part.name,
        chapters: part.chapters || []
      }));
      setPartCategory(formattedParts);
    }
  }, [props.curriculumData]);

  const addChapter = () => {
    if (chapterValue.trim() === '') {
      setChapterValue('');
      return false;
    }
    const updatedParts = partCategory.map((part, index) => {
      if (index === selectedCategory) {
        const newChapters = [{ name: chapterValue },...part.chapters];
        
        return { ...part, chapters: newChapters };
      }
      return part;
    });
    
  
    props.updatePartCategory(updatedParts);
  
    setChapterValue('');
  };

  const changeInputValue = event => {
    setChapterValue(event.target.value);
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      addChapter();
    }
  };


  
  const handleRemoveClick = (index) => {
    // selectedCategory를 인덱스로 사용하여 해당 part를 가져옵니다.
    const updatedParts = partCategory.map((part, partIndex) => {
      if (partIndex === selectedCategory) {
        const newChapters = part.chapters.filter((_, i) => i !== index);
        return { ...part, chapters: newChapters };
      }
      return part;
    });
  
    props.updatePartCategory(updatedParts);
  };
  

  const handleUpClick = (index) => {
    if (index > 0) {
      const updatedParts = partCategory.map((part, partIndex) => {
        if (partIndex === selectedCategory) {
          const newChapters = [...part.chapters];
          [newChapters[index - 1], newChapters[index]] = [newChapters[index], newChapters[index - 1]];
          return { ...part, chapters: newChapters };
        }
        return part;
      });
  
      props.updatePartCategory(updatedParts);
    }
  };

  const handleDownClick = (index) => {
    // selectedCategory를 인덱스로 사용하여 해당 part를 가져옵니다.
    const selectedPart = partCategory[selectedCategory];
    
    if (index < selectedPart.chapters.length - 1) {
      const updatedParts = partCategory.map((part, partIndex) => {
        if (partIndex === selectedCategory) {
          const newChapters = [...part.chapters];
          [newChapters[index], newChapters[index + 1]] = [newChapters[index + 1], newChapters[index]];
          return { ...part, chapters: newChapters };
        }
        return part;
      });
  
      props.updatePartCategory(updatedParts);
    }
  };
  

  const handleCategoryClick = (index) => {
    setSelectedCategory(index);
  };

  const onNext = async () => {
    history.push(`/curriculum/edit_curriculum/${props.curriculum}/3`);
  }

  const EnableConfirm = () => {
    console.log("partCategory",partCategory)
    const areAllArraysNonEmpty = partCategory.every(
      part => part.chapters.length > 0
    );

    return areAllArraysNonEmpty;
  }

  const goBack = () => {
    history.push(`/curriculum/edit_curriculum/${props.curriculum}/1`);
   }
  

  return (
    <div className="main-content create-curriculum-step2-container">
      <div className="top content-container">
        <div className="progress-container">
          <div className="progress-box">
            <div className="progress"/>
          </div>
          <div className="step"><span>2</span> / 5</div>
        </div>
        <div className="flex-box">
          <div className="title-box">
          <img className="cursor" src="/assets/image/icon_arrow_back_black.png" alt="" onClick={()=>goBack()}/>
            <div className="title">
              {props?.curriculumData?.thumbnail?.title}
            </div>
            <img className="cursor" src="/assets/image/icon_edit_btn_gray.png" alt="" onClick={()=>setEditStatus(true)}/>
          </div>
          <div className="curriculum-guide-box">
            {/* <img src="/assets/image/icon_warning_point.png" alt="" onClick={()=>setWarningPoint(true)}/> */}
            <img src="/assets/image/icon_curriculum_guide.png" style={{width:'65px'}} alt="" onClick={()=>setOpenGuide(true)}/>
            {openGuide &&
            <img className="guide-img" src="/assets/image/img_curriculum_guide_2.png" alt="" />
    }
          </div>
        </div>
      </div>
      {editStatus && (<EditThumbnailModal
          open={editStatus}
          onClose={() => {
            setEditStatus(false);
          }}
          curriculumData={props.curriculumData}
          updateThumbnail={props.updateThumbnail}
        />)} 
      <div className="middle content-container">
        <div className="title">중단원 추가하기</div>
        {partCategory.length > 0 ?
          <div className="row-box">
            {partCategory.map((item, index) => (
              <div 
                key={index}
                className={`partCategory-box ${selectedCategory === index ? item.color : ''}`}
                onClick={() => handleCategoryClick(index)}
              >
                <div>{item.name}</div>
              </div>
            ))}
          </div>
          : null
        }
        <div className="class-search align-item-center box-shadow" style={{ flex: 8 }}>
          <input
            id='inputBox'
            className="input-transparent cursor"
            placeholder={"중단원 이름을 적어주세요"}
            inputType={'text'}
            value={chapterValue}
            onChange={changeInputValue}
            onKeyPress={handleKeyPress}
          />
          <img className='cursor add' src={"/assets/image/icon_plus_gray.png"} alt='' onClick={() => addChapter()} />
        </div>
      </div>
      <div className="bottom content-container">
        <div className="container-top">
          <div className="title">중단원 항목</div>
            <PerfectScrollbar className="partCategory-container" style={{ height: '620px' }} options={{ suppressScrollX: true }}>
              {partCategory[selectedCategory]?.chapters.map((item, index) => (
                <div className="row-box" key={index}>
                  <div class="arrow-buttons">
                    <button className="up-btn" onClick={() => handleUpClick(index)}>▲</button>
                    <button className="down-btn" onClick={() => handleDownClick(index)}>▼</button>
                  </div>
                  <div className="partCategory-box">
                    <div className="left">
                      <div className={`category ${item}`} />
                      <div>{item.name}</div>
                    </div>
                    <div className="right">
                      <img className="cursor" src={"/assets/image/icon_minus.png"} alt="" onClick={() => handleRemoveClick(index)} />
                    </div>
                  </div>
                </div>
              ))}
            </PerfectScrollbar>
        </div>
        <button className="next-btn" style={{ backgroundColor: EnableConfirm() ? '#0F84F4' : '#DFE3E8' }} disabled={!EnableConfirm()} onClick={() => onNext()}>다음으로</button>
      </div>
    </div>
  );
        }

const BalloonContainer = styled.div`
  position: relative;
  background: #fff;
  border-radius: 10px;
  padding: 30px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  margin: 20px;
  position: absolute;
  top: 20px;
  right: 40px;
  z-index: 9999;
  width: 350px;
  
  &:before {
    content: '';
    position: absolute;
    top: 0px;
    right: -8px;
    width: 10px;
    height: 10px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #fff;
  }
`;

const CloseButton = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const BalloonImage = styled.img`
  display: block;
  margin: 0 auto 10px;
`;

const BalloonText = styled.div`
  text-align: center;
  color: #71768C;

font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 25px; /* 156.25% */
`;

export default withRouter(EditCurriculumStep2);
