import { observer } from 'mobx-react';
import moment from 'moment';
import React, { ChangeEvent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import styled from 'styled-components';
import Button from '../../components/button';
import Calendar from '../../components/calendar';
import CalendarModal from '../../components/calendar_modal';
import Checkbox from '../../components/checkbox';
import IconButton from '../../components/icon_button';
import IconClock from '../../components/icons/clock';
import IconFlag from '../../components/icons/flag';
import IconMenu from '../../components/icons/menu';
import IconNotification from '../../components/icons/notification';
import Pencil from '../../components/icons/pencil';
import PlusIcon from '../../components/icons/plus';
import IconX from '../../components/icons/x';
import ImageCircle from '../../components/image_circle';
import SelectBox from '../../components/select_box';
import TextField from '../../components/text_field';
import { Page_settings } from '../../config/page_settings';
import colors from '../../design/colors';
import fonts from '../../design/typography';
import { rootStore } from '../../mobx/store';
import { IClassList } from '../../type/classroom';
import { getFilePath } from '../../utils/image';
import NoClassPage from '../no_class';

interface IClassSessionInfoResponse {
    remainingSessionNum: number;
    totalSessionNum: number;
    sessionStartDate: string;
    sessionCycleNum: number;
    sessionTimeInfo: {
        startTime: string;
        endTime: string;
        dayOfWeek: string;
    }[];
    lateTimeMinute: number;
    sessionDescription: string;
}

interface IClassSessionRequest {
    sessionNum: number;
    sessionStartDate: string;
    sessionCycleNum: number;
    sessionTimeInfo: {
        startTime: string;
        endTime: string;
        dayOfWeek: string;
    }[];
    lateTimeMinute: number;
    sessionDescription: string;
}

interface IClassSessionTimeInfo {
    id: number;
    classroomId: number;
    startTime: string;
    endTime: string;
    lateMinute: number;
    number? : number;
}

const dayOfWeekOptions = [
    {label: '월요일', value: 'MONDAY'},
    {label: '화요일', value: 'TUESDAY'},
    {label: '수요일', value: 'WEDNESDAY'},
    {label: '목요일', value: 'THURSDAY'},
    {label: '금요일', value: 'FRIDAY'},
    {label: '토요일', value: 'SATURDAY'},
    {label: '일요일', value: 'SUNDAY'},
]


const ClassSchedule = observer(() => {
    const classId = rootStore.getClassId;
    const context : any = useContext(Page_settings);
    const profile = rootStore.getProfile;
    const [classInfo, setClassInfo] = useState<IClassList | null>(null);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [classSessionInfo, setClassSessionInfo] = useState<IClassSessionInfoResponse | null>(null);
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [allSessionTimeInfoList, setAllSessionTimeInfoList] = useState<IClassSessionTimeInfo[]>([]);
    const [onMenuIndex, setOnMenuIndex] = useState<number>(-1);
    const [deleteSessionInfo, setDeleteSessionInfo] = useState<IClassSessionTimeInfo | null>(null);
    const [editSessionInfo, setEditSessionInfo] = useState<IClassSessionTimeInfo | null>(null);
    const userType = rootStore.getProfile?.userType;
    const navigate = useNavigate();
    useEffect(() => {

        if (!classId) {
            return;
        }

        context.get(`/class/${classId}`,{},(res: IClassList) => {
            setClassInfo(res);
        });

        context.get(`/classroom/${classId}/session-info`,{},(res: IClassSessionInfoResponse) => {
            setClassSessionInfo(res);
        });
    }, [classId]);

    useEffect(() => {
        if (!classId) {
            setAllSessionTimeInfoList([]);
            return;
        }
        context.get(`/classroom/${classId}/session`,{},(res: IClassSessionTimeInfo[]) => {
            setAllSessionTimeInfoList(res);
        });
    }, [classId]);

    if (!classInfo) {
        return  <NoClassPage userType={rootStore.getProfile?.userType ?? "STUDENT"}/>
    }

    const daysOfWithSession =allSessionTimeInfoList.map((item) => {
        return moment(item.startTime).format('YYYY-MM-DD');
    });

    const renderDayContents = (dayOfMonth: number, date: Date) => {
        const hasSession = daysOfWithSession.includes(moment(date).format('YYYY-MM-DD'));
        return (
            <div 
                className={hasSession ? 'has-session' : ''}
                style={{
                    display: 'flex', 
                    flexDirection: 'row', 
                    alignItems: 'center', 
                    justifyContent: 'center'
                }}
            >
                {dayOfMonth}
            </div>
        );
    }

    return <Container>
        {
            isEdit ? 
            <EditSection classInfo={classInfo} setClassInfo={setClassInfo} alreadyCreate={classSessionInfo ? true : false}/>
            :
            <>
                <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between'}}>
                    <ClassSection>
                        <ImageCircle size={88} src={getFilePath(classInfo?.thumbnail, '/assets/image/default_class.png')}/>
                        <div style={{display: 'flex', flexDirection: 'column', marginLeft: 16, gap: 12}}>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8}}>
                                <span style={{...fonts.body3Medium, color: colors.gray600}}>{classInfo?.corp || '-'}</span>
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: 4, height: 4, backgroundColor: colors.gray500, borderRadius: 4,}}/>
                                <span style={{...fonts.body3Medium, color: colors.gray600}}>{((classInfo?.memberName || profile?.name) + ' 선생님')}</span>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: 27, ...fonts.title2SemiBold, color: colors.gray900,}}>
                                {classInfo?.title || '클래스 이름'}
                            </div> 
                        </div>
                    </ClassSection>
                    <div style={{marginTop: 12}}>
                        {userType === "EXPERT" ? 
                        <IconButton size='medium' text='클래스 수정' icon={<Pencil width='20' height='20' color={colors.white}/>} onClick={() => {setIsEdit(true)}} width='104px' height='36px'/>
                    :
                    <div style={{marginTop: 12}}>
                        <Button text="입학원서" onClick={() => {navigate("/class/application-for-admission/info")}} size="small" width="77px" height="36px" />
                    </div>
                    }
                    </div>
                </div>
                <div style={{width: '100%', height: 1, backgroundColor: colors.gray100, marginTop: 28, marginBottom: 40}}/>
                {
                    classSessionInfo ? 
                    <div style={{display: 'flex', flexDirection: 'row', width: '100%', height: 'calc(100% - 200px)'}}>
                        <Calendar date={selectedDate} onChange={(date: Date) => {setSelectedDate(date)}} renderDayContents={renderDayContents}/>
                        <div style={{display: 'flex', flexDirection: 'column', width: 'calc(45% - 180.5px)', height: '100%', gap: 12, marginLeft: 12, overflowY: 'auto'}}>
                            {
                                allSessionTimeInfoList.map((item, index) => {

                                    const day = moment(item.startTime);

                                    return (
                                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: 120, backgroundColor: colors.white, padding: "24px 16px", gap: 8, borderRadius: 8, position: 'relative'}}>
                                            <span style={{...fonts.label4Medium, color: colors.gray600}}>{`${day.format('MM월 DD일')} ${day.format('ddd')}요일`}</span>
                                            <div style={{display: 'flex', flexDirection: 'row', width: '100%', gap: 12, justifyContent: 'space-between'}}>
                                                <span style={{...fonts.label3Medium, color: colors.gray900}}>{`${item.number}차시 수업`}</span>
                                                {userType === "EXPERT" && <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer'}} onClick={(e) => {
                                                    if (onMenuIndex === index) {
                                                        setOnMenuIndex(-1);
                                                    } else {
                                                        e.stopPropagation();
                                                        setOnMenuIndex(index);
                                                    }
                                                }}>
                                                    <IconMenu width='20px' height='20px' color={colors.gray900}/>
                                                </div>}
                                            </div>
                                            <span style={{...fonts.label4Medium, color: colors.gray600}}>
                                                {`${day.format("ddd")}요일 ${day.format('HH:mm')} - ${moment(item.endTime).format('HH:mm')}`}
                                            </span>
                                            {
                                                onMenuIndex === index && (
                                                    <MenuContainer>
                                                        <MenuItem isFirst={true} isLast={false} onClick={() => {
                                                            setEditSessionInfo(item);
                                                        }}>
                                                            일정 변경하기
                                                        </MenuItem>
                                                        <MenuItem isFirst={false} isLast={false} onClick={() => {
                                                            setDeleteSessionInfo(item);
                                                        }}>
                                                            일정 취소하기
                                                        </MenuItem>
                                                    </MenuContainer>
                                                )
                                            }
                                        </div>

                                    )
                                })
                            }
                        </div>
                        <div style={{width: 1, height: '100%', backgroundColor: colors.gray100, margin: "0 24px"}}/>
                        <div style={{display: 'flex', flexDirection: 'column', width: 'calc(55% - 180.5px)', height: '100%', gap: 12}}>
                            <div style={{display: 'flex', flexDirection: 'column', width: '100%', gap: 16, backgroundColor: colors.white, padding: "28px 24px", borderRadius: 8, height: 'calc(60% - 6px)'}}>
                                <span style={{...fonts.label3Medium, color: colors.gray900}}>CLASS</span>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', gap: 12, alignItems: 'center'}}>
                                    <IconNotification width='20px' height='20px' color={colors.gray900}/>
                                    <span style={{...fonts.label3Medium, color: classSessionInfo?.sessionDescription ? colors.gray900 : colors.gray400}}>{classSessionInfo?.sessionDescription || '클래스 설명을 입력해주세요'}</span>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', gap: 12, alignItems: 'center'}}>
                                    <IconClock width='24px' height='24px' color={colors.gray900}/>
                                    <span style={{...fonts.label3Medium, color: colors.gray900}}>{`${classSessionInfo?.totalSessionNum}차시`}</span>
                                    <div style={{width: 1, height: 9, backgroundColor: colors.gray400}}/>
                                    {
                                        classSessionInfo.sessionTimeInfo.map((item) => {
                                            return <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8}}>
                                                <span style={{...fonts.label3Medium, color: colors.gray900}}>{`${dayOfWeekOptions.find((option) => option.value === item.dayOfWeek)?.label}`}</span>
                                                <span style={{...fonts.label3Medium, color: colors.gray900}}>{`${item.startTime} - ${item.endTime}`}</span>
                                            </div>
                                        })
                                    }
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', gap: 12, alignItems: 'center'}}>
                                    <IconFlag width='20px' height='20px' color={colors.gray900}/>
                                    <span style={{...fonts.label3Medium, color: colors.gray900}}>{`${classSessionInfo?.sessionStartDate} 부터`}</span>
                                </div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', width: '100%', gap: 16, backgroundColor: colors.white, padding: "24px 16px", borderRadius: 8, height: 'calc(40% - 6px)'}}>
                                <span style={{...fonts.label4SemiBold, color: colors.gray900}}>MEMO</span>
                            </div>
                        </div>
                    </div>
                    :
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: 'calc(100% - 200px)', alignItems: 'center', justifyContent: 'center'}}>
                        <img src="/assets/image/sad_rai.png" style={{width: '152px', height: '113px'}}/>
                        <span style={{...fonts.body3Medium, color: colors.gray400, marginTop: 24}}>아직 등록된 클래스 정보가 없습니다</span>
                        <span style={{...fonts.body3Medium, color: colors.gray400}}>지금 바로 클래스 정보를 추가해보세요!</span>
                    </div>
                }
            </>
        }
        {
            deleteSessionInfo && (
                <DeleteSessionInfoModal toggle={() => {setDeleteSessionInfo(null)}} classSessionInfo={deleteSessionInfo} onConfirm={(cancelReason) => {
                    context.delete(`/classroom/${classId}/session/${deleteSessionInfo?.id}`,{
                        cancelReason: cancelReason
                    },(res: any) => {
                        setDeleteSessionInfo(null);
                    });
                }}/>
            )
        }
        {
            editSessionInfo && (
                <EditSessionInfoModal toggle={() => {setEditSessionInfo(null)}} classSessionInfo={editSessionInfo}/>
            )
        }
    </Container>;
});


const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 10px;
    overflow-y: auto;
`;

const ClassSection = styled.div`
    display: flex;
    flex-direction: row;
    height: 88px;
    align-items: center;
`;

const EditSessionInfoModal = (props: { toggle: () => void, classSessionInfo: IClassSessionTimeInfo}) => {
    
    const [startDateCalendarOpen, setStartDateCalendarOpen] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<string>(moment(props.classSessionInfo?.startTime).format("YYYY-MM-DD"));
    const [startTime, setStartTime] = useState<string>(moment(props.classSessionInfo?.startTime).format("HH:mm"));
    const [endTime, setEndTime] = useState<string>(moment(props.classSessionInfo?.endTime).format("HH:mm"));

    const context : any = useContext(Page_settings);
    const classId = rootStore.getClassId;
    const navigate = useNavigate();
    const onConfirm = () => {
        context.put(`/classroom/${classId}/session/${props.classSessionInfo?.id}`,{
            date: startDate,
            startTime: startTime,
            endTime: endTime,
        },(res: any) => {
            props.toggle();
            navigate(0);
        });
    }
    return (
        <StyledModal isOpen={true} toggle={props.toggle}>
            <ModalBody style={{display: 'flex', flexDirection: 'column', width: '100%', gap: 32}}>
                <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between'}}>
                    <span style={{...fonts.body2SemiBold, color: colors.gray900}}>일정 변경</span>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer'}} onClick={() => {props.toggle()}}>
                        <IconX width='24px' height='24px' color={colors.gray900}/>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                    <span style={{...fonts.label3SemiBold, color: colors.gray900, marginBottom: 16}}>변경 일자</span>
                    <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center'}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width:'calc(40% - 10px)', position: 'relative'}}>
                            <TextField value={startDate} size="small" complete={true}/> 
                            <Overlay disabled={false} onClick={() => setStartDateCalendarOpen(prev => !prev)}/>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width:'calc(15% - 10px)', marginLeft: 12}} >
                            <TextField value={startTime.split(":")[0]} size="small" onChange={(e: any) => {
                                const value = parseInt(e.target.value);
                                const minute = startTime.split(":")[1];
                                if (value < 0  || isNaN(value)) {
                                    setStartTime("00:"+minute);
                                } else if (value > 23) {
                                    setStartTime("23:"+minute);
                                } else {
                                    setStartTime(value.toString().padStart(2, '0')+":"+minute);
                                }
                            }} complete={true}/> 
                        </div>
                        <span style={{...fonts.body3Medium, color: colors.gray900, width: 20, textAlign: 'center'}}> : </span>
                        <TextField value={startTime.split(":")[1]} size="small" onChange={(e: any) => {
                            const value = parseInt(e.target.value);
                            const hour = startTime.split(":")[0];
                            if (value < 0  || isNaN(value)) {
                                setStartTime(hour+":00");
                            } else if (value > 59) {
                                setStartTime(hour+":59");
                            } else {
                                setStartTime(hour+":"+value.toString().padStart(2, '0'));
                            }
                        }} complete={true} width='calc(15% - 10px)'/> 
                            <span style={{...fonts.body3Medium, color: colors.gray900, width: 20, textAlign: 'center'}}> ~ </span>
                        <TextField value={endTime.split(":")[0].padStart(2, '0')} size="small" onChange={(e: any) => {
                            const value = parseInt(e.target.value);
                            const minute = endTime.split(":")[1];
                            if (value < 0  || isNaN(value)) {
                                setEndTime("00:"+minute);
                            } else if (value > 23) {
                                setEndTime("23:"+minute);
                            } else {
                                setEndTime(value.toString().padStart(2, '0')+":"+minute);
                            }
                        }} complete={true} width='calc(15% - 10px)'/> 
                        <span style={{...fonts.body3Medium, color: colors.gray900, width: 20, textAlign: 'center'}}> : </span>
                        <TextField value={endTime.split(":")[1]} size="small" onChange={(e: any) => {
                            const value = parseInt(e.target.value);
                            const hour = endTime.split(":")[0];
                            if (value < 0  || isNaN(value)) {
                                setEndTime(hour+":00");
                            } else if (value > 59) {
                                setEndTime(hour+":59");
                            } else {
                                setEndTime(hour+":"+value.toString().padStart(2, '0'));
                            }
                        }} complete={true} width='calc(15% - 10px)'/> 
                    </div>
                </div>
                <Button text='변경하기' onClick={() => {onConfirm()}} size='large' height='43px'/>
                {
                    startDateCalendarOpen && (
                        <div style={{position: 'absolute', top: 50, left: -250, width: '100%', height: '100%', zIndex: 1000}}>
                            <CalendarModal onClose={() => {setStartDateCalendarOpen(false)}} dateValue={startDate} setDateValue={(date) => {
                                setStartDate(date);
                            }} disablePreviousDays={true}/>
                        </div>
                    )
                }
            </ModalBody>
        </StyledModal>
    )
}

const DeleteSessionInfoModal = (props: {toggle: () => void, classSessionInfo: IClassSessionTimeInfo, onConfirm: (string) => void}) => {

    const {toggle, classSessionInfo} = props;

    const [cancelReason, setCancelReason] = useState('');

    return (
        <StyledModal isOpen={true} toggle={toggle}>
            <ModalBody style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', marginBottom: 32}}>
                    <span style={{...fonts.body2SemiBold, color: colors.gray900}}>
                        일정 취소
                    </span>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer'}} onClick={() => {props.toggle()}}>
                        <IconX width='24px' height='24px' color={colors.gray900}/>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: 16, width: '100%'}}>
                    <span style={{...fonts.label3SemiBold, color: colors.gray900}}>
                        날짜
                    </span>
                    <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center'}}>
                        <TextField value={moment(classSessionInfo?.startTime).format("YYYY-MM-DD")} size="small" disabled={true} width='calc(40% - 38px)'/> 
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width:'calc(15% - 10px)', marginLeft: 12}} >
                            <TextField value={moment(classSessionInfo?.startTime).format("HH")} size="small" disabled={true} /> 
                        </div>
                        <span style={{...fonts.body3Medium, color: colors.gray900, width: 20, textAlign: 'center'}}> : </span>
                        <TextField value={moment(classSessionInfo?.startTime).format("mm")} size="small" disabled={true} width='calc(15% - 10px)'/> 
                        <span style={{...fonts.body3Medium, color: colors.gray900, width: 26, textAlign: 'center'}}> ~ </span>
                        <TextField value={moment(classSessionInfo?.endTime).format("HH")} size="small" disabled={true} width='calc(15% - 10px)'/> 
                        <span style={{...fonts.body3Medium, color: colors.gray900, width: 20, textAlign: 'center'}}> : </span>
                        <TextField value={moment(classSessionInfo?.endTime).format("mm")} size="small" disabled={true} width='calc(15% - 10px)'/> 
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', width: '100%', marginTop: 32, marginBottom: 32}}>
                    <span style={{...fonts.label3SemiBold, color: colors.gray900, marginBottom: 16}}>
                        취소 사유
                    </span>
                    <TextField value={cancelReason} onChange={(e: any) => {setCancelReason(e.target.value)}}/>
                </div>
                <Button text='취소하기' onClick={() => {props.onConfirm(cancelReason)}} size='large' height='48px'/>
            </ModalBody>
        </StyledModal>
    )
}


const StyledModal = styled(Modal)`
    border-radius: 20px;
    padding: 20px 24px;
`;


const EditSection = ({classInfo, setClassInfo, alreadyCreate}: {classInfo: IClassList, setClassInfo: (classInfo: IClassList) => void, alreadyCreate: boolean}) => {

    const context : any = useContext(Page_settings);
    const classId = rootStore.getClassId;
    const navigate = useNavigate();

    const [classSessionInfo, setClassSessionInfo] = useState<IClassSessionInfoResponse>({
        remainingSessionNum: 0,
        totalSessionNum: 0,
        sessionStartDate: moment().format("YYYY-MM-DD"),
        sessionCycleNum: 1,
        sessionTimeInfo: [],
        lateTimeMinute: 0,
        sessionDescription: ""
    });
    const [selectDefaultThumbnail, setSelectDefaultThumbnail] = useState<boolean>(false);
    const imageRef = useRef<HTMLInputElement>(null);
    const [thumbnail, setThumbnail] = useState<File | null>(null);
    const [editingBasicInfo, setEditingBasicInfo] = useState<boolean>(false);
    const [editingSessionInfo, setEditingSessionInfo] = useState<boolean>(false);
    const [isStartDateCalendarOpen, setStartDateCalendarOpen] = useState<boolean>(false);
    const thumbnailUrl = useMemo(() => {
        if (thumbnail) {
            return URL.createObjectURL(thumbnail);
        }
        return null;
    }, [thumbnail]);

    const [sessionDiff, setSessionDiff] = useState<number>(0);

    const [editConfirmModalOpen, setEditConfirmModalOpen] = useState<boolean>(false);
    const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState<boolean>(false);
    const onImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setThumbnail(file);
            setEditingBasicInfo(true);
        }
    }

    const onDelete = () => {
        context.delete(`/class/${classId}`,{},(res: any) => {
            navigate(`/class`);
        });
    }

    useEffect(() => {
        context.get(`/classroom/${classId}/session-info`,{},(res: IClassSessionInfoResponse) => {
            if (res !== null) {
                setClassSessionInfo(res);
            }
        });
    },[classId]);

    useEffect(() => {
        if (classSessionInfo && classSessionInfo.totalSessionNum > 0) {
            setSessionDiff(classSessionInfo.totalSessionNum - classSessionInfo.remainingSessionNum);
        }
    }, [classSessionInfo]);


    const isSaveAvailable = useMemo(() => {
        if (!editingBasicInfo && !editingSessionInfo) {
            return false;
        }

        let ret = true;

        if (editingBasicInfo) {
            ret = ret && classInfo.corp && classInfo.corp.length > 0;
            ret = ret && classInfo.title && classInfo.title.length > 0;
            ret = ret && (selectDefaultThumbnail || (thumbnail !== null) || (classInfo.thumbnail !== "" && classInfo.thumbnail !== null));
        }

        if (editingSessionInfo) {
            if (!classSessionInfo) {
                return false;
            }

            ret = ret && classSessionInfo.remainingSessionNum > 0;
            ret = ret && classSessionInfo.sessionCycleNum > 0;
            ret = ret && classSessionInfo.sessionStartDate !== "";
            ret = ret && classSessionInfo.sessionTimeInfo.length > 0;
            ret = ret && classSessionInfo.sessionTimeInfo.every((item) => {
                return item.dayOfWeek !== ""
            });
        }
        return ret;
    }, [editingBasicInfo, editingSessionInfo, classInfo, classSessionInfo, thumbnail, selectDefaultThumbnail]);


    const onSave = async () => {
        const promises = [];

        if (editingBasicInfo) {
            if (thumbnail) {
                const formData = new FormData();
                formData.append("file", thumbnail);
                promises.push(new Promise((resolve) => {
                    context.post(`/upload/image/class`, formData, (res: any) => {
                        context.put(`/class/${classId}`, {
                            corp: classInfo.corp,
                            title: classInfo.title,
                            thumbnail: res.url,
                            memberName: classInfo.memberName
                        }, (res: any) => {
                            resolve(res);
                        });
                    });
                }));
            } else {
                promises.push(new Promise((resolve) => {
                    context.put(`/class/${classId}`, {
                        corp: classInfo.corp,
                        title: classInfo.title,
                        thumbnail: selectDefaultThumbnail ? null : classInfo.thumbnail,
                        memberName: classInfo.memberName,
                        isEditingThumbnail: selectDefaultThumbnail
                    }, (res: any) => {
                        resolve(res);
                    });
                }));
            }
        }

        if (editingSessionInfo) {
            const { remainingSessionNum, totalSessionNum, ...rest } = classSessionInfo;
            const requestbody: IClassSessionRequest = {
                ...rest,
                sessionTimeInfo: classSessionInfo?.sessionTimeInfo.map((item) => {
                    return {
                        ...item,
                        startTime: moment(item.startTime, 'HH:mm').format('HH:mm'),
                        endTime: moment(item.endTime, 'HH:mm').format('HH:mm'),
                    }
                }),
                sessionNum: remainingSessionNum
            };
            promises.push(new Promise((resolve) => {
                context.post(`/classroom/${classId}/session-info`, {
                    ...requestbody
                }, (res: any) => {
                    resolve(res);
                });
            }));
        }

        setEditConfirmModalOpen(false);
        await Promise.all(promises);
        navigate(0);
    }

    return <>
        <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'flex-end', justifyContent: 'space-between', marginBottom: 24}}>
            <span style={{...fonts.title3SemiBold, color: colors.gray900, marginBottom: 8}}>클래스 정보 수정</span>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 12}}>
                <Button size='medium' text='저장하기' onClick={() => {setEditConfirmModalOpen(true)}} width='88px' height='40px' disabled={!isSaveAvailable}/>
                <Button size='medium' text='클래스 삭제' onClick={() => {setDeleteConfirmModalOpen(true)}} width='112px' height='40px' buttonColor={colors.warning}/>
            </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', width: '100%', gap: 56, backgroundColor: colors.white, height: "calc(100% - 100px)", padding: 32, borderRadius: 20, overflowY: 'auto'}}>
            <div style={{display: 'flex', flexDirection: 'column', width: 'calc(50% - 28.5px)', gap: 32}}>
                <div style={{display: 'flex', flexDirection: 'column', gap: 16, width: '100%'}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 4}}>
                        <span style={{...fonts.body3SemiBold, color: colors.gray900}}>기관</span>
                        <RequriedCircle/>
                    </div>
                    <TextField value={classInfo.corp} onChange={(e: any) => {setClassInfo({...classInfo, corp: e.target.value}); setEditingBasicInfo(true);}} complete={classInfo.corp && classInfo.corp.length > 0}/>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: 16, width: '100%'}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 4}}>
                        <span style={{...fonts.body3SemiBold, color: colors.gray900}}>제목</span>
                        <RequriedCircle/>
                    </div>
                    <TextField value={classInfo.title} onChange={(e: any) => {setClassInfo({...classInfo, title: e.target.value}); setEditingBasicInfo(true);}} complete={classInfo.title && classInfo.title.length > 0}
                        helperText='클래스를 진행하는 학습분야, 대상에 대한 제목을 작성해주세요'/>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: 16, width: '100%'}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 4}}> 
                        <span style={{...fonts.body3SemiBold, color: colors.gray900}}>이름</span>
                    </div>
                    <TextField value={classInfo.memberName} onChange={(e: any) => {setClassInfo({...classInfo, memberName: e.target.value}); setEditingBasicInfo(true);}} complete={classInfo.memberName && classInfo.memberName.length > 0}
                        helperText='회원가입 시 설정한 이름과 다르게 클래스를 운영할 시 입력해주세요'/>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: 16, width: '100%'}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 4}}>
                        <span style={{...fonts.body3SemiBold, color: colors.gray900}}>썸네일 이미지</span>
                        <RequriedCircle/>
                    </div>
                    <input type="file" accept="image/*" className="hide" onChange={(e) => onImageChange(e)} ref={imageRef} style={{display: "none"}} />
                    {
                        selectDefaultThumbnail ? (
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" , width: "64px", height: "64px", marginBottom: 16, border: `1px solid ${colors.gray100}`, borderRadius: 4}}>
                                <img style={{minWidth: "100%", height: "100%", objectFit: "cover"}} src={`assets/image/default_class.png`} alt="" />
                            </div>
                        ):(
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" , width: "64px", height: "64px", marginBottom: 16, border: `1px solid ${colors.gray100}`, borderRadius: 4, cursor: "pointer", position: "relative"}}
                            onClick={() => {
                                if (thumbnail || classInfo.thumbnail) {
                                    setThumbnail(null);
                                    setClassInfo({...classInfo, thumbnail: null});
                                } else {
                                    imageRef?.current.click();
                                }
                            }}>
                            {thumbnail ? (
                                <>
                                <img style={{minWidth: "100%", height: "100%", objectFit: "cover"}} src={thumbnailUrl} alt="" />
                                <div style={{position: "absolute", top: 20, right: 20, width: 24, height: 24, borderRadius: 24, backgroundColor: colors.gray50, display: "flex", alignItems: "center", justifyContent: "center", ...fonts.body3Medium, color: colors.gray900}}>
                                    <IconX width='24px' height='24px' color={colors.gray500}/>
                                </div>
                                </>
                            ) : (
                                classInfo.thumbnail ? (
                                    <>
                                        <img style={{minWidth: "100%", height: "100%", objectFit: "cover"}} src={getFilePath(classInfo.thumbnail, "assets/image/default_class.png")} alt="" />
                                        <div style={{position: "absolute", top: 20, right: 20, width: 24, height: 24, borderRadius: 24, backgroundColor: colors.gray50, display: "flex", alignItems: "center", justifyContent: "center", ...fonts.body3Medium, color: colors.gray900}}>
                                            <IconX width='24px' height='24px' color={colors.gray500}/>
                                        </div>
                                    </>
                                ) : (
                                    <PlusIcon width="24px" height="24px" color={colors.gray900}/>
                                )
                            )}
                        </div>
                        )
                    }
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" , width: "100%"}}>
                        <Checkbox
                            checked={selectDefaultThumbnail}
                            setChecked={() => {setSelectDefaultThumbnail(!selectDefaultThumbnail); setEditingBasicInfo(true);}}
                            size="20px"
                        />
                        <span style={{ ...fonts.label4Medium, color: colors.gray500, marginLeft: 8}}>기본 이미지로 할게요</span>
                    </div>
                </div>
            </div>
            <div style={{width: 1, height: '100%', backgroundColor: colors.gray100}}/>
            <div style={{display: 'flex', flexDirection: 'column', width: 'calc(50% - 28.5px)', gap: 32}}>
                <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center'}}>
                    <div style={{display: 'flex', height: "1px", width: '33%', backgroundColor: colors.warning}}/>
                    <div style={{display: 'flex', flexDirection: 'row', width: '34%', alignItems: 'center', justifyContent: 'center', ...fonts.label3SemiBold, color: colors.warning}}>
                        추가정보를 입력해주세요
                    </div>
                    <div style={{display: 'flex', height: "1px", width: '33%', backgroundColor: colors.warning}}/>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', width: '100%', gap: 16}}>
                    <div style={{display: 'flex', flexDirection: 'column', width: 'calc(50% - 8px)', gap: 16}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 4}}>
                            <span style={{...fonts.label3SemiBold, color: colors.gray900}}>총 클래스 차수</span>
                            <RequriedCircle/>
                        </div>
                        <TextField value={classSessionInfo?.totalSessionNum.toString()} onChange={(e: any) => {
                            const value = parseInt(e.target.value);
                            if (isNaN(value) || value < 0) {
                                setClassSessionInfo({...classSessionInfo, remainingSessionNum: 0, totalSessionNum: sessionDiff});
                            } else if (value > 50) {
                                setClassSessionInfo({...classSessionInfo, remainingSessionNum: 50, totalSessionNum: sessionDiff + 50});
                            } else {
                                setClassSessionInfo({...classSessionInfo, remainingSessionNum: value, totalSessionNum: sessionDiff + value});
                            }
                            setEditingSessionInfo(true);
                        }} disabled={alreadyCreate} complete={classSessionInfo?.remainingSessionNum > 0}/>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', width: 'calc(50% - 8px)', gap: 16, position: 'relative'}}>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 4}}>
                            <span style={{...fonts.label3SemiBold, color: colors.gray900}}>클래스 시작일</span>
                        </div>
                        {classSessionInfo?.sessionStartDate ? 
                        <>
                            <TextField value={moment(classSessionInfo?.sessionStartDate).format("YYYY-MM-DD")} size="small" complete={true} disabled={alreadyCreate}/> 
                            <Overlay disabled={alreadyCreate} onClick={() => !alreadyCreate && setStartDateCalendarOpen(prev => !prev)}/>
                        </>: 
                        <SelectBox options={[]} defaultOptionLabel="종료 날짜 선택" onSelect={() => setStartDateCalendarOpen(true)}/>}
                        {isStartDateCalendarOpen && 
                        <div style={{position: "absolute", top: 100, left: 0, width: "100%", height: "100%", zIndex: 1000}}>
                            <CalendarModal disablePreviousDays={true} onClose={() => setStartDateCalendarOpen(false)} dateValue={classSessionInfo?.sessionStartDate} setDateValue={(date: any) => {setClassSessionInfo({...classSessionInfo, sessionStartDate: date}); setStartDateCalendarOpen(false); setEditingSessionInfo(true);}}/>
                        </div>
                        }
                    </div>
                </div>
                {
                    alreadyCreate && (
                        <div style={{display: 'flex', flexDirection: 'column', gap: 16, width: '100%'}}>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 4}}>
                                <span style={{...fonts.body3SemiBold, color: colors.gray900}}>남은 클래스 차수</span>
                                <RequriedCircle/>
                            </div>
                            <TextField value={classSessionInfo?.remainingSessionNum.toString()} onChange={(e: any) => {
                                const value = parseInt(e.target.value);
                                if (isNaN(value)) {
                                    setClassSessionInfo({...classSessionInfo, totalSessionNum: sessionDiff, remainingSessionNum: 0});
                                } else {
                                    setClassSessionInfo({...classSessionInfo, totalSessionNum: sessionDiff + value, remainingSessionNum: value});
                                }
                                setEditingSessionInfo(true);
                            }} complete={classSessionInfo?.remainingSessionNum > 0}/>
                        </div>
                    )
                }
                <div style={{display: 'flex', flexDirection: 'column', gap: 16, width: '100%'}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 4}}>
                        <span style={{...fonts.body3SemiBold, color: colors.gray900}}>클래스 주기 설정</span>
                        <RequriedCircle/>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 4, width: '100%'}}>
                        <TextField value={classSessionInfo?.sessionCycleNum.toString()} width='calc(100% - 48px)' textAlign='right'
                        onChange={(e: any) => {setClassSessionInfo({...classSessionInfo, sessionCycleNum: (isNaN(parseInt(e.target.value)) || (parseInt(e.target.value) < 0)) ? 0 : parseInt(e.target.value)})}}/>
                        <div style={{...fonts.body3Medium, color: colors.gray900, marginLeft: 'auto'}}>주마다</div>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: 16, width: '100%'}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 4}}>
                        <span style={{...fonts.body3SemiBold, color: colors.gray900}}>클래스 시간 추가</span>
                        <RequriedCircle/>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', gap: 8, width: '100%'}}>
                        {
                            classSessionInfo?.sessionTimeInfo.map((item, index) => {
                                const startHour = moment(item.startTime, 'HH:mm').format('HH');
                                const startMinute = moment(item.startTime, 'HH:mm').format('mm');
                                const endHour = moment(item.endTime, 'HH:mm').format('HH');
                                const endMinute = moment(item.endTime, 'HH:mm').format('mm');
                                return <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%'}}>
                                    <SelectBox defaultOptionLabel={'요일 선택'} 
                                    options={dayOfWeekOptions.map((option) => ({...option, onSelect: () => {
                                        setClassSessionInfo({...classSessionInfo, sessionTimeInfo: classSessionInfo?.sessionTimeInfo.map((item, itemIndex) => {
                                            if(itemIndex === index) {
                                                return {...item, dayOfWeek: option.value}
                                            }
                                            return item;
                                        })})
                                    }}))} 
                                    selectedOptionLabel={dayOfWeekOptions.find((option) => option.value === item.dayOfWeek)?.label} 
                                    width='calc(50% - 74px)'
                                    />
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: 'calc(12.5% - 9px)', marginLeft: 8}}>
                                        <TextField width='100%' value={startHour.toString().padStart(2, '0')} onChange={(e: any) => {setClassSessionInfo({...classSessionInfo, sessionTimeInfo: classSessionInfo?.sessionTimeInfo.map((item, itemIndex) => {
                                            if(itemIndex === index) {
                                                if (parseInt(e.target.value) > 23) {
                                                    return {...item, startTime: '23' + ':' + startMinute}
                                                } else if (parseInt(e.target.value) < 0 || isNaN(parseInt(e.target.value))) {
                                                    return {...item, startTime: '00' + ':' + startMinute}
                                                } else {
                                                    return {...item, startTime: parseInt(e.target.value).toString().padStart(2, '0') + ':' + startMinute}
                                                }
                                            }
                                            return item;
                                            
                                        })}); setEditingSessionInfo(true);}}
                                        complete={true}/>
                                    </div>
                                    <div style={{width: 20, textAlign: 'center', ...fonts.body3Medium, color: colors.gray900}}>:</div>
                                    <TextField width='calc(12.5% - 9px)' value={startMinute.toString().padStart(2, '0')} onChange={(e: any) => {setClassSessionInfo({...classSessionInfo, sessionTimeInfo: classSessionInfo?.sessionTimeInfo.map((item, itemIndex) => {
                                        if(itemIndex === index) {
                                            if (parseInt(e.target.value) > 59) {
                                                return {...item, startTime: startHour + ':' + '59'}
                                            } else if (parseInt(e.target.value) < 0 || isNaN(parseInt(e.target.value))) {
                                                return {...item, startTime: startHour + ':' + '00'}
                                            } else {
                                                return {...item, startTime: startHour + ':' + parseInt(e.target.value).toString().padStart(2, '0')}
                                            }
                                        }
                                        return item;
                                    })}); setEditingSessionInfo(true);}}
                                    complete={true}/>
                                    <div style={{width: 26, textAlign: 'center', ...fonts.body3Medium, color: colors.gray900}}>~</div>
                                    <TextField width='calc(12.5% - 9px)' value={endHour.toString().padStart(2, '0')} onChange={(e: any) => {setClassSessionInfo({...classSessionInfo, sessionTimeInfo: classSessionInfo?.sessionTimeInfo.map((item, itemIndex) => {
                                        if(itemIndex === index) {
                                            if (parseInt(e.target.value) > 23) {
                                                return {...item, endTime: '23' + ':' + endMinute}
                                            } else if (parseInt(e.target.value) < 0 || isNaN(parseInt(e.target.value))) {
                                                return {...item, endTime: '00' + ':' + endMinute}
                                            } else {
                                                return {...item, endTime: parseInt(e.target.value).toString().padStart(2, '0') + ':' + endMinute}
                                            }
                                        }
                                        return item;
                                    })}); setEditingSessionInfo(true);}}
                                    complete={true}/>
                                    <div style={{width: 20, textAlign: 'center', ...fonts.body3Medium, color: colors.gray900}}>:</div>
                                    <TextField width='calc(12.5% - 9px)' value={endMinute.toString().padStart(2, '0')} onChange={(e: any) => {setClassSessionInfo({...classSessionInfo, sessionTimeInfo: classSessionInfo?.sessionTimeInfo.map((item, itemIndex) => {
                                        if(itemIndex === index) {
                                            if (parseInt(e.target.value) > 59) {
                                                return {...item, endTime: endHour + ':' + '59'}
                                            } else if (parseInt(e.target.value) < 0 || isNaN(parseInt(e.target.value))) {
                                                return {...item, endTime: endHour + ':' + '00'}
                                            } else {
                                                return {...item, endTime: endHour + ':' + parseInt(e.target.value).toString().padStart(2, '0')}
                                            }
                                        }
                                        return item;
                                    })}); setEditingSessionInfo(true);}}
                                    complete={true}/>
                                    <div style={{width: 24, height: 24, borderRadius: "50%", backgroundColor: colors.gray100, display: 'flex', alignItems: 'center', justifyContent: 'center', ...fonts.body3Medium, color: colors.gray900, marginLeft: 12}}
                                    onClick={() => {setClassSessionInfo({...classSessionInfo, sessionTimeInfo: classSessionInfo?.sessionTimeInfo.filter((item, itemIndex) => itemIndex !== index)}); setEditingSessionInfo(true);}}>
                                        <IconX width='24px' height='24px' color={colors.gray500}/>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <IconButton size='medium' text='시간 추가' icon={
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: 24, height: 24, borderRadius: 24, backgroundColor: colors.white}}>
                            <PlusIcon color={colors.gray900} width='24px' height='24px'/>
                        </div>
                    } onClick={() => {setClassSessionInfo({...classSessionInfo, sessionTimeInfo: [...classSessionInfo?.sessionTimeInfo, {startTime: '00:00', endTime: '00:00', dayOfWeek: ''}]})}} width='100%' height='40px' buttonColor={colors.gray100} textColor={colors.gray900}/>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: 16, width: '100%'}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 4}}>
                        <span style={{...fonts.body3SemiBold, color: colors.gray900}}>지각시간 설정</span>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 4, width: '100%'}}>
                        <TextField value={classSessionInfo?.lateTimeMinute.toString()} width='calc(100% - 112px)'
                        onChange={(e: any) => {setClassSessionInfo({...classSessionInfo, lateTimeMinute: (isNaN(parseInt(e.target.value)) || (parseInt(e.target.value) < 0)) ? 0 : parseInt(e.target.value)})}}
                        textAlign='right'/>
                        <div style={{...fonts.body3Medium, color: colors.gray900, marginLeft: 'auto'}}>분 이후 지각처리</div>
                    </div>
                    
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: 16, width: '100%'}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 4}}>
                        <span style={{...fonts.body3SemiBold, color: colors.gray900}}>소개글</span>
                    </div>
                    <TextField value={classSessionInfo?.sessionDescription} onChange={(e: any) => {setClassSessionInfo({...classSessionInfo, sessionDescription: e.target.value})}}/>
                </div>
                {editConfirmModalOpen && <EditConfirmModal toggle={() => {setEditConfirmModalOpen(false)}} onConfirm={onSave}/>}
                {deleteConfirmModalOpen && <DeleteConfirmModal toggle={() => {setDeleteConfirmModalOpen(false)}} onConfirm={onDelete}/>}
            </div>

        </div>
    </>
}

const RequriedCircle = styled.div`
    width: 8px;
    height: 8px;
    background-color: ${colors.warning};
    border-radius: 50%;
`;

const Overlay = styled.div<{disabled: boolean}>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: ${props => props.disabled ? "default" : "pointer"};
    background-color: transparent;
    z-index: 10;
    width: 100%;
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  top: 60px;
  right: 30px;
  background-color: ${colors.white};
  border-radius: 8px;
  border: 1px solid ${colors.gray100};
  height: 88px;
  z-index: 1000;
`;

const MenuItem = styled.div<{isFirst: boolean, isLast: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 52px;
  cursor: pointer;
  font-size: ${fonts.body3Regular.fontSize};
  color: ${colors.gray500};
  font-family: ${fonts.body3Regular.fontFamily};
  line-height: ${fonts.body3Regular.lineHeight};
  border-radius: ${props => props.isFirst && props.isLast ? "8px" : props.isFirst ? "8px 8px 0 0" : props.isLast ? "0 0 8px 8px" : "0"};
  padding: 0 12px;

  &:hover {
    background-color: ${colors.blue50};
  }
`;

const EditConfirmModal = (props: {toggle: () => void, onConfirm: () => void}) => {
    return <Modal size='sm' isOpen={true} toggle={props.toggle}>
        <ModalBody style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 24}}>
            <span style={{...fonts.body3SemiBold, color: colors.gray900, marginTop: 24, marginBottom: 32}}>클래스 정보를 수정하시겠습니까?</span>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', gap: 8}}>
                <Button text='취소' onClick={props.toggle} size='medium' buttonColor={colors.gray100} textColor={colors.gray900} width='112px' height='40px'/>
                <Button text='확인' onClick={props.onConfirm} size='medium' buttonColor={colors.blue600} textColor={colors.white} width='112px' height='40px'/>
            </div>
        </ModalBody>
    </Modal>
}

const DeleteConfirmModal = (props: {toggle: () => void, onConfirm: () => void}) => {
    return <Modal size='md' isOpen={true} toggle={props.toggle}>
    <ModalBody style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 24}}>
        <span style={{...fonts.body2Medium, color: colors.gray900, marginTop: 24}}>해당 클래스를 삭제하시겠습니까?</span>
        <span style={{...fonts.body2Medium, color: colors.gray900, marginBottom: 32}}>클래스를 삭제하면 모든 학습 데이터가 함께 삭제됩니다</span>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', gap: 8}}>
            <Button text='취소' onClick={props.toggle} size='medium' buttonColor={colors.gray100} textColor={colors.gray900} width='112px' height='40px'/>
            <Button text='삭제' onClick={props.onConfirm} size='medium' buttonColor={colors.warning} textColor={colors.white} width='112px' height='40px'/>
        </div>
    </ModalBody>
</Modal>
}

export default ClassSchedule;

