import React, { useState } from "react";
import styled from "styled-components";
import colors from "../../../design/colors";
import fonts from "../../../design/typography";
import { IClassList } from "../../../type/classroom";
import { ChevronDown, ChevronUp } from "../../icons/chevron";
import IconX from "../../icons/x";
import IconFilter from "../../icons/filter";
import { getFilePath } from "../../../utils/image";
import ImageCircle from "../../image_circle";
interface INoticeModalProps {
    classList: IClassList[];
    toggle: () => void;
}

interface IAlarmList {
    id: number;
    title: string;
    content: string;
}

const NoticeModal = ({ classList, toggle }: INoticeModalProps) => {
    const [selectedClass, setSelectedClass] = useState<IClassList | null>(classList.length > 0 ? classList[0] : null);
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
    const [alarmList, setAlarmList] = useState<IAlarmList[]>([]);
    const [hoverdIndex, setHoverdIndex] = useState<number>(-1);
    return (
        <NoticeModalWrapper>
            <NoticeModalHeader>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                    <ImageCircle src={getFilePath(selectedClass?.thumbnail, "/assets/image/default_class.png")} size={44} />
                    <div style={{position: "relative"}}>
                        <span style={{ ...fonts.label5Medium, color: colors.gray600, marginLeft: 8 }}> {selectedClass?.corp || "-"} </span>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", cursor: "pointer"}} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                            <span style={{ ...fonts.body3SemiBold, color: colors.gray900, marginLeft: 8 }}> {selectedClass?.title.length > 15 ? selectedClass.title.substring(0,15)+ "..." : selectedClass?.title} </span>
                            <div>
                                {isDropdownOpen ? <ChevronUp color={colors.gray900} width="24px" height="24px" style={{marginLeft: 8}}/> : <ChevronDown color={colors.gray900} width="24px" height="24px" style={{marginLeft: 8}}/>}
                            </div>
                        </div>
                        {
                        isDropdownOpen && <ClassDropdown>
                            {classList.map((item, index) => (
                                <ClassDropdownItem key={item.id} onMouseEnter={() => setHoverdIndex(index)} onMouseLeave={() => setHoverdIndex(-1)} isHoverd={hoverdIndex === index} onClick={() => setSelectedClass(item)}>
                                    <ImageCircle src={getFilePath(item.thumbnail, "/assets/image/default_class.png")} size={36} />
                                    <div style={{marginLeft: 8}}>
                                        <div style={{ ...fonts.label5Medium, color: colors.gray600 }}>{item.corp}</div>
                                        <div style={{ ...fonts.label4Medium, color: colors.gray900, display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            {item.title.length > 15 ? item.title.substring(0,15)+"..." : item.title}
                                        </div>
                                    </div>
                                </ClassDropdownItem>
                            ))}
                        </ClassDropdown>
                        }
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", position: "relative"}}>
                    <div style={{cursor: "pointer"}} onClick={() => setIsFilterOpen(!isFilterOpen)}>
                        <IconFilter color={colors.gray900} width="24px" height="24px"/>
                    </div>
                    <div style={{cursor: "pointer", marginLeft: 12}} onClick={toggle}>
                        <IconX color={colors.gray900} width="24px" height="24px"/>
                    </div>
                    {isFilterOpen &&
                    <FilterWrapper >
                        <FilterItem index={0}>
                            <span style={{ ...fonts.label4Medium, color: colors.gray500 }}> 읽음 처리</span>
                        </FilterItem>
                        <FilterItem index={1}>
                            <span style={{ ...fonts.label4Medium, color: colors.gray500 }}> 알림 삭제</span>
                            </FilterItem>
                        </FilterWrapper>
                    }
                </div>
            </NoticeModalHeader>
            <NoticeModalContent isListEmpty={alarmList.length === 0}>
                {alarmList.length === 0 &&
                <>
                    <img src="/assets/image/sad_rai.png" style={{width: 152, height: 113}}/>
                    <span style={{ ...fonts.body3Medium, color: colors.gray400, marginTop: 24 }}> 등록된 알림이 없습니다</span>
                </>
                }
            </NoticeModalContent>
        </NoticeModalWrapper>
    )
}

export default NoticeModal;

const NoticeModalWrapper = styled.div`
    width: 384px;
    height: 576px;
    top: 80px;
    right: 68px;
    border-radius: 16px;
    background-color: ${colors.white};
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

const NoticeModalHeader = styled.div`
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: ${colors.gray50};
    border-radius: 16px 16px 0 0;
    padding: 0 20px 0 24px;
`;

const FilterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 20px;
    left: 20px;
    border-radius: 16px;
    border: 1px solid ${colors.gray100};
    width: 80px;
`;

const FilterItem = styled.div<{index: number}>`
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${props => props.index === 0 ? "16px 16px 0 0" : "0 0 16px 16px"};
    background-color: ${colors.white};
    cursor: pointer;
`;

const NoticeModalContent = styled.div<{isListEmpty: boolean}>`
    width: 100%;
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: ${props => props.isListEmpty ? "center" : "flex-start"};
    padding: 16px;
`;

const ClassDropdown = styled.div`
    position: absolute;
    top: 60px;
    left: -50px;
    width: 222px;
    max-height: 220px;
    background-color: ${colors.white};
    border-radius: 8px;
    padding: 8px 10px 16px 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    overflow-y: auto;
`;

const ClassDropdownItem = styled.div<{isHoverd: boolean}>`
    width: 100%;
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 8px;
    background-color: ${props => props.isHoverd ? colors.blue50 : colors.white};
    padding: 8px 10px;
    cursor: pointer;
`;