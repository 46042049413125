
import AI_Sec1 from "../AI/AI_Sec1";
import AI_Sec2 from "../AI/AI_Sec2";
import AI_SubTop from "../AI/AI_SubTop";
import Header from '../Layout/Header';
import Qna from '../Layout/Qna';
import Footer from '../Layout/Footer';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Page_settings } from '../../../config/page_settings';
import { useNavigate } from "react-router-dom";

const AI = () => {
    const context = useContext(Page_settings);
    const idRef = useRef();

    const navigate = useNavigate();
    useEffect(() => {
        const userInfoString = localStorage.getItem("user_info");
        const userInfo = JSON.parse(userInfoString);
    
        // 'id' 키가 있는지 확인합니다.
        if (userInfo && userInfo.hasOwnProperty('id')) {
            navigate("/class"); // "/class" 페이지로 리디렉션
        }
            context.handleSetPageHeader(false);
            context.handleSetPageSidebar(false);
    
            idRef?.current?.focus();
    
            return () => {
                context.handleSetPageHeader(true);
                context.handleSetPageSidebar(true);
            }
        }, []);
    return (
        <>
            <Header/>
            <AI_SubTop />
            <AI_Sec1 />
            <AI_Sec2 />
            <Footer/>
        </>
    )
}

export default AI;