import React, { useState, useRef, useContext, useEffect } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import styled from "styled-components";
import strings from "../../../lang/strings";
import OrgaSearch from "../customSearch/orgaSearch";
import ClassSearch from "../customSearch/classSearch";
import { Page_settings } from "../../../config/page_settings";
import { rootStore } from "../../../mobx/store";


function ClassEditModal({ onClose, selectedClass}) {
  const context = useContext(Page_settings);
  const [orgaSearchValue, setOrgaSearchValue] = useState("");
  const [choiceYear, setChoiceYear] = useState("연도");
  const [choiceGrade, setChoiceGrade] = useState("학년");
  const [inputClass, setInputClass] = useState("");
  const imageRef = useRef();
  const [imgFile, setImgFile] = useState("")
  const [url, setUrl] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [selectThumbnail, setSelectThumbnail] = useState(false);
  const [classInfo, setClassInfo] = useState("");
  // const [confirmButtonEnable,setConfirmButtonEnable] = useState(false);
  const profile = rootStore.getProfile?.profile;
  
  

  useEffect(() => {
    // loadSchoolList();
    loadClassInfo();
}, []);


const loadSchoolList = () => {

    context.get(
    'class/getSchoolList',
    {},
    response => {
      
    }
);
}

const loadClassInfo = () => {

  context.get(
  'class/getClassInfo',
  {class_id : selectedClass},
  response => {
    setClassInfo(response);
    setOrgaSearchValue(response.class_name)
    setChoiceGrade(response.class_grade)
    setInputClass(response.class_class)
    setImgFile(response.class_thumbnail)
  }
);
}

const onUpdateClass = () => {

  if (selectThumbnail) {
      let formData = new FormData();
      formData.append('file', url);

      context.post(
          'upload/image/thumbnail',
          formData,
          response => {
              setThumbnail(response.image);
              sendClassData(response.image);
          });
  } else {
    sendClassData();
  }
}


  const sendClassData = (image) =>{
    
    context.post(
      'class/updateClass',
      {
        class_id : selectedClass,
        class_name : orgaSearchValue,
        class_grade : choiceGrade,
        class_class : inputClass,
        class_thumbnail : image ?? thumbnail,
      },
      response => {
        window.location.reload();
        
      });
  }


  const handleClose = () => {
    onClose?.();
  };

  const onImageChange = (e) => {
    if (e.target.files.length < 1) {
        return;
    }
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.onloadend = function () {
        setSelectThumbnail(true);
        setImgFile(reader.result);
        setUrl(file);

        console.log("reader",reader)
        // setEdited(true);
    };
    reader.readAsDataURL(file);
};

const EnableConfirm = () => {
  return (
    orgaSearchValue !== '' && choiceGrade !== '학년' && inputClass !== ''
  )
}





  return (
      <Overlay>
        <ModalWrap>
          <div class="classAddModal-head">
            <h4>클래스 수정</h4>
            <img onClick={handleClose} src="/assets/image/icon_close_black.png"/>
          </div>
          <div class="classAddModal-subTitle">
            기관 검색
          </div>
          <div>
          <OrgaSearch
            placeholder={strings.organization_search_placeholder}
            inputType={'text'}
            value={orgaSearchValue}
            onChange={(v) => {
              setOrgaSearchValue(v)
            }}
            onSearch={() => {
              //여기에 기관 뜨는 데이터 넣어야할듯 
            }}
        />
          </div>
          <div className="classAddModal-secondContent">
            {/* <div className="col-md-2">
            </div> */}
            <div className="col-md-3">
              <div class="classAddModal-subTitle">학년</div>
              <Dropdown>
                 <Dropdown.Toggle variant="success" id="dropdown-basic" className="classAddModal-dropdown">
                  {choiceGrade}
                 </Dropdown.Toggle>
                  <Dropdown.Menu>
                    { strings.class_grade.map((item,index)=>{
                      return(
                      <Dropdown.Item onClick={()=> {setChoiceGrade(item.grade)}}>
                        {item.grade}
                      </Dropdown.Item>
                    )
                    })}
                  </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-md-1"/>
            <div className="col-md-8">
              <div class="classAddModal-subTitle">반</div>
              <ClassSearch
                placeholder={strings.class_input_placeholder}
                inputType={'text'}
                value={inputClass}
                onChange={(v) => {
                  setInputClass(v)
                }}
                onSearch={() => {
                  //여기에 기관 뜨는 데이터 넣어야할듯 
                }}
        />
            </div>
          </div>
          <input type='file' accept='image/*' className='hide'
                           onChange={(e) => onImageChange(e)}
                           ref={imageRef}/>

          <div class="classAddModal-subTitle">
            섬네일 이미지
          </div>
          <div class="classAddModal-imgAdd" onClick={() => imageRef?.current.click()}>
            {selectThumbnail ? 
            <img class= "classAddModal-thumbnail" src = {imgFile} alt=""/>
            :
            <>
            <img class= {imgFile ? "classAddModal-thumbnail" : "classAddModal-no-thumbnail" }  
            src={imgFile ? context.loadImage(imgFile) : "/assets/image/class_addImg1.png"}  
            alt=""/>
            </>
            }
            
          </div>
          <Button style={{backgroundColor: EnableConfirm() ? '#0F84F4' : '#DFE3E8'}}disabled ={!EnableConfirm()} onClick={onUpdateClass}>확인</Button>
        </ModalWrap>
      </Overlay>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const ModalWrap = styled.div`
  width: 400px;
  height: fit-content;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
`;
const Button = styled.button`
  cursor: pointer;
  width: 100%;
  color: #ffffff;
  border-radius: 5px;
  border:0px;
  padding: 10px;
  margin-top: 20px;
`;
export default ClassEditModal;