import DatePicker from "react-datepicker";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import strings from "../../../lang/strings";
import './planner.scss'
import 'react-datepicker/dist/react-datepicker.css';
import { useState } from "react";

const CalendarModal = ({onClose,setDateValue}) => {
  const [selDate, setSelDate] = useState(new Date());
  const [eventList, setEventList] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const weekdays = [
    strings.weekdays.monday,
    strings.weekdays.tuesday,
    strings.weekdays.wednesday,
    strings.weekdays.thursday,
    strings.weekdays.friday,
    strings.weekdays.saturday
];

  const getDataIndex = (date) => {
        let idx = -1;
        for (let i = 0; i < eventList.length; i++) {
            if (eventList[i].show_date == moment(date).format('YYYY-MM-DD')) {
                idx = i;
                break;
            }
        }
        return idx;
    }
    const checkEvent = (date) => {
      let result = [];
      for (let i = 0; i < eventList.length; i++) {
          if (eventList[i].show_date == moment(date).format('YYYY-MM-DD')) {
              if (result.filter(e => e.tag == eventList[i].tag).length == 0 && result.length < 4) {
                  result.push(eventList[i]);
              }
          }
      }

      return result;
  }

  function isEmptyObj(obj) {
    for (const prop in obj) {
        if (Object.hasOwn(obj, prop)) {
            return false;
        }
    }

    return true;
}

  const selectDate = (date) => {
    setDateValue(moment(date).format('YYYY-MM-DD'))
    
    onClose?.();
  }
  return (

    <div className="calendar-overlay">
      <div className="calendar-wrap">
      <div className={'calendar'}>
                            <DatePicker
                                inline
                                selected={selDate}
                                onChange={(date) => selectDate(date)}
                                showNeighboringMonth={false}
                                renderDayContents={(dayOfMonth, date) => {
                                    let className = '';
                                    let planIndex = getDataIndex(date);
                                    if (moment(date).format('YYYY-MM-DD') == moment(selDate).format('YYYY-MM-DD')) {
                                        className = 'day-selected';
                                    } else if (planIndex < 0) {
                                        className = 'day-disable';
                                    } else {
                                        className = 'day-active';
                                    }
                                    let event = checkEvent(date);
                                    return (
                                        <div className={'day-area'}>
                                            {
                                                className == 'day-active' &&
                                                <div className={className}>
                                                    {dayOfMonth}
                                                    {
                                                        event.length > 2 &&
                                                        <div>
                                                            <div className="event-area">
                                                                <div
                                                                    className="event-day"
                                                                    style={{
                                                                        display: isEmptyObj(event[0]) ? 'none' : "inline",
                                                                        backgroundColor: isEmptyObj(event[0]) ? "transparent" : event[0].color
                                                                    }}
                                                                />
                                                                <div
                                                                    className="event-day"
                                                                    style={{
                                                                        display: isEmptyObj(event[1]) ? 'none' : "inline",
                                                                        backgroundColor: isEmptyObj(event[1]) ? "transparent" : event[1].color,
                                                                        marginLeft: 2
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="event-area" style={{ marginTop: 2 }}>
                                                                <div
                                                                    className="event-day"
                                                                    style={{
                                                                        display: isEmptyObj(event[2]) ? 'none' : "inline",
                                                                        backgroundColor: isEmptyObj(event[2]) ? "transparent" : event[2].color
                                                                    }}
                                                                />
                                                                <div
                                                                    className="event-day"
                                                                    style={{
                                                                        display: isEmptyObj(event[3]) ? 'none' : "inline",
                                                                        backgroundColor: isEmptyObj(event[3]) ? "transparent" : event[3].color,
                                                                        marginLeft: 2
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        event.length < 3 &&
                                                        <div className="event-area">
                                                            <div
                                                                className="event-day"
                                                                style={{
                                                                    display: isEmptyObj(event[0]) ? 'none' : "inline",
                                                                    backgroundColor: isEmptyObj(event[0]) ? "transparent" : event[0].color
                                                                }}
                                                            />
                                                            <div
                                                                className="event-day"
                                                                style={{
                                                                    display: isEmptyObj(event[1]) ? 'none' : "inline",
                                                                    backgroundColor: isEmptyObj(event[1]) ? "transparent" : event[1].color,
                                                                    marginLeft: 2
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            {
                                                className != 'day-active' &&
                                                <div className={className}>{dayOfMonth}</div>
                                            }
                                        </div>
                                    )
                                }}
                                renderCustomHeader={(params => {
                                    return (
                                        <div style={{ width: '100%' }}>
                                            <div className="calendar-header">
                                                <button className={'btn month-btn'} onClick={() => {
                                                    setStartDate(new Date(moment(startDate).subtract(1, 'months').format('YYYY-MM-DD')));
                                                    params.decreaseMonth();
                                                }}>
                                                    <img className="ic_header_home" style={{ width: 12, height: 15 }}
                                                        src={"/assets/image/icon_calendar_prev.png"} />
                                                </button>
                                                <div style={{ flex: 1 }}>
                                                    <div className={"header-month-text"}>
                                                        {(params.monthDate.getMonth() + 1).toString() + strings.common.m}
                                                    </div>
                                                    <div className={"header-year-text"}>
                                                        {(params.monthDate.getFullYear()).toString()}
                                                    </div>
                                                </div>
                                                <button className={'btn month-btn'} onClick={() => {
                                                    setStartDate(new Date(moment(startDate).add(1, 'months').format('YYYY-MM-DD')));
                                                    params.increaseMonth();
                                                }}>
                                                    <img className="ic_header_home" style={{ width: 12, height: 15 }}
                                                        src={"/assets/image/icon_calendar_next.png"} />
                                                </button>
                                            </div>
                                            <div className="weekDay-area d-flex mt-2">
                                                <div
                                                    className={selDate.getDay() == 0 ? 'weekday-select' : 'weekday-normal'}>{strings.weekdays.sunday}</div>
                                                {
                                                    weekdays.map((item, idx) =>
                                                        <div key={idx}
                                                            className={selDate.getDay() == idx + 1 ? 'weekday-select weekDay-space' : 'weekday-normal weekDay-space'}>{item}</div>
                                                    )
                                                }
                                            </div>
                                            <div className="divider mt-1" />
                                        </div>
                                    )
                                })}
                            />
                        </div>
                        
      </div>
      </div>
      

  );
}




export default withRouter(CalendarModal);