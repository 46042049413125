import { withRouter } from "react-router-dom";
import React, { useContext, useEffect, useRef, useState } from "react";
import { rootStore } from "../../mobx/store";
import { Page_settings } from "../../config/page_settings";
import strings from "../../lang/strings";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import AddChapterUnitModal from "./modal/add_chapter_unit_modal";
import CreateCurriculumStep4Modal from "./modal/create_curriculum_step4_modal";
import WarningModal from "./modal/warning_modal";
import TeacherCourseInfoModal from "./modal/teacher_course_info_modal";

const TeacherCurriculumInfo = (props) => {
  const history = useHistory();
  const context = useContext(Page_settings);
  const [categoryColor, setCategoryColor] = useState('yellow'); //카테고리 색상값
  const [selectedPart, setSelectedPart] = useState();
  const [selectedPartIdx, setSelectedPartIdx] = useState(0);
  const [data, setData] = useState({});
  const [openInfoModal,setOpenInfoModal] = useState(false);
  const [thumbnailData, setThumbnailData] = useState();
  const [curriculumData, setCurriculumData] = useState([]); 
  const [clickPartItem,setClickPartItem] = useState();
  const [clickChapterItem,setClickChapterItem] = useState();
  const [clickSectionItem, setClickSectionItem] = useState();
  const [clickUnitItem, setClickUnitItem] = useState();
  const [isMouseDown, setIsMouseDown] = useState(false);

  const scrollRef = useRef();
    const mouseCoords = useRef({
        startX: 0,
        startY: 0,
        scrollLeft: 0,
        scrollTop: 0
    });

  const curriculum_id = parseInt(props.match.params.id);

  useEffect(() => {
    loadCurriculumData();
  }, []);

  const loadCurriculumData = () => {
    context.get(
        "curriculum/getCurriculumData",
        {
          curriculum_id
        },
        response => {
          console.log("response: " + JSON.stringify(response.list.parts, null, 2));
            setThumbnailData(response.list.thumbnail)
            setCurriculumData(response.list.parts)
            setSelectedPart(response.list.parts.name)
        }
    );
}




  const handleCategoryClick = (title, color,idx) => {
    setSelectedPart(title);
    setCategoryColor(color);
    setSelectedPartIdx(idx)
  };


  const openModal = (part,chapter,section,unit) => {
    setClickPartItem(part)
    setClickChapterItem(chapter)
    setClickSectionItem(section)
    setClickUnitItem(unit)
    setOpenInfoModal(true)
  }

  
  // const filteredChapters = curriculumData.filter(part => part.name === selectedPart);
  const filteredPart = curriculumData[selectedPartIdx];
  // const filteredChapters = [curriculumData[selectedPartIdx]]; // 객체를 배열로 감싸서 map 함수 사용 가능하게 만듦
  
  const handleDragStart = (e) => {
    const startX = e.pageX - scrollRef.current._container.offsetLeft;
    const startY = e.pageY - scrollRef.current._container.offsetTop;
    const scrollLeft = scrollRef.current._container.scrollLeft;
    const scrollTop = scrollRef.current._container.scrollTop;
    mouseCoords.current = { startX, startY, scrollLeft, scrollTop }
    setIsMouseDown(true);
}

const handleDragEnd = (e) => {
    setIsMouseDown(false);
}

const handleDrag = (e) => {
    if (!isMouseDown) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current._container.offsetLeft;
    const y = e.pageY - scrollRef.current._container.offsetTop;
    const walkX = x - mouseCoords.current.startX;
    const walkY = y - mouseCoords.current.startY;
    scrollRef.current._container.scrollLeft = mouseCoords.current.scrollLeft - walkX;
    scrollRef.current._container.scrollTop = mouseCoords.current.scrollTop - walkY;
}

const allConfirmColor = (section,unit) => {
  if((unit?.link || unit?.video_url) || (unit?.test_url || unit?.commentary_url) && (section?.answer_data && section?.commentary_url && section?.test_url && section?.time > 0)){
    return true;
  }else{
    return false;
  }
}


  return (
    <div class="main-content teacher-curriculum-info-container">

{openInfoModal && (<TeacherCourseInfoModal
        open={openInfoModal}
        onClose={() => {
          setOpenInfoModal(false);
        }}
        partItem={clickPartItem}
        chapterItem={clickChapterItem}
        sectionItem={clickSectionItem}
        unitItem={clickUnitItem}
      />)}

      <div className="content-container">
        <div className="container-top">
        <div className="title">{thumbnailData?.title ? thumbnailData.title: null}</div>
        {curriculumData.length > 0 ? 
          <div className="part-box" >
            {curriculumData.map((item, index) => (
                <div
                  key={index}
                  className={`partCategory-box ${selectedPartIdx === index ? item.color : ''}`}
                  onClick={() => handleCategoryClick(item.name, item.color,index)}>
                  <div>{item.name}</div>
                </div>
              ))}
          </div>
          :
          null
      }
      <div style={{height:'75%'}}>
       {/* {filteredChapters.length > 0 ? (
    <PerfectScrollbar  className="section-unit-container" ref={scrollRef} style={{ height: '100%', cursor: "grab" }} onMouseDown={handleDragStart} onMouseUp={handleDragEnd} onMouseMove={handleDrag}>
              {filteredChapters.map((part, partIdx) => (
                part.chapters.map((chapter, chapterIdx) => (
                  // chapter.name (
                    <React.Fragment key={chapterIdx}>
                      <div className="row-box" key={chapterIdx}>
                      <div className="chapter">{chapter.name}</div>
                      {chapter.sections.map((section, sectionIdx) => (
                        <div className="section-container" key={sectionIdx}>
                          <div className="section-box">
                            <div className="section-title">{section.name}</div>
                            <div className={`unit-box-outside ${categoryColor}`}>
                               {section.units.map((unit, unitIdx) => (
                        <div className={`unit-box-inside ${unit.achievement_rate === 100 ? categoryColor :''}`} key={unitIdx} onClick={() => openModal(part,section,unit)}>
                          {unit.name}
                        </div>
                      ))}
                            </div>
                          </div>
                        </div>
                      ))}
                      </div>
                    </React.Fragment>
                  // )
                ))
              ))}
        </PerfectScrollbar>
      ) : null} */}
{
  filteredPart ? (
    <PerfectScrollbar
      className="section-unit-container"
      ref={scrollRef}
      style={{ height: '100%', cursor: "grab", padding: '10px 0px' }}
      onMouseDown={handleDragStart}
      onMouseUp={handleDragEnd}
      onMouseMove={handleDrag}
    >
      {filteredPart.chapters.map((chapter, chapterIdx) => (
        <React.Fragment key={chapterIdx}>
          <div className="row-box">
            <div className="chapter">{chapter.name}</div>
            {chapter.sections.map((section, sectionIdx) => (
              <div className="section-container" key={sectionIdx}>
                <div className="section-box">
                  <div className="section-title">{section.name}</div>
                  <div className={`unit-box-outside ${filteredPart?.color}`}>
                    {section.units.map((unit, unitIdx) => (
                      <div
                        className={`unit-box-inside`}
                        key={unitIdx}
                        onClick={() => openModal(filteredPart, chapter , section, unit)}
                      >
                        {unit.name}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </React.Fragment>
      ))}
    </PerfectScrollbar>
  ) : null
}





      </div>



        </div>
      </div >


    </div >
  );






}
export default withRouter(TeacherCurriculumInfo);
