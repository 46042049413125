import React, { useState } from "react";

export default function orgaSearch(props) {

  const handleEnter = e => {
    if (e.keyCode == 13) {
        props.onSearch()
    }
  }

    return (
            <div className="class-search  align-item-center box-shadow" >             
                <input className="input-transparent" placeholder={props.placeholder} type={props.inputType} maxLength={props.maxLength} value={props.value} onChange={(e) => props.onChange(e.target.value)} onKeyD
                own={handleEnter}
                />
                <button className="common-button" > 
                    <img src={'/assets/image/icon_search_black.png'} alt=""/>
                </button>
            </div>
    );
}