import React from 'react';
import strings from "../../../lang/strings";
import moment from "moment";

const CategoryRoomItem = (props) => {
    const { item, onClick, image } = props;
    return (
        <div className={"my-study-room category-room col-lg-3"}
            style={{ backgroundImage: "url(" + image + ")", backgroundRepeat: "no-repeat", backgroundPosition: "center center", backgroundSize: "cover" }}
            onClick={() => onClick()}>
            <div className="member-count">
                <img src={"/assets/image/icon_room_circle.png"} alt="" />
                <div className="flex-grow-1 room-list-item-count">{item.today_count} 명</div>
                {item.secret === 2 ?
                <img className="localStudy_secret" src='/assets/image/localStudy_secret.png'/>
                :
                null    
            }
                {/* 1 = 시크릿 , 2 = 오픈  */}
                
            
            </div>
            <div className="room-title room-list-item-title">{item.title}</div>
            <div className="flex-grow-1 text-ellipsis room-list-item-name">{item.user.name}</div>
        </div>
    );
}

export default CategoryRoomItem;
