import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import strings from "../../../lang/strings";
import 'react-datetime/css/react-datetime.css';
import { ReactNotifications, Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import moment from "moment";
import { Page_settings } from "../../../config/page_settings";
import SweetAlert from "react-bootstrap-sweetalert";
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { getAgeFromBirthday, getTimeFromSecond } from '../../../helper/common';

const ExamTeacher = (props) => {
    const context = useContext(Page_settings);
    const examId = parseInt(props.match.params.exam_id);

    const [examInfo, setExamInfo] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [scoreAlert, setScoreAlert] = useState(false);
    const [userList, setUserList] = useState([]);
    const [extraScoreType, setExtraScoreType] = useState(0);
    const [extraScore, setExtraScore] = useState(0);
    const [examResultInfo, setExamResultInfo] = useState(null);
    const [correctAnswerList, setCorrectAnswerList] = useState([]);
    const [userAnswerList, setUserAnswerList] = useState([]);
    const [showFeedback, setShowFeedback] = useState(false);
    const [feedback, setFeedback] = useState('');

    useEffect(() => {
        getExamInfo();
    }, []);

    useEffect(() => {
        if (selectedUser != null && selectedUser.exam_submission_time) {
            getExamResult();
        }
    }, [selectedUser])

    const getExamInfo = () => {
        context.post(
            'exam/info',
            {
                id: examId
            },
            response => {
                console.log("info",response)
                const examInfo = response.info;
                const users = response.users;
                setExamInfo(examInfo);
                const correctAnswerList = JSON.parse(examInfo.answer_data);
                correctAnswerList.forEach((item, index) => {
                    if(item.type === 1) {
                        item.answer = (item.answer === '' || !item.answer) ? [] : typeof JSON.parse(item.answer) === 'number' ? [JSON.parse(item.answer)] : JSON.parse(item.answer);
                    }
                });
                setCorrectAnswerList(correctAnswerList);
                setUserList(users);
            }
        );
    }

    const getExamResult = () => {
        context.post(
            'exam/result',
            {
                exam_id: examId,
                member_id: selectedUser.id
            },
            response => {
                const resultInfo = response.info;
                setExamResultInfo(resultInfo);
                const userAnswerList = JSON.parse(resultInfo.answer_data);
                userAnswerList.forEach((item, index) => {
                    if(item.type === 1) {
                        item.answer = (item.answer === '' || !item.answer) ? [] : typeof JSON.parse(item.answer) === 'number' ? [JSON.parse(item.answer)] : JSON.parse(item.answer);
                    }
                });
                setUserAnswerList(userAnswerList);
                setExtraScore(resultInfo.extra_score > 0 ? resultInfo.extra_score : resultInfo.extra_score < 0 ? -resultInfo.extra_score : 0);
                setExtraScoreType(resultInfo.extra_score > 0 ? 1 : resultInfo.extra_score < 0 ? -1 : 0);
                setFeedback(resultInfo.feedback);
            }
        );
    }

    const onStudent = (index) => {
        setSelectedUser(userList[index]);
    }

    const onSaveFeedback = () => {
        context.post(
            'exam/save_feedback',
            {
                id: examId,
                memberId: selectedUser.id,
                feedback: feedback,
            },
            response => {
                addNotification('success', '', strings.success_to_save)
            }
        );
    }

    const onSaveExtraScore = () => {
        if (extraScore === '') {
            return;
        }

        if (extraScore  < 0) {
            addNotification('warning', '', '양수를 입력하세요.')
            return;
        }

        context.post(
            'exam/set_extra_score',
            {
                id: examId,
                memberId: selectedUser.id,
                score: extraScoreType ===  1 ? Number(extraScore) : -Number(extraScore),
            },
            response => {
                addNotification('success', '', strings.success_to_save)
            }
        );
    }

    const addNotification = (notificationType, notificationTitle, notificationMessage) => {
        Store.addNotification({
            title: notificationTitle,
            message: notificationMessage,
            type: notificationType,
            insert: 'top',
            container: 'top-center',
            dismiss: {
                duration: 1000,
            }
        });
    }

    const answersAreEqual = (ans1, ans2, answer_type) => {
        if(answer_type === 1) {

            // ans1, ans2 배열 => 배열의 길이가 다르면 false
            if (ans1.length !== ans2.length) {
                return false;
            }

            // 각 요소를 비교
            return ans1.every((value, index) => value === ans2[index]);
        } else {
            return ans1 === ans2;
        }
    }

    return (
        <div style={{ width: '100%', height: 'calc(100vh - 90px)' }}>
            <div className="main-content exam-result-content" style={{ width: '100%', height: '100%', padding: '18px 28px 0', borderRadius: 12, background: '#fff', display: 'flex', flexDirection: 'column' }}>
                <ReactNotifications />
                <div className="f-s-25 f-w-600 m-b-10 d-flex flex-row align-items-center">
                    {
                        selectedUser == null ?
                            '시험' :
                            (
                                <div className='d-flex flex-row align-items-center' style={{ cursor: 'pointer' }} onClick={() => {
                                    setSelectedUser(null);
                                }}>
                                    <img src='/assets/image/icon_arrow_back_gray.png' />
                                    <img className='top-profile' src={selectedUser.profile != null ? context.loadImage(selectedUser.profile) : "/assets/image/chewing_plus_logo.png"} />
                                    <div className='top-nickname'>{selectedUser.nickname}</div>
                                </div>
                            )
                    }
                </div>
                <div className='d-flex flex-row flex-1' style={{ overflow: 'hidden', paddingBottom: 24 }}>
                    <div className='d-flex flex-column col-md-7'>
                        {
                            selectedUser == null &&
                            <div className='d-flex flex-column' style={{ width: '100%' }}>
                                <div style={{ color: '#0F84F4', fontSize: 13, fontWeight: 600, letterSpacing: -0.26, marginTop: 15 }}>
                                    {examInfo == null ? '' : moment(examInfo.date).format('YY.MM.DD (Z)') + ' ' + examInfo.start_time + '~' + examInfo.end_time}
                                </div>
                                <div style={{ color: '#1F2022', fontSize: 20, fontWeight: 700, lineHeight: '24px', letterSpacing: -0.4, marginTop: 7 }}>
                                    {examInfo == null ? '' : examInfo.title}
                                </div>
                                <DocViewer className="exam-file-viewer" documents={[{ uri: examInfo == null ? '' : context.loadImage(examInfo.file), fileType:'pdf'}]} pluginRenderers={DocViewerRenderers}
                                    config={{
                                        header: {
                                            disableHeader: true,
                                            disableFileName: false,
                                            retainURLParams: false
                                        },
                                        pdfZoom: {
                                            defaultZoom: 0.9,
                                        },
                                        pdfVerticalScrollByDefault: true,
                                    }} />
                                {/* <div style={{ height: 52, borderRadius: 12, border: '1px solid #EEF4FB', background: '#FFF', display: 'flex', alignItems: 'center' }}>

                                </div> */}
                            </div>
                        }
                        {
                            selectedUser != null && selectedUser.exam_submission_time &&
                            <div className='d-flex flex-column' style={{ width: '100%', height: '100%' }}>
                                <div className='d-flex flex-row align-items-center' style={{ marginTop: 10 }}>
                                    <div className='result-time-wrap'>
                                        <div>{'응시시간'}</div>
                                        <div className='time'>{getTimeFromSecond(examResultInfo == null ? 0 : examResultInfo.exam_time)}</div>
                                    </div>
                                    <div className='result-score-wrap' style={{ marginLeft: 10 }}>
                                        <div>{'점수'}</div>
                                        <div className='score'>{(examResultInfo == null ? 0 : examResultInfo.score) + '점'}</div>
                                    </div>
                                    {
                                        extraScoreType !== 0 &&
                                        <div className={'extra-score-wrap ' + (extraScoreType === 1 ? 'additional' : 'deducted')} style={{ marginLeft: 10 }}>
                                            <div>{extraScoreType === 1 ? '가점' : '감점'}</div>
                                            <input
                                                type='number'
                                                value={extraScore}
                                                onBlur={() => onSaveExtraScore()}
                                                onChange={(e) => (setExtraScore(e.target.value))} />
                                        </div>
                                    }
                                    <button className='extra-score-button' onClick={() => setScoreAlert(true)}>
                                        <img src='/assets/image/btn_plus_minus.png' />
                                    </button>
                                </div>
                                <div style={{ marginTop: 22, marginBottom: 10, color: '#222', fontSize: 15, fontWeight: 700, letterSpacing: -0.3 }}>
                                    {'OMR 답안지'}
                                </div>
                                <div className='flex-1' style={{ overflowX: 'auto', border: '1px solid #DFE1E8', borderRadius: 7, position: 'relative', padding: '16px 14px 0' }}>
                                    <div className='d-flex flex-column' style={{ flexWrap: 'wrap', height: '100%' }} >
                                        {
                                            userAnswerList.map((answerItem, index) => {
                                                return <div key={index} className='d-flex flex-row align-items-center m-b-10'>
                                                    <div className='d-flex' style={{ width: 38, height: 38, position: 'relative' }}>
                                                        <div style={{ margin: 'auto' }}>{(index + 1) + '번'}</div>
                                                        <img src={'/assets/image/icon_answer_' + (answersAreEqual(correctAnswerList[index].answer,answerItem.answer,answerItem.type) ? 'correct' : 'wrong') + '.png'} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                                                    </div>
                                                    {
                                                        answerItem.type === 1 ?
                                                            <div className='omr-answer'>
                                                                <button className={'check-item' + (answerItem.answer.includes(1) ? ' selected' : '') + (correctAnswerList[index].answer.includes(1) ? ' correct' : '')} />
                                                                <button className={'check-item' + (answerItem.answer.includes(2) ? ' selected' : '') + (correctAnswerList[index].answer.includes(2) ? ' correct' : '')} />
                                                                <button className={'check-item' + (answerItem.answer.includes(3) ? ' selected' : '') + (correctAnswerList[index].answer.includes(3) ? ' correct' : '')} />
                                                                <button className={'check-item' + (answerItem.answer.includes(4) ? ' selected' : '') + (correctAnswerList[index].answer.includes(4) ? ' correct' : '')} />
                                                                <button className={'check-item' + (answerItem.answer.includes(5) ? ' selected' : '') + (correctAnswerList[index].answer.includes(5) ? ' correct' : '')} />
                                                            </div>
                                                            :
                                                            <div className='omr-answer'>
                                                                <div className='answer-text'>
                                                                    {answerItem.answer}
                                                                    {
                                                                        correctAnswerList[index].answer !== answerItem.answer &&
                                                                        <div className='correct-answer'>{correctAnswerList[index].answer}</div>
                                                                    }
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                                {
                                        showFeedback &&
                                        <div className='feedback-area teacher'>
                                            <textarea
                                                className="feedback-input"
                                                placeholder={''}
                                                rows={7}
                                                value={feedback}
                                                style={{ resize: 'none' }}
                                                onBlur={() => onSaveFeedback()}
                                                onChange={(e) => setFeedback(e.target.value)} />
                                        </div>
                                    }
                                    <button className='btn-feedback d-center teacher' onClick={() => setShowFeedback(!showFeedback)}>
                                        <div className='f-s-16 m-l-10'>{'피드백'}</div>
                                        <img
                                            src={showFeedback ? "/assets/image/icon_arrow_up_white.png" : "/assets/image/icon_arrow_down_white.png"}
                                            alt="" style={{ width: 15, height: 10, marginLeft: 16 }} />
                                    </button>
                            </div>
                        }
                        {
                            selectedUser != null && selectedUser.is_participate === 0 &&
                            <div className='d-flex flex-column align-items-center justify-content-center' style={{ width: '100%', height: '100%' }}>
                                <div className='d-flex flex-column align-items-center'>
                                    <img src='/assets/image/icon_ai_coach.png' style={{ width: 128 }} />
                                    <div style={{ color: '#A5AEB2', fontSize: 15, fontWeight: 400, lineHeight: '20px', letterSpacing: -0.3, marginTop: 14 }}>{'시험을 응시하지 않았습니다.'}</div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='d-flex flex-column col-md-5'>
                        <div className='students-list'>
                            <div className='list-header'>
                                <div className='text'>{'학생'}</div>
                                <div className='participates'>
                                    <div className='active'>
                                        {(examInfo == null ? 0 : examInfo.participates) + '명 / '}
                                    </div>
                                    <div className='normal'>
                                        {(examInfo == null ? 0 : userList.length) + '명'}
                                    </div>
                                </div>
                            </div>
                            <div className='list-content'>
                                {
                                    userList.map((student, index) => {
                                        return <div key={index} className={'student-item' + (student === selectedUser ? ' selected' : '')} onClick={() => { onStudent(index) }}>
                                            <img className='profile' src={student.profile != null ? context.loadImage(student.profile) : "/assets/image/chewing_plus_logo.png"} />
                                            <div className='d-flex flex-column' style={{ marginLeft: 12 }}>
                                                <div className='nickname'>{student.nickname}</div>
                                                <div className='info'>{student.school + ' • ' + (getAgeFromBirthday(student.birthday) >= 19 ? strings.g3 : (getAgeFromBirthday(student.birthday) === 18 ? strings.g2 : strings.g1))}</div>
                                            </div>
                                            <div className={'status' + (student.exam_submission_time ? '' : ' red')}>
                                                {student.exam_submission_time ? '응시' : '미응시'}
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {(scoreAlert &&
                <SweetAlert showCancel
                    confirmBtnText={'가점'}
                    confirmBtnBsStyle="primary"
                    cancelBtnText={'감점'}
                    cancelBtnBsStyle="danger"
                    title={
                        <div>
                            {'출제자가 직접 점수를'}
                            <br />
                            {'바꿀 수 있습니다.'}
                        </div>
                    }
                    customClass="exam-save-alert"
                    onConfirm={() => {
                        setScoreAlert(false);
                        setExtraScoreType(1);
                    }}
                    onCancel={() => {
                        setScoreAlert(false);
                        setExtraScoreType(-1);
                    }}
                    confirmBtnCssClass="alert-confirm"
                    cancelBtnCssClass="alert-cancel"
                />
            )}
        </div>
    );

}

export default withRouter(ExamTeacher);
