import React from "react";

const ClassSec3 = () => {
    return (
        <>
            <section className="wrap class-sec3">
                <div className="inner">
                    <div className="main-tit">
                        <h2>
                            <span>내가 봐야하는 </span> <br />
                            정보를 한눈에
                        </h2>
                    </div>
                    <div className="box-layout">
                        <div className="wid-50 box1">
                            <div className="txt-box">
                                <h6>클라우드</h6>
                                <h3>
                                    차곡차곡 쌓이는 <br />
                                    학습자료
                                </h3>
                                <p>일상과 업무를 나눌수 있는 개별 클라우드 제공</p>
                            </div>
                            <div className="img-box">
                                <img src="Img/sub/class-sec3-box1.png" alt="클라우드" />
                            </div>
                        </div>
                        <div className="wid-50 box2">
                            <div className="txt-box">
                                <h6>학사일정</h6>
                                <h3>
                                    까먹지 마세요, <br />
                                    학사일정에서 확인하세요
                                </h3>
                                <p>내가 속한 그룹에서 어떤일이 벌어지고 있는지 확인해보세요</p>
                            </div>
                            <div className="img-box">
                                <img src="Img/sub/class-sec3-box2.png" alt="학사일정" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ClassSec3;