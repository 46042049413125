import React, { useContext, useEffect, useRef, useState } from 'react';
import { withRouter } from "react-router-dom";
import strings from "../../../lang/strings";
import 'react-datetime/css/react-datetime.css';
import { ReactNotifications, Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import moment from "moment";
import { Page_settings } from "../../../config/page_settings";
import SweetAlert from "react-bootstrap-sweetalert";
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { getAgeFromBirthday, getTimeFromSecond, getMinuteFromSecond } from '../../../helper/common';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const QuizTeacher = (props) => {
    const context = useContext(Page_settings);
    const quizId = parseInt(props.match.params.quiz_id);
    const history = useHistory();
    const [quizInfo, setQuizInfo] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [scoreAlert, setScoreAlert] = useState(false);
    const [userList, setUserList] = useState([]);
    const [extraScoreType, setExtraScoreType] = useState(0);
    const [extraScore, setExtraScore] = useState(0);
    const [quizResultInfo, setQuizResultInfo] = useState(null);
    const [userAnswerList, setUserAnswerList] = useState([]);
    const [showFeedback, setShowFeedback] = useState(false);
    const [feedback, setFeedback] = useState('');
    const [answerList, setAnswerList] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        getQuizInfo();
    }, []);

    useEffect(() => {
        if (selectedUser != null && selectedUser.is_participate == 1) {
            getQuizResult();
        }
    }, [selectedUser])

    const getQuizInfo = () => {
        context.post(
            'quiz/info',
            {
                id: quizId
            },
            response => {
                console.log("info", response)
                const quizInfo = response.info;
                const users = response.users;
                setQuizInfo(quizInfo);
                setAnswerList(JSON.parse(quizInfo.answer_data))
                setUserList(users);
            }
        );
    }

    const getQuizResult = () => {
        context.post(
            'quiz/result',
            {
                quiz: quizId,
                member: selectedUser.id
            },
            response => {
                console.log("result", response)
                const resultInfo = response.info;
                setQuizResultInfo(resultInfo);
                setUserAnswerList(JSON.parse(resultInfo.answer_data));
                setExtraScore(resultInfo.extra_score);
                setExtraScoreType(resultInfo.extra_score > 0 ? 1 : resultInfo.extra_score < 0 ? -1 : 0);
                setFeedback(resultInfo.feedback);
            }
        );
    }

    const onStudent = (index) => {
        if (userList[index].is_participate === -1) {
            return null;
        }
        setSelectedUser(userList[index]);
    }

    const onSaveFeedback = () => {

        context.post(
            'quiz/save_feedback',
            {
                id: quizId,
                member: selectedUser.id,
                feedback: feedback

            },
            response => {
                addNotification('success', '', strings.success_to_save)
            }
        );
    }

    const handleNext = () => {
        if (currentIndex < answerList.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const addNotification = (notificationType, notificationTitle, notificationMessage) => {
        Store.addNotification({
            title: notificationTitle,
            message: notificationMessage,
            type: notificationType,
            insert: 'top',
            container: 'top-center',
            dismiss: {
                duration: 1000,
            }
        });
    }

    const backQuizLobby = () => {
        history.goBack();
    }

    // 가장 높은 progress 값을 찾기 (answerList가 정의되어 있을 때)
    const high_progress = answerList.length > 0 && answerList[currentIndex]?.progress ? Math.max(...Object.values(answerList[currentIndex].progress)) : 0;

    return (
        <div style={{ width: '100%', height: 'calc(100vh - 90px)' }}>
            <div className="main-content quiz-result-content teacher" style={{ width: '100%', height: '100%', padding: '18px 28px 0', borderRadius: 12, background: '#fff', display: 'flex', flexDirection: 'column' }}>

                <ReactNotifications />
                <div className='d-flex flex-row flex-1' style={{ overflow: 'hidden', paddingBottom: 24 }}>
                    <div className='d-flex flex-column col-md-9'>

                        {
                            selectedUser == null &&
                            <>
                                <img src='/assets/image/icon_back_gray_circle.png' style={{ position: 'fixed', top: '10px', zIndex: '9999', cursor: 'pointer' }} onClick={() => backQuizLobby()} />
                                <div className='d-flex flex-column' style={{ width: '100%', height: '100%' }}>
                                    <div className="flex">

                                    {quizInfo?.correct_rate && quizInfo?.correct_rate !== undefined &&(
                                          <div className='correct-rate-box'>
                                          <div className="title">정답률</div>
                                          <div className='correct-rate'>{quizInfo?.correct_rate}%</div>
                                      </div>
                                    )}
                                      

                                        <div style={{ color: '#0F84F4', fontSize: 13, fontWeight: 600, marginTop: 15 }}>
                                            {quizInfo == null ? '' : `${moment(quizInfo?.start_time).format('YY.MM.DD (ddd) HH:mm')}~${moment(quizInfo?.end_time).format('YY.MM.DD (ddd) HH:mm')}`}
                                        </div>
                                    </div>
                                    <div className='quiz-info-box'>
                                        <div className='quiz-top'>
                                            <div className='quiz-title'>{quizInfo?.title}</div>
                                        </div>
                                        
                                            <div className="quiz-question-box">
                                                <div className='number'>
                                                    {currentIndex + 1}.
                                                </div>
                                                <div className='question'>
                                                    {answerList[currentIndex]?.title}
                                                </div>
                                            </div>
                                        <div className={`quiz-answer-box ${answerList[currentIndex]?.type === 0 ? 'subjective' : answerList[currentIndex]?.type === 2 ? 'ox-quiz-box' : ''}`}>
                                            {(answerList[currentIndex]?.type === 1 || answerList[currentIndex]?.type === 2) && answerList.length > 0 && answerList[currentIndex] && Object.keys(answerList[currentIndex]?.question).map(key => (
                                                <div key={key} className='quiz-answer'>
                                                    <button
                                                        style={{
                                                            width: '30px',
                                                            height: '30px',
                                                            borderRadius: '50%',
                                                            border: answerList[currentIndex].answer === key ? '2px solid black' : '1px solid grey',
                                                            background: answerList[currentIndex].answer === key ? 'black' : 'white',
                                                            color: answerList[currentIndex].answer === key ? 'white' : 'black'
                                                        }}
                                                        disabled={true}
                                                    >
                                                        {key}
                                                    </button>
                                                    {answerList[currentIndex].type === 1 ? <span>{answerList[currentIndex].question[key]}</span> : null}
                                                    
      {answerList[currentIndex]?.progress && answerList[currentIndex]?.progress[key] !== undefined && (
        <div className={`quiz-progress-box ${answerList[currentIndex].progress[key] === high_progress ? 'high' : ''} ${answerList[currentIndex].progress[key] === 0 ? 'empty' : ''}`}>
          {`${Math.round(answerList[currentIndex].progress[key])}%`}
        </div>
      )}
    </div>
  ))}
                                            {answerList[currentIndex]?.type === 0 && <div className='quiz-input'>{answerList[currentIndex].answer}</div>}
                                        </div>

                                        <div className='quiz-btn-box' >
                                            <button className='left-btn' style={{ marginBottom: 'none' }} onClick={handlePrev} disabled={currentIndex === 0}>
                                                <img src={currentIndex === 0 ? '/assets/image/icon_arrow_left_white.png' : '/assets/image/icon_arrow_left_white.png'} alt='' />
                                            </button>
                                            <div className='flex-box'>
                                                <div className='quiz-progress' style={{ position: 'unset', border: '1px solid #222222', padding: '5px 30px', width: '100px', margin: '0 auto' }}>{currentIndex + 1} / {answerList.length}</div>
                                                <div className='flex-content'>
                                                    {typeof high_progress === 'number' && isFinite(high_progress) && high_progress >= 0 && (
                                                        <div className='quiz-time'>{high_progress}%</div>
                                                    )}
                                                </div>
                                            </div>
                                            <button className='right-btn' onClick={handleNext} disabled={currentIndex === answerList.length - 1}>
                                                <img src={currentIndex === 0 ? '/assets/image/icon_arrow_right_white1.png' : '/assets/image/icon_arrow_right_white1.png'} alt="" />
                                            </button>
                                        </div>
                                        
                                    </div>
                                </div>
                            </>
                        }


                        {
                            selectedUser != null && selectedUser.is_participate == 1 &&
                            <div className='d-flex flex-column' style={{ width: '100%', height: '100%' }}>
                                <img src='/assets/image/icon_back_gray_circle.png' style={{ position: 'fixed', top: '10px', zIndex: '9999', cursor: 'pointer' }} onClick={() => setSelectedUser(null)} />
                                <div className='d-flex flex-row align-items-center' style={{ cursor: 'pointer', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div className="selected-student-head" style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                        <img className='top-profile' src={selectedUser.profile != "" ? context.loadImage(selectedUser.profile) : "/assets/image/chewing_plus_logo.png"} />
                                        <div className='top-nickname'>{selectedUser.name}</div>

                                        <div className={(selectedUser.is_participate === -1 ? 'status' : selectedUser.is_participate === 1 ? 'status blue' : 'status red')}>
                                            {selectedUser.is_participate === -1 ? '미제출' : selectedUser.is_participate === 1 ? '합격' : '불합격'}
                                        </div>
                                        <div className={(selectedUser.is_participate === -1 ? 'status' : selectedUser.is_participate === 1 ? 'status blue' : 'status red')}>
                                            {getMinuteFromSecond(selectedUser.quiz_time)}
                                        </div>

                                    </div>
                                    <div style={{ position: 'relative' }}>
                                        {
                                            showFeedback &&
                                            <div className='feedback-area teacher'>
                                                <textarea
                                                    className="feedback-input"
                                                    placeholder={''}
                                                    rows={7}
                                                    value={feedback}
                                                    style={{ resize: 'none' }}
                                                    onBlur={() => onSaveFeedback()}
                                                    onChange={(e) => setFeedback(e.target.value)} />
                                            </div>
                                        }
                                        <button className='btn-feedback d-center teacher' style={{ position: 'unset', width: '130px' }} onClick={() => setShowFeedback(!showFeedback)} >
                                            <div className='f-s-16 m-l-10'>{'피드백'}</div>
                                            <img
                                                src={showFeedback ? "/assets/image/icon_arrow_up_white.png" : "/assets/image/icon_arrow_down_white.png"}
                                                alt="" style={{ width: 15, height: 10, marginLeft: 16 }} />
                                        </button>
                                    </div>
                                </div>
                                <div className='flex-1' style={{ overflowX: 'auto', border: '1px solid #DFE1E8', borderRadius: 7, position: 'relative', padding: '16px 14px 0', display: 'flex', marginTop: '10px' }}>
                                    <div style={{ textAlign: 'center', fontSize: '16px', position: 'relative', width: '80%', flex: '8', marginTop: '35px', display:'flex',flexDirection:'column',padding:'0px 20px' }}>
                                        <div className='quiz-top' >
                                            <div className='quiz-title'>{quizInfo.title}</div>
                                        </div>
                                        <div className='d-flex'>
                                            <div className="quiz-question-box" >
                                                <div className='number'>
                                                    {currentIndex + 1}.
                                                    <img src={'/assets/image/icon_answer_' + (answerList[currentIndex]?.answer == userAnswerList[currentIndex]?.answer ? 'correct' : 'wrong') + '.png'} style={{ position: 'absolute', top: '50%', left: '30%', transform: 'translate(-50%, -50%)' }} />
                                                </div>
                                                <div className='question'>
                                                    {answerList[currentIndex]?.title}
                                                </div>
                                            </div>


                                        </div>
                                        <div className={`quiz-answer-box ${userAnswerList[currentIndex]?.type === 0 ? ' subjective' : userAnswerList[currentIndex]?.type === 2 ? 'ox-quiz-box' : ''}`}>


                                            {(userAnswerList[currentIndex]?.type === 1 || userAnswerList[currentIndex]?.type === 2) && userAnswerList.length > 0 && userAnswerList[currentIndex] && Object.keys(userAnswerList[currentIndex]?.question).map(key => (
                                                <div key={key} className='quiz-answer'>

                                                    <button
                                                        style={{
                                                            width: '30px',
                                                            height: '30px',

                                                            borderRadius: '50%',
                                                            border: userAnswerList[currentIndex]?.answer === key ? '2px solid black' : '1px solid grey',
                                                            background: userAnswerList[currentIndex]?.answer === key ? 'black' : 'white',
                                                            color: userAnswerList[currentIndex]?.answer === key ? 'white' : 'black'
                                                        }}
                                                        disabled={true}
                                                    >
                                                        {key}
                                                    </button>
                                                    {userAnswerList[currentIndex]?.type === 1 ?
                                                        <span>{userAnswerList[currentIndex]?.question[key]}</span>
                                                        : null}
                                                    <div className={`quiz-progress-box ${answerList[currentIndex].progress[key] === high_progress ? 'high' : ''} ${answerList[currentIndex].progress[key] === 0 ? 'empty' : ''}`}>
                                                        {answerList[currentIndex].progress[key] !== undefined ? `${answerList[currentIndex].progress[key]}%` : null}
                                                    </div>


                                                </div>
                                            ))}
                                            {userAnswerList[currentIndex]?.type === 0 &&
                                                <div className='quiz-input'>{userAnswerList[currentIndex]?.answer} </div>
                                            }

                                        </div>
                                        <div className={'quiz-btn-box'}>
                                            <button className='left-btn' style={{ marginBottom: 'none' }} onClick={handlePrev} disabled={currentIndex === 0}>
                                                <img src={currentIndex === 0 ? '/assets/image/icon_arrow_left_white.png' : '/assets/image/icon_arrow_left_white.png'} alt='' />
                                            </button>
                                            <div className='flex-box'>
                                                <div className='quiz-progress' style={{ position: 'unset', border: '1px solid #222222', padding: '5px 30px', width: '100px', margin: '0 auto' }}>{currentIndex + 1} / {answerList.length}</div>
                                                <div className='flex-content'>
                                                    {typeof high_progress === 'number' && isFinite(high_progress) && high_progress >= 0 && (
                                                        <div className='quiz-time'>{high_progress}%</div>
                                                    )}
                                                    <div className='quiz-time'>{getMinuteFromSecond(userAnswerList[currentIndex]?.time)}</div>
                                                </div>
                                            </div>

                                            <button className='right-btn' onClick={handleNext} disabled={currentIndex === answerList.length - 1}>
                                                <img src={currentIndex === 0 ? '/assets/image/icon_arrow_right_white1.png' : '/assets/image/icon_arrow_right_white1.png'} alt="" />
                                            </button>
                                        </div>
                                    </div>
                                    <div style={{ width: '30%' }}>

                                        <div style={{ marginBottom: 10, color: '#222', fontSize: 15, fontWeight: 700, letterSpacing: -0.3 }}>
                                            {'OMR 답안지'}
                                        </div>

                                        <div className='d-flex flex-column' style={{ flexWrap: 'wrap', height: '95%' }} >
                                            <div className="omr-content">
                                                <div className='omr-data'>
                                                    <div className='omr-header'>
                                                        <div className='omr-no'>{'문제'}</div>
                                                        <div style={{ width: 1, height: '100%', background: '#DFE1E8' }} />
                                                        <div className='omr-answer'>
                                                            <div>1</div>
                                                            <div>2</div>
                                                            <div>3</div>
                                                            <div>4</div>
                                                            <div>5</div>
                                                        </div>
                                                    </div>
                                                    <div className='omr-list'>
                                                        {
                                                            userAnswerList.map((answer, index) => {
                                                                return <div key={index} className='d-flex flex-row align-items-center' style={{ height: 40 }}>
                                                                    <div className='d-flex' style={{ width: 50, height: '100%', position: 'relative' }}>
                                                                        <div style={{ margin: 'auto' }}>{(index + 1) + '번'}</div>
                                                                        <img src={'/assets/image/icon_answer_' + (answerList[index]?.answer == answer?.answer ? 'correct' : 'wrong') + '.png'} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                                                                    </div>
                                                                    <div style={{ width: 1, height: '100%', background: '#DFE1E8' }} />
                                                                    {
                                                                        answer.type === 1 ? (
                                                                            <div className='omr-answer'>
                                                                                <div className={'check-item' + (answer.answer === '1' ? ' selected' : '')} />
                                                                                <div className={'check-item' + (answer.answer === '2' ? ' selected' : '')} />
                                                                                <div className={'check-item' + (answer.answer === '3' ? ' selected' : '')} />
                                                                                <div className={'check-item' + (answer.answer === '4' ? ' selected' : '')} />
                                                                                <div className={'check-item' + (answer.answer === '5' ? ' selected' : '')} />
                                                                            </div>
                                                                        ) : answer.type === 0 ? (
                                                                            <div className='omr-answer'>

                                                                                <div className='answer-text'>
                                                                                    <PerfectScrollbar style={{ padding: '7px 9px', display: 'flex', whiteSpace: 'nowrap' }}>
                                                                                        {answer?.answer}
                                                                                        {
                                                                                            answerList[index]?.answer != answer?.answer &&
                                                                                            <div className='correct-answer'>{answerList[index]?.answer}</div>
                                                                                        }
                                                                                    </PerfectScrollbar>
                                                                                </div>

                                                                            </div>
                                                                        ) : (
                                                                            <div className='omr-answer' style={{ justifyContent: 'space-around', textAlign: 'center' }}>
                                                                                <div className={'check-item' + (answer.answer === 'O' ? ' selected' : '')} >O</div>
                                                                                <div className={'check-item' + (answer.answer === 'X' ? ' selected' : '')} >X</div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        }
                        {
                            selectedUser != null && selectedUser.is_participate == 0 &&
                            <div className='d-flex flex-column align-items-center justify-content-center' style={{ width: '100%', height: '100%' }}>
                                <div className='d-flex flex-column align-items-center'>
                                    <img src='/assets/image/icon_ai_coach.png' style={{ width: 128 }} />
                                    <div style={{ color: '#A5AEB2', fontSize: 15, fontWeight: 400, lineHeight: '20px', letterSpacing: -0.3, marginTop: 14 }}>{'시험을 응시하지 않았습니다.'}</div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='d-flex flex-column col-md-3'>
                        <div className='students-list' style={{ maxWidth: 'unset' }}>
                            <div className='list-header'>
                                <div className='text'>{'학생'}</div>
                                <div className='participates' style={{ marginRight: '10px' }}>
                                    <div className='active'>
                                        {(quizInfo == null ? 0 : quizInfo.participates) + '명 / '}
                                    </div>
                                    <div className='normal'>
                                        {(quizInfo == null ? 0 : quizInfo.testers.split(',').length) + '명'}
                                    </div>
                                </div>
                            </div>
                            <div className='list-content'>
                                {
                                    userList.map((student, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={'student-item' + (student === selectedUser ? ' selected' : '')}
                                                onClick={() => { onStudent(index) }}
                                            >
                                                <img
                                                    className='profile'
                                                    src={student.profile != "" ? context.loadImage(student.profile) : "/assets/image/chewing_plus_logo.png"}
                                                />
                                                <div className='d-flex flex-column' style={{ marginLeft: 12 }}>
                                                    <div className='nickname'>{student.name}</div>
                                                    <div className='info'>
                                                        {student.school + ' • ' + (getAgeFromBirthday(student.birthday) >= 19 ? strings.g3 : (getAgeFromBirthday(student.birthday) === 18 ? strings.g2 : strings.g1))}
                                                    </div>
                                                </div>
                                                <div className="flex-left-auto" style={{ marginRight: '10px' }}>
                                                    {student.is_participate !== -1 && (
                                                        <div className={'status' + (student.is_participate === 1 ? ' blue' : ' red')}>
                                                            <div className='time'>
                                                                {getMinuteFromSecond(student.quiz_time)}
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className={(student.is_participate === -1 ? 'status' : student.is_participate === 1 ? 'status blue' : 'status red')}>
                                                        {student.is_participate === -1 ? '미제출' : student.is_participate === 1 ? '합격' : '불합격'}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {(scoreAlert &&
                <SweetAlert showCancel
                    confirmBtnText={'가점'}
                    confirmBtnBsStyle="primary"
                    cancelBtnText={'감점'}
                    cancelBtnBsStyle="danger"
                    title={
                        <div>
                            {'출제자가 직접 점수를'}
                            <br />
                            {'바꿀 수 있습니다.'}
                        </div>
                    }
                    customClass="quiz-save-alert"
                    onConfirm={() => {
                        setScoreAlert(false);
                        setExtraScoreType(1);
                    }}
                    onCancel={() => {
                        setScoreAlert(false);
                        setExtraScoreType(-1);
                    }}
                    confirmBtnCssClass="alert-confirm"
                    cancelBtnCssClass="alert-cancel"
                />
            )}
        </div>
    );

}

export default withRouter(QuizTeacher);
