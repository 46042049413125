import React, { useContext, useEffect, useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from "styled-components";
import strings from "../../../lang/strings";

import { Page_settings } from '../../../config/page_settings';
import { rootStore } from "../../../mobx/store";
import { API_URL } from "../../../config/const";
import axios from "axios";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import moment from "moment";
import PerfectScrollbar from 'react-perfect-scrollbar';

function EditCurriculumStep4Modal(props) {
  const history = useHistory();
  const [wholeTextArray, setWholeTextArray] = useState([]);
  const [classValue, setClassValue] = useState('')


  const [videoLink, setVideoLink] = useState()

  const [clickInput, setClickInput] = useState(false)
  const [selectedUnit, setSelectedUnit] = useState([]);

  const step1FileRef = useRef();
  const step2TestFileRef = useRef();
  const step2CommentaryFileRef = useRef();
  const step3TestFileRef = useRef();
  const step3CommentaryFileRef = useRef();

  const [profile, setProfile] = useState("");
  const [step1ModeBtn, setStep1ModeBtn] = useState(1);
  const [step1Url, setStep1Url] = useState([]);
  const [step1Thumbnail, setStep1Thumbnail] = useState([]);
  const [step1FileList, setStep1FileList] = useState([]);
  const [step2TestUrl, setStep2TestUrl] = useState([]);
  const [step2TestThumbnail, setStep2TestThumbnail] = useState([]);
  const [step2TestFileList, setStep2TestFileList] = useState([]);
  const [step2CommentaryUrl, setStep2CommentaryUrl] = useState([]);
  const [step2CommentaryThumbnail, setStep2CommentaryThumbnail] = useState([]);
  const [step2CommentaryFileList, setStep2CommentaryFileList] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [step, setStep] = useState(1);
  const [step2Title, setStep2Title] = useState(props.clickUnit.name);
  const [step2QuestionCount, setStep2QuestionCount] = useState(0);
  const [step2QuestionTime, setStep2QuestionTime] = useState(0);
  const [step3TestUrl, setStep3TestUrl] = useState([]);
  const [step3Title, setStep3Title] = useState(props.clickSection.name);
  const [step3TestThumbnail, setStep3TestThumbnail] = useState([]);
  const [step3TestFileList, setStep3TestFileList] = useState([]);
  const [step3CommentaryUrl, setStep3CommentaryUrl] = useState([]);
  const [step3CommentaryThumbnail, setStep3CommentaryThumbnail] = useState([]);
  const [step3CommentaryFileList, setStep3CommentaryFileList] = useState([]);
  const [step3QuestionCount, setStep3QuestionCount] = useState('');
  const [step3QuestionTime, setStep3QuestionTime] = useState('');

  const test_score = props.curriculumData.thumbnail.test_score;

  // 추가된 부분 시작
  useEffect(() => {
    if (props.clickSection) {
      if (props.clickUnit) {
        const unit = props.clickUnit;
        if (unit.video_url) {
          setStep1Url(unit.video_url)
          if (unit.video_file) {
            setStep1FileList(unit.video_file);
          } else {
            let list = [];
            list.push({ title: unit.video_title, size: unit.video_size, type: unit.video_type })
            setStep1FileList(list)
          }

        }
        if (unit.link) {
          setVideoLink(unit.link)
        }
        if (unit.count) {
          setStep2QuestionCount(unit.count)
          setStep2QuestionTime(unit.count)
        }
        if (unit.test_url && unit.test_url.length > 0) {
          setStep2TestUrl(unit.test_url)
          if (unit.test_file) {
            setStep2TestFileList(unit.test_file)
          } else {
            let list = [];
            list.push({ title: unit.test_title, size: unit.test_size, type: unit.test_type })
            setStep2TestFileList(list)
          }

        }
        if (unit.commentary_url) {
          setStep2CommentaryUrl(unit.commentary_url)
          if (unit.commentary_file) {
            setStep2CommentaryFileList(unit.commentary_file)
          } else {
            let list = [];
            list.push({ title: unit.commentary_title, size: unit.commentary_size, type: unit.commentary_type })
            setStep2CommentaryFileList(list)
          }
        }
      }

    }
  }, [props.clickSection]);


  const fileTypes = [
    "image/bmp",
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/tiff",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "text/htm",
    "text/html",
    "application/pdf",
    "application/vnd.ms-powerpoint",
    "applicatiapplication/vnd.openxmlformats-officedocument.presentationml.presentation",
    "text/plain",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "video/mp4"]

  const context = useContext(Page_settings);


  useEffect(() => {

    if (rootStore.getProfile != null) {
      setProfile(rootStore.getProfile.profile);
    }
  }, []);


  const EnableConfirm = () => {
    if((step1FileList.length > 0 || videoLink) && (step2CommentaryUrl.length === 0 && step2TestUrl.length === 0)){
      return true;
    }

    if(step2CommentaryUrl.length > 0 && step2TestUrl.length > 0){
        return true;
    }
    
    return false;
  
  }


  const client = axios.create({
    baseURL: API_URL + '/app/',
  })

  const sendFile = async (url) => {
    let formData = new FormData();
    url.forEach((entry, index) => {
      formData.append('file', entry[0]);
    });

    try {
      const response = await client.post(
        'upload/file/curriculum',
        formData,
      );

      return response.data.result_data.file; // 리스폰 값을 리턴합니다
    } catch (error) {
      console.error('File upload error:', error);
      return null; // 에러 발생 시 null 리턴
    }

  }

  const onSave = async (partIdx, chapterIdx, sectionIdx, unitIdx) => {

    const updatedUnitData = props.curriculumData.parts

    const unit = updatedUnitData[partIdx]
      .chapters[chapterIdx]
      .sections[sectionIdx]
      .units[unitIdx];
        updatedUnitData[partIdx]
          .chapters[chapterIdx]
          .sections[sectionIdx]
          .units[unitIdx] = {
          ...unit,
          link: videoLink,
          video_url: Array.isArray(step1Url) && step1Url.length > 0 ? step1Url : step1Url,
          video_file: step1FileList,
          title: step2Title,
          test_url: Array.isArray(step2TestUrl) && step2TestUrl.length > 0 ? step2TestUrl : step2TestUrl,
          test_file: step2TestFileList,
          test_file: step2TestFileList,
          commentary_url: Array.isArray(step2CommentaryUrl) && step2CommentaryUrl.length > 0 ? step2CommentaryUrl : step2CommentaryUrl,
          commentary_file: step2CommentaryFileList,
          achievement_rate: 100
        };
      props.updatePartCategory(updatedUnitData);
      handleClose();

    }




  const handleClose = () => {
    props.onClose?.();
  };


  const onStep1FileChange = (e) => {

    let file = [e.target.files];
    let thumbnail = e.target.files[0];
    setStep1Url([...step1Url, ...file]);
    let list = [];

    let reader = new FileReader();

    // 이미지 불러오기
    reader.onloadend = function () {
      setStep1Thumbnail([...step1Thumbnail, reader.result]);
    };
    reader.readAsDataURL(thumbnail);

    file.forEach(item => {
      if (fileTypes.indexOf(item[0].type) < 0) return

      let ext = item[0].name.split(".")[item[0].name.split(".").length - 1];
      let type = "/assets/image/icon_file_";
      if (ext == "docx") {
        type += "docs";
      } else if (ext == "mp4") {
        type += "mp4"
      } else {
        type += ext
      }
      type += ".png";

      list.push({ title: item[0].name, size: item[0].size, type: type })
    });
    // setStep1FileList([...step1FileList, ...list])
    setStep1FileList(list)
  }
  const onStep2TestFileChange = (e) => {

    let file = [e.target.files];
    let thumbnail = e.target.files[0];
    setStep2TestUrl([...step2TestUrl, ...file]);

    let list = [];

    let reader = new FileReader();

    // 이미지 불러오기
    reader.onloadend = function () {
      setStep2TestThumbnail([...step2TestThumbnail, reader.result]);
    };
    reader.readAsDataURL(thumbnail);

    file.forEach(item => {
      if (fileTypes.indexOf(item[0].type) < 0) return

      let ext = item[0].name.split(".")[item[0].name.split(".").length - 1];
      let type = "/assets/image/icon_file_";
      if (ext == "docx") {
        type += "docs";
      } else if (ext == "mp4") {
        type += "drive"
      } else {
        type += ext
      }
      type += ".png";

      list.push({ title: item[0].name, size: item[0].size, type: type })
    });
    // setStep2TestFileList([...step2TestFileList, ...list])
    setStep2TestFileList(list)
  }

  const onStep2CommentaryFileChange = (e) => {

    let file = [e.target.files];
    let thumbnail = e.target.files[0];
    setStep2CommentaryUrl([...step2CommentaryUrl, ...file]);

    let list = [];

    let reader = new FileReader();

    // 이미지 불러오기
    reader.onloadend = function () {
      setStep2CommentaryThumbnail([...step2CommentaryThumbnail, reader.result]);
    };
    reader.readAsDataURL(thumbnail);

    file.forEach(item => {
      if (fileTypes.indexOf(item[0].type) < 0) return

      let ext = item[0].name.split(".")[item[0].name.split(".").length - 1];
      let type = "/assets/image/icon_file_";
      if (ext == "docx") {
        type += "docs";
      } else if (ext == "mp4") {
        type += "drive"
      } else {
        type += ext
      }
      type += ".png";

      list.push({ title: item[0].name, size: item[0].size, type: type })
    });
    // setStep2CommentaryFileList([...step2CommentaryFileList, ...list])
    setStep2CommentaryFileList(list)
  }

  const changeStep2Title = (e) => {
    setStep2Title(e.target.value)
  }

  const changeVideoLink = (e) => {
    setVideoLink(e.target.value)
  };



  const delFile = (step, item) => {

    if (step === 'step1') {
      setStep1FileList(prevList => prevList.filter(file => file !== item));
      setStep1Url([]);
    } else if (step === 'step2Test') {
      setStep2TestFileList(prevList => prevList.filter(file => file !== item));
      setStep2TestUrl([]);
    } else if (step === 'step2Commentary') {
      setStep2CommentaryFileList(prevList => prevList.filter(file => file !== item));
      setStep2CommentaryUrl([]);
    }

  }


  return (
    <>

      <Overlay>
        <ModalWrap>
          <div className='create-roadmap-step4-modal'>
            <div className='step4-modal-header'>
              <div className='title'>
                <div className={`part`}> {props.clickPart.name} </div>
                <div>{">"}</div>
                <div className={`section ${step === 3 ? 'highlight' : ''} `}> {props.clickSection.name} </div>
                <div>{">"}</div>
                <div className={`unit ${step === 2 || step === 1 ? 'highlight' : ''} `}> {props.clickUnit.name} </div>
              </div>
              <div className='btn-container'>
                <button className='save' style={{ backgroundColor: EnableConfirm() ? '#0F84F4' : '#D9D9D9' }} disabled={!EnableConfirm()} onClick={() => onSave(props.clickPartIdx, props.clickChapterIdx, props.clickSectionIdx, props.clickUnitIdx)}>저장</button>
                <button className='close' onClick={() => handleClose()}>닫기</button>
              </div>
            </div>

            <div className='step-container'>
              <div className={`step-box ${step === 1 ? 'selected' : ''}`} onClick={() => setStep(1)}>
                영상
              </div>
              <div className={`step-box ${step === 2 ? 'selected' : ''}`} onClick={() => setStep(2)}>
                문제 풀이
              </div>
            </div>
            {step === 1 && (
  <div className='step1-container'>
    <div className='title'>
      <span className='highlight'>유닛</span>영상 업로드
      <div className='subtitle'>
        * 링크나 파일 둘 중 하나를 선택해 업로드해주세요.
      </div>
    </div>
    <div className='mode-btn-container'>
      {/* <button className={`${step1ModeBtn === 0 ? 'on' : 'off'} ${step1ModeBtn === 1 && step1FileList.length > 0 ? 'disabled' : ''}`} onClick={() => setStep1ModeBtn(0)} disabled={step1ModeBtn === 1 && step1FileList.length > 0}>영상 링크 첨부</button> */}
      <button className={`${step1ModeBtn === 1 ? 'on' : 'off'} ${step1ModeBtn === 0 && videoLink ? 'disabled' : ''}  `} onClick={() => setStep1ModeBtn(1)} disabled={step1ModeBtn === 0 && videoLink}>영상 파일 첨부</button>
    </div>
    {step1ModeBtn === 0 ? (
      <>
        <div className="sub-title">
          영상 링크
        </div>
        <div className="class-search align-item-center box-shadow">
          <input
            id='inputBox'
            className="input-transparent cursor"
            placeholder={"링크를 등록해주세요."}
            type={'text'}
            value={videoLink}
            onChange={changeVideoLink}
            disabled={step1Url.length > 0}
          />
        </div>
      </>
    ) : 
    <>
    <div className="sub-title">영상 파일</div>
    <input type='file' accept='video/mp4' className='hide'
      onChange={(e) => onStep1FileChange(e)}
      ref={step1FileRef} disabled={!!videoLink} />
    {step1FileList.length > 0 && step1FileList[0].type ? (
      <div style={{ width: '140px', height: '140px', border: '2px solid #D9D9D9', padding: '10px', borderRadius: '10px', position: 'relative' }}>
        <img className={'classAddModal-thumbnail'} src={step1FileList[0].type} alt="" />
        <img className='cursor' src='/assets/image/icon_close_gray.png' alt=''
          onClick={() => {
            delFile('step1', step1FileList[0])
          }}
          style={{ position: 'absolute', top: '0', right: '0' }}
        />
      </div>
    ) : (
      <div className={`img-add-box ${isChecked ? 'basic' : ''}`} onClick={() => {
        step1FileRef?.current.click();
      }}>
        <img className="classAddModal-no-thumbnail" src="/assets/image/class_addImg1.png" alt="" />
      </div>
    )}
      </>
    }
  </div>
)}


            {step === 2 &&
              <div className='step2-container'>
                <div className='title'>
                  <span className='highlight'>유닛</span>문제풀이 출제
                </div>
                <div class="sub-title">
                  문제풀이 제목

                </div>
                <div className="class-search  align-item-center box-shadow disable-input" >
                  <input
                    id='inputBox'
                    className="input-transparent cursor"
                    placeholder={"제목을 입력해주세요."}
                    type={'text'}
                    value={step2Title}
                    onChange={changeStep2Title}
                    disabled={true}
                  />

                </div>
                <div class="sub-title">시험 파일
                  <span className='subtitle'>
                    * 시험지 형식의 파일을 올려주세요.
                  </span>
                </div>
                <input type='file' accept='application/pdf' className='hide'
                  onChange={(e) => onStep2TestFileChange(e)}
                  ref={step2TestFileRef} />
                {step2TestFileList.length > 0 && step2TestFileList[0].type ?
                  <div style={{ width: '140px', height: '140px', border: '2px solid #D9D9D9', padding: '10px', borderRadius: '10px', position: 'relative' }}>
                    <img className={'classAddModal-thumbnail '} src={step2TestFileList[0].type} alt="" />
                    <img className='cursor' src='/assets/image/icon_close_gray.png' alt=''
                      onClick={() => {
                        delFile('step2Test', step2TestFileList[0])
                      }}
                      style={{ position: 'absolute', top: '0', right: '0' }}
                    />

                  </div>

                  :
                  <div className={`img-add-box ${isChecked ? 'basic' : ''}`} onClick={() => {
                    step2TestFileRef?.current.click();
                  }}
                  >
                    <img className="classAddModal-no-thumbnail" src="/assets/image/class_addImg1.png" alt="" />
                  </div>
                }
                <div class="sub-title">해설지 파일
                  <span className='subtitle'>
                    * 해설지 형식의 파일을 올려주세요.
                  </span>
                </div>
                <input type='file' accept='application/pdf' className='hide'
                  onChange={(e) => onStep2CommentaryFileChange(e)}
                  ref={step2CommentaryFileRef} />
                {step2CommentaryFileList.length > 0 && step2CommentaryFileList[0].type ?
                  <div style={{ width: '140px', height: '140px', border: '2px solid #D9D9D9', padding: '10px', borderRadius: '10px', position: 'relative' }}>
                    <img className={'classAddModal-thumbnail '} src={step2CommentaryFileList[0].type} alt="" />
                    <img className='cursor' src='/assets/image/icon_close_gray.png' alt=''
                      onClick={() => {
                        delFile('step2Commentary', step2CommentaryFileList[0])
                      }}
                      style={{ position: 'absolute', top: '0', right: '0' }}
                    />

                  </div>

                  :
                  <div className={`img-add-box ${isChecked ? 'basic' : ''}`} onClick={() => {
                    step2CommentaryFileRef?.current.click();
                  }}
                  >
                    <img className="classAddModal-no-thumbnail" src="/assets/image/class_addImg1.png" alt="" />
                  </div>
                }
              </div>
            }

          </div>

        </ModalWrap>
      </Overlay>
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const ModalWrap = styled.div`
  width: 1300px;
  height: 790px;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px 30px;
  overflow: auto;
  
`;
const Button = styled.button`
cursor: pointer;
width: 100%;
color: #ffffff;
border-radius: 5px;
border:0px;
padding: 15px;
margin-top: 60px;
display:flex;
align-items: center;
justify-content:center;
font-size:16px;
gap:5px;
`;



export default EditCurriculumStep4Modal;