import $ from "jquery";
import React, { useEffect, useMemo, useContext } from 'react';
import { Page_settings } from "../../../config/page_settings";

const ConferenceItem = (props) => {
    const context = useContext(Page_settings);
    const { item, videoTrack, audioTrack, muteVideo, muteAudio, reverseCam } = props;

    useMemo(() => {
        if (videoTrack != undefined && videoTrack != null) {
            if (muteVideo == true) {
                videoTrack.stop()
            } else if (muteVideo == false) {
                const currentVideoComponentId = `#stream-player-${item.user.id}`;
                $(currentVideoComponentId).children().eq(1).remove();
                videoTrack.play(`stream-player-${item.user.id}`)
            }
        }
    }, [muteVideo, videoTrack])

    useMemo(() => {
        if (audioTrack != undefined && audioTrack != null) {
            if (muteAudio == true) {
                audioTrack.stop()
            } else if (muteAudio == false) {
                audioTrack.play()
            }
        }
    }, [muteAudio, audioTrack])

    useEffect(() => {
        if (videoTrack != undefined && videoTrack != null) {
            if (muteVideo == true) {
                videoTrack.stop()
            } else if (muteVideo == false) {
                const currentVideoComponentId = `#stream-player-${item.user.id}`;
                $(currentVideoComponentId).children().eq(1).remove();
                videoTrack.play(`stream-player-${item.user.id}`)
            }
        }
    }, [muteVideo, videoTrack])

    useEffect(() => {
        if (audioTrack != undefined && audioTrack != null) {
            if (muteAudio == true) {
                audioTrack.stop()
            } else if (muteAudio == false) {
                audioTrack.play()
            }
        }
    }, [muteAudio, audioTrack])

    return (
        <div className={"conference-room-item position-relative" + (reverseCam ? " camera-reverse" : "")}
            style={{ display: "flex", position: "relative" }}
            id={`stream-player-${item.user.id}`}>
            {
                (muteVideo || videoTrack == undefined || videoTrack == null) &&
                <div
                    className="width-100-percent height-full d-flex align-item-center justify-content-around" style={{ position: 'absolute', left: 0, top: 0 }}>
                    <img src={item.user.profile != null && item.user.profile != "" ? context.loadImage(item.user.profile) : "/assets/image/chewing_plus_logo.png"}
                        style={{ width: 120, height: 120, borderRadius: 60, objectFit: 'cover' }}
                        alt=""
                    />
                </div>
            }
            <div className="user text-ellipsis">{item.user.name}</div>
            {
                item.reaction != undefined && item.reaction != null && item.reaction != "" &&
                <div className="width-100-percent position-absolute height-full d-flex align-item-center justify-content-around top-0" style={{ background: '#000000D4' }}>
                    <img src={item.reaction.icon} alt="" />
                </div>
            }
        </div>
    );
}

export default ConferenceItem;
