import React, { useContext } from 'react';
import { Modal, ModalBody } from "reactstrap";
import './modal.scss'
import { Page_settings } from "../../../config/page_settings";
import PerfectScrollbar from "react-perfect-scrollbar";

const RoadmapViewModal = (props) => {
    const context = useContext(Page_settings)
    const { item, show, toggle, testScore, courseList, onView, onSolve, onTest, onCourseItem } = props;
    console.log("courseList",courseList)
    return (
        <Modal isOpen={show} toggle={() => toggle()} className="roadmap-view-modal">
            <ModalBody>
                <div className="d-flex align-items-center m-b-30">
                    <div className="title flex-grow-1">{item.name}</div>
                    <button className='modal-close-btn' onClick={() => toggle()}>
                        <img src={"/assets/image/icon_close_black.png"} alt="" className="modal-close" />
                    </button>
                </div>
                <div className="title m-b-10" style={{ fontSize: 14, letterSpacing: -0.28 }}>{'소단원 코스'}</div>
                <div className='view-main'>
                    <PerfectScrollbar style={{ height: '100%' }}>
                        {
                            courseList.map((course, index) => (
                                <div key={index} className='d-flex align-items-center' style={{ paddingRight: 21 }}>
                                    <div className='course-index-wrapper'>
                                        <div className={'course-index' + (course.progress == 1 ? ' done' : '')}>{index + 1}</div>
                                        <div className={'course-index-arrow' + (index == courseList.length - 1 ? ' d-none' : '')}>
                                            {
                                                course.progress == 1 ?
                                                    <img src='/assets/image/icon_arrow_down_green.png' />
                                                    :
                                                    <img src='/assets/image/icon_arrow_down_gray.png' />
                                            }
                                        </div>
                                    </div>
                                    <div className={'course-item' + (course.progress == 1 ? ' done' : '')} onClick={() => onCourseItem(course)}>
                                        <div className='d-flex flex-column'>
                                            <div className='course-title'>{course.name}</div>
                                            <div className='d-flex flex-row align-items-center' style={{ marginTop: 20 }}>
                                                <div className='course-times'>{'문제풀이 횟수 '}<strong>{course.solve_count + '회'}</strong></div>
                                            </div>
                                            <div className='d-flex flex-row align-items-center' style={{ marginTop: 7 }}>
                                                <div className='course-times'>{'영상공부 '}<strong>{course.watch_count + '회'}</strong></div>
                                            </div>
                                        </div>
                                        <div className='d-flex flex-column'>
                                            {
                                                course.progress == 1 &&
                                                <div className='course-done-badge'>완료</div>
                                            }
                                            <div className="m-t-auto d-flex align-items-center">
                                                <button className="buttons solve-button" style={{ marginRight: 8 }} onClick={() => onSolve(course)}>
                                                    {'문제풀이'}
                                                </button>
                                                <button className="buttons view-button" onClick={() => onView(course)}>
                                                    {'영상공부'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </PerfectScrollbar>
                </div>
                <div className='test-button' style={{ marginTop: 24, marginLeft: 'auto' }} onClick={() => onTest()}>
                    <div className='d-flex flex-column'>
                        <div style={{ color: '#fff', fontSize: 14, fontWeight: 700, lineHeight: '16px', letterSpacing: -0.28 }}>{'테스트 하기'}</div>
                        <div style={{ color: '#E3F0FF', fontSize: 10, fontWeight: 500, lineHeight: '12px' }}>{'70점 이상 성취율 100% 달성'}</div>
                    </div>
                    <div className='d-flex align-items-center justify-content-center' style={{ width: 20, height: 20, borderRadius: 10, background: '#319AFF' }}>
                        <img src='/assets/image/icon_arrow_right_white.png' />
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default RoadmapViewModal;
