import { observer } from "mobx-react";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import DateTime from "react-datetime";
import { useForm } from "react-hook-form";
import { FaPlusCircle, FaTrash } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Button from "../../../components/button";
import SelectBox from "../../../components/select_box";
import TextField from "../../../components/text_field";
import { Page_settings } from "../../../config/page_settings";
import colors from "../../../design/colors";
import fonts from "../../../design/typography";
import { rootStore } from "../../../mobx/store";


interface FamilyInfo {
  name: string;
  phone: string;
  time: string;
  relationship: "부" | "모" | "기타";
}

interface StudyCounseling {
  subject: "국어" | "영어" | "수학" | "탐구";
  content: string;
}

interface CounselingFormData {
  id: number | null;
  name: string;
  counselingDate: string;
  birthday: string;
  gender: "M" | "F" | "";
  school: string;
  grade: number | null;
  isGraduated: boolean;
  university: string | null;
  department: string | null;
  address: string;
  phone: string;
  email: string;
  familyInfo: FamilyInfo[];
  studyCounseling: StudyCounseling[];
  lifeCounseling: string;
  suggestion: string;
  career: string | null;
}

const CounselingLog: React.FC = observer(() => {
  const [isUniv, setIsUniv] = useState(false);
  const context: any = useContext(Page_settings);
  const classId = rootStore.getClassId;
  const { register, handleSubmit, setValue, watch, setError, clearErrors, control ,formState: { errors }, trigger } =
    useForm<CounselingFormData>({
      mode: "onChange",
      defaultValues: {
        counselingDate: moment().format("YYYY-MM-DD"),
        birthday: moment().format("YYYY-MM-DD"),
        gender: "",
        familyInfo: [],
        isGraduated: false,
        grade: null,
        studyCounseling: [
          { subject: "국어", content: "" },
          { subject: "영어", content: "" },
          { subject: "수학", content: "" },
          { subject: "탐구", content: "" },
        ]
      },
    });

  const { id } = useParams();
  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    if (id) {
      setIsEdit(true);
      context.get(`/class/${classId}/counseling-log/${id}`, {
        counselingLogId: id
      }, (res: CounselingFormData) => {
        setValue("id", res.id);
        setValue("name", res.name);
        setValue("counselingDate", res.counselingDate);
        setValue("birthday", res.birthday);
        setValue("gender", res.gender);
        setValue("phone", res.phone);
        setValue("address", res.address);
        setValue("school", res.school);
        setValue("grade", res.grade);
        setValue("isGraduated", res.isGraduated);
        setValue("university", res.university);
        setValue("department", res.department);
        setValue("email", res.email);
        setValue("familyInfo", res.familyInfo);
        setValue("studyCounseling", res.studyCounseling);
        setValue("lifeCounseling", res.lifeCounseling);
        setValue("suggestion", res.suggestion);
        setValue("career", res.career);
      })
    }
  }, [id])

  useEffect(() => {
    if (watch('birthday') === '') {
      setError('birthday', { message: "생년월일을 입력해주세요" });
    } else {
      clearErrors('birthday');
    }

    if (watch('gender') === '') {
      setError('gender', { message: "성별을 선택해주세요" });
    } else {
      clearErrors('gender');
    }
  }, [watch('birthday'), watch('gender')]);


  useEffect(() => {
    if (isUniv) {
      if (watch('university') === '') {
        setError("university", { message: "대학교 이름을 입력해주세요" });
      } else if (watch('department') === '') {
        setError("department", { message: "학과 이름을 입력해주세요" });
      } else {
        clearErrors("university");
        clearErrors("department");
      }
    } else {
      setValue("university", null);
      setValue("department", null);
      clearErrors("university");
      clearErrors("department");
    }
  }, [watch('university'), watch('department'), setValue, isUniv, setError, clearErrors]);

  const navigate = useNavigate();

  const onSubmit = (data: CounselingFormData) => {
    if (isEdit) {
      const {id, ...rest} = data;
      context.put(
        `/class/${classId}/counseling-log/${data.id}`,
        rest,
        (res: any) => {
          navigate(`/class/counseling-log/${data.id}`);
        }
      )
    } else {
      context.post(
        `/class/${classId}/counseling-log`,
        data,
        (res: any) => {
          navigate(`/class/counseling-log`);
        }
      )
    }
  };

  useEffect(() => {
    const regex = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
    const familyInfo = watch('familyInfo');

    if (familyInfo.length > 0) {
      familyInfo.forEach((_, index) => {
        const name = watch(`familyInfo.${index}.name`);
        const phone = watch(`familyInfo.${index}.phone`);
        const time = watch(`familyInfo.${index}.time`);

        if (name === "") {
          setError(`familyInfo.${index}.name`, { message: "이름을 입력해주세요" });
        } else {
          clearErrors(`familyInfo.${index}.name`);
        }

        if (phone === "") {
          setError(`familyInfo.${index}.phone`, { message: "연락처를 입력해주세요" });
        } else if (!regex.test(phone)) {
          setError(`familyInfo.${index}.phone`, { message: "올바른 핸드폰 번호를 입력해주세요" });
        } else {
          clearErrors(`familyInfo.${index}.phone`);
        } 
        
        if (time === "") {
          setError(`familyInfo.${index}.time`, { message: "상담 가능 시간을 입력해주세요" });
        } else {
          clearErrors(`familyInfo.${index}.time`);
        }
      });
    } else {
      clearErrors('familyInfo');
    }
  }, [watch('familyInfo')]);

  return (
    <WholeWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 24,
        }}
      >
        <span style={{...fonts.title2SemiBold}}>상담 일지</span>
        <Button onClick={handleSubmit(onSubmit)} disabled={Object.keys(errors).length > 0} size="small" text={isEdit ? "수정 완료" : "저장하기"} width="69px" height="32px"/>
      </div>
      <FormWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>상담 일자</SectionTitle>
            <RequiredCircle />
          </RowWrapper>
          <RowWrapper>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "25%",
                height: 50,
                border: "1px solid #E9EBED",
                borderRadius: 8,
              }}
            >
              <DatePickerCustom
                value={moment(watch("counselingDate"))}
                onChange={(date) =>
                  setValue(
                    "counselingDate",
                    (date as moment.Moment).format("YYYY-MM-DD")
                  )
                }
                timeFormat={false}
                dateFormat="YYYY-MM-DD"
              />
            </div>
          </RowWrapper>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>이름 및 성별</SectionTitle>
            <RequiredCircle />
          </RowWrapper>
          <RowWrapper>
            <TextField
              placeholder="이름을 입력해주세요"
              type="text"
              value={watch("name")}
              onChange={(e) => {
                setValue("name", e.target.value);
                if (e.target.value === "") {
                  setError("name", { message: "이름을 입력해주세요" });
                } else {
                  clearErrors("name");
                }
              }}
              error={errors.name !== undefined}
              complete={watch("name") &&watch("name") !== ""}
            />
            <SexButton
              isPressed={watch("gender") === "M"}
              onClick={() => setValue("gender", "M")}
            >
              남자
            </SexButton>
            <SexButton
              isPressed={watch("gender") === "F"}
              onClick={() => setValue("gender", "F")}
            >
              여자
            </SexButton>
          </RowWrapper>
          <RowWrapper>
            {errors.name && (
              <div style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
                * {errors.name.message}
              </div>
            )}
            {errors.gender && (
              <div style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
                * {errors.gender.message}
              </div>
            )}
          </RowWrapper>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper style={{ width: "100%" }}>
            <div style={{ width: "25%" }}>
              <RowWrapper style={{ marginBottom: "15px" }}>
                <SectionTitle>생년월일</SectionTitle>
                <RequiredCircle />
              </RowWrapper>
              <RowWrapper>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: 50,
                    border: "1px solid #E9EBED",
                    borderRadius: 8,
                  }}
                >
                  <DatePickerCustom
                    value={moment(watch("birthday"))}
                    onChange={(date) =>
                      setValue(
                        "birthday",
                        (date as moment.Moment).format("YYYY-MM-DD")
                      )
                    }
                    timeFormat={false}
                    dateFormat="YYYY-MM-DD"
                  />
                </div>
              </RowWrapper>
              {errors.birthday ? (
                <div style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
                  * {errors.birthday.message}
                </div>
              ) : (
                <div style={{ color: "white", fontSize: "14px", marginTop: "5px" }}>
                  {" 빈 텍스트" }
                </div>
              )}
            </div>
            <div style={{ width: "75%" }}>
              <RowWrapper style={{ marginBottom: "15px" }}>
                <SectionTitle>연락처</SectionTitle>
                <RequiredCircle />
              </RowWrapper>
              <RowWrapper>
                <TextField
                  placeholder="전화번호를 입력해주세요"
                  type="text"
                  value={watch("phone")}
                  onChange={(e) => {

                    const regex = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;

                    setValue("phone", e.target.value);
                    if (e.target.value === "") {
                      setError("phone", { message: "전화번호를 입력해주세요" });
                    } else if (!regex.test(e.target.value)) {
                      setError("phone", { message: "올바른 핸드폰 번호를 입력해주세요" });
                    } else {
                      clearErrors("phone");
                    }
                  }}
                  error={errors.phone !== undefined}
                  complete={watch("phone") !== ""}
                />
              </RowWrapper>
              {errors.phone ? (
                <div style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
                  * {errors.phone.message}
                </div>
              ) : (
                <div style={{ color: "white", fontSize: "14px", marginTop: "5px" }}>
                  {" 빈 텍스트" }
                </div>
              )}
            </div>
          </RowWrapper>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>주소</SectionTitle>
          </RowWrapper>
          <RowWrapper>
            <TextField
              placeholder="주소를 입력해주세요"
              type="text"
              value={watch("address")}
              onChange={(e) => {
                setValue("address", e.target.value);
              }}
              complete={watch("address") !== ""}
            />
          </RowWrapper>
          {errors.address && (
            <div style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
              * {errors.address.message}
            </div>
          )}
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>학교</SectionTitle>
          </RowWrapper>
          <RowWrapper>
            <TextField
              placeholder="학교를 입력해주세요"
              type="text"
              value={watch("school")}
              onChange={(e) => {
                setValue("school", e.target.value);
                if (e.target.value === "") {
                  setError("school", { message: "학교를 입력해주세요" });
                } else {
                  clearErrors("school");
                }
              }}
              error={errors.school !== undefined}
              width="290px"
            />
            <SelectBox
              width="190px"
              height="50px"
              disabled={watch("isGraduated")}
              selectedOptionLabel={watch("grade") ? watch("grade").toString() + "학년" : undefined}
              defaultOptionLabel="학년 선택"
              options={[
                {
                  label: "1학년",
                  onSelect: () => setValue("grade", 1),
                },
                {
                  label: "2학년",
                  onSelect: () => setValue("grade", 2),
                },
                {
                  label: "3학년",
                  onSelect: () => setValue("grade", 3),
                },
              ]}
            />
            <CheckBoxCustom
              {...register("isGraduated")}
            />
            <span
              style={{ display: "flex", alignItems: "center",}}
            >
              졸업
            </span>
          </RowWrapper>
          {errors.school && (
            <div style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
              * {errors.school.message}
            </div>
          )}
          {errors.grade && (
            <div style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
              * {errors.grade.message}
            </div>
          )}
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>반수</SectionTitle>
          </RowWrapper>
          <RowWrapper>
            <TextField
              width="250px"
              placeholder="대학교 이름을 입력해주세요"
              type="text"
              disabled={!isUniv}
              value={watch("university")}
              onChange={(e) => {
                setValue("university", e.target.value);
                if (e.target.value === "") {
                  setError("university", { message: "대학교 이름을 입력해주세요" });
                } else {
                  clearErrors("university");
                }
              }}
              error={errors.university !== undefined}
            />
            <TextField
              width="250px"
              placeholder="학과 이름을 입력해주세요"
              type="text"
              disabled={!isUniv}
              value={watch("department")}
              onChange={(e) => {
                setValue("department", e.target.value);
                if (e.target.value === "") {
                  setError("department", { message: "학과 이름을 입력해주세요" });
                } else {
                  clearErrors("department");
                }
              }}
            />
            <CheckBoxCustom
              checked={!isUniv}
              onChange={(e) => setIsUniv(!e.target.checked)}
            />
            <span
              style={{ display: "flex", alignItems: "center", }}
            >
              해당 없음
            </span>
          </RowWrapper>
          {errors.university && (
            <div style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
              * {errors.university.message}
            </div>
          )}
          {errors.department && (
            <div style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
              * {errors.department.message}
            </div>
          )}
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>이메일</SectionTitle>
          </RowWrapper>
          <RowWrapper>
            <TextField
              placeholder="이메일 주소를 입력해주세요"
              type="text"
              value={watch("email")}
              onChange={(e) => {
                const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                setValue("email", e.target.value);
                if (e.target.value === "") {
                  clearErrors("email");
                } else if (!regex.test(e.target.value)) {
                  setError("email", { message: "올바른 이메일 주소를 입력해주세요" });
                } else {
                  clearErrors("email");
                }
              }}
              error={errors.email !== undefined}
              complete={watch("email") !== ""}
            />
          </RowWrapper>
          {errors.email && (
            <div style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
              * {errors.email.message}
            </div>
          )}
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>가족 관계</SectionTitle>
          </RowWrapper>
          <TableCustom>
            <thead>
              <tr>
                <ColoredCell>관계</ColoredCell>
                <ColoredCell>이름</ColoredCell>
                <ColoredCell>연락처</ColoredCell>
                <ColoredCell>상담 가능 시간</ColoredCell>
                <ColoredCell>삭제</ColoredCell>
              </tr>
            </thead>
            <tbody>
              {watch('familyInfo').map((item, index) => (
                <tr key={index}>
                  <td>
                    <SelectBox
                      options={[
                        { label: "부", onSelect: () => setValue(`familyInfo.${index}.relationship`, "부") },
                        { label: "모", onSelect: () => setValue(`familyInfo.${index}.relationship`, "모") },
                        { label: "기타", onSelect: () => setValue(`familyInfo.${index}.relationship`, "기타") },
                      ]}
                      selectedOptionLabel={item.relationship}
                    />
                  </td>
                  <td>
                    <TextField
                      placeholder="이름 입력"
                      type="text"
                      value={item.name}
                      onChange={(e) => {
                        setValue(`familyInfo.${index}.name`, e.target.value);
                        if (e.target.value === "") {
                          setError(`familyInfo.${index}.name`, { message: "이름을 입력해주세요" });
                        } else {
                          clearErrors(`familyInfo.${index}.name`);
                        }
                      }}
                      error={errors.familyInfo?.[index]?.name !== undefined}
                      complete={watch(`familyInfo.${index}.name`) !== ""}
                    />
                  </td>
                  <td>
                    <TextField
                      placeholder="연락처 입력"
                      type="text"
                      value={item.phone}
                      onChange={(e) => {
                        const regex = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;

                        setValue(`familyInfo.${index}.phone`, e.target.value);
                        if (e.target.value === "") {
                          setError(`familyInfo.${index}.phone`, { message: "연락처를 입력해주세요" });
                        } else if (!regex.test(e.target.value)) {
                          setError(`familyInfo.${index}.phone`, { message: "올바른 핸드폰 번호를 입력해주세요" });
                        } else {
                          clearErrors(`familyInfo.${index}.phone`);
                        }
                      }}
                      error={errors.familyInfo?.[index]?.phone !== undefined}
                      complete={watch(`familyInfo.${index}.phone`) !== ""}
                    />
                  </td>
                  <td>
                    <TextField
                      placeholder="상담 가능 시간 입력"
                      type="text"
                      value={item.time}
                      onChange={(e) => {
                        setValue(`familyInfo.${index}.time`, e.target.value);
                        if (e.target.value === "") {
                          setError(`familyInfo.${index}.time`, { message: "상담 가능 시간을 입력해주세요" });
                        } else {
                          clearErrors(`familyInfo.${index}.time`);
                        }
                      }}
                      error={errors.familyInfo?.[index]?.time !== undefined}
                      complete={watch(`familyInfo.${index}.time`) !== ""}
                    />
                  </td>
                  <td>
                    <DeleteButton onClick={() => {
                      const updatedFamilyInfo = watch('familyInfo').filter((_, i) => i !== index);
                      setValue('familyInfo', updatedFamilyInfo);
                      Object.keys(errors.familyInfo).forEach((key) => {
                        if (errors.familyInfo[key] !== null && errors.familyInfo[key] !== undefined) {
                          delete errors.familyInfo[key];
                        }
                      });
                    }}>
                      <FaTrash />
                    </DeleteButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </TableCustom>
          { <AddRowButton onClick={() => {
            setValue('familyInfo', [...watch('familyInfo'), {
              relationship: "부",
              name: "",
              phone: "",
              time: "",
            }]);
          }}>
            <FaPlusCircle />
            <span>가족 추가</span>
          </AddRowButton>}  
          <div style={{ color: "red", fontSize: "14px", marginTop: "5px", display: "flex", flexDirection: "row", gap: "5px" }}>
            {errors.familyInfo && errors.familyInfo !== null && Object.keys(errors.familyInfo).length > 0 && 
            Object.keys(errors.familyInfo).map((key, index) => {
              if (errors.familyInfo[key] !== null && errors.familyInfo[key] !== undefined) {
                return Object.keys(errors.familyInfo[key]).map((subKey, subIndex) => (
                  <span key={subIndex}>{errors.familyInfo[key][subKey].message}</span>
                ))
              }
            })}
          </div>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
              <SectionTitle>학습 상담</SectionTitle>
          </RowWrapper>
          <TableCustom>
            <thead>
              <tr>
                <ColoredCell>과목</ColoredCell>
                <ColoredCell>내용</ColoredCell>
              </tr>
            </thead>
            <tbody>
                {watch('studyCounseling').map((item, index) => (
                  <tr key={index}>
                    <td>{item.subject}</td>
                    <td>
                      <textarea
                        placeholder="내용 입력"
                        {...register(`studyCounseling.${index}.content`)}
                        style={{
                          width: "100%",
                          height: 100,
                          border: "1px solid #E9EBED",
                          borderRadius: 8,
                          padding: 10,
                          fontSize: 16,
                        }}/>
                    </td>
                  </tr>
                ))}
            </tbody>
          </TableCustom>
          <div style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
                
          </div>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>생활 상담</SectionTitle>
          </RowWrapper>
          <RowWrapper>
            <textarea
              placeholder="내용 입력"
              {...register("lifeCounseling")}
              style={{
                width: "100%",
                height: 100,
                border: "1px solid #E9EBED",
                borderRadius: 8,
                padding: 10,
                fontSize: 16,
              }}
            />
          </RowWrapper>
          {errors.lifeCounseling && (
            <div style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
              * {errors.lifeCounseling.message}
            </div>
          )}
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>진로 상담</SectionTitle>
          </RowWrapper>
          <RowWrapper>
            <textarea
              placeholder="내용 입력"
              {...register("career")}
              style={{
                width: "100%",
                height: 100,
                border: "1px solid #E9EBED",
                borderRadius: 8,
                padding: 10,
                fontSize: 16,
              }}
            />
          </RowWrapper>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>건의 사항</SectionTitle>
          </RowWrapper>
          <RowWrapper>
            <textarea
              placeholder="내용 입력"
              {...register("suggestion")}
              style={{
                width: "100%",
                height: 100,
                border: "1px solid #E9EBED",
                borderRadius: 8,
                padding: 10,
                fontSize: 16,
              }}
            />
          </RowWrapper>
        </SectionWrapper>
      </FormWrapper>
    </WholeWrapper>
  );
});

const WholeWrapper = styled.div`
  padding: 0 80px 50px 80px;
  height: 100%;
  overflow-y: auto;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 50px 250px;
  margin-top: 28px;
  border-radius: 20px;
  gap: 40px;
  position: relative;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const SectionTitle = styled.span`
  font-size: ${fonts.body3SemiBold.fontSize};
  font-family: ${fonts.body3SemiBold.fontFamily};
  line-height: ${fonts.body3SemiBold.lineHeight};
  color: ${colors.gray900};
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

const RequiredCircle = styled.div`
  width: 10px;
  height: 10px;
  background-color: #ee4c69;
  border-radius: 50%;
`;

const TextInput = styled.input`
  width: 100%;
  height: 50px;
  border: 1px solid #e9ebed;
  border-radius: 8px;
  padding: 0 15px;
  font-size: 16px;
`;

const SexButton = styled.div<{ isPressed: boolean }>`
  width: 80px;
  height: 50px;
  border: 1px solid #e9ebed;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${(props) => (props.isPressed ? "#0F84F4" : "#ffffff")};
  color: ${(props) => (props.isPressed ? "#ffffff" : "#73787E")};
`;

const TableCustom = styled.table`
  border-collapse: collapse;
  width: 100%;
  & td,
  th {
    text-align: center;
    vertical-align: middle;
    height: 45px;
    padding: 8px 10px;
    font-size: 14px;
  }
`;

const ColoredCell = styled.td`
  background-color: #f5f7fa;
`;

const DatePickerCustom = styled(DateTime)`
  border: none;
  .form-control {
    border: none;
    text-align: center;
    outline: none;
    font-size: 15px;
    background: transparent;
    padding: 0 30px;
    cursor: pointer;
  }

  .form-control:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
`;

const CheckBoxCustom = styled.input.attrs({ type: 'checkbox' })`
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #e9ebed;
  cursor: pointer;
  appearance: none;
  background-color: #ffffff;  
  margin: 0;
  
  &:checked {
    background-color: #0F84F4;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4L4.5 7.5L11 1' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    border-color: #0F84F4;
  }

  &:hover {
    border-color: #0F84F4;
  }
`;

const AddRowButton = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  cursor: pointer;
  background-color: #F7F8F9;
  color: #73787E;
  font-size: 16px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const DeleteButton = styled.div`
  cursor: pointer;
  color: #73787E;
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
  &:hover {
    color: #0F84F4;
  }
`;

export default CounselingLog;