import moment from "moment";
import strings from "../lang/strings";

export const getTimeFromSecond = (seconds) => {
    
    let second = seconds % 3600;
    let hour = (seconds - second) / 3600;
    let sec = second % 60;
    let min = (second - sec) / 60;
    let hour_str = "";
    let min_str = "";
    let sec_str = "";
    if (hour < 10) {
        hour_str = "0" + hour.toString();
    } else {
        hour_str = hour.toString();
    }
    if (min < 10) {
        min_str = "0" + min.toString();
    } else {
        min_str = min.toString();
    }
    if (sec < 10) {
        sec_str = "0" + sec.toString();
    } else {
        sec_str = sec.toString();
    }

    return hour_str + ":" + min_str + ":" + sec_str
}

export const check_validation_email = (email) => {
    const preEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i;
    return preEx.test(email);
}

export const getMinuteFromSecond = (seconds) => {
    let sec = seconds % 60;
    let min = (seconds - sec) / 60;
    let min_str = "";
    let sec_str = "";
    if (min < 10) {
        min_str = "0" + min.toString();
    } else {
        min_str = min.toString();
    }
    if (sec < 10) {
        sec_str = "0" + sec.toString();
    } else {
        sec_str = sec.toString();
    }

    return min_str + ":" + sec_str
}

export const extractNumber = (num) => {
    return num.toString().replace(/[^\d\.]/g, "");
}

export const formatNumber = (num) => {
    if (num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    } else {
        return num;
    }
}

export const getAgeFromBirthday = (p_item) => {
    p_item = p_item + "";
    if (isInvalid(p_item) || p_item == "0")
        return "";
    return (new Date().getFullYear()) - p_item.substring(0, 4) + 1;
}

export const getGradeFromBirthday = (p_item) => {
    p_item = p_item + "";
    if (isInvalid(p_item) || p_item == "0")
        return "";
    let age = parseInt((new Date().getFullYear()) - p_item.substring(0, 4) + 1);
    if (age > 19) {
        return strings.g3
    } else if (age === 18) {
        return strings.g2
    } else return  strings.g1
}

export const pwdCheck = (pwd) => {
    const preEx = /^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#\$&*~+,:;=?|'<>.-^*()%]).{8,20}$/i;
    return preEx.test(pwd);
}

export const isInvalid = (p_value) => {
    return (p_value == undefined || p_value == null || p_value.length == 0);
}

export const dateForm = (date) => {
    let date_str = ""
    if (date < 10) {
        date_str = "0" + date.toString();
    } else {
        date_str = date.toString();
    }
    return date_str
}

export const getDaysFromStartEnd = (start, end, momentObj) => {
    let list = [];
    let endDate = moment(momentObj).daysInMonth();
    if (start > end) {
        for (let i = start; i <= endDate; i++) {
            list.push(dateForm(i) + strings._d);
        }
        for (let i = 1; i <= end; i++) {
            list.push(dateForm(i) + strings._d);
        }
    } else {
        for (let i = start; i <= end; i++) {
            list.push(dateForm(i) + strings._d);
        }
    }
    return list
}

export const getDecimal = (num_str) => {
    let num  = num_str.split(".")[0]
    let decimal = num_str.split(".")[1]
    if (decimal !== ("" || undefined) && decimal[0] !== "0") {
        return num + "." + decimal.substr(0,1);
    } else {
        return num
    }

}

export const getRandomNumber = (num) => {
    let rand = Math.floor((Math.random() * 10) + 1) % num
    return rand
}

export const getVideoIdFromYoutubeURL = (url) => {
    let videoId = url.substr(url.lastIndexOf('/') + 1);
    return videoId
}

// 커리큘럼에서 선생이 올린 링크
export const getYoutubeVideoId = (url) => {
    console.log("link",url)
    const regex = /(?:youtube\.com\/.*v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    console.log("match",match)
    return match ? match[1] : null;
}

export const getAchievementRate = (testScore, watchCount, solveCount) => {
    let rate = 0;
    if (testScore == 100) { // 중단원 테스트 점수가 100점이면 그대로 100%
        rate = 100;
    } else {
        if (testScore >= 90) {  // 중단원 테스트 점수가 90점이상이면 20%
            rate += 20;
        } else if (testScore >= 70) { // 중단원 테스트 점수가 70점이상이면 10%
            rate += 10;
        }
        if (watchCount > 0) { // 영상시청을 하였으면 40%
            rate += 40;
        }
        if (solveCount > 0) { // 문제풀기를 하였으면 40%
            rate += 40;
        }
    }
    return rate;
}

export const getWeekOfMonth = (year, month) => {
    const generateWeeklyDates = (year, month) => {
      // 해당 월의 첫날과 마지막 날 생성
      const startDate = new Date(year, month - 1, 1); // 첫날
      const endDate = new Date(year, month, 0); // 마지막 날
  
      const weeklyDates = [];
  
      // 월의 일수에 따라 반복문 횟수 설정
      const daysInMonth = endDate.getDate(); // 해당 월의 일수
      
      // 일수를 기준으로 반복
      for (let i = 0; i < daysInMonth; i++) {
        const newDate = new Date(
          startDate.getTime() + i * 24 * 60 * 60 * 1000
        ); // 하루씩 추가
  
        const day = newDate.getDate().toString().padStart(2, '0'); // 일
        weeklyDates.push(day); // 날짜 추가
      }
  
      // 마지막 날을 추가
      const endDay = endDate.getDate().toString().padStart(2, '0');
      if (!weeklyDates.includes(endDay)) {
        weeklyDates.push(endDay); // 마지막 날짜 추가
      }
  
      return weeklyDates;
    };
  
    return generateWeeklyDates(year, month);
  };
  
  