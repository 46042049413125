import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import 'react-datetime/css/react-datetime.css';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { Page_settings } from "../../../config/page_settings";
import { getTimeFromSecond } from '../../../helper/common';
import { rootStore } from '../../../mobx/store';

const ExamStudent = (props) => {
    const context = useContext(Page_settings);
    const examId = parseInt(props.match.params.exam_id);

    const [examInfo, setExamInfo] = useState(null);
    const [examResultInfo, setExamResultInfo] = useState(null);
    const [extraScoreType, setExtraScoreType] = useState(0);
    const [extraScore, setExtraScore] = useState(0);
    const [correctAnswerList, setCorrectAnswerList] = useState([]);
    const [userAnswerList, setUserAnswerList] = useState([]);
    const [showFeedback, setShowFeedback] = useState(false);

    useEffect(() => {
        getExamInfo();
    }, []);

    const getExamInfo = () => {
        context.post(
            'exam/info',
            {
                id: examId
            },
            response => {
                const examInfo = response.info;
                setExamInfo(examInfo);
                const correctAnswerList = JSON.parse(examInfo.answer_data);
                correctAnswerList.forEach((item, index) => {
                    if(item.type === 1) {
                        item.answer = (item.answer === '' || !item.answer) ? [] : typeof JSON.parse(item.answer) === 'number' ? [JSON.parse(item.answer)] : JSON.parse(item.answer);
                    }
                });
                setCorrectAnswerList(correctAnswerList);
                getExamResult();
            }
        );
    }

    const getExamResult = () => {
        context.post(
            'exam/result',
            {
                exam_id: examId,
                member_id: rootStore.getProfile?.id
            },
            response => {
                const resultInfo = response.info;
                setExamResultInfo(resultInfo);
                const userAnswerList = JSON.parse(resultInfo.answer_data);
                userAnswerList.forEach((item, index) => {
                    item.type = userAnswerList[index].type;
                    if(item.type === 1) {
                        item.answer = item.answer === '' ? [] : typeof JSON.parse(item.answer) === 'number' ? [JSON.parse(item.answer)] : JSON.parse(item.answer);
                    }
                });
                setUserAnswerList(userAnswerList);
                setExtraScore(resultInfo.extra_score);
                setExtraScoreType(resultInfo.extra_score > 0 ? 1 : resultInfo.extra_score < 0 ? -1 : 0);
            }
        );
    }

    const answersAreEqual = (ans1, ans2, answer_type) => {
        if(answer_type === 1) {

            // ans1, ans2 배열 => 배열의 길이가 다르면 false
            if (ans1.length !== ans2.length) {
                return false;
            }

            // 각 요소를 비교
            return ans1.every((value, index) => value === ans2[index]);
        } else {
            return ans1 === ans2;
        }
    }

    return (
        <div style={{ width: '100%', height: 'calc(100vh - 90px)' }}>
            <div className="main-content exam-result-content" style={{ width: '100%', height: '100%', padding: '18px 28px 0', borderRadius: 12, background: '#fff', display: 'flex', flexDirection: 'column' }}>
                <ReactNotifications />
                <div className="f-s-25 f-w-600 m-b-10 d-flex flex-row align-items-center">
                    <div className='d-flex flex-row align-items-center' style={{ cursor: 'pointer' }} onClick={() => {
                        props.history.push('/exam/' + (examInfo.class));
                    }}>
                        <img src='/assets/image/icon_arrow_back_gray.png' />
                        <div className='top-nickname'>{examInfo == null ? '' : examInfo.title}</div>
                    </div>
                </div>
                <div className='d-flex flex-row flex-1' style={{ overflow: 'hidden', paddingBottom: 24 }}>
                    <div className='d-flex flex-column col-md-12'>
                        <div className='d-flex flex-column' style={{ width: '100%', height: '100%' }}>
                            <div className='d-flex flex-row align-items-center' style={{ marginTop: 10 }}>
                                <div className='result-time-wrap'>
                                    <div>{'응시시간'}</div>
                                    <div className='time'>{getTimeFromSecond(examResultInfo == null ? 0 : examResultInfo.exam_time)}</div>
                                </div>
                                <div className='result-score-wrap' style={{ marginLeft: 10 }}>
                                    <div>{'점수'}</div>
                                    <div className='score'>{(examResultInfo == null ? 0 : examResultInfo.score) + '점'}</div>
                                </div>
                                {
                                    extraScoreType !== 0 &&
                                    <div className={'extra-score-wrap ' + (extraScoreType === 1 ? 'additional' : 'deducted')} style={{ marginLeft: 10 }}>
                                        <div>{extraScoreType === 1 ? '가점' : '감점'}</div>
                                        <input type='number' value={extraScoreType === 1 ? extraScore : -extraScore} disabled style={{fontSize: 16, fontWeight: 700, color: extraScoreType === 1 ? '#0F84F4' : '#EE4C69' }} />
                                    </div>
                                }
                                {
                                    showFeedback &&
                                    <div className='feedback-area'>
                                        <textarea
                                            className="feedback-input"
                                            placeholder={''}
                                            rows={7}
                                            value={examResultInfo == null ? '' : examResultInfo.feedback}
                                            disabled
                                            style={{resize: 'none'}} />
                                    </div>
                                }
                                <button className='btn-feedback d-center' onClick={() => setShowFeedback(!showFeedback)}>
                                    <div className='f-s-16 m-l-10'>{'피드백 확인'}</div>
                                    <img
                                        src={showFeedback ? "/assets/image/icon_arrow_up_white.png" : "/assets/image/icon_arrow_down_white.png"}
                                        alt="" style={{ width: 15, height: 10, marginLeft: 16 }} />
                                </button>
                            </div>
                            <div style={{ marginTop: 22, marginBottom: 10, color: '#222', fontSize: 15, fontWeight: 700, letterSpacing: -0.3 }}>
                                {'OMR 답안지'}
                            </div>
                            <div className='flex-1' style={{ overflowX: 'auto', border: '1px solid #DFE1E8', borderRadius: 7, padding: '16px 14px' }}>
                                <div className='d-flex flex-column' style={{ flexWrap: 'wrap', height: '100%' }} >
                                    {
                                        userAnswerList.map((answerItem, index) => {
                                            return <div key={index} className='d-flex flex-row align-items-center m-b-10'>
                                                <div className='d-flex' style={{ width: 38, height: 38, position: 'relative' }}>
                                                    <div style={{ margin: 'auto' }}>{(index + 1) + '번'}</div>
                                                    <img src={'/assets/image/icon_answer_' + (answersAreEqual(correctAnswerList[index].answer,answerItem.answer, answerItem.type) ? 'correct' : 'wrong') + '.png'} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                                                </div>
                                                {
                                                    answerItem.type === 1 ?
                                                        <div className='omr-answer'>
                                                            <button className={'check-item' + (answerItem.answer.includes(1) ? ' selected' : '') + (correctAnswerList[index].answer.includes(1) ? ' correct' : '')} />
                                                            <button className={'check-item' + (answerItem.answer.includes(2) ? ' selected' : '') + (correctAnswerList[index].answer.includes(2) ? ' correct' : '')} />
                                                            <button className={'check-item' + (answerItem.answer.includes(3) ? ' selected' : '') + (correctAnswerList[index].answer.includes(3) ? ' correct' : '')} />
                                                            <button className={'check-item' + (answerItem.answer.includes(4) ? ' selected' : '') + (correctAnswerList[index].answer.includes(4) ? ' correct' : '')} />
                                                            <button className={'check-item' + (answerItem.answer.includes(5) ? ' selected' : '') + (correctAnswerList[index].answer.includes(5) ? ' correct' : '')} />
                                                        </div>
                                                        :
                                                        <div className='omr-answer'>
                                                            <div className='answer-text'>
                                                                {answerItem.answer}
                                                                {
                                                                    correctAnswerList[index].answer !== answerItem.answer &&
                                                                    <div className='correct-answer'>{correctAnswerList[index].answer}</div>
                                                                }
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );

}

export default withRouter(ExamStudent);
