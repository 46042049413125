import strings from "../../../lang/strings";
import { useContext } from "react";
import { Page_settings } from "../../../config/page_settings";
import moment from "moment";

const CoachListItem = (props) => {
    const context = useContext(Page_settings);
    const { item, selected, onClick } = props;

     // item.id가 2인 경우 아무것도 렌더링하지 않음
     if (item.id === 2) {
        return null;
    }


    return (  
        <button className={("coach-item " + (selected ? "selected" : ""))} onClick={() => onClick()}>
            <img className="profile"
                src={item.image != null ? context.loadImage(item.image) : "/assets/image/chewing_plus_logo.png"}
                alt="" />
            <div
                className="name">{"AI " + (item.name == '국어' ? '도우미' : item.name)}</div>
            <div
                className="time">{item.chat_date != "" ? moment(item.chat_date).format("a hh:mm") : ""}</div>
        </button>
    );
}

export default CoachListItem;
