import React, { useContext } from "react";
import { Page_settings } from "../../../config/page_settings";

const AIChatItem = (props) => {
    const context = useContext(Page_settings);
    const { item, mine } = props;
    return (
        <div className={(mine ? "m-l-auto " : "m-r-auto m-l-20 ") + ("d-flex align-items-xs-end coach-chat")}>
            {
                !mine &&
                <img src={"/assets/image/img_rai_head.png"}
                    style={{
                        width: 38,
                        height: 38,
                        borderRadius: 19,
                        marginRight: 10,
                        marginBottom: "auto",
                        objectFit: "cover"
                    }} alt="" />
            }
            {
                mine &&
                <div style={{
                    margin: "0 5px 10px auto",
                }}>
                </div>
            }
            <div style={!mine ? { flex: 1 } : {}}>
                {
                    !mine &&
                    <div className="name">{"AI 도우미"}</div>
                }
                <div className="d-flex width-100-percent">
                    <div className={mine ? "right" : "left"}>
                        <div style={{ wordBreak: "break-all", textAlign: "left" }}>
                            {item.text}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default AIChatItem;
