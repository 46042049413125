import React, {  } from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';

const ExamFileViewer = (props) => {
    const { url } = props
    return (
        <DocViewer className="" documents={[{ uri: url, fileType:'pdf' }]} pluginRenderers={DocViewerRenderers}
            config={{
                header: {
                    disableHeader: true,
                    disableFileName: true,
                    retainURLParams: true
                },
                pdfVerticalScrollByDefault: true,
            }} />
    );
}

export default React.memo(ExamFileViewer);
