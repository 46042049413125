import React from "react";
import { IconType } from "../../type/icon_type";    

const IconCheck = ({ color = "#73787E", width = "24", height = "24" }: IconType) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 8L11.1667 16L7 11.7895" stroke={color} strokeWidth="2.4" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    );
};

export default IconCheck;