import React from "react";

const HomeSec7 = () => {
    return (
        <>
            <section className="wrap main-sec7">
                <div className="inner">
                    <div className="main-tit">
                        <h4>
                            <img src="/assets/image/landing_page/main/sec7-tit.svg" alt="로드맵 아이콘" />
                            AI 질문형 문제 파악
                        </h4>
                        <h2>
                            실수는 사라지고 <br />
                            약점은 줄어듭니다
                        </h2>
                        <p>
                            내가 정말 모르는 부분은 어디인지 <br />
                            라이의 질문을 통해 취약점을 알아보세요
                        </p>
                    </div>
                    <div className="sec7-con">
                        <div className="box1">
                            <img src="/assets/image/landing_page/main/sec7-box1.png" alt="오답노트" />
                            <div className="txt-box">
                            <h3>
                                오답노트? <br />
                                <span>NO질문, 응답, 분석.</span>
                            </h3>
                            <p>
                                해설만있는 오답노트 말고 <br />
                                진짜 모르는 부분을 파악해보세요
                            </p>
                            </div>
                        </div>
                        <div className="box2 wid-50">
                            <div className="txt-box">
                                <h6>난이도 조절</h6>
                                <h3>테스트를 통해 더 적합한 문제를 선별</h3>
                            </div>
                            <div className="img-box">
                                <img src="/assets/image/landing_page/main/sec7-box2.png" alt="적합한 문제" />
                            </div>
                        </div>
                        <div className="box3 wid-50">
                            <div className="txt-box">
                                <h6>맞춤 콘텐츠 제공</h6>
                                <h3>학생이 처한 상황에 딱 맞게</h3>
                            </div>
                            <div className="img-box">
                                <img src="/assets/image/landing_page/main/sec7-box3.png" alt="맞춤 콘텐츠" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeSec7;