import React, { useContext, useEffect, useState } from 'react';
import 'react-datetime/css/react-datetime.css';
import 'react-notifications-component/dist/theme.css';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ChevronDown, ChevronUp } from '../../../components/icons/chevron';
import SearchBar from '../../../components/search_bar';
import { Page_settings } from "../../../config/page_settings";
import colors from '../../../design/colors';
import fonts from '../../../design/typography';
import useDebounce from '../../../hooks/useDebounce';
import { getTimeFromSecond } from '../../../helper/common';
import ImageCircle from '../../../components/image_circle';
import { getFilePath } from '../../../utils/image';
import IconButton from '../../../components/icon_button';
import { ChevronRight, ChevronLeft } from '../../../components/icons/chevron';
import { ArrowLeft } from '../../../components/icons/arrow';
import { Document, Page } from 'react-pdf';
import { IconZoomIn, IconZoomOut } from '../../../components/icons/zoom';
import Pencil from '../../../components/icons/pencil';
import Check from '../../../components/icons/check';

interface IExam {
    info: {
      id: number;
      startTime: string;
      endTime: string;
      items: number;
      subject: string;
      title: string;
      status: number;
      participantCount: number;
      submitCount: number;
      submit: boolean;
      file: {
        id: number;
        name: string;
        size: number;
        type: string;
        url: string;
      }
    },
    users: {
        id: number;
        name: string;
        email: string;
        profile: string;
        birthday: string;
        academy: string;
        score?: number;
        examTime?: number;
    }[]
}


const categoryList: { name: "응시" | "미응시" | "전체"; category: string }[] = [
    { name: "전체", category: "" },
    { name: "응시", category: "응시" },
    { name: "미응시", category: "미응시" },
  ];

const ExamTeacher = () => {
    const context: any = useContext(Page_settings);
    const {exam_id : examId} = useParams();
    const [exam, setExam] = useState<IExam | null>(null);
    const [examResult, setExamResult] = useState<IExamResult | null>(null);
    const [selectedMemberId, setSelectedMemberId] = useState<number>(-1);
    const [mode, setMode] = useState<"list" | "omr">("list");
    const [numPages, setNumPages] = useState<number>(0);
    const [scale, setScale] = useState<number>(1.0);

    useEffect(() => {
        context.get(
            `exam/${examId}`,
            {},
            response => {
                setExam(response);
            }
        )
    }, []);


    useEffect(() => {
        if (selectedMemberId !== -1) {
            context.get(
                `exam/${examId}/result/${selectedMemberId}`,
                {},
                response => {
                    setExamResult(response);
                }
            )
        }
    }, [selectedMemberId]);
    

    if (!exam) {
        return <div>Loading...</div>;
    }

    const increaseScale = () => setScale(prevScale => Math.min(prevScale + 0.1, 2.0));
    const decreaseScale = () => setScale(prevScale => Math.max(prevScale - 0.1, 0.5));


    const examSubmitStudents = exam.users.filter(user => user.examTime && user.examTime !== 0)
    const averageScore = examSubmitStudents.length > 0 ? examSubmitStudents.reduce((acc, user) => acc + user.score, 0) / examSubmitStudents.length : 0;

    return (
        <Container>
            <div style={{width: selectedMemberId === -1 ? "100%" : "calc(62.5% -0.5px)", height: "100%", padding: "32px 40px"}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: 40}}>
                    <span style={{...fonts.title2SemiBold, color: colors.gray900}}>{exam.info.title}</span>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", height: 36, backgroundColor: colors.blue50, color: colors.blue800, borderRadius: 12, padding: "0 16px", ...fonts.body2SemiBold}}>
                        {"평균 "}
                        <span style={{...fonts.body2SemiBold, color: colors.blue800, marginLeft: 4}}>{averageScore.toFixed(2)}</span>
                        {"점"}
                    </div>
                </div>
                {selectedMemberId === -1 ? 
                <ExamStudentListInfo exam={exam} setSelectedMemberId={setSelectedMemberId}/> 
                : 
                mode === "list" ?
                <ExamStudentResultInfo 
                    memberName={exam.users.find(user => user.id === selectedMemberId)?.name || ""} 
                    memberProfile={exam.users.find(user => user.id === selectedMemberId)?.profile || ""} 
                    memberSchool={exam.users.find(user => user.id === selectedMemberId)?.academy || ""} 
                    examResultInfo={examResult} 
                    goToOmr={() => {
                        setMode("omr");
                    }}
                    goToList={() => {
                        setSelectedMemberId(-1);
                    }}
                />
                :
                <OmrContent 
                    examResult={examResult} 
                    goToResult={() => {
                        setMode("list");
                    }}
                />
                }
            </div>
            {
                selectedMemberId !== -1 &&
                (
                    <>
                        <div style={{width: "1px", height: "100%", backgroundColor: colors.gray100}}/>
                        <div style={{width: "calc(37.5% - 0.5px)", height: "100%", padding: "32px 40px", position: "relative"}}>
                            <ContentBox style={{height: "100%", display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center"}}>
                                <div style={{width: '95px', height: '36px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: colors.white, border: `1px solid ${colors.gray100}`, borderRadius: 8, zIndex: 1000, gap: 8}}>
                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}} onClick={() => {
                                        increaseScale()
                                    }}>
                                        <IconZoomIn width='15px' height='15px' color={scale === 2.0 ? colors.gray400 : colors.gray600} />
                                    </div>
                                    <span style={{...fonts.label5Regular, color: colors.gray900}}>{Math.round(scale * 100)}%</span>
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", cursor: 'pointer'}} onClick={() => {
                                        decreaseScale()
                                    }}>
                                        <IconZoomOut width='15px' height='15px' color={scale === 0.5 ? colors.gray400 : colors.gray600} />
                                    </div>
                                </div>
                                <PDFViewerDocument file={getFilePath(exam.info.file.url)} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
                                    {Array.from(new Array(numPages), (el, index) => (
                                        <PdfViewerPage key={`page_${index + 1}`} pageNumber={index + 1} scale={scale}/>
                                    ))}
                                </PDFViewerDocument>
                            </ContentBox>
                        </div>
                    </>
                )
            }
        </Container>
    );
}


const ExamStudentListInfo = ({exam, setSelectedMemberId}: {exam: IExam, setSelectedMemberId: (id: number) => void}) => {

    const [searchValue, setSearchValue] = useState('');
    const [selectCategory, setSelectCategory] = useState<{
        name: "응시" | "미응시" | "전체";
        category: string;
    }>({ name: "전체", category: "" });
    const [categoryDropdownStatus, setCategoryDropdownStatus] = useState(false);


    const name = useDebounce(searchValue, 500);

    return (
        <>
        <div style={{marginBottom: 20}}>
                <SearchBar
                    value={searchValue}
                    width='245px'
                    placeholder="이름을 검색해보세요"
                    onChange={(e) => {
                        setSearchValue(e.target.value);
                    }}
                />
            </div>
            <Table>
                <thead>
                    <tr>
                        <Th width="140px">
                            <DropdownBox
                                onClick={() =>
                                setCategoryDropdownStatus(!categoryDropdownStatus)
                                }
                            >
                                {selectCategory.name}
                                {categoryDropdownStatus ? (
                                    <ChevronUp color={colors.gray500} width="16px" height="16px"/>
                                ) : (
                                    <ChevronDown color={colors.gray500} width="16px" height="16px"/>
                                )}
                                {categoryDropdownStatus && (
                                <DropdownItemBox>
                                    {categoryList.map((item, index) => (
                                    <DropdownBoxItem
                                        key={index}
                                        onClick={() => {
                                        setCategoryDropdownStatus(false);
                                        setSelectCategory({
                                            name: item.name,
                                            category: item.category,
                                        });
                                        }}
                                    >
                                        {item.name}
                                    </DropdownBoxItem>
                                    ))}
                                </DropdownItemBox>
                                )}
                        </DropdownBox>
                        </Th>
                        <Th width="calc(30% - 910px)">이름</Th>
                        <Th width="139px">점수</Th>
                        <Th width="calc(30% - 91px)">응시시간</Th>
                        <Th width="calc(40% - 91px)">학교</Th>
                    </tr>
                </thead>
                <tbody>
                {exam.users
                .sort((a, b) => a.name.localeCompare(b.name))
                .filter((user) => {
                    if (name && name !== "" && !user.name.includes(name)) {
                        return false;
                    }
                    if (selectCategory.category === "") {
                        return true;
                    }
                    return user.examTime !== 0 ? selectCategory.category === "응시" : selectCategory.category === "미응시";
                })
                .map((user) => (
                    <tr key={user.id}>
                    <Td
                        style={{cursor: "pointer"}}
                        onClick={() => {
                            setSelectedMemberId(user.id);
                        }}
                    >
                        <StatusBadge is_registered={user.examTime && user.examTime !== 0}>
                        {user.examTime && user.examTime !== 0 ? "응시" : "미응시"}
                        </StatusBadge>
                    </Td>
                    <Td
                        style={{cursor: "pointer"}}
                        onClick={() => {
                            setSelectedMemberId(user.id);
                        }}
                    >
                        {user.name}
                    </Td>
                    <Td 
                        style={{cursor: "pointer"}}
                        onClick={() => {
                            setSelectedMemberId(user.id);
                        }}
                    >
                        <span>
                        {user.score ?? "-"} {user.score !== null && user.score !== undefined && user.score !== 0 && "점"}
                        </span>
                    </Td>
                    <Td 
                        style={{cursor: "pointer"}}
                        onClick={() => {
                            setSelectedMemberId(user.id);
                        }}
                    >
                        <span>
                        {user.examTime ? getTimeFromSecond(user.examTime) : "-"}
                        </span>
                    </Td>
                    <Td
                        style={{cursor: "pointer"}}
                        onClick={() => {
                            setSelectedMemberId(user.id);
                        }}
                    >
                        <span>
                        {user.academy || "-"}
                        </span>
                    </Td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </>
    );
}

interface IExamResult {
    member: number,
    exam: number,
    testQuestion: {
        id: number,
        number: number,
        questionType: number,
        score: number,
        correctAnswer: string,
        title: string,
        data: string,
        commentary: string
    }[],
    testAnswer: {
        number: number,
        answer: string,
        isCorrect: boolean
    }[],
    examTime: number,
    objectiveScore: number,
    objectiveCorrectCountRate: number,
    subjectiveScore: number,
    subjectiveCorrectCountRate: number,
    extraScore: number,
    totalScore: number,
    feedback: string,
    submitTime: string
}

const ExamStudentResultInfo = ({memberName, memberProfile, memberSchool, examResultInfo, goToOmr, goToList}: {memberName: string, memberProfile: string, memberSchool: string, examResultInfo: IExamResult, goToOmr: () => void, goToList: () => void}) => {
    const context: any = useContext(Page_settings);
    const [showFeedback, setShowFeedback] = useState(false);
    
    const [editFeedback, setEditFeedback] = useState<boolean>(false);

    const [feedback, setFeedback] = useState<string>(examResultInfo?.feedback ?? "");

    useEffect(() => {
        setFeedback(examResultInfo?.feedback ?? "");
    }, [examResultInfo]);

    useEffect(() => {
        if (!editFeedback && examResultInfo && feedback !== examResultInfo.feedback) {
            context.post(`exam/${examResultInfo.exam}/result/${examResultInfo.member}/feedback`, {
                feedback: feedback
            });
        }
    }, [editFeedback]);

    if (!examResultInfo) {
        return <div>Loading...</div>;
    }

    return (
        <ContentBox>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between",height: 48, width: "100%", position: "relative"}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 12}}>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 8, cursor: "pointer"}} onClick={() => {
                        goToList();
                    }}>
                        <ChevronLeft color={colors.gray900} width="24px" height="24px"/>    
                    </div>
                    <ImageCircle src={getFilePath(memberProfile)} size={48}/>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center"}}>
                        <span style={{...fonts.body3SemiBold, color: colors.gray900}}>{memberName}</span>
                        <span style={{...fonts.label4Medium, color: colors.gray600}}>{memberSchool}</span>
                    </div>
                </div>
                <IconButton
                    icon={showFeedback ? <ChevronUp color={colors.white} width="20px" height="20px"/> : <ChevronDown color={colors.white} width="20px" height="20px"/>}
                    onClick={() => {
                        setShowFeedback(!showFeedback);
                    }}
                    size="medium"
                    text={"피드백"}
                    width="78px"
                    height="36px"
                />
                {showFeedback && (
                    <div style={{position: "absolute", top: 48, right: 0, width: "320px", height: "188px", backgroundColor: colors.white, zIndex: 1000, borderRadius: 12, padding: "20px 16px", 
                    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.15)"}}>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                            <span style={{...fonts.body3SemiBold, color: colors.gray900}}>선생님 피드백</span>
                            <div style={{cursor: "pointer"}} onClick={() => {
                                setEditFeedback(!editFeedback);
                            }}>
                                {editFeedback ? <Check color={colors.gray600} width="20px" height="20px"/> : <Pencil color={colors.gray600} width="20px" height="20px"/>}
                            </div>
                        </div>
                        <FeedbackBox hasText={examResultInfo.feedback && examResultInfo.feedback !== ""}>
                            { ((feedback !== "" && feedback !== null && feedback !== undefined) || editFeedback) && <FeedbackInput value={feedback} disabled={!editFeedback}
                            onChange={(e) => {
                                setFeedback(e.target.value);
                            }}
                            />
                            }
                            {
                                feedback === "" && !editFeedback &&
                                <span>등록된 피드백이 없습니다</span>
                            }
                        
                        </FeedbackBox>
                    </div>
                )}
            </div>
            {examResultInfo.testAnswer.length > 0 && 
            <>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", gap: 8, marginTop: 32}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 20, border: `1px solid ${colors.gray100}`, padding: "0 8px", borderRadius: 8, height: 40}}>
                    <span style={{...fonts.label4Medium, color: colors.gray600}}>응시 시간</span>
                    <span style={{...fonts.body3SemiBold, color: colors.blue600}}>{getTimeFromSecond(examResultInfo.examTime || 0)}</span>
                </div>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 20, border: `1px solid ${colors.gray100}`, padding: "0 8px", borderRadius: 8, height: 40}}>
                    <span style={{...fonts.label4Medium, color: colors.gray600}}>총 점수</span>
                    <span style={{...fonts.body3SemiBold, color: colors.blue600}}>{examResultInfo.totalScore}</span>
                </div>
            </div>
            <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "16px", marginTop: 32}}>
                <span style={{...fonts.body3SemiBold, color: colors.gray900}}>채점 결과</span>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "4px", cursor: "pointer"}} onClick={() => {
                    goToOmr();
                }}>
                    <span style={{...fonts.label4Medium, color: colors.gray500}}>OMR 확인하기</span>
                    <ChevronRight color={colors.gray500} width="20px" height="20px"/>
                </div>
            </div>
            <AnswerTable>
                <thead>
                    <th style={{width: "50px"}} />
                    <th style={{width: "calc(25% - 12.5px)"}}>학생 답</th>
                    <th style={{width: "calc(25% - 12.5px)"}}>정답</th>
                    <th style={{width: "calc(13% - 12.5px)"}}>문제 배점</th>
                    <th style={{width: "calc(37% - 12.5px)"}}>내 점수</th>
                </thead>
                <tbody>
                    {examResultInfo.testQuestion.map((question, index) => {
                        let correctAnswer = "";
                        let studentAnswer = "";
                        if (question.questionType === 1) {
                            correctAnswer = JSON.parse(question.correctAnswer).map((answer: string) => {
                                return answer+ "번";
                            }).join(",");
                            studentAnswer = JSON.parse(examResultInfo.testAnswer[index].answer).map((answer: string) => {
                                return answer+ "번";
                            }).join(",");
                        } else {
                            correctAnswer = question.correctAnswer;
                            studentAnswer = examResultInfo.testAnswer[index].answer;
                        }

                        return (
                        <tr>
                            <td style={{borderLeft: 'none'}}>{index + 1}</td>
                            <td style={{color: examResultInfo.testAnswer[index].isCorrect ? colors.blue600 : colors.warning}}>{studentAnswer}</td>
                            <td style={{color: examResultInfo.testAnswer[index].isCorrect ? colors.blue600 : colors.warning}}>{correctAnswer}</td>
                            <td>{question.score.toFixed(1)}</td>
                            <td>{examResultInfo.testAnswer[index].isCorrect ? question.score.toFixed(1) : "0.0"}</td>
                        </tr>
                        )
                    })}
                </tbody>
            </AnswerTable>
            </>
            }
            {
                examResultInfo.testAnswer.length === 0 &&
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", height: "calc(100% - 100px)", marginTop: 32}}>
                    <img src={"/assets/image/sad_rai.png"} width="152px" height="113px"/>
                    <span style={{...fonts.body3Medium, color: colors.gray400, marginTop: 23}}>시험을 응시하지 않았습니다.</span>
                </div>
            }
        </ContentBox>
    );
}

const OmrContent = ({examResult, goToResult}: {examResult: IExamResult, goToResult: () => void}) => {
    const [answerCurrentPage, setAnswerCurrentPage] = useState<number>(1);

    return (
        <ContentBox>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "16px", cursor: "pointer", marginTop: "12px", marginBottom: "32px"}} onClick={() => {
                goToResult();
            }}>
                <ArrowLeft color={colors.gray400} width="24px" height="24px"/>
                <span style={{...fonts.body3Medium, color: colors.gray900}}>OMR 확인하기</span>
            </div>
            <OMRBody>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "250px", gap: 20}}>
                {examResult.testQuestion.slice((answerCurrentPage - 1) * 20, (answerCurrentPage - 1) * 20 + 10).map((answer, index) => (
                    <OMRItem>
                        <div style={{width: "26px", textAlign: "center"}}>{index + 1 + (answerCurrentPage - 1) * 20 + "번"}</div>
                        {examResult.testAnswer[index + (answerCurrentPage - 1) * 20].isCorrect ?
                                <div style={{position: "absolute", top: 0, left: 0}}>
                                    <img src={"/assets/image/correct.png"} width="50%" height="50%"/>
                                </div>
                            :
                                <div style={{position: "absolute", top: 0, left: 0}}>
                                    <img src={"/assets/image/wrong.png"} width="50%" height="50%"/>
                                </div>
                            }
                        <div style={{width: "200px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginLeft: "21px"}}>
                            {answer.questionType === 1 ?
                            <>
                                {Array.from({length: 5}, (_, i) => {
                                    const answer: number[] = JSON.parse(examResult.testAnswer[index + (answerCurrentPage - 1) * 20].answer);
                                    const correctAnswer: number[] = JSON.parse(examResult.testQuestion[index + (answerCurrentPage - 1) * 20].correctAnswer);
                                return (
                                    <div style={{width: "24px", height: "24px", borderRadius: "50%", 
                                    backgroundColor: answer.includes(i + 1) ? colors.black : colors.white, 
                                    border: `1px solid ${colors.gray200}`, 
                                    boxShadow: !examResult.testAnswer[index + (answerCurrentPage - 1) * 20].isCorrect && correctAnswer.includes(i + 1) ? `0 0 0 4px ${colors.warning}` : 'none'}} />
                                )
                                })}
                            </>
                            :
                            <div style={{width: "100%", position: "relative"}}>
                                <OMRInput type="text" value={examResult.testAnswer[index + (answerCurrentPage - 1) * 20].answer} width="100%" readOnly={true} disabled={true} />
                                <div style={{position: "absolute", top: 1, left: 50}}>
                                    {
                                        !examResult.testAnswer[index + (answerCurrentPage - 1) * 20].isCorrect &&
                                        (
                                            <span style={{...fonts.label4Medium, color: colors.blue600}}>{examResult.testQuestion[index + (answerCurrentPage - 1) * 20].correctAnswer}</span>
                                        )
                                    }
                                </div>
                            </div>
                            }
                        </div>
                    </OMRItem>
                ))}
                </div>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "250px", gap: 20}}>
                {(examResult.testQuestion.length > answerCurrentPage * 20 - 10) && (
                    <>
                    {examResult.testQuestion.slice(answerCurrentPage * 20 - 10, answerCurrentPage * 20).map((answer, index) => (
                        <OMRItem>
                            <div style={{width: "26px", textAlign: "center"}}>{index + (answerCurrentPage) * 20 - 9 + "번"}</div>
                            {examResult.testAnswer[index + (answerCurrentPage) * 20 - 10].isCorrect ?
                                <div style={{position: "absolute", top: 0, left: 0}}>
                                    <img src={"/assets/image/correct.png"} width="50%" height="50%"/>
                                </div>
                            :
                                <div style={{position: "absolute", top: 0, left: 0}}>
                                    <img src={"/assets/image/wrong.png"} width="50%" height="50%"/>
                                </div>
                            }
                            <div style={{width: "200px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginLeft: "21px"}}>
                                {answer.questionType === 1 ?
                                <>
                                    {Array.from({length: 5}, (_, i) => {
                                        const answer: number[] = JSON.parse(examResult.testAnswer[index + (answerCurrentPage) * 20 - 10 ].answer);
                                        const correctAnswer: number[] = JSON.parse(examResult.testQuestion[index + (answerCurrentPage) * 20 - 10].correctAnswer);
                                    return (
                                        <div style={{width: "24px", height: "24px", borderRadius: "50%", cursor: "pointer",
                                        backgroundColor: answer.includes(i + 1) ? colors.black : colors.white, border: `1px solid ${colors.gray200}`,
                                        boxShadow: !examResult.testAnswer[index + (answerCurrentPage) * 20 - 10].isCorrect && correctAnswer.includes(i + 1) ? `0 0 0 4px ${colors.warning}` : 'none'}} />
                                    )
                                    })}
                                </>
                                :
                                <div style={{width: "100%", position: "relative"}}>
                                    <OMRInput type="text" value={examResult.testAnswer[index + (answerCurrentPage) * 20 - 10].answer} width="100%" readOnly={true} disabled={true} />
                                    <div style={{position: "absolute", top: 1, left: 50}}>
                                        {
                                            !examResult.testAnswer[index + (answerCurrentPage) * 20 - 10].isCorrect &&
                                            (
                                                <span style={{...fonts.label4Medium, color: colors.blue600}}>{examResult.testQuestion[index + (answerCurrentPage) * 20 - 10].correctAnswer}</span>
                                            )
                                        }
                                    </div>
                                </div>
                                }
                            </div>
                        </OMRItem>
                    ))}
                    </>
                )}
                </div>
            </OMRBody>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 8, marginTop: 20}}>
                <div style={{cursor: answerCurrentPage > 1 ? 'pointer' : 'default'}} onClick={() => {
                    if(answerCurrentPage > 1) {
                        setAnswerCurrentPage(answerCurrentPage - 1)
                    }
                }}>
                    <ChevronLeft width='20px' height='20px' color={answerCurrentPage > 1 ? colors.gray500 : colors.gray200}/>
                </div>
                <span style={{...fonts.label5Regular, color: colors.gray900}}>{answerCurrentPage}/{Math.ceil(examResult.testQuestion.length / 20)}</span>
                <div style={{cursor: answerCurrentPage < Math.ceil(examResult.testQuestion.length / 20) ? 'pointer' : 'default'}} onClick={() => {
                    if(answerCurrentPage < Math.ceil(examResult.testQuestion.length / 20)) {
                        setAnswerCurrentPage(answerCurrentPage + 1)
                    }
                }}>
                    <ChevronRight width='20px' height='20px' color={answerCurrentPage < Math.ceil(examResult.testQuestion.length / 20) ? colors.gray500 : colors.gray200}/> 
                </div>
            </div>
        </ContentBox>
    )
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th<{ width?: string }>`
  background-color: #f5f7fa;
  padding: 12px;
  text-align: center;
  border-bottom: 1px solid #e9ebed;
  width: ${(props) => props.width || "auto"};
  height: 40px;
  color: ${colors.gray500};
  font-family: ${fonts.label4Medium.fontFamily};
  font-size: ${fonts.label4Medium.fontSize};
  line-height: ${fonts.label4Medium.lineHeight};
`;

const Td = styled.td`
  padding: 12px;
  border-bottom: 1px solid #e9ebed;
  background-color: white;
  height: 48px;
  text-align: center;
  vertical-align: middle;
  font-family: ${fonts.label4Medium.fontFamily};
  font-size: ${fonts.label4Medium.fontSize};
  line-height: ${fonts.label4Medium.lineHeight};
  color: ${colors.gray900};
`;

const DropdownBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: transparent;
  position: relative;
  gap: 10px;
  width: 100px;
`;

const DropdownItemBox = styled.div`
  position: absolute;
  top: 35px;
  right: 0px;
  border-radius: 8px;
  background: ${colors.white};
  text-align: center;
  font-size: ${fonts.body3Regular.fontSize};
  font-family: ${fonts.body3Regular.fontFamily};
  line-height: ${fonts.body3Regular.lineHeight};
  letter-spacing: -0.28px;
  box-shadow: 0px 1px 3.3px 0px rgba(0, 0, 0, 0.1);
  width: 100%;

`;

const DropdownBoxItem = styled.div`
  padding: 10px;
  white-space: nowrap;
  border-bottom: 2px solid #f5f9fd;
  border-radius: 4px;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    color: #0f84f4;
  }

  &:active {
    color: #95969a;
    font-weight: 700;
  }
`;

const StatusBadge = styled.span<{ is_registered: boolean }>`
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  background-color: ${(props) => (props.is_registered ? "#E3F2FD" : "#FFE0E0")};
  color: ${(props) => (props.is_registered ? "#1976D2" : "#D32F2F")};
`;


const ContentBox = styled.div`
    width: 100%;
    height: calc(100% - 76px);
    padding: 40px 24px;
    background-color: ${colors.white};
    border-radius: 12px;
    overflow-y: auto;
`;

const AnswerTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    font-size: ${fonts.label5Medium.fontSize};
    font-family: ${fonts.label5Medium.fontFamily};
    line-height: ${fonts.label5Medium.lineHeight};

    th, td {
        text-align: center;
        vertical-align: middle;
    }

    th {
        background-color: ${colors.gray50};
        color: ${colors.gray900};
        height: 32px;
    }

    td {
        color: ${colors.gray900};
        height: 40px;
        border-left: 1px solid ${colors.gray100};
        border-bottom: 1px solid ${colors.gray100};
    }
`;

const OMRBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 40px;
`;

const OMRItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 24px;
  position: relative;
`;

const PDFViewerDocument = styled(Document)`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
`;

const PdfViewerPage = styled(Page)`
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
`;


const FeedbackBox = styled.div<{hasText: boolean}>`
    width: 100%;
    height: calc(100% - 36px);
    background-color: ${colors.gray50};
    border-radius: 12px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: ${props => props.hasText ? "flex-start" : "center"};
    justify-content: ${props => props.hasText ? "flex-start" : "center"};
    font-size: ${fonts.body3Regular.fontSize};
    font-family: ${fonts.body3Regular.fontFamily};
    line-height: ${fonts.body3Regular.lineHeight};
    color: ${props => props.hasText ? colors.gray900 : colors.gray500};
    margin-top: 16px;
`;

const FeedbackInput = styled.textarea`
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: ${fonts.body3Regular.fontSize};
    font-family: ${fonts.body3Regular.fontFamily};
    line-height: ${fonts.body3Regular.lineHeight};
    color: ${colors.gray900};

    &:disabled {
        color: ${colors.gray900};
    }
`;


const OMRInput = (props) => {
    const {value, onChange, complete, width, type, textAlign="left"} = props;
    const [isFocused, setIsFocused] = useState(false);
  
    return (
      <div style={{width: width, border: `1px solid ${isFocused ? colors.blue600 : colors.gray100}`, height: "100%", borderRadius: "4px", padding: "0 8px", backgroundColor: complete ? colors.gray50 : colors.white}}>
        <input 
          type={type} 
          value={value} 
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          style={{backgroundColor: complete ? colors.gray50 : colors.white, border: "none", ...fonts.label4Medium, width: "100%", textAlign: textAlign}} 
          onChange={onChange as (e: React.ChangeEvent<HTMLInputElement>) => void}
          />
      </div>
    )
  }


export default ExamTeacher;
