import React from "react";
import ClassTop from "../Class/ClassTop";
import ClassSec1 from "../Class/ClassSec1";
import ClassSec2 from "../Class/ClassSec2";
import ClassSec3 from "../Class/ClassSec3";

const Class = () => {

    return (
        <>
            <ClassTop />
            <ClassSec1 />
            <ClassSec2 />
            <ClassSec3 />
        </>
    );
}

export default Class;