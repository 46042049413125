import { observer } from 'mobx-react';
import moment from "moment";
import React, { useContext, useEffect, useState } from 'react';
import DateTime from "react-datetime";
import { Controller, useForm } from 'react-hook-form';
import { FaPlusCircle, FaTrash } from "react-icons/fa";
import { Modal, ModalBody } from 'reactstrap';
import styled from 'styled-components';
import Button from '../../components/button';
import Checkbox from '../../components/checkbox';
import IconX from '../../components/icons/x';
import SelectBox from '../../components/select_box';
import TextField from '../../components/text_field';
import { Page_settings } from "../../config/page_settings";
import colors from '../../design/colors';
import fonts from '../../design/typography';
import { rootStore } from '../../mobx/store';
import { useNavigate } from 'react-router-dom';

interface ApplicationFormData {
  name: string;
  gender: string;
  birthday: string;
  phone: string;
  address: string;
  school: string;
  grade: number;
  isGraduated: boolean;
  university: string;
  department: string;
  email: string;
  guardianInfo: {
    name: string;
    phone: string;
    time: string;
    relationship: string;
  }[];
  siblingsInfo: {
    name: string;
    phone: string;
    school: string;
    grade: number;
  }[];
  subjects: {
    korean: {
      option: string;
      rank: number;
    },
    math: {
      option: string;
      rank: number;
    },
    english: number;
    history: number;
    elective1: {
      category: string;
      option: string;
      rank: number;
    },
    elective2: {
      category: string;
      option: string;
      rank: number;
    }
  },
  weakSubject: string;
  onlineClass: {
    subject: string;
    content: string;
    site: string;
  }[];
  etc: {
    learning: string;
    health: string;
    lifeStyle: string;
  };
  admissionReason: {
    internet: boolean;
    recommend: boolean;
    etc: string;
  };
  career: string;
  devices: {
    notebook: boolean;
    tablet: boolean;
    smartphone: boolean;
    others: string;
  }
}

interface IApplicationForResponse {
  id: number;
  classMember: number;
  name: string;
  gender: string;
  phone: string;
  guardianInfo: {
    name: string;
    time: string;
    phone: string;
    relationship: string;
  }[];
  email: string;
  koreanOption: string;
  koreanRank: number;
  mathOption: string;
  mathRank: number;
  englishRank: number;
  elective1Option: string;
  elective2Option: string;
  elective1Rank: number;
  elective2Rank: number;
  devices: {
    notebook: boolean;
    tablet: boolean;
    smartphone: boolean;
    others: string;
  };
  career: string;
  birthday: string;
  address: string;
  school: string;
  isGraduated: boolean;
  university: string;
  weakSubject: string;
  onlineClass: {
    subject: string;
    content: string;
    site: string;
  }[];
  etcInfo: null;
  createDate: string;
  grade: number;
  updateDate: null;
  historyRank: number;
  admissionReason: {
    internet: boolean;
    recommend: boolean;
    etc: string;
  };
  siblingsInfo: {
    name: string;
    phone: string;
    school: string;
    grade: number;
  }[];
  department: string;
}

const electiveOptionsDict = {
  "사회탐구": ["생활과 윤리", "윤리와 사상", "한국지리", "세계사", "경제", "동아시아사", "법과 정치", "사회문화", "세계지리"],
  "과학탐구": ["물리1", "화학1", "생명과학1", "지구과학1", "물리2", "화학2", "생명과학2", "지구과학2"],
};

const ApplicationForAdmission: React.FC = observer(() => {
  const context: any = useContext(Page_settings);
  const { control, handleSubmit, setValue, watch, setError, clearErrors, trigger, formState: { errors, isValid } } = useForm<ApplicationFormData>(
    {
      mode: "onChange",
      defaultValues: {
        onlineClass: [],
        birthday: moment().format("YYYY-MM-DD"),
        grade: null,
        admissionReason: {
          internet: false,
          recommend: false,
          etc: null,
        },
        university: null,
        department: null,
        guardianInfo: [],
        siblingsInfo: [],
      }
    }
  );
  const navigate = useNavigate();

  useEffect(() => {
    if(watch('birthday') === '') {
      setError('birthday', { message: "생년월일을 입력해주세요" });
    } else {
      clearErrors('birthday');
    }

    if (watch('gender') === '') {
      setError('gender', { message: "성별을 선택해주세요" });
    } else {
      clearErrors('gender');
    }
  }, [watch('birthday'), watch('gender')]);


  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [isInitialCategorySet, setIsInitialCategorySet] = useState(false);
  const [electiveOptions, setElectiveOptions] = useState<string[]>([]);
  const [isGraduated, setIsGraduated] = useState(false);
  useEffect(() => {
    setValue('isGraduated', isGraduated);
  }, [isGraduated]);

  const [isUniv, setIsUniv] = useState(false);

  useEffect(() => {
    if (selectedCategory) {
      setElectiveOptions(electiveOptionsDict[selectedCategory]);
      if (!isInitialCategorySet) {
        setIsInitialCategorySet(true);
        return;
      }
      setValue("subjects.elective1.option", null);
      setValue("subjects.elective1.category", selectedCategory);
      setValue("subjects.elective2.option", null);
      setValue("subjects.elective2.category", selectedCategory);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (watch('grade') === null && !isGraduated) {
      setError('grade', { message: "졸업 또는 학년을 선택해주세요" });
    } else {
      clearErrors('grade');
    }

    if (isGraduated) {
      setValue('grade', null);
    }

  },[watch('grade'), isGraduated])

  useEffect(() => {
    if (isUniv) {
      if (watch('university') === null || watch('university') === '') {
        setError('university', { message: "대학 이름과 학과 이름을 입력해주세요" });
      } else if (watch('department') === null || watch('department') === '') {
        setError('department', { message: "대학 이름과 학과 이름을 입력해주세요" });
      } else {
        clearErrors('university');
        clearErrors('department');
      }
    } else {
      setValue('university', null);
      setValue('department', null);
      clearErrors('university');
      clearErrors('department');
    }
  }, [watch('university'), watch('department'), isUniv]);

  useEffect(() => {
    if (watch('guardianInfo').length === 0) {
      setError('guardianInfo', { message: "보호자 정보를 입력해주세요" });
    } else {
      clearErrors('guardianInfo');
      watch('guardianInfo').forEach((_, index) => {
        console.log(watch('guardianInfo'));

        const nameField = `guardianInfo.${index}.name` as const;
        const phoneField = `guardianInfo.${index}.phone` as const;
        const timeField = `guardianInfo.${index}.time` as const;
        
        if (watch(nameField) === '') {
          setError(nameField, { message: "보호자 이름을 입력해주세요" });
        } else {
          clearErrors(nameField);
        }
        
        if (watch(phoneField) === '') {
          setError(phoneField, { message: "보호자 연락처를 입력해주세요" });
        } else {
          clearErrors(phoneField);
        }
        
        if (watch(timeField) === '') {
          setError(timeField, { message: "보호자 상담 가능 시간을 입력해주세요" });
        } else {
          clearErrors(timeField);
        }
      });
    }
  }, [watch('guardianInfo')]);

  useEffect(() => {
    if (watch('subjects.korean.option') === '' || watch('subjects.math.option') === '' || watch('subjects.elective1.option') === '' || watch('subjects.elective2.option') === '') {
      setError('subjects', { message: "선택과목을 선택해주세요" });
    } else {
      clearErrors('subjects');

    }
  }, [watch('subjects.korean.option'), watch('subjects.math.option'), watch('subjects.elective1.option'), watch('subjects.elective2.option')])

  const [useAdmissionReasonEtc, setUseAdmissionReasonEtc] = useState(false);
  const [admissionReasonEtcInput, setAdmissionReasonEtcInput] = useState('');

  useEffect(() => {
    if (watch('admissionReason.internet') === false && watch('admissionReason.recommend') === false && useAdmissionReasonEtc === false) {
      setError('admissionReason.etc', { message: "입학 동기를 선택해주세요" });
    } else {
      clearErrors('admissionReason.etc');
    }

    if (useAdmissionReasonEtc) {
      setValue('admissionReason.etc', admissionReasonEtcInput);
    } else {
      setValue('admissionReason.etc', null);
    }
  }, [watch('admissionReason.internet'), watch('admissionReason.recommend'), useAdmissionReasonEtc, admissionReasonEtcInput]);

  const [useDeviceOthers, setUseDeviceOthers] = useState(false);
  const [deviceOthersInput, setDeviceOthersInput] = useState('');

  useEffect(() => {
    if (useDeviceOthers) {
      setValue('devices.others', deviceOthersInput);
    } else {
      setValue('devices.others', null);
    }
  }, [useDeviceOthers, deviceOthersInput]);

  const classId = rootStore.getClassId;
  const [applicationId, setApplicationId] = useState<number | null>(null);
  useEffect(() => {
    context.get(`class/${classId}/class-application/${rootStore.getProfile?.id}`, {},
    (res: IApplicationForResponse) => {
      if (res === null) {
        return;
      }
      setApplicationId(res.id);
      setValue('name', res.name);
      setValue('gender', res.gender);
      setValue('birthday', res.birthday);
      setValue('phone', res.phone);
      setValue('address', res.address);
      setValue('school', res.school);
      setValue('grade', res.grade);
      setIsGraduated(res.isGraduated); // Checkbox 상태 변경
      setIsUniv(res.university !== null);
      setValue('university', res.university);
      setValue('department', res.department);
      setValue('email', res.email);
      setValue('guardianInfo', res.guardianInfo ?? []);
      setValue('siblingsInfo', res.siblingsInfo ?? []);
      const category = electiveOptionsDict["사회탐구"].includes(res.elective1Option) ? "사회탐구" : "과학탐구";
      setSelectedCategory(category);
      setValue('subjects', {
        korean: {
          option: res.koreanOption,
          rank: res.koreanRank,
        },
        math: {
          option: res.mathOption,
          rank: res.mathRank,
        },
        english: res.englishRank,
        history: res.historyRank,
        elective1: {
          category: category,
          option: res.elective1Option,
          rank: res.elective1Rank,
        },
        elective2: {
          category: category,
          option: res.elective2Option,
          rank: res.elective2Rank,
        },
      });
      setValue('weakSubject', res.weakSubject);
      setValue('onlineClass', res.onlineClass ?? []);
      setValue('etc', res.etcInfo);
      setValue('admissionReason', res.admissionReason);
      setUseAdmissionReasonEtc(res.admissionReason.etc !== null);
      setAdmissionReasonEtcInput(res.admissionReason.etc);
      setValue('career', res.career);
      setValue('devices', res.devices);
      setUseDeviceOthers(res.devices.others !== null);
      setDeviceOthersInput(res.devices.others);
    });
  }, [classId]);


  const onSubmit = (data: ApplicationFormData) => {
    context.post(
      `class/${classId}/class-application`,
      {
        ...data
      },
      (res) => {
        navigate(`/class/application-for-admission/info`);
      }
    )
  };

  useEffect(() => {
    if (watch('siblingsInfo').length > 0) {
      watch('siblingsInfo').forEach((item, index) => {
        trigger(`siblingsInfo.${index}.school`);
        trigger(`siblingsInfo.${index}.grade`);
        trigger(`siblingsInfo.${index}.name`);
        trigger(`siblingsInfo.${index}.phone`);
      });
    }
  }, [watch('siblingsInfo')]);

  const [showSubmitAlert, setShowSubmitAlert] = useState(false);

  const validatePhone = (value: string) => {
    const phonePattern = /^01([0|1|6|7|8|9])?([0-9]{3,4})?([0-9]{4})$/;
    if (!value) {
      setError("phone", { type: "required", message: "전화번호를 입력해주세요" });
    } else if (!phonePattern.test(value)) {
      setError("phone", { type: "pattern", message: "올바른 핸드폰 번호를 입력해주세요" });
    } else {
      clearErrors("phone");
    }
  };

  console.log(watch('subjects'));

  return (
    <WholeWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 20,
        }}
      >
        <span style={{ ...fonts.title3SemiBold }}>입학원서</span>
        <Button text={applicationId ? "수정하기" : "제출하기"} onClick={() => setShowSubmitAlert(true)} size="small" width="77px" height="36px" />
      </div>
      <FormWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>이름 및 성별</SectionTitle>
            <RequiredCircle />
          </RowWrapper>
          <RowWrapper>
            <TextField
              value={watch("name")}
              onChange={(e) => {
                setValue("name", e.target.value);
                if (e.target.value === "") {
                  setError("name", { type: "required", message: "이름을 입력해주세요" });
                } else {
                  clearErrors("name");
                }
              }}
              onBlur={() => {
                setValue("name", watch("name"));
              }}
              error={errors.name !== undefined}
              helperText={errors.name?.message}
              complete={watch("name") && errors.name === undefined}
            />
            <SexButton
              isPressed={watch("gender") === "M"}
              onClick={() => setValue("gender", "M")}
            >
              남자
            </SexButton>
            <SexButton
              isPressed={watch("gender") === "F"}
              onClick={() => setValue("gender", "F")}
            >
              여자
            </SexButton>
          </RowWrapper>
          {errors.gender && (
            <div style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
              * {errors.gender.message}
            </div>
          )}
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper style={{ width: "100%" , alignItems: "flex-start"}}>
            <div style={{ width: "25%" }}>
              <RowWrapper style={{ marginBottom: "15px" }}>
                <SectionTitle>생년월일</SectionTitle>
                <RequiredCircle />
              </RowWrapper>
              <RowWrapper>
                <div style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    height: 50,
                    border: "1px solid #E9EBED",
                    borderRadius: 8,
                  }}>
                  <DatePickerCustom
                    value={moment(watch("birthday"))}
                    onChange={(date) =>
                      setValue(
                        "birthday",
                        (date as moment.Moment).format("YYYY-MM-DD")
                      )
                    }
                    timeFormat={false}
                    dateFormat="YYYY-MM-DD"
                  />
                </div>
              </RowWrapper>
            </div>
            <div style={{ width: "75%" }}>
              <RowWrapper style={{ marginBottom: "15px" }}>
                <SectionTitle>연락처</SectionTitle>
                <RequiredCircle />
              </RowWrapper>
              <RowWrapper>
                <TextField
                  value={watch("phone")}
                  onChange={(e) => {
                    setValue("phone", e.target.value);
                    validatePhone(e.target.value);
                  }}
                  error={errors.phone !== undefined}
                  helperText={errors.phone?.message}
                  type="text"
                  placeholder="전화번호를 입력해주세요"
                  complete={watch("phone") && errors.phone === undefined}
                />
              </RowWrapper>
            </div>
          </RowWrapper>          
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>주소</SectionTitle>
            <RequiredCircle />
          </RowWrapper>
          <RowWrapper>
            <TextField
              value={watch("address")}
              onChange={(e) => {setValue("address", e.target.value);
                if (e.target.value === "") {
                  setError("address", { type: "required", message: "주소를 입력해주세요" });
                } else {
                  clearErrors("address");
                }
              }}
              error={errors.address !== undefined}
              helperText={errors.address?.message}
              type="text"
              placeholder="주소를 입력해주세요"
              complete={watch("address") && errors.address === undefined}
            />
          </RowWrapper>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>학교</SectionTitle>
            <RequiredCircle />
          </RowWrapper>
          <RowWrapper>
            <TextField
              value={watch("school")}
              onChange={(e) => {setValue("school", e.target.value);
                if (e.target.value === "") {
                  setError("school", { type: "required", message: "학교를 입력해주세요" });
                } else {
                  clearErrors("school");
                }
              }}
              error={errors.school !== undefined}
              type="text"
              placeholder="학교를 입력해주세요"
              width='calc(50% - 4px)'
              complete={watch("school") && errors.school === undefined}
            />
            <SelectBox
              options={[
                { label: "1학년", onSelect: () => setValue("grade", 1) },
                { label: "2학년", onSelect: () => setValue("grade", 2) },
                { label: "3학년", onSelect: () => setValue("grade", 3) },
              ]}
              selectedOptionLabel={watch("grade") ? watch("grade").toString() + "학년" : "학년선택"}
              disabled={watch("isGraduated")}
              width='calc(25% - 4px)'
            />
            <Checkbox
              checked={isGraduated}
              setChecked={() => setIsGraduated(!isGraduated)}
            />
            <span
              style={{ display: "flex", alignItems: "center", ...fonts.label4Medium, color: colors.gray500 }}
            >
              졸업
            </span>
          </RowWrapper>
          <RowWrapper>
          {
            errors.school && (
              <div style={{ color: colors.warning, fontSize: "14px", marginTop: "5px" }}>
                * {errors.school.message}
              </div>
            )
          }
          {errors.grade && (
            <div style={{ color: colors.warning, fontSize: "14px", marginTop: "5px" }}>
              * {errors.grade.message}
            </div>
          )}
          </RowWrapper>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>반수</SectionTitle>
            <RequiredCircle />
          </RowWrapper>
          <RowWrapper style={{alignItems: "flex-start"}}>
              <TextField
                value={isUniv ? watch("university") : ""}
                onChange={(e) => {setValue("university", e.target.value)}}
                error={errors.university !== undefined || errors.university?.message !== undefined || errors.department?.message !== undefined}
                helperText={errors.university?.message || errors.department?.message}
                type="text"
                placeholder="대학교 이름을 입력해주세요"
                width='calc(50% - 4px)'
                disabled={!isUniv}
                complete={watch("university") && errors.university === undefined}
              />
              <div style={{display: "flex", alignItems: "center", width: "calc(50% + 4px)", gap: 8}}>
                <TextField
                  value={isUniv ? watch("department") : ""}
                  onChange={(e) => {setValue("department", e.target.value);
                    if (e.target.value === "") {
                      setError("department", { type: "required", message: "학과를 입력해주세요" });
                    } else {
                      clearErrors("department");
                    }
                  }}
                  error={errors.department !== undefined || errors.department?.message !== undefined}
                  type="text"
                  placeholder="학과 이름을 입력해주세요"
                  width='80%'
                  disabled={!isUniv}
                  complete={watch("department") && errors.department === undefined}
                />
                <Checkbox
                  checked={!isUniv}
                  setChecked={() => setIsUniv(!isUniv)}
                />
                <span
                  style={{ display: "flex", alignItems: "center", ...fonts.label4Medium, color: colors.gray500 }}
                >
                  해당 없음
                </span>
              </div>
          </RowWrapper>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>이메일</SectionTitle>
            <RequiredCircle />
          </RowWrapper>
          <RowWrapper>
            <TextField
              value={watch("email")}
              onChange={(e) => {setValue("email", e.target.value);
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailPattern.test(e.target.value)) {
                  setError("email", { type: "pattern", message: "올바른 이메일 주소를 입력해주세요" });
                } else {
                  clearErrors("email");
                }
              }}
              error={errors.email !== undefined || errors.email?.message !== undefined}
              helperText={errors.email?.message}
              type="email"
              placeholder="이메일 주소를 입력해주세요"
              complete={watch("email") && errors.email === undefined}
            />
          </RowWrapper>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>보호자 정보</SectionTitle>
            <RequiredCircle />
          </RowWrapper>
          <TableCustom>
            <thead>
              <tr>
                <ColoredCell>관계</ColoredCell>
                <ColoredCell>이름</ColoredCell>
                <ColoredCell>연락처</ColoredCell>
                <ColoredCell>상담 가능 시간</ColoredCell>
                <ColoredCell>삭제</ColoredCell>
              </tr>
            </thead>
            <tbody>
              {watch('guardianInfo').map((item, index) => (
                <tr key={index}>
                  <td>
                    <SelectBox
                      selectedOptionLabel={item.relationship}
                      options={[
                        { label: "부", onSelect: () => setValue(`guardianInfo.${index}.relationship`, "부") },
                        { label: "모", onSelect: () => setValue(`guardianInfo.${index}.relationship`, "모") },
                        { label: "기타", onSelect: () => setValue(`guardianInfo.${index}.relationship`, "기타") },
                      ]}
                    />
                  </td>
                  <td>
                    <TextField
                      value={watch(`guardianInfo.${index}.name`)}
                      onChange={(e) => {setValue(`guardianInfo.${index}.name`, e.target.value);
                        if (e.target.value === "") {
                          setError(`guardianInfo.${index}.name`, { type: "required", message: "이름을 입력해주세요" });
                        } else {
                          clearErrors(`guardianInfo.${index}.name`);
                        }
                      }}
                      error={errors.guardianInfo?.[index]?.name !== undefined}
                      type="text"
                      placeholder="이름 입력"
                      complete={watch(`guardianInfo.${index}.name`) && errors.guardianInfo?.[index]?.name === undefined}
                    />  
                  </td>
                  <td>
                    <TextField
                      value={watch(`guardianInfo.${index}.phone`)}
                      onChange={(e) => {setValue(`guardianInfo.${index}.phone`, e.target.value);
                        const phonePattern = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
                        if (!phonePattern.test(e.target.value)) {
                          setError(`guardianInfo.${index}.phone`, { type: "pattern", message: "올바른 핸드폰 번호를 입력해주세요" });
                        } else {
                          clearErrors(`guardianInfo.${index}.phone`);
                        }
                      }}
                      error={errors.guardianInfo?.[index]?.phone !== undefined}
                      type="text"
                      placeholder="연락처 입력"
                      complete={watch(`guardianInfo.${index}.phone`) && errors.guardianInfo?.[index]?.phone === undefined}
                    />
                  </td>
                  <td>
                    <TextField
                      value={watch(`guardianInfo.${index}.time`)}
                      onChange={(e) => {setValue(`guardianInfo.${index}.time`, e.target.value);
                        if (e.target.value === "") {
                          setError(`guardianInfo.${index}.time`, { type: "required", message: "보호자 상담 가능 시간을 입력해주세요" });
                        } else {
                          clearErrors(`guardianInfo.${index}.time`);
                        }
                      }}
                      error={errors.guardianInfo?.[index]?.time !== undefined}
                      type="text"
                    placeholder="상담 가능 시간 입력"
                    complete={watch(`guardianInfo.${index}.time`) && errors.guardianInfo?.[index]?.time === undefined}
                  />
                </td>
                <td>
                  <DeleteButton onClick={() => {
                    const updatedGuardianInfo = watch('guardianInfo').filter((_, i) => i !== index);
                    setValue('guardianInfo', updatedGuardianInfo);
                  }}>
                    <FaTrash />
                  </DeleteButton>
                </td>
                </tr>
              ))}
            </tbody>
          </TableCustom>
          {watch('guardianInfo').length < 5 && <AddRowButton onClick={() => {
            setValue('guardianInfo', [...watch('guardianInfo'), {
              relationship: "부",
              name: "",
              phone: "",
              time: "",
            }]);
          }}>
            <FaPlusCircle />
            보호자 추가
          </AddRowButton>}
          {errors.guardianInfo && (
            <div style={{ color: colors.warning, ...fonts.label4Medium, marginTop: "5px" }}>
              * {errors.guardianInfo?.message || errors.guardianInfo?.map((guardianError, index) => (
                guardianError?.relationship?.message || guardianError?.name?.message || guardianError?.phone?.message || guardianError?.time?.message
              )).filter(Boolean).join(', ')}
            </div>
          )}
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>형제자매</SectionTitle>
          </RowWrapper>
          <TableCustom>
            <thead>
              <tr>
                <ColoredCell>관계</ColoredCell>
                <ColoredCell>이름</ColoredCell>
                <ColoredCell>연락처</ColoredCell>
                <ColoredCell>학교 및 학년</ColoredCell>
                <ColoredCell>삭제</ColoredCell>
              </tr>
            </thead>
            <tbody>
              {watch('siblingsInfo').map((item, index) => (
                <tr key={index}>
                  {index === 0 && <td rowSpan={watch('siblingsInfo').length}>형제<br />자매</td>}
                  <td>
                    <TextField
                      value={watch(`siblingsInfo.${index}.name`)}
                      onChange={(e) => {setValue(`siblingsInfo.${index}.name`, e.target.value);
                        if (e.target.value === "") {
                          setError(`siblingsInfo.${index}.name`, { type: "required", message: "이름을 입력해주세요" });
                        } else {
                          clearErrors(`siblingsInfo.${index}.name`);
                        }
                      }}
                      error={errors.siblingsInfo?.[index]?.name !== undefined}
                      size="small"
                      complete={watch(`siblingsInfo.${index}.name`) && errors.siblingsInfo?.[index]?.name === undefined}
                    />
                  </td>
                  <td>
                    <TextField
                      value={watch(`siblingsInfo.${index}.phone`)}
                      onChange={(e) => {setValue(`siblingsInfo.${index}.phone`, e.target.value);
                        const phonePattern = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
                        if (!phonePattern.test(e.target.value)) {
                          setError(`siblingsInfo.${index}.phone`, { type: "pattern", message: "올바른 핸드폰 번호를 입력해주세요" });
                        } else {
                          clearErrors(`siblingsInfo.${index}.phone`);
                        }
                      }}
                      error={errors.siblingsInfo?.[index]?.phone !== undefined}
                      type="text"
                      placeholder="연락처 입력"
                      size="small"
                      complete={watch(`siblingsInfo.${index}.phone`) && errors.siblingsInfo?.[index]?.phone === undefined}
                    />
                  </td>
                  <td>
                    <RowWrapper>
                      <TextField
                        value={watch(`siblingsInfo.${index}.school`)}
                        onChange={(e) => {setValue(`siblingsInfo.${index}.school`, e.target.value);
                          if (e.target.value === "") {
                            setError(`siblingsInfo.${index}.school`, { type: "required", message: "학교를 입력해주세요" });
                          } else {
                            clearErrors(`siblingsInfo.${index}.school`);
                          }
                        }}
                        error={errors.siblingsInfo?.[index]?.school !== undefined}
                        type="text"
                        placeholder="학교 입력"
                        size="small"
                        complete={watch(`siblingsInfo.${index}.school`) && errors.siblingsInfo?.[index]?.school === undefined}
                      />
                      <SelectBox
                        selectedOptionLabel={(watch(`siblingsInfo.${index}.grade`).toString()) === "undefined" ? "학년선택" : (watch(`siblingsInfo.${index}.grade`).toString()+"학년")}
                        options={[
                          { label: "1학년", onSelect: () => setValue(`siblingsInfo.${index}.grade`, 1) },
                          { label: "2학년", onSelect: () => setValue(`siblingsInfo.${index}.grade`, 2) },
                          { label: "3학년", onSelect: () => setValue(`siblingsInfo.${index}.grade`, 3) },
                        ]}
                        size="small"
                      />
                    </RowWrapper>
                  </td>
                  <td>
                    <DeleteButton onClick={() => {
                      setValue('siblingsInfo', watch('siblingsInfo').filter((_, i) => i !== index));
                    }}>
                      <FaTrash />
                    </DeleteButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </TableCustom>
          {watch('siblingsInfo').length < 5 && <AddRowButton onClick={() => {
                  setValue('siblingsInfo', [...watch('siblingsInfo'), {
                    name: "",
                    phone: "",
                    school: "",
                    grade: 1,
                  }]);
                }}>
                  <FaPlusCircle />
                  형제자매 추가
                </AddRowButton>}
          {errors.siblingsInfo && (
            <div style={{ color: colors.warning, ...fonts.label4Medium, marginTop: "5px" }}>
              * {errors.siblingsInfo?.map((siblingError, index) => (
                siblingError?.name?.message || siblingError?.phone?.message || siblingError?.school?.message || siblingError?.grade?.message
              )).filter(Boolean).join(', ')}
            </div>
          )}
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>수능 및 모의고사 성적</SectionTitle>
            <RequiredCircle />
          </RowWrapper>
          <TableCustom>
            <tbody>
              <tr>
                <ColoredCell>국어</ColoredCell>
                <td>
                  <SelectBox
                    selectedOptionLabel={watch("subjects.korean.option") ?? "선택"}
                    options={[
                      {
                        label: "화법과 작문",
                        onSelect: () => setValue("subjects.korean.option", "화법과 작문"),
                      },
                      {
                        label: "언어와 매체",
                        onSelect: () => setValue("subjects.korean.option", "언어와 매체"),
                      },
                    ]}
                    size="small"
                  />
                </td>
                <td>
                  <RowWrapper style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <TextField
                      value={(watch("subjects.korean.rank") ?? "").toString()}
                      onChange={(e) => {setValue("subjects.korean.rank", parseInt(e.target.value));
                        if (e.target.value === "") {
                          setError("subjects.korean.rank", { type: "required", message: "성적을 입력해주세요" });
                        } else {
                          clearErrors("subjects.korean.rank");
                        }
                      }}
                      type="number"
                      width='80%'
                      size="small"
                      complete={watch("subjects.korean.rank") && errors?.subjects?.korean?.rank === undefined }
                      error={errors?.subjects?.korean?.rank !== undefined}
                    />
                    <span style={{...fonts.label4Medium, color: colors.gray900}}>등급</span>
                  </RowWrapper>
                </td>
              </tr>
              <tr>
                <ColoredCell>수학</ColoredCell>
                <td>
                  <SelectBox
                    options={[
                      {
                        label: "확률과 통계",
                        onSelect: () => setValue("subjects.math.option", "확률과 통계"),
                      },
                      {
                        label: "기하",
                        onSelect: () => setValue("subjects.math.option", "기하"),
                      },
                      {
                        label: "미적분",
                        onSelect: () => setValue("subjects.math.option", "미적분"),
                      },
                    ]}
                    selectedOptionLabel={watch("subjects.math.option") ?? "선택"}
                    size="small"
                  />
                </td>
                <td>
                  <RowWrapper style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <TextField
                      value={(watch("subjects.math.rank") ?? "").toString()}
                      onChange={(e) => {setValue("subjects.math.rank", parseInt(e.target.value));
                        if (e.target.value === "") {
                          setError("subjects.math.rank", { type: "required", message: "성적을 입력해주세요" });
                        }
                      }}
                      type="number"
                      width='80%'
                      size="small"
                      complete={watch("subjects.math.rank") && errors?.subjects?.math?.rank === undefined}
                      error={errors?.subjects?.math?.rank !== undefined}
                    />
                    <span style={{...fonts.label4Medium, color: colors.gray900}}>등급</span>
                  </RowWrapper>
                </td>
              </tr>
              <tr>
                <ColoredCell>영어</ColoredCell>
                <td>
                  <span> - </span>
                </td>
                <td>
                  <RowWrapper style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <TextField
                      value={(watch("subjects.english") ?? "").toString()}
                      onChange={(e) => {setValue("subjects.english", parseInt(e.target.value));
                        if (e.target.value === "") {
                          setError("subjects.english", { type: "required", message: "성적을 입력해주세요" });
                        }
                      }}
                      type="number"
                      width='80%'
                      size="small"
                      complete={watch("subjects.english") && errors?.subjects?.english === undefined}
                      error={errors?.subjects?.english !== undefined}
                    />
                    <span style={{...fonts.label4Medium, color: colors.gray900}}>등급</span>
                  </RowWrapper>
                </td>
              </tr>
              <tr>
                <ColoredCell>한국사</ColoredCell>
                <td>
                  <span> - </span>
                </td>
                <td>
                  <RowWrapper style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <TextField
                      value={(watch("subjects.history") ?? "").toString()}
                      onChange={(e) => {setValue("subjects.history", parseInt(e.target.value));
                        if (e.target.value === "") {
                          setError("subjects.history", { type: "required", message: "성적을 입력해주세요" });
                        }
                      }}
                      type="number"
                      width='80%'
                      size="small"
                      complete={watch("subjects.history") && errors?.subjects?.history === undefined}
                      error={errors?.subjects?.history !== undefined}
                    />
                    <span style={{...fonts.label4Medium, color: colors.gray900}}>등급</span>
                  </RowWrapper>
                </td>
              </tr>
              <tr>
                <ColoredCell>탐구1</ColoredCell>
                <td>
                  <RowWrapper>  
                    <Controller
                      control={control}
                      name="subjects.elective1.category"
                      render={({ field }) => (
                        <SelectBox
                          options={[
                            {
                              label: "사회탐구",
                              onSelect: () => {
                                setSelectedCategory("사회탐구");
                                field.onChange("사회탐구");
                              },
                            },
                            {
                              label: "과학탐구",
                              onSelect: () => {
                                setSelectedCategory("과학탐구");
                                field.onChange("과학탐구");
                              },
                            },
                          ]}
                          selectedOptionLabel={selectedCategory ?? "탐구 선택"}
                          width='calc(50% - 5px)'
                          size="small"
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="subjects.elective1.option"
                      render={({ field }) => (
                        <SelectBox
                          options={[
                            ...electiveOptions.filter((option) => option !== watch("subjects.elective2.option")).map((option) => ({ 
                              label: option, 
                              onSelect: () => field.onChange(option)
                            })),
                          ]}
                          selectedOptionLabel={field.value ?? "선택"}
                          width='calc(50% - 5px)'
                          size="small"
                        />
                      )}
                    />
                  </RowWrapper>
                </td>
                <td>
                  <RowWrapper style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Controller
                      control={control}
                      name="subjects.elective1.rank"
                      rules={{
                        required: "성적을 입력해주세요",
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "숫자만 입력해주세요"
                        }
                      }}
                      render={({ field }) => (
                        <TextField
                          value={(watch("subjects.elective1.rank") ?? "").toString()}
                          onChange={(e) => {setValue("subjects.elective1.rank", parseInt(e.target.value));
                            if (e.target.value === "") {
                              setError("subjects.elective1.rank", { type: "required", message: "성적을 입력해주세요" });
                            }
                          }}
                          type="number"
                          width='80%'
                          complete={watch("subjects.elective1.rank") && errors?.subjects?.elective1?.rank === undefined}
                          error={errors?.subjects?.elective1?.rank !== undefined}
                        />
                      )}
                    />
                    <span style={{...fonts.label4Medium, color: colors.gray900}}>등급</span>
                  </RowWrapper>
                </td>
              </tr>
              <tr>
                <ColoredCell>탐구2</ColoredCell>
                <td>
                  <RowWrapper>
                    <div style={{ width: "calc(50% - 5px)", height: "50px", ...fonts.label4Medium, color: colors.gray900, display: "flex", alignItems: "center", justifyContent: "flex-start", border: `1px solid ${colors.gray100}`, borderRadius: "8px", paddingLeft: "16px" }}>
                      {selectedCategory || "탐구 선택"}
                    </div>
                    <Controller
                      control={control}
                      name="subjects.elective2.option"
                      render={({ field }) => (
                        <SelectBox
                          options={[
                            ...electiveOptions.filter((option) => option !== watch("subjects.elective1.option")).map((option) => ({ 
                              label: option, 
                              onSelect: () => field.onChange(option)
                            })),
                          ]}
                          selectedOptionLabel={field.value ?? "선택"}
                          width='calc(50% - 5px)'
                          size="small"
                        />
                      )}
                    />
                  </RowWrapper>
                </td>
                <td>
                  <RowWrapper style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <TextField
                      value={(watch("subjects.elective2.rank") ?? "").toString()}
                      onChange={(e) => {setValue("subjects.elective2.rank", parseInt(e.target.value));
                        if (e.target.value === "") {
                          setError("subjects.elective2.rank", { type: "required", message: "성적을 입력해주세요" });
                        }
                      }}
                      type="number"
                      width='80%'
                      complete={watch("subjects.elective2.rank") && errors?.subjects?.elective2?.rank === undefined}
                      error={errors?.subjects?.elective2?.rank !== undefined}
                    />
                    <span style={{...fonts.label4Medium, color: colors.gray900}}>등급</span>
                  </RowWrapper> 
                </td>
              </tr>
            </tbody>
          </TableCustom>
          {errors.subjects && (
            <div style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
              * {typeof errors.subjects === 'object' && 'message' in errors.subjects 
                ? errors.subjects.message 
                : Object.values(errors.subjects as Record<string, { message: string }>)
                  .map(error => error?.message)
                  .filter(Boolean)
                  .join(', ')}
            </div>
          )}
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>취약 과목</SectionTitle>
            <RequiredCircle />
          </RowWrapper>
          <RowWrapper>
            <TextField
              value={watch("weakSubject") ?? ""}
              onChange={(e) => {setValue("weakSubject", e.target.value);
                if (e.target.value === "") {
                  setError("weakSubject", { type: "required", message: "내용을 입력해주세요" });
                } else {
                  clearErrors("weakSubject");
                }
              }}
              placeholder="내용을 입력해주세요"
              complete={watch("weakSubject") && errors.weakSubject === undefined}
            />
          </RowWrapper>
          {errors.weakSubject && (
            <div style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
              * {errors.weakSubject?.message}
            </div>
          )}
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>수강한 인터넷 강의 (최대 10개)</SectionTitle>
          </RowWrapper>
          <TableCustom>
            <thead>
              <tr>
                <ColoredCell>과목</ColoredCell>
                <ColoredCell>내용</ColoredCell>
                <ColoredCell>사이트</ColoredCell>
                <ColoredCell>삭제</ColoredCell>
              </tr>
            </thead>
            <tbody>
              {watch('onlineClass').map((item, index) => (
                <tr key={index}>
                  <td>
                    <SelectBox
                      options={[
                        {
                          label: "국어",
                          onSelect: () => setValue(`onlineClass.${index}.subject`, "국어"),
                        }, {
                          label: "수학",
                          onSelect: () => setValue(`onlineClass.${index}.subject`, "수학"),
                        }, {
                          label: "영어",
                          onSelect: () => setValue(`onlineClass.${index}.subject`, "영어"),
                        }, {
                          label: "한국사",
                          onSelect: () => setValue(`onlineClass.${index}.subject`, "한국사"),
                        }, {
                          label: "탐구",
                          onSelect: () => setValue(`onlineClass.${index}.subject`, "탐구"),
                        }
                    ]}
                    selectedOptionLabel={watch(`onlineClass.${index}.subject`) ?? "선택"}
                  />
                </td>
                <td>
                  <TextField
                    value={watch(`onlineClass.${index}.content`) ?? ""}
                    onChange={(e) => {setValue(`onlineClass.${index}.content`, e.target.value);
                      if (e.target.value === "") {
                        setError(`onlineClass.${index}.content`, { type: "required", message: "내용을 입력해주세요" });
                      }
                    }}
                    placeholder="내용 입력"
                    size="small"
                  />
                </td>
                <td>
                  <TextField
                    placeholder='사이트 입력'
                    value={watch(`onlineClass.${index}.site`) ?? ""}
                    onChange={(e) => {setValue(`onlineClass.${index}.site`, e.target.value);
                      if (e.target.value === "") {
                        setError(`onlineClass.${index}.site`, { type: "required", message: "사이트를 입력해주세요" });
                      }
                    }}
                    size="small"
                  />
                </td>
                <td>
                  <DeleteButton onClick={() => {
                    setValue('onlineClass', watch('onlineClass').filter((_, i) => i !== index));
                  }}>
                    <FaTrash />
                  </DeleteButton>
                </td>
              </tr>
              ))}
            </tbody>
          </TableCustom>
          {watch('onlineClass').length < 10 && <AddRowButton onClick={() => {
            setValue('onlineClass', [...watch('onlineClass'), {
              subject: "",
              content: "",
              site: "",
            }]);
          }}>
            <FaPlusCircle />
            <span>강의 추가</span>
          </AddRowButton>}  
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>특이 사항</SectionTitle>
          </RowWrapper>
          <TableCustom>
            <tbody>
              <tr>
                <ColoredCell>학습</ColoredCell>
                <td>
                  <TextField
                    value={watch('etc.learning') ?? ""}
                    onChange={(e) => {setValue('etc.learning', e.target.value);
                      if (e.target.value === "") {
                        setError('etc.learning', { type: "required", message: "내용을 입력해주세요" });
                      }
                    }}
                    placeholder="특정 신청 과목 등"
                  />
                </td>
              </tr>
              <tr>
                <ColoredCell>건강</ColoredCell>
                <td>
                  <TextField
                    value={watch('etc.health') ?? ""}
                    onChange={(e) => {setValue('etc.health', e.target.value);
                      if (e.target.value === "") {
                        setError('etc.health', { type: "required", message: "내용을 입력해주세요" });
                      }
                    }}
                    placeholder="아토피, 당뇨, 코골이 등"
                  />
                </td>
              </tr>
              <tr>
                <ColoredCell>생활</ColoredCell>
                <td>
                  <TextField
                    value={watch('etc.lifeStyle') ?? ""}
                    onChange={(e) => {setValue('etc.lifeStyle', e.target.value);
                      if (e.target.value === "") {
                        setError('etc.lifeStyle', { type: "required", message: "내용을 입력해주세요" });
                      }
                    }}
                    placeholder="아침형, 저녁형, 채식주의 등"
                  />
                </td>
              </tr>
            </tbody>
          </TableCustom>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>입학 계기</SectionTitle>
            <RequiredCircle />
          </RowWrapper>
          <RowWrapper>
            <Checkbox 
              checked={Boolean(watch('admissionReason.internet'))} 
              setChecked={() => setValue('admissionReason', { ...watch('admissionReason'), internet: !watch('admissionReason.internet') })} 
            />
            <TextSpan>{"인터넷 검색 (파워링크, 블로그, 유튜브 등)"}</TextSpan>
          </RowWrapper>
          <RowWrapper>
            <Checkbox checked={Boolean(watch('admissionReason.recommend'))} setChecked={() => setValue('admissionReason', {...watch('admissionReason'), recommend: !watch('admissionReason.recommend')})} />
            <TextSpan>{"주위 추천 (부모님, 친구, 선후배, 강사, 지인, 타 학원, 타 학습 사이트 등)"}</TextSpan>
          </RowWrapper>
          <RowWrapper>
            <Checkbox checked={useAdmissionReasonEtc} setChecked={() => setUseAdmissionReasonEtc(!useAdmissionReasonEtc)} />
            <TextSpan>{"기타"}</TextSpan>
            <TextField
              placeholder="내용 입력"
              value={admissionReasonEtcInput}
              onChange={(e) => setAdmissionReasonEtcInput(e.target.value)}
              complete={admissionReasonEtcInput !== ""}
              size="small"
              width="calc(100% - 80px)"
            />
          </RowWrapper>
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>진로 계획</SectionTitle>
            <RequiredCircle />
          </RowWrapper>
          <RowWrapper>
            <TextField
              value={watch('career') ?? ""}
              onChange={(e) => {setValue('career', e.target.value);
                if (e.target.value === "") {
                  setError('career', { type: "required", message: "내용을 입력해주세요" });
                } else {
                  clearErrors("career");
                }
              }}
              placeholder="내용을 입력해주세요"
              helperText={"* 대학, 전공, 자사고, 특목고 진학 등의 계획을 작성해주세요"}
              size="small"
              complete={watch("career") && errors.career === undefined}
            />
          </RowWrapper>
          {errors.career && (
            <div style={{ color: "red", fontSize: "14px", marginTop: "5px" }}>
              * {errors.career?.message}
            </div>
          )}
        </SectionWrapper>
        <SectionWrapper>
          <RowWrapper>
            <SectionTitle>전자기기</SectionTitle>
          </RowWrapper>
          <RowWrapper>
            <Checkbox checked={Boolean(watch('devices.notebook'))} setChecked={() => setValue('devices.notebook', !watch('devices.notebook'))} />
            <TextSpan>{"노트북"}</TextSpan>

            <Checkbox checked={Boolean(watch('devices.tablet'))} setChecked={() => setValue('devices.tablet', !watch('devices.tablet'))} />
            <TextSpan>{"태블릿PC"}</TextSpan>

            <Checkbox checked={Boolean(watch('devices.smartphone'))} setChecked={() => setValue('devices.smartphone', !watch('devices.smartphone'))} />
            <TextSpan>{"스마트폰"}</TextSpan>
          </RowWrapper>
          <RowWrapper>
            <Checkbox checked={useDeviceOthers} setChecked={() => setUseDeviceOthers(!useDeviceOthers)}/>
            <TextSpan>{"기타"}</TextSpan>
            <TextField
              placeholder="내용 입력"
              value={deviceOthersInput}
              onChange={(e) => setDeviceOthersInput(e.target.value)}
              complete={deviceOthersInput !== ""}
              width="calc(100% - 80px)"
            />
          </RowWrapper>
        </SectionWrapper>
      </FormWrapper>
      {showSubmitAlert && <SubmitAlert toggle={() => setShowSubmitAlert(false)} onConfirm={() => {
        setShowSubmitAlert(false);
        handleSubmit(onSubmit)();
      }} isNew={!applicationId}/>}
    </WholeWrapper>
  );
});

const WholeWrapper = styled.div`
  padding: 0 80px 30px 80px;
  overflow-y: auto;
  height: 100%;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 50px 250px;
  margin-top: 20px;
  border-radius: 20px;
  gap: 40px;
  position: relative;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const SectionTitle = styled.span`
  font-family: ${fonts.body3SemiBold.fontFamily};
  font-size: ${fonts.body3SemiBold.fontSize};
  color: ${colors.gray900};
  line-height: ${fonts.body3SemiBold.lineHeight};
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
`;

const RequiredCircle = styled.div`
  width: 10px;
  height: 10px;
  background-color: #ee4c69;
  border-radius: 50%;
`;

const SexButton = styled.div<{ isPressed: boolean }>`
  width: 80px;
  height: 50px;
  border: 1px solid #e9ebed;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${(props) => (props.isPressed ? "#0F84F4" : "#ffffff")};
  color: ${(props) => (props.isPressed ? "#ffffff" : "#73787E")};
`;


const TableCustom = styled.table`
  border-collapse: collapse;
  width: 100%;
  & td, th {
    text-align: center;
    vertical-align: middle;
    height: 45px;
    padding: 8px 10px;
    font-size: 14px;
    border-bottom: 1px solid #E9EBED;
    border-top: 1px solid #E9EBED;
  }
`;

const ColoredCell = styled.td`
  background-color: #E9EBED;
  font-family: ${fonts.label4Medium.fontFamily};
  font-size: ${fonts.label4Medium.fontSize};
  color: ${colors.gray500};
  line-height: ${fonts.label4Medium.lineHeight};
`;

const AddRowButton = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  cursor: pointer;
  background-color: #F7F8F9;
  color: #73787E;
  font-size: 16px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const TextSpan = styled.span`
  font-family: ${fonts.label4Medium.fontFamily};
  font-size: ${fonts.label4Medium.fontSize};
  color: ${colors.gray900};
  line-height: ${fonts.label4Medium.lineHeight};
`;

const DeleteButton = styled.div`
  cursor: pointer;
  color: #73787E;
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
  &:hover {
    color: #0F84F4;
  }
`;

const DatePickerCustom = styled(DateTime)`
  border: none;
  .form-control {
    border: none;
    text-align: center;
    outline: none;
    font-size: 15px;
    background: transparent;
    padding: 0 30px;
    cursor: pointer;
  }

  .form-control:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
`;


const SubmitAlert = (props: {toggle: () => void, onConfirm: () => void, isNew: boolean}) => {
  const { toggle, onConfirm, isNew } = props;

  return (
    <Modal size="middle" isOpen={true} toggle={toggle}>
      <ModalBody style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 10, width: "100%", height: "100%", padding: "32px 30px"}}>
        <div style={{display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "flex-end", gap: 10, width: "100%"}}>
          <IconX color={colors.gray500} width="28px" height="28px" />
        </div>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: 60, position: "relative"}}>
          <img src={"/assets/image/warning_rai.png"} alt="submit_alert" width={173} height={182} style={{marginLeft: "50px"}}/>
          <span style={{...fonts.body3Medium, color: "#F9B5B5",  position: "absolute", top: 35, right: 30}}>잠깐!</span>
        </div>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 10}}>
          <span style={{...fonts.body3Medium, color: colors.gray600, marginTop:44, marginBottom: 42}}>{isNew ? "입학 원서를 제출하시겠습니까?" : "입학 원서를 수정하시겠습니까?"}</span>
        </div>
        <Button text={"확인"} onClick={onConfirm} size="small" height="44px" />
      </ModalBody>
    </Modal>
  );
};

export default ApplicationForAdmission;
