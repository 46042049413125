import React from "react";
import { IconType } from "../../type/icon_type";

interface IconInfoProps extends IconType {
    color?: string;
}

const IconInfo = ({width="24px", height="24px", color = "#73787E"}: IconInfoProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 8.4V12M12 15.6H12.009M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke={color} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export default IconInfo;