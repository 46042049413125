import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import { Store } from "react-notifications-component";
import { Page_settings } from "../../../../config/page_settings";
import { dateForm, getDecimal } from "../../../../helper/common";
import strings from "../../../../lang/strings";
const DataLabModalReport = (props) => {
  const [periodOpen, setPeriodOpen] = useState(false);
  const context = useContext(Page_settings);
  const [period, setPeriod] = useState(-1);
  const [yearSelectOpen, setYearSelectOpen] = useState(false);
  const [yearList, setYearList] = useState([]);
  const [year, setYear] = useState(
    yearList.indexOf(moment(new Date()).year().toString() + strings._y)
  );
  const [monthList, setMonthList] = useState([]);
  const [month, setMonth] = useState(-1);
  const [dayList, setDayList] = useState([]);
  const [day, setDay] = useState(-1);
  const [goalAchieve, setGoalAchieve] = useState(0);
  const [goalTotal, setGoalTotal] = useState(0);
  const monthLine = [
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월",
  ];
  const [planGraphData, setPlanGraphData] = useState([]);

  const userId = props.member;
  const class_id = props.class_id;

  //클래스 관련
  const [missionData, setMissionData] = useState();
  const [missionAchieve, setMissionAchieve] = useState(0);

  // 시험데이터 관련
  const [testData, setTestData] = useState();

  const Chart = {
    type: "bar",
    data: {
      // labels: period == 2 ? monthLine : (period == 1 ? weekLine : timeLine),
      labels: monthLine,
      datasets: [
        {
          data: planGraphData, // 데이터 배열
          backgroundColor: (context) => {
            const chart = context.chart;
            const { ctx, chartArea } = chart;

            if (!chartArea) {
              // This case happens on initial chart load
              return null;
            }
            const gradient = ctx.createLinearGradient(
              0,
              chartArea.top,
              0,
              chartArea.bottom
            );
            gradient.addColorStop(0, "rgba(20, 109, 226, 1)");
            gradient.addColorStop(1, "rgba(11, 60, 125, 1)");
            return gradient;
          },
          border: "unset",
          borderWidth: 1,
          borderRadius: 5, // 둥근 모서리 설정
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: false, // 범례 숨기기
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return `${context.dataset.label}: ${context.raw}`; // 툴팁 포맷
            },
          },
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          min: 0, // Y축 최소값 설정
          max: 100, // Y축 최대값 설정
          ticks: {
            stepSize: 25, // Y축 눈금 간격
            callback: function (value) {
              if (
                value === 100 ||
                value === 75 ||
                value === 50 ||
                value === 25 ||
                value === 0
              ) {
                return value; // 100, 75, 50, 25, 0 값만 표시
              }
            },
            color: "#333", // Y축 눈금 텍스트 색상
          },
          grid: {
            color: "#fff", // Y축 그리드 라인 색상
            lineWidth: 3, // Y축 그리드 라인의 두께를 3으로 설정
            borderWidth: 0,
          },
        },
        x: {
          ticks: {
            color: "#333", // X축 눈금 텍스트 색상
          },
          grid: {
            display: false, // X축 그리드 라인 숨기기
            borderColor: "#000",
            borderWidth: 2,
          },
        },
      },
      layout: {
        padding: {
          top: 30,
          right: 0,
          bottom: 0,
          left: 20,
        },
      },
    },
  };

  const goalChart = {
    data: {
      datasets: [
        {
          data: [missionAchieve, 100 - missionAchieve],
          backgroundColor: ["#146DE3", "#dadada"],
          borderColor: ["#146DE3", "#dadada"],
          borderWidth: 2,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: true,
      cutout: "70%",
      rotation: 270,
      circumference: 180,
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
        labels: false,
      },
    },
  };

  useEffect(() => {
    context.setHeaderType(2);
    context.handleSetPlannerPageHeader(true);
    getYearList();
    getMonthList();
    getDayList(moment(new Date()).daysInMonth());
    return () => {
      context.setHeaderType(1);
      context.handleSetPlannerPageHeader(false);
    };
  }, []);

  useEffect(() => {
    getPlannerData("MONTH", "2024-01-01", "2024-12-31");
    getMissionData("2024-01-01", "2024-12-31");
    getTestData("2024-01-01", "2024-12-31");
  }, []);

  const getChartData = (mode, from, to) => {
    context.get(
      "datalab/getChartData",
      {
        userId: userId,
        mode: mode,
        from: moment(from).format("YYYY-MM-DD"),
        to: moment(to).format("YYYY-MM-DD"),
      },
      (response) => {
        console.log("dd", response);
        let total = [];
        let real = [];
        let totalSum = 0;
        let realSum = 0;
        response.list.forEach((item) => {
          total.push(parseInt(item.total_diff));
          totalSum += parseInt(item.total_diff);
          real.push(parseInt(item.real_diff));
          realSum += parseInt(item.real_diff);
        });
      }
    );
  };

  const getPlannerData = (mode, from, to) => {
    context.get(
      "datalab/getPlannerData",
      {
        userId: userId,
        mode: mode,
        from: moment(from).format("YYYY-MM-DD"),
        to: moment(to).format("YYYY-MM-DD"),
      },
      (response) => {
        setGoalTotal(response.todo.total_count);

        let total = [];
        response.graph.forEach((item) => {
          let completeCount = parseInt(item.complete_count);
          let totalCount = parseInt(item.total_count);

          if (totalCount === 0) {
            total.push(0); // totalCount가 0일 때 0을 추가합니다.
          } else {
            total.push(Math.round((completeCount / totalCount) * 100));
          }
        });
        setPlanGraphData(total);
        console.log("total", total);

        if (response.todo.total_count != 0) {
          setGoalAchieve(
            Math.round(
              (parseInt(response.todo.complete_count) /
                parseInt(response.todo.total_count)) *
                100
            )
          );
        } else {
          setGoalAchieve(0);
        }
      }
    );
  };

  const getMissionData = (from, to) => {
    context.get(
      "datalab/getMissionData",
      {
        member: userId,
        class_id: class_id,
        from: moment(from).format("YYYY-MM-DD"),
        to: moment(to).format("YYYY-MM-DD"),
      },
      (response) => {
        console.log("getMissionData", response);
        setMissionData(response);
        setMissionAchieve(response.pass_rate);
      }
    );
  };

  const getTestData = (from, to) => {
    context.get(
      "datalab/getTestData",
      {
        class_id: class_id,
        user_id: userId,
        from: moment(from).format("YYYY-MM-DD"),
        to: moment(to).format("YYYY-MM-DD"),
      },
      (response) => {
        console.log("testData", response);
        setTestData(response);
      }
    );
  };

  useEffect(() => {
    if (period == 2) {
      const from = yearList[year].split("년")[0] + "-" + "01" + "-" + "01";
      const to = yearList[year].split("년")[0] + "-" + "12" + "-" + "31";
      getChartData(
        "MONTH",
        yearList[year].split("년")[0] + "-" + "01" + "-" + "01",
        yearList[year].split("년")[0] + "-" + "12" + "-" + "31"
      );
      getPlannerData("MONTH", from, to);
      getMissionData(from, to);
      getTestData(from, to);
    } else if (period == 1) {
      if (day > dayList.length - 1) {
        setDay(0);
      }
      if (year != -1) {
        getDayFromWeek(
          yearList[year].split("년")[0] + "." + monthList[month].split("월")[0]
        );
      }
    } else if (period == 0) {
      if (dayList.length < 10) {
        getDayList(
          moment(
            yearList[year].split("년")[0] +
              "." +
              monthList[month].split("월")[0] +
              ".01"
          ).daysInMonth()
        );
      }
      getChartData(
        "MONTH",
        yearList[year].split("년")[0] +
          "-" +
          monthList[month].split("월")[0] +
          "-" +
          (dayList.length < 10 ? "01" : dayList[day].split("일")[0]),
        yearList[year].split("년")[0] +
          "-" +
          monthList[month].split("월")[0] +
          "-" +
          (dayList.length < 10 ? "01" : dayList[day].split("일")[0])
      );
      const from =
        yearList[year].split("년")[0] +
        "-" +
        monthList[month].split("월")[0] +
        "-" +
        (dayList.length < 10 ? "01" : dayList[day].split("일")[0]);
      const to =
        yearList[year].split("년")[0] +
        "-" +
        monthList[month].split("월")[0] +
        "-" +
        (dayList.length < 10 ? "01" : dayList[day].split("일")[0]);
      getPlannerData(from, to);
      getMissionData(from, to);
      getTestData(from, to);
    }
  }, [period, year, month, day, dayList]);

  useEffect(() => {
    if (period == 1) {
      setDayList(strings.weekList);
      return;
    }
    if (year != -1 && month != -1) {
      getDayList(
        moment(
          yearList[year].split("년")[0] +
            "." +
            monthList[month].split("월")[0] +
            ".01"
        ).daysInMonth()
      );
      // setDay(0)
    }
  }, [year, month]);

  const getYearList = () => {
    let now = moment(new Date()).year();
    let list = [];
    for (let i = now; i < now + 1; i++) {
      list.push(i.toString() + strings._y);
    }
    setYearList(list);
    setYear(list.indexOf(moment(new Date()).year().toString() + strings._y));
  };

  const getMonthList = () => {
    let list = [];
    let month = "";
    for (let i = 1; i < 13; i++) {
      month = dateForm(i) + strings._m;
      list.push(month);
    }
    setMonthList(list);
  };

  const getDayList = (days) => {
    let list = [];
    let day = "";
    for (let i = 1; i <= days; i++) {
      day = dateForm(i) + strings._d;
      list.push(day);
    }
    setDayList(list);
  };

  const getDayFromWeek = (momentObj) => {
    let obj = moment(momentObj),
      first,
      last,
      weekCount,
      weekStart,
      weekEnd,
      lastDay;
    console.log("obj", obj);
    first = obj.startOf("month").week();
    console.log("first", first);
    last = obj.endOf("month").week();
    console.log("last", last);
    lastDay = obj.endOf("month").day();
    console.log("lastDay", lastDay);
    if (last < first) {
      weekCount = 52 + first - last;
    } else {
      weekCount = last - first + 1;
    }
    if (weekCount < 5 || lastDay < 6) {
      setDayList(strings.weekList);
    } else {
      setDayList(strings.weekList5);
    }
    weekStart = moment(
      moment(
        yearList[year].split("년")[0] + "." + (first + day).toString(),
        "YYYY.ww"
      )
        .startOf("week")
        .toDate()
    ).format("YYYY-MM-DD");
    weekEnd = moment(
      moment(
        yearList[year].split("년")[0] + "." + (first + day).toString(),
        "YYYY.ww"
      )
        .endOf("week")
        .toDate()
    ).format("YYYY-MM-DD");
    getChartData("DAY", weekStart, weekEnd);
    getPlannerData(weekStart, weekEnd);
    getMissionData(weekStart, weekEnd);
    getTestData(weekStart, weekEnd);
  };

  const togglePeriod = () => {
    setPeriodOpen(!periodOpen);
  };

  const toggleYear = () => {
    setYearSelectOpen(!yearSelectOpen);
  };

  const periodFix = () => {
    if (period == -1) {
      // setPeriod(0);
      setPeriod(2);
    }
    if (year == -1) {
      setYear(
        yearList.indexOf(moment(new Date()).year().toString() + strings._y)
      );
    }
    if (month == -1) {
      let mm = moment(new Date()).month() + 1;
      mm = dateForm(mm) + strings._m;
      setMonth(monthList.indexOf(mm));
    }
    if (day == -1) {
      setDay(dayList.indexOf(dateForm(moment(new Date()).date()) + strings._d));
    }
  };

  const vaildDate = (yearIdx, monthIdx, dayIdx) => {
    const yIdx =
      yearIdx == -1
        ? yearList.indexOf(moment(new Date()).year().toString() + strings._y)
        : yearIdx;
    const mIdx =
      monthIdx == -1
        ? monthList.indexOf(
            dateForm(moment(new Date()).month() + 1) + strings._m
          )
        : monthIdx;
    const dIdx =
      dayIdx == -1
        ? dayList.indexOf(dateForm(moment(new Date()).date()) + strings._d)
        : dayIdx;

    if (yIdx == undefined || mIdx == undefined || dIdx == undefined) {
      return false;
    }
    const dateStr =
      yearList[yIdx].split("년")[0] +
      "-" +
      monthList[mIdx].split("월")[0] +
      "-" +
      dayList[dIdx].split("일")[0];
    var date = moment(dateStr);
    return period == 1 ? true : date.isValid();
  };

  const addNotification = (
    notificationType,
    notificationTitle,
    notificationMessage
  ) => {
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: "top-right",
      dismiss: {
        duration: 1000,
      },
    });
  };

  return (
    <div className="row" style={{ gap: "30px", height: "100%" }}>
      <div
        class="left"
        style={{
          flex: "1",
          display: "flex",
          flexDirection: "column",
          gap: "30px",
          paddingRight: "unset",
        }}
      >
        <div
          className="col-xl-7 d-flex p-0 m-t-15"
          style={{ maxWidth: "100%", gap: "30px", flex: "1" }}
        >
          <div
            className="goal-achieve m-l-10"
            style={{
              marginRight: "unset",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <div className="flex-box">
              <div className="f-s-24 f-w-700">과제</div>
              <div className="report-mission-time">
                {moment(moment.now()).format("YYYY/MM/DD hh:mm")}
              </div>
            </div>
            <div
              className="d-flex width-100-percent justify-content-center align-bottom"
              style={{ flex: "10" }}
            >
              <div className="doughnut-container">
                <div className="d-flex align-item-center goal-chart">
                  <div
                    className="goal-hint report-percent p-b-10"
                    style={{ position: "absolute", bottom: "0%", left: "4%" }}
                  >
                    0%
                  </div>
                  <Doughnut data={goalChart.data} options={goalChart.options} />
                  <div
                    className="goal-hint report-percent p-b-10"
                    style={{
                      position: "absolute",
                      bottom: "12%",
                      right: "4%",
                    }}
                  >
                    100%
                  </div>
                </div>
                <div className="report-chart-label">
                  <div className="f-s-35 f-w-600">
                    {getDecimal(missionAchieve.toString())}
                  </div>
                  <div className="achieve-number f-s-20">%</div>
                </div>
                <div className="report-chart-label">
                  <div className="goal-hint">
                    {missionData?.pass_count.toString() +
                      "/" +
                      missionData?.total_count.toString()}
                  </div>
                </div>
              </div>
            </div>
            <div className="mission-data-container">
              <div className="box approve">
                <div className="left">
                  <div className="title-box">
                    <div className="status-dot"></div>
                    <div className="status-title">승인된 과제 수</div>
                  </div>
                </div>
                <div className="right">
                  {missionData?.pass_count ? missionData?.pass_count : "0"}건
                </div>
              </div>
              <div className="box not-submit">
                <div className="left">
                  <div className="title-box">
                    <div className="status-dot"></div>
                    <div className="status-title">미제출 과제 수</div>
                  </div>
                </div>
                <div className="right">
                  {missionData?.none_count ? missionData?.none_count : "0"}건
                </div>
              </div>
              <div className="box companion">
                <div className="left">
                  <div className="title-box">
                    <div className="status-dot"></div>
                    <div className="status-title">반려된 과제 수</div>
                  </div>
                </div>
                <div className="right">
                  {missionData?.reject_count ? missionData?.reject_count : "0"}
                  건
                </div>
              </div>
            </div>
          </div>
          <div className="goal-achieve test" style={{ marginRight: "unset" }}>
            <div className="f-s-24 f-w-700">시험</div>
            <div className="data-container">
              <div className="data-box">
                <div className="title">총 시험 횟수</div>
                <div className="data-content">
                  <span className="highlight blue-font">{testData?.count}</span>{" "}
                  건
                </div>
              </div>

              <div className="data-box">
                <div className="title">시험 평균점수</div>
                <div className="data-content">
                  <span className="highlight">
                    {testData?.average_score?.toFixed(2)}
                  </span>{" "}
                  점
                </div>
              </div>
              <div className="data-box">
                <div className="title">객관식 평균 정답률</div>
                <div className="data-content">
                  <span className="highlight">
                    {testData?.multiple_choice_percent !== null
                      ? testData?.multiple_choice_percent.toFixed(2)
                      : "0"}
                  </span>{" "}
                  %
                </div>
              </div>
              <div className="data-box">
                <div className="title">주관식 평균 정답률</div>
                <div className="data-content">
                  <span className="highlight">
                    {testData?.essay_percent !== null
                      ? testData?.essay_percent.toFixed(2)
                      : "0"}
                  </span>{" "}
                  %
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="goal-achieve plan"
          style={{ width: "100%", height: "100%", flex: "1" }}
        >
          <div className="d-flex align-item-center">
            <div className="f-s-24 f-w-700">플랜</div>
          </div>
          <div className="data-container">
            <div className="left">
              <div className="report-line-chart">
                <Bar data={Chart.data} options={Chart.options} />
                <div className="report-chart-y">(단위 : %)</div>
              </div>
            </div>
            <div className="right">
              <div className="data-box">
                <div className="title">달성률</div>
                <div className="data-content">
                  <span className="highlight blue-font">{goalAchieve}</span> %
                </div>
              </div>

              <div className="data-box">
                <div className="title">플랜 개수</div>
                <div className="data-content">
                  <span className="highlight">{goalTotal}</span> 개
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-5 d-flex p-0 m-t-15">
          <div
            className="goal-achieve position-relative"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="d-flex align-item-center">
              <div className="f-s-24 f-w-700 m-r-auto text-center">
                AI 종합 레포트
              </div>
            </div>
            <div className="predicted-coming" style={{ margin: "auto" }}>
              <div>
                <img src={"/assets/image/img_digging.png"} alt="" />
                <div className="m-t-25">{strings.coming_soon}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DataLabModalReport;
