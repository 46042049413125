import React, { useContext, useEffect, useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from "styled-components";
import strings from "../../../lang/strings";

import { Page_settings } from '../../../config/page_settings';
import { rootStore } from "../../../mobx/store";
import { API_URL } from "../../../config/const";
import axios from "axios";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import moment from "moment";
import PerfectScrollbar from 'react-perfect-scrollbar';

function DeleteCurriculumModal(props) {
  useEffect(() => {
  }, []);

  const context = useContext(Page_settings);
  const handleClose = () => {
    props.onClose?.();
  };


  const deleteCurriculum = () => {
    context.get(
      'curriculum/deleteCurriculum',
      {  
        curriculum: props.id
      },
      response => {
        handleClose()
      }
    );
  }


  return (
    <>

      <Overlay>
        <ModalWrap>
          <div className='curriculum-warning-modal'>
            <div className='title'>삭제</div>
            <div className='warning-comment'>정말로 커리큘럼을 삭제하시겠습니까?</div>
            <div className="container-bottom">
          <button className="no-btn" onClick={()=> handleClose()} >아니요</button>
          <button className="yes-btn" onClick={() => deleteCurriculum()} >네</button>
        </div>
          </div>
        </ModalWrap>
      </Overlay>
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const ModalWrap = styled.div`
  width: 320px;
  height: 160px;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
`;
const Button = styled.button`
cursor: pointer;
width: 100%;
color: #ffffff;
border-radius: 5px;
border:0px;
padding: 15px;
display:flex;
align-items: center;
justify-content:center;
font-size:16px;
gap:5px;
`;


export default DeleteCurriculumModal;