import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { withRouter } from "react-router-dom";
import PaymentSearch from "../../components/control/custom_input/payment_search";
import { Page_settings } from "../../config/page_settings";
import { rootStore } from "../../mobx/store";
import PaymentHeader from "./header/payment_header";
import BillCancelModal from "./modal/bill_cancel_modal";
import BillDetailModal from "./modal/bill_detail_modal";
import ConfirmModal from "./modal/confirm_modal";
import { set } from "mobx";

const Shipment = observer((props) => {
  const [checkboxes, setCheckboxes] = useState([]);
  const context = useContext(Page_settings);
  const [billList, setBillList] = useState([]);
  const [totalAmount, setTotalAmount] = useState({});
  const [choiceBill, setChoiceBill] = useState([]);
  const [detailBill, setDetailBill] = useState(); // 우측 끝에 ... 클릭한 청구서
  const [count, setCount] = useState(0);
  const [selectAll, setSelectAll] = useState(false); // 전체 선택 체크박스
  const [toggleMenu, setToggleMenu] = useState(-1);
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectCategory, setSelectCategory] = useState(false);
  const [category, setCategory] = useState("all");
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [resendStatus, setResendStatus] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [cancelStatus, setCancelStatus] = useState(false);
  const { setMode } = props;

  const [paymentToken, setPaymentToken] = useState(
    localStorage.getItem("payment_token")
      ? JSON.parse(localStorage.getItem("payment_token")).token
      : null
  );
  const [tokenTime, setTokenTime] = useState(
    localStorage.getItem("payment_token")
      ? JSON.parse(localStorage.getItem("payment_token")).time
      : null
  );

  useEffect(() => {
    return () => {
      context.handleSetPageHeader(true);
      context.handleSetPageSidebar(true);
    };
  }, []);

  useEffect(() => {
    getBillList();
  }, [category, deleteStatus, resendStatus, context.billSendModal, cancelStatus]);

  const tokenVerification = (tokenTime) => {
    // 현재 시각을 밀리초 단위로 얻음
    const currentTime = Date.now();

    // 주어진 시간 값과 현재 시각의 차이를 계산
    const difference = currentTime - tokenTime;

    // 차이가 지정된 시간 이상이면 true, 아니면 false 반환
    return difference >= 3600000;
  };

  // 메뉴버튼, 다른곳 클릭시 사라짐
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target.closest(".menu") === null) {
        setToggleMenu(-1);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleChange = (index) => {
    const newCheckboxes = [...checkboxes];

    const selectBill = [...billList];

    newCheckboxes[index] = !newCheckboxes[index];

    if (newCheckboxes[index]) {
      // 체크박스가 선택될 경우, 배열에 데이터를 추가합니다.
      setChoiceBill([...choiceBill, selectBill[index]]);
    } else {
      // 체크박스가 해제될 경우, 배열에서 해당 데이터를 제거합니다.
      const removedItem = selectBill[index];
      setChoiceBill(choiceBill.filter((item) => item.id !== removedItem.id));
    }

    setCheckboxes(newCheckboxes);

    const newCount = newCheckboxes.filter((checkbox) => checkbox).length;
    setCount(newCount);

    // 모든 체크박스가 체크되어 있다면 전체 선택 체크박스도 체크하게 하고, 그렇지 않다면 해제합니다.
    setSelectAll(newCheckboxes.every((checkbox) => checkbox));
  };

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    setCheckboxes(checkboxes.map(() => !selectAll));
    setCount(!selectAll ? checkboxes.length : 0);
  };

  const handleMenuClick = (index, event) => {
    event.stopPropagation();

    const selectBill = [...billList];
    setDetailBill(selectBill[index]);

    setToggleMenu(index);
  };

  const getBillList = () => {
    // 토큰 유효성 검사 후, 만료된 경우 새 토큰 발행
    if (tokenVerification(tokenTime)) {
      context.post("payment/getToken", {}, (response) => {
        rootStore.setPaymentToken(response);
        setPaymentToken(response);
        setTokenTime(Date.now());
        context.post(
          "payment/getBillList",
          {
            token: response,
            category,
            keyword,
          },
          (response) => {
            setBillList(response.bills);
            setTotalAmount(response.totalAmount);
            setCheckboxes(new Array(response.bills.length).fill(false));
          }
        );
      });
    } else {
      context.post(
        "payment/getBillList",
        {
          token: paymentToken,
          category,
          keyword,
        },
        (response) => {
          setBillList(response.bills);
          setTotalAmount(response.totalAmount);
          setCheckboxes(new Array(response.bills.length).fill(false));
        }
      );
    }
  };

  const deleteBill = () => {
    console.log(detailBill);
    // 토큰 유효성 검사 후, 만료된 경우 새 토큰 발행
    if (tokenVerification(tokenTime)) {
      context.post("payment/getToken", {}, (response) => {
        rootStore.setPaymentToken(response);
        setPaymentToken(response);
        setTokenTime(Date.now());
        context.post(
          "payment/delete",
          {
            token: paymentToken,
            billID: detailBill.id,
          },
          (response) => {
            setDeleteStatus(!deleteStatus);
          }
        );
      });
    } else {
      context.post(
        "payment/delete",
        {
          token: paymentToken,
          billID: detailBill.id,
        },
        (response) => {
          setDeleteStatus(!deleteStatus);
        }
      );
    }
  };

  const onDetailModal = () => {
    setDetailModal(true);
  };

  return (
    <div class="main-content bill-management-container">
      {/* <CloudContainer/> */}
      {
        <PaymentSearch
          placeholder={"이름,휴대폰 번호"}
          inputType={"text"}
          value={keyword}
          onChange={(v) => {
            setKeyword(v);
          }}
          onSearch={() => {
            getBillList(keyword);
          }}
        />
      }

      <PaymentHeader/>

      {confirmModal && (
        <ConfirmModal
          open={confirmModal}
          onClose={() => {
            setConfirmModal(false);
          }}
          onResend={() => {
            setResendStatus(!resendStatus);
          }}
          count={count}
          choiceBill={choiceBill}
        />
      )}
      {detailModal && (
        <BillDetailModal
          open={detailModal}
          onClose={() => {
            setDetailModal(false);
          }}
          bill={detailBill}
          onDelete={() => {
            setDeleteStatus(!deleteStatus);
          }}
          onResend={() => {
            setResendStatus(!resendStatus);
          }}
        />
      )}

      {cancelModal && (
        <BillCancelModal
          open={cancelModal}
          onClose={() => {
            setCancelModal(false);
            setCancelStatus(!cancelStatus);
          }}
          bill={detailBill}
        />
      )}

      <div class="bill-management-box manage-shipment-storage-top">
        <div class="bill-management-header">
          <div class="left selected">발송</div>
          <div class="left" onClick={() => setMode(1)}>
            수납
          </div>
        </div>
        <div class="top-content-box">
          <div class="cell">
            <div class="cell-header">발송</div>
            <div class="cell-middle">
              {totalAmount?.TOTAL?.count ? totalAmount.TOTAL.count : "0"}건
            </div>
            <div class="cell-bottom">
              {totalAmount?.TOTAL?.total ? totalAmount.TOTAL.total : "0"}원
            </div>
          </div>
          <div class="cell">
            <div class="cell-header">미납</div>
            <div class="cell-middle">
              {totalAmount?.PENDING?.count ? totalAmount.PENDING.count : "0"}건
            </div>
            <div class="cell-bottom">
              {totalAmount?.PENDING?.total ? totalAmount.PENDING.total : "0"}원
            </div>
          </div>
          <div class="cell">
            <div class="cell-header">수납</div>
            <div class="cell-middle">
              {totalAmount?.DONE?.count ? totalAmount.DONE.count : "0"}건
            </div>
            <div class="cell-bottom">
              {totalAmount?.DONE?.total ? totalAmount.DONE.total : "0"}원
            </div>
          </div>
          <div class="cell">
            <div class="cell-header">취소</div>
            <div class="cell-middle">
              {totalAmount?.CANCELED?.count ? totalAmount.CANCELED.count : "0"}
              건
            </div>
            <div class="cell-bottom">
              {totalAmount?.CANCELED?.total ? totalAmount.CANCELED.total : "0"}
              원
            </div>
          </div>
          <div class="cell"></div>
        </div>
      </div>

      <div class="bill-management-box middle-height">
        <div class="bill-grid-container manage-shipment-grid">
          <input
            type="checkbox"
            class="all-check"
            checked={selectAll}
            onChange={handleSelectAllChange}
          />
          <div
            class="category"
            onClick={() => setSelectCategory(!selectCategory)}
          >
            <div class="flex">
              {category === "PENDING"
                ? "미납"
                : category === "DONE"
                ? "수납"
                : category === "CANCELED"
                ? "취소"
                : "전체"}
              <img
                class={selectCategory ? "reverse" : ""}
                src="/assets/image/icon_arrow_down_gray.png"
                alt=""
              />
            </div>
            {selectCategory && (
              <div className="category-box">
                <button onClick={() => setCategory("ALL")}>전체</button>
                <button onClick={() => setCategory("PENDING")}>미납</button>
                <button onClick={() => setCategory("DONE")}>수납</button>
              </div>
            )}
          </div>

          <div>받는사람</div>
          <div style={{ textAlign: "end" }}>청구금액</div>
          <div>청구날짜</div>
          <div>수납날짜</div>
          <div>청구사유</div>
          <div>안내 메시지</div>
          {/* <div>재발송</div> */}
        </div>
        <PerfectScrollbar
          style={{ height: `82%` }}
          options={{ suppressScrollX: true }}
        >
          {billList.length > 0 &&
            billList.map((item, index) => (
              <div
                class={`grid-container bill-grid-6-columns manage-shipment-content-grid ${
                  checkboxes[index] ? "checked-bill" : ""
                }`}
              >
                <input
                  type="checkbox"
                  class="row-check"
                  checked={checkboxes[index]}
                  onChange={() => handleChange(index)}
                />
                <button class={`status-btn ${item.status}`}>
                  {item.status === "PENDING"
                    ? "미납"
                    : item.status === "DONE"
                    ? "수납"
                    : "취소"}
                </button>
                <div class="padding-left">
                  <div class="name">{item.receiver.name}</div>
                  <div class="phone-num">{item.receiver.phone}</div>
                </div>
                <div class="billing-amount">{item.totalAmount}</div>
                <div class="billing-reason">
                  <div class="date">{item.createdDate}</div>
                  <div class="time">{item.createdTime}</div>
                </div>
                {item.status === "DONE" ? (
                  <div class="billing-reason">
                    <div class="date">{item.doneDate}</div>
                    <div class="time">{item.doneTime}</div>
                  </div>
                ) : (
                  <div class="billing-reason">
                    <div class="date"></div>
                    <div class="time"></div>
                  </div>
                )}

                <div class="billing-reason no-wrap">{item.reason}</div>
                <div class="message no-wrap">{item.message}</div>
                {/* <div class="re-send">0건</div> */}
                <div
                  class="menu"
                  onClick={(event) => handleMenuClick(index, event)}
                >
                  <img src="/assets/image/icon_3dots.png" alt="" />
                  {toggleMenu === index && (
                    <div className="del-box">
                      <button onClick={() => onDetailModal()} className="">
                        상세보기
                      </button>
                      {item.status === "PENDING" ?  (
                        <button
                          onClick={() => deleteBill()}
                          className="del-btn"
                        >
                          삭제
                        </button>
                      ): null}
                    </div>
                  )}
                </div>
              </div>
            ))}
        </PerfectScrollbar>

        {count > 0 && (
          <div className="bottom-menu">
            <div class="left"></div>
            <div class="right" onClick={() => setConfirmModal(true)}>
              <span>{count}건 </span>
              재발송
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default withRouter(Shipment);
