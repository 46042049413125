import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { Doughnut, Line } from "react-chartjs-2";
import { withRouter } from "react-router-dom";
import PaymentMainButton from "../../components/control/button/payment_main_button";
import { Page_settings } from "../../config/page_settings";
import { getDecimal, getWeekOfMonth } from "../../helper/common";
import { rootStore } from "../../mobx/store";
import PaymentHeader from "./header/payment_header";
import SweetAlert from "react-bootstrap-sweetalert";

const Main = observer((props) => {
  const context = useContext(Page_settings);
  const [saleOfMonth, setSaleOfMonth] = useState();
  const [todayMonth, setTodayMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [ratio, setRatio] = useState(0);
  const [doneCount, setDoneCount] = useState(0);
  const [doneAmount, setDoneAmount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [pendingAmount, setPendingAmount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [billList, setBillList] = useState([]);

  const [seriesTotal, setSeriesTotal] = useState([]);
  const [seriesReal, setSeriesReal] = useState([]);

  const lineChart = {
    data: {
      labels: getWeekOfMonth(2024, 4),
      datasets: [
        {
          label: "수납",
          borderColor: "#0f84f4",
          backgroundColor: "#0f84f4",
          pointRadius: 1,
          data: seriesReal,
          pointHoverBackgroundColor: "rgba(231,243,254,0.3)",
          pointHoverRadius: 10,
          fill: false,
        },
        {
          label: "발송",
          borderColor: "#A0A3AB",
          backgroundColor: "#A0A3AB",
          pointRadius: 1,
          data: seriesTotal,
          pointHoverBackgroundColor: "rgba(230,250,243,0.3)",
          pointHoverRadius: 10,
          fill: false,
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          position: "bottom",
          display: true,
          labels: {
            font: {
              size: 15,
            },
            boxWidth: 11,
            boxHeight: 2,
            textAlign: "center",
          },
        },
        tooltip: {
          enabled: true,
          position: "nearest",
        },
      },
      interaction: {
        mode: "nearest",
        intersect: false,
        axis: "x",
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          min: 0,
          grid: {
            display: false,
          },
          ticks: {},
        },
        x: {
          grid: {
            display: false,
          },
        },
      },
    },
  };
  const [paymentToken, setPaymentToken] = useState(
    localStorage.getItem("payment_token")
      ? JSON.parse(localStorage.getItem("payment_token")).token
      : null
  );
  const [tokenTime, setTokenTime] = useState(
    localStorage.getItem("payment_token")
      ? JSON.parse(localStorage.getItem("payment_token")).time
      : null
  );

  const tokenVerification = (tokenTime) => {
    // 현재 시각을 밀리초 단위로 얻음
    const currentTime = Date.now();

    // 주어진 시간 값과 현재 시각의 차이를 계산
    const difference = currentTime - tokenTime;

    // 차이가 지정된 시간 이상이면 true, 아니면 false 반환
    return difference >= 3600000;
  };

  useEffect(() => {
    context.handleSetPageHeader(false);
    getChartData();
    getStorageOfMonth(currentYear, currentMonth);
    getRecentBill();

    return () => {
      context.handleSetPageHeader(true);
      context.handleSetPageSidebar(true);
    };
  }, []);

  const getChartData = () => {
    if (tokenVerification(tokenTime)) {
      context.post("payment/getToken", {}, (response) => {
        if (!response) {
          return;
        }
        rootStore.setPaymentToken(response);
        context.post(
          "payment/getChartData",
          {
            token: response,
            year: currentYear,
            month: currentMonth,
          },
          (response) => {
            if (!response) {
              return;
            }
            let total = [];
            let real = [];

            response.currentChartData?.forEach((item) => {
              total.push(parseInt(item.total_diff));
              // totalSum += parseInt(item.total_diff);
              real.push(parseInt(item.real_diff));
              // realSum += parseInt(item.real_diff);
            });
            setSeriesTotal(total);
            setSeriesReal(real);
            setSaleOfMonth(response.saleOfMonth?.toLocaleString());
          }
        );
      });
    } else {
      context.post(
        "payment/getChartData",
        {
          token: paymentToken,
          year: currentYear,
          month: currentMonth,
        },
        (response) => {
          if (!response) {
            return;
          }
          console.log("response: ", response);
          let total = [];
          let real = [];
          // let totalSum = 0;
          // let realSum = 0;
          response.chartData?.forEach((item) => {
            total.push(parseInt(item.total_diff));
            // totalSum += parseInt(item.total_diff);
            real.push(parseInt(item.real_diff));
            // realSum += parseInt(item.real_diff);
          });
          setSeriesTotal(total);
          setSeriesReal(real);
          setSaleOfMonth(response.saleOfMonth?.toLocaleString());
        }
      );
    }
  };

  const getStorageOfMonth = (currentYear, currentMonth) => {
    if (tokenVerification(tokenTime)) {
      context.post("payment/getToken", {}, (response) => {
        if (!response) {
          return;
        }

        rootStore.setPaymentToken(response);
        context.post(
          "payment/getStorageOfMonth",
          {
            token: response,
            year: currentYear,
            month: currentMonth,
          },
          (response) => {
            if (!response) {
              return;
            }
            setRatio(response.ratio === "NaN" ? 0 : response.ratio);
            setDoneCount(response.done.count);
            setDoneAmount(response.done.amount.toLocaleString());
            setPendingCount(response.pending.count);
            setPendingAmount(response.pending.amount.toLocaleString());
            setTotalCount(response.total.count);
          }
        );
      });
    } else {
      context.post(
        "payment/getStorageOfMonth",
        {
          token: paymentToken,
          year: currentYear,
          month: currentMonth,
        },
        (response) => {
          if (!response) {
            return;
          }
          setRatio(response.ratio === "NaN" ? 0 : response.ratio);
          setDoneCount(response.done.count);
          setDoneAmount(response.done.amount.toLocaleString());
          setPendingCount(response.pending.count);
          setPendingAmount(response.pending.amount.toLocaleString());
          setTotalCount(response.total.count);
        }
      );
    }
  };

  const nextClick = () => {
    if (currentMonth === 12) {
      setCurrentMonth(1); // 12월에서 다음 달로 가면 1월
      setCurrentYear(currentYear + 1); // 연도를 1 더함
      getStorageOfMonth(currentYear + 1, currentMonth);
    } else {
      setCurrentMonth(currentMonth + 1); // 다음 달로 이동
      getStorageOfMonth(currentYear, currentMonth + 1);
    }
    // getStorageOfMonth()
  };

  const previousClick = () => {
    if (currentMonth === 1) {
      setCurrentMonth(12); // 1월에서 이전 달로 가면 12월
      setCurrentYear(currentYear - 1); // 연도를 1 줄임
      getStorageOfMonth(currentYear - 1, currentMonth);
    } else {
      setCurrentMonth(currentMonth - 1); // 이전 달로 이동
      getStorageOfMonth(currentYear, currentMonth - 1);
    }
  };

  const goalChart = {
    data: {
      datasets: [
        {
          data: [ratio, 100 - ratio],
          backgroundColor: ["#0F84F4", "#dadada"],
          borderColor: ["#0F84F4", "#dadada"],
          borderWidth: 2,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: true,
      cutout: "70%",
      rotation: 270,
      circumference: 180,
      plugins: {
        legend: {
          display: false,
        },
      },
    },
  };

  const getRecentBill = () => {
    if (tokenVerification(tokenTime)) {
      context.post("payment/getToken", {}, (response) => {
        if (!response) {
          return;
        }

        rootStore.setPaymentToken(response);
        context.post(
          "payment/getRecentBill",
          {
            token: response,
          },
          (response) => {}
        );
      });
    } else {
      context.post(
        "payment/getRecentBill",
        {
          token: paymentToken,
        },
        (response) => {
          setBillList(response);
        }
      );
    }
  };

  console.log("paymentToken", paymentToken);

  return paymentToken != null ? (
    <div class="main-content payment-main-container ">
      {/* <CloudContainer/> */}
      <PaymentHeader />

      <div class="payment-row">
        <div class="payment-chart-box">
          <div class="report-btn-box">
            <div class="report-btn">
              매출보고서
              <img src={"/assets/image/icon_carousel_right_black.png"} alt="" />
            </div>
          </div>
          <div class="month-sales">{todayMonth}월 매출</div>
          <div class="price">{saleOfMonth ? saleOfMonth : 0}원</div>
          <div className="sale-line-chart">
            <Line
              data={lineChart.data}
              options={lineChart.options}
              style={{ height: "100%" }}
            />
            {/* <div
                    className="report-chart-x">{period == 2 ? strings.month : (period == 1 ? strings.week : strings.time)}</div> */}
            {/* <div className="report-chart-y">{strings.time}</div> */}
          </div>
        </div>
      </div>

      <div class="payment-row">
        <div class="acceptance-chart-box">
          <div class="box-header">
            <div class="left">월별 수납률</div>
            <div class="right">
              <PaymentMainButton
                // more={previousSelectStatus != 1}
                direction={"left"}
                onClick={() => previousClick()}
              />
              <div>
                {currentYear}년 {currentMonth}월
              </div>
              <PaymentMainButton
                // more={nextSelectStatus != 1}
                direction={"right"}
                onClick={() => nextClick()}
              />
            </div>
          </div>
          <div class="box-content">
            <div
              className="d-flex width-100-percent justify-content-center align-bottom"
              style={{ height: 150 }}
            >
              <div className="goal-hint report-percent p-b-10">0%</div>
              <div
                className="position-relative d-flex align-item-center justify-content-center"
                style={{ width: "70%" }}
              >
                <div className="d-flex align-item-center goal-chart">
                  <Doughnut
                    data={goalChart.data}
                    options={goalChart.options}
                    style={{ pointerEvents: "none" }}
                  />
                </div>
                <div className="report-chart-label">
                  <div className="f-s-45 f-w-600">
                    {getDecimal(ratio.toString())}
                  </div>
                  <div className="achieve-number f-s-45">%</div>
                </div>
                <div className="report-chart-label">
                  <div className="goal-hint">
                    {doneCount.toString() + "/" + totalCount.toString()}
                  </div>
                </div>
              </div>

              <div className="goal-hint report-percent p-b-10">100%</div>
            </div>
          </div>
          <div class="box-bottom">
            <div class="left">
              <div class="box-header">
                <div class="left">수납건수</div>
                <div class="right">{doneCount}건</div>
              </div>
              <div class="middle"></div>
              <div class="bottom">{doneAmount}원</div>
            </div>
            <div class="right">
              <div class="box-header">
                <div class="left">미납건수</div>
                <div class="right">{pendingCount}건</div>
              </div>
              <div class="middle"></div>
              <div class="bottom">{pendingAmount}원</div>
            </div>
          </div>
        </div>

        <div class="bill-container">
          <div class="box-header">
            <div class="left">청구서 관리</div>
            <div
              class="right"
              onClick={() => props.history.push("/payment/bill_management")}
            >
              <img src={"/assets/image/icon_carousel_right_black.png"} alt="" />
            </div>
          </div>
          <div class="bill-box">
            {billList?.map((item, index) => (
              <div class="content-box">
                <div class="box-content">
                  <div class="content-header">
                    <div className="date">{item.createdDate}</div>
                    <div className="time">{item.createdTime}</div>
                  </div>
                  <div class="bottom">
                    <div class="left">
                      <div class="name">{item.receiver.name}</div>
                      <div class="phone">{item.receiver.phone}</div>
                    </div>
                    <div class="right">
                      {item.totalAmount.toLocaleString()}원
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <SweetAlert
      showCancel={true}
      confirmBtnText={"설문지 제출로 이동"}
      confirmBtnBsStyle="primary"
      cancelBtnText={'뒤로 가기'}
      cancelBtnBsStyle="default"
      title={
        <div>
          <span>결제를 위한 사전 등록이 필요합니다</span>
        </div>
      }
      customClass="exam-save-alert"
      onConfirm={() => {
        window.location.href = "https://forms.gle/Psbf5hFtuLUGwupf7";
      }}
      onCancel={() => props.history.goBack()}
      confirmBtnCssClass="alert-confirm"
      cancelBtnCssClass="alert-cancel"
    />
  );
});

export default withRouter(Main);
