import React, { useContext, useEffect, useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from "styled-components";
import strings from "../../../lang/strings";

import { Page_settings } from '../../../config/page_settings';
import { rootStore } from "../../../mobx/store";
import { API_URL } from "../../../config/const";
import axios from "axios";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import moment from "moment";
import PerfectScrollbar from 'react-perfect-scrollbar';
import StudentWarningModal from './student_warning_modal';


function StudentCourseInfoModal(props) {

  const [profile, setProfile] = useState("");
  const color = props.partItem.color;
  const chapterItem = props.chapterItem;
  const sectionItem = props.sectionItem;
  const unitItem = props.unitItem;

  console.log("sectionItem: " + sectionItem)



  const handleClose = () => {
    props.onClose?.();
  };

  const history = useHistory();

  const context = useContext(Page_settings);
  const limit_time = [];

  useEffect(() => {
    loadStudyData();
    if (rootStore.getProfile != null) {
      setProfile(rootStore.getProfile.profile);
    }
  }, []);

  const openWarningModal = () =>{
    if(sectionItem?.test?.answer_data){
      props.clickMoveTest?.();
    }else{
      alert('테스트가 준비되지 않았습니다.')
    }
    
  }

  const moveProblemSolving = (id) => {
      history.push(`/curriculum/problem_solving/${id}`);
  }

  const loadStudyData = () => {
    context.get(
      'curriculum/getStudyData',
      {  
        section: sectionItem.id
      },
      response => {
       // 변환 과정
       if(response){
        response.forEach(row => {
          // 각 유닛 오브젝트를 찾아서 업데이트
          const unit = sectionItem.units.find(unit => unit.id === row.unit);
          if (unit) {
              unit.solve_count = row.solve_count;
              unit.watch_count = row.watch_count;
              unit.test_score = row.test_score;
              unit.result = row.result;
              unit.solve_met = row.solve_met;
              unit.watch_met = row.watch_met;
              unit.test_met = row.test_met;
          }

          console.log("unit",unit)
        });
       }
      }
    );
  }

  const playVideo = (unit) => {
      props.onView(unit)
      handleClose();
    
  }


  

  return (
    <>

      <Overlay>
        <ModalWrap>
          <div className='student-course-info-container'>
            <div className='modal-header'>
              <div className='title'>{chapterItem.name}</div>
              <img class="exit-btn cursor" onClick={handleClose} src="/assets/image/icon_close_gray.png" />
            </div>
            <div className='middle'>
              <div className='title'>{sectionItem.name}</div>
              <div className={`course-container ${color}`}>
              <PerfectScrollbar className="partCategory-container" style={{height: '300px', padding:'20px 20px 0px 0px'}} options={{ suppressScrollX: true }}>
              {sectionItem.units.map((unit,unitIdx)=> (
                <div className='course-row'>
                  <div className='number'>
                    <div className='number-box'>
                    {unitIdx + 1}
                    </div>
                    {sectionItem.units.length > 1 && unitIdx !== sectionItem.units.length - 1 && (
        <div className='course-index-arrow'>
          <img src='/assets/image/icon_arrow_down_gray.png' />
        </div>
      )}
                  </div>
                  <div className={`course-box ${unit.result === 1 ? color : ''}`}>
                    <>
                    <div className='title'>
                    <>{unit.name}</>
                    <div className='perfect-mark'>완료</div>
                    </div>
                    <div className='info-box'>
                    {
                      unit?.commentary_url ? 
                      <div className='info-row'>
                          <div className='left'>
                        문제 풀이 누적 수
                        </div>
                        <div className={`right ${unit.solve_met === 1 ? 'perfect' : ''}`}>
                          {unit.solve_count+'회'}
                        </div>
                      </div>
                      : null
                    }
                    {
                      unit?.video_url || unit?.link ? 
                      <div className='info-row'>
                      <div className='left '>
                      영상 공부
                      </div>
                      <div className={`right ${unit.watch_met === 1 ? 'perfect' : ''}`}>
                      {unit.watch_count+'회'}
                      </div>
                    </div>
                      : null
                    }                    
                  
                      {unit.test_score && unit.test_score !== null ? 
                          <div className='info-row'>
                          <div className='left '>
                          테스트 최고 점수
                          </div>
                          <div className={`right ${unit.test_met === 1 ? 'perfect' : ''}`}>
                          {unit.test_score}점
                          </div>
                        </div>
                      : null}
                  

                    </div>
                    <div className={`btn-container `}>
                      {
                      unit?.video_url || unit?.link ? 
                      <button className={`${unit?.watch_met === 1 ? '' : 'non-perfect'} ${color}`} onClick={()=> playVideo(unit)}>영상공부</button>
                      : null}
                      {
                        unit?.commentary_url ? 
                        <button className={`${unit?.solve_met === 1 ? '' : 'non-perfect'} ${color}`} onClick={()=> moveProblemSolving(unit.id)}>문제풀이</button>
                        : null
                      }
                      
                    </div>
                  </>
                  
                  </div>
                  
                </div>
                ))}
                {/* <img class="exit-btn cursor" src="/assets/image/icon_arrow_down_gray.png" /> */}
              </PerfectScrollbar>
              </div>
            </div>
          </div>
          <button className={`move-test ${color}`} onClick={()=> openWarningModal()}>
            <div className='left'>
              테스트 보러가기
            </div>
            <div className='right'>
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" viewBox="0 0 6 9" fill="none">
  <path d="M0 1.0575L3.7085 4.5L0 7.9425L1.1417 9L6 4.5L1.1417 0L0 1.0575Z" fill="#146DE3"/>
</svg>
            </div>
          </button>
        </ModalWrap>
      </Overlay>
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalWrap = styled.div`
  width: 680px;
  height: 500px;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  
`;



export default StudentCourseInfoModal;