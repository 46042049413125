
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import { withRouter } from "react-router-dom";
import PaymentSearch from '../../components/control/custom_input/payment_search';
import { Page_settings } from '../../config/page_settings';
import { rootStore } from '../../mobx/store';
import PaymentHeader from './header/payment_header';
import ConfirmModal from './modal/confirm_modal';
import { set } from 'mobx';

const Storage = observer((props) => {
  const [checkboxes, setCheckboxes] = useState([]);
  const context = useContext(Page_settings);
  
  const [count, setCount] = useState(0);
  const [selectAll, setSelectAll] = useState(false); // 전체 선택 체크박스
  const [billList,setBillList]  = useState([]);
  const [totalAmount, setTotalAmount] = useState();
  const [toggleMenu, setToggleMenu] = useState({});
  const [keyword,setKeyword] = useState('');
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectCategory, setSelectCategory] = useState(false);
  const [category, setCategory] = useState('all');
  const [selectStorageType, setSelectStorageType] = useState(false);
  const { setMode } = props;
  
  const [paymentToken, setPaymentToken] =  useState(localStorage.getItem('payment_token') ? JSON.parse(localStorage.getItem('payment_token')).token : null);
  const [tokenTime, setTokenTime] =  useState(localStorage.getItem('payment_token') ? JSON.parse(localStorage.getItem('payment_token')).time : null)

  useEffect(() => {
    return () => {
        context.handleSetPageHeader(true);
        context.handleSetPageSidebar(true);
    }
  }, []);

  useEffect(() => {
    getBillList();
  }, [category]);

    const tokenVerification = (tokenTime) => {
      // 현재 시각을 밀리초 단위로 얻음
    const currentTime = Date.now();
  
    // 주어진 시간 값과 현재 시각의 차이를 계산
    const difference = currentTime - tokenTime;
  
    // 차이가 지정된 시간 이상이면 true, 아니면 false 반환
    return difference >= 3600000;
    }


useEffect(() => {
  const handleOutsideClick = (event) => {
    if (event.target.closest('.menu') === null) {
      setToggleMenu(false);
    }
  };

  document.addEventListener('click', handleOutsideClick);

  return () => {
    document.removeEventListener('click', handleOutsideClick);
  };
}, []);

const getBillList = () => {
  // 토큰 유효성 검사 후, 만료된 경우 새 토큰 발행
  if (tokenVerification(tokenTime)) {
    context.post(
      'payment/getToken',
      {},
      response => {
          rootStore.setPaymentToken(response);
          setPaymentToken(response);
          setTokenTime(Date.now());
          context.post(
            'payment/getBillList',
            {
              token : response,
              mode: "storage",
              category,
              keyword
            },
            response => {
              setTotalAmount(response.totalAmount)
              setBillList(response.bills)
              setCheckboxes(new Array(response.bills.length).fill(false));
          }); 
      }
    )
  }else{
    context.post(
      'payment/getBillList',
      {
        token : paymentToken,
        mode: "storage",
        category,
        keyword
      },
      response => {
        
        setTotalAmount(response.totalAmount)
        setBillList(response.bills)
        setCheckboxes(new Array(response.bills.length).fill(false));
      }); 
  }
}

const handleChange = (index) => {
  const newCheckboxes = [...checkboxes];
  newCheckboxes[index] = !newCheckboxes[index];
  setCheckboxes(newCheckboxes);

  const newCount = newCheckboxes.filter((checkbox) => checkbox).length;
  setCount(newCount);

  // 모든 체크박스가 체크되어 있다면 전체 선택 체크박스도 체크하게 하고, 그렇지 않다면 해제합니다.
  setSelectAll(newCheckboxes.every((checkbox) => checkbox));
};

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    setCheckboxes(checkboxes.map(() => !selectAll));
    setCount(!selectAll ? checkboxes.length : 0);
  };
  
  const handleMenuClick = (index, event) => {
    event.stopPropagation();
    setToggleMenu((prevToggleMenu) => ({
      ...prevToggleMenu,
      [index]: !prevToggleMenu[index]
    }));
  };


  return (
    <div class="main-content bill-management-container">
      {/* <CloudContainer/> */}
      {
        <PaymentSearch
          placeholder={"이름,휴대폰 번호"}
          inputType={'text'}
          value={keyword}
          onChange={(v) => {
            setKeyword(v)
          }}
          onSearch={() => {
              getBillList(keyword);
          }}
        />

      }
      <PaymentHeader/>

        {confirmModal && (<ConfirmModal
          open={confirmModal}
          onClose={() => {
            setConfirmModal(false);
          }}
          count={count}
        />)}
<div class="bill-management-box manage-shipment-storage-top">
  <div class="bill-management-header">
    <div class="left" onClick={() => setMode(0)}>발송</div>
    <div class="left selected">수납</div>
  </div>
  <div class="top-content-box">
    <div class="cell">
      <div class="cell-header">전체</div>
      <div class="cell-middle">{totalAmount?.TOTAL?.count ? totalAmount.TOTAL.count : '0'}건</div>
      <div class="cell-bottom">{totalAmount?.TOTAL?.total ?totalAmount.TOTAL.total : '0'}원</div>
    </div>
    <div class="cell">
    <div class="cell-header">수납</div>
    <div class="cell-middle">{totalAmount?.DONE?.count ? totalAmount.DONE.count : '0'}건</div>
      <div class="cell-bottom">{totalAmount?.DONE?.total ? totalAmount.DONE.total : '0'}원</div>
    </div>
    <div class="cell">
      <div class="cell-header">취소</div>
      <div class="cell-middle">{totalAmount?.CANCELED?.count ? totalAmount.CANCELED.count : '0'}건</div>
      <div class="cell-bottom">{totalAmount?.CANCELED?.total ? totalAmount.CANCELED.total : '0'}원</div>
    </div>
    <div class="cell"></div>
  </div>
</div>

<div class="bill-management-box middle-height">
  <div class="bill-grid-container manage-storage-grid">
        <input type="checkbox" class="all-check" checked={selectAll} onChange={handleSelectAllChange}/>
        <div class="category" onClick={()=> setSelectCategory(!selectCategory)}>
          <div class="flex">{category === "DONE" ? '수납' : category === "CANCELED" ? '취소' : '전체'}
          <img class={selectCategory ? 'reverse' : ''} src='/assets/image/icon_arrow_down_gray.png' alt=''/>
          </div>
        {selectCategory && (
            <div className="category-box">
              <button onClick={() => setCategory("ALL")}>전체</button>
              <button onClick={() => setCategory("DONE")} >수납</button>
              <button onClick={() => setCategory("CANCELED")}>취소</button>
            </div>
          )}
        </div>
        <div>받는사람</div>
        <div style={{textAlign:'end'}}>청구금액</div>
        <div>수납날짜</div>
        <div>취소날짜</div>
        <div>청구사유</div>
        <div class="category" onClick={()=> setSelectStorageType(!selectStorageType)}>
          <div class="flex">수납유형
          <img class={selectStorageType ? 'reverse' : ''} src='/assets/image/icon_arrow_down_gray.png' alt=''/>
          </div>
        {selectStorageType && (
            <div className="category-box">
              <button onClick={() => console.log("삭제")}>전체</button>
              <button onClick={() => console.log("삭제")}>청구서</button>
              <button onClick={() => console.log("삭제")}>현장결제</button>
              
            </div>
          )}
        </div>
        <div>승인번호</div>
        <div>결제유형</div>
        
  </div>    
  <PerfectScrollbar style={{ height: `82%`}} options={{ suppressScrollX: true }}>
  {billList.length > 0 &&
      billList.map((item, index) =>
      <div class={`grid-container bill-grid-6-columns manage-storage-content-grid ${checkboxes[index] ? "checked-bill" : ""}`}>
      <input type="checkbox" class="row-check" checked={checkboxes[index]} onChange={() => handleChange(index)} />
      <button class={`status-btn ${item.status}`}>{item.status === "PENDING" ? "미납" : item.status === "DONE" ? "수납": "취소"}</button>
      <div class="padding-left"><div class="name">{item.receiver.name}</div><div class="phone-num">{item.receiver.phone}</div></div>
      <div class="billing-amount">{item.totalAmount}</div>
      <div class="billing-reason"><div class="date">{item.createdDate}</div>
      <div class="time">{item.createdTime}</div></div>
      {item.status === 'CANCELED' ?
      <div class="billing-reason"><div class="date">{item.cancelDate}</div>
      <div class="time">{item.cancelTime}</div></div>:
      <div class="billing-reason"><div class="date"></div>
      <div class="time"></div></div>
      }
      <div class="billing-reason no-wrap">{item.reason}</div>
      
      <div>청구서</div>
      <div>{item.catID}</div>
      <div>카드</div>
      </div>
  )}
  </PerfectScrollbar>
  
  {count > 0 && <div className="storage-bottom-menu">
    <div class="left">
      인쇄하기
    </div>
  </div>}    
</div>

      </div>
    
    
  );
})


export default withRouter(Storage);