import React from "react"
import { IconType } from "../../type/icon_type"

const IconPhone = ({ width="24px", height="24px", color="#111827" }: IconType) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M19.9995 16.7278V19.287C20.0004 19.5245 19.9517 19.7597 19.8563 19.9774C19.7609 20.1951 19.6211 20.3905 19.4457 20.5511C19.2702 20.7117 19.0632 20.834 18.8376 20.9101C18.6121 20.9862 18.3732 21.0145 18.1361 20.9931C15.5059 20.7078 12.9794 19.8109 10.7596 18.3742C8.69433 17.0644 6.94337 15.3169 5.63104 13.2558C4.18648 11.0303 3.28751 8.49657 3.00694 5.85976C2.98558 5.62386 3.01367 5.3861 3.08942 5.16163C3.16518 4.93716 3.28693 4.73089 3.44694 4.55596C3.60694 4.38102 3.80169 4.24125 4.01879 4.14555C4.23589 4.04985 4.47057 4.00031 4.7079 4.00008H7.27217C7.68698 3.99601 8.08913 4.14261 8.40366 4.41257C8.71818 4.68252 8.92362 5.05741 8.98168 5.46735C9.08991 6.28635 9.29063 7.09049 9.58001 7.86445C9.69501 8.16978 9.7199 8.50162 9.65173 8.82063C9.58355 9.13965 9.42518 9.43248 9.19537 9.66441L8.10983 10.7478C9.32662 12.8835 11.0984 14.6518 13.2384 15.8662L14.3239 14.7828C14.5563 14.5534 14.8497 14.3954 15.1694 14.3273C15.489 14.2593 15.8215 14.2841 16.1274 14.3989C16.9029 14.6877 17.7087 14.888 18.5293 14.9961C18.9445 15.0545 19.3237 15.2632 19.5948 15.5825C19.8658 15.9018 20.0099 16.3094 19.9995 16.7278Z" stroke={color} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export default IconPhone;