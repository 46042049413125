import React, { useContext, useEffect, useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from "styled-components";
import strings from "../../../lang/strings";
import StudentSearch from "../customSearch/studentSearch";
import { Page_settings } from '../../../config/page_settings';
import { rootStore } from "../../../mobx/store";
import Form from 'react-bootstrap/Form';
import CalendarModal from './calendarModal';
import {API_URL} from "../../../config/const";
import axios from "axios";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";

function NotificationAddModal({ onClose,class_id}) {
  const [wholeTextArray, setWholeTextArray] = useState([]);
  const [studentValue, setStudentValue] = useState('')
  const [titleValue, setTitleValue] = useState('')
  const [contentValue, setContentValue] = useState('')
  const imageRef = useRef();
  const [selectImg, setSelectImg] = useState(false);
  const [getThumbnail, setGetThumbnail] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [url, setUrl] = useState([]);
  const [profile, setProfile] = useState("");
  const [dateValue, setDateValue] = useState(moment().format('YYYY-MM-DD'))
  const [dropdownStatus, setDropdownStatus] = useState(false);
  const [limitTime, setLimitTime] = useState('00:00');
  const [openCalendar, setOpenCalendar] = useState(false);
  const [reservStatus, setReservStauts]  = useState(1);
  const [clickLimitTime,setClickLimitTime] = useState(false);

  const fileTypes = [
    "image/bmp",
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/tiff",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "text/htm",
    "text/html",
    "application/pdf",
    "application/vnd.ms-powerpoint",
    "applicatiapplication/vnd.openxmlformats-officedocument.presentationml.presentation",
    "text/plain",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "video/mp4"]
    const imgType = ["bmp",
  "jpg",
  "jpeg",
  "png",
  "tiff"]

  const context = useContext(Page_settings);
  const limit_time = [];

  useEffect(() => {
    if (rootStore.getProfile != null) {
      setProfile(rootStore.getProfile.profile);
    }
  }, []);

  for (let hours = 0; hours < 24; hours++) {
    for (let minutes = 0; minutes < 60; minutes += 10) {
      const hourFormatted = hours.toString().padStart(2, '0');
      const minuteFormatted = minutes.toString().padStart(2, '0');
      limit_time.push({ time: `${hourFormatted}:${minuteFormatted}` });
    }
  }

  const changeTitleValue = event => {
    setTitleValue(event.target.value)
  }
  const changeDateValue = event => {
    setDateValue(event.target.value)
  }

  const changeContentValue = event => {
    setContentValue(event.target.value)
  }

  const delFile = (idx) => {
    setFileList(preList => preList.filter((item, index) => {
      return idx != index
    }));
    setUrl(preList => preList.filter((item, index) => {
      return idx != index
    }));

    setGetThumbnail(preList => preList.filter((item, index) => {
      return idx != index
    }));
  }

  const onClickTime = (data) => {
    setLimitTime(data)
    setClickLimitTime(false)
  }


  const onClickDate = () => {
    setOpenCalendar(true);
  };

  const handleClose = () => {
    onClose?.();
  };

  const handleWheel = (event) => {
    const delta = event.deltaY;
    if (delta > 0) {
      // 스크롤 다운
      selectNextTime();
    } else if (delta < 0) {
      // 스크롤 업
      selectPrevTime();
    }
  };

  const selectNextTime = () => {
    const currentIndex = limit_time.findIndex(timeObj => timeObj.time === limitTime);
    if (currentIndex < limit_time.length - 1) {
      setLimitTime(limit_time[currentIndex + 1].time);
    }
  };

  const selectPrevTime = () => {
    const currentIndex = limit_time.findIndex(timeObj => timeObj.time === limitTime);
    if (currentIndex > 0) {
      setLimitTime(limit_time[currentIndex - 1].time);
    }
  };

  const formatFileSize = (fileSize) => {
    const dataSize = fileSize
        let formattedData = '';
        
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      
        if (dataSize === 0) return '0 Byte';
        
        const k = parseInt(Math.floor(Math.log(dataSize) / Math.log(1024)));
      
        formattedData =  Math.round(100 * (dataSize / Math.pow(1024, k))) / 100 + ' ' + sizes[k];
        return formattedData;
   }

  const EnableConfirm = () => {
    
      if(reservStatus === 1){
        return(
        titleValue !== '' && contentValue !== '' 
        )
      }else{
        return(
          titleValue !== '' && contentValue !== '' && limitTime !== '' && dateValue !== ''
          )
      }
    
  }
  const onImageChange = (e) => {

    let file = [e.target.files];
    let thumbnail = e.target.files[0];
    setUrl([...url, ...file]);
        let list = [];

        let reader = new FileReader();

        // 이미지 불러오기
        reader.onloadend = function () {
          setGetThumbnail([...getThumbnail, reader.result]);
        };
        reader.readAsDataURL(thumbnail);

        file.forEach(item => {
            if (fileTypes.indexOf(item[0].type) < 0) return
            
            let ext = item[0].name.split(".")[item[0].name.split(".").length - 1];
            let type = "/assets/image/icon_file_";
            if (ext == "docx") {
                type += "docs";
            } else if (ext == "mp4") {
                type += "drive"
            } else {
                type += ext
            }
            type += ".png";
            
            list.push({title: item[0].name, size: item[0].size, type: type})
        });
        setFileList([...fileList, ...list])
        
    }

    const client = axios.create({
      baseURL: API_URL + '/app/',
  })
  
  const onAddNotification = async () => {


      if (url.length != 0) {
                let formData = new FormData();
                
                url.forEach((entry, index) => {
                    formData.append('file', entry[0]);
                });                
                await client.post(
                    'upload/files/notification',
                    formData,
                    ).then(
                      response => {
                          context.post(
                            'class/addNotification',
                          {
                            class_id,
                            title: titleValue,
                            content: contentValue,
                            file_list : fileList,
                            file_url: response.data.result_data.file,
                            date: dateValue,
                            time: limitTime,
                          },
                            response => {
                                setUrl([]);
                                setFileList([]);
                                setGetThumbnail([]);
                                onClose?.();
                            }
                        );           
                      }
                    );
            } else {
                context.post(
                    "class/addNotification",
                    {
                      class_id,
                      title: titleValue,
                      content: contentValue,
                      file_list : null,
                      file_url: null,
                      date: dateValue,
                      time: limitTime,
                    },
                    response => {
                      onClose?.();
                    }
                );
            // }
    
  } 
  }

  


  return (
     <>
    {openCalendar && (<CalendarModal
      open={openCalendar}
      onClose={() => {
        setOpenCalendar(false);
      }}
      setDateValue={setDateValue}
    />)}
    <Overlay>
      <ModalWrap>
        <div class="classAddModal-head">
          <h4>공지 추가</h4>
          <img onClick={handleClose} src="/assets/image/icon_close_gray.png" />
        </div>

        <div class="classAddModal-subTitle">
          제목
        </div>
        <input class="class-search"
          placeholder={strings.enter_title}
          inputType={'text'}
          value={titleValue}
          onChange={changeTitleValue}
          style={{padding:'15px'}}
          >
          
        </input>

        <div class="classAddModal-subTitle">
          내용
        </div>
        <textarea class="missionAddModal-content"
          placeholder={strings.enter_content}
          inputType={'text'}
          value={contentValue}
          onChange={changeContentValue}>
        </textarea>

        <input type='file' accept='image/bmp,image/jpg,image/jpeg,image/png,image/tiff,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/htm,text/html,application/pdf,application/vnd.ms-powerpoint,applicatiapplication/vnd.openxmlformats-officedocument.presentationml.presentation,text/plain,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,video/mp4' className='hide'
          onChange={(e) => onImageChange(e)}
          ref={imageRef} />
            {fileList.map((file, index) => (
                    <div>
                      {!imgType.includes(file.title.split('.').pop().toLowerCase())?  
                      
                        <div class="mission-add-file-box">
                        <div className="">
                          <img class="grid-item file-img" src={file.type} alt='' />
                        </div>
                        <div className="grid-item file-title">
                          {file.title}
                        </div>
                        <div className="grid-item file-size">
                          {formatFileSize(file.size)}
                        </div>
                        <div className="grid-item img-box">
                          <img src='/assets/image/icon_close_gray.png' alt=''
                            onClick={()=> {
                              delFile(index)
                            }}
                          />
                        </div>
                      </div>
                      :
                      null
                      }
              </div>
                ))}

          <div class="missionAddModal-img-container">
            <div class="missionAddModal-imgAdd" onClick={() => imageRef?.current.click()}>
                <img class="classAddModal-no-thumbnail" src="/assets/image/class_addImg1.png" alt="" />
            </div>
            <div class="missionAddModal-img-contents">
            {fileList.map((file, index) => (
                    <div className='position-relative'>
                      {imgType.includes(file.title.split('.').pop().toLowerCase())?  
                      <>
                        <img key={index} class="missionAddModal-thumbnail" src={getThumbnail[index]} alt="" />
                        <button
                  style={{
                    border: "none",
                    background: "none",
                    position: "absolute",
                    top: -10,
                    right: -15
                  }}
                  onClick={() => {
                    delFile(index)
                  }}
                >
                  <img src={"/assets/image/icon_cancel_btn.png"} alt="" />
                </button>
                </>
                      :
                      null
                        // <img key={index} class="missionAddModal-thumbnail" src={file.type} alt="" />
                      }
                
              </div>
                ))}
            </div>
          </div>

        <div class="classAddModal-subTitle">
          기한
        </div>
        <div class="classAddModal-bottom" style={{gap:'10px'}}>
          <div class={`cursor ${reservStatus === 1 ? "notificationAddModal-btn-selected" : "notificationAddModal-btn-unselected"} `} onClick={()=>setReservStauts(1)}> 즉시 전송</div>
          <div class={`cursor ${reservStatus === 2 ? "notificationAddModal-btn-selected" : "notificationAddModal-btn-unselected"} `} onClick={()=>setReservStauts(2)}> 예약</div>
          {reservStatus === 2 ? 
          <>
          <input class="class-search"
            // placeholder={"2023. 01. 01"}
            inputType={'text'}
            value={dateValue}
            onChange={changeDateValue}
            onClick ={onClickDate}>
            
          </input>
          <div onWheel={handleWheel}>
          
          <button class="pl on" onClick={() => setClickLimitTime(!clickLimitTime)}>
                <div>{limitTime} </div>
                <img src={'/assets/image/icon_arrow_down_gray.png'} alt='' />
              </button>
            { clickLimitTime ? (
              <ul class="listbox" id="listbox">
              <PerfectScrollbar style={{ height: "70px"}} options={{ suppressScrollX: true }}>
              {limit_time.map((timeObj, index) => (
                  <li key={index} >
                    <button class="list" onClick={()=>onClickTime(timeObj.time)}>{timeObj.time}</button>
                  </li>
              ))}
              </PerfectScrollbar>
              </ul>
            ): null
            
            }
            
          </div> 
          </>
          : null }
          
        </div>
        
        <Button style={{backgroundColor: EnableConfirm() ? '#0F84F4' : '#DFE3E8'}}disabled ={!EnableConfirm()} onClick={onAddNotification}>추가</Button>
      </ModalWrap>
    </Overlay>
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const ModalWrap = styled.div`
  width: 700px;
  height: fit-content;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  overflow: auto;
`;


const Button = styled.button`
cursor: pointer;
width: 100%;
color: #ffffff;
border:0px;
padding: 10px;
margin-top: 20px;
border-radius: 6px;
background: var(--chewing_main, #0F84F4);
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 20px;
`;


export default NotificationAddModal;