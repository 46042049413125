import styled from "styled-components";
import React from "react";
import colors from "../design/colors";
import fonts from "../design/typography";
import { ChevronRight } from "./icons/chevron";
import { ko } from "date-fns/locale/ko";
import DatePicker from "react-datepicker";
import moment from "moment";
import { ChevronLeft } from "./icons/chevron";
const Calendar = (props: {
    date: Date;
    onChange: (date: Date) => void;
    renderDayContents?: (dayOfMonth: number, date: Date) => React.JSX.Element
}) => {

    const {date, onChange} = props;

    
    return (
        <StyledDatePickerWrapper>
            <DatePicker
                      inline
                      selected={date}
                      locale={ko}
                      onChange={(date: Date) => {
                          onChange(date);
                      }}
                      renderDayContents={props.renderDayContents}
                      renderCustomHeader={(params => {
                      return (
                          <CalendarHeader>
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', padding: "0 8px"}}>
                                  <span style={{ marginLeft: 4, color: colors.gray900, ...fonts.label3SemiBold }}>
                                      {(params.monthDate.getFullYear()).toString() + '년 '}
                                  </span>
                                  <span style={{ color: colors.gray900, ...fonts.label3SemiBold, marginLeft: 4 }}>
                                      {(params.monthDate.getMonth() + 1).toString() + '월'}
                                  </span>
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', padding: "0 8px"}}>
                                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}} onClick={() => {
                                      params.decreaseMonth();
                                  }}>
                                      <ChevronLeft color={colors.gray500} width="20px" height="20px" />
                                  </div>
                                  
                                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}} onClick={() => {
                                      params.increaseMonth();
                                  }}>
                                      <ChevronRight color={colors.gray500} width="20px" height="20px" />
                                  </div>
                              </div>
                          </CalendarHeader>
                      )
                  })}/>
        </StyledDatePickerWrapper>
    );
};

const CALENDAR_FONT_STYLES = {
    fontSize: fonts.label4Regular.fontSize,
    lineHeight: fonts.label4Regular.lineHeight,
    fontFamily: fonts.label4Regular.fontFamily,
  };

const StyledDatePickerWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 305px;
  height: 310px;
  border-radius: 8px;
  background-color: ${colors.gray50};
  box-shadow: 0 1px 2px 0 rgb(0, 0, 0, 0.05);

  .react-datepicker {
    background-color: ${colors.white};
    border-radius: 8px;
    width: 100%;
    height: 100%;
    border: none;
    padding: 12px 16px;
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__month {
    margin: 8px 0 0 0 ;
  }

  .react-datepicker__week {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .react-datepicker__day-names {
    display: flex;
    justify-content: space-around;
    margin-top: 8px;
  }

  .react-datepicker__day-name {
    color: ${colors.gray500};
    ${CALENDAR_FONT_STYLES}
  }

  .react-datepicker__day {
    width: 32px;
    height: 32px;
    ${CALENDAR_FONT_STYLES}
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
    border-radius: 50%;
    position: relative;

    .has-session {
      color: ${colors.blue600};
    }
  }

  .react-datepicker__day--selected {
    background-color: ${colors.blue500};
    color: ${colors.white};

    .has-session {
      color: ${colors.white};
    }
  }

  .react-datepicker__day--today {
    font-weight: bold;
  }

  .react-datepicker__header {
    background-color: white;
    border-bottom: none;
    border-radius: 20px 20px 0 0;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: ${colors.white};
    color: ${colors.gray900};
  }

  .react-datepicker__day--outside-month {
    color: ${colors.gray400};

    .has-session {
      color: ${colors.gray400};
    }
  }

  .react-datepicker__day:hover {
    background-color: ${colors.blue50};
    color: ${colors.blue600};
    border-radius: 50%;
  }
`;

const CalendarHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: ${colors.white};
`;

export default Calendar;