const colors = {
    blue900: "#1642AF",
    blue800: "#1561CE",
    blue700: "#1373E0",
    blue600: "#0F84F4",
    blue500: "#0194FF",
    blue400: "#36A3FF",
    blue300: "#5DB4FF",
    blue200: "#8DC9FF",
    blue100: "#BADEFF",
    blue50: "#E3F2FF",
    green900: "#006935",
    green800: "#00894B",
    green700: "#009B58",
    green600: "#00AE66",
    green500: "#00BD71",
    green400: "#04C886",
    green300: "#56D29C",
    green200: "#8FDFB9",
    green100: "#BCEBD4",
    green50: "#E3F7EE",
    yellow900: "#F67E11",
    yellow800: "#FAA820",
    yellow700: "#FBC028",
    yellow600: "#FDD830",
    yellow500: "#FFEB36",
    yellow400: "#FCEB52",
    yellow300: "#FEF073",
    yellow200: "#FFF59C",
    yellow100: "#FFF9C3",
    yellow50: "#FFFDE7",
    gray900: "#1B1D1F",
    gray800: "#26282B",
    gray600: "#464C52",
    gray500: "#73787E",
    gray400: "#9FA4A8",
    gray200: "#C9CDD3",
    gray100: "#E9EBED",
    gray50: "#F7F8F9",
    black: "#000000",
    white: "#FFFFFF",
    red900: "#8C1B45",
    red800: "#AF264A",
    red700: "#C32C4D",
    red600: "#D93250",
    red500: "#E93752",
    red400: "#EE4C69",
    red300: "#F46983",
    red200: "#F893A6",
    red100: "#FBBDC9",
    red50: "#FDE5E9",
    success: "#36A3FF",
    warning: "#EE4C69",
    warning50: "#FDE5E9",
};

export default colors; 