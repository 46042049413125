import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import SweetAlert from 'react-bootstrap-sweetalert';
import PerfectScrollbar from "react-perfect-scrollbar";
import PlanAddButton from "../../../components/control/button/plan_add_button";
import PlannerButton from "../../../components/control/button/planner_button";
import PlanInput from "../../../components/control/custom_input/plan_input";
import PlanItem from "../../../components/control/item/plan_item";
import { Page_settings } from "../../../config/page_settings";
import { IoCloseSharp } from "react-icons/io5";
import strings from "../../../lang/strings";
import styled from "styled-components";

interface IPlannerRightContainerProps {
    roomId: number;
    onClose: () => void;
}

const PlannerRightContainer = ({roomId, onClose}: IPlannerRightContainerProps) => {

    const context: any = useContext(Page_settings);
    const [selectedTag, setSelectedTag] = useState(0);
    const [todoList, setTodoList] = useState([])
    const [addPlan, setAddPlan] = useState(false);
    const [newPlan, setNewPlan] = useState("");
    const [curPlan, setCurPlan] = useState("");
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [delIdx, setDelIdx] = useState(-1);
    const [tagList, setTagList] = useState([]);

    const [todoStatus, setTodoStatus] = useState(false);
    useEffect(() => {
        context.get(
            'planner/getTagList',
            {
                planner_date: moment(moment.now()).format("YYYY-MM-DD"),
            },
            response => {
                setTagList(response.list);
                if (response.list.length != 0) {
                    setTodoList(response.list[selectedTag].todo_list.map((item, idx) => {
                        return {
                            id: item.id,
                            status: item.status,
                            title: item.title,
                            editable: false,
                            color: response.list[selectedTag].color
                        };
                    }));
                    let total = 0;
                    let done = 0;
                    response.list.forEach((item) => {
                        item.todo_list.forEach((itList) => {
                            total += 1;
                            if (itList.status == "Y") {
                                done += 1
                            }
                        })
                    });
                }
            }
        );
    }, [todoStatus])

    const getTodoList = (idx) => {
        const color = tagList[idx].color;
        setTodoList(tagList[idx].todo_list.map((item, idx) => {
            return {
                id: item.id,
                status: item.status,
                title: item.title,
                editable: false,
                color: color
            };
        }));
    }

    const onAddPlan = () => {
        if (newPlan == "") return
        context.post(
            'planner/addTodo',
            {
                show_date: moment().format('YYYY-MM-DD'),
                tag: tagList[selectedTag].id,
                title: newPlan,
                id: ''
            },
            response => {
                setTodoStatus(!todoStatus);
            }
        );
        setAddPlan(false);
    };

    const onDeletePlan = (idx) => {
        setDelIdx(idx);
        toggleSweetAlert();
    };

    const toggleSweetAlert = () => {
        setDeleteAlert(!deleteAlert);
    };

    const onConfirmPlan = (idx) => {
        setTodoList(todoList.map((item, index) => {
            item.title = idx == index ? curPlan : item.title;
            item.status = idx == index ? false : item.status;
            item.editable = idx == index ? false : item.editable;
            return item;
        }));

        context.post(
            'planner/addTodo',
            {
                show_date: moment().format('YYYY-MM-DD'),
                tag: tagList[selectedTag].id,
                title: curPlan,
                id: todoList[idx].id
            },
        );

    };

    const onEditPlan = (idx) => {
        setCurPlan(todoList[idx].title);
        setTodoList(todoList.map((item, index) => {
            item.editable = idx == index ? true : item.editable;
            return item;
        }));
    };

    const onChange = (text) => {
        setCurPlan(text);
    }

    const doDelete = () => {
        context.post(
            'planner/deleteTodo',
            {
                id: todoList[delIdx].id
            },
            response => {
                // getTagList();
                setTodoList(prev => prev.filter((item, index) => delIdx != index));
                toggleSweetAlert();
            }
        );
    };

    const onCheckTodo = (idx) => {
        let tag = tagList[selectedTag];
        let status = tag.todo_list[idx].status == 'Y' ? 'N' : 'Y';
        context.post(
            'planner/changeTodoStatus',
            {
                id: tag.todo_list[idx].id,
                status: status
            },
            response => {
                setTodoStatus(!todoStatus);
            }
        );
    }

    return (
        <PlannerRightWrapper>
            <div style={{height: '100%'}}>
                <ContainerHeader>
                    <span style={{fontSize: 18}}>{strings.planner}</span>
                    <button onClick={() => onClose()}>
                        <IoCloseSharp color="#A8ACBA" size={22} />
                    </button>
                </ContainerHeader>
                { tagList.length > 0 && (<PerfectScrollbar className="d-flex p-b-10 h-auto" options={{ suppressScrollY: true }}>
                    {
                        tagList.map((item, idx) =>
                            <PlannerButton
                                key={idx}
                                value={item.title}
                                selectedColor={item.color}
                                selected={idx == selectedTag}
                                onClick={() => {
                                    setSelectedTag(idx);
                                    getTodoList(idx);
                                    setAddPlan(false);
                                    setNewPlan("");
                                }}
                            />
                        )
                    }
                </PerfectScrollbar> )}
                <PlanContainer>
                    {
                        tagList.length != 0 &&
                            (
                            <><PerfectScrollbar options={{ suppressScrollX: true }}>
                                {
                                    todoList.map((item, idx) =>
                                        <PlanItem
                                            key={idx}
                                            item={item}
                                            value={curPlan}
                                            onEdit={() => onEditPlan(idx)}
                                            onDelete={() => onDeletePlan(idx)}
                                            onConfirm={() => onConfirmPlan(idx)}
                                            onChange={(text) => onChange(text)}
                                            onCheckTodo={() => onCheckTodo(idx)}
                                        />
                                    )
                                }
                                {
                                    addPlan &&
                                    <PlanInput
                                        value={newPlan}
                                        onChange={(v) => setNewPlan(v)}
                                        type={"text"}
                                        onAddPlan={() => onAddPlan()}
                                    />
                                }
                            </PerfectScrollbar>
                            <div style={{display:"flex", flexDirection: "row", justifyContent: "space-between"}}>
                                <div style={{width: 10, height: 10}}/>
                                <PlanAddButton
                                    onClick={() => {
                                        setAddPlan(true);
                                        setNewPlan("")
                                    }}
                                />
                            </div>
                            </>)
                        
                    }
                    {
                        tagList.length == 0 &&
                        <div className="no-item">{strings.no_plan_today}</div>
                    }
                </PlanContainer>
            </div>
            
            {(deleteAlert &&
                <SweetAlert showCancel
                    confirmBtnText={strings.delete}
                    confirmBtnBsStyle="primary"
                    cancelBtnText={strings.cancel}
                    cancelBtnBsStyle="default"
                    title={strings.delete_planner}
                    customClass="alert-black"
                    onConfirm={() => doDelete()}
                    onCancel={() => toggleSweetAlert()}
                    confirmBtnCssClass="alert-confirm"
                    cancelBtnCssClass="alert-cancel"
                >
                    {strings.delete_planner_alert}
                </SweetAlert>
            )}
        </PlannerRightWrapper>
    );
}

export default PlannerRightContainer;

const PlannerRightWrapper = styled.div`
    height: calc(100% - 133px);
    width: 350px;
    position: absolute;
    right: 0;
    background: #353443;
    margin-top: -11px;
    border-left: black 1px solid;
    z-index: 30;
    padding: 15px 20px 0px 20px;
`;

const ContainerHeader = styled.div`
    display: flex;
    justify-content: space-between;
    height: 50px;
    color: #A8ACBA;
    button {
        background: none;
        border: none;
        cursor: pointer;
        img {
            width: 14px;
            height: 14px;
        }
    }
    align-items: center;
    margin-bottom: 10px;    
`;

const PlanContainer = styled.div`
    height: calc(100% - 103px);
    width: 100%;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    .no-item {
        color: #A8ACBA;
        text-align: center;
        margin-top: 20px;
    }
    background-color: #212222;
    padding: 20px;
    border-radius: 7px;
`;