import React from "react";

export default function StudyRoomInput(props) {
    return (
        <div className={props.name != "" ? "study-room-input" : "study-room-input confirm-password"}>
            {
                props.name != "" &&
                <div className="display-flex-important align-top f-w-600">
                    <div>{props.name}</div>
                    {
                        props.required &&
                        <div className="required">*</div>
                    }
                </div>
            }
            <input placeholder={props.placeholder} type={props.inputType} maxLength={props.maxLength}
                   value={props.value} onChange={(e) => props.onChange(e.target.value)} disabled={props.disable}
            />
            {
                props.search &&
                    <button className="transparent-button" onClick={()=> props.onSearch()}>
                        <img src={"/assets/image/icon_search_black.png"} alt=""/>
                    </button>
            }
        </div>
    );
}

