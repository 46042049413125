import React, { useRef, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import ConferenceItem from "../../../components/control/item/conference_item";
import ConferenceCarouselButton from "../../../components/control/button/conference_carousel_button";
import HideButton from "../../../components/control/button/hide_button";
import { isMobile } from "react-device-detect";
import styled from "styled-components";

const ConferenceTop = (props) => {
    const { mine, memberList, reaction, localVideoTrack, muteVideo, reverseCamera, name, profile, screenTrack, screenShare, myShare, isStudent, showBoard, splitBoard } = props;
    const idCarousel = useRef();
    const [roomSelected, setRoomSelected] = useState(0);
    const [carouselOpen, setCarouselOpen] = useState(mine && memberList.length != 0);

    return (
        <div className="conference-main" style={{ height: isMobile ? "calc(100vh - 182px)" : "calc(100vh - 135px)" }}>
            {
                mine && carouselOpen &&
                <div className="conference-top">
                    {
                        (memberList.length != 0) &&
                        <div>
                            <Carousel
                                ref={idCarousel}
                                draggable={true}
                                swipeable={true}
                                // focusOnSelect={true}
                                // centerMode={true}
                                containerClass={"conference-room-container"}
                                itemClass="react-multi-carousel-item custom-carousel-item"
                                responsive={{
                                    LargeDesktop: {
                                        breakpoint: { max: 4000, min: 1400 },
                                        items: 5,
                                        slidesToSlide: 5
                                    },
                                    desktop: {
                                        breakpoint: { max: 1400, min: 1024 },
                                        items: 3,
                                        slidesToSlide: 3 // optional, default to 1.
                                    },
                                    tablet: {
                                        breakpoint: { max: 1024, min: 464 },
                                        items: 2,
                                        slidesToSlide: 2 // optional, default to 1.
                                    },
                                    mobile: {
                                        breakpoint: { max: 464, min: 0 },
                                        items: 1,
                                        slidesToSlide: 1 // optional, default to 1.
                                    }
                                }}
                                arrows={false}
                                showDots={false}
                            // slidesToSlide={3}
                            >
                                {
                                    memberList
                                    // .filter(item => item.id != owner=)
                                    .map((item, idx) =>
                                        <ConferenceItem
                                            key={idx}
                                            item={item}
                                            videoTrack={item.videoTrack}
                                            audioTrack={item.audioTrack}
                                            muteVideo={item.muteVideo}
                                            muteAudio={item.muteAudio}
                                            reverseCam={item.reverseCam}
                                        />
                                    )

                                }
                            </Carousel>
                            <ConferenceCarouselButton
                                more={roomSelected != 0}
                                direction={'left'}
                                onClick={() => {
                                    if (roomSelected != 0) {
                                        idCarousel?.current?.goToSlide(roomSelected - 1);
                                        // idCarousel?.current?.next();
                                        setRoomSelected(roomSelected - 1);
                                    } else {
                                        // idCarousel?.current?.goToSlide(memberList.length - 1);
                                        // setRoomSelected(memberList.length - 1);
                                    }
                                }}
                            />
                            <ConferenceCarouselButton
                                more={roomSelected != memberList.length - 1}
                                direction={'right'}
                                onClick={() => {
                                    if (roomSelected != memberList.length - 1) {
                                        idCarousel?.current?.goToSlide(roomSelected + 1);
                                        setRoomSelected(roomSelected + 1);
                                    } else {
                                        // idCarousel?.current?.goToSlide(0);
                                        // setRoomSelected(0);
                                    }
                                }}
                            />
                        </div>
                    }
                </div>
            }
            {
                mine &&
                <div style={{ position: 'absolute', top: carouselOpen ? 190 : 0, zIndex: 3, width: '100%' }}>
                    <HideButton
                        opened={carouselOpen}
                        onClick={() => {
                            setCarouselOpen(!carouselOpen)
                        }}
                    />
                </div>
            }
            <div style={{ background: "white", height: showBoard && carouselOpen ? "calc(100% - 210px)" : '100%', width: "calc(100% - 60px)", display: 'flex', flexDirection: 'row' , justifyContent: splitBoard ? 'space-between' : 'flex-start', marginLeft: 30}} id="whiteboardDiv">
                <WhiteboardSDKElementWrapper splitBoard ={splitBoard} screenShare={screenShare} id="whiteboardSDKElement"></WhiteboardSDKElementWrapper>
                <ScreenShareElementWrapper splitBoard={splitBoard} screenShare={screenShare} id="screenShareElement"></ScreenShareElementWrapper>
            </div>
        </div>

    );
}
export default ConferenceTop;

const WhiteboardSDKElementWrapper = styled.div`
    width: ${(props) => props.splitBoard ? '50%' : '100%'};
    height: 100%;
    display: ${(props) => props.splitBoard || !props.screenShare ? 'block' : 'none'};
`;

const ScreenShareElementWrapper = styled.div`
    width: ${(props) => props.splitBoard ? '50%' : '100%'};
    height: 100%;
    display: ${(props) => props.screenShare ? 'block' : 'none'};
`;