import React from 'react';

const CoverImageItem = (props) => {

    const {item, selected, onClick} = props;
    return (
        <button className={selected ? "room-cover-image-selected" : "room-cover-image"}
                style={{backgroundImage: "url(" + item + ")"}} onClick={() => onClick()}/>
    );
}

export default CoverImageItem;
