import React from 'react';
import { IconType } from "../../type/icon_type";

const TrashCan = ({ color, width, height, style }: IconType) => {
    return (
        <svg width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path d="M4 6.6H20M18.2222 6.6V19.2C18.2222 20.1 17.3333 21 16.4444 21H7.55556C6.66667 21 5.77778 20.1 5.77778 19.2V6.6M8.44444 6.6V4.8C8.44444 3.9 9.33333 3 10.2222 3H13.7778C14.6667 3 15.5556 3.9 15.5556 4.8V6.6M10.2222 11.1V16.5M13.7778 11.1V16.5" stroke={color} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}   

export default TrashCan;