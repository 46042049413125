import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../../../design/colors";
import fonts from "../../../design/typography";
import IconX from "../../icons/x";
import ToggleSwitch from "../../toggle_switch";
import { Page_settings } from "../../../config/page_settings";

interface NoticeContentProps {
    toggle: () => void;
}

interface INoticeSetting {
    notificationWeb: boolean;
    notificationChat: boolean;
    notificationMarketing: boolean;
}

const NoticeContent = ({ toggle }: NoticeContentProps) => {

    const context: any = useContext(Page_settings);
    const [noticeSetting, setNoticeSetting] = useState<INoticeSetting>({
        notificationWeb: true,
        notificationChat: true,
        notificationMarketing: true
    });

    useEffect(() => {
        context.get('notification/setting', {}, (response: INoticeSetting) => {
            setNoticeSetting(response);
        });
    }, []);

    const handleNoticeSetting = (key: string, value: boolean) => {
        setNoticeSetting({ ...noticeSetting, [key]: value });
        context.put('notification/setting', { ...noticeSetting, [key]: value }, () => {
        });
    };


    return (
        <ContentWrapper>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: 36, width: "100%"}}>
                <span style={{...fonts.label2SemiBold, color: colors.gray900}}>알림</span>
                <div style={{cursor: "pointer"}} onClick={toggle}>
                    <IconX width="32px" height="32px" color={colors.gray900} background={colors.gray900}/>
                </div>
            </div>
            <div style={{width: "100%"}}>
                <span style={{...fonts.label3SemiBold, color: colors.gray900}}>알림 수신 설정</span>
            </div>
            <div style={{width: "100%", marginBottom: 20, marginTop: 28, height: 1, backgroundColor: colors.gray100}}/>
            <div style={{width: "100%", display: "flex", flexDirection: "column", gap: 20}}>
                <div style={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 12}}>
                    <span style={{...fonts.label3Medium, color: colors.gray900}}>츄잉톡 알림</span>
                    <ToggleSwitch isOn={noticeSetting.notificationChat} handleToggle={() => handleNoticeSetting('notificationChat', !noticeSetting.notificationChat)} />
                </div>
            </div>
        </ContentWrapper>
    );
}

export default NoticeContent;

const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 28px 32px 0 32px;
    position: relative;
`;