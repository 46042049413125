import React from "react";

export default function HideButton(props) {
    return (
        <button
            className="hide-button"
            onClick={props.onClick}>
            {
                props.opened&&
                    <img src={"/assets/image/icon_arrow_up_white.png"} alt=""/>
            }
            {
                !props.opened&&
                <img src={"/assets/image/icon_dropdown_arrow.png"} alt=""/>
            }
        </button>
    );
}
