import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import fonts from "../design/typography";
import colors from "../design/colors";
import { ChevronDown } from "./icons/chevron";
import { ChevronUp } from "./icons/chevron";

export interface IOption {
    label: string;
    onSelect: () => void;
}

interface SelectBoxProps {
    width?: string;
    height?: string;
    options: IOption[];
    defaultOptionLabel?: string;
    selectedOptionLabel?: string;
    disabled?: boolean;
    size?: "small" | "medium";
    onSelect?: () => void;
}

const SelectBox = (props : SelectBoxProps) => {
    const { options, width, height, defaultOptionLabel, selectedOptionLabel, disabled, size, onSelect } = props;

    const [selectedOption, setSelectedOption] = useState<IOption | null>(() => {
        if (selectedOptionLabel) {
            return options.find(option => option.label === selectedOptionLabel);
        }
        return null;
    });

    useEffect(() => {
        if (!selectedOptionLabel) {
            setSelectedOption(null);
            return;
        }
        
        const newOption = options.find(option => option.label === selectedOptionLabel);
        if (newOption) {
            setSelectedOption(newOption);
        } else {
            setSelectedOption(null);
        }
    }, [selectedOptionLabel, options]);

    useEffect(() => {
        if (disabled) {
            setIsOpen(false);
            setSelectedOption(null);
        }
    }, [disabled]);

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    const [hoveredIndex, setHoveredIndex] = useState<number>(-1);

    return (  
        <div ref={dropdownRef} style={{ position: 'relative', width: width || "100%"}}>
            <SelectedOptionBox 
                onClick={() => {
                    if (onSelect) {
                        onSelect();
                    }
                    !disabled && setIsOpen(!isOpen)
                }} 
                isOpen={isOpen}
                isComplete={(!isOpen && selectedOption !== null) || disabled}
                height={height}
                size={size}
                disabled={disabled}
            >
                {selectedOption?.label || defaultOptionLabel || "선택"}
                {isOpen ? <ChevronUp color={colors.blue600} style={{marginLeft: 5}} /> : <ChevronDown color={selectedOption ? colors.gray600 : colors.gray200} style={{marginLeft: 5}} />}      
            </SelectedOptionBox>
            {!disabled && isOpen && (
                    <DropdownContent>
                        {options.map((option, index) => (
                            <div 
                                key={index}
                                onClick={() => {
                                    option.onSelect();
                                    setSelectedOption(option);
                                    setIsOpen(false);
                                }}
                                style={{
                                    backgroundColor: hoveredIndex === index ? colors.blue50 : colors.white
                                }}
                                onMouseEnter={(e) => setHoveredIndex(index)}
                                onMouseLeave={(e) => setHoveredIndex(-1)}
                                >
                                    <span style={{paddingLeft: 10, color: hoveredIndex === index ? colors.gray900 : colors.gray500, 
                                        fontSize: size === "small" ? fonts.label4Medium.fontSize : fonts.body3Medium.fontSize, 
                                        fontFamily: size === "small" ? fonts.label4Medium.fontFamily : fonts.body3Medium.fontFamily, 
                                        lineHeight: size === "small" ? fonts.label4Medium.lineHeight : fonts.body3Medium.lineHeight}}>
                                        {option.label}
                                    </span>
                            </div>
                        ))}
                    </DropdownContent>
                )}
        </div>
    );
}

export default SelectBox;

const SelectedOptionBox = styled.div<{ width?: string, height?: string, size?: "small" | "medium", isOpen: boolean, isComplete: boolean, disabled: boolean }>`
    cursor: ${props => props.disabled ? "default" : "pointer"};
    border-radius: 8px;
    width: ${props => props.width || "100%"};
    height: ${props => props.height || "48px"}  ;
    padding-left: 16px;
    padding-right: 16px;
    font-size: ${props => props.size === "small" ? fonts.label4Medium.fontSize : fonts.label3Medium.fontSize};
    font-family: ${props => props.size === "small" ? fonts.label4Medium.fontFamily : fonts.label3Medium.fontFamily};
    line-height: ${props => props.size === "small" ? fonts.label4Medium.lineHeight : fonts.label3Medium.lineHeight};
    color: ${props => props.isOpen ? colors.gray900 : props.isComplete ? colors.gray900 : colors.gray500};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: ${props => props.isComplete ? colors.gray50 : colors.white};
    border:  ${props => props.isOpen ? `1px solid ${colors.blue600}` : props.isComplete ? `none` : `1px solid ${colors.gray100}`};
`

const DropdownContent = styled.div`
    position: absolute;
    background-color: ${colors.white};
    border: 1px solid ${colors.gray100};
    z-index: 1;
    width: 100%;
    & > div {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 44px;
        color: ${colors.gray500};
        background-color: ${colors.white};
        font-size: ${fonts.body3Regular.fontSize};
        font-family: ${fonts.body3Regular.fontFamily};
        line-height: ${fonts.body3Regular.lineHeight};
    }
    border-radius: 8px;
`