import React, { useContext, useState, useEffect } from "react";
import {Page_settings} from "../../../config/page_settings";
import moment from "moment";
import styled from "styled-components";
import colors from "../../../design/colors";
import fonts from "../../../design/typography";


interface IFile {
    name: string;
    type: string;
    url: string;
    size?: number;
}

interface IChat {
    id: number;
    room: number;
    sender: {
        id: number;
        name: string;
        profile: string | null;
    };
    message: string;
    createDate: string;
    isRead: boolean;
    file: IFile[] | null;
};

interface IChewingTalkChatItem {
    item: IChat;        
    mine: boolean;
    setLightboxImage: (url: string) => void;
    showTime: boolean;
}

const ChewingTalkChatItem = (props: IChewingTalkChatItem) => {

    const {item, mine, setLightboxImage, showTime} = props;
    
    return (
        <ChatItemContainer mine={mine}>
            {mine && (
                <ChatAllWrapper>
                    <ChatTimeAndReadContainer mine={true}>
                        <ChatRead>{item.isRead ? "" : "1"}</ChatRead>
                        {showTime ? <ChatTime mine={true}><span color={'#A8ACBA'}>{moment(item.createDate).format("HH:mm")}</span></ChatTime> : <div style={{width: 90, height: 10}}/>}
                    </ChatTimeAndReadContainer>
                    { item.file && item.file.length > 0 ? 
                        item.file.map((file_i, index) => (
                            <ChatFile key={index} file={file_i} setLightboxImage={setLightboxImage}/>
                        ))
                        : 
                        <ChatArea mine={true}>
                            {item.message}
                        </ChatArea>
                    }
                </ChatAllWrapper>
            )}
            {!mine && (
                <>
                <ChatAllWrapper>
                    <ChatMessageAndNameContainer>
                        {item.file && item.file.length > 0 ? 
                           item.file.map((file_i, index) => (
                            <ChatFile key={index} file={file_i} setLightboxImage={setLightboxImage}/>
                        ))
                        :
                        <ChatArea mine={false}>
                            {item.message}
                        </ChatArea>
                        }
                    </ChatMessageAndNameContainer>
                    <ChatTimeAndReadContainer mine={false}>
                        <ChatRead>{item.isRead || !mine ? "" : "1"}</ChatRead>
                        {showTime ? <ChatTime mine={false}><span color={'#A8ACBA'}>{moment(item.createDate).format("HH:mm")}</span></ChatTime> : <div style={{width: 90, height: 10}}/>}
                    </ChatTimeAndReadContainer>
                </ChatAllWrapper>
                </>
            )}
        </ChatItemContainer>
    );
}

const ChatFile = (props: {file: IFile, setLightboxImage: (string) => void}) => {
    const context: any = useContext(Page_settings);
    const {file, setLightboxImage} = props;
    const ext = file.url.split(".")[file.url.split(".").length - 1];
    if (ext === "jpg" || ext === "jpeg" || ext === "png" || ext === "gif") {
        return (
        <ImageWithFallback 
            src={context.loadImage(file.url)} 
            alt={file.name}  
            fallbackSrc={`/assets/image/icon_file_${ext}.png`} 
            setLightboxImage={setLightboxImage}    
        />
        )
            
    } else if (ext === "mp4" || ext === "avi" || ext === "mov" || ext === "wmv") {
        return (
        <ImageBox>
            <video src={context.loadImage(file.url)} controls style={{height:'150%'}}/>
        </ImageBox>
        )
    } else {
        return (
            <button style={{marginBottom: 10}} onClick={() => onDownload(file.url, file.name)}>
                <FileContainer file={file} />
            </button>
            
        )
    }
}

const onDownload = async (url, name) => {
    const STORAGE_BASE_URL = process.env.REACT_APP_BUCKET_URL;
    const fileUrl = `${STORAGE_BASE_URL}/${url}`;

    try {
        const response = await fetch(fileUrl, { method: "GET" });
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = name;
        document.body.appendChild(a);
        a.click();

        // 메모리 해제
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);
    } catch (error) {
        console.error("파일 다운로드 중 오류 발생:", error);
    }
}

export default ChewingTalkChatItem;

const ImageWithFallback = ({ src, alt, fallbackSrc, setLightboxImage, ...props }) => {
    const [error, setError] = useState(false);
  
    return (
        error ? <FileContainer file={{name: alt, type: "image", url: fallbackSrc}} /> :
        <button onClick={() => setLightboxImage(src)} >
            <ImageBox>
                <img src={src} alt={alt} onError={() => setError(true)} {...props} style={{width:'100%'}}/>
            </ImageBox>
        </button>
    )
  };

const FileContainer = ({file}: {file: IFile}) => {
    const [fileSize, setFileSize] = useState<string | null>(null);

    const ext = file.url.split(".")[file.url.split(".").length - 1];

    // 파일 용량을 계산하여 반환하는 함수
    const getFileSize = async (url: string) => {
        const res = await fetch(url);
        const blob = await res.blob();
        const size = blob.size;

        if (size < 1024) {
            return `${size} B`;
        } else if (size < 1024 * 1024) {
            return `${(size / 1024).toFixed(2)} KB`;
        } else {
            return `${(size / 1024 / 1024).toFixed(2)} MB`;
        }
    };

    useEffect(() => {
        if(file.size) {
            setFileSize(file.size < 1024 ? `${file.size} B` : file.size < 1024 * 1024 ? `${(file.size / 1024).toFixed(2)} KB` : `${(file.size / 1024 / 1024).toFixed(2)} MB`);
            return;
        }
        getFileSize(file.url).then(size => setFileSize(size));
    }, [file.url]);
    
    return (<FileBox>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", maxWidth: "70%"}}>
                    <span style={{...fonts.body3Medium, color: "#1B1D1F"}}>{file.name.length > 25 ? file.name.substring(0,25) + "...": file.name}</span>
                </div>
                <div style={{display:"flex", flexDirection: "row", justifyContent:"space-between"}}>
                    <div style={{display:"flex", flexDirection: "row", alignItems: "flex-end", ...fonts.detail2Medium, color: "#9FA4A8"}}>{fileSize}</div>
                    <img src={`/assets/image/icon_file_${ext}.png`} alt={``} width={24} height={24}/>
                </div>
            </FileBox>)
}

const ChatItemContainer = styled.div<{mine: boolean}>`
    display: flex;
    margin-left: ${props => props.mine ? "auto" : "20px"};
    margin-right: ${props => props.mine ? "20px" : "auto"};
    justify-content: ${props => props.mine ? "flex-end" : "flex-start"};
`;

const ChatMessageAndNameContainer = styled.div`
    max-width: 70%;
`;

const ChatTimeAndReadContainer = styled.div<{mine: boolean}>`
    margin-left: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.mine ? "flex-end" : "flex-start"};
`;

const ChatTime = styled.div<{mine: boolean}>`
    min-width: 90px;
    padding-left: 20;
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.mine ? "flex-end" : "flex-start"};
    align-items: flex-end;
    color: ${colors.gray400};
    font-family: ${fonts.label5Regular.fontFamily};
    font-size: ${fonts.label5Regular.fontSize};
    line-height: ${fonts.label5Regular.lineHeight};
`;

const ChatRead = styled.div`
    font-family: ${fonts.label5Regular.fontFamily};
    font-size: ${fonts.label5Regular.fontSize};
    line-height: ${fonts.label5Regular.lineHeight};
    color: ${colors.blue600};
    margin-left: auto;
`;

const ChatArea = styled.div<{mine: boolean}>`
    display: flex;
    flex-direction: column;
    background-color: ${props => props.mine ? colors.gray800 : colors.white};
    border-radius: 16px;
    padding: 6px 14px;
    margin-bottom: 10px;
    word-break: break-all;
    color: ${props => props.mine ? colors.white : colors.gray800};
    font-family: ${fonts.label4Medium.fontFamily};
    font-size: ${fonts.label4Medium.fontSize};
    line-height: ${fonts.label4Medium.lineHeight};
`;

const ChatAllWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
`

const FileBox = styled.div`
    width: 186px;
    height: 102px;
    border-radius: 12px;
    background-color: #FFFFFF;
    padding: 16px 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
`;

const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 186px;
  height: 102px;
  overflow: hidden;
  border-radius: 12px;
  margin-bottom: 10px;
  background-color: black;
`