import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Create_curriculum_step1 from "./create_curriculum_step1";
import Create_curriculum_step2 from "./create_curriculum_step2";
import Create_curriculum_step3 from "./create_curriculum_step3";
import Create_curriculum_step4 from "./create_curriculum_step4";
import Create_curriculum_step5 from "./create_curriculum_step5";

const CreateCurriculumStep = (props) => {
  const curriculumStep = parseInt(props.match.params.step);
  const [curriculumData, setCurriculumData] = useState({ parts: [] });
  const [lastClickPart, setLastClickPart] = useState();

  useEffect(() => {}, []);

  const updatePartCategory = (newPartCategory) => {
    setCurriculumData((prevData) => ({
      ...prevData,
      parts: newPartCategory,
    }));
  };

  const updateThumbnail = (newThumbnail) => {
    setCurriculumData((prevData) => ({
      ...prevData,
      thumbnail: newThumbnail,
    }));
  };

  const updateLastClickPart = (color, subject) => {
    setLastClickPart({
      color,
      subject,
    });
  };

  return (
    <div>
      {curriculumStep == 1 && (
        <Create_curriculum_step1
          curriculumData={curriculumData}
          lastClickPart={lastClickPart}
          updatePartCategory={updatePartCategory}
          updateThumbnail={updateThumbnail}
          updateLastClickPart={updateLastClickPart}
        />
      )}
      {curriculumStep == 2 && (
        <Create_curriculum_step2
          curriculumData={curriculumData}
          updatePartCategory={updatePartCategory}
          updateThumbnail={updateThumbnail}
          updateLastClickPart={updateLastClickPart}
        />
      )}
      {curriculumStep == 3 && (
        <Create_curriculum_step3
          curriculumData={curriculumData}
          updatePartCategory={updatePartCategory}
          updateThumbnail={updateThumbnail}
          updateLastClickPart={updateLastClickPart}
        />
      )}
      {curriculumStep == 4 && (
        <Create_curriculum_step4
          curriculumData={curriculumData}
          updatePartCategory={updatePartCategory}
          updateThumbnail={updateThumbnail}
          updateLastClickPart={updateLastClickPart}
        />
      )}
      {curriculumStep == 5 && (
        <Create_curriculum_step5
          curriculumData={curriculumData}
          updatePartCategory={updatePartCategory}
          updateThumbnail={updateThumbnail}
          updateLastClickPart={updateLastClickPart}
        />
      )}
    </div>
  );
};
export default withRouter(CreateCurriculumStep);
