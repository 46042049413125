
const AI_SubTop = () => {
    return (
      <>
        <section className="wrap sub-top sub-top2">
          <div className="inner">
            <div className="main-tit">
              <h4>
                <img src="/assets/image/landing_page/sub/ai-tit.svg" alt="라이 아이콘" />
                AI 라이
              </h4>
              <h2>
                학생의 취약점을 책임지는 <br />
                츄잉 AI 코스웨어
              </h2>
              <p>
                학생별 취약 유닛 분석을 통해 <br />
                로드맵과 맞춤형 학습 콘텐츠를 제공합니다
              </p>
            </div>
            <div className="con-box">
              <div className="btn-box">
                <a className="on" href="#">
                  {" "}
                  츄잉 시작하기{" "}
                </a>
                <a href="#">문의하기</a>
              </div>
              <div className="img-box">
                <img src="/assets/image/landing_page/sub/ai-subtop.png" alt="클래스 운영" />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };
  
  export default AI_SubTop;
  