module.exports = {
    isLocalhost: true,
    API_URL: 'https://api.chewingplus.com', // this.isLocalhost ? 'http://192.168.0.54:3005' : 'https://api.chewingplus.com',
    // API_URL:  'http://localhost:3005',
    SOCKET_URL: 'https://api.chewingplus.com', // this.isLocalhost ? 'http://192.168.0.54:3005' : 'https://api.chewingplus.com',
    // SOCKET_URL:  'http://localhost:3005',
    

    GOOGLE_CLIENT_ID: '1000537720482-78f9n4e6kmk6tb4a73bkg5tfr63ejkp5.apps.googleusercontent.com',

    NAVER_CLIENT_ID: 'R7wJeKOHVy70BhuwIHPb',
    NAVER_CLIENT_SECRET: '1O6FqckJUC',
    NAVER_CALLBACK_URL: 'https://chewingplus.com/login', //this.isLocalhost ? 'http://localhost:3000/login' : 'https://chewingplus.com/login',

    AGORA_APP_ID: '103450b8fa6e4cdcbd2e90b8cf3336f1',
    AGORA_REGION: 'SG',
    AGORA_WHITEBOARD_APP_IDENTIFIER: "FKMz8EhpEe6FmSO9ryx04g/lcKtErXuA8zt1w",
    AGORA_WHITEBOARD_AK: "d1l89LSqRjmSEZaJ",
    AGORA_WHITEBOARD_SK: "fw35Mjg2P25-Mdjn9EYAV_-bJuOghQQE",
    AGORA_WHITEBOARD_REGION: "sg",

    colorList: [
        "#C92121",
        "#DF4949",
        "#F97E70",
        "#FFAF64",
        "#F0CF58",
        "#EDC63A",
        "#5B9559",
        "#93BC40",
        "#603D22",
        "#8A6D4E",
        "#C79127",
        "#CE612D",
        "#E68621",
        "#E39280",
        "#494556",
        "#696479",
        "#7B8891",
        "#9A918C",
        "#C1A886",
        "#4D2035",
        "#9F5C60",
        "#AA2E41",
        "#D54855",
        "#CF6B75",
        "#B61370",
        "#DD3093",
        "#E165B9",
        "#E06390",
        "#E58AAC",
        "#E9AAC4",
        "#3A21FA",
        "#5037FB",
        "#5A139F",
        "#6653A9",
        "#9F5EF0",
        "#7075F2",
        "#628046",
        "#264C53",
        "#406369",
        "#4D878D",
        "#4F9E9E",
        "#7ABBC1",
        "#262A4E",
        "#202C8B",
        "#314E9E",
        "#366DFC",
        "#519EE6",
        "#79BBF9"
    ],

    checkIconList: [
        "/assets/image/icon_todo_check_1.png",
        "/assets/image/icon_todo_check_2.png",
        "/assets/image/icon_todo_check_3.png",
        "/assets/image/icon_todo_check_4.png",
        "/assets/image/icon_todo_check_5.png",
        "/assets/image/icon_todo_check_6.png",
        "/assets/image/icon_todo_check_7.png",
        "/assets/image/icon_todo_check_8.png",
        "/assets/image/icon_todo_check_9.png",
        "/assets/image/icon_todo_check_10.png",
        "/assets/image/icon_todo_check_11.png",
        "/assets/image/icon_todo_check_12.png",
        "/assets/image/icon_todo_check_13.png",
        "/assets/image/icon_todo_check_14.png",
        "/assets/image/icon_todo_check_15.png",
        "/assets/image/icon_todo_check_16.png",
        "/assets/image/icon_todo_check_17.png",
        "/assets/image/icon_todo_check_18.png",
        "/assets/image/icon_todo_check_19.png",
        "/assets/image/icon_todo_check_20.png",
        "/assets/image/icon_todo_check_21.png",
        "/assets/image/icon_todo_check_22.png",
        "/assets/image/icon_todo_check_23.png",
        "/assets/image/icon_todo_check_24.png",
        "/assets/image/icon_todo_check_25.png",
        "/assets/image/icon_todo_check_26.png",
        "/assets/image/icon_todo_check_27.png",
        "/assets/image/icon_todo_check_28.png",
        "/assets/image/icon_todo_check_29.png",
        "/assets/image/icon_todo_check_30.png",
        "/assets/image/icon_todo_check_31.png",
        "/assets/image/icon_todo_check_32.png",
        "/assets/image/icon_todo_check_33.png",
        "/assets/image/icon_todo_check_34.png",
        "/assets/image/icon_todo_check_35.png",
        "/assets/image/icon_todo_check_36.png",
        "/assets/image/icon_todo_check_37.png",
        "/assets/image/icon_todo_check_38.png",
        "/assets/image/icon_todo_check_39.png",
        "/assets/image/icon_todo_check_40.png",
        "/assets/image/icon_todo_check_41.png",
        "/assets/image/icon_todo_check_42.png",
        "/assets/image/icon_todo_check_43.png",
        "/assets/image/icon_todo_check_44.png",
        "/assets/image/icon_todo_check_45.png",
        "/assets/image/icon_todo_check_46.png",
        "/assets/image/icon_todo_check_47.png",
        "/assets/image/icon_todo_check_48.png",
    ],

    emoticonList: [
        "/assets/image/icon_sad.png",
        "/assets/image/icon_effi_open_mouth.png",
        "/assets/image/icon_effi_smile.png",
        "/assets/image/icon_effi_heart_eye.png",
        "/assets/image/icon_effi_sunglasses.png",
    ],

    reactionList: [
        {
            icon: "/assets/image/icon_reaction_thumb_up.png",
            desc: "엄지척"
        },
        {
            icon: "/assets/image/icon_reaction_hi.png",
            desc: "손인사"
        },
        {
            icon: "/assets/image/icon_reaction_clap.png",
            desc: "박수"
        },
        {
            icon: "/assets/image/icon_reaction_ok.png",
            desc: "오케이"
        },
        {
            icon: "/assets/image/icon_reaction_strong.png",
            desc: "자랑"
        },
        {
            icon: "/assets/image/icon_reaction_angry.png",
            desc: "화남"
        },
        {
            icon: "/assets/image/icon_reaction_kiss.png",
            desc: "애정표현"
        },
        {
            icon: "/assets/image/icon_reaction_haloed.png",
            desc: "천사"
        },
        {
            icon: "/assets/image/icon_reaction_explod.png",
            desc: "답답"
        },
        {
            icon: "/assets/image/icon_reaction_cry.png",
            desc: "슬픔"
        },
        {
            icon: "/assets/image/icon_reaction_wink.png",
            desc: "애교"
        },
        {
            icon: "/assets/image/icon_reaction_afraid.png",
            desc: "소름"
        },
        {
            icon: "/assets/image/icon_reaction_geezer.png",
            desc: "장난"
        },
        {
            icon: "/assets/image/icon_reaction_no_expression.png",
            desc: "해탈"
        },
        {
            icon: "/assets/image/icon_reaction_dizzy.png",
            desc: "아픔"
        }
    ],

    barColorList: [
        "#F04852",
        "#F97E70",
        "#F8EA69",
        "#04C886",
        "#0F84F4",
        "#F8EA69",
    ],

    HEADER: {
        AUTH_TOKEN: 'x-access-token',
        CONTENT_TYPE: {
            NAME: 'Content-Type',
            JSON: 'application/json',
            FILE: 'multipart/form-data',
            FORM: 'application/x-www-form-urlencoded'
        },
        LANG: 'Accept-Language',
    },

    //. 각종 시간/일자 포맷
    FORMAT: {
        DATE_TIME: 'YYYY-MM-DD HH:mm:ss',
        DATE: 'YYYY-MM-DD',
        TIME: 'HH:mm:ss',
        MONTH: 'YYYY-MM',
        YEAR: 'YYYY'
    },

    //. 응답코드
    RESULT_CODE: {
        SUCCESS: 0,                     //. 정상
        EMPTY_TOKEN: -1,                //. 토큰정보 없음.
        TOKEN_EXPIRED: -2,              //. 토큰 기한 만료
        EMPTY_PARAM: -3,                //. 요청파라미터 누락
        ERROR: -10,                     //. api 내부 오류
    },

    //. 사용자 상태코드
    USER_STATUS: {
        LEAVE: -3,                      //. 탈퇴
        BLOCK: -2,                      //. 영구정지
        HOLD: -1,                       //. 홀딩
        PENDING: 0,                     //. 심사대기
        NORMAL: 1,                      //. 정상
    },

    //. 심사중 상태코드
    PENDING_STATUS: {
        PENDING: 0,                     //. 심사요청
        REJECT: -1,                     //. 심사탈락
        EDIT_NEED: 1,                   //. 1차합격, 정보수정필요
        ADD_FAVOR: 2,                   //. 1차합격, 취향정보작성전
        COMPLETE: 3,                    //. 심사완료
    },

    //. 성별
    GENDER: {
        MALE: 'M',
        FEMALE: 'F'
    },

    //. 상태코드
    STATUS: {
        PENDING: 0,         //. 대기
        NORMAL: 1,          //. 정상
        REJECT: 2,          //. 반려
        DELETED: -1,        //. 삭제
        FINISH: 2,          //. 완료
        CANCEL: 2,          //. 취소
        REAPPLY: 3,         //. 재심요청
        YES: 'Y',
        NO: 'N'
    },

    passwordRegex: /^(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[0-9])|(?=.*[A-Z])(?=.*[!@#$%^+=-])|(?=.*[0-9])(?=.*[!@#$%^+=-]))[a-zA-Z0-9!@#$%^+=-]{8,20}$/,
}
