import React from "react";

const ClassSec2 = () => {
    return (
        <>
            <section className="wrap class-sec2">
                <div className="inner">
                    <div className="main-tit">
                        <h2>
                            학생과 교사의 소통도 <br />
                            이제는 스마트하게
                        </h2>
                    </div>
                    <div className="box-layout">
                        <div className="wid-100 box1">
                            <div className="txt-box">
                                <h6>츄잉톡</h6>
                                <h3>
                                    언제나, 어디서나 <br />
                                    모든기기에서 편하게
                                </h3>
                                <p>
                                    종이와 펜으로 하는 상담은 이제 안녕, <br />
                                    실시간으로 편하게 커뮤니케이션 해보세요
                                </p>
                            </div>
                            <div className="img-box">
                                <img src="Img/sub/class-sec2-box1.png" alt="모든기기" />
                            </div>
                        </div>
                        <div className="wid-50 box2">
                            <div className="txt-box">
                                <h6>과제관리</h6>
                                <h3>
                                    반복적인 과제관리 <br />
                                    이제 자동화 하세요
                                </h3>
                                <p>
                                    학생/학급별 알맞은 과제설정으로 <br />
                                    손쉽게 관리하세요
                                </p>
                            </div>
                            <div className="img-box">
                                <img src="Img/sub/class-sec2-box2.png" alt="자동화" />
                            </div>
                        </div>
                        <div className="wid-50 box3">
                            <div className="txt-box">
                                <h6>대량업무처리</h6>
                                <h3>
                                    실수는 사라지고 <br />
                                    시간은 줄어듭니다.
                                </h3>
                                <p>
                                    학생 몇명이든 한번의 클릭으로 <br />
                                    대량업무를 처리할 수 있습니다.
                                </p>
                            </div>
                            <div className="img-box">
                                <img src="Img/sub/class-sec2-box3.png" alt="대량업무" />
                            </div>
                        </div>
                        <div className="wid-50 box4">
                            <div className="txt-box">
                                <h6>정보연동</h6>
                                <h3>
                                    선생님의 정보는 <br />
                                    누구보다 소중합니다.
                                </h3>
                                <p>개인정보유출 피해를 최소화 할 수 있는 최적의 솔루션</p>
                            </div>
                            <div className="img-box">
                                <img src="Img/sub/class-sec2-box4.png" alt="개인정보" />
                            </div>
                        </div>
                        <div className="wid-50 box5">
                            <div className="txt-box">
                                <h6>자료공유</h6>
                                <h3>
                                    공유가 필요할때 <br />
                                    아직도 찾고 계시나요?
                                </h3>
                                <p>
                                    학생들에게 필요한 모든 자료는 <br />
                                    자료공유 버튼으로 한 번에 보내보세요
                                </p>
                            </div>
                            <div className="img-box">
                                <img src="Img/sub/class-sec2-box5.png" alt="자료공유" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ClassSec2;