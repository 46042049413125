import React, { useContext, useEffect, useState } from 'react';
import styled from "styled-components";
import strings from "../../../lang/strings";
import { Page_settings } from '../../../config/page_settings';
import {rootStore} from "../../../mobx/store";

function  BillCancelModal(props) {
  const context = useContext(Page_settings);
  
  const {bill, count, onClose }= props;

  const [paymentToken, setPaymentToken] =  useState(localStorage.getItem('payment_token') ? JSON.parse(localStorage.getItem('payment_token')).token : null);
  const [tokenTime, setTokenTime] =  useState(localStorage.getItem('payment_token') ? JSON.parse(localStorage.getItem('payment_token')).time : null)
  const [reason, setReason] =  useState(null)

  useEffect(() => {
  
    return () => {
      context.handleSetPageHeader(true);
      context.handleSetPageSidebar(true);
    };

    
  }, []);
  
  console.log(bill)
  const tokenVerification = (tokenTime) => {
    // 현재 시각을 밀리초 단위로 얻음
  const currentTime = Date.now();

  // 주어진 시간 값과 현재 시각의 차이를 계산
  const difference = currentTime - tokenTime;

  // 차이가 지정된 시간 이상이면 true, 아니면 false 반환
  return difference >= 3600000;
  }

  const handleClose = () => {    
    onClose?.();
  };

  const cancelBill = () => {    
    if (tokenVerification(tokenTime)) {
      context.post(
        'payment/getToken',
        {},
        response => {
            rootStore.setPaymentToken(response);
            context.post(
              'payment/cancel',
              {
                token: response,
                billID: bill.id,
                reason: reason
              },
              response => {
                onClose?.();
              }
            )
        }
      )
    }else{
      context.post(
        'payment/cancel',
        {
          token: paymentToken,
          billID: bill.id,
          reason: reason
        },
        response => {
          onClose?.();
        }
      )
    }
  };

  return (
      <Overlay>
        <ModalWrap>
          <div className='bill-confirm-container'>
            <div className='title'>청구서를 취소하시겠습니까?</div>
            <div className='content'>
            <div class="payment-input-box  align-item-center box-shadow">
              <input class="input-transparent" placeholder={strings.payment_reason_placeholder} type="text"value={reason} onChange={(e) => setReason(e.target.value)}/></div>
            </div>
            
            <div className='btn-box'>
              <button onClick={()=>handleClose()} className='cancel-btn'>아니오</button>
              <button onClick={()=>cancelBill()} className='send-btn'>예</button>
            </div>
          </div>
        </ModalWrap>
      </Overlay>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const ModalWrap = styled.div`
  width: 326px;
  height: 180px;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
`;


export default BillCancelModal;