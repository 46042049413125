import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { isLocalhost } from "../config/const";

const firebaseConfig = {
  apiKey: "AIzaSyABhMj2GNg3m1NZntQvtg5N55D7K47Hd3E",
  authDomain: "chewing-374901.firebaseapp.com",
  projectId: "chewing-374901",
  storageBucket: "chewing-374901.appspot.com",
  messagingSenderId: "1000537720482",
  appId: "1:1000537720482:web:4f3093070512aafd75b9fb",
  measurementId: "G-52N7G097RH"
};

const firebaseApp = initializeApp(firebaseConfig);

const messaging = isLocalhost ? null : getMessaging(firebaseApp);

export const fetchToken = (setTokenFound, setFcmToken) => {
  return getToken(messaging, {
    vapidKey: 'BBNBURcaJZi6-KwNZo1YNKRzYXkLxGL9K4K_rWzYqb0b5qGd07_TjE-5I-6PC-t7T8sG-5dZ9cKAi67ZI4Hryac'
  }).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      setTokenFound(true);
      setFcmToken(currentToken);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      setFcmToken('');
      // shows on the UI that permission is required
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });