import React, { useState, useRef, useContext, ChangeEvent, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import styled from 'styled-components';
import IconX from '../../icons/x';
import colors from '../../../design/colors';
import fonts from '../../../design/typography';
import TextField from '../../text_field';
import { Page_settings } from '../../../config/page_settings';
import PlusIcon from '../../icons/plus';
import Checkbox from '../../checkbox';
import Button from '../../button';
import { getFilePath } from '../../../utils/image';
import { useNavigate } from 'react-router-dom';
interface ClassAddModalProps {
    toggle: () => void;
}

interface IFileResponse {
    name: string;
    size: number;
    url: string;
    type: string;
}

const ClassAddModal = ({ toggle }: ClassAddModalProps) => {

    const context: any = useContext(Page_settings);
    const [corp, setCorp] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [memberName, setMemberName] = useState<string>("");
    const [thumbnail, setThumbnail] = useState<IFileResponse | null>(null);
    const navigate = useNavigate();

    const imageRef = useRef<HTMLInputElement>(null);
    const [selectDefaultThumbnail, setSelectDefaultThumbnail] = useState<boolean>(false);
    

    const onImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        const formData = new FormData();
        formData.append("file", file);
        if (file) {
            context.post("upload/image/class", formData, (res: any) => {
                const thumnail = res;

                setThumbnail({
                    name: thumnail.name,
                    size: thumnail.size,
                    url: thumnail.url,
                    type: thumnail.type
                });
            })
        }
    }

    useEffect(() => {
        if (selectDefaultThumbnail) {
            setThumbnail(null);
        }
    }, [selectDefaultThumbnail]);


    const createClass = () => {
        if (corp === "" || title === "" || (thumbnail === null && !selectDefaultThumbnail)) {
            return;
        }

        context.post("class", {
            corp, title, memberName, thumbnail: selectDefaultThumbnail ? null : thumbnail?.url
        }, (res: any) => {  
            toggle();
            navigate(0);
        });
    }
    
    return (
        <Modal isOpen={true} toggle={toggle} style={{ width: 444, borderRadius: 20, padding:"20px 24px 24px 20px"}}>
            <ClassAddModalContainer>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" , width: "100%", marginBottom: 40}}>
                    <span style={{ ...fonts.title2SemiBold, color: colors.gray900 }}>클래스 생성</span>
                    <div style={{ cursor: "pointer" }} onClick={toggle}>
                        <IconX width="24px" height="24px" color={colors.gray900}/>
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" , width: "100%", marginBottom: 32}}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" , width: "100%", marginBottom: 16}}>
                        <span style={{ ...fonts.body3SemiBold, color: colors.gray900 }}>기관 입력</span>
                        <RequiredCircle style={{ marginLeft: 4, marginBottom: 5}}/>
                    </div>
                    <TextField
                        placeholder="기관 입력"
                        width="100%"
                        value={corp}
                        onChange={(e) => setCorp(e.target.value)}
                        size="small"
                        complete={corp !== ""}
                    />
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" , width: "100%", marginBottom: 32}}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" , width: "100%", marginBottom: 16}}>
                        <span style={{ ...fonts.body3SemiBold, color: colors.gray900 }}>제목</span>
                        <RequiredCircle style={{ marginLeft: 4, marginBottom: 5}}/>
                    </div>
                    <TextField
                        placeholder="제목 입력"
                        width="100%"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        size="small"
                        helperText="클래스를 진행하는 학습분야, 대상에 대한 제목을 작성해주세요"
                        complete={title !== ""}
                    />
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" , width: "100%", marginBottom: 32}}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" , width: "100%", marginBottom: 16}}>
                        <span style={{ ...fonts.body3SemiBold, color: colors.gray900 }}>이름</span>
                    </div>
                    <TextField
                        placeholder="이름을 입력해주세요"
                        width="100%"
                        value={memberName}
                        onChange={(e) => setMemberName(e.target.value)}
                        size="small"
                        helperText="회원가입 시 설정한 이름과 다르게 클래스를 운영할 시 입력해주세요"
                        complete={memberName !== ""}
                    />
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" , width: "100%", marginBottom: 32}}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" , width: "100%", marginBottom: 16}}>
                        <span style={{ ...fonts.body3SemiBold, color: colors.gray900 }}>썸네일 이미지</span>
                        <RequiredCircle style={{ marginLeft: 4, marginBottom: 5}}/>
                    </div>
                    <input type="file" accept="image/*" className="hide" onChange={(e) => onImageChange(e)} ref={imageRef} style={{display: "none"}} />
                    {
                        selectDefaultThumbnail ? (
                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" , width: "64px", height: "64px", marginBottom: 16, border: `1px solid ${colors.gray100}`, borderRadius: 4}}>
                                <img style={{minWidth: "100%", height: "100%", objectFit: "cover"}} src={`assets/image/default_class.png`} alt="" />
                            </div>
                        ):(
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" , width: "64px", height: "64px", marginBottom: 16, border: `1px solid ${colors.gray100}`, borderRadius: 4, cursor: "pointer"}}
                            onClick={() => imageRef?.current.click()}>
                            {thumbnail ? (
                                <img style={{minWidth: "100%", height: "100%", objectFit: "cover"}} src={getFilePath(thumbnail?.url, "assets/image/default_class.png")} alt="" />
                            ) : (
                                <PlusIcon width="24px" height="24px" color={colors.gray900}/>
                            )}
                        </div>
                        )
                    }
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" , width: "100%"}}>
                        <Checkbox
                            checked={selectDefaultThumbnail}
                            setChecked={() => setSelectDefaultThumbnail(!selectDefaultThumbnail)}
                            size="20px"
                        />
                        <span style={{ ...fonts.label4Medium, color: colors.gray500, marginLeft: 8}}>기본 이미지로 할게요</span>
                    </div>
                </div>
                <Button
                    text="만들기"
                    width="100%"
                    height="40px"
                    size="medium"
                    disabled={corp === "" || title === "" || (thumbnail === null && !selectDefaultThumbnail)}
                    onClick={createClass}
                />
            </ClassAddModalContainer>
        </Modal>
    )
}

const ClassAddModalContainer = styled(ModalBody)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    padding:20px 24px 24px 20px;
`;

const RequiredCircle = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${colors.red500};
`;

export default ClassAddModal;