import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import {inject, observer} from 'mobx-react';
import strings from "../../../lang/strings";
import {Page_settings} from "../../../config/page_settings";
import {rootStore} from "../../../mobx/store";

const DropdownProfile = observer((props) => {
    const [nickname, setNickname] = useState("");
    const [email, setEmail] = useState("");
    const [profile, setProfile] = useState("");
    const [readPremium, setReadPremium] = useState(false)

    useEffect(() => {
        if (rootStore.getProfile != null) {
            setEmail(rootStore.getProfile.email);
            setNickname(rootStore.getProfile.name);
            setProfile(rootStore.getProfile.profile);
            setReadPremium(rootStore.getProfile.premium == 1)
        }
    })

    const context = useContext(Page_settings);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => {
        setDropdownOpen(!dropdownOpen)
    }

    const onLogout = () => {
        context.post(
            'member/logout',
        );
        props.rootStore.signOut();
        props.history.replace('/login');
    };

    const onEditProfile = () => {
        context.handleProfileModal(true);
    }

    const onSetting = () => {
        context.handleSettingModal(true);
    }

    const onPremium = () => {
        context.post(
            "member/updatePremium",
            {
                premium: 1
            },
            response => {
                rootStore.setPremium(1)
                setReadPremium(true)
            }
        )
    }


    return (
        <Dropdown isOpen={dropdownOpen} toggle={() => toggle()}
                  className="dropdown navbar-user rank-dropdown" tag="li">
            <DropdownToggle tag="a" className="display-flex-important align-item-center">
                <img src={profile != null && profile != "" ? context.loadImage(profile) : "/assets/image/chewing_plus_logo.png"} alt=""/>
                {/* <div className="d-flex align-item-center">
                        <span className="d-inline"
                              style={{color: props.theme == "white" ? "black" : "white"}}>{nickname}</span>
                    {
                        readPremium &&
                        <img src={"/assets/image/icon_premium.png"} className="m-l-5 premium" alt=""/>
                    }
                </div> */}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu dropdown-menu-right rank-dropdown-menu" tag="ul">
                <DropdownItem className="dropdown-header" header>
                    {
                        readPremium &&
                        <div className="premium-profile">
                            <img src={"/assets/image/icon_premium.png"} alt=""/>
                        </div>
                    }
                    <div className="user-info">
                        <div className="display-flex-important justify-content-center">
                            <img className="user-img"
                                 src={profile != null && profile != "" ? context.loadImage(profile) : "/assets/image/chewing_plus_logo.png"}
                                 alt=""/>
                        </div>
                        <div className="f-s-16 text-center user-name f-w--600">{nickname}</div>
                        <div className="user-email f-s-14 text-center">{email}</div>
                        {
                            !readPremium &&
                            <button
                                onClick={() => onPremium()}
                                className="user-status user-status-new display-flex-important align-item-center justify-content-center">
                                <div className="f-s-16">{strings.premium_subscription}</div>
                            </button>
                        }
                    </div>
                </DropdownItem>

                <DropdownItem onClick={() => onEditProfile()}>
                    <div className="profile-tab">
                        <div className="flex-grow-1">{strings.edit_profile}</div>
                        <img src={"/assets/image/icon_arrow_right_black.png"} alt=""/>
                    </div>
                </DropdownItem>
                <DropdownItem onClick={() => onSetting()}>
                    <div className="profile-tab">
                        <div className="flex-grow-1">{strings.settings}</div>
                        <img src={"/assets/image/icon_arrow_right_black.png"} alt=""/>
                    </div>
                </DropdownItem>
                <DropdownItem onClick={() => onLogout()}>
                    <div className="profile-tab">
                        <div className="flex-grow-1">{strings.menu.log_out}</div>
                    </div>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
})

export default withRouter(inject('rootStore')(DropdownProfile));
