import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import CustomSearch from '../../../components/control/custom_input/custom_search';
import strings from '../../../lang/strings';
import './exam.scss'
import moment from 'moment';
import { Page_settings } from '../../../config/page_settings';
import { rootStore } from '../../../mobx/store';
import StartExamModal from '../../../components/control/modal/start_exam_modal';
import {Store} from "react-notifications-component";

const Exam = observer((props) => {
  const classId = props.match.params.class_id;
  const context = useContext(Page_settings);
  const history = useHistory();
  const [filter, setFilter] = useState(0);
  const [examList, setExamList] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [selectedItem, setSelectedItem] = useState({});
  const [showStartModal, setShowStartModal] = useState(false);
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  },[]);

  useEffect(() => {
    getExamList();
  }, [filter]);

  const getExamList = () => {
    context.get(
      'exam/list',
      {
        class_id: classId,
        status: filter
      },
      response => {
        setExamList(response.list);
      }
    );
  }

  const onAdd = () => {
    history.push("/exam/" + classId + "/add/0");
  };

  const addNotification = (notificationType, notificationTitle, notificationMessage) => {
    Store.addNotification({
        title: notificationTitle,
        message: notificationMessage,
        type: notificationType,
        insert: 'top',
        container: 'top-right',
        dismiss: {
            duration: 1000,
        }
    });
}

  const handleClick = (item, status) => {
    if (item.status === 0) { // 임시저장된 시험인 경우 작성화면으로 이동
      history.push("/exam/" + classId + "/add/" + item.id);
    } else {
      if (rootStore.getProfile?.user_type === 'STUDENT') {
        if (item.submission_time) {
          history.push("/exam/student/" + item.id);
        } else {
          if (status === 1) {
            setSelectedItem(item);
            setShowStartModal(true);
          } else if (status === 2) {
            addNotification('warning', '', '시험이 종료되었습니다.');
          } else {
            addNotification('warning', '', '아직 시험 시작 전입니다.');
          }
        }
      } else {
        history.push("/exam/teacher/" + item.id)
      }
    }
  }

  return (

    <AppWrap>
      <div className="main-content">
        {/* <CloudContainer/> */}
        <div className="exam-head">
          <div className="exam-title">
            시험장
          </div>
          {
            rootStore.getProfile?.user_type === 'EXPERT' &&
            <Button onClick={onAdd}><img src={"/assets/image/icon_plus_white.png"} style={{ marginRight: 10, height: 10 }} /> 시험 출제</Button>
          }
        </div>

        {
          <CustomSearch
            placeholder={strings.exam_search_placeholder}
            inputType={'text'}
            value={keyword}
            onChange={(v) => {
              setKeyword(v)
            }}
            onSearch={() => {
            }}
          />
        }

        <div className="col-md-12" style={{ textAlign: 'start', padding: 0 }}>
          {
            strings.exam_filter_list.map((item, idx) => {
              return (
                <button
                  key={idx}
                  className={"exam-filter " + (filter === item.code ? "selected" : "")}
                  onClick={() => setFilter(item.code)}>
                  {item.name}
                </button>
              )
            })
          }
        </div>

        {examList.length > 0 ? (
          <div className="exam-list-container">
            {examList.map((exam, idx) => {
              const isTempSave = exam.status === 0; // 임시저장 여부 -> 추후 제거 및 변경
              let examStatus;
              const examStartTime = new Date(exam.start_time);
              const examEndTime = new Date(exam.end_time);
              if (time < examStartTime) {
                examStatus = 3; // 대기
              } else if (time > examEndTime ) {
                examStatus = 2; // 마감
              } else {
                examStatus = 1; // 진행중
              }

              return (
                <div key={idx} className={"exam-list-item" + (isTempSave ? ' temp' : ''/* 임시저장 이면 temp 클래스 추가 */)} onClick={() => handleClick(exam, examStatus) }>    
                  <div className='d-flex' style={{ justifyContent: 'space-between' }}>
                    <div className='title'>{exam.title}</div>
                    <div className={'date ' + (isTempSave ? 'temp' : examStatus === 2 ? 'ended' : '')}>
                      {isTempSave ? '임시저장' : examStatus === 2 ? '마감' : (exam.start_time + '~' + exam.end_time)}
                    </div>
                  </div>
                  <div style={{ width: 'max-content' }}>
                    <div className="subject">{exam.subject}</div>
                  </div>
                  <div className='d-flex align-items-center' style={{ justifyContent: 'flex-end', marginTop: 'auto' }}>
                    {exam.submission_time && <SubmissionBox>제출</SubmissionBox>}
                  </div>
                  <div className='d-flex align-items-center' style={{ justifyContent: 'space-between', marginTop: 'auto' }}>
                    <div className="items">{exam.items + '문항'}</div>
                    <div className='d-flex align-items-center'>
                      <div className='testers active'>{exam.submission + '명 / '}</div>
                      <div className='testers'>{exam.participates + '명'}</div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        ) :
          (
            <div className="exam-nothing">
              <img className="exam-nothing-img" src="/assets/image/class_nothing.png" />
            </div>
          )}
      </div>
      {
        showStartModal &&
        <StartExamModal 
          show={showStartModal}
          toggle={() => setShowStartModal(!showStartModal)}
          onStart={() => {
            setShowStartModal(false);
            history.push("/exam/start/" + selectedItem.id);
            setSelectedItem({});
          }}
        />
      }
    </AppWrap>

  );
})

const Button = styled.button`
  font-size: 13px;
  padding: 9px 14px 9px 9px;
  border: none;
  background-color: #0F84F4;
  border-radius: 6px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  line-height: 13px;
  // &:hover {
  //   background-color: #fac2be;
  // }
`;

const AppWrap = styled.div`
  text-align: center;
  margin: 10px auto;
  position: relative;
  width: 100%;
  height: 100vh; /* 화면 높이의 100%로 설정하여 화면 전체를 채우도록 만듭니다. */
`;

const SubmissionBox = styled.div`
  display: flex;
  background-color: #01BE7E;
  color: #D3FFF0;
  padding: 3px 6px;
  border-radius: 5px;
`


export default withRouter(Exam);
