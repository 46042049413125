import moment from "moment";
import React, { useContext, useRef, useState } from 'react';
import Dropzone, { DropzoneRef } from "react-dropzone";
import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";
import { Page_settings } from "../../../config/page_settings";
import colors from "../../../design/colors";
import fonts from "../../../design/typography";
import strings from "../../../lang/strings";
import Button from "../../button";
import IconX from "../../icons/x";

interface IFileRequest {
    name: string;
    type: string;
    size: number;
    url: string;
    createDate: string;
}

const CloudUploadModal = ({show, toggle, reload}) => {
    const [fileList, setFileList] = useState<any[]>([]);
    const [uploading, setUploading] = useState(false);
    const fileTypes = [
        "image/bmp",
        "image/jpg",
        "image/jpeg",
        "image/png",
        "image/tiff",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "text/htm",
        "text/html",
        "application/pdf",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "text/plain",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "video/mp4"]

    const dropRef = useRef<DropzoneRef>(null);
    const context: any = useContext(Page_settings);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const onDrop = (acceptedFiles: File[]) => {
        const validFiles = acceptedFiles.filter(file => fileTypes.includes(file.type));
        if (validFiles.length !== acceptedFiles.length) {
            alert("Some files were not accepted due to unsupported file types.");
        }

        const newFileList = validFiles.map(file => ({
            title: file.name,
            size: file.size,
            type: `/assets/image/icon_file_${file.name.split('.').pop()}.png`,
            file: file
        }));

        setFileList(prev => [...prev, ...newFileList]);
    }


    const initialize = () => {
        setFileList([]);
        setUploading(false);
    }

    const uploadFiles = async () => {
        if (uploading || fileList.length === 0) return;

        const formData = new FormData();
        fileList.forEach(file => formData.append('files', file.file));

        setUploading(true);

        context.post("/upload/files/cloud", formData, (response: any) => {
            const fileList: IFileRequest[] = response.map((file: any) => ({
                name: file.name,
                type: file.type,
                size: file.size,
                url: file.url,
                createDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
            }));
            
            context.post("cloud", { files: fileList }, () => {
                initialize();
                toggle();
                reload();
                setUploading(false);
            });
        });
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files: File[] = Array.from(event.target.files);
        if (files) {
            const newFileList = files.map(file => ({
                title: file.name,
                size: file.size,
                type: `/assets/image/icon_file_${file.name.split('.').pop()}.png`,
                file: file
            }));
            setFileList(prev => [...prev, ...newFileList]);
        }
    }

    return (
        <Modal isOpen={show} toggle={() => toggle()} size="lg" style={{ width: "530px" , height: "410px", borderRadius: "20px" }} onOpened={() => {
            initialize()
        }}>
            <UploadModalBody>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "24px", justifyContent: "space-between" }}>
                    <div style={{ ...fonts.body2SemiBold }}>{strings.upload_file}</div>
                    <div onClick={() => toggle()} style={{ cursor: "pointer" }}>
                        <IconX width={"20px"} height={"20px"} color={colors.gray900} />
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "24px", justifyContent: "flex-start"}}>
                    <div style={{ ...fonts.label3Medium, color: colors.gray900, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", textAlign: "center", border: `1px solid ${colors.gray100}`, borderRadius: "4px", height: "32px", padding: "0 12px" , cursor: "pointer"}}
                        onClick={() => fileInputRef.current?.click()}
                    >{"내 PC"}</div>
                </div>
                <Dropzone ref={dropRef} onDrop={onDrop}>
                    {({getRootProps, getInputProps}) => (
                        <section>
                            <UploadBox 
                                {...getRootProps({
                                    onClick: event => event.stopPropagation()
                                })}
                                hasFile={fileList.length != 0}>
                                <input {...getInputProps()}
                                    accept={fileTypes.join(',')}/>
                                {
                                    fileList.length == 0 &&
                                        <>
                                            <img src={"/assets/image/img_drag_drop.png"} width={"68px"} height={"68px"} style={{marginTop: "212x"}}/>
                                            <div style={{ ...fonts.label3SemiBold, color: colors.gray900, textAlign: "center", marginTop: "16px" }}>{strings.upload_file}</div>
                                            <div style={{ ...fonts.label4Medium, color: colors.gray500, textAlign: "center", marginTop: "16px" }}>{strings.drag_drop_file}</div>
                                        </>
                                    
                                }
                                {
                                    fileList.length != 0 &&
                                    <div style={{ maxHeight: "100%", overflow: "auto", width: "100%", padding: "12px", gap: "16px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                        {
                                            fileList.map((item, idx) => {
                                                return (
                                                    <div key={idx} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                                            <img src={item.type} alt="" style={{width: "20px", height: "20px" }}/>
                                                            <span style={{ ...fonts.label4Medium, color: colors.gray600, textAlign: "center", marginLeft: "8px", verticalAlign: "middle" }}>{item.title}</span>
                                                        </div>
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" , cursor: "pointer" }} onClick={() => {
                                                            setFileList(fileList.filter((_, i) => i !== idx));
                                                        }}>
                                                            <IconX width={"20px"} height={"20px"} color={colors.gray900} />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                            </UploadBox>
                        </section>
                    )}
                </Dropzone>
                <Button style={{ ...fonts.label3Medium, color: colors.gray900, textAlign: "center", marginTop: "24px" }} text= "업로드" size="large" height="43px" disabled={uploading || fileList.length == 0} onClick={() => {
                    uploadFiles();
                }}/>
            </UploadModalBody>
            <input type="file" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChange} />
        </Modal>
    );
}

export default CloudUploadModal;

const UploadModalBody = styled(ModalBody)`
    width: 100%;
    height: 410px;
    border-radius: 20px;
    padding: 20px 24px 24px 24px;
`;

const UploadBox = styled.div<{hasFile: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: ${props => props.hasFile ? "flex-start" : "center"};
    border: 1px solid ${colors.gray100};
    border-radius: 4px;
    width: 100%;
    height: 196px;
`;