import { inject, observer } from 'mobx-react';
import moment from "moment";
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Page_settings } from '../../config/page_settings';
import colors from '../../design/colors';
import { extractNumber } from "../../helper/common";
import { rootStore } from "../../mobx/store";
import SignupStep0 from "./signup_step0";
import SignupStep1 from "./signup_step1";
import SignupStep2 from "./signup_step2";
import SignupStep3 from "./signup_step3";
import SignupStep4 from './signup_step4';

const Signup = observer((props) => {
    const context: any = useContext(Page_settings);
    const navigate = useNavigate();
    const [id, setId] = useState('');
    const [pwd, setPwd] = useState('');
    const [pwdConfirm, setPwdConfirm] = useState('');
    const [agreeInfo, setAgreeInfo] = useState(false);
    const [agreeAdv, setAgreeAdv] = useState(false);
    const signupStep = parseInt(useParams().step);
    const signupInfo = rootStore.getSignUpInfo;
    const [name, setName] = useState("");
    const [dateBirth, setDateBirth] = useState("");
    const [phone, setPhone] = useState("");
    const [code, setCode] = useState("");
    const [userType, setUserType] = useState<"STUDENT" | "EXPERT">("STUDENT");
    const [sexNum, setSexNum] = useState(0);
    const [school, setSchool] = useState("");
    const [eduOfficeCode, setEduOfficeCode] = useState("");
    const [schoolCode, setSchoolCode] = useState("");
    const [type, setType] = useState("EMAIL");
    useEffect(() => {
        if (signupInfo != null) {
            setId(signupInfo.email);
            setType(signupInfo.loginType);
        }
    }, []);

    const onDone = () => {
        const changeDateBirth = (birthString) => {
            let yy = parseInt(birthString.slice(0, 2));
            if (yy < 40) {
                return "20" + birthString;
            } else {
                return "19" + birthString;
            }
        }

        context.post(
            "auth/signup",
            {
                email: id,
                password: pwd,
                loginType: type,
                userType: userType,
                name: name,
                phone: phone,
                birthday: moment(changeDateBirth(dateBirth)).format("YYYY-MM-DD"),
                eduOfficeCode: eduOfficeCode,
                schoolCode: schoolCode,
                gender: (sexNum === 1 || sexNum === 3) ? "M" : "F",
                authCode: code,
                school: school,
            },
            response => {
                navigate("/login");
            }
        );
    }

    return (
        <WhiteBackground>
            <LoginContainer>
                <LoginContent>
                    {  
                        signupStep == 0 && 
                        <SignupStep0
                            setUserType={setUserType}
                        />
                    }
                    {
                        signupStep == 1 &&
                        <SignupStep1
                            id={id}
                            type={type}
                            onChangeId={setId}
                            pwd={pwd}
                            onChangePwd={(v) => {
                                setPwd(v);
                            }}
                            pwdConfirm={pwdConfirm}
                            onChangePwdConfirm={(v) => {
                                setPwdConfirm(v);
                            }}
                            agreeInfo={agreeInfo}
                            onAgreeInfo={() => setAgreeInfo(!agreeInfo)}
                            agreeAdv={agreeAdv}
                            onAgreeAdv={() => setAgreeAdv(!agreeAdv)}
                            navigate={navigate}
                        />
                    }
                    {
                        signupStep == 2 &&
                        <SignupStep2
                            name={name}
                            onChangeName={(v) => setName(v)}
                            dateBirth={dateBirth}
                            onChangeDateBirth={(v) => setDateBirth(extractNumber(v))}
                            phone={phone}
                            onChangePhone={(v) => {
                                setPhone(extractNumber(v));
                            }}
                            code={code}
                            onChangeCode={(v) => {
                                setCode(extractNumber(v));
                            }}
                            onDone={() => {
                                if (userType === "EXPERT") {
                                    navigate(`/signup/4`);
                                } else {
                                    navigate(`/signup/3`);
                                }
                            }}
                            sexNum={sexNum}
                            setSexNum={setSexNum}
                        />
                    }
                    {
                        userType === "STUDENT" && signupStep == 3 &&
                        <SignupStep3
                            eduOfficeCode={eduOfficeCode}
                            setEduOfficeCode={setEduOfficeCode}
                            schoolCode={schoolCode}
                            setSchoolCode={setSchoolCode}
                            school={school}
                            setSchool={setSchool}
                            onDone={() => {
                                navigate(`/signup/4`);
                            }}
                        />
                    }
                    {
                        signupStep == 4 &&
                        <SignupStep4
                            onDone={() => onDone()}
                        />
                    }
                </LoginContent>
            </LoginContainer>
        </WhiteBackground>
    );
});

export default inject('rootStore')(Signup);

const WhiteBackground = styled.div`
    background-color: ${colors.white};
    height: calc(100vh > 100% ? 100vh : 100%);
    width: 100%;
    height: calc(100vh - 80px);
    overflow-y: auto;
`;

const LoginContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    align-items: center;
    padding-bottom: 80px;
`;

const LoginContent = styled.div`
    width: 460px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
