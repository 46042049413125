import React, { useContext, useEffect, useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from "styled-components";
import strings from "../../../lang/strings";
import StudentSearch from "../customSearch/studentSearch";
import { Page_settings } from '../../../config/page_settings';
import { rootStore } from "../../../mobx/store";
import CalendarModal from './calendarModal';
import axios from "axios";

function RejectModal({ onClose,submit_id, stu_id }) {
  const [openCalendar, setOpenCalendar] = useState(false);
  const [reservStatus, setReservStauts]  = useState(1);
  const context = useContext(Page_settings);
  const limit_time = [];

  
  const handleClose = () => {
    onClose?.();
  };

  const reject = () => {
    context.post('class/changeStatusMission',
      {
        submit_id,
        mission_status : '반려',
        stu_id,
      },
      response => {
        onClose?.();
      })
  }


  return (
     <>
    
    <Overlay>
      <ModalWrap>
        <div class="rejectModal-container">
        <div class="rejectModal-head">
          반려
        </div>
        <div class="rejectModal-subTitle">
          정말로 반려하시겠습니까?
        </div>
        <div class="rejectModal-bottom">
          <button class="cancel-btn" onClick={()=> handleClose()}>취소</button>
          <button class="reject-btn" onClick={()=> reject()}>반려</button>
        </div>
        </div>
      </ModalWrap>
    </Overlay>
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const ModalWrap = styled.div`
  width: 350px;
  height: fit-content;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -70%);
  padding: 10px;
  overflow: auto;
`;

const CloseButton = styled.div`
  float: right;
  width: 40px;
  height: 40px;
  margin: 20px;
  cursor: pointer;
  i {
    color: #5d5d5d;
    font-size: 30px;
  }
`;

const Contents = styled.div`
  margin: 50px 30px;
  h1 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 60px;
  }
  img {
    margin-top: 60px;
    width: 300px;
  }
`;
const Button = styled.button`
cursor: pointer;
width: 100%;
color: #ffffff;
border-radius: 5px;
border:0px;
padding: 10px;
margin-top: 20px;
`;

const DropDownBox = styled.ul`
  display: block;
  position: absolute;
  margin: 0 auto;
  padding: 0px 5px;
  width: 93%;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-top: none;
  border-radius: 0 0 16px 16px;
  box-shadow: 0 10px 10px rgb(0, 0, 0, 0.3);
  list-style-type: none;
  z-index: 3;
  max-height: 200px; /* 최대 높이 설정 */
  overflow: auto; /* 스크롤바 표시 설정 */
`

const DropDownItem = styled.li`
  text-align: start;
  padding: 0px 5px;
  margin: 10px 0px;
  &.selected {
    background-color: #EFF3F9;
    border: 1px solid #EFF3F9;
    border-radius: 8px;
    
  }
`

export default RejectModal;