import moment from "moment";
import React, { useContext, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../../../components/button";
import CalendarModal from "../../../components/calendar_modal";
import PlusIcon from "../../../components/icons/plus";
import IconX from "../../../components/icons/x";
import TextField from "../../../components/text_field";
import { Page_settings } from '../../../config/page_settings';
import colors from "../../../design/colors";
import fonts from "../../../design/typography";
import strings from "../../../lang/strings";
const FILE_TYPES = {
  IMAGES: ["bmp", "jpg", "jpeg", "png", "tiff"],
  ALLOWED: [
    "image/bmp", "image/jpg", "image/jpeg", "image/png", "image/tiff",
    "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "text/htm", "text/html", "application/pdf", "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "text/plain", "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "video/mp4"
  ]
} as const;

interface INotificationAddModal {
  onClose: () => void;
  classId: number;
  notification?: INotificationItem;
  setNotification?: (notification: INotificationItem) => void;
}

interface INotificationItem {
  id: number;
  classroom: number;
  title: string;
  content: string;
  reservation: string;
  showTime: string | null;
  createDate: string;
  fileList: IFileResponse[];
}

interface IFileResponse {
  name: string;
  size: number;
  type: string;
  url: string;
}

const NotificationAddModal: React.FC<INotificationAddModal> = ({ onClose, classId, notification, setNotification }) => {
  const context: any = useContext(Page_settings);
  const [titleValue, setTitleValue] = useState(notification?.title || '')
  const [contentValue, setContentValue] = useState(notification?.content || '')
  const [curFileList, setCurFileList] = useState<IFileResponse[]>(notification?.fileList || []);
  const [fileList, setFileList] = useState<File[]>([]);
  const imageRef = useRef<HTMLInputElement>(null);

  const [dateValue, setDateValue] = useState(notification?.showTime ? moment(notification.showTime).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'))
  const [timeValue, setTimeValue] = useState<number>(notification?.showTime ? moment(notification.showTime).hour() : 0);
  const [minuteValue, setMinuteValue] = useState<number>(notification?.showTime ? moment(notification.showTime).minute() : 0);

  const [openCalendar, setOpenCalendar] = useState(false);
  const [reservStatus, setReservStauts] = useState(notification?.reservation ? 2 : 1);

  const [isContentHighlight, setIsContentHighlight] = useState(false);

  const navigate = useNavigate();

  const formatFileSize = (fileSize) => {
    const dataSize = fileSize
    let formattedData = '';

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    if (dataSize === 0) return '0 Byte';

    const k = Math.floor(Math.log(dataSize) / Math.log(1024));

    formattedData = Math.round(100 * (dataSize / Math.pow(1024, k))) / 100 + ' ' + sizes[k];
    return formattedData;
  }

  const EnableConfirm = () => {

    if (reservStatus === 1) {
      return (
        titleValue !== '' && contentValue !== ''
      )
    } else {
      return (
        titleValue !== '' && contentValue !== '' && dateValue !== ''
      )
    }

  }

  const getImagePath = (name: string) => {
    let ext = name.split(".")[name.split(".").length - 1];
    let path = "/assets/image/icon_file_";
    if (ext == "docx") {
      path += "doc";
    } else {
      path += ext;
    }
    path += ".png";
    return path
  };


  const onImageChange = (e) => {
    const files = e.target.files;
    if (!files?.length) return;

    if (!FILE_TYPES.ALLOWED.includes(files[0].type as any)) return;
    setFileList(prev => [...prev, ...files]);
  };

  const onAddNotification = async () => {
    if (fileList.length != 0) {
      let formData = new FormData();

      fileList.forEach((entry, index) => {
        formData.append('files', entry);
      });
      context.post(
        'upload/files/notice',
        formData,
        (response: any) => {
          context.post(
            `classroom/${classId}/notice`,
            {
              title: titleValue,
              content: contentValue,
              fileList: response,
              showTime: reservStatus === 1 ? null : `${dateValue} ${timeValue.toString().padStart(2, '0')}:${minuteValue.toString().padStart(2, '0')}:00`,
            },
            () => {
              setFileList([]);
              onClose?.();
              navigate(0);
            }
          );
        }
      );
    } else {
      context.post(
        `classroom/${classId}/notice`,
        {
          title: titleValue,
          content: contentValue,
          fileList: null,
          showTime: reservStatus === 1 ? null : `${dateValue} ${timeValue.toString().padStart(2, '0')}:${minuteValue.toString().padStart(2, '0')}:00`,
        },
        () => {
          onClose?.();
          navigate(0);
        }
      );
    }
  }

  const onUpdateNotification = async () => {
    if (fileList.length != 0) {
      let formData = new FormData();

      fileList.forEach((entry, index) => {
        formData.append('files', entry);
      });

      context.post(
        `upload/files/notice`,
        formData,
        (response: any) => {
          context.put(
            `classroom/${classId}/notice/${notification.id}`,
            {
              title: titleValue,
              content: contentValue,
              fileList: [
                ...curFileList,
                ...response
              ],
              showTime: reservStatus === 1 ? null : `${dateValue} ${timeValue.toString().padStart(2, '0')}:${minuteValue.toString().padStart(2, '0')}:00`,
            },
            () => {
              onClose?.();
              navigate(0);
            }
          );
        }
      );
    } else {
      context.put(
        `classroom/${classId}/notice/${notification.id}`,
        {
          title: titleValue,
          content: contentValue,
          fileList: [
            ...curFileList
          ],
          showTime: reservStatus === 1 ? null : `${dateValue} ${timeValue.toString().padStart(2, '0')}:${minuteValue.toString().padStart(2, '0')}:00`,
        },
        () => {
          onClose?.();
          navigate(0);
        }
      );
    }
  }

  return (
    <Overlay>
      <ModalWrap>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "24px" }}>
          <Title>{notification ? "공지 수정" : "공지 추가"}</Title>
          <div onClick={onClose}>
            <IconX width="24px" height="24px" color={colors.gray900} />
          </div>
        </div>
        <div style={{ marginBottom: "32px" }}>
          <SubTitle>제목</SubTitle>
          <TextField placeholder={strings.enter_title} value={titleValue} onChange={e => setTitleValue(e.target.value)} complete={titleValue !== ""} />
        </div>
        <div style={{ marginBottom: "16px" }}>
          <SubTitle>내용</SubTitle>
          <TextField placeholder={strings.enter_content} value={contentValue} onChange={e => setContentValue(e.target.value)} complete={contentValue !== ""} type="textarea" height="100px"/>
        </div>
        <input type='file' accept='image/bmp,image/jpg,image/jpeg,image/png,image/tiff,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/htm,text/html,application/pdf,application/vnd.ms-powerpoint,applicatiapplication/vnd.openxmlformats-officedocument.presentationml.presentation,text/plain,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,video/mp4' className='hide'
          onChange={(e) => onImageChange(e)}
          ref={imageRef} />

        {(curFileList.length > 0 || fileList.length > 0) && <FileWrapper>
          {[...curFileList, ...fileList].map((file, index) => (
            <FileItem>
              <img src={getImagePath(file.name)} alt='' style={{ width: "24px", height: "24px" }}/>
              <div style={{ ...fonts.label4Medium, color: colors.gray500, width: 228}}>
                {file.name}
              </div>
              <div style={{ ...fonts.label4Medium, color: colors.gray500, width: "calc(100% - 228px - 24px - 24px)", textAlign: "center" }}>
                {formatFileSize(file.size)}
              </div>
              <div style={{ cursor: "pointer" }} onClick={() => {
                if (curFileList && index < curFileList.length) {
                  setCurFileList(curFileList.filter((item, idx) => idx !== index));
                } else {
                  setFileList(fileList.filter((item, idx) => idx !== index - curFileList.length));
                }
              }}>
                <IconX width="24px" height="24px" color={colors.gray500} />
              </div>
            </FileItem>
          ))}
        </FileWrapper>}
        <div style={{ width: "56px", height: "56px", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", border: `1px solid ${colors.gray100}`, borderRadius: "4px", marginBottom: "32px" }} onClick={() => imageRef?.current.click()}>
          <PlusIcon width="24px" height="24px" color={colors.gray900} />
        </div>
        <SubTitle>업로드</SubTitle>
        <div style={{ gap: '6px', display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}>
            <UploadButton selected={reservStatus === 1} onClick={() => setReservStauts(1)}> 바로 추가</UploadButton>
            <UploadButton selected={reservStatus === 2} onClick={() => setReservStauts(2)}> 예약</UploadButton>
        </div>
        {reservStatus === 2 ?
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%", position: "relative", marginTop: "16px"}}>
              <div style={{position: "relative", width: "50%", marginRight: "12px"}}>
                <TextField value={dateValue} onChange={e => setDateValue(e.target.value)} complete={dateValue !== ""} />
                <div style={{position: "absolute", bottom: "0", left: "0", width: "100%", height: "100%", zIndex: 2, backgroundColor: "transparent", cursor: "pointer"}} onClick={() => setOpenCalendar(true)}/>
              </div>
              <div style={{position: "relative", width: "25%"}}>
                <TextField value={timeValue.toString().padStart(2, '0')} onChange={e => {
                  const value = parseInt(e.target.value);
                  if (isNaN(value) || value < 0) {
                    setTimeValue(0);
                  } else if (value > 23) {
                    setTimeValue(23);
                  } else {
                    setTimeValue(value);
                  }}} complete={true} textAlign="center"/>
              </div>
              <span style={{width: "20px", textAlign: "center"}}>:</span>
              <div style={{position: "relative", width: "25%", zIndex: 3, cursor: "pointer"}}>
                <TextField value={minuteValue.toString().padStart(2, '0')} onChange={e => {
                  const value = parseInt(e.target.value);
                  if (isNaN(value) || value < 0) {
                    setMinuteValue(0);
                  } else if (value > 59) {
                    setMinuteValue(59);
                  } else {
                    setMinuteValue(value);
                  }
                  e.target.value = minuteValue.toString().padStart(2, '0')}} complete={true} textAlign="center"/>
              </div>
              {openCalendar && 
              <div style={{position: "absolute", bottom: "260px", left: "0", width: "100%", height: "100%"}}>
                <CalendarModal dateValue={dateValue} onClose={() => setOpenCalendar(false)} setDateValue={setDateValue}/>
              </div>
              }
            </div>
            : null}
        <Button disabled={!EnableConfirm()} onClick={notification ? onUpdateNotification : onAddNotification} size="large" text={notification ? "수정하기" : "추가하기"} height="44px"  style={{marginTop: "32px"}}/>
      </ModalWrap>
    </Overlay>

  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const ModalWrap = styled.div`
  width: 600px;
  height: fit-content;
  border-radius: 20px;
  background-color: ${colors.white};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px 24px;
  overflow: auto;
`;

const Title = styled.div`
  font-size: ${fonts.body2SemiBold.fontSize};
  line-height: ${fonts.body2SemiBold.lineHeight};
  font-family: ${fonts.body2SemiBold.fontFamily};
  color: ${colors.gray900};
`;

const SubTitle = styled.div`
  font-size: ${fonts.label3SemiBold.fontSize};
  line-height: ${fonts.label3SemiBold.lineHeight};
  font-family: ${fonts.label3SemiBold.fontFamily};
  color: ${colors.gray900};
  margin-bottom: 16px;
`;

const FileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  gap: 4px;
  gap: 4px;
`;

const FileItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px;
  gap: 8px;
`;

const UploadButton = styled.div<{ selected: boolean }>`
  cursor: pointer;
  border-radius: 8px;
  background-color: ${props => props.selected ? colors.blue600 : colors.gray50};
  border: ${props => props.selected ? `1px solid ${colors.blue600}` : "none"};
  width: calc(50% - 4px);
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.selected ? colors.white : colors.gray400};
  font-size: ${fonts.label4Medium.fontSize};
  line-height: ${fonts.label4Medium.lineHeight};
  font-family: ${fonts.label4Medium.fontFamily};
`;



export default NotificationAddModal;