import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Page_settings } from '../../../../config/page_settings';
import colors from '../../../../design/colors';
import fonts from '../../../../design/typography';

interface OnlineClass {
  subject: string;
  content: string;
  site: string;
}

interface ApplicationFormData {
  id: number;
  classMember: number;
  name: string;
  gender: string;
  phone: string;
  guardianInfo: [
    {
      name: string;
      phone: string;
      time: string;
      relationship: string;
    }
  ],
  email: string;
  koreanOption: string;
  koreanRank: number;
  mathOption: string;
  mathRank: number;
  englishRank: number;
  elective1Option: string;
  elective2Option: string;
  elective1Rank: number;
  elective2Rank: number;
  devices: {
    notebook: boolean;
    tablet: boolean;
    smartphone: boolean;
    others: string;
  };
  career: string;
  birthday: string;
  address: string;
  school: string;
  isGraduated: boolean;
  university: string;
  weakSubject: string;
  onlineClass: [
    {
      subject: string;
      content: string;
      site: string;
    }
  ],
  etcInfo: {
    learning: string;
    health: string;
    lifeStyle: string;
  };
  createDate: string;
  grade: number;
  updateDate: string;
  historyRank: number;
  admissionReason: {
    internet: boolean;
    recommend: boolean;
    etc: string;
  };
  siblingsInfo: [
    {
      name: string;
      phone: string;
      school: string;
      grade: number;
    }
  ],
  department: string;
  };


interface ApplicationContentProps {
  member: any;
  classId: any;
}

const ApplicationContent: React.FC<ApplicationContentProps> = ({ member, classId }) => {
  const [data, setData] = useState<ApplicationFormData | null>(null);
  const context: any = useContext(Page_settings);
  useEffect(() => {
    context.get(
      `/class/${classId}/class-application/${member}`,
      {},
      (res: any) => {

        if (res === null) {
          return;
        }

        setData({
          ...res,
          birthday: res.birthday,
          koreanOption: res.koreanOption,
          mathOption: res.mathOption,
          englishRank: res.englishRank,
          historyRank: res.historyRank,
          elective1Option: res.elective1Option,
          elective2Option: res.elective2Option,
          elective1Rank: res.elective1Rank,
          elective2Rank: res.elective2Rank,
          weakSubject: res.weakSubject,
          onlineClass: res.onlineClass,
          etcInfo: res.etcInfo,
          career: res.career,
          devices: res.devices,
          admissionReason: res.admissionReason,
          classId: res.classId,
          guardianInfo: res.guardianInfo || [],
          siblingsInfo: res.siblingsInfo || [],
        })
      }
    )
  }, []);

  return (
    <WholeWrapper>
      {!data && (
        <FormWrapper style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}>
          <span style={{ fontSize: "24px", fontWeight: "bold"}}>입학원서가 존재하지 않습니다.</span>
        </FormWrapper>
      )}
      {data && (
        <FormWrapper>
          <SectionWrapper>
            <RowWrapper>
              <SectionTitle>이름 및 성별</SectionTitle>
            </RowWrapper>
            <RowWrapper>
              <FieldText style={{width: "calc(100% - 200px)"}}>{data.name}</FieldText>
              <SexButton isPressed={data.gender === 'M'}>남자</SexButton>
              <SexButton isPressed={data.gender === 'F'}>여자</SexButton>
            </RowWrapper>
          </SectionWrapper>

          <SectionWrapper>
            <RowWrapper style={{ width: "100%" }}>
              <div style={{ width: "25%" }}>
                <RowWrapper style={{ marginBottom: "15px" }}>
                  <SectionTitle>생년월일</SectionTitle>
                </RowWrapper>
                <RowWrapper>
                  <FieldText style={{textAlign: "center", width: "100%"}}>{data.birthday}</FieldText>
                </RowWrapper>
              </div>
              <div style={{ width: "75%" }}>
                <RowWrapper style={{ marginBottom: "15px" }}>
                  <SectionTitle>연락처</SectionTitle>
                </RowWrapper>
                <RowWrapper>
                  <FieldText style={{width: "100%"}}>{data.phone}</FieldText>
                </RowWrapper>
              </div>
            </RowWrapper>
          </SectionWrapper>

          <SectionWrapper>
            <RowWrapper>
              <SectionTitle>주소</SectionTitle>
            </RowWrapper>
            <RowWrapper>
              <FieldText style={{textAlign: "start", width: "100%"}}>{data.address}</FieldText>
            </RowWrapper>
          </SectionWrapper>

          <SectionWrapper>
            <RowWrapper>
              <SectionTitle>학교</SectionTitle>
            </RowWrapper>
            <RowWrapper>
              <FieldText style={{width: 290}}>{data.school}</FieldText>
              <FieldText style={{width: 190}}>{data.grade}학년</FieldText>
              <CheckBoxCustom checked={data.isGraduated === true} onChange={() => {}} />
              <span style={{ display: "flex", alignItems: "center" }}>졸업</span>
            </RowWrapper>
          </SectionWrapper>

          <SectionWrapper>
            <RowWrapper>
              <SectionTitle>반수</SectionTitle>
            </RowWrapper>
            <RowWrapper>
              {data.university ? (
                <div style={{display: "flex", alignItems: "center", gap: "20px"}}>
                  <FieldText>{data.university}</FieldText>
                  <FieldText>{data.department}</FieldText>
                </div>
              ) : (
                <>
                  <CheckBoxCustom checked={true} readOnly />
                  <span style={{ display: "flex", alignItems: "center" }}>해당 없음</span>
                </>
              )}
            </RowWrapper>
          </SectionWrapper>

          <SectionWrapper>
            <RowWrapper>
              <SectionTitle>이메일</SectionTitle>
            </RowWrapper>
            <RowWrapper>
              <FieldText style={{width: "100%"}}>{data.email}</FieldText>
            </RowWrapper>
          </SectionWrapper>

          <SectionWrapper>
            <RowWrapper>
              <SectionTitle>가족 관계</SectionTitle>
            </RowWrapper>
            <TableCustom>
              <thead>
                <tr>
                  <ColoredCell>관계</ColoredCell>
                  <ColoredCell>이름</ColoredCell>
                  <ColoredCell>연락처</ColoredCell>
                  <ColoredCell>상담 가능 시간</ColoredCell>
                </tr>
              </thead>
              <tbody>
                {data.guardianInfo.map((item, index) => (
                  <tr key={index}>
                    <td>{item.relationship}</td>
                    <td><FieldText style={{width: "100%", textAlign: "start"}}>{item.name}</FieldText></td>
                    <td><FieldText style={{width: "100%", textAlign: "start"}}>{item.phone}</FieldText></td>
                    <td><FieldText style={{width: "100%", textAlign: "start"}}>{item.time}</FieldText></td>
                  </tr>
                ))}
              </tbody>
            </TableCustom>
          </SectionWrapper>

          <SectionWrapper>
            <RowWrapper>
              <SectionTitle>수능 및 모의고사 성적</SectionTitle>
            </RowWrapper>
            <TableCustom>
              <tbody>
                <tr>
                  <ColoredCell style={{width: "20%"}}>국어</ColoredCell>
                  <td>
                    <FieldText style={{width: "100%"}}>{data.koreanOption}</FieldText>
                  </td>
                  <td>
                    <RowWrapper>
                      <FieldText style={{width: "calc(100% - 50px)", textAlign: "center"}}>{data.koreanRank}</FieldText>
                      <span style={{width: "50px", textAlign: "center"}}>등급</span>
                    </RowWrapper>
                  </td>
                </tr>
                <tr>
                  <ColoredCell style={{width: "20%"}}>수학</ColoredCell>
                  <td>
                    <FieldText style={{width: "100%"}}>{data.mathOption}</FieldText>
                  </td>
                  <td>
                    <RowWrapper>
                      <FieldText style={{width: "calc(100% - 50px)", textAlign: "center"}}>{data.mathRank}</FieldText>
                      <span style={{width: "50px", textAlign: "center"}}>등급</span>
                    </RowWrapper>
                  </td>
                </tr>
                <tr>
                  <ColoredCell style={{width: "20%"}}>영어</ColoredCell>
                  <td>
                    <span style={{width: "100%"}}> - </span>
                  </td>
                  <td>
                    <RowWrapper>
                      <FieldText style={{width: "calc(100% - 50px)", textAlign: "center"}}>{data.englishRank}</FieldText>
                      <span style={{width: "50px", textAlign: "center"}}>등급</span>
                    </RowWrapper>
                  </td>
                </tr>
                <tr>
                  <ColoredCell style={{width: "20%"}}>한국사</ColoredCell>
                  <td>
                    <span style={{width: "100%"}}> - </span>
                  </td>
                  <td>
                    <RowWrapper>
                      <FieldText style={{width: "calc(100% - 50px)", textAlign: "center"}}>{data.historyRank}</FieldText>
                      <span style={{width: "50px", textAlign: "center"}}>등급</span>
                    </RowWrapper>
                  </td>
                </tr>
                <tr>
                  <ColoredCell style={{width: "20%"}}>탐구1</ColoredCell>
                  <td>
                    <FieldText style={{width: "100%"}}>{data.elective1Option}</FieldText>
                  </td>
                  <td>
                    <RowWrapper>
                      <FieldText style={{width: "calc(100% - 50px)", textAlign: "center"}}>{data.elective1Rank}</FieldText>
                      <span style={{width: "50px", textAlign: "center"}}>등급</span>
                    </RowWrapper>
                  </td>
                </tr>
                <tr>
                  <ColoredCell style={{width: "20%"}}>탐구2</ColoredCell>
                  <td>
                    <FieldText style={{width: "100%"}}>{data.elective2Option}</FieldText>
                  </td>
                  <td>
                    <RowWrapper>
                      <FieldText style={{width: "calc(100% - 50px)", textAlign: "center"}}>{data.elective2Rank}</FieldText>
                      <span style={{width: "50px", textAlign: "center"}}>등급</span>
                    </RowWrapper>
                  </td>
                </tr>
              </tbody>
            </TableCustom>
          </SectionWrapper>

          <SectionWrapper>
            <RowWrapper>
              <SectionTitle>취약 과목</SectionTitle>
            </RowWrapper>
            <RowWrapper>
              <FieldText style={{width: "100%"}}>{data.weakSubject}</FieldText>
            </RowWrapper>
          </SectionWrapper>

          <SectionWrapper>
            <RowWrapper>
              <SectionTitle>수강한 인터넷 강의</SectionTitle>
            </RowWrapper>
            <TableCustom>
              <thead>
                <tr>
                  <ColoredCell>과목</ColoredCell>
                  <ColoredCell>내용</ColoredCell>
                  <ColoredCell>사이트</ColoredCell>
                </tr>
              </thead>
              <tbody>
                {(() => {
                  const filteredClasses = data?.onlineClass?.filter((item: any) => 
                    item.subject !== null
                    && item.content !== null 
                    && item.subject !== ''
                    && item.content !== ''
                  );

                  if (!filteredClasses?.length) {
                    return (
                      <tr>
                        <td colSpan={3} style={{textAlign: 'center'}}>
                          인터넷 강의가 없습니다
                        </td>
                      </tr>
                    );
                  }

                  return filteredClasses.map((item, index) => (
                    <tr key={index}>
                      <td>
                        {item.subject}
                      </td>
                      <td>
                        <FieldText style={{width: "100%", textAlign: "start"}}>{item.content}</FieldText>
                      </td>
                      <td>
                        <FieldText style={{width: "100%", textAlign: "start"}}>{item.site}</FieldText>
                      </td>
                    </tr>
                  ));
                })()}
              </tbody>
            </TableCustom>
          </SectionWrapper>

          <SectionWrapper>
            <RowWrapper>
              <SectionTitle>특이 사항</SectionTitle>
            </RowWrapper>
            <TableCustom>
              <tbody>
                <tr>
                  <ColoredCell style={{width: "20%"}}>학습</ColoredCell>
                  <td>
                    <textarea style={{width: "100%"}} value={data?.etcInfo?.learning || '학습 관련 특이사항이 없습니다.'} disabled={true}/>
                  </td>
                </tr>
                <tr>
                  <ColoredCell style={{width: "20%"}}>건강</ColoredCell>
                  <td>
                    <textarea style={{width: "100%"}} value={data?.etcInfo?.health || '건강 관련 특이사항이 없습니다.'} disabled={true}/>
                  </td>
                </tr>
                <tr>
                  <ColoredCell style={{width: "20%"}}>생활</ColoredCell>
                  <td>
                    <textarea style={{width: "100%"}} value={data?.etcInfo?.lifeStyle || '생활 관련 특이사항이 없습니다.'} disabled={true}/>
                  </td>
                </tr>
              </tbody>
            </TableCustom>
          </SectionWrapper>
          <SectionWrapper>
            <RowWrapper>
              <SectionTitle>입학 계기</SectionTitle>
            </RowWrapper>
            {data?.admissionReason?.internet && (
              <RowWrapper>
                <CheckBoxCustom checked={true} readOnly />
                <span style={{width: "calc(100% - 200px)", ...fonts.label4Medium}}>{"인터넷 검색 (파워링크, 블로그, 유튜브 등)"}</span>
              </RowWrapper>
            )}
            {data?.admissionReason?.recommend && (
              <RowWrapper>
                <CheckBoxCustom checked={true} readOnly />
                <span style={{width: "calc(100% - 200px)", ...fonts.label4Medium}}>{"주위 추천 (부모님, 친구, 선후배, 강사, 지인, 타 학원, 타 학습 사이트 등)"}</span>
              </RowWrapper>
            )}
            {data?.admissionReason?.etc && (
              <RowWrapper>
                <CheckBoxCustom checked={true} readOnly />
                <span style={{...fonts.label4Medium}}>{"기타"}</span>
                <span style={{width: "calc(100% - 200px)", ...fonts.label4Medium}}>{data?.admissionReason?.etc ? "- " + data?.admissionReason?.etc : ''}</span>
              </RowWrapper>
            )}
          </SectionWrapper>

          <SectionWrapper>
            <RowWrapper>
              <SectionTitle>진로 계획</SectionTitle>
            </RowWrapper>
            <RowWrapper>
              <FieldText style={{width: "100%"}}>{data?.career || '진로 계획이 존재하지 않습니다.'}</FieldText>
            </RowWrapper>
          </SectionWrapper>

          <SectionWrapper>
            <RowWrapper>
              <SectionTitle>전자기기</SectionTitle>
            </RowWrapper>
            <RowWrapper>
              {data?.devices?.notebook && (
                <>
                  <CheckBoxCustom checked={true} readOnly />
                  <span style={{width: "100px", ...fonts.label4Medium}}>{"노트북"}</span>
                </>
              )}
              {data?.devices?.tablet && (
                <>
                  <CheckBoxCustom checked={true} readOnly />
                  <span style={{width: "100px", ...fonts.label4Medium}}>{"태블릿PC"}</span>
                </>
              )}
              {data?.devices?.smartphone && (
                <>
                  <CheckBoxCustom checked={true} readOnly />
                  <span style={{width: "100px", ...fonts.label4Medium}}>{"스마트폰"}</span>
                </>
              )}
            </RowWrapper>
            {data?.devices?.others && (
              <RowWrapper>
                <CheckBoxCustom checked={true} readOnly />
                <span style={{...fonts.label4Medium}}>{"기타"}</span>
                <span style={{width: "calc(100% - 200px)", ...fonts.label4Medium}}>{data?.devices?.others ? " - " + data?.devices?.others : ''}</span>
              </RowWrapper>
            )}
          </SectionWrapper>
        </FormWrapper>
      )}
    </WholeWrapper>
  );
};

const WholeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
      width: 8px; /* Adjust the width of the scrollbar */
  }
  &::-webkit-scrollbar-thumb {
      background-color: ${colors.gray200}; /* Color of the scrollbar thumb */
      border-radius: 4px; /* Rounded corners for the thumb */
  }
  &::-webkit-scrollbar-track {
      background: transparent; /* Make the track invisible */
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 50px 0;
  align-items: center;
  margin-top: 20px;
  border-radius: 20px;
  gap: 40px;
  position: relative;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 800px;
`;

const SectionTitle = styled.span`
  color: ${colors.gray900};
  font-size: ${fonts.body3SemiBold.fontSize};
  line-height: ${fonts.body3SemiBold.lineHeight};
  font-family: ${fonts.body3SemiBold.fontFamily};
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

const FieldText = styled.span`
  color: ${colors.gray900};
  padding: 12px 16px;
  background-color: #f8f9fa;
  border-radius: 8px;
  min-width: 100px;
  display: inline-block;
  font-size: ${fonts.label4Medium.fontSize};
  line-height: ${fonts.label4Medium.lineHeight};
  font-family: ${fonts.label4Medium.fontFamily};
`;

const CheckBoxCustom = styled.input.attrs({ type: 'checkbox' , readOnly: true})`
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #e9ebed;
  cursor: pointer;
  appearance: none;
  background-color: #ffffff;
  margin: 0;
  
  &:checked {
    background-color: #0F84F4;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4L4.5 7.5L11 1' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    border-color: #0F84F4;
  }

  &:hover {
    border-color: #0F84F4;
  }
`;

const TableCustom = styled.table`
  border-collapse: collapse;
  width: 100%;
  & td, th {
    text-align: center;
    vertical-align: middle;
    height: 45px;
    padding: 8px 10px;
    font-size: 14px;
    border-bottom: 1px solid #E9EBED;
    border-top: 1px solid #E9EBED;
  }
`;

const ColoredCell = styled.td`
  background-color: #E9EBED;
`;

const AddRowButton = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 50px;
  border-radius: 8px;
  cursor: pointer;
  background-color: #F7F8F9;
  color: #73787E;
  font-size: 16px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const TextSpan = styled.span`
  font-size: 16px;
  color: #73787E;
  `;

const FieldSpan = styled.span`
  font-size: 16px;
  color: black;
  `;

const SexButton = styled.div<{ isPressed: boolean }>`
  width: 80px;
  height: 50px;
  border: 1px solid #e9ebed;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${(props) => (props.isPressed ? "#0F84F4" : "#ffffff")};
  color: ${(props) => (props.isPressed ? "#ffffff" : "#73787E")};
`;

export default ApplicationContent;
