import React from 'react';
import styled from 'styled-components';
import colors from '../design/colors';
import fonts from '../design/typography';

interface IButtonProps {
    width?: string;
    height?: string;
    size: 'small' | 'medium' | 'large';
    text: string | React.ReactNode;
    onClick: () => void;
    disabled?: boolean;
    style?: React.CSSProperties;
    buttonColor?: string;
    textColor?: string;
}


const Button = (props: IButtonProps) => {
    const {size, onClick, text, disabled, width, height, style, buttonColor: color,  textColor} = props;
    const [hovered, setHovered] = React.useState(false);

    return (
        <ButtonContainer 
            onMouseEnter={() => setHovered(true)} 
            onMouseLeave={() => setHovered(false)} 
            hovered={hovered}
            size={size}
            onClick={onClick}
            disabled={disabled}
            width={width}
            height={height}
            style={style}
            color={color}
        >
            <ButtonText size={size} color={textColor} disabled={disabled}>{text}</ButtonText>
        </ButtonContainer>
    )
}

export default Button;

const ButtonContainer = styled.button<{hovered: boolean, size?: 'small' | 'medium' | 'large', disabled?: boolean, width?: string, height?: string, color?: string}>`
    background-color: ${props => props.hovered ? (props.color ? props.color : colors.blue800) : props.disabled ? colors.gray200 : props.color ? props.color : colors.blue600};
    width: ${props => props.width ? props.width : "100%"};
    height: ${props => props.height ? props.height : "100%"};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`;

const ButtonText = styled.span<{size?: 'small' | 'medium' | 'large', color?: string, disabled?: boolean}>`
    color: ${props => props.color ? props.color : props.disabled ? colors.gray500 : colors.white};
    font-size: ${props => props.size === 'small' ? fonts.label4Medium.fontSize : props.size === 'medium' ? fonts.label3Medium.fontSize : fonts.body2Medium.fontSize};
    font-family: ${props => props.size === 'small' ? fonts.label4Medium.fontFamily : props.size === 'medium' ? fonts.label3Medium.fontFamily : fonts.body2Medium.fontFamily};
    line-height: ${props => props.size === 'small' ? fonts.label4Medium.lineHeight : props.size === 'medium' ? fonts.label3Medium.lineHeight : fonts.body2Medium.lineHeight};
`;