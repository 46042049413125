import React, {useContext} from "react";
import {Page_settings} from "../../../config/page_settings";
import moment from "moment";
import { rootStore } from "../../../mobx/store";
import styled from "styled-components";


interface IChat {
    sender: number;
    sender_name: string;
    sender_profile?: string;
    receiver?: number;
    receiver_name?: string;
    message: string;
    time: string;
    show_profile: boolean;
    show_time: boolean;
}

interface IChatItemProps {
    item: IChat;
}

const ChatItem = (props: IChatItemProps) => {
    const context: any = useContext(Page_settings)
    const {item} = props;

    const mine = item.sender == rootStore.getProfile.id;
    return (
        <div>
            <ChatRowWrapper mine={mine}>
                {
                    mine ? (
                        <ChatWholeWrapper>
                            { item.show_time && <div className="chat-time">{moment(item.time).format("HH:mm")}</div>}
                            <div style={{display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
                                <div style={{display: "flex", flexDirection: "row"}}>
                                    {item.receiver_name && (<>
                                        <span style={{color: "white"}}>{item.receiver_name+ " 에게 "} </span>
                                        <span style={{color: "red"}}>{" (DM)"}</span>
                                    </>)}
                                </div>
                                <MineChatBubbleWrapper>
                                    <span>{item.message}</span>
                                </MineChatBubbleWrapper>
                            </div>
                        </ChatWholeWrapper>
                    ) : (
                        <ChatWholeWrapper>
                            <div style={{display: "flex", flexDirection: "row", alignItems:"start"}}>
                                { item.show_profile ? 
                                    <img src={(item.sender_profile && item.sender_profile !== "") ? context.loadImage(item.sender_profile) : "/assets/image/chewing_plus_logo.png"} style={{width: 30, height: 30, borderRadius: 15, marginRight: 10}}/>
                                    :
                                    <div style={{width: 30, height: 30, marginRight: 10}}/>
                                }
                                <div>
                                    { (item.receiver || item.show_profile) && <span style={{color: "white"}}>{item.receiver ? item.sender_name + " (DM)" : item.sender_name}</span>}
                                    <ChatBubbleWrapper>
                                        <span>{item.message}</span>
                                    </ChatBubbleWrapper>
                                </div>
                            </div>
                            {item.show_time && <div className="chat-time">{moment(item.time).format("HH:mm")}</div>}
                        </ChatWholeWrapper>
                    )
                }


            </ChatRowWrapper>
            {/* {
                item.notice != undefined &&
                    <div className="notice d-flex">{item.notice}</div>
            } */}
        </div>

    );
}

export default ChatItem;

interface IChatRowProps {
    mine: boolean;
}

const ChatRowWrapper = styled.div<IChatRowProps>`
    display: flex;
    flex-direction: "row";
    margin-bottom: 5px;
    align-items: end;
    justify-content: ${props => props.mine ? "flex-end" : "flex-start"};
    padding: 0 10px;
`;

const ChatWholeWrapper = styled.div`
    max-width: 70%;
    display: flex;
    flex-direction: row;
    align-items: end;

    .chat-time {
        font-size: 12px;
        color: #7d7d7d;
        margin: 0px 10px;
    }
`;


const ChatBubbleWrapper = styled.div`
    background-color: #404243;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px;
    color: white;
`;

const MineChatBubbleWrapper = styled.div`
    background-color: #f1f0f0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 10px;
    color: black;
`;