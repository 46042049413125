import React from "react";
import strings from "../lang/strings";

import Find from "../pages/auth/find";
import Login from "../pages/auth/login";
import Signup from "../pages/auth/signup";
import Planner from "../pages/cabinet/planner";
import Report from "../pages/cabinet/report";
import ChewingTalk from "../pages/chewingtalk/chewingtalk";
import Attendance from "../pages/class/attendance/attendance";
import Class from "../pages/class/class";
import CreateExam from "../pages/class/exam/create_exam";
import Exam from "../pages/class/exam/exam";
import ExamStudent from "../pages/class/exam/exam_student";
import ExamTeacher from "../pages/class/exam/exam_teacher";
import StartExam from "../pages/class/exam/start_exam";
import MissionList from "../pages/class/missions/missionList";
import MissionListInfo from "../pages/class/missions/missionListInfo";
import Notification from "../pages/class/notification/notification";
import NotificationInfo from "../pages/class/notification/notificationInfo";
import CreateQuiz from "../pages/class/quiz/create_quiz";
import Quiz from "../pages/class/quiz/quiz";
import QuizStudent from "../pages/class/quiz/quiz_student";
import QuizTeacher from "../pages/class/quiz/quiz_teacher";
import Clinic from "../pages/clinic/clinic";
import ConsultHistory from "../pages/clinic/consult_history";
import ExpertDetail from "../pages/clinic/expert_detail";
import CreateCurriculum from "../pages/curriculum/create_curriculum";
import CreateCurriculumLobby from "../pages/curriculum/create_curriculum_lobby";
import CustomizeCurriculum from "../pages/curriculum/customize_curriculum";
import EditCurriculum from "../pages/curriculum/edit/edit_curriculum";
import ProblemSolving from "../pages/curriculum/problem_solving";
import RoadMap from "../pages/curriculum/roadmap";
import StudentCurriculumInfo from "../pages/curriculum/student_curriculum_info";
import TeacherCurriculumInfo from "../pages/curriculum/teacher_curriculum_info";
import CurriculumTest from "../pages/curriculum/test";
import TestReview from "../pages/curriculum/test_review";
import TestStore from "../pages/curriculum/test_store";
import LandingAI from "../pages/landing/Page/AI";
import LandingClass from "../pages/landing/Page/Class";
import LandingMain from "../pages/landing/Page/Main";
import LandingPlatForm from "../pages/landing/Page/PlatForm";
import Bill_management from "../pages/payment/bill_management";
import Main from "../pages/payment/main";
import Manage_shipment_storage from "../pages/payment/manage_shipment_storage";
import Ranking from "../pages/ranking/ranking";
import Challenge from "../pages/room/challenge";
import ChallengeRoom from "../pages/room/challenge/challenge_room";
import CreateStudyRoom from "../pages/room/create_study_room";
import Study from "../pages/room/study";
import StudyRoom from "../pages/room/study/study_room";
import Shop from "../pages/shop/shop";
import Cloud from "../pages/cabinet/cloud";

export const Extra = [
    {
        path: '/login', title: strings.login.login, exact: true, private: false,
        component: () => <Login/>
    },
    {
        path: '/landing/main', title: "츄잉", exact: true, private: false,
        component: () => <LandingMain/>
    },
    {
        path: '/landing/class', title: "클래스", exact: true, private: false,
        component: () => <LandingClass/>
    },
    {
        path: '/landing/ai', title: "AI", exact: true, private: false,
        component: () => <LandingAI/>
    },
    {
        path: '/landing/platform', title: "플랫폼", exact: true, private: false,
        component: () => <LandingPlatForm/>
    },
    {
        path: '/signup/:step', title: strings.signup, exact: true, private: false,
        component: () => <Signup/>
    },
    {
        path: '/class/mission_list/:class_id', title: strings.mission_list, exact: true, private: true,
        component: () => <MissionList/>
    },
    {
        path: '/class/notification/:class_id', title: strings.notification, exact: true, private: false,
        component: () => <Notification/>
    },
    {
        path: '/class/notification_info/:class_id/:notification_id', title: strings.mission_list, exact: true, private: false,
        component: () => <NotificationInfo/>
    },
    {
        path: '/class/mission_info/:class_id/:mission_id/:sort', title: strings.mission_list, exact: true, private: false,
        component: () => <MissionListInfo/>
    },
    {
        path: '/class/attendance/:class_id', title: strings.class_attendacne, exact: true, private: false,
        component: () => <Attendance/>
    },
    {
        path: '/find/:type', title: strings.signup, exact: true, private: false,
        component: () => <Find/>
    },
    {
        path: '/room/create_study_room/:type', title: strings.create_study_room, exact: true, private: false,
        component: () => <CreateStudyRoom/>
    },
    {
        path: '/room/study/:type', title: strings.my_room, exact: true, private: false,
        component: () => <Challenge/>
    },
    {
        path: '/room/study/study_room/:id', title: strings.study_room, exact: true, private: true,
        component: () => <StudyRoom/>
    },
    {
        path: '/room/challenge/challenge_room/:id', title: strings.challenge_room, exact: true, private: true,
        component: () => <ChallengeRoom/>
    },
    {
        path: '/clinic/consult_history/:clinic_id', title: strings.consultation_history, exact: true, private: true,
        component: () => <ConsultHistory/>
    },
    {
        path: '/clinic/expert_detail/:expert_id/:tab', title: strings.consultation_history, exact: true, private: true,
        component: () => <ExpertDetail/>
    },
    {
        path: '/room/study/conference', title: strings.menu.conference_room, exact: true, private: true,
        component: () => <Challenge/>
    },
    {
        path: '/curriculum/create_curriculum/:step', title: strings.create_curriculum, exact: true, private: false,
        component: () => <CreateCurriculum/>
    },
    {
        path: '/curriculum/teacher_curriculum_info/:id', title: strings.create_curriculum, exact: true, private: false,
        component: () => <TeacherCurriculumInfo/>
    },
    {
        path: '/curriculum/student_curriculum_info/:id', title: strings.create_curriculum, exact: true, private: false,
        component: () => <StudentCurriculumInfo/>
    },
    {
        path: '/curriculum/edit_curriculum/:id/:step', title: strings.create_curriculum, exact: true, private: false,
        component: () => <EditCurriculum/>
    },
    {
        path: '/curriculum/test/:id', title: strings.create_curriculum, exact: true, private: false,
        component: () => <CurriculumTest/>
    },
    {
        path: '/curriculum/test_review/:id', title: strings.create_curriculum, exact: true, private: false,
        component: () => <TestReview/>
    },
    {
        path: '/curriculum/problem_solving/:id', title: strings.create_curriculum, exact: true, private: false,
        component: () => <ProblemSolving/>
    },
    {
        path: '/ranking',
        title: strings.menu.ranking,
        exact: true,
        private: true,
        component: () => <Ranking/>
    },
    {
        path: '/chewingTalk',
        title: "츄잉톡",
        exact: true,
        private: true,
        component: () => <ChewingTalk/>
    },
    {
        path: '/shop',
        icon_inactive: '/assets/image/icon_menu_shop_inactive.png',
        icon_active: '/assets/image/icon_menu_shop_active.png',
        title: strings.menu.chewing_shop,
        exact: true,
        private: true,
        component: () => <Shop/>
    },
    {
        path: '/class/quiz/:class', title: strings.exam_room, exact: true, private: false,
        component: () => <Quiz/>
    },
    {
        path: '/exam/:class', title: strings.exam_room, exact: true, private: false,
        component: () => <Exam/>
    },
    {
        path: '/exam/:class/add/:exam_id', title: strings.create_exam, exact: true, private: false,
        component: () => <CreateExam/>
    },
    {
        path: '/exam/teacher/:exam_id', title: strings.exam_detail, exact: true, private: false,
        component: () => <ExamTeacher/>
    },
    {
        path: '/exam/start/:exam_id', title: strings.exam, exact: true, private: false,
        component: () => <StartExam/>
    },
    {
        path: '/exam/student/:exam_id', title: strings.exam_result, exact: true, private: false,
        component: () => <ExamStudent/>
    },
    {
        path: '/quiz/:class/add/:quiz_id', title: strings.create_quiz, exact: true, private: false,
        component: () => <CreateQuiz/>
    },
    {
        path: '/quiz/teacher/:quiz_id', title: strings.quiz_detail, exact: true, private: false,
        component: () => <QuizTeacher/>
    },
    {
        path: '/quiz/student/:quiz_id', title: strings.quiz_result, exact: true, private: false,
        component: () => <QuizStudent/>
    },
    {
        path: '/cabinet/cloud',
        icon_inactive: '/assets/image/icon_menu_cloud_inactive.png',
        icon_active: '/assets/image/icon_menu_cloud_active.png',
        title: strings.menu.cloud,
        exact: true,
        private: true,
        component: () => <Cloud/>
    },
];

const Menu = [
    // {
    //     path: '/dashboard',
    //     icon_inactive: '/assets/image/icon_menu_lounge_inactive.png',
    //     icon_active: '/assets/image/icon_menu_lounge_active.png',
    //     title: strings.menu.lounge,
    //     exact: true,
    //     private: true,
    //     component: () => <Dashboard/>
    // },
    {
        path: '/class',
        icon_inactive: '/assets/image/icon_class_inactive.png',
        icon_active: '/assets/image/icon_class_active.png',
        title: strings.menu.class,
        children: [
            {
                path: '/class', title: "홈", exact: true, private: true,
                component: () => <Class/>
            },
            {
                path: `/class/mission_list/:class_id`, title: strings.mission_list, exact: true, private: true,
                component: () => <MissionList/>
            },
            {
                path: '/class/notification/:class_id', title: "공지사항", exact: true, private: false,
                component: () => <Notification/>
            },
        ]

    },
    {
        path: '/room/study',
        icon_inactive: '/assets/image/icon_menu_study_cafe_inactive.png',
        icon_active: '/assets/image/icon_menu_study_cafe_active.png',
        title: strings.menu.study_cafe,
        children: [
            {
                path: '/room/study', title: strings.menu.study_room, exact: true, private: true,
                component: () => <Study/>
            },
            {
                path: '/room/study/conference', title: strings.menu.conference_room, exact: true, private: true,
                component: () => <Challenge/>
            },
        ]
    },
    {
        
        path: '/clinic',
        icon_inactive: '/assets/image/icon_menu_ai_coach_inactive.png',
        icon_active: '/assets/image/icon_menu_ai_coach_active.png',
        title: strings.menu.clinic,
        exact: true,
        private: true,
        component: () => <Clinic/>
    },
    {
        path: '/curriculum/create_curriculum_lobby' ,
        icon_inactive: '/assets/image/icon_menu_curriculum_inactive.png',
        icon_active: '/assets/image/icon_menu_curriculum_active.png',
        title: strings.menu.curriculum,
        children: [
            {
                path: '/curriculum/create_curriculum_lobby', title: strings.menu.create_curriculum, exact: true, private: true,
                component: () => <CreateCurriculumLobby/>
            },
            {
                path: '/curriculum/roadmap', title: strings.menu.mock_exam, exact: true, private: true,
                component: () => <RoadMap/>
            },
        ],
        user_type: 'EXPERT'
    }
    ,
    {
        path: '/curriculum/customize_curriculum' ,
        icon_inactive: '/assets/image/icon_menu_curriculum_inactive.png',
        icon_active: '/assets/image/icon_menu_curriculum_active.png',
        title: strings.menu.curriculum,
        children: [
            {
                path: '/curriculum/customize_curriculum', title: strings.menu.customize_curriculum, exact: true, private: true,
                component: () => <CustomizeCurriculum/>
            },
            {
                path: '/curriculum/roadmap', title: strings.menu.mock_exam, exact: true, private: true,
                component: () => <RoadMap/>
            },
        ],
        user_type: 'STUDENT'
    },
    {
        path: '/cabinet/cloud/planner',
        icon_inactive: '/assets/image/icon_menu_planner_inactive.png',
        icon_active: '/assets/image/icon_menu_planner_active.png',
        title: strings.menu.planner,
        exact: true,
        private: true,
        component: () => <Planner/>,
        user_type: 'STUDENT'
        
    },
    {
        path: '/curriculum/store',
        title: "테스트 보관함",
        icon_inactive: '/assets/image/icon_menu_cloud_inactive.png',
        icon_active: '/assets/image/icon_menu_cloud_active.png',
        exact: true,
        private: true,
        component: () => <TestStore/>,
        user_type: 'STUDENT'
    },
    {
        path: '/cabinet/cloud/report',
        icon_inactive: '/assets/image/icon_menu_datalab_inactive.png',
        icon_active: '/assets/image/icon_menu_datalab_active.png',
        title: strings.menu.data_lab,
        exact: true,
        private: true,
        component: () => <Report/>,
        user_type: 'STUDENT'
    }, 
    {
        path: '/payment/main',
        icon_inactive: '/assets/image/icon_menu_payment_inactive.png',
        icon_active: '/assets/image/icon_menu_payment_active.png',
        title: strings.menu.payment,
        children: [
            {
                path: '/payment/main', title: strings.menu.main, exact: true, private: true,
                component: () => <Main/>
            },
            {
                path: '/payment/bill_management', title: strings.menu.bill_management, exact: true, private: true,
                component: () => <Bill_management/>
            },
            {
                path: '/payment/manage_shipment_storage', title: strings.menu.send_receive_management, exact: true, private: true,
                component: () => <Manage_shipment_storage/>
            },
            // {
            //     path: '/payment/sales_report', title: strings.menu.sales_report, exact: true, private: true,
            //     component: () => <SalesReport/>
            // },

        ],
        user_type: 'EXPERT'
    },
    

    // {
    //     path: '/shop',
    //     icon_inactive: '/assets/image/icon_menu_shop_inactive.png',
    //     icon_active: '/assets/image/icon_menu_shop_active.png',
    //     title: strings.menu.chewing_shop,
    //     exact: true,
    //     private: true,
    //     component: () => <Shop/>
    // },
    // {
    //     path: '/ranking',
    //     icon_inactive: '/assets/image/icon_menu_rank_inactive.png',
    //     icon_active: '/assets/image/icon_menu_ranking_active.png',
    //     title: strings.menu.ranking,
    //     exact: true,
    //     private: true,
    //     component: () => <Ranking/>
    // }
];

export default Menu;
