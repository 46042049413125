import React, { useContext, useEffect, useState, useRef } from "react";
import { Page_settings } from "../../config/page_settings";
import { rootStore } from "../../mobx/store";
import styled from "styled-components";
import { observer } from "mobx-react";

import fonts from "../../design/typography";
import colors from "../../design/colors";
import Button from "../../components/button";
import PlusIcon from "../../components/icons/plus";
import { IClassMemberList } from "../../type/classroom";
import AddStudentModal from "./modals/add_student_modal"
import { getFilePath } from "../../utils/image";
import IconMenu from "../../components/icons/menu";
import IconPhone from "../../components/icons/phone";
import { Modal, ModalBody } from "reactstrap";
import StudentInfoModal from "./modals/student_info_modal";
import ImageCircle from "../../components/image_circle";
import NoClassPage from "../no_class";

const ClassManageStudent = observer(() => {
    const context: any = useContext(Page_settings);
    const [members, setMembers] = useState<IClassMemberList[]>([]);
    const classId = rootStore.getClassId;
    const [memberFecthStatus, setMemberFecthStatus] = useState<boolean>(false);
    const [isOpenAddStudentModal, setIsOpenAddStudentModal] = useState<boolean>(false);
    const [openMenu, setOpenMenu] = useState<{index: number, menuType: "delete" | "phone"} | null>(null);
    const [membersToDeleteId, setMembersToDeleteId] = useState<number>(-1);
    const [infoModalIndex, setInfoModalIndex] = useState<number>(-1);
    const menuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setOpenMenu(null);
            }
        };

        if (openMenu !== null) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [openMenu]);

    useEffect(() => {
        if (classId) {
            context.get(`/class/${classId}/member`, {}, response => {
                setMembers(response);
            });
        }
    }, [classId, memberFecthStatus]);

    
    

    return (
        classId ?
        <div style={{ width: "100%", height: "100%", padding: "24px 40px 0 40px", overflowY: "auto" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "48px" }}>
                <span style={{ ...fonts.title2SemiBold, color: colors.gray900 }}>학생목록</span>
                <Button size="medium" 
                width="90px"
                height="36px"
                text={<div style={{ ...fonts.label4Medium, color: colors.white, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <PlusIcon color={colors.white} width="16px" height="16px"/>
                    <span style={{ marginLeft: "4px" }}>학생 추가</span></div>} 
                onClick={() => setIsOpenAddStudentModal(true)} />
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "2px" }}>
                <span style={{ ...fonts.body3SemiBold, color: colors.gray600 }}>총 </span>
                <span style={{ ...fonts.body3SemiBold, color: colors.blue800 }}>{members.length}</span>
                <span style={{ ...fonts.body3SemiBold, color: colors.gray600 }}>명</span>
            </div>
            {
                members.length === 0 && (
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: "190px" }}>
                        <img src={"/assets/image/sad_rai.png"} alt="no_student" style={{ width: "152px", height: "113px" }} />
                        <span style={{ ...fonts.body3SemiBold, color: colors.gray400, marginTop: "24px" }}>아직 등록된 학생이 없습니다.</span>
                        <span style={{ ...fonts.body3SemiBold, color: colors.gray400 }}>지금 바로 학생을 등록해보세요!</span>
                    </div>
                )
            }{
                members.length > 0 && (
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start", marginTop: "24px" , gap: "24px" , flexWrap: "wrap"}}>
                        {members.map((member, index) => (
                            <MemberBox key={member.memberId}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between", height: "100%", width: "100%"}}>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start",height: "100%", width: "100%" , cursor: "pointer"}} onClick={() => setInfoModalIndex(index)}>
                                        <ImageCircle src={getFilePath(member.profile, "/assets/image/default_profile.png")} size={48} style={{ marginRight: "12px" }} />
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                            <span style={{ ...fonts.body3SemiBold, color: colors.gray900 }}>{member.name}</span>
                                            <span style={{ ...fonts.label4Medium, color: colors.gray500 }}>{member.school ?? " - "}</span>
                                        </div>
                                    </div>
                                    <div ref={menuRef} style={{ display: "flex" , flexDirection: "row", alignItems: "center", justifyContent: "center", cursor: "pointer", position: "relative" }}
                                        onClick={() => {
                                            if (openMenu?.index === index) {
                                                setOpenMenu(null);
                                            } else {
                                                setOpenMenu({index: index, menuType: "delete"});
                                            }
                                        }}>
                                            <IconMenu width="24px" height="24px" color={colors.gray900} />
                                            {openMenu?.index === index && openMenu?.menuType === "delete" && (
                                                <DeleteBox onClick={() => {
                                                    setMembersToDeleteId(member.memberId);
                                                }}>
                                                    <span style={{ ...fonts.body3Regular, color: colors.warning }}>삭제하기</span>
                                                </DeleteBox>
                                            )}
                                    </div>
                                </div>
                                <PhoneBox isOpen={openMenu?.index === index && openMenu?.menuType === "phone"} onClick={() => {
                                    if (openMenu?.index === index) {
                                        setOpenMenu(null);
                                    } else {
                                        setOpenMenu({index: index, menuType: "phone"});
                                    }
                                }}>
                                    <IconPhone width="24px" height="24px" color={colors.blue600} />
                                </PhoneBox>
                                {openMenu?.index === index && openMenu?.menuType === "phone" && (
                                    <div style={{ position: "absolute", bottom: "60px", right: "20px", width: "120px", height: "40px",borderRadius: "8px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: colors.gray600, padding: "0 12px" }}>
                                        <span style={{ ...fonts.label4Regular, color: colors.white }}>{member.phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')}</span>
                                    </div>
                                )}
                            </MemberBox>
                        ))}
                    </div>
                )
            }
            {
                isOpenAddStudentModal && (
                    <AddStudentModal onClose={() => {setIsOpenAddStudentModal(false); setMemberFecthStatus(!memberFecthStatus)}} classId={classId} memberList={members.map(member => member.memberId)} />
                )
            }
            {
                membersToDeleteId !== -1 && (
                    <DeleteModal toggle={() => setMembersToDeleteId(-1)} onDelete={() => {
                        context.delete(`/class/${classId}/member`, {
                            memberIds: [membersToDeleteId]
                        }, response => {
                            setMemberFecthStatus(!memberFecthStatus);
                        });
                        setMembersToDeleteId(-1);
                    }} />
                )
            }
            {
                infoModalIndex !== -1 && (
                    <StudentInfoModal toggle={() => setInfoModalIndex(-1)} studentInfo={members[infoModalIndex]} classId={classId} />
                )
            }

        </div>
        :
        <NoClassPage userType={rootStore.getProfile?.userType ?? "STUDENT"}/>

    );
});


const DeleteModal = ({toggle, onDelete}: {toggle: () => void, onDelete: () => void}) => {
    return (
        <Modal isOpen={true} toggle={toggle} size="sm">
            <ModalBody style={{ padding: "48px 24px 24px 24px" }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginBottom: "32px" }}>
                    <span style={{ ...fonts.label4SemiBold, color: colors.gray900 }}>해당 학생을 클래스에서 삭제하시겠습니까?</span>
                    <span style={{ ...fonts.label4SemiBold, color: colors.gray900 }}>학생과 관련된 정보들이 저장되지 않습니다</span>
                </div>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: "8px" }}>
                    <Button size="medium" width="100px" height="36px" text="취소" onClick={toggle} buttonColor={colors.gray100} textColor={colors.gray400} />
                    <Button size="medium" width="100px" height="36px" text="삭제" onClick={onDelete} buttonColor={colors.warning} textColor={colors.white} />
                </div>
            </ModalBody>
        </Modal>
    )
};

export default ClassManageStudent;

const MemberBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 280px;
    height: 132px;
    padding: 20px;
    background-color: ${colors.white};
    border-radius: 16px;
    position: relative;
`;

const DeleteBox = styled.div`
    position: absolute;
    top: 20px;
    right: -62px;
    width: 72px;
    height: 44px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.white};
    z-index: 2;
    border: 1px solid ${colors.gray100};
`;

const PhoneBox = styled.div<{isOpen: boolean}>`
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 36px;
    height: 36px;
    border: 1px solid ${colors.blue600};
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.isOpen ? colors.blue50 : "transparent"};
`;