import { inject } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../components/button";
import SelectBox from "../../components/select_box";
import TextField from "../../components/text_field";
import { Page_settings } from "../../config/page_settings";
import colors from "../../design/colors";
import fonts from "../../design/typography";
import strings from "../../lang/strings";
import { useNavigate } from "react-router-dom";
import Const from "../../config/const";
import { IconEyeOpen, IconEyeClosed } from "../../components/icons/eye";

const Find = (props) => {
  const context: any = useContext(Page_settings);
  const [email, setEmail] = useState("");
  const [phone, onChangePhone] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [step, setStep] = useState(1);

  const onConfirm = () => {
    context.put(
      "/auth/change/password",
      {
        email,
        authCode: code,
        newPassword,
      },
      (response) => {
        setStep(3);
      }
    );
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: colors.white,
        alignItems: "center",
      }}
    >
      <div style={{ width: "384px" }}>
        {step === 1 && (
          <FindStep1
            context={context}
            email={email}
            setEmail={setEmail}
            phone={phone}
            onChangePhone={onChangePhone}
            code={code}
            setCode={setCode}
            setStep={setStep}
          />
        )}
        {step === 2 && (
          <FindStep2
            newPassword={newPassword}
            setNewPassword={setNewPassword}
            onConfirm={onConfirm}
          />
        )}
        {step === 3 && <FindStep3 />}
      </div>
    </div>
  );
};

const FindStep1 = ({
  context,
  email,
  setEmail,
  phone,
  onChangePhone,
  code,
  setCode,
  setStep,
}: {
  context: any;
  email: string;
  setEmail: (email: string) => void;
  phone: string;
  onChangePhone: (phone: string) => void;
  code: string;
  setCode: (code: string) => void;
  setStep: (step: number) => void;
}) => {
  const phoneRegex = /^(010|011|016|017|018|019)\d{7}$/;
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [duplicatePhone, setDuplicatePhone] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const [remainingTimeText, setRemainingTimeText] = useState("");
  const [isExistEmail, setIsExistEmail] = useState<boolean | null>(null);
  const [invalidCode, setInvalidCode] = useState(false);
  const [wrongCode, setWrongCode] = useState(false);
  const [phoneType, setPhoneType] = useState("");

  const codeSend = (email: string, phone: string) => {
    context.post(
      "/auth/phone-auth",
      {
        email: email,
        phone,
      },
      (response) => {
        setCodeSent(true);
        setRemainingTime(180);
      }
    );
  };

  const codeVerify = (phone: string, code: string) => {
    context.post(
      "/auth/phone-auth/check",
      {
        phone,
        authCode: code,
      },
      (response) => {
        if (response) {
          setStep(2);
        } else {
          setWrongCode(true);
        }
      }
    );
  };

  useEffect(() => {
    if (remainingTime > 0) {
      const interval = setInterval(() => {
        setRemainingTime(remainingTime - 1);
        const minutes = Math.floor(remainingTime / 60);
        const sec = remainingTime % 60;
        setRemainingTimeText(`${minutes}:${sec.toString().padStart(2, "0")}`);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [remainingTime]);

  const checkEmail = (email: string) => {
    context.get(
      "/auth/check-user",
      {
        email,
        loginType: "EMAIL",
      },
      (response) => {
        setIsExistEmail(!response);
      }
    );
  };
  return (
    <>
      <Title>{"비밀번호 재설정"}</Title>
      <div style={{ marginBottom: 16 }}>
        <span style={{ ...fonts.body3SemiBold, color: colors.gray900 }}>
          {"이메일"}
        </span>
      </div>
      <TextField
        placeholder={"이메일을 입력해주세요"}
        value={email}
        complete={email !== ""}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        onBlur={() => {
          if (email !== "") {
            checkEmail(email);
          }
        }}
        error={isExistEmail != null && !isExistEmail}
        helperText={
          isExistEmail != null && !isExistEmail
            ? "이메일이 존재하지 않습니다."
            : ""
        }
      />

      <div style={{ marginBottom: 16, marginTop: 40 }}>
        <span style={{ ...fonts.body3SemiBold }}>{"통신사"}</span>
      </div>
      <SelectBox
        options={[
          {
            label: "SKT",
            onSelect: () => {
              setPhoneType("SKT");
            },
          },
          {
            label: "KT",
            onSelect: () => {
              setPhoneType("KT");
            },
          },
          {
            label: "LG U+",
            onSelect: () => {
              setPhoneType("LGU+");
            },
          },
          {
            label: "SKT 알뜰폰",
            onSelect: () => {
              setPhoneType("SKT 알뜰폰");
            },
          },
          {
            label: "KT 알뜰폰",
            onSelect: () => {
              setPhoneType("KT 알뜰폰");
            },
          },
          {
            label: "LG U+ 알뜰폰",
            onSelect: () => {
              setPhoneType("LGU+ 알뜰폰");
            },
          },
        ]}
        selectedOptionLabel={""}
        disabled={false}
      />
      <div style={{ marginBottom: 16, marginTop: 40 }}>
        <span style={{ ...fonts.body3SemiBold }}>{strings.phone}</span>
      </div>
      <RowWrapper style={{ alignItems: "flex-start" }}>
        <TextField
          placeholder={"‘-’ 제외한 휴대폰 번호를 입력해주세요"}
          value={phone}
          width="calc(100% - 80px)"
          onChange={(e) => {
            onChangePhone(e.target.value);
            if (phoneRegex.test(phone)) {
              setInvalidPhone(false);
            } else {
              setInvalidPhone(true);
            }
            setDuplicatePhone(false);
            setCodeSent(false);
          }}
          error={invalidPhone || duplicatePhone}
          helperText={
            invalidPhone
              ? "올바른 휴대폰 번호를 입력해주세요"
              : duplicatePhone
              ? "이미 가입된 휴대폰 번호입니다."
              : ""
          }
          complete={codeSent}
        />
        <Button
          size="medium"
          text={"인증"}
          onClick={() => {
            codeSend(email, phone);
          }}
          disabled={
            invalidPhone ||
            isExistEmail == null ||
            !isExistEmail ||
            phone === "" ||
            phoneType === ""
          }
          width="72px"
          height="48px"
        />
      </RowWrapper>
      {codeSent && (
        <>
          <div style={{ marginBottom: 16, marginTop: 40 }}>
            <span style={{ ...fonts.body3SemiBold }}>{"인증번호"}</span>
          </div>
          <div style={{ width: "100%", position: "relative" }}>
            <TextField
              placeholder={"인증번호 6자리"}
              value={code}
              onChange={(e) => {
                const inputValue = e.target.value.replace(/\D/g, ""); // Allow only digits
                setCode(inputValue);
                if (inputValue.length === 6) {
                  setInvalidCode(false);
                } else {
                  setInvalidCode(true);
                }
                setWrongCode(false);
              }}
              error={wrongCode || invalidCode}
              helperText={wrongCode ? "인증번호가 틀렸습니다." : ""}
            />
            <span
              style={{
                position: "absolute",
                top: 15.5,
                right: 30,
                width: 10,
                height: 10,
                color: colors.warning,
                fontSize: fonts.body3Regular.fontSize,
                lineHeight: fonts.body3Regular.lineHeight,
                fontFamily: fonts.body3Regular.fontFamily,
              }}
            >
              {remainingTimeText}
            </span>
          </div>
          <RowWrapper
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: 16,
            }}
          >
            <span style={{ ...fonts.body3SemiBold }}>
              {"인증번호를 받지 못하셨나요?"}
            </span>
            <span
              style={{
                ...fonts.body3SemiBold,
                color: colors.blue600,
                cursor: "pointer",
              }}
              onClick={() => {
                codeSend(email, phone);
              }}
            >
              {"다시 받기"}
            </span>
          </RowWrapper>
        </>
      )}
      <Button
        size="medium"
        height="48px"
        text={"다음"}
        onClick={() => {
          if (invalidPhone || invalidCode || !codeSent || remainingTime === 0) {
            return;
          }
          codeVerify(phone, code);
        }}
        disabled={
          invalidPhone || invalidCode || !codeSent || remainingTime === 0
        }
        style={{ marginTop: 40 }}
      />
    </>
  );
};

const FindStep2 = ({
  newPassword,
  setNewPassword,
  onConfirm,
}: {
  newPassword: string;
  setNewPassword: (newPassword: string) => void;
  onConfirm: () => void;
}) => {
  const passwordRegex = Const.passwordRegex;
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [invalidNewPassword, setInvalidNewPassword] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [invalidPwdConfirm, setInvalidPwdConfirm] = useState(true);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const checkNewPassword = (newPassword: string) => {

    console.log(newPassword);
    console.log(passwordRegex.test(newPassword));

    if (passwordRegex.test(newPassword)) {
      setInvalidNewPassword(false);
    } else {
      setInvalidNewPassword(true);
    }
  };
  return (
    <>
      <Title>{"비밀번호 재설정"}</Title>
      <div style={{ ...fonts.body3SemiBold, marginBottom: 16 }}>
        {strings.password}
      </div>
      <div style={{ position: "relative", marginBottom: 40 }}>
        <TextField
          type={showPassword ? "text" : "password"}
          placeholder={"비밀번호를 입력해주세요"}
          value={newPassword}
          complete={newPassword !== "" && !invalidNewPassword}
          onChange={(e) => {
            setNewPassword(e.target.value);
            checkNewPassword(e.target.value);
            if (e.target.value !== passwordConfirm) {
              setInvalidPwdConfirm(true);
            } else {
              setInvalidPwdConfirm(false);
            }
          }}
          helperText={"영소문자 필수, 숫자, 특수문자, 영대문자 중 2개 이상 (8~20자)"}
          error={newPassword !== "" && invalidNewPassword}
        />
        {newPassword != "" && (
          <Eye
            onMouseDown={() => setShowPassword(true)}
            onMouseUp={() => setShowPassword(false)}
            onTouchStart={() => setShowPassword(true)}
            onTouchEnd={() => setShowPassword(false)}
          >
            {showPassword ? <IconEyeOpen /> : <IconEyeClosed />}
          </Eye>
        )}
      </div>
      <div style={{ ...fonts.body3SemiBold, marginBottom: 16 }}>
        {"비밀번호 확인"}
      </div>
      <div style={{ position: "relative", marginBottom: 40 }}>
        <TextField
          type={showPasswordConfirm ? "text" : "password"}
          placeholder={"비밀번호를 입력해주세요."}
          value={passwordConfirm}
          complete={passwordConfirm !== "" && !invalidPwdConfirm}
          onChange={(e) => {
            setPasswordConfirm(e.target.value);
            if (e.target.value != newPassword) {
              setInvalidPwdConfirm(true);
            } else {
              setInvalidPwdConfirm(false);
            }
          }}
          error={passwordConfirm !== "" && invalidPwdConfirm}
          helperText={
            invalidPwdConfirm
              ? "비밀번호가 일치하지 않습니다."
              : "비밀번호가 일치합니다"
          }
        />
        {passwordConfirm != "" && (
          <Eye
            onMouseDown={() => setShowPasswordConfirm(true)}
            onMouseUp={() => setShowPasswordConfirm(false)}
            onTouchStart={() => setShowPasswordConfirm(true)}
            onTouchEnd={() => setShowPasswordConfirm(false)}
          >
            {showPasswordConfirm ? <IconEyeOpen /> : <IconEyeClosed />}
          </Eye>
        )}
      </div>
      <Button
        size="large"
        text={"다음"}
        onClick={() => {
          if (invalidNewPassword || invalidPwdConfirm) {
            return;
          }
          onConfirm();
        }}
        disabled={invalidNewPassword || invalidPwdConfirm}
        height="44px"
      />
    </>
  );
};

const FindStep3 = () => {
  const navigate = useNavigate();
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" ,  marginTop: 170}}>
      <img src={"/assets/image/ok_rai.png"} style={{ width: 200, height: 200, marginLeft: 70 }} alt="" />
      <div style={{ ...fonts.title3SemiBold, marginTop: 40, marginBottom: 56, textAlign: "center" }}>
        <p>비밀번호 변경이 완료되었습니다</p>
        <p>변경된 비밀번호로 다시 로그인 해주세요</p>
      </div>
      <Button size="large" text={"다시 로그인하기"} onClick={() => {
        navigate("/login");
      }} height="44px" />
    </div>
  );
};

export default inject("rootStore")(Find);

const Title = styled.p`
  font-family: ${fonts.heading1Bold.fontFamily};
  font-size: ${fonts.heading1Bold.fontSize};
  line-height: ${fonts.heading1Bold.lineHeight};
  color: ${colors.gray900};
  margin-bottom: 56px;
  text-align: center;
  margin-top: 80px;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

const Eye = styled.button`
  position: absolute;
  right: 20px;
  top: 16px;
  border: none;
  background: none;
`;
