import React, { useContext, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { IMemberInfo } from "../../../type/member";
import IconX from "../../icons/x";
import colors from "../../../design/colors";
import fonts from "../../../design/typography";
import Pencil from "../../icons/pencil";
import { ChevronRight, ChevronLeft } from "../../icons/chevron";
import TextField from "../../text_field";
import Button from "../../button";
import { Page_settings } from "../../../config/page_settings";
import { useNavigate } from "react-router-dom";
import { rootStore } from "../../../mobx/store";
import { Modal, ModalBody } from "reactstrap";
import { getFilePath } from "../../../utils/image";
import ImageCircle from "../../image_circle";
import Const from "../../../config/const";
import { IconEyeOpen, IconEyeClosed } from "../../icons/eye";
import SearchBar from "../../search_bar";
import useDebounce from "../../../hooks/useDebounce";
import Checkbox from "../../checkbox";

interface SettingModalPrivacyContentProps{
    refetchMemberInfo: () => void;
    memberInfo: IMemberInfo;
    toggle: () => void;
}


const PrivacyContent = ({ memberInfo, toggle, refetchMemberInfo }: SettingModalPrivacyContentProps) => {
    const [pageType, setPageType] = useState<"MENU" | "PROFILE" | "PASSWORD" | "PASSWORD_CHANGE">("MENU");
    return (
        <>
            {pageType === "MENU" && <MenuContent memberInfo={memberInfo} toggle={toggle} goToProfile={() => setPageType("PROFILE")} goToPassword={() => setPageType("PASSWORD")} refetchMemberInfo={refetchMemberInfo} />}
            {pageType === "PROFILE" && <ProfileContent memberInfo={memberInfo} toggle={toggle} goToMenu={() => setPageType("MENU")} goToPasswordChange={() => setPageType("PASSWORD_CHANGE")} refetchMemberInfo={refetchMemberInfo} />}
            {pageType === "PASSWORD" && <PasswordContent memberInfo={memberInfo} toggle={toggle} goToMenu={() => setPageType("MENU")} goToPasswordChange={() => setPageType("PASSWORD_CHANGE")} refetchMemberInfo={refetchMemberInfo} />}
            {pageType === "PASSWORD_CHANGE" && <PasswordChangeContent memberInfo={memberInfo} toggle={toggle} goToPassword={() => setPageType("PASSWORD")} refetchMemberInfo={refetchMemberInfo} />}
        </>
    )
}

export default PrivacyContent;
interface MenuContentProps extends SettingModalPrivacyContentProps {
    goToProfile: () => void;
    goToPassword: () => void;
}

const MenuContent = ({ memberInfo, toggle, goToProfile, goToPassword }: MenuContentProps) => {

    const context: any = useContext(Page_settings);
    const navigate = useNavigate();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
    const platform = rootStore.getMe?.platform;

    const onLogout = () => {
        context.post('auth/logout', {
            platform: platform
        }, (response: any) => {
            navigate('/login');
            rootStore.signOut();
        });
    }

    const onImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            context.post('upload/image/class', formData, (response) => {
                context.put('member/info', {
                    profile: response.url
                }, (response: any) => {
                    navigate(0);
                });
            });
        }
    }

    const [showWithdrawModal, setShowWithdrawModal] = useState<boolean>(false);

    const onWithdraw = () => {
        context.delete('auth/member', {
        }, (response: any) => {
            navigate('/login');
            rootStore.signOut();
        });
    }

    return (
        <ContentWrapper>
            <input type="file" accept="image/*" ref={fileInputRef} style={{display: "none"}} onChange={onImageChange} />
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: 36}}>
                <span style={{...fonts.label2SemiBold, color: colors.gray900}}>개인정보</span>
                <div style={{cursor: "pointer"}} onClick={toggle}>
                    <IconX width="32px" height="32px" color={colors.gray900} background={colors.gray900}/>
                </div>
            </div>
            <div>
                <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", gap: 24}}>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", cursor: "pointer"}} onClick={goToProfile}>
                        <span style={{...fonts.label3SemiBold, color: colors.gray900}}>프로필 정보 수정</span>
                        <ChevronRight width="28px" height="28px" color={colors.gray500}/>
                    </div>
                    {platform === "EMAIL" && <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", cursor: "pointer"}} onClick={goToPassword}>
                        <span style={{...fonts.label3SemiBold, color: colors.gray900}}>비밀번호 변경</span>
                        <ChevronRight width="28px" height="28px" color={colors.gray500}/>
                    </div>}
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", cursor: "pointer"}} onClick={() => setShowLogoutModal(true)}>
                        <span style={{...fonts.label3SemiBold, color: colors.gray900}}>로그아웃</span>
                        <ChevronRight width="28px" height="28px" color={colors.gray500}/>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", cursor: "pointer"}} onClick={() => setShowWithdrawModal(true)}>
                        <span style={{...fonts.label3SemiBold, color: colors.gray900}}>탈퇴하기</span>
                        <ChevronRight width="28px" height="28px" color={colors.gray500}/>
                    </div>
                </div>
            </div>
            {showWithdrawModal && <WithdrawModal closeWithdrawModal={() => setShowWithdrawModal(false)} onWithdraw={onWithdraw} />}
            {showLogoutModal && <LogoutModal closeLogoutModal={() => setShowLogoutModal(false)} onLogout={onLogout} />}
        </ContentWrapper>
    );
}

const WithdrawModal = ({ closeWithdrawModal, onWithdraw }: { closeWithdrawModal: () => void, onWithdraw: () => void }) => {

    const WarningSpan = styled.span`
        font-family: Pretendard;
        font-weight: 800;
        font-size: 22px;
        line-height: 20px;
        letter-spacing: 0%;
        text-align: center;
        color: #F9B5B5;
    `;

    return (
        <Modal isOpen={true} toggle={closeWithdrawModal} size="md">
            <ModalBody style={{padding: "30px 32px 36px 32px"}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "flex-end"}}>
                    <div style={{cursor: "pointer"}} onClick={closeWithdrawModal}>
                        <IconX width="28px" height="28px" color={colors.gray900} background={colors.gray900}/>
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: 28, marginBottom: 24, position: "relative"}}>
                    <img src="/assets/image/warning_rai.png" style={{width: "172px", height: "184px", marginLeft: 30}}/>
                    <div style={{position: "absolute", top: 36.5, left: 250, display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <WarningSpan>잠깐!</WarningSpan>
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginBottom: 40}}>
                    <span style={{...fonts.body2Medium, color: colors.gray900}}>정말로 탈퇴하시겠습니까?</span>
                    <span style={{...fonts.body2Medium, color: colors.gray900}}>탈퇴 시 츄잉에서 학습 또는 수업을 진행한</span>
                    <span style={{...fonts.body2Medium, color: colors.gray900}}>모든 데이터가 삭제되며 복구할 수 없습니다</span>
                </div>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 12}}>
                    <Button size="medium" width="112px" height="44px" text="취소" onClick={closeWithdrawModal} buttonColor={colors.gray100} textColor={colors.gray400}/>
                    <Button size="medium" width="112px" height="44px" text="탈퇴하기" onClick={onWithdraw} buttonColor={colors.warning}/>
                </div>
            </ModalBody>
        </Modal>
    )
}

interface ProfileContentProps extends SettingModalPrivacyContentProps {
    goToMenu: () => void;
    goToPasswordChange: () => void;
}

const ProfileContent = ({ memberInfo, toggle, goToMenu, goToPasswordChange, refetchMemberInfo }: ProfileContentProps) => {

    const [step, setStep] = useState<number>(1);


    return (
        <ContentWrapper>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: 36}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start"}}>
                    <div style={{cursor: "pointer"}} onClick={goToMenu}>
                        <ChevronLeft width="28px" height="28px" color={colors.gray900}/>
                    </div>
                    <span style={{...fonts.label2SemiBold, color: colors.gray900, marginLeft: 12}}>프로필 정보 수정</span>
                </div>
                <div style={{cursor: "pointer"}} onClick={toggle}>
                    <IconX width="32px" height="32px" color={colors.gray900} background={colors.gray900}/>
                </div>
            </div>
            {step === 1 && <ProfileStep1 goToStep2={() => setStep(2)} memberInfo={memberInfo} goToPasswordChange={goToPasswordChange} />}
            {step === 2 && <ProfileStep2 memberInfo={memberInfo} refetchMemberInfo={refetchMemberInfo}/>}
        </ContentWrapper>
    )
}


const ProfileStep1 = ({ goToStep2, memberInfo, goToPasswordChange }: { goToStep2: () => void, memberInfo: IMemberInfo, goToPasswordChange: () => void }) => {
    const context: any = useContext(Page_settings);
    const [currentPassword, setCurrentPassword] = useState<string>("");
    const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false);
    const [invalidCurrentPassword, setInvalidCurrentPassword] = useState<boolean>(false);

    const doCheck = () => {
        context.post('auth/login', {
            loginType: rootStore.getMe?.platform,
            email: memberInfo?.email,
            password: currentPassword
        }, (response: any) => {
            goToStep2();
        });
    }

    return (
        <>
        <div style={{width: "100%"}}>
            <div style={{...fonts.label4Medium, color: colors.gray600, marginBottom: 12}}>프로필 변경을 위해 현재 비밀번호를 입력해주세요</div>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", width: "100%", marginBottom: 12}}>
                <div style={{...fonts.label4Medium, color: colors.gray600, width: 102}}>현재 비밀번호</div>
                <div style={{width: "calc(100% - 102px)", position: "relative"}}>
                    <TextField size="small" type={showCurrentPassword ? "text" : "password"} width="100%" placeholder="현재 비밀번호를 입력해주세요." value={currentPassword} onChange={(e) => {
                        setCurrentPassword(e.target.value)
                        setInvalidCurrentPassword(false)
                        }} 
                        error={invalidCurrentPassword}
                        helperText={invalidCurrentPassword ? "현재 비밀번호가 일치하지 않습니다." : ""}
                        complete={currentPassword !== "" && !invalidCurrentPassword}
                    />
                    {currentPassword !== "" && (<div style={{position: "absolute", top: 12, right: 16, display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer"}} 
                    onMouseDown={() => setShowCurrentPassword(true)} onMouseUp={() => setShowCurrentPassword(false)}
                        onTouchStart={() => setShowCurrentPassword(true)} onTouchEnd={() => setShowCurrentPassword(false)}>
                        {showCurrentPassword ? <IconEyeOpen width="24px" height="24px" color={colors.gray600}/> : <IconEyeClosed width="24px" height="24px" color={colors.gray600}/>}
                    </div>)}
                </div>
            </div>
        </div>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", width: "100%", marginTop: 20}} > 
            <Button size="medium" width="112px" height="44px" text="확인" onClick={doCheck} disabled={currentPassword.length < 8}/>
        </div>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", width: "100%", marginTop: 20}} > 
            <div style={{display: "flex", flexDirection: "row", cursor: "pointer"}} onClick={goToPasswordChange}>
                <span style={{...fonts.detail2Regular, color: colors.gray600}} >비밀번호 재설정</span>
            </div>
        </div>
        </>
    )
}


const ProfileStep2 = ({ memberInfo, refetchMemberInfo }: { memberInfo: IMemberInfo, refetchMemberInfo: () => void}) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const context: any = useContext(Page_settings);
    const navigate = useNavigate();

    const onImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            context.post('upload/image/class', formData, (response) => {
                context.put('member/info', {
                    profile: response.url
                }, (response: any) => {
                    refetchMemberInfo();
                });
            });
        }
    }
    
    const [showChangeSchoolModal, setShowChangeSchoolModal] = useState<boolean>(false);

    return (
        <>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start"}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", position: "relative"}}>
                    <ImageCircle src={getFilePath(memberInfo?.profile, "/assets/image/default_profile.png")} size={72} />
                    <div style={{position: "absolute", bottom: -6, right: 0, width: 24, height: 24, backgroundColor: colors.gray100, borderRadius: 50, display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer"}}
                        onClick={() => fileInputRef.current?.click()}
                    >
                        <Pencil width="16px" height="16px" color={colors.gray500}/>
                    </div>
                </div>
                <div style={{display: "flex", height: "100%", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between", marginLeft: 24}}>
                    <RowWrapper>
                        <span style={{...fonts.label5Medium, color: colors.gray600, width: 60}}>{"이름"}</span> <span style={{...fonts.label4Medium, color: colors.gray900}}>{memberInfo?.name}</span>
                    </RowWrapper>
                    <RowWrapper>
                        <span style={{...fonts.label5Medium, color: colors.gray600, width: 60}}>{"이메일"}</span> <span style={{...fonts.label4Medium, color: colors.gray900}}>{memberInfo?.email}</span>
                    </RowWrapper>
                    <RowWrapper>
                        <span style={{...fonts.label5Medium, color: colors.gray600, width: 60}}>{"생년월일"}</span> <span style={{...fonts.label4Medium, color: colors.gray900}}>{memberInfo?.birthday.replace(/-/g, '.')}</span>
                    </RowWrapper>
                    <RowWrapper>
                        <span style={{...fonts.label5Medium, color: colors.gray600, width: 60}}>{"전화번호"}</span> <span style={{...fonts.label4Medium, color: colors.gray900}}>{memberInfo?.phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')}</span>
                    </RowWrapper>
                    {memberInfo.userType === "STUDENT" && (
                        <RowWrapper>
                            <span style={{...fonts.label5Medium, color: colors.gray600, width: 60}}>{"학교"}</span> <span style={{...fonts.label4Medium, color: colors.gray900}}>{memberInfo?.school || " - "}</span>
                            <div style={{cursor: "pointer", marginLeft: 8}} onClick={() => setShowChangeSchoolModal(true)}>
                                <Pencil width="16px" height="16px" color={colors.gray500}/>
                            </div>
                        </RowWrapper>
                    )}
                </div>
                <input type="file" accept="image/*" ref={fileInputRef} style={{display: "none"}} onChange={onImageChange} />
            </div>
            {showChangeSchoolModal && <ChangeSchoolModal memberInfo={memberInfo} toggle={() => setShowChangeSchoolModal(false)} refetchMemberInfo={refetchMemberInfo} />}
        </>
    )
}

interface ISchoolInfo {
    SCHUL_NM: string;
    SD_SCHUL_CODE: string;
    ATPT_OFCDC_SC_CODE: string;
}

const ChangeSchoolModal = ({ memberInfo, toggle, refetchMemberInfo }: { memberInfo: IMemberInfo, toggle: () => void, refetchMemberInfo: () => void }) => {

    const context: any = useContext(Page_settings);
    const [isSchoolSelected, setIsSchoolSelected] = useState(false);
    const [havingSchool, setHavingSchool] = useState(true);
    const [schoolList, setSchoolList] = useState<ISchoolInfo[]>([]);
    const [schoolSearchVal, setSchoolSearchVal] = useState("");
    const schoolSearch = useDebounce(schoolSearchVal, 500);
    useEffect(() => {
        if (schoolSearchVal.length > 1) {
            fetchSchoolList(schoolSearchVal, setSchoolList);
        } else {
            setSchoolList([]);
        }
    }, [schoolSearch]);

    const [school, setSchool] = useState<string>("");
    const [schoolCode, setSchoolCode] = useState<string>("");
    const [eduOfficeCode, setEduOfficeCode] = useState<string>("");
    
    const validSchool = !havingSchool || (isSchoolSelected && schoolCode && eduOfficeCode);

    const onConfirm = () => {

        if (havingSchool) {
            context.put('member/info', {
                school: school,
                schoolCode: schoolCode,
                eduOfficeCode: eduOfficeCode
            }, (response: any) => {
                refetchMemberInfo();
                toggle();
            });
        } else {
            context.put('member/info', {
                school: null,
                schoolCode: null,
                eduOfficeCode: null,
                isEditingSchool: true
            }, (response: any) => {
                refetchMemberInfo();
                toggle();
            });
        }
    }

    return (
        <Modal isOpen={true} onClose={toggle}>
            <ModalBody style={{padding: 24}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", marginBottom: 40}}>
                    <span style={{...fonts.body1SemiBold, color: colors.gray900}}>학교 정보 수정</span>
                    <div style={{cursor: "pointer"}} onClick={toggle}>
                        <IconX width="24px" height="24px" color={colors.gray900}/>
                    </div>
                </div>
                <div>
                    <SearchBar
                    placeholder={"학교를 검색해주세요"}
                    value={schoolSearchVal}
                    onChange={(e) => {
                        setSchoolSearchVal(e.target.value);
                        setIsSchoolSelected(false);
                    }}
                    disabled={!havingSchool}
                    options={schoolList.map(schoolCurrent => ({

                        label: schoolCurrent.SCHUL_NM,
                        onSelect: () => {
                            setSchool(schoolCurrent.SCHUL_NM);
                            setSchoolSearchVal(schoolCurrent.SCHUL_NM);
                            setSchoolCode(schoolCurrent.SD_SCHUL_CODE);
                            setEduOfficeCode(schoolCurrent.ATPT_OFCDC_SC_CODE);
                            setIsSchoolSelected(true);
                        }
                    }))}/>
                </div>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", marginTop: 12, marginBottom: 212}}>
                    <Checkbox
                        checked={!havingSchool}
                        setChecked={(checked) => {
                            setHavingSchool(prev => !prev);
                        }}
                    />
                    <span style={{...fonts.body3Medium, color: colors.gray900, marginLeft: 8}}>{"학교를 다니고 있지 않아요"}</span>
                </div>
                <Button size="medium" height="44px" text="완료" onClick={onConfirm} disabled={!validSchool}/>
            </ModalBody>
        </Modal>
    )
}

const fetchSchoolList = async (schoolName: string, setSchoolList: (schools: ISchoolInfo[]) => void) => {
    const NEIS_API_URL = process.env.REACT_APP_NEIS_API_URL!;
    const NEIS_API_KEY = process.env.REACT_APP_NEIS_API_KEY!;
    
    try {
        const response = await fetch(`${NEIS_API_URL}/schoolInfo?pIndex=1&pSize=100&KEY=${NEIS_API_KEY}&SCHUL_NM=${schoolName}&Type=xml`);
        const xmlText = await response.text();
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlText, "text/xml");
        
        const schools: ISchoolInfo[] = [];
        const rows = xmlDoc.getElementsByTagName("row");
        
        for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            schools.push({
                SCHUL_NM: row.getElementsByTagName("SCHUL_NM")[0].textContent?.replace("<![CDATA[", "").replace("]]>", "") || "",
                SD_SCHUL_CODE: row.getElementsByTagName("SD_SCHUL_CODE")[0].textContent?.replace("<![CDATA[", "").replace("]]>", "") || "",
                ATPT_OFCDC_SC_CODE: row.getElementsByTagName("ATPT_OFCDC_SC_CODE")[0].textContent?.replace("<![CDATA[", "").replace("]]>", "") || ""
            });
        }
        
        setSchoolList(schools);
        console.log('파싱된 학교 목록:', schools);
    } catch (error) {
        console.error('API 호출 에러:', error);
    }
}

interface PasswordContentProps extends SettingModalPrivacyContentProps {
    goToMenu: () => void;
    goToPasswordChange: () => void;
}

const PasswordContent = ({ memberInfo, toggle, goToMenu, goToPasswordChange }: PasswordContentProps) => {
    const context: any = useContext(Page_settings);
    const [currentPassword, setCurrentPassword] = useState<string>("");
    const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false);
    const [newPassword, setNewPassword] = useState<string>("");
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

    const passwordRegex = Const.passwordRegex;
    const [invalidCurrentPassword, setInvalidCurrentPassword] = useState<boolean>(false);
    const [invalidNewPassword, setInvalidNewPassword] = useState<boolean>(false);
    const [invalidConfirmPassword, setInvalidConfirmPassword] = useState<boolean>(false);
    

    const handleChangePassword = () => {
        if (currentPassword === "" || newPassword === "" || confirmPassword === "" || invalidNewPassword || invalidConfirmPassword) {
            return;
        }

        try {
            context.post('auth/change/password', {
                password: currentPassword,
                newPassword: newPassword
            }, (response: any) => {
                toggle();
            });
        } catch (error) {
            setInvalidCurrentPassword(true);
        }
    }

    return (
        <ContentWrapper>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: 36}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start"}}>
                    <div style={{cursor: "pointer"}} onClick={goToMenu}>
                        <ChevronLeft width="28px" height="28px" color={colors.gray900}/>
                    </div>
                    <span style={{...fonts.label2SemiBold, color: colors.gray900, marginLeft: 12}}>비밀번호 변경</span>
                </div>
                <div style={{cursor: "pointer"}} onClick={toggle}>
                    <IconX width="32px" height="32px" color={colors.gray900} background={colors.gray900}/>
                </div>
            </div>
            <div style={{width: "100%"}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", width: "100%", marginBottom: 12}}>
                    <div style={{...fonts.label4Medium, color: colors.gray600, width: 102}}>현재 비밀번호</div>
                    <div style={{width: "calc(100% - 102px)", position: "relative"}}>
                        <TextField size="small" type={showCurrentPassword ? "text" : "password"} width="100%" placeholder="현재 비밀번호를 입력해주세요." value={currentPassword} onChange={(e) => {
                            setCurrentPassword(e.target.value)
                            setInvalidCurrentPassword(false)
                            }} 
                            error={invalidCurrentPassword}
                            helperText={invalidCurrentPassword ? "현재 비밀번호가 일치하지 않습니다." : ""}
                            complete={currentPassword !== "" && !invalidCurrentPassword}
                        />
                        {currentPassword !== "" && (<div style={{position: "absolute", top: 12, right: 16, display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer"}} 
                        onMouseDown={() => setShowCurrentPassword(true)} onMouseUp={() => setShowCurrentPassword(false)}
                            onTouchStart={() => setShowCurrentPassword(true)} onTouchEnd={() => setShowCurrentPassword(false)}>
                            {showCurrentPassword ? <IconEyeOpen width="24px" height="24px" color={colors.gray600}/> : <IconEyeClosed width="24px" height="24px" color={colors.gray600}/>}
                        </div>)}
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", width: "100%", marginBottom: 12}}>
                    <div style={{...fonts.label4Medium, color: colors.gray600, width: 102}}>새 비밀번호</div>
                    <div style={{width: "calc(100% - 102px)", position: "relative"}}>
                        <TextField size="small" type={showNewPassword ? "text" : "password"} width="100%" placeholder="새 비밀번호를 입력해주세요." value={newPassword} onChange={(e) => {
                            setNewPassword(e.target.value)
                            if (!passwordRegex.test(e.target.value)) {
                                setInvalidNewPassword(true)
                            } else {
                                setInvalidNewPassword(false)
                            }

                            if (confirmPassword === e.target.value) {
                                setInvalidConfirmPassword(false)
                            } else {
                                setInvalidConfirmPassword(true)
                            }
                        }} 
                            error={invalidNewPassword}
                            helperText={(invalidNewPassword || newPassword === "" )? "8~20자 이내 숫자, 특수문자, 영문자 중 2가지 이상" : ""}
                            complete={newPassword !== "" && !invalidNewPassword}
                        />
                        {newPassword !== "" && (<div style={{position: "absolute", top: 12, right: 16, display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer"}} 
                        onMouseDown={() => setShowNewPassword(true)} onMouseUp={() => setShowNewPassword(false)}
                            onTouchStart={() => setShowNewPassword(true)} onTouchEnd={() => setShowNewPassword(false)}>
                            {showNewPassword ? <IconEyeOpen /> : <IconEyeClosed />}
                        </div>)}
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", width: "100%"}}>
                    <div style={{...fonts.label4Medium, color: colors.gray600, width: 102}}>새 비밀번호 확인</div>
                    <div style={{width: "calc(100% - 102px)", position: "relative"}}>
                        <TextField size="small" type={showConfirmPassword ? "text" : "password"} width="100%" placeholder="비밀번호 확인을 위해 비밀번호를 입력해주세요." value={confirmPassword} onChange={(e) => {
                            setConfirmPassword(e.target.value)
                            if (e.target.value != newPassword) {
                                setInvalidConfirmPassword(true)
                            } else {
                                setInvalidConfirmPassword(false)
                            }
                        }} 
                            error={confirmPassword !== "" && invalidConfirmPassword}
                            helperText={confirmPassword !== "" && invalidConfirmPassword ? "비밀번호가 일치하지 않습니다." : ""}
                            complete={confirmPassword !== "" && !invalidConfirmPassword}
                        />
                        {confirmPassword !== "" && (<div style={{position: "absolute", top: 12, right: 16, display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer"}} 
                            onMouseDown={() => setShowConfirmPassword(true)} onMouseUp={() => setShowConfirmPassword(false)}
                            onTouchStart={() => setShowConfirmPassword(true)} onTouchEnd={() => setShowConfirmPassword(false)}>
                            {showConfirmPassword ? <IconEyeOpen /> : <IconEyeClosed />}
                        </div>)}
                    </div>
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", width: "100%", marginTop: 20}} >
                <div style={{display: "flex", flexDirection: "row", cursor: "pointer"}} onClick={goToPasswordChange}>
                    <span style={{...fonts.detail2Regular, color: colors.gray600}} >비밀번호 재설정</span>
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", width: "100%", position: "absolute", bottom: 24, right: 32}}>
                <Button size="small" width="80px" height="36px" text="변경하기" disabled={currentPassword === "" || newPassword === "" || confirmPassword === "" || invalidNewPassword || invalidConfirmPassword} 
                onClick={handleChangePassword} />
            </div>
        </ContentWrapper>
    )
}


interface PasswordChangeContentProps extends SettingModalPrivacyContentProps {
    goToPassword: () => void;
}

const PasswordChangeContent = ({ memberInfo, toggle, goToPassword }: PasswordChangeContentProps) => {
    const context: any = useContext(Page_settings);
    const [code, setCode] = useState<string>("");
    const [step, setStep] = useState<number>(1);
    const [newPassword, setNewPassword] = useState<string>("");

    const onChangePassword = () => {
        context.put('auth/change/password', {
            email: memberInfo?.email,
            authCode: code,
            newPassword: newPassword
        }, (response: any) => {
            toggle();
        });
    }

    return (
        <ContentWrapper>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: 36}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start"}}>
                    <div style={{cursor: "pointer"}} onClick={goToPassword}>
                        <ChevronLeft width="28px" height="28px" color={colors.gray900}/>
                    </div>
                    <span style={{...fonts.label2SemiBold, color: colors.gray900, marginLeft: 12}}>비밀번호 재설정</span>
                </div>
                <div style={{cursor: "pointer"}} onClick={toggle}>
                    <IconX width="32px" height="32px" color={colors.gray900} background={colors.gray900}/>
                </div>
            </div>
            {step === 1 && <PasswordChangeStep1 memberInfo={memberInfo} context={context} code={code} setCode={setCode} onNext={() => setStep(2)}/>}
            {step === 2 && <PasswordChangeStep2 newPassword={newPassword} setNewPassword={setNewPassword} onChangePassword={onChangePassword}/>}
        </ContentWrapper>
    )
}

interface PasswordChangeStep1Props{
    memberInfo: IMemberInfo;
    context: any;
    code: string;
    setCode: (code: string) => void;
    onNext: () => void;
}

const PasswordChangeStep1 = ({ memberInfo, context, code, setCode, onNext }: PasswordChangeStep1Props) => {
    
    const [isCodeSent, setIsCodeSent] = useState<boolean>(false);
    const [invalidCode, setInvalidCode] = useState<boolean>(false);
    const [isWrongCode, setIsWrongCode] = useState<boolean>(false);
    const [remainingTime, setRemainingTime] = useState<number>(180);

    useEffect(() => {
        const interval = setInterval(() => {
            if (remainingTime > 0) {
                setRemainingTime(remainingTime - 1);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [remainingTime]);

    const remainingTimeText = (remainingTime: number) => {
        const minutes = Math.floor(remainingTime / 60);
        const seconds = remainingTime % 60;
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    }

    const sendCode = () => {
        context.post('auth/phone-auth', {
            phone: memberInfo?.phone
        }, (response: any) => {
            setIsCodeSent(true);
            setRemainingTime(180);
        });
    }

    const verifyCode = () => {
        context.post('auth/phone-auth/check', {
            phone: memberInfo?.phone,
            authCode: code
        }, (response: any) => {
            if (response) {
                onNext();
            } else {
                setIsWrongCode(true);
            }
        });
    }

    return (
        <div style={{width: "100%"}}>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", width: "100%", marginBottom: 12}}>
            <div style={{...fonts.label4Medium, color: colors.gray600, width: 102}}>휴대폰 번호</div>
            <div style={{width: "calc(100% - 168px)", position: "relative"}}>
                <TextField size="small" type="text" width="100%" placeholder="휴대폰 번호를 입력해주세요." disabled={true} value={memberInfo?.phone} />
            </div>
            <div style={{width: "64px", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: 12}}>
                <Button size="small" width="52px" height="44px" text="전송" onClick={sendCode} />  
            </div>
        </div>
        {isCodeSent && (
            <>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", width: "100%", marginBottom: 20}}>
                    <div style={{...fonts.label4Medium, color: colors.gray600, width: 102}}>인증번호</div>
                    <div style={{width: "calc(100% - 102px)", position: "relative"}}>
                        <TextField size="small" type="text" width="100%" placeholder="인증번호 6자리" value={code} onChange={(e) => {
                            const code = e.target.value.replace(/[^0-9]/g, '');
                            setCode(code)
                            if (code.length === 6) {
                                setInvalidCode(false)
                            } else {
                                setInvalidCode(true)
                            }
                            setIsWrongCode(false)
                        }} error={invalidCode || isWrongCode} helperText={invalidCode ? "6자리를 입력해주세요" : isWrongCode ? "인증 코드가 일치하지 않습니다." : ""} complete={code !== "" && !invalidCode && !isWrongCode} />
                        <div style={{position: "absolute", top: 12, right: 16, width: 24, height: 24, display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer"}}>
                            <span style={{...fonts.body3Regular, color: colors.warning}}>{remainingTimeText(remainingTime)}</span>
                        </div>
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", width: "100%", marginTop: 20, cursor: "pointer"}} onClick={sendCode}>
                    <span style={{...fonts.detail2Regular, color: colors.gray600}} >인증번호를 받지 못하셨나요?</span>
                    <span style={{...fonts.detail2Regular, color: colors.blue600, cursor: "pointer", marginLeft: 4}} >다시받기</span>
                </div>
            </>
        )}

        <Button size="small" width="80px" height="36px" text="변경하기" onClick={verifyCode} style={{position: "absolute", bottom: 24, right: 32}} disabled={code === "" || remainingTime === 0 || invalidCode || isWrongCode} />
    </div>
    )
}

interface PasswordChangeStep2Props{
    newPassword: string;
    setNewPassword: (newPassword: string) => void;
    onChangePassword: () => void;
}

const PasswordChangeStep2 = ({ newPassword, setNewPassword, onChangePassword }: PasswordChangeStep2Props) => {

    const [invalidNewPassword, setInvalidNewPassword] = useState<boolean>(false);
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>("");
    const [invalidNewPasswordConfirm, setInvalidNewPasswordConfirm] = useState<boolean>(false);
    const [showNewPasswordConfirm, setShowNewPasswordConfirm] = useState<boolean>(false);

    const passwordRegex = Const.passwordRegex;
    
    useEffect(() => {
        if (!passwordRegex.test(newPassword)) {
            setInvalidNewPassword(true)
        } else {
            setInvalidNewPassword(false)
        }
    }, [newPassword])

    useEffect(() => {
        if (newPasswordConfirm !== newPassword) {
            setInvalidNewPasswordConfirm(true)
        } else {
            setInvalidNewPasswordConfirm(false)
        }
    }, [newPasswordConfirm, newPassword])

    return (
        <div style={{width: "100%"}}>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", width: "100%", marginBottom: 12}}>
                <div style={{...fonts.label4Medium, color: colors.gray600, width: 102}}>새 비밀번호</div>
                <div style={{width: "calc(100% - 102px)", position: "relative"}}>
                    <TextField size="small" type={showNewPassword ? "text" : "password"} width="100%" placeholder="새 비밀번호를 입력해주세요." value={newPassword} onChange={(e) => {
                        setNewPassword(e.target.value)
                        if (!passwordRegex.test(e.target.value)) {
                            setInvalidNewPassword(true)
                        } else {
                            setInvalidNewPassword(false)
                        }
                    }} error={newPassword !== "" && invalidNewPassword} helperText={invalidNewPassword ? "8~20자 이내 숫자, 특수문자, 영문자 중 2가지 이상" : ""} complete={newPassword !== "" && !invalidNewPassword} />
                    <Eye onMouseDown={() => setShowNewPassword(true)} onMouseUp={() => setShowNewPassword(false)}
                        onTouchStart={() => setShowNewPassword(true)} onTouchEnd={() => setShowNewPassword(false)}>
                        <img src={"/assets/image/icon_eye.png"} alt="" />
                    </Eye>
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", width: "100%", marginBottom: 12}}>
                <div style={{...fonts.label4Medium, color: colors.gray600, width: 102}}>새 비밀번호 확인</div>
                <div style={{width: "calc(100% - 102px)", position: "relative"}}>
                    <TextField size="small" type={showNewPasswordConfirm ? "text" : "password"} width="100%" placeholder="비밀번호 확인을 위해 비밀번호를 입력해주세요." value={newPasswordConfirm} onChange={(e) => {
                        setNewPasswordConfirm(e.target.value)
                        if (e.target.value != newPassword) {
                            setInvalidNewPasswordConfirm(true)
                        } else {
                            setInvalidNewPasswordConfirm(false)
                        }
                    }} error={newPasswordConfirm !== "" && invalidNewPasswordConfirm} helperText={(invalidNewPasswordConfirm && newPasswordConfirm !== "") ? "비밀번호가 일치하지 않습니다." : ""} complete={newPasswordConfirm !== "" && !invalidNewPasswordConfirm} />
                    <Eye onMouseDown={() => setShowNewPasswordConfirm(true)} onMouseUp={() => setShowNewPasswordConfirm(false)}
                        onTouchStart={() => setShowNewPasswordConfirm(true)} onTouchEnd={() => setShowNewPasswordConfirm(false)}>
                        <img src={"/assets/image/icon_eye.png"} alt="" />
                    </Eye>
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", width: "100%", marginTop: 20}}>
                <Button size="small" width="80px" height="44px" text="변경하기" onClick={onChangePassword} disabled={newPassword === "" || newPasswordConfirm === "" || invalidNewPassword || invalidNewPasswordConfirm}/>
            </div>
        </div>
    )
}

const LogoutModal = ({ closeLogoutModal, onLogout }: { closeLogoutModal: () => void, onLogout: () => void }) => {

    return (
        <Modal isOpen={true} toggle={closeLogoutModal} size="sm">
            <ModalBody style={{padding: "24px 40px"}}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginBottom: 32, marginTop: 48}}>
                    <div style={{...fonts.body2Medium, color: colors.gray900}}>로그아웃 하시겠습니까?</div>
                </div>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 12}}>
                    <Button size="medium" width="112px" height="44px" text="취소" onClick={closeLogoutModal} buttonColor={colors.gray100} textColor={colors.gray400}/>
                    <Button size="medium" width="112px" height="44px" text="로그아웃" onClick={onLogout} buttonColor={colors.warning}/>
                </div>
            </ModalBody>
        </Modal>
    )
}

const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 28px 32px 0 32px;
    position: relative;
`;


const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const Eye = styled.button`
    position: absolute;
    right: 20px;
    top: 16px;
    border: none;
    background: none;
`;
