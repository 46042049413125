import React, { useEffect, useContext, useState, useRef, useImperativeHandle, forwardRef, useCallback, memo } from 'react';
import styled from 'styled-components';
import colors from '../../../design/colors';
import fonts from '../../../design/typography';
import { Page_settings } from '../../../config/page_settings';
import ImageCircle from '../../image_circle';
import { getFilePath } from '../../../utils/image';
import { ArrowRight } from '../../icons/arrow';
import IconPaperClip from '../../icons/paper_clip';
import ChewingTalkChatItem from '../../control/item/chewingtalk_chat_item';
import moment from 'moment';
import { rootStore } from '../../../mobx/store';
import { Client, StompSubscription } from '@stomp/stompjs';
import Lightbox from 'react-18-image-lightbox';

interface IRoomList {
    user: {
        id: number;
        name: string;
        profile: string;
        userType: string;
        schoolOrClassroom: string;
    };
    id: number;
    message: string;
    messageDateTime: string;
    pinned: boolean;
    notification: boolean;
    unreadCount: number;
}

interface IMessage {
    id: number;
    room: number;
    message: string;
    sender: {
        id: number;
        name: string;
        profile: string;
    };
    file:{
        id: number;
        name: string;
        size: number;
        type: string;
        url: string;
    }[];
    isRead: boolean;
    createDate: string;
}

// 외부에서 호출 가능한 메서드 인터페이스 정의
export interface ChatRoomRef {
  addNewMessage: (message: IMessage) => void;
  updateReadStatus: () => void;
}

const ChatRoomSection = forwardRef<ChatRoomRef, {
  roomInfo: IRoomList, 
  classId: number, 
  socketClient: Client | null
}> ((props, ref) => {
    const {roomInfo, classId, socketClient} = props;
    const context: any = useContext(Page_settings);
    const [messages, setMessages] = useState<IMessage[]>([]);
    const userId = rootStore.getProfile.id;
    const [lightboxImage, setLightboxImage] = useState<string>("");
    const token = rootStore.getMe?.token;

    const subRefArray = useRef<StompSubscription[]>([]);
    // 초기 메시지 로드 - roomInfo.id가 변경될 때만 실행
    useEffect(() => {
        context.get(
            `chewingtalk/room/${roomInfo.id}`,
            {},
            (res) => {
                setMessages(res.reverse());
            }
        );
        
        // 읽음 상태 업데이트
        if (socketClient && socketClient.connected) {
            subRefArray.current.push(socketClient.subscribe(`/topic/chewingtalk/${roomInfo.id}/unread`, (msg) => {
                const newMessage = JSON.parse(msg.body) as IMessage;
                setMessages(prevMessages => [newMessage, ...prevMessages]);
                socketClient.publish({
                    destination: "/app/updateReadStatus",
                    headers: {
                        "x-access-token": token ?? ""
                    },
                    body: JSON.stringify({
                        roomId: roomInfo.id,
                    })
                });
            }));

            subRefArray.current.push(socketClient.subscribe(`/topic/chewingtalk/${roomInfo.id}/read/${roomInfo.user.id}`, () => {
                setMessages(prevMessages => 
                    prevMessages.map(item => 
                        item.sender.id === userId ? { ...item, isRead: true } : item
                    )
                );
            }));
            
            socketClient.publish({
                destination: "/app/updateReadStatus",
                headers: {
                    "x-access-token": token ?? ""
                },
                body: JSON.stringify({
                    roomId: roomInfo.id,
                })
            });
        }

        socketClient.publish({
            destination: "/app/updateReadStatus",
            headers: {
                "x-access-token": token ?? ""
            },
            body: JSON.stringify({
                roomId: roomInfo.id,
            })
        });

        return () => {
            subRefArray.current.forEach(sub => sub.unsubscribe());
        }
    }, [roomInfo.id, socketClient]);

    // 메시지 전송 핸들러 (메모이제이션 적용)
    const handleSendMessage = useCallback((message: string) => {
        if (!message.trim()) return;
        
        socketClient?.publish({
            destination: "/app/sendMessage",
            headers: {
                "x-access-token": token ?? ""
            },
            body: JSON.stringify({
                room: roomInfo.id,
                sender: userId,
                partner: roomInfo.user.id,
                message: message.trim(),
                classId: classId
            })
        });
        
    }, [roomInfo.id, userId, roomInfo.user.id, classId, socketClient, token]);

    // 파일 업로드 핸들러 (메모이제이션 적용)
    const handleFileUpload = useCallback((file: File) => {
        if (!file) return;
        
        const formData = new FormData();
        formData.append('file', file);
        
        context.post('upload/file/chewingtalk', formData, (response) => {
            socketClient?.publish({
                destination: "/app/sendMessage",
                headers: {
                    "x-access-token": token ?? ""
                },
                body: JSON.stringify({
                    room: roomInfo.id,
                    sender: userId,
                    partner: roomInfo.user.id,
                    classId: classId,
                    file: response
                })
            });
        });
    }, [roomInfo.id, userId, roomInfo.user.id, classId, socketClient, token, context]);

    const renderResult = messages.map((item, idx) => {
        const curDate = moment(item.createDate).format("YYYY-MM-DD");
        // 현재 메시지가 마지막 메시지이거나, 다음 메시지와 날짜가 다른 경우에 날짜 구분선 표시
        const showDateDivider = idx === messages.length - 1 || 
            (curDate !== moment(messages[idx + 1].createDate).format("YYYY-MM-DD"));

        const dateString = moment(curDate).format("YYYY년 MM월 DD일 (ddd)");
        
        // 시간 표시 조건: 같은 프로필의 마지막 메시지에는 항상 시간을 표시
        const curTime = moment(item.createDate).format("HH:mm");
        const showTime = idx === 0 || curTime !== moment(messages[idx - 1].createDate).format("HH:mm") || item.sender.id !== messages[idx - 1].sender.id;
        return (
            <>
            <ChewingTalkChatItem
                mine={item.sender.id === userId}
                item={item}
                showTime={showTime}
                setLightboxImage={setLightboxImage}
            />
            {showDateDivider && (
                <div className="date-divider">
                    <span>{dateString}</span>
                </div>
            )}
            </>
        );
    }) ;

  return (
    <ChatRoomSectionWrapper>
        {lightboxImage && (
            <Lightbox
                mainSrc={lightboxImage}
                onCloseRequest={() => setLightboxImage("")}
            />
        )}
        <ChatRoomHeader>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <ImageCircle src={getFilePath(roomInfo.user.profile, "/assets/image/default_profile.png")} size={40} />
            <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", marginLeft: 12}}>
                <div style={{...fonts.label3SemiBold, color: colors.gray900}}>{roomInfo.user.name}</div>
                <div style={{...fonts.label4Medium, color: colors.gray500}}>{roomInfo.user.schoolOrClassroom}</div>
            </div>
        </div>
        </ChatRoomHeader>
        <ChatRoomContent>
            {renderResult}
        </ChatRoomContent>
        <ChatRoomInput onSend={handleSendMessage} onFileUpload={handleFileUpload}/>
    </ChatRoomSectionWrapper>
  )
});

export default ChatRoomSection;

const ChatRoomSectionWrapper = styled.div`
  width: 700px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.gray50};
  position: relative;
`;

const ChatRoomHeader = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
`;

const ChatRoomContent = styled.div`
  width: 100%;
  height: 406px;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: flex-start;
`;

const ChatRoomInputWrapper = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 28px 24px 32px 24px;
`;

const ChatRoomInputArea = styled.input<{isFocused: boolean}>`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: ${colors.white};
  border-radius: 20px;
  padding-left: 44px;
  font-family: ${fonts.label4Medium.fontFamily};
  font-size: ${fonts.label4Medium.fontSize};
  line-height: ${fonts.label4Medium.lineHeight};
  color: ${colors.gray900};
  border: ${props => props.isFocused ? `1px solid ${colors.blue600}` : `none`};

  &::placeholder {
    color: ${colors.gray500};
  }
`;

const ChatRoomInputButton = styled.div<{hasMessage: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: ${props => props.hasMessage ? colors.blue600 : colors.gray200};
  border-radius: 20px;
  cursor: pointer;
  position: absolute;
  right: 30px;
  bottom: 40px;
`;

// 채팅방 입력 컴포넌트 최적화
const ChatRoomInput = memo((props: {
  onSend: (message: string) => void, 
  onFileUpload: (file: File) => void
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const [message, setMessage] = useState("");
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            props.onFileUpload(file);
        }
    }
    return (
        <ChatRoomInputWrapper>
            <input type="file" style={{display: "none"}} ref={fileInputRef} onChange={handleFileUpload}/>
            <div style={{position: "absolute", left: 36, bottom: 40, cursor: "pointer"}} onClick={() => fileInputRef.current?.click()}>
                <IconPaperClip width="24px" height="24px" color={colors.gray500}/>
            </div>
            <ChatRoomInputArea placeholder='메시지를 입력해주세요' isFocused={isFocused} onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} onChange={(e) => setMessage(e.target.value)} value={message}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        e.preventDefault();
                        if (message.trim()) {
                            props.onSend(message);
                            setMessage("");
                        }
                    }

                    e.stopPropagation();
                }}
            />
            <ChatRoomInputButton hasMessage={message.length > 0} onClick={() => {
                props.onSend(message);
                setMessage("");
            }}>
                <ArrowRight width="24px" height="24px" color={colors.white}/>
            </ChatRoomInputButton>
        </ChatRoomInputWrapper>
    )
});

