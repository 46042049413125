import React, { useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import styled from 'styled-components';
import menus from '../../config/page_route';
import colors from '../../design/colors';
import fonts from '../../design/typography';
import { rootStore } from '../../mobx/store.js';
import { ChevronDown, ChevronRight } from '../icons/chevron';

const Container = styled.div`
    width: 200px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: ${colors.white};
    padding: 25px 20px;
    border-right: 1px solid ${colors.gray100};

    @media screen and (max-width: 1000px) {  // 화면 너비의 50% 이하에서 사이드바 숨김
        display: none;
    }

`;

const Sidebar = () => {
    const userType = rootStore.getProfile?.userType ?? 'STUDENT';
    const updatedMenu = menus.map((menu) => {
        
        if (menu.path === '/clinic') {
            return null;
        }

        if (menu.user_type && menu.user_type !== userType) {
            return null;
        }

        
        const updatedChildren = menu.children
            .filter((child) => {
              if (child.user_type && child.user_type !== userType) {
                return false;
              }
              return true;
            })
            return { ...menu, children: updatedChildren };
        }
    
      ).filter(menu => menu !== null);


    const [isMenuOpen, setIsMenuOpen] = useState<boolean[]>(updatedMenu.map(() => true));
    const currentPath = useLocation().pathname;
    const [hoverdIndex, setHoverdIndex] = useState<number[]>([-1, -1]);

    const isSelected = (menu: any) => {
        return menu.children.some(child => child.containPath.some(p => new RegExp(`^${p.replace(/:[^\s/]+/g, '[^/]+')}$`).test(currentPath)));
    }

    return (
        <Container>
            <div style={{marginBottom: 57}}>
                <Link to="/" className="navbar-brand">
                    <img className="logo" src={'/assets/image/logo_header.png'} alt="" width="140px" height="30px" style={{marginLeft: 4}}/>
                </Link>
            </div>
            <SidebarMenuWrapper>
                {updatedMenu.filter(menu => menu !== null).map((menu, i) => (
                    <React.Fragment key={i}>
                        <SidebarMenuMain isHoverd={hoverdIndex[0] === i && hoverdIndex[1] === -1} isSelected={isSelected(menu)} onMouseEnter={() => setHoverdIndex([i, -1])} onMouseLeave={() => setHoverdIndex([-1, -1])}>
                            {isMenuOpen[i] ? <div onClick={() => setIsMenuOpen(isMenuOpen.map((_, index) => index === i ? !isMenuOpen[i] : isMenuOpen[index]))} style={{cursor: "pointer", marginRight: 6}}>
                                <ChevronDown width="20px" height="20px" color={colors.gray200}/>
                            </div> : <div onClick={() => setIsMenuOpen(isMenuOpen.map((_, index) => index === i ? !isMenuOpen[i] : isMenuOpen[index]))} style={{cursor: "pointer", marginRight: 6}}>
                                <ChevronRight width="20px" height="20px" color={colors.gray200}/>
                            </div>}
                            <Link style={{display: "flex", flexDirection: "row", alignItems: "center", cursor: "pointer"}} to={menu.path}>
                                {isSelected(menu) ? menu.iconActive : menu.iconInactive}
                                <div style={{ ...fonts.body3SemiBold, color: isSelected(menu) ? colors.blue600 : colors.gray500, marginLeft: 8}}>{menu.title}</div>
                            </Link>
                        </SidebarMenuMain>
                        {isMenuOpen[i] && <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", gap: 8, marginBottom: 20}}>
                        {menu.children.map((child, j) => (
                            <SidebarMenuSub key={j} isHoverd={hoverdIndex[0] === i && hoverdIndex[1] === j} onMouseEnter={() => setHoverdIndex([i, j])} onMouseLeave={() => setHoverdIndex([-1, -1])}>
                                <Link style={{...fonts.body3SemiBold, color: child.containPath.some(p => new RegExp(`^${p.replace(/:[^\s/]+/g, '[^/]+')}$`).test(currentPath)) ? colors.blue600 : colors.gray500, marginLeft: 40}} to={child.path}>{child.title}</Link>
                            </SidebarMenuSub>
                            ))}
                        </div>}
                    </React.Fragment>
                ))}
            </SidebarMenuWrapper>
        </Container>
    )
}

export default Sidebar;

const SidebarMenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: calc(100% - 87px);
`;

const SidebarMenuMain = styled.div<{isHoverd: boolean, isSelected: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border-radius: 8px;
    padding: 0 8px;
    height: 40px;
    background-color: ${props => {
        switch (true) {
            case props.isHoverd && props.isSelected:
                return colors.gray100; // Hovered and selected
            case props.isHoverd && !props.isSelected:
                return colors.gray50; // Hovered but not selected
            case !props.isHoverd && props.isSelected:
                return colors.gray50; // Not hovered but selected
            default:
                return colors.white; // Not hovered and not selected
        }
    }};
    margin-bottom: 8px;
`;

const SidebarMenuSub = styled.div<{isHoverd: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 34px;
    background-color: ${props => props.isHoverd ? colors.gray50 : colors.white};
    border-radius: 8px;
`;
