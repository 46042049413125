import React from "react";
import { IconType } from "../../type/icon_type";

const IconPaperClip = ({width = "24", height = "24", color = "#73787E"}: IconType) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5285 19.5137L19 12.7544M14.8335 7.39933L7.82198 14.6976C7.50954 15.0164 7.33402 15.4487 7.33402 15.8995C7.33402 16.3503 7.50954 16.7826 7.82198 17.1014C8.13452 17.4201 8.55835 17.5991 9.00028 17.5991C9.44221 17.5991 9.86604 17.4201 10.1786 17.1014L17.1901 9.80317C17.8149 9.16557 18.166 8.30091 18.166 7.39933C18.166 6.49776 17.8149 5.6331 17.1901 4.99549C16.565 4.35808 15.7173 4 14.8335 4C13.9496 4 13.1019 4.35808 12.4769 4.99549L5.46455 12.2929C4.52681 13.2494 4 14.5467 4 15.8995C4 17.2522 4.52681 18.5496 5.46455 19.5061C6.40228 20.4626 7.67413 21 9.00028 21C10.3264 21 11.5983 20.4626 12.536 19.5061" stroke={color} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export default IconPaperClip;