import React from "react";
import { IconType } from "../../type/icon_type";



const IconDatalab = ({width="24px", height="24px", color="#73787E", style}: IconType) => {
    return(
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path d="M13.75 3V6.6C13.75 7.07739 13.9344 7.53523 14.2626 7.87279C14.5908 8.21036 15.0359 8.4 15.5 8.4H19M8.5 17.4V15.6M12 17.4V13.8M15.5 17.4V12M14.625 3H6.75C6.28587 3 5.84075 3.18964 5.51256 3.52721C5.18437 3.86477 5 4.32261 5 4.8V19.2C5 19.6774 5.18437 20.1352 5.51256 20.4728C5.84075 20.8104 6.28587 21 6.75 21H17.25C17.7141 21 18.1592 20.8104 18.4874 20.4728C18.8156 20.1352 19 19.6774 19 19.2V7.5L14.625 3Z" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )

}

const IconDatalabFill = ({width="24px", height="24px", color="#73787E", style}: IconType) => {
    return(
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path d="M13.75 3H6.75C6.28587 3 5.84075 3.18964 5.51256 3.52721C5.18437 3.86477 5 4.32261 5 4.8V19.2C5 19.6774 5.18437 20.1352 5.51256 20.4728C5.84075 20.8104 6.28587 21 6.75 21H17.25C17.7141 21 18.1592 20.8104 18.4874 20.4728C18.8156 20.1352 19 19.6774 19 19.2V8.4H15.5C15.0359 8.4 14.5908 8.21036 14.2626 7.87279C13.9344 7.53523 13.75 7.07739 13.75 6.6V3Z" fill={color}/>
            <path d="M6.75 3H14.625L19 7.5V19.2M6.75 3C6.28587 3 5.84075 3.18964 5.51256 3.52721C5.18437 3.86477 5 4.32261 5 4.8V19.2C5 19.6774 5.18437 20.1352 5.51256 20.4728C5.84075 20.8104 6.28587 21 6.75 21H17.25C17.7141 21 18.1592 20.8104 18.4874 20.4728C18.8156 20.1352 19 19.6774 19 19.2M6.75 3H13.75V6.6C13.75 7.07739 13.9344 7.53523 14.2626 7.87279C14.5908 8.21036 15.0359 8.4 15.5 8.4H19V19.2M8.5 17.4V15.6M12 17.4V13.8M15.5 17.4V12" stroke={color} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 13.5V17.5" stroke="white" stroke-width="1.4" stroke-linecap="round"/>
            <path d="M8.5 15.5V17.5" stroke="white" stroke-width="1.4" stroke-linecap="round"/>
            <path d="M15.5 12V17.5" stroke="white" stroke-width="1.4" stroke-linecap="round"/>
        </svg>
        
    )
}

export {IconDatalab, IconDatalabFill};