import React, { useState } from "react";
import styled from "styled-components";
import colors from "../../design/colors";
import fonts from "../../design/typography";
import Button from "../../components/button";
import Checkbox from "../../components/checkbox";
import { ChevronRight } from "../../components/icons/chevron";
import { Modal, ModalBody } from "reactstrap";
import { Page_settings } from "../../config/page_settings";
import { useContext, useEffect } from "react";
import IconX from "../../components/icons/x";

const SignupStep4 = ({ onDone }: { onDone: () => void }) => {
    const [agreements, setAgreements] = useState({
        terms: false,
        personalInfo: false,
    });

    const [type, setType] = useState<"service" | "privacy">("service");
    const [showAgreementModal, setShowAgreementModal] = useState(false);


    return (
        <Container>
            <Title>이용약관 동의</Title>
            <AgreementBox>
                <CheckboxWrapper style={{ backgroundColor: colors.gray100 , height: "50px", borderRadius: "8px", marginBottom: "20px" , marginLeft: "0", width: "100%"}}>
                    <Checkbox
                        checked={agreements.terms && agreements.personalInfo}
                        setChecked={() => {
                            if (
                                agreements.terms &&
                                agreements.personalInfo
                            ) {
                                setAgreements({
                                    terms: false,
                                    personalInfo: false,
                                });
                            } else {
                                setAgreements({
                                    terms: true,
                                    personalInfo: true,
                                });
                            }
                        }}
                        style={{ marginLeft: "16px" }}
                    />
                    <Label style={{ marginLeft: "16px" }}>전체 동의</Label>
                </CheckboxWrapper>
                    <CheckboxWrapper>
                        <Checkbox
                            checked={agreements.terms}
                            setChecked={() => {
                                setAgreements({
                                    ...agreements,
                                    terms: !agreements.terms,
                                });
                            }}
                        />
                        <RowWrapper onClick={() => {
                            setType("service");
                            setShowAgreementModal(true);
                        }}>
                            <Label>[필수] 츄잉 이용약관</Label>
                            <ChevronRight/>
                        </RowWrapper>
                    </CheckboxWrapper>
                    {/* <CheckboxWrapper>
                        <Checkbox
                            checked={agreements.phoneVerification}
                            setChecked={() => {
                                handleIndividualCheck('phoneVerification')
                                setAllChecked(false);
                            }}
                        />
                        <RowWrapper onClick={() => {
                            window.open("https://www.google.com", "_blank");
                        }}>
                            <Label>[필수] 휴대폰 본인인증 이용약관</Label>
                            <ChevronRight/>
                        </RowWrapper>
                    </CheckboxWrapper> */}

                    <CheckboxWrapper>
                        <Checkbox
                            checked={agreements.personalInfo}
                            setChecked={() => {
                                setAgreements({
                                    ...agreements,
                                    personalInfo: !agreements.personalInfo,
                                });
                            }}
                        />
                        <RowWrapper onClick={() => {
                            setType("privacy");
                            setShowAgreementModal(true);
                        }}>
                            <Label>[필수] 개인정보 수집 이용 동의</Label>
                            <ChevronRight/>
                        </RowWrapper>
                    </CheckboxWrapper>

            </AgreementBox>
            <Button
                size="large"
                height="48px"
                text={"가입하기"}
                onClick={onDone}
                disabled={!agreements.terms ||   !agreements.personalInfo}
            />
            {showAgreementModal && <AgreementModal onClose={() => setShowAgreementModal(false)} type={type} />}

        </Container>
    );
};

export default SignupStep4;

const Container = styled.div`
    width: 100%;
    margin-top: 80px;
`;

const Title = styled.p`
    font-family: ${fonts.heading1Bold.fontFamily};
    font-size: ${fonts.heading1Bold.fontSize};
    line-height: ${fonts.heading1Bold.lineHeight};
    color: ${colors.gray900};
    margin-bottom: 56px;
    text-align: center;
`;

const AgreementBox = styled.div`
    margin-bottom: 56px;
`;

const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    width: calc(100% - 12px);
    margin-bottom: 20px;
    margin-left: 16px;
`;

const Label = styled.span`
    font-size: ${fonts.body3Medium.fontSize};
    color: ${colors.gray900};
    font-family: ${fonts.body3Medium.fontFamily};
    line-height: ${fonts.body3Medium.lineHeight};
`;

const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 36px);
    padding-right: 16px;
    cursor: pointer;
    margin-left: 16px;
`;


const AgreementModal = ({ onClose, type }: { onClose: () => void, type: "service" | "privacy" }) => {
    const context: any = useContext(Page_settings);
    const [content, setContent] = useState<React.ReactNode>(<></>);

    useEffect(() => {
        context.get(
            `/setting/terms/${type}`,
            {},
            (res: any) => {
                const content = res.split("\n").map((line: string) => <p>{line}</p>);
                setContent(<>{content}</>);
            }
        )
    }, []);
    return (
        <Modal isOpen={true} toggle={onClose} size="xl">
            <ModalContainer>
                <Header>
                    <ModalTitle>{type === "service" ? "서비스 이용약관" : "개인정보 처리방침"}</ModalTitle>
                    <div onClick={onClose}>
                        <IconX color={colors.gray900} width="24px" height="24px"/>
                    </div>
                </Header>
                <ModalContent>
                    {content}
                </ModalContent>
            </ModalContainer>
        </Modal>
    );
}

const ModalContainer = styled(ModalBody)`
    width: 100%;
    height: 500px !important;
    padding: 28px 32px 24px 32px;
`;

const Header = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;

const ModalTitle = styled.span`
    font-family: ${fonts.label2SemiBold.fontFamily};
    font-size: ${fonts.label2SemiBold.fontSize};
    line-height: ${fonts.label2SemiBold.lineHeight};
    color: ${colors.gray900};
`


const ModalContent = styled.div`
    width: 100%;
    height: calc(100% - 70px);
    overflow-y: auto;
    background-color: ${colors.gray50};
    padding: 16px;
    border-radius: 8px;
`