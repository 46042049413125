
import React from "react";
import { IconType } from "../../type/icon_type";


const IconCloud = ({ width = "24px", height = "24px", color = "#111827", background = "none", style }: IconType) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 17" fill={background} xmlns="http://www.w3.org/2000/svg" style={style}>
            <path d="M1.5 11.2935C1.5 11.9334 1.62218 12.5671 1.85956 13.1584C2.09693 13.7496 2.44487 14.2868 2.88348 14.7393C3.76931 15.6532 4.97076 16.1667 6.22351 16.1667H18.8195C19.7495 16.1684 20.6455 15.8062 21.3268 15.1531C22.0081 14.5001 22.424 13.6048 22.4906 12.6478C22.5572 11.6908 22.2695 10.7434 21.6856 9.99665C21.1017 9.2499 20.265 8.75938 19.3444 8.62405C19.3546 6.78172 18.7142 4.99861 17.5428 3.60801C16.3714 2.21741 14.7492 1.31453 12.9795 1.06812C11.2098 0.821719 9.41367 1.24867 7.92688 2.26916C6.44009 3.28965 5.36442 4.83383 4.90093 6.61304C3.91944 6.90838 3.05732 7.52429 2.44407 8.36826C1.83082 9.21222 1.49955 10.2387 1.5 11.2935Z" fill={background} stroke={color} stroke-width="1.4" stroke-linejoin="round"/>
        </svg>
    )
}

export default IconCloud;
