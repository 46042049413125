import React, {useContext, useRef, useState} from 'react';
import {Modal, ModalBody} from "reactstrap";
import strings from "../../../lang/strings";
import './modal.scss'
import PerfectScrollbar from "react-perfect-scrollbar";
import Dropzone from "react-dropzone";
import {Page_settings} from "../../../config/page_settings";
import axios from "axios";
import {API_URL} from "../../../config/const";
import ProgressBar from "../progress_bar/progress_bar";


const CloudUploadModal = (props) => {
    const [fileList, setFileList] = useState([]);
    const [urlList, setUrlList] = useState([]);
    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const fileTypes = [
        "image/bmp",
        "image/jpg",
        "image/jpeg",
        "image/png",
        "image/tiff",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "text/htm",
        "text/html",
        "application/pdf",
        "application/vnd.ms-powerpoint",
        "applicatiapplication/vnd.openxmlformats-officedocument.presentationml.presentation",
        "text/plain",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "video/mp4"]

    const dropRef = useRef();
    const context = useContext(Page_settings);

    const {show, toggle, reload} = props;

    const onDrop = (acceptedFiles) => {
        setUrlList([...urlList, ...acceptedFiles]);
        let list = [];
        acceptedFiles.forEach(item => {
            if (fileTypes.indexOf(item.type) < 0) return
            let ext = item.name.split(".")[item.name.split(".").length - 1];
            let type = "/assets/image/icon_file_";
            if (ext == "docx") {
                type += "docs";
            } else if (ext == "mp4") {
                type += "drive"
            } else {
                type += ext
            }
            type += ".png";
            list.push({title: item.name, size: item.size, type: type})
        });
        setFileList([...fileList, ...list])
    }

    const client = axios.create({
        baseURL: API_URL + '/app/',
    })

    const initialize = () => {
        setFileList([]);
        setUrlList([]);
        setProgress(0)
    }

    const uploadFiles = async () => {
        if (uploading) return
        if (urlList.length != 0) {
            let formData = new FormData();
            urlList.forEach((entry, index) => {
                formData.append('file', entry);
            });
            setUploading(true);

            await client.post(
                "upload/files/cloud",
                formData,
                {
                    onUploadProgress: function (axiosProgressEvent) {
                        let percentCompleted = Math.floor(axiosProgressEvent.loaded / axiosProgressEvent.total * 100);
                        setProgress(percentCompleted);
                        if (percentCompleted == 100) {
                            setUploading(false)
                        }
                    },
                },
            ).then(
                response => {
                    fileList.map((item, idx) => {
                        item.url = response.data.result_data.file[idx];
                    });
                    context.post(
                        "cloud/add",
                        {
                            file_data: fileList
                        },
                        resp => {
                            setFileList([]);
                            setUrlList([]);
                            toggle();
                            reload()
                        }
                    );
                }
            );
        }
    }
    return (
        <Modal isOpen={show} toggle={() => toggle()} className="cloud-view-modal" onOpened={() => {
            initialize()
        }}>
            <ModalBody>
                <div className="d-flex align-items-center m-b-40 p-l-40 p-r-40">
                    <div className="f-s-20 f-w-600 flex-grow-1">{strings.upload_file}</div>
                    <button className='modal-close-btn' onClick={() => toggle()}>
                        <img src={"/assets/image/icon_close_black.png"} alt="" className="modal-close"/>
                    </button>
                </div>
                <ProgressBar
                    percent={progress}
                    color={"#0f84f4"}
                    height={2}
                    width={"100%"}
                />
                <Dropzone ref={dropRef} onDrop={acceptedFiles => onDrop(acceptedFiles)}>
                    {({getRootProps, getInputProps}) => (
                        <section>
                            <div
                                className={("cloud-view-main ") + (fileList.length == 0 ? "m-b-20 d-flex align-item-center justify-content-center" : "cloud-view-main-file")}
                                {...getRootProps({
                                    onClick: event => event.stopPropagation()
                                })}>
                                <input {...getInputProps()}
                                       accept='image/bmp,image/jpg,image/jpeg,image/png,image/tiff,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/htm,text/html,application/pdf,application/vnd.ms-powerpoint,applicatiapplication/vnd.openxmlformats-officedocument.presentationml.presentation,text/plain,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,video/mp4'/>
                                {
                                    fileList.length == 0 &&
                                    <div className="upload-dotted">
                                        <div>
                                            <div className="d-flex justify-content-center">
                                                <img src={"/assets/image/img_drag_drop.png"} alt=""/>
                                            </div>
                                            <div
                                                className="f-s-28 f-w-600 text-center m-t-25">{strings.upload_file}</div>
                                            <div className="drag-drop m-t-15">{strings.drag_drop_file}</div>
                                            <button className="cloud-upload-btn modal-upload m-t-35" onClick={() => {
                                                dropRef?.current.open()
                                            }}>
                                                <img src={"/assets/image/icon_upload.png"} alt=""/>
                                                <div>{strings.upload_file}</div>
                                            </button>
                                        </div>
                                    </div>
                                }
                                {
                                    fileList.length != 0 &&
                                    <div>
                                        <PerfectScrollbar className="upload-modal-scroll m-b-25"
                                                          options={{suppressScrollX: true}}>
                                            <div className="row">
                                                {
                                                    fileList.map((item, idx) => {
                                                        return (
                                                            <div
                                                                key={idx}
                                                                className="upload-item">
                                                                <div className="d-flex justify-content-center">
                                                                    <img src={item.type}
                                                                         alt=""
                                                                         className="m-b-10"/>
                                                                </div>
                                                                <div
                                                                    className="f-s-14 text-center item-name text-ellipsis">{item.title}</div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </PerfectScrollbar>
                                        <div className="d-flex">
                                            <button
                                                className={("cloud-upload-btn modal-upload m-l-auto ") + (uploading ? "disabled-upload" : "")}
                                                onClick={() => {
                                                    if (!uploading) {
                                                        dropRef?.current.open()
                                                    }
                                                }}>
                                                <img src={"/assets/image/icon_plus_white.png"} alt=""/>
                                                <div>{strings.add_more}</div>
                                            </button>
                                            <button
                                                className={("cloud-upload-btn modal-upload m-l-20 ") + (uploading ? "disabled-upload" : "")}
                                                onClick={() => uploadFiles()}>
                                                <img src={"/assets/image/icon_upload.png"} alt=""/>
                                                <div>{strings.upload_file}</div>
                                            </button>
                                        </div>

                                    </div>
                                }
                            </div>
                        </section>
                    )}
                </Dropzone>
            </ModalBody>
        </Modal>
    );
}

export default CloudUploadModal;
