import strings from "../../../lang/strings";
import {useContext} from "react";
import {Page_settings} from "../../../config/page_settings";
import moment from "moment";

const ExpertChatListItem = (props) => {
    const context = useContext(Page_settings);
    const {item, selected, onClick, expert} = props;

    return (
        <button className={("expert-chat-item " + (selected ? "expert-selected" : ""))} onClick={() => onClick()}>
            <img className="profile"
                 src={item.user.profile != null ? context.loadImage(item.user.profile) : "/assets/image/chewing_plus_logo.png"}
                 alt=""/>
            <div className="d-block chat-info">
                <div className="d-flex align-item-center">
                    <div
                        className="expert-name f-w-600">{item.user.name + " " + (expert ? "" : strings.expert)}</div>
                    <div
                        className="time">{item.chat_date != "" ? moment(item.chat_date).format("a hh:mm") : ""}</div>
                </div>
                <div className="d-flex">
                    <div className="chat">{item.content}</div>
                    {
                        item.unread_cnt != 0 && !selected &&
                        <div className="not-read">{item.unread_cnt}</div>
                    }
                </div>
            </div>
        </button>
    );
}

export default ExpertChatListItem;
