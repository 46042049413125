import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Page_settings } from "../../../config/page_settings";
import { rootStore } from "../../../mobx/store";
import { observer } from "mobx-react";
import colors from "../../../design/colors";
import SubjectChip from "../../../components/subject_chip";
import IconButton from "../../../components/icon_button";
import { ChevronDown, ChevronLeft, ChevronRight, ChevronUp } from "../../../components/icons/chevron";
import fonts from "../../../design/typography";
import { Document, Page } from "react-pdf";
import { getFilePath } from "../../../utils/image";
import { IconZoomIn } from "../../../components/icons/zoom";
import { IconZoomOut } from "../../../components/icons/zoom";
import { ArrowLeft } from "../../../components/icons/arrow";
import IconX from "../../../components/icons/x";

interface IExam {
    info: {
      id: number;
      startTime: string;
      endTime: string;
      items: number;
      subject: string;
      title: string;
      status: number;
      file: {
        id: number;
        name: string;
        size: number;
        type: string;
        url: string;
      }
    }
}


interface IExamResult {
    member: number;
    exam: number;
    testQuestion: {
        id: number;
        number: number;
        questionType: number;
        score: number;
        correctAnswer: string;
        title: string;
        data: string;
        commentary: string;
      }[],
    testAnswer: {
        number: number;
        answer: string;
        isCorrect: boolean;
      }[],
    examTime: number;
    objectiveScore: number;
    objectiveCorrectCountRate: number;
    subjectiveScore: number;
    subjectiveCorrectCountRate: number;
    extraScore: number;
    totalScore: number;
    feedback: string;
    submitTime: string;
}


const ExamStudentResult = observer(() => {
    const { exam_id } = useParams();

    const context: any = useContext(Page_settings);
    const memberId = rootStore.getProfile?.id;

    const [examResult, setExamResult] = useState<IExamResult | null>(null);
    const [exam, setExam] = useState<IExam | null>(null);
    const [numPages, setNumPages] = useState<number>(0);
    const [scale, setScale] = useState<number>(1.0);
    const [mode, setMode] = useState<"result" | "omr">("result");

    useEffect(() => {
        context.get(`/exam/${exam_id}`,{}, (data) => {
            setExam(data);
        });

        context.get(`/exam/${exam_id}/result/${memberId}`,{}, (data) => {
            const examResult: IExamResult = data;
            setExamResult(examResult);
        });
    }, []);

    const increaseScale = () => setScale(prevScale => Math.min(prevScale + 0.1, 2.0));
    const decreaseScale = () => setScale(prevScale => Math.max(prevScale - 0.1, 0.5));

    if (!exam || !examResult) {
        return <div>Loading...</div>;
    }
    return (
        <Container>
            <LeftSection>
                {mode === "result" ? <ResultContent exam={exam} examResult={examResult} goToOmr={() => setMode("omr")}/> : <OmrContent examResult={examResult} goToResult={() => setMode("result")}/>}
            </LeftSection>
            <div style={{width: "1px", height: "100%", margin: "0 40px", backgroundColor: colors.gray100}}/>
            <RightSection>
                <Document file={getFilePath(exam.info.file.url)} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
                    {Array.from(new Array(numPages), (el, index) => (
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={scale}/>
                    ))}
                </Document>
            </RightSection>
            <div style={{position: 'absolute', top: 60, right: 52, width: '95px', height: '36px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: colors.gray50, borderRadius: 8, zIndex: 1000}}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 8}}>
                    <div style={{cursor: 'pointer'}} onClick={() => {
                        increaseScale()
                    }}>
                        <IconZoomIn width='20px' height='20px' color={colors.gray500} />
                    </div>
                    <span style={{...fonts.label5Regular, color: colors.gray900}}>{Math.round(scale * 100)}%</span>
                    <div style={{cursor: 'pointer'}} onClick={() => {
                        decreaseScale()
                    }}>
                        <IconZoomOut width='20px' height='20px' color={colors.gray500} />
                    </div>
                </div>
            </div>
        </Container>
    )
});

export default ExamStudentResult;


const ResultContent = ({exam, examResult, goToOmr}: {exam: IExam, examResult: IExamResult, goToOmr: () => void}) => {

    const [showFeedback, setShowFeedback] = useState<boolean>(false);

    return (
        <>
            <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "36px", position: "relative"}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "12px"}}>
                    <SubjectChip subject={exam?.info.subject}/>
                    <div style={{display: "flex", flexDirection: "column", gap: "4px"}}>
                        <span style={{fontSize: "24px", fontWeight: "bold", color: colors.black}}>{exam?.info.title}</span>
                    </div>
                </div>
                <IconButton icon={showFeedback ? <ChevronUp color={colors.white} width="20px" height="20px"/> : <ChevronDown color={colors.white} width="20px" height="20px"/>} text="피드백" size="medium" onClick={() => {
                    setShowFeedback(!showFeedback);
                }} width="78px" height="36px"/>
                {showFeedback && 
                    <div style={{position: "absolute", top: 40, right: 0, width: "320px", backgroundColor: colors.white, zIndex: 1000, boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.15)", padding: "20px 16px", borderRadius: 20}}>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                            <span style={{...fonts.label4Medium, color: colors.gray900}}>선생님 피드백</span>
                            <div style={{cursor: "pointer"}} onClick={() => {
                                setShowFeedback(false);
                            }}>
                                <IconX color={colors.gray500} width="20px" height="20px"/>
                            </div>
                        </div>
                        <div style={{padding: "12px 16px", backgroundColor: colors.gray50, borderRadius: 8, marginTop: 16, minHeight: 112}}>
                            <span style={{...fonts.body3Medium, color: colors.gray900}}>{examResult.feedback || "선생님이 작성한 피드백이 없습니다."}</span>
                        </div>
                    </div>}
            </div>
            <div style={{width: "100%", height: 40, display: "flex", flexDirection: "row", justifyContent: "flex-start", marginBottom: "36px",gap:8}}>
                <div style={{height: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-start", gap:20, padding: "0 12px", border: `1px solid ${colors.gray100}`, borderRadius: "8px", alignItems: "center"}}>
                    <span style={{...fonts.label4Medium, color: colors.gray900}}>응시 시간</span>
                    <div style={{height: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", padding: "0 12px"}}>
                        <span style={{...fonts.label3Medium, color: colors.blue600}}>{Math.floor(examResult.examTime/3600).toString().padStart(1, "0")}:</span>
                        <span style={{...fonts.label3Medium, color: colors.blue600}}>{Math.floor((examResult.examTime%3600)/60).toString().padStart(2, "0")}:</span>
                        <span style={{...fonts.label3Medium, color: colors.blue600}}>{(examResult.examTime%60).toString().padStart(2, "0")}</span>
                    </div>
                </div>
                <div style={{height: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-start", gap:20, padding: "0 12px", border: `1px solid ${colors.gray100}`, borderRadius: "8px", alignItems: "center"}}>
                    <span style={{...fonts.label4Medium, color: colors.gray900}}>점수</span>
                    <span style={{...fonts.label3Medium, color: colors.blue600}}>{examResult.totalScore.toFixed(1)}</span>
                </div>
            </div>
            <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "16px"}}>
                <span style={{...fonts.body3SemiBold, color: colors.gray900}}>채점 결과</span>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "4px", cursor: "pointer"}} onClick={() => {
                    goToOmr();
                }}>
                    <span style={{...fonts.label4Medium, color: colors.gray500}}>OMR 확인하기</span>
                    <ChevronRight color={colors.gray500} width="20px" height="20px"/>
                </div>
            </div>
            <AnswerTable>
                <thead>
                    <th style={{width: "50px"}} />
                    <th style={{width: "calc(25% - 12.5px)"}}>학생 답</th>
                    <th style={{width: "calc(25% - 12.5px)"}}>정답</th>
                    <th style={{width: "calc(13% - 12.5px)"}}>문제 배점</th>
                    <th style={{width: "calc(37% - 12.5px)"}}>내 점수</th>
                </thead>
                <tbody>
                    {examResult.testQuestion.map((question, index) => {
                        let correctAnswer = "";
                        let studentAnswer = "";
                        if (question.questionType === 1) {
                            correctAnswer = JSON.parse(question.correctAnswer).map((answer: string) => {
                                return answer+ "번";
                            }).join(",");
                            studentAnswer = JSON.parse(examResult.testAnswer[index].answer).map((answer: string) => {
                                return answer+ "번";
                            }).join(",");
                        } else {
                            correctAnswer = question.correctAnswer;
                            studentAnswer = examResult.testAnswer[index].answer;
                        }

                        return (
                        <tr>
                            <td style={{borderLeft: 'none'}}>{index + 1}</td>
                            <td style={{color: examResult.testAnswer[index].isCorrect ? colors.blue600 : colors.warning}}>{studentAnswer}</td>
                            <td style={{color: examResult.testAnswer[index].isCorrect ? colors.blue600 : colors.warning}}>{correctAnswer}</td>
                            <td>{question.score.toFixed(1)}</td>
                            <td>{examResult.testAnswer[index].isCorrect ? question.score.toFixed(1) : "0.0"}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </AnswerTable>
        </>
    )
}

const OmrContent = ({examResult, goToResult}: {examResult: IExamResult, goToResult: () => void}) => {
    const [answerCurrentPage, setAnswerCurrentPage] = useState<number>(1);

    return (
        <>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "16px", cursor: "pointer", marginTop: "12px", marginBottom: "32px"}} onClick={() => {
                goToResult();
            }}>
                <ArrowLeft color={colors.gray400} width="24px" height="24px"/>
                <span style={{...fonts.body3Medium, color: colors.gray900}}>OMR 확인하기</span>
            </div>
            <OMRBody>
                <>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "250px", gap: 20}}>
                {examResult.testQuestion.slice((answerCurrentPage - 1) * 20, (answerCurrentPage - 1) * 20 + 10).map((answer, index) => (
                    <OMRItem>
                        <div style={{width: "26px", textAlign: "center"}}>{index + 1 + (answerCurrentPage - 1) * 20 + "번"}</div>
                        {examResult.testAnswer[index + (answerCurrentPage - 1) * 20].isCorrect ?
                                <div style={{position: "absolute", top: 0, left: 0}}>
                                    <img src={"/assets/image/correct.png"} width="50%" height="50%"/>
                                </div>
                            :
                                <div style={{position: "absolute", top: 0, left: 0}}>
                                    <img src={"/assets/image/wrong.png"} width="50%" height="50%"/>
                                </div>
                            }
                        <div style={{width: "200px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginLeft: "21px"}}>
                            {answer.questionType === 1 ?
                            <>
                                {Array.from({length: 5}, (_, i) => {
                                    const answer: number[] = JSON.parse(examResult.testAnswer[index + (answerCurrentPage - 1) * 20].answer);
                                    const correctAnswer: number[] = JSON.parse(examResult.testQuestion[index + (answerCurrentPage - 1) * 20].correctAnswer);
                                return (
                                    <div style={{width: "24px", height: "24px", borderRadius: "50%", 
                                    backgroundColor: answer.includes(i + 1) ? colors.black : colors.white, 
                                    border: `1px solid ${colors.gray200}`, 
                                    boxShadow: !examResult.testAnswer[index + (answerCurrentPage - 1) * 20].isCorrect && correctAnswer.includes(i + 1) ? `0 0 0 4px ${colors.warning}` : 'none'}} />
                                )
                                })}
                            </>
                            :
                            <div style={{width: "100%", position: "relative"}}>
                                <OMRInput type="text" value={examResult.testAnswer[index + (answerCurrentPage - 1) * 20].answer} width="100%" readOnly={true} disabled={true} />
                                <div style={{position: "absolute", top: 1, left: 50}}>
                                    {
                                        !examResult.testAnswer[index + (answerCurrentPage - 1) * 20].isCorrect &&
                                        (
                                            <span style={{...fonts.label4Medium, color: colors.blue600}}>{examResult.testQuestion[index + (answerCurrentPage - 1) * 20].correctAnswer}</span>
                                        )
                                    }
                                </div>
                            </div>
                            }
                        </div>
                    </OMRItem>
                ))}
                </div>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "250px", gap: 20}}>
                {(examResult.testQuestion.length > answerCurrentPage * 20 - 10) && (
                    <>
                    {examResult.testQuestion.slice(answerCurrentPage * 20 - 10, answerCurrentPage * 20).map((answer, index) => (
                        <OMRItem>
                            <div style={{width: "26px", textAlign: "center"}}>{index + (answerCurrentPage) * 20 - 9 + "번"}</div>
                            {examResult.testAnswer[index + (answerCurrentPage) * 20 - 10].isCorrect ?
                                <div style={{position: "absolute", top: 0, left: 0}}>
                                    <img src={"/assets/image/correct.png"} width="50%" height="50%"/>
                                </div>
                            :
                                <div style={{position: "absolute", top: 0, left: 0}}>
                                    <img src={"/assets/image/wrong.png"} width="50%" height="50%"/>
                                </div>
                            }
                            <div style={{width: "200px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginLeft: "21px"}}>
                                {answer.questionType === 1 ?
                                <>
                                    {Array.from({length: 5}, (_, i) => {
                                        const answer: number[] = JSON.parse(examResult.testAnswer[index + (answerCurrentPage) * 20 - 10 ].answer);
                                        const correctAnswer: number[] = JSON.parse(examResult.testQuestion[index + (answerCurrentPage) * 20 - 10].correctAnswer);
                                    return (
                                        <div style={{width: "24px", height: "24px", borderRadius: "50%", cursor: "pointer",
                                        backgroundColor: answer.includes(i + 1) ? colors.black : colors.white, border: `1px solid ${colors.gray200}`,
                                        boxShadow: !examResult.testAnswer[index + (answerCurrentPage) * 20 - 10].isCorrect && correctAnswer.includes(i + 1) ? `0 0 0 4px ${colors.warning}` : 'none'}} />
                                    )
                                    })}
                                </>
                                :
                                <div style={{width: "100%", position: "relative"}}>
                                    <OMRInput type="text" value={examResult.testAnswer[index + (answerCurrentPage) * 20 - 10].answer} width="100%" readOnly={true} disabled={true} />
                                    <div style={{position: "absolute", top: 1, left: 50}}>
                                        {
                                            !examResult.testAnswer[index + (answerCurrentPage) * 20 - 10].isCorrect &&
                                            (
                                                <span style={{...fonts.label4Medium, color: colors.blue600}}>{examResult.testQuestion[index + (answerCurrentPage) * 20 - 10].correctAnswer}</span>
                                            )
                                        }
                                    </div>
                                </div>
                                }
                            </div>
                        </OMRItem>
                    ))}
                    </>
                )}
                </div>
                </>
            </OMRBody>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 8, marginTop: 8}}>
                <div style={{cursor: answerCurrentPage > 1 ? 'pointer' : 'default'}} onClick={() => {
                    if(answerCurrentPage > 1) {
                        setAnswerCurrentPage(answerCurrentPage - 1)
                    }
                }}>
                    <ChevronLeft width='20px' height='20px' color={answerCurrentPage > 1 ? colors.gray500 : colors.gray200}/>
                </div>
                <span style={{...fonts.label5Regular, color: colors.gray900}}>{answerCurrentPage}/{Math.ceil(examResult.testQuestion.length / 20)}</span>
                <div style={{cursor: answerCurrentPage < Math.ceil(examResult.testQuestion.length / 20) ? 'pointer' : 'default'}} onClick={() => {
                    if(answerCurrentPage < Math.ceil(examResult.testQuestion.length / 20)) {
                        setAnswerCurrentPage(answerCurrentPage + 1)
                    }
                }}>
                    <ChevronRight width='20px' height='20px' color={answerCurrentPage < Math.ceil(examResult.testQuestion.length / 20) ? colors.gray500 : colors.gray200}/> 
                </div>
            </div>
        </>
    )
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 32px 40px;
    display: flex;
    flex-direction: row;
    position: relative;
`;

const LeftSection = styled.div`
    width: calc(60% - 40.5px);
    height: 100%;
    background-color: ${colors.white};
    border-radius: 20px;
    padding: 28px 24px;
    overflow-y: auto;
`;

const RightSection = styled.div`
    width: calc(40% - 40.5px);
    height: 100%;
    background-color: ${colors.white};
    border-radius: 20px;
    padding: 28px 24px;
    overflow-y: auto;
    position: relative;
`;

const AnswerTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    font-size: ${fonts.label5Medium.fontSize};
    font-family: ${fonts.label5Medium.fontFamily};
    line-height: ${fonts.label5Medium.lineHeight};

    th, td {
        text-align: center;
        vertical-align: middle;
    }

    th {
        background-color: ${colors.gray50};
        color: ${colors.gray900};
        height: 32px;
    }

    td {
        color: ${colors.gray900};
        height: 40px;
        border-left: 1px solid ${colors.gray100};
        border-bottom: 1px solid ${colors.gray100};
    }
`;

const OMRBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 40px;
  height: calc(100% - 100px);
`;

const OMRItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 24px;
  position: relative;
`;

const OMRInput = (props) => {
    const {value, onChange, complete, width, type, textAlign="left"} = props;
    const [isFocused, setIsFocused] = useState(false);
  
    return (
      <div style={{width: width, border: `1px solid ${isFocused ? colors.blue600 : colors.gray100}`, height: "100%", borderRadius: "4px", padding: "0 8px", backgroundColor: complete ? colors.gray50 : colors.white}}>
        <input 
          type={type} 
          value={value} 
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          style={{backgroundColor: complete ? colors.gray50 : colors.white, border: "none", ...fonts.label4Medium, width: "100%", textAlign: textAlign}} 
          onChange={onChange as (e: React.ChangeEvent<HTMLInputElement>) => void}
          />
      </div>
    )
  }