import { useContext } from "react";
import { Page_settings } from "../../../config/page_settings";
import moment from "moment";
import React, {useState, useRef, useEffect} from "react";
import styled from "styled-components";
import { rootStore } from "../../../mobx/store";

interface IRoomList {
    id: number;
    sender: number;
    partner: number;
    message: string;
    message_date: string;
    pinned: number;
    notification: number;
    unread_count: number;
    user_type: "STUDENT" | "EXPERT";
    is_file: number;
    user: {
      id: number;
      name: string;
      profile: string | null;
    };
  }

interface IContextMenu {
    roomId: number;
    x: number;
    y: number;
}

interface ChewingTalkChatListProps {
    item: IRoomList;
    selected: boolean;
    onClick: () => void;
    onPin: (roomId: number, memberId: number, pinned: boolean) => void;
    onShowContextMenu: (roomId: number, x: number, y: number) => void;
    rightClickedRoom: IContextMenu | null; 
}

const formatDate = (date: string) => {
    // 오늘인 경우 시각으로 표시
    if (moment().format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD")) {
      return moment(date).format("HH:mm");
    }

    // 오늘로부터 24시간 이후인 경우 날짜 차이로 표시
    return moment(date).format("YYYY-MM-DD");
  };


const ChewingTalkChatList: React.FC<ChewingTalkChatListProps> = (props) => {
    const context: any = useContext(Page_settings);
    const { item, selected, onClick, onPin, rightClickedRoom, onShowContextMenu } = props;
    const menuRef = useRef(null);

    // 마우스 오른쪽 클릭 시 추가 메뉴 표시
    const handleContextMenu = (event) => {
        event.preventDefault(); // 기본 브라우저 메뉴 표시 유지
        onShowContextMenu(item.id, event.pageX , event.pageY);
    };
    return (
        <button className={("coach-item " + (selected ? "selected" : ""))} onClick={() => onClick()} onContextMenu={(event) => {
            handleContextMenu(event);
        }}>
            {/* 우클릭 메뉴 */}
            {rightClickedRoom && rightClickedRoom.roomId == item.id && (
                    <ContextMenuContainer ref={menuRef} style={{ top: `${rightClickedRoom.y-50}px`, left: `${rightClickedRoom.x}px` }}>
                      <ContextMenuItem onClick={() => 
                        {
                            onPin(item.id, rootStore.getProfile.id, item.pinned === 0)
                            onShowContextMenu(0, 0, 0)
                        }}>
                        {item.pinned === 0 ? "상단 고정" : "상단 고정 해제"}
                      </ContextMenuItem>
                    </ContextMenuContainer>
                )}
            <div className="chewingtalk-profile">
                <img 
                    src={item.user.profile != null ? context.loadImage(item.user.profile) : "/assets/image/chewing_plus_logo.png"}
                    alt="" />
            </div>
            <div className="name-chat-time-box">
                <div className="name">{item.user.name}
                {item.user_type === "EXPERT" ? <div className="expert">선생님</div> :null}
                {item.pinned == 1 && <div className="pinned"style={{ display: "flex", flexDirection:"row", justifyContent:"flex-start"}}><img src={"/assets/image/icon_pinned.png"} width={10}/></div>}
                {item.notification == 0 && <div className="notification"></div>}
                </div>
                <div className="chat">{item.message}</div>
                <div className="time">{(item.message_date && item.message_date != "") ? formatDate(item.message_date) : ""}</div>

            </div>

            <div className="unread-count">        
                    {
                        item.unread_count != 0 && !selected &&
                        <div className="red-circle">
                            <div className="count">
                            {item.unread_count}
                            </div>
                        </div>
                    }
            </div>
        </button>
    );
}

export default ChewingTalkChatList;

const ContextMenuContainer = styled.div`
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 5px 0;
  margin: 0;
  z-index: 1000;
`;

const ContextMenuItem = styled.li`
  padding: 5px 20px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;
