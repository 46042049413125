
interface ITypography {
    fontSize: string;
    fontFamily: string;
    lineHeight: string;
}

const typography: { [key: string]: ITypography } = {
    heading1Bold: {
        fontSize: '32px',
        fontFamily: 'Pretendard Bold',
        lineHeight: "1.5"
    },heading1SemiBold: {
        fontSize: '32px',
        fontFamily: 'Pretendard SemiBold',
        lineHeight: "1.5"
    },title1Bold: {
        fontSize: '25px',
        fontFamily: 'Pretendard Bold',
        lineHeight: "1.5"
    },title1SemiBold: {
        fontSize: '25px',
        fontFamily: 'Pretendard SemiBold',
        lineHeight: "1.5"
    },title2Bold: {
        fontSize: '21px',
        fontFamily: 'Pretendard Bold',
        lineHeight: "1.5"
    },title2SemiBold: {
        fontSize: '21px',
        fontFamily: 'Pretendard SemiBold',
        lineHeight: "1.5"
    },title3Bold: {
        fontSize: '19px',
        fontFamily: 'Pretendard Bold',
        lineHeight: "1.5"
    },title3SemiBold: {
        fontSize: '19px',
        fontFamily: 'Pretendard SemiBold',
        lineHeight: "1.5"
    },body1SemiBold: {
        fontSize: '19px',
        fontFamily: 'Pretendard SemiBold',
        lineHeight: "1.5"
    },body1Medium: {
        fontSize: '19px',
        fontFamily: 'Pretendard Medium',
        lineHeight: "1.5"
    },body1Regular: {
        fontSize: '19px',
        fontFamily: 'Pretendard Regular',
        lineHeight: "1.5"
    },body2SemiBold: {
        fontSize: '17px',
        fontFamily: 'Pretendard SemiBold',
        lineHeight: "1.5"
    },body2Medium: {
        fontSize: '17px',
        fontFamily: 'Pretendard Medium',
        lineHeight: "1.5"
    },body2Regular: {
        fontSize: '17px',
        fontFamily: 'Pretendard Regular',
        lineHeight: "1.5"
    },body3SemiBold: {
        fontSize: '15px',
        fontFamily: 'Pretendard SemiBold',
        lineHeight: "1.5"
    },body3Medium: {
        fontSize: '15px',
        fontFamily: 'Pretendard Medium',
        lineHeight: "1.5"
    },body3Regular: {
        fontSize: '15px',
        fontFamily: 'Pretendard Regular',
        lineHeight: "1.5"
    },detail1Regular: {
        fontSize: '15px',
        fontFamily: 'Pretendard Regular',
        lineHeight: "1.5"
    },detail2Regular: {
        fontSize: '13px',
        fontFamily: 'Pretendard Regular',
        lineHeight: "1.5"
    },label1SemiBold: {
        fontSize: '19px',
        fontFamily: 'Pretendard SemiBold',
        lineHeight: "1.5"
    },label1Medium: {
        fontSize: '19px',
        fontFamily: 'Pretendard Medium',
        lineHeight: "1.5"
    },label1Regular: {
        fontSize: '19px',
        fontFamily: 'Pretendard Regular',
        lineHeight: "1.5"
    },label2SemiBold: {
        fontSize: '17px',
        fontFamily: 'Pretendard SemiBold',
        lineHeight: "1.5"
    },label2Medium: {
        fontSize: '17px',
        fontFamily: 'Pretendard Medium',
        lineHeight: "1.5"
    },label2Regular: {
        fontSize: '17px',
        fontFamily: 'Pretendard Regular',
        lineHeight: "1.5"
    },label3SemiBold: {
        fontSize: '15px',    
        fontFamily: 'Pretendard SemiBold',
        lineHeight: "1.5"
    },label3Medium: {
        fontSize: '15px',
        fontFamily: 'Pretendard Medium',
        lineHeight: "1.5"
    },label3Regular: {
        fontSize: '15px',
        fontFamily: 'Pretendard Regular',
        lineHeight: "1.5"
    },label4SemiBold: {
        fontSize: '13px',
        fontFamily: 'Pretendard SemiBold',
        lineHeight: "1.5"
    },label4Medium: {
        fontSize: '13px',
        fontFamily: 'Pretendard Medium',
        lineHeight: "1.5"
    },label4Regular: {
        fontSize: '13px',
        fontFamily: 'Pretendard Regular',
        lineHeight: "1.5"
    },label5SemiBold: {
        fontSize: '11px',
        fontFamily: 'Pretendard SemiBold',
        lineHeight: "1.5"
    },label5Medium: {
        fontSize: '11px',
        fontFamily: 'Pretendard Medium',
        lineHeight: "1.5"
    },label5Regular: {
        fontSize: '11px',
        fontFamily: 'Pretendard Regular',
        lineHeight: "1.5"
    }
};

export default typography; 