import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import { withRouter } from "react-router-dom";
import PaymentSearch from '../../components/control/custom_input/payment_search';
import { Page_settings } from '../../config/page_settings';
import strings from '../../lang/strings';
import { rootStore } from '../../mobx/store';
import PaymentHeader from './header/payment_header';
import ConfirmModal from './modal/confirm_modal';



const BillManagement = observer((props) => {
  const [checkboxes, setCheckboxes] = useState([]);
  const [billList,setBillList]  = useState([]);
  const [choiceBill, setChoiceBill] = useState([]);
  const context = useContext(Page_settings);
  const [count, setCount] = useState(0);
  const [keyword,setKeyword] = useState('');
  const [selectAll, setSelectAll] = useState(false); // 전체 선택 체크박스
  const [toggleMenu, setToggleMenu] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectCategory, setSelectCategory] = useState(false);
  const [resendStatus, setResendStatus] = useState(false);
  const [sort, setSort] = useState(0);
  const [sortNanme, setSortName] = useState(strings.payment_sort[0].name);
  const [paymentToken, setPaymentToken] =  useState(localStorage.getItem('payment_token') ? JSON.parse(localStorage.getItem('payment_token')).token : null);
  const [tokenTime, setTokenTime] =  useState(localStorage.getItem('payment_token') ? JSON.parse(localStorage.getItem('payment_token')).time : null)
  const [deleteStatus, setDeleteStatus] = useState(false);

  useEffect(() => {
    return () => {
      context.handleSetPageHeader(true);
      context.handleSetPageSidebar(true);
    };
  }, []);

  useEffect(() => {
    getBillList();
  }, [sort,resendStatus, deleteStatus]);
  

  const tokenVerification = (tokenTime) => {
    // 현재 시각을 밀리초 단위로 얻음
  const currentTime = Date.now();

  // 주어진 시간 값과 현재 시각의 차이를 계산
  const difference = currentTime - tokenTime;

  // 차이가 지정된 시간 이상이면 true, 아니면 false 반환
  return difference >= 3600000;
  }

// 메뉴버튼, 다른곳 클릭시 사라짐
useEffect(() => {
  const handleOutsideClick = (event) => {
    if (event.target.closest('.menu') === null) {
      setToggleMenu(false);
    }
  };

  document.addEventListener('click', handleOutsideClick);

  return () => {
    document.removeEventListener('click', handleOutsideClick);
  };
}, []);

const handleChange = (index) => {
  const newCheckboxes = [...checkboxes];
  
  const selectBill = [...billList];
  // setChoiceBill([...choiceBill,selectBill[index]])
  
  newCheckboxes[index] = !newCheckboxes[index];

  if (newCheckboxes[index]) {
    // 체크박스가 선택될 경우, 배열에 데이터를 추가합니다.
    setChoiceBill([...choiceBill, selectBill[index]]);
  } else {
    // 체크박스가 해제될 경우, 배열에서 해당 데이터를 제거합니다.
    const removedItem = selectBill[index];
    setChoiceBill(choiceBill.filter((item) => item.id !== removedItem.id));
  }

  setCheckboxes(newCheckboxes);

  const newCount = newCheckboxes.filter((checkbox) => checkbox).length;
  setCount(newCount);

  // 모든 체크박스가 체크되어 있다면 전체 선택 체크박스도 체크하게 하고, 그렇지 않다면 해제합니다.
  setSelectAll(newCheckboxes.every((checkbox) => checkbox));
};

  const handleSelectAllChange = () => {
    const newSelectAll = !selectAll;
    setSelectAll(!selectAll);
    setCheckboxes(checkboxes.map(() => !selectAll));
    setCount(!selectAll ? checkboxes.length : 0);

    if (newSelectAll) {
      // 전체 선택이 활성화된 경우, billList의 모든 항목을 choiceBill에 추가
      setChoiceBill([...billList]);
    } else {
      // 전체 선택이 비활성화된 경우, choiceBill을 비움
      setChoiceBill([]);
    }

  };
  
  const handleMenuClick = (index, event) => {
    event.stopPropagation();
    setToggleMenu((prevToggleMenu) => ({
      ...prevToggleMenu,
      [index]: !prevToggleMenu[index]
    }));
  };

  const getBillList = () => {
    // 토큰 유효성 검사 후, 만료된 경우 새 토큰 발행
    if (tokenVerification(tokenTime)) {
      context.post(
        'payment/getToken',
        {},
        response => {
            rootStore.setPaymentToken(response);
            setPaymentToken(response);
            setTokenTime(Date.now());
            context.post(
              'payment/getBillList',
              {
                token : response,
                // mode: "management"
                sort,
                keyword
              },
              response => {
                setBillList(response.bills)
                setCheckboxes(new Array(response.bills.length).fill(false));
              });  
        }
      )
    }else{
      context.post(
        'payment/getBillList',
        {
          token : paymentToken,
          // mode: "management"
          sort,
          keyword
        },
        response => {
          setBillList(response.bills)
          setCheckboxes(new Array(response.bills.length).fill(false));
          console.log("re",response)
        });  
    }
  }

  const deleteBill = (bill_id) => {
    
    // 토큰 유효성 검사 후, 만료된 경우 새 토큰 발행
    if (tokenVerification(tokenTime)) {
      context.post(
        'payment/getToken',
        {},
        response => {
            rootStore.setPaymentToken(response);
            context.post(
              'payment/delete',
              {
                token : paymentToken,
                billID : bill_id
              },
              response => {
                setDeleteStatus(!deleteStatus);
              });  
        }
      )
      }else{
    context.post(
      'payment/delete',
      {
        token : paymentToken,
        billID : bill_id
      },
      response => {
        setDeleteStatus(!deleteStatus);
      });  
    }
  }



  // totalAmount 변경을 처리하는 함수
function handleTotalAmountChange(index, newValue) {
  // 상태 업데이트를 위한 새 배열 생성
  const newBillList = [...billList];
  // 선택된 항목의 totalAmount 값을 업데이트
  newBillList[index].totalAmount = newValue;
  // 상태 업데이트
  setBillList(newBillList);
}

// reason 변경을 처리하는 함수
function handleReasonChange(index, newValue) {
  const newBillList = [...billList];
  newBillList[index].reason = newValue;
  setBillList(newBillList);
}

// message 변경을 처리하는 함수
function handleMessageChange(index, newValue) {
  const newBillList = [...billList];
  newBillList[index].message = newValue;
  setBillList(newBillList);
}

const clickSort= (item) => {
  setSort(item.code);
  setSortName(item.name)
}


  return (
      <div class="main-content bill-management-container">
      {/* <CloudContainer/> */}
      {
        <PaymentSearch
          placeholder={"이름,휴대폰 번호"}
          inputType={'text'}
          value={keyword}
          onChange={(v) => {
            setKeyword(v)
          }}
          onSearch={() => {
              getBillList(keyword);
          }}
        />

      }
      <PaymentHeader/>
      {confirmModal && (<ConfirmModal
          open={confirmModal}
          onClose={() => {
            setConfirmModal(false);
          }}
          onResend={() => {
            setResendStatus(!resendStatus)
          }}
          count={count}
          choiceBill={choiceBill}
        />)}
        <div class="bill-management-box">
          <div class="bill-management-header">
            <div class="left selected">일반</div>
          </div>
          <div class="bill-grid-container">
                <input type="checkbox" class="all-check" checked={selectAll} onChange={handleSelectAllChange}/>
                <div class="category" onClick={()=> setSelectCategory(!selectCategory)}>
                <div class="flex">{sortNanme}
                <img class={selectCategory ? 'reverse' : ''} src='/assets/image/icon_arrow_down_gray.png' alt=''/>
                </div>
                {selectCategory && (
                  <div className="category-box">
                      {
                    strings.payment_sort.map((item, idx) => {
                      return(
                          <button onClick={()=>clickSort(item)}>{item.name}</button>
                      )
                    })
                  }
                  </div>
                  )}
                </div>
                <div style={{textAlign:'end'}}>청구금액</div>
                <div>청구사유</div>
                <div>안내 메시지</div>
          </div>    
          <PerfectScrollbar style={{ height: `82%`}} options={{ suppressScrollX: true }}>
          {
          billList.length > 0 ?
            billList.map((item, index) => 
              <div className={`grid-container bill-grid-6-columns ${checkboxes[index] ? "checked-bill" : ""}`}>
                <input type="checkbox" class="row-check"  checked={checkboxes[index]}  onChange={() => handleChange(index)} />
                <div class="padding-left"><div class="name">{item.receiver.name}</div><div class="phone-num">{item.receiver.phone}</div></div>
                <input className={`table-input billing-amount ${checkboxes[index] ? "checked-bill-input" : ""}`} value={item.totalAmount} onChange={(e) => handleTotalAmountChange(index, e.target.value)} />
                <input class={`table-input billing-reason ${checkboxes[index] ? "checked-bill-input" : ""}`} value={item.reason} onChange={(e) => handleReasonChange(index, e.target.value)}/>
                <input class={`table-input instruction-message ${checkboxes[index] ? "checked-bill-input" : ""}`} value={item.message} onChange={(e) => handleMessageChange(index, e.target.value)} />
                <div class="menu" onClick={(event) => handleMenuClick(index, event)}
                >
                  <img src="/assets/image/icon_3dots.png" alt='' />
                  {toggleMenu[index] && (
                    <div className="del-box">
                      <button className='del-btn' onClick={() => deleteBill(item.id)}>삭제</button>
                    </div>
                  )}
                </div>
              </div>
            
          )
          :null
          
          }
          </PerfectScrollbar>
          
          {count > 0 && <div className="bottom-menu">
            <div class="left">
              {/* 삭제하기 */}
            </div>
            <div class="right" onClick={() => setConfirmModal(true)}>
              <span>{count}건 </span>
              보내기
            </div>
          </div>}    
        </div>
        
      </div>
    
    
  );
})


export default withRouter(BillManagement);
