import React from "react";

export default function ChatInput(props) {
    const handleEnter = e => {
        if (e.keyCode == 13) {
            props.onSend()
        }
    };
    return (
        <div className="chat-input">
            <input className="input-transparent" placeholder={props.placeholder} type={props.inputType} maxLength={props.maxLength}
                   value={props.value} onChange={(e) => props.onChange(e.target.value)}
                   onKeyDown={handleEnter}
            />
            <button onClick={()=>props.onSend()} >
                <img src={props.value == '' ? "/assets/image/btn_send.png" : "/assets/image/btn_send_active.png"} alt=""/>
            </button>
        </div>
    );
}

