import React, { useContext, useEffect, useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from "styled-components";
import strings from "../../../lang/strings";
import StudentSearch from "../customSearch/studentSearch";
import { Page_settings } from '../../../config/page_settings';
import { rootStore } from "../../../mobx/store";
import { API_URL } from "../../../config/const";
import axios from "axios";
import CalendarModal from './calendarModal';
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";

function MissionEditModal({ onClose, mission_id, class_id, mission_student, onUpdate }) {
  const [wholeTextArray, setWholeTextArray] = useState([]);
  const [studentValue, setStudentValue] = useState('')
  const [titleValue, setTitleValue] = useState('')
  const [contentValue, setContentValue] = useState('')
  const [isHaveInputValue, setIsHaveInputValue] = useState(false)
  const [clickInput, setClickInput] = useState(false)
  const [dropDownList, setDropDownList] = useState(wholeTextArray)
  const [dropDownItemIndex, setDropDownItemIndex] = useState(-1)
  const [allSelectedStatus, setAllSelectedStatus] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const imageRef = useRef();
  const [selectImg, setSelectImg] = useState(false);
  const [getThumbnail, setGetThumbnail] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [url, setUrl] = useState([]);
  const [profile, setProfile] = useState("");
  const [dateValue, setDateValue] = useState(moment().format('YYYY-MM-DD'))
  const [dropdownStatus, setDropdownStatus] = useState(false);
  const [limitTime, setLimitTime] = useState('00:00');
  const [openCalendar, setOpenCalendar] = useState(false);
  const [clickLimitTime, setClickLimitTime] = useState(false);
  console.log("class_id",class_id)

  const fileTypes = [
    "image/bmp",
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/tiff",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "text/htm",
    "text/html",
    "application/pdf",
    "application/vnd.ms-powerpoint",
    "applicatiapplication/vnd.openxmlformats-officedocument.presentationml.presentation",
    "text/plain",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "video/mp4"]
  const imgType = ["bmp",
    "jpg",
    "jpeg",
    "png",
    "tiff"]

  const context = useContext(Page_settings);
  const limit_time = [];

  useEffect(() => {
    loadStudentInfo();
    loadMissionInfo();
    loadMissionInfoFile();
    if (rootStore.getProfile != null) {
      setProfile(rootStore.getProfile.profile);
    }
  }, []);

  useEffect(() => {
    showDropDownList()
  }, [studentValue, clickInput])

  useEffect(() => {
    function handleClickOutside(event) {

      function getIdOrParentId(event) {
        let targetElement = event.target;
        
        // targetElement에 id가 있으면 해당 id를 사용
        while (targetElement && !targetElement.id) {
            // id가 없으면 부모 요소로 이동
            targetElement = targetElement.parentNode;
        }
    
        // 최종적으로 찾은 요소의 id 반환, 요소에 id가 없다면 null 반환
        return targetElement ? targetElement.id : null;
    }

      if (getIdOrParentId(event) !== 'inputBox' && getIdOrParentId(event) !== 'dropBox'  && getIdOrParentId(event) !== 'dropBox1' ) {
        handleFocusOut();  // 외부 클릭 시 실행할 함수
    }
    }

    // 문서 전체에 이벤트 리스너 추가
    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      // 컴포넌트 언마운트 시 이벤트 리스너 제거
      document.removeEventListener('mousedown', handleClickOutside);
    };


  }, []);  // 의존성 배열에 handleFocusOut 추가


  const loadStudentInfo = () => {
    context.get(
      'class/getClassMemberList',
      { class_id },
      response => {
        console.log("fff",response)
        // 멤버리스트가 있을경우 
        if(response){
          setWholeTextArray(response);
          response.forEach(dropdownItem => {
          mission_student.forEach(memberItem => {
            if (dropdownItem.member === memberItem.member) {
              // setCheckedItems(dropdownItem);
              setCheckedItems((prev) => [...prev, dropdownItem]);

            }
          });
        });
        }
        
      }
    );
  }
  const loadMissionInfo = () => {
    context.get(
      'class/getMissionInfo',
      { mission_id },
      response => {
        console.log("?",response)
        setTitleValue(response.title)
        setContentValue(response.content)
      }
    );
  }

  const loadMissionInfoFile = () => {
    
    context.get(
      'class/getMissionInfoFile',
      { mission_id },
      response => {
        console.log("r",response)
        setFileList(response)
        const newUrls = response.map(file => file.url); // 모든 파일에서 URL 추출
        setUrl(prevUrls => [...prevUrls, ...newUrls]); // 기존 URL 배열에 새 URL 배열 추가
        setGetThumbnail([...getThumbnail, ...response]);
      }
    );
  }

  const showDropDownList = () => {

    if (studentValue === '') {
      setIsHaveInputValue(false);
      setDropDownList([]);
    } else {
      const choosenTextList = wholeTextArray.filter((dataItem) =>
        dataItem.name.includes(studentValue)
      );
      setDropDownList(choosenTextList);

      // Check if there are any unchecked items
      const hasUncheckedItems = choosenTextList.some(
        (item) => !checkedItems.includes(item)
      );

      if (hasUncheckedItems) {
        setAllSelectedStatus(false);
      }
    }

    if (studentValue === '' && clickInput) {
      const choosenTextList = wholeTextArray.filter((dataItem) =>
        dataItem.name.includes(studentValue)
      );
      setDropDownList(choosenTextList);

      // Check if there are any unchecked items
      const hasUncheckedItems = choosenTextList.some(
        (item) => !checkedItems.includes(item)
      );

      if (hasUncheckedItems) {
        setAllSelectedStatus(false);
      }
    }
  };

  for (let hours = 0; hours < 24; hours++) {
    for (let minutes = 0; minutes < 60; minutes += 10) {
      const hourFormatted = hours.toString().padStart(2, '0');
      const minuteFormatted = minutes.toString().padStart(2, '0');
      limit_time.push({ time: `${hourFormatted}:${minuteFormatted}` });
    }
  }

  const changeInputValue = event => {
    setStudentValue(event.target.value)
    setIsHaveInputValue(true)
    setClickInput(false)
  }

  const clickInputBox = event => {
    setClickInput(true)
  }


  const changeTitleValue = event => {
    setTitleValue(event.target.value)
  }
  const changeDateValue = event => {
    setDateValue(event.target.value)
  }

  const changeContentValue = event => {
    setContentValue(event.target.value)
  }

  const handleUpdate = () => {
    onUpdate?.();
  };

  const clickDropDown = () => {
    setDropdownStatus(!dropdownStatus)
  }


  const clickDropDownItem = clickedItem => {
    setStudentValue(clickedItem)
    setIsHaveInputValue(false)
  }

  const handleEnterKeyPress = () => {
    setIsHaveInputValue(false)
    setAllSelectedStatus(false);
    setStudentValue('');
    setClickInput(false);
  };


  const handleDropDownKey = event => {
    if (checkedItems.length > 0) {
      if (event.key === 'Enter') {
        handleEnterKeyPress();
      }
    }
  }

  const handleFocusOut = () => {
    setIsHaveInputValue(false)
    setAllSelectedStatus(false);
    setStudentValue('');
    setClickInput(false);
  }



  const handleCheckboxChange = (event, dropDownIndex) => {
    const isChecked = event ? event.target.checked : !checkedItems.includes(dropDownList[dropDownIndex]);

    if (isChecked) {
      setCheckedItems((prev) => [...prev, dropDownList[dropDownIndex]]);
    } else {
      setCheckedItems((prev) =>
        prev.filter((item) => item !== dropDownList[dropDownIndex])
      );
    }
  };

  const onClickTime = (data) => {
    setLimitTime(data)
    setClickLimitTime(false)
  }


  const onClickAllStudent = () => {
    setAllSelectedStatus((prev) => !prev);

    if (!allSelectedStatus) {
      setCheckedItems((prev) => {
        const updatedItems = [...prev, ...dropDownList.filter((item) => !prev.includes(item))];
        return updatedItems;
      });
    } else {
      setCheckedItems([]);
    }
  };

  const deleteStudent = (studentID) => {
    // 삭제 버튼 클릭 시 해당 아이디를 checkedItems에서 찾아서 삭제
    setCheckedItems(prevItems => prevItems.filter(item => item.member !== studentID));
  }

  const onClickDate = () => {
    setOpenCalendar(true);
  };

  const handleClose = () => {
    onClose?.();
  };

  const handleWheel = (event) => {
    const delta = event.deltaY;
    if (delta > 0) {
      // 스크롤 다운
      selectNextTime();
    } else if (delta < 0) {
      // 스크롤 업
      selectPrevTime();
    }
  };

  const selectNextTime = () => {
    const currentIndex = limit_time.findIndex(timeObj => timeObj.time === limitTime);
    if (currentIndex < limit_time.length - 1) {
      setLimitTime(limit_time[currentIndex + 1].time);
    }
  };

  const selectPrevTime = () => {
    const currentIndex = limit_time.findIndex(timeObj => timeObj.time === limitTime);
    if (currentIndex > 0) {
      setLimitTime(limit_time[currentIndex - 1].time);
    }
  };

  const EnableConfirm = () => {
    return (
      checkedItems.length > 0 && titleValue !== '' && contentValue !== '' && dateValue !== '' &&
      limitTime !== ''
    )
  }

  const onImageChange = (e) => {

    let file = [e.target.files];
    let thumbnail = e.target.files[0];
    setUrl([...url, ...file]);
    let list = [];

    let reader = new FileReader();

    // 이미지 불러오기
    reader.onloadend = function () {
      setGetThumbnail([...getThumbnail, reader.result]);
    };
    reader.readAsDataURL(thumbnail);

    file.forEach(item => {
      if (fileTypes.indexOf(item[0].type) < 0) return

      let ext = item[0].name.split(".")[item[0].name.split(".").length - 1];
      let type = "/assets/image/icon_file_";
      if (ext == "docx") {
        type += "docs";
      } else if (ext == "mp4") {
        type += "drive"
      } else {
        type += ext
      }
      type += ".png";

      list.push({ title: item[0].name, size: item[0].size, type: type })
    });
    setFileList([...fileList, ...list])
  }

  const client = axios.create({
    baseURL: API_URL + '/app/',
  })

  const onEditMission = async () => {
    if (url.length != 0) {
      let formData = new FormData();
      const filteredUrls = url.filter(entry => {
        return typeof entry === 'string' || (entry instanceof File);
      });
      let urls = url.filter(entry => typeof entry !== 'string' && entry instanceof FileList);
      urls.forEach((entry, index) => {
        formData.append('file', entry[0]);
      });
      if(urls.length != 0 ){
        await client.post(
          'upload/files/mission',
          formData,
        ).then(
          response => {
            const uploadedUrls = response.data.result_data.file; // 서버가 반환하는 URL 목록
            urls = filteredUrls.concat(uploadedUrls);
            
  
            context.post(
              'class/editMission',
              {
                mission_id,
                title: titleValue,
                content: contentValue,
                file_list: fileList,
                file_url: urls,
                member_info: checkedItems,
                
                deadline: dateValue + "/" + limitTime,
              },
              response => {
                setUrl([]);
                setFileList([]);
                setGetThumbnail([]);
                handleUpdate();
                onClose?.();
                
              }
            );
          }
        );
      }else{
        context.post(
          'class/editMission',
          {
            mission_id,
            title: titleValue,
            content: contentValue,
            file_list: fileList,
            file_url: url,
            member_info: checkedItems,
            
            deadline: dateValue + "/" + limitTime,
          },
          response => {
            setUrl([]);
            setFileList([]);
            setGetThumbnail([]);
            handleUpdate();
            onClose?.();
            
          }
        );
      }

      


      
    } else {
      context.post(
        "class/editMission",
        {
          mission_id,
          title: titleValue,
          content: contentValue,
          file_list: null,
          file_url: null,
          member_info: checkedItems,
          status: "진행중",
          deadline: dateValue + "/" + limitTime,
        },
        response => {
          setUrl([]);
          setFileList([]);
          setGetThumbnail([]);
          handleUpdate();
          onClose?.();
          
        }
      );
    }
  }

  const delFile = (idx) => {

    

    setFileList(preList => preList.filter((item, index) => {
      return idx != index
    }));
    setUrl(preList => preList.filter((item, index) => {
      return idx != index
    }));

    setGetThumbnail(preList => preList.filter((item, index) => {
      return idx != index
    }));

  }

  const fileResize = (fileSize) => {
    const dataSize = fileSize
    let formattedData = '';

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    if (dataSize === 0) return '0 Byte';

    const k = parseInt(Math.floor(Math.log(dataSize) / Math.log(1024)));

    formattedData = Math.round(100 * (dataSize / Math.pow(1024, k))) / 100 + ' ' + sizes[k];
    return formattedData;
  }

  return (
    <>
      {openCalendar && (<CalendarModal
        open={openCalendar}
        onClose={() => {
          setOpenCalendar(false);
        }}
        setDateValue={setDateValue}
      />)}
      <Overlay>
        <ModalWrap>
          <div class="classAddModal-head">
            <h4>과제 수정</h4>
            <img class="exit-btn" onClick={handleClose} src="/assets/image/icon_close_gray.png" />
          </div>
          <div class="classAddModal-subTitle">
            학생 검색
          </div>
          <div class="studentAddModal-searchContainer" isHaveInputValue={isHaveInputValue}>
          <div className="class-search  align-item-center box-shadow" style={{flex:8}}  >
            <input 
              id='inputBox'
              autoComplete="off" // 자동 완성 기능 비활성화
              className="input-transparent cursor"
              placeholder={"학생 이름을 검색하세요."}
              inputType={'text'}
              value={studentValue}
              onChange={changeInputValue}
              onKeyUp={handleDropDownKey}
              onClick={clickInputBox}
              
            />
            </div>
          </div>

          {clickInput & !isHaveInputValue ? (
            <DropDownBox id='dropBox'>
              {dropDownList.length === 0 ? (
                <DropDownItem>해당하는 학생이 없습니다</DropDownItem>
              ):
              <div onClick={() => onClickAllStudent()} class={allSelectedStatus ? "missionAddModal-all-checked" : "missionAddModal-all-unchecked"}>
                모두 선택
                <img src={allSelectedStatus ? "/assets/image/icon_checked.png" : "/assets/image/icon_unchecked.png"} alt="" />
              </div>
            }
              
              {dropDownList.map((dropDownItem, dropDownIndex) => {
  return (
    <DropDownItem
      key={dropDownIndex}
      onMouseOver={() => setDropDownItemIndex(dropDownIndex)}
      className={dropDownItemIndex === dropDownIndex ? 'selected' : ''}
    >
      <div
        className="studentAddModal-dropdown-container"
        onClick={() => handleCheckboxChange(null, dropDownIndex)} // 부모 영역 클릭 시 체크박스 선택
      >
        <div className='studentAddModal-dropdown-profile'>
          <img className="user-img"
            src={dropDownItem.profile != null && dropDownItem.profile !== "" ? context.loadImage(dropDownItem.profile) : "/assets/image/chewing_plus_logo.png"}
            alt=''
          />
        </div>
        <div className="studentAddModal-dropdown-name-mail">
          {dropDownItem.name}
        </div>
        <div className="missionAddModal-dropdown-checkbox">
          <div class="form-check">
            <input className="form-check-input"
              type="checkbox"
              checked={checkedItems.includes(dropDownItem)}
              onChange={(event) => handleCheckboxChange(event, dropDownIndex)}
              onClick={(e) => e.stopPropagation()} // 클릭 이벤트가 부모에게 전파되지 않도록 함
            />
          </div>
        </div>
      </div>
    </DropDownItem>
  );
})}
            </DropDownBox>
          ) : null}


          {isHaveInputValue && (
            <DropDownBox id='dropBox1'>

              {dropDownList.length === 0 ? (
                <DropDownItem>해당하는 학생이 없습니다</DropDownItem>
              ) :
                <div onClick={() => onClickAllStudent()} class={allSelectedStatus ? "missionAddModal-all-checked" : "missionAddModal-all-unchecked"}>
                  모두 선택
                  <img src={allSelectedStatus ? "/assets/image/icon_checked.png" : "/assets/image/icon_unchecked.png"} alt="" />
                </div>
              }
{dropDownList.map((dropDownItem, dropDownIndex) => {
  return (
    <DropDownItem
      key={dropDownIndex}
      onMouseOver={() => setDropDownItemIndex(dropDownIndex)}
      className={dropDownItemIndex === dropDownIndex ? 'selected' : ''}
    >
      <div
        className="studentAddModal-dropdown-container"
        onClick={() => handleCheckboxChange(null, dropDownIndex)} // 부모 영역 클릭 시 체크박스 선택
      >
        <div className='studentAddModal-dropdown-profile'>
          <img className="user-img"
            src={dropDownItem.profile != null && dropDownItem.profile !== "" ? context.loadImage(dropDownItem.profile) : "/assets/image/chewing_plus_logo.png"}
            alt=''
          />
        </div>
        <div className="studentAddModal-dropdown-name-mail">
          {dropDownItem.name}
        </div>
        <div className="missionAddModal-dropdown-checkbox">
          <div class="form-check">
            <input className="form-check-input"
              type="checkbox"
              checked={checkedItems.includes(dropDownItem)}
              onChange={(event) => handleCheckboxChange(event, dropDownIndex)}
              onClick={(e) => e.stopPropagation()} // 클릭 이벤트가 부모에게 전파되지 않도록 함
            />
          </div>
        </div>
      </div>
    </DropDownItem>
  );
})}
            </DropDownBox>
          )}
          <div class={checkedItems.length > 0 ? "missionAdd-student-list-container" : "missionAdd-student-list-null"}>
            {checkedItems.length > 0 ?
              checkedItems.map((item, idx) => {

                return (
                  <div class="missionAdd-student-list">
                    <div class="missionAdd-img-box">
                      <img src={item.profile != null ? context.loadImage(item.profile) : "/assets/image/chewing_plus_logo.png"} alt="" />
                    </div>
                    <div class="missionAdd-name"> {item.name}</div>
                    <div class="missionAdd-cancel" onClick={() => deleteStudent(item.member)}> <img src="/assets/image/icon_close_gray.png" alt='' /></div>
                  </div>
                )
              }) :

              "과제를 받을 학생을 추가해주세요"


            }
          </div>

          <div class="classAddModal-subTitle">
            제목
          </div>
          <input class="class-search"
            placeholder={strings.enter_title}
            inputType={'text'}
            value={titleValue}
            onChange={changeTitleValue}>
          </input>

          <div class="classAddModal-subTitle">
            내용
          </div>
          <input class="missionAddModal-content"
            placeholder={strings.enter_content}
            inputType={'text'}
            value={contentValue}
            onChange={changeContentValue}>
          </input>

          <input type='file' accept='image/bmp,image/jpg,image/jpeg,image/png,image/tiff,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/htm,text/html,application/pdf,application/vnd.ms-powerpoint,applicatiapplication/vnd.openxmlformats-officedocument.presentationml.presentation,text/plain,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,video/mp4' className='hide'
            onChange={(e) => onImageChange(e)}
            ref={imageRef} />

          {fileList.map((file, index) => (
            <div>
              {!imgType.includes(file.title.split('.').pop().toLowerCase()) ?
                <div class="mission-add-file-box">
                  <div className="">
                    <img class="grid-item file-img" src={file.type} alt='' />
                  </div>
                  <div className="grid-item file-title">
                    {file.title}
                  </div>
                  <div className="grid-item file-size">
                    {fileResize(file.size)}
                  </div>
                  <div className="grid-item img-box">
                    <img className="cursor" src='/assets/image/icon_close_gray.png' alt=''
                      onClick={() => {
                        delFile(index)
                      }}
                    />
                  </div>
                </div>
                :
                null
              }
            </div>
          ))}

          <div class="missionAddModal-img-container">
            <div class="missionAddModal-imgAdd" onClick={() => imageRef?.current.click()}>
              <img class="classAddModal-no-thumbnail" src="/assets/image/class_addImg1.png" alt="" />
            </div>
            <div class="missionAddModal-img-contents">
              {fileList.map((file, index) => (
                <div className='position-relative'>
                  {imgType.includes(file.title.split('.').pop().toLowerCase()) ?
                    <>
                      <img key={index} class="missionAddModal-thumbnail" src={file.url ? context.loadImage(file.url) : getThumbnail[index]} alt="" />
                      <button
                        style={{
                          border: "none",
                          background: "none",
                          position: "absolute",
                          top: -10,
                          right: -15
                        }}
                        onClick={() => {
                          delFile(index)
                        }}
                      >
                        <img src={"/assets/image/icon_cancel_btn.png"} alt="" />
                      </button>
                    </>
                    :
                    null
                  }

                </div>
              ))}
            </div>
          </div>

          <div class="classAddModal-subTitle">
            기한
          </div>
          <div class="classAddModal-bottom">
            <input class="class-search"
              placeholder={"2023. 01. 01"}
              inputType={'text'}
              value={dateValue}
              onChange={changeDateValue}
              onClick={onClickDate}>

            </input>
            <div onWheel={handleWheel}>

              <button class="pl on" onClick={() => setClickLimitTime(!clickLimitTime)}>
                <div>{limitTime} </div>
                <img src={'/assets/image/icon_arrow_down_gray.png'} alt='' />
                </button>
              {clickLimitTime ? (
                <ul class="listbox" id="listbox">
                  <PerfectScrollbar style={{ height: "70px" }} options={{ suppressScrollX: true }}>
                    {limit_time.map((timeObj, index) => (
                      <li key={index} >
                        <button class="list" onClick={() => onClickTime(timeObj.time)}>{timeObj.time}</button>
                      </li>
                    ))}
                  </PerfectScrollbar>
                </ul>
              ) : null

              }
            </div>
          </div>

          <Button style={{ backgroundColor: EnableConfirm() ? '#0F84F4' : '#DFE3E8' }} disabled={!EnableConfirm()} onClick={onEditMission}>수정</Button>
        </ModalWrap>
      </Overlay>
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const ModalWrap = styled.div`
  width: 700px;
  height: 650px;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  overflow: auto;
`;
const Button = styled.button`
cursor: pointer;
width: 100%;
color: #ffffff;
border-radius: 5px;
border:0px;
padding: 10px;
margin-top: 20px;
`;

const DropDownBox = styled.ul`
  display: block;
  position: absolute;
  margin: 0 auto;
  padding: 0px 5px;
  width: 94%;
  background-color: white;
  border: 1px solid #DFE1E8;
  border-radius: 8px;
  box-shadow: 0 10px 10px rgb(0, 0, 0, 0.3);
  list-style-type: none;
  z-index: 3;
  max-height: 200px; /* 최대 높이 설정 */
  overflow: auto; /* 스크롤바 표시 설정 */
`

const DropDownItem = styled.li`
  text-align: start;
  padding: 0px 5px;
  margin: 10px 0px;
`

export default MissionEditModal;