import React from 'react';
import moment from "moment";

const CloudItem = (props) => {

    const {item, onClick, type, onDoubleClick} = props;
    const ext_pos = item.title.lastIndexOf(".");
    const file_name = item.title.substring(0, ext_pos);
    const ext = item.title.substring(ext_pos + 1, item.title.length);

    const addDefaultSrc = (ev) => {
        ev.target.src = "/assets/image/icon_file_drive.png"
    }

    return (
        <button
            className={("cloud-item ") + (item.selected ? "cloud-item-selected" : "")}
            onClick={() => onClick()}
            onDoubleClick={() => onDoubleClick()}
        >
            {
                !item.selected &&
                <img src={"/assets/image/icon_circle_check_normal.png"} className="cloud-check cloud-uncheck" alt=""/>
            }
            {
                item.selected &&
                <img src={"/assets/image/icon_circle_check.png"} className="cloud-check" alt=""/>
            }

            <img src={type} alt="" onError={addDefaultSrc} className="item-image m-b-10"/>
            <div className="item-name d-flex justify-content-center">
                <div className="text-ellipsis">{file_name}</div>
                <div className="">.{ext}</div>
            </div>
            <div className="create-date">{moment(item.create_date).format("YY.MM.DD")}</div>
        </button>
    );
}

export default CloudItem;
