import React from "react";
import { Subject } from "../type/subject";
import styled from "styled-components";
import fonts from "../design/typography"

interface SubjectChipProps {
    subject: keyof typeof Subject;
    style?: React.CSSProperties;
}

const SubjectChip = (props: SubjectChipProps) => {
    const { subject, style } = props;

    return (
        <ChipWrapper style={{...style, backgroundColor: Subject[subject].backgroundColor}}>
            <ChipName style={{color: Subject[subject].color}}>{Subject[subject].showingName}</ChipName>
        </ChipWrapper>
    );
};

export default SubjectChip;


const ChipWrapper = styled.div`
    width: 39px;
    height: 24px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ChipName = styled.div`
    font-size: ${fonts.label4Medium.fontSize};
    font-family: ${fonts.label4Medium.fontFamily};
    line-height: ${fonts.label4Medium.lineHeight};
`;



