import React, { useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import "../style/main.css";
import "../style/mob.css";

const Answer = ({ isOpen, children }) => {
    const styles = useSpring({
        opacity: isOpen ? 5 : 0,
        maxHeight: isOpen ? 200 : 0,
        overflow: 'hidden',
        display : isOpen ? 'block' : 'none',
        from: { opacity: 0, maxHeight: 0 },
        config: { duration: 300 },
    });

    return (
        <animated.p style={styles} className="ans">
            {children}
        </animated.p>
    );
};

const Qna = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        setActiveIndex(prevIndex => (prevIndex === index ? null : index));
    };

    return (
        <section className="wrap qna-wrap">
            <div className="inner">
                <div className="main-tit">
                    <h2>이런 부분이 더 궁금해요!</h2>
                </div>
                <div className="qna-list">
                    {[
                        {
                            question: 'AI 튜터가 주는 문제는 어떻게 만들어지는것인가요?',
                            answer: '제공되는 문제는 평가원 출제위원 분들이 직접 만든 문제들을 기반으로 하고 있으며 AI 튜터를 위해 개발된 6000여가지의 유닛으로 이루어진 단원들에 대한 문제가 나와있습니다. 기관별로 필요시 문제은행 솔루션도 제공하고 있습니다.',
                        },
                        {
                            question: '학생별 맞춤 콘텐츠 제공을 위해서는 어떤 과정이 필요한가요?',
                            answer: '기본적으로 제공되는 로드맵을 학생들이 스스로 이용하면서 취약점을 알아가고 A라는 목표를 달성하기 위해 제시된 로드맵을 보고 영상학습과 문제풀이 등 다양한 학습을 스스로 할 수 있습니다. 해당 과정이 마무리되면 AI 튜터와 테스트, 취약점 분석을 위한 질의응답을 통해 학생들의 맞춤 커리큘럼이 나오게 되어 맞춤 콘텐츠 제공이 이루어집니다. 이과정을 반복하게 될경우 더 정확한 학습설계 및 커리큘럼 제공이 가능합니다.',
                        },
                        {
                            question: '학생들의 데이터는 어떻게 관리 되나요?',
                            answer: '츄잉 플랫폼 안에서 일어나는 모든 행동들은 데이터화 되어 학생별 데이터레포트가 생성되게 됩니다. 해당 화면에 나오는 대시보드는 선생님/학생/학부모 세종류로 이루어져있으며 기관에 따라 커스터마이징 하여 사용할 수 있도록 도와드리고 있습니다.',
                        },
                        {
                            question: '학습 컨설팅을 받으려면 어떻게 해야하나요?',
                            answer: '자체 보유중인 컨설턴트분께 컨설팅을 받고 싶다면 전문가 탭을 눌러 예약 > 상담 후 컨설팅을 받을 수 있습니다. 컨설팅 서비스는 주간 컨설팅 + 일일 컨설팅으로 이루어져 있으며 학생들의 지속적인 플래너 관리와 피드백 그리고 전문가와 함께 하는 중장기 로드맵 제작에 중심을 두어 목표달성을 위한 컨설팅 서비스를 제공하고 있습니다. 물론 기관에서 보유중인 컨설턴트가 있을경우 전문가 탭에서 추가가 가능합니다.',
                        },
                    ].map((item, index) => (
                        <div key={index} className="qna-box">
                            <p 
                                className={`que ${activeIndex === index ? 'active' : ''}`} 
                                onClick={() => handleToggle(index)}
                            >
                                {item.question}
                                <img src="/assets/image/landing_page/icon/ic-qna.svg" alt="화살표 아이콘" />
                            </p>
                            <Answer isOpen={activeIndex === index}>
                                {item.answer}
                            </Answer>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Qna;