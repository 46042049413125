import React, { useContext, useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from "styled-components";
import strings from "../../../lang/strings";
import StudentSearch from "../customSearch/studentSearch";
import { Page_settings } from '../../../config/page_settings';

function StudentAddModal({ onClose, class_id, member_list }) {

  const [inputValue, setInputValue] = useState('')
  const [showDropDown, setShowDropDown] = useState(false);
  const [dropDownList, setDropDownList] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]); // 선택된 멤버들
  const context = useContext(Page_settings);

  const onSearch = () => {
    setShowDropDown(true);
    if (inputValue === '') {
      setDropDownList([]);
    } else {
      context.get(
        'member/getMemberByEmail',
        {
          email: inputValue,
        },
      response => {
        if(response) {
          setDropDownList([response.user_info]);
        }
      });
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {

      function getIdOrParentId(event) {
        let targetElement = event.target;

        // targetElement에 id가 있으면 해당 id를 사용
        while (targetElement && !targetElement.id) {
          // id가 없으면 부모 요소로 이동
          targetElement = targetElement.parentNode;
        }

        // 최종적으로 찾은 요소의 id 반환, 요소에 id가 없다면 null 반환
        return targetElement ? targetElement.id : null;
      }

      if (getIdOrParentId(event) !== 'inputBox' && getIdOrParentId(event) !== 'dropBox' && getIdOrParentId(event) !== 'dropBox1') {
        setShowDropDown(false);
      }
    }


    // 문서 전체에 이벤트 리스너 추가
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // 컴포넌트 언마운트 시 이벤트 리스너 제거
      document.removeEventListener('mousedown', handleClickOutside);
    };


  }, []);  // 의존성 배열에 handleFocusOut 추가

  const changeInputValue = event => {
    setInputValue(event.target.value);
    setShowDropDown(false);
  }

  const addStudent = () => {

    context.post(
      'class/addClassMember',
      {
        class_id,
        member_ids: checkedItems.map(item => item.id),
      },
      response => {
        onClose?.();
      });
  }

  const handleEnterKeyPress = event => {
    if (event.key === 'Enter' && inputValue !== '') {
      onSearch();
    }
  }

  const EnableConfirm = () => {
    return (
      checkedItems.length > 0
    )
  }


  // 학생 추가 관련

  const deleteStudent = (studentID) => {
    // 삭제 버튼 클릭 시 해당 아이디를 checkedItems에서 찾아서 삭제
    setCheckedItems(prevItems => prevItems.filter(item => item.id !== studentID));
  }


  const handleCheckboxChange = (event, dropDownIndex) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setCheckedItems((prev) => [...prev, dropDownList[dropDownIndex]]);
    } else {
      setCheckedItems((prev) =>
        prev.filter((item) => item !== dropDownList[dropDownIndex])
      );
    }
  };


  const clickInputBox = event => {
    onSearch();
  }

  const handleClose = () => {
    onClose?.();
  };

  return (
    <Overlay>
      <div className='studentAddModal'>
        <div class="classAddModal-head">
          <h4>학생 추가</h4>
          <img onClick={handleClose} src="/assets/image/icon_close_gray.png" />
        </div>
        <div className='studentAddModal-content-box'>
          <div class="classAddModal-subTitle">
            학생 검색
          </div>
          <div class="studentAddModal-searchContainer" >
            <StudentSearch
              placeholder={strings.student_search_placeholder}
              inputType={'text'}
              value={inputValue}
              onChange={changeInputValue}
              onKeyUp={handleEnterKeyPress}
              onClick={clickInputBox}
              autoComplete="off" // 자동 완성 기능 비활성화
            />
            {showDropDown ? (
              <DropDownBox id='dropBox'>
                {dropDownList.filter(item => item.email === inputValue).length === 0 ? (
                  <DropDownItem>
                    <div style={{padding:'10px 15px', fontSize:'14px'}}>해당하는 학생이 없습니다</div>
                    </DropDownItem>
                ) : (
                  <>
                    {dropDownList.map((dropDownItem, dropDownIndex) => (
                      <DropDownItem
                        key={dropDownIndex}
                        // onMouseOver={() => setDropDownItemIndex(dropDownIndex)}
                        className={checkedItems.includes(dropDownItem) ? 'selected' : ''}
                      >
                        <div className="studentAddModal-dropdown-container">
                          <div className='studentAddModal-dropdown-profile'>
                            <img
                              className="user-img"
                              src={dropDownItem.profile ? context.loadImage(dropDownItem.profile) : "/assets/image/chewing_plus_logo.png"}
                              alt=''
                            />
                          </div>
                          <div className="studentAddModal-dropdown-name-mail">
                            {dropDownItem.name}
                          </div>
                          <div className="missionAddModal-dropdown-checkbox">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={checkedItems.includes(dropDownItem)}
                                onChange={(event) => handleCheckboxChange(event, dropDownIndex)}
                                disabled={member_list.includes(dropDownItem.id)}
                              />
                            </div>
                          </div>
                        </div>
                      </DropDownItem>
                    ))}
                  </>
                )}
              </DropDownBox>

            ) : null}
            <Button style={{ backgroundColor: EnableConfirm() ? '#0F84F4' : '#DFE3E8' }} disabled={!EnableConfirm()} onClick={addStudent}>추가</Button>
          </div>


          <div class="student-list-container">
          {checkedItems.length > 0 ?
              <>
              {
                
                checkedItems.map((item, idx) => {

                  return (

                    <div class="student-list">
                      <div class="img-box">
                        <img src={item.profile != null ? context.loadImage(item.profile) : "/assets/image/chewing_plus_logo.png"} alt="" />
                      </div>
                      <div class="studentAdd-name"> {item.name}</div>
                      <div class="studentAdd-cancel" onClick={() => deleteStudent(item.id)}> <img src="/assets/image/icon_close_gray.png" alt='' /></div>
                    </div>

                  )
                })
              }
            </>
            : null}
            </div>
        </div>
      </div>
    </Overlay>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top\: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;



const Button = styled.button`
  cursor: pointer;
  width: 100%;
  
  background-color: #DFE3E8;
  color: #ffffff;
  border-radius: 5px;
  border:0px;
  padding: 10px;
  flex:2;
  margin-left: 10px;
  &:hover {
    background-color: #898989;
  }
`;

const DropDownBox = styled.ul`
display: block;
position: absolute;
margin: 0 auto;
padding: 10px 5px;
width: 77%;
    top: 100%;
background-color: white;
border: 1px solid #DFE1E8;
border-radius: 8px;
box-shadow: 0 10px 10px rgb(0, 0, 0, 0.3);
list-style-type: none;
z-index: 3;
max-height: 200px; /* 최대 높이 설정 */
overflow: auto; /* 스크롤바 표시 설정 */
border-top: none;
`

const DropDownItem = styled.li`
  text-align: start;
`

export default StudentAddModal;