import React, { useContext, useRef, useState } from 'react';
import { withRouter } from "react-router-dom";
import strings from "../../lang/strings";
import StudyRoomInput from "../../components/control/custom_input/study_room_input";
import StudyRoomButton from "../../components/control/button/study_room_button";
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/';
import CoverImageItem from "../../components/control/item/cover_image_item";
import CarouselButton from "../../components/control/button/carousel_button";
import CategoryButton from "../../components/control/button/category_button";
import RoomCreateButton from "../../components/control/button/room_create_button";
import DateTime from "react-datetime";
import 'react-datetime/css/react-datetime.css';
import { ReactNotifications, Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import moment from "moment";
import { Page_settings } from "../../config/page_settings";
import ReactTags from "react-tag-autocomplete";
import { pwdCheck } from "../../helper/common";
import { useEffect } from 'react';
import { rootStore } from '../../mobx/store';

const CreateStudyRoom = (props) => {
    const context = useContext(Page_settings);
    const [title, setTitle] = useState("");
    const roomMode = parseInt(props.match.params.type);
    const [type, setType] = useState(roomMode);
    const [roomStatus, setRoomStatus] = useState(1)
    const [imageId, setImageId] = useState(0)
    const idCarousel = useRef();
    const [tagList, setTagList] = useState('');
    const [introduction, setIntroduction] = useState("");
    const [password, setPassword] = useState('');
    const [startDate, setStartDate] = useState(moment(new Date()).format('YYYY.MM.DD'));
    const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY.MM.DD'));
    const [showMax, setShowMax] = useState(true);
    const [createButtonStatus, setCreateButtonStatus] = useState(false);
    const minYesterday = DateTime.moment().subtract(1, 'day');
    const maxDate = DateTime.moment().add(1, 'month');

    const roomImageList = context.getSettingList("room_images");

    useEffect(() => {
        context.setContentBgType(2);
        return () => {
            context.setContentBgType(1);
        }
    }, []);

    useEffect(() => {
        setCreateButtonStatus(title != '' && tagList !== "" && moment(new Date(endDate)).isAfter(moment(new Date())));
    }, [title, tagList, endDate])

    const minDateRange = (current) => {
        return current.isAfter(minYesterday) && current.isBefore(maxDate);
    }

    const onCreate = () => {

        let selectedDate = moment(endDate).format("YYYY-MM-DD 23:59:59");

        if (title == "") {
            addNotification('warning', strings.warning, strings.enter_title)
            return;
        }
        if (roomStatus == 2 && password == "") {
            addNotification('warning', strings.warning, strings.enter_password)
            return;
        }

        if (!pwdCheck(password) && password != "") {
            addNotification('warning', strings.warning, strings.enter_password_correctly)
            return;
        }

        if (!moment(new Date(selectedDate)).isAfter(moment(new Date()))) {
            addNotification('warning', strings.warning, strings.select_period)
            return;
        }

        if (tagList == "") {
            addNotification('warning', strings.warning, "한 개 이상의 키워드를 입력해주세요.")
            return;
        }

        context.post(
            'room/add',
            {
                mode: roomMode,
                title: title,
                type: type,
                secret: roomStatus,
                password: password,
                image: roomImageList[imageId],
                tag: tagList,
                category: roomMode,
                open_date: moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'),
                intro: introduction
            },
            response => {
                if (roomMode == 1) {
                    props.history.push('/room/study');
                } else {
                    props.history.push('/room/study/conference');
                }

            }
        );
    }
    const handleDateChange = (date) => {
        const newDate = moment(date).format('YYYY.MM.DD');
        setEndDate(newDate);
        setShowMax(moment(newDate).diff(startDate, 'days') < 30);
    }

    const addNotification = (notificationType, notificationTitle, notificationMessage) => {
        Store.addNotification({
            title: notificationTitle,
            message: notificationMessage,
            type: notificationType,
            insert: 'top',
            container: 'top-center',
            dismiss: {
                duration: 1000,
            }
        });
    }

    const onDelete = (i) => {
        const tag_list = tagList.split('#')
        tag_list.splice(i, 1)
        let tag = tag_list.join('#');
        setTagList(tag)

    }

    const onAddition = (tag) => {
        let new_tag = tag.name.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
        let tag_list = [];
        if (tagList != "") {
            tag_list = tagList.split('#')
            if (tagList.split('#').length == 5) {
                addNotification('warning', strings.warning, strings.you_cannot_add_more);
                return
            }
        }
        tag_list = [].concat(tag_list, new_tag)
        setTagList(tag_list.join('#'));
    }

    const onClickMax = () => {
        setEndDate(moment(maxDate))
        setShowMax(false);
    }

    console.log(tagList)

    return (
        <div className="main-content" style={{ paddingBottom: '30px' }}>
            <ReactNotifications />
            <div className="f-s-25 f-w-600 m-b-10">{roomMode == 1 ? strings.create_study_room : strings.create_conference_room}</div>
            <StudyRoomInput
                value={title}
                name={strings.room_title}
                required={true}
                placeholder={strings.enter_title}
                onChange={(v) => {
                    setTitle(v);
                }}
                inputType={"text"}
            />
        
            <div className="display-flex-important align-top study-room-input f-w-600">
                <div>{strings.room_public}</div>
                <div className="required">*</div>
            </div>
            <div className="m-t-10 display-flex-important">
                <StudyRoomButton
                    selected={roomStatus == 1}
                    onClick={() => {
                        setRoomStatus(1)
                        setPassword("")
                    }}
                    value={strings.open}
                />
                <StudyRoomButton
                    selected={roomStatus == 2}
                    onClick={() => {
                        // addNotification('info', strings.in_progress)
                        setRoomStatus(2)
                    }}
                    value={strings.secret}
                />
            </div>
            {
                roomStatus == 2 &&
                <div>
                    <StudyRoomInput
                        value={password}
                        name={''}
                        required={false}
                        onChange={(v) => {
                            setPassword(v);
                        }}
                        inputType={"password"}
                        placeholder={strings.password_placeholder}
                    />
                </div>
            }

            <div className="display-flex-important align-top study-room-input f-w-600">
                <div>{strings.cover_image}</div>
            </div>
            <Carousel
                ref={idCarousel}
                draggable={true}
                swipeable={true}
                focusOnSelect={true}
                centerMode={true}
                containerClass={"custom-carousel-container m-t-10"}
                itemClass="react-multi-carousel-item custom-carousel-item"
                responsive={{
                    desktop: {
                        breakpoint: { max: 3000, min: 1024 },
                        items: 5,
                        slidesToSlide: 1 // optional, default to 1.
                    },
                    tablet: {
                        breakpoint: { max: 1024, min: 464 },
                        items: 5,
                        slidesToSlide: 1 // optional, default to 1.
                    },
                    mobile: {
                        breakpoint: { max: 464, min: 0 },
                        items: 1,
                        slidesToSlide: 1 // optional, default to 1.
                    }
                }}
                arrows={false}
                showDots={false}
            >
                {
                    roomImageList.map((item, idx) =>
                        <CoverImageItem key={idx}
                            item={context.loadImage(item)}
                            selected={imageId == idx}
                            onClick={() => {
                                setImageId(idx)
                            }}
                        />
                    )
                }
            </Carousel>
                {/* 테스트 */}
            
            <div className="d-flex">
                <div className="flex-grow-1" />
                <CarouselButton
                    more={imageId != 0}
                    direction={'left'}
                    onClick={() => {
                        if (imageId != 0) {
                            idCarousel?.current?.goToSlide(imageId - 1);
                            setImageId(imageId - 1);
                        }
                        //  else {
                        //     idCarousel?.current?.goToSlide(roomImageList.length - 2);
                        //     idCarousel?.current?.next();
                        //     setImageId(roomImageList.length - 1);
                        // }
                    }}
                />
                <CarouselButton
                    more={imageId != roomImageList.length - 1}
                    direction={'right'}
                    onClick={() => {
                        if (imageId != roomImageList.length - 1) {
                            idCarousel?.current?.goToSlide(imageId + 1);
                            setImageId(imageId + 1);
                        }
                        //  else {
                        //     idCarousel?.current?.goToSlide(0);
                        //     setImageId(0);
                        // }
                    }}
                />
            </div>
            <div className="display-flex-important align-top study-room-input f-w-600 m-b-20">
                <div>{strings.keyword}</div>
                <div className="required">*</div>
            </div>
            <ReactTags
                tags={tagList != "" ? tagList?.split('#').map((item, index) => {
                    return { id: index, name: item }
                }) : []}
                placeholderText={'키워드 최대 5가지'}
                removeButtonText={''}
                delimiters={['Enter', 'Tab', ' ']}
                allowNew={tagList?.split('#').length < 6}
                onAddition={onAddition}
                onFocus={() => {
                    if (tagList?.split('#').length == 5) {
                        addNotification('warning', strings.warning, strings.you_cannot_add_more)
                    }
                }}
                onDelete={onDelete} />
            <div className="display-flex-important study-room-input align-item-center f-w-600">
                <div>{strings.period}</div>
                <div className="required">*</div>
                <div className="sub-detail">{strings.up_to_month}</div>
            </div>
            <div className="display-flex-important m-t-10">
                <div className="datepicker-custom-container disabled">
                    {startDate}
                </div>
                <div className="display-flex-important align-item-center">~</div>
                <div className="datepicker-custom-container timepicker-container m-l-10">
                    <DateTime value={endDate} onChange={(date) => handleDateChange(date)} isValidDate={minDateRange}
                        timeFormat={false} className="create-room-datepicker" />
                </div>
                {
                    showMax && <button className="max-button" onClick={() => onClickMax()}>{strings.max}</button>
                }
            </div>

            <StudyRoomInput
                value={introduction}
                name={strings.introduction}
                required={false}
                inputType={"text"}
                placeholder={strings.introduction_placeholder}
                onChange={(v) => {
                    setIntroduction(v);
                }}
            />
            <RoomCreateButton
                value={roomMode == 1 ? strings.create_study_room : strings.create_conference_room}
                enable={createButtonStatus}
                onClick={() => {
                    onCreate()
                }}
            />

            <div className='m-t-50' />
        </div>

    );

}

export default withRouter(CreateStudyRoom);
