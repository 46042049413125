import { action, observable } from 'mobx';

export const rootStore = observable({

    me: null,
    user_info: null,
    notification: null,
    signup_info: null,
    class_id: null,

    signIn: action((user, platform) => {
        rootStore.me = {
            ...user,
            platform: platform
        };
        localStorage.setItem('admin', JSON.stringify({
            ...user,
            platform: platform
        }));
    }),

    signOut: action(() => {
        rootStore.me = null;
        localStorage.clear();
        sessionStorage.clear();
    }),

    setProfile: action((user_info) => {
        rootStore.user_info = user_info;
        localStorage.setItem("user_info", JSON.stringify(user_info));
    }),

    setClassId: action((class_id) => {
        rootStore.class_id = class_id;
        sessionStorage.setItem("class_id", JSON.stringify(class_id));
    }),

    setSignUpInfo: action((signup_info) => {
        rootStore.signup_info = signup_info;
        localStorage.setItem("signup_info", JSON.stringify(signup_info));
    }),

    get getMe() {
        if (rootStore.me == null) {
            try {
                rootStore.me = JSON.parse(localStorage.getItem('admin'));
            } catch (e) {
                console.log(e);
            }
        }
        return rootStore.me;
    },

    get getProfile() {
        if (rootStore.user_info == null) {
            try {
                rootStore.user_info = JSON.parse(localStorage.getItem('user_info'));
            } catch (e) {
                console.log(e);
            }
        }
        return rootStore.user_info;
    },

    get isSignedIn() {
        return rootStore.getMe !== null;
    },

    get token() {
        return rootStore.getMe?.token ?? '';
    },

    get getClassId() {
        if (rootStore.class_id == null) {
            try {
                rootStore.class_id = JSON.parse(sessionStorage.getItem('class_id'));
            } catch (e) {
                console.log(e);
            }
        }
        return rootStore.class_id;
    },

    get getSignUpInfo() {
        if (rootStore.signup_info == null) {
            try {
                rootStore.signup_info = JSON.parse(localStorage.getItem('signup_info'));
            } catch (e) {
                console.log(e); 
            }
        }
        return rootStore.signup_info;
    },

});
