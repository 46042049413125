
const AI_Sec2 = () => {
  return (
    <>
      <section className="wrap ai-sec2">
        <div className="inner">
          <div className="main-tit">
            <h2>
              <span>쏟아지는 질문들</span> <br />
              어떻게 해야할 지 막막하다고요?
            </h2>
          </div>
          <div className="box-layout">
            <div className="wid-100 box1">
              <div className="img-box">
                <img src="/assets/image/landing_page/sub/ai-sec2-box1.png" alt="ai 라이" />
              </div>
              <div className="txt-box">
                <h6>AI 라이</h6>
                <h3>
                  너가 뭘 모르고 있는지 <br />
                  내가 알려줄게
                </h3>
                <p>
                  테스트와 역질문을 통한 <br />
                  학생별 취약점 정밀 분석
                </p>
              </div>
            </div>
            <div className="wid-50 box2">
              <div className="txt-box">
                <h6>테스트</h6>
                <h3>
                  10문제로도 <br />
                  끝낼 수 있는 약점 분석
                </h3>
                <p>원하는 과목의 테스트 후 틀린 문항내 취약점 정밀 분석</p>
              </div>
              <div className="img-box">
                <img src="/assets/image/landing_page/sub/ai-sec2-box2.png" alt="테스트" />
              </div>
            </div>
            <div className="wid-50 box3">
              <div className="txt-box">
                <h6>커리큘럼</h6>
                <h3>
                  누군에겐 심플하지만 <br />
                  누군가는 복잡한 학습?
                </h3>
                <p>분석된 취약점을 통해 학생멸 맞춤 커리큘럼 제공</p>
              </div>
              <div className="img-box">
                <img src="/assets/image/landing_page/sub/ai-sec2-box3.png" alt="커리큘럼" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default AI_Sec2;
