import React from 'react';
import ReactLoading from 'react-loading';

const Loading_bar = React.memo(() => (
  <div style={{
    display: 'block',
    position: 'fixed',
    left: "50%",
    width: '100%',
    top: "50%",
    height: '100%',
    fontSize: '3em',
    textAlign: 'center',
    zIndex: '30000',
  }}>
    <ReactLoading type='spinningBubbles' color='#000000' className='loading'/>
  </div>
));

export default Loading_bar;
