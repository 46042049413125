import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import ClassAddModal from './Modals/classAddModal';
import styled from "styled-components";
import StudentAddModal from './Modals/studentAddModal';
import { Page_settings } from '../../config/page_settings';
import DatalabModal from './Modals/datalabModal';
import { rootStore } from '../../mobx/store';
import Dropdown from 'react-bootstrap/Dropdown';
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import ClassDeleteModal from './Modals/classDeleteModal';
import ClassEditModal from './Modals/classEditModal';

const ClassInfo = observer((props) => {

  const history = useHistory();
  const [openInviteCode, setOpenInviteCode] = useState(false);
  const [openAddStudent, setOpenAddStudent] = useState(false);
  const [openDataLab, setOpenDataLab] = useState(false);
  const [member, setMember] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(true);
  const context = useContext(Page_settings);
  const [classInfo, setClassInfo] = useState([]);
  const [dropdownStatus, setDropdownStatus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [missionList, setMissionList] = useState([]);
  const [notification, setNotification] = useState([]);
  const [studentInfo, setStudentInfo] = useState([]);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [openDeleteModal,setOpenDeleteModal] = useState(false); 
  const [openEditModal,setOpenEditModal] = useState(false); 
  const [activeIndex,setActiveIndex] = useState('');
  const [selectedClass,setSelectedClass] = useState(0);
  const [classList,setClassList] = useState([]);
  const [changeClass,setChangeClass] = useState(false);
  const user_type = rootStore.getProfile?.user_type ?? ""


  useEffect(() => {
    getClassList();
  }, [changeClass]);

  // 클래스 리스트 우측 메뉴 버튼, 활성화 상태에서 다른 곳 클릭시 비활성화 
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target.closest('.class-head-right-title') === null) {
        setActiveIndex(null);
      }
    };
  
    document.addEventListener('click', handleOutsideClick);
  
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (classInfo.length > 0) {
      loadNotification();
      loadMissionList();
      loadStudentInfo();
      
      // deleteClass();
    }
  }, [classInfo, openAddStudent, deleteStatus]);

  const getClassList = () => {
    context.get(
      'class/getClassList',
      {},
      response => {
        const initClass = response[0].id
        rootStore.setClassId(initClass)
        setClassList(response)
        loadClassInfo(initClass);
      });  
  }

  const loadClassInfo = (initClass) => {
    context.get(
      'class/getClassInfo',
      {
        class_id: initClass,
      },
      response => {
        
        setSelectedClass(initClass);
        setClassInfo([response])
      });
  }

  const loadStudentInfo = () => {
    context.get(
      'class/getClassMemberList',
      {class_id: classInfo[0].id},
      response => {
        setStudentInfo(response)
      }
    );
  }

  const loadMissionList = () => {
    context.get(
      'class/getMissionList',
      { class_id: classInfo[0].id},
      response => {
        setMissionList(response.missionList)
      }
    );
  }

  const loadNotification = () => {
    context.get(
      'class/getNotificationList',
      { class_id: classInfo[0].id},
      response => {
        if(response){
          setNotification(response.filter(item => item.reservation !== "예약"))
        }
        
      }
    );
  }

  


  const onclickDataLab = (id) => {
    setOpenDataLab(true);
    setMember(id);
  }

  const confirmChewingTalk = () => {
    setOpenInviteCode(true);
    alert("개발 진행중입니다.")
  };

  const onClickAddStudent = () => {
    setOpenAddStudent(true);
  };

  const onClickEnterQuiz = () => {
    history.push("/class/quiz/" + selectedClass);
  }

  const onClickEnterExam = () => {
    history.push("/exam/" + selectedClass);
  }

  const handleMouseEnter = (index) => {
    setIsHovered(index);
  };

  const handleMouseLeave = () => {
    setIsHovered(null);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const deleteStudentInfo = (idx) => {
    // 디비에 접근해서 클릭한 학생정보 데이터 삭제하기
    context.get(
      'class/classMemberDelete',
      {
        class_id : classInfo[0].id,
        member_id: idx,
      },
      response => {
        setDeleteStatus(!deleteStatus);
      });
  }
  const clickDropDown = () => {
    setDropdownStatus(!dropdownStatus)
  }

  const onClickAddClass = () => {
    setIsOpen(true);
  }

  const selectSchool = (id) => {
    context.get(
      'class/getClassInfo',
      {
        class_id: id,
      },
      response => {
        setSelectedClass(id);
        setClassInfo([response])
      });
  }

  const clickClass = (id) => {
    context.get(
      'class/clickClass',
      {
        class_id: id,
      },
      response => {
        setChangeClass(!changeClass)
      });
  }

  const moveAttendance = () => {
    props.history.push(`/class/attendance/${classInfo[0].id}`)
  }

  //useEffect 사용해서 id가 classId인 값인 데이터를 가져와서 화면에 랜더링해줘야댐 

  return (
    <div className="class-info-container">
      
      <div class="class-head">
        <div class="class-head-left">

          {isOpen && (<ClassAddModal
            open={isOpen}
            onClose={() => {
              setIsOpen(false);
            }}
          />)}
          {classInfo.length > 0 ?
            <div class="class-info-dropdown" onClick={() => clickDropDown()}>
              <Dropdown >
                <Dropdown.Toggle variant="success" id="dropdown-basic" >
                  <div class="class-info-img-box"><img src={classInfo[0].thumbnail ? context.loadImage(classInfo[0].thumbnail) : "/assets/image/chewing_plus_logo.png"}
                    alt="" /></div>
                  <div class="class-head-left-child">
                    <div class="class-head-left-title">{classInfo[0].class_name} {classInfo[0].grade} {classInfo[0].classroom}반
                      {dropdownStatus ?  <img src={"/assets/image/icon_arrow_up_black.png"} alt="" /> : <img src={"/assets/image/icon_arrow_down_black1.png"} alt="" />}
                    </div>
                    <div class="class-head-left-subtitle">{classInfo[0].creator_name} 선생님</div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {classList.map((item, index) => {
                    return (
                      <Dropdown.Item >
                        <div class="class-info-dropdown-content" onClick={()=> {selectSchool(item.id); rootStore.setClassId(item.id); clickClass(item.id);}}>

                          <div class="class-info-img-box" ><img src={item.thumbnail ? context.loadImage(item.thumbnail) : "/assets/image/chewing_plus_logo.png"}
                            alt="" /></div>
                          <div class="class-head-left-title col-md-8">{item.class_name} {item.grade} {item.classroom}반 </div>
                          {item.is_creator ? 
                             <div class="class-head-right-title col-md-2 class-content-box" onClick={(event) => {
                              event.stopPropagation();
                              setActiveIndex(prevIndex => prevIndex === index ? null : index);
                              }}>
                              <img src={"assets/image/icon_3_dotted.png"} alt="" 
                              />
                              <div className={`edit-del-box ${activeIndex === index ? '' : 'hidden'}`}>
                                <button onClick={() => setOpenEditModal(true)}>수정하기</button>
                                <button onClick={() => setOpenDeleteModal(true)} className='del-btn'>삭제하기</button>
                              </div>
                            </div>
                          : <div class="class-head-right-title col-md-2 class-content-box" onClick={(event) => {
                            event.stopPropagation();
                            setActiveIndex(prevIndex => prevIndex === index ? null : index);
                            }}>
                            </div>}
                         
                        </div>
                      </Dropdown.Item>
                    )
                  })}
                  {user_type === "STUDENT" ? null: 
                  <Button onClick={onClickAddClass}>+ 클래스 추가</Button>}
                  
                </Dropdown.Menu>
              </Dropdown>
            </div>
            :
            <div class="class-info-dropdown" onClick={() => clickDropDown()}>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic" >
                  <div class="class-info-img-box"><img src={classList[0]?.thumbnail ? context.loadImage(classList[0].thumbnail) : "/assets/image/chewing_plus_logo.png"}
                    alt="" /></div>
                  <div class="class-head-left-child">
                    <div class="class-head-left-title">{classList[0]?.class_name} {classList[0]?.grade} {classList[0]?.classroom}반
                      {!dropdownStatus ? <img src={"/assets/image/icon_arrow_down_black1.png"} alt="" /> : <img src={"/assets/image/icon_arrow_up_black.png"} alt="" />}
                    </div>
                    <div class="class-head-left-subtitle">{classList[0]?.creator_name} 선생님</div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {classList.map((item, index) => {
                    return (
                      <Dropdown.Item onClick={() => selectSchool(item.id)}>
                        <div class="class-info-dropdown-content">

                          <div class="class-info-img-box"><img src={item.thumbnail ? context.loadImage(item.thumbnail) : "/assets/image/chewing_plus_logo.png"}
                            alt="" /></div>
                          <div class="class-head-left-title col-md-8">{item.class_name} {item.grade} {item.classroom}반 </div>

                          <div class="class-head-right-title col-md-2">
                            <img src={"assets/image/icon_3_dotted.png"} alt="" />
                          </div>
                        </div>
                      </Dropdown.Item>
                    )
                  })}
                  <Button onClick={onClickAddClass}>+ 클래스 추가</Button>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            // null
          }
        </div>
        <div class="class-head-buttonContainer">
          {/* {classInfo.length > 0  && user_type === "STUDENT" ? 
          <div class="class-head-teacherTalk" onClick={confirmChewingTalk}> 
            <div class="class-info-img-box"><img src= "/assets/image/chewing_plus_logo.png" alt="" /></div> {classInfo[0].creator_name} 선생님<button class="confirm"> 톡 확인하기 </button> 
          </div>
          : null } */}
          
          <div class="class-head-test-container position-relative " onClick={onClickEnterQuiz}>퀴즈 
            <div
                style={{
                  border: "none",
                  background: "#04C886",
                  color: "#fff",
                  padding: "3px 5px",
                  borderRadius: "4px",
                  position: "absolute",
                  top: -9,
                  right: -8,
                  fontSize: 10,
                  lineHeight: '12px',
                  fontWeight: 600,
                }}
                
              >
                BETA
              </div>
          </div>
          <div class="class-head-test-container position-relative " onClick={onClickEnterExam}>시험장 
            <button class="test">입장하기</button>    
          </div>
        </div>
      </div>

      {/* 미션 리스트 / 공지사항 */}
      <div class="class-info-middle-content">
        {/* 미션 리스트 */}
        <div class="class-info-mission-container">
          
          <div class="class-info-mission-head">
            <div class="left">과제 리스트</div>
            <div className="right" onClick={() => props.history.push(`/class/mission_list/${classInfo[0].id}`)}>
              더보기 &gt;</div>
          </div>
          <div class="class-info-mission-content-box"  onClick={() => props.history.push(`/class/mission_list/${classInfo[0].id}`)}>
            {missionList !== null && missionList.length > 0 ? 
                <>
            <div class="class-info-mission-content-box-top">
              <div class="left">{missionList[0].title}</div>
              <div className={`right ${missionList[0].deadline === '마감' ? "end" : ""}`}>
                      {missionList[0].deadline}
                      </div>
            </div>
            <div class="class-info-mission-content-box-middle">
              {missionList[0].content?.split('\n').map(item => (<p>{item}</p>))}
            </div>
            <div class="class-info-mission-content-box-bottom">
            {user_type === "STUDENT" ? null :
            <>
            <div class="left">{missionList[0].resolved_students}&nbsp;/</div> 
            <div class="right">&nbsp;{missionList[0].total_students}명</div>
            </>
          }
          </div>
            
            </>
        : 
            <div class="class-info-mission-null">등록된 과제 리스트가 없습니다</div>
        }
          </div>
        </div>
        {/* 공지사항 */}
        <div class="class-info-mission-container">
          <div class="class-info-mission-head">
            <div class="left">공지사항</div>
            <div class="right" onClick={() => props.history.push({
              pathname : `/class/notification/${classInfo[0].id}`,
              state : { class_id : classInfo[0].id,
                        creator : classInfo[0].creator
              }})}>더보기 &gt;</div>
          </div>
          <div class="class-info-mission-content-box"  onClick={() => props.history.push({
              pathname : `/class/notification/${classInfo[0].id}`,
              state : { class_id : classInfo[0].id,
                        creator : classInfo[0].creator
              }})}>
            { notification !== null && notification.length > 0 ? 
             <>
            <div class="class-info-mission-content-box-top">
              <div class="left">{notification[0].title}</div>
              <div class="right"><img src="/assets/image/icon_notice_link.png" alt="" /></div>

            </div>
            <div class="class-info-mission-content-box-middle">
              {notification[0].content}

            </div>
            {/* <div class="class-info-mission-content-box-bottom">빈값</div> */}
           </>
            : 
            <div class="class-info-mission-null">등록된 공지사항이 없습니다</div>
        }
          </div>
        </div>
      </div>

      {/* // 학생정보 */}
      <div class="class-head">
        <div class="class-studentInfo">
          학생 정보
        </div>
        { user_type !== "STUDENT" ? 
          <div class="class-head-buttonContainer">
          <button class="class-head-addStudent" onClick={moveAttendance}>출결</button>
        </div>
        : null}
        
      </div>

      {openAddStudent && (<StudentAddModal
        open={openAddStudent}
        onClose={() => {
          setOpenAddStudent(false);
        }}
        class_name={classInfo[0].class_name}
        class_id={classInfo[0].id}
        member_list={(studentInfo !== null && studentInfo.length > 0) ? [...studentInfo.map(item => item.member), classInfo[0].creator] : [classInfo[0].creator]}
      />)}

      {openDataLab && (<DatalabModal
        open={openDataLab}
        onClose={() => {
          setOpenDataLab(false);
        }}
        member={member}
        class_id={classInfo[0].id}
      />)}

      {openDeleteModal && (<ClassDeleteModal
        open={openDeleteModal}
        onClose={() => {
          setOpenDeleteModal(false);
        }}
        selectedClass={selectedClass}
      />)}

      {openEditModal && (<ClassEditModal
        open={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
        }}
        selectedClass={selectedClass}
      />)}

      
        <div class="class-list-container">
        { user_type !== "STUDENT" ? 
              <div class={`classInfo-list-content`} style={{padding: '0px'}} onClick={onClickAddStudent}>
                <div className='add-student-box' >
                  <img src='/assets/image/icon_add_plus_white.png' alt=''/>
                 </div>
              </div>
        :null}
          { studentInfo?.map((item, idx) => {
            const dystropy = item.mission_problem || item.data_problem ? 1 : null;
            return (
              <div
                key={idx}
                onMouseEnter={() => handleMouseEnter(idx)}
                onMouseLeave={handleMouseLeave}
                onClick={() => user_type !== "STUDENT" && onclickDataLab(item.member) }
                class={`classInfo-list-content ${user_type !== "STUDENT" && dystropy ? 'classInfo-list-warning-content' : ''}`}>
                <div class="classInfo-list-content-head">
                  <div>
                    <img className="user-img"
                      src={item.profile != null && item.profile != "" ? context.loadImage(item.profile) : "/assets/image/chewing_plus_logo.png"} alt="" />
                  </div>
                  <div class="classInfo-list-studentInfo">
                    <div class="classInfo-list-name">{item.name}</div>
                    <div class="classInfo-list-school">{item.school} · {item.classInfo}</div>
                  </div>
                </div>
                {isHovered === idx && user_type !== "STUDENT" ?
                  <div className="classInfo-dropdown-container" onClick={(e) => e.stopPropagation()} >
                    <img
                      src="/assets/image/icon_3dots.png"
                    />
                    
                    {isDropdownVisible && (
                      <div className="classInfo-dropdown-menu">
                        <ul>
                          <li onClick={() => deleteStudentInfo(item.member)}>삭제</li>
                      
                        </ul>
                      </div>
                    )}
                  </div>
                  : null}
                {user_type !== "STUDENT" ? 
                  dystropy ? (
                    <div class="classInfo-dystropy">
                    <div class="classInfo-dystropy-flex">
                      미션 이상행동 <span class="classInfo-warningFont">{item.mission_problem}건</span>

                      <img src={"/assets/image/icon_classinfo_warning_point.png"} />

                    </div>
                    <div class="classInfo-dystropy-flex">
                      데이터 이상행동 <span class="classInfo-warningFont">{item.data_problem}건</span>
                      <img src={"/assets/image/icon_classinfo_warning_point.png"} />
                    </div>
                  </div>
                  ) : (
                    <div class="classInfo-noworryFont">
                    이상 없음
                  </div>
                  )
                 : null
                }

              </div>
            )
          })}
        </div>
    </div>
  );
})

const Button = styled.button`
  font-size: 14px;
  padding: 10px 20px;
  border: 1px solid #D2D9E2;
  background-color: #FFFFFF;
  border-radius: 10px;
  color: black;
  font-weight: 600;
  cursor: pointer;
  width: 90%;
  margin: 10px 0px 10px 0px;
`;


export default withRouter(ClassInfo);
