import React, { useContext } from 'react';
import { Modal, ModalBody } from "reactstrap";
import './modal.scss'
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Page_settings } from "../../../config/page_settings";

const DocViewModal = (props) => {
    const context = useContext(Page_settings)
    const { show, toggle, url } = props;

    return (
        <Modal isOpen={show} toggle={() => toggle()} className="doc-view-modal">
            <ModalBody>
                <div className="d-flex align-items-center">
                    <div className="f-s-20 f-w-600 flex-grow-1"></div>
                    <button className='modal-close-btn' onClick={() => toggle()}>
                        <img src={"/assets/image/icon_close_black.png"} alt="" className="modal-close" />
                    </button>
                </div>
                <DocViewer className="doc-view-main" documents={[{ uri: context.loadImage(url), fileType: url?.split(',')[url?.split('.').length - 1] }]} pluginRenderers={DocViewerRenderers}
                    config={{
                        header: {
                            disableHeader: true,
                            disableFileName: false,
                            retainURLParams: false
                        }
                    }} />
            </ModalBody>
        </Modal>
    );
}

export default DocViewModal;
