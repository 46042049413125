import React from "react";

export default function ConferenceCarouselButton(props) {
    return (
        <button
            className={(!props.more ? "conference-carousel-button-black " : "conference-carousel-button-grey ") + (props.direction =="left" ? "carousel-left":"carousel-right")}
            onClick={props.onClick}>
            <img src={!props.more? "/assets/image/icon_carousel_"+props.direction+"_gray.png":"/assets/image/icon_carousel_"+props.direction+"_white.png"} alt=""/>
        </button>
    );
}
