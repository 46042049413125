import {inject} from 'mobx-react';
import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';

import {Page_settings} from '../../config/page_settings';
import strings from '../../lang/strings';
import {extractNumber, getMinuteFromSecond} from "../../helper/common";
import styled from "styled-components";
import { Row } from 'reactstrap';


const Find = (props) => {
    const context: any = useContext(Page_settings);
    const [type, setType] = useState(parseInt(props.match.params.type));
    const [idAuth, setIdAuth] = useState(0);
    const [passwordAuth, setPasswordAuth] = useState(0);
    const [resultId, setResultId] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [phone, setPhone] = useState("");
    const [phonePwd, setPhonePwd] = useState("");
    const [code, setCode] = useState("");
    const [codePwd, setCodePwd] = useState("");
    const [phoneSentPwd, setPhoneSentPwd] = useState(false);
    const [phoneSent, setPhoneSent] = useState(false);
    const [codeCheck, setCodeCheck] = useState(false);
    const [codeCheckPwd, setCodeCheckPwd] = useState(false);
    const [countDown, setCountDown] = useState(180);
    const [countDownPwd, setCountDownPwd] = useState(180);
    const [intervalNow, setIntervalNow] = useState<NodeJS.Timeout | null>(null);


    useEffect(() => {
        context.handleSetPageHeader(false);
        context.handleSetPageSidebar(false);

        return () => {
            context.handleSetPageHeader(true);
            context.handleSetPageSidebar(true);
        }
    }, []);

    useEffect(() => {
        if (countDown === 0) {
            setCountDown(180);
            if (intervalNow) {
                clearInterval(intervalNow);
                setIntervalNow(null);
            }
        }
    }, [countDown, intervalNow]);

    useEffect(() => {
        if (countDownPwd === 0) {
            if (intervalNow) {
                clearInterval(intervalNow);
                setIntervalNow(null);
            }
        }
    }, [countDownPwd, intervalNow]);

    const onFindId = () => {
        if (phone == "" || code == "") return
        if (idAuth == 0) {
            context.post(
                "auth/findEmail",
                {
                    phone: phone,
                    auth_code: code
                },
                response => {
                    setResultId(response.email);
                    setIdAuth(1)
                }
            );
        } else {
            props.history.push("/login")
        }

    }

    const onFindPwd = () => {
        if (phonePwd == "" || codePwd == "" || email == "") return
        if (passwordAuth == 0) {
            context.post(
                "auth/findEmail",
                {
                    phone: phonePwd,
                    auth_code: codePwd
                },
                response => {
                    if (response.email === email) {
                        setPasswordAuth(1)
                    } else {
                        context.showAlert("이메일이 일치하지 않습니다.")
                    }
                }
            );
        } else {
            props.history.push("/login")
        }
    }

    const onSubmitPwd = () => {
        if (password == "" || passwordConfirm == "") {
            context.showAlert("비밀번호를 입력해주세요.")
            return;
        }
        if (password !== passwordConfirm) {
            context.showAlert("비밀번호가 일치하지 않습니다.")
            return;
        }

        context.post(
            "auth/change/password",
            {
                email: email,
                new_password: password,
                auth_code: codePwd,
                phone: phonePwd
            },
            response => {
                context.showAlert("비밀번호가 변경되었습니다.")
                props.history.push("/login")
            }
        );

    }

    const onSend = () => {
        if (phone == "") {
            return
        }
        context.post(
            "auth/requestAuthCode",
            {
                phone: phone,
            },
            response => {
                setPhoneSent(true);
                setCodeCheck(false)
                setCountDown(180);
                setIntervalNow(setInterval(() => {
                    setCountDown(prevTime => prevTime - 1);
                }, 1000))
            }
        );

    }
    const onSendPwd = () => {
        if (phonePwd == "") {
            return
        }
        context.post(
            "auth/requestAuthCode",
            {
                phone: phonePwd,
            },
            response => {
                setPhoneSentPwd(true);
                setCodeCheckPwd(false)
                setCountDownPwd(5);
                setIntervalNow(setInterval(() => {
                    setCountDownPwd(prevTime => prevTime - 1);
                }, 1000))
            }
        );

    }

    return (
        <>
            <div id="header" className="header navbar-default login-header">
                <div className="navbar-header">
                    <img className="logo" src={'/assets/image/logo_chewing_plus.png'} alt="" />
                </div>
            </div>
            <div className="login-container">
                <div className="login-title">{strings.find_account}</div>
                <div className="login-content">
                    <div className="d-flex" style={{width: "100%"}}>
                        <button
                            className={("find-id ") + (type == 0 ? "find-id-active" : "")}
                            onClick={() => setType(0)}
                        >{strings.find_id}</button>
                        <button
                            className={("find-id find-password ") + (type == 1 ? "find-id-active" : "")}
                            onClick={() => setType(1)}
                        >{"비밀번호 변경"}</button>
                    </div>
                    <div className="id-info" style={{width: "100%"}}>
                        {
                            type == 0 && idAuth == 0 &&
                            strings.you_can_check_id
                        }
                        {
                            type == 0 && idAuth == 1 &&
                            <div>
                                {strings.this_is_your_id}
                                <div className="result-id">{resultId}</div>
                            </div>
                        }
                        {
                            type == 1 && passwordAuth == 0 &&
                            <div>
                                {strings.enter_registered_id}
                            </div>
                        }
                        {
                            type == 1 && passwordAuth == 1 &&
                            <div>
                                <RowWholeWrapper>
                                    <span style={{marginBottom: 5}}>{"새 비밀번호"}</span>
                                    <PasswordInput type={"password"} hasError={password.match("^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,20}$") == null} value={password} onChange={e => setPassword(e.target.value)}/>
                                    {password.match("^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,20}$") == null && <span style={{color: "#FC4359"}}>{"비밀번호는 영문, 숫자, 특수문자를 포함한 8자 이상이어야 합니다."}</span>}
                                </RowWholeWrapper>
                                <RowWholeWrapper>
                                    <span style={{marginBottom: 5}}>{"새 비밀번호 확인"}</span>
                                    <PasswordInput type={"password"} hasError={passwordConfirm !== password} value={passwordConfirm} onChange={e => setPasswordConfirm(e.target.value)}/>
                                    {passwordConfirm !== password && <span style={{color: "#FC4359"}}>{"비밀번호가 일치하지 않습니다."}</span>}
                                </RowWholeWrapper>
                            </div>
                        }
                    </div>
                    {
                        type == 1 && passwordAuth == 0 &&
                        <div style={{width: "100%"}}>
                            <input
                                className="login-input m-b-10"
                                type="text"
                                autoComplete={'off'}
                                placeholder={strings.enter_your_email}
                                value={email}
                                onChange={e => {
                                    setEmail(e.target.value);
                                }}/>
                            <div className="position-relative">
                                <input
                                    className="login-input m-b-10"
                                    type="text"
                                    autoComplete={'off'}
                                    placeholder={strings.enter_phone}
                                    value={phonePwd}
                                    onChange={e => {
                                        setPhonePwd(extractNumber(e.target.value));
                                    }}/>
                                <button className={("phone-send ") + (phonePwd != "" ? "send-active" : "")}
                                        onClick={() => onSendPwd()}>{phoneSentPwd ? strings.re_send : strings.send}</button>
                            </div>
                            <div className="position-relative">
                                <input type="text"
                                       className="login-input m-b-25"
                                       placeholder={strings.enter_code}
                                       value={codePwd}
                                       onChange={e => {
                                           setCodePwd(extractNumber(e.target.value));
                                       }}
                                       disabled={codeCheckPwd || !phoneSentPwd}/>
                                {
                                    phoneSentPwd && !codeCheckPwd &&
                                    <div className="d-flex align-item-center">
                                        <div className="count-down"
                                             style={{right: 20}}>{getMinuteFromSecond(countDownPwd)}</div>
                                    </div>

                                }
                            </div>
                        </div>

                    }
                    {
                        type == 0 && idAuth == 0 &&
                        <div style={{width: "100%"}}>
                            <div className="position-relative">
                                <input
                                    className="login-input m-b-10"
                                    type="text"
                                    autoComplete={'off'}
                                    placeholder={strings.enter_phone}
                                    value={phone}
                                    onChange={e => {
                                        setPhone(extractNumber(e.target.value));
                                    }}/>
                                <button className={("phone-send ") + (phone != "" ? "send-active" : "")}
                                        onClick={() => onSend()}>{phoneSent ? strings.re_send : strings.send}</button>
                            </div>
                            <div className="position-relative">
                                <input type="text"
                                       className="login-input m-b-25"
                                       placeholder={strings.enter_code}
                                       value={code}
                                       onChange={e => {
                                           setCode(extractNumber(e.target.value));
                                       }}
                                       disabled={codeCheck || !phoneSent}/>
                                {
                                    phoneSent && !codeCheck &&
                                    <div className="d-flex align-item-center">
                                        <div className="count-down"
                                             style={{right: 20}}>{getMinuteFromSecond(countDown)}</div>
                                    </div>

                                }
                            </div>
                        </div>


                    }
                    {
                        type == 0 &&
                        <button className="auth-phone"
                                disabled={phone == "" || code == "" || countDown === 0}
                                onClick={() => onFindId()}>{idAuth == 0 ? strings.authenticate_phone : strings.go_login}</button>
                    }
                    {
                        type == 1 &&
                        <button className="auth-phone"
                                onClick={() => passwordAuth == 0 ? onFindPwd() : onSubmitPwd()}
                                disabled={
                                    countDownPwd === 0 ||
                                    (passwordAuth == 1 && (password.match("^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,20}$") == null || passwordConfirm !== password))}
                                >{passwordAuth == 0 ? strings.authenticate_phone : "비밀번호 변경하기"}</button>
                    }
                </div>

            </div>
        </>
    );
}

export default withRouter(inject('rootStore')(Find));

const RowWholeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    margin: 15px 0px;
`;

interface IPassWordInputProps {
    hasError: boolean;
}

const PasswordInput = styled.input<IPassWordInputProps>`
    height: 55px;
    width: 100%;
    border: ${(props) => props.hasError ? "#FC4359" : "#c3c6cd"} 1px solid;
    border-radius: 10px;
    padding-left: 20px;
    color: black;
    font-size: 15px;
    outline-color: #0f84f4;
`;