import strings from "../../lang/strings";
import React, {useContext, useState} from "react";
import {withRouter} from "react-router-dom";
import {inject} from "mobx-react";
import {getMinuteFromSecond} from "../../helper/common";
import {Page_settings} from "../../config/page_settings";
import moment from "moment";

const SignupStep2 = (props) => {
    const context = useContext(Page_settings);
    const [invalidName, setInvalidName] = useState(false);
    const [invalidDate, setInvalidDate] = useState(false);

    const {
        name,
        onChangeName,
        dateBirth,
        onChangeDateBirth,
        phone,
        onChangePhone,
        code,
        onChangeCode,
        onSend,
        phoneSent,
        countDown,
        onConfirm,
        codeCheck,
        invalidPhone,
        onDone
    } = props;

    const birthday_valid_check = () => {
        let yy = parseInt(dateBirth.slice(0, 2));
        let birth = ""
        if (yy < 40) {
            birth = moment("20" + dateBirth);
        } else {
            birth = moment("19" + dateBirth);
        }
        let valid_date = birth.isValid();
        return valid_date
    }

    const onNext = () => {
        if (name == "" || dateBirth == "" || phone == "" || code == "" || !codeCheck) {
            return
        }

        if (!birthday_valid_check()) {
            setInvalidDate(!birthday_valid_check())
            return;
        }
        context.post(
            'auth/check',
            {
                phone
            },
            response => {
                props.history.push('/signup/3');
                onDone();
            }
        );
    }
    return (
        <div>
            <div className="login-item">{strings.name}</div>
            <input
                className="login-input m-b-40"
                type="text"
                autoComplete={'off'}
                placeholder={strings.enter_your_name}
                value={name}
                onChange={e => {
                    onChangeName(e.target.value);
                }}/>
            <div className="login-item">{strings.date_birth}</div>
            <div className="position-relative">
                <input type="text"
                       className={("login-input ") + (invalidDate ? "signup-error" : "m-b-40")}
                       placeholder={strings.enter_birth}
                       maxLength={6}
                       value={dateBirth}
                       onChange={e => {
                           onChangeDateBirth(e.target.value);
                           setInvalidDate(false)
                       }}/>
            </div>
            {
                invalidDate &&
                <div className="sign-error-text m-b-15">{strings.invalid_date}</div>
            }
            <div className="login-item">{strings.phone}</div>
            <div className="position-relative">
                <input type="text"
                       className={("login-input ") + (invalidPhone ? "signup-error" : "m-b-15")}
                       placeholder={strings.enter_phone}
                       value={phone}
                       maxLength={11}
                       onChange={e => {
                           onChangePhone(e.target.value);
                       }}/>
                <button className={("phone-send ") + (phone != "" ? "send-active" : "")}
                        onClick={() => onSend()}>{phoneSent ? strings.re_send : strings.send}</button>
            </div>
            {
                invalidPhone &&
                <div className="sign-error-text m-b-15">{strings.phone_duplicate}</div>
            }
            <div className="position-relative">
                <input type="text"
                       className="login-input m-b-40"
                       placeholder={strings.enter_code}
                       value={code}
                       onChange={e => {
                           onChangeCode(e.target.value);
                       }}
                       disabled={codeCheck || !phoneSent}/>
                {
                    phoneSent && !codeCheck &&
                        <div className="d-flex align-item-center">
                            <div className="count-down">{getMinuteFromSecond(countDown)}</div>
                            <button className={("phone-send ") + (code != "" ? "send-active" : "")}
                                    onClick={() => onConfirm()}>{strings.confirm}</button>
                        </div>

                }
                {
                    phoneSent && codeCheck &&
                        <img className="checked" src={"/assets/image/icon_tick.png"} alt=""/>
                }
            </div>
            <button onClick={() => onNext()}
                    className={"login-button m-b-40 " + ((name != "" && dateBirth != "" && phone != "" && code != "" && codeCheck) ? "login-button-enable" : "")}>{strings.next}</button>
        </div>
    );
}

export default withRouter(inject('rootStore')(SignupStep2));
