import React from "react";

export default function MissionInfoButton(props) {
    return (
        <button
            className={props.user_type === "STUDENT" ? "student" : ""}
            onClick={props.onClick}
            disabled={props.more}
            >
            <img src={props.more? "/assets/image/icon_carousel_"+props.direction+"_gray.png":"/assets/image/icon_carousel_"+props.direction+"_black.png"} alt=""/>
        </button>
        // <div className={`${props.user_type === "STUDENT" ? "student" : ""}`}>
        //     <img src={props.more? "/assets/image/icon_carousel_"+props.direction+"_white.png":"/assets/image/icon_mission_arrow_"+props.direction+"_black.png"} alt=""/>
        // </div>
    );
}
