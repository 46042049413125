import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import CustomSearch from '../../../components/control/custom_input/custom_search';
import strings from '../../../lang/strings';
import CloudContainer from '../../cabinet/cloud_container';
import { Page_settings } from '../../../config/page_settings';
import { rootStore } from '../../../mobx/store';
import NotificationAddModal from '../Modals/notificationAddModal';


const Notification = (props) => {

  const [dropdownStatus, setDropdownStatus] = useState(false);
  const [openAddNotification, setOpenAddNotification] = useState(false);
  const class_id = parseInt(props.match.params.class_id);
  const [notificationList, setNotificationList] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [searchStatus, setSearchStatus] = useState(false);
  const context = useContext(Page_settings);
  const user_type = rootStore.getProfile?.user_type ?? ""

  useEffect(()=>{
    loadNotificationList()
  },[openAddNotification])

  const loadNotificationList = () => {
    if(!isNaN(class_id)){
    context.get(
      'class/getNotificationList',
      { class_id,
        keyword
      },
      response => {
        
        setNotificationList(response)
      }
    );
    }
  }

  const clickDropDown = () => {
    setDropdownStatus(!dropdownStatus)
  }

  const onClickAddNotification = () => {
    setOpenAddNotification(true);
  };

  const onClickNotification = (notificationId) => {
    props.history.push(`/class/notification_info/${class_id}/` + notificationId);
}

const handleBack = () => {
  props.history.push(`/class/notification`);
  setTimeout(()=>props.history.push(`/class/notification/${class_id}`),0.1);
};


  return (

    
      <div class="main-content">
        {/* <CloudContainer/> */}
        {
        <CustomSearch
          placeholder={"공지사항 검색"}
          inputType={'text'}
          value={keyword}
          onChange={(v) => {
            setKeyword(v)
          }}
          onSearch={() => {
            if (keyword == "")setSearchStatus(false)
              loadNotificationList(class_id,keyword)
              setSearchStatus(true);
            
          }}
        />

      }
      {
        searchStatus &&
        <button className="common-button cafe-search" onClick={handleBack}>
          <img src={'/assets/image/btn_back.png'} alt="" />
        </button>
      }



      {
        searchStatus &&
        <button className="common-button btn-reset " onClick={() => setKeyword('')}>
          <img src={'/assets/image/btn_reset.png'} alt="" />
        </button>
      }
      {/* <AppWrap> */}
        <div class="class-head">
          <div class="class-myClass">
            공지 사항
          </div>
          {user_type === "STUDENT" ? null : 
          <Button onClick={() => onClickAddNotification()}>+ 공지 추가</Button>}
          
        </div>


        {openAddNotification && (<NotificationAddModal
        open={openAddNotification}
        onClose={() => {
          setOpenAddNotification(false);
        }}
        class_id={class_id}
      />)}

      


      
        {/* 미션 리스트 */}
           
        {notificationList !== null && notificationList.length > 0 ? 
        <div class="mission-content">
          {notificationList.map((item, idx) => {
            return (
              
              <div class="class-info-mission-container" onClick={()=>onClickNotification(item.id)}>
                <div class="class-info-mission-content-box">
                  <div class="class-info-mission-content-box-top">
                    <div class="left">{item.title}</div>
                    <div className={`right ${item.formatted_date.includes('예약') ? '' : 'no-resolve'}`}>{item.formatted_date}</div>
                  </div>
                  <div class="class-info-mission-content-box-middle">
                    {item.content?.split('\n').map(item => (<p>{item}</p>))}

                  </div>
                </div>
              </div>
        
          )
          
          })
          }
          </div>

          : 
          
          <div class="class-nothing-student">
          <div className='nothing-container'>
            <img class="class-nothing-img" src="/assets/image/img_gray_chuchu.png" alt="" />
            <div>아직 공지사항이 없습니다.<br />지금 바로 공지사항을 등록해보세요!</div>
          </div>
        </div>
          

          }
      
        
      {/* </AppWrap> */}
    </div>
    

  );
}

const Button = styled.button`
    font-size: 14px;
    padding: 8px 15px;
    border: none;
    background-color: rgb(15, 132, 244);
    border-radius: 10px;
    color: white;
    font-weight: 500;
    cursor: pointer;
`;

const AppWrap = styled.div`
  margin: 10px auto;
  position: relative;
  width: 1440px;
`;

export default withRouter(Notification);
