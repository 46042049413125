import strings from "../../lang/strings";
import React, {useState} from "react";
import {withRouter} from "react-router-dom";
import {inject} from "mobx-react";
import SignUpSchoolModal from "../../components/control/modal/signUp_school_modal";

const SignupStep3 = (props) => {

    const {
        school,
        setSchool,
        hopeSchool,
        onChangeHope,
        onChangeCareer,
        career,
        onDone
    } = props;

    const onNext = () => {
        if (school.name) {
            onDone()
        }
    }

    

    const [schoolSearch, setSchoolSearch] = useState(false);
    return (
        <div className="step3" style={{width: "100%"}}>
            <div className="d-flex">
                <div className="login-item">{strings.school}</div>
                <div className="login-item-optional">{strings.include_graduation}</div>
            </div>
            <div className="position-relative">
                <input type={"text"}
                       className="login-input m-b-40"
                       placeholder={strings.search_school}
                       value={school?.name}
                       disabled={true}/>
                <button className="sign-search" onClick={()=> setSchoolSearch(true)}>
                    <img src={"/assets/image/icon_search_black.png"} alt=""/>
                </button>
            </div>
            <button onClick={() => onNext()}
                    className={("login-button m-b-40 m-t-40 ") + ((school != "") ? "login-button-enable" : "")}>{strings.done}</button>
            <SignUpSchoolModal show={schoolSearch} onSearchDone={(schoolName) => setSchool(schoolName)}
                                toggle={() => {setSchoolSearch(!schoolSearch)}}/>
        
        </div>
    );
}

export default withRouter(inject('rootStore')(SignupStep3));
