import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Modal, ModalBody } from 'reactstrap';
import IconX from './icons/x';
import colors from '../design/colors';
import fonts from '../design/typography';

const PDFViewer = (props: {file: string, name: string, toggle: () => void}) => {
    const [numPages, setNumPages] = useState(null);

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
    }
    
    return (
        <Modal isOpen={true} toggle={props.toggle} size="xl">
            <ModalBody style={{padding: "24px", display: "flex", flexDirection: "column", alignItems: "center"}}>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
                    <span style={{...fonts.body3SemiBold, color: colors.gray900}}>{props.name}</span>
                    <div onClick={props.toggle}>
                        <IconX width="20px" height="20px" color={colors.gray600}/>
                    </div>
                </div>
                <div style={{width: "100%", height: "1px", backgroundColor: colors.gray50, margin: "24px 0"}}/>
                <div style={{
                    display: "flex", 
                    justifyContent: "center", 
                    alignItems: "center", 
                    width: "772px",
                    height: "490px",
                    overflow: "auto"
                }}>
                    <Document 
                        file={props.file} 
                        onLoadSuccess={onDocumentLoadSuccess}
                        options={{
                            maxImageSize: 1024 * 5000,
                            cMapUrl: 'cmaps/',
                            cMapPacked: true,
                        }}
                    >
                        {Array.from({ length: numPages }, (_, i) => (
                            <div key={i} style={{ marginBottom: "10px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                <Page 
                                    pageNumber={i + 1} 
                                    width={750}
                                    renderTextLayer={false}
                                />
                            </div>
                        ))}
                    </Document>
                </div>
            </ModalBody>
        </Modal>
    )
}   

export default PDFViewer;
