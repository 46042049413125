import React, { useState } from "react";

export default function CustomSearch(props) {

    const [marginLeft, setMarginLeft] = useState(0);
    const [customSearchWidth, setCustomSearchWidth] = useState(0);
    const [hasMoved, setHasMoved] = useState(0);


    const handleEnter = e => {
        if (e.keyCode == 13) {
            props.onSearch()
        }

        //엔터누르면 오른쪽으로 마진 이동
        if (e.keyCode == 13 && !hasMoved ) {
            setCustomSearchWidth((prevWidth) => prevWidth + 70);
            setMarginLeft((prevMargin) => prevMargin + 70);
            setHasMoved(true);
        }
    }; 

    const clickMove = () =>{
        if(!hasMoved){
        setCustomSearchWidth((prevWidth) => prevWidth + 70);
        setMarginLeft((prevMargin) => prevMargin + 70);
        setHasMoved(true);
        }
    }


    const style = {
        // width: `${customSearchWidth}px`,
        marginLeft: `${marginLeft}px`,
      };

    return (
        <div style={style}>
            <div className="custom-search  align-item-center box-shadow">             
                <button className="common-button" onClick={()=>{props.onSearch(); clickMove();}} > 
                    <img src={'/assets/image/icon_search_black.png'} alt=""/>
                </button>
                <input className="input-transparent" placeholder={props.placeholder} type={props.inputType} maxLength={props.maxLength} value={props.value} onChange={(e) => props.onChange(e.target.value)} onKeyDown={handleEnter}
                />
            </div>
        </div>
    );
}