import React, { useState } from "react";
import AiRai from "../../../pages/ai/ai_rai";

export default function AIRaiButton() {

    const [isVisible, setIsVisible] = useState(false);

    const toggleComponent = (e) => {
        e.stopPropagation(); // 이벤트 버블링을 막음
      setIsVisible(false);
    };

    const openChat = () => {
        setIsVisible(true);
        console.log("test")
      };

    return (
        <>
        
    
        <button onClick={()=>openChat()} className="AI-Rai-Button">
            <img src={'/assets/image/img_AI_rai.png'} alt=""/>
            
        </button>
        <AiRai onClose={toggleComponent} isVisible={isVisible}/>
        
        </>
    );

}
