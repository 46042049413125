import React, { useState } from 'react';
import styled from 'styled-components';
import colors from '../design/colors';

interface ToggleSwitchProps {
    isOn: boolean;
    handleToggle: () => void;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ isOn, handleToggle }) => {
    return (
        <SwitchContainer onClick={handleToggle} isOn={isOn}>
            <Switch isOn={isOn} />
        </SwitchContainer>
    );
};

export default ToggleSwitch;

const SwitchContainer = styled.div<{ isOn: boolean }>`
    width: 44px;
    height: 22px;
    border-radius: 11px;
    background-color: ${props => (props.isOn ? colors.blue600 : colors.gray200)};
    display: flex;
    align-items: center;
    justify-content: ${props => (props.isOn ? 'flex-end' : 'flex-start')};
    padding: 2px;
    cursor: pointer;
    transition: background-color 0.3s;
`;

const Switch = styled.div<{ isOn: boolean }>`
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: white;
    transition: transform 0.3s;
`; 