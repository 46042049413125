import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { IClassMemberList } from "../../../type/classroom";
import styled from "styled-components";
import { getFilePath } from "../../../utils/image";
import colors from "../../../design/colors";
import fonts from "../../../design/typography";
import { IconClassFilled } from "../../../components/icons/class";
import IconPhone from "../../../components/icons/phone";
import IconInfo from "../../../components/icons/info";
import IconX from "../../../components/icons/x";
import DataLabModalPlanner from "./component/DataLabModalPlanner";
import LifeRecordContent from "./component/life_record_content";
import ApplicationContent from "./component/application_content";
import ImageCircle from "../../../components/image_circle";

const StudentInfoModal = ({toggle, studentInfo, classId}: {toggle: () => void, studentInfo: IClassMemberList, classId: number}) => {

    const [selectedTab, setSelectedTab] = useState<"PLANNER" | "LIFE_RECORD" | "ADMISSION_APPLICATION">("PLANNER");


    return (
        <Modal isOpen={true} toggle={toggle} size="xl" >
            <Container>
                <Header>
                    <ImageCircle src={getFilePath(studentInfo.profile, "/assets/image/default_profile.png")} size={48} style={{ marginRight: "12px" }} />
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" , width: "calc(100% - 48px)"}}>
                        <span style={{ ...fonts.body1SemiBold, color: colors.gray900 }}>{studentInfo.name}</span>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start"}}>
                                <IconClassFilled width="16px" height="16px" color={colors.gray500} />
                                <span style={{ ...fonts.label4Medium, color: colors.gray500, marginLeft: "4px" }}>{studentInfo.school || " - "}</span>
                            </div>
                            <div style={{ width: "1px", height: "12px", backgroundColor: colors.gray400, margin: "0 16px" }}/>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start"}}>
                                <IconPhone width="16px" height="16px" color={colors.gray500} />
                                <span style={{ ...fonts.label4Medium, color: colors.gray500, marginLeft: "4px" }}>{studentInfo.phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')}</span>
                            </div>
                            <div style={{ width: "1px", height: "12px", backgroundColor: colors.gray400, margin: "0 16px" }}/>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                                <IconInfo width="16px" height="16px" color={colors.gray500} />
                                <span style={{ ...fonts.label4Medium, color: colors.gray500, marginLeft: "4px" }}>비고(준비 중)</span>
                            </div>
                        </div>
                    </div>
                    <div style={{ position: "absolute", right: "24px", top: "20px", cursor: "pointer" }} onClick={toggle}>
                        <IconX width="24px" height="24px" color={colors.gray800} />
                    </div>
                </Header>
                <TabWrapper>
                    <Tab selected={selectedTab === "PLANNER"} onClick={() => setSelectedTab("PLANNER")}>
                        <TabText selected={selectedTab === "PLANNER"}>플래너</TabText>
                    </Tab>
                    <Tab selected={selectedTab === "LIFE_RECORD"} onClick={() => setSelectedTab("LIFE_RECORD")}>
                        <TabText selected={selectedTab === "LIFE_RECORD"}>생기부노트</TabText>
                    </Tab>
                    <Tab selected={selectedTab === "ADMISSION_APPLICATION"} onClick={() => setSelectedTab("ADMISSION_APPLICATION")}>
                        <TabText selected={selectedTab === "ADMISSION_APPLICATION"}>입학원서</TabText>
                    </Tab>
                </TabWrapper>
                <ContentWrapper>
                    {selectedTab === "PLANNER" && (
                        <DataLabModalPlanner member={studentInfo.memberId} classId={classId} />
                    )}
                    {selectedTab === "LIFE_RECORD" && (
                        <LifeRecordContent member={studentInfo.memberId} classId={classId}/>
                    )}
                    {selectedTab === "ADMISSION_APPLICATION" && (
                        <ApplicationContent member={studentInfo.memberId} classId={classId}/>
                    )}
                </ContentWrapper>
            </Container>
        </Modal>
    );
};

export default StudentInfoModal;

const Container = styled(ModalBody)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 48px 40px 40px 40px;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background-color: ${colors.gray50};
    padding: 0 24px;
    border-radius: 12px;
    height: 104px;
    margin-bottom: 40px;
    position: relative;
`;

const TabWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid ${colors.gray100};
    margin-bottom: 32px;
`;

const Tab = styled.div<{selected: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 88px;
    height: 100%;
    border-bottom: 2px solid ${props => props.selected ? colors.gray900 : "transparent"};
    cursor: pointer;
`;

const TabText = styled.span<{selected: boolean}>`
    color: ${props => props.selected ? colors.gray900 : colors.gray500};
    font-size: ${props => props.selected ? fonts.body3SemiBold.fontSize : fonts.body3Medium.fontSize};
    font-family: ${props => props.selected ? fonts.body3SemiBold.fontFamily : fonts.body3Medium.fontFamily};
    line-height: ${props => props.selected ? fonts.body3SemiBold.lineHeight : fonts.body3Medium.lineHeight};
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 488px;
`;
