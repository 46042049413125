import React from "react";
import { IconType } from "../../type/icon_type";

const ChevronRight = (props: IconType) => {
    const { width, height, color, style } = props;
    return (
        <svg width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path d="M9 18L15 12L9 6" stroke={color || "#73787E"} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
};

const ChevronLeft = (props: IconType) => {
    const { width, height, color, style } = props;
    return (
        <svg width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style} >
            <path d="M15 18L9 12L15 6" stroke={color || "#73787E"} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
};


const ChevronDown = (props: IconType) => {
    const { width, height, color, style } = props;
    return (
        <svg width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path d="M6 9L12 15L18 9" stroke={color || "#73787E"} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    );
};

const ChevronUp = (props: IconType) => {
    const { width, height, color, style } = props;
    return (
        <svg width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path d="M18 15L12 9L6 15" stroke={color || "#73787E"}   stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
            
    );
};

export { ChevronRight, ChevronLeft, ChevronDown, ChevronUp };