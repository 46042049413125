import React, { useState } from "react";

export default function GoalInput(props) {

    const [focus, setFocus] = useState(false);

    const handleFocus = e => {
        setFocus(true);
    }

    const handleEnter = e => {
        if (e.keyCode == 13) {
            onEnter();
        }
    };

    const { onChange, placeholder, value, onBlur, onEnter, onPencil, disabled, inputType, maxLength, inputRef } = props;
    return (
        <div className="goal-input align-item-center"
            onClick={() => {
                if (disabled) {
                    onPencil()
                }
            }}>
            <input className="input-transparent" placeholder={focus ? '' : placeholder} type={inputType}
                maxLength={maxLength}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onFocus={handleFocus}
                onKeyDown={handleEnter}
                onBlur={() => {
                    setFocus(false);
                    onBlur();
                }}
                disabled={disabled}
                ref={inputRef}
            />
            {
                (value == '' && !focus) &&
                <button className="common-button">
                    <img src={'/assets/image/icon_pencil_blue.png'} alt="" />
                </button>
            }
        </div>
    );
}

