import { inject } from "mobx-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SearchBar from "../../components/search_bar";
import colors from "../../design/colors";
import fonts from "../../design/typography";
import Button from "../../components/button";
import Checkbox from "../../components/checkbox";
import useDebounce from "../../hooks/useDebounce";

interface ISchoolInfo {
    SCHUL_NM: string;
    SD_SCHUL_CODE: string;
    ATPT_OFCDC_SC_CODE: string;
}

const SignupStep3 = (props) => {

    const {
        eduOfficeCode,
        setEduOfficeCode,
        schoolCode,
        setSchoolCode,
        school,
        setSchool,
        onDone
    } = props;

    const [isSchoolSelected, setIsSchoolSelected] = useState(false);
    const [havingSchool, setHavingSchool] = useState(true);
    const [schoolList, setSchoolList] = useState<ISchoolInfo[]>([]);
    const [schoolSearchVal, setSchoolSearchVal] = useState("");
    const schoolSearch = useDebounce(schoolSearchVal, 500);
    useEffect(() => {
        if (schoolSearchVal.length > 1) {
            fetchSchoolList(schoolSearchVal, setSchoolList);
        } else {
            setSchoolList([]);
        }
    }, [schoolSearch]);
    

    const validSchool = !havingSchool || (isSchoolSelected && schoolCode && eduOfficeCode);

    return (
        <div style={{width: "100%", marginTop: 80}}>
            <Title>학교 검색</Title>
            <div style={{marginBottom: 16}}>
                <span style={{...fonts.body3SemiBold, color: colors.gray900}}>{"학교"}</span>
                <span style={{...fonts.body3SemiBold, color: colors.gray400, marginLeft: 10}}>{"(졸업 포함)"}</span>
            </div>
            <SearchBar
                placeholder={"학교를 검색해주세요"}
                value={schoolSearchVal}
                onChange={(e) => {
                    setSchoolSearchVal(e.target.value);
                    setIsSchoolSelected(false);
                }}
                disabled={!havingSchool}
                options={schoolList.map(schoolCurrent => ({

                    label: schoolCurrent.SCHUL_NM,
                    onSelect: () => {
                        setSchool(schoolCurrent.SCHUL_NM);
                        setSchoolSearchVal(schoolCurrent.SCHUL_NM);
                        setSchoolCode(schoolCurrent.SD_SCHUL_CODE);
                        setEduOfficeCode(schoolCurrent.ATPT_OFCDC_SC_CODE);
                        setIsSchoolSelected(true);
                    }
                }))}
            />
            <Row>
                <Checkbox
                    checked={!havingSchool}
                    setChecked={(checked) => {
                        setHavingSchool(prev => !prev);
                    }}
                />
                <span style={{...fonts.body3Medium, color: colors.gray900, marginLeft: 8}}>{"학교를 다니고 있지 않아요"}</span>
            </Row>
            <Button
                text={"다음"}
                disabled={!validSchool}
                onClick={() => {
                    if (validSchool) {
                        onDone();
                    }
                }}
                size={"large"}
                height={"48px"}
            />
        </div>
    );
}

export default inject('rootStore')(SignupStep3);

const Title = styled.p`
    font-family: ${fonts.heading1Bold.fontFamily};
    font-size: ${fonts.heading1Bold.fontSize};
    line-height: ${fonts.heading1Bold.lineHeight};
    color: ${colors.gray900};
    margin-bottom: 56px;
    text-align: center;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 56px;
`;


const fetchSchoolList = async (schoolName: string, setSchoolList: (schools: ISchoolInfo[]) => void) => {
    const NEIS_API_URL = process.env.REACT_APP_NEIS_API_URL!;
    const NEIS_API_KEY = process.env.REACT_APP_NEIS_API_KEY!;
    
    try {
        const response = await fetch(`${NEIS_API_URL}/schoolInfo?pIndex=1&pSize=100&KEY=${NEIS_API_KEY}&SCHUL_NM=${schoolName}&Type=xml`);
        const xmlText = await response.text();
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlText, "text/xml");
        
        const schools: ISchoolInfo[] = [];
        const rows = xmlDoc.getElementsByTagName("row");
        
        for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            schools.push({
                SCHUL_NM: row.getElementsByTagName("SCHUL_NM")[0].textContent?.replace("<![CDATA[", "").replace("]]>", "") || "",
                SD_SCHUL_CODE: row.getElementsByTagName("SD_SCHUL_CODE")[0].textContent?.replace("<![CDATA[", "").replace("]]>", "") || "",
                ATPT_OFCDC_SC_CODE: row.getElementsByTagName("ATPT_OFCDC_SC_CODE")[0].textContent?.replace("<![CDATA[", "").replace("]]>", "") || ""
            });
        }
        
        setSchoolList(schools);
        console.log('파싱된 학교 목록:', schools);
    } catch (error) {
        console.error('API 호출 에러:', error);
    }
}