import React, { useEffect, useRef } from 'react';

const FlowBanner = ({ animationName, name, children }) => {
    const wrapRef = useRef(null); 

    useEffect(() => { // useEffect 사용안하면 querySelector가 실행이 안됨
        const $wrap = wrapRef.current;
        const $list = $wrap.querySelector('.logo-list');
        const wrapWidth = $wrap.offsetWidth;
        const listWidth = $list.offsetWidth;
        const speed = 60;

        const $clone = $list.cloneNode(true);
        $wrap.appendChild($clone);
        flowBannerAct();

        function flowBannerAct() {
        if (listWidth < wrapWidth) {
            const listCount = Math.ceil((wrapWidth * 2) / listWidth);
            for (let i = 2; i < listCount; i++) {
            const newClone = $list.cloneNode(true);
            $wrap.appendChild(newClone);
            }
        }
        $wrap.querySelectorAll('.logo-list').forEach((list) => {
            list.style.animation = `${listWidth / speed}s linear infinite ${animationName}`;
        });
        }
    }, [animationName, name]);

    return (
        <div className={name} ref={wrapRef}>
            {children}
        </div>
    );
};

const HomeSec3 = () => {
    return (
        <section className="wrap main-sec3">
            <div className="main-tit">
                <h2>
                    수많은 교육기관과 함께 <br />
                    오늘도 변화하는 츄잉
                </h2>
            </div>
            <div className="flow-wrap">
                <FlowBanner animationName="flowRolling" name="flow-logo">
                    <ul className="logo-list">
                        <li><img src="/assets/image/landing_page/main/sec3-logo1.png" alt="" /></li>
                        <li><img src="/assets/image/landing_page/main/sec3-logo2.png" alt="" /></li>
                        <li><img src="/assets/image/landing_page/main/sec3-logo3.png" alt="" /></li>
                        <li><img src="/assets/image/landing_page/main/sec3-logo4.png" alt="" /></li>
                        <li><img src="/assets/image/landing_page/main/sec3-logo5.png" alt="" /></li>
                    </ul>
                </FlowBanner>
                <FlowBanner animationName="newFlowRolling" name="flow-logo2">
                    <ul className="logo-list">
                        <li><img src="/assets/image/landing_page/main/sec3-logo6.png" alt="" /></li>
                        <li><img src="/assets/image/landing_page/main/sec3-logo7.png" alt="" /></li>
                        <li><img src="/assets/image/landing_page/main/sec3-logo8.png" alt="" /></li>
                        <li><img src="/assets/image/landing_page/main/sec3-logo9.png" alt="" /></li>
                        <li><img src="/assets/image/landing_page/main/sec3-logo10.png" alt="" /></li>
                    </ul>
                </FlowBanner>
                <FlowBanner animationName="flowRolling" name="flow-logo3">
                    <ul className="logo-list">
                        <li><img src="/assets/image/landing_page/main/sec3-logo11.png" alt="" /></li>
                        <li><img src="/assets/image/landing_page/main/sec3-logo12.png" alt="" /></li>
                        <li><img src="/assets/image/landing_page/main/sec3-logo13.png" alt="" /></li>
                        <li><img src="/assets/image/landing_page/main/sec3-logo14.png" alt="" /></li>
                        <li><img src="/assets/image/landing_page/main/sec3-logo15.png" alt="" /></li>
                    </ul>
                </FlowBanner>
            </div>
        </section>
    );
};

export default HomeSec3;
