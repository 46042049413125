import React, { useContext, useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import DropdownProfile from './dropdown/profile.jsx';
import {Page_settings} from '../../config/page_settings.js';
import DropdownNotification from "./dropdown/notification";
import DropdownRank from "./dropdown/rank";
import DropdownShop from './dropdown/cloud.jsx';
import CustomSearch from '../control/custom_input/custom_search.js';
import ChewingTalkHeader from "./dropdown/chewingTalk_header.jsx";
import strings from '../../lang/strings.js';
import AIRaiButton from '../control/button/ai_rai_button.js';


const HeaderWhite = (props) => {
    const context = useContext(Page_settings);
    const [keyword, setKeyword] = useState("");
    const [showSearch, setShowSearch] = useState(false);
    const [loadMoreHide, setLoadMoreHide] = useState(false);
    const [openChewingTalk, setOpenChewingTalk] = useState(false);
    const [pageNum, setPageNum] = useState(1);
    const [allRoomList, setAllRoomList] = useState([]);
    const [studyRoom, setStudyRoom] = useState("all");
    const [roomStatus, setRoomStatus] = useState(1);
    const [category, setCategory] = useState(3);
    const [subCategory, setSubCategory] = useState(0);

    const [categoryRoomList, setCategoryRoomList] = useState([]);

    const loadRoomList = (mine, secret, type, category, page_num, keyword) => {
        context.get(
            'room/list',
            {
                mode: 2,
                mine: mine,
                secret: secret,
                type: type,
                category: category,
                page_num: page_num,
                keyword: keyword
            },
            response => {
                if (mine != null) {
                    setAllRoomList(response.list.slice(0, 5));
                } else {
                    if (page_num == 1) {
                        setCategoryRoomList(response.list);
                    } else {
                        setCategoryRoomList([...categoryRoomList, ...response.list]);
                    }
                }
                if ((response.list.length < response.limit) && (mine == null)) {
                    setLoadMoreHide(true);
                }
            }
        );  
    }

    useEffect(() => {
        loadRoomList(studyRoom, 0, 0, 0, 1, keyword);
    }, [studyRoom]);

    useEffect(() => {
        loadRoomList(null, roomStatus, category, subCategory, 1, keyword);
        setPageNum(1);
        setLoadMoreHide(false);
    }, [roomStatus, category, subCategory,]);

    const onLoadMore = () => {
        loadRoomList(null, roomStatus, category, subCategory, pageNum + 1, keyword);
        setPageNum(pageNum + 1)

    }

    

    
    return (
        <Page_settings.Consumer>
            
            {({toggleMobileSidebar, pageSidebar, plannerPageHeader}) => (
                
                <div id="header" className={"header navbar-default" + (plannerPageHeader ? " planner-header" : "")}>
                    <div className="navbar-header">
                        <Link to="/" className="navbar-brand">
                            <img className="logo" src={'/assets/image/logo_chewing_plus.png'} alt=""/>
                        </Link>
                        {pageSidebar && (
                            <button type="button" className="navbar-toggle" onClick={toggleMobileSidebar}>
                                <span className="icon-bar"/>
                                <span className="icon-bar"/>
                                <span className="icon-bar"/>
                            </button>
                        )}
                    </div>


                    

                    <ul className="navbar-nav rank-info" >
                        <ChewingTalkHeader theme={"white"} type={props.type}/>
                    </ul>

                    <ul className="navbar-nav rank-info navbar-right align-item-center">
                        {/* <DropdownRank type={props.type}/> */}
                        <AIRaiButton />
                        {/* <img src={'/assets/image/img_AI_rai.png'} alt=""/> */}
                    </ul>

                    <ul className="navbar-nav navbar-right align-item-center">
                        <DropdownShop theme={"white"} type={props.type}/>
                        <DropdownNotification theme={"white"} type={props.type}/>
                        <DropdownProfile theme={"white"} type={props.type}/>
                    </ul>
                </div>
            )}
        </Page_settings.Consumer>
    )
}

export default HeaderWhite;