export default function PaymentMainButton(props) {
  return (
      <button
          className={props.user_type === "STUDENT" ? "student" : ""}
          onClick={props.onClick}
          disabled={props.more}
          >
          <img src={props.more? "/assets/image/icon_carousel_"+props.direction+"_gray.png":"/assets/image/icon_carousel_"+props.direction+"_black.png"} alt=""/>
      </button>
  );
}
