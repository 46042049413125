import React, { memo, useContext, useEffect, useState } from 'react';
import 'react-datetime/css/react-datetime.css';
import 'react-notifications-component/dist/theme.css';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Page_settings } from "../../../config/page_settings";
import SubjectChip from '../../../components/subject_chip';
import colors from '../../../design/colors';
import fonts from '../../../design/typography';
import { Page, Document } from 'react-pdf';
import { getFilePath } from '../../../utils/image';
import { ChevronLeft, ChevronRight } from '../../../components/icons/chevron';
import { IconZoomIn, IconZoomOut } from '../../../components/icons/zoom';
import Button from '../../../components/button';
import { Modal, ModalBody } from 'reactstrap';

interface IExamInfo {
    info: {
        id: number;
        classId: number;
        member: number;
        title: string;
        subject: string;
        participates: number;
        status: number;
        timeLimit: number;
        questionCount: number;
        testPaper: ITestPaper;
        file: {
            id: number;
            name: string;
            size: number;
            type: string;
            url: string;
        };
        createDate: string;
        startDate: string;
        endDate: string;
    }
}


interface ITestPaper {
    id: number;
    questionTotalCount: number;
    questions: {
        number: number;
        questionType: number;
        score: number;
    }[];
}

const ExamStudent = () => {
    const context: any = useContext(Page_settings);
    const examId = useParams().exam_id;
    const [examInfo, setExamInfo] = useState<IExamInfo>(null);
    const [answerList, setAnswerList] = useState<string[]>([]);
    const [answerTotalPages, setAnswerTotalPages] = useState<number>(0);
    const [answerCurrentPage, setAnswerCurrentPage] = useState<number>(1);
    const [remainingTime, setRemainingTime] = useState<number>(-1);
    const [showSubmitConfirmModal, setShowSubmitConfirmModal] = useState<boolean>(false);

    const navigate = useNavigate();
    useEffect(() => {
        
        context.post(`/exam/${examId}`, {
        }, response => {
        context.get(
            `/exam/${examId}`,
            {
            },
            response => {
                const examInfo: IExamInfo = response;
                setExamInfo(examInfo);
                    setAnswerList(
                        examInfo.info.testPaper.questions.map((question) => {
                            if(question.questionType === 1) {
                                return "[]";
                            } else {
                                return "";
                            }
                        })
                    );
                    setAnswerTotalPages(Math.ceil(examInfo.info.testPaper.questions.length / 12));
                    setRemainingTime(examInfo.info.timeLimit * 60);
                }
            );
        });
    }, []);

    const onChangeAnswer = (index: number, answer: string) => {
        setAnswerList(answerList.map((item, i) => i === index ? answer : item));
    }

    const onSubmit = () => {

        const testAnswer = answerList.map((item, i) => {
            return {
                answer: item
            }
        });

        context.post(`/exam/${examId}/result`, {
            testAnswer: testAnswer
        }, response => {
            navigate(`/study/exam`);
        });
    }


    useEffect(() => {
        if (remainingTime > 0) {
            const timer = setTimeout(() => {
                setRemainingTime(prevTime => prevTime - 1);
            }, 1000);

            return () => clearTimeout(timer);
        }

        if (remainingTime === 0) {
            onSubmit();
        }
    }, [remainingTime]);

    if(!examInfo) {
        return (
            <Container>
                <div>Loading...</div>
            </Container>
        )
    }

    return (
        <Container>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 48}}>
                <div style={{display: 'flex', alignItems: 'center', gap: 12}}>
                    <SubjectChip subject={examInfo.info.subject} />
                    <span style={{...fonts.body2SemiBold, color: colors.gray900}}>{examInfo.info.title}</span>
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: 8, backgroundColor: colors.white, height: 40, padding: '0 12px', borderRadius: 12}}>
                    <span style={{...fonts.label4SemiBold, color: colors.gray900}}>남은 시간</span>
                    <span style={{...fonts.label4SemiBold, color: colors.gray600}}>
                        {remainingTime > 3600 && `${Math.floor(remainingTime / 3600)}:`} 
                        {Math.floor((remainingTime % 3600) / 60).toString().padStart(2, '0')}:{(remainingTime % 60).toString().padStart(2, '0')}
                    </span>
                </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', gap: 28, width: '100%', height: 'calc(100% - 100px)'}}>
                <div style={{width: 'calc(75% - 14px)', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <PdfFileViewer file={examInfo.info.file.url} />
                </div>
                <div style={{width: 'calc(25% - 14px)'}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 16}}>
                        <span style={{...fonts.label4SemiBold, color: colors.gray900}}>OMR 답안지</span>
                        <Button
                            text='제출하기'
                            size='small'
                            onClick={onSubmit}
                            width='77px'
                            height='32px'
                        />
                    </div>
                    <OMRWrapper>
                        <OMRHeader>
                            <div style={{width: "30px", textAlign: "center"}}>{"문제"}</div>
                            <div style={{width: "192px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                                <div style={{width: "24px", textAlign: "center"}}>{"1"}</div>
                                <div style={{width: "24px", textAlign: "center"}}>{"2"}</div>
                                <div style={{width: "24px", textAlign: "center"}}>{"3"}</div>
                                <div style={{width: "24px", textAlign: "center"}}>{"4"}</div>
                                <div style={{width: "24px", textAlign: "center"}}>{"5"}</div>
                            </div>
                            <div style={{width: "48px", textAlign: "center"}}>{"배점"}</div>
                        </OMRHeader>
                        <OMRBody>
                        {examInfo.info.testPaper.questions.slice((answerCurrentPage - 1) * 12, answerCurrentPage * 12).map((answer, index) => (
                            <OMRItem>
                                <div style={{width: "30px", textAlign: "center"}}>{index + 1 + (answerCurrentPage - 1) * 12 + "번"}</div>
                                <div style={{width: "192px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                                    {answer.questionType === 1 ?
                                    <>
                                        {Array.from({length: 5}, (_, i) => {
                                            const answer: number[] = JSON.parse(answerList[index + (answerCurrentPage - 1) * 12]);

                                        return (
                                            <div style={{width: "24px", height: "24px", borderRadius: "50%", cursor: "pointer",
                                            backgroundColor: answer.includes(i + 1) ? colors.black : colors.white, border: `1px solid ${colors.gray200}`}} 
                                            onClick={() => onChangeAnswer(index + (answerCurrentPage - 1) * 12, answer.includes(i + 1) ? JSON.stringify(answer.filter((item) => item !== i + 1)) : JSON.stringify([...answer, i + 1]))}/>
                                        )
                                        })}
                                    </>
                                    :
                                    <>
                                        <OMRInput type="text" value={answerList[index + (answerCurrentPage - 1) * 12]} onChange={(e) => onChangeAnswer(index + (answerCurrentPage - 1) * 12, e.target.value)} width="100%"  />
                                    </>
                                    }
                                </div>
                                <div style={{width: "48px"}}>
                                    <OMRInput type="number" value={answer.score.toFixed(1)} width="100%" complete={true} textAlign="center" disabled={true} readOnly={true} />
                                </div>
                            </OMRItem>
                        ))}
                        </OMRBody>
                    </OMRWrapper>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 8, marginTop: 12}}>
                        <div onClick={() => {
                            if(answerCurrentPage > 1) {
                                setAnswerCurrentPage(answerCurrentPage - 1)
                            }
                        }}>
                            <ChevronLeft width='20px' height='20px' color={answerCurrentPage > 1 ? colors.gray500 : colors.gray200}/>
                        </div>
                        <div style={{...fonts.label4Medium, color: colors.gray900, display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <span style={{...fonts.label4Medium, color: colors.blue600}}>{answerCurrentPage}</span>
                            <span>/{answerTotalPages}</span>
                        </div>
                        <div onClick={() => {
                            if(answerCurrentPage < answerTotalPages) {
                                setAnswerCurrentPage(answerCurrentPage + 1)
                            }
                        }}>
                            <ChevronRight width='20px' height='20px' color={answerCurrentPage < answerTotalPages ? colors.gray500 : colors.gray200}/>
                        </div>
                    </div>
                </div>
            </div>
            {showSubmitConfirmModal && 
            <SubmitConfirmModal onClose={() => setShowSubmitConfirmModal(false)} 
            onConfirm={onSubmit} 
            isAllAnswerChecked={answerList.every((item) => (item !== "" && item !== "[]"))} />}
        </Container>
    );

}

export default ExamStudent;


const PdfFileViewer = memo((props: {file: string}) => {
    const [numPages, setNumPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [scale, setScale] = useState(1.0);

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
    }

    const increaseScale = () => setScale(prevScale => Math.min(prevScale + 0.1, 2.0));
    const decreaseScale = () => setScale(prevScale => Math.max(prevScale - 0.1, 0.5));
    
    return (
        <>
        <PdfViewerDocument 
            file={getFilePath(props.file)} 
            onLoadSuccess={onDocumentLoadSuccess}
        options={{
            maxImageSize: 1024 * 5000,
            cMapUrl: 'cmaps/',
            cMapPacked: true,
        }}
        >
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: 'relative'}}>
            <PdfViewerPage 
                pageNumber={currentPage} 
                scale={scale}
                width={900}
                renderTextLayer={false}

            />
        </div>
        </PdfViewerDocument>
    <div style={{position: 'absolute', bottom: 24, left: 24, width: '95px', height: '36px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: colors.gray50, borderRadius: 8}}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 8}}>
            <div style={{cursor: 'pointer'}} onClick={() => {
                increaseScale()
            }}>
                <IconZoomIn width='20px' height='20px' color={colors.gray500} />
            </div>
            <span style={{...fonts.label5Regular, color: colors.gray900}}>{Math.round(scale * 100)}%</span>
            <div style={{cursor: 'pointer'}} onClick={() => {
                decreaseScale()
            }}>
                <IconZoomOut width='20px' height='20px' color={colors.gray500} />
            </div>
        </div>
    </div>
    <div style={{position: 'absolute', bottom: 24, right: 24, width: '95px', height: '36px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: colors.gray50, borderRadius: 8}}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 8}}>
            <div style={{cursor: currentPage > 1 ? 'pointer' : 'default'}} onClick={() => {
                if(currentPage > 1) {
                    setCurrentPage(currentPage - 1)
                }
            }}>
                <ChevronLeft width='20px' height='20px' color={currentPage > 1 ? colors.gray500 : colors.gray200}/>
            </div>
            <span style={{...fonts.label5Regular, color: colors.gray900}}>{currentPage}/{numPages}</span>
            <div style={{cursor: currentPage < numPages ? 'pointer' : 'default'}} onClick={() => {
                if(currentPage < numPages) {
                    setCurrentPage(currentPage + 1)
                }}}>
                <ChevronRight width='20px' height='20px' color={currentPage < numPages ? colors.gray500 : colors.gray200}/>
            </div>
        </div>
    </div>
    </>
    )
});

const OMRInput = (props) => {
  const {value, onChange, complete, width, type, textAlign="left"} = props;
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div style={{width: width, border: `1px solid ${isFocused ? colors.blue600 : colors.gray100}`, height: "100%", borderRadius: "4px", padding: "0 8px", backgroundColor: complete ? colors.gray50 : colors.white}}>
      <input 
        type={type} 
        value={value} 
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        style={{backgroundColor: complete ? colors.gray50 : colors.white, border: "none", ...fonts.label4Medium, width: "100%", textAlign: textAlign}} 
        onChange={onChange as (e: React.ChangeEvent<HTMLInputElement>) => void}
        />
    </div>
  )
}

const SubmitConfirmModal = ({onClose, onConfirm, isAllAnswerChecked}) => {
    return (
      <Modal size="sm" isOpen={true} toggle={onClose}>
        <ModalBody style={{padding: "24px"}}>
          <div style={{...fonts.body2Medium, color: colors.gray900, marginBottom: 32, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: 24}}>
            {isAllAnswerChecked ? 
            (<span>답안지를 제출하시겠습니까?</span>) : 
            (<>
                <span>답변을 선택하지 않은 문제가 있습니다</span>
                <span style={{...fonts.label4Medium, color: colors.gray500}}>그래도 제출하시겠습니까?</span>
            </>
            )}
          </div>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 8}}>
            <Button
              size="medium"
              text="취소"
              onClick={onClose}
              buttonColor={colors.gray100}
              textColor={colors.gray500}
              width="112px"
              height="44px"
            />
            <Button
              size="medium"
              text="제출"
              onClick={onConfirm}
              buttonColor={colors.blue600}
              textColor={colors.white}
              width="112px"
              height="44px"
            />
          </div>
        </ModalBody>
      </Modal>
    );
  }

const Container = styled.div`
    width: 100%;
    height: 100%;
    padding: 28px 40px;
`;

const PdfViewerDocument = styled(Document)`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const PdfViewerPage = styled(Page)`
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
`;


const OMRWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: calc(100% - 100px);
  border: 1px solid ${colors.gray100};
  border-radius: 8px;
  padding: 0 16px;
  background-color: ${colors.white};
`;

const OMRHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 32px 0 28px 0;
  font-size: ${fonts.label5Medium.fontSize};
  font-family: ${fonts.label5Medium.fontFamily};
  line-height: ${fonts.label5Medium.lineHeight};
  color: ${colors.gray500};
`;


const OMRBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 24px;
  height: 800px;
  overflow-y: auto;

  &::-webkit-scrollbar {
      width: 4px; /* Adjust the width of the scrollbar */
  }
  &::-webkit-scrollbar-thumb {
      background-color: ${colors.gray200}; /* Color of the scrollbar thumb */
      border-radius: 4px; /* Rounded corners for the thumb */
  }
  &::-webkit-scrollbar-track {
      background: transparent; /* Make the track invisible */
  }
`;

const OMRItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 24px;
`;