import React from "react";
import { inject } from "mobx-react";
import styled from "styled-components";
import { IoChevronForwardOutline } from "react-icons/io5";
import fonts from "../../design/typography";
import colors from "../../design/colors";
import { useNavigate } from "react-router-dom";
import { mainSheet } from "styled-components/dist/models/StyleSheetManager";

interface SignupStep0Props {
  setUserType: (userType: "STUDENT" | "EXPERT") => void;
}

const SignupStep0 = (props: SignupStep0Props) => {
  const { setUserType } = props;
  const navigate = useNavigate();
  const onNext = () => {
    navigate(`/signup/1`);
  };

  return (
    <Container>
      <img src="/assets/image/title.png" alt="logo" width={418} height={36} />
      <RowWholeWrapper>
        <CardWrapper userType={"EXPERT"}>
          <ImageWrapper userType={"EXPERT"}>
            <img
              src="/assets/image/img_expert.png"
              height={150}
              style={{ marginTop: 20 }}
            />
          </ImageWrapper>
          <span style={fonts.body1SemiBold}>선생님으로</span>
          <span style={fonts.body1SemiBold}>활동하고 싶어요</span>
          <ButtonWrapper
            userType={"EXPERT"}
            onClick={() => {
              setUserType("EXPERT");
              onNext();
            }}
          >
            <span style={fonts.body3SemiBold}>선생님 회원</span> <IoChevronForwardOutline />
          </ButtonWrapper>
        </CardWrapper>
        <CardWrapper userType={"STUDENT"}>
          <ImageWrapper userType={"STUDENT"}>
            <img
              src="/assets/image/img_student.png"
              height={170}
            />
          </ImageWrapper>
          <span style={fonts.body1SemiBold}>학생으로</span>
          <span style={fonts.body1SemiBold}>활동하고 싶어요</span>
          <ButtonWrapper
            userType={"STUDENT"}
            onClick={() => {
              setUserType("STUDENT");
              onNext();
            }}
          >
            <span style={fonts.body3SemiBold}>학생 회원</span> <IoChevronForwardOutline />
          </ButtonWrapper>
        </CardWrapper>
      </RowWholeWrapper>
    </Container>
  );
};

export default inject("rootStore")(SignupStep0);

interface IUserType {
  userType: "STUDENT" | "EXPERT";
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 80px;
  margin-top: 80px;
`;

const RowWholeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 634px;
  gap: 80px;
`;

const CardWrapper = styled.div<IUserType>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 273px;
  height: 400px;
  padding-top: 40px;
  padding-right: 51.5px;
  padding-left: 51.5px;
  background-color: ${(props) => props.userType === "EXPERT" ? colors.blue50 : colors.green50};
  border-radius: 16px;
  font-size: 15px;
  font-weight: bold;
`;

const ImageWrapper = styled.div<IUserType>`
  width: 170px;
  height: 170px;
  border-radius: 50%;
  margin-bottom: 25px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.userType === "EXPERT" ? colors.blue100 : colors.green100};
`;

const ButtonWrapper = styled.button<IUserType>`
  width: 100%;
  height: 44px;
  font: 20px;
  color: ${(props) => (props.userType === "EXPERT" ? colors.blue600 : colors.green400)};
  border: 1px solid
    ${(props) => (props.userType === "EXPERT" ? colors.blue600 : colors.green400)};
  border-radius: 5px;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 32px;

  &:hover {
    background-color: ${(props) =>
      props.userType === "EXPERT" ? colors.blue600 : colors.green400};
    color: white;
    transition: 0.3s;
  }
`;
