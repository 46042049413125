import { inject } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../components/button";
import SelectBox from "../../components/select_box";
import TextField from "../../components/text_field";
import { Page_settings } from "../../config/page_settings";
import colors from "../../design/colors";
import fonts from "../../design/typography";
import strings from "../../lang/strings";

interface ISignupStep2Props {
    name: string;
    onChangeName: (name: string) => void;
    dateBirth: string;
    onChangeDateBirth: (dateBirth: string) => void;
    phone: string;
    onChangePhone: (phone: string) => void;
    code: string;
    onChangeCode: (code: string) => void;
    sexNum: number;
    setSexNum: (sexNum: number) => void;
    onDone: () => void;
}

const SignupStep2 = (props: ISignupStep2Props) => {
    const context: any = useContext(Page_settings);
    const {
        name,
        onChangeName,
        dateBirth,
        onChangeDateBirth,
        phone,
        onChangePhone,
        code,
        onChangeCode,
        onDone,
        sexNum,
        setSexNum
    } = props;

    const [invalidName, setInvalidName] = useState(false);
    const [invalidDate, setInvalidDate] = useState(false);
    const [invalidSex, setInvalidSex] = useState(false);
    const [invalidPhone, setInvalidPhone] = useState(true);
    const [duplicatePhone, setDuplicatePhone] = useState(false);
    const [codeSent, setCodeSent] = useState(false);
    const [invalidCode, setInvalidCode] = useState(true);
    const [wrongCode, setWrongCode] = useState(false);

    const [remainingTime, setRemainingTime] = useState(0);
    const remainingTimeText = remainingTime > 0 ? `${Math.floor(remainingTime / 60)}:${remainingTime % 60}` : "";
    const [selectedTelecom, setSelectedTelecom] = useState("");
    const phoneRegex = /^01[016789]\d{6,7}$/;

    const codeSend  = (phone: string) => {
        context.post(
            "/auth/phone-auth",
            {
                phone
            },
            response => {
                setCodeSent(true);
                setRemainingTime(180);
            }
        )
    }

    const codeVerify = (phone: string, code: string) => {
        context.post(
            "/auth/phone-auth/check",
            {
                phone,
                authCode: code
            },
            response => {
                if (response) {
                    onDone();
                } else {
                    setWrongCode(true);
                }
            }
        )
    }

    useEffect(() => {
        if (remainingTime > 0) {
            const interval = setInterval(() => {
                setRemainingTime(remainingTime - 1);
            }, 1000);
            return () => clearInterval(interval);
        }

    }, [remainingTime]);
    

    return (
        <div style={{width: "100%", marginTop: 80, height: "100%"}}>
            <Title>회원가입</Title>
            <div style={{marginBottom: 16}}>
                <span style={{...fonts.body3SemiBold}}>{strings.name}</span>
            </div>
            <TextField
                placeholder={"이름을 입력해주세요"}
                value={name}
                onChange={(e) => {
                    onChangeName(e.target.value)
                    if (name.length > 0) {
                        setInvalidName(false)
                    } else {
                        setInvalidName(true)
                    }
                }}
                error={invalidName}
                complete={!invalidName && name.length > 0}
            />
            <div style={{marginBottom: 16, marginTop: 40}}>
                <span style={{...fonts.body3SemiBold}}>{strings.date_birth}</span>
            </div>
            <RowWrapper>
                <TextField
                    placeholder={"생년월일 6자리"}
                    value={dateBirth}
                    onChange={(e) => {
                        const inputValue = e.target.value.replace(/\D/g, ''); // Allow only digits
                        if (inputValue.length <= 6) {
                            onChangeDateBirth(inputValue);
                        }

                        if (inputValue.length === 6) {
                            setInvalidDate(false);
                        } else {
                            setInvalidDate(true);
                        }
                    }}
                    width="calc(50% - 10px)"
                    complete={dateBirth.length === 6}
                />
                <span> - </span>
                <RelativeWrapper style={{width: "calc(50% - 10px)"}}>
                    <TextField
                        type="number"
                        value={
                            isNaN(sexNum) ? "" : sexNum.toString()
                        }
                        placeholder={"0"}
                        onChange={(e) => {
                            const inputValue = e.target.value.replace(/\D/g, ''); // Allow only digits
                            const targetValue = inputValue.length > 1 ? parseInt(inputValue.substring(1)) : parseInt(inputValue);    
                            setSexNum(targetValue);
                            if (targetValue >= 1 && targetValue <= 6) {
                                setInvalidSex(false);
                            } else {
                                setInvalidSex(true);
                            }
                        }}
                        complete={sexNum>= 1 && sexNum <=6}
                        error={invalidSex}
                    />
                    <RowWrapper style={{position: "absolute", top: 0, left: 30, width: "70%", height: "100%", alignItems: "center", justifyContent: "center"}}>
                        {Array.from({ length: 6 }, (_, index) => (
                            <div key={index} style={{width: 9, height: 9, borderRadius: 9, backgroundColor: colors.black}}/>
                        ))}
                    </RowWrapper>
                </RelativeWrapper>
            </RowWrapper>
            <div style={{marginBottom: 16, marginTop: 40}}>
                <span style={{...fonts.body3SemiBold}}>{"통신사"}</span>
            </div>
            <SelectBox
                options={[
                    {
                        label: "SKT",
                        onSelect: () => {
                            setSelectedTelecom("SKT");
                        }
                    },
                    {
                        label: "KT",
                        onSelect: () => {
                            setSelectedTelecom("KT");
                        }
                    },
                    {
                        label: "LG U+",
                        onSelect: () => {
                            setSelectedTelecom("LGU+");
                        }
                    },
                    {
                        label: "SKT 알뜰폰",
                        onSelect: () => {
                            setSelectedTelecom("SKT 알뜰폰");
                        }
                    },
                    {
                        label: "KT 알뜰폰",
                        onSelect: () => {
                            setSelectedTelecom("KT 알뜰폰");
                        }
                    },
                    {
                        label: "LG U+ 알뜰폰",
                        onSelect: () => {
                            setSelectedTelecom("LGU+ 알뜰폰");
                        }
                    },
                ]}
                selectedOptionLabel={selectedTelecom}
                disabled={false}
            />
            <div style={{marginBottom: 16, marginTop: 40}}>
                <span style={{...fonts.body3SemiBold}}>{strings.phone}</span>
            </div>
            <RowWrapper style={{alignItems: "flex-start", width: "100%"}}>
                <TextField
                    placeholder={"‘-’ 제외한 휴대폰 번호를 입력해주세요"}
                    value={phone}
                    width="calc(100% - 80px)"
                    onChange={(e) => {
                        onChangePhone(e.target.value)
                        if (phoneRegex.test(phone)) {
                            setInvalidPhone(false)
                        } else {
                            setInvalidPhone(true)
                        }
                        setDuplicatePhone(false);
                        setCodeSent(false);
                    }}
                    error={phone.length > 0 && (invalidPhone || duplicatePhone)}
                    helperText={phone.length > 0 && (invalidPhone ? "올바른 휴대폰 번호를 입력해주세요" : duplicatePhone ? "이미 가입된 휴대폰 번호입니다." : "")}
                    complete={codeSent}
                />
                <Button
                    size="medium"
                    text={"인증"}
                    onClick={() => {
                        codeSend(phone);
                    }}
                    disabled={invalidPhone || codeSent}
                    width="72px"
                    height="48px"
                />
            </RowWrapper>
            {codeSent && (
                <>
            <div style={{marginBottom: 16, marginTop: 40}}>
                <span style={{...fonts.body3SemiBold}}>{"인증번호"}</span>
            </div>
                <div style={{width: "100%", position: "relative"}}>
                    <TextField
                        placeholder={"인증번호 6자리"}
                        value={code}
                        onChange={(e) => {
                            const inputValue = e.target.value.replace(/\D/g, ''); // Allow only digits
                            onChangeCode(inputValue)
                            if (inputValue.length === 6) {
                                setInvalidCode(false);
                            } else {
                                setInvalidCode(true);
                            }
                            setWrongCode(false);
                        }}
                        error={code.length > 0 && (wrongCode || invalidCode)}
                        helperText={wrongCode ? "인증번호가 틀렸습니다." : ""}
                    />
                    <span style={{position: "absolute", top: 15.5, right: 30, width: 10, height: 10, color: colors.warning, fontSize: fonts.body3Regular.fontSize, lineHeight: fonts.body3Regular.lineHeight, fontFamily: fonts.body3Regular.fontFamily}}>{remainingTimeText}</span>
                </div>
                <RowWrapper style={{justifyContent: "center", alignItems: "center", marginTop: 16}}>
                    {remainingTime === 0 ? 
                        (<span style={{...fonts.body3SemiBold, color: colors.warning}}>{"인증 시간이 초과되었습니다. 다시 시도해 주세요."}</span>)
                      : (<span style={{...fonts.body3SemiBold}}>{"인증번호를 받지 못하셨나요?"}</span>)}
                    <span style={{...fonts.body3SemiBold, color: colors.blue600, cursor: "pointer"}} onClick={
                        () => {
                            codeSend(phone);
                        }
                    }>{"다시 받기"}</span>
                </RowWrapper>
                </>    
            )}
            <Button
                size="medium"
                height="48px"
                text={"다음"}
                onClick={() => {
                    if (invalidName || invalidDate || invalidSex || invalidPhone || invalidCode || !codeSent || remainingTime === 0) {
                        return;
                    }
                    codeVerify(phone, code);
                }}
                disabled={invalidName || invalidDate || invalidSex || invalidPhone || invalidCode || !codeSent || remainingTime === 0}
                style={{marginTop: 40, marginBottom: 80}}
            />
        </div>
    );
}

export default inject('rootStore')(SignupStep2);

const Title = styled.div`
    font-family: ${fonts.heading1Bold.fontFamily};
    font-size: ${fonts.heading1Bold.fontSize};
    line-height: ${fonts.heading1Bold.lineHeight};
    color: ${colors.gray900};
    margin-bottom: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
`;

const RelativeWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
`;