import {inject} from 'mobx-react';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {Page_settings} from '../../config/page_settings';
import strings from '../../lang/strings';
import {rootStore} from "../../mobx/store";
import SignupStep1 from "./signup_step1";
import SignupStep2 from "./signup_step2";
import {extractNumber} from "../../helper/common";
import SignupStep3 from "./signup_step3";

import moment from "moment";

const Signup = (props) => {
    const context = useContext(Page_settings);
    const idRef = useRef();
    const [id, setId] = useState('');
    const [type, setType] = useState('');
    const [pwd, setPwd] = useState('');
    const [pwdConfirm, setPwdConfirm] = useState('');
    const [agreeInfo, setAgreeInfo] = useState(false);
    const [agreeAdv, setAgreeAdv] = useState(false);
    const signupStep = parseInt(props.match.params.step);
    const signupInfo = rootStore.getSignUpInfo;
    const [name, setName] = useState("");
    const [dateBirth, setDateBirth] = useState("");
    const [phone, setPhone] = useState("");
    const [code, setCode] = useState("");
    const [phoneSent, setPhoneSent] = useState(false);
    const [countDown, setCountDown] = useState(180);
    const [tick, setTick] = React.useState(0);
    const [codeCheck, setCodeCheck] = useState(false);
    const [school, setSchool] = useState({});
    const [hopeSchool, setHopeSchool] = useState("");
    const [career, setCareer] = useState("");
    const [invalidPhone, setInvalidPhone] = useState(false);

    useEffect(() => {
        context.handleSetPageHeader(false);
        context.handleSetPageSidebar(false);
        idRef?.current?.focus();

        if (signupInfo != null) {
            setId(signupInfo.email);
            setType(signupInfo.login_type);
        }

        return () => {
            context.handleSetPageHeader(true);
            context.handleSetPageSidebar(true);
        }
    }, []);

    useEffect(() => {
        if (countDown == 0) {
            setCountDown(180)
            clearInterval(tick)
        }
    }, [countDown])

    const onSend = () => {
        if (phone == "") {
            return
        }
        context.post(
            'auth/check',
            {
                phone: phone,
            },
            response => {
                if (response.phone) {
                    setPhoneSent(true);
                    setCodeCheck(false)
                    setCountDown(180);
                    clearInterval(tick)
                    setTick(setInterval(() => {
                        setCountDown(prevTime => prevTime - 1);
                    }, 1000));
                    context.post(
                        "auth/requestAuthCode",
                        {
                            phone: phone
                        },
                    );

                } else {
                    setInvalidPhone(true);
                }
            }
        );

    }

    const onConfirm = () => {
        context.post(
            "auth/confirmAuthCode",
            {
                phone: phone,
                auth_code: code
            },
            response => {
                setCodeCheck(true);
                clearInterval(tick);
            }
        );
    }

    const onDone = () => {
        console.log(school);
        context.post(
            "auth/signup",
            {
                login_type: type,
                email: id,
                pwd: pwd,
                name: name,
                birthday: moment(dateBirth).format("YYYY-MM-DD"),
                phone: phone,
                school: school.id,
                hope_school: hopeSchool,
                hope_career: career,
                auth_code: code,
                user_type: "STUDENT"
            },
            response => {
                props.history.push("/login");
            }
        );
    }

    const changeDateBirth = () => {
        let yy = parseInt(dateBirth.slice(0, 2));
        if (yy < 40) {
            setDateBirth("20" + dateBirth);
        } else {
            setDateBirth("19" + dateBirth);
        }
    }

    return (
        <React.Fragment>
            <div id="header" className="header navbar-default login-header">
                <div className="navbar-header">
                    <img className="logo" src={'/assets/image/logo_chewing_plus.png'} alt=""/>
                </div>
            </div>

            <div className="login-container">
                <div className="login-title">{strings.signup}</div>
                <div className="login-content">
                    {
                        signupStep == 1 &&
                        <SignupStep1
                            id={id}
                            idRef={idRef}
                            type={type}
                            onChangeId={(v) => {
                                setId(v);
                            }}
                            pwd={pwd}
                            onChangePwd={(v) => {
                                setPwd(v);
                            }}
                            pwdConfirm={pwdConfirm}
                            onChangePwdConfirm={(v) => {
                                setPwdConfirm(v);
                            }}
                            agreeInfo={agreeInfo}
                            onAgreeInfo={() => setAgreeInfo(!agreeInfo)}
                            agreeAdv={agreeAdv}
                            onAgreeAdv={() => setAgreeAdv(!agreeAdv)}
                        />
                    }
                    {
                        signupStep == 2 &&
                        <SignupStep2
                            name={name}
                            onChangeName={(v) => setName(v)}
                            dateBirth={dateBirth}
                            onChangeDateBirth={(v) => setDateBirth(extractNumber(v))}
                            phone={phone}
                            onChangePhone={(v) => {
                                setPhone(extractNumber(v));
                                setInvalidPhone(false);
                            }}
                            code={code}
                            onChangeCode={(v) => {
                                setCode(extractNumber(v));
                            }}
                            onSend={() => onSend()}
                            phoneSent={phoneSent}
                            countDown={countDown}
                            onConfirm={() => onConfirm()}
                            codeCheck={codeCheck}
                            invalidPhone={invalidPhone}
                            onDone={() => changeDateBirth()}
                        />
                    }
                    {
                        signupStep == 3 &&
                        <SignupStep3
                            school={school}
                            setSchool={setSchool}
                            hopeSchool={hopeSchool}
                            onChangeHope={(v) => setHopeSchool(v)}
                            career={career}
                            onChangeCareer={(v) => setCareer(v)}
                            onDone={() => onDone()}
                        />
                    }
                </div>
            </div>
        </React.Fragment>
    );
}

export default withRouter(inject('rootStore')(Signup));
