import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { Page_settings } from '../../../config/page_settings';
import ViewModal from "../Modals/viewModal";
import VideoModal from "../Modals/videoModal";
import { rootStore } from '../../../mobx/store';
import MissionSubmitModal from '../Modals/missionSubmitModal';
import RejectModal from '../Modals/rejectModal';
import MissionEditModal from '../Modals/missionEditModal';
import MissionInfoButton from '../../../components/control/button/mission_info_button';
import MissionDeleteModal from '../Modals/missionDeleteModal';
import CloudContainer from '../../cabinet/cloud_container';
import MissionSubmitEditModal from '../Modals/missionSubmitEditModal';
import PerfectScrollbar from "react-perfect-scrollbar";
import MissionFinishModal from '../Modals/missionFinishModal';
import ApproveModal from '../Modals/approveModal';
import MissionSubmitDeleteModal from '../Modals/missionSubmitDeleteModal ';


const MissionListInfo = (props) => {

  const [missionInfo, setMissionInfo] = useState([]);
  const [missionFile, setMissionFile] = useState([]);
  const [missionStudent, setMissionStudent] = useState([]);
  const [missionStatus, setMissionStatus] = useState("");
  const [resolveStudent, setResolveStudent] = useState();
  const [totalStudent, setTotalStudent] = useState();
  const [openSubmitMission, setOpenSubmitMission] = useState(false);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const context = useContext(Page_settings);
  const [class_id, setClassId] = useState(parseInt(props.match.params.class_id));
  const [mission_id, setMissionId] = useState(parseInt(props.match.params.mission_id));
  const [sort, setSort] = useState(parseInt(props.match.params.sort));
  const [clickedIndex, setClickedIndex] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [showFileItem, setShowFileItem] = useState(false);
  const [showVideoItem, setShowVideoItem] = useState(false);
  const user_type = rootStore.getProfile?.user_type ?? ""
  const user_id = rootStore.getProfile?.id
  const [item, setItem] = useState("");
  const [submitMissionInfo, setSubmitMissionInfo] = useState([]);
  const [submitMissionInfoFile, setSubmitMissionInfoFile] = useState(null);
  const [clickStuInfo, setClickStuInfo] = useState("");
  const [clickStuInfoFile, setClickStuInfoFile] = useState(null);
  const [clickStuId, setClickStuId] = useState(null);
  const [clickSubmitId, setClickSubmitId] = useState(null);
  const [openDeleteModal,setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openEditSubmitModal, setOpenEditSubmitModal] = useState(false);
  const [openDeleteSubmitModal, setOpenDeleteSubmitModal] = useState(false);
  const [openFinishModal,setOpenFinishModal] = useState(false);
  const [previousSelectStatus,setPreviousSelectStatus] = useState();
  const [previousId,setPreviousId] = useState();
  const [nextSelectStatus,setNextSelectStatus] = useState();
  const [toggleMenu, setToggleMenu] = useState(false);
  const [nextId,setNextId] = useState();
  const [updateStatus,setUpdateStatus] = useState(false);
  
  const imgType = ["bmp",
  "jpg",
  "jpeg",
  "png",
  "tiff"]
  const [missionListCount, setMissionListCount] = useState();
  const [clickEvent,setClickEvent] = useState(false);

  useEffect(() => {
    getMissionInfo();
    getMissionInfoFile();
    loadMissionCount();
    getMissionArrowFunction();
  }, [clickEvent,updateStatus])

  useEffect(() => {
    getMissionStudent();
    loadClickStuInfo();
  }, [openRejectModal,openApproveModal,clickEvent,updateStatus])

  useEffect(() => {
    if(user_type === "STUDENT"){
      getSubmitMissionInfo();
      getSubmitMissionInfoFile();
      getMissionStudent();
    }
  }, [openSubmitMission,openEditModal,clickEvent,openDeleteSubmitModal])

  const loadMissionCount = () => {
    context.get(
      'class/getMissionList',
      {
        class_id,
      },
      response => {
        console.log("getMissionList",response);
        setMissionListCount(response.missionListCount);
      }
    );
  }


  const getMissionInfo = () => {
    context.get(
      'class/getMissionInfo',
      { mission_id: mission_id },
      response => {
        console.log("getMissionInfo",response)
        if(response.length === 0) {
          alert('찾을수 없습니다');
          props.history.replace(`/class/mission/${class_id}`)
        }
        setMissionInfo(response)
      }
    );

  }


  const getMissionInfoFile = () => {
    context.get(
      'class/getMissionInfoFile',
      { mission_id: mission_id },
      response => {
        console.log("getMissionInfoFile",response)
        setMissionFile(response)
      }
    );

  }

  const getSubmitMissionInfo = () => {
    context.get(
      'class/getSubmitMissionInfo',
      { 
        mission_id
      },
      response => {
        console.log("getSubmitMissionInfo",response)
        setSubmitMissionInfo(response)
      }
    );
  }

  
  const getSubmitMissionInfoFile = () => {
    context.get(
      'class/getSubmitMissionInfoFile',
      { 
        mission_id
      },
      response => {
        console.log("getSubmitMissionInfoFile",response)
        setSubmitMissionInfoFile(response)
      }
    );
  }


  const getMissionStudent = () => {
    context.get(
      'class/getMissionStudent',
      { mission_id: mission_id },
      response => {
        if(response === null) {
          return false;
        }
        console.log("getMissionStudent",response)        
        setMissionStudent(response)
        const totalStudent = response.length
        setTotalStudent(totalStudent) 
        const resolveStudent = response.filter(item => item.mission_status
          !== "").length;
        setResolveStudent(resolveStudent);
        const targetItem = response.find(item => item.member === user_id);
        const missionStatusValue = targetItem?.mission_status
        ;
        setMissionStatus(missionStatusValue)
      }
    );

  }

  const getMissionArrowFunction = () => {
    context.get(
      'class/getMissionArrowFunction',
      { class_id,
        mission_id,
        sort
       },
      response => {
        console.log("getMissionArrowFunction",response)
        setPreviousSelectStatus(response.previous[0])
        setPreviousId(response.previous[1])
        setNextSelectStatus(response.next[0])
        setNextId(response.next[1])
      }
    );
  }

  const loadClickStuInfoFile = (member) => {
    context.get(
      'class/clickMissionStuInfoFile',
      { mission_id,
        member:clickStuId ? clickStuId : member },
      response => {
        console.log("clickMissionStuInfoFile",response)
        setClickStuInfoFile(response)
      }
    );
  }

  const loadClickStuInfo = (member) => {
    if(member || clickStuId){
      context.get(
        'class/clickMissionStuInfo',
        { mission_id,
          member:clickStuId ? clickStuId : member},
        response => {
          console.log("clickMissionStuInfo",response)
          setClickStuInfo(response)
        }
      );
    }

  }




  
  const handleClick = (index,member,submit_id) => {
      console.log("ddd",index)
      setClickedIndex(index);
      setClickStuId(member);
      setClickSubmitId(submit_id);
      loadClickStuInfoFile(member);
      loadClickStuInfo(member);
    
  };

  const handleMouseEnter = (index,submit) => {
    if(submit){
      setIsHovered(index + submit);  
    }else{
      setIsHovered(index);
    }
    
    
  };

  const nextClick = () => {
    props.history.replace(`/class/mission_info/${class_id}/${nextId}/${sort}`)
    setMissionId(nextId)
    setClickEvent(!clickEvent);
  }

  const previousClick = () => {
    props.history.replace(`/class/mission_info/${class_id}/${previousId}/${sort}`)
    setMissionId(previousId)
    setClickEvent(!clickEvent);
  }

  const handleMouseLeave = () => {
    setIsHovered(null);
  };

  const onOpenFile = (item) => {
    setItem(item);
    let ext = item.title.split(".")[item.title.split(".").length - 1];
    if (ext == 'mp4') {
      setShowVideoItem(true);
    } else {
      setShowFileItem(true);
    }
};

const submitDownload = (list) => {
    
  context.download(
      "GET",
      "/class/submitDownload",
      {
          id: list.join("#")
      },
      response => {
      }
  );
  
};

  const openSubmitModal = () => {
    setOpenSubmitMission(true)
  }

  const clickBack = () => {
    setClickStuInfo(null)
    setClickedIndex(null)
    setClickStuInfoFile(null)
    // setClickStuId(null)
  }

  const statusClass = (missionStatus) => {
    if (missionStatus === '반려') {
      return 'status-reject';
    } else if (missionStatus === '승인') {
      return 'status-success';
    } else if (missionStatus === '제출') {
      return  'status-submit';
    }
  }



    // 클래스 리스트 우측 메뉴 버튼, 활성화 상태에서 다른 곳 클릭시 비활성화 
    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (event.target.closest('.three-dots') === null) {
          setToggleMenu(false);
        }
      };
    
      document.addEventListener('click', handleOutsideClick);
    
      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }, []);

    const fileResize = (size) => {

      const dataSize = size;
      let formattedData = '';
      
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    
      if (dataSize === 0) return '0 Byte';
      
      const k = parseInt(Math.floor(Math.log(dataSize) / Math.log(1024)));
    
      formattedData =  Math.round(100 * (dataSize / Math.pow(1024, k))) / 100 + ' ' + sizes[k];
      return formattedData;
    }
  
  const missionDownload = (url, name) => {
    const a = document.createElement("a");
    const STORAGE_BASE_URL = 'https://kr.object.ncloudstorage.com/chewing-storage';
    a.href = STORAGE_BASE_URL+"/"+url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }


  return (
    
    <div class="mission-list-info-main-container">
      {/* <CloudContainer/> */}
      <div class="row">
      

        {/* 왼쪽 내용 */}
        <div className={user_type === "STUDENT" &&
        submitMissionInfo !== null ? "col-md-6" : "col-md-7"}>
          <div className="mission-list-info-head">
            {clickStuInfo ?
              <>
                <img src="/assets/image/icon_back_gray.png" style={{cursor:'pointer'}} alt="" onClick={()=> clickBack()}/>
                {clickStuInfo.mission_stu_name}
                <div className='mission-select-stu-info'>
                <img class="stu-profile" src={clickStuInfo.profile != null ? context.loadImage(clickStuInfo.profile) : "/assets/image/chewing_plus_logo.png"}
                              alt="" />
                  <div className='stu-info'>
                    <div className='name-status'>
                      <div className='name'>{clickStuInfo.name}</div>
                      {/* <div className='status'>{clickStuInfo.status}</div> */}
                      <div class={`status ${statusClass(clickStuInfo.status)}`}>{clickStuInfo.status}</div>
                    </div>
                    <div class='school'>{clickStuInfo.grade ? `${clickStuInfo.grade}` : "@"}학년 {clickStuInfo.classroom ? `${clickStuInfo.classroom}` : "@"}반</div>
                  </div>
                </div>
              </>
              :
              <>과제</>
            }
          </div>
              <div class="mission-list-info-time">{clickStuInfo ? clickStuInfo.create_date :missionInfo.deadline}</div>
              
              <PerfectScrollbar style={{height: '720px'}} options={{ suppressScrollX: true }}>
              
              <div class="mission-list-info-title">{clickStuInfo ? clickStuInfo.title  : missionInfo.title}</div>
              <div class="mission-list-info-content-box">
              <div class="mission-list-info-content">{clickStuInfo ? clickStuInfo?.content?.split('\n').map(item => (<p>{item}</p>)) : missionInfo?.content?.split('\n').map(item => (<p>{item}</p>))}</div>
              
          {/* } */}

          {/* 학생이 올린 파일 */}
          {clickStuInfo && clickStuInfoFile ? (

              <>
                {clickStuInfoFile.map((item, idx) => {
                  return !imgType.includes(item.url.split('.').pop().toLowerCase()) ?
                    (
                      <div class="mission-list-info-file-box ">
                        <div className="">
                          <img class="grid-item file-img" onClick={() => onOpenFile(item)} src={item.type} alt='' />
                        </div>
                        <div className="grid-item file-title" onClick={() => onOpenFile(item)} >
                          {item.file_title}
                        </div>
                        <div className="grid-item file-size">
                          {fileResize(item.size)}
                        </div>
                        <div className="grid-item img-box">
                          <img src='/assets/image/icon_mission_download.png' alt=''
                            onClick={(event)=> {
                              missionDownload(item.url, item.file_title)
                            }}
                          />
                          <img src='/assets/image/icon_mission_cloud.png' alt=''/>
                        </div>
                      </div>
                    )
                    : (
                      null
                    );
                })}
              </>
            ) : 
            // 선생이 올린 파일
            missionFile && clickedIndex === null ? (
              <>
                {missionFile.map((item, idx) => {
                  return !imgType.includes(item.url.split('.').pop().toLowerCase()) ?
                    (
                      <div class="mission-list-info-file-box ">
                        <div className="">
                          <img class="grid-item file-img" onClick={() => onOpenFile(item)} src={item.type} alt='' />
                        </div>
                        <div className="grid-item file-title"  onClick={() => onOpenFile(item)}>
                          {item.title}
                        </div>
                        <div className="grid-item file-size">
                          {fileResize(item.size)}
                        </div>
                        <div className="grid-item img-box" style={{marginLeft:'auto'}}>
                          <img src='/assets/image/icon_mission_download.png' alt=''
                            onClick={(event)=> {
                              missionDownload(item.url, item.title)
                            }}
                          />
                          {/* <img src='/assets/image/icon_mission_cloud.png' alt=''/> */}
                        </div>
                      </div>
                    )
                    : (
                      null
                    );
                })}
              </>
            ) : null
              }

             {/* 학생이 올린 이미지 */}
            {clickedIndex !== null  && clickStuInfoFile ? (
              <div class="mission-list-info-img-box">
                {clickStuInfoFile.map((item, idx) => {
                  return imgType.includes(item.url.split('.').pop().toLowerCase()) ? (
                    <div
                      class={`mission-list-info-img `}
                      key={idx}
                      onMouseEnter={() => handleMouseEnter(idx)}
                      onMouseLeave={handleMouseLeave}
                    >
                      {isHovered === idx ? (
                        <div class="on-hover" onClick={() => onOpenFile(item)}>
                          <div class="top">{item.file_title}</div>
                          <div class="bottom">
                            <button
                              class="img-container right-margin"
                              onClick={(event) => {
                                event.stopPropagation();
                                let list = [item.id];
                                submitDownload(list);
                              }}
                            >
                              <img class="download" src="/assets/image/icon_mission_download.png" alt="" />
                            </button>
                            {user_type === "STUDENT" ? 
                              <div class="img-container ">
                              <img class="cloud" src="/assets/image/icon_mission_cloud.png" alt="" />
                              </div>
                            : null}
                            
                          </div>
                        </div>
                      ) : (
                        <img src={context.loadImage(item.url)} alt="" />
                      )}
                    </div>
                  ) : null;
                })}
              </div>
            ) : missionFile && clickedIndex === null ? (
              <div class="mission-list-info-img-box">
                {missionFile.map((item, idx) => {
                  return imgType.includes(item.url.split('.').pop().toLowerCase()) ? (
                    <div
                      class="mission-list-info-img"
                      key={idx}
                      onMouseEnter={() => handleMouseEnter(idx)}
                      onMouseLeave={handleMouseLeave}
                    >
                      {isHovered === idx ? (
                        <div class="on-hover" onClick={() => onOpenFile(item)}>
                          <div class="top">{item.title}</div>
                          <div class="bottom">
                            <div
                              class="img-container right-margin"
                              onClick={(event) => {
                                missionDownload(item.url, item.title);
                              }}
                            >
                              <img class="download" src="/assets/image/icon_mission_download.png" alt="" />
                            </div>

                            {user_type === "STUDENT" ? 
                              <div class="img-container ">
                              <img class="cloud" src="/assets/image/icon_mission_cloud.png" alt="" />
                              </div>
                            : null}
                          </div>
                        </div>
                      ) : (
                        <img src={context.loadImage(item.url)} alt="" />
                      )}
                    </div>
                  ) : null;
                })}
              </div>
            ) : null}
          
              </div>
              </PerfectScrollbar>
        </div>

        <ViewModal
          show={showFileItem}
          toggle={() => setShowFileItem(!showFileItem)}
          item={item}
          onDownload={clickedIndex !== null ? (list) => submitDownload(list) : (list) => {
            

          }}
        />

        <VideoModal
          show={showVideoItem}
          toggle={() => setShowFileItem(!showFileItem)}
          item={item}
        />

        {openSubmitMission && (<MissionSubmitModal
          open={openSubmitMission}
          onClose={() => {
            setOpenSubmitMission(false);
          }}
          mission_id={mission_id}
        />)}

          {openApproveModal && (<ApproveModal
          open={openApproveModal}
          onClose={() => {
            setOpenApproveModal(false);
          }}
          submit_id={clickSubmitId}
          mission_id={mission_id}
          stu_id={clickStuId}
        />)}

        {openRejectModal && (<RejectModal
          open={openRejectModal}
          onClose={() => {
            setOpenRejectModal(false);
          }}
          submit_id={clickSubmitId}
          mission_id={mission_id}
          stu_id={clickStuId}
        />)}

        {openEditModal && (<MissionEditModal
          open={openEditModal}
          onClose={() => {
            setOpenEditModal(false);
          }}
          onUpdate={() => {
            setUpdateStatus(!updateStatus);
          }}
          mission_id={mission_id}
          class_id={missionInfo.class}
          mission_student={missionStudent}
        />)}

        {openDeleteModal && (<MissionDeleteModal
          open={openDeleteModal}
          onClose={() => {
            setOpenDeleteModal(false);
          }}
          mission_id={mission_id}
          class_id={missionInfo.class}
        />)}

        {openFinishModal && (<MissionFinishModal
          open={openFinishModal}
          onClose={() => {
            setOpenFinishModal(false);
          }}
          mission_id={mission_id}
          class_id={missionInfo.class}
        />)}

        {openEditSubmitModal && (<MissionSubmitEditModal
          open={openEditModal}
          onClose={() => {
            setOpenEditSubmitModal(false);
          }}
          mission_id={mission_id}
          class_id={missionInfo.class}
          mission_student={missionStudent}
        />)}

        {openDeleteSubmitModal && (<MissionSubmitDeleteModal
          open={openDeleteSubmitModal}
          onClose={() => {
            setOpenDeleteSubmitModal(false);
          }}
          mission_id={mission_id}
          class_id={missionInfo.class}
          mission_student={missionStudent}
        />)}



        {user_type === "STUDENT" && submitMissionInfo !== null ? null :
        <div className="col-md-1" />}
        

        {/* 오른쪽 */}
        <div className={user_type === "STUDENT" && submitMissionInfo !== null ?"col-md-6" : "col-md-4"}>
          { clickedIndex !== null  ? 
            <div class="mission-list-info-head-right submitmission" style={clickStuInfo?.status ==='반려' || clickStuInfo?.status ==='승인'?{gridTemplateColumns: 'none'}:null}>
              {clickStuInfo?.status === '반려' ? <div class="approve" onClick={()=>setOpenApproveModal(true)}> 승인 </div> : 
              clickStuInfo?.status === '승인' ? <div class="reject" onClick={()=>setOpenRejectModal(true)}> 반려</div> 
              : clickStuInfo?.status === '제출' ? <> <div class="reject" onClick={()=>setOpenRejectModal(true)}> 반려</div> <div class="approve" onClick={()=>setOpenApproveModal(true)}> 승인 </div> </> : null
            }
              
              
              
            </div>
          : 
          <div class={`mission-list-info-head-right ${user_type === "STUDENT" ? "student-right" : ""}`}>
            {/* <div> 
              {missionListCount}
            개 과제</div> */}

            <MissionInfoButton
              more={previousSelectStatus != 1}
              direction={'left'}
              user_type={user_type}
              onClick={previousClick}
            />

            <MissionInfoButton
              more={nextSelectStatus != 1}
              direction={'right'}
              user_type={user_type}
              onClick={nextClick}
            />

            {user_type !== "STUDENT" ? 
                <div class="three-dots"
                  onClick={(event) => {
                    event.stopPropagation();
                    setToggleMenu(!toggleMenu);
                    }}
                  >
                  <img src="/assets/image/icon_3dots.png" alt="" />
                  {toggleMenu && (
                    <div className="edit-del-box">
                      <button onClick={() => setOpenEditModal(true)}>수정하기</button>
                      <button onClick={() => setOpenDeleteModal(true)}>삭제하기</button>
                      <button onClick={() => setOpenFinishModal(true)}>마감하기</button>
                    </div>
                  )}
                </div> 
              : null}
            
          </div>
            }

      {user_type === "STUDENT" ? (
        submitMissionInfo !== null ?
        
        <div class="mission-list-info-submit-container">
          <div class="head">
            <div class="left">
            <div class="profile">
            <img src={rootStore.getProfile.profile != null && rootStore.getProfile.profile != "" ? context.loadImage(rootStore.getProfile.profile) : "/assets/image/chewing_plus_logo.png"}
                                    style={{ width: 50, height: 50, borderRadius: 60, objectFit: 'cover' }}
                                    alt=""
                                />
            </div>
            <div class="name">{submitMissionInfo.name}</div>
            {missionStatus? <div class={statusClass(missionStatus)}>{missionStatus}</div> : null}
            </div>
            <div class="right">
              {missionStatus === '반려'? 
                <button class="edit" onClick={()=>setOpenEditSubmitModal(true)}>수정하기</button>
              :
              <div class="three-dots"
                  onClick={(event) => {
                    event.stopPropagation();
                    setToggleMenu(!toggleMenu);
                    }}
                  >
                  <img src="/assets/image/icon_3dots.png" alt="" />
                  {toggleMenu && (
                    <div className="edit-del-box">
                      <button onClick={() => setOpenEditSubmitModal(true)}>수정하기</button>
                      <button onClick={() => setOpenDeleteSubmitModal(true)}>삭제하기</button>
                    </div>
                  )}
                </div> 
              }
              
            </div>
          </div>
          <div class="time">
          {submitMissionInfo.create_date}
          </div>
          <div class="title">
          {submitMissionInfo.title}
          </div>

          <div class="submit-content">
            {submitMissionInfo.content}
          </div>


          {/* 학생 본인이 올린 과제 확인 */}
          {submitMissionInfoFile ? (
              <>
                {submitMissionInfoFile.map((item, idx) => {
                  return !imgType.includes(item.url.split('.').pop().toLowerCase()) ?
                    (
                      <div class="mission-list-info-file-box ">
                        <div className="">
                          <img class="grid-item file-img" onClick={() => onOpenFile(item)} src={item.type} alt='' />
                        </div>
                        <div className="grid-item file-title" onClick={() => onOpenFile(item)}>
                          {item.file_title}
                        </div>
                        <div className="grid-item file-size">
                          {fileResize(item.size)}
                        </div>
                        <div className="grid-item img-box">
                          <img  src='/assets/image/icon_mission_download.png' alt=''
                            onClick={(event)=> {
                              missionDownload(item.url, item.file_title)
                            }}
                          />
                          <img src='/assets/image/icon_mission_cloud.png' alt='' onClick={(event)=> {
                            event.stopPropagation()
                          }}/>
                        </div>
                      </div>
                    )
                    : (
                      null
                    );
                })}
              </>
            ) : null}

          {submitMissionInfoFile ? (
              <div class="mission-list-info-img-box">
                {submitMissionInfoFile.map((item, idx) => {
                  return imgType.includes(item.url.split('.').pop().toLowerCase()) ?
                    (
                      <div class="mission-list-info-img"  key={idx} onMouseEnter={(event) => handleMouseEnter(idx,"submit",event)}
                      onMouseLeave={handleMouseLeave} >
                        {isHovered === idx + "submit" ?
                          <div class="on-hover" onClick={() => onOpenFile(item)}>
                            <div class="top">{item.file_title}</div>
                            <div class="bottom">
                              <div class="img-container right-margin" >
                                <img  class="download" src="/assets/image/icon_mission_download.png" onClick={(event)=> {
                                  missionDownload(item.url, item.file_title)
                              }}alt=''/>
                              </div>

                              {user_type === "STUDENT" ? 
                              <div class="img-container ">
                              <img class="cloud" src="/assets/image/icon_mission_cloud.png" alt='' onClick={(event)=> {
                                event.stopPropagation()
                              }}/>
                            </div>
                            : null}
                           
                            </div>
                          </div>
                        : <img src={context.loadImage(item.url)} alt='' />}
                      </div>
                      
                    )
                    : (
                      null
                    );
                })}
              </div>
            ) : null}

            


        </div> 
        
        
        : 

        <div class="mission-list-info-head-right submit-btn">
        <button class="mission-submit" onClick={()=>openSubmitModal()}>제출하기</button>
        </div>
        )
      :
      <div class="mission-list-student-container">          
      <div class="mission-list-student-head">
        <div class="title">학생</div>
        <span class="right">{resolveStudent ? resolveStudent : 0}명 / <span>&nbsp;{totalStudent ? totalStudent : 0}명</span></span>
      </div>
      
        <div class="mission-list-student-list-box">
        {missionStudent.map((item,idx)=> {
          const isClicked = idx === clickedIndex;

          return(
            <div 
            
              key={idx}
              className={`mission-list-student-content ${isClicked ? 'click-student' : ''} ${item.mission_status ? 'cursor' : ''}`}
              onClick={() => handleClick(idx,item.member,item.submit_id)}
            >
              <div class='mission-list-student-img'>
              <img src={item.profile != null ? context.loadImage(item.profile) : "/assets/image/chewing_plus_logo.png"}
                              alt="" />
                </div>
              <div class=''>
                <div>
                  <div class='name'>{item.name}</div>
                  <div class='school'>{item.grade ? `${item.grade}` : "@"}학년 {item.classroom ? `${item.classroom}` : "@"}반</div>
                </div>
                </div>
              
              <div class={`${statusClass(item.mission_status)}`}>{item.mission_status}</div>
  
            </div>
          )
        })}
        </div>
      
    </div>
  }


        </div>
      </div>

    </div>
    
  );
}



const AppWrap = styled.div`
  margin: 10px auto;
  position: relative;
  width: 1440px;
`;

export default withRouter(MissionListInfo);