import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import MissionCategoryButton from '../../../components/control/button/mission_category_button';
import CustomSearch from '../../../components/control/custom_input/custom_search';
import { Page_settings } from '../../../config/page_settings';
import strings from '../../../lang/strings';
import { rootStore } from '../../../mobx/store';
import MissionAddModal from '../Modals/missionAddModal';


const MissionList = (props) => {
  const [selectCategory, setSelectCategory] = useState(strings.mission_sort_list[0].name)
  const [dropdownStatus, setDropdownStatus] = useState(false);
  const [sort, setSort] = useState(0);
  const [category, setCategory] = useState(0);
  const [openAddMission, setOpenAddMission] = useState(false);
  // URL로 부터 데이터 가져오기
  const class_id = parseInt(props.match.params.class_id);
  const creator = parseInt(props.match.params.creator);
  const [missionList, setMissionList] = useState([]);
  const [missionListCount, setMissionListCount] = useState();
  const [keyword, setKeyword] = useState('');
  const [searchStatus, setSearchStatus] = useState(false);
  const context = useContext(Page_settings);
  const user_type = rootStore.getProfile?.user_type ?? ""

  useEffect(() => {
    
    loadMissionList();
    
  }, [openAddMission, category, sort])

  const loadMissionList = () => {
    if(!isNaN(class_id)){
    context.get(
      'class/getMissionList',
      {
        class_id,
        category,
        sort,
        keyword
      },
      response => {
        setMissionList(response.missionList)
        setMissionListCount(response.missionList?.length);
      }
    );
  }
}


  const handleBack = () => {
    
    props.history.push(`/class/mission_list`);
    setTimeout(()=>props.history.push(`/class/mission_list/${class_id}`),0.1);
  };


  const clickDropDown = () => {
    setDropdownStatus(!dropdownStatus)
  }

  const onClickAddMission = () => {
    setOpenAddMission(true);
  };

  const onClickMission = (missionId) => {
    props.history.push(`/class/mission_info/${class_id}/${missionId}/${sort}`)
  }
  const selectSort = (item) => {
    setSort(item.code)
    setSelectCategory(item.name)
  }
  

  const statusClass = (missionStatus) => {
    if (missionStatus === '반려') {
      return 'status-reject';
    } else if (missionStatus === '승인') {
      return 'status-success';
    } else if (missionStatus === '제출') {
      return 'status-submit';
    }
  }

  return (



    <div class="main-content mission-list-container">
      {/* <CloudContainer/> */}

      {
        <CustomSearch
          placeholder={"과제 검색"}
          inputType={'text'}
          value={keyword}
          onChange={(v) => {
            setKeyword(v)
          }}
          onSearch={() => {
            if (keyword == "")setSearchStatus(false)
              loadMissionList(class_id,category,sort,keyword)
              setSearchStatus(true);
            
          }}
        />

      }
      {
        searchStatus &&
        <button className="common-button cafe-search" onClick={handleBack}>
          <img src={'/assets/image/btn_back.png'} alt="" />
        </button>
      }



      {
        searchStatus &&
        <button className="common-button btn-reset " onClick={() => setKeyword('')}>
          <img src={'/assets/image/btn_reset.png'} alt="" />
        </button>
      }
      {/* <AppWrap> */}
      <div class="mission-head-container">
      <div class="class-head">
        <div class="class-myClass">
          과제 리스트
        </div>
        {user_type === "STUDENT" ? null :
          <Button onClick={() => onClickAddMission()}>+ 과제 추가</Button>
        }

      </div>
      
      <div class="mission-head">
        <div class="mission-head">
          <div class="btn-grid">
            {user_type === "STUDENT" ?
              strings.mission_categroy_student_list.map((item, idx) => {
                return (
                  <MissionCategoryButton
                    key={idx}
                    selected={category == item.code}
                    onClick={() => setCategory(item.code)}
                    value={item.name}
                    
                  />
                )
              })
              :
              strings.mission_categroy_teacher_list.map((item, idx) => {
                return (
                  <MissionCategoryButton
                    key={idx}
                    selected={category == item.code}
                    onClick={() => setCategory(item.code)}
                    value={item.name}
                  />
                )
              })
            }
          </div>
        </div>
      </div>
      <div class="dropdown-box cursor" onClick={() => clickDropDown()}>
          
            {selectCategory}
            {dropdownStatus ? <img src={"/assets/image/icon_arrow_down_gray1.png"} alt="" /> : <img src={"/assets/image/icon_arrow_up_gray.png"} alt="" />}
          
          {dropdownStatus ? 
          <div className='dropdown-item-box'>
          {strings.mission_sort_list.map((item, index) => {
            return (
              <div className='dropdown-box-item' onClick={() => selectSort(item)} >{item.name}</div>
            )
          })}
        </div>:
        null  
        }
          
      </div>
      <div className='list-count'>
        총 <span className='count'>{missionListCount}</span>개
      </div>
      
      </div>

      {openAddMission && (<MissionAddModal
        open={openAddMission}
        onClose={() => {
          setOpenAddMission(false);
        }}
        class_id={class_id}
        creator={creator}
      />)}





      {/* 미션 리스트 */}

      {missionList !== null && missionList.length > 0 ?
        <div class="mission-content">
          {missionList.map((item, idx) => {
            return (

              <div class="class-info-mission-container" onClick={() => onClickMission(item.id, item.status)}>
                <div class="class-info-mission-content-box" style={{justifyContent:'space-between'}}>
                  <div class="class-info-mission-content-box-top">
                    <div class="left">{item.title}</div>
                    <div className={`right  ${item.deadline === '마감' ? "end" : ""}`}>
                      {item.deadline}
                    </div>
                  </div>
                  <div class="class-info-mission-content-box-middle">
                    {item.content.split('\n').map((line) => <p>{line}</p>)}
                  </div>
                  <div class="class-info-mission-content-box-bottom">
                    {user_type === "STUDENT" ?
                      <div class={`left ${statusClass(item.status)}`}>{item.status}</div>
                      :
                      <>
                        <div class="left">{item.resolved_students}&nbsp;/</div>
                        <div class="right">&nbsp;{item.total_students}명</div>
                      </>}
                  </div>
                </div>
              </div>

            )
          })
          }
        </div>
        :

        <div class="class-nothing-student">
          <div className='nothing-container'>
            <img class="class-nothing-img" src="/assets/image/img_gray_chuchu.png" alt="" />
            <div>아직 과제가 없습니다.<br />지금 바로 과제를 등록해보세요!</div>
          </div>
        </div>


      }

    {/* </AppWrap> */}
    </div>

  );
}

const Button = styled.button`
  font-size: 14px;
  padding: 8px 15px;
  border: none;
  background-color: #0F84F4;
  border-radius: 10px;
  color: white;
  font-weight: 500;
  cursor: pointer;
  // &:hover {
  //   background-color: #fac2be;
  // }
`;

const DropDownBox = styled.ul`
  display: block;
  position: absolute;
  margin: 0 auto;
  padding: 0px 5px;
  width: 94%;
  background-color: white;
  border: 1px solid #DFE1E8;
  border-radius: 8px;
  box-shadow: 0 10px 10px rgb(0, 0, 0, 0.3);
  list-style-type: none;
  z-index: 3;
  max-height: 200px; /* 최대 높이 설정 */
  overflow: auto; /* 스크롤바 표시 설정 */
`

const DropDownItem = styled.li`
  text-align: start;
  padding: 0px 5px;
  margin: 10px 0px;
`

export default withRouter(MissionList);