import React, {useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import DatePicker from "react-datepicker";
import moment from "moment";
import { Page_settings } from "../../../../config/page_settings";
import strings from "../../../../lang/strings";
import { colorList, checkIconList } from '../../../../config/const';
import { Store } from 'react-notifications-component';

interface PlannerPropI {
    member: number;
    class_id: number;
}

const DataLabModalPlanner = (props: PlannerPropI) => {
    const { member, class_id } = props;
    const context: any = useContext(Page_settings);
    
    const [startDate, setStartDate] = useState(new Date());

    interface PlannerTagI {
        id: number;
        planner_date: string;
        member: number;
        title: string;
        color: string;
        create_date: string;
        start_date: string;
        order: 0;
        todo_list: PlannerToDoI[];
    }

    interface PlannerToDoI {
        id: number;
        tag: number;
        title: string;
        create_date: string;
        status: string;
    }
    const [tagsOfMonth, setTagsOfMonth] = useState<PlannerTagI[]>([]);

    useEffect(() => {
        context.get(
            'planner/getCalendarEventList',
            {
                userId: member,
                classId: class_id,
                from: moment(startDate).startOf('month').format('YYYY-MM-DD'),
                to: moment(startDate).endOf('month').format('YYYY-MM-DD')
            },
            response => {
                setTagsOfMonth(response.list.map((item) => {
                    return {...item, todo_list:[]}
                }));
            }
        );
    }, [startDate]);

    const getDataIndex = (date) => {
        let idx = -1;
        for (let i = 0; i < tagsOfMonth.length; i++) {
            if (tagsOfMonth[i].start_date == moment(date).format('YYYY-MM-DD')) {
                idx = i;
                break;
            }
        }
        return idx;
    }

    const [selDate, setSelDate] = useState(new Date());
    const [selDateTag, setSelDateTag] = useState<PlannerTagI[]>([]);

    useEffect(() => {
        context.get(
            'planner/getTagList',
            {
                userId: member,
                classId: class_id,
                planner_date: moment(selDate).format('YYYY-MM-DD')
            },
            response => {
                setSelDateTag(response.list.map((item) => {
                    return item;
                }));
            }
        )
    }, [selDate]);

    useEffect(() => {
        context.get(
            'planner/getItem',{
                planner_date: moment(selDate).format('YYYY-MM-DD'),
                member: member,
            },
            response => {
                if (response && response.planner_info && response.planner_info.feedback) {
                    setFeedback(response.planner_info.feedback);
                } else {
                    setFeedback('');
                }
            }
        )
    }, [selDate])

    const isEmptyObj = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    const weekdays = [
        strings.weekdays.monday,
        strings.weekdays.tuesday,
        strings.weekdays.wednesday,
        strings.weekdays.thursday,
        strings.weekdays.friday,
        strings.weekdays.saturday
    ];

    const [feedback, setFeedback] = useState('');

    const saveFeedback = () => {
        context.post(
            'planner/saveFeedback',
            {
                member,
                planner_date: moment(selDate).format('YYYY-MM-DD'),
                feedback: feedback
            },
            response => {
                addNotification('success', '', strings.success_to_save)
            }
        )
    }

    const addNotification = (notificationType, notificationTitle, notificationMessage) => {
        Store.addNotification({
            title: notificationTitle,
            message: notificationMessage,
            type: notificationType,
            insert: 'top',
            container: 'top-right',
            dismiss: {
                duration: 1000,
            }
        });
    }


    return (
    <Container>
        <div>
            <div className={'calendar'}>
                <DatePicker
                    inline
                    selected={selDate}
                    onChange={(date) => setSelDate(date)}
                    showNeighboringMonth={false}
                    renderDayContents={(dayOfMonth, date) => {
                        let className = '';
                        let planIndex = getDataIndex(date);
                        if (moment(date).format('YYYY-MM-DD') == moment(selDate).format('YYYY-MM-DD')) {
                            className = 'day-selected';
                        } else if (planIndex < 0) {
                            className = 'day-disable';
                        } else {
                            className = 'day-active';
                        }
                        let event = tagsOfMonth.filter((item) => item.planner_date == moment(date).format('YYYY-MM-DD'));
                        return (
                            <div className={'day-area'}>
                                {
                                    className == 'day-active' &&
                                    <div className={className}>
                                        {dayOfMonth}
                                        {
                                            event.length > 2 &&
                                            <div>
                                                <div className="event-area">
                                                    <div
                                                        className="event-day"
                                                        style={{
                                                            display: isEmptyObj(event[0]) ? 'none' : "inline",
                                                            backgroundColor: isEmptyObj(event[0]) ? "transparent" : event[0].color
                                                        }}
                                                    />
                                                    <div
                                                        className="event-day"
                                                        style={{
                                                            display: isEmptyObj(event[1]) ? 'none' : "inline",
                                                            backgroundColor: isEmptyObj(event[1]) ? "transparent" : event[1].color,
                                                            marginLeft: 2
                                                        }}
                                                    />
                                                </div>
                                                <div className="event-area" style={{ marginTop: 2 }}>
                                                    <div
                                                        className="event-day"
                                                        style={{
                                                            display: isEmptyObj(event[2]) ? 'none' : "inline",
                                                            backgroundColor: isEmptyObj(event[2]) ? "transparent" : event[2].color
                                                        }}
                                                    />
                                                    <div
                                                        className="event-day"
                                                        style={{
                                                            display: isEmptyObj(event[3]) ? 'none' : "inline",
                                                            backgroundColor: isEmptyObj(event[3]) ? "transparent" : event[3].color,
                                                            marginLeft: 2
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            event.length < 3 &&
                                            <div className="event-area">
                                                <div
                                                    className="event-day"
                                                    style={{
                                                        display: isEmptyObj(event[0]) ? 'none' : "inline",
                                                        backgroundColor: isEmptyObj(event[0]) ? "transparent" : event[0].color
                                                    }}
                                                />
                                                <div
                                                    className="event-day"
                                                    style={{
                                                        display: isEmptyObj(event[1]) ? 'none' : "inline",
                                                        backgroundColor: isEmptyObj(event[1]) ? "transparent" : event[1].color,
                                                        marginLeft: 2
                                                    }}
                                                />
                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    className != 'day-active' &&
                                    <div className={className}>{dayOfMonth}</div>
                                }
                            </div>
                        )
                    }}
                    renderCustomHeader={(params => {
                        return (
                            <div style={{ width: '100%' }}>
                                <div className="calendar-header">
                                    <button className={'btn month-btn'} onClick={() => {
                                        setStartDate(new Date(moment(startDate).subtract(1, 'months').format('YYYY-MM-DD')));
                                        params.decreaseMonth();
                                    }}>
                                        <img className="ic_header_home" style={{ width: 12, height: 15 }}
                                            src={"/assets/image/icon_calendar_prev.png"} />
                                    </button>
                                    <div style={{ flex: 1 }}>
                                        <div className={"header-month-text"}>
                                            {(params.monthDate.getMonth() + 1).toString() + strings.common.m}
                                        </div>
                                        <div className={"header-year-text"}>
                                            {(params.monthDate.getFullYear()).toString()}
                                        </div>
                                    </div>
                                    <button className={'btn month-btn'} onClick={() => {
                                        setStartDate(new Date(moment(startDate).add(1, 'months').format('YYYY-MM-DD')));
                                        params.increaseMonth();
                                    }}>
                                        <img className="ic_header_home" style={{ width: 12, height: 15 }}
                                            src={"/assets/image/icon_calendar_next.png"} />
                                    </button>
                                </div>
                                <div className="weekDay-area d-flex mt-2">
                                    <div
                                        className={selDate.getDay() == 0 ? 'weekday-select' : 'weekday-normal'}>{strings.weekdays.sunday}</div>
                                    {
                                        weekdays.map((item, idx) =>
                                            <div key={idx}
                                                className={selDate.getDay() == idx + 1 ? 'weekday-select weekDay-space' : 'weekday-normal weekDay-space'}>{item}</div>
                                        )
                                    }
                                </div>
                                <div className="divider mt-1" />
                            </div>
                        )
                    })}
                />    
            </div>
            <FeedBackContainer>
                <textarea placeholder={strings.input_feedback} style={{width: '100%', height: '100%', border: 'none', fontSize: 15, color: "#0f84f4"}} 
                    onChange={(e) => setFeedback(e.target.value)} value={feedback} onBlur={() => saveFeedback()}
                />
            </FeedBackContainer>
        </div>
        <ToDoListContainer>
                {selDateTag.length == 0 && 
                    <div style={{paddingTop: 140, justifyContent: 'center'}}>
                        <ToDoImageWrapper>
                            <img src={'/assets/image/img_empty_todo%20list.png'}/>
                        </ToDoImageWrapper>
                        <ToDoHint>해당 날짜의 투두 리스트가 존재하지 않습니다</ToDoHint>
                    </div>}

                {
                    selDateTag.map((item, idx) => {
                        return (
                                <>
                                <TagContainer bgColor={item.color} idx={idx} >{item.title}</TagContainer>
                                {
                                    item.todo_list.map((todo, idx) => {
                                        return (
                                            <ToDoItemContainer key={idx}>
                                                <img src={todo.status === 'Y' ? 
                                                    checkIconList[colorList.indexOf(item.color)] : 
                                                    "/assets/image/icon_todo_check_normal_gray.png"} style={{ width: 15, height: 15 }} />
                                                <span style={{marginLeft: 10}}>{todo.title}</span>
                                            </ToDoItemContainer>
                                        )
                                    })
                                }
                                </>
                        )
                    })
                }
        </ToDoListContainer>
    </Container>
    );
}

export default DataLabModalPlanner;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const FeedBackContainer = styled.div`
    width: 400px;
    height: 180px;
    border-radius: 10px;
    border: #0f84f4 1px solid;
    background-color: #e2f1ff;
    padding: 20px;
    bottom: 20px;
    right: 20px;
    margin-top: 50px;
    outline: none;
    line-height: 20px; 
`;

interface ToDoListContainerProps {
    width?: string;
};

const ToDoListContainer = styled.div<ToDoListContainerProps>`
    height: 630px;
    width: ${(props) => props.width || '100%'};
    box-shadow: 1px 2px 5px 2px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 20px 30px;
    position: relative;
    background-color: white;
    margin-left: 30px;
    overflow-y: scroll;
`;

interface TagContainerProps {
    bgColor?: string;
    idx: number;
};

const TagContainer = styled.span<TagContainerProps>`
    height: 34px;
    border-radius: 4px;
    font-size: 15px;
    line-height: 15px;
    color: white;
    margin-top: ${(props) => props.idx == 0 ? '0px' : '20px'};
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: ${(props) => props.bgColor || '#FF0000'};
    display: inline-flex;
    flex-direction: row;
    align-items: center;
`;

const ToDoItemContainer = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 10px;
    width: 100%;
    min-height: 25px;
    font-weight: 500;
`;

const ToDoImageWrapper = styled.div`
    width: 110px;
    height: 95px;
    display: block;
    margin: auto;
`;

const ToDoHint = styled.div`
    margin-top: 20px;
    font-size: 17px;
    color: #adadad;
    white-space: pre-line;
    text-align: center;
`;