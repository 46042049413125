import React from "react";
import { IconType } from "../../type/icon_type";

const IconClock = (props: IconType) => {
    const { color="#73787E", width="24", height="24", style } = props;
    
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path d="M12 7.2V12L15.2 13.6M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z" stroke={color} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    );
}

export default IconClock;