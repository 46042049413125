import React, { useContext, useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from "styled-components";
import strings from "../../../lang/strings";
import StudentSearch from "../customSearch/studentSearch";
import { Page_settings } from '../../../config/page_settings';
import {rootStore} from "../../../mobx/store";
import { useHistory } from 'react-router-dom';

function  MissionDeleteModal({ onClose, mission_id, class_id}, props) {
  const [wholeTextArray,setWholeTextArray] = useState([]);

  const history = useHistory();
  const context = useContext(Page_settings);
  

  const handleClose = () => {    
    onClose?.();
  };

  const deleteMission = () => {
    context.post(
      'class/deleteMission',
      { 
        mission_id : mission_id
      },
      response => {
        history.push(`/class/mission_list/${class_id}`);
      }
    );
  }


  return (
      <Overlay>
        <ModalWrap>
          <div className='deleteModal-container'>
            <div className='title'>삭제</div>
            <div className='content'>정말로 삭제하시겠습니까?</div>
            <div className='btn-box'>
              <button onClick={()=>handleClose()} className='cancel-btn'>취소</button>
              <button onClick={()=>deleteMission()} className='delete-btn'>삭제</button>
            </div>
          </div>
        </ModalWrap>
      </Overlay>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top\: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const ModalWrap = styled.div`
  width: 326px;
  height: 160px;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
`;

const CloseButton = styled.div`
  float: right;
  width: 40px;
  height: 40px;
  margin: 20px;
  cursor: pointer;
  i {
    color: #5d5d5d;
    font-size: 30px;
  }
`;

const Contents = styled.div`
  margin: 50px 30px;
  h1 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 60px;
  }
  img {
    margin-top: 60px;
    width: 300px;
  }
`;
const Button = styled.button`
  cursor: pointer;
  width: 100%;
  background-color: #DFE3E8;
  color: #ffffff;
  border-radius: 5px;
  border:0px;
  padding: 10px;
  flex:2;
  margin-left: 10px;
  &:hover {
    background-color: #898989;
  }
`;

const DropDownBox = styled.ul`
  display: block;
  position: absolute;
  margin: 0 auto;
  padding: 0px 5px;
  width: 71.5%;
  background-color: white;
  border: 1px solid #DFE1E8;
  border-radius: 8px;
  box-shadow: 0 10px 10px rgb(0, 0, 0, 0.3);
  list-style-type: none;
  z-index: 3;
  max-height: 200px; /* 최대 높이 설정 */
  overflow: auto; /* 스크롤바 표시 설정 */
`

const DropDownItem = styled.li`
  text-align: start;
  padding: 0px 5px;
  margin: 10px 0px;
`

export default MissionDeleteModal;