    import React, { useState } from 'react';
    import styled from 'styled-components';
    import colors from '../design/colors';
    import fonts from '../design/typography';
import { UseControllerProps } from 'react-hook-form';

    interface ITextFieldProps {
        value: string;
        onChange?: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void;
        type?: string;
        disabled?: boolean;
        error?: boolean;
        size?: 'small' | 'medium';
        width?: string;
        height?: string;
        placeholder?: string;
        helperText?: string;
        complete?: boolean;
        onBlur?: () => void;
        textAlign?: string;
    }

    const TextField = (props: ITextFieldProps) => {
        const {value, onChange, disabled, error, size, width, placeholder, type, helperText, complete, onBlur, height, textAlign} = props;
        const [isFocused, setIsFocused] = useState(false);

        return (
            <div style={{width: width?? "100%", position: 'relative', height: height}}>
                {type === "textarea" ? (
                    <Textarea 
                    value={value}
                    onChange={onChange as (e: React.ChangeEvent<HTMLTextAreaElement>) => void}
                    disabled={disabled} 
                    error={error}
                    size={size}
                    placeholder={placeholder}
                    complete={complete}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => {
                    setIsFocused(false);
                    if (onBlur) {
                        onBlur();
                    }
                }}
                isFocused={isFocused}
                height={height}
                hasHelperText={helperText ? true : false}
                textAlign={textAlign}
                />) : (
                    <Input type={type ?? "text"} 
                    value={value}
                    onChange={onChange as (e: React.ChangeEvent<HTMLInputElement>) => void} 
                    disabled={disabled} 
                    error={error}
                    size={size}
                    placeholder={placeholder}
                    complete={complete}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => {
                    setIsFocused(false);
                    if (onBlur) {
                        onBlur();
                    }
                }}
                isFocused={isFocused}
                hasHelperText={helperText ? true : false}
                textAlign={textAlign}
                />
                )}
                {helperText && <span style={{color: error ? colors.warning : isFocused ? colors.blue600 : colors.gray400, fontSize: fonts.detail2Regular.fontSize, fontFamily: fonts.detail2Regular.fontFamily, lineHeight: fonts.detail2Regular.lineHeight}}>{helperText}</span>
                }
            </div>
        )
    }

    export default TextField;

    const Input = styled.input<{size?: 'small' | 'medium', error?: boolean, isFocused?: boolean, complete?: boolean, hasHelperText?: boolean, textAlign?: string}>`
        height: 48px;
        width: 100%;
        border-radius: 8px;
        
        padding: 8px 16px;
        gap: 10px;
        font-size: ${props => props.size === 'small' ? fonts.label4Medium.fontSize : fonts.body3Regular.fontSize};
        font-family: ${props => props.size === 'small' ? fonts.label4Medium.fontFamily : fonts.body3Regular.fontFamily};
        line-height: ${props => props.size === 'small' ? fonts.label4Medium.lineHeight : fonts.body3Regular.lineHeight};
        color: ${colors.black};
        
        background-color: ${props => props.complete && !props.error ? colors.gray50 : colors.white};
        text-align: ${props => props.textAlign ?? "left"};
        border: ${props => props.error ? `1px solid ${colors.warning}` : props.isFocused ? `1px solid ${colors.blue600}` : props.complete ? "none" : `1px solid ${colors.gray100}`};
        
        &:focus {
            background-color: ${colors.white};
        }

        &:placeholder {
            color: ${colors.gray500};
            font-family: ${fonts.label4Medium.fontFamily};
            font-size: ${fonts.label4Medium.fontSize};
            line-height: ${fonts.label4Medium.lineHeight};
        }

        &:disabled {
            background-color: ${colors.gray100};
            color: ${colors.gray400};
            border: none;
        }
        ${props => props.hasHelperText && `
            margin-bottom: 12px;
        `}
    `;

    const Textarea = styled.textarea<{size?: 'small' | 'medium', error?: boolean, isFocused?: boolean, complete?: boolean, hasHelperText?: boolean, height?: string, textAlign?: string}>`
        height: ${props => props.height ?? "48px"};
        width: 100%;
        border-radius: 8px;
        padding: 8px 16px;
        gap: 10px;
        font-size: ${props => props.size === 'small' ? fonts.body3Regular.fontSize : fonts.label4Medium.fontSize};
        font-family: ${props => props.size === 'small' ? fonts.body3Regular.fontFamily : fonts.label4Medium.fontFamily};
        line-height: ${props => props.size === 'small' ? fonts.body3Regular.lineHeight : fonts.label4Medium.lineHeight};
        color: ${colors.black};
        
        background-color: ${props => props.complete && !props.error ? colors.gray50 : colors.white};
        text-align: ${props => props.textAlign ?? "left"};
        border: ${props => props.error ? `1px solid ${colors.warning}` : props.isFocused ? `1px solid ${colors.blue600}` : props.complete ? "none" : `1px solid ${colors.gray100}`};
        
        &:focus {
            background-color: ${colors.white};
        }

        &:placeholder {
            color: ${colors.gray500};
            font-family: ${fonts.label4Medium.fontFamily};
            font-size: ${fonts.label4Medium.fontSize};
            line-height: ${fonts.label4Medium.lineHeight};
        }

        &:disabled {
            background-color: ${colors.gray50};
            color: ${colors.gray900};
            border: none;
        }

        ${props => props.hasHelperText && `
            margin-bottom: 12px;
        `}
    `;

