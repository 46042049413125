import React, { useContext, useEffect, useState } from "react";
import { Page_settings } from "../../config/page_settings";
import strings from "../../lang/strings";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import moment from "moment";
import ReportProgressBar from "../../components/control/progress_bar/report_progress_bar";
import { barColorList, emoticonList } from "../../config/const";
import { Doughnut, Line, Bar } from 'react-chartjs-2';
import ProgressBar from "../../components/control/progress_bar/progress_bar";
import { dateForm, getDaysFromStartEnd, getDecimal, getTimeFromSecond } from "../../helper/common";
import PerfectScrollbar from "react-perfect-scrollbar";
import { withRouter } from "react-router-dom";
import { rootStore } from "../../mobx/store";
import { Store } from "react-notifications-component";
import Cloud from "./cloud";
import CloudContainer from "./cloud_container";
import DropdownBoot from 'react-bootstrap/Dropdown';

const Report = (props) => {

    const [periodOpen, setPeriodOpen] = useState(false);
    const context = useContext(Page_settings);
    // const [period, setPeriod] = useState(-1)
    const [period, setPeriod] = useState(-1)
    const [yearSelectOpen, setYearSelectOpen] = useState(false);
    const [monthSelectOpen, setMonthSelectOpen] = useState(false);
    const [daySelectOpen, setDaySelectOpen] = useState(false);
    const [yearList, setYearList] = useState([]);
    // const [year, setYear] = useState(-1);
    const [year, setYear] = useState(yearList.indexOf(moment(new Date()).year().toString() + strings._y));
    const [monthList, setMonthList] = useState([]);
    const [month, setMonth] = useState(-1);
    const [dayList, setDayList] = useState([]);
    const [day, setDay] = useState(-1);
    const [currentPeriod, setCurrentPeriod] = useState("");
    const [goalAchieve, setGoalAchieve] = useState(0);
    const [goalDone, setGoalDone] = useState(0);
    const [goalTotal, setGoalTotal] = useState(0);
    const [seriesTotal, setSeriesTotal] = useState([]);
    const [seriesReal, setSeriesReal] = useState([]);
    const [up3, setUp3] = useState([]);
    const [attendance, setAttendance] = useState(0);
    const [down3, setDown3] = useState([]);
    const [studyCafe, setStudyCafe] = useState(0);
    const [cabinet, setCabinet] = useState(0);
    const [nothing, setNothing] = useState(0)
    const [realTimeYesterday, setRealTimeYesterday] = useState(0);
    const [totalTimeYesterday, setTotalTimeYesterday] = useState(0);
    const [realTimeToday, setRealTimeToday] = useState(0);
    const [totalTimeToday, setTotalTimeToday] = useState(0);
    const [goalAchYesterday, setGoalAchYesterday] = useState(0);
    const [goalAchToday, setGoalAchToday] = useState(0);
    const [efficiencyYesterday, setEfficiencyYesterday] = useState(0);
    const [efficiencyToday, setEfficiencyToday] = useState(0);
    const [efficiency, setEfficiency] = useState(0);
    const [missionYesterday, setMissionYesterday] = useState(0);
    const [missionTotalYesterday, setMissionTotalYesterday] = useState(0);
    const [missionToday, setMissionToday] = useState(0);
    const [missionTotalToday, setMissionTotalToday] = useState(0);
    const [statusId, setStatusId] = useState(0);
    const [usedCloud, setUsedCloud] = useState(0);
    const [totalCloud, setTotalCloud] = useState(0);
    const timeLine = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
    const monthLine = ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"];
    const [weekLine, setWeekLine] = useState([]);
    const [premium, setPremium] = useState(0);
    const [planGraphData,setPlanGraphData] = useState([]);

    const userId = rootStore.getProfile.id;

    //클래스 관련 
    const [missionData, setMissionData] = useState();
    const [missionAchieve, setMissionAchieve] = useState(0);
    const [classInfo, setClassInfo] = useState([]);
    const [classList, setClassList] = useState([]);
    const [dropdownStatus, setDropdownStatus] = useState(false); // 클래스 리스트 드랍 박스 on / off
    const [selectedClass, setSelectedClass] = useState(0); //선택한 클래스 id
    const [changeClass, setChangeClass] = useState(false);
    const user_name = rootStore.getProfile?.name ?? ""

    // 시험데이터 관련 
    const [testData, setTestData] = useState();



    const lineChart = {
        data: {
            labels: period == 2 ? monthLine : (period == 1 ? weekLine : timeLine),
            datasets: [{
                label: strings.study_time,
                borderColor: '#0f84f4',
                backgroundColor: '#0f84f4',
                pointRadius: 1,
                data: seriesTotal,
                pointHoverBackgroundColor: "rgba(231,243,254,0.3)",
                pointHoverRadius: 10,
                fill: false
            },
            ]
        },
        options: {
            plugins: {
                legend: {
                    display: true,
                    labels: {
                        font: {
                            size: 15
                        },
                        boxWidth: 11,
                        boxHeight: 2,
                        textAlign: "center",
                    }
                },
                tooltip: {
                    enabled: true,
                    position: 'nearest',
                    callbacks: {
                        label: function (context) {
                            let data = context.dataset.data;
                            let label = context.dataset.label;
                            let index = context.dataIndex;
                            return label + " " + getTimeFromSecond(data[index]);
                        }
                    }
                }
            },
            interaction: {
                mode: "nearest",
                intersect: false,
                axis: "x"
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                y: {
                    beginAtZero: true,
                    min: 0,
                    grid: {
                        display: false
                    },
                    ticks: {
                        callback: function (value, index, values) {
                            return getTimeFromSecond(value);
                        }
                    }
                },
                x: {
                    grid: {
                        display: false
                    }
                }
            }
        }
    }

    const Chart = {
        type: 'bar',
        data: {
            // labels: period == 2 ? monthLine : (period == 1 ? weekLine : timeLine),
            labels: monthLine,
            datasets: [{
                data: planGraphData, // 데이터 배열
                backgroundColor: (context) => {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;
    
                    if (!chartArea) {
                        // This case happens on initial chart load
                        return null;
                    }
                    const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
                    gradient.addColorStop(0, 'rgba(20, 109, 226, 1)');
                    gradient.addColorStop(1, 'rgba(11, 60, 125, 1)');
                    return gradient;
                },
                border: 'unset',
                borderWidth: 1,
                borderRadius: 5, // 둥근 모서리 설정
            }]
        },
        options: {
            plugins: {
                legend: {
                    display: false // 범례 숨기기
                },
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            return `${context.dataset.label}: ${context.raw}`; // 툴팁 포맷
                        }
                    }
                }
            },
            scales: {
                y: {
                    beginAtZero: true,
                    min: 0, // Y축 최소값 설정
                    max: 100, // Y축 최대값 설정
                    ticks: {
                        stepSize: 25, // Y축 눈금 간격
                        callback: function (value) {
                            if (value === 100 || value === 75 || value === 50 || value === 25 || value === 0) {
                                return value; // 100, 75, 50, 25, 0 값만 표시
                            }
                        },
                        color: '#333', // Y축 눈금 텍스트 색상
                    },
                    grid: {
                        color: '#fff', // Y축 그리드 라인 색상
                        lineWidth: 3, // Y축 그리드 라인의 두께를 3으로 설정
                        borderWidth: 0
                    }
                },
                x: {
                    ticks: {
                        color: '#333', // X축 눈금 텍스트 색상
                    },
                    grid: {
                        display: false, // X축 그리드 라인 숨기기
                        borderColor: '#000',
                        borderWidth: 2,
                    }
                }
            },
            layout: {
                padding: {
                    top: 30,
                    right: 0,
                    bottom: 0,
                    left: 20
                }
            }
        }
    };
    

    const doughnutChart = {
        data: {
            labels: [strings.study, strings.menu.clinic, strings.menu.cabinet],
            datasets: [{
                data: [attendance, studyCafe, cabinet, nothing],
                backgroundColor: ['#0f84f4', '#04c886', '#ffeb36', '#dadada'],
                borderColor: ['#0f84f4', '#04c886', '#ffeb36', '#dadada'],
                borderWidth: 2,
            }]
        },
        options: {
            responsive: true,
            maintainAspectRatio: true,
            cutout: "90%",
            plugins: {
                legend: {
                    display: false
                },
            }
        }
    }
    const goalChart = {
        data: {
            datasets: [{
                data: [missionAchieve, 100 - missionAchieve],
                backgroundColor: ['#146DE3', '#dadada'],
                borderColor: ['#146DE3', '#dadada'],
                borderWidth: 2,
            }]
        },
        options: {
            responsive: true,
            maintainAspectRatio: true,
            cutout: "70%",
            rotation: 270,
            circumference: 180,
            plugins: {
                legend: {
                    display: false
                },
            }
        }

    }

    useEffect(() => {
        context.setHeaderType(2);
        context.handleSetPlannerPageHeader(true);
        getYearList();
        getMonthList();
        getDayList(moment(new Date()).daysInMonth());
        // getPlannerData(new Date(), new Date());
        // getMissionData(new Date(), new Date());
        // getTestData("2024-01-01", "2024-12-31");
        getClassList();
        // getCompareData("HOUR");
        return () => {
            context.setHeaderType(1);
            context.handleSetPlannerPageHeader(false);
        }
    }, []);

    useEffect(() => {
        getPlannerData("MONTH", "2024-01-01", "2024-12-31");
        getMissionData("2024-01-01", "2024-12-31");
        getTestData("2024-01-01", "2024-12-31");
        if (selectedClass !== 0) {
            const from = "2024-01-01"
            const to = "2024-12-31" // 실제 종료 날짜 설정
            getMissionData(from, to);
        }
    }, [selectedClass])

    // useEffect(() => {
    //     setPremium(rootStore.getProfile.premium ?? 0)
    //     getCloudDate()
    // }, [rootStore.getPremium]);

    useEffect(() => {
        if (period == 0) {
            // getCompareData("HOUR")
            // getPlannerData(new Date, new Date);
            // getMissionData(new Date, new Date);
        } else if (period == 1) {
            // getCompareData("DAY");
            // getPlannerData(moment(new Date).startOf("week").toDate(), new Date)
            // getMissionData(moment(new Date).startOf("week").toDate(), new Date)
        } else if (period == 2) {
            // getCompareData("MONTH")
            // getPlannerData(moment(new Date).startOf("month").toDate(), new Date)
            // getMissionData(moment(new Date).startOf("month").toDate(), new Date)
        }
    }, [period]);

    useEffect(() => {
        if (efficiency < 30) {
            setStatusId(0)
        } else if (efficiency <= 50) {
            setStatusId(1)
        } else if (efficiency <= 70) {
            setStatusId(2)
        } else if (efficiency <= 90) {
            setStatusId(3)
        } else if (efficiency <= 100) {
            setStatusId(4)
        }

    }, [efficiency])

    // const getCloudDate = () => {
    //     context.get(
    //         "datalab/getCloudData",
    //         {},
    //         response => {
    //             setTotalCloud(Number(response.total_limit));
    //             setUsedCloud(Number(response.used_capacity) < 0 ? 0 : Number(response.used_capacity))
    //         }
    //     )
    // }

    const getToday = () => {
        let date = new Date()
        setCurrentPeriod(moment(date).year().toString() + strings._y + " " + dateForm(moment(date).month() + 1) + strings._m + " " + dateForm(moment(date).date()) + strings._d);
    }

    const getChartData = (mode, from, to) => {
        context.get(
            "datalab/getChartData",
            {
                userId: userId,
                mode: mode,
                from: moment(from).format("YYYY-MM-DD"),
                to: moment(to).format("YYYY-MM-DD")
            },
            response => {
                console.log("dd", response);
                let total = [];
                let real = [];
                let totalSum = 0;
                let realSum = 0;
                response.list.forEach(item => {
                    total.push(parseInt(item.total_diff));
                    totalSum += parseInt(item.total_diff);
                    real.push(parseInt(item.real_diff));
                    realSum += parseInt(item.real_diff);
                });
                setSeriesTotal(total);
                setSeriesReal(real);
                setEfficiency(totalSum != 0 ? (realSum / totalSum) * 100 : 0);
            }
        );
    }

    const getPlannerData = (mode, from, to) => {
        context.get(
            "datalab/getPlannerData",
            {
                userId: userId,
                mode: mode,
                from: moment(from).format("YYYY-MM-DD"),
                to: moment(to).format("YYYY-MM-DD")
            },
            response => {
                setGoalDone(response.todo.complete_count);
                setGoalTotal(response.todo.total_count);
                console.log("dd",response.graph)
                

                let total = [];
                response.graph.forEach(item => {
                    let completeCount = parseInt(item.complete_count);
                    let totalCount = parseInt(item.total_count);

                    if (totalCount === 0) {
                        total.push(0); // totalCount가 0일 때 0을 추가합니다.
                    } else {
                        total.push(Math.round((completeCount / totalCount) * 100));

                    }
                });
                setPlanGraphData(total)

                if (response.todo.total_count != 0) {
                    setGoalAchieve(Math.round(parseInt(response.todo.complete_count) / parseInt(response.todo.total_count) * 100))
                } else {
                    setGoalAchieve(0)

                }

                setUp3(response.tag_top)
                setDown3(response.tag_low)
            }
        );
    }

    const getMissionData = (from, to) => {
        context.get(
            "datalab/getMissionData",
            {
                member: userId,
                class_id: selectedClass === 0 ? 0 : selectedClass,
                from: moment(from).format("YYYY-MM-DD"),
                to: moment(to).format("YYYY-MM-DD")
            },
            response => {
                console.log("getMissionData", response)
                setMissionData(response);
                setMissionAchieve(response.pass_rate);
                // if (response.mission_count == 0) {
                //     setNothing(1);
                //     setAttendance(0);
                //     setCabinet(0);
                //     setStudyCafe(0);
                // } else {
                //     setNothing(0);
                //     let typeList = [];
                //     response.mission.forEach(item => {
                //         typeList.push(item.type)
                //         if (item.type == "STUDY") {
                //             setAttendance(item.total_count)
                //         } else if (item.type == "CABINET") {
                //             setCabinet(item.total_count)
                //         } else if (item.type == "CLINIC") {
                //             setStudyCafe(item.total_count)
                //         }
                //     });
                //     if (typeList.indexOf("STUDY") == -1) {
                //         setAttendance(0)
                //     }
                //     if (typeList.indexOf("CABINET") == -1) {
                //         setCabinet(0)
                //     }
                //     if (typeList.indexOf("CLINIC") == -1) {
                //         setStudyCafe(0)
                //     }
                // }
            }
        );
    }

    const getTestData = (from, to) => {
        context.get(
            "datalab/getTestData",
            {
                class_id: selectedClass,
                user_id: userId,
                from: moment(from).format("YYYY-MM-DD"),
                to: moment(to).format("YYYY-MM-DD")
            },
            response => {
                console.log("testData", response)
                setTestData(response)
                // if (response.mission_count == 0) {
                //     setNothing(1);
                //     setAttendance(0);
                //     setCabinet(0);
                //     setStudyCafe(0);
                // } else {
                //     setNothing(0);
                //     let typeList = [];
                //     response.mission.forEach(item => {
                //         typeList.push(item.type)
                //         if (item.type == "STUDY") {
                //             setAttendance(item.total_count)
                //         } else if (item.type == "CABINET") {
                //             setCabinet(item.total_count)
                //         } else if (item.type == "CLINIC") {
                //             setStudyCafe(item.total_count)
                //         }
                //     });
                //     if (typeList.indexOf("STUDY") == -1) {
                //         setAttendance(0)
                //     }
                //     if (typeList.indexOf("CABINET") == -1) {
                //         setCabinet(0)
                //     }
                //     if (typeList.indexOf("CLINIC") == -1) {
                //         setStudyCafe(0)
                //     }
                // }
            }
        );
    }


    const getCompareData = (mode, from, to) => {
        context.get(
            "datalab/getCompareData",
            {
                userId: userId,
                mode: mode,
                from,
                to
            },
            response => {
                setRealTimeToday(parseInt(response.today.study_real_time) / 3600);
                setTotalTimeToday(parseInt(response.today.study_total_time) / 3600);
                setRealTimeYesterday(parseInt(response.yesterday.study_real_time) / 3600);
                setTotalTimeYesterday(parseInt(response.yesterday.study_total_time) / 3600);
                setGoalAchToday(response.today.todo_total_count != 0 ? response.today.todo_complete_count / response.today.todo_total_count * 100 : 0);
                setGoalAchYesterday(response.yesterday.todo_total_count != 0 ? response.yesterday.todo_complete_count / response.yesterday.todo_total_count * 100 : 0);
                setEfficiencyToday(parseInt(response.today.study_total_time) != 0 ? parseInt(response.today.study_real_time) / parseInt(response.today.study_total_time) * 100 : 0)
                setEfficiencyYesterday(parseInt(response.yesterday.study_total_time) != 0 ? parseInt(response.yesterday.study_real_time) / parseInt(response.yesterday.study_total_time) * 100 : 0)
                setMissionToday(parseInt(response.today.mission_complete_count));
                setMissionTotalToday(parseInt(response.today.mission_total_count));
                setMissionYesterday(parseInt(response.yesterday.mission_complete_count));
                setMissionTotalYesterday(parseInt(response.yesterday.mission_total_count));
            }
        );
    }

    useEffect(() => {
        if (period == 2) {
            // setCurrentPeriod(yearList[year] + " " + monthList[month])
            setCurrentPeriod(yearList[year])
            const from = yearList[year].split("년")[0] + "-" + "01" + "-" + "01";
            const to = yearList[year].split("년")[0] + "-" + "12" + "-" + "31";
            getChartData("MONTH", yearList[year].split("년")[0] + "-" + "01" + "-" + "01", yearList[year].split("년")[0] + "-" + "12" + "-" + "31")
            getPlannerData("MONTH", from, to)
            getMissionData(from, to)
            getTestData(from, to)
            getCompareData("MONTH", from, to);
        } else if (period == 1) {
            if (day > dayList.length - 1) {
                setDay(0);
            }
            if (year != -1) {
                getDayFromWeek(yearList[year].split("년")[0] + "." + monthList[month].split("월")[0]);
            }
            setCurrentPeriod(yearList[year] + " " + monthList[month] + " " + dayList[day])
        } else if (period == 0) {
            if (dayList.length < 10) {
                getDayList(moment(yearList[year].split("년")[0] + "." + monthList[month].split("월")[0] + ".01").daysInMonth());
            }
            setCurrentPeriod(yearList[year] + " " + monthList[month] + " " + dayList[day]);
            // getChartData("HOUR", yearList[year].split("년")[0] + "-" + monthList[month].split("월")[0] + "-" + (dayList.length < 10 ? "01" : dayList[day].split("일")[0]), yearList[year].split("년")[0] + "-" + monthList[month].split("월")[0] + "-" + (dayList.length < 10 ? "01" : dayList[day].split("일")[0]));
            // const from = yearList[year].split("년")[0] + "-" + monthList[month].split("월")[0] + "-" + (dayList.length < 10 ? "01" : dayList[day].split("일")[0]);
            // const to = yearList[year].split("년")[0] + "-" + monthList[month].split("월")[0] + "-" + (dayList.length < 10 ? "01" : dayList[day].split("일")[0]);
            getChartData("MONTH", yearList[year].split("년")[0] + "-" + monthList[month].split("월")[0] + "-" + (dayList.length < 10 ? "01" : dayList[day].split("일")[0]), yearList[year].split("년")[0] + "-" + monthList[month].split("월")[0] + "-" + (dayList.length < 10 ? "01" : dayList[day].split("일")[0]));
            const from = yearList[year].split("년")[0] + "-" + monthList[month].split("월")[0] + "-" + (dayList.length < 10 ? "01" : dayList[day].split("일")[0]);
            const to = yearList[year].split("년")[0] + "-" + monthList[month].split("월")[0] + "-" + (dayList.length < 10 ? "01" : dayList[day].split("일")[0]);
            getPlannerData(from, to);
            getMissionData(from, to);
            getTestData(from, to);
            getCompareData("HOUR", from, to);
        } else {
            getToday();
            // getChartData("HOUR", new Date, new Date);
            // getChartData("MONTH", new Date, new Date);            
            // getChartData("MONTH", "2024-01-01", "2024-12-31")
            // getPlannerData(new Date, new Date);
            // getMissionData(new Date, new Date);
            // getTestData(new Date, new Date);
            // getCompareData("HOUR", new Date, new Date);
        }
    }, [period, year, month, day, dayList])

    useEffect(() => {
        if (period == 1) {
            setDayList(strings.weekList);
            return
        }
        if (year != -1 && month != -1) {
            getDayList(moment(yearList[year].split("년")[0] + "." + monthList[month].split("월")[0] + ".01").daysInMonth())
            // setDay(0)
        }
    }, [year, month]);

    const getYearList = () => {
        let now = moment(new Date()).year();
        let list = [];
        // for (let i = now - 5; i < now + 1; i++) {
        //     list.push(i.toString() + strings._y)
        // }
           for (let i = now; i < now; i++) {
            list.push(i.toString() + strings._y)
        }

        setYearList(list)
        // setYear(yearList.length - 1)
        setYear(list.indexOf(moment(new Date()).year().toString() + strings._y))
    }

    const getMonthList = () => {
        let list = [];
        let month = ""
        for (let i = 1; i < 13; i++) {
            month = dateForm(i) + strings._m
            list.push(month)
        }
        setMonthList(list)
    }

    const getDayList = (days) => {
        let list = [];
        let day = "";
        for (let i = 1; i <= days; i++) {
            day = dateForm(i) + strings._d
            list.push(day)
        }
        setDayList(list)
    }

    const getDayFromWeek = (momentObj) => {
        let obj = moment(momentObj), first, last, weekCount, weekStart, weekEnd, lastDay;
        console.log("obj", obj)
        first = obj.startOf("month").week();
        console.log("first", first)
        last = obj.endOf("month").week();
        console.log("last", last)
        lastDay = obj.endOf("month").day();
        console.log("lastDay", lastDay)
        if (last < first) {
            weekCount = 52 + first - last;
        } else {
            weekCount = last - first + 1;
        }
        if (weekCount < 5 || lastDay < 6) {
            setDayList(strings.weekList);
        } else {
            setDayList(strings.weekList5);
        }
        weekStart = moment(moment(yearList[year].split("년")[0] + "." + (first + day).toString(), "YYYY.ww").startOf("week").toDate()).format("YYYY-MM-DD");
        weekEnd = moment(moment(yearList[year].split("년")[0] + "." + (first + day).toString(), "YYYY.ww").endOf("week").toDate()).format("YYYY-MM-DD");
        setWeekLine(getDaysFromStartEnd(moment(weekStart).date(), moment(weekEnd).date(), weekStart))
        getChartData("DAY", weekStart, weekEnd)
        getPlannerData(weekStart, weekEnd)
        getMissionData(weekStart, weekEnd)
        getTestData(weekStart, weekEnd);
        getCompareData("DAY", weekStart, weekEnd);
    }

    const togglePeriod = () => {
        setPeriodOpen(!periodOpen);
    }

    const toggleYear = () => {
        setYearSelectOpen(!yearSelectOpen);
    }

    const toggleMonth = () => {
        setMonthSelectOpen(!monthSelectOpen);
    }

    const toggleDay = () => {
        setDaySelectOpen(!daySelectOpen);
    }

    const periodFix = () => {
        if (period == -1) {
            // setPeriod(0);
            setPeriod(2);
        }
        if (year == -1) {
            setYear(yearList.indexOf(moment(new Date()).year().toString() + strings._y));
        }
        if (month == -1) {
            let mm = moment(new Date()).month() + 1;
            mm = dateForm(mm) + strings._m
            setMonth(monthList.indexOf(mm))
        }
        if (day == -1) {
            setDay(dayList.indexOf(dateForm(moment(new Date()).date()) + strings._d))
        }
    }

    const vaildDate = (yearIdx, monthIdx, dayIdx) => {
        const yIdx = yearIdx == -1 ? yearList.indexOf(moment(new Date()).year().toString() + strings._y) : yearIdx;
        const mIdx = monthIdx == -1 ? monthList.indexOf(dateForm(moment(new Date()).month() + 1) + strings._m) : monthIdx;
        const dIdx = dayIdx == -1 ? dayList.indexOf(dateForm(moment(new Date()).date()) + strings._d) : dayIdx;

        if (yIdx == undefined || mIdx == undefined || dIdx == undefined) {
            return false;
        }
        const dateStr = yearList[yIdx].split("년")[0] + "-" + monthList[mIdx].split("월")[0] + "-" + dayList[dIdx].split("일")[0]
        var date = moment(dateStr);
        return period == 1 ? true : date.isValid();
    }

    const addNotification = (notificationType, notificationTitle, notificationMessage) => {
        Store.addNotification({
            title: notificationTitle,
            message: notificationMessage,
            type: notificationType,
            insert: 'top',
            container: 'top-right',
            dismiss: {
                duration: 1000,
            }
        });
    };

    const getClassList = () => {
        context.get(
            'class/getClassList',
            {},
            response => {
                if(response){
                const initClass = response[0].id
                rootStore.setClassId(initClass)
                setClassList(response)
                loadClassInfo(initClass);
                }else{
                    
                }
                

            });
    }

    const loadClassInfo = (initClass) => {
        context.get(
            'class/getClassInfo',
            {
                class_id: initClass,
            },
            response => {
                setSelectedClass(initClass);
                setClassInfo([response])
            });
    }

    const selectSchool = (id) => {
        context.get(
            'class/getClassInfo',
            {
                class_id: id,
            },
            response => {
                setSelectedClass(id);
                setClassInfo([response])
            });
    }

    const clickDropDown = () => {
        setDropdownStatus(!dropdownStatus)
    }

    const clickClass = (id) => {
        context.get(
            'class/clickClass',
            {
                class_id: id,
            },
            response => {
                setChangeClass(!changeClass)
            });
    }


    return (
        <div className="main-content report-container" >
            <div class="class-info-dropdown" style={{ width: 'fit-content', paddingLeft: 'unset' }} onClick={() => clickDropDown()}>
                {classInfo.length > 0 ? 
                  <DropdownBoot >
                  <DropdownBoot.Toggle variant="success" id="dropdown-basic" style={{ paddingLeft: 'unset' }}>
                      <div class="class-info-img-box"><img src={classInfo[0]?.thumbnail ? context.loadImage(classInfo[0]?.thumbnail) : "/assets/image/chewing_plus_logo.png"}
                          alt="" /></div>
                      <div class="class-head-left-child">
                          <div class="class-head-left-title">{classInfo[0]?.class_name} {classInfo[0]?.class_grade} {classInfo[0]?.classroom}반
                              {/* {dropdownStatus ? <img src={"/assets/image/icon_arrow_down_black1.png"} alt="" /> : <img src={"/assets/image/icon_arrow_up_black.png"} alt="" />} */}
                          </div>
                          <div class="class-head-left-subtitle">{user_name}</div>
                      </div>
                  </DropdownBoot.Toggle>
                  <DropdownBoot.Menu>
                      {classList.map((item, index) => {
                          return (
                              <DropdownBoot.Item >
                                  <div class="class-info-dropdown-content" style={{ justifyContent: 'space-around' }} onClick={() => { selectSchool(item.id); rootStore.setClassId(item.id); clickClass(item.id); }}>

                                      <div class="class-info-img-box" ><img src={item.thumbnail ? context.loadImage(item.thumbnail) : "/assets/image/chewing_plus_logo.png"}
                                          alt="" /></div>
                                      <div class="class-head-left-title col-md-8">{item.class_name} {item.grade} {item.classroom}반 </div>
                                  </div>
                              </DropdownBoot.Item>
                          )
                      })}

                  </DropdownBoot.Menu>
              </DropdownBoot>
                :
                <img className="none-class" src="/assets/image/img_datalab_none_class.png" alt=""/>
                
                }
              
            </div>
            <div className="d-flex m-b-10"
            // style={{padding: 'unset'}}
            >
                <div className="flex-box">
                    <div className="left">
                        <div className="selected">레포트</div>
                        <div className="">플래너</div>
                    </div>
                    <div className="right">
                        {/* <Dropdown isOpen={periodOpen} toggle={() => togglePeriod()}
                            className="dropdown navbar-user date-dropdown report-dropdown" tag="li">
                            <DropdownToggle tag="a"
                                className="display-flex-important align-item-center profile-dropdown report-dropdown">
                                {/* <div
                            className="flex-grow-1">{period != -1 ? strings.report_period[period].name : strings.report_period[0].name}</div> */}
                              {/*  
                                   <div className="flex-grow-1">{period === 2 ? "년도별" : "년도별"}</div>
                                <img src={"/assets/image/icon_arrow_down_black.png"} alt="" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu dropdown-menu-right profile-dropdown-menu" tag="ul">
                                {
                                    strings.report_period.map((item, idx) =>
                                        <DropdownItem key={idx}
                                            className="dropdown-header display-flex-important justify-content-center f-s-15"
                                            onClick={() => {
                                                periodFix()
                                                setPeriod(item.code);
                                            }}
                                        >
                                            {item.name}
                                        </DropdownItem>
                                    )
                                }
                            </DropdownMenu>
                        </Dropdown> */}

                        <Dropdown isOpen={yearSelectOpen} toggle={() => toggleYear()}
                            className="dropdown navbar-user report-dropdown" tag="li" style={{ width: "100px" }}>
                            <DropdownToggle tag="a"
                                className="display-flex-important align-item-center profile-dropdown report-dropdown">
                                <div
                                    className="flex-grow-1">{year != -1 ? yearList[year] : moment(new Date()).year().toString() + strings._y}</div>
                                <img src={"/assets/image/icon_arrow_down_black.png"} alt="" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu dropdown-menu-right profile-dropdown-menu" tag="ul">
                                {
                                    yearList.map((item, idx) =>
                                        <DropdownItem key={idx}
                                            className="dropdown-header display-flex-important justify-content-center f-s-15"
                                            onClick={() => {
                                                if (!vaildDate(idx, month, day)) {
                                                    addNotification('warning', '유효하지 않은 날짜입니다.')
                                                    return;
                                                }

                                                periodFix()
                                                setYear(idx)
                                            }}
                                        >
                                            {item}
                                        </DropdownItem>
                                    )
                                }
                            </DropdownMenu>
                        </Dropdown>

                        {/* {
                    period != 2 &&
                    <Dropdown isOpen={monthSelectOpen} toggle={() => toggleMonth()}
                        className="dropdown navbar-user report-dropdown m-l-10" tag="li">
                        <DropdownToggle tag="a"
                            className="display-flex-important align-item-center profile-dropdown report-dropdown">
                            <div
                                className="flex-grow-1">{month != -1 ? monthList[month] : dateForm(moment(new Date()).month() + 1) + strings._m}</div>
                            <img src={"/assets/image/icon_arrow_down_black.png"} alt="" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu dropdown-menu-right profile-dropdown-menu" tag="ul">
                            {
                                monthList.map((item, idx) =>
                                    <DropdownItem key={idx}
                                        className="dropdown-header display-flex-important justify-content-center f-s-15"
                                        onClick={() => {
                                            if (!vaildDate(year, idx, day)) {
                                                addNotification('warning', '유효하지 않은 날짜입니다.')
                                                return;
                                            }
                                            periodFix()
                                            setMonth(idx)
                                        }}
                                    >
                                        {item}
                                    </DropdownItem>
                                )
                            }
                        </DropdownMenu>
                    </Dropdown>
                }
                {
                    period <= 1 &&
                    <Dropdown isOpen={daySelectOpen} toggle={() => toggleDay()}
                        className="dropdown navbar-user report-dropdown m-l-10" tag="li">
                        <DropdownToggle tag="a"
                            className="display-flex-important align-item-center profile-dropdown report-dropdown">
                            <div
                                className="flex-grow-1">{day != -1 ? dayList[day] : moment(new Date()).date().toString() + strings._d}</div>
                            <img src={"/assets/image/icon_arrow_down_black.png"} alt="" />
                        </DropdownToggle>
                        <DropdownMenu
                            className="dropdown-menu dropdown-menu-right profile-dropdown-menu "
                            tag="ul">
                            <PerfectScrollbar className="report-dropdown-menu" options={{ suppressScrollX: true }}>
                                {
                                    dayList.map((item, idx) =>
                                        <DropdownItem key={idx}
                                            className="dropdown-header display-flex-important justify-content-center f-s-15"
                                            onClick={() => {
                                                if (!vaildDate(year, month, idx)) {
                                                    addNotification('warning', '유효하지 않은 날짜입니다.')
                                                    return;
                                                }
                                                periodFix()
                                                setDay(idx)
                                            }}
                                        >
                                            {item}
                                        </DropdownItem>
                                    )
                                }
                            </PerfectScrollbar>
                        </DropdownMenu>
                    </Dropdown>
                } */}


                    </div>
                </div>

            </div>
            <div className="row" style={{ gap: '30px', height: '100%' }}>
                <div class="left" style={{ flex: '1', display: 'flex', flexDirection: 'column', gap: '30px', paddingRight: 'unset' }}>
                    <div className="col-xl-7 d-flex p-0" style={{ maxWidth: '100%', gap: '30px', flex: '1' }}>
                        <div className="goal-achieve m-l-10" style={{ marginRight: 'unset', display: 'flex', flexDirection: 'column', gap: 'inherit' }}>
                            <div className="flex-box">
                                <div className="f-s-24 f-w-700">과제</div>
                                <div className="report-mission-time">{moment(moment.now()).format("YYYY/MM/DD hh:mm")}</div>
                            </div>
                            <div className="d-flex width-100-percent justify-content-center align-bottom"
                                style={{ flex: '10' }}>

                                <div className="doughnut-container">
                                    <div className="d-flex align-item-center goal-chart">
                                        <div className="goal-hint report-percent p-b-10" style={{ position: 'absolute', bottom: '12%', left: '4%' }}>0%</div>
                                        <Doughnut data={goalChart.data} options={goalChart.options} />
                                        <div className="goal-hint report-percent p-b-10" style={{ position: 'absolute', bottom: '12%', right: '4%' }}>100%</div>
                                    </div>
                                    <div className="report-chart-label">
                                        <div className="f-s-35 f-w-600">{getDecimal(missionAchieve.toString())}</div>
                                        <div className="achieve-number f-s-20">%</div>
                                    </div>
                                    <div className="report-chart-label">
                                        <div className="goal-hint">{missionData?.pass_count.toString() + "/" + missionData?.total_count.toString()}</div>
                                    </div>
                                </div>



                            </div>
                            <div className="mission-data-container">
                                <div className="box approve">
                                    <div className="left">
                                        <div className="title-box">
                                            <div className="status-dot"></div>
                                            <div className="status-title">승인된 과제 수</div>
                                        </div>
                                    </div>
                                    <div className="right">
                                        {missionData?.pass_count ? missionData?.pass_count : '0'}건
                                    </div>
                                </div>
                                <div className="box not-submit">
                                    <div className="left">
                                        <div className="title-box">
                                            <div className="status-dot"></div>
                                            <div className="status-title">미제출 과제 수</div>
                                        </div>
                                    </div>
                                    <div className="right">
                                        {missionData?.none_count ? missionData?.none_count : '0'}건
                                    </div>
                                </div>
                                <div className="box companion" >
                                    <div className="left">
                                        <div className="title-box">
                                            <div className="status-dot"></div>
                                            <div className="status-title">반려된 과제 수</div>
                                        </div>
                                    </div>
                                    <div className="right">
                                        {missionData?.reject_count ? missionData?.reject_count : '0'}건
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="goal-achieve test" style={{ marginRight: 'unset' }}>
                            <div className="f-s-24 f-w-700">시험</div>
                            <div className="data-container">
                                <div className="data-box">
                                    <div className="title">총 시험 횟수</div>
                                    <div className="data-content"><span className="highlight blue-font">{testData?.count}</span> 건</div>
                                </div>

                                <div className="data-box">
                                    <div className="title">시험 평균점수</div>
                                    <div className="data-content"><span className="highlight">{testData?.average_score?.toFixed(2)}</span> 점</div>
                                </div>
                                <div className="data-box">
                                    <div className="title">객관식 평균 정답률</div>
                                    <div className="data-content"><span className="highlight">{testData?.multiple_choice_percent !== null ? testData?.multiple_choice_percent.toFixed(2) : '0'}</span> %</div>
                                </div>
                                <div className="data-box">
                                    <div className="title">주관식 평균 정답률</div>
                                    <div className="data-content"><span className="highlight">{testData?.essay_percent !== null ? testData?.essay_percent.toFixed(2) : '0'}</span> %</div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="goal-achieve plan" style={{ width: '100%', height: '100%', flex: '1' }}>
                        <div className="d-flex align-item-center">
                            <div className="f-s-24 f-w-700">플랜</div>
                        </div>
                        <div className="data-container">
                            <div className="left">
                                <div className="report-line-chart">
                                    <Bar data={Chart.data} options={Chart.options} />
                                    {/* <div
                    className="report-chart-x">{period === 2 ? strings.month : (period === 1 ? strings.week : strings.time)}</div> */}
                                    {/* <div className="report-chart-y">{strings.time}</div> */}
                                    <div className="report-chart-y">(단위 : %)</div>
                                </div>
                            </div>
                            <div className="right">
                                <div className="data-box">
                                    <div className="title">달성률</div>
                                    <div className="data-content"><span className="highlight blue-font">{goalAchieve}</span> %</div>
                                </div>

                                <div className="data-box">
                                    <div className="title">플랜 개수</div>
                                    <div className="data-content"><span className="highlight">{goalTotal}</span> 개</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col-xl-5 d-flex p-0">

                    <div className="goal-achieve position-relative" style={{ width: "100%" }}>
                        <div className="d-flex align-item-center">
                            <div className="f-s-24 f-w-700 m-r-auto text-center">AI 종합 레포트</div>
                        </div>
                        <div className="predicted-coming">
                            <div>
                                <img src={"/assets/image/img_digging.png"} alt="" />
                                <div className="m-t-25">{strings.coming_soon}</div>
                            </div>
                        </div>
                        {/* <button className="curriculum" onClick={() => props.history.push("/clinic")}>
                            <div className="m-r-5">{strings.curriculum}</div>
                            <div className="check-out m-r-5">{strings.check_out}</div>
                            <img src={"/assets/image/icon_arrow_right_blue.png"} alt="" />
                        </button> */}
                    </div>
                </div>
            </div>
        </div>
    );

}

export default withRouter(Report);
