import React from 'react';

import { IconType } from "../../type/icon_type";

const IconX = ({ color, width, height }: IconType) => {
    return <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 7L7 17M7 7L17 17" stroke={color} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    ;
};

export default IconX;
