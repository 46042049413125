import { inject } from "mobx-react";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import TextField from "../../components/text_field";
import { Page_settings } from "../../config/page_settings";
import colors from "../../design/colors";
import fonts from "../../design/typography";
import { check_validation_email } from "../../helper/common";
import strings from "../../lang/strings";
import { IconEyeOpen, IconEyeClosed } from "../../components/icons/eye";
import Const from "../../config/const";
const SignupStep1 = (props) => {
    const context:any = useContext(Page_settings);

    const {
        id,
        type,
        onChangeId,
        onChangePwdConfirm,
        pwd,
        onChangePwd,
        pwdConfirm,
        navigate
    } = props;

    const [invalidId, setInvalidId] = useState(false);
    const [duplicateId, setDuplicateId] = useState(false);
    const [invalidPwd, setInvalidPwd] = useState(true);
    const [invalidPwdConfirm, setInvalidPwdConfirm] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    
    const passwordRegex = Const.passwordRegex;
    const onNext = () => {
        if (id == "" || (type == "EMAIL" && (pwd == "" || pwdConfirm == ""))) {
            return
        } else if (!check_validation_email(id)) {
            setInvalidId(true);
            return
        }
        if (type == "EMAIL") {
            if(invalidPwd || invalidPwdConfirm) {
                return
            }
        }
        context.get(
            'auth/check-user',
            {
                email: id,
                loginType: type,
            },
            response => {
                if (response) {
                    navigate('/signup/2');
                } else {
                    setDuplicateId(true);
                }
            }
        );

    }

    return (
        <div style={{width: "100%", marginTop: 80}}>
            <Title>회원가입</Title>
            <div style={{marginBottom: 16}}>
                <span style={{...fonts.body3SemiBold}}>{strings.email}</span>
                <span style={{fontSize: 13, fontWeight: "lighter", marginLeft: 10}}>{"("}</span>
                <span style={{color:"red"}}>{" * "}</span>
                <span style={{fontSize: 13, fontWeight: "lighter"}}>{"잘못된 이메일 입력시 일부 기능이 제한될 수 있습니다.)"}</span>
            </div>
            <TextField
                placeholder={"이메일을 입력해주세요"}
                value={id}
                complete={id !== "" && !invalidId}
                onChange={(e) => {
                    onChangeId(e.target.value)
                    if (!check_validation_email(e.target.value)) {
                        setInvalidId(true)
                    } else {
                        setInvalidId(false)
                    }
                    setDuplicateId(false);
                }}
                error={invalidId || duplicateId}
                helperText={invalidId ? "이메일 형식이 올바르지 않습니다." : duplicateId ? "이미 사용중인 이메일입니다." : ""}
                disabled={type !== "EMAIL"}
            />
            {
                type == 'EMAIL' &&
                <div style={{marginBottom: 16, marginTop: 40}}>
                    <div style={{...fonts.body3SemiBold, marginBottom: 16}}>{strings.password}</div>
                    <div style={{position: "relative", marginBottom: 40}}>
                        <TextField
                            type={showPassword ? "text" : "password"}
                            placeholder={"비밀번호를 입력해주세요"}
                            value={pwd}
                            complete={pwd !== "" && !invalidPwd}
                            onChange={(e) => {
                                onChangePwd(e.target.value)
                                if (!passwordRegex.test(e.target.value)) {
                                    setInvalidPwd(true)
                                } else {
                                    setInvalidPwd(false)
                                }

                                if (pwdConfirm === e.target.value) {
                                    setInvalidPwdConfirm(false)
                                } else {
                                    setInvalidPwdConfirm(true)
                                }
                            }}
                            helperText={"영소문자 필수, 숫자, 특수문자, 영대문자 중 2개 이상 (8~20자)"}
                            disabled={type !== "EMAIL"}
                            error={pwd !== "" && invalidPwd}
                        />
                        {
                            pwd != "" &&
                            <Eye onMouseDown={() => setShowPassword(true)} onMouseUp={() => setShowPassword(false)}
                                onTouchStart={() => setShowPassword(true)} onTouchEnd={() => setShowPassword(false)}>
                                {showPassword ? <IconEyeOpen /> : <IconEyeClosed />}
                            </Eye>
                        }
                    </div>
                    <div style={{...fonts.body3SemiBold, marginBottom: 16}}>{"비밀번호 확인"}</div>
                    <div style={{position: "relative", marginBottom: 40}}>
                        <TextField
                            type={showPasswordConfirm ? "text" : "password"}
                            placeholder={"비밀번호를 입력해주세요."}
                            value={pwdConfirm}
                            complete={pwdConfirm !== "" && !invalidPwdConfirm}
                            onChange={(e) => {
                                onChangePwdConfirm(e.target.value)
                                if (e.target.value != pwd) {
                                    setInvalidPwdConfirm(true)
                                } else {
                                    setInvalidPwdConfirm(false)
                                }
                            }}
                            error={pwdConfirm !== "" && invalidPwdConfirm}
                            helperText={invalidPwdConfirm ? "비밀번호가 일치하지 않습니다." : ""}
                            disabled={type !== "EMAIL"}
                        />
                        {
                            pwdConfirm != "" &&
                            <Eye onMouseDown={() => setShowPasswordConfirm(true)} onMouseUp={() => setShowPasswordConfirm(false)}
                                onTouchStart={() => setShowPasswordConfirm(true)} onTouchEnd={() => setShowPasswordConfirm(false)}>
                                {showPasswordConfirm ? <IconEyeOpen /> : <IconEyeClosed />}
                            </Eye>
                        }
                    </div>
                </div>
            }
            <NextButton onClick={() => onNext()}
                disabled={type == "EMAIL" && (invalidId || invalidPwd || invalidPwdConfirm)}>
                {strings.next}
            </NextButton>
        </div>
    );
}

export default inject('rootStore')(SignupStep1);

const Title = styled.p`
    font-family: ${fonts.heading1Bold.fontFamily};
    font-size: ${fonts.heading1Bold.fontSize};
    line-height: ${fonts.heading1Bold.lineHeight};
    color: ${colors.gray900};
    margin-bottom: 56px;
    text-align: center;
`;

const Eye = styled.button`
    position: absolute;
    right: 15px;
    top: 14px;
    border: none;
    background: none;
`;

const NextButton = styled.button`
    width: 100%;
    height: 48px;
    border-radius: 8px;
    background-color: ${colors.blue600};

    font-family: ${fonts.label4Medium.fontFamily};
    font-size: ${fonts.label4Medium.fontSize};
    line-height: ${fonts.label4Medium.lineHeight};
    color: ${colors.gray50};

    &:disabled {
        background-color: ${colors.gray200};
        color: ${colors.gray500};
    }
    margin-top: 16px;
`;