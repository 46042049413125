import React, { useContext, useEffect, useRef, useState } from "react";
import { Page_settings } from "../../config/page_settings";
import { IClassList } from "../../type/classroom";
import { IconRaiFill } from "../icons/rai";
import IconNotice from "../icons/notice";
import IconTalk from "../icons/talk";
import colors from "../../design/colors";
import styled from "styled-components";
import fonts from "../../design/typography";
import { ChevronDown, ChevronUp } from "../icons/chevron";
import Button from "../button";
import ChatbotDropdown from "./dropdown/chatbot_dropdown";
import SettingModal from "./dropdown/setting_modal";
import NoticeModal from "./dropdown/notice_modal";
import { IMemberInfo } from "../../type/member";
import ClassAddModal from "./dropdown/class_add_modal";
import { rootStore } from "../../mobx/store";
import { useNavigate } from "react-router-dom";
import { getFilePath } from "../../utils/image";
import ImageCircle from "../image_circle";
import ChewingTalkModal from "./dropdown/chewingtalk";
import { Client } from "@stomp/stompjs";
import SockJS from "sockjs-client";
import { SOCKET_URL } from "../../config/const";

const Header = () => {
    const context: any = useContext(Page_settings);
    const selectedClass = useRef<IClassList | null>(null);
    const [classList, setClassList] = useState<IClassList[]>([]);
    const [memberInfo, setMemberInfo] = useState<IMemberInfo | null>(null);
    const [isOpenClassDropdown, setIsOpenClassDropdown] = useState<boolean>(false);
    const [activeModal, setActiveModal] = useState<"chatBot" | "talk" | "notice" | "setting" | null>(null);
    const [showClassAddModal, setShowClassAddModal] = useState<boolean>(false);
    const classDropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (classDropdownRef.current && !classDropdownRef.current.contains(event.target as Node)) {
                setIsOpenClassDropdown(false);
            }
        };

        if (isOpenClassDropdown) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpenClassDropdown]);

    const setShowingChatBot = () => setActiveModal(prev => prev === "chatBot" ? null : "chatBot");
    const setShowingTalk = () => setActiveModal(prev => prev === "talk" ? null : "talk");
    const setShowingNotice = () => setActiveModal(prev => prev === "notice" ? null : "notice");
    const setShowingSetting = () => setActiveModal(prev => prev === "setting" ? null : "setting");
    const [hoverdIndex, setHoverdIndex] = useState<number>(-1);
    const userType = rootStore.getProfile?.userType;
    const token = rootStore.getMe?.token;
    useEffect(() => {

        if (!token) {
            return;
        }

        context.get("/class", {},
            (res: IClassList[]) => {
                if (res.length > 0) {
                    setClassList(res);
                    selectedClass.current = res[0];
                    rootStore.setClassId(res[0].id);
                } else {
                    setClassList([]);
                }
            }
        )
    }, [token]);

    useEffect(() => {
        if (!token) {
            return;
        }

        context.get("/member/info", {} , response => {
            setMemberInfo(response);
        });
    }, [token]);

    

    const refetchMemberInfo = () => {
        context.get("/member/info", {} , response => {
            setMemberInfo(response);
        });
    }

    const navigate = useNavigate();

    const onClickClass = (id: number) => {
        context.put(`/class/${id}/select`, {}, response => {
            rootStore.setClassId(id);
            navigate(0);
        });

        const selectedClassCurrent = classList.find(item => item.id === id);
        selectedClass.current = selectedClassCurrent;
        setClassList([selectedClassCurrent, ...classList.filter(item => item.id !== id)]);
    }

    const [socketClient, setSocketClient] = useState<Client | null>(null);

    useEffect(() => {

        if (!token) {
            return;
        }

        const client = new Client({
            webSocketFactory: () => new SockJS(`${SOCKET_URL}/ws`),
            connectHeaders: {
                "x-access-token": rootStore.getMe?.token ?? ""
            },
            onConnect: () => {
                console.log("connected");
            },
            onDisconnect: () => {
                console.log("disconnected");
            },
            onStompError: (frame) => {
                console.log("stomp error", frame);
            },
            onWebSocketClose: () => {
                console.log("web socket close");
            }
        });
        client.activate();
        setSocketClient(client);

        return () => {
            if (client && client.connected) {
                client.deactivate();
            }
        }
    }, [token]);

    return (
        <HeaderWrapper>
            <div ref={classDropdownRef} style={{ cursor: "pointer" }} onClick={() => setIsOpenClassDropdown(!isOpenClassDropdown)}>
                <ClassSection isOpen={isOpenClassDropdown}>
                    {
                        selectedClass.current &&
                            <>
                            <ImageCircle src={getFilePath(selectedClass.current?.thumbnail, "/assets/image/default_class.png")} size={44} />
                            <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", width: "100%"}}>
                            <div style={{ ...fonts.label5Medium, color: colors.gray600 }}>{selectedClass.current?.corp || "-"}</div>
                            <div style={{ ...fonts.body3SemiBold, color: colors.gray900, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                {selectedClass.current?.title.length > 15 ? selectedClass.current?.title.slice(0, 15) + "..." : selectedClass.current?.title}
                                {isOpenClassDropdown ? <ChevronUp style={{ marginLeft: 4 }} /> : <ChevronDown style={{ marginLeft: 4 }} />}
                                </div>
                            </div>
                            {
                                isOpenClassDropdown && <ClassDropdown>
                                    {classList.map((item, index) => (
                                    <ClassDropdownItem key={item.id} onMouseEnter={() => setHoverdIndex(index)} onMouseLeave={() => setHoverdIndex(-1)} isHoverd={hoverdIndex === index} onClick={() => {
                                        onClickClass(item.id);
                                    }}>
                                        <ImageCircle src={getFilePath(item.thumbnail, "/assets/image/default_class.png")} size={36} />
                                        <div style={{marginLeft: 8}}>
                                            <div style={{ ...fonts.label5Medium, color: colors.gray600 }}>{item.corp || "-"}</div>
                                            <div style={{ ...fonts.label4Medium, color: colors.gray900, display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                {item.title.length > 15 ? item.title.slice(0, 15) + "..." : item.title}
                                            </div>
                                        </div>
                                    </ClassDropdownItem>
                                ))}
                                {
                                    userType === "EXPERT" &&
                                    <Button size="medium" text="클래스 추가" height="34px" onClick={() => {
                                        setShowClassAddModal(true);
                                    }} style={{marginTop: 20}} />
                                }
                            </ClassDropdown>
                                }
                            </>
                    }
                </ClassSection>
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", position: "relative" }}>
                <div style={{ cursor: "pointer" ,  width: "44px", height: "44px", borderRadius: "50%", background: "linear-gradient(180deg, #0F84F4, #8DC9FF)", display: "flex", alignItems: "center", justifyContent: "center" }}
                    onClick={() => setShowingChatBot()}
                >  
                    <IconRaiFill color={colors.white} background={colors.blue400} width="32px" height="32px"/>
                </div>
                <SettingBox>
                    <div style={{ cursor: "pointer" }} onClick={() => setShowingTalk()}>
                        <IconTalk width="28px" height="28px" />
                    </div>
                    <div style={{ cursor: "pointer" }} onClick={() => setShowingNotice()}>
                        <IconNotice width="28px" height="28px" />
                    </div>
                    <ImageCircle src={getFilePath(memberInfo?.profile, "/assets/image/default_profile.png")} size={32} onClick={() => setShowingSetting()} style={{cursor: "pointer"}} />
                </SettingBox>
                {
                    activeModal === "chatBot" && 
                    <div style={{position: "absolute", top: 80, right: 20}}>
                        <ChatbotDropdown toggle={() => setShowingChatBot()} />
                    </div>
                }
            </div>
            {
                activeModal === "setting" &&
                <div style={{ position: "absolute", top: window.innerHeight/2 - 250, left: "calc(50% - 440px)"}}>
                    <SettingModal memberInfo={memberInfo} toggle={() => setShowingSetting()} refetchMemberInfo={refetchMemberInfo} />
                </div>
            }
            {
                activeModal === "notice" &&
                <div style={{ position: "absolute", top: 80, right: 68}}>
                    <NoticeModal classList={classList} toggle={() => setShowingNotice()} />
                </div>
            }
            {
                activeModal === "talk" &&
                <div style={{ position: "absolute", top: 80, right: 68}}>
                    <ChewingTalkModal classList={classList} toggle={() => setShowingTalk()} socketClient={socketClient} />
                </div>
            }
            {
                showClassAddModal &&
                <ClassAddModal
                    toggle={() => setShowClassAddModal(!showClassAddModal)}
                />
            }
        </HeaderWrapper>
    )
}

const HeaderWrapper = styled.div`
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px 0 24px;
    background-color: ${colors.white};
    z-index: 5;
    position: relative;
    border-bottom: 1px solid ${colors.gray100};
`;

const ClassSection = styled.div<{isOpen: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 250px;
    height: 56px;
    gap: 10px 8px;
    background-color: ${props => props.isOpen ? colors.gray50 : colors.white};
    padding: 6px 8px;
    border-radius: 8px;
`;

const SettingBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: ${colors.white};
    border-radius: 8px;
    padding: 0 8px;
    width: 144px;
    height: 44px;
    margin-left: 16px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
`;

const ClassDropdown = styled.div`
    position: absolute;
    top: 80px;
    left: 20px;
    width: 250px;
    max-height: 440px;
    background-color: ${colors.white};
    border-radius: 8px;
    padding: 8px 10px 16px 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 8px; /* Width of the scrollbar */
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${colors.gray100}; /* Color of the scrollbar thumb */
        border-radius: 4px; /* Rounded corners for the scrollbar thumb */
    }
    &::-webkit-scrollbar-track {
        background: transparent; /* Background of the scrollbar track */
    }
    `;


const ClassDropdownItem = styled.div<{isHoverd: boolean}>`
    width: 100%;
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 8px;
    background-color: ${props => props.isHoverd ? colors.gray50 : colors.white};
    padding: 8px 10px;
`;

export default Header;