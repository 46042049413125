import React from 'react';
import { IconType } from '../../type/icon_type';
import colors from '../../design/colors';

const IconFeedback = (props : IconType) => {
    const { width = "24px", height = "24px", color = colors.gray900 } = props;
    
    return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.8889 8.44444H7.55556M16.4444 12H7.55556M20 14.6667C20 15.1382 19.8127 15.5903 19.4793 15.9237C19.1459 16.2571 18.6937 16.4444 18.2222 16.4444H7.55556L4 20V5.77778C4 5.30628 4.1873 4.8541 4.5207 4.5207C4.8541 4.1873 5.30628 4 5.77778 4H18.2222C18.6937 4 19.1459 4.1873 19.4793 4.5207C19.8127 4.8541 20 5.30628 20 5.77778V14.6667Z" stroke={color} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    )
}

export default IconFeedback;