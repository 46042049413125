import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import colors from '../../design/colors';

const BlankHeader = () => {
    const navigate = useNavigate();
    return <Header>
        <img src={'/assets/image/logo_real.png'} alt="logo" width={140} height={30} onClick={() => {
            navigate("/landing/main");
        }} style={{ cursor: "pointer" }} />
    </Header>;
};

export default BlankHeader;

const Header = styled.div`
    height: 80px;
    width: 100%;
    padding-left: 140px;
    background-color: ${colors.white};
    display: flex;
    align-items: center;
`;
