import React, { useState } from "react";
import styled from "styled-components";
import colors from "../design/colors";
import fonts from "../design/typography";
import ClassAddModal from "../components/header/dropdown/class_add_modal";
import IconButton from "../components/icon_button";

const NoClassPage = (props: {userType: "EXPERT" | "STUDENT"}) => {

    const [showClassAddModal, setShowClassAddModal] = useState(false);

    return (
        <Container>
            <div style={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end"}}>
                {props.userType === "EXPERT" && <IconButton
                    size="large"
                    text="클래스 생성"
                    onClick={() => setShowClassAddModal(true)}
                    width="128px"
                    height="44px"
                />}
            </div>
            <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                <img src={"/assets/image/sad_rai.png"} alt="sad_rai" style={{width: 152, height: 113}}/>
                <span style={{...fonts.body3Medium, color: colors.gray400, marginTop: 24}}>등록된 클래스가 없습니다.</span>
                <span style={{...fonts.body3Medium, color: colors.gray400}}>{props.userType === "EXPERT" ? "지금 바로 클래스를 생성해보세요!" : "선생님께 클래스 등록을 요청해보세요!"}</span>
            </div>
            {
                showClassAddModal &&
                <ClassAddModal
                    toggle={() => setShowClassAddModal(!showClassAddModal)}
                />
            }
        </Container>
    );
};

export default NoClassPage;


const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 40px;
`;