import { observer } from "mobx-react";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import "react-datetime/css/react-datetime.css";
import "react-notifications-component/dist/theme.css";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";
import Button from "../../../components/button";
import CalendarModal from "../../../components/calendar_modal";
import Checkbox from "../../../components/checkbox";
import PlusIcon from "../../../components/icons/plus";
import IconX from "../../../components/icons/x";
import ImageCircle from "../../../components/image_circle";
import SearchBar from "../../../components/search_bar";
import SelectBox from "../../../components/select_box";
import TextField from "../../../components/text_field";
import { Page_settings } from "../../../config/page_settings";
import colors from "../../../design/colors";
import fonts from "../../../design/typography";
import { rootStore } from "../../../mobx/store";
import { IClassMemberList } from "../../../type/classroom";
import { Subject } from "../../../type/subject";
import { getFileImagePath, getFilePath } from "../../../utils/image";


interface IAnswerItem {
  questionType: 1 | 2;
  correctAnswer: string;
  score: number;

}


const CreateExam = observer((props) => {
  const context: any = useContext(Page_settings);
  const classId = rootStore.getClassId;
  const { exam_id } = useParams();
  const examId = exam_id ? parseInt(exam_id) : 0;

  const [title, setTitle] = useState("");
  const [subject, setSubject] = useState<keyof typeof Subject | null>(null);
  const [questionCount, setQuestionCount] = useState(0);
  const [totalScore, setTotalScore] = useState<number>(100);
  const [startHour, setStartHour] = useState<number>(0);
  const [startMinute, setStartMinute] = useState<number>(0);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endHour, setEndHour] = useState<number>(0);
  const [endMinute, setEndMinute] = useState<number>(0);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isStartDateCalendarOpen, setIsStartDateCalendarOpen] = useState(false);
  const [isEndDateCalendarOpen, setIsEndDateCalendarOpen] = useState(false);

  const [studentList, setStudentList] = useState<IClassMemberList[]>([]);
  const [selectedStudentList, setSelectedStudentList] = useState<IClassMemberList[]>([]);

  const [answerList, setAnswerList] = useState<IAnswerItem[]>([]);
  const [keyword, setKeyword] = useState("");
  const [limitTime, setLimitTime] = useState<number>(0);
  
  const fileRef = useRef<HTMLInputElement>(null);
  const [fileList, setFileList] = useState([]);

  const [isOpenCreateAlert, setIsOpenCreateAlert] = useState(false);
  
  const navigate = useNavigate();

  useEffect(() => {
    context.get(
      `class/${classId}/member`,
      {},
      (response) => {
        setStudentList(response);
      }
    );
    
    if (examId === 0) {
      return;
    }
    
    context.get(
      `exam/${examId}`,
      {},
      (response) => {
        const examInfo = response.info;
        setTitle(examInfo.title);
        setSubject(examInfo.subject);
        setQuestionCount(examInfo.items);
        setStartDate(new Date(moment(examInfo.start_time).format("YYYY-MM-DD")));
        setStartHour(parseInt(moment(examInfo.start_time).format("HH")));
        setStartMinute(parseInt(moment(examInfo.start_time).format("mm")));
        setEndDate(new Date(moment(examInfo.end_time).format("YYYY-MM-DD")));
        setEndHour(parseInt(moment(examInfo.end_time).format("HH")));
        setEndMinute(parseInt(moment(examInfo.end_time).format("mm")));
        setLimitTime(examInfo.time);
        const answerList = JSON.parse(examInfo.answer_data);
        setAnswerList(answerList);
      }
    );
  }, []);

  useEffect(() => {
    if (questionCount < answerList.length) {
      setAnswerList(answerList.slice(0, questionCount));
    } else {
      const diff = questionCount - answerList.length;
      setAnswerList([...answerList, ...Array.from({ length: diff }, () => ({
        questionType: 1,
        correctAnswer: "[]",
        score: 0  
      })) as IAnswerItem[]]);
    }
  }, [questionCount]);

  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.type !== "application/pdf") return;
    setFileList([file]);
  };

  const createAvailable = (
    title.trim().length > 0 &&
    subject &&
    questionCount > 0 &&
    totalScore > 0 &&
    startDate &&
    endDate &&
    moment(`${startDate} ${startHour}:${startMinute}:00`).isBefore(moment(`${endDate} ${endHour}:${endMinute}:00`)) &&
    limitTime > 0 &&
    selectedStudentList.length > 0 &&
    fileList.length > 0 &&
    answerList.every((item) => item.score > 0) &&
    answerList.every((item) => ((item.correctAnswer !== "") && (item.correctAnswer !== "[]"))) &&
    answerList.reduce((acc, curr) => acc + Math.round(curr.score * 10), 0) === Math.round(totalScore * 10)
  )

  const onCreate = () => {
    let formData = new FormData();
    formData.append("file", fileList[0]);
    context.post(
      "upload/file/exam",
      formData,
      (response) => {
        context.post(
          "exam",
          {
            classId: classId,
            title: title,
            subject: subject,
            questionCount,
            timeLimit: limitTime,
            file: response,
            questions: answerList,
            startTime: moment(startDate).format("YYYY-MM-DD") + " " + startHour.toString().padStart(2, "0") + ":" + startMinute.toString().padStart(2, "0") + ":00",
            endTime: moment(endDate).format("YYYY-MM-DD") + " " + endHour.toString().padStart(2, "0") + ":" + endMinute.toString().padStart(2, "0") + ":00",
            testers: selectedStudentList.map((item) => item.memberId),
            status: 1,
          },
          (response) => {
            navigate("/study/exam");
          }
        );
      }
    );
  };

  const onAnswerType = (index, type) => {
    const newList = answerList.map((item, idx) => {
      if (idx === index) {
        const updatedItem = {
          ...item,
          questionType: type,
          correctAnswer: type === 1 ? "[]" : "",
        };

        return updatedItem;
      }

      return item;
    });
    setAnswerList(newList);
  };

  const onChangeScore = (index, score) => {
    const newList = answerList.map((item, idx) => {
      if (idx === index) {
        const updatedItem = {
          ...item,
          score: score,
        };

        return updatedItem;
      }

      return item;
    });
    setAnswerList(newList);
  };

  const onChangeAnswer = (index, answer) => {
    const newList = answerList.map((item, idx) => {
      if (idx === index) {
        const updatedItem = {
          ...item,
          correctAnswer: answer,
        };

        return updatedItem;
      }

      return item;
    });
    setAnswerList(newList);
  };

  const formatFileSize = (fileSize) => {
    const dataSize = fileSize
    let formattedData = '';

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    if (dataSize === 0) return '0 Byte';

    const k = Math.floor(Math.log(dataSize) / Math.log(1024));

    formattedData = Math.round(100 * (dataSize / Math.pow(1024, k))) / 100 + ' ' + sizes[k];
    return formattedData;
}

  const curScoreSum10Times = answerList.reduce((acc, curr) => acc + Math.round(curr.score * 10), 0);

  return (
    <Container>
      <Header>
        시험 출제
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
          {/* <Button
            style={{ marginLeft: "auto" }}
            size="large"
            onClick={() => {
              setIsOpenTempSaveAlert(true);
            }}
            text={"임시저장"}
            width="77px"
            height="36px"
          /> */}
          <Button
            style={{ marginLeft: 10 }}
            onClick={() => {
              setIsOpenCreateAlert(true);
            }}
            text={"출제하기"}
            size="large"
            disabled={!createAvailable}
            width="77px"
            height="36px"
          />
        </div>
      </Header>
      <ContentWrapper>
        <Section>
          <div style={{width: "100%", display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start", marginBottom: 24}}>
            <div style={{width: "calc(25% - 3px)", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start"}}>
              <TitleWrapper>
                {"과목"}
                <RequiredCircle/>
              </TitleWrapper>
              <SelectBox width="100%" options={Object.values(Subject).map((subject) => ({label: subject.showingName, onSelect: () => setSubject(subject.name)}))} selectedOptionLabel={subject ? Subject[subject].showingName : "과목을 선택해주세요"}/>
            </div>
            <div style={{width: "calc(75% - 9px)", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", marginLeft: 12}}>
              <TitleWrapper>
                {"시험 제목"}
                <RequiredCircle/>
              </TitleWrapper>
              <TextField
                placeholder={"시험 제목을 입력해주세요"}
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                complete={title.trim().length > 0}
              />
            </div>
          </div>
          <div style={{width: "100%", display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start", marginBottom: 24}}>
            <div style={{width: "calc(50% - 4px)", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start"}}>
              <TitleWrapper>
                {"문항 수"}
                <RequiredCircle/>
              </TitleWrapper>
              <IncludeTextNumberInput
                value={questionCount}
                onChange={(e) => {
                  const number = parseInt(e.target.value);
                  if (isNaN(number)  || number < 0) {
                    setQuestionCount(0);
                  } else if (number > 50) {
                    setQuestionCount(50);
                  } else {
                    setQuestionCount(number);
                  }
                }}
                text={"문항"}
                complete={questionCount > 0}
              />
            </div>
            <div style={{width: "calc(50% - 4px)", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", marginLeft: 8}}>
              <TitleWrapper>
                {"총 점수"}
                <RequiredCircle/>
              </TitleWrapper>
              <IncludeTextNumberInput
                value={totalScore}
                onChange={(e) => {
                  const number = parseInt(e.target.value);
                  if (isNaN(number)  || number < 0) {
                    setTotalScore(0);
                  } else {
                    setTotalScore(number);
                  } 
                }}
                text={"점"}
                complete={totalScore > 0}
              />
            </div>
          </div>
          <TitleWrapper>
              <span>시간</span>
              <RequiredCircle/>
              {(moment(`${moment(startDate).format("YYYY-MM-DD")} ${startHour.toString().padStart(2, '0')}:${startMinute.toString().padStart(2, '0')}:00`).isAfter(moment(`${moment(endDate).format("YYYY-MM-DD")} ${endHour.toString().padStart(2, '0')}:${endMinute.toString().padStart(2, '0')}:00`)))
              && <span style={{color: colors.warning, ...fonts.label4Medium, marginLeft: 4}}>종료 시간이 시작 시간보다 이전입니다.</span>}
          </TitleWrapper>
          <div style={{width: "100%", height: "100%", gap: 8, display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", marginBottom: 24, position: "relative"}}>
            <div style={{width: "100%", height: "100%", gap: 16, display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start", position: "relative"}}>
              <div style={{width: "calc(58% - 12px)", height: "100%"}}>
                  {startDate ? 
                  <>
                      <TextField value={moment(startDate).format("YYYY-MM-DD")} size="small" complete={true}/>
                      <Overlay onClick={() => setIsStartDateCalendarOpen(prev => !prev)} />
                  </> : 
                  <SelectBox options={[]} defaultOptionLabel="시작 날짜 선택" onSelect={() => setIsStartDateCalendarOpen(true)}/>}
              </div>
              <div style={{width: "calc(42% - 12px)", height: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start"}}>
                  <TextField type="number" value={isNaN(startHour) ? "0" : startHour.toString()} onChange={(e) => {
                    const number = parseInt(e.target.value);
                    if (isNaN(number) || number < 0) {
                      setStartHour(0);
                    } else if (number > 23) {
                      setStartHour(23); 
                    } else {
                      setStartHour(number);
                    }
                  }} placeholder="시간을 입력해주세요" size="small" textAlign="center" width="calc(50% - 10px)"/>
                  <span style={{color: colors.gray900, ...fonts.body3Medium, width: 20, textAlign: "center"}}>:</span>
                  <TextField type="number" value={isNaN(startMinute) ? "0" : startMinute.toString()} onChange={(e) => {
                    const number = parseInt(e.target.value);
                    if (isNaN(number) || number < 0) {
                      setStartMinute(0);
                    } else if (number > 59) {
                      setStartMinute(59);
                    } else {
                      setStartMinute(number);
                    }
                  }} placeholder="분을 입력해주세요" size="small" textAlign="center" width="calc(50% - 10px)"/>
              </div>
            </div>
            <div style={{width: "100%", height: "100%", gap: 16, display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start", position: "relative"}}>
              <div style={{width: "calc(58% - 12px)", height: "100%"}}>
                  {endDate ? 
                  <>
                      <TextField value={moment(endDate).format("YYYY-MM-DD")} size="small" complete={true}/> 
                      <Overlay onClick={() =>setIsEndDateCalendarOpen(prev => !prev)}/>
                  </>: 
                  <SelectBox options={[]} defaultOptionLabel="종료 날짜 선택" onSelect={() => setIsEndDateCalendarOpen(true)}/>}
              </div>
              <div style={{width: "calc(42% - 12px)", height: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start"}}>
                  <TextField type="number" value={isNaN(endHour) ? "0" : endHour.toString()} onChange={(e) => {
                    const number = parseInt(e.target.value);
                    if (isNaN(number) || number < 0) {
                      setEndHour(0);
                    } else if (number > 23) {
                      setEndHour(23);
                    } else {
                      setEndHour(number);
                    }
                  }} placeholder="시간을 입력해주세요" size="small" textAlign="center" width="calc(50% - 10px)"/>
                  <span style={{color: colors.gray900, ...fonts.body3Medium, width: 20, textAlign: "center"}}>:</span>
                  <TextField type="number" value={isNaN(endMinute) ? "0" : endMinute.toString()} onChange={(e) => {
                    const number = parseInt(e.target.value);
                    if (isNaN(number) || number < 0) {
                      setEndMinute(0);
                    } else if (number > 59) {
                      setEndMinute(59);
                    } else {
                      setEndMinute(number);
                    }
                  }} placeholder="분을 입력해주세요" size="small" textAlign="center" width="calc(50% - 10px)"/>
              </div>
            </div>
            {isStartDateCalendarOpen && 
                <div style={{position: "absolute", top: 56, left: 0, width: "100%", height: "100%", zIndex: 1000}}>
                    <CalendarModal onClose={() => setIsStartDateCalendarOpen(false)} dateValue={startDate} setDateValue={setStartDate} disablePreviousDays={true}/>
                </div>
            }
            {isEndDateCalendarOpen && 
                <div style={{position: "absolute", top: 114, left: 0, width: "100%", height: "100%", zIndex: 1000}}>
                    <CalendarModal onClose={() => setIsEndDateCalendarOpen(false)} dateValue={endDate} setDateValue={setEndDate} disablePreviousDays={true}/>
                </div>
            }
          </div>
          <TitleWrapper>
            {"제한 시간"}
            <RequiredCircle/>
          </TitleWrapper>
          <div style={{width: "100%", height: "100%", gap: 8, display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start", marginBottom: 24, position: "relative"}}>
            <div style={{width: "calc(50% - 4px)", height: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start"}}>
              <IncludeTextNumberInput value={Math.floor(limitTime/60)} onChange={(e) => setLimitTime(parseInt(e.target.value || "0") * 60 + limitTime % 60)} text={"시간"} complete={limitTime > 0}/>
            </div>
            <div style={{width: "calc(50% - 4px)", height: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start"}}>
              <IncludeTextNumberInput value={limitTime%60} onChange={(e) => setLimitTime(Math.floor(limitTime/60) * 60 + parseInt(e.target.value || "0"))} text={"분"} complete={limitTime > 0}/>
            </div>
          </div>
          <TitleWrapper>
            <span style={{color: colors.gray900}}>학생 추가</span>
            <RequiredCircle/>
          </TitleWrapper>
          <SearchBar placeholder="학생 이름을 검색해주세요." size="medium" width="100%" value={keyword} onChange={(e) => setKeyword(e.target.value)}/>
          <SelectedStudentWrapper isVisible={selectedStudentList.length > 0}>
              {selectedStudentList.map((student) => (
                  <SelectedStudentItem>
                      <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start"}}>
                          <ImageCircle src={getFilePath(student.profile)} size={28} style={{borderRadius: 14}}/>
                          <span style={{...fonts.label4SemiBold, color: colors.gray900, marginLeft: 12}}>{student.name}</span>
                      </div>
                      <Checkbox size="20px" checked={selectedStudentList.map((s) => s.id).includes(student.id)} 
                      setChecked={() => {setSelectedStudentList(selectedStudentList.map((s) => s.id).includes(student.id) ? selectedStudentList.filter((s) => s.id !== student.id) : [...selectedStudentList, student])}}/>
                  </SelectedStudentItem>
              ))}
          </SelectedStudentWrapper>
          <CheckBoxWrapper>
              <Checkbox checked={selectedStudentList.length === studentList.length} setChecked={() => {
                  if (selectedStudentList.length === studentList.length) {
                      setSelectedStudentList([]);
                  } else {
                      setSelectedStudentList(studentList);
                  }
              }}/>
              <span style={{...fonts.label4SemiBold, color: colors.gray900, marginLeft: 12}}>모두 선택</span>
          </CheckBoxWrapper>
          <StudentListWrapper isVisible={selectedStudentList.length > 0}>
              {studentList.filter((student) => {
                  if (keyword.length > 0) {
                      return student.name.includes(keyword);
                  }
                  return true;
              }).map((student) => (
                  <StudentItemWrapper>
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", width: "calc(100% - 20px)", height: "100%"}}>
                          <ImageCircle src={getFilePath(student.profile)} size={28} style={{borderRadius: 14}}/>
                          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center"}}>
                              <span style={{...fonts.label4SemiBold, color: colors.gray900, marginLeft: 7}}>{student.name}</span>
                              <span style={{...fonts.label5Regular, color: colors.gray500, marginLeft: 7}}>{student.email}</span>
                          </div>
                      </div>
                      <Checkbox size="20px" checked={selectedStudentList.map((s) => s.id).includes(student.id)} 
                      setChecked={() => {
                          setSelectedStudentList(selectedStudentList.map((s) => s.id).includes(student.id) ? selectedStudentList.filter((s) => s.id !== student.id) : [...selectedStudentList, student])
                          }}/>
                  </StudentItemWrapper>
              ))}
          </StudentListWrapper>
          <TitleWrapper>
            {"시험 파일(pdf)"}
            <RequiredCircle/>
          </TitleWrapper>
          <input type='file' accept='application/pdf' className='hide'
                    onChange={(e) => onFileChange(e)}
                    ref={fileRef} />
          <FileWrapper isVisible={fileList.length > 0}>
              {fileList.map((file, index) => (
                  <FileItem>
                      <img src={getFileImagePath(file.name)} alt='' style={{ width: "24px", height: "24px" }}/>
                      <div style={{ ...fonts.label4Medium, color: colors.gray600, width: 228}}>
                          {file.name.length > 35 ? file.name.slice(0, 35) + "..." : file.name}
                      </div>
                      <div style={{ ...fonts.label4Medium, color: colors.gray400, width: "calc(100% - 228px - 24px - 24px)", textAlign: "center" }}>
                          {formatFileSize(file.size)}
                      </div>
                      <div style={{ cursor: "pointer" }} onClick={() => 
                              setFileList(preList => preList.filter((item, idx) => {
                                  return idx != index
                              }))}
                      >
                          <IconX width="24px" height="24px" color={colors.gray500} />
                      </div>
                  </FileItem>
              ))}
          </FileWrapper>
          <div style={{ width: "56px", height: "56px", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", border: `1px solid ${colors.gray100}`, borderRadius: "4px", marginBottom: "24px" }} onClick={() => fileRef?.current.click()}>
              <PlusIcon width="24px" height="24px" color={colors.gray200} />
          </div>
        </Section>
        <div style={{width: "1px", height: "100%", backgroundColor: colors.gray100, margin: "0 56px"}}/>
        <Section>
          <TitleWrapper>
            {"OMR 답안지"}
            <RequiredCircle/>
            {
              curScoreSum10Times < totalScore * 10 && <span style={{color: colors.warning, ...fonts.label4Medium, marginLeft: 4}}>{`${((totalScore * 10 - curScoreSum10Times)/10).toFixed(1)}점 부족합니다.`}</span>
            }
            {
              curScoreSum10Times > totalScore * 10 && <span style={{color: colors.success, ...fonts.label4Medium, marginLeft: 4}}>{`${((curScoreSum10Times - totalScore * 10)/10).toFixed(1)}점 초과합니다.`}</span>
            }
          </TitleWrapper>
          <OMRWrapper>
            {questionCount > 0 ?
            (<>
            <OMRHeader>
              <div style={{width: "30px", textAlign: "center"}}>{"문제"}</div>
              <div style={{width: "92px", textAlign: "center"}}>{"유형"}</div>
              <div style={{width: "192px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                <div style={{width: "24px", textAlign: "center"}}>{"1"}</div>
                <div style={{width: "24px", textAlign: "center"}}>{"2"}</div>
                <div style={{width: "24px", textAlign: "center"}}>{"3"}</div>
                <div style={{width: "24px", textAlign: "center"}}>{"4"}</div>
                <div style={{width: "24px", textAlign: "center"}}>{"5"}</div>
              </div>
              <div style={{width: "48px", textAlign: "center"}}>{"배점"}</div>
            </OMRHeader>
            <OMRBody>
              {answerList.map((answer, index) => (
                <OMRItem>
                  <div style={{width: "30px", textAlign: "center"}}>{index + 1 + "번"}</div>
                  <div style={{width: "92px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>{
                    <>
                      <div style={{width: "calc(50% - 2px)", height: "100%", backgroundColor: answer.questionType === 1 ? colors.blue600 : colors.gray50, borderRadius: "4px", cursor: "pointer", textAlign: "center", color: answer.questionType === 1 ? colors.white : colors.gray600, ...fonts.label4Medium}} onClick={() => onAnswerType(index, 1)}>객관</div>
                      <div style={{width: "calc(50% - 2px)", height: "100%", backgroundColor: answer.questionType === 2 ? colors.blue600 : colors.gray50, borderRadius: "4px", cursor: "pointer", textAlign: "center", color: answer.questionType === 2 ? colors.white : colors.gray600, ...fonts.label4Medium, marginLeft: 4}} onClick={() => onAnswerType(index, 2)}>주관</div>
                    </>
                  }</div>
                  <div style={{width: "192px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                    {answer.questionType === 1 ?
                      <>
                        {Array.from({length: 5}, (_, i) => {
                          const correctAnswerArr = Array.isArray(JSON.parse(answer.correctAnswer)) ? JSON.parse(answer.correctAnswer) : [];
                          return (
                            <div style={{width: "24px", height: "24px", borderRadius: "50%", cursor: "pointer",
                              backgroundColor: correctAnswerArr.includes(i + 1) ? colors.black : colors.white, border: `1px solid ${colors.gray200}`}} 
                              onClick={() => onChangeAnswer(index, correctAnswerArr.includes(i + 1) ? JSON.stringify(correctAnswerArr.filter((item) => item !== i + 1)) : JSON.stringify([...correctAnswerArr, i + 1]))}/>
                          )
                        })}
                      </>
                      :
                      <>
                        <OMRInput type="text" value={answer.correctAnswer} onChange={(e) => onChangeAnswer(index, e.target.value)} width="100%"  />
                      </>
                    }
                  </div>
                  <div style={{width: "48px"}}>
                    <OMRInput type="number" value={answer.score} onChange={(e) => onChangeScore(index, Math.floor(parseFloat(e.target.value) * 10)/10)} width="100%" complete={answer.score > 0} textAlign="center" />
                  </div>
                </OMRItem>
              ))}
            </OMRBody>
            <OMRFooter>
              <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", backgroundColor: colors.gray100, padding: "7.5px 10px", borderRadius: "4px"}}>
                {`총 점수 ${totalScore}`}
              </div>
              <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", backgroundColor: colors.gray100, padding: "7.5px 10px", borderRadius: "4px"}}>
                {`잔여 ${((totalScore * 10 - answerList.reduce((acc, curr) =>  acc + (isNaN(curr.score) ? 0 : Math.round(curr.score * 10)), 0)) / 10).toFixed(1)}`}
              </div>
            </OMRFooter>
            </>):
            <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
              <span style={{...fonts.label4Medium, color: colors.gray600}}>문항수를 입력해주세요.</span>
            </div>
            }
          </OMRWrapper>       
        </Section>
      </ContentWrapper>
      {/* {isOpenTempSaveAlert && (
        <Modal
          size="sm"
          isOpen={isOpenTempSaveAlert}
          toggle={() => setIsOpenTempSaveAlert(!isOpenTempSaveAlert)}
        >
          <ModalBody style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", paddingTop: 48, ...fonts.body2Medium}}>
            <span>시험을 임시저장 하시겠습니까?</span>
            <span>임시저장한 시험은 출제되지 않으며,</span>
            <span>언제든지 수정할 수 있습니다.</span>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", gap: 8,  marginTop: 32}}>
              <Button size="medium" text="취소" onClick={() => setIsOpenTempSaveAlert(false)} width="112px" height="40px" textColor={colors.gray400} buttonColor={colors.gray100}/>
              <Button size="medium" text="임시저장" onClick={() => onTempSave()} width="112px" height="40px" textColor={colors.white} buttonColor={colors.blue600}/>
            </div>
          </ModalBody>
        </Modal>
      )} */}
      {isOpenCreateAlert && (
        <Modal
          size="md"
          isOpen={isOpenCreateAlert}
          toggle={() => setIsOpenCreateAlert(!isOpenCreateAlert)}
        >
          <ModalBody style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start", gap: 8, padding: 24, ...fonts.body2Medium}}>
            <span style={{marginTop: 24}}>시험을 출제 하시겠습니까?</span>
            <span>출제 하시면 시험장에 바로 업로드 됩니다.</span>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", gap: 8, ...fonts.body2Medium, marginTop: 32}}>
              <Button size="medium" text="취소" onClick={() => setIsOpenCreateAlert(false)} width="112px" height="40px" textColor={colors.gray400} buttonColor={colors.gray100}/>
              <Button size="medium" text="출제" onClick={() => onCreate()} width="112px" height="40px" textColor={colors.white} buttonColor={colors.blue600}/>
            </div>
          </ModalBody>
        </Modal>
      )}
    </Container>
  );
});

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 44px 40px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
`;

const Header = styled.div`
    font-size: ${fonts.title3SemiBold.fontSize};
    line-height: ${fonts.title3SemiBold.lineHeight};
    font-family: ${fonts.title3SemiBold.fontFamily};
    color: ${colors.gray900};
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    border-radius: 20px;
    background-color: ${colors.white};
    padding: 32px 24px 32px 32px;
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: calc(50% - 56px);
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: ${fonts.body3SemiBold.fontSize};
    line-height: ${fonts.body3SemiBold.lineHeight};
    font-family: ${fonts.body3SemiBold.fontFamily};
    color: ${colors.gray900};
    margin-bottom: 16px;
`;

const RequiredCircle = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${colors.warning};
    margin-left: 4px;
`;


const SelectedStudentWrapper = styled.div<{isVisible: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-height: 124px;
    flex-wrap: wrap;
    gap: 8px 14px; /* 14px for x-axis gap, 10px for y-axis gap */
    overflow-y: auto;
    margin-top: ${props => props.isVisible ? "16px" : "0px"};
`;

const SelectedStudentItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(25% - 14px);
    height: 40px;
    background-color: ${colors.gray50};
    border-radius: 16px;
    font-size: ${fonts.label4Medium.fontSize};
    line-height: ${fonts.label4Medium.lineHeight};
    font-family: ${fonts.label4Medium.fontFamily};
    color: ${colors.gray50};
    padding: 0 8px;
`;

const CheckBoxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 20px;
    margin-top: 16px;
    margin-bottom: 16px;
`;

const StudentListWrapper = styled.div<{isVisible: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 240px;
    overflow-y: auto;
    border: 1px solid ${colors.gray100};
    border-radius: 8px;

    &::-webkit-scrollbar {
        width: 5px; /* Adjust the width of the scrollbar */
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${colors.gray200}; /* Color of the scrollbar thumb */
        border-radius: 4px; /* Rounded corners for the thumb */
    }
    &::-webkit-scrollbar-track {
        background: transparent; /* Make the track invisible */
    }
    margin-bottom: 24px;
`;

const StudentItemWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    background-color: ${colors.white};
    padding: 16px 20px 16px 16px;
`;

const FileWrapper = styled.div<{isVisible: boolean}>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  margin-bottom: ${props => props.isVisible ? "16px" : "0px"};
`;

const FileItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 8px;
  gap: 8px;
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    background-color: transparent;
    z-index: 10;
    width: calc(58% - 12px);
`;

const OMRWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 924px;
  border: 1px solid ${colors.gray100};
  border-radius: 8px;
  padding: 0 16px;
`;

const OMRHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 32px 0 28px 0;
  font-size: ${fonts.label5Medium.fontSize};
  font-family: ${fonts.label5Medium.fontFamily};
  line-height: ${fonts.label5Medium.lineHeight};
  color: ${colors.gray500};
`;


const OMRBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 24px;
  height: 800px;
  overflow-y: auto;

  &::-webkit-scrollbar {
      width: 4px; /* Adjust the width of the scrollbar */
  }
  &::-webkit-scrollbar-thumb {
      background-color: ${colors.gray200}; /* Color of the scrollbar thumb */
      border-radius: 4px; /* Rounded corners for the thumb */
  }
  &::-webkit-scrollbar-track {
      background: transparent; /* Make the track invisible */
  }
`;

const OMRItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 24px;
`;

const OMRFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 56px;
  padding: 0 16px;
  border-top: 1px solid ${colors.gray100};
`;


const OMRInput = (props) => {
  const {value, onChange, complete, width, type, textAlign="left"} = props;
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div style={{width: width, border: `1px solid ${isFocused ? colors.blue600 : colors.gray100}`, height: "100%", borderRadius: "4px", padding: "0 8px", backgroundColor: complete ? colors.gray50 : colors.white}}>
      <input 
        type={type} 
        value={value} 
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        style={{backgroundColor: complete ? colors.gray50 : colors.white, border: "none", ...fonts.label4Medium, width: "100%", textAlign: textAlign}} 
        onChange={onChange as (e: React.ChangeEvent<HTMLInputElement>) => void}
        />
    </div>
  )
}

const IncludeTextNumberInput = (props) => {
  const {value, onChange, text, complete} = props;
  const [isFocused, setIsFocused] = useState(false);
  
  return (<InputWrapper 
    onChange={onChange as (e: React.ChangeEvent<HTMLInputElement>) => void} 
    complete={complete}
    isFocused={isFocused}
    >
      <input 
        type={"number"} 
        value={value} 
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        style={{ textAlign: 'right', width: 'auto', backgroundColor: complete ? colors.gray50 : colors.white }} 
        onChange={onChange as (e: React.ChangeEvent<HTMLInputElement>) => void}/>
      {text}
    </InputWrapper>)
}

const InputWrapper = styled.div<{isFocused?: boolean, complete?: boolean}>`
    height: 48px;
    width: 100%;
    border-radius: 8px;
    border: ${props => props.isFocused ? `1px solid ${colors.blue600}` : props.complete ? "none" : `1px solid ${colors.gray100}`};
    padding: 8px 16px;
    gap: 10px;
    font-size: ${fonts.label4Medium.fontSize};
    font-family: ${fonts.label4Medium.fontFamily};
    line-height: ${fonts.label4Medium.lineHeight};
    color: ${colors.black};
    
    background-color: ${props => props.complete ? colors.gray50 : colors.white};
    &:focus {
        background-color: ${colors.white};
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;
export default CreateExam;
