import React, {useContext, useEffect} from 'react';
import {Modal, ModalBody} from "reactstrap";
import strings from "../../../lang/strings";
import './modal.scss'
import {Page_settings} from "../../../config/page_settings";
import {getGradeFromBirthday, getTimeFromSecond} from "../../../helper/common";

const MemberInfoModal = (props) => {
    const context = useContext(Page_settings);
    const {memberInfo, setMemberInfo, now} = props;
    const curMonth = new Date().getMonth() + 1;

    const getStudyTime = (study_time, now, study_start_time) => {
        return getTimeFromSecond(study_time + (study_start_time ? Math.floor((now.getTime() - study_start_time.getTime()) / 1000) : 0));
    }

    return (
        <Modal isOpen={memberInfo  !== null || memberInfo != ""} className="member-info-modal">
            <ModalBody>
                <div className='d-flex m-b-10 align-item-center'>
                    <div className='f-s-20 f-w-600 m-l-10'>{strings.info}</div>
                    <div className='flex-grow-1'/>
                    <button className='modal-close-btn' onClick={() => setMemberInfo(null)}>
                        <img src={"/assets/image/icon_close_white.png"} alt="" className="modal-close"/>
                    </button>
                </div>
                <div className="d-flex justify-content-center m-b-20">
                    {
                        memberInfo &&
                        <img
                            className="member-profile"
                            src={memberInfo.user.profile != null ? context.loadImage(memberInfo.user.profile) : "/assets/image/chewing_plus_logo.png"}
                            alt=""/>
                    }
                </div>
                <div className="member-nick">{memberInfo?.user?.nickname ?? ""}</div>
                <div className="info-cont">
                    <div className="d-flex width-full m-b-20">
                        <div className="title">{strings.name}</div>
                        <div className="info-detail text-ellipsis">{memberInfo?.user?.name ?? ""}</div>
                    </div>
                    <div className="d-flex m-b-20">
                        <div className="title">{strings.age}</div>
                        {
                            memberInfo &&
                            <div>{getGradeFromBirthday(memberInfo.user?.birthday)}</div>
                        }
                    </div>
                    <div className="d-flex width-full">
                        <div className="title">{strings.school}</div>
                        <div className="info-detail text-ellipsis">{memberInfo?.user?.school ?? ""}</div>
                    </div>
                </div>
                <div className="f-s-18 f-w-600 m-b-10">{strings.study_time}</div>
                <div className="study-time-cont">
                    <div className="flex-1 text-center">
                        <div className="time-indicator">{strings.today}</div>
                        <div className="f-s-15">{getStudyTime(parseInt(memberInfo?.user?.studyTime ?? 0),now, memberInfo?.user?.studyStartTime)}</div>
                    </div>
                    <div className="vertical-divider"/>
                    <div className="flex-1 text-center">
                        <div className="time-indicator">{`${curMonth}월 공부량`}</div>
                        <div
                            className="f-s-15 real-time">{getStudyTime(parseInt(memberInfo?.user?.studyTimeThisMonth ?? 0),now, memberInfo?.user?.studyStartTime)}</div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default MemberInfoModal;
