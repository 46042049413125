import React from 'react';

const MyRoomItem = (props) => {
    const { item, selected, onClick, image } = props;
    return (
        <div className={"my-study-room"}
            onClick={() => onClick()}
        >
            <div className="member-count">
                <img src={"/assets/image/icon_room_circle.png"} alt="" />
                {/*<div>{item.today_count + "/" + item.total_count}</div>*/}
                <div className='room-list-item-count'>{item.today_count} 명</div> 
                {/* 변수명 today_count라고되어있는데 현재 들어가있는 인원 체크된거임 */}
                {item.secret === 2 ?
                <img className="myStudy_secret" src='/assets/image/myStudy_secret.png'/>
                : null}
                {/* 2 = 시크릿 , 1 = 오픈  */}
            </div>
            <div className="my-study-room-title">{item.title}</div>
            <div className="my-study-room-host">{item.user.name}</div>
            
            {/* {item.tag !== "" ?
                <div className='my-study-room-keyword'>
                    <div className="my-study-room-keyword-item">{item.tag.split('#')[0]}</div>
                    <div className="my-study-room-keyword-item">{item.tag.split('#')[1]}</div>
                    <div className="my-study-room-keyword-item">{item.tag.split('#')[2]}</div>
                </div>
                :
                <div className="my-study-room-keyword" />
            } */}
            {/* 키워드값 없애기 8/7 */}

            <div className={"my-study-img"} style={{ backgroundImage: "url(" + image + ")" }}></div>


        </div>
    );
}

export default MyRoomItem;
