import React from "react";

export default function CategoryButton(props) {
    return (
        <button
            className={props.selected ? "category-button-selected" : "category-button"}
            onClick={props.onClick}>
            {
                props.selected &&
                    <div className="white-dot"/>
            }
            {props.value}</button>
    );
}
