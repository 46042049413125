import React, { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import ParticipantItem from "../../../components/control/item/participant_item";
import strings from "../../../lang/strings";
import styled from 'styled-components';
import { rootStore } from "../../../mobx/store";
import { IoCloseSharp } from "react-icons/io5";
import { Socket } from "socket.io-client";

interface IRoomMember {
    user: {
        id: number;
        name: string;
        video: "ON" | "OFF";
        speaker: "ON" | "OFF";
        mirror: "ON" | "OFF";
    };
    member: number;
    videoTrack?: any;
    audioTrack?: any;
    reaction?: any;
    studyTime?: number;
    studyTimeThisMonth?: number;
}

interface IMemberListProps {
    roomId: number;
    roomOwner: number;
    socket: Socket;
    memberList: IRoomMember[];
    setShowParticipant: (boolean) => void;
    setMemberInfo: (IRoomMember) => void;
    onInvite: () => void;
}


const MemberListRightContainer = (props: IMemberListProps) => {
    const { roomId, roomOwner, socket, memberList, setShowParticipant, setMemberInfo, onInvite } = props;
    const profile_id = rootStore.getProfile.id;
    const [selectedParticipantIndex, setSelectedParticipantIndex] = useState<number>(-1);

    return (
        <RightContainerWrapper>
            <ContainerHeader>
                <div style={{display: 'flex', flexDirection: 'row', fontSize: 18}}>
                    <span style={{marginRight: 5}}>{'인원수'}</span>
                    <span>{`(${memberList.length})`}</span>
                </div>
                <button onClick={() => setShowParticipant(false)}>
                    <IoCloseSharp color="#A8ACBA" size={22} />
                </button>
            </ContainerHeader>
            <ScrollBar options={{ suppressScrollX: true }}>
                <ParticipantItemWrapper>
                {
                    memberList.map((item, idx) => {
                        return (
                            <ParticipantItem
                                key={idx}
                                item={item}
                                index={idx}
                                onKick={() =>{
                                    socket.emit('kickout', { room: roomId, member: item.user.id });
                                }}
                                selected={selectedParticipantIndex == idx}
                                setSelectedParticipantIndex={setSelectedParticipantIndex}
                                setMemberInfo={setMemberInfo}
                                isOwner={roomOwner == profile_id}
                            />
                        )
                    })
                }
                </ParticipantItemWrapper>
                <InviteButton onClick={() => onInvite()}>
                    <img src={"/assets/image/icon_person_add_white.png"} alt="" />
                    <div className="">{strings.invite}</div>
                </InviteButton>
            </ScrollBar>
            
        </RightContainerWrapper>
    );
}

export default MemberListRightContainer;


const RightContainerWrapper = styled.div`
    height: calc(100% - 133px);
    width: 350px;
    position: absolute;
    right: 0;
    background: #353443;
    margin-top: -11px;
    border-left: black 1px solid;
    z-index: 30;
    padding: 15px 20px 0px 20px;
`;

const ContainerHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    color: #A8ACBA;
    button {
        background: none;
        border: none;
        cursor: pointer;
        img {
            width: 14px;
            height: 14px;
        }
    }
`;

const InviteButton = styled.button`
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    border: 1px solid #05c182;
    border-radius: 10px;
    background: #1f4440;
    width:100%;
    height: 40px;
    margin: 0px 10px 0px 10px;
`;

const ScrollBar = styled(PerfectScrollbar)`
    height: calc(100% - 60px);
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ParticipantItemWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100% - 50px);
    .no-item {
        color: #A8ACBA;
    }
`;