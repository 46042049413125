import React, { useContext, useEffect, useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from "styled-components";
import strings from "../../../lang/strings";

import { Page_settings } from '../../../config/page_settings';
import { rootStore } from "../../../mobx/store";
import { API_URL } from "../../../config/const";
import axios from "axios";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import moment from "moment";
import PerfectScrollbar from 'react-perfect-scrollbar';

function SaveModal(props) {

  const handleClose = () => {
    props.onClose?.();
  };

  return (
    <>

      <Overlay>
        <ModalWrap>
          <div className='curriculum-save-modal'>
            <div className='title'>임시저장 되었습니다.</div>
            <div className='save-comment'>맞춤 커리큘럼 홈에서 확인할 수 있습니다.</div>
            <div className="container-bottom">
          <button className="yes-btn" onClick={()=> handleClose()}>확인</button>
        </div>
          </div>
        </ModalWrap>
      </Overlay>
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
`;

const ModalWrap = styled.div`
  width: 320px;
  height: fit-content;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  border-radius: 11px;
background: #FFF;
box-shadow: 0px 10px 20px 0px rgba(174, 174, 174, 0.25);
`;


export default SaveModal;