import React, { memo, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../../components/button';
import IconPercent from '../../components/icons/percent';
import IconPlus from '../../components/icons/plus';
import TrashCan from '../../components/icons/trash_can';
import TextField from '../../components/text_field';
import { Page_settings } from '../../config/page_settings';
import colors from '../../design/colors';
import fonts from '../../design/typography';
import SubjectSelectModal from './modals/subject_select';
import { Modal, ModalBody } from 'reactstrap';
import IconX from '../../components/icons/x';
interface ISpecialty {
    id: number;
    major: string;
    bookList: string;
    clubActivity: string;
    optionalSubject: string;
    performanceAssessment: string;
    schoolEvent: string;
    feedbacks: IFeedback[];
}

interface IFeedback {
    id: number;
    classTeacherMember: number;
    className: string;
    content: string;
    teacherName: string;
}

export default function Specialty() {
    const context: any = useContext(Page_settings);
    const [currentTab, setCurrentTab] = useState<"specialty" | "score">("specialty");

    const [edit, setEdit] = useState(false);
    const [specialty, setSpecialty] = useState<ISpecialty>({
        id: 0,
        major: "",
        bookList: "",
        clubActivity: "",
        optionalSubject: "",
        performanceAssessment: "",
        schoolEvent: "",
        feedbacks: []
    });

    const onEdit = (specialty: ISpecialty) => {
        const {id, feedbacks, ...rest} = specialty;
        context.post(
            "/specialty",
            {
                ...rest
            },
            (res: any) => {
                setEdit(false);
            }
        );
    }

    return (
        <Container>
            <Header>
                <span style={{...fonts.title2SemiBold, color: colors.gray900}}>생기부노트</span>
                {currentTab === "specialty" && <Button
                    text={edit ? "저장하기" : "수정하기"}
                    size="small"
                    onClick={() => {
                        if(edit) {
                            onEdit(specialty);
                        } else {
                            setEdit(true);
                        }
                    }}
                    width='72px'
                    height='36px'
                />}
            </Header>
            <TabWrapper>
                <Tab active={currentTab === "specialty"} onClick={() => setCurrentTab("specialty")}>
                    세부특기사항
                </Tab>
                <Tab active={currentTab === "score"} onClick={() => setCurrentTab("score")}>
                    내신 계산기
                </Tab>
            </TabWrapper>
            <ContentWrapper>
                {currentTab === "specialty" && <SpecialtyContent context={context} edit={edit} specialty={specialty} setSpecialty={setSpecialty}/>}
                {currentTab === "score" && <ReportScoreCalculator/>}
            </ContentWrapper>
        </Container>
    );
}


const SpecialtyContent = ({context, edit, specialty, setSpecialty}: {context: any, edit: boolean, specialty: ISpecialty, setSpecialty: React.Dispatch<React.SetStateAction<ISpecialty>>}) => {

    useEffect(() => {
        context.get(
            "/specialty"
        ,{}, (res: any) => {
            setSpecialty(res);
        })
    }, []);
    return (
        <>
            <TopInputWrapper>
                <TopInputTitle>지원예정학과</TopInputTitle>
                <TopInputValue type='text' value={specialty?.major ?? "-"} disabled={!edit} onChange={e => {
                    setSpecialty(prev => {
                        if(prev) {
                            return {...prev, major: e.target.value};
                        }
                        return prev;
                    });
                }}/>
            </TopInputWrapper>
            <InputBoxWrapper>
                <InputBox title='독서리스트' disabled={!edit} value={specialty?.bookList} onChange={e => {
                    setSpecialty(prev => {
                        if(prev) {
                            return {...prev, bookList: e};
                        }
                        return prev;
                    });
                }}/>
                <InputBox title='동아리 활동' disabled={!edit} value={specialty?.clubActivity} onChange={e => {
                    setSpecialty(prev => {
                        if(prev) {
                            return {...prev, clubActivity: e};
                        }
                        return prev;
                    });
                }}/>
                <InputBox title='선택 과목' disabled={!edit} value={specialty?.optionalSubject} onChange={e => {
                    setSpecialty(prev => {
                        if(prev) {
                            return {...prev, optionalSubject: e};
                        }
                        return prev;
                    });
                }}/>
                <InputBox title='수행평가' disabled={!edit} value={specialty?.performanceAssessment} onChange={e => {
                    setSpecialty(prev => {
                        if(prev) {
                            return {...prev, performanceAssessment: e};
                        }
                        return prev;
                    });
                }}/>
                <InputBox title='교내행사' disabled={!edit} value={specialty?.schoolEvent} onChange={e => {
                    setSpecialty(prev => {
                        if(prev) {
                            return {...prev, schoolEvent: e};
                        }
                        return prev;
                    });
                }}/>
            </InputBoxWrapper>
            <div style={{position: "relative", width: "100%", height: "260px", padding: "28px 24px", backgroundColor: colors.white, borderRadius: "12px", display: "flex", flexDirection: "column", alignItems: "space-between", justifyContent: "space-between"}}>
                <span style={{...fonts.label2SemiBold, color: colors.gray900}}>선생님 피드백</span>
                <div style={{width: "100%", height: 165, backgroundColor: colors.gray50, padding: "24px 16px", ...fonts.body3Medium, overflowY: "auto"}}>
                    {specialty.feedbacks && specialty.feedbacks.length > 0 ? 
                        specialty.feedbacks.map(
                            feedback => (
                                <>
                                <span style={{fontSize: 16}}>{feedback.className} {feedback.teacherName}</span>
                                <div style={{display: "flex", flexDirection: "row", width: "100%", marginTop: 10, alignItems: "flex-start"}}>
                                    <span style={{fontSize: 20}}>{feedback.teacherName}  </span>
                                    <span style={{fontSize: 20, margin: "0 10px"}}> : </span>
                                    <textarea disabled={true} style={{width: "calc(100% - 100px)", minHeight: 100, }} value={feedback.content}/>
                                </div>
                                </>
                        )
                        ) : ""}
                </div>
            </div>
        </>
    )
}

interface subject {
    type: "국어" | "수학" | "영어" | "사회" | "과학";
    name: string;
    time_1: number;
    rank_1: number; 
    time_2: number;
    rank_2: number;
    deletable: boolean;
}

const ReportScoreCalculator = () => {
    const [gradePercent, setGradePercent] = useState<number[]>([NaN, NaN, NaN]);
    const setPercent = (index: number, percent: number) => {
        const newGradePercent = [...gradePercent];
        newGradePercent[index] = percent;
        setGradePercent(newGradePercent);
    };

    const [firstGradeScore, setFirstGradeScore] = useState<subject[]>(
        [{type: "국어", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
        {type: "수학", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
        {type: "영어", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
        {type: "사회", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
        {type: "과학", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false}]);

    const [secondGradeScore, setSecondGradeScore] = useState<subject[]>(
        [{type: "국어", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "수학", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "영어", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "사회", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "과학", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false}]
    );

    const [thirdGradeScore, setThirdGradeScore] = useState<subject[]>(
        [{type: "국어", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "수학", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "영어", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "사회", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "과학", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false}]
    );
        
    const [isSubjectSelectModalOpen, setSubjectSelectModalOpen] = useState<boolean>(false);
    const [currentModalType, setCurrentModalType] = useState<subject["type"] | null>(null);
    const [currentModalGrade, setCurrentModalGrade] = useState<number | null>(null);
    const [currentIndex, setCurrentIndex] = useState<number | null>(null);
    const [isResultModalOpen, setResultModalOpen] = useState<boolean>(false);

    const openModal = (type: subject["type"], grade: number, index: number) => {
        setCurrentModalType(type);
        setCurrentModalGrade(grade);
        setCurrentIndex(index);
        setSubjectSelectModalOpen(true);
    };

    const handleConfirm = (selectedName: string) => {
        if (currentIndex !== null && currentModalType !== null) {
            if (currentModalGrade === 0) {
                const newSubjectList = [...firstGradeScore];
                newSubjectList[currentIndex] = {...newSubjectList[currentIndex], name: selectedName};
                setFirstGradeScore(newSubjectList);
            } else if (currentModalGrade === 1) {
                const newSubjectList = [...secondGradeScore];
                newSubjectList[currentIndex] = {...newSubjectList[currentIndex], name: selectedName};
                setSecondGradeScore(newSubjectList);
            } else if (currentModalGrade === 2) {
                const newSubjectList = [...thirdGradeScore];
                newSubjectList[currentIndex] = {...newSubjectList[currentIndex], name: selectedName};
                setThirdGradeScore(newSubjectList);
            }
        }
        setSubjectSelectModalOpen(false);
    };

    const handleClose = () => {
        setSubjectSelectModalOpen(false);
    };

    const [firstGradeScoreAverage, setFirstGradeScoreAverage] = useState<number>(NaN);
    const [secondGradeScoreAverage, setSecondGradeScoreAverage] = useState<number>(NaN);
    const [thirdGradeScoreAverage, setThirdGradeScoreAverage] = useState<number>(NaN);
    const [totalScore, setTotalScore] = useState<number>(NaN);
    const [isResetModalOpen, setResetModalOpen] = useState<boolean>(false);
    const onCalculate = () => {

        const firstGradeScoreSum = firstGradeScore.reduce((acc, cur) => {
            let addingNum = 0;
            if (!Number.isNaN(cur.time_1) && !Number.isNaN(cur.rank_1)) {
                addingNum += cur.time_1 * cur.rank_1;
            }
            if (!Number.isNaN(cur.time_2) && !Number.isNaN(cur.rank_2)) {
                addingNum += cur.time_2 * cur.rank_2;
            }
            return acc + addingNum;
        }, 0);
        const firstGradeScoreTimeSum = firstGradeScore.reduce((acc, cur) => {
            let addingNum = 0;
            if (!Number.isNaN(cur.time_1) && !Number.isNaN(cur.rank_1)) {
                addingNum += cur.time_1;
            }
            if (!Number.isNaN(cur.time_2) && !Number.isNaN(cur.rank_2)) {
                addingNum += cur.time_2;
            }
            return acc + addingNum;
        }, 0);
        const firstGradeScoreAverageCur = firstGradeScoreSum / firstGradeScoreTimeSum;
        setFirstGradeScoreAverage(firstGradeScoreAverageCur);

        const secondGradeScoreSum = secondGradeScore.reduce((acc, cur) => {
            let addingNum = 0;
            if (!Number.isNaN(cur.time_1) && !Number.isNaN(cur.rank_1)) {
                addingNum += cur.time_1 * cur.rank_1;
            }
            if (!Number.isNaN(cur.time_2) && !Number.isNaN(cur.rank_2)) {
                addingNum += cur.time_2 * cur.rank_2;
            }
            return acc + addingNum;
        }, 0);
        const secondGradeScoreTimeSum = secondGradeScore.reduce((acc, cur) => {
            let addingNum = 0;
            if (!Number.isNaN(cur.time_1) && !Number.isNaN(cur.rank_1)) {
                addingNum += cur.time_1;
            }
            if (!Number.isNaN(cur.time_2) && !Number.isNaN(cur.rank_2)) {
                addingNum += cur.time_2;
            }
            return acc + addingNum;
        }, 0);
        const secondGradeScoreAverageCur = secondGradeScoreSum / secondGradeScoreTimeSum;
        setSecondGradeScoreAverage(secondGradeScoreAverageCur);

        const thirdGradeScoreSum = thirdGradeScore.reduce((acc, cur) => {
            let addingNum = 0;
            if (!Number.isNaN(cur.time_1) && !Number.isNaN(cur.rank_1)) {
                addingNum += cur.time_1 * cur.rank_1;
            }
            if (!Number.isNaN(cur.time_2) && !Number.isNaN(cur.rank_2)) {
                addingNum += cur.time_2 * cur.rank_2;
            }
            return acc + addingNum;
        }, 0);
        const thirdGradeScoreTimeSum = thirdGradeScore.reduce((acc, cur) => {
            let addingNum = 0;
            if (!Number.isNaN(cur.time_1) && !Number.isNaN(cur.rank_1)) {
                addingNum += cur.time_1;
            }
            if (!Number.isNaN(cur.time_2) && !Number.isNaN(cur.rank_2)) {
                addingNum += cur.time_2;
            }
            return acc + addingNum;
        }, 0);
        const thirdGradeScoreAverageCur = thirdGradeScoreSum / thirdGradeScoreTimeSum;
        setThirdGradeScoreAverage(thirdGradeScoreAverageCur);

        console.log(gradePercent[0], gradePercent[1], gradePercent[2]);

        console.log(firstGradeScoreAverageCur, secondGradeScoreAverageCur, thirdGradeScoreAverageCur);
        setTotalScore(
            !Number.isNaN(gradePercent[0]) && !Number.isNaN(gradePercent[1]) && !Number.isNaN(gradePercent[2]) && (gradePercent[0] + gradePercent[1] + gradePercent[2] === 100) ?
            (firstGradeScoreAverageCur * gradePercent[0] + secondGradeScoreAverageCur * gradePercent[1] + thirdGradeScoreAverageCur * gradePercent[2])/(gradePercent[0] + gradePercent[1] + gradePercent[2]) :
            NaN
        );
        setResultModalOpen(true);
    }

    const onReset = () => {
        setGradePercent([NaN, NaN, NaN]);
        setFirstGradeScore([{type: "국어", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "수학", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "영어", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "사회", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "과학", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false}]);
        setSecondGradeScore([{type: "국어", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "수학", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "영어", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "사회", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "과학", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false}]);
        setThirdGradeScore([{type: "국어", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "수학", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "영어", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "사회", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false},
            {type: "과학", name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: false}]);
        setFirstGradeScoreAverage(NaN);
        setSecondGradeScoreAverage(NaN);
        setThirdGradeScoreAverage(NaN);
        setTotalScore(NaN);
    }

    
    return (
        <>
            <div style={{width: "100%", display: "flex", justifyContent: "flex-end", marginBottom: 8, height: 36}}>
                <Button onClick={onCalculate} size="small" text="성적 산출하기" width='103px'/>
                <Button onClick={() => setResetModalOpen(true)} size="small" text="초기화" width='66px' style={{marginLeft: 8}} buttonColor={colors.gray100} textColor={colors.gray500}/>
            </div>
            <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%", }}>
                <GradePercentRow grade={1} percent={gradePercent[0]} setPercent={(percent) => setPercent(0, percent)} />
                <GradePercentTable gradeIndex={0} subjectList={firstGradeScore} setSubjectList={(subjectList) => setFirstGradeScore(subjectList)} openModal={openModal}/>
            </div>
            <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%", marginTop: 16}}>
                <GradePercentRow grade={2} percent={gradePercent[1]} setPercent={(percent) => setPercent(1, percent)} />
                <GradePercentTable gradeIndex={1} subjectList={secondGradeScore} setSubjectList={(subjectList) => setSecondGradeScore(subjectList)} openModal={openModal}/>
            </div>
            <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%", marginTop: 16}}>
                <GradePercentRow grade={3} percent={gradePercent[2]} setPercent={(percent) => setPercent(2, percent)} />
                <GradePercentTable gradeIndex={2} subjectList={thirdGradeScore} setSubjectList={(subjectList) => setThirdGradeScore(subjectList)} openModal={openModal}/>
            </div>
            <SubjectSelectModal 
                show={isSubjectSelectModalOpen}
                type={currentModalType!}
                onConfirm={handleConfirm}
                onClose={handleClose}
            />
            {isResultModalOpen && 
                <ReportScoreResult firstGradeScore={firstGradeScoreAverage} secondGradeScore={secondGradeScoreAverage} thirdGradeScore={thirdGradeScoreAverage} totalScore={totalScore} toggle={() => setResultModalOpen(false)}/>
            }
            {
                isResetModalOpen &&
                <ResetModal closeResetModal={() => setResetModalOpen(false)} onReset={onReset}/>
            }
        </>
    )
}

// Memoize GradePercentRow to prevent unnecessary re-renders
const GradePercentRow = memo((props: {
    grade: number;
    percent: number;
    setPercent: (percent: number) => void;
}) => {
    const { grade, percent } = props;
    return (
        <GradePercentRowWrapper>
            <div style={{width: 45, ...fonts.label3SemiBold}}>{grade}학년</div>
            <TextField value={isNaN(percent) ? "" : percent.toString()} placeholder="값을 입력하세요" onChange={(e) => props.setPercent(parseInt(e.target.value))} width="172px" size='medium' />
            <div style={{position: "absolute", right: 14, top: 8}}>
                <IconPercent width="24px" height="24px" color={colors.gray500}/>
            </div>
        </GradePercentRowWrapper>
    )
});

// Memoize GradePercentTable to enhance performance
const GradePercentTable = memo((props: {
    gradeIndex: number;
    subjectList: subject[];
    setSubjectList: (subjectList: subject[]) => void;
    openModal: (type: subject["type"], grade: number, index: number) => void;
}) => {
    const {gradeIndex, subjectList, setSubjectList, openModal} = props;

    const [subjectCountArray, setSubjectCountArray] = useState<number[]>([1,1,1,1,1]);
    const addRowAfterIndex = (index: number, type:  "국어" | "수학" | "영어" | "사회" | "과학") => {
        const before = subjectList.slice(0, index + 1);
        const after = subjectList.slice(index + 1);
        setSubjectList([...before, {type: type, name: "", time_1: NaN, rank_1: NaN, time_2: NaN, rank_2: NaN, deletable: true}, ...after]);
        const prev = subjectCountArray;
        switch (type) {
            case "국어":
                setSubjectCountArray([prev[0] + 1, prev[1], prev[2], prev[3], prev[4]]);
                break;
            case "수학":
                setSubjectCountArray([prev[0], prev[1] + 1, prev[2], prev[3], prev[4]]);
                break;
            case "영어":
                setSubjectCountArray([prev[0], prev[1], prev[2] + 1, prev[3], prev[4]]);
                break;
            case "사회":
                setSubjectCountArray([prev[0], prev[1], prev[2], prev[3] + 1, prev[4]]);
                break;
            case "과학":
                setSubjectCountArray([prev[0], prev[1], prev[2], prev[3], prev[4] + 1]);
                break;
        }
    }
    const deleteRowAtIndex = (index: number) => {
        const targetSubject = subjectList[index];
        const before = subjectList.slice(0, index);
        const after = subjectList.slice(index + 1);
        setSubjectList([...before, ...after]);
        const prev = subjectCountArray;
        switch(targetSubject.type) {
            case "국어":
                setSubjectCountArray([prev[0] - 1, prev[1], prev[2], prev[3], prev[4]]);
                break;
            case "수학":
                setSubjectCountArray([prev[0], prev[1] - 1, prev[2], prev[3], prev[4]]);
                break;
            case "영어":
                setSubjectCountArray([prev[0], prev[1], prev[2] - 1, prev[3], prev[4]]);
                break;
            case "사회":
                setSubjectCountArray([prev[0], prev[1], prev[2], prev[3] - 1, prev[4]]);
                break;
            case "과학":
                setSubjectCountArray([prev[0], prev[1], prev[2], prev[3], prev[4] - 1]);
                break;
        }
    }

    const setSubjectAtIndex = (index: number, new_subject:subject) => {
        const newSubjectList = [...subjectList];
        newSubjectList[index] = new_subject;
        setSubjectList(newSubjectList);
    }


    return (
        <Table>
            <tr>
                <th rowSpan={2}>교과</th>
                <th rowSpan={2} >교과목</th>
                <th colSpan={2}>1학기</th>
                <th colSpan={2}>2학기</th>
                <th rowSpan={2}>삭제</th>
            </tr>
            <tr>
                <th>이수단위</th>
                <th>과목등급</th>
                <th>이수단위</th>
                <th>과목등급</th>
            </tr>
            {
                subjectCountArray.map((count, countIndex) => {

                    const prevSubjectCount = countIndex === 0 ? 0 : Array.from({ length: countIndex }, (_, i) => i).map(i => subjectCountArray[i]).reduce((acc, cur) => acc + cur, 0);
                    const index = prevSubjectCount;
                    const subject = subjectList[index];
                    if(subject === undefined) {
                        return null;
                    }
                    let typeName = "";
                    switch(countIndex) {
                        case 0:
                            typeName = "국어";
                            break;
                        case 1:
                            typeName = "수학";
                            break;
                        case 2:
                            typeName = "영어";
                            break;
                        case 3:
                            typeName = "사회";
                            break;
                        case 4:
                            typeName = "과학";
                    }
                    
                    // First create an array of all rows for this subject type
                    const rows = [];
                    
                    // First row with subject type cell
                    rows.push(
                        <tr key={`${countIndex}-0`}>
                            <td rowSpan={count}>
                                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}} >
                                    <div style={{...fonts.label4Medium}}>{typeName}</div>
                                    <div style={{width: 20, height: 20, backgroundColor: colors.gray100, borderRadius: "50%", marginLeft: 10, cursor: "pointer"}} onClick={() => addRowAfterIndex(prevSubjectCount, subject.type)}>
                                        <IconPlus width="16px" height="16px" color={colors.gray500}/>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div style={{display: "flex", flexDirection: "row", position: "relative", padding: "0 16px", width: "100%", height: "100%", alignItems: "center", justifyContent: "flex-start", cursor: "pointer", border: `1px solid ${colors.gray100}`, borderRadius: 8, ...fonts.label4Medium}} onClick={() => openModal(subject.type, gradeIndex, index)}>
                                    {subject.name === "" ? <span style={{color: colors.gray500}}>교과목 추가</span> : <span style={{color: colors.gray900}}>{subject?.name}</span>}
                                </div>
                            </td>
                            <td><TextField type='number' placeholder='이수단위를 입력하세요.' size='medium' value={subject.time_1.toString()}
                                onChange={(e) => setSubjectAtIndex(index, {...subject, time_1: parseInt(e.target.value)})}
                            /></td>
                            <td><TextField type='number' placeholder='과목등급을 입력하세요.' size='medium' value={subject.rank_1.toString()}
                                onChange={(e) => setSubjectAtIndex(index, {...subject, rank_1: parseInt(e.target.value)})}
                            /></td>
                            <td><TextField type='number' placeholder='이수단위를 입력하세요.' size='medium' value={subject.time_2.toString()}
                                onChange={(e) => setSubjectAtIndex(index, {...subject, time_2: parseInt(e.target.value)})}
                            /></td>
                            <td><TextField type='number' placeholder='과목등급을 입력하세요.' size='medium' value={subject.rank_2.toString()}
                                onChange={(e) => setSubjectAtIndex(index, {...subject, rank_2: parseInt(e.target.value)})}
                            /></td>
                            <td>
                            </td>
                        </tr>
                    );
                    
                    // Additional rows for the same subject type
                    if (count > 1) {
                        for (let i = 1; i < count; i++) {
                            const subjectIdx = prevSubjectCount + i;
                            const rowSubject = subjectList[subjectIdx];
                            
                            if (rowSubject === undefined) {
                                continue;
                            }
                            
                            rows.push(
                                <tr key={`${countIndex}-${i}`}>
                                    <td>
                                        <div style={{display: "flex", flexDirection: "row", position: "relative", padding: "0 16px", width: "100%", height: "100%", alignItems: "center", justifyContent: "flex-start", cursor: "pointer", border: `1px solid ${colors.gray100}`, borderRadius: 8, ...fonts.label4Medium}} 
                                        onClick={() => openModal(rowSubject.type, gradeIndex, subjectIdx)}>
                                            {rowSubject.name === "" ? <span style={{color: colors.gray500}}>교과목 추가</span> : <span style={{color: colors.gray900}}>{rowSubject?.name}</span>}
                                        </div>
                                    </td>
                                    <td>
                                        <TextField type='number' placeholder='이수단위를 입력하세요.' size='medium' value={rowSubject.time_1.toString()}
                                            onChange={(e) => setSubjectAtIndex(subjectIdx, {...rowSubject, time_1: parseInt(e.target.value)})}
                                        />
                                    </td>
                                    <td>
                                        <TextField type='number' placeholder='과목등급을 입력하세요.' size='medium' value={rowSubject.rank_1.toString()}
                                            onChange={(e) => setSubjectAtIndex(subjectIdx, {...rowSubject, rank_1: parseInt(e.target.value)})}
                                        />
                                    </td>
                                    <td>
                                        <TextField type='number' placeholder='이수단위를 입력하세요.' size='medium' value={rowSubject.time_2.toString()}
                                            onChange={(e) => setSubjectAtIndex(subjectIdx, {...rowSubject, time_2: parseInt(e.target.value)})}
                                        />
                                    </td>
                                    <td>
                                        <TextField type='number' placeholder='과목등급을 입력하세요.' size='medium' value={rowSubject.rank_2.toString()}
                                            onChange={(e) => setSubjectAtIndex(subjectIdx, {...rowSubject, rank_2: parseInt(e.target.value)})}
                                        />
                                    </td>
                                    <td>
                                        {rowSubject.deletable && 
                                            <div style={{cursor: "pointer"}} onClick={() => deleteRowAtIndex(subjectIdx)}>
                                                <TrashCan width="24px" height="24px" color={colors.gray400}/>
                                            </div>
                                        }
                                    </td>
                                </tr>
                            );
                        }
                    }
                    
                    return rows;
                })
            }
        </Table>
    )
})

const ReportScoreResult = memo((props: {
    firstGradeScore: number;
    secondGradeScore: number;
    thirdGradeScore: number;
    totalScore: number;
    toggle: () => void;
}) => {
    const {firstGradeScore, secondGradeScore, thirdGradeScore, totalScore} = props;

    const WarningSpan = styled.span`
    font-family: Pretendard;
    font-weight: 800;
    font-size: 22px;
    line-height: 20px;
    letter-spacing: 0%;
    text-align: center;
    color: ${colors.warning};
    `;
    return (
        <Modal isOpen={true} toggle={props.toggle}>
            <ModalBody style={{padding: "20px 24px", display:'flex', flexDirection: "column", width: "100%"}}>
                {
                    Number.isNaN(totalScore) ?
                    <div style={{display: "flex", flexDirection: "column", width: "100%", alignItems: "center", justifyContent: "center"}}>
                        <div style={{display: "flex", flexDirection: "row", width: "100%", alignItems: "center", justifyContent: "flex-end"}}>
                            <div style={{cursor: "pointer"}} onClick={props.toggle}>
                                <IconX width="24px" height="24px" color={colors.gray900}/>
                            </div>
                        </div>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: 40, position: "relative"}}>
                            <img src="/assets/image/warning_rai.png" style={{width: "172px", height: "184px", marginLeft: 30}}/>
                            <div style={{position: "absolute", top: 37.5, left: 134, display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <WarningSpan>잠깐!</WarningSpan>
                            </div>
                        </div>
                        <span style={{...fonts.body3SemiBold, color: colors.gray800, marginTop: 45}}>학년별 비율이 100%가 아닙니다.</span>
                        <span style={{...fonts.body3Medium, color: colors.gray600, marginBottom: 40}}>비율을 다시 입력해주세요.</span>
                        <Button onClick={props.toggle} size="large" text="확인" width='100%' height='44px' />
                    </div>
                    :
                    <>
                        <div style={{display: "flex", flexDirection: "row", width: "100%", alignItems: "center", justifyContent: "space-between"}}>
                            <span style={{fontSize: 15, fontWeight: "bolder"}}>성적 산출</span>
                            <div style={{cursor: "pointer"}} onClick={props.toggle}>
                                <IconX width="24px" height="24px" color={colors.gray900}/>
                            </div>
                        </div>
                        <ResultTable>
                            <thead>
                                <tr>
                                    <th>학년</th>
                                    <th>등급</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>{Number.isNaN(firstGradeScore) ? 0 : firstGradeScore.toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>{Number.isNaN(secondGradeScore) ? 0 : secondGradeScore.toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>{Number.isNaN(thirdGradeScore) ? 0 : thirdGradeScore.toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </ResultTable>
                        <span style={{...fonts.body3SemiBold, color: colors.gray800, marginBottom: 12}}>합산등급 성적</span>
                        <TextField value={Number.isNaN(totalScore) ? "0" : totalScore.toFixed(2)+ " 등급"} size='medium' width='100%' disabled={true}/>
                    </>
                }
            </ModalBody>
        </Modal>
    )
});

const GradePercentRowWrapper = styled.div`
    width: 217px;
    height: 40px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    padding: 10px 5px;
    border-radius: 8px;
    align-items: center;
    position: relative;
`


const Table = styled.table`
    width: 100%;
    border-radius: 8px;
    border-collapse: separate;
    th, td {
        height: 50px;
        text-align: center;
        vertical-align: middle;
        padding: 8px 10px;
    }
    th {
        font-size: ${fonts.label4Medium.fontSize};
        font-family: ${fonts.label4Medium.fontFamily};
        line-height: ${fonts.label4Medium.lineHeight};
        color: ${colors.gray800};
        background-color: ${colors.blue50};
    }

    td {
        font-size: ${fonts.label4SemiBold.fontSize};
        font-family: ${fonts.label4SemiBold.fontFamily};
        line-height: ${fonts.label4SemiBold.lineHeight};
        color: ${colors.gray900};
        background-color: ${colors.white};
    }
`;

const ResultTable = styled.table`
    width: 100%;
    margin-top: 20px;
    margin-bottom: 50px;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    
    th, td {
        height: 50px;
        text-align: center;
        vertical-align: middle;
        padding: 8px 10px;
    }
    
    th {
        font-size: ${fonts.label4Medium.fontSize};
        font-family: ${fonts.label4Medium.fontFamily};
        line-height: ${fonts.label4Medium.lineHeight};
        background-color: ${colors.blue50};
        color: ${colors.gray800};
        border-top: 1px solid ${colors.gray100};
    }
    
    td {
        font-size: ${fonts.label4SemiBold.fontSize};
        font-family: ${fonts.label4SemiBold.fontFamily};
        line-height: ${fonts.label4SemiBold.lineHeight};
        color: ${colors.gray900};
        border-bottom: 1px solid ${colors.gray100};
    }

`;

// Move ButtonWrapper outside to avoid recreation on each render
const ButtonWrapper = styled.div`
    position: absolute;
    top: 10px;
    right: 20px;
    padding: 0;
    font-size: 12px;
    cursor: pointer;
    width: 70px;
    height: calc(100% - 20px);
    background: #E0E0E0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 40px;
    overflow-y: auto;
`;

const Header = styled.div`
    width: 100%;
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
`;

const TabWrapper = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid ${colors.gray100};
`;

const Tab = styled.div<{active: boolean}>`
    width: 126px;
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: ${props => props.active ? fonts.body3SemiBold.fontSize : fonts.body3Medium.fontSize};
    font-family: ${props => props.active ? fonts.body2SemiBold.fontFamily : fonts.body2Medium.fontFamily};
    line-height: ${props => props.active ? fonts.body3SemiBold.lineHeight : fonts.body3Medium.lineHeight};
    color: ${props => props.active ? colors.gray900 : colors.gray600};
    cursor: pointer;
    border-bottom: ${props => props.active ? `2px solid ${colors.gray900}` : "none"};
`;

const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
    gap: 16px;
`;

const InputBoxWrapper = styled.div`
    width: 100%;
    height: 886px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 18px 24px;
    background-color: ${colors.white};
    border-radius: 12px;
    margin-top: 16px;
`;

const TopInputWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 52px;
    align-items: center;
    border-radius: 12px;
    background-color: ${colors.white};
`;

const TopInputTitle = styled.div`
    width: 15%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px 0px 0px 12px;
    font-size: ${fonts.label1SemiBold.fontSize};
    font-family: ${fonts.label1SemiBold.fontFamily};
    line-height: ${fonts.label1SemiBold.lineHeight};
    color: ${colors.blue600};
`;

const TopInputValue = styled.input`
    width: 85%;
    height: 100%;
    border-radius: 0px 12px 12px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: ${fonts.label1SemiBold.fontSize};
    font-family: ${fonts.label1SemiBold.fontFamily};
    line-height: ${fonts.label1SemiBold.lineHeight};
    color: ${colors.gray900};

    &:disabled {
        background-color: ${colors.white};
    }
`;
const InputBox = (
    props : {
        title: string;
        value: string;
        onChange: (e: string) => void;
        disabled: boolean;
    }
) => {
    const {title, value, onChange, disabled} = props;
    
    return (
        <div style={{width: "100%", height: 158, margin: "6px 0", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
            <div style={{display: "flex", flexDirection:"row", width: "200px", height: "100%", position:"relative", alignItems: "center", justifyContent: "center", backgroundColor: colors.blue50, borderRadius: "8px 0 0 8px"}}>
                <span style={{...fonts.label2SemiBold, color: colors.gray900}}>{title}</span>
            </div>
            <InputBoxTextArea value={value} disabled={disabled} onChange={e => onChange(e.target.value)}/>
        </div>
    );
}

const InputBoxTextArea = styled.textarea`
    width: calc(100% - 200px);
    height: 100%;
    background-color: ${colors.gray50};
    padding: 24px 16px;
    font-size: ${fonts.body2Medium.fontSize};
    font-family: ${fonts.body2Medium.fontFamily};
    line-height: ${fonts.body2Medium.lineHeight};
    color: ${colors.gray900};
    border-radius: 0 0 8px 8px;
`;

const ResetModal = ({ closeResetModal, onReset }: { closeResetModal: () => void, onReset: () => void }) => {

    return (
        <Modal isOpen={true} toggle={closeResetModal} size="sm">
            <ModalBody style={{padding: "24px 40px"}}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginBottom: 32, marginTop: 48}}>
                    <div style={{...fonts.body2Medium, color: colors.gray900}}>입력한 성적을 초기화하시겠습니까?</div>
                </div>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 12}}>
                    <Button size="medium" width="112px" height="44px" text="취소" onClick={closeResetModal} buttonColor={colors.gray100} textColor={colors.gray400}/>
                    <Button size="medium" width="112px" height="44px" text="초기화" onClick={onReset} buttonColor={colors.warning}/>
                </div>
            </ModalBody>
        </Modal>
    )
}