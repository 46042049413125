import React, { useContext, useEffect, useRef, useState } from "react";
import strings from "../../lang/strings";
import MyRoomItem from "../../components/control/item/my_room_item";
import RoomCategoryButton from "../../components/control/button/room_category_button";
import CarouselButton from "../../components/control/button/carousel_button";
import CustomSearch from "../../components/control/custom_input/custom_search";
import SubCategoryButton from "../../components/control/button/sub_category_button";
import CategoryRoomItem from "../../components/control/item/category_room_item";
import LoadMoreButton from "../../components/control/button/load_more_button";
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import { withRouter } from "react-router-dom";
import { Page_settings } from "../../config/page_settings";
import { rootStore } from "../../mobx/store";
import { observer } from "mobx-react";
import PasswordModal from "../../components/control/modal/password_modal";
import Dropdown from 'react-bootstrap/Dropdown';
import moment from "moment";
import MyScore from "../dashboard/myScore";
import SetGoalModal from "../../components/control/modal/set_goal_modal";



const Challenge = observer((props) => {
    const context = useContext(Page_settings);
    const [goalToday, setGoalToday] = useState('');
    const [studyRoom, setStudyRoom] = useState("all");
    const [allRoomList, setAllRoomList] = useState([]);
    const [roomStatus, setRoomStatus] = useState(0);
    const [sort, setSort] = useState(0);
    const [roomSelected, setRoomSelected] = useState(1);
    const [keyword, setKeyword] = useState("");
    const [subCategory, setSubCategory] = useState(0);
    const [categoryRoomList, setCategoryRoomList] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [loadMoreHide, setLoadMoreHide] = useState(false);
    const [userName, setUserName] = useState("");
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [roomTemp, setRoomTemp] = useState("");
    const goalRef = useRef();
    const idCarousel = useRef();
    const [edit, setEdit] = useState(true);
    const [totalRoom, setTotalRoom] = useState(0);
    const [searchStatus, setSearchStatus] = useState(false);
    const [selectCategory, setSelectCategory] = useState(strings.room_new_category_list[0].name);
    const [searchWord, setSearchWord] = useState('');
    const [todoTotal, setTodoTotal] = useState(0);
    const [todoDone, setTodoDone] = useState(0);
    const [goalAchToday, setGoalAchToday] = useState(0);
    const [realTimeToday, setRealTimeToday] = useState(0);
    const [efficiencyToday, setEfficiencyToday] = useState(0);
    const [realTimeYesterday, setRealTimeYesterday] = useState(0);
    const [goalAchYesterday, setGoalAchYesterday] = useState(0);
    const [efficiencyYesterday, setEfficiencyYesterday] = useState(0);
    const [totalTimeToday, setTotalTimeToday] = useState(0);
    const [totalTimeYesterday, setTotalTimeYesterday] = useState(0);
    const [showGoalModal, setShowGoalModal] = useState(false);

    const userId = rootStore.getProfile.id;

    const getCompareData = () => {
        const from = moment().format('YYYY-MM-DD');
        const to = moment().format('YYYY-MM-DD');
        context.get(
            "datalab/getCompareData",
            {
                userId: userId,
                mode: 'HOUR',
                from,
                to,
            },
            response => {
                setRealTimeToday(parseInt(response.today.study_real_time));
                setRealTimeYesterday(parseInt(response.yesterday.study_real_time) / 3600);
                setGoalAchToday(response.today.todo_total_count != 0 ? response.today.todo_complete_count / response.today.todo_total_count : 0);
                setGoalAchYesterday(response.yesterday.todo_total_count != 0 ? response.yesterday.todo_complete_count / response.yesterday.todo_total_count : 0);
                setEfficiencyToday(parseInt(response.today.study_total_time) != 0 ? parseInt(response.today.study_real_time) / parseInt(response.today.study_total_time) : 0)
                setEfficiencyYesterday(parseInt(response.yesterday.study_total_time) != 0 ? parseInt(response.yesterday.study_real_time) / parseInt(response.yesterday.study_total_time) : 0)
                setTodoDone(response.today.todo_complete_count);
                setTodoTotal(response.today.todo_total_count);
                setTotalTimeYesterday(parseInt(response.yesterday.study_total_time) / 3600);
                setTotalTimeToday(parseInt(response.today.study_total_time) / 3600);
            }
        );
    }

    const handleBack = () => {
        props.history.push("/room/study");
        setTimeout(() => props.history.push("/room/study/conference"), 0.1);
    };

    useEffect(() => {
        setUserName(rootStore.getProfile?.name ?? "")
    }, [rootStore.getProfile])

    const loadRoomList = (mine, secret, type, sort, page_num, keyword) => {
        context.get(
            'room/list2',
            {
                mode: 2,
                mine: mine,
                secret: secret,
                type: type,
                sort: sort,
                page_num: page_num,
                keyword: keyword
            },
            response => {
                setTotalRoom(response.total_count);
                if (mine != null) {
                    setAllRoomList(response.list.slice(0, 5));
                } else {
                    if (page_num == 1) {
                        setCategoryRoomList(response.list);
                    } else {
                        setCategoryRoomList([...categoryRoomList, ...response.list]);
                    }
                }
                if ((response.list.length < response.limit) && (mine == null)) {
                    setLoadMoreHide(true);
                }
            }
        );
    }

    useEffect(() => {
        loadRoomList("all", 0, 0, 0, 1, "");
        loadRoomList(null, roomStatus, sort, subCategory, 1, "");
        getCompareData();
        getGoal();
    }, []);

    useEffect(() => {
        if (!edit) {
            goalRef?.current.focus()
        }
    }, [edit]);

    useEffect(() => {
        loadRoomList(studyRoom, 0, 0, 0, 1, keyword);
    }, [studyRoom]);

    useEffect(() => {
        loadRoomList(null, roomStatus, sort, subCategory, 1, keyword);
        setPageNum(1);
        setLoadMoreHide(false);
    }, [roomStatus, sort, subCategory,]);

    const onLoadMore = () => {
        loadRoomList(null, roomStatus, sort, subCategory, pageNum + 1, keyword);
        setPageNum(pageNum + 1)
    }
    
    const getGoal = () => {
        context.get(
            "member/getGoal",
            {},
            response => {
                if (response.goal_info != null) {
                    setGoalToday(response.goal_info.title);
                }
            }
        );
    }

    const updateGoal = () => {
        context.post(
            "member/updateGoal",
            {
                title: goalToday,
            },
            response => {
                setEdit(true);
            }
        );
    }

    const onOpenRoom = () => {
        props.history.push('/room/create_study_room/2');
    };

    const doJoinRoom = (item) => {
        console.log("/challenge/challenge_room/" + item.id);
        props.history.push("/room/challenge/challenge_room/" + item.id);
    }

    const onJoinRoom = (item) => {
        if (item.secret == 2) {
            setRoomTemp(item);
            setShowPasswordModal(true);
        } else {
            doJoinRoom(item)
        }
    }

    return (
        <div className="main-content p-r-5">
            <div className="row">
                <div className="col-lg-9">
                    <div className="display-flex-important">
                        <div className="flex-grow-1 to-goal">{'마이 멀티룸'}</div>
                        <button className="add-room" onClick={() => {
                            onOpenRoom()
                        }}>
                            <div className="display-block">
                                <div>+ {strings.open_room}</div>
                            </div>
                        </button>
                    </div>
                    <Carousel
                        ref={idCarousel}
                        draggable={true}
                        swipeable={true}
                        // focusOnSelect={true}
                        // centerMode={true}
                        containerClass={"custom-carousel-container m-t-20"}
                        itemClass="react-multi-carousel-item custom-carousel-item"
                        responsive={{
                            QHDDeskTOP: {
                                breakpoint: { max: 3840, min: 2560 },
                                items: 3,
                            },
                            FHDDeskTop: {
                                breakpoint: { max: 2560, min: 1920 },
                                items: 3,
                            },
                            FHDLabTab: {
                                breakpoint: { max: 1919, min: 1536 },
                                items: 3,
                            },
                            LargeDesktop: {
                                breakpoint: { max: 1535, min: 1440 },
                                items: 3,
                            },
                            desktop: {
                                breakpoint: { max: 1440, min: 1024 },
                                items: 3,
                                slidesToSlide: 1 // optional, default to 1.
                            },
                            tablet: {
                                breakpoint: { max: 1024, min: 464 },
                                items: 3,
                                slidesToSlide: 1 // optional, default to 1.
                            },
                            mobile: {
                                breakpoint: { max: 464, min: 0 },
                                items: 1,
                                slidesToSlide: 1 // optional, default to 1.
                            }
                        }}
                        arrows={false}
                        showDots={false}
                    >
                        {
                            allRoomList.map((item, idx) =>
                                <MyRoomItem key={idx}
                                    item={item}
                                    selected={roomSelected == idx + 1}
                                    image={context.loadImage(item.image)}
                                    onClick={() => {
                                        setRoomSelected(idx + 1);
                                        onJoinRoom(item);
                                    }}
                                />
                            )
                        }

                    </Carousel>
                    {
                        allRoomList.length != 0 &&
                        <div className="d-flex m-b-40">
                            <div className="flex-grow-1" />

                            <CarouselButton
                                more={roomSelected != 1}
                                direction={'left'}
                                onClick={() => {
                                    setTimeout(() => {
                                        if (roomSelected != 1) {
                                            idCarousel?.current?.goToSlide(roomSelected - 2);
                                            setRoomSelected(roomSelected - 1);
                                            // } else {
                                            //     idCarousel?.current?.goToSlide(allRoomList.length + 1);
                                            //     setRoomSelected(allRoomList.length + 1);
                                        }
                                    }, 200)
                                }}
                            />
                            <CarouselButton
                                more={allRoomList.length >= 4 && roomSelected != allRoomList.length - 2}
                                direction={'right'}
                                onClick={() => {
                                    setTimeout(() => {
                                        if (roomSelected != allRoomList.length < 4) {
                                            if (roomSelected != allRoomList.length - 2) {
                                                setRoomSelected(roomSelected + 1);
                                                idCarousel?.current?.goToSlide(roomSelected);
                                            }
                                        }
                                    }, 200)
                                }}

                            />
                        </div>
                    }
                    <div className="display-flex-important m-t-60">
                        <div className="room-status-selected">로컬 멀티룸
                            {/* <span className="total-room">총<span className="total-room-count"> {totalRoom} </span>룸</span> */}
                        </div>
                    </div>
                    <div className="row m-b-15">
                        <div className="col-md-6">
                            {
                                strings.multi_room_type_list.map((item, idx) => {
                                    return (
                                        <RoomCategoryButton
                                            key={idx}
                                            selected={sort == item.code}
                                            onClick={() => setSort(item.code)}
                                            value={item.name}
                                        />
                                    )
                                })
                            }
                        </div>
                        <div className="col-md-6 text-right">
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    {selectCategory}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {strings.room_new_category_list.map((item, index) => {
                                        return (
                                            <SubCategoryButton
                                                key={index}
                                                selected={subCategory == item.code}
                                                value={item.name}
                                                onClick={() => {
                                                    setSubCategory(item.code)
                                                    setSelectCategory(item.name);
                                                }}
                                            />
                                        )
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                    </div>
                    <div className="row room-container">
                        {
                            categoryRoomList.map((item, idx) =>
                                <CategoryRoomItem key={idx}
                                    item={item}
                                    image={context.loadImage(item.image)}
                                    onClick={() => {
                                        onJoinRoom(item)
                                    }}
                                />
                            )
                        }
                    </div>
                    {
                        categoryRoomList.length == 0 &&
                        <div className="text-center m-t-120 f-s-20">{strings.no_room}</div>
                    }
                    {
                        (categoryRoomList.length != 0 && !loadMoreHide) &&
                        <div className="display-flex-important justify-content-center">
                            <LoadMoreButton onClick={() => onLoadMore()} />
                        </div>
                    }
                    <PasswordModal
                        show={showPasswordModal}
                        room={roomTemp}
                        toggle={() => setShowPasswordModal(!showPasswordModal)}
                        close={() => setShowPasswordModal(false)}
                        onConfirm={() => doJoinRoom(roomTemp)}
                    />
                </div> {/* col-lg-9 */}


                <div className="col-lg-3 p-l-15 p-r-0">
                    <div className="flex-grow-1 to-goal right-goal">{strings.my_score}</div>
                    <MyScore
                        realTimeToday={realTimeToday}
                        goalToday={goalToday}
                        efficiency={efficiencyToday}
                        todoTotal={todoTotal}
                        todoDone={todoDone}
                        goalAchToday={goalAchToday}
                        realTimeYesterday={realTimeYesterday}
                        goalAchYesterday={goalAchYesterday}
                        efficiencyYesterday={efficiencyYesterday}
                        period={0}
                        totalTimeYesterday={totalTimeYesterday}
                        totalTimeToday={totalTimeToday}
                    />
                </div>
            </div>
        </div>
    );

})

export default withRouter(Challenge);
