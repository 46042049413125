import React, { useContext } from 'react';
import { Modal, ModalBody } from "reactstrap";
import './modal.scss'
import { API_URL } from "../../../config/const";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import {Page_settings} from "../../../config/page_settings";

const ViewModal = (props) => {
    const context = useContext(Page_settings)
    const { show, toggle, item, onDownload, hideDownload } = props;
    return (
        <Modal isOpen={show} toggle={() => toggle()} className="cloud-view-modal">
            <ModalBody>
                <div className="d-flex align-items-center m-b-40 p-l-40 p-r-40">
                    <div className="f-s-20 f-w-600 flex-grow-1">{item.title || item.title}</div>
                    <button className='modal-close-btn' onClick={() => toggle()}>
                        <img src={"/assets/image/icon_close_black.png"} alt="" className="modal-close" />
                    </button>
                </div>
                <DocViewer className="cloud-view-main m-b-20" documents={[{ uri: context.loadImage(item.url), fileType: item.url?.split('.')[item.url.split('.').length - 1]}]} pluginRenderers={DocViewerRenderers}
                    config={{
                        header: {
                            disableHeader: true,
                            disableFileName: false,
                            retainURLParams: false
                        }
                    }} />
                {
                    !hideDownload &&
                    <div className="p-r-35 width-100-percent d-flex">
                        <button
                            className="common-button m-l-auto"
                            onClick={() => onDownload([item.id])}>
                            <img src={"/assets/image/icon_cloud_download.png"} alt="" />
                        </button>
                    </div>
                }
            </ModalBody>
        </Modal>
    );
}

export default React.memo(ViewModal);
