import strings from "../../lang/strings";
import { useContext, useEffect, useState } from "react";
import { formatNumber } from "../../helper/common";
import { Page_settings } from "../../config/page_settings";
import ShopItemModal from "../../components/control/modal/shop_item_modal";
import ShopConfirmModal from "../../components/control/modal/shop_confirm";
import { observer } from "mobx-react";
import { rootStore } from "../../mobx/store";
import PerfectScrollbar from "react-perfect-scrollbar";

const Shop = observer((props) => {
    const context = useContext(Page_settings);
    const [point, setPoint] = useState(1000);
    const [keyword, setKeyword] = useState("");
    const [tabIndex, setTabIndex] = useState(0);
    const [header, setHeader] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [item, setItem] = useState("");
    const [itemModalShow, setItemModalShow] = useState(false);
    const [confirmShow, setConfirmShow] = useState(false);
    const [pageNum, setPageNum] = useState(1);
    const [height, setHeight] = useState(0);
    const [adList, setAdList] = useState([]);
    const [loadMore, setLoadMore] = useState(false);
    const [scrollPos, setScrollPos] = useState(0);

    useEffect(() => {
        getAds();
        getItemList(pageNum);

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    useEffect(() => {
        if (adList.length !== 0) setHeight(document.getElementsByClassName("shop-ads-img")[0].offsetHeight ?? 0);
    }, [adList])

    useEffect(() => {
        setPoint(rootStore.getProfile?.point ?? 0)
    }, [rootStore.getProfile]);

    useEffect(() => {
        setPageNum(1);
        getItemList(1)
    }, [tabIndex]);

    const getAds = () => {
        context.get(
            "product/adList",
            {},
            response => {
                setAdList(response.list);
                if (response.list.length === 0) {
                    setHeader(true)
                }
            }
        )
    };

    const listenToScroll = (scrollElement) => {
        const winScroll = scrollElement.scrollTop;
        setHeight(document.getElementsByClassName("shop-ads-img")[0].offsetHeight ?? 0);
        const _height = document.getElementsByClassName("shop-ads-img")[0].offsetHeight ?? 0;
        if (winScroll >= _height + 100) {
            setHeader(true);
        } else {
            setHeader(false);
        }
    };

    const handleResize = () => {
        setHeight(document.getElementsByClassName("shop-ads-img")[0].offsetHeight ?? 0);
    };

    const onEnd = () => {
        if (scrollPos <= 3) {
            setScrollPos(scrollPos + 1)
        }
        if (loadMore && scrollPos == 2) {
            getItemList(pageNum + 1)
        }
    }

    const getItemList = (pageNum) => {
        context.get(
            "product/list",
            {
                category: strings.shop_tab[tabIndex].code,
                page_num: pageNum,
                keyword: keyword
            },
            response => {
                if (pageNum === 1) {
                    setItemList(response.list);
                    setPageNum(1)
                } else {
                    setItemList([...itemList, ...response.list]);
                    setPageNum(pageNum)
                }
                setLoadMore(response.list.length == response.limit);
            }
        );
    };

    const handleEnter = e => {
        if (e.keyCode == 13) {
            getItemList(1)
        }
    };

    const onClickItem = (item) => {
        setScrollPos(window.scrollY);
        setItem(item);
        setItemModalShow(true);
    };

    const onGet = () => {
        setItemModalShow(false);
        setTimeout(function () {
            setConfirmShow(true);
        }, 500)
    };

    return (
        <div className="shop">
            <PerfectScrollbar className="shop-scroll" options={{ suppressScrollX: true }} onYReachEnd={() => {
                onEnd()
            }} onScrollUp={() => setScrollPos(0)} onYReachStart={() => setScrollPos(0)} onScrollY={scrollElement => listenToScroll(scrollElement)}>
                <div className="shop-top">
                    <div className="d-flex align-item-center top-status top-search">
                        <div className="f-s-28 m-r-20 c-shop">{strings.c_shop}</div>
                        <div className="point">
                            <img className="m-r-10" src={"/assets/image/icon_coin.png"} alt="" style={{ width: '22px', height: '22px' }} />
                            <div>{formatNumber(point)}</div>
                        </div>
                        <div className="shop-search">
                            <input
                                className="input-transparent"
                                value={keyword}
                                placeholder={strings.enter_brand_product}
                                onChange={(e) => setKeyword(e.target.value)}
                                onKeyDown={handleEnter}
                                style={{ marginTop: 2 }}
                            />
                            <button className="common-button" onClick={() => getItemList(1)}>
                                <img className="m-l-10" src={"/assets/image/icon_search_white.png"} alt="" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="shop-ads row" style={adList.length === 0 ? { height: 250 } : { height: 0.6 * height + 150 }}>
                    {
                        adList.map((item, idx) => {
                            return (
                                <div key={idx} className="col-xl-4 shop-ads-div">
                                    <img key={idx} className="shop-ads-img" src={context.loadImage(item.image_url)} alt="" />
                                </div>
                            )
                        })
                    }
                </div>
                <div className={("shop-tab ") + (header ? "shop-header" : "")}
                    style={!header ? { marginTop: 0.4 * height + 50 } : { marginTop: 115 }}>
                    <div className="top-status">
                        {strings.shop_tab.map((item, idx) => {
                            return (
                                <button
                                    key={idx}
                                    className={("shop-tab-normal ") + (tabIndex == idx ? ("shop-tab-selected " + (header ? "tab-white" : "")) : "")}
                                    onClick={() => setTabIndex(idx)}>
                                    {item.name}
                                </button>
                            )
                        })}
                    </div>
                </div>
                <div
                    className={("d-flex justify-content-center ") + (header ? (adList.length === 0 ? "m-t-40" : "m-t-240") : "m-t-5")}>
                    <div className="product-list top-status">
                        {
                            itemList.map((item, idx) => {
                                return (
                                    <div key={idx}
                                        className="product-container">
                                        <div className="product">
                                            <div className="product-item">
                                                <img className="brand-image" src={context.loadImage(item.brand_image)}
                                                    alt="" />
                                                <div className="d-flex justify-content-center pro-img-container">
                                                    <button className="common-button width-full p-0"
                                                        onClick={() => onClickItem(item)}>
                                                        <img className="pro-img" src={context.loadImage(item.image)}
                                                            alt="" />
                                                    </button>
                                                </div>
                                                <div className="d-flex shop-get-container">
                                                    <button className={"common-button m-l-auto shop-get-button"}
                                                        onClick={() => {
                                                            setScrollPos(window.scrollY);
                                                            setItem(item);
                                                            setConfirmShow(true)
                                                        }}>
                                                        <img className="shop-get-image"
                                                            src={"/assets/image/btn_exchange.png"} alt="" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="brand">{item.brand}</div>
                                        <div className="title">{item.title}</div>
                                        <div className="d-flex align-item-center">
                                            <img src={"/assets/image/icon_coin.png"} alt="" />
                                            <div className="m-l-5 points">{formatNumber(item.point)}</div>
                                        </div>
                                    </div>

                                )
                            })
                        }
                        {
                            itemList.length === 0 &&
                            <div className="f-s-20 text-center m-t-50 m-b-100 w-100" style={{ color: "black" }}>{strings.no_item}</div>
                        }
                    </div>
                </div>
            </PerfectScrollbar>
            <ShopItemModal
                show={itemModalShow}
                item={item}
                toggle={() => setItemModalShow(!itemModalShow)}
                onGet={() => onGet()}
            />
            <ShopConfirmModal
                show={confirmShow}
                item={item}
                toggle={() => setConfirmShow(!confirmShow)}
                onGet={(p) => setPoint(p)}
            />
        </div>
    );
});

export default Shop;
