import React from "react";

const StarRatingCustom = (props) => {

    const {disabled, onClick, fullStar, emptyStar, halfStar, starCnt, size, padding} = props;

    const onStarClick = (index) => {
        if (!disabled) {
            onClick(index);
        }
    }

    const onDrawStar = (index, val) => {
        let starLeft = Math.round(val * 2) / 2;
        starLeft -= index - 1;
        if (starLeft >= 1) {
            return fullStar;
        } else if (starLeft == 0.5) {
            return halfStar;
        } else {
            return emptyStar;
        }
    }

    return (
        <div className="d-flex align-item-center">
            <button className={("star-rating ") + (padding ? "" : "p-2")} onClick={() => onStarClick(1)}>
                <img src={onDrawStar(1, starCnt)} style={{width: size, height: size}} alt=""/>
            </button>
            <button className={("star-rating ") + (padding ? "" : "p-2")} onClick={() => onStarClick(2)}>
                <img src={onDrawStar(2, starCnt)} style={{width: size, height: size}} alt=""/>
            </button>
            <button className={("star-rating ") + (padding ? "" : "p-2")} onClick={() => onStarClick(3)}>
                <img src={onDrawStar(3, starCnt)} style={{width: size, height: size}} alt=""/>
            </button>
            <button className={("star-rating ") + (padding ? "" : "p-2")} onClick={() => onStarClick(4)}>
                <img src={onDrawStar(4, starCnt)} style={{width: size, height: size}} alt=""/>
            </button>
            <button className={("star-rating ") + (padding ? "" : "p-2")} onClick={() => onStarClick(5)}>
                <img src={onDrawStar(5, starCnt)} style={{width: size, height: size}} alt=""/>
            </button>
        </div>
    );
}

export default StarRatingCustom;
