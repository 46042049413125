import moment from "moment";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { withRouter } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import ChewingTalkChatItem from "../../components/control/item/chewingtalk_chat_item";
import ChewingTalkChatList from "../../components/control/item/chewingtalk_chat_list";
import { Page_settings } from "../../config/page_settings";
import strings from "../../lang/strings";
import { rootStore } from "../../mobx/store";
import DatalabModal from "../class/Modals/datalabModal";
import io, { Socket } from "socket.io-client";
import { SOCKET_URL } from "../../config/const";
import Lightbox from "react-image-lightbox";

const ChewingTalk = (props) => {
  const context: any = useContext(Page_settings);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>("");
  const user_type: "STUDENT" | "EXPERT" = rootStore.getProfile?.user_type ?? "";
  const user_id: number = rootStore.getProfile?.id ?? "";
  const [socket, setSocket] = useState<Socket | null>(null);
  
  interface IClass {
    class: number;
    class_name: string;
    grade: string;
    classroom: string;
    creator: number;
    creator_name: string;
  }
  const [classList, setClassList] = useState<IClass[]>([]);
  const [selectedClass, setSelectedClass] = useState<IClass>();

  interface IRoomList {
    id: number;
    sender: number;
    partner: number;
    message: string;
    message_date: string | null;
    pinned: number;
    notification: number;
    unread_count: number;
    user_type: "STUDENT" | "EXPERT";
    is_file: number;
    user: {
      id: number;
      name: string;
      profile: string | null;
    };
  }

  interface IFile {
    id: number;
    name: string;
    type: string;
    url: string;
  }
  const [roomList, setRoomList] = useState<IRoomList[]>([]);
  const [filteredRoomList, setFilteredRoomList] = useState<IRoomList[]>([]); // 검색된 맴버 리스트
  const [selectedRoom, setSelectedRoom] = useState<IRoomList | null>(null);
  const [selectedMemberType, setSelectedMemberType] = useState<"STUDENT" | "EXPERT">("STUDENT");

  interface IChat {
    id: number;
    room: number;
    sender: number;
    message: string;
    create_date: string;
    is_read: number;
    user: {
      sender_name: string;
      sender_profile: string | null;
    };
    file: IFile[] | null;
  }
  const [chatList, setChatList] = useState<IChat[]>([]);
  const [chatInput, setChatInput] = useState<string>("");
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const imageRef: any = useRef();
  const [openDataLab, setOpenDataLab] = useState<boolean>(false);
  const parentRef = useRef(null);

  useEffect(() => {
    context.handleSetPageHeader(false);
    context.handleSetPageSidebar(false);
    context.handleSetPageHeaderFixed(false);
    context.handleSetContent(true);
    if (parentRef.current) {
      parentRef.current.parentElement.style.padding = "0";
    }

    context.get("class/getClassList", { user_id }, (response) => {
      if (response !== null) {
        let result = response.map((item) => ({
          class: item.class || item.id,
          class_name: item.class_name,
          creator: item.creator,
          creator_name: item.creator_name,
          grade: item.grade,
          classroom: item.classroom
        }));
        setClassList(result);
        setSelectedClass(result[0]);
        loadRoomList(result[0].class);
      }
    });

    setSocket(
      io(SOCKET_URL, {
        auth: {
          token: rootStore.token,
        },
      })
    );

    return () => {
      if (socket) {
        socket.disconnect();
        setSocket(null);
      }
    };
  }, []);

  useEffect(() => {
    if (!socket) return;

    socket.connect();
    
    return(() => {
      if (socket) {
        socket.disconnect();
    }});
  }, [socket]);

  const roomIdsString = useMemo(() => {
    return roomList.map((item) => item.id)
    .sort((a, b) => a - b)
    .join(",");
  }, [roomList]);

  let room_status = false;

  useEffect(() => {
    if (!socket) return;

    if (selectedRoom && selectedRoom.id) {
      context.get(
        "chewingtalk/getChewingtalkMessageList",
        {
          roomId: selectedRoom.id,
          page_num: 1,
        },
        (response) => {
          setChatList(response.list);
        }
      );
    }

    socket.on("receiveMessage", (data) => {
      const { create_date, file, id, is_read, message, room, user} = data;

      const roomIds = roomList.map((item) => item.id);
      if (roomIds.reduce((acc, cur) => acc || cur == room, false)) {
        if(room == selectedRoom.id) {
          setRoomList((prev) => {
            const updatedRoomList = prev.map((item) => {
              if (item.id == room) {
                item.message = message;
                item.message_date = create_date;
              }
              return item;
            })

            const targetRoom = updatedRoomList.find((item) => item.id == room);
            if (targetRoom.pinned == 1) {
              return updatedRoomList;
            }
            
            const pinnedRooms = updatedRoomList.filter((item) => item.pinned == 1);
            const rest = updatedRoomList.filter((item) => item.pinned == 0 && item.id != room);
            return [...pinnedRooms, targetRoom, ...rest];}
          );
        } else {
          setRoomList((prev) => {
            const updatedRoomList = prev.map((item) => {
              if (item.id == room) {
                item.message = message;
                item.message_date = create_date;
                item.unread_count += 1;
              }
              return item;
            });

            const targetRoom = updatedRoomList.find((item) => item.id == room);
            if (targetRoom.pinned == 1) {
              return updatedRoomList;
            }

            const pinnedRooms = updatedRoomList.filter((item) => item.pinned == 1);
            const rest = updatedRoomList.filter((item) => item.pinned == 0 && item.id != room);
            return [...pinnedRooms, targetRoom, ...rest];}
          );
        }
      }

      if (selectedRoom && room == selectedRoom.id) {
        setChatList((prev) => {
          return [
            {
              id,
              room,
              sender: user.id,
              message,
              create_date,
              is_read: is_read,
              user,
              file: file ? [file] : null,
            },...prev
          ];
        });

        if (user.id != user_id) {
          socket.emit("updateReadStatus", {
            roomId: room,
          });
        }
      }
    });

    socket.on("updateReadStatus", (data) => {
      const { roomId } = data;
      if (selectedRoom && roomId == selectedRoom.id) {
        setChatList((prev) =>
          prev.map((item) => {
            if (item.is_read == 0) {
              item.is_read = 1;
            }
            return item;
          })
        );
      }
    });

    return () => {
      if (socket) {
        socket.off("receiveMessage");
        socket.off("updateReadStatus");
      }
    };
  }, [socket, selectedRoom, roomIdsString, room_status]);

  const loadRoomList = (class_id: number) => {
    context.get(
      "chewingtalk/getChewingtalkList?classId=" + class_id,
      {},
      (response) => {
        setRoomList(response.list);
      }
    );
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const onSearch = () => {
    if (!keyword.trim()) {
      return;
    }
    // 대소문자 구분 없이 검색하기 위해 소문자로 변환

    // 검색어가 비어있을 때, 초기 리스트로 복원
    const lowerKeyword = keyword.trim().toLowerCase();
    const filteredList = roomList.filter(
      (member) =>
        member.user.name.toLowerCase().includes(lowerKeyword) ||
        (member.user && member.user.name.toLowerCase().includes(lowerKeyword))
    );

    setFilteredRoomList(filteredList);
  };

  const onSend = () => {
    let new_chat = chatInput.replace(/ /g, "");
    if ((chatInput != "" && new_chat != "")  && selectedRoom) {
      socket.emit(
        "sendMessage",
        {
          room: selectedRoom.id,
          sender: user_id,
          partner: selectedRoom.user.id,
          message: chatInput,
          file: null,
          is_read: 0,
          class: selectedClass.class,
        });
        setChatInput("");
      }
    };

    const onFileSend = (e) => { 
      const files = e.target.files; // 파일 배열 가져오기
      if (files.length < 1) return;
      const file = files[0]; // 첫 번째 파일 선택
    
      // FormData에 파일 추가
      let formData = new FormData();
      formData.append("file", file);
    
      // 서버로 파일 업로드 요청
      context.post("upload/image/chewingtalk", formData, (response) => {
        if (response) {
          // 업로드 성공 시 소켓으로 메시지 전송
          socket.emit("sendMessage", {
            room: selectedRoom.id,
            sender: user_id,
            partner: selectedRoom.user.id,
            message: "파일을 전송했습니다.",
            is_read: 0,
            class: selectedClass.class,
            file: {
              size: response.size,
              name: response.name,
              type: response.type,
              url: response.image,
            }, // 서버에서 받은 파일 경로 또는 URL
          });
    
          setChatInput(""); // 입력 필드 초기화
        } else {
          console.error("File upload failed:", response);
        }
      });
    };
    

  const handleEnter = (e) => {
    if (e.keyCode == 13) {
      onSearch();
    }
  };

  const handleEnterChat = (e) => {
    if (e.keyCode == 13) {
      onSend();
    }
  };

  const onClickMember = (index) => {
    setSelectedRoom(roomList[index]);
    setSelectedMemberType(roomList[index].user_type);
    setRoomList((prev) =>
      prev.map((item, idx) => {
        if (index == idx) {
          item.unread_count = 0;
        }
        return item;
      })
    );
    socket?.emit("updateReadStatus", roomList[index].id);
  };

  const onPin = (roomId, memberId, pinned) => {
    socket.emit("pinnedChewingtalkRoom", { roomId, memberId, pinned });
    loadRoomList(selectedClass.class);
  };

    // lightbox 이미지 modal 용 변수
  const [lightboxImage, setLightboxImage] = useState<string>("");

    // 이미지 모달 열기 핸들러
  const handleLightboxOpen = (imageUrl) => {
    setLightboxImage(imageUrl);
  };


  const updatedChatItems = useMemo(() => {
    let lastDate = "";
    return chatList.map((item, idx) => {
      const curDate = moment(item.create_date).format("YYYY-MM-DD");
      const showDateDivider = (lastDate !== curDate) && (idx != 0); // 날짜가 바뀌는지 확인
      const dateString = moment(new Date(lastDate)).format(
        "YYYY년 MM월 DD일 (ddd)"
      );
      lastDate = curDate;
      return (
        <React.Fragment key={item.id}>
          {/* 날짜 구분선 삽입 */}
          {showDateDivider && (
            <div className="date-divider" key={`date-${idx}`}>
              <span>{dateString}</span>
            </div>
          )}

          <ChewingTalkChatItem
            mine={selectedRoom.user.id !== item.sender}
            key={item.id}
            item={item}
            partner={selectedRoom.user}
            setLightboxImage={handleLightboxOpen}
          />
          {/* 마지막 메시지일 경우 날짜 구분선 삽입 */}
          {idx === chatList.length - 1 && (
            <div className="date-divider" key={`date-${idx}`}>
              <span>
                {moment(new Date(curDate)).format("YYYY년 MM월 DD일 (ddd)")}
              </span>
            </div>
          )}
        </React.Fragment>
      );
    });
  }, [chatList]);

  interface IContextRoom {
    roomId: number;
    x: number;
    y: number;
  }

  const [rightClickedRoom, setRightClickedRoom] = useState<IContextRoom|null>(null);

    // 메뉴 외부 클릭 시 메뉴 닫기
  const handleCloseMenu = (event) => {
      if (rightClickedRoom !== null) {
        setRightClickedRoom(null); // 메뉴 닫기
      }
    };

  // 우클릭으로 메뉴를 표시
  const handleShowContextMenu = (roomId, x, y) => {
    setRightClickedRoom({ roomId, x, y });
    room_status = !room_status;
  };

  useEffect(() => {
    document.addEventListener('click', handleCloseMenu);
    return () => {
      document.removeEventListener('click', handleCloseMenu);
    
    };
  }, [rightClickedRoom]);

  return (
    <div ref={parentRef} className="main-content chewingtalk-content">
      <div className="ai-coach-bg">
        <div
          className="row height-full"
          style={{ marginLeft: 0, marginRight: 0 }}
        >
          <div className={"coach-list " + "col-sm-3"}>
            <div className="d-flex align-item-center p-20">
              <Dropdown
                isOpen={dropdownOpen}
                toggle={() => toggleDropdown()}
                className="dropdown navbar-user rank-dropdown"
                tag="li"
                style={{ width: 80 }}
              >
                <DropdownToggle
                  tag="a"
                  className="display-flex-important align-item-center sort-dropdown"
                  style={{ background: "white", margin: 0 }}
                >
                  <div
                    className="flex-grow-1 school-list"
                    style={{ color: "#3f3f3f" }}
                  >
                    {selectedClass ? `${selectedClass.class_name} ${selectedClass.grade} ${selectedClass.classroom}반` : "선택"}
                  </div>
                  <img
                    src={"/assets/image/icon_arrow_down_black.png"}
                    alt=""
                    style={{ paddingLeft: "10px" }}
                  />
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-menu dropdown-menu-right profile-dropdown-menu"
                  tag="ul"
                >
                  {classList.map((item, idx) => (
                    <DropdownItem
                      key={idx}
                      className="dropdown-header display-flex-important justify-content-center f-s-15"
                      onClick={() => {
                        setSelectedClass(item);
                        loadRoomList(item.class);
                      }}
                    >
                      {`${item.class_name} ${item.grade} ${item.classroom}반`}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
              <button
                className="common-button m-l-auto"
                onClick={() => setShowSearch(!showSearch)}
              >
                <img
                  src={"/assets/image/icon_search_black.png"}
                  alt=""
                  style={{ width: "20px" }}
                />
              </button>
            </div>
            <PerfectScrollbar
              style={{ height: "92%" }}
              options={{ suppressScrollX: true }}
            >
              {showSearch && (
                <div
                  className="position-relative m-b-10 m-r-10 m-l-10"
                  style={{ marginLeft: "10px" }}
                >
                  <input
                    value={keyword}
                    className="search-expert"
                    type={"text"}
                    onChange={(e) => {
                      setKeyword(e.target.value)
                      onSearch();
                      }}
                    onKeyDown={handleEnter}
                  />
                  <button className="search-button" onClick={() => onSearch()}>
                    <img src={"/assets/image/icon_search_black.png"} alt="" />
                  </button>
                </div>
              )}
              {roomList.length != 0 && keyword == "" &&
                roomList.map((item, idx) => {
                  return (
                    <ChewingTalkChatList
                      selected={item.id == selectedRoom?.id}
                      key={idx}
                      item={item}
                      onClick={() => {
                        onClickMember(idx);
                      }}
                      onPin={onPin}
                      onShowContextMenu={handleShowContextMenu}
                      rightClickedRoom={rightClickedRoom}
                    />
                  );
                })}
              {filteredRoomList.length != 0 && keyword != "" &&
                filteredRoomList.map((item, idx) => {
                  return (
                    <ChewingTalkChatList
                      selected={item.id == selectedRoom?.id}
                      key={idx}
                      item={item}
                      onClick={() => {
                        onClickMember(idx);
                      }}
                      onPin={onPin}
                      onShowContextMenu={handleShowContextMenu}
                      rightClickedRoom={rightClickedRoom}
                    />
                  );
                })}
            </PerfectScrollbar>
          </div>
          {selectedRoom ? (
            <div className={"chat-area " + "col-sm-9"}>
              <div
                className={
                  "chat-input-container" +
                  (selectedRoom.id ? "" : "") +
                  " chewing-chat-input-container"
                }
              >
                <input
                  type="file"
                  className="hide"
                  onChange={(e) => {
                    console.log(e);
                    onFileSend(e);
                  }}
                  ref={imageRef}
                />
                <input
                  placeholder={strings.chewingtalk_chat_placeholder}
                  type={"text"}
                  value={chatInput}
                  onChange={(e) => setChatInput(e.target.value)}
                  onKeyDown={handleEnterChat}
                />
                <div>
                </div>
                <div className="d-flex m-t-20 ">
                  <button
                    className="emoticon"
                    onClick={() => {imageRef?.current.click();}}
                  >
                    <img
                      src={"/assets/image/icon_link.png"}
                      style={{ width: 35, height: 35 }}
                      alt=""
                    />
                  </button>
                  <button
                    className={
                      "send " +
                      ((chatInput != "" &&
                        chatInput.replace(/ /g, "") != "")  &&
                      selectedRoom?.id
                        ? "send-active"
                        : "")
                    }
                    onClick={() => onSend()}
                  >
                    {strings.send}
                  </button>
                </div>
              </div>
              <div
                className={
                  "coach-chat-area p-t-10" + (selectedRoom?.id ? "" : " d-none")
                }
              >
                {updatedChatItems}
              </div>
              <div
                className={
                  "chat-area-top" + (selectedRoom?.id ? "" : " d-none")
                }
                style={
                  user_type !== "STUDENT"
                    ? { padding: "7px 22px" }
                    : { padding: "10px 22px" }
                }
              >
                <div className="chewingtalk-profile">
                  <img
                    src={
                      selectedRoom.user.profile != null
                        ? context.loadImage(selectedRoom.user.profile)
                        : "/assets/image/chewing_plus_logo.png"
                    }
                    alt=""
                  />
                </div>
                <div className="name-school-box">
                  <div className="name">
                    {selectedRoom.user.name}
                    {selectedMemberType === "EXPERT" ? (
                      <div className="expert">선생님</div>
                    ) : null}
                  </div>
                  {/* <div className="school-info">{selectedMember}</div> */}
                </div>
                <div className="function-buttons">
                  {/* <button className="search" onClick={() => onChatSearch()}>
                                    <img src={"/assets/image/icon_search_black.png"} alt="" />
                                </button>
                                <button className="settings" onClick={() => onChatSettings()}>
                                    <img src={"/assets/image/icon_settings_black.png"} alt="" />
                                </button> */}
                  {user_type !== "STUDENT" ? (
                    <button
                      className="roadmap"
                      onClick={() => setOpenDataLab(true)}
                    >
                      데이터
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {openDataLab && (
        <DatalabModal
          open={openDataLab}
          onClose={() => {
            setOpenDataLab(false);
          }}
          dataLabID={selectedRoom.id}
          resizeView={1560}
          member={selectedRoom.user.id}
          class_id={selectedClass?.class}
        />
      )}
      {lightboxImage !== "" && (
        <Lightbox mainSrc={lightboxImage} onCloseRequest={() => setLightboxImage("")}/>
      )}
    </div>
  );
};
export default withRouter(ChewingTalk);
