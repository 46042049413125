import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';


function ChewingTalkHeader(props) {

const onClickOpenBrowser = () => {
  // 버튼 클릭 시 새로운 브라우저 창을 엽니다.
  window.open('/chewingTalk', 'NewWindow', 'height=831px width=1068px');
};

  return (
    <>

      <div class="chewingtalk-container " onClick={onClickOpenBrowser} >
          <img
            src={'/assets/image/icon_chewing_talk.png'}
            alt=""
            />
          츄잉톡
      </div>
    </>
  )
}

export default ChewingTalkHeader