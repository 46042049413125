import React, {useContext} from 'react';
import {Modal, ModalBody} from "reactstrap";
import strings from "../../../lang/strings";
import './modal.scss'
import {Page_settings} from "../../../config/page_settings";

const SettingModal = (props) => {
    const context = useContext(Page_settings);
    const {show, toggle} = props;
    const onNotification = () => {
        context.handleSettingModal(false);
        context.handleNotificationModal(true);
    }
    const onTerm = () => {
        context.handleTermModal(true);
        context.handleSettingModal(false);
    }
    const onAccountInfo = () => {
        context.handleAccountModal(true);
        context.handleSettingModal(false);
    }
    return (
        <Modal isOpen={show} toggle={() => toggle()} className="account-info-modal">
            <ModalBody className="p-30">
                <div className='d-flex modal-title-custom'>
                    <div className='f-s-20 f-w-600'>{strings.settings}</div>
                    <div className='flex-grow-1'/>
                    <button className='modal-close-btn' onClick={() => toggle()}>
                        <img src={"/assets/image/icon_close_black.png"} alt="" className="modal-close"/>
                    </button>
                </div>
                <button className='d-flex align-item-center common-button width-100-percent'
                        onClick={() => onAccountInfo()}>
                    <div className='f-s-16 f-w-400'>{strings.account_info}</div>
                    <div className='flex-grow-1'/>
                    <img src={"/assets/image/icon_arrow_right_black.png"} alt="" className="modal-next"/>
                </button>
                <button className='d-flex m-t-40 align-item-center common-button width-100-percent'
                        onClick={() => onNotification()}>
                    <div className='f-s-16 f-w-400'>{strings.notification}</div>
                    <div className='flex-grow-1'/>
                    <img src={"/assets/image/icon_arrow_right_black.png"} alt="" className="modal-next"/>
                </button>
                <button className='d-flex m-t-40 align-item-center common-button width-100-percent m-b-250'
                        onClick={() => onTerm()}>
                    <div className='f-s-16 f-w-400'>{strings.terms}</div>
                    <div className='flex-grow-1'/>
                    <img src={"/assets/image/icon_arrow_right_black.png"} alt="" className="modal-next"/>
                </button>
            </ModalBody>
        </Modal>
    );
}

export default SettingModal;
