import React, { useContext, useEffect, useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from "styled-components";
import strings from "../../../lang/strings";

import { Page_settings } from '../../../config/page_settings';
import { rootStore } from "../../../mobx/store";
import { API_URL } from "../../../config/const";
import axios from "axios";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import moment from "moment";
import PerfectScrollbar from 'react-perfect-scrollbar';

function EditCurriculumStep5Modal(props) {
  const history = useHistory();
  const [videoLink, setVideoLink] = useState()
  const step3TestFileRef = useRef();
  const step3CommentaryFileRef = useRef();

  const [profile, setProfile] = useState("");
  const [step1ModeBtn, setStep1ModeBtn] = useState(0);
  const [step1Url, setStep1Url] = useState([]);
  const [step1Thumbnail, setStep1Thumbnail] = useState([]);
  const [step1FileList, setStep1FileList] = useState([]);
  const [step2TestUrl, setStep2TestUrl] = useState([]);
  const [step2TestThumbnail, setStep2TestThumbnail] = useState([]);
  const [step2TestFileList, setStep2TestFileList] = useState([]);
  const [step2CommentaryUrl, setStep2CommentaryUrl] = useState([]);
  const [step2CommentaryThumbnail, setStep2CommentaryThumbnail] = useState([]);
  const [step2CommentaryFileList, setStep2CommentaryFileList] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [step, setStep] = useState(3);
  const [step2Title, setStep2Title] = useState(props.clickUnit.name);
  const [step2QuestionCount, setStep2QuestionCount] = useState(0);
  const [step2QuestionTime, setStep2QuestionTime] = useState(0);
  const [step3TestUrl, setStep3TestUrl] = useState([]);
  const [step3Title, setStep3Title] = useState(props.clickSection.name);
  const [step3TestThumbnail, setStep3TestThumbnail] = useState([]);
  const [step3TestFileList, setStep3TestFileList] = useState([]);
  const [step3CommentaryUrl, setStep3CommentaryUrl] = useState([]);
  const [step3CommentaryThumbnail, setStep3CommentaryThumbnail] = useState([]);
  const [step3CommentaryFileList, setStep3CommentaryFileList] = useState([]);
  const [step3QuestionCount, setStep3QuestionCount] = useState('');
  const [step3QuestionTime, setStep3QuestionTime] = useState('');
  const [step3PassScore, setStep3PassScore] = useState('');
  const [passScore, setPassScore] = useState(0);

  const test_score = props.curriculumData.thumbnail.test_score;

  console.log("props",props.curriculumData)

  // 추가된 부분 시작
  useEffect(() => {
    if (props.clickSection) {
      const section = props.clickSection;
      if (section.test) {
        const test = section.test;
        let answerData;

        try {
          // JSON.parse를 시도
          answerData = JSON.parse(test.answer_data);
        } catch (error) {
          // JSON.parse 실패 시 그대로 사용
          answerData = test.answer_data;
        }
        if (answerData) {
          setStep3QuestionCount(answerData.length)
          setAnswerList(answerData);
        }
        if (test.time) {
          setStep3QuestionTime(test.time);
        }
        if (test.pass_score) {
          const numericValue = extractNumericValue(test.pass_score);
          setStep3PassScore(test.pass_score);
          setPassScore(numericValue);
        }
        if (test.test_url) {
          setStep3TestUrl(test.test_url)
          if (test.test_file) {
            setStep3TestFileList(test.test_file)
          } else {
            let list = [];
            list.push({ title: test.test_title, size: test.test_size, type: test.test_type })
            setStep3TestFileList(list)
          }
        }
        if (test.commentary_url) {
          setStep3CommentaryUrl(test.commentary_url)
          if (test.commentary_file) {
            setStep3CommentaryFileList(test.commentary_file);
          } else {
            let list = [];
            list.push({ title: test.commentary_title, size: test.commentary_size, type: test.commentary_type })
            setStep3CommentaryFileList(list)
          }
        }
      }

    }
  }, [props.clickSection]);



  // OMR답안지
  const [omrError, setOMRError] = useState(false);
  const [answerList, setAnswerList] = useState([]);
  const answerItem = {
    type: 1,
    answer: '',
    rate: ''
  }

  const fileTypes = [
    "image/bmp",
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/tiff",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "text/htm",
    "text/html",
    "application/pdf",
    "application/vnd.ms-powerpoint",
    "applicatiapplication/vnd.openxmlformats-officedocument.presentationml.presentation",
    "text/plain",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "video/mp4"]

  const context = useContext(Page_settings);


  useEffect(() => {

    if (rootStore.getProfile != null) {
      setProfile(rootStore.getProfile.profile);
    }
  }, []);

  // answerList 변경 시 omrError 업데이트
  useEffect(() => {
    let totalRate = 0;
    answerList.forEach(item => {
      if (item.rate !== '') {
        totalRate += parseInt(item.rate);
      }
    });
    if (totalRate >= step3PassScore) {
      setOMRError(false);
    } else {
      setOMRError(true);
    }
  }, [answerList, step3PassScore]);



  const EnableConfirm = () => {

    const allAnswersFilled = answerList.every(item => item.answer !== "");

    if ((allAnswersFilled && step3QuestionCount > 0 && step3PassScore !== "" && !omrError && (step3TestUrl && step3TestUrl.length > 0) && (step3CommentaryUrl && step3CommentaryUrl.length > 0 ) && step3QuestionTime > 0)) {
      return true
    }
    return false
  }

  const confirmStep3 = () => {
    let totalRate = 0;
    answerList.map((item, index) => {
      if (item.rate != '') {
        totalRate += parseInt(item.rate);
      }
    });
    const checkAnswerList = answerList.every(item => item.answer !== '' && item.rate !== ''); // omr에 정답과 점수를 입력했을시에만 true를 반환
    return (
      step3TestFileList.length > 0 && step3QuestionCount !== '' && step3QuestionTime !== '' && checkAnswerList && totalRate >= step3PassScore
    )
  }

  const client = axios.create({
    baseURL: API_URL + '/app/',
  })

  const sendFile = async (url) => {
    let formData = new FormData();
    url.forEach((entry, index) => {
      formData.append('file', entry[0]);
    });

    try {
      const response = await client.post(
        'upload/file/curriculum',
        formData,
      );

      return response.data.result_data.file; // 리스폰 값을 리턴합니다
    } catch (error) {
      console.error('File upload error:', error);
      return null; // 에러 발생 시 null 리턴
    }

  }





  const onSave = async (partIdx, chapterIdx, sectionIdx, unitIdx) => {

    const updatedUnitData = props.curriculumData.parts
    const unit = updatedUnitData[partIdx]
      .chapters[chapterIdx]
      .sections[sectionIdx]
      .units[unitIdx];

        updatedUnitData[partIdx]
        .chapters[chapterIdx]
        .sections[sectionIdx].test = {
        name: step3Title,
        time: step3QuestionTime,
        pass_score: step3PassScore,
        test_url: Array.isArray(step3TestUrl) && step3TestUrl.length > 0 ? step3TestUrl : step3TestUrl,
        test_file: step3TestFileList,
        commentary_url: step3CommentaryUrl,
        commentary_file: step3CommentaryFileList,
        answer_data: answerList
      };
      props.updatePartCategory(updatedUnitData);

    handleClose();
  };




  const handleClose = () => {
    props.onClose?.();
  };


  const onStep3TestFileChange = (e) => {

    let file = [e.target.files];
    let thumbnail = e.target.files[0];
    setStep3TestUrl(file);

    let list = [];

    let reader = new FileReader();

    // 이미지 불러오기
    reader.onloadend = function () {
      setStep3TestThumbnail([...step3TestThumbnail, reader.result]);
    };
    reader.readAsDataURL(thumbnail);

    file.forEach(item => {
      if (fileTypes.indexOf(item[0].type) < 0) return

      let ext = item[0].name.split(".")[item[0].name.split(".").length - 1];
      let type = "/assets/image/icon_file_";
      if (ext == "docx") {
        type += "docs";
      } else if (ext == "mp4") {
        type += "drive"
      } else {
        type += ext
      }
      type += ".png";

      list.push({ title: item[0].name, size: item[0].size, type: type })
    });
    // setStep3TestFileList([...step3TestFileList, ...list])
    setStep3TestFileList(list)
  }


  const onStep3CommentaryFileChange = (e) => {

    let file = [e.target.files];
    let thumbnail = e.target.files[0];
    setStep3CommentaryUrl(file);

    let list = [];

    let reader = new FileReader();

    // 이미지 불러오기
    reader.onloadend = function () {
      setStep3CommentaryThumbnail([...step3CommentaryThumbnail, reader.result]);
    };
    reader.readAsDataURL(thumbnail);

    file.forEach(item => {
      if (fileTypes.indexOf(item[0].type) < 0) return

      let ext = item[0].name.split(".")[item[0].name.split(".").length - 1];
      let type = "/assets/image/icon_file_";
      if (ext == "docx") {
        type += "docs";
      } else if (ext == "mp4") {
        type += "drive"
      } else {
        type += ext
      }
      type += ".png";

      list.push({ title: item[0].name, size: item[0].size, type: type })
    });
    setStep3CommentaryFileList(list) //현재는 하나밖에 안되기때문에 하나로갑니다.
    // setStep3CommentaryFileList([...step3CommentaryFileList, ...list])
  }

  const changeStep3QuestionTime = (e) => {
    setStep3QuestionTime(e.target.value)
  }


  const changeStep3Title = (e) => {
    setStep3Title(e.target.value)
  }

  const changeStep3PassScore = (e) => {
    const value = e.target.value;
    const numericValue = extractNumericValue(value);

    if (numericValue > 100) {
      setStep3PassScore(`100${value.replace(/\d+/g, '')}`);
      setPassScore(100);
    } else {
      setStep3PassScore(value);
      setPassScore(numericValue);
    }
  };

    // 함수 정의
    const extractNumericValue = (value) => {
    if (typeof value === 'string') {
        const numericValue = parseInt(value.replace(/\D/g, ''), 10);
        return isNaN(numericValue) ? 0 : numericValue;
    }
    return typeof value === 'number' ? value : 0;
    };


  // OMR관련 함수 START 

  const onChangeRate = (index, rate) => {
    const newList = answerList.map((item, idx) => {
      if (idx === index) {
        const updatedItem = {
          ...item,
          rate: rate,
        };

        return updatedItem;
      }

      return item;
    });
    setAnswerList(newList);
  }

  const onChangeItems = (e) => {
    let list = [];
    for (let idx = 0; idx < e.target.value; idx++) {
      list.push(answerItem);
    }
    setAnswerList(list);
    setStep3QuestionCount(e.target.value);
  }

  const changeVideoLink = (e) => {
    setVideoLink(e.target.value)
  };

  const onChangeAnswer = (index, answer) => {

    const newList = answerList.map((item, idx) => {
      if (idx === index) {
        const updatedItem = {
          ...item,
          answer: answer,
        };

        return updatedItem;
      }

      return item;
    });

    setAnswerList(newList);
  }

  const onAnswerType = (index, type) => {
    const newList = answerList.map((item, idx) => {
      if (idx === index) {
        const updatedItem = {
          ...item,
          type: type,
          answer: '',
        };

        return updatedItem;
      }

      return item;
    });
    setAnswerList(newList);
  }

  const delFile = (step, item) => {

    if (step === 'step1') {
      setStep1FileList(prevList => prevList.filter(file => file !== item));
      setStep1Url([]);
    } else if (step === 'step2Test') {
      setStep2TestFileList(prevList => prevList.filter(file => file !== item));
      setStep2TestUrl([]);
    } else if (step === 'step2Commentary') {
      setStep2CommentaryFileList(prevList => prevList.filter(file => file !== item));
      setStep2CommentaryUrl([]);
    } else if (step === 'step3Test') {
      setStep3TestFileList(prevList => prevList.filter(file => file !== item));
      setStep3TestUrl([]);
    } else if (step === 'step3Commentary') {
      setStep3CommentaryFileList(prevList => prevList.filter(file => file !== item));
      setStep3CommentaryUrl([]);
    }

  }




  return (
    <>

      <Overlay>
        <ModalWrap>
          <div className='create-roadmap-step5-modal'>
            <div className='step5-modal-header'>
              <div className='title'>
                
                <div>소단원명 :</div>
                <div className={`section ${step === 3 ? 'highlight' : ''} `}> {props.clickSection.name} </div>
                
              </div>
              <div className='btn-container'>
                <button className='save' style={{ backgroundColor: EnableConfirm() ? '#0F84F4' : '#D9D9D9' }} disabled={!EnableConfirm()} onClick={() => onSave(props.clickPartIdx, props.clickChapterIdx, props.clickSectionIdx, props.clickUnitIdx)}>저장</button>
                <button className='close' onClick={() => handleClose()}>닫기</button>
              </div>
            </div>

            <div className='step-container'>
              {/* <div className={`step-box ${step === 1 ? 'selected' : ''}`} onClick={() => setStep(1)}>
                1.영상
              </div>
              <div className={`step-box ${step === 2 ? 'selected' : ''}`} onClick={() => setStep(2)}>
                2.문제 풀이
              </div> */}
              {/* <div className={`step-box selected`}>
                테스트
              </div> */}
            </div>

            {step === 3 &&
              <div className='step3-container'>
                <div className='left'>
                  <div className='title'>
                    <span className='highlight'>소단원</span>테스트 출제
                  </div>
                  <div class="sub-title">
                    테스트 제목

                  </div>
                  <div className="class-search  align-item-center box-shadow disable-input" >
                    <input
                      id='inputBox'
                      className="input-transparent cursor"
                      placeholder={"제목을 입력해주세요."}
                      type={'text'}

                      onChange={changeStep3Title}
                      value={step3Title}

                      disabled={true}
                    />

                  </div>


                  <div class="sub-title">
                    문항수
                  </div>
                  <div className="class-search  align-item-center box-shadow" style={{ width: '30%' }} >
                    <input
                      id='inputBox'
                      className="input-transparent cursor"
                      placeholder={"01"}
                      type={'text'}
                      value={step3QuestionCount}
                      onChange={onChangeItems}
                    />

                  </div>

                  <div class="sub-title">
                    시험 시간
                  </div>
                  <div className="class-search  align-item-center box-shadow" style={{ width: '30%' }} >
                    <input
                      id='inputBox'
                      className="input-transparent cursor"
                      placeholder={"60분"}
                      type={'text'}
                      value={step3QuestionTime}
                      onChange={changeStep3QuestionTime}
                    />

                  </div>

                  <div class="sub-title">
                    테스트 통과 점수 설정
                  </div>
                  <div className="class-search  align-item-center box-shadow" style={{ width: '30%' }} >
                    <input
                      id='inputBox'
                      className="input-transparent cursor"
                      placeholder={"0점"}
                      type={'text'}
                      value={step3PassScore}
                      onChange={changeStep3PassScore}
                    />
                  </div>



                  <div class="sub-title">시험 파일
                    <span className='subtitle'>
                      * 시험지 형식의 파일을 올려주세요.
                    </span>
                  </div>
                  <input type='file' accept='application/pdf' className='hide'
                    onChange={(e) => onStep3TestFileChange(e)}
                    ref={step3TestFileRef} />
                  {step3TestFileList.length > 0 && step3TestFileList[0].type ?
                    <div style={{ width: '140px', height: '140px', border: '2px solid #D9D9D9', padding: '10px', borderRadius: '10px', position: 'relative' }}>
                      <img className={'classAddModal-thumbnail '} src={step3TestFileList[0].type} alt="" />
                      <img className='cursor' src='/assets/image/icon_close_gray.png' alt=''
                        onClick={() => {
                          delFile('step3Test', step3TestFileList[0])
                        }}
                        style={{ position: 'absolute', top: '0', right: '0' }}
                      />

                    </div>

                    :
                    <div className={`img-add-box ${isChecked ? 'basic' : ''}`} onClick={() => {
                      step3TestFileRef?.current.click();
                    }}
                    >
                      <img className="classAddModal-no-thumbnail" src="/assets/image/class_addImg1.png" alt="" />
                    </div>
                  }
                  <div class="sub-title">해설지 파일
                  </div>
                  <input type='file' accept='application/pdf' className='hide'
                    onChange={(e) => onStep3CommentaryFileChange(e)}
                    ref={step3CommentaryFileRef} />
                  {step3CommentaryFileList.length > 0 && step3CommentaryFileList[0].type ?
                    <div style={{ width: '140px', height: '140px', border: '2px solid #D9D9D9', padding: '10px', borderRadius: '10px', position: 'relative' }}>
                      <img className={'classAddModal-thumbnail '} src={step3CommentaryFileList[0].type} alt="" />
                      <img className='cursor' src='/assets/image/icon_close_gray.png' alt=''
                        onClick={() => {
                          delFile('step3Commentary', step3CommentaryFileList[0])
                        }}
                        style={{ position: 'absolute', top: '0', right: '0' }}
                      />

                    </div>

                    :
                    <div className={`img-add-box ${isChecked ? 'basic' : ''}`} onClick={() => {
                      step3CommentaryFileRef?.current.click();
                    }}
                    >
                      <img className="classAddModal-no-thumbnail" src="/assets/image/class_addImg1.png" alt="" />
                    </div>
                  }

                </div>

                <div className='right'>
                  <div className='d-flex flex-column omr-wrap'>
                    <div className="display-flex-important study-room-input align-item-center f-w-600" style={{ marginTop: '0px' }}>
                      <div>{'OMR 답안지'}</div>
                      {
                        omrError &&
                        <div style={{ marginLeft: 5, color: '#EB4A4A', fontSize: 12, fontWeight: 500, lineHeight: '13px' }}>
                          {`* 배점의 합이 ${step3PassScore}점 이상이 아닙니다. (최대 100점)`}
                        </div>
                      }
                    </div>
                    <PerfectScrollbar style={{ height: '430px', borderRadius: '7px', border: '1px solid #dfe1e8', marginTop: '10px' }} options={{ suppressScrollX: true }}>
                      <div className="omr-content" style={{ height: '100%' }}>
                        {
                          step3QuestionCount == 0 ?
                            <div className='omr-empty'>
                              {'문항수가 존재하지 않습니다.'}
                            </div>
                            :
                            <div className='omr-data'>
                              <div className='omr-header'>
                                <div className='omr-no'>{'문제'}</div>
                                <div style={{ width: 1, height: '100%', background: '#DFE1E8' }} />
                                <div className='omr-type'>
                                  <div style={{ margin: 'auto' }}>{'유형'}</div>
                                </div>
                                <div style={{ width: 1, height: '100%', background: '#DFE1E8' }} />
                                <div className='omr-answer'>
                                  <div>1</div>
                                  <div>2</div>
                                  <div>3</div>
                                  <div>4</div>
                                  <div>5</div>
                                </div>
                                <div style={{ width: 1, height: '100%', background: '#DFE1E8' }} />
                                <div className='omr-rate'>
                                  <div>{'배점'}</div>
                                </div>
                              </div>
                              <div className='omr-list'>
                                {
                                  answerList.map((answer, index) => {
                                    return <div key={index} className='omr-item'>
                                      <div className='omr-no'>{(index + 1) + '번'}</div>
                                      <div style={{ width: 1, height: '100%', background: '#DFE1E8' }} />
                                      <div className='omr-type'>
                                        <button className={'type-button' + (answer.type == 1 ? ' selected' : '')} style={{ marginRight: 5 }} onClick={() => { onAnswerType(index, 1) }}>
                                          {'객관식'}
                                        </button>
                                        <button className={'type-button' + (answer.type == 2 ? ' selected' : '')} onClick={() => { onAnswerType(index, 2) }}>
                                          {'주관식'}
                                        </button>
                                      </div>
                                      <div style={{ width: 1, height: '100%', background: '#DFE1E8' }} />
                                      {
                                        answer.type == 1 ?
                                          <div className='omr-answer'>
                                            <button className={'check-item' + (answer.answer == '1' ? ' selected' : '')} onClick={() => { onChangeAnswer(index, '1') }} />
                                            <button className={'check-item' + (answer.answer == '2' ? ' selected' : '')} onClick={() => { onChangeAnswer(index, '2') }} />
                                            <button className={'check-item' + (answer.answer == '3' ? ' selected' : '')} onClick={() => { onChangeAnswer(index, '3') }} />
                                            <button className={'check-item' + (answer.answer == '4' ? ' selected' : '')} onClick={() => { onChangeAnswer(index, '4') }} />
                                            <button className={'check-item' + (answer.answer == '5' ? ' selected' : '')} onClick={() => { onChangeAnswer(index, '5') }} />
                                          </div>
                                          :
                                          <div className='omr-answer'>
                                            <input value={answer.answer} onChange={(e) => { onChangeAnswer(index, e.target.value) }} />
                                          </div>
                                      }
                                      <div style={{ width: 1, height: '100%', background: '#DFE1E8' }} />
                                      <div className={'omr-rate'}>
                                        <input value={answer.rate} className={(omrError ? ' error' : '')} onChange={(e) => { onChangeRate(index, e.target.value) }} />
                                      </div>
                                    </div>
                                  })
                                }
                              </div>
                            </div>
                        }
                      </div>
                    </PerfectScrollbar>
                  </div>
                </div>

              </div>



            }

          </div>

        </ModalWrap>
      </Overlay>
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const ModalWrap = styled.div`
  width: 1300px;
  height: 790px;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px 30px;
  overflow: auto;
  
`;
const Button = styled.button`
cursor: pointer;
width: 100%;
color: #ffffff;
border-radius: 5px;
border:0px;
padding: 15px;
margin-top: 60px;
display:flex;
align-items: center;
justify-content:center;
font-size:16px;
gap:5px;
`;



export default EditCurriculumStep5Modal;