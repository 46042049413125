import React, {useContext} from "react";
import strings from "../../../lang/strings";
import {Page_settings} from "../../../config/page_settings";
import moment from "moment";

const ExpertChatItem = (props) => {
    const context = useContext(Page_settings);
    const {item, expert, mine, partner, onDownload} = props;
    return (
        <div className={(mine ? "m-l-auto " : "m-r-auto m-l-20 ") + ("d-flex align-items-xs-end clinic-chat")}>
            {
                !mine &&
                <img src={partner.profile != null ? context.loadImage(partner.profile) : "/assets/image/chewing_plus_logo.png"}
                     style={{
                         width: 50,
                         height: 50,
                         borderRadius: 25,
                         marginRight: 10,
                         marginBottom: "auto",
                         objectFit: "cover"
                     }} alt=""/>
            }
            {mine &&
            <div style={{
                color: "#73727e",
                fontSize: 13,
                margin: "0 10px 10px auto",
                minWidth: 90,
                paddingLeft: 20
            }}>{moment(item.create_date).format("a hh:mm")}</div>
            }
            <div style={!mine ? {flex: 1} : {}}>
                {
                    !mine &&
                    <div className="name f-w-600">{partner.name + " " + (expert ? "" : strings.expert)}</div>
                }
                <div className="d-flex width-100-percent">
                    <div className={mine ? "right" : "left"} style={{ background: (item.file != null && item.file != '') ? 'none' : ''}}>
                        {
                            item.content != "" &&
                            <div style={{wordBreak: "break-all"}}>{item.content}</div>
                        }
                        {
                            item.file != null && item.file != "" &&
                                <button
                                    className="common-button p-0"
                                    onClick={() => onDownload(item)}>
                                    <img
                                        src={context.loadImage(item.file)}
                                        // style={{maxWidth: "100%", objectFit: "cover"}}
                                        alt=""/>
                                </button>

                        }
                    </div>
                    {!mine &&
                    <div style={{
                        color: "#73727e",
                        fontSize: 13,
                        margin: "auto auto 10px 10px",
                    }}>{moment(item.create_date).format("a hh:mm")}</div>
                    }
                </div>
            </div>

        </div>
    );
}

export default ExpertChatItem;
