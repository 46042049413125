import React, { useContext, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import strings from "../../../lang/strings";
import styled from "styled-components";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import moment from "moment";
import { Page_settings } from "../../../config/page_settings";
import SweetAlert from "react-bootstrap-sweetalert";
import AddUnitModal from "./add_unit_modal";
import CreateQuizModal from "./create_quiz_modal";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  getAgeFromBirthday,
} from "../../../helper/common";
import { set } from "mobx";

interface ISubject {
    name: string;
    ready: boolean;
}

interface IUnitData {
  classification: string;
}

const CreateQuiz = (props) => {
  const context: any = useContext(Page_settings);
  const classId = parseInt(props.match.params.class);
  const quizId = parseInt(props.match.params.quiz_id);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [title, setTitle] = useState<string>("");
  const courseList = ["중등", "고등"];
  const [courseItem, setCourseItem] = useState<"중등"|"고등"|"">("");
  const [courseDropdownOpen, setCourseDropdownOpen] = useState(false);
  const subjectList: ISubject[] = [{ name: "국어", ready: false }, { name: "수학", ready: false }, { name: "영어", ready: false }, { name: "과탐", ready: true }, { name: "사회", ready: true }];
  const [subject, setSubject] = useState<ISubject>();
  const [subjectDropdownOpen, setSubjectDropdownOpen] = useState(false);

  const [passCountItem, setPassCountItem] = useState(0);
  const [count, setCount] = useState<number>(0);
  const [answerList, setAnswerList] = useState([]);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [startHour, setStartHour] = useState<number>(new Date().getHours());
  const [startMinute, setStartMinute] = useState<number>(
    new Date().getMinutes()
  );
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [endHour, setEndHour] = useState<number>(new Date().getHours());
  const [endMinute, setEndMinute] = useState<number>(new Date().getMinutes());

  const [note, setNote] = useState<string>("");
  const [limitTime, setLimitTime] = useState(0);
  const [saveAlert, setSaveAlert] = useState(false);
  const [createAlert, setCreateAlert] = useState(false);
  const [addUnitModal, setAddUnitModal] = useState(false);
  const minYesterday = moment().subtract(1, "day");
  const maxDate = moment().add(1, "month");
  const [quizList, setQuizList] = useState([]);
  const [createQuizModal, setCreateQuizModal] = useState(false);
  const [userList, setUserList] = useState([]);
  const [unitData, setUnitData] = useState<IUnitData>();
  const [createDone, setCreateDone] = useState(false);

  useEffect(() => {
    let list = [];
    let pass_list = [];
    for (let idx = 0; idx <= 50; idx += 5) {
      list.push("" + idx);
    }

    for (let idx = 0; idx <= list.length; idx += 1) {
      pass_list.push("" + idx);
    }

    if (quizId != 0) {
      getQuizInfo();
    }
    getClassUsers();
  }, []);

  const getQuizInfo = () => {
    context.post(
      "quiz/info",
      {
        id: quizId,
      },
      (response) => {
        const quizInfo = response.info;
        setTitle(quizInfo.title);
        setSubject(quizInfo.subject);
        setCount(quizInfo.count);

        const start_time = moment(quizInfo.start_time);
        setStartDate(new Date(start_time.format("YYYY-MM-DD")));
        setStartHour(parseInt(start_time.format("HH")));
        setStartMinute(parseInt(start_time.format("HH")));

        const end_time = moment(quizInfo.end_time);
        setEndDate(new Date(end_time.format("YYYY-MM-DD")));
        setEndHour(parseInt(end_time.format("HH")));
        setEndMinute(parseInt(end_time.format("HH")));

        setLimitTime(quizInfo.time);
        const answerList = JSON.parse(quizInfo.answer_data);
        setAnswerList(answerList);
      }
    );
  };

  const getClassUsers = () => {
    context.get(
      "class/getClassMemberList",
      { class_id: classId },
      (response) => {
        console.log("r", response);

        setUserList(response);
      }
    );
  };

  const toggleGradeDropdown = () => {
    setCourseDropdownOpen(!courseDropdownOpen);
  };

  const toggleSubjectDropdown = () => {
    setSubjectDropdownOpen(!subjectDropdownOpen);
  };

  const minDateRange = (current) => {
    return current.isAfter(minYesterday) && current.isBefore(maxDate);
  };

  const onChangeItems = (category, item) => {
    if (category === "grade") {
      setCourseItem(item);
    }
    if (category === "subject") {
      setSubject(item);
    }
    if (category === "count") {
      setCount(item);
    }
    if (category === "pass_count") {
      const parsedItem = parseInt(item, 10);
      if (isNaN(parsedItem)) {
        setPassCountItem(null);
      } else if (count < parsedItem) {
        setPassCountItem(count);
      } else {
        setPassCountItem(parsedItem);
      }
    }
  };

  const onTempSave = () => {
    let answer = JSON.stringify(answerList);

    context.post(
      "quiz/add",
      {
        id: quizId,
        class_id: classId,
        title: title,
        subject: subject.name,
        count: count,
        start_time: moment(startDate).format("YYYY-MM-DD") + " " + startHour + ":" + startMinute,
        end_time: moment(endDate).format("YYYY-MM-DD") + " " + endHour + ":" + endMinute,
        time: limitTime,
        testers: "",
        answer_data: answer,
        status: 0,
      },
      (response) => {
        props.history.push("/class/quiz/" + classId);
      }
    );
  };

  const [createAvaliable, setCreateAvaliable] = useState(false);
  useEffect(() => {
    if (
      courseItem === "" ||
      !subject ||
      count <= 0 ||
      title === "" ||
      !unitData
    ) {
      setCreateAvaliable(false);
    } else {
      setCreateAvaliable(true);
    }
  }, [courseItem, subject, count, title, unitData]);

  const [submitAvailable, setCheckSubmitAvailable] = useState(false);
  useEffect(() => {
    // start time, end time check
    const startDateString = moment(startDate).format("YYYY-MM-DD");
    const endDateString = moment(endDate).format("YYYY-MM-DD");
    const todayString = moment().format("YYYY-MM-DD");


    console.log("startDateString", startDateString);
    console.log("endDateString", endDateString);
    console.log("todayString", todayString);

    if (startDateString > endDateString ) {
        setCheckSubmitAvailable(false);
        return;
    } else if (startDateString === endDateString && startHour > endHour) {
        setCheckSubmitAvailable(false);
        return;
    } else if (startDateString === endDateString && startHour === endHour && startMinute > endMinute) {
        setCheckSubmitAvailable(false);
        return;
    }

    if (endDateString < todayString) {
        setCheckSubmitAvailable(false);
        return;
    } else if (endDateString === todayString && endHour < new Date().getHours()) {
        setCheckSubmitAvailable(false);
        return;
    } else if (endDateString === todayString && endHour === new Date().getHours() && endMinute < new Date().getMinutes()) {
        setCheckSubmitAvailable(false);
        return;
    }

console.log("courseItem", courseItem, subject, selectedUsers.length, count, answerList.length, title, unitData, passCountItem);

    if (
      courseItem === "" ||
      !subject ||
      selectedUsers.length <= 0 ||
      count <= 0 ||
      answerList.length <= 0 ||
      title === "" ||
      !unitData ||
      passCountItem < 0
    ) {
        setCheckSubmitAvailable(false);
    } else {
        setCheckSubmitAvailable(true);
    }

  }, [startDate, startHour, startMinute, endDate, endHour, endMinute, courseItem, subject, count, title, unitData, passCountItem]);

  const onCreate = () => {
    let answer = JSON.stringify(answerList);

    let testers = [];
    selectedUsers.map((item, index) => {
      testers.push(item.member);
    });

    context.post(
      "quiz/add",
      {
        id: quizId,
        class_id: classId,
        title: title,
        subject: subject.name,
        count: count,
        pass_count: passCountItem,
        start_time: moment(startDate).format("YYYY-MM-DD") + " " + startHour + ":" + startMinute,
        end_time: moment(endDate).format("YYYY-MM-DD") + " " + endHour + ":" + endMinute,
        time: limitTime,
        testers: testers.join(","),
        answer_data: answer,
        status: 1,
      },
      (response) => {
        props.history.push("/class/quiz/" + classId);
      }
    );
  };
  
  const addNotification = (
    notificationType,
    notificationTitle,
    notificationMessage
  ) => {
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: "top-center",
      dismiss: {
        duration: 1000,
      },
    });
  };

  //수동상태 퀴즈 추가
  const addQuiz = () => {
    setQuizList([...quizList, { quiz: "" }]);
  };

  const handleNoteChange = (index, value) => {
    const newQuizList = [...quizList];
    newQuizList[index].quiz = value;
    setQuizList(newQuizList);
  };

  const getAnswerList = (answerList) => {
    setAnswerList(answerList);
    setCreateDone(true);
  };

  const getUnitData = (unitData) => {
    setUnitData(unitData);
  };

  // 학생을 배열에 추가하거나 제거하는 함수
  const addStudent = (index) => {
    const user = userList[index];
    setSelectedUsers((prevSelectedUsers) => {
      // 중복 체크: 이미 배열에 있는 사용자인지 확인
      if (
        prevSelectedUsers.some(
          (selectedUser) => selectedUser.member === user.member
        )
      ) {
        // 배열에서 해당 사용자 제거
        return prevSelectedUsers.filter(
          (selectedUser) => selectedUser.member !== user.member
        );
      } else {
        // 배열에 사용자 추가
        return [...prevSelectedUsers, user];
      }
    });
  };

  // 전체 선택 함수
  const selectAllStudents = () => {
    setSelectedUsers((prevSelectedUsers) => {
      if (prevSelectedUsers.length === userList.length) {
        // 이미 모두 선택된 경우 선택 해제
        return [];
      } else {
        // 모두 선택
        return userList;
      }
    });
  };

  // 퀴즈 생성 함수
  const createQuiz = () => {
    context.post(
      "quiz/create_quiz",
      {
        course: courseItem,
        subject: subject.name,
        unitData,
        count,
        note,
      },
      (response) => {
        try {
          const parsedResponse =
            typeof response === "string" ? JSON.parse(response) : response;
          setAnswerList(parsedResponse);
          setCreateQuizModal(true);
        } catch (error) {
          console.error("Error parsing response:", error);
          alert("다시 시도해주세요");
        }
      }
    );
  };

  return (
    <div style={{ width: "100%", height: "calc(100vh - 90px)" }}>
      <div
        className="main-content create-quiz"
        style={{
          width: "100%",
          height: "100%",
          padding: "18px 28px 0",
          borderRadius: 12,
          background: "#fff",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ReactNotifications />
        <div className="f-s-25 f-w-600 m-b-10 d-flex flex-row align-items-center">
          {/* <div className='head-flex'>
                        <div className={mode === 0 ? 'selected' : ''} onClick={() => setMode(0)}>자동</div>
                        <div className={mode === 1 ? 'selected' : ''} onClick={() => setMode(1)}>수동</div>
                    </div> */}
          <div>자동 퀴즈 생성하기</div>
          {createDone ? (
            <button
              className={
                submitAvailable
                  ? "quiz-create-button"
                  : "quiz-no-create-button"
              }
              style={{ marginLeft: "auto" }}
              disabled={!submitAvailable}
              onClick={() => setCreateAlert(true)}
            >
              {"출제하기"}
            </button>
          ) : (
            <button
              className={
                createAvaliable
                  ? "quiz-create-button"
                  : "quiz-no-create-button"
              }
              style={{ marginLeft: "auto" }}
              disabled={!createAvaliable}
              onClick={() => createQuiz()}
            >
              {"생성하기"}
            </button>
          )}
        </div>
        <div className="d-flex flex-row flex-1" style={{ overflow: "hidden" }}>
          <div
            className="info-wrap"
            style={{ flex: 1, overflowY: "auto", position: "relative" }}
          >
            <div className={"study-room-input"}>
              <div className="display-flex-important align-top f-w-600">
                <div>{"퀴즈 제목"}</div>
              </div>

              <input
                placeholder={"제목을 입력해주세요"}
                type={"text"}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <>
              <div style={{ display: "flex", gap: "10px" }}>
                <div>
                  <div className="display-flex-important study-room-input align-item-center f-w-600">
                    <div>{"학년"}</div>
                  </div>
                  <div className="display-flex-important m-t-10">
                    <Dropdown
                      isOpen={courseDropdownOpen}
                      toggle={() => toggleGradeDropdown()}
                      className="dropdown rank-dropdown quiz-dropdown"
                      style={{ width: "160px" }}
                      tag="li"
                    >
                      <DropdownToggle
                        tag="a"
                        className="display-flex-important align-item-center profile-dropdown"
                        style={{
                          background: "white",
                          margin: 0,
                          padding: "0px 15px",
                          height: "100%",
                        }}
                      >
                        <div
                          className="flex-grow-1"
                          style={{ color: "#3f3f3f", lineHeight: "13px" }}
                        >
                          {courseItem == "" ? "선택" : courseItem}
                        </div>
                        <img
                          src={"/assets/image/icon_arrow_down_black.png"}
                          alt=""
                        />
                      </DropdownToggle>
                      <DropdownMenu
                        className="dropdown-menu dropdown-menu-right profile-dropdown-menu"
                        tag="ul"
                      >
                        {courseList.map((item, idx) => (
                          <DropdownItem
                            key={idx}
                            className="dropdown-header display-flex-important justify-content-center f-s-15"
                            onClick={() => {
                              onChangeItems("grade", item);
                            }}
                          >
                            {item ?? "선택"}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
                <div>
                  <div className="display-flex-important study-room-input align-item-center f-w-600">
                    <div>{"과목"}</div>
                  </div>
                  <div className="display-flex-important m-t-10">
                    <Dropdown
                      isOpen={subjectDropdownOpen}
                      toggle={() => toggleSubjectDropdown()}
                      className="dropdown rank-dropdown quiz-dropdown"
                      tag="li"
                    >
                      <DropdownToggle
                        tag="a"
                        className="display-flex-important align-item-center profile-dropdown"
                        style={{
                          background: "white",
                          margin: 0,
                          padding: "0px 15px",
                          height: "100%",
                        }}
                      >
                        <div
                          className="flex-grow-1"
                          style={{ color: "#3f3f3f", lineHeight: "13px" }}
                        >
                          {subject ? subject.name : "선택" }
                        </div>
                        <img
                          src={"/assets/image/icon_arrow_down_black.png"}
                          alt=""
                        />
                      </DropdownToggle>
                      <DropdownMenu
                        className="dropdown-menu dropdown-menu-right profile-dropdown-menu"
                        tag="ul"
                      >
                        {subjectList.map((item, idx) => (
                          <DropdownItem
                            key={idx}
                            className="dropdown-header display-flex-important justify-content-center f-s-15"
                            onClick={() => {
                                if (item.ready) {
                                    onChangeItems("subject", item);
                                }
                            }}
                            style={{ cursor: item.ready ? "pointer" : "not-allowed" }}
                          >
                            {item ? `${item.name} ${item.ready ? "": "(준비중)"}` : "선택"}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div className="display-flex-important study-room-input align-item-center f-w-600">
                    <div>{"단원 선택"}</div>
                  </div>
                  <button
                    className="gray-btn"
                    disabled={courseItem === "" || !subject}
                    onClick={() => setAddUnitModal(true)}
                  >
                    {unitData ? (
                      unitData.classification
                    ) : (
                      <span>{"+ 추가하기"}</span>
                    )}
                  </button>
                </div>
              </div>
              <div style={{ display: "flex", gap: "10px" }}>
                <div>
                  <div className={"study-room-input"}>
                    <div className="display-flex-important align-top f-w-600">
                      <div>{"문항수"}</div>
                    </div>

                    <input
                      placeholder={"0"}
                      type={"text"}
                      value={count}
                      onChange={(e) => {
                        if (e.target.value.length < 1) {
                            setCount(0);
                            return;
                        }
                        setCount(parseInt(e.target.value))}
                        }
                    />
                  </div>
                </div>
                <div>
                  <div className={"study-room-input"}>
                    <div className="display-flex-important align-top f-w-600">
                      <div>{"합격 문항수"}</div>
                    </div>

                    <input
                      placeholder={"0"}
                      type={"text"}
                      value={passCountItem}
                      onChange={(e) =>
                        onChangeItems("pass_count", e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="display-flex-important study-room-input align-item-center f-w-600">
                <div>{"시험 기한"}</div>
              </div>
              <TimeRowWrapper>
                <div className="datepicker-custom-container timepicker-container">
                  <DateTime
                    value={startDate}
                    onChange={(date) => {
                        setStartDate(new Date((date as moment.Moment).format("YYYY-MM-DD")));
                    }}
                    isValidDate={minDateRange}
                    timeFormat={false}
                    className="create-room-datepicker"
                  />
                </div>
                <TimeInput
                  id="timeInput"
                  type="text"
                  value={startHour}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length > 2) return;
                    if (value > "23") return;
                    if (value === "") {
                      setStartHour(0);
                      return;
                    }
                    setStartHour(parseInt(value));
                  }}
                />
                <span>:</span>
                <TimeInput
                  id="timeInput"
                  type="text"
                  value={startMinute}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length > 2) return;
                    if (value > "59") return;
                    if (value === "") {
                      setStartMinute(0);
                      return;
                    }
                    setStartMinute(parseInt(value));
                  }}
                />
                <span> ~ </span>
                <div className="datepicker-custom-container timepicker-container">
                  <DateTime
                    value={endDate}
                    onChange={(date) =>{
                        setEndDate(new Date((date as moment.Moment).format("YYYY-MM-DD")));
                    }}
                    isValidDate={minDateRange}
                    timeFormat={false}
                    className="create-room-datepicker"
                  />
                </div>
                <TimeInput
                  id="timeInput"
                  type="text"
                  value={endHour}
                  onChange={(e) => {
                    const value = e.target.value;

                    if (value.length > 2) return;
                    if (value > "23") return;
                    if (value === "") {
                      setEndHour(0);
                      return;
                    }

                    setEndHour(parseInt(value));
                  }}
                />
                <span>:</span>
                <TimeInput
                  id="timeInput"
                  type="text"
                  value={endMinute}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length > 2) return;
                    if (value > "59") return;
                    if (value === "") {
                      setEndMinute(0);
                      return;
                    }

                    setEndMinute(parseInt(value));
                  }}
                />
              </TimeRowWrapper>
              <div className={"study-room-input"}>
                <div className="display-flex-important align-top f-w-600">
                  <div>
                    {"비고"}
                    <span className="quiz-note"> *선택사항</span>
                  </div>
                </div>

                <textarea
                  placeholder={
                    "더 상세한 퀴즈를 생성하고 싶으면 자세한 상황 부여는 여기 적어주세요."
                  }
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>
            </>
            <>
              <PerfectScrollbar style={{ height: "55%" }}>
                {quizList.length > 0 &&
                  quizList.map((item, index) => (
                    <React.Fragment key={index}>
                      <div className="study-room-input">
                        <div className="display-flex-important align-top f-w-600">
                          <div>{index + 1}번 퀴즈</div>
                        </div>
                        <textarea
                          placeholder="출제할 퀴즈를 적어주세요."
                          value={item.quiz}
                          onChange={(e) =>
                            handleNoteChange(index, e.target.value)
                          }
                        />
                      </div>
                    </React.Fragment>
                  ))}
              </PerfectScrollbar>
              <button
                className={"quiz-create-button"}
                style={{ marginLeft: "auto" }}
                onClick={addQuiz}
              >
                {"+ 퀴즈 추가"}
              </button>
            </>
          </div>
          <div
            style={{
              width: 1,
              background: "#DFE1E8",
              margin: "18px 24px 24px",
            }}
          />
          <div className="d-flex flex-column col-md-4">
            <div className="students-list" style={{ maxWidth: "unset" }}>
              <div className="list-header">
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "end" }}
                >
                  <div className="text">{"학생"}</div>
                  <div className="participates" style={{ marginRight: "10px" }}>
                    <div className="active">
                      {selectedUsers.length + "명 / "}
                    </div>
                    <div className="normal">{userList.length + "명"}</div>
                  </div>
                </div>
                <div
                  style={{
                    cursor: "pointer",
                    color: "#505050",
                    fontSize: "13px",
                  }}
                  onClick={() => selectAllStudents()}
                >
                  전체선택
                </div>
              </div>
              <div className="list-content">
                {userList.map((student, index) => {
                  const isSelected = selectedUsers.some(
                    (selected) => selected.member === student.member
                  ); // 학생이 selectedUsers에 있는지 확인
                  return (
                    <div
                      key={index}
                      className={
                        "student-item" + (isSelected ? " selected" : "")
                      }
                      onClick={() => {
                        addStudent(index);
                      }}
                    >
                      <img
                        className="profile"
                        src={
                          student.profile != null
                            ? context.loadImage(student.profile)
                            : "/assets/image/chewing_plus_logo.png"
                        }
                      />
                      <div
                        className="d-flex flex-column"
                        style={{ marginLeft: 12 }}
                      >
                        <div className="nickname">{student.name}</div>
                        <div className="info">
                          {student.school +
                            " • " +
                            (Number(getAgeFromBirthday(student.birthday)) >= 19
                              ? strings.g3
                              : Number(getAgeFromBirthday(student.birthday)) ===
                                18
                              ? strings.g2
                              : strings.g1)}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {saveAlert && (
        <SweetAlert
          showCancel
          confirmBtnText={strings.save}
          confirmBtnBsStyle="primary"
          cancelBtnText={strings.cancel}
          cancelBtnBsStyle="default"
          title={"임시 저장"}
          customClass="quiz-save-alert"
          onConfirm={() => onTempSave()}
          onCancel={() => setSaveAlert(false)}
          confirmBtnCssClass="alert-confirm"
          cancelBtnCssClass="alert-cancel"
        >
          <div>
            {"임시 저장을 하시겠습니까?"}
            <br />
            {"학생에겐 보이지 않습니다."}
          </div>
        </SweetAlert>
      )}
      {addUnitModal && (
        <AddUnitModal
          onClose={() => {
            setAddUnitModal(false);
          }}
          getUnitData={getUnitData} // 단원 선택 데이터 가져오기
          course={courseItem}
          subject={subject.name}
        />
      )}

      {createQuizModal && (
        <CreateQuizModal
          onClose={() => {
            setCreateQuizModal(false);
          }}
          getAnswerList={getAnswerList}
          title={title}
          count={count}
          answerList={answerList}
          unitData={unitData}
          course={courseItem}
          subject={subject}
        />
      )}

      {createAlert && (
        <SweetAlert
          showCancel
          confirmBtnText={"출제"}
          confirmBtnBsStyle="primary"
          cancelBtnText={strings.cancel}
          cancelBtnBsStyle="default"
          title={"퀴즈 출제"}
          customClass="exam-save-alert"
          onConfirm={() => onCreate()}
          onCancel={() => setCreateAlert(false)}
          confirmBtnCssClass="alert-confirm"
          cancelBtnCssClass="alert-cancel"
        >
          <div>
            {"퀴즈를 출제 하시겠습니까?"}
            <br />
            {"출제 하시면 퀴즈방에 바로 업로드 됩니다."}
          </div>
        </SweetAlert>
      )}
    </div>
  );
};

const DropDownBox = styled.ul`
  display: block;
  position: absolute;
  margin: 0 auto;
  padding: 14px 13px 0;
  width: 100%;
  border-radius: 7px;
  border: 1px solid #dfe1e8;
  background: #fff;
  box-shadow: 0px 10px 30px 0px rgba(134, 134, 134, 0.25);
  list-style-type: none;
  z-index: 3;
  max-height: 200px; /* 최대 높이 설정 */
  overflow: auto; /* 스크롤바 표시 설정 */
`;

const DropDownItem = styled.li`
  text-align: start;
  padding: 0px 10px;
  margin: 10px 0px;
  &.selected {
    background-color: #eff3f9;
    border: 1px solid #eff3f9;
    border-radius: 8px;
  }
`;

const TimeRowWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const TimeInput = styled.input`
  width: 60px;
  height: 50px;
  border-radius: 5px;
  border: #e9ecee 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fdfdfd;
  padding: 0 20px;
  font-size: 15px;
  margin-right: 10px;
`;

export default withRouter(CreateQuiz);
