import React, { useState } from "react";
import ChatInput from "../../../components/control/custom_input/chat_input";
import ChatItem from "../../../components/control/item/chat_item";
import strings from "../../../lang/strings";
import styled from "styled-components";
import { Socket } from "socket.io-client";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { rootStore } from "../../../mobx/store";


interface IRoomMember {
    user: {
        id: number;
        name: string;
        video: "ON" | "OFF";
        speaker: "ON" | "OFF";
        mirror: "ON" | "OFF";
    };
    member: number;
    videoTrack?: any;
    audioTrack?: any;
    reaction?: any;
    studyTime?: number;
}

interface IChat {
    sender: number;
    sender_name: string;
    sender_profile?: string;
    receiver?: number;
    receiver_name?: string;
    message: string;
    time: string;
    show_profile: boolean;
    show_time: boolean;
}

interface IChatRightContainerProps {
    memberList: IRoomMember[];
    onSendChat: (message: string, receiver?: number, receiver_name?: string) => void;
    chatList: IChat[]; 
}


const ChatRightContainer = (props: IChatRightContainerProps) => {
    const { memberList, onSendChat, chatList } = props;
    const [chatInput, setChatInput] = useState("");
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [ selectedMemberIndex, setSelectedMemberIndex ] = useState<number>(-1);

    return (
        <ChatRightWrapper>
            <ChatHeader>
                <div style={{color: '#747383'}}>{'채팅'}</div>
                <div className="chat-member-count m-l-auto" style={{ width: 'auto', height: 'auto', background: 'none' }}>
                    <img src={"/assets/image/icon_user_gray.png"} alt="" />
                    <div className="f-s-11 p-r-5" style={{ lineHeight: '17px', color: '#747383' }}>{memberList.length}</div>
                </div>
            </ChatHeader>
            <ChatArea>
                    {chatList.length != 0 &&
                        chatList.map((item, idx) => {
                            return (
                                <ChatItem
                                    key={idx}
                                    item={item}
                                />)
                        })
                    }
            </ChatArea>
            <ChattingTargetContainer>
                <span>To: </span>
                <Dropdown isOpen={isDropdownOpen} toggle={() => setIsDropdownOpen(prev => !prev)}>
                    <DropdownToggle style={{height: 10, display:"flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                        <span style={{fontSize: 10}}>{selectedMemberIndex === -1 ? strings.all : memberList[selectedMemberIndex].user.name}</span>
                    </DropdownToggle>
                    <DropdownMenu>
                        <div className="dropdown-item" onClick={() => {
                            setSelectedMemberIndex(-1)
                            setIsDropdownOpen(false)
                            }}>
                            <div className="text-ellipsis opponent-name">{strings.all}</div>
                        </div>
                        {memberList.map((item, idx) => {
                            if(item.user.id == rootStore.getProfile.id) return null;

                            return (
                                <div key={idx} className="dropdown-item" onClick={() => {
                                    setSelectedMemberIndex(idx)
                                    setIsDropdownOpen(false)
                                }}>
                                    <div className="text-ellipsis opponent-name">{item.user.name}</div>
                                </div>
                            )
                        })}
                    </DropdownMenu>
                </Dropdown>
            </ChattingTargetContainer>
                <ChatInput
                    placeholder={strings.enter_chat}
                    onChange={(v) => setChatInput(v)}
                    value={chatInput}
                    inputType={"text"}
                    onSend={() => {
                        if (chatInput != "" && chatInput.replace(/ /g, "") != "") {
                            setChatInput("")
                            if(selectedMemberIndex === -1) {
                                onSendChat(chatInput);
                            } else {
                                onSendChat(chatInput, memberList[selectedMemberIndex].user.id, memberList[selectedMemberIndex].user.name);
                        }
                    }}}/>       
        </ChatRightWrapper>
    );
}

export default ChatRightContainer;


const ChatRightWrapper = styled.div`
    overflow-y: auto;
    height: calc(100% - 133px);
    width: 350px;
    position: absolute;
    right: 0;
    background: #353443;
    margin-top: -11px;
    border-left: black 1px solid;
    z-index: 30;
`;

const ChatHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: #353443;
    color: white;
    font-size: 16px;
    font-weight: bold;
    height: 40px;
`;

const ChatArea = styled.div`
    padding: 0 15px;
    height: calc(100% - 133px);
    flex: 1 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
    background: #353443;
`;

const ChattingTargetContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    background: #353443;
    color: white;
    font-size: 10px;
    font-weight: bold;
    height: 20px;
`;