import React from "react";

const ReportProgressBar = (props) => {

    const { yesterday, today, indicator, yesterdayPercent, todayPercent, margin } = props;

    return (
        <>
            <div className={(margin ? "m-b-5 m-t-15" : "m-t-25 m-b-15")}>
                <div className="myscore-indicator align-item-center">{indicator}</div>
                <div className="d-flex align-item-center">
                    <div className="progress rounded-left report-comparison-bar progress-bar-left">
                        <div className="progress-bar" style={{
                            width: `${yesterdayPercent}%`,
                            backgroundColor: "#4f5a5f",
                            transition: 'all .2s ease-out',
                            minWidth: '0px',
                        }}
                        />
                    </div>
                    <div>
                        <img src={"/assets/image/img_test_01.png"} />
                    </div>
                    <div className="progress rounded-right myscore-comparison-bar flex-row-reverse progress-bar-right">
                        <div className="progress-bar" style={{
                            width: `${todayPercent}%`,
                            backgroundColor: "#0f84f4",
                            transition: 'all .2s ease-out',
                            minWidth: '0px',
                        }}
                        />
                    </div>
                </div>
                <div className="d-flex align-item-center">
                    <div className="p-2 myscore-compare-yesterday">{yesterday}</div>
                    <div className="ms-auto myscore-compare-today">{today}</div>
                </div>
            </div>
        </>
    );
}

export default ReportProgressBar;
