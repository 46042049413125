import React from 'react';
import { IconType } from "../../type/icon_type";

const Pencil = ({ width, height, color }: IconType) => {
    return (
    <svg width={width || "24"} height={height || "24"} viewBox="0 0 24 24" stroke={color} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.4 6.40033L17.6001 9.60036M19.3393 7.84994C19.7623 7.42707 19.9999 6.8535 20 6.25541C20.0001 5.65731 19.7626 5.08368 19.3397 4.66071C18.9168 4.23774 18.3433 4.00008 17.7452 4C17.1471 3.99992 16.5734 4.23745 16.1505 4.66031L5.47356 15.3396C5.28781 15.5248 5.15045 15.7528 5.07356 16.0036L4.01675 19.4852C3.99607 19.5544 3.99451 19.6279 4.01223 19.6979C4.02995 19.7679 4.06628 19.8318 4.11739 19.8829C4.16849 19.9339 4.23245 19.9701 4.30248 19.9877C4.37251 20.0053 4.446 20.0036 4.51515 19.9829L7.99758 18.9268C8.24812 18.8506 8.47613 18.7141 8.66159 18.5292L19.3393 7.84994Z" stroke={color} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    );
}

export default Pencil;