import React, { useContext, useEffect, useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from "styled-components";
import strings from "../../../lang/strings";

import { Page_settings } from '../../../config/page_settings';
import { rootStore } from "../../../mobx/store";
import { API_URL } from "../../../config/const";
import axios from "axios";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import moment from "moment";
import PerfectScrollbar from 'react-perfect-scrollbar';

function StudentWarningModal(props) {

  const [profile, setProfile] = useState("");
  



  const handleClose = () => {
    props.onClose?.();
  };

  const history = useHistory();

  const context = useContext(Page_settings);
  const limit_time = [];

  useEffect(() => {
    

  }, []);

  const moveTest = () => {
    history.push(`/curriculum/test/${props.sectionItem.test.id}`);
  }


  const movePrevent = () => {
    history.goBack();
  }


  return (
    <>

      <Overlay>
        <ModalWrap>
          <div className='student-warning-container'>
            <div className='modal-header'>
              <img class="exit-btn cursor" onClick={handleClose} src="/assets/image/icon_close_gray.png" />
            </div>
            <div className='middle'>
              <img class="warning-rai" src="/assets/image/img_warning_rai.png" />
              <div className='warning-content'>
                {props.mode === "test" ?
                  <>
                    <div>시험을 응시하게 되면 다른창으로 이동할 수 없습니다.</div>
                    <div>임시저장 또는 시험중단이 되지 않으니 신중하게 시작해주세요</div>
                  </>
                  :
                  <>
                  <div>문제풀이 중간에 나가면 풀이시간이 인정되지 않아요. </div>
                  <div>모든 문제를 풀고 <span className="highlight">뒤로가기</span>를 눌러주세요.</div>
                  </>
                }
              </div>
              <div className='bottom'>
                <div className='btn-container'>
                {props.mode === "test" ?
                  <button onClick={()=>moveTest()}>테스트 시작</button>
                  :
                  <>
                  <button className='keep-test' onClick={()=>handleClose()}>문제 마저 풀기</button>
                  <button onClick={()=>movePrevent()}>뒤로가기</button>
                  </>
                  }
                </div>
              </div>
            </div>
          </div>
        </ModalWrap>
      </Overlay>
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
`;

const ModalWrap = styled.div`
  width: 450px;
  height: 470px;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  
`;



export default StudentWarningModal;