import React from "react";
import Lightbox from "react-18-image-lightbox";

const ImageViewer = (props: {file: string, name: string, toggle: () => void}) => {
    return <Lightbox
       mainSrc={props.file}
       onCloseRequest={props.toggle}
    />
}

export default ImageViewer;
