import React, { useContext, useEffect, useRef, useState } from 'react';
import { Modal, ModalBody } from "reactstrap";
import strings from "../../../lang/strings";
import './modal.scss'
import StudyRoomInput from "../custom_input/study_room_input";
import { rootStore } from "../../../mobx/store";
import { Page_settings } from "../../../config/page_settings";
import { observer } from "mobx-react";
import { extractNumber } from '../../../helper/common';

const SetGoalModal = observer((props) => {
    const context = useContext(Page_settings);
    const [hour, setHour] = useState(0);
    const [minute, setMinute] = useState(0);
    const [hourFocused, setHourFocused] = useState(false);
    const [minuteFocused, setMinuteFocused] = useState(false);

    useEffect(() => {
        setInfo();
    }, []);

    const setInfo = () => {
        const time = props.goalTime ?? ""
        if (time != "") {
            const hourStr = time.split(':')[0];
            const minuteStr = time.split(':')[1];
            setHour(Number(hourStr));
            setMinute(Number(minuteStr));
        }
    }

    const onApply = () => {
        if (hour == 0 && minute == 0) {
            alert('시간을 입력하세요');
            return;
        }

        const hourStr = hour.toString().padStart(2,'0');
        const minuteStr = minute.toString().padStart(2, '0');
        const time = hourStr + ':' + minuteStr;

        context.post(
            "member/updateGoal",
            {
                time: time,
            },
            response => {
                props.onApply(time);
            }
        );
    }

    const onOpened = () => {

    }

    const { show, toggle } = props;
    return (
        <Modal isOpen={show} toggle={() => toggle()} onOpened={() => onOpened()} className="set-goal-modal">
            <ModalBody className="">
                <div className='d-flex align-items-center'>
                    <div className='f-s-15 f-w-600'>{'목표 시간 설정'}</div>
                    <div className='flex-grow-1' />
                    <button className='modal-close-btn' onClick={() => toggle()}>
                        <img src={"/assets/image/icon_close_black.png"} alt="" style={{ width: 16, height: 16 }} />
                    </button>
                </div>
                <div className="d-flex align-items-center m-t-10 m-b-10" style={{justifyContent: 'space-between'}}>
                    <div className={'goal-time-input' + (hourFocused ? ' active' : '')}>
                        <input
                            type={'text'}
                            value={hour}
                            placeholder={''}
                            maxLength={2}
                            onChange={(e) => { setHour(extractNumber(e.target.value)); }}
                            onFocus={() => {
                                setHourFocused(true)
                            }}
                            onBlur={() => {
                                setHourFocused(false)
                            }}
                        />
                        시간
                    </div>

                    <div className={'goal-time-input' + (minuteFocused ? ' active' : '')}>
                        <input
                            type={'text'}
                            value={minute}
                            placeholder={''}
                            maxLength={2}
                            onChange={(e) => { setMinute(extractNumber(e.target.value)); }}
                            onFocus={() => {
                                setMinuteFocused(true)
                            }}
                            onBlur={() => {
                                setMinuteFocused(false)
                            }}
                        />
                        분
                    </div>

                    <button className='goal-apply-btn' onClick={() => onApply()}>
                        {'적용'}
                    </button>
                </div>
                <div className='goal-time-desc'>{'* 최대 24시간까지 가능합니다.'}</div>
            </ModalBody>
        </Modal>

    );
})

export default SetGoalModal;
