import {withRouter} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {Page_settings} from "../../config/page_settings";
import strings from "../../lang/strings";
import {formatNumber, getAgeFromBirthday, getDecimal} from "../../helper/common";
import StarRatingCustom from "../../components/control/item/star_rating";
import ProgressBar from "../../components/control/progress_bar/progress_bar";
import StudentReviewItem from "../../components/control/item/student_review_item";
import ImageViewModal from "../../components/control/modal/image_view_modal";
import {Store} from "react-notifications-component";
import WriteReview from "../../components/control/modal/write_review";
import ReviewDetailModal from "../../components/control/modal/review_detail_modal";

const ExpertDetail = (props) => {

    const context = useContext(Page_settings);
    const [tabIndex, setTabIndex] = useState(props.match.params.tab);
    const expertId = parseInt(props.match.params.expert_id);
    const [header, setHeader] = useState(false);
    const [g3, setG3] = useState(0);
    const [g2, setG2] = useState(0);
    const [g1, setG1] = useState(0);
    const [reviewList, setReviewList] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageLength, setPageLength] = useState([]);
    const [img, setImg] = useState("");
    const [showImage, setShowImage] = useState(false);
    const pocariImg = ["/assets/image/image_expert detail_1.png", "/assets/image/image_expert detail_2.png", "/assets/image/image_expert detail_3.png"];
    const [expertInfo, setExpertInfo] = useState("");
    const [reviewModalShow, setReviewModalShow] = useState(false);
    const [reviewDetailShow, setReviewDetailShow] = useState(false);
    const [star, setStar] = useState(0);

    useEffect(() => {
        context.setContentBgType(2);

        window.scrollTo(0, 0);
        window.addEventListener('scroll', listenToScroll);
        getExpertDetail(expertId);
        getReview(1);

        return () => {
            context.setContentBgType(1);

            window.removeEventListener('scroll', listenToScroll);
        }
    }, []);

    useEffect(() => {
        getReview(pageNum)
    }, [pageNum])

    const getExpertDetail = (expert_id) => {
        context.get(
            "clinic/getExpertDetail",
            {
                expert_id: expert_id
            },
            response => {
                setExpertInfo(response.expert_info);
            }
        );
    }

    const getReview = (pageNum) => {
        context.get(
            "clinic/getReviewList",
            {
                expert_id: expertId,
                page_num: pageNum
            },
            response => {
                setReviewList(response.list);
                let g1 = response.grade_review.g1_count != null ? parseInt(response.grade_review.g1_count) : 0
                let g2 = response.grade_review.g1_count != null ? parseInt(response.grade_review.g2_count) : 0
                let g3 = response.grade_review.g1_count != null ? parseInt(response.grade_review.g3_count) : 0

                if (g1 + g2 + g3 == 0) {
                    setG1(0)
                    setG2(0)
                    setG3(0)
                } else {
                    setG1(g1 / (g1 + g2 + g3) * 100)
                    setG2(g2 / (g1 + g2 + g3) * 100)
                    setG3(g3 / (g1 + g2 + g3) * 100)
                }
                let page_count = Math.ceil(response.count / response.limit);
                let list = [];
                for (let i = 1; i <= page_count; i++) {
                    list.push(i)
                }
                setPageLength(list)
            }
        );
    }

    const onWriteDone = (review, fileList) => {
        if (fileList.length != 0) {
            let formData = new FormData();
            fileList.forEach((entry, index) => {
                formData.append('file', entry);
            });

            context.post(
                'upload/images/room',
                formData,
                response => {
                    postReview(review, response.image);
                });
        } else {
            postReview(review, []);
        }

    }

    const addNotification = (notificationType, notificationTitle, notificationMessage) => {
        Store.addNotification({
            title: notificationTitle,
            message: notificationMessage,
            type: notificationType,
            insert: 'top',
            container: 'top-left',
            dismiss: {
                duration: 1000,
            }
        });
    };

    const postReview = (review, fileList) => {
        context.post(
            "clinic/postReview",
            {
                clinic: expertInfo.clinic_id,
                content: review,
                score: star,
                file: fileList.length != 0 ? fileList.join("#") : "",
            },
            response => {
                setReviewDetailShow(false);
                let newStatus = expertInfo
                newStatus.clinic_status = 3
                setExpertInfo(newStatus)
            }
        );
    }


    const listenToScroll = () => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        if (winScroll >= 550) {
            setHeader(true);
        } else {
            setHeader(false);
        }
    }

    const toFirst = () => {
        setPageNum(1)
    }

    const toEnd = () => {
        setPageNum(pageLength.length)
    }

    const onNext = () => {
        if (pageNum != pageLength.length) {
            setPageNum(pageNum + 1);
        }
    }

    const onPrevious = () => {
        if (pageNum != 1) {
            setPageNum(pageNum - 1);
        }
    }

    const onClickImage = (item, index) => {
        setImg(item.file.split("#")[index]);
        setShowImage(true)
    }

    const onBook = () => {

        if (expertInfo.clinic_status == 1 || expertInfo.clinic_status == 3) {
            return
        }
        if (expertInfo.clinic_status == 0) {
            context.post(
                "clinic/postClinic",
                {
                    expert_id: expertId
                },
                response => {
                    let newStatus = expertInfo
                    newStatus.clinic_status = 1
                    setExpertInfo(newStatus)
                }
            );
        } else {
            setReviewModalShow(true)
        }

    }

    return (
        <div className="expert-page position-relative">
            <div className="expert-page-scroll position-relative">
                <div className="d-flex justify-content-center">
                    <div className="expert-profile-container">
                        <img src={"/assets/image/img_radial_circle.png"} alt="" className="expert-back"/>
                        <div className="expert-advertise">
                            {strings.for_successful_admission}
                            <div className="new_consult">{strings.new_consulting}</div>
                        </div>
                        <button
                            disabled={expertInfo.clinic_status == 1 || expertInfo.clinic_status == 3}
                            className={("reservation-button ") + ((expertInfo.clinic_status == 1 || expertInfo.clinic_status == 3) ? "disabled-btn" : "")}
                            onClick={() => onBook()}>
                            {
                                expertInfo.clinic_status == 0 &&
                                <div>{strings.make_reservation}</div>
                            }
                            {
                                expertInfo.clinic_status == 1 &&
                                <div>{strings.reservation_complete}</div>
                            }
                            {
                                expertInfo.clinic_status == 2 &&
                                <div>{strings.write_review}</div>
                            }
                            {
                                expertInfo.clinic_status == 3 &&
                                <div>{strings.match_complete}</div>
                            }
                        </button>
                        <img className="expert-profile"
                             src={expertInfo.profile != null ? context.loadImage(expertInfo.profile) : "/assets/image/chewing_plus_logo.png"}
                             alt=""/>
                    </div>
                </div>
                <div className={("d-flex align-item-center justify-content-center ") + (header ? "expert-tab" : "")}>
                    {
                        strings.expert_detail_button_list.map((item, idx) => {
                            return (
                                <button
                                    key={idx}
                                    className={("expert-detail-button ") + (tabIndex == idx ? "expert-detail-button-selected" : "")}
                                    onClick={() => setTabIndex(idx)}>{item.name + (idx == 2 ? (expertInfo.review_count != undefined ? `(${formatNumber(expertInfo.review_count)})` : "(0)") : "")}</button>
                            )
                        })
                    }
                </div>
                <div className={header ? "m-t-40" : ""}>
                    {
                        tabIndex == 0 &&
                        <div>
                            <div
                                className={"expert-advertise-chewing "}>{strings.expert_advertise_chewing}</div>
                            <div className={("expert-detail-intro ") + (header ? "p-b-0" : "")}>
                                <div className="d-flex justify-content-center">
                                    <img
                                        src={expertInfo.profile != null ? context.loadImage(expertInfo.profile) : "/assets/image/chewing_plus_logo.png"}
                                        alt=""/>
                                    <div className="expert-intro">
                                        <div
                                            className="f-s-15 m-b-10">{(expertInfo != "" ? expertInfo.department : "") + " " + strings._consulting}</div>
                                        <div className="expert-name">
                                            <div
                                                className="flex-grow-1 m-r-10">{strings.consultant + "" + (expertInfo != "" ? expertInfo.name : "")}</div>
                                            <div
                                                className="f-s-20 f-w-800">{expertInfo != "" ? expertInfo.english_name : ""}</div>
                                        </div>
                                        <div className="f-s-16 m-t-35 f-w-600">{strings.resume}</div>
                                        <div
                                            className="intro-detail m-b-40 m-t-10">{expertInfo != "" ? expertInfo.resume : ""}</div>
                                        <div className="f-s-16 m-t-35 f-w-600 m-b-10">{strings.career}</div>
                                        <div
                                            className="intro-detail m-t-10">{expertInfo != "" ? expertInfo.career : ""}</div>
                                    </div>
                                </div>

                            </div>
                            <div className="pocari-consulting">
                                <div className="requirement">{"3" + " " + strings.requirements}</div>
                                <div className="f-s-32 text-center m-t-20">{strings.pocari_consulting}</div>
                                <div className="consulting-container">
                                    {
                                        strings.pocari_list.map((item, idx) => {
                                            return (
                                                <div
                                                    key={idx}
                                                    className={("img-container ") + (idx == 1 ? "m-r-40 m-l-40" : "")}>
                                                    <img src={pocariImg[idx]} alt="" className="consulting-back"/>
                                                    <div className="opacity-control">
                                                        <div>
                                                            <div className="sm-title m-b-20">{item.small}</div>
                                                            <div className="big-title">{item.big}</div>
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="anywhere">
                                <div className="text-center">
                                    <div className="f-s-20 m-t-25">{strings.anywhere_anytime}</div>
                                    <div className="f-s-36 consult-available">{strings.consulting_available}</div>
                                    <img src={"/assets/image/image_computer.png"} alt=""/>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        tabIndex == 1 &&
                        <div className="curriculum-tab">
                            <div className="f-s-24 f-w-600 m-b-20">{strings.curriculum}</div>
                            <div className="f-s-15 detail">{expertInfo.curriculum}</div>
                        </div>
                    }
                    {
                        tabIndex == 2 &&
                        <div className="curriculum-tab review-tab">
                            <div
                                className="f-s-24 f-w-600 m-b-10">{strings.review + (expertInfo.review_count != undefined ? `(${formatNumber(expertInfo.review_count)})` : "(0)")}</div>
                            <div className="d-flex align-content-lg-end m-b-50">
                                <div className="align-self-end d-flex flex-grow-1">
                                    <StarRatingCustom
                                        starCnt={expertInfo.score}
                                        fullStar={"/assets/image/icon_fav_active.png"}
                                        halfStar={"/assets/image/icon_fav_active.png"}
                                        emptyStar={"/assets/image/icon_fav_inactive.png"}
                                        disabled={true}
                                        padding={true}
                                        size={40}
                                    />
                                    <div
                                        className={("rating-count m-l-15 align-self-end ") + (expertInfo.score > 0 ? "rating-count-active" : "")}>{expertInfo.score}</div>
                                    <div className="rating-count m-l-10 align-self-end">/ 5</div>
                                </div>
                                <div className="expert-progress">
                                    <div className="d-flex align-item-center m-b-15">
                                        <div
                                            className="f-s-15 m-l-auto progress-indicator-left m-r-10" style={{width: "70px"}}>{strings.g3_ns}</div>
                                        <ProgressBar
                                            percent={g3}
                                            color={g3 < 50 ? "#bfbfbf" : "black"}
                                            height={5}
                                            width={"180px"}
                                        />
                                        <div className="f-s-18 f-w-600 m-l-10 progress-indicator-right">{getDecimal(g3.toString()) + "%"}</div>
                                    </div>
                                    <div className="d-flex align-item-center m-b-15">
                                        <div
                                            className="f-s-15 m-l-auto progress-indicator-left m-r-10">{strings.g2}</div>
                                        <ProgressBar
                                            percent={g2}
                                            color={g2 < 50 ? "#bfbfbf" : "black"}
                                            height={5}
                                            width={"180px"}
                                        />
                                        <div className="f-s-18 f-w-600 m-l-10 progress-indicator-right">{getDecimal(g2.toString()) + "%"}</div>
                                    </div>
                                    <div className="d-flex align-item-center">
                                        <div
                                            className="f-s-15 m-l-auto progress-indicator-left m-r-10">{strings.g1}</div>
                                        <ProgressBar
                                            percent={g1}
                                            color={g1 < 50 ? "#bfbfbf" : "black"}
                                            height={5}
                                            width={"180px"}
                                        />
                                        <div className="f-s-18 f-w-600 m-l-10 progress-indicator-right">{getDecimal(g1.toString()) + "%"}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="m-b-50">
                                {
                                    reviewList.map((item, idx) => {
                                        let age = getAgeFromBirthday(item.user.birthday);
                                        return (
                                            <StudentReviewItem
                                                key={idx}
                                                item={item}
                                                age={age}
                                                onClickImage={(index) => onClickImage(item, index)}
                                            />
                                        )
                                    })
                                }
                                {
                                    reviewList.length == 0 &&
                                    <div className="f-s-30 f-w-600 text-center">{strings.no_review}</div>
                                }
                            </div>
                            {
                                pageLength.length != 0 &&
                                <div className="d-flex align-items-center justify-content-center">
                                    <button className="common-button page-btn f-s-20 m-r-20"
                                            onClick={() => toFirst()}>
                                        <img src={"/assets/image/icon_pagination_backward.png"} alt=""/>
                                    </button>
                                    <button className="common-button page-btn f-s-20 m-r-20"
                                            onClick={() => onPrevious()}>
                                        <img src={"/assets/image/icon_pagination_prev.png"} alt=""/>
                                    </button>
                                    {
                                        pageLength.map((item, idx) => {
                                            return (
                                                <button
                                                    key={idx}
                                                    onClick={() => {
                                                        setPageNum(item);
                                                    }}
                                                    className={("common-button f-s-20 f-w-600 m-r-20 ") + (pageNum == item ? "" : "page-btn")}>{item}</button>
                                            )
                                        })
                                    }
                                    <></>
                                    <button className="common-button page-btn f-s-20 m-r-20"
                                            onClick={() => onNext()}>
                                        <img src={"/assets/image/icon_pagination_next.png"} alt=""/>
                                    </button>
                                    <button className="common-button page-btn f-s-20 m-r-20"
                                            onClick={() => toEnd()}>
                                        <img src={"/assets/image/icon_pagination_forward.png"} alt=""/>
                                    </button>
                                </div>
                            }
                        </div>
                    }
                    {
                        tabIndex == 3 &&
                        <div className="curriculum-tab refund-tab">
                            <div className="f-s-24 f-w-600 m-b-35">{strings.refund_policy}</div>
                            <div className="f-s-18 f-w-600 m-b-20">{strings.precaution_refund_policy}</div>
                            <div className="detail">{expertInfo.refund_policy}</div>
                            {/*<div className="m-t-35 f-s-16 f-w-600 m-b-15">{strings.more_than_days_after}</div>*/}
                        </div>
                    }
                </div>
                <ImageViewModal
                    show={showImage}
                    toggle={() => setShowImage(!showImage)}
                    close={() => setShowImage(false)}
                    image={img}
                />
                <WriteReview
                    show={reviewModalShow}
                    toggle={() => setReviewModalShow(!reviewModalShow)}
                    close={() => setReviewModalShow(false)}
                    expert={expertInfo}
                    onNext={(starCnt) => {
                        setStar(starCnt);
                        setReviewModalShow(false);
                        setReviewDetailShow(true);
                    }}/>
                <ReviewDetailModal
                    show={reviewDetailShow}
                    toggle={() => setReviewDetailShow(!reviewDetailShow)}
                    close={() => setReviewDetailShow(false)}
                    expert={expertInfo}
                    onPhoto={() => {
                        addNotification('info', strings.in_progress)
                    }}
                    maxImageError={() => {
                        addNotification('warning', strings.you_cannot_select_more)
                    }}
                    onWrite={(review, fileList) => onWriteDone(review, fileList)}
                />
            </div>
            {
                header &&
                <div className="d-flex justify-content-center position-fixed flex-grow-1" style={{bottom:10, zIndex:2, width: "calc(100vw - 220px)"}}>
                    <div className="button-hide">
                        <div className="flex-grow-1 m-l-20">{strings.hour_Q_A_available}</div>
                        <button
                            disabled={expertInfo.clinic_status == 1 || expertInfo.clinic_status == 3}
                            className={((expertInfo.clinic_status == 1 || expertInfo.clinic_status == 3) ? "disabled-btn" : "")}
                            onClick={() => onBook()}> {
                            expertInfo.clinic_status == 0 &&
                            <div>{strings.make_reservation}</div>
                        }
                            {
                                expertInfo.clinic_status == 1 &&
                                <div>{strings.reservation_complete}</div>
                            }
                            {
                                expertInfo.clinic_status == 2 &&
                                <div>{strings.write_review}</div>
                            }
                            {
                                expertInfo.clinic_status == 3 &&
                                <div>{strings.match_complete}</div>
                            }</button>
                    </div>
                </div>
            }
        </div>
    )
};
export default withRouter(ExpertDetail);
