import React, { useState, useContext } from 'react';
import { Document, Page } from 'react-pdf';
import { Page_settings } from '../../../config/page_settings';
import styled from 'styled-components';

const ExamFileViewer = (props) => {
    const { url } = props
    const [numPages, setNumPages] = useState<number>();
    const [pageNumber, setPageNumber] = useState<number>(1);
  
    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
      setNumPages(numPages);
    }
    return (
    <div style={{display:'flex', flexDirection:"column", alignItems: 'center'}}>
        <Document
            file={{url}}
            loading={<div>Loading PDF...</div>}
            onLoadSuccess={onDocumentLoadSuccess}
            className={"exam-viewer"}
        >
            <Page 
            pageNumber={pageNumber}
            scale={1.0}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            />
            <Page 
            pageNumber={pageNumber + 1}
            scale={1.0}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            />
        </Document>
        <PageWrapper>
            <button onClick={() => pageNumber > 1 && setPageNumber(pageNumber - 2)}>이전</button>
                <span>{pageNumber} / {numPages}</span>
            <button onClick={() => pageNumber < numPages && setPageNumber(pageNumber + 2)}>다음</button>
        </PageWrapper>
    </div>
    );
}

export default React.memo(ExamFileViewer);

const PageWrapper = styled.div`
    padding: 0 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`
