import React, { useContext, useEffect, useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from "styled-components";
import strings from "../../../lang/strings";

import { Page_settings } from '../../../config/page_settings';
import { rootStore } from "../../../mobx/store";
import { API_URL } from "../../../config/const";
import axios from "axios";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import moment from "moment";
import PerfectScrollbar from 'react-perfect-scrollbar';

function FinishCreateCurriculumModal(props) {

  const [profile, setProfile] = useState("");


  const handleClose = () => {
    history.push('/curriculum/create_curriculum_lobby', { showAlert: false });
    props.onClose?.();
  };

  const history = useHistory();

  const context = useContext(Page_settings);
  const limit_time = [];

  useEffect(() => {
    if (rootStore.getProfile != null) {
      setProfile(rootStore.getProfile.profile);
    }
  }, []);


  return (
    <>

      <Overlay>
        <ModalWrap>
          <div className='finish-create-curriculum-modal'>
            <div className='title'>커리큘럼은 생성되었어요✨</div>
            <div className='finish-comment'>저장된 커리큘럼은 클라우드에서 확인 가능합니다.</div>
            <div className="container-bottom">
          <button className="confirm-btn" onClick={() => handleClose()} >확인</button>
        </div>
          </div>
        </ModalWrap>
      </Overlay>
    </>
  );
}

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  
`;

const ModalWrap = styled.div`
  width: 380px;
  height: 170px;
  border-radius: 15px;
  background-color: #fff;
  position: absolute;
  top: 45%;
  left: 55%;
  transform: translate(-50%, -50%);
  overflow: auto;
  box-shadow: 0px 10px 20px 0px rgba(174, 174, 174, 0.25);
`;



export default FinishCreateCurriculumModal;