import React from "react";

const ReportProgressBar = (props) => {

    const {yesterday, today, indicator, yesterdayPercent, todayPercent, margin} = props;

    return (
        <div className={("d-flex align-item-center ") + (margin ? "m-b-10 m-t-20" :"m-t-25 m-b-15")}>
            <div className="report-compare-yesterday">{yesterday}</div>
            <div className="progress rounded-corner report-comparison-bar flex-row-reverse">
                <div className="progress-bar" style={{
                    width: `${yesterdayPercent}%`,
                    backgroundColor: "#4f5a5f",
                    transition: 'all .2s ease-out',
                    minWidth: '0px',
                }}
                />
            </div>
            <div className="report-indicator">{indicator}</div>
            <div className="progress rounded-corner report-comparison-bar">
                <div className="progress-bar" style={{
                    width: `${todayPercent}%`,
                    backgroundColor: "#0f84f4",
                    transition: 'all .2s ease-out',
                    minWidth: '0px',
                }}
                />
            </div>
            <div className="report-compare-today">{today}</div>
        </div>
    );
}

export default ReportProgressBar;
