import React, {useContext} from 'react';
import strings from "../../../lang/strings";
import {getTimeFromSecond} from "../../../helper/common";
import {Page_settings} from "../../../config/page_settings";

const RankItem = (props) => {
    const context = useContext(Page_settings)
    const {theme, item, title} = props;

    return (
        <div className={"rank-item-content rank-item-" + theme}>
            <div className="media-body">
                <div className="display-flex-important">
                    <div className="fs-15">{title}</div>
                    <div style={{flex: 1}}/>
                    <img style={{objectFit:"cover"}} src={item.user.profile != null && item.user.profile != "" ? context.loadImage(item.user.profile) : "/assets/image/chewing_plus_logo.png"} alt=""/>
                </div>
                <div style={{display: "flex", alignItems: "center", marginTop: 10}}>
                    <img className='medal' src={'/assets/image/icon_medal_' + theme + '.png'} alt=""/>
                    <div className="ranker-name-list">{item.user.name}</div>
                    <div style={{flex: 1}}/>
                    <div className="header-month-time">
                        <div className="header-month">{strings.month}</div>
                        <div className={"header-time time-" + theme}>{getTimeFromSecond(parseInt(item.real_time))}</div>
                    </div>
                    <div className="header-vertical-divider"/>
                    <div className="header-month-time">
                        <div className="header-month">{strings.today}</div>
                        <div className="today-time">{getTimeFromSecond(parseInt(item.today_time))}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RankItem;
