import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import 'react-datetime/css/react-datetime.css';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { Page_settings } from "../../../config/page_settings";
import { getTimeFromSecond, getMinuteFromSecond } from '../../../helper/common';
import { rootStore } from '../../../mobx/store';
import moment from "moment";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const QuizStudent = (props) => {
    const context = useContext(Page_settings);
    const quizId = parseInt(props.match.params.quiz_id);
    const history = useHistory();
    const [quizInfo, setQuizInfo] = useState(null);
    const [quizResultInfo, setQuizResultInfo] = useState(null);
    const [extraScoreType, setExtraScoreType] = useState(0);
    const [extraScore, setExtraScore] = useState(0);
    const [userAnswerList, setUserAnswerList] = useState([]);
    const [showCommentary, setShowCommentary] = useState(false);
    const [showFeedback, setShowFeedback] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [answerList, setAnswerList] = useState([{}]);
    const [name, setName] = useState('')
    const [profile, setProfile] = useState('')
    



    useEffect(() => {
        getQuizInfo();
        getQuizResult();
        if (rootStore.getProfile !== "") {
            setName(rootStore.getProfile.name);
            setProfile(rootStore.getProfile.profile);
        }
    }, []);

    const getQuizInfo = () => {
        context.post(
            'quiz/info',
            {
                id: quizId
            },
            response => {
                console.log("info", response)
                const quizInfo = response.info;
                setQuizInfo(quizInfo);
                setAnswerList(JSON.parse(quizInfo.answer_data));
            }
        );
    }

    const getQuizResult = () => {
        context.post(
            'quiz/result',
            {
                quiz: quizId,
                member: rootStore.getProfile?.id
            },
            response => {
                const resultInfo = response.info;
                console.log("resultInfo", resultInfo)
                setQuizResultInfo(resultInfo);
                setUserAnswerList(JSON.parse(resultInfo.answer_data));
                console.log("userAnser", JSON.parse(resultInfo.answer_data))
                setExtraScore(resultInfo.extra_score);
                setExtraScoreType(resultInfo.extra_score > 0 ? 1 : resultInfo.extra_score < 0 ? -1 : 0);
            }
        );
    }

    const handleNext = () => {
        if (currentIndex < userAnswerList.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const changeShowCommentary = () => {
        setShowCommentary(!showCommentary)
        setShowFeedback(false)
    }

    const changeShowFeedback = () => {
        setShowFeedback(!showFeedback)
        setShowCommentary(false)
    }

    const backQuizLobby = () => {
        history.goBack();
    }

    // 가장 높은 progress 값을 찾기 (answerList가 정의되어 있을 때)
    const high_progress = answerList.length > 0 && answerList[currentIndex]?.progress ? Math.max(...Object.values(answerList[currentIndex].progress)) : 0;

    return (
        <div style={{ width: '100%', height: 'calc(100vh - 90px)' }}>
            <div className="main-content quiz-result-content" style={{ width: '100%', height: '100%', padding: '18px 5%', borderRadius: 12, background: '#fff', display: 'flex', flexDirection: 'column' }}>
                <ReactNotifications />
                <img src='/assets/image/icon_back_gray_circle.png' style={{ position: 'fixed', top: '10px', zIndex: '9999', cursor: 'pointer' }} onClick={() => backQuizLobby()} />
                <div className='d-flex flex-row align-items-center quiz-header' style={{ position: 'relative', justifyContent: 'space-between' }}>
                    <div className='left'>
                        <div className='profile'>      <img
                            className='profile'
                            src={profile !== "" ? context.loadImage(profile) : "/assets/image/chewing_plus_logo.png"}
                        /></div>
                        <div className='name'>{name}</div>
                        <div className={`status ${quizInfo?.participates === 1 ? 'blue' : 'red'}`}>
                            {quizInfo?.participates === 1 ? '합격' : '불합격'}
                        </div>
                        <div className={`time ${quizInfo?.participates === 1 ? 'blue' : 'red'}`}>
                            {getMinuteFromSecond(quizResultInfo?.time)}
                        </div>
                    </div>
                    {
                        extraScoreType != 0 &&
                        <div className={'extra-score-wrap ' + (extraScoreType == 1 ? 'additional' : 'deducted')} style={{ marginLeft: 10 }}>
                            <div>{extraScoreType == 1 ? '가점' : '감점'}</div>
                            <input type='number' value={extraScore} disabled style={{ fontSize: 16, fontWeight: 700, color: extraScoreType == 1 ? '#0F84F4' : '#EE4C69' }} />
                        </div>
                    }
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <div style={{ position: 'relative' }}>
                            <button className='btn-feedback student d-center' onClick={changeShowCommentary}>
                                <div className='f-s-16 m-l-10'>{'해설 보기'}</div>
                                <img
                                    src={showCommentary ? "/assets/image/icon_arrow_up_white.png" : "/assets/image/icon_arrow_down_white.png"}
                                    alt="" style={{ width: 15, height: 10, marginLeft: 16 }} />
                            </button>
                            {
                                showCommentary &&
                                <div className='feedback-area student'>
                                    <textarea
                                        className="feedback-input"
                                        placeholder={''}
                                        rows={7}
                                        value={answerList[currentIndex].commentary}
                                        disabled
                                        style={{ resize: 'none' }} />
                                </div>
                            }
                        </div>
                        <div style={{ position: 'relative' }}>
                            <button className='btn-feedback student d-center' onClick={changeShowFeedback}>
                                <div className='f-s-16 m-l-10'>{'피드백 확인'}</div>
                                <img
                                    src={showFeedback ? "/assets/image/icon_arrow_up_white.png" : "/assets/image/icon_arrow_down_white.png"}
                                    alt="" style={{ width: 15, height: 10, marginLeft: 16 }} />
                            </button>
                            {
                                showFeedback &&
                                <div className='feedback-area student'>
                                    <textarea
                                        className="feedback-input"
                                        placeholder={''}
                                        rows={7}
                                        value={quizResultInfo == null ? '' : quizResultInfo.feedback}
                                        disabled
                                        style={{ resize: 'none' }} />
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div style={{ width: '100%', height: '100%', border: '1px solid #DFE1E8', borderRadius: '20px', padding: '20px', marginTop: '10px' }}>
                    <div className='d-flex flex-row flex-1' style={{ overflow: 'hidden', height: '100%' }}>
                        <div className='d-flex flex-column col-md-8'>

                            {
                                answerList.length > 0 && answerList.length > 0 && userAnswerList.length > 0 &&

                                <div className='d-flex flex-column' style={{ width: '100%', height: '100%' }}>
                                    <div className='quiz-top'>
                                        <div className='quiz-title'>{quizInfo?.title}</div>
                                    </div>

                                    <div className='d-flex' style={{ position: 'relative' }}>
                                    <div className="quiz-question-box" >
                                                <div className='number'>
                                                    {currentIndex + 1}.
                                                    <img src={'/assets/image/icon_answer_' + (answerList[currentIndex]?.answer == userAnswerList[currentIndex]?.answer ? 'correct' : 'wrong') + '.png'} style={{ position: 'absolute', top: '50%', left: '30%', transform: 'translate(-50%, -50%)' }} />
                                                </div>
                                                <div className='question'>
                                                    {answerList[currentIndex]?.title}
                                                </div>
                                            </div>


                                    </div>

                                    <div className={`quiz-answer-box ${answerList[currentIndex]?.type === 0 ? 'subjective' : answerList[currentIndex]?.type === 2 ? 'ox-quiz-box' : ''}`}>
                                        {(answerList[currentIndex]?.type === 1 || answerList[currentIndex]?.type === 2) && answerList.length > 0 && answerList[currentIndex] && Object.keys(answerList[currentIndex]?.question).map(key => (
                                            <div key={key} className='quiz-answer'>
                                                <button
                                                    style={{
                                                        width: '30px',
                                                        height: '30px',
                                                        borderRadius: '50%',
                                                        border: answerList[currentIndex]?.answer === key ? '2px solid black' : '1px solid grey',
                                                        background: answerList[currentIndex]?.answer === key ? 'black' : 'white',
                                                        color: answerList[currentIndex]?.answer === key ? 'white' : 'black'
                                                    }}
                                                    disabled={true}
                                                >
                                                    {key}
                                                </button>
                                                {answerList[currentIndex]?.type === 1 ? <span>{answerList[currentIndex]?.question[key]}</span> : null}
                                                {answerList[currentIndex]?.progress && (
                                                    <div className={`quiz-progress-box ${answerList[currentIndex]?.progress[key] === high_progress ? 'high' : ''} ${answerList[currentIndex]?.progress[key] === 0 ? 'empty' : ''}`}>
                                                        {answerList[currentIndex]?.progress[key] !== undefined ? `${Math.round(answerList[currentIndex]?.progress[key])}%` : null}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                        {answerList[currentIndex]?.type === 0 && <div className='quiz-input'>{answerList[currentIndex]?.answer}</div>}
                                    </div>
                                    <div className={'quiz-btn-box'}>
                                        <button className='left-btn' style={{ marginBottom: 'none' }} onClick={handlePrev} disabled={currentIndex === 0}>
                                            <img src={currentIndex === 0 ? '/assets/image/icon_arrow_left_white.png' : '/assets/image/icon_arrow_left_white.png'} alt='' />
                                        </button>
                                        <div className='flex-box'>
                                            <div className='quiz-progress' style={{ position: 'unset', border: '1px solid #222222', padding: '5px 30px', width: '100px', margin: '0 auto' }}>{currentIndex + 1} / {answerList.length}</div>
                                            <div className='flex-content'>
                                                {typeof high_progress === 'number' && isFinite(high_progress) && high_progress >= 0 && (
                                                    <div className='quiz-time'>{high_progress}%</div>
                                                )}

                                                <div className='quiz-time'>{getMinuteFromSecond(userAnswerList[currentIndex]?.time)}</div>
                                            </div>
                                        </div>

                                        <button className='right-btn' onClick={handleNext} disabled={currentIndex === answerList.length - 1}>
                                            <img src={currentIndex === 0 ? '/assets/image/icon_arrow_right_white1.png' : '/assets/image/icon_arrow_right_white1.png'} alt="" />
                                        </button>
                                    </div>
                                </div>

                            }
                        </div>
                        <div className='d-flex flex-column col-md-4'>
                            <div className='d-flex flex-column' style={{ width: '100%', height: '100%' }}>
                                <div style={{ marginBottom: 10, color: '#222', fontSize: 15, fontWeight: 700, letterSpacing: -0.3 }}>
                                    {'OMR 답안지'}
                                </div>

                                <div className='d-flex flex-column' style={{ flexWrap: 'wrap', height: '95%' }} >
                                    <div className="omr-content">
                                        <div className='omr-data'>
                                            <div className='omr-header'>
                                                <div className='omr-no'>{'문제'}</div>
                                                <div style={{ width: 1, height: '100%', background: '#DFE1E8' }} />
                                                <div className='omr-answer'>
                                                    <div>1</div>
                                                    <div>2</div>
                                                    <div>3</div>
                                                    <div>4</div>
                                                    <div>5</div>
                                                </div>
                                            </div>
                                            <div className='omr-list'>
                                                {
                                                    userAnswerList.map((answer, index) => {
                                                        return <div key={index} className='d-flex flex-row align-items-center' style={{ height: 40 }}>
                                                            <div className='d-flex' style={{ width: 50, height: '100%', position: 'relative' }}>
                                                                <div style={{ margin: 'auto' }}>{(index + 1) + '번'}</div>
                                                                <img src={'/assets/image/icon_answer_' + (answerList[index]?.answer == answer?.answer ? 'correct' : 'wrong') + '.png'} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
                                                            </div>
                                                            <div style={{ width: 1, height: '100%', background: '#DFE1E8' }} />
                                                            {
                                                                answer.type === 1 ? (
                                                                    <div className='omr-answer'>
                                                                        <div className={'check-item' + (answer.answer === '1' ? ' selected' : '')} />
                                                                        <div className={'check-item' + (answer.answer === '2' ? ' selected' : '')} />
                                                                        <div className={'check-item' + (answer.answer === '3' ? ' selected' : '')} />
                                                                        <div className={'check-item' + (answer.answer === '4' ? ' selected' : '')} />
                                                                        <div className={'check-item' + (answer.answer === '5' ? ' selected' : '')} />
                                                                    </div>
                                                                ) : answer.type === 0 ? (
                                                                    <div className='omr-answer'>

                                                                        <div className='answer-text'>
                                                                            <PerfectScrollbar style={{ padding: '7px 9px', display: 'flex', whiteSpace: 'nowrap' }}>
                                                                                {answer?.answer}
                                                                                {
                                                                                    answerList[index]?.answer != answer?.answer &&
                                                                                    <div className='correct-answer'>{answerList[index]?.answer}</div>
                                                                                }
                                                                            </PerfectScrollbar>
                                                                        </div>

                                                                    </div>
                                                                ) : (
                                                                    <div className='omr-answer' style={{ justifyContent: 'space-around', textAlign: 'center' }}>
                                                                        <div className={'check-item' + (answer.answer === 'O' ? ' selected' : '')} >O</div>
                                                                        <div className={'check-item' + (answer.answer === 'X' ? ' selected' : '')} >X</div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );

}

export default withRouter(QuizStudent);
