import React, { useEffect } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { Redirect, Switch } from 'react-router';
import { inject, observer } from "mobx-react";

import strings from "../../lang/strings";
import Menu, { Extra } from '../../config/page_route.jsx';


const Content = ({ history, rootStore, bgType }) => {

  const routes = [...Extra, ...Menu];

  const setTitle = path => {
    let pageTitle = strings.admin;

    const route = routes.find(it => it.path == path || it.children?.some(c => c.path == path));
    if (route) {
      if (route.path == path) {
        pageTitle = route.title;
      } else {
        pageTitle = route.children?.find(it => it.path == path)?.title;
      }
    }

    document.title = pageTitle;
  }

  const getRoute = (route, index) =>
    <Route
      key={index}
      path={route.path}
      exact={route.exact}
      component={
        !route.private || rootStore.isSignedIn ? route.component : () => <Redirect to='/login'/>
      }
    />;

  useEffect(() => {
    history.listen(() => {
      setTitle(history.location.pathname);
    });

    setTitle(history.location.pathname);
  });

  return (
    <div className={`content ${
      (() => {
        switch (bgType) {
          case 0:
            return 'black-bg';
          case 1:
            return '';
          default:
            return 'white-bg';
        }
      })()
    }`}>
      <Switch>
        {routes.map((route, index) => {
          if (route.hasOwnProperty('children')) {
            return route.children.map((subRoute, subIndex) =>
              getRoute(subRoute, subIndex));
          } else {
            return getRoute(route, index);
          }
        })}
        <Route component={() => <Redirect to='/class'/>}/>
      </Switch>
    </div>
  )
}

export default withRouter(inject('rootStore')(observer(Content)));
