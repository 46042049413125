import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../../../design/colors";
import fonts from "../../../design/typography";
import IconX from "../../icons/x";
import { Page_settings } from "../../../config/page_settings";
interface TermsContentProps {
    toggle: () => void;
}

const TermsContent = ({ toggle }: TermsContentProps) => {
    const context: any = useContext(Page_settings);
    const [terms, setTerms] = useState<React.ReactNode>(null);
    useEffect(() => {
        const fetchTerms = async () => {
            context.get('setting/terms/service', {}, (response: string) => {
                const terms = response.split("\n");
                setTerms(terms.map((term: string) => {
                    return <p style={{...fonts.label5Medium, color: colors.gray900}}>{term}</p>
                }));
            });
        };

        fetchTerms();
    }, []);
    
    
    
    return (
        <ContentWrapper>
            <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: 20, width: "100%"}}>
                <span style={{...fonts.label2SemiBold, color: colors.gray900}}>서비스 이용약관</span>
                <div style={{cursor: "pointer"}} onClick={toggle}>
                    <IconX width="32px" height="32px" color={colors.gray900} background={colors.gray900}/>
                </div>
            </div>
            <TermsWrapper>
                {terms}
            </TermsWrapper>
        </ContentWrapper>
    )
};

export default TermsContent;

const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 28px 32px 0 32px;
    position: relative;
`;

const TermsWrapper = styled.div`
    width: 100%;
    height: calc(100% - 76px);
    overflow-y: auto;
    padding: 16px;
    background-color: ${colors.gray50};
    border-radius: 8px;

    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-thumb {
        background: ${colors.gray400};
        border-radius: 4px;
    }
`;