import { ko } from "date-fns/locale/ko";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";
import Button from "../../components/button";
import Checkbox from "../../components/checkbox";
import IconButton from "../../components/icon_button";
import IconCheck from "../../components/icons/check";
import { ChevronLeft, ChevronRight } from "../../components/icons/chevron";
import IconFeedback from "../../components/icons/feedback";
import IconMenu from "../../components/icons/menu";
import IconX from "../../components/icons/x";
import TextField from "../../components/text_field";
import { Page_settings } from "../../config/page_settings";
import colors from "../../design/colors";
import fonts from "../../design/typography";
import strings from "../../lang/strings";
import { useNavigate } from "react-router-dom"; 

interface IPlanInfo {
  id: number;
  member: number;
  memo: string;
  planDate: string;
}


interface IPlanFeedback {
  id: number;
  classroomTitle: string;
  member: string;
  content: string;
}

interface IPlannerTag {
  id: number;
  plan: number;
  title: string;
  color: string;
  orderNum: number;
  planDate: string;
  todoList: IPlannerToDo[];
}

interface IPlannerToDo {
  id?: number;
  tag?: number;
  title: string;
  createDate: string;
  status: "DONE" | "IN_PROGRESS";
}

const Planner = (props) => {
  const context: any = useContext(Page_settings);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [onCalendarMonth, setOnCalendarMonth] = useState(new Date());
  const [tagSearchStatus, setTagSearchStatus] = useState(false);
  const [tagsOfMonth, setTagsOfMonth] = useState<IPlannerTag[]>([]);
  const [planInfo, setPlanInfo] = useState<IPlanInfo | null>(null);
  const [feedbackList, setFeedbackList] = useState<IPlanFeedback[]>([]);

  const [tagList, setTagList] = useState([]); // 선택한 날의 태그 목록
  const [editTagIdx, setEditTagIdx] = useState<number>(-1);
  
  const [showAddTagModal, setShowAddTagModal] = useState(false);
  const [delTagId, setDelTagId] = useState(-1);
  const [delTodoId, setDelTodoId] = useState<number[]>([-1, -1]);

  const [dropDownTagIndex, setDropDownTagIndex] = useState(-1);
  const [dropDownTodoIndex, setDropDownTodoIndex] = useState<number[]>([-1, -1]);
  const [hoveredToDoIndex, setHoveredToDoIndex] = useState<number[]>([-1, -1]);
  const [editingToDoIndex, setEditingToDoIndex] = useState<number[]>([-1, -1]);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    context.get(
      "plan/tag/monthly",
      {
        yearMonth: moment(onCalendarMonth).format('YYYY-MM')
      },
      (response) => {
        setTagsOfMonth(response);
      }
    );
  }, [onCalendarMonth, tagSearchStatus]);

  useEffect(() => {
    context.get(
      "plan",
      {
        plannerDate: moment(selectedDate).format('YYYY-MM-DD')
      },
      (response) => {
        if (response) {
          setPlanInfo(response?.planInfo ?? null);
          setFeedbackList(response?.feedbacks ?? []);
        } else {
          setPlanInfo(null);
          setFeedbackList([]);
        }
      }
    );

    context.get(
      "plan/tag",
      {
        selectedDate: moment(selectedDate).format('YYYY-MM-DD')
      },
      (response) => {
        setTagList(response);
      }
    );
  }, [selectedDate, tagSearchStatus]);

  const onAddToDo = (index: number) => {
    const targetTag = tagList[index];
    const newTag = {
      ...targetTag,
      todoList: [
        ...targetTag.todoList,
        {
          tag: targetTag.id,
          title: "",
          createDate: moment().format('YYYY-MM-DD HH:mm:ss'),
          status: 0,
        }
      ]
    }
    setTagList([...tagList.slice(0, index), newTag, ...tagList.slice(index + 1)]);
    setEditingToDoIndex([index, newTag.todoList.length - 1]);
  }

  const onChangeDoneToDo = (index: number, todoIndex: number) => {
    const targetTag = tagList[index];

    context.put(
      `plan/tag/${targetTag.id}/todo/${targetTag.todoList[todoIndex].id}?status=${targetTag.todoList[todoIndex].status === "DONE" ? 0 : 1}`,
      {},
      (response) => {
      }
    )

    const newTag = {
      ...targetTag,
      todoList: [
        ...targetTag.todoList.slice(0, todoIndex),
        {
          ...targetTag.todoList[todoIndex],
          status: targetTag.todoList[todoIndex].status === "DONE" ? "IN_PROGRESS" : "DONE",
        },
        ...targetTag.todoList.slice(todoIndex + 1)
      ]
    }
    setTagList([...tagList.slice(0, index), newTag, ...tagList.slice(index + 1)]);
  }

  const onEditToDo = (index: number, todoIndex: number, title: string) => {
    const targetTag = tagList[index];
    const newTag = {
      ...targetTag,
      todoList: [
        ...targetTag.todoList.slice(0, todoIndex),
        {
          ...targetTag.todoList[todoIndex],
          title: title,
        },
        ...targetTag.todoList.slice(todoIndex + 1)
      ]
    }
    setTagList([...tagList.slice(0, index), newTag, ...tagList.slice(index + 1)]);
  }

  const patchTodo = (tagIdx: number, todoIdx: number, title: string) => {
    if (title.length > 0) {
      context.put(
        `plan/tag/${tagList[tagIdx].id}/todo`,
        {
          ...(tagList[tagIdx].todoList[todoIdx].id ? {id: tagList[tagIdx].todoList[todoIdx].id} : {}),
        title: title
      },
      (response) => {
          setTagSearchStatus(prev => !prev);
        }
      )
    }
  }

  const onDeleteTag = (tagId: number) => {
    context.delete(
      `plan/tag/${tagId}`,
      {},
      (response) => {
        setDelTagId(-1);
        setTagSearchStatus(prev => !prev);
      }
    );
  }

  const onDeleteTodo = (tagId: number, todoId: number) => {
    context.delete(
      `plan/tag/${tagId}/todo/${todoId}`,
      {},
      (response) => {
        setDelTodoId([-1, -1]);
        setTagSearchStatus(prev => !prev);
      }
    );
  }
  return (
    <Container>
      <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", height: "100%"}}>
        <div>
          <StyledDatePickerWrapper>
            <DatePicker
                      inline
                      selected={selectedDate}
                      locale={ko}
                      onChange={(date: Date) => {
                          setSelectedDate(date);
                      }}
                      renderDayContents={(dayOfMonth, date) => {
                      let event = tagsOfMonth.filter((item) => item.planDate == moment(date).format('YYYY-MM-DD'));
                      return (
                          <>
                              <div style={{position: 'relative'}}>
                                  {dayOfMonth}
                                {(event.length > 0  && moment(selectedDate).format('YYYY-MM-DD') !== moment(date).format('YYYY-MM-DD'))&& 
                                  <div style={{position: 'absolute', top: 15, left: 0, width: '100%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                                    {event.length < 3 ? (
                                      event.map((item) => {
                                        return <div style={{height: 5, width: 5, backgroundColor: item.color, borderRadius: "50%", marginLeft: 1, marginRight: 1, marginBottom: 6}}></div>
                                      })
                                    ) : (
                                      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start'}}>
                                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
                                          <div style={{height: 5, width: 5, backgroundColor: event[0].color, borderRadius: "50%"}} />
                                          <div style={{height: 5, width: 5, backgroundColor: event[1].color, borderRadius: "50%", marginLeft: 2}} />
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
                                          <div style={{height: 5, width: 5, backgroundColor: event[2].color, borderRadius: "50%"}} />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                }
                              </div>
                          </>
                      )
                  }}
                  renderCustomHeader={(params => {
                      return (
                          <CalendarHeader>
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', padding: "0 8px"}}>
                                  <span style={{ marginLeft: 4, color: colors.gray900, ...fonts.label3SemiBold }}>
                                      {(params.monthDate.getFullYear()).toString() + '년 '}
                                  </span>
                                  <span style={{ color: colors.gray900, ...fonts.label3SemiBold, marginLeft: 4 }}>
                                      {(params.monthDate.getMonth() + 1).toString() + '월'}
                                  </span>
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', padding: "0 8px"}}>
                                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}} onClick={() => {
                                      setOnCalendarMonth(new Date(moment(onCalendarMonth).subtract(1, 'months').format('YYYY-MM-DD')));
                                      params.decreaseMonth();
                                  }}>
                                      <ChevronLeft color={colors.gray500} width="20px" height="20px" />
                                  </div>
                                  
                                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}} onClick={() => {
                                      setOnCalendarMonth(new Date(moment(onCalendarMonth).add(1, 'months').format('YYYY-MM-DD')));
                                      params.increaseMonth();
                                  }}>
                                      <ChevronRight color={colors.gray500} width="20px" height="20px" />
                                  </div>
                              </div>
                          </CalendarHeader>
                      )
                  })}/>
          </StyledDatePickerWrapper>
          <MemoArea>
            <span style={{...fonts.label4SemiBold, color: colors.gray600}}>{strings.memo}</span>
            <textarea
              className="comment-input"
              rows={7}
              value={planInfo?.memo ?? ""}
              onBlur={() => {
                context.put(
                  "plan/memo",
                  {
                    plannerDate: moment(selectedDate).format('YYYY-MM-DD'),
                    memo: planInfo?.memo ?? "",
                  },
                  (response) => {}
                )
              }}
              onChange={(e) => setPlanInfo({...planInfo, memo: e.target.value})}
            />
          </MemoArea>
        </div>
      </div>
      <div style={{width: "1px", height: "640px", backgroundColor: colors.gray100, margin: "0 40px"}} />
      <div style={{width: "calc(100% - 386px)", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start"}}>
        <RightWrapper>
          <RightWrapperHeader>
            <span style={{...fonts.title2SemiBold, color: colors.gray900}}>{moment(selectedDate).format('YYYY MM월 DD일 dddd')}</span>
            <IconButton size="medium" text="태그 추가" onClick={() => {
              setShowAddTagModal(true);
            }} width="93px" height="36px"/>
          </RightWrapperHeader>
          <RightWrapperContent>
            {tagList.map((item, index) => {
              return (
                <TagItem key={index}>
                  <div style={{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", cursor: "pointer"}} onClick={() => onAddToDo(index)}>
                      <span style={{color: colors.white, ...fonts.label4SemiBold, backgroundColor: item.color, padding: "8px 10px", borderRadius: "4px"}}>{item.title}</span>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", cursor: "pointer"}} onClick={() => {index == dropDownTagIndex ? setDropDownTagIndex(-1) : setDropDownTagIndex(index)}}>
                      <IconMenu color={colors.gray500} width="24px" height="24px" />
                    </div>
                  </div>
                  <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", marginTop: item.todoList.length > 0 ? "16px" : "0", gap: 20}}>
                      {item.todoList.map((todo, todoIndex) => {
                        return (
                          <div style={{width: "100%", height: "24px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", position: "relative"}} >
                            <Checkbox size="20px" checked={todo.status === "DONE"} setChecked={() => {onChangeDoneToDo(index, todoIndex)}} />
                            {editingToDoIndex[0] == index && editingToDoIndex[1] == todoIndex ? (
                              <ToDoItem type="text" value={todo.title} onChange={(e) => onEditToDo(index, todoIndex, e.target.value)} onBlur={() => {setEditingToDoIndex([-1, -1]); patchTodo(index, todoIndex, todo.title)}} color={item.color} onKeyDown={(e) => {
                                if (e.key == "Enter") {
                                  setEditingToDoIndex([-1, -1]);
                                  patchTodo(index, todoIndex, todo.title);
                                }
                              }}/>
                            ) : (
                              <div style={{width: "calc(100% - 32px)", height: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start"}}
                                onMouseEnter={() => setHoveredToDoIndex([index, todoIndex])} onMouseLeave={() => setHoveredToDoIndex([-1, -1])}>
                                <div style={{...fonts.label3Medium, color: colors.gray800, width: hoveredToDoIndex[0] == index && hoveredToDoIndex[1] == todoIndex ? "calc(100% - 32px)" : "calc(100% - 8px)", height: "100%", cursor: hoveredToDoIndex[0] == index && hoveredToDoIndex[1] == todoIndex ? "pointer" : "default"}}
                                  onClick={() => {
                                    setEditingToDoIndex([index, todoIndex]);
                                    setHoveredToDoIndex([-1, -1]);
                                  }}>{todo.title}</div>
                                  {hoveredToDoIndex[0] == index && hoveredToDoIndex[1] == todoIndex && <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginRight: "8px", cursor: "pointer"}} onClick={() => {{
                                    if (dropDownTodoIndex[0] === index && dropDownTodoIndex[1] === todoIndex) {
                                      setDropDownTodoIndex([-1, -1]);
                                    } else {
                                      setDropDownTodoIndex([index, todoIndex]);
                                    }
                                  }}}>
                                  <IconMenu color={colors.gray500} width="24px" height="24px" />
                                </div>}
                              </div>
                            )}
                            {dropDownTodoIndex[0] == index && dropDownTodoIndex[1] == todoIndex && (
                              <DropDownBox style={{top: "24px", right: "6px"}}>
                                <DropDownItem onClick={() => {setDropDownTodoIndex([-1, -1]); setDelTodoId([item.id, todo.id])}}>
                                  <span>할 일 삭제</span>
                                </DropDownItem>
                                <DropDownItem onClick={() => {setDropDownTodoIndex([-1, -1]); setEditingToDoIndex([index, todoIndex])}}>
                                  <span>할 일 수정</span>
                                </DropDownItem>
                              </DropDownBox>
                            )}
                          </div>
                        )
                        })}
                    </div>
                    {dropDownTagIndex == index && (
                      <DropDownBox>
                        <DropDownItem onClick={() => {setDropDownTagIndex(-1); setDelTagId(item.id)}}>
                          <span>태그 삭제</span>
                        </DropDownItem>
                        <DropDownItem onClick={() => {setDropDownTagIndex(-1); setEditTagIdx(index); setShowAddTagModal(true)}}>
                          <span>태그 수정</span>
                        </DropDownItem>
                      </DropDownBox>
                    )}
                </TagItem>
              )
            })}
          </RightWrapperContent>
          <FeedbackButton onClick={() => setShowFeedbackModal(true)}>
            <IconFeedback color={colors.white} width="32px" height="32px" />
          </FeedbackButton>
          {showFeedbackModal && (
            <FeedbackModal feedbackList={feedbackList} toggle={() => setShowFeedbackModal(false)} />
          )}
        </RightWrapper>
      </div>

      {showAddTagModal && <TagModal
        tag={editTagIdx > -1 ? tagList[editTagIdx] : null}
        toggle={() => {
          setShowAddTagModal(!showAddTagModal);
          setTagSearchStatus(prev => !prev);
        }}
        planDate={moment(selectedDate).format("YYYY-MM-DD")}
      />}
      {delTagId > -1 && (
        <CheckModal
          message={"해당 태그를 삭제하겠습니까?"}
          onConfirm={() => onDeleteTag(delTagId)}
          onCancel={() => setDelTagId(-1)}
          confirmText={strings.delete}
          cancelText={strings.cancel}
        />
      )}
      {delTodoId[0] > -1 && delTodoId[1] > -1 && (
        <CheckModal
          message={"해당 할 일을 삭제하겠습니까?"}
          onConfirm={() => onDeleteTodo(delTodoId[0], delTodoId[1])}
          onCancel={() => setDelTodoId([-1, -1])}
          confirmText={strings.delete}
          cancelText={strings.cancel}
        />
      )}
    </Container>
  );
};

export default Planner;

const CALENDAR_FONT_STYLES = {
  fontSize: fonts.label4Regular.fontSize,
  lineHeight: fonts.label4Regular.lineHeight,
  fontFamily: fonts.label4Regular.fontFamily,
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 40px;
  height: 100%;
`;

const StyledDatePickerWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 305px;
  height: 310px;
  border-radius: 8px;
  background-color: ${colors.gray50};
  box-shadow: 0 1px 2px 0 rgb(0, 0, 0, 0.05);

  .react-datepicker {
    background-color: ${colors.white};
    border-radius: 8px;
    width: 100%;
    height: 100%;
    border: none;
    padding: 12px 16px;
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__month {
    margin: 8px 0 0 0 ;
  }

  .react-datepicker__week {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .react-datepicker__day-names {
    display: flex;
    justify-content: space-around;
    margin-top: 8px;
  }

  .react-datepicker__day-name {
    color: ${colors.gray500};
    ${CALENDAR_FONT_STYLES}
  }

  .react-datepicker__day {
    width: 32px;
    height: 32px;
    ${CALENDAR_FONT_STYLES}
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
    border-radius: 50%;
    position: relative;
  }

  .react-datepicker__day--selected {
    background-color: ${colors.blue500};
    color: ${colors.white};
  }

  .react-datepicker__day--today {
    font-weight: bold;
  }

  .react-datepicker__header {
    background-color: white;
    border-bottom: none;
    border-radius: 20px 20px 0 0;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: ${colors.white};
    color: ${colors.gray900};
  }

  .react-datepicker__day--outside-month {
    color: ${colors.gray400};
  }

  .react-datepicker__day:hover {
    background-color: ${colors.blue50};
    color: ${colors.blue600};
    border-radius: 50%;
  }
`;

const MemoArea = styled.div`
  margin-top: 16px;
  width: 100%;
  height: 280px;
  background-color: ${colors.white};
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 8px;

  textarea {
    width: 100%;
    height: 290px;
    padding: 8px;
    border: none;
    background-image: linear-gradient(transparent, transparent 1.4em, #e5e5e5 0px);
    background-size: 100% 1.5em;
    background-position: 0 6px;
    font-size: ${fonts.label4SemiBold.fontSize};
    line-height: ${fonts.label4SemiBold.lineHeight};
    font-family: ${fonts.label4SemiBold.fontFamily};
    color: ${colors.gray900};
    resize: none;
    
    &:focus {
      outline: none;
    }
  }
`;

const CalendarHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: ${colors.white};
`;


const RightWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  border-radius: 8px;
  height: 591px;
  position: relative;
`;

const RightWrapperHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 72px;
  padding: 0 24px 0 32px;
`;

const RightWrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 32px;
  height: calc(100% - 84px);
  width: 100%;
  margin-top: 12px;
  gap: 32px;
  overflow-y: auto;
`;

const TagItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  position: relative;
`;


const DropDownBox = styled.div`
  position: absolute;  
  width: 74px;
  background-color: white;
  border: 1px solid ${colors.gray100};
  border-radius: 8px;
  z-index: 3;
  top: 40px;
  right: 0px;
`;

const DropDownItem = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  color: ${colors.gray500};
  font-size: ${fonts.body3Regular.fontSize};
  line-height: ${fonts.body3Regular.lineHeight};
  font-family: ${fonts.body3Regular.fontFamily};
  &:hover {
    background-color: ${colors.blue50};
  }
`;


const ToDoItem = styled.input<{color: string}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 32px);
  border-bottom: 1px solid ${props => props.color};
  font-size: ${fonts.label3Medium.fontSize};
  line-height: ${fonts.label3Medium.lineHeight};
  font-family: ${fonts.label3Medium.fontFamily};
  height: 30px;
`;

const FeedbackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${colors.blue600};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  position: absolute;
  bottom: 8px;
  right: 8px;
`;

const TagModal = (props: {tag: IPlannerTag | null, toggle: () => void, planDate: string}) => {
  const context: any = useContext(Page_settings);
  const { tag, toggle, planDate } = props;

  interface ITagRequest {
    id?: number;
    color: string;
    title: string;
    planDate: string;
  }

  const [tagValue, setTagValue] = useState<ITagRequest>(
    tag ? {
      id: tag.id,
      color: tag.color,
      title: tag.title,
      planDate: tag.planDate,
    } : {
      color: "",
      title: "",
      planDate: planDate,
    }
  );

  const colorList = [
    "#FF6565", "#FFB253", "#FFD751", "#A1E05B", "#6EC3F2", "#A07CF5", "#EFA3C6", "#8DA4BE"
  ]

  const onConfirm = () => {
    context.put(
      "plan/tag",
      tagValue,
      (response) => {
        toggle();
      }
    )
  }


  return (
    <Modal size="md" isOpen={true} toggle={toggle} style={{borderRadius: "8px"}}>
      <ModalBody style={{padding: "20px 24px", borderRadius: "8px"}}>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: "32px"}}>
          <span style={{...fonts.body2SemiBold, color: colors.gray900}}>{tag ? "태그 수정" : "태그 추가"}</span>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}} onClick={toggle}>
            <IconX color={colors.gray900} width="24px" height="24px" />
          </div>
        </div>
        <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between", marginBottom: "32px"}}>
          <span style={{...fonts.label3SemiBold, color: colors.gray900, marginBottom: "16px"}}>이름</span>
          <TextField placeholder="태그 이름을 입력해주세요." value={tagValue.title} onChange={(e) => setTagValue({...tagValue, title: e.target.value})} />
        </div>
        <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between", marginBottom: "32px"}}>
          <span style={{...fonts.label3SemiBold, color: colors.gray900, marginBottom: "16px"}}>색상</span>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
            {colorList.map((color, index) => (
              <div style={{width: "28px", height: "28px", backgroundColor: color, borderRadius: "50%", marginRight: "8px", cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center"}} onClick={() => setTagValue({...tagValue, color: color})}>
                {tagValue.color == color && <IconCheck color={colors.white} width="20px" height="20px" />}
              </div>
            ))}
          </div>
        </div>
        <Button width="100%" height="43px" onClick={onConfirm} size="large" text={tag ? "수정하기" : "추가하기"} disabled={tagValue.title == "" || tagValue.color == ""}/>
      </ModalBody>
    </Modal>
  )
}

const CheckModal = (props: {
  onCancel: () => void,
  onConfirm: () => void,
  cancelText?: string,
  confirmText?: string,
  message: string
}) => {
  const { onCancel, onConfirm, cancelText, confirmText, message } = props;

  return (
    <Modal size="sm" isOpen={true} toggle={onCancel} style={{borderRadius: "8px"}}> 
      <ModalBody style={{padding: "20px 24px", borderRadius: "8px", marginTop: "36px"}}>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginBottom: "32px"}}>
          <span style={{...fonts.body2Medium, color: colors.gray900}}>{message}</span>
        </div>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", gap: "8px"}}>
          <Button width="calc(50% - 4px)" height="43px" onClick={onCancel} size="large" text={cancelText ?? "취소"} buttonColor={colors.gray100} textColor={colors.gray400}/>
          <Button width="calc(50% - 4px)" height="43px" onClick={onConfirm} size="large" text={confirmText ?? "확인"} buttonColor={colors.warning}/>
        </div>
      </ModalBody>
    </Modal>
  )
}

const FeedbackModal = (props: {feedbackList: IPlanFeedback[], toggle: () => void}) => {
  const { feedbackList, toggle } = props;

  console.log(feedbackList);
  
  return (
    <div style={{position: "absolute", bottom: 72, right: 0, width: 320, height: 192, backgroundColor: colors.white, zIndex: 5, borderRadius: "8px", boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.15)", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start", padding: "16px 20px"}}>
      <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
        <span style={{...fonts.body3SemiBold, color: colors.gray900}}>선생님 피드백</span>
        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", cursor: "pointer"}} onClick={toggle}>
          <IconX color={colors.gray900} width="24px" height="24px" />
        </div>
      </div>
      <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", width: "100%", marginTop: "16px", height: "112px"}}>
        <textarea style={{width: "100%", height: "100%", border: "none", backgroundColor: colors.gray50, borderRadius: "8px", padding: "12px 16px", fontSize: fonts.label3Medium.fontSize, lineHeight: fonts.label3Medium.lineHeight, fontFamily: fonts.label3Medium.fontFamily, color: colors.gray900}} 
          value={feedbackList.map(feedback => `${feedback.classroomTitle} - ${feedback.member} : ${feedback.content}`).join("\n")} 
          readOnly
        />
      </div>
    </div>
  )
}