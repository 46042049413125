import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { Page_settings } from '../../../config/page_settings';
import ViewModal from "../Modals/viewModal";
import VideoModal from "../Modals/viewModal";
import MissionInfoButton from '../../../components/control/button/mission_info_button';
import { rootStore } from '../../../mobx/store';
import NotificationDeleteModal from '../Modals/notificationDeleteModal';
import CloudContainer from '../../cabinet/cloud_container';
import NotificationEditModal from '../Modals/notificationEditModal';

const NotificationInfo = (props) => {

  const [notificationInfo, setNotificationInfo] = useState([]);
  const context = useContext(Page_settings);
  const [notification_id, setNotificationId] = useState(parseInt(props.match.params.notification_id));
  const [class_id, setClassId] = useState(parseInt(props.match.params.class_id));
  const [notificationFile, setNotificationFile] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [showFileItem, setShowFileItem] = useState(false);
  const [showVideoItem, setShowVideoItem] = useState(false);
  const [item, setItem] = useState("");
  const user_type = rootStore.getProfile?.user_type ?? ""
  const [openDeleteModal,setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [clickEvent,setClickEvent] = useState(false);
  const [previousSelectStatus,setPreviousSelectStatus] = useState();
  const [previousId,setPreviousId] = useState();
  const [nextSelectStatus,setNextSelectStatus] = useState();
  const [nextId,setNextId] = useState();
  const [toggleMenu, setToggleMenu] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);
  const imgType = ["bmp",
    "jpg",
    "jpeg",
    "png",
    "tiff"]

  useEffect(() => {
    getNotificationInfo();
    getNotificationInfoImg();
    getMissionArrowFunction();
  }, [clickEvent,updateStatus])

  const getNotificationInfo = () => {
    context.get(
      'class/getNotificationInfo',
      { notification_id: notification_id },
      response => {
        if(!response) {
          alert('찾을수 없습니다');
          props.history.replace(`/class/notification/${class_id}`)
        }
        setNotificationInfo(response)
      }
    );
  }

  const getNotificationInfoImg = () => {
    context.get(
      'class/getNotificationInfoFile',
      { notification_id: notification_id },
      response => {
        setNotificationFile(response)
      }
    );
  }

  const getMissionArrowFunction = () => {
    context.get(
      'class/getNotificationArrowFunction',
      { class_id,
        notification_id },
      response => {
        setPreviousSelectStatus(response.previous[0])
        setPreviousId(response.previous[1])
        setNextSelectStatus(response.next[0])
        setNextId(response.next[1])
      }
    );

  }

  const handleMouseEnter = (index) => {
    setIsHovered(index);
  };

  const handleMouseLeave = () => {
    setIsHovered(null);
  };

  const onOpenFile = (item) => {
    setItem(item);
    console.log("item",item)
    let ext = item.title.split(".")[item.title.split(".").length - 1];
    if (ext == 'mp4') {
      setShowVideoItem(true);
    } else {
      setShowFileItem(true);
    }
  };

  const onDownload = (url, name) => {
    const a = document.createElement("a");
    const STORAGE_BASE_URL = 'https://kr.object.ncloudstorage.com/chewing-storage';
    a.href = STORAGE_BASE_URL+"/"+url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  

  const nextClick = () => {
    props.history.replace(`/class/notification_info/${class_id}/${nextId}`)
    setNotificationId(nextId)
    setClickEvent(!clickEvent);
  }

  const previousClick = () => {
    props.history.replace(`/class/notification_info/${class_id}/${previousId}`)
    setNotificationId(previousId)
    setClickEvent(!clickEvent);
  }

  const fileResize = (size) => {

    const dataSize = size;
    let formattedData = '';
    
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  
    if (dataSize === 0) return '0 Byte';
    
    const k = parseInt(Math.floor(Math.log(dataSize) / Math.log(1024)));
  
    formattedData =  Math.round(100 * (dataSize / Math.pow(1024, k))) / 100 + ' ' + sizes[k];
    return formattedData;
  }


  return (
    <div class="mission-list-info-main-container">
      {/* <CloudContainer/> */}
      <div class="row">

        {/* 왼쪽 내용 */}
        
        <div className="col-md-8">
          <div class="mission-list-info-head">공지</div>
          <div className={`mission-list-info-time`}>{notificationInfo.formatted_date}</div>
          <div class="mission-list-info-title">{notificationInfo.title}</div>
          <div class="mission-list-info-content-box">
            <div class="mission-list-info-content">{notificationInfo.content?.split(`\n`).map(item => (<p>{item}</p>))}</div>

            {/* 파일 */}
            {notificationFile !== null ? (
              <>
                {notificationFile.map((item, idx) => {
                  return !imgType.includes(item.url.split('.').pop().toLowerCase()) ?
                    (
                      <div class="mission-list-info-file-box ">
                        <div className="">
                          <img class="grid-item file-img" src={item.type} alt='' />
                        </div>
                        <div className="grid-item file-title">
                          {item.title}
                        </div>
                        <div className="grid-item file-size">
                          {fileResize(item.size)}
                        </div>
                        <div className="grid-item img-box">
                          <img src='/assets/image/icon_mission_download.png' alt=''
                            onClick={()=> {
                              onDownload(item.url, item.title)
                            }}
                          />
                          <img src='/assets/image/icon_mission_cloud.png' alt=''/>
                        </div>
                      </div>
                    )
                    : (
                      null
                    );
                })}
              </>
            ) : null}

            {notificationFile !== null ? (
              <div class="mission-list-info-img-box">
                {notificationFile.map((item, idx) => {
                  return imgType.includes(item.url.split('.').pop().toLowerCase()) ?
                    (
                      <div class="mission-list-info-img" key={idx} onMouseEnter={() => handleMouseEnter(idx)}
                        onMouseLeave={handleMouseLeave} >
                        {isHovered === idx ?
                          <div class="on-hover" onClick={() => onOpenFile(item)}>
                            <div class="top">{item.title}</div>
                            <div class="bottom">
                              <div class="img-container right-margin" onClick={(event) => {
                                onDownload(item.url, item.title)
                              }}>
                                <img class="download" src="/assets/image/icon_mission_download.png" alt='' />
                              </div>
                              {user_type === "STUDENT" ? 
                              <div class="img-container ">
                              <img class="cloud" src="/assets/image/icon_mission_cloud.png" alt='' onClick={(event)=> {
                                event.stopPropagation()
                              }}/>
                            </div>
                            : null}
                            </div>
                          </div>
                          : <img src={context.loadImage(item.url)} alt='' />}
                      </div>

                    )
                    : (
                      null
                    );
                })}
              </div>
            ) : null}



          </div>
        </div>
        <div className="col-md-1" />

        <ViewModal
          show={showFileItem}
          toggle={() => setShowFileItem(!showFileItem)}
          item={item}
          onDownload={(list) => onDownload(list)}
        />

        <VideoModal
          show={showVideoItem}
          toggle={() => setShowFileItem(!showFileItem)}
          item={item}
        />

        {/* 오른쪽 */}
        <div className="col-md-3">
          <div class={`mission-list-info-head-right notificationInfo ${user_type === "STUDENT" ? "student" : ""}`}>
          <MissionInfoButton
              more={previousSelectStatus != 1}
              direction={'left'}
              user_type={user_type}
              onClick={previousClick}
            />

            <MissionInfoButton
              more={nextSelectStatus != 1}
              direction={'right'}
              user_type={user_type}
              onClick={nextClick}
            />

            {/* {user_type !== "STUDENT" ? */}
              <div class="three-dots"
                onClick={(event) => {
                  event.stopPropagation();
                  setToggleMenu(!toggleMenu);
                }}
              >
                <img src="/assets/image/icon_3dots.png" alt="" />
                {toggleMenu && (
                  <div className="edit-del-box">
                    <button onClick={() => setOpenEditModal(true)}>수정하기</button>
                    <button onClick={() => setOpenDeleteModal(true)} className='del-btn'>삭제하기</button>
                  </div>
                )}
              </div>
              {/* : null} */}
          </div>
        </div>
      </div>
      {openDeleteModal && (<NotificationDeleteModal
            open={openDeleteModal}
            onClose={() => {
              setOpenDeleteModal(false);
            }}
            notification_id={notification_id}
            class_id={class_id}
          />)}

      {openEditModal && (<NotificationEditModal
        open={openEditModal}
        onClose={() => {
          setOpenEditModal(false);
        }}
        onUpdate={() => {
          setUpdateStatus(!updateStatus);
        }}
        notification_id={notification_id}
        class_id={notificationInfo.class_id}
      />)}

    
    </div>
    
                  
  );
}

const Button = styled.button`
  font-size: 14px;
  padding: 10px 20px;
  border: none;
  background-color: #0F84F4;
  border-radius: 10px;
  color: white;
  font-weight: 500;
  cursor: pointer;
  // &:hover {
  //   background-color: #fac2be;
  // }
`;

const AppWrap = styled.div`
  margin: 10px auto;
  position: relative;
  width: 1440px;
`;

export default withRouter(NotificationInfo);
