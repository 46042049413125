import React, { useContext, useEffect, useState, useRef, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { IClassList } from '../../../type/classroom';
import ImageCircle from '../../image_circle';
import { getFilePath } from '../../../utils/image';
import fonts from '../../../design/typography';
import colors from '../../../design/colors';
import { ChevronUp, ChevronDown } from '../../icons/chevron';
import IconFilter from '../../icons/filter';
import IconX from '../../icons/x';
import { Page_settings } from '../../../config/page_settings';
import IconPin from '../../icons/pin';
import SearchBar from '../../search_bar';
import useDebounce from '../../../hooks/useDebounce';
import ChatRoomSection, { ChatRoomRef } from './chewingtalk_chatroom';
import { Client } from '@stomp/stompjs';
import { rootStore } from '../../../mobx/store';
import moment from 'moment';

interface INoticeModalProps {
  classList: IClassList[];
  toggle: () => void;
  socketClient: Client | null;
}

interface IRoomList {
  user: {
    id: number;
    name: string;
    profile: string;
    userType: string;
    schoolOrClassroom: string;
  };
  id: number;
  message: string;
  messageDateTime: string;
  pinned: boolean;
  notification: boolean;
  unreadCount: number;
  classId: number;
}

interface IMessage {
  id: number;
  room: number;
  message: string;
  sender: {
      id: number;
      name: string;
      profile: string;
  };
  file:{
      id: number;
      name: string;
      size: number;
      type: string;
      url: string;
  }[];
  isRead: boolean;
  createDate: string;
}

function ChewingTalkModal({classList, toggle, socketClient}: INoticeModalProps) {
  const context: any = useContext(Page_settings);
  const userType = rootStore.getProfile?.userType ?? "STUDENT";
  const [selectedClass, setSelectedClass] = useState<IClassList | null>(classList.length > 0 && userType === "EXPERT" ? classList[0] : null);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [hoverdIndex, setHoverdIndex] = useState<number>(-1);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  
  const messageRoomsRef = useRef<IRoomList[]>([]);
  const emptyRoomsRef = useRef<IRoomList[]>([]);
  
  const [updateTrigger, setUpdateTrigger] = useState<number>(0);
  
  const name = useDebounce(searchKeyword, 500);
  const [selectedRoom, setSelectedRoom] = useState<IRoomList | null>(null);
  const [sortNumber, setSortNumber] = useState<number>(0);
  const [listSearchStatus, setListSearchStatus] = useState<boolean>(false);
  const [hoverdRoomIndex, setHoverdRoomIndex] = useState<number[]>([-1, -1]);
  const chatRoomRef = useRef<ChatRoomRef>(null);
  const subscriptionsRef = useRef<{[key: string]: any}>({});

  useEffect(() => {
    context.get(`/chewingtalk/room`, {
      sort: sortNumber
    }, (response) => {
      messageRoomsRef.current = response.messageRooms;
      emptyRoomsRef.current = response.emptyRooms;
      setUpdateTrigger(prev => prev + 1);
    });
  }, [sortNumber, listSearchStatus]);

  const [rightClickedIndex, setRightClickedIndex] = useState<number[]>([-1, -1]);

  useEffect(() => {
    const handleClick = () => {
      setRightClickedIndex([-1, -1]);
    };

    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  const setupSubscriptions = useCallback(() => {
    if (!socketClient || !socketClient.connected) return;
    
    Object.values(subscriptionsRef.current).forEach(sub => {
      if (sub && typeof sub.id === 'string') {
        socketClient.unsubscribe(sub.id);
      }
    });
    subscriptionsRef.current = {};
    
    [...messageRoomsRef.current, ...emptyRoomsRef.current].forEach(item => {
      const subId = socketClient.subscribe(`/topic/chewingtalk/${item.id}/unread`, (message) => {
        try {
          const body = JSON.parse(message.body) as IMessage;
          const isCurrentRoom = selectedRoom && selectedRoom.id === item.id;  
          const messageRoomsIndex = messageRoomsRef.current.findIndex(room => room.id === item.id);
          const emptyRoomsIndex = emptyRoomsRef.current.findIndex(room => room.id === item.id);

          if (messageRoomsIndex !== -1) {
            const targetRoom = messageRoomsRef.current[messageRoomsIndex];
            const newRoom = {
              ...targetRoom,
              unreadCount: isCurrentRoom ? 0 : targetRoom.unreadCount + 1,
              message: body.message,
              messageDateTime: body.createDate
            }
            
            const fixedRoom = messageRoomsRef.current.filter(item => item.pinned && item.id !== targetRoom.id);
            const unFixedRoom = messageRoomsRef.current.filter(item => !item.pinned && item.id !== targetRoom.id);
            
            if (targetRoom.pinned) {
              messageRoomsRef.current = [newRoom, ...fixedRoom, ...unFixedRoom];
            } else {
              messageRoomsRef.current = [...fixedRoom, newRoom, ...unFixedRoom];
            }
          } else if (emptyRoomsIndex !== -1) {
            const targetRoom = emptyRoomsRef.current[emptyRoomsIndex];
            const newRoom = {
              ...targetRoom,
              unreadCount: isCurrentRoom ? 0 : targetRoom.unreadCount + 1,
              message: body.message,
              messageDateTime: body.createDate
            }
            
            emptyRoomsRef.current = emptyRoomsRef.current.filter((_, i) => i !== emptyRoomsIndex);
            
            const fixedRoom = messageRoomsRef.current.filter(item => item.pinned);
            const unFixedRoom = messageRoomsRef.current.filter(item => !item.pinned);
            
            if (targetRoom.pinned) {
              messageRoomsRef.current = [newRoom, ...fixedRoom, ...unFixedRoom];
            } else {
              messageRoomsRef.current = [...fixedRoom, newRoom, ...unFixedRoom];
            }
          }
          
          setUpdateTrigger(prev => prev + 1);
          
        } catch (error) {
          console.error("메시지 처리 중 오류 발생:", error);
        }
      });
      subscriptionsRef.current[`unread-${item.id}`] = subId;
    });
  }, [socketClient, updateTrigger, selectedRoom]);
  
  useEffect(() => {
    setupSubscriptions();
    return () => {
      if (socketClient && socketClient.connected) {
        Object.values(subscriptionsRef.current).forEach(sub => {
          if (sub && typeof sub === 'string') {
            socketClient.unsubscribe(sub);
          }
        });
      }
      subscriptionsRef.current = {};
    };
  }, [setupSubscriptions]);


  const onFix = (pinned: boolean, roomId: number) => {
    context.post(`/chewingtalk/room/pinned`, {
      room: roomId,
      pinned: pinned
    }, (response) => {
      console.log(response);
      setListSearchStatus(prev => !prev);
    });
  };

  const onSelectRoom = (room: IRoomList, index: number[]) => {
    setSelectedRoom(room);
    setRightClickedIndex([-1, -1]);

    if (socketClient && socketClient.connected) {
      socketClient.publish({
        destination: "/app/updateReadStatus",
        headers: {
          "x-access-token": rootStore.getMe?.token ?? ""
        },
        body: JSON.stringify({
          roomId: room.id,
        })
      });

      if (index[0] === 0) {
        const updatedRooms = [...messageRoomsRef.current];
        if (updatedRooms[index[1]]) {
          updatedRooms[index[1]] = {
            ...updatedRooms[index[1]],
            unreadCount: 0
          };
        }
        messageRoomsRef.current = updatedRooms;
      } else {
        const updatedRooms = [...emptyRoomsRef.current];
        if (updatedRooms[index[1]]) {
          updatedRooms[index[1]] = {
            ...updatedRooms[index[1]],
            unreadCount: 0
          };
        }
        emptyRoomsRef.current = updatedRooms;
      }
      
      setUpdateTrigger(prev => prev + 1);
    }
  }

  return (
      <TalkModalWrapper isRoomSelected={selectedRoom !== null}>
        <ListSection>
          <TalkModalHeader isChatRoomOpen={selectedRoom !== null}>
            { userType === "EXPERT" ?
              (<div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
                  <ImageCircle src={getFilePath(selectedClass?.thumbnail, "/assets/image/default_class.png")} size={44} />
                  <div style={{position: "relative"}}>
                      <span style={{ ...fonts.label5Medium, color: colors.gray600, marginLeft: 8 }}> {selectedClass?.corp ? selectedClass?.corp.length > 12 ? selectedClass?.corp.slice(0, 12) + "..." : selectedClass?.corp : "-"} </span>
                      <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", cursor: "pointer"}} onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                          <span style={{ ...fonts.body3SemiBold, color: colors.gray900, marginLeft: 8 }}> {selectedClass?.title} </span>
                          <div>
                              {isDropdownOpen ? <ChevronUp color={colors.gray900} width="24px" height="24px" style={{marginLeft: 8}}/> : <ChevronDown color={colors.gray900} width="24px" height="24px" style={{marginLeft: 8}}/>}
                          </div>
                      </div>
                      {
                      isDropdownOpen && <ClassDropdown>
                          {classList.map((item, index) => (
                              <ClassDropdownItem key={item.id} onMouseEnter={() => setHoverdIndex(index)} onMouseLeave={() => setHoverdIndex(-1)} isHoverd={hoverdIndex === index} onClick={() => {setSelectedClass(item); setIsDropdownOpen(false)}}>
                                  <ImageCircle src={getFilePath(item.thumbnail, "/assets/image/default_class.png")} size={40} />
                                  <div style={{marginLeft: 8}}>
                                      <div style={{ ...fonts.label5Medium, color: colors.gray600 }}>{item.corp ? item.corp.length > 12 ? item.corp.slice(0, 12) + "..." : item.corp : "-"}</div>
                                      <div style={{ ...fonts.label4Medium, color: colors.gray900, display: "flex", flexDirection: "row", alignItems: "center" }}>
                                          {item.title.length > 12 ? item.title.slice(0, 12) + "..." : item.title}
                                      </div>
                                  </div>
                              </ClassDropdownItem>
                          ))}
                      </ClassDropdown>
                      }
                  </div>
              </div>)
              :
              (<div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", ...fonts.body1SemiBold, color: colors.gray900}}>
                {"츄잉톡"}
              </div>)
            }
              <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", position: "relative"}}>
                  <div style={{cursor: "pointer"}} onClick={() => setIsFilterOpen(!isFilterOpen)}>
                      <IconFilter color={colors.gray900} width="24px" height="24px"/>
                  </div>
                  <div style={{cursor: "pointer", marginLeft: 12}} onClick={toggle}>
                      <IconX color={colors.gray900} width="24px" height="24px"/>
                  </div>
                  {isFilterOpen &&
                  <FilterWrapper >
                    <FilterItem index={0} onClick={() => {setSortNumber(0); setIsFilterOpen(false)}}>
                      <span style={{ ...fonts.label4Medium, color: colors.gray500 }}>최신 메세지 순</span>
                    </FilterItem>
                    <FilterItem index={1} onClick={() => {setSortNumber(1); setIsFilterOpen(false)}}>
                      <span style={{ ...fonts.label4Medium, color: colors.gray500 }}>읽지 않은 메세지 순</span>
                    </FilterItem>
                  </FilterWrapper>
                  }
              </div>
          </TalkModalHeader>
          <TalkModalContent>
              <SearchBar
                placeholder="이름을 검색해주세요"
                onChange={(e) => {
                  setSearchKeyword(e.target.value);
                }}
                value={searchKeyword}
                style={{marginBottom: 16}}
              />
              {
              <div style={{width: "100%"}}>
                {messageRoomsRef.current.filter((room) => {
                  if (selectedClass !== null && selectedClass.id !== room.classId) {
                    return false;
                  }

                  if(name == "") {
                    return true;
                  }
                  return room.user.name.includes(name);
                }).map((room, index) => (
                  <Rooms room={room} 
                    isRightClicked={rightClickedIndex[0] === 0 && rightClickedIndex[1] === index} 
                    onRightClick={() => setRightClickedIndex([0, index])} 
                    onClick={() => onSelectRoom(room, [0, index])}
                    onFix={(boolean) => onFix(boolean, room.id)}
                    setIsRightClicked={(value) => setRightClickedIndex([0, index])}
                    isHoverd={hoverdRoomIndex[0] === 0 && hoverdRoomIndex[1] === index}
                    onMouseEnter={() => setHoverdRoomIndex([0, index])}
                    onMouseLeave={() => setHoverdRoomIndex([-1, -1])}
                    isEmpty={false}
                  />
                ))}
              </div>
              }
              {
                messageRoomsRef.current.length > 0 && emptyRoomsRef.current.length > 0 &&
                <div style={{width: "100%", height: 1, marginBottom: 8, backgroundColor: colors.gray100}}/>
              }
              {
              <div style={{width: "100%"}}>
                {emptyRoomsRef.current.filter((room) => {
                  if (selectedClass !== null && selectedClass.id !== room.classId) {
                    return false;
                  }

                  if(name == "") {
                    return true;
                  }
                  return room.user.name.includes(name);
                }).map((room, index) => (
                  <Rooms room={room} 
                    isRightClicked={rightClickedIndex[0] === 1 && rightClickedIndex[1] === index} 
                    onRightClick={() => setRightClickedIndex([1, index])} 
                    onClick={() => onSelectRoom(room, [1, index])}
                    onFix={(boolean) => onFix(boolean, room.id)}
                    setIsRightClicked={(value) => setRightClickedIndex([1, index])}
                    isHoverd={hoverdRoomIndex[0] === 1 && hoverdRoomIndex[1] === index}
                    onMouseEnter={() => setHoverdRoomIndex([1, index])}
                    onMouseLeave={() => setHoverdRoomIndex([-1, -1])}
                    isEmpty={true}
                  />
                ))}
              </div>
              }
          </TalkModalContent>
        </ListSection>
        {selectedRoom &&
          <ChatRoomSection 
            ref={chatRoomRef}
            classId={selectedClass?.id}
            roomInfo={selectedRoom}
            socketClient={socketClient}
          />
        }
      </TalkModalWrapper>
  )
}

const TalkModalWrapper = styled.div<{isRoomSelected: boolean}>`
  width: ${props => props.isRoomSelected ? "1084px" : "384px"};
  height: 576px;
  top: 80px;
  right: 68px;
  border-radius: 16px;
  background-color: ${colors.white};
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const ListSection = styled.div`
  width: 384px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const TalkModalHeader = styled.div<{isChatRoomOpen: boolean}>`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.gray50};
  border-radius: ${props => props.isChatRoomOpen ? "0" : "16px 16px 0 0"};
  padding: 0 20px 0 24px;
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 20px;
  left: 0px;
  border-radius: 8px;
  border: 1px solid ${colors.gray100};
  width: 132px;
  z-index: 10;
`;

const FilterItem = styled.div<{index: number}>`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${props => props.index === 0 ? "8px 8px 0 0" : "0 0 8px 8px"};
  background-color: ${colors.white};
  cursor: pointer;
  z-index: 10;

  &:hover {
    background-color: ${colors.blue50};
  }
`;

const TalkModalContent = styled.div`
  width: 100%;
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 16px;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colors.gray100}; /* Color of the scrollbar thumb */
    border-radius: 4px; /* Rounded corners for the scrollbar thumb */
  }
`;

const ClassDropdown = styled.div`
  position: absolute;
  top: 60px;
  left: -50px;
  width: 222px;
  max-height: 220px;
  background-color: ${colors.white};
  border-radius: 8px;
  padding: 8px 10px 16px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  overflow-y: auto;
  z-index: 11;
`;

const ClassDropdownItem = styled.div<{isHoverd: boolean}>`
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  background-color: ${props => props.isHoverd ? colors.gray50 : colors.white};
  padding: 8px 10px;
  cursor: pointer;
  z-index: 11;
`;

const Rooms = (props: {room: IRoomList, isRightClicked: boolean, setIsRightClicked: (boolean) => void, onRightClick: () => void, onClick: () => void, onFix: (boolean) => void, isHoverd: boolean, onMouseEnter: () => void, onMouseLeave: () => void, isEmpty: boolean}) => {
  const {room, isRightClicked, setIsRightClicked, onRightClick, onClick, onFix, isHoverd, onMouseEnter, onMouseLeave, isEmpty} = props;
  const formatMessageDateTime = (messageDateTime: string) => {
    const date = new Date(messageDateTime);
    const now = new Date();
    
    if (moment(date).format("YYYY-MM-DD") === moment(now).format("YYYY-MM-DD")) {
      return moment(date).format("HH:mm");
    } else if (moment(date).format("YYYY") === moment(now).format("YYYY")) {
      const diffInDays = moment(now).diff(moment(date), "days");
      if (diffInDays === 1) {
        return "어제";
      } else {
        return moment(date).format("MM. DD");
      }
    } else {
      return moment(date).format("YYYY. MM. DD");
    }
  }

  const handleRightClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    onRightClick();
  }

  return (
    <div style={{position: "relative", width: "100%", height: "74px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: isHoverd ? colors.gray50 : colors.white, padding: "0 20px", marginBottom: 8, cursor: "pointer"}} 
      onContextMenu={handleRightClick}
      onClick={ onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div style={{display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "center"}}>
        <ImageCircle src={getFilePath( room.user.profile, "/assets/image/default_profile.png")} size={40} />
        <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", marginLeft: 8}}>
          <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
            <div style={{ ...fonts.label3SemiBold, color: colors.gray900 }}>{ room.user.name}</div>
            <div style={{width: 4, height: 4, backgroundColor: colors.gray200, borderRadius: "50%", margin: "0 4px"}}/>
            <div style={{ ...fonts.label4Medium, color: colors.gray500 }}>{ room.user.schoolOrClassroom ?? " - "}</div>
            { room.pinned && <IconPin color={colors.gray400} width="20px" height="20px" />}
          </div>
          <div style={{display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center"}}>          
              <div style={{ ...fonts.label4Regular, color: colors.gray500 }}>{ room.message ?? "새로운 채팅을 시작해보세요!"}</div>
          </div>
        </div>
      </div>
      <div style={{display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "center", ...fonts.label5Regular, color: colors.gray400}}>
        {room.messageDateTime && formatMessageDateTime(room.messageDateTime)}
        { room.unreadCount > 0 && 
          <div style={{ fontFamily: "Pretendard-Regular", fontSize: 12, fontWeight: 600, color: colors.white, width: 18, height: 18, backgroundColor: colors.blue600, borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center"}}>{room.unreadCount}</div>}
      </div>
      { isRightClicked &&
        <ContextMenuWrapper >
          {!isEmpty && <ContextMenuItem index={0} onClick={() => {onFix(! room.pinned); setIsRightClicked(false)}}>
            <span style={{ ...fonts.label4Medium, color: colors.gray500 }}>{ room.pinned ? "채팅방 고정 해제" : "채팅방 고정"}</span>
          </ContextMenuItem>}
          <ContextMenuItem index={1} onClick={() => {setIsRightClicked(false)}}>
            <span style={{ ...fonts.label4Medium, color: colors.gray500 }}>이름 변경</span>
          </ContextMenuItem>
        </ContextMenuWrapper>
      }
    </div>
  );
}

const ContextMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 30px;
  right: 50px;
  border-radius: 8px;
  border: 1px solid ${colors.gray100};
  width: 114px;
  z-index: 10;
`;

const ContextMenuItem = styled.div<{index: number}>`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${props => props.index === 0 ? "8px 8px 0 0" : "0 0 8px 8px"};
  background-color: ${colors.white};
  cursor: pointer;

  &:hover {
    background-color: ${colors.blue50};
  }
`;

export default React.memo(ChewingTalkModal)