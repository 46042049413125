import React from 'react';
import { IconType } from "../../type/icon_type";

const PlusIcon = ({ width, height, color }: IconType) => {
    return (
        <svg width={width || "24"} height={height || "24"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 12H18M12 6V18" stroke={color} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export default PlusIcon;