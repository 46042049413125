import React, { useContext, useEffect, useState } from 'react';
import useDebounce from '../../../hooks/useDebounce';
import { Modal, ModalBody } from 'reactstrap';
import { Page_settings } from '../../../config/page_settings';
import colors from '../../../design/colors';
import fonts from '../../../design/typography';
import IconX from '../../../components/icons/x';
import SearchBar from '../../../components/search_bar';
import { getFilePath } from '../../../utils/image';
import Checkbox from '../../../components/checkbox';
import Button from '../../../components/button';
import ImageCircle from '../../../components/image_circle';
interface IMemberList {
  id: number;
  name: string;
  profile: string;
  email: string;
}

function AddStudentModal({ onClose, classId, memberList }: { onClose: () => void, classId: number, memberList: number[] }) {
    const context: any = useContext(Page_settings);

    const [searchVal, setSearchVal] = useState<string>("");
    const debouncedSearchVal = useDebounce(searchVal, 500);
    const [searchResult, setSearchResult] = useState<IMemberList[]>([]);
    const [selectedMembers, setSelectedMembers] = useState<IMemberList[]>([]);

    useEffect(() => {
        context.get('/member/student', { email: debouncedSearchVal }, (res: IMemberList[]) => {
            setSearchResult(res);
        });
    }, [debouncedSearchVal]);

    return (
        <Modal isOpen={true} onClose={onClose} size="md">
            <ModalBody style={{padding: "20px 24px 24px 24px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", marginBottom: "38px"}}>
                    <span style={{...fonts.body2SemiBold, color: colors.gray900}}>학생 추가</span>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", cursor: "pointer"}} onClick={onClose}>
                        <IconX color={colors.gray900} width="24px" height="24px" />
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", marginBottom: "24px"}}>
                    <SearchBar
                        value={searchVal}
                        onChange={(e) => setSearchVal(e.target.value)}
                        placeholder="이메일을 검색해보세요"
                    />
                </div>
                {
                    selectedMembers.length > 0 && (
                        <div style={{display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start", width: "100%", marginBottom: "24px", height: "100%", gap: "8px", flexWrap: "wrap"}}>
                            {selectedMembers.map((member) => (
                                <div key={member.id} style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", width: "calc(25% - px)", height: "36px", padding: "0 8px", cursor: "pointer", backgroundColor: colors.gray50, borderRadius: "16px"}} onClick={() => {
                                    if (selectedMembers.map((m) => m.id).includes(member.id)) {
                                        setSelectedMembers(selectedMembers.filter((m) => m.id !== member.id));
                                    } else {
                                        setSelectedMembers([...selectedMembers, member]);
                                    }
                                }}>
                                    <img src={getFilePath(member.profile, "assets/image/default_profile.png")} alt="profile" style={{width: "20px", height: "20px", borderRadius: "10px", marginRight: "8px"}} />
                                    <span style={{...fonts.label4Medium, color: colors.gray900, width: "45px"}}>{member.name}</span>
                                    <Checkbox size="16px" checked={selectedMembers.map((m) => m.id).includes(member.id)} setChecked={() => {}} />
                                </div>
                            ))}
                        </div>
                    )
                }
                <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", width: "100%", marginBottom: "24px", height: "120px", border: `1px solid ${colors.gray100}`, borderRadius: "8px"}}>
                    {searchResult.length > 0 && (
                        searchResult.map((result) => (
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", width: "100%", height: "60px", padding: "0 20px 0 16px"}}>
                            <ImageCircle src={getFilePath(result.profile, "assets/image/default_profile.png")} size={40} style={{marginRight: "8px"}} />
                            <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", width: "calc(100% - 60px)", height: "100%"}}>
                                <span style={{...fonts.label4SemiBold, color: colors.gray900}}>{result.name}</span>
                                <span style={{...fonts.label5Regular, color: colors.gray500}}>{result.email}</span>
                            </div>
                            <Checkbox checked={memberList.includes(result.id) || selectedMembers?.map((m) => m.id).includes(result.id)} size="20px" setChecked={() => {
                                if (memberList.includes(result.id)) {
                                    return;
                                } else if (selectedMembers?.map((m) => m.id).includes(result.id)) {
                                    setSelectedMembers(selectedMembers?.filter((m) => m.id !== result.id));
                                } else {
                                    setSelectedMembers([...selectedMembers, result]);
                                }
                            }} />
                        </div>
                        ))
                    )}
                </div>
                <Button size="large" width="100%" height="44px" text="추가하기" disabled={selectedMembers.length === 0} onClick={() => {
                    context.post(`/class/${classId}/member`, { memberIds: selectedMembers.map((m) => m.id)}, () => {
                        onClose();
                    });
                }} />
            </ModalBody>
        </Modal>    
    );  
}

export default AddStudentModal;
