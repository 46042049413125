import PerfectScrollbar from "react-perfect-scrollbar";
import strings from "../../lang/strings";
import React, { useContext, useEffect, useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import CloudItem from "../../components/control/item/cloud-item";
import CloudViewModal from "../../components/control/modal/cloud_view_modal";
import CloudUploadModal from "../../components/control/modal/cloud_upload_modal";
import CloudVideoModal from "../../components/control/modal/cloud_video_modal";
import { Page_settings } from "../../config/page_settings";
import SweetAlert from "react-bootstrap-sweetalert";
import { getDecimal } from "../../helper/common";
import CloudContainer from "./cloud_container";

const Cloud = (props) => {
    const context = useContext(Page_settings);
    const [keyword, setKeyword] = useState("");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [sortCode, setSortCode] = useState(0);
    const [fileList, setFileList] = useState([]);
    const [selectedCnt, setSelectedCnt] = useState(0);
    const [selectedItemList, setSelectedItemList] = useState([]);
    const [showCloudItem, setShowCloudItem] = useState(false);
    const [showCloudVideoItem, setShowCloudVideoItem] = useState(false);
    const [showCloudUpload, setShowCloudUpload] = useState(false);
    const [item, setItem] = useState("");
    const [pageNum, setPageNum] = useState(1);
    const [loadMore, setLoadMore] = useState(false);
    const [showDelAlert, setShowDelAlert] = useState(false);
    const [scrollIndicator, setScrollIndicator] = useState(0);

    useEffect(() => {
        context.setContentBgType(2);
        getList("", "abc", 1);
        
        return () => {
            context.setContentBgType(1);
        }
    }, []);

    useEffect(() => {
        getList(keyword, strings.order_list[sortCode].code, 1);
    }, [sortCode]);

    const getType = (item) => {
        let ext = item.split(".")[item.split(".").length - 1];
        let type = "/assets/image/icon_file_";
        if (ext == "docx") {
            type += "doc";
        } else if (ext == "mp4") {
            type += "drive";
        } else {
            type += ext
        }
        type += ".png";
        return type
    };

    const getList = (keyword, order, pageNum) => {
        context.get(
            "cloud/getList",
            {
                keyword: keyword,
                order: order,
                page_num: pageNum
            },
            response => {
                setSelectedCnt(0);
                setSelectedItemList([]);
                if (pageNum == 1) {
                    setFileList(response.list)
                } else {
                    setFileList([...fileList, ...response.list]);
                    setPageNum(pageNum);
                }
                setLoadMore(response.list.length == response.limit)
            }
        );
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleEnter = e => {
        if (e.keyCode == 13) {
            onSearch()
        }
    };

    const onSearch = () => {
        getList(keyword, strings.order_list[sortCode].code, 1);
    };

    const onClick = (item) => {
        if (item.selected) {
            setSelectedCnt(selectedCnt - 1);
            let list = [];
            selectedItemList.forEach(it => {
                if (it.id != item.id) {
                    list.push(it);
                }
            });
            setSelectedItemList(list);
        } else {
            setSelectedCnt(selectedCnt + 1);
            setSelectedItemList([...selectedItemList, item]);
        }
        setFileList(prevList => prevList.map((it, idx) => {
            if (it.id == item.id) {
                it.selected = !it.selected
            }
            return it
        }))
    };

    const onDoubleClick = (item) => {
        setItem(item);
        let ext = item.title.split(".")[item.title.split(".").length - 1];
        if (ext == 'mp4') {
            setShowCloudVideoItem(true);
        } else {
            setShowCloudItem(true);
        }
    };

    const onClose = () => {
        setFileList(prevList => prevList.map((it, idx) => {
            it.selected = false;
            return it
        }));
        setSelectedItemList([]);
        setSelectedCnt(0)
    };

    const onOpenFile = () => {
        setItem(selectedItemList[0]);
        let ext = selectedItemList[0].title.split(".")[selectedItemList[0].title.split(".").length - 1];
        if (ext == 'mp4') {
            setShowCloudVideoItem(true);
        } else {
            setShowCloudItem(true);
        }
    };

    const onDownload = (list) => {
        context.download(
            "GET",
            "cloud/download",
            {
                id: list.join("#")
            },
            response => {
            }
        );
    };

    const toggleSweetAlert = () => {
        setShowDelAlert(!showDelAlert);
    };

    const onRemove = () => {
        toggleSweetAlert()
    };

    const doRemove = () => {
        let list = selectedItemList.map((item) => {
            return item.id
        });
        context.post(
            "cloud/delete",
            {
                id: list.join("#")
            },
            response => {
                let list_new = [];
                fileList.map((item, idx) => {
                    if (list.indexOf(item.id) == -1) {
                        list_new.push(item)
                    }
                });
                setFileList(list_new);
                setSelectedItemList([]);
                setSelectedCnt(0);
                toggleSweetAlert();
            }
        );
    };

    const onUploadShow = () => {
        onClose();
        setShowCloudUpload(true);
    };

    const onLoadMore = () => {
        if (scrollIndicator <= 3) {
            setScrollIndicator(scrollIndicator + 1)
        }
        if (loadMore && scrollIndicator == 2) {
            getList(keyword, strings.order_list[sortCode].code, pageNum + 1)
        }
    };

    return (
        <div className="cloud-content d-flex row">
            {/* <CloudContainer/> */}
            <PerfectScrollbar className={("cloud-scroll ") + (selectedCnt > 0 ? "cloud-scroll-small" : "")}
                options={{ suppressScrollX: true }} onYReachEnd={() => onLoadMore()}
                onScrollUp={() => setScrollIndicator(0)} onYReachStart={() => setScrollIndicator(0)}>
                <div>
                    <div className="d-flex align-items-center m-b-50">
                        <button className="cloud-upload-btn" onClick={() => onUploadShow()}>
                            <img src={"/assets/image/icon_upload.png"} alt="" />
                            <div>{strings.upload}</div>
                        </button>
                        <div className="cloud-search m-l-auto">
                            <input className="input-transparent"
                                value={keyword}
                                onChange={(e) => setKeyword(e.target.value)}
                                onKeyDown={handleEnter}
                                type={"text"}
                            />
                            <button className="common-button" onClick={() => onSearch()}>
                                <img src={"/assets/image/icon_search_black.png"} alt="" />
                            </button>
                        </div>
                    </div>
                    <div className="d-flex align-items-center m-b-50">
                        <div className="f-s-24 f-w-600">{strings.cloud_storage}</div>
                        <Dropdown isOpen={dropdownOpen} toggle={() => toggleDropdown()}
                            className="dropdown navbar-user rank-dropdown cloud-dropdown m-l-auto" tag="li">
                            <DropdownToggle tag="a"
                                className="display-flex-important align-item-center profile-dropdown"
                                style={{ background: "white", margin: 0 }}>
                                <div className="flex-grow-1"
                                    style={{ color: "#3f3f3f" }}>{strings.order_list[sortCode].name}</div>
                                <img src={"/assets/image/icon_arrow_down_black.png"} alt="" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu dropdown-menu-right profile-dropdown-menu" tag="ul">
                                {
                                    strings.order_list.map((item, idx) =>
                                        <DropdownItem key={idx}
                                            className="dropdown-header display-flex-important justify-content-center f-s-15"
                                            onClick={() => {
                                                setSortCode(idx);
                                            }}>
                                            {item.name}
                                        </DropdownItem>
                                    )
                                }
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div className="row">
                        {
                            fileList.map((item, idx) => {
                                let type = getType(item.title)
                                return (
                                    <CloudItem
                                        key={idx}
                                        type={type}
                                        item={item}
                                        onClick={() => onClick(item)}
                                        onDoubleClick={() => onDoubleClick(item)}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </PerfectScrollbar>
            {
                selectedCnt > 0 &&
                <PerfectScrollbar className="cloud-status" options={{ suppressScrollX: true }}>
                    {
                        selectedCnt == 1 &&
                        <div>
                            <div className="d-flex align-items-lg-start m-b-30">
                                <div className="f-s-20 f-w-600 p-r-10"
                                    style={{ wordBreak: "break-all" }}>{selectedItemList[0].title}</div>
                                <button className="common-button m-l-auto" onClick={() => onClose()}>
                                    <img src={"/assets/image/icon_close_gray.png"} alt="" />
                                </button>
                            </div>
                            <div className="cloud-status-item m-b-30">
                                <img src={getType(selectedItemList[0].title)} alt="" />
                            </div>
                            <div className="d-flex m-b-30">
                                <div className="cloud-detail f-s-14">{strings.type}</div>
                                <div
                                    className="f-s-15">{selectedItemList[0].title.split(".")[selectedItemList[0].title.split(".").length - 1] + strings._file}</div>
                            </div>
                            <div className="d-flex m-b-30">
                                <div className="cloud-detail f-s-14">{strings.size}</div>
                                <div
                                    className="f-s-15">{selectedItemList[0].size < 1024 ? "1" + strings._KB : (selectedItemList[0].size < 1048576 ? parseInt(selectedItemList[0].size / 1024).toString() + strings._KB : (selectedItemList[0].size < 1073741824 ? getDecimal((selectedItemList[0].size / 1048576).toString()) + strings._MB : getDecimal((selectedItemList[0].size / 1073741824).toString()) + strings._GB))}</div>
                            </div>
                            <div className="d-flex m-b-50">
                                <div className="cloud-detail f-s-14">{strings.date}</div>
                                <div className="f-s-15">{selectedItemList[0].create_date}</div>
                            </div>
                            <div className="horizontal-divider m-b-50" />
                            <div className="m-b-40">
                                <button className="common-button" onClick={() => onOpenFile()}>
                                    <img src={"/assets/image/icon_folder.png"} alt="" />
                                    <div>{strings.open_file}</div>
                                </button>
                            </div>
                            <div className="m-b-40">
                                <button className="common-button" onClick={() => {
                                    let list = selectedItemList.map((item) => {
                                        return item.id
                                    });
                                    onDownload(list)
                                }}>
                                    <img src={"/assets/image/icon_cloud_download.png"} alt="" />
                                    <div>{strings.download_file}</div>
                                </button>
                            </div>
                            <div>
                                <button className="common-button" onClick={() => onRemove()}>
                                    <img src={"/assets/image/icon_trash.png"} alt="" />
                                    <div>{strings.remove_from_cloud}</div>
                                </button>
                            </div>
                        </div>
                    }
                    {
                        selectedCnt > 1 &&
                        <div>
                            <div className="d-flex align-item-center m-b-40">
                                <div className={"f-s-16 flex-grow-1"}>{selectedCnt.toString() + strings._selected}</div>
                                <button className="common-button" onClick={() => onClose()}>
                                    <img src={"/assets/image/icon_close_gray.png"} alt="" />
                                </button>
                            </div>
                            <div className="horizontal-divider m-b-50" />
                            <div className="m-b-40">
                                <button className="common-button" onClick={() => {
                                    let list = selectedItemList.map((item) => {
                                        return item.id
                                    });
                                    onDownload(list)
                                }}>
                                    <img src={"/assets/image/icon_cloud_download.png"} alt="" />
                                    <div>{strings.download_file}</div>
                                </button>
                            </div>
                            <div>
                                <button className="common-button" onClick={() => onRemove()}>
                                    <img src={"/assets/image/icon_trash.png"} alt="" />
                                    <div>{strings.remove_from_cloud}</div>
                                </button>
                            </div>
                        </div>
                    }
                </PerfectScrollbar>
            }
            <CloudViewModal
                show={showCloudItem}
                toggle={() => setShowCloudItem(!showCloudItem)}
                item={item}
                onDownload={(list) => onDownload(list)}
            />
            <CloudUploadModal
                show={showCloudUpload}
                toggle={() => setShowCloudUpload(!showCloudUpload)}
                reload={() => getList(keyword, strings.order_list[sortCode].code, 1)}
            />
            <CloudVideoModal
                show={showCloudVideoItem}
                toggle={() => setShowCloudVideoItem(!showCloudVideoItem)}
                item={item}
            />
            {(showDelAlert &&
                <SweetAlert showCancel
                    confirmBtnText={strings.delete}
                    confirmBtnBsStyle="primary"
                    cancelBtnText={strings.cancel}
                    cancelBtnBsStyle="default"
                    title={strings.delete_cloud}
                    customClass="alert-white"
                    onConfirm={() => doRemove()}
                    onCancel={() => toggleSweetAlert()}
                    confirmBtnCssClass="alert-confirm"
                    cancelBtnCssClass="alert-cancel-white"
                >
                    {strings.delete_planner_alert}
                </SweetAlert>
            )}
        </div>
    );

}

export default Cloud;
