const AI_Sec1 = () => {
  return (
    <>
      <section className="wrap ai-sec1">
        <div className="inner">
          <div className="main-tit">
            <h2>
              학생에게 어디서부터 <br />
              설명해야할까요?
            </h2>
          </div>
          <div className="box-layout">
            <div className="wid-100 box1">
              <div className="txt-box">
                <h6>로드맵</h6>
                <h3>
                  결과를 얻으려면 <br />
                  과정이 중요합니다.
                </h3>
                <p>
                  과목별 라벨링을 통한 로드맵을 제시하여 <br />
                  학습을 어디서부터 해야할지 길을 알려줘요
                </p>
              </div>
              <div className="img-box">
                <img src="/assets/image/landing_page/sub/ai-sec1-box1.png" alt="로드맵" />
              </div>
            </div>
            <div className="wid-50 box2">
              <div className="txt-box">
                <h6>영상제공</h6>
                <h3>
                  등급 상관없이 <br />
                  기본 학습부터
                </h3>
                <p>
                  유닛별 모든 학생이 학습가능하도록 <br />
                  기본개념 영상을 제공합니다.
                </p>
              </div>
              <div className="img-box">
                <img src="/assets/image/landing_page/sub/ai-sec1-box2.png" alt="영상제공" />
              </div>
            </div>
            <div className="wid-50 box3">
              <div className="txt-box">
                <h6>문제제공</h6>
                <h3>
                  개념학습 후 <br />
                  아직도 멍때리기?
                </h3>
                <p>
                  개념을 문제에 적용할 수 있는 <br />
                  유닛별 콘텐츠를 제공합니다.
                </p>
              </div>
              <div className="img-box">
                <img src="/assets/image/landing_page/sub/ai-sec1-box3.png" alt="문제제공" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AI_Sec1;
