import React, { useContext, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import strings from "../../../lang/strings";
import styled from "styled-components";
import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { ReactNotifications, Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import moment from "moment";
import { Page_settings } from "../../../config/page_settings";
import SweetAlert from "react-bootstrap-sweetalert";

const CreateExam = (props) => {
  const context: any = useContext(Page_settings);
  const classId = parseInt(props.match.params.class);
  const examId = parseInt(props.match.params.exam_id);

  const [title, setTitle] = useState("");
  const [subject, setSubject] = useState("");
  const [items, setItems] = useState(0);
  const [fileUrl, setFileUrl] = useState("");
  const [answerList, setAnswerList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [limitTime, setLimitTime] = useState(0);
  const [classUsers, setClassUsers] = useState([]);
  const [dropDownList, setDropDownList] = useState([]);
  const [users, setUsers] = useState([]);
  const [titleError, setTitleError] = useState(false);
  const [subjectError, setSubjectError] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [omrError, setOMRError] = useState(false);
  const [saveAlert, setSaveAlert] = useState(false);
  const [createAlert, setCreateAlert] = useState(false);
  const [allCheckStatus, setAllCheckStatus] = useState(false);
  const minYesterday = moment().subtract(1, "day");
  const maxDate = moment().add(1, "month");
  const [clickInput, setClickInput] = useState(false);
  const [totalRate, setTotalRate] = useState("");

  const fileRef = useRef<HTMLInputElement>(null);

  const [startHour, setStartHour] = useState<number>(0);
  const [startMinute, setStartMinute] = useState<number>(0);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endHour, setEndHour] = useState<number>(0);
  const [endMinute, setEndMinute] = useState<number>(0);
  const [endDate, setEndDate] = useState<Date>(new Date());

  const answerItem = {
    type: 1,
    answer: "[]",
    rate: "",
  };

  useEffect(() => {
    if (examId !== 0) {
      getExamInfo();
    }
    getClassUsers();
  }, []);

  useEffect(() => {
    showDropDownList();
  }, [keyword, clickInput]);

  const getExamInfo = () => {
    context.post(
      "exam/info",
      {
        id: examId,
      },
      (response) => {
        const examInfo = response.info;
        setTitle(examInfo.title);
        setSubject(examInfo.subject);
        setItems(examInfo.items);
        setFileUrl(examInfo.file);
        setStartDate(new Date(moment(examInfo.start_time).format("YYYY-MM-DD")));
        setStartHour(parseInt(moment(examInfo.start_time).format("HH")));
        setStartMinute(parseInt(moment(examInfo.start_time).format("mm")));
        setEndDate(new Date(moment(examInfo.end_time).format("YYYY-MM-DD")));
        setEndHour(parseInt(moment(examInfo.end_time).format("HH")));
        setEndMinute(parseInt(moment(examInfo.end_time).format("mm")));
        setLimitTime(examInfo.time);
        const answerList = JSON.parse(examInfo.answer_data);
        setAnswerList(answerList);
      }
    );
  };

  const getClassUsers = () => {
    context.get(
      "class/getClassMemberList",
      { class_id: classId,
        only_student: true },
      (response) => {
        setClassUsers(response);
      }
    );
  };

  const minDateRange = (current) => {
    return current.isAfter(minYesterday) && current.isBefore(maxDate);
  };

  const onChangeItems = (item_count) => {
    let list = [];
    for (let idx = 0; idx < item_count; idx++) {
      list.push(answerItem);
    }
    setAnswerList(list);
    setItems(item_count);
  };

  const onFileChange = (e) => {
    if (e.target.files.length < 1) {
      return;
    }
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.onloadend = function () {
      uploadFile(file);
      e.target.value = null;
    };
    reader.readAsDataURL(file);
  };

  const uploadFile = (url) => {
    let formData = new FormData();
    formData.append("file", url);

    context.post("upload/file/exam", formData, (response) => {
      setFileUrl(response.file);
      setFileError(false);
    });
  };

  const onTempSave = () => {
    let answer = JSON.stringify(answerList);
    context.post(
      "exam/add",
      {
        id: examId,
        class_id: classId,
        title: title,
        subject: subject,
        items: items,
        file: fileUrl,
        start_time: moment(startDate).format("YYYY-MM-DD") + " " + startHour + ":" + startMinute,
        end_time: moment(endDate).format("YYYY-MM-DD") + " " + endHour + ":" + endMinute,
        time: limitTime,
        answer_data: answer,
        status: 0,
      },
      (response) => {
        props.history.push("/exam/" + classId);
      }
    );
  };

  const checkCreateAvailable = () => {
    var hasError = false;
    if (title === "") {
      setTitleError(true);
      hasError = true;
    }
    if (subject === "") {
      setSubjectError(true);
      hasError = true;
    }
    if (fileUrl === "") {
      setFileError(true);
      hasError = true;
    }

    if (!answerList.every((item) => {
      if (item.rate === "" || item.rate === 0 || item.answer === "" || item.answer === "[]") {
        return false;
      }
      return true;
    })) {
      setOMRError(true);
      hasError = true;
    } else {
      setOMRError(false);
    }

    if (hasError) {
      return;
    }

    if (moment(new Date()).isBefore(moment(new Date()), "day")) {
      addNotification("warning", strings.warning, strings.select_period);
      return;
    }

    const startDateString = moment(startDate).format("YYYY-MM-DD");
    const endDateString = moment(endDate).format("YYYY-MM-DD");
    const todayString = moment(new Date()).format("YYYY-MM-DD");

    if (startDateString === endDateString) {
      if (startHour > endHour) {
        addNotification("warning", strings.warning, "시작 시간이 종료 시간보다 늦습니다.");
        return;
      }
      if (startHour === endHour && startMinute >= endMinute) {
        addNotification("warning", strings.warning, "시작 시간이 종료 시간보다 늦습니다.");
        return;
      }
    } else if (startDate > endDate) {
      addNotification("warning", strings.warning, "시작 시간이 종료 시간보다 늦습니다.");
      return;
    }

    const currentTime = moment();
    if (endDateString < todayString) {
      addNotification("warning", strings.warning, "종료 시간이 현재 시간보다 빠릅니다.");
      return;
    } else if (endDateString === todayString) {
      if (endHour < parseInt(currentTime.format('HH'))) {
        addNotification("warning", strings.warning, "종료 시간이 현재 시간보다 빠릅니다.");
        return;
      } else if (endHour === parseInt(currentTime.format('HH'))) {
        if (endMinute < parseInt(currentTime.format('mm'))) {
          addNotification("warning", strings.warning, "종료 시간이 현재 시간보다 빠릅니다.");
          return;
        }
      }
    }

    if (limitTime === 0) {
      addNotification("warning", strings.warning, "제한 시간을 입력해주세요.");
      return;
    }

    if (users.length === 0) {
      addNotification("warning", strings.warning, "응시자를 추가하세요.");
      return;
    }

    setCreateAlert(true);
  };

  const onCreate = () => {
    let answer = JSON.stringify(answerList);

    context.post(
      "exam/add",
      {
        id: examId,
        class_id: classId,
        title: title,
        subject: subject,
        items: items,
        file: fileUrl,
        start_time: moment(startDate).format("YYYY-MM-DD") + " " + startHour + ":" + startMinute,
        end_time: moment(endDate).format("YYYY-MM-DD") + " " + endHour + ":" + endMinute,
        time: limitTime,
        testers: users.map((item) => item.member).join(","),
        answer_data: answer,
        status: 1,
      },
      (response) => {
        props.history.push("/exam/" + classId);
      }
    );
  };

  const onAnswerType = (index, type) => {
    const newList = answerList.map((item, idx) => {
      if (idx === index) {
        const updatedItem = {
          ...item,
          type: type,
          answer: type === 1 ? "[]" : "",
        };

        return updatedItem;
      }

      return item;
    });
    setAnswerList(newList);
  };

  const onChangeRate = (index, rate) => {
    const newList = answerList.map((item, idx) => {
      if (idx === index) {
        const updatedItem = {
          ...item,
          rate: rate,
        };

        return updatedItem;
      }

      return item;
    });
    setAnswerList(newList);
    setOMRError(false);
  };

  const onChangeAnswer = (index, answer) => {
    const newList = answerList.map((item, idx) => {
      if (idx === index) {
        const updatedItem = {
          ...item,
          answer: answer,
        };

        return updatedItem;
      }

      return item;
    });
    setAnswerList(newList);
  };

  const clickDropDownItem = (clickedItem) => {
    // setInputValue(clickedItem)
  };

  const onSelectAll = () => {
    setAllCheckStatus((prev) => !prev);
    if (!allCheckStatus) {
      setUsers((prev) => {
        const updatedItems = [
          ...prev,
          ...dropDownList.filter((item) => !prev.includes(item)),
        ];
        return updatedItems;
      });
    } else {
      setUsers([]);
    }
  };

  const handleCheckboxChange = (event, dropDownIndex) => {
    const isChecked = event
      ? event.target.checked
      : !users.includes(dropDownList[dropDownIndex]);

    if (isChecked) {
      setUsers((prev) => [...prev, dropDownList[dropDownIndex]]);
    } else {
      setUsers((prev) =>
        prev.filter((item) => item !== dropDownList[dropDownIndex])
      );
    }
  };

  const showDropDownList = () => {
    if (keyword === "") {
      //   setIsHaveInputValue(false);
      setDropDownList([]);
    } else {
      if (classUsers != null) {
        const choosenTextList = classUsers.filter((dataItem) =>
          dataItem.name.includes(keyword)
        );
        setDropDownList(choosenTextList);

        // Check if there are any unchecked items
        const hasUncheckedItems = choosenTextList.some(
          (item) => !users.includes(item)
        );

        if (hasUncheckedItems) {
          setAllCheckStatus(false);
        }
      } else {
        setDropDownList([]);
      }
    }

    if (keyword === "" && clickInput) {
      if (classUsers != null) {
        const choosenTextList = classUsers.filter((dataItem) =>
          dataItem.name.includes(keyword)
        );
        setDropDownList(choosenTextList);

        // Check if there are any unchecked items
        const hasUncheckedItems = choosenTextList.some(
          (item) => !users.includes(item)
        );

        if (hasUncheckedItems) {
          setAllCheckStatus(false);
        }
      } else {
        setDropDownList([]);
      }
    }
  };

  const onRemoveStudent = (index) => {
    const student = users[index];

    const newList = dropDownList.map((item, idx) => {
      if (item === student) {
        const updatedItem = {
          ...item,
          selected: false,
        };

        return updatedItem;
      }

      return item;
    });
    setDropDownList(newList);

    const newUserList = users.filter((userItem) => userItem !== student);
    setUsers(newUserList);
  };

  const addNotification = (
    notificationType,
    notificationTitle,
    notificationMessage
  ) => {
    Store.addNotification({
      title: notificationTitle,
      message: notificationMessage,
      type: notificationType,
      insert: "top",
      container: "top-center",
      dismiss: {
        duration: 1000,
      },
    });
  };

  const clickInputBox = (event) => {
    setClickInput(!clickInput);
  };

  const handleDropDownKey = (event) => {
    if (setUsers.length > 0) {
      if (event.key === "Enter") {
        handleEnterKeyPress();
      }
    }
  };

  const handleEnterKeyPress = () => {
    // setIsHaveInputValue(false)
    // setAllSelectedStatus(false);
    setKeyword("");
    setClickInput(false);
  };

  const fixRangeValue = (value, min, max) => {
    value = parseInt(value);
    if (value < min) {
      return min;
    } else if (value > max) {
      return max;
    } else {
      return value;
    }
  }

  useEffect(() => {
    setTotalRate((answerList.reduce((acc, cur) => {
      const rate = cur.rate ? cur.rate * 10 : 0;
      return acc + rate;
    }, 0)/10).toFixed(1));
  }, [answerList])

  return (
    <div style={{ width: "100%", height: "calc(100vh - 90px)" }}>
      <div
        className="main-content"
        style={{
          width: "100%",
          height: "100%",
          padding: "18px 28px 0",
          borderRadius: 12,
          background: "#fff",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ReactNotifications />
        <div className="f-s-25 f-w-600 m-b-10 d-flex flex-row align-items-center">
          {strings.create_exam}

          <button
            className={"exam-save-button"}
            style={{ marginLeft: "auto" }}
            onClick={() => {
              setSaveAlert(true);
            }}
          >
            {"임시저장"}
          </button>
          <button
            className={"exam-create-button"}
            style={{ marginLeft: 10 }}
            onClick={() => {
              checkCreateAvailable();
            }}
          >
            {"출제하기"}
          </button>
        </div>
        <div className="d-flex flex-row flex-1" style={{ overflow: "hidden" }}>
          <div
            className="info-wrap"
            style={{ flex: 1, overflowY: "auto", position: "relative" }}
          >
            <div className={"study-room-input"}>
              <div className="display-flex-important align-top f-w-600">
                <div>{"시험 제목"}</div>
              </div>
              <input
                className={titleError ? "error" : ""}
                placeholder={strings.enter_title}
                type={"text"}
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setTitleError(false);
                }}
              />
            </div>
            <div className={"study-room-input"}>
              <div className="display-flex-important align-top f-w-600">
                <div>{"과목"}</div>
              </div>
              <input
                className={subjectError ? "error" : ""}
                placeholder={"과목을 입력해주세요"}
                type={"text"}
                value={subject}
                onChange={(e) => {
                  setSubject(e.target.value);
                  setSubjectError(false);
                }}
                />
              </div>
              <div className={"study-room-input"}>
                <div className="display-flex-important study-room-input align-item-center f-w-600">
                <div>{"문항수"}</div>
                </div>
                <div className="display-flex-important m-t-10">
                <input
                  className={subjectError ? "error" : ""}
                  value={items === 0 ? "" : items}
                  onChange={(e) => {
                  if (e.target.value === "") {
                    onChangeItems(0);
                  } else {
                    const value = fixRangeValue(e.target.value, 1, 60);
                    onChangeItems(value);
                  }
                  }}
                  placeholder="문항 수를 입력해주세요 (1~60)"
                />
                </div>
              </div>
              <div className="display-flex-important study-room-input align-item-center f-w-600">
                <div>{"시험 파일"}</div>
              </div>
              <div className="display-flex-important m-t-10">
                {fileUrl && (
                <div className="exam-file">
                  <img
                  src="/assets/image/icon_file_pdf.png"
                  style={{ width: 21 }}
                  />
                  <button
                  className="remove-button"
                  onClick={() => {
                      setFileUrl("");
                    }}
                  >
                    <img src="/assets/image/icon_minus.png" />
                  </button>
                </div>
              )}
              <button
                className="exam-file-upload"
                onClick={() => {
                  if (fileRef.current) {
                    fileRef.current.click();
                  }
                }}
              >
                <img src="/assets/image/icon_plus_black.png" />
              </button>
              {fileError && (
                <div
                  style={{
                    marginLeft: 9,
                    marginTop: "auto",
                    color: "#EB4A4A",
                    fontSize: 12,
                    fontWeight: 500,
                    lineHeight: "13px",
                  }}
                >
                  {"* 파일을 업로드 해주세요."}
                </div>
              )}
              <input
                type="file"
                accept="application/pdf"
                className="hide"
                onChange={(e) => onFileChange(e)}
                ref={fileRef}
              />
            </div>
            <div className="display-flex-important study-room-input align-item-center f-w-600">
              <div>{"시험 기한"}</div>
            </div>
            <div className="display-flex-important m-t-10">
            <TimeRowWrapper>
              <div className="datepicker-custom-container timepicker-container">
                <DateTime
                    value={startDate}
                    onChange={(date) => setStartDate(new Date((date as moment.Moment).format("YYYY-MM-DD")))}
                    isValidDate={minDateRange}
                    timeFormat={false}
                    className="create-room-datepicker"
                />
              </div>
              <TimeInput
                  id="timeInput"
                  type="text"
                  value={startHour}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length > 2) return;
                    if (value > "23") return;
                    if (value === "") {
                      setStartHour(0);
                      return;
                    }
                    setStartHour(parseInt(value));
                    }}
              />
              <span>:</span>
              <TimeInput
                  id="timeInput"
                  type="text"
                  value={startMinute}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length > 2) return;
                    if (value > "59") return;
                    if (value === "") {
                      setStartMinute(0);
                      return;
                    }
                    setStartMinute(parseInt(value));}}
              />
              <span> ~ </span>
              <div className="datepicker-custom-container timepicker-container">
                <DateTime
                    value={endDate}
                    onChange={(date) => setEndDate(new Date((date as moment.Moment).format("YYYY-MM-DD")))}
                    isValidDate={minDateRange}
                    timeFormat={false}
                    className="create-room-datepicker"
                  />
              </div>
              <TimeInput
                  id="timeInput"
                  type="text"
                  value={endHour}
                  onChange={(e) => {
                    const value = e.target.value;
                    
                    if (value.length > 2) return;
                    if (value > "23") return;
                    if (value === "") {
                      setEndHour(0);
                      return;
                    }
                    
                    setEndHour(parseInt(value));}}
              />
              <span>:</span>
              <TimeInput
                  id="timeInput"
                  type="text"
                  value={endMinute}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length > 2) return;
                    if (value > "59") return;
                    if (value === "") {
                      setEndMinute(0);
                      return;
                    }

                    setEndMinute(parseInt(value));}}
              />
          </TimeRowWrapper>
            </div>
            <div className="display-flex-important study-room-input align-item-center f-w-600">
              <div>{"제한 시간"}</div>
            </div>
            <TimeDiv>
              <div className="display-flex-important align-item-center m-t-10">
                <input type={'number'} placeholder={'0'} value={Math.floor(limitTime / 60 ) === 0 ? '' : Math.floor(limitTime / 60 )} style={{textAlign: 'right', marginRight: '10px'}} onChange={(e) => {
                    const remainMinute = limitTime % 60
                    setLimitTime(fixRangeValue(e.target.value === '' ? 0 : e.target.value, 0, 6) * 60 + remainMinute)
                  }
                }/> <span style={{ fontSize: 15 }}>시간  </span>
                <input type={'number'} value={limitTime % 60} onChange={e => {
                    const hour = Math.floor(limitTime / 60)
                    setLimitTime(hour * 60 + fixRangeValue((e.target.value === '') ? 0 : e.target.value, 0, 59))
                }} style={{marginLeft: 10, marginRight: '10px', textAlign: 'right'}}/> <span style={{fontSize: 15}}>분</span>
              </div>
            </TimeDiv>
            <div className={"study-room-input"}>
              <div className="display-flex-important align-top f-w-600">
                <div>{"응시자"}</div>
              </div>

              <input
                placeholder={"학생을 검색해주세요"}
                type={"text"}
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                onKeyUp={handleDropDownKey}
                onClick={clickInputBox}
              />
              <button
                className="transparent-button"
                style={{ right: 10 }}
                onClick={() => {
                  if (keyword.length > 0) {
                    setKeyword("");
                    setClickInput(false);
                  }
                }}
              >
                <img
                  src={
                    "/assets/image/" +
                    (keyword.length > 0
                      ? "btn_delete2.png"
                      : "icon_search_black.png")
                  }
                  alt=""
                />
              </button>
            </div>

            {clickInput ? (
              // keyword.length > 0 &&
              <DropDownBox>
                {dropDownList.length === 0 && (
                  <DropDownItem>해당하는 단어가 없습니다</DropDownItem>
                )}
                {dropDownList.length > 0 && (
                  <DropDownItem>
                    <button
                      className={
                        allCheckStatus
                          ? "select-all-button"
                          : "unselect-all-button"
                      }
                      onClick={() => onSelectAll()}
                    >
                      {"모두 선택"}
                      <img
                        src={
                          allCheckStatus
                            ? "/assets/image/icon_checked.png"
                            : "/assets/image/icon_unchecked.png"
                        }
                        alt=""
                      />
                    </button>
                  </DropDownItem>
                )}
                {dropDownList.map((dropDownItem, dropDownIndex) => {
                  return (
                    <DropDownItem
                      key={dropDownIndex}
                      onClick={() => clickDropDownItem(dropDownItem.name)}
                      className={""}
                    >
                      <div
                        className="student-dropdown-container"
                        onClick={() =>
                          handleCheckboxChange(null, dropDownIndex)
                        }
                      >
                        <div className="student-profile">
                          <img
                            src={
                              dropDownItem.profile != null &&
                              dropDownItem.profile !== ""
                                ? context.loadImage(dropDownItem.profile)
                                : "/assets/image/chewing_plus_logo.png"
                            }
                          />
                        </div>
                        <div className="student-name">{dropDownItem.name}</div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={users.includes(dropDownItem)}
                            onChange={(event) =>
                              handleCheckboxChange(event, dropDownIndex)
                            }
                            onClick={(e) => e.stopPropagation()} // 클릭 이벤트가 부모에게 전파되지 않도록 함
                          />
                        </div>
                      </div>
                    </DropDownItem>
                  );
                })}
              </DropDownBox>
            ) : null}

            <div
              className={`student-box ${
                users.length === 0 ? "unselected-students" : "selected-students"
              }`}
            >
              {users.map((user, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      height: 36,
                      borderRadius: 18,
                      border: "1px solid #DFE1E8",
                      padding: "0 6px",
                      display: "flex",
                      alignItems: "center",
                      marginRight: 8,
                      cursor: "pointer",
                    }}
                  >
                    <img
                      style={{ width: 26, height: 26, borderRadius: 18 }}
                      src={
                        user.profile != null && user.profile !== ""
                          ? context.loadImage(user.profile)
                          : "/assets/image/chewing_plus_logo.png"
                      }
                    />
                    <div
                      style={{
                        marginLeft: 7,
                        fontSize: 13,
                        fontWeight: "500",
                        color: "#515151",
                      }}
                    >
                      {user.name}
                    </div>
                    <div
                      style={{ paddingLeft: 7 }}
                      onClick={() => onRemoveStudent(index)}
                    >
                      <img style={{}} src={"/assets/image/icon_x.png"} />
                    </div>
                  </div>
                );
              })}
              {users.length === 0 && (
                <div style={{ color: "#B6B6B6", fontSize: 13, margin: "auto" }}>
                  {"시험 출제 받을 학생을 추가해주세요."}
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              width: 1,
              background: "#DFE1E8",
              margin: "18px 24px 24px",
            }}
          />
          <div className="d-flex flex-column omr-wrap">
            <div className="display-flex-important study-room-input align-item-center f-w-600">
              <div>{"OMR 답안지"}</div>
              {omrError && (
                <div
                  style={{
                    marginLeft: 5,
                    color: "#EB4A4A",
                    fontSize: 12,
                    fontWeight: 500,
                    lineHeight: "13px",
                  }}
                >
                  {"* 점수를 입력하지 않았거나 답을 입력하지 않은 문제가 존재합니다."}
                </div>
              )}
            </div>
            <div className="omr-content">
              {items === 0 ? (
                <div className="omr-empty">{"문항수가 존재하지 않습니다."}</div>
              ) : (
                <div className="omr-data">
                  <div className="omr-header">
                    <div className="omr-no">{"문제"}</div>
                    <div
                      style={{
                        width: 1,
                        height: "100%",
                        background: "#DFE1E8",
                      }}
                    />
                    <div className="omr-type">
                      <div style={{ margin: "auto" }}>{"유형"}</div>
                    </div>
                    <div
                      style={{
                        width: 1,
                        height: "100%",
                        background: "#DFE1E8",
                      }}
                    />
                    <div className="omr-answer">
                      <div>1</div>
                      <div>2</div>
                      <div>3</div>
                      <div>4</div>
                      <div>5</div>
                    </div>
                    <div
                      style={{
                        width: 1,
                        height: "100%",
                        background: "#DFE1E8",
                      }}
                    />
                    <div className="omr-rate">
                      <div>{"배점"}</div>
                    </div>
                  </div>
                  <div className="omr-list">
                    {answerList.map((answer, index) => {
                      var selectedAnswers = answer.type === 1 ? JSON.parse(answer.answer) : null;                      
                      const toggleAnswer = (answer) => {
                        if (selectedAnswers.includes(answer)) {
                          selectedAnswers = selectedAnswers.filter(
                            (item) => item !== answer
                          );
                        } else {
                          selectedAnswers.push(answer);
                        }
                        selectedAnswers.sort((a, b) => a - b);
                      };

                      const changeChoice = (answer, index) => {
                        toggleAnswer(answer);
                        onChangeAnswer(
                          index,
                          JSON.stringify(selectedAnswers)
                        );
                      } 

                      return (
                        <div key={index} className="omr-item">
                          <div className="omr-no">{index + 1 + "번"}</div>
                          <div style={{
                              width: 1,
                              height: "100%",
                              background: "#DFE1E8",
                            }}
                          />
                          <div className="omr-type">
                            <button
                              className={
                                "type-button" +
                                (answer.type === 1 ? " selected" : "")
                              }
                              style={{ marginRight: 5 }}
                              onClick={() => {
                                onAnswerType(index, 1);
                              }}
                            >
                              {"객관식"}
                            </button>
                            <button
                              className={
                                "type-button" +
                                (answer.type === 2 ? " selected" : "")
                              }
                              onClick={() => {
                                onAnswerType(index, 2);
                              }}
                            >
                              {"주관식"}
                            </button>
                          </div>
                          <div
                            style={{
                              width: 1,
                              height: "100%",
                              background: "#DFE1E8",
                            }}
                          />
                          {answer.type === 1 ? (
                            <div className="omr-answer">
                              <button
                                className={
                                  "check-item" +
                                  (selectedAnswers.includes(1)
                                    ? " selected"
                                    : "")
                                }
                                onClick={() => changeChoice(1, index)}/>
                              <button
                                className={
                                  "check-item" +
                                  (selectedAnswers.includes(2)
                                    ? " selected"
                                    : "")
                                }
                                onClick={() => changeChoice(2, index)}/>
                              <button
                                className={
                                  "check-item" +
                                  (selectedAnswers.includes(3)
                                    ? " selected"
                                    : "")
                                }
                                onClick={() => changeChoice(3, index)}/>
                              <button
                                className={
                                  "check-item" +
                                  (selectedAnswers.includes(4)
                                    ? " selected"
                                    : "")
                                }
                                onClick={() => changeChoice(4, index)}/>
                              <button
                                className={
                                  "check-item" +
                                  (selectedAnswers.includes(5)
                                    ? " selected"
                                    : "")
                                }
                                onClick={() => changeChoice(5, index)}/>
                            </div>
                          ) : (
                            <div className="omr-answer">
                              <input
                                value={answer.answer}
                                onChange={(e) => {
                                  onChangeAnswer(index, e.target.value);
                                }}
                              />
                            </div>
                          )}
                          <div
                            style={{
                              width: 1,
                              height: "100%",
                              background: "#DFE1E8",
                            }}
                          />
                          <div className={"omr-rate"}>
                            <input
                              value={answer.rate}
                              type={"number"}
                              className={omrError ? " error" : ""}
                              onChange={(e) => {
                                onChangeRate(index, e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                    <div className="omr-item">
                      <div className="omr-no">합계</div>
                      <div style={{
                              width: 1,
                              height: "100%",
                              background: "#DFE1E8",
                            }}
                          />
                      <div className="omr-type"/>
                      <div style={{
                              width: 1,
                              height: "100%",
                              background: "#DFE1E8",
                            }}
                          />
                      <div className="omr-answer"/>
                      <div style={{
                              width: 1,
                              height: "100%",
                              background: "#DFE1E8",
                            }}
                          />
                      <div className="omr-rate" style={{display:'flex', justifyContent:'center'}}>
                      {totalRate} 
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {saveAlert && (
        <SweetAlert
          showCancel
          confirmBtnText={strings.save}
          confirmBtnBsStyle="primary"
          cancelBtnText={strings.cancel}
          cancelBtnBsStyle="default"
          title={"임시 저장"}
          customClass="exam-save-alert"
          onConfirm={() => onTempSave()}
          onCancel={() => setSaveAlert(false)}
          confirmBtnCssClass="alert-confirm"
          cancelBtnCssClass="alert-cancel"
        >
          <div>
            {"임시 저장을 하시겠습니까?"}
            <br />
            {"학생에겐 보이지 않습니다."}
          </div>
        </SweetAlert>
      )}
      {createAlert && (
        <SweetAlert
          showCancel
          confirmBtnText={"출제"}
          confirmBtnBsStyle="primary"
          cancelBtnText={strings.cancel}
          cancelBtnBsStyle="default"
          title={"시험 출제"}
          customClass="exam-save-alert"
          onConfirm={() => onCreate()}
          onCancel={() => setCreateAlert(false)}
          confirmBtnCssClass="alert-confirm"
          cancelBtnCssClass="alert-cancel"
        >
          <div>
            {"시험을 출제 하시겠습니까?"}
            <br />
            {"출제 하시면 시험장에 바로 업로드 됩니다."}
          </div>
        </SweetAlert>
      )}
    </div>
  );
};

const DropDownBox = styled.ul`
  display: block;
  position: absolute;
  margin: 0 auto;
  padding: 14px 13px 0;
  width: 100%;
  border-radius: 7px;
  border: 1px solid #dfe1e8;
  background: #fff;
  box-shadow: 0px 10px 30px 0px rgba(134, 134, 134, 0.25);
  list-style-type: none;
  z-index: 3;
  max-height: 200px; /* 최대 높이 설정 */
  overflow: auto; /* 스크롤바 표시 설정 */
`;

const DropDownItem = styled.li`
  text-align: start;
  padding: 0px 5px;
  margin: 10px 0px;
  &.selected {
    background-color: #eff3f9;
    border: 1px solid #eff3f9;
    border-radius: 8px;
  }
`;

const TimeDiv = styled.div`
  margin-top: 10px;
  background: 'transparent';
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  font-size: 15px;
  height: 50px;
  width: 400px;
  padding: 0 10px;
`

const TimeRowWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;


const TimeInput = styled.input`
    width: 60px;
    height: 50px;
    border-radius: 5px;
    border: #e9ecee 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fdfdfd;
    padding: 0 20px;
    font-size: 15px;
    margin-right: 10px;
`; 

export default withRouter(CreateExam);
