import React, { useContext } from 'react';
import { Modal, ModalBody } from "reactstrap";
import './modal.scss'
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Page_settings } from "../../../config/page_settings";

const RoadmapSolveModal = (props) => {
    const context = useContext(Page_settings)
    const { show, toggle, item } = props;

    return (
        <Modal isOpen={show} toggle={() => toggle()} className="cloud-view-modal roadmap-solve-modal">
            <ModalBody>
                <div className="d-flex align-items-center p-l-30 p-r-20 p-t-15 p-b-15">
                    <div className="f-s-22 f-w-700">{item.name}</div>
                    <button className='modal-close-btn m-l-auto' onClick={() => toggle()}>
                        <img src={"/assets/image/icon_close_black.png"} alt="" className="modal-close" />
                    </button>
                </div>
                <DocViewer className="cloud-view-main" documents={[{ uri: context.loadImage('uploads/aistudy/' + item.code + '.pdf') , fileType:'pdf'}]} pluginRenderers={DocViewerRenderers}
                    config={{
                        header: {
                            disableHeader: true,
                            disableFileName: true,
                            retainURLParams: false
                        },
                        pdfVerticalScrollByDefault: true
                    }} />
            </ModalBody>
        </Modal>
    );
}

export default RoadmapSolveModal;
