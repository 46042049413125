import React from "react";
import { IconType } from "../../type/icon_type";

const IconPin = (props: IconType) => {
    const { width="24", height="24", color="#73787E" } = props;

    return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.3174 8.77393L15.2261 4.68263C14.9648 4.42147 14.6453 4.22612 14.2938 4.11267C13.9422 3.99923 13.5688 3.97094 13.2042 4.03013C12.8395 4.08933 12.4942 4.23431 12.1966 4.45314C11.899 4.67198 11.6577 4.95839 11.4925 5.28878L9.42822 9.41738C9.35396 9.56625 9.2243 9.68007 9.06708 9.73445L5.5353 10.9552C5.36553 11.0139 5.21346 11.1147 5.09336 11.2483C4.97327 11.3819 4.8891 11.5438 4.84875 11.7188C4.80841 11.8938 4.81321 12.0762 4.86272 12.2489C4.91222 12.4216 5.0048 12.5788 5.13176 12.7059L7.76322 15.3373L4 19.1014V20H4.89863L8.66269 16.2359L11.295 18.8682C11.4221 18.9952 11.5793 19.0878 11.752 19.1373C11.9246 19.1868 12.107 19.1916 12.2821 19.1512C12.4571 19.1109 12.619 19.0267 12.7525 18.9066C12.8861 18.7865 12.987 18.6345 13.0456 18.4647L14.2664 14.9329C14.3206 14.7758 14.4341 14.6462 14.5826 14.5718L18.7112 12.5075C19.0416 12.3423 19.328 12.101 19.5469 11.8034C19.7657 11.5058 19.9107 11.1605 19.9699 10.7958C20.0291 10.4312 20.0008 10.0578 19.8873 9.70624C19.7739 9.35471 19.5785 9.03516 19.3174 8.77393Z" fill={color}/>
        <path d="M19.3174 8.77393L15.2261 4.68263C14.9648 4.42147 14.6453 4.22612 14.2938 4.11267C13.9422 3.99923 13.5688 3.97094 13.2042 4.03013C12.8395 4.08933 12.4942 4.23431 12.1966 4.45314C11.899 4.67198 11.6577 4.95839 11.4925 5.28878L9.42822 9.41738C9.35396 9.56625 9.2243 9.68007 9.06708 9.73445L5.5353 10.9552C5.36553 11.0139 5.21346 11.1147 5.09336 11.2483C4.97327 11.3819 4.8891 11.5438 4.84875 11.7188C4.80841 11.8938 4.81321 12.0762 4.86272 12.2489C4.91222 12.4216 5.0048 12.5788 5.13176 12.7059L7.76322 15.3373L4 19.1014V20H4.89863L8.66269 16.2359L11.295 18.8682C11.4221 18.9952 11.5793 19.0878 11.752 19.1373C11.9246 19.1868 12.107 19.1916 12.2821 19.1512C12.4571 19.1109 12.619 19.0267 12.7525 18.9066C12.8861 18.7865 12.987 18.6345 13.0456 18.4647L14.2664 14.9329C14.3206 14.7758 14.4341 14.6462 14.5826 14.5718L18.7112 12.5075C19.0416 12.3423 19.328 12.101 19.5469 11.8034C19.7657 11.5058 19.9107 11.1605 19.9699 10.7958C20.0291 10.4312 20.0008 10.0578 19.8873 9.70624C19.7739 9.35471 19.5785 9.03516 19.3174 8.77393Z" fill={color} fill-opacity="0.2"/>
    </svg>
    );
};

export default IconPin;
